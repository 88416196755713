export class Receiver {

    constructor (
        public sentRecipientGroup: string , 
        public sentRecipientName: string , 
        public sentToMessage: string[], 
        public sentToMail: string[],
        public sentCcMail: string[],
        public sentBccMail: string[],
        public sentGreetings: string
    ){}
}