
<div class="row">
    <div class="col-xs-12">

     

        <div class="row">
        
            <div class="col-xs-12">
              
                <button type="button" 
                        class="btn btn-link"
                        routerLink="/results" 
                        routerLinkActive="activebutton" 
                        mat-dialog-close >
                        go to results

                </button>
            </div>
            
        </div>


    </div>
</div>






              <!-- BUTTON TO GO TO SEE DIRECTLY THE CHAT   -->
            <!-- <div class="col-xs-6">
               
                <button  (click)="onRouterToChatItem()"
                         routerLinkActive="activebutton"
                         mat-dialog-close  
                         class="btn btn-primary"> 
                         see group 
                </button>
            </div> -->
             <!-- END BUTTON TO GO TO SEE RESULTS LIST  -->
