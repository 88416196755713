import { Component, OnInit, Input , ViewChild, Output , EventEmitter, OnDestroy} from '@angular/core';
import { Tab } from '../tab.model';
import { TabService } from '../tab.service';
import { MatTabGroup } from '@angular/material/tabs';
import { Subject, Observable, Subscription } from 'rxjs';
import { FeatureResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/feature-result.model';
import { ParagraphResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/paragraph-result.model';
import { TabFeatureDetailResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/tab-feature-results/tab-feature-result.model';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { Feature } from '../../../feature.model';
import { WordInputDetailResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/word-input-result/word-input-detail-result.model';
import { FormService } from 'src/app/forms/form.service';
import { AdviseIfHttpStoreComponentsCallHasBeenMadeService } from 'src/app/services/other-components-services/advise-if-http-store-components-has-been-made.service';
import { StoreTabComponentsService } from 'src/app/services/get-and-store-components/store-tab-components.service';
import { StoreTabItemPlusChildsConfirmationService } from 'src/app/services/get-and-store-components/store-tab-item-plus-childs-confirmation.service';



@Component({
  selector: 'app-tab-item',
  templateUrl: './tab-item.component.html',
  styleUrls: ['./tab-item.component.css']
})
export class TabItemComponent implements OnInit , OnDestroy {

  user: string 
  
  @Input() isParagraphEdit: boolean;
  @Input() noNeeded: boolean;
  @Input() index_id: string;
  index: number;
  @Input() feature: Feature

  @Input() paragraphEdit_selectedIndex_id: string 

  tab: Tab

  selectedIndex = 0

  @ViewChild('tabs') tabGroup: MatTabGroup

  paragraphResult: ParagraphResult;
  featureResult: FeatureResult;
   tabFeatureDetailResult: TabFeatureDetailResult;
   tabFeatureDetailResults: TabFeatureDetailResult[]
   selectedTabFeatureDetailResult: TabFeatureDetailResult[]




  

  subscribeClickEvent: Subject<void> = new Subject<void>();
  @Output() updateFeatureResultEvent = new EventEmitter<FeatureResult>();



   /**
    * output to paragraphItem the mailTextbody part
    */
    @Output() updateEmailTextFeatureBody = new EventEmitter<string>()
    mailTextParagraphBodyPart: string;
    mailTextParagraphBodyParts: string[];
    finalMailtextParagraphBodyPart: string ;
    featureCounter: number
    totalMailTextFeaturesResultList: number[];

    mailTextFeatureBodyPart: string 


    dummyResultList: any[]
    subscribeToChildComponentsResultsAgain = new Subject<void>();

    textResultsToOrder: string[]

    @Output() updateBothFeatureResultAndTextPlusIndex = new EventEmitter<any>()
  
    updateOnUserEmail: Subscription 

    isLoading: boolean = false 

    tabComponentPlusItemHaveBeenStoreConfirmation: Subscription 

   constructor( private tabService: TabService , 
                private userObjectUpdateService: UserObjectUpdateService , 
                private formService: FormService , 
                private adviseIfHttpStoreComponentsCallHasBeenMadeService: AdviseIfHttpStoreComponentsCallHasBeenMadeService , 
                private storeTabComponentService: StoreTabComponentsService , 
                private storeTabItemPlusChildsConfirmationService: StoreTabItemPlusChildsConfirmationService
                ) { }


  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
    .subscribe((updatedUserEmail)=>{
     this.user = updatedUserEmail
     console.log('updated USER = ', this.user)
    })

    this.isLoading = true 
    
    this.tab = this.tabService.getTabBy_id(this.index_id)
    this.index = 0
    console.log('feature = ', this.feature, this.tab )

        /**
     * check if the component + childs have been stored 
     * check if id match with index_id or keep loading 
     */
        if ( this.user === null ){
      
          if ( this.isParagraphEdit &&  this.tab._id === this.paragraphEdit_selectedIndex_id ){ this.continueOnInIt() }
          if ( this.isParagraphEdit &&  this.tab._id !== this.paragraphEdit_selectedIndex_id ){ this.isLoading = true , console.log('fire an error , and go back to ??  ')}
          if ( !this.isParagraphEdit &&  this.tab._id === this.paragraphEdit_selectedIndex_id ){this.continueOnInIt() }
        }
        /**
         * check if 
         */
        if ( this.user !== null ){ 
            if ( this.isParagraphEdit &&  this.tab._id === this.paragraphEdit_selectedIndex_id ){ 
                 if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.tabListPlusChildsHasBeenStoredList.find(x=> x.tab_id === this.index_id).hasBeenStored === true ){
                      if ( this.tab.owner === this.user ){ 
                        // and then stop loading 
                        this.continueOnInIt()
                        console.log( 'is editing personal tab, keep loading for now before we get the childs components ')
                      }
                      if ( this.tab.owner !== this.user ){
                        // throw an error should not be able to edit a different user component 
                        // redirect user to edit page or return to where he was 
                        console.log('fire an error message and send back to ??')
                        
                      }
    
                 }
                 if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.tabListPlusChildsHasBeenStoredList.find(x=> x.tab_id === this.index_id).hasBeenStored !== true ){
                      // call tab + childs 
                      this.storeTabComponentService.getPersonalTab_withChilds_by_id(this.user , this.tab._id)
                      console.log( 'is editing personal multiFeature, keep loading for now before we get the childs components ')
                 }
            }
    
            if ( this.isParagraphEdit &&  this.tab._id !== this.paragraphEdit_selectedIndex_id ){ this.isLoading = true  , console.log( 'fire an error plus send back to ?? ')}
            if ( !this.isParagraphEdit &&  this.tab._id === this.paragraphEdit_selectedIndex_id ){ this.continueOnInIt() }
    
        }

  this.tabComponentPlusItemHaveBeenStoreConfirmation = this.storeTabItemPlusChildsConfirmationService.tab_id_plusChildsHasBeenStored
  .subscribe((tab_id_isStored: string )=>{

    if ( this.tab._id === this.paragraphEdit_selectedIndex_id ){  console.log(tab_id_isStored) ,  this.continueOnInIt() }
})

   
    
}

continueOnInIt(){

  this.tabFeatureDetailResults = []

  /**
   * build dummy initial featureDetailResults  and text array 
   */
   const newWordInputResultLabel = ''
   const newWordInputResultContent = ''
   const newWordInputResultHint = ''
   const newWordInputDetailResult = new WordInputDetailResult ( newWordInputResultLabel , newWordInputResultContent, newWordInputResultHint )
   
   const newTabFeatureDetailResults = []
   this.textResultsToOrder = []
   for ( let i=0; i< this.tab.tabDetails[0].features.length ; i++){

    newTabFeatureDetailResults.push(newWordInputDetailResult);

    this.textResultsToOrder.push('')
    

  }
  this.tabFeatureDetailResults = newTabFeatureDetailResults

  console.log(this.textResultsToOrder)

  this.isLoading = false 


}

  /** for each click we recreate a new feature  */
onLinkClick($event) {

  this.index = this.tabGroup.selectedIndex ; 

   /**
     * build dummy initial featureDetailResults 
     */
    const newWordInputResultLabel = ''
    const newWordInputResultContent = ''
    const newWordInputResultHint = ''
    const newWordInputDetailResult = new WordInputDetailResult ( newWordInputResultLabel , newWordInputResultContent, newWordInputResultHint )
    
    const newTabFeatureDetailResults = []
    this.textResultsToOrder= []
    for ( let i=0; i< this.tab.tabDetails[this.index].features.length ; i++){

     newTabFeatureDetailResults.push(newWordInputDetailResult)

     this.textResultsToOrder.push('')
    }

    console.log(this.textResultsToOrder)
    this.tabFeatureDetailResults = newTabFeatureDetailResults

    /**
     * subscribe to child components to send the respective results again 
     */
    // this.subscribeToChildComponentsResultsAgain.next()
    this.formService.sendRequestToFinalizeResultAgain()
 
   }



onUpdateBothFeatureResultTextResultAndIndex($event){
  console.log($event)
  console.log(this.index)

  console.log( this.mailTextParagraphBodyParts)
 
  if ( $event.newTab_index === this.index ){ 

    this.tabFeatureDetailResults[$event.newFeatureDrop_Id] = $event.newFeatureResult
    this.textResultsToOrder[$event.newFeatureDrop_Id] = $event.newMailTextFeatureBodyPart

    console.log(this.textResultsToOrder)
  }
  console.log( this.tabFeatureDetailResults)

  if (this.isParagraphEdit !== true){
      this.sendFinalFeatureResult()
   }


};

onSend(){
  this.sendFinalFeatureResult()
};


/**
 * compose final feature with selected tab only
 */
    sendFinalFeatureResult(){

      const newTabFeatureDetailResultType = 'tabFeatureResult'
      const newTabfeatureDetailResultName = this.tab.name
      console.log(this.tabFeatureDetailResults)
      const newTabFeatureDetailResults = this.tabFeatureDetailResults

      const newFeatureResult = new FeatureResult ( newTabFeatureDetailResultType, newTabfeatureDetailResultName, newTabFeatureDetailResults)
      console.log(newFeatureResult)
  
      this.featureResult = newFeatureResult 

      for ( let i=0; i<this.textResultsToOrder.length; i++){
        if ( i === 0){this.mailTextFeatureBodyPart = this.textResultsToOrder[i] + '%0D%0A'}
        if ( i !== 0){this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart + this.textResultsToOrder[i] + '%0D%0A'}

      }

      console.log(this.mailTextFeatureBodyPart)
      const newTab_index = null 
      const newFeatureDrop_Id = this.feature.dropId
      const newMailTextFeatureBodyPart = this.mailTextFeatureBodyPart
  
      this.updateBothFeatureResultAndTextPlusIndex.emit({  newTab_index , newFeatureDrop_Id, newFeatureResult, newMailTextFeatureBodyPart})
  

    }


    
    ngOnDestroy(){

      this.updateOnUserEmail.unsubscribe()
      this.tabComponentPlusItemHaveBeenStoreConfirmation.unsubscribe()
    
    }



}
