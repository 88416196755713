import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { AppRoutingModule } from "../app-routing.module";
import { MaterialDesignModule } from "../material-design/material-design.module";
import { SharedModule } from "../shared/shared.module";
import { DateAndTimeComponent } from "./date-and-time.component";
import { FormDateAndTimeComponent } from "./form-date-and-time/form-date-and-time.component";
import { ParagraphDateAndTimeComponent } from "./paragraph-date-and-time/paragraph-date-and-time.component";

@NgModule({
    declarations: [
    DateAndTimeComponent,
    FormDateAndTimeComponent,
    ParagraphDateAndTimeComponent
    ],

    exports: [
        DateAndTimeComponent,
        FormDateAndTimeComponent,
        ParagraphDateAndTimeComponent
        ],


        imports: [
            AppRoutingModule,
            CommonModule,
            FormsModule,
            MaterialDesignModule, 
            ReactiveFormsModule,
            LayoutModule,
            MatToolbarModule,
            MatButtonModule,
            MatSidenavModule,
            MatIconModule,
            MatListModule,
            MatExpansionModule,
            SharedModule,
        ],

})
   

export class DateAndTimeModule {}