<!-- <p>fix-layout-edit-string works!</p> -->


<div class="row">
    <div class="col-xs-12">
        <form [formGroup]="fixLayoutEditStringForm">

            <div class="row">
                <mat-form-field>
                    <mat-label>label</mat-label> 
                    <input 
                       matInput 
                       placeholder='example'
                       type="text"
                       id="label"
                       formControlName="label" >
                    <mat-hint > here is the "hint"  </mat-hint>
                </mat-form-field>
            </div>
           
            <div class="row">
                <mat-form-field>
                    <mat-label>word</mat-label> 
                    <input 
                        matInput 
                        placeholder='example'
                        type="text"
                        id="name"
                        formControlName="name" >
                    <mat-hint > here is the "hint"  </mat-hint>
                </mat-form-field>
            </div>

            <br>

            <div class="row">
                <button (click)="onSubmitString()" class="btn btn-primary">confirm</button>
            </div>

        </form>
    </div>
</div>
