import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { RangeInfo } from '../range-info.model';
import { Observable, Subject, Subscription, interval } from 'rxjs';
import { FixTextDetail } from '../../../texts/fix-text-list/fix-text-detail.model';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { RangeInfoResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/shared-calendar-status-result/range-info-result.model';
import { FormService } from 'src/app/forms/form.service';
import { IsOnlineOfflineService } from 'src/app/services/shared-services/is-online-offline.service';

interface InputLabelInterface {

  exist: boolean ,
  name: string,
  maxLength: number,
  errorHandler: boolean 
}

@Component({
  selector: 'app-inner-input-item',
  templateUrl: './inner-input-item.component.html',
  styleUrls: ['./inner-input-item.component.css']
})
export class InnerInputItemComponent implements OnInit , OnDestroy{

  @Input() rangeInfo: RangeInfo
  @Input() rangeInfo_index: number
  @Input() isParagraphEdit: boolean 

  @Input() changeSelectedDatesView: Observable<void>
  detectChangeOfSelectedDates: Subscription

  @Input() canUseIt: boolean 

  initialPlaceholder: string 

  inputTextInnerCompNameInterface: InputLabelInterface
  inputTextLabelInterface: InputLabelInterface
  inputEditTextLabelInterface: InputLabelInterface
  innerTextItem: FixTextDetail
  emailInput: string = ''
  accessType: string 
  textInput: string = ''
  initialText: string = ''
 
  temporaryTextToAdd: string = ''
  temporaryEditedText: string = ''

  emptyInputNameAfterAdded = new Subject<void>()

  private inputSubject = new Subject<any>();
  private debounceTimeMs = 1500; // Adjust the debounce time as needed

  editingVisible = false 

  rangeInfoResult: RangeInfoResult

  @Output() emitSelectedValues = new EventEmitter<any>() 

  isFirstTimeUpdating: boolean = true 
  ngUnsubscribe = new Subject<void>();


  constructor( private ngZone: NgZone , 
               private cdr: ChangeDetectorRef , 
               private isOnlineOfflineService: IsOnlineOfflineService
             ) 
             {
                this.inputSubject.pipe(
                     debounceTime(this.debounceTimeMs),
                     takeUntil(this.ngUnsubscribe)
                     )
                    .subscribe((textPlusRangeInfo: any) => {

                      const newText: string = textPlusRangeInfo.newInitialText
                      const newRangeInfo: RangeInfo = textPlusRangeInfo.newRangeInfo 
                     this.saveText(newText, newRangeInfo);
                });
              }

  ngOnInit(): void {

    console.log('this rangeInfo input can be used => ', this.canUseIt)

    const newrangeDetail: any = this.rangeInfo.rangeDetails[3]
    const newInitialText: FixTextDetail = newrangeDetail
    this.initialText = newInitialText.text 

    const newMaxLengthText: any = this.rangeInfo.rangeDetails[1] 

    console.log( ' INITIAL TEXT => ', this.rangeInfo, newInitialText, this.initialText )

    const newMaxLength: number = parseInt( newMaxLengthText.text , 10 )
    console.log('MAX LENGTH IS => ',newMaxLength)

    const inputCompName: any = this.rangeInfo.rangeDetails[0]
    this.inputTextInnerCompNameInterface = { exist: true, name: inputCompName.text , maxLength: newMaxLength, errorHandler: true }

    const newPlaceholder: any = this.rangeInfo.rangeDetails[2]
    this.initialPlaceholder = newPlaceholder.text

    this.detectChangeOfSelectedDates = this.changeSelectedDatesView
        .subscribe(()=> {
          this.updateSelectedDatesViewInfo()
    })


  }


  updateSelectedDatesViewInfo(){
    this.ngZone.run(()=>{
      this.rangeInfo = this.rangeInfo
    })
    this.cdr.detectChanges()

  }



  onUpdateNameInputEvent($event){
    console.log( ' UPDATED NAME INPUT EVENT ',$event)

        /**
     * check if the selectedrangeDatesInfo were still the same at the moment we are updating this 
     *  1- call the latest sharedCalendarStatusItem
     *  2- check if the same selectedrangeDatesInfo at least has still the same values? 
     *  3- if it exist and had the same setup , we can update it , 
     *  4- if it hadn't anymore the setup and the particular option didn't exist anymore , pop up error and reset with latest valid update 
     */

    if ( this.isOnlineOfflineService.checkIsOnlineOffline() ){

         const newUpdatedRangeDetail = new FixTextDetail ($event)
  
         const rangeDetail0: any = this.rangeInfo.rangeDetails[0]
         const rangeDetail1: any = this.rangeInfo.rangeDetails[1]
         const rangeDetail2: any = this.rangeInfo.rangeDetails[2]

         const newInnerDetails:FixTextDetail[]= [rangeDetail0,rangeDetail1, rangeDetail2 , newUpdatedRangeDetail ]
         const newRangeInfo = new RangeInfo(this.rangeInfo.type, this.rangeInfo.name, this.rangeInfo.usersAccess, newInnerDetails )

        const newInitialText = $event

         this.inputSubject.next({ newInitialText, newRangeInfo  });
         this.editingVisible = true 
    }

    if ( !this.isOnlineOfflineService.checkIsOnlineOffline() ){
      console.log('THROW ONLINE ERROR ')
    }

 
    

  }

  onUpdateNameInputValidity($event){
    console.log($event)
    
  }

  saveText(text: string, newRangeInfo: RangeInfo) {

    console.log('Saving:', text);


    this.editingVisible = false 

    this.onSend(newRangeInfo)
    this.isFirstTimeUpdating = true 
   
  }

  onSend(newRangeInfo: RangeInfo){
    
    const rangeInfo_index = this.rangeInfo_index
    console.log('new range info => ',newRangeInfo)
  
    this.emitSelectedValues.emit({ rangeInfo_index, newRangeInfo})
 
  }


  ngOnDestroy(): void {
      this.detectChangeOfSelectedDates?.unsubscribe()
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete()
  }

}
