import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { InnerArraySelect } from '../inner-array-select.model';
import { RangeInfo } from '../range-info.model';
import { Observable, Subject, Subscription } from 'rxjs';
import { RangeInfoResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/shared-calendar-status-result/range-info-result.model';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { SharedCalendarStatusService } from '../shared-calendar-status.service';


@Component({
  selector: 'app-inner-checkbox-item',
  templateUrl: './inner-checkbox-item.component.html',
  styleUrls: ['./inner-checkbox-item.component.css']
})
export class InnerCheckboxItemComponent implements OnInit , OnDestroy {

  @Input() rangeInfo: RangeInfo
  @Input() rangeInfo_index: number
  @Input() isParagraphEdit: boolean 

  @Input() changeSelectedDatesView: Observable<void>
  detectChangeOfSelectedDates: Subscription

  @Input() canUseIt: boolean 
  isDisabled: boolean 

  innerCheckboxItem: InnerArraySelect

  @Output() emitSelectedValues = new EventEmitter<any>()

  rangeInfoResult: RangeInfoResult

  ngUnsubscribe = new Subject<void>();
  private checkboxSubject = new Subject<any>();
  private debounceTimeMs = 1500; // Adjust the debounce time as needed

  copyOfDetails: any[]
 
  constructor( private ngZone: NgZone , 
               private cdr: ChangeDetectorRef ,
               private sharedCalendarStatusService: SharedCalendarStatusService
             ){
              this.checkboxSubject.pipe(
                debounceTime(this.debounceTimeMs),
                takeUntil(this.ngUnsubscribe)
                )
               .subscribe((rangeInfo_AndIndex: any) => {

                this.onSend(rangeInfo_AndIndex)

           });
             }


  ngOnInit(): void {

    console.log('this rangeInfo checkbox can be used => ', this.canUseIt)

    this.copyOfDetails = this.sharedCalendarStatusService.createRangeInfoDetailClone_OfDeterminedRangeInfo(this.rangeInfo.type, this.rangeInfo.rangeDetails)

    this.isDisabled = !this.canUseIt
    
    this.detectChangeOfSelectedDates = this.changeSelectedDatesView
        .subscribe(()=> {
          this.updateSelectedDatesViewInfo()
    })

  }

  updateSelectedDatesViewInfo(){
    this.ngZone.run(()=>{
      this.rangeInfo = this.rangeInfo 

    })
    this.cdr.detectChanges()

  }

 

  isCheckboxDisabled(index: number): boolean {
    // Add your custom logic to determine the disabled state of the checkbox
    // For example, you can disable a checkbox based on certain conditions 

    return true;
  }

  debounceHasStarted: boolean = false 
  rangeInfo_DebounceCopy: RangeInfo 

  onCheckboxChange($event, i){
    console.log(i , ' => ', $event)

    if ( !this.debounceHasStarted ){

    this.debounceHasStarted = true 
         
        //  let newRangeInfo: RangeInfo = this.sharedCalendarStatusService.createRangeInfosClones([this.rangeInfo])[0]

          const newType = this.rangeInfo.type 
          const newName = this.rangeInfo.name
          const newUserAccess = this.sharedCalendarStatusService.createUsersAccessClone_OfDeterminedRangeInfo(this.rangeInfo.usersAccess)
          const newRangeDetails = this.sharedCalendarStatusService.createRangeInfoDetailClone_OfDeterminedRangeInfo(this.rangeInfo.type, this.rangeInfo.rangeDetails)

          let newRangeInfo = new RangeInfo (newType, newName, newUserAccess, newRangeDetails)
         const newSelectedRangeDetail: InnerArraySelect = newRangeDetails[i]
               newSelectedRangeDetail.selected = $event 

         newRangeInfo.rangeDetails[i] = newSelectedRangeDetail 
         const rangeInfo_index = this.rangeInfo_index 

         this.rangeInfo_DebounceCopy = newRangeInfo
  
         this.checkboxSubject.next({ rangeInfo_index , newRangeInfo  });
         

    }

    if ( this.debounceHasStarted ){

      let newRangeInfo: RangeInfo = this.rangeInfo_DebounceCopy 

         const newRangeDetails: any[] = newRangeInfo.rangeDetails
         const newSelectedRangeDetail: InnerArraySelect = newRangeDetails[i]
               newSelectedRangeDetail.selected = $event 

         newRangeInfo.rangeDetails[i] = newSelectedRangeDetail 
         const rangeInfo_index = this.rangeInfo_index 

         this.rangeInfo_DebounceCopy = newRangeInfo
  
         this.checkboxSubject.next({ rangeInfo_index , newRangeInfo  });

    }
  }

  onSend(rangeInfo_AndIndex: any){
    this.debounceHasStarted = false 
    console.log(rangeInfo_AndIndex)

    const rangeInfo_index = rangeInfo_AndIndex.rangeInfo_index 
    const newRangeInfo = rangeInfo_AndIndex.newRangeInfo

    this.emitSelectedValues.emit({ rangeInfo_index, newRangeInfo }) 

  }



  ngOnDestroy(): void {
    this.detectChangeOfSelectedDates?.unsubscribe()
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete()
  }




}
