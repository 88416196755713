import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "src/app/app-routing.module";
import { MaterialDesignModule } from "src/app/material-design/material-design.module";
import { SharedModule } from "src/app/shared/shared.module";
import { CalculatorComponent } from "./calculator.component";
import { CalculatorMixTableListComponent } from "../calculator/calculator-mix-table-list/calculator-mix-table-list.component"
import { CalculatorMixTableItemComponent } from "./calculator-mix-table-list/calculator-mix-table-item/calculator-mix-table-item.component";
import { CalculatorMixTableEditComponent } from "./calculator-mix-table-list/calculator-mix-table-edit/calculator-mix-table-edit.component";
import { AuxiliaryComponentsModule } from "src/app/loading-spinner/auxiliary-components.module";

@NgModule({
    declarations:[
        CalculatorComponent ,
        CalculatorMixTableListComponent,
        CalculatorMixTableItemComponent,
        CalculatorMixTableEditComponent,

    ],

    exports:[
        CalculatorComponent ,
        CalculatorMixTableListComponent,
        CalculatorMixTableItemComponent,
        CalculatorMixTableEditComponent,

    ],

    imports:[
        AppRoutingModule,
        CommonModule,
        MatDatepickerModule,
        FormsModule,
        BrowserAnimationsModule,
        MaterialDesignModule, 
        ReactiveFormsModule,
        MatDialogModule,
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatExpansionModule,
        SharedModule,
        AuxiliaryComponentsModule

    ]
})

export class CalculatorModule {}