import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-toggle-word-button-edit',
  templateUrl: './toggle-word-button-edit.component.html',
  styleUrls: ['./toggle-word-button-edit.component.css']
})
export class ToggleWordButtonEditComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
