export class RecipientIndexListItem {

    constructor ( public _id : string,
                  public componentName: string ,
                  public UIID: string,
                  public owner: string,
                  public allUsersAllowed: boolean ,
                  public userListAllowed: string[], 
                  public dateAndTime: string ,
                  public recipientIndexListName: string,
                  public recipientIndexList_ids: string[],
                  public recipientIndexSelected_id: string 
                  ){}
}