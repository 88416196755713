import { Injectable } from "@angular/core";
import { InitialNullSetupService } from "src/app/services/starting-services/initial-null-setup.service";
import { FeatureContainerListItem } from "../../../feature-container-list-item.model";
import { Subject, Subscription } from "rxjs";
import { Feature } from "../../../../paragraph-item/features/feature.model";



@Injectable({
    providedIn: 'root'
})

export class FeatureContainerListItemService {

    constructor( private initialNullSetupService: InitialNullSetupService , 
                 ){}


    newFeatureContainerAndparentsHaveBeenStored = new Subject<any>()             
    featureContainerListItems: FeatureContainerListItem[] = this.initialNullSetupService.initialNullFeatureContainerListItems

    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.featureContainerListItemsChangeBackToNull
    .subscribe((res)=> {
           this.featureContainerListItems = res 
           console.log('updated featureContainerListItems back to null = ', res)
 
       })

    getFeatureContainerListItems(){
        return this.featureContainerListItems
    }

    getFeatureContainerListItem(i: number){
        return this.featureContainerListItems[i]
    }


    getFeatureContainerListItemBy_id(_id: string){
        const newFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItems.find(
            res=> {
                return res._id === _id
            }
        )
        return newFeatureContainerListItem
    }

    getPersonalFeatureContainersLisItemBy_Type(type: string){
        const newFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItems.find(x=>x.name === type )
        return newFeatureContainerListItem
    }

    updateFeatureContainerListItem(featureContainerListItem: FeatureContainerListItem){
        const index = this.featureContainerListItems.findIndex(x=> x._id === featureContainerListItem._id )
        this.featureContainerListItems[index] = featureContainerListItem 

        this.updateInitialNullFeatureContainerListItems(this.featureContainerListItems)
    }

    adviseComponentListTheContainersHaveBeenUpdated( feature: Feature ,  featureContainerName: string ){

        this.newFeatureContainerAndparentsHaveBeenStored.next({feature , featureContainerName} )

    }

    updateInitialNullFeatureContainerListItems(featureContainerListItems: FeatureContainerListItem[]){

        this.initialNullSetupService.initialNullFeatureContainerListItems = featureContainerListItems

    }

}