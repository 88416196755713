<!-- <p>fix-layout-item works!</p> -->

        <div class="row">
            <!-- <div class="row">
                <button (click)="onEditFixLayout()" type="button" class="btn btn-primary"> edit</button>
            </div> -->
            <div class="col-xs-12 " >

                <br>

            <form [formGroup]="fixLayoutEditForm" >
                <div *ngIf="isEditing"  class="row">
                    <div class="col-xs-12">
                        
                   <mat-form-field style="width: 250px" appearance="outline">
                    <mat-label>component name</mat-label>
                    <input 
                    matInput
                    type="text"
                    formControlName="name"
                    id="name"
                    value="{{fixLayout.name}}"
                    >
                </mat-form-field>
            
                        <mat-error *ngIf="!fixLayoutEditForm.get('name').valid" >Please enter a valid name, maximum 30 letters </mat-error>
                      </div>
                </div>
            </form>

                <br>

                <table >
                <div *ngFor="let tr of fixLayout.fixLayoutTableTRs; let i=index"  >
                       
                      <tr >

                           <td *ngFor="let td of tr.fixLayoutTableTDs; let j=index" style="padding-right: 1em;float:center"  >
                         
                            <div [ngSwitch]="td.tableTDType" >
   
                                  <div *ngSwitchCase="'string'" >
                                  <mat-form-field [ngStyle]="{width: getWidth(i, j)}" appearance="outline" >
                                    <mat-label>{{td.tableTDDetailStringLabel}}</mat-label>
                                    <input style="color:black" matInput disabled value="{{td.tableTDDetailString}}">
                                        <mat-icon *ngIf="isEditing" (click)="onEditStringForm( i,j )" class="pointer"  matSuffix>mode_edit</mat-icon>
                                  </mat-form-field>
                                  </div>

                                  <div *ngSwitchCase="'description'" >
                                    <mat-form-field  [ngStyle]="{width: getWidth(i, j)}"  appearance="outline" >
                                    <mat-label>{{td.tableTDDetailDescriptionLabel}}</mat-label>
                                    <input style="color:black" matInput disabled matInputAutosize value="{{td.tableTDDetailDescription}}">
                                    <mat-icon *ngIf="isEditing" (click)="onEditDescriptionForm(i,j)" class="pointer"  matSuffix>mode_edit</mat-icon>
                                    </mat-form-field>
                                    </div>

                                  <div *ngSwitchCase="'number'" >
                                  <mat-form-field [ngStyle]="{width: getWidth(i, j)}" appearance="outline">
                                    <mat-label>{{td.tableTDDetailNumberLabel}}</mat-label>
                                  <input matInput style="color:black" disabled
                                 value="{{td.tableTDDetailNumber}}">
                                  <mat-icon *ngIf="isEditing" (click)="onEditNumberForm( i,j )" class="pointer"  matSuffix>mode_edit</mat-icon>
                                  </mat-form-field>
                                  </div>

                                  <div *ngSwitchCase="'chart'" style="padding-right: 8em;float:center"  >
                                  <div>
                                    <button class="btn btn-danger" type="button">{{td.tableTDDetailChartShare}}</button>
                                    <button class="btn btn-danger" type="button">
                                       {{td.tableTDDetailChartTotal}}
                                       <mat-icon *ngIf="isEditing" (click)="onEditChartForm( i,j )" class="pointer"  matSuffix>mode_edit</mat-icon>
                                    </button>
                                  </div>
                                  </div>

                            </div>
                           
                           </td>

                           <td *ngIf="isEditing"  style="padding-right: 1em;float:center">
                            <button *ngIf="!isEditingDetails" (click)="onDeleteDetails(i)" type="button" mat-mini-fab color="warn" >delete</button>
                           </td>

                        <td *ngIf="isEditing" style="padding-right: 1em;float:center">
                            <button *ngIf="!isEditingDetails" (click)="onEditDetails(i)" type="button" mat-mini-fab color="primary"> +</button>
                        </td>
                        <td *ngIf="isEditing" style="padding-right: 1em;float:center">
                            <button *ngIf="isEditingDetails" (click)="onAddString(i)" type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
                               
                                  <svg style="color: white ;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-blockquote-right" viewBox="0 0 16 16">
                                    <path d="M2.5 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm0 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm0 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm0 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm10.113-5.373a6.59 6.59 0 0 0-.445-.275l.21-.352c.122.074.272.17.452.287.18.117.35.26.51.428.156.164.289.351.398.562.11.207.164.438.164.692 0 .36-.072.65-.216.873-.145.219-.385.328-.721.328-.215 0-.383-.07-.504-.211a.697.697 0 0 1-.188-.463c0-.23.07-.404.211-.521.137-.121.326-.182.569-.182h.281a1.686 1.686 0 0 0-.123-.498 1.379 1.379 0 0 0-.252-.37 1.94 1.94 0 0 0-.346-.298zm-2.168 0A6.59 6.59 0 0 0 10 6.352L10.21 6c.122.074.272.17.452.287.18.117.35.26.51.428.156.164.289.351.398.562.11.207.164.438.164.692 0 .36-.072.65-.216.873-.145.219-.385.328-.721.328-.215 0-.383-.07-.504-.211a.697.697 0 0 1-.188-.463c0-.23.07-.404.211-.521.137-.121.327-.182.569-.182h.281a1.749 1.749 0 0 0-.117-.492 1.402 1.402 0 0 0-.258-.375 1.94 1.94 0 0 0-.346-.3z"/>
                                  </svg>
                                
                              </button>
                        </td>

                        <td *ngIf="isEditing" style="padding-right: 1em;float:center">
                            <button *ngIf="isEditingDetails" (click)="onAddNumber(i)" type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
                               
                                <svg style="color: white;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-123" viewBox="0 0 16 16">
                                    <path d="M2.873 11.297V4.142H1.699L0 5.379v1.137l1.64-1.18h.06v5.961h1.174Zm3.213-5.09v-.063c0-.618.44-1.169 1.196-1.169.676 0 1.174.44 1.174 1.106 0 .624-.42 1.101-.807 1.526L4.99 10.553v.744h4.78v-.99H6.643v-.069L8.41 8.252c.65-.724 1.237-1.332 1.237-2.27C9.646 4.849 8.723 4 7.308 4c-1.573 0-2.36 1.064-2.36 2.15v.057h1.138Zm6.559 1.883h.786c.823 0 1.374.481 1.379 1.179.01.707-.55 1.216-1.421 1.21-.77-.005-1.326-.419-1.379-.953h-1.095c.042 1.053.938 1.918 2.464 1.918 1.478 0 2.642-.839 2.62-2.144-.02-1.143-.922-1.651-1.551-1.714v-.063c.535-.09 1.347-.66 1.326-1.678-.026-1.053-.933-1.855-2.359-1.845-1.5.005-2.317.88-2.348 1.898h1.116c.032-.498.498-.944 1.206-.944.703 0 1.206.435 1.206 1.07.005.64-.504 1.106-1.2 1.106h-.75v.96Z"/>
                                  </svg>
                                
                              </button>
                        </td>

                        <td *ngIf="isEditing" style="padding-right: 1em;float:center">
                            <button *ngIf="isEditingDetails" (click)="onAddDescription(i)" type="button" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
                                <svg style="color: white;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-body-text" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M0 .5A.5.5 0 0 1 .5 0h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 0 .5Zm0 2A.5.5 0 0 1 .5 2h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5Zm9 0a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5Zm-9 2A.5.5 0 0 1 .5 4h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5Zm5 0a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5Zm7 0a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5Zm-12 2A.5.5 0 0 1 .5 6h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5Zm8 0a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5Zm-8 2A.5.5 0 0 1 .5 8h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5Zm7 0a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5Zm-7 2a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5Zm0 2a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5Zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5Z"/>
                                  </svg>
                              </button>
                        </td>
                        <td *ngIf="isEditing" style="padding-right: 1em;float:center">
                            <button *ngIf="isEditingDetails" (click)="onAddChart(i)" type="button" mat-mini-fab color="primary" >
                                <svg style="color:white" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bar-chart-line-fill" viewBox="0 0 16 16">
                                    <path d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2z"/>
                                  </svg>
                              </button>
                        </td>
                       </tr>
        
                 </div> 

                 <br>

                 <tr *ngIf="isEditing">
                    <td >
                        <button (click)="onAddTRDetails()" type="button" class="btn btn-primary btn-sm "> add detail line</button>
                    </td>
                 </tr>

                 <br>

                 <tr *ngIf="isEditing">
                    <td >
                        <button (click)="onSubmitFixLayout()" type="button" class="btn btn-danger btn-sm "> confirm </button>
                    </td>
                 </tr>

                </table>

                <br>


            </div>
        </div>


