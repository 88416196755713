import { Component, Input, OnInit, Output } from '@angular/core';
import { Feature } from './feature.model'
import { DialogmanagerService} from '../../../../../../../services/other-components-services/dialogmanager.service'; 

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {

  features : Feature[] = [];
  featureid : number ;
  @Input() selectedIndex : number ;
  



  constructor(private dms: DialogmanagerService) { }

  ngOnInit(): void {
  }


 





}
