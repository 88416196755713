import { Feature } from './paragraph-list/paragraph-item/features/feature.model';

export class Paragraph {

constructor( 
    public _id: string,
    public componentName: string , 
    public UIID: string ,
    public owner: string, 
    public dateAndTime: string,
    public updateDateAndTime: string,
    public isRemoved: boolean ,  
    public allUsersAllowed: boolean,
    public userListAllowed: string[],
    public canBeResharedByOthers: boolean , 
    public name: string , 
    public desc: string , 
    public features: Feature[],
    public feature_id_list: string[] ){}

}

// have to add the property of User under name 
// to create differentiation in case the paragraph shared name is the same 

// need to add a service wherewe check if there is already an existing paragraph name 