export class Recipient {

    constructor (public _id: string, 
                 public componentName: string ,
                 public UIID: string , 
                 public owner: string,
                 public dateAndTime: string,
                 public updateDateAndTime: string ,
                 public chatRoomId: string,
                 public allUsersAllowed: boolean,
                 public userListAllowed: string[],
                 public blockedUserList: string[], 
                 public name: string , 
                 public recipientList: string[], 
                 public toMessage: string[] , 
                 public toMail: string[], 
                 public ccMail: string[] , 
                 public bccMail: string[], 
                 public greeting: string ) {}
  }

  // public chat_id: string,
  // public chatId: number, 

  /**
   * need to add recipientIndexId which is created on the fly 
   * needed torecognize which group it belong to , once we create the Receiver
   */