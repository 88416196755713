import { Component, Input, OnInit , Output, EventEmitter} from '@angular/core';
import { RangeInfo } from '../range-info.model';
import { FixTextDetail } from '../../../texts/fix-text-list/fix-text-detail.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { InnerLinkDetail } from '../inner-link-item/inner-link-detail.model';

interface IndexRangeInfo{
  index: number ,
  rangeInfo: RangeInfo
}

@Component({
  selector: 'app-inner-link-edit',
  templateUrl: './inner-link-edit.component.html',
  styleUrls: ['./inner-link-edit.component.css']
})
export class InnerLinkEditComponent implements OnInit {

  innerLinkEditFormName: FormGroup

  @Input() rangeInfo: RangeInfo
  @Input() isEditing: boolean 
  @Input() index: number

 
  innerLinkDetails: InnerLinkDetail[]

  typeUrl: string 
  typeTelNumber: string
  typeWAMessage: string 
  typeWACall: string
  typeWAVideo: string 

  @Output() updatedRangeInfo = new EventEmitter<IndexRangeInfo>()



  constructor() { }

  ngOnInit(): void {

    this.innerLinkEditFormName = new FormGroup({
      name : new FormControl (this.rangeInfo.name , [ Validators.required, Validators.maxLength(30) ] )
    });

    console.log(this.rangeInfo)

    // const newTypeUrlDetail = new InnerLinkDetail ('typeUrl', 'www.google.com' )
    // const newTypeTelNumber = new InnerLinkDetail ('typeTelNumber', "+39234567890" )
    // const newTypeWAMessage = new InnerLinkDetail ('typeWAMessage', "+1234567890")
    // const newTypeWACall = new InnerLinkDetail ('typeWACall', "+1234567890" )
    // const newTypeWAVideo = new InnerLinkDetail ( 'typeWAVideo' , "+1234567890" )


    // this.typeUrl = newTypeUrlDetail.content 
    // this.typeTelNumber = newTypeTelNumber.content
    // this.typeWAMessage = newTypeWAMessage.content  
    
    // this.innerLinkDetails = [ newTypeUrlDetail, newTypeTelNumber, newTypeWACall, newTypeWAVideo ] 

  }



  onSubmitName(){

  }

  onAddLink(){
    console.log('adding link')
  }

  onDeleteLink(i: number){
    console.log('deleting')
    this.innerLinkDetails.splice(i, 1)
  }

  onConfirmInnerComponent(){

    const newIndex = this.index 
    const newRangeInfo = this.rangeInfo

    this.updatedRangeInfo.emit({index: newIndex, rangeInfo: newRangeInfo})

  }

}
