import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Chat } from '../../chat.model';
import { Subscription, Observable, Subject } from 'rxjs';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';

@Component({
  selector: 'app-chat-item-content',
  templateUrl: './chat-item-content.component.html',
  styleUrls: ['./chat-item-content.component.css']
})
export class ChatItemContentComponent implements OnInit , OnDestroy{

  user: string 
  userExist: boolean

  @Input() chat: Chat ;
  chatIsSelected: boolean; 

  subscribeNewFormResults: Subject<void> = new Subject<void>();

  //@Output() scrollFormResultToBottom = new EventEmitter<number>()
  updateOnUserEmail: Subscription

  constructor(  private userObjectUpdateService: UserObjectUpdateService ) { }

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail 

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
        .subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail

      this.updateUserData(this.user)
      console.log('updated USER = ', this.user)
    })

    if( this.user === null){ this.userExist = false }
    if( this.user !== null){ this.userExist = true }

    this.chatIsSelected = this.chat.isSelected

  };

  updateUserData(userEmail: string){
    this.user = userEmail
    if( this.user === null){ this.userExist = false }
    if( this.user !== null){ this.userExist = true }

  }

  onSubscribeUpdatedFormResults(){
    console.log('the subscription has arrived 222')
    this.subscribeNewFormResults.next()
    
  };

  ngOnDestroy(): void {
      this.updateOnUserEmail.unsubscribe()
  }



} 
