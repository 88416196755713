import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from "@angular/core";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "src/app/app-routing.module";
import { MaterialDesignModule } from "src/app/material-design/material-design.module";
import { SharedModule } from "src/app/shared/shared.module";
import { CalendarComponent } from "./calendar.component";
import { CalendarExpireDateComponent } from './calendar-expire-date/calendar-expire-date.component';
import { CalendarListComponent } from './calendar-list/calendar-list.component';
import { CalendarItemComponent } from './calendar-list/calendar-item/calendar-item.component';
import { CalendarEditComponent } from './calendar-list/calendar-edit/calendar-edit.component';
import { CalendarRangeListComponent } from './calendar-range-list/calendar-range-list.component';
import { CalendarRangeItemComponent } from './calendar-range-list/calendar-range-item/calendar-range-item.component';
import { CalendarRangeEditComponent } from './calendar-range-list/calendar-range-edit/calendar-range-edit.component';
import { MultiEntryCalendarComponent } from './multi-entry-calendar/multi-entry-calendar.component';
import { MultiEntryCalendarListComponent } from './multi-entry-calendar/multi-entry-calendar-list/multi-entry-calendar-list.component';
import { MultiEntryCalendarItemComponent } from './multi-entry-calendar/multi-entry-calendar-list/multi-entry-calendar-item/multi-entry-calendar-item.component';
import { MultiEntryCalendarEditComponent } from './multi-entry-calendar/multi-entry-calendar-list/multi-entry-calendar-edit/multi-entry-calendar-edit.component';
import { AuxiliaryComponentsModule } from "src/app/loading-spinner/auxiliary-components.module";
import { SharedCalendarStatusListComponent } from './shared-calendar-status-list/shared-calendar-status-list.component';
import { SharedCalendarStatusEditComponent } from './shared-calendar-status-list/shared-calendar-status-edit/shared-calendar-status-edit.component';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule} from '@angular/material/input';
import {  MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedCalendarStatusItemComponent } from './shared-calendar-status-list/shared-calendar-status-item/shared-calendar-status-item.component';
import { AddSharedCalendarStatusItemRangeComponent } from './shared-calendar-status-list/add-shared-calendar-status-item-range/add-shared-calendar-status-item-range.component';
import { DeleteSharedCalendarStatusItemRangesComponent } from './shared-calendar-status-list/delete-shared-calendar-status-item-ranges/delete-shared-calendar-status-item-ranges.component';
import { SharedCalendarStatusItemViewComponent } from './shared-calendar-status-list/shared-calendar-status-item-view/shared-calendar-status-item-view.component';
import { InnerCheckboxItemComponent } from './shared-calendar-status-list/inner-checkbox-item/inner-checkbox-item.component';
import { InnerDropdownItemComponent } from './shared-calendar-status-list/inner-dropdown-item/inner-dropdown-item.component';
import { InnerTextItemComponent } from './shared-calendar-status-list/inner-text-item/inner-text-item.component';
import { InnerSlideToggleItemComponent } from "./shared-calendar-status-list/inner-slide-toggle-item/inner-slide-toggle-item.component";
import { InnerSliderItemComponent } from "./shared-calendar-status-list/inner-slider-item/inner-slider-item.component";
import { InnerButtonItemComponent } from "./shared-calendar-status-list/inner-button-item/inner-button-item.component";
import { InnerLinkItemComponent } from './shared-calendar-status-list/inner-link-item/inner-link-item.component';
import { MatSelectModule } from "@angular/material/select";
import {MatSlideToggleModule, _MatSlideToggleRequiredValidatorModule} from '@angular/material/slide-toggle';
import { InnerButtonEditComponent } from './shared-calendar-status-list/inner-button-edit/inner-button-edit.component';
import { InnerCheckboxEditComponent } from './shared-calendar-status-list/inner-checkbox-edit/inner-checkbox-edit.component';
import { InnerDropdownEditComponent } from './shared-calendar-status-list/inner-dropdown-edit/inner-dropdown-edit.component';
import { InnerLinkEditComponent } from './shared-calendar-status-list/inner-link-edit/inner-link-edit.component';
import { InnerSlideToggleEditComponent } from './shared-calendar-status-list/inner-slide-toggle-edit/inner-slide-toggle-edit.component';
import { InnerSliderEditComponent } from './shared-calendar-status-list/inner-slider-edit/inner-slider-edit.component';
import { InnerTextEditComponent } from './shared-calendar-status-list/inner-text-edit/inner-text-edit.component';
import { InnerInputItemComponent } from './shared-calendar-status-list/inner-input-item/inner-input-item.component';
import { InnerInputEditComponent } from './shared-calendar-status-list/inner-input-edit/inner-input-edit.component';
import { InnerChartItemComponent } from './shared-calendar-status-list/inner-chart-item/inner-chart-item.component';
import { InnerChartEditComponent } from './shared-calendar-status-list/inner-chart-edit/inner-chart-edit.component';
import { InnerTabEditComponent } from './shared-calendar-status-list/inner-tab-edit/inner-tab-edit.component';
import { InnerTabItemComponent } from './shared-calendar-status-list/inner-tab-item/inner-tab-item.component';
import { InnerRatingItemComponent } from './shared-calendar-status-list/inner-rating-item/inner-rating-item.component';
import { InnerRatingEditComponent } from './shared-calendar-status-list/inner-rating-edit/inner-rating-edit.component';
import { InnerDailyActivitiesItemComponent } from './shared-calendar-status-list/inner-daily-activities-item/inner-daily-activities-item.component';
import { InnerDailyActivitiesEditComponent } from './shared-calendar-status-list/inner-daily-activities-edit/inner-daily-activities-edit.component';
import { InnerDateRangeItemComponent } from './shared-calendar-status-list/inner-date-range-item/inner-date-range-item.component';
import { InnerComponentEditDialogComponent } from './shared-calendar-status-list/inner-component-edit-dialog/inner-component-edit-dialog.component';
import { InnerComponentEditBottomSheetComponent } from './shared-calendar-status-list/inner-component-edit-bottom-sheet/inner-component-edit-bottom-sheet.component';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import { InnerSumItemComponent } from './shared-calendar-status-list/inner-sum-item/inner-sum-item.component';
import { InnerSumEditComponent } from './shared-calendar-status-list/inner-sum-edit/inner-sum-edit.component';
import { SharedCalendarStatusEditBottomSheetComponent } from './shared-calendar-status-list/shared-calendar-status-edit-bottom-sheet/shared-calendar-status-edit-bottom-sheet.component';
import { UsersAccessEditComponent } from './shared-calendar-status-list/users-access-edit/users-access-edit.component';
import { ContactsModule } from "src/app/contacts/contacts.module";
import { InputModule } from "../input/input.module";
import { MatRadioModule } from "@angular/material/radio";
import { MatChipsModule} from '@angular/material/chips';



@NgModule({
    declarations:[
        CalendarComponent,
        CalendarExpireDateComponent,
        CalendarListComponent,
        CalendarItemComponent,
        CalendarEditComponent,
        CalendarRangeListComponent,
        CalendarRangeItemComponent,
        CalendarRangeEditComponent,
        MultiEntryCalendarComponent,
        MultiEntryCalendarListComponent,
        MultiEntryCalendarItemComponent,
        MultiEntryCalendarEditComponent,
        SharedCalendarStatusListComponent,
        SharedCalendarStatusEditComponent,
        SharedCalendarStatusItemComponent,
        AddSharedCalendarStatusItemRangeComponent,
        DeleteSharedCalendarStatusItemRangesComponent,
        SharedCalendarStatusItemViewComponent,
        InnerCheckboxItemComponent,
        InnerDropdownItemComponent,
        InnerTextItemComponent,
        InnerSlideToggleItemComponent,
        InnerSliderItemComponent, 
        InnerButtonItemComponent, 
        InnerLinkItemComponent, 
        InnerButtonEditComponent, 
        InnerCheckboxEditComponent, 
        InnerDropdownEditComponent, 
        InnerLinkEditComponent, 
        InnerSlideToggleEditComponent, 
        InnerSliderEditComponent, 
        InnerTextEditComponent, 
        InnerInputItemComponent, 
        InnerInputEditComponent, 
        InnerChartItemComponent, 
        InnerChartEditComponent, 
        InnerTabEditComponent, 
        InnerTabItemComponent, 
        InnerRatingItemComponent, 
        InnerRatingEditComponent, 
        InnerDailyActivitiesItemComponent, 
        InnerDailyActivitiesEditComponent, 
        InnerDateRangeItemComponent, 
        InnerComponentEditDialogComponent, 
        InnerComponentEditBottomSheetComponent, 
        InnerSumItemComponent, 
        InnerSumEditComponent, 
        SharedCalendarStatusEditBottomSheetComponent, 
        UsersAccessEditComponent


    ],
    exports:[
        CalendarComponent,
        CalendarExpireDateComponent,
        CalendarListComponent,
        CalendarItemComponent,
        CalendarEditComponent,
        CalendarRangeListComponent,
        CalendarRangeItemComponent,
        CalendarRangeEditComponent,
        MultiEntryCalendarComponent,
        MultiEntryCalendarListComponent,
        MultiEntryCalendarItemComponent,
        MultiEntryCalendarEditComponent,
        SharedCalendarStatusListComponent,
        SharedCalendarStatusEditComponent,
        SharedCalendarStatusItemComponent,
        AddSharedCalendarStatusItemRangeComponent,
        DeleteSharedCalendarStatusItemRangesComponent,
        SharedCalendarStatusItemViewComponent,
        InnerCheckboxItemComponent,
        InnerDropdownItemComponent,
        InnerTextItemComponent,
        InnerSlideToggleItemComponent,
        InnerSliderItemComponent , 
        InnerButtonItemComponent , 
        InnerLinkItemComponent,
        InnerButtonEditComponent, 
        InnerCheckboxEditComponent, 
        InnerDropdownEditComponent, 
        InnerLinkEditComponent, 
        InnerSlideToggleEditComponent, 
        InnerSliderEditComponent, 
        InnerTextEditComponent, 
        InnerInputItemComponent, 
        InnerInputEditComponent, 
        InnerChartItemComponent, 
        InnerChartEditComponent, 
        InnerTabEditComponent, 
        InnerTabItemComponent,
        InnerRatingItemComponent, 
        InnerRatingEditComponent,
        InnerDailyActivitiesItemComponent, 
        InnerDailyActivitiesEditComponent,
        InnerDateRangeItemComponent, 
        InnerComponentEditDialogComponent, 
        InnerComponentEditBottomSheetComponent,
         InnerSumItemComponent, 
         InnerSumEditComponent,
         SharedCalendarStatusEditBottomSheetComponent,
         UsersAccessEditComponent
    ],


    imports:[
        AppRoutingModule,
        CommonModule,
        MatDatepickerModule,
        FormsModule,
        BrowserAnimationsModule,
        MaterialDesignModule, 
        ReactiveFormsModule,
        MatDialogModule,
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatExpansionModule,
        SharedModule,
        AuxiliaryComponentsModule , 
        MatNativeDateModule,
        MatCardModule , 
        MatFormFieldModule , 
        BrowserModule,
        MatInputModule,
        MatRippleModule,
        MatTooltipModule,
        MatSelectModule,
        MatSlideToggleModule,
        _MatSlideToggleRequiredValidatorModule,
        MatBottomSheetModule,
        ContactsModule,
        InputModule,
        MatRadioModule,
        MatChipsModule 
       

    ]
})
export class CalendarModule{}