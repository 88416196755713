

export class FormFeatureContainer {

    constructor( public _id: string , 
                 public componentName: string , 
                 public UIID: string , 
                 public owner: string , 
                 public allUsersAllowed: boolean ,
                 public userListAllowed: string[] , 
                 public dateAndTime: string , 
                 public isActive: boolean, 
                 public isAccepted: boolean, 
                 public name: string, 
                 public formFeatureState: boolean , // visible to check at arrival : this property change all the times according if the formFeature is present or not 
                 public recipientIndex_id: string , // store shared forms RECIPIENT INDEX 
                 public form_id: string
                  ){}
}