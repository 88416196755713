import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup , FormBuilder } from '@angular/forms';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { ParagraphTopicIndexService } from '../../forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-topic-index.service';
import { ParagraphTopicService } from '../../forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.service';
import { ParagraphTopic } from '../../forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.model';
import { ParagraphTopicIndex } from '../../forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-topic-index.model';
import { FormIndexTopicService } from '../../forms/form-list/form-index-topic-list/form-index-topic.service';
import { FormIndexTopic } from '../../forms/form-list/form-index-topic-list/form-index-topic.model';
import { FormIndexTopicListItem } from '../../forms/form-list/form-index-topic-list/form-index-topic-list-item.model';
import { FormIndexTopicListItemService } from '../../forms/form-list/form-index-topic-list/form-index-topic-list-item.service';


@Component({
  selector: 'app-find-form-or-paragraph',
  templateUrl: './find-form-or-paragraph.component.html',
  styleUrls: ['./find-form-or-paragraph.component.css']
})
export class FindFormOrParagraphComponent implements OnInit {

  public separatorKeysCodes = [ENTER, COMMA, SPACE];
  emailList = []; // this is for paragraphs
  formEmailList = []; // this is for forms 
  removable = true;
  findUserEmailParagraphFormFeature: FormGroup


  fb: FormBuilder = new FormBuilder();

  @Input() weAreInFormIndexTopicList: boolean 

  @Output() emitUserEmailFound = new EventEmitter<string>()
  @Output() emitUserEmailIndexToCancel = new EventEmitter<string>()


  constructor( private paragraphTopicIndexService: ParagraphTopicIndexService , 
               private formIndexTopicService: FormIndexTopicService ,
               private formIndexTopicListItemService: FormIndexTopicListItemService ,
               private paragraphTopicService: ParagraphTopicService 
               ) { }

  ngOnInit() {

    

    /**
     * divide if we are in paragraph or form 
     */
    if (this.weAreInFormIndexTopicList === false ){
      for ( let i=0 ; i<this.paragraphTopicIndexService.foundEmailList.length ; i++ ){
        if ( this.emailList.findIndex(x=> x.value === this.paragraphTopicIndexService.foundEmailList[i] ) === -1  ){
          this.emailList.push({ value: this.paragraphTopicIndexService.foundEmailList[i], invalid: false });
        }
       
      }
      console.log(this.emailList)
    }

    if (this.weAreInFormIndexTopicList === true ){
      for ( let i=0 ; i<this.formIndexTopicService.foundEmailList.length ; i++ ){
        if ( this.formEmailList.findIndex(x=> x.value === this.formIndexTopicService.foundEmailList[i] ) === -1  ){
        this.formEmailList.push({ value: this.formIndexTopicService.foundEmailList[i], invalid: false });
        }
      }
      console.log(this.formEmailList)
    }
    

    this.findUserEmailParagraphFormFeature = this.fb.group({
      emails : this.fb.array( [] , [this.validateArrayNotEmpty]  )
    })

   

  }

 /**
 * 
 * @param event add email to recipient
 */
   add(event){
    console.log('email = ',event.value.toLowerCase())
    if (event.value.toLowerCase()) {
      if (this.validateEmail(event.value)) {
        /**
         * divide if we are in paragraph or form 
         */
          if (this.weAreInFormIndexTopicList === false ){
            if ( this.emailList.findIndex(x=> x.value === event.value.toLowerCase()) === -1  ){
              this.emailList.push({ value: event.value.toLowerCase(), invalid: false });
              this.emitUserEmailFound.emit(event.value.toLowerCase())
            }
          }

          if (this.weAreInFormIndexTopicList === true ){
           if ( this.formEmailList.findIndex(x=> x.value === event.value.toLowerCase()) === -1  ){
            this.formEmailList.push({ value: event.value.toLowerCase(), invalid: false })
            this.emitUserEmailFound.emit(event.value.toLowerCase())
           }
          }
          
      } else {
        if (this.weAreInFormIndexTopicList === false ){
          this.emailList.push({ value: event.value.toLowerCase(), invalid: true });
          this.findUserEmailParagraphFormFeature.controls['emails'].setErrors({'incorrectEmail': true});
        }
        if (this.weAreInFormIndexTopicList === true ){
          this.formEmailList.push({ value: event.value.toLowerCase(), invalid: true });
          this.findUserEmailParagraphFormFeature.controls['emails'].setErrors({'incorrectEmail': true});
        }
       
      }
    }
    if (event.input) {
      event.input.value = '';
    }
    console.log(this.emailList , this.formEmailList)


  }


  /**
   * 
   * @param data remove email from recipient
   */
  removeEmail(data: any){
    console.log(data , "data value = ",  data.value)
    if ( this.weAreInFormIndexTopicList === false ){
    if (this.emailList.indexOf(data) >= 0) {
      this.emailList.splice(this.emailList.indexOf(data), 1);
      console.log('Removing ' + data.value)
      this.emitUserEmailIndexToCancel.emit(data.value )

      // now we need to uodate the paragraphTopicIndex list and cancel the paragraphTopic removed 
       this.updatePublicParagraphTopicsAfterRemoval(data)
    }

    }
    if ( this.weAreInFormIndexTopicList === true ){
      if (this.formEmailList.indexOf(data) >=0){
          this.formEmailList.splice(this.formEmailList.indexOf(data),1);
          console.log('Removing ' + data.value)
          this.emitUserEmailIndexToCancel.emit(data.value)
          this.updatePublicFormTopicsAfterRemoval(data)
      }
    }
    
};

updatePublicParagraphTopicsAfterRemoval(data: any){
     /**
       * we need to find the ones belonging to PUBLIC only and in case there is a ParagraphTopic 
       * with public paragrFeatures then we cancel it 
       */
  const availableParagraphTopics: ParagraphTopic[] = []
  const publicParagraphTopicIndex:ParagraphTopicIndex  = this.paragraphTopicIndexService.getParagraphTopicIndex_by_type('public-paragraphs')
  for ( let i=0 ; i<this.paragraphTopicIndexService.getParagraphTopicIndex_by_id(publicParagraphTopicIndex._id).paragraphTopic_ids.length ; i++ ){
    availableParagraphTopics.push(this.paragraphTopicService.getParagraphTopicBy_id(this.paragraphTopicIndexService.getParagraphTopicIndex_by_id(publicParagraphTopicIndex._id).paragraphTopic_ids[i]))
  }

  if ( availableParagraphTopics.findIndex( x=> x._id === data.value ) === -1 ) { return }
  if ( availableParagraphTopics.findIndex( x=> x._id === data.value ) !== -1 ) { 
       this.paragraphTopicIndexService.foundEmailList.splice(this.paragraphTopicIndexService.foundEmailList.findIndex(x=> x === data.value) , 1 )
       this.paragraphTopicService.deletePublicParagraphTopicItem(data.value)
       this.paragraphTopicIndexService.deletePublicParagraphTopicItem(data.value)
   }
}

updatePublicFormTopicsAfterRemoval(data: any){
  console.log("resetting forms topics")

  const availableFormIndexTopics: FormIndexTopic[] = []
  const publicFormIndexTopicListItem: FormIndexTopicListItem = this.formIndexTopicListItemService.getFormIndexTopicListItem_by_type('public')
  for ( let i=0; i<publicFormIndexTopicListItem.formIndexTopic_ids.length ; i++ ){
         availableFormIndexTopics.push(this.formIndexTopicService.getFormIndexTopicBy_id(publicFormIndexTopicListItem.formIndexTopic_ids[i] ))
  }

  if (availableFormIndexTopics.findIndex( x=> x._id === data.value ) === -1 ){ return }
  if (availableFormIndexTopics.findIndex( x=> x._id === data.value ) !== -1 ){
      this.formIndexTopicService.foundEmailList.splice(this.formIndexTopicService.foundEmailList.findIndex(x=> x === data.value ), 1 )
      this.formIndexTopicService.deletePublicFormIndexTopic(data.value)
      this.formIndexTopicListItemService.deleteFormIndexTopic_idOnFormIndexTopicListItem(data.value)

  }

}



  private validateArrayNotEmpty(c: FormControl) {
    if (c.value && c.value.length === 0) {
      return {
        validateArrayNotEmpty: { valid: false }
      };
    }
    return null;
  }

  private validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


  onSubmit(){


  }

}
