import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { FeatureContainer } from '../../../feature-container.model';
import { FeatureContainerService } from './feature-container.service';
import { FeatureIndexSelection } from '../../feature-index-selection.model';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { Subscription } from 'rxjs';
import { ParagraphService } from '../../../../../paragraph.service';
import { MultiFeaturesService } from '../../../../paragraph-item/features/multi-features-list/multi-features.service';
import { TabService } from '../../../../paragraph-item/features/tabs/tab-list/tab.service';


@Component({
  selector: 'app-feature-container',
  templateUrl: './feature-container.component.html',
  styleUrls: ['./feature-container.component.css']
})
export class FeatureContainerComponent implements OnInit {

user: string 
@Input() featureContainer_id: string

indexSelection: FeatureIndexSelection
poster = 'https://upload.wikimedia.org/wikipedia/en/4/40/Star_Wars_Phantom_Menace_poster.jpg' 


featureContainer: FeatureContainer

featureContainerIsLoad = new EventEmitter<void>()

featureContainerIsLoaded: boolean = false

updateOnUserEmail: Subscription

  constructor( private featureContainerService: FeatureContainerService , 
               private userObjectUpdateService: UserObjectUpdateService , 
               private paragraphService: ParagraphService , 
               private multiFeatureService: MultiFeaturesService,
               private tabService: TabService
               ) { }

  /**
   * export class FeatureContainer {

    constructor ( public _id: string, 
                  public owner: string , 
                  public allUsersAllowed: boolean , 
                  public userListAllowed: [],
                  public dateAndTime: string , 
                  public isActive: boolean , 
                  public isAccepted: boolean , 
                  public name: string ,
                  public componentReference_id: string, 
                  public feature: Feature // maybe need to use array just to justify drag and drop
                  // but eventually always put just 1 feature and point to the 0 index 
                  ){
   */

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail
    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
    });

    this.featureContainerIsLoaded = true 

    this.featureContainer = this.featureContainerService.getFeatureContainerBy_id(this.featureContainer_id)
    console.log(this.featureContainer)


    console.log( this.featureContainer_id)

    this.featureContainerIsLoad.emit()

    setTimeout(()=>{
      this.featureContainerIsLoaded = false 
    },100)
    

  
  }

  noReturnPredicate(){
    return false ;
  }

  onAddFeatureToParagraph(feature){
    console.log('adding this feature to paragraph', feature )

    if ( this.paragraphService.isEditing.isEditingStandard){
         console.log('is standard feature , use paragraphService')
         this.paragraphService.onAddingFeatureToParagraphCreateEdit(feature)
    }

    if ( this.paragraphService.isEditing.isEditingMultiFeature){
         console.log('is editing multiFeature')
         this.multiFeatureService.onAddSingleFeatureToMultiFeatureEdit(feature)

    }

    if ( this.paragraphService.isEditing.isEditingTab){
         console.log('is editing tab')
         this.tabService.onAddingFeatureToTabEdit(feature)
    }
  }

  ngOnDestroy(){
    this.updateOnUserEmail.unsubscribe()
  }


}
