
export class SingleSliderDetail {

    constructor(  public detailName: string, 
                  public unit: string, 
                  public autoTicks : boolean,
                  public disabled : boolean,
                  public invert : boolean,
                  public max : number ,
                  public min : number,
                  public showTicks : boolean ,
                  public step : number,
                  public thumbLabel : boolean, 
                  public value : number,
                  public vertical : boolean,
                  public tickInterval : number 
                  ){}
}