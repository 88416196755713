import { Injectable } from "@angular/core";
import { Tab } from "./tab.model";
import { InitialNullSetupService } from "src/app/services/starting-services/initial-null-setup.service";
import { Subject, Subscription } from "rxjs";
import { StoreFormItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-form-item-plus-childs-confirmation.service";
import { StoreParagraphItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-paragraph-item-plus-childs-confirmation.service";
import { StoreTabItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-tab-item-plus-childs-confirmation.service";
import { Feature } from "../../feature.model";

@Injectable ({
    providedIn: 'root' 
})

export class TabService {

    constructor( 
        private initialNullSetupService: InitialNullSetupService, 
        private storeFormItemPlusChildsConfirmationService: StoreFormItemPlusChildsConfirmationService ,
        private storeTabItemPlusChildsConfirmationService: StoreTabItemPlusChildsConfirmationService , 
        private storeParagraphItemPlusChildsConfirmationService: StoreParagraphItemPlusChildsConfirmationService 
        ){}


    tabList: Tab[] = this.initialNullSetupService.initialNullTabs
    inilitalPersonalTabListHaveBeenStored = new Subject<void>()

    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.tabsChangedBackToNull
    .subscribe((res)=> {
          this.tabList = res 
          console.log('updated fixText back to null = ', res)

    });

    getTabList(){
        return this.tabList
    }

    getTab(i: number){
        return this.tabList[i]
    }

    getTabBy_id(_id: string){
         const tab = this.tabList.find(
            res => {
                 return res._id === _id}
         )
         return tab
    };


    addTab(tab: Tab){
        this.tabList.push(tab )
        this.updateInitialComponents(this.tabList)
    };

    updateTab(updatedTab: Tab){
        const index = this.tabList.findIndex(x=> x._id === updatedTab._id)
        this.tabList[index]= updatedTab 
        console.log('TAB UPDATED')
        this.updateInitialComponents(this.tabList)
    }

    /**
   * 
   * @param newFeature  from featureContainer add feature to paragraphEdit
   */
    additionalFeature = new Subject<Feature>()
    onAddingFeatureToTabEdit(newFeature: Feature ){
          this.additionalFeature.next(newFeature)
    }

    addNewTabs(tabs: Tab[]){

        for ( let i=0; i<tabs.length ; i++ ){
            if ( this.tabList.findIndex(x=> x._id === tabs[i]._id ) === -1){
                this.tabList.push(tabs[i])
            }
            if ( i=== tabs.length-1){
                this.updateInitialComponents(this.tabList)
                console.log('tabs have been stored ', this.tabList)
                this.inilitalPersonalTabListHaveBeenStored.next()
            }
        }
        // console.log('tabs have been stored ', this.tabList)
        // this.inilitalPersonalTabListHaveBeenStored.next()
    }


    addNewTab(tabToAdd: Tab , isComingFromFormItemCall : boolean , isComingFromMultiFeatures: boolean ){

        if ( this.tabList.findIndex(x=> x._id === tabToAdd._id ) !== -1 ) {
             const index =  this.tabList.findIndex(x=> x._id === tabToAdd._id )
             this.tabList[index] = tabToAdd
            this.updateInitialComponents(this.tabList)

       }

        if ( this.tabList.findIndex(x=> x._id === tabToAdd._id ) === -1 ) {
             this.tabList.push(tabToAdd) 
             this.updateInitialComponents(this.tabList)

        }

        if ( isComingFromFormItemCall === true  ){ this.storeFormItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenFormItemPlusChildsAreStored( tabToAdd._id) }
        if ( isComingFromFormItemCall === false ){ this.storeParagraphItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenParagraphItemPlusChildsAreStored( tabToAdd._id) }
        if ( isComingFromMultiFeatures=== false ){ this.storeTabItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenTabItemPlusChildsAreStored( tabToAdd._id) }
  

        console.log('TABS updated = ', this.tabList )
    };

    updateInitialComponents(updatedTabs: Tab[]){
        this.initialNullSetupService.initialNullTabs = updatedTabs
    }

    ngOnDestroy(){
        this. updateInitialNullDatafromInitialNullSetupService.unsubscribe()
    }


};