import { Component, OnInit , ViewEncapsulation, Input, ViewChild, AfterViewInit, OnDestroy, } from '@angular/core';
import { SharedCalendarStatusResult } from './shared-calendar-status-result.model';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MatCalendar } from '@angular/material/datepicker';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';


@Component({
  selector: 'app-shared-calendar-status-result',
  templateUrl: './shared-calendar-status-result.component.html',
  styleUrls: ['./shared-calendar-status-result.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class SharedCalendarStatusResultComponent implements OnInit , AfterViewInit, OnDestroy {

  @Input() sharedCalendarStatusResult: SharedCalendarStatusResult
  @Input() featureResultName: string

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  user: string 

  @ViewChild('calendar') calendar: MatCalendar<any>;

  selectedDates: Date[] = [];

  calendarMonthSubscription: Subscription 

  isUpdatingCalendar: boolean = false 

  canViewIt: boolean = false 

  updateOnUserEmail: Subscription

  rangeInfoResultsCanViewIt: boolean[] = []


  constructor( private breakpointObserver: BreakpointObserver,
               private userObjectUpdateService: UserObjectUpdateService, 
               
               ) { }

  ngOnInit(): void {

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
    .subscribe((updatedUserEmail)=>{
     this.user = updatedUserEmail
     console.log('updated USER = ', this.user)
    })

    if ( this.sharedCalendarStatusResult.usersAccessResult.includes(this.user)){ this.canViewIt = true }

    for (let i=0; i<this.sharedCalendarStatusResult.rangeInfoResults.length ; i++ ){
         let newCanViewIt: boolean 
         const newUsersAccess = this.sharedCalendarStatusResult.rangeInfoResults[i].usersAccessResult
         if ( newUsersAccess.includes(this.user) ){ newCanViewIt = true }
         if ( !newUsersAccess.includes(this.user) ){ newCanViewIt = false }

         this.rangeInfoResultsCanViewIt.push(newCanViewIt)

    }

    this.selectedDates = this.updateSelectedDates()

    this.isUpdatingCalendar = true
    this.onUpdatingCalendar()


  }

  onUpdatingCalendar(){

    setTimeout(() => {
      this.isUpdatingCalendar = false
      this.sethRightMonth()
    }, 200);
    
  }

  sethRightMonth(){
    setTimeout(() => {
      this.calendar.activeDate = new Date( this.sharedCalendarStatusResult.start);
    }, 200);
    
  }

  updateSelectedDates(): Date[] {

    const allDates: Date[] = [];

    const currentDate = new Date(this.sharedCalendarStatusResult.start)
          currentDate.setHours(0, 0, 0, 0);
    const endDate = new Date(this.sharedCalendarStatusResult.end )
          endDate.setHours(23, 59, 59, 999);
          while  (currentDate <= endDate ){
            allDates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() +1 )
            currentDate.setHours(0, 0, 0, 0);
          }
    return allDates
  }

  ngAfterViewInit(): void {

    this.calendarMonthSubscription = this.calendar.monthSelected.subscribe((date: Date) => {
      const month = date.getMonth();
      const year = date.getFullYear();
      console.log(`Currently displayed month: ${month}, Year: ${year}`);
    });


  }


  dateClassCallback = (date: Date): string => {
 
    this.selectedDates = this.updateSelectedDates() // update selected  

    // console.log("selected dates => ",this.selectedDates)
    // for each selected allocate a color depending on which daterange they belong to 
    // if they belong to more than 1 dateRange give them bicolor 

    const selectedDate = this.selectedDates.find(item => this.isSameDay(item, date));
    if (selectedDate) {
      return 'azure'
    }
    return '';
  };

  isSameDay(date1: Date, date2: Date): boolean { 
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  }


ngOnDestroy(): void {
    this.calendarMonthSubscription?.unsubscribe()
    this.updateOnUserEmail?.unsubscribe()
}


}
