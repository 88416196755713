import { Component, Input , OnInit } from '@angular/core';
import { SingleWordInputDetailResult } from './single-word-input-detail-result.model';

@Component({
  selector: 'app-single-word-input-result',
  templateUrl: './single-word-input-result.component.html',
  styleUrls: ['./single-word-input-result.component.css']
})
export class SingleWordInputResultComponent implements OnInit {

  @Input() singleWordInputDetailResult : SingleWordInputDetailResult

  constructor() { }

  ngOnInit(){
    this.singleWordInputDetailResult
  }

}
