import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { RecipientIndexListItem } from '../../../recipient-index-list/recipient-index-list-item.model';
import { RecipientIndexListItemService } from '../../../recipient-index-list/recipient-index-list-item.service';
import { RecipientIndex } from '../../../recipient-index-list/recipient-index.model';
import { RecipientIndexService } from '../../../recipient-index-list/recipient-index.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { Observable, Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { RecipientIndexColumn } from '../../../recipient-index-list/recipient-index-column.model';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { ErrorsDialogManagerService } from 'src/app/services/other-components-services/errors-dialog-manager.service';


@Component({
  selector: 'app-recipient-index-list-item',
  templateUrl: './recipient-index-list-item.component.html',
  styleUrls: ['./recipient-index-list-item.component.css']
})
export class RecipientIndexListItemComponent implements OnInit , OnDestroy {

  user: string 

  @Input() recipientIndexListItem_id: string 
  @Input() youAreInFormEdit: boolean
  @Input() recipientIndexColumn: RecipientIndexColumn

  recipientIndexListItem: RecipientIndexListItem

  recipientIndex: RecipientIndex

  @Output() updateRecipientIndex = new EventEmitter<any>()
  @Output() updateRecipientIndexId = new EventEmitter<number>()

  updateOnUserEmail: Subscription

  updateOnLatestItems: Subscription 

  isRenamingGroup: boolean = false 

  renameRecipientForm: FormGroup 

  constructor( private recipientIndexListItemService: RecipientIndexListItemService , 
               private userObjectUpdateService: UserObjectUpdateService , 
               private recipientIndexService: RecipientIndexService ,
               private breakpointObserver: BreakpointObserver,
               private location: Location , 
               private router: Router , 
               private postComponentsHttpRequestService: PostComponentsHttpRequestService , 
               private initialNullSetupService: InitialNullSetupService , 
               private errorsDialogManagerService: ErrorsDialogManagerService
               ) { }

  
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
     .subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
     })

    this.recipientIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItemBy_id(this.recipientIndexListItem_id)
    this.recipientIndex = this.recipientIndexService.getRecipientIndexBy_id(this.recipientIndexListItem.recipientIndexSelected_id)
    
    this.renameRecipientForm = new FormGroup({
      name: new FormControl ('') ,   
     });

    this.updateOnLatestItems = this.recipientIndexListItemService.updateWithLatestRecipientIndexListItems 
        .subscribe(()=>{
          /**
           * updating recipientIndexListItem after update has happened 
           */
          this.recipientIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItemBy_id(this.recipientIndexListItem_id)
         // this.recipientIndex = this.recipientIndexService.getRecipientIndexBy_id(this.recipientIndexListItem.recipientIndexSelected_id)
        })
  }


   /**
   * 
   * @param i send recipientIndex whether to change formEdit recipient or formItem recipient list
   */
    onSelectRecipientIndex(){

      const newRecipientIndex: RecipientIndex = this.recipientIndexService.getRecipientIndexBy_id(this.recipientIndexListItem.recipientIndexSelected_id)
      const newRecipientIndexListItem: RecipientIndexListItem = this.recipientIndexListItem 

      console.log({ newRecipientIndex, newRecipientIndexListItem})

      if ( newRecipientIndex === undefined ){ 
        this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: "NO ACTIVE SHARED GROUP AT THE MOMENT"})
      }
      if ( newRecipientIndex !== undefined ){
       let isEditMode: boolean 
  
      var url = window.location.href;
      // Define a regular expression pattern to match "create-form" or "edit-form"
      var pattern = /(create-form|edit-form)/;
     // Extract the path from the URL
      var match = url.match(pattern);

    if (match) {
        console.log("Found:", match[0]);
        if (match[0] === 'create-form'){ isEditMode = false }
        if (match[0] === 'edit-form'){ isEditMode = true }

      } else {
         console.log("Not found");
     }

      if ( this.youAreInFormEdit ){
          

           console.log('THIS IS EDIT MODE => ', isEditMode )
           if (this.user !== null ){ 
                if ( !isEditMode ){ this.changeUserUrlParams_onCreateForm_Recipient(newRecipientIndex)}
             
           }
 
          if (this.user === null) { 
               if ( !isEditMode ){ this.changeNullUserUrlParams_onCreateForm_Recipient(newRecipientIndex )}
           
          }
      
      }


      this.updateRecipientIndex.emit({ newRecipientIndex, newRecipientIndexListItem})

    }
                                      
  
    }

    changeUserUrlParams_onCreateForm_Recipient(recipientIndex: RecipientIndex){

      this.recipientIndex = recipientIndex
      const newRecipientIndex_id: string = this.recipientIndex._id ;
   
      const base = 'recipientIndex-';
      const extractedString = newRecipientIndex_id.split(base)[1];
      console.log(extractedString); 
   
      const currentUrl = this.location.path();
      console.log(currentUrl)
      const lastIndex = currentUrl.lastIndexOf('/');
      const extractedString2 = currentUrl.substring(lastIndex + 1);
   
      console.log(extractedString2);
   
      this.router.navigate([ 'create-form', extractedString, extractedString2 ] ) 
   
   
   }
   
   
   changeNullUserUrlParams_onCreateForm_Recipient(recipientIndex: RecipientIndex ){
   
   
      this.recipientIndex = recipientIndex
      const newRecipientIndex_id: string = this.recipientIndex._id ;
   
      const base = 'recipientIn-';
      const extractedString = newRecipientIndex_id.split(base)[1];
      console.log(extractedString);
   
      const currentUrl = this.location.path();
      console.log(currentUrl)
      const lastIndex = currentUrl.lastIndexOf('/');
      const extractedString2 = currentUrl.substring(lastIndex + 1);
   
     this.router.navigate([ 'create-form', extractedString, extractedString2 ] ) 
      
   }
  



   onRenameRecipientGroup(){
    console.log('rename recipient group')
    this.isRenamingGroup = true 

    this.renameRecipientForm = new FormGroup({
      name: new FormControl (this.recipientIndexListItem.recipientIndexListName ) ,   
     });


    setTimeout(() => {
      this.isRenamingGroup = false
    }, 45000);
   }

   onSubmit(){
    console.log(this.renameRecipientForm.value.name)

    if ( this.user !== null ){ this.httpUpdaterecipientIndexListItemname() }
    if ( this.user === null ){ this.eventuallyUpdateRecipientIndexListItemName() }
   }

   httpUpdaterecipientIndexListItemname(){

    const updatingThisRecipientIndexListItem: RecipientIndexListItem = this.recipientIndexListItem
    updatingThisRecipientIndexListItem.recipientIndexListName = this.renameRecipientForm.value.name 

      console.log(updatingThisRecipientIndexListItem)

      this.postComponentsHttpRequestService.httpUpdateComponent(this.user, 'recipientIndexListItem' , updatingThisRecipientIndexListItem )
          .subscribe((res)=>{
            console.log(res)

            this.eventuallyUpdateRecipientIndexListItemName()

          }, error => {
            console.log(error, 'throw an error message' )

            /**
             * temporarely change the name anyway but tell userit's just temporary
             */
            this.eventuallyUpdateRecipientIndexListItemName()
          })
   }

   eventuallyUpdateRecipientIndexListItemName(){

    this.recipientIndexListItem.recipientIndexListName = this.renameRecipientForm.value.name 

    const index = this.recipientIndexListItemService.getRecipientIndexListItems().findIndex(x=> x._id === this.recipientIndexListItem._id )
    this.recipientIndexListItemService.recipientIndexListItems[index] = this.recipientIndexListItem 

    const nullIndex = this.initialNullSetupService.initialNullRecipientIndexListItems.findIndex(x=> x._id === this.recipientIndexListItem._id)
    this.initialNullSetupService.initialNullRecipientIndexListItems[nullIndex] = this.recipientIndexListItem 

    this.renameRecipientForm = new FormGroup({
      name: new FormControl ('') ,   
     });

    this.isRenamingGroup = false

   }

    ngOnDestroy(): void {
      this.updateOnUserEmail.unsubscribe()
      this.updateOnLatestItems.unsubscribe()
  }

}
