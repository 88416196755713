<div class="row" >
    <div class="col-xs-12">
      <div class="row">
        <div class="col-2">
       
          <svg style="color: #3f51b5" xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
            <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
            <path fill-rule="evenodd" d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/>
            <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
          </svg>
       
        </div>
        <div class="col-10">
  
              <mat-chip-list #chipList>
                <div *ngFor="let chip of groupOptions; let i=index" >
                  <div *ngIf="groupIsDeselected">
                    <mat-chip *ngIf="user"
                              style="background-color: whitesmoke ; color:  grey ; width:150px "
                              type="button"
                              (click)="onSelectGroup()"> 
                              {{chip}} 
                    </mat-chip>
                    <!-- matTooltip="LOGIN TO USE THIS "
                              matTooltipPosition="right" -->
                    <mat-chip *ngIf="!user"
                              style="background-color: whitesmoke ; color:  grey ; width:150px "
                              type="button">
                              {{chip}} 
                    </mat-chip>
                  </div>
                  <div *ngIf="!groupIsDeselected">
                    <mat-chip style="background-color: #3f51b5 ; color: white ; width:150px "
                              type="button"
                              (click)="onDeselectGroup()"> 
                              {{chip}} 
                    </mat-chip>
                 
                  </div>
    
                </div>
              </mat-chip-list>
           
        </div>
      </div>
    </div>
</div>

<br>

<div class="row" *ngIf="!groupIsDeselected">
    <div class="col-xs-12" *ngIf="!isLoadingForm">

      <mat-form-field>
        <mat-label>select group</mat-label>
        <mat-select [(ngModel)]="selectedValue" 
                    name="availableChoice"
                    (selectionChange)="onSelectionChange($event)">
          <mat-option *ngFor="let availableChoice of availableChoices" [value]="availableChoice.value">
            {{availableChoice.name}}
          </mat-option>
        </mat-select>
     
      </mat-form-field>

    </div>
</div>
