import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormResultsService } from '../../form-results.service';

interface TypeMessage_Mail_FromTo {
  type: string ,
  fromTo: string[]
}

@Component({
  selector: 'app-filter-mail-sent-to',
  templateUrl: './filter-mail-sent-to.component.html',
  styleUrls: ['./filter-mail-sent-to.component.css']
})
export class FilterMailSentToComponent implements OnInit , OnDestroy {

  mailOptions: string[] = ['sent'  ]

 @Input() sentMailIsDeselected: boolean
 @Input() isSubmitted: boolean
 @Output() onSentMailIsDeselected = new EventEmitter<boolean>()

 subscriptionToFormResultList_onCheckUpdateFilterStatus: Subscription

  constructor( private formResultsService: FormResultsService ,
               private cdr: ChangeDetectorRef ,
               private ngZone: NgZone
    ) { }

  ngOnInit(): void { 

    

    this.onSentMailIsDeselected.emit(this.sentMailIsDeselected)

    this.subscriptionToFormResultList_onCheckUpdateFilterStatus = this.formResultsService.checkUpdateFilterResult_fromResultList
      .subscribe((res)=>{
        console.log(res)
        const message_fromTo: TypeMessage_Mail_FromTo = res.typeMessage_Mail_FromTo
        this.onUpdateStatusOnFormResultListPopUp(message_fromTo)
    })
  }

  onSelectedMailType(i){
    console.log('user selected: ', this.mailOptions[i])
    if ( this.isSubmitted === false){
      this.sentMailIsDeselected = false
      this.onSentMailIsDeselected.emit(this.sentMailIsDeselected)
    }
  }

  onDeselectedMailType(i){
    console.log('user DEselected: NO ', this.mailOptions[i])
    if ( this.isSubmitted === false){
    this.sentMailIsDeselected = true
    this.onSentMailIsDeselected.emit(this.sentMailIsDeselected)
    }
  }

  onUpdateStatusOnFormResultListPopUp(message_fromTo: TypeMessage_Mail_FromTo ){
    this.ngZone.run(() => {
   
    if (message_fromTo.type === 'all'){
        this.sentMailIsDeselected = false 
    }
    if (message_fromTo.type === 'message'){
        this.sentMailIsDeselected = true 
    }
    if (message_fromTo.type === 'email'){
      // NOT ANY MESSAGE TYPE 
          this.sentMailIsDeselected = false 
    }

  })

  this.cdr.detectChanges()
    this.onSentMailIsDeselected.emit(this.sentMailIsDeselected)
  }

  ngOnDestroy(): void {
    this.subscriptionToFormResultList_onCheckUpdateFilterStatus.unsubscribe()
  }

}
