import { NgModule } from "@angular/core";
import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { ExchangeRateComponent } from "./exchange-rate.component";
import { FormsModule } from '@angular/forms'; 



@NgModule({
    declarations:[
        ExchangeRateComponent
        
    ] ,
    exports: [
        ExchangeRateComponent
        
    ],
    imports: [
        LayoutModule,
        CommonModule,
        FormsModule
    ]

})

export class ExchangeRateModule{}