import { Component, Input, OnInit, Output, EventEmitter , HostListener, OnDestroy , ChangeDetectorRef, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, Subscription, interval } from 'rxjs';
import { FormResult } from '../form-result.model';
import { FormResultsService } from '../form-results.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { DatePipe, Location } from '@angular/common';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay, debounceTime } from 'rxjs/operators';
import { TimeAlignService } from 'src/app/services/other-components-services/time-align.service';


/**
 * form that have been stored and are present in our storage service
 */
interface FormResultStored_MessageLasSeq_MailLastSeq {
  formResultsStored: FormResult[] ,
  messageFirstSeq: number ,
  messageLastSeq: number , 
  messageFirstDateTime: string , 
  messageLastDateTime: string,
  messagesLength: number , 
  ismessagesDone: boolean ,
  mailFirstSeq: number ,
  mailLastSeq: number , 
  mailFirstDateTime: string, 
  mailLastDateTime: string ,
  mailsLength: number, 
  isMailsDone:boolean 

}

/**
 * forms that are visible on the DOM 
 */
interface FormResults_Displaying {
  formResults_displayed: FormResult[],
  indexOfResultDisplayed_Start: number ,
  indexOfResultDisplayed_End: number , 
  triggerIndex: number , 
  count: number 
}

interface AvailableChoice {
  name: string , 
  value: string 
}



@Component({
  selector: 'app-form-results-index',
  templateUrl: './form-results-index.component.html',
  styleUrls: ['./form-results-index.component.css']
  // providers: [{provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}]
})
export class FormResultsIndexComponent implements OnInit , OnDestroy {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
  );

  user: string 

  drappBeginningOfTime: Date 
  numberOfResultTo_Call_See: number = 6

  formResult: FormResult;
  formResults: FormResult[];

  formResults_selected: number[]

  formResultsStored: FormResultStored_MessageLasSeq_MailLastSeq
  formResults_Displaying: FormResults_Displaying

  formResultIndexes: FormResult[]

  noExistingForms: boolean ; 

  isDesktop: boolean;
  isMobile: boolean; 

  trialFormResults: FormResult[]
  isLoading = false 
  error = false 
  noTrialFormResultsYet = false 

  isSelected: boolean 
  isLoadingAdditionalResults: boolean = false 


  subscribeToRestartFormResultIndexList: Subscription
  @Input() resetFormResultIndexList: Observable<void>
  @Input() weAreInMobileMode: boolean

  @Output() updateFormResultsOnChoosenFormResult = new EventEmitter<void>()
  @Output() updateFormResultOnBeingOnMobileMode = new EventEmitter<void>()

  updateOnUserEmail: Subscription
  // formResultsUpdateSubscription: Subscription 
  // additionalMailFormResultsUpdateSubscription: Subscription

  messagePhone: string ="use fix date filters like 'today', '7 days ago', 'last week' or '12 months ago' with a recurrent subject and add them to your home screen , so they are alway ONE CLICK away"

  firstCallOnFormResultsSubscription: Subscription 
  scrollSubscription: Subscription;

  messageFirstSeq: number 
  messageLastSeq: number  
  mailFirstSeq: number 
  mailLastSeq: number 

  /**
   * debouncer subscriptions
   */
  debounceSubject: Subject<any> = new Subject<any>();
  debounceObservable: Observable<any>;
  debounceSubscription: Subscription;

  subscriptionToSuccessiveFormResults: Subscription 
  allFormResults_haveBeenStoredSubscription: Subscription
  timeIntervalSubscription: Subscription


  anOperationHasBeenDone_already: boolean // serve to divide the 2 operations of adding or remove a formesults from list
  temporaryMobileParams: string 
  atLeastOneresultIsSelected: boolean

  availableChoices: AvailableChoice[]

  onClearSelectedFormResultsSubscription: Subscription 
  
  constructor( private formResultsService: FormResultsService ,
               private route: ActivatedRoute, 
               private breakpointObserver: BreakpointObserver , 
               private userObjectUpdateService: UserObjectUpdateService,
               private router: Router , 
               private datePipe: DatePipe ,
               private timeAlignService: TimeAlignService,
               private cdr: ChangeDetectorRef ,
               private ngZone: NgZone , 
               private location: Location
              
    ) { }


@HostListener('document:visibilitychange', ['$event'])
visibilitychange() {
    if (document.hidden){
          console.log("PAGE IS HIDDEN make a property that set to hidden=true and stop all subscriptions " , new Date());
    } else {
          console.log("PAGE IS VISIBLE" , new Date());
      
    }
}

ngOnInit() {

  this.user = this.userObjectUpdateService.userEmail

  this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
    .subscribe((updatedUserEmail)=>{
     this.user = updatedUserEmail
     console.log('updated USER = ', this.user)
  })

  /**
   * get drapp beginning of time 
   */
  const dateStart = this.timeAlignService.drappBeginningOfTime
  this.drappBeginningOfTime = new Date(dateStart)  
  console.log(this.drappBeginningOfTime)
  const newDrappBeginningOfTime = this.datePipe.transform( this.drappBeginningOfTime, "yyyy-MM-ddTHH:mm:ss")
  console.log(newDrappBeginningOfTime)



if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
// {  this.weAreInMobileMode = true ,  this.isDesktop = true, this.isMobile = false ,  console.log('isMobile')} 
// else { this.weAreInMobileMode = false,  this.isDesktop = false,  this.isMobile = false ,   console.log( 'isDesktop' )} 
{  this.weAreInMobileMode = true ,
   this.isDesktop = false,  
   this.isMobile = true ,  
   this.updateFormResultOnBeingOnMobileMode.emit()
   console.log('isMobile')} 
else { this.weAreInMobileMode = false,  
       this.isDesktop = true,  
       this.isMobile = false ,   
       console.log( 'isDesktop' )} 


  this.isLoading = true 

   if ( this.user === null ){ // when user is NULL we just show the whole list from service 
     const newFormResults_displayed: FormResult[] = this.formResultsService.getFormResultsSortBy_Date_fromLast_toFirst()

     this.temporaryMobileParams = ''
     this.atLeastOneresultIsSelected = false 
     this.formResults_selected = []
     console.log(this.formResults_selected)
     

     this.formResults_Displaying = { formResults_displayed: newFormResults_displayed ,
                                     indexOfResultDisplayed_Start: null ,
                                     indexOfResultDisplayed_End: null  , 
                                     triggerIndex: this.numberOfResultTo_Call_See , 
                                     count: this.numberOfResultTo_Call_See }
    this.onClearSelectedFormResults()
      setTimeout(() => {
        this._UpdateFormResultIndexes()
      }, 250);
   }

 /**
 * always recall latest Count n. of formresults 9 both message and email ) 
 * then compare the 2 lists and just get the first 20 in term of timing let say , 
 * thn next call we need to check which one have the earlier last seq , and if it 
 * doesn't reach the same time of the beginning of the last seq we need to call first and compare again  
 */

  if ( this.user !== null ){

        this.temporaryMobileParams = ''
        this.atLeastOneresultIsSelected = false 

 

        console.log( 'date of drapp beginning + newDate Now = ', newDrappBeginningOfTime, ) // newDateNow_ConvertedToServerTime  )
        // this.formResultsService.getAndStoreAllFormResultsBy_user_dateFrom_dateTo(this.user , newDrappBeginningOfTime , localDateNow_String) 
        //this.formResultsService.getAndStoreAllFormResultsBy_user_dateFrom_dateTo(this.user , newDrappBeginningOfTime , localDateNow_String) 
        const type = 'all' 
        // if ( this.route.snapshot.params['messageToFrom'] === 'sent' )
        console.log('ROUTE PARAMETER SNAPSHOT',this.route.snapshot , 'ROUTER URL = ', this.route.snapshot.url )
        
        this.formResultsService.isNowCalling_FormResultList = false

             const localDateNow = new Date()
             const localDateNow_String = this.datePipe.transform( localDateNow, "yyyy-MM-ddTHH:mm:ss")

             this.formResults_Displaying = { formResults_displayed: [] ,
                                             indexOfResultDisplayed_Start: null ,
                                             indexOfResultDisplayed_End: null  , 
                                             triggerIndex: this.numberOfResultTo_Call_See , 
                                             count: this.numberOfResultTo_Call_See 
                                           }

             setTimeout(() => {
                this.formResultsService.getInitial_AllFormResults_ByUser_InitialDrappDate_ToLocalDateNow_Count( type, this.user , newDrappBeginningOfTime , localDateNow_String, this.numberOfResultTo_Call_See )
             },300);
             
        // }
        
  }

  this.timeIntervalSubscription = interval(60000).subscribe(() => {
    // code to be executed every minute
      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "visible") { 
          this.visibilitychange()

        }
    });
   
    console.log('Task executed every minute updating relative times, call possible updated above the current results');
  });

  // subscription from formResults to restart this component
   this.subscribeToRestartFormResultIndexList = this.resetFormResultIndexList
       .subscribe(()=> 
       this.onResetFormResultIndexList()
    )

   this.firstCallOnFormResultsSubscription = this.formResultsService.firstFormResults_messageLastSeq_mailLastSeq_haveBeenStored
       .subscribe((firstCallOnFormResults: FormResultStored_MessageLasSeq_MailLastSeq )=>{
        console.log(firstCallOnFormResults)

        this.showlastCountNumberFormResults_Block( firstCallOnFormResults )
  })

  this.debounceObservable = this.debounceSubject.asObservable().pipe(debounceTime(300)); // Adjust the debounce time as needed (e.g., 300 milliseconds)
  this.debounceSubscription = this.debounceObservable
      .subscribe(() => {
       this.callNextBlockOf_formResults(); // Replace with your function to be debounced
  });

  this.subscriptionToSuccessiveFormResults = this.formResultsService.successiveFormResults_messageLastSeq_mailLastSeq_haveBeenStored
      .subscribe((successiveCallOnFormResults: FormResultStored_MessageLasSeq_MailLastSeq )=>{
    
        this.showNextFormResults_Block( successiveCallOnFormResults )
  })

  this.allFormResults_haveBeenStoredSubscription = this.formResultsService.allFormResults_haveBeenStored
      .subscribe(()=>{
        this.showAllRemainingFormResults()
  })

  this.onClearSelectedFormResultsSubscription = this.formResultsService.clearSelectedFormResultsItems_fromFormResultsItems
      .subscribe(()=>{
        this.onClearSelectedFormResults()
      })


};

/**
 * coming from debounceSubject / observable once the triggerIndex is Visible
 * 
 * interface FormResultStored_MessageLasSeq_MailLastSeq {
    formResultStored: FormResult[] ,
    messageFirstSeq: number ,
    messageLastSeq: number , 
    mailFirstSeq: number ,
    mailLastSeq: number 
  }
 */
callNextBlockOf_formResults(){
  console.log( 'call the next block of results')
  console.log(this.formResultsStored, this.user )
  // call the next formResults starting from the last lastSeq of each type of formresult 
  // calling the message first , the service will deal to get mails too 

  this.formResultsService.isNowCalling_FormResultList = false 

  const newType: string = 'all'
  const newUser = this.user 
  const newDrappBeginningOfTime = this.datePipe.transform( this.drappBeginningOfTime, "yyyy-MM-ddTHH:mm:ss")
  const localDateNow = new Date()
  const localDateNow_String = this.datePipe.transform( localDateNow, "yyyy-MM-ddTHH:mm:ss")
  const newCount = this.formResults_Displaying.count
  const newFormResultsStored = this.formResultsStored

  const isComingFromFormList: boolean = false
  this.formResultsService.checkNextCall_Messages_Emails_Results_DatesStartEnd_beforeGettingNewResults(isComingFromFormList, newType, newUser, newDrappBeginningOfTime, localDateNow_String, newCount, newFormResultsStored )
  
  /**
   * interface availableChoice {
  name: string , 
  value: string 
}
   */
  

}

/**
 * 
 * @param $event check when the result we want is visible , so that we start to call the next 
 *               blocks of results 
 * 
 *  1- on this event change triggerIndex
 *  2- check if after the call we still have another 20 results , if less no more calls 
 *  3- set the bumber
 *  3- when arrived to the last one no more calls 
 */
onComponentVisible($event , i: number){
     console.log($event , i , this.user ) // show false if not visible , show true when it is ... on ngOnInit it show false 

  if( this.user !== null && $event === true && i == this.formResults_Displaying.triggerIndex-1 ){ 
      console.log($event , i )

      this.isLoadingAdditionalResults = true 

      const newTriggerIndex = this.formResults_Displaying.triggerIndex+this.numberOfResultTo_Call_See

      this.formResults_Displaying.triggerIndex = newTriggerIndex
      this.debounceSubject.next(); // Trigger the debouncing logic whenever the input value changes
    
    }

};



  getCurrentDate_AdjustedTo_ServerDateTime(timeZoneOffsetInMinutes: any ){

    const currentDate = new Date();
    const dateAtSeverTime = new Date(currentDate.getTime() + ( timeZoneOffsetInMinutes * 60 * 1000))
    const newDateNow_ConvertedToServerTime = this.datePipe.transform( dateAtSeverTime, "yyyy-MM-ddTHH:mm:ss")

    return  newDateNow_ConvertedToServerTime

    /**
     * const dateString = '2023-05-09T21:00:00'; // Replace with your date string
       const date = new Date(dateString);
       date.setHours(date.getHours() + 2); // Add two hours to the date
       const formattedDate = date.toISOString().replace('Z', ''); // Format the date as a string without the GMT time zone offset
       console.log(formattedDate); // Output the formatted date string
     */

  }

  onRouteToCreateForm(){
    this.router.navigate(['../create-form' ], { relativeTo: this.route.parent })
  }

  showFormResultIndexes(){
    
    this.formResults_Displaying.formResults_displayed = this.formResultsService.getFormResultsSortBy_Date_fromLast_toFirst()
 
    this._UpdateFormResultIndexes()
  }

  showlastCountNumberFormResults_Block(firstCallOnFormresults: FormResultStored_MessageLasSeq_MailLastSeq){

    this.formResultsStored = firstCallOnFormresults 
    // this.formResultsService.currentFormResultsStored = this.formResultsStored
    console.log(firstCallOnFormresults, this.formResultsStored )

    
    /**
     * slice the first block out of all stored formresults starting from last one
     */
    const newFormResults_Displayed: FormResult[] = this.formResultsService.getFormResultsSortBy_Date_fromLast_toFirst()
                                                                          .slice(0, this.numberOfResultTo_Call_See); 

    this.formResults_Displaying.formResults_displayed = newFormResults_Displayed 

    console.log(this.formResults_Displaying.formResults_displayed)

    this.formResults_selected = []
    console.log(this.formResults_selected)
    // this.formResultsService.currentFormResults_Displaying = this.formResults_Displaying
   
    /**
     * interface FormResults_Displaying {
       formResults_displayed: FormResult[],
       index_Start: number ,
       index_End: number 
       }
       1- fix the first 20 formResults as the first block and define indexStart indexEnd 
       2- define also messageLastSeq and mailLastSeq so we know what to call next 
       3- fix index w=so we know where to start next after adding new formResults to the store list 
       4- updateFormResultsIndexes 
       5- CHANGE html formresults with formresults_displayed 
     */
    
    // console.log(this.formResults)
    this._UpdateFormResultIndexes()

    
  }

  sendAvailableChoicesTo_groupFilter(){
    this.availableChoices = []

    console.log('form results stored => ',this.formResultsStored.formResultsStored)

    for ( let i=0; i <this.formResultsStored.formResultsStored.length ; i++ ){
        
        /**
         * has to be a message type first , then we don't add the repetitions also with second IF 
         */
        console.log('turn => ', i)
        if (this.formResultsStored.formResultsStored[i].recipientResult.recipientList.length > 0){
          const newAvailableChoice: AvailableChoice = { name: this.formResultsStored.formResultsStored[i].recipientResult.name , value: this.formResultsStored.formResultsStored[i].userChatRoomId  }
          if ( this.availableChoices.findIndex(x=> x.value === newAvailableChoice.value) === -1){
               this.availableChoices.push(newAvailableChoice)
               console.log(this.availableChoices)
          }
        }
        if (i === this.formResultsStored.formResultsStored.length-1 ){
          this.formResultsService.communicateToGroupFilter_newUpdatedAvailableChoices(this.availableChoices)
        }
    }
  }
 
  showNextFormResults_Block( successiveCallOnFormResults: FormResultStored_MessageLasSeq_MailLastSeq){

    this.formResultsStored = successiveCallOnFormResults
    console.log(this.formResultsStored , successiveCallOnFormResults )

    if (this.formResultsStored.isMailsDone && this.formResultsStored.ismessagesDone ){
        console.log('BOTH MESSAGE AND EMAIL ARE DONE , SHOW REMAINING RESULTS')
        this.showAllRemainingFormResults()
    }

    if ( !this.formResultsStored.isMailsDone || !this.formResultsStored.ismessagesDone ){
         console.log('STILL NEED TO LOOK FOR SOME RESULTS')
         const newSliceStaringPoint: number = this.formResults_Displaying.formResults_displayed.length
         const newSliceEndingPoint: number = newSliceStaringPoint+this.numberOfResultTo_Call_See
  
         const newFormResults_Displayed: FormResult[] = this.formResultsService.getFormResultsSortBy_Date_fromLast_toFirst()
                                                                          .slice(newSliceStaringPoint, newSliceEndingPoint); 

    
         this.ngZone.run(() => {
              this.formResults_Displaying.formResults_displayed.push(...newFormResults_Displayed)
             
         });
         this.cdr.detectChanges()

         this._Update_Successive_FormResultIndexes()

    }


    

  }

  /**
   * adding the latest leftOver hidden formResults
   */
  showAllRemainingFormResults(){
    const lastCurrentFormResultIndex = this.formResults_Displaying.formResults_displayed.length
    const remainingFormResults: FormResult[] =  this.formResultsService.getFormResultsSortBy_Date_fromLast_toFirst().slice(lastCurrentFormResultIndex)

    console.log("REMAINING RESULTS ",remainingFormResults)
    this.ngZone.run(() => {
      this.formResults_Displaying.formResults_displayed.push(...remainingFormResults),
      // this.sendAvailableChoicesTo_groupFilter()
      this._Update_Successive_FormResultIndexes()
    });


  }

  _UpdateFormResultIndexes(){

    if (this.formResults_Displaying.formResults_displayed.length === 0){ this.noExistingForms = true}
    if (this.formResults_Displaying.formResults_displayed.length > 0){
        this.noExistingForms = false 
        /**
         * SEND OBJECT TO FORMRESULT GROUP INDEX 
         * interface availableChoice {
           name: string , 
           value: string 
           }
         */
    }

     console.log(this.formResults_Displaying.formResults_displayed.length)
      this.stopLoading()

      setTimeout(() => {
        if (this.formResults_Displaying.formResults_displayed.length > 0 && this.user !== null){
        this.sendAvailableChoicesTo_groupFilter()  
        }
      }, 200);
      

  };

  _Update_Successive_FormResultIndexes(){

 if (this.formResults_Displaying.formResults_displayed.length === 0){ this.noExistingForms = true}
 if (this.formResults_Displaying.formResults_displayed.length > 0){ this.noExistingForms = false 

 }

  console.log(this.formResults_Displaying.formResults_displayed.length)

  
  // Manually trigger change detection 
  this.cdr.detectChanges();
  setTimeout(() => {
    this.isLoadingAdditionalResults = false
  }, 500);
  

  setTimeout(() => {
    if (this.formResults_Displaying.formResults_displayed.length > 0 && this.user !== null){
    this.sendAvailableChoicesTo_groupFilter()
    }
  }, 200);
  

  
  

};



  stopLoading(){
    this.isLoading = false 
  }

  onSelectedFormResult(i: number){

    this.anOperationHasBeenDone_already = false 

    if ( this.formResults_selected.findIndex(x=> x == i ) == -1 ){
         this.anOperationHasBeenDone_already = true 
         this.addFormResult_toItems(i)
    }

    if ( this.formResults_selected.findIndex(x=> x == i ) !== -1 && !this.anOperationHasBeenDone_already ){
         this.removeFormResult_fromItems(i)
    }
  
  }

  onClearSelectedFormResults(){

    this.ngZone.run(()=>{
      for (let i=0; i< this.formResults_Displaying.formResults_displayed.length ; i++ ){
        this.formResults_Displaying.formResults_displayed[i].isSelected = false
      }
      this.formResults_selected = []
      this.anOperationHasBeenDone_already = false
    })

    this.cdr.detectChanges()

   
  }


  removeFormResult_fromItems(i: number){

    this.ngZone.run(() => {
   

      if (this.isMobileDevice()){
        /**
         * wait untill we have selected all we need and then on button click we change page
         */
         if ( this.formResults_selected.length === 1 ){
              this.formResults_Displaying.formResults_displayed[i].isSelected = false 
              const indexToRemove = this.formResults_selected.findIndex(x=> x == i )
              this.formResults_selected.splice(indexToRemove , 1)
              this.temporaryMobileParams = '' 
              this.atLeastOneresultIsSelected = false 
              console.log("NO PARAMS TO GO")
         }
         if ( this.formResults_selected.length > 1 ){
              
              this.formResults_Displaying.formResults_displayed[i].isSelected = false 
              const indexToRemove = this.formResults_selected.findIndex(x=> x == i )
              this.formResults_selected.splice(indexToRemove , 1)
              const valueToRemove: string = i.toString()
              const updatedParams = this.removeItemFromRouteParams(this.temporaryMobileParams , valueToRemove  )

              this.temporaryMobileParams = updatedParams
              this.atLeastOneresultIsSelected = true 
              console.log("TEMPORARY PARAMS => ", updatedParams )
         }
  
      }
      if (!this.isMobileDevice() && this.formResults_selected.length === 1 ){
          this.formResults_Displaying.formResults_displayed[i].isSelected = false 
          const indexToRemove = this.formResults_selected.findIndex(x=> x == i )
          this.formResults_selected.splice(indexToRemove , 1)

          this.temporaryMobileParams = ''
          this.atLeastOneresultIsSelected = false 

        this.updateFormResultsOnChoosenFormResult.emit() 
        this.router.navigate(['../results' ], { relativeTo: this.route })
      };

      if (!this.isMobileDevice() && this.formResults_selected.length > 1 ){

           this.formResults_Displaying.formResults_displayed[i].isSelected = false 
           const indexToRemove = this.formResults_selected.findIndex(x=> x == i )
           this.formResults_selected.splice(indexToRemove , 1)
           const paramValue = this.formResultsService.getParams()
           console.log( paramValue , this.formResults_selected)
           const routeParams = paramValue['form-result-ids']
           const valueToRemove: string = i.toString()

           const updatedParams = this.removeItemFromRouteParams(routeParams , valueToRemove  )

           this.temporaryMobileParams = updatedParams
           this.atLeastOneresultIsSelected = true 
        this.updateFormResultsOnChoosenFormResult.emit() 
        this.router.navigate(['../results/form-result-items', updatedParams ], { relativeTo: this.route })
      }

      
      

      

    })
    this.cdr.detectChanges()
  
  }

  addFormResult_toItems(i: number){
    this.formResults_Displaying.formResults_displayed[i].isSelected = true

      this.formResults_selected.push(i)
      console.log(this.formResults_selected)
      const routeParams: string = this.formResults_selected.join(',')

      if (this.isMobileDevice()){ 
        /**
         * wait untill we have selected all we need and then on button click we change page
         */
        this.ngZone.run(()=>{
          this.temporaryMobileParams = routeParams 
          this.atLeastOneresultIsSelected = true 
          console.log(this.temporaryMobileParams)
        })
        this.cdr.detectChanges()
       
  
      }
      if (!this.isMobileDevice()){
        this.updateFormResultsOnChoosenFormResult.emit() 
        this.temporaryMobileParams = routeParams 
        this.atLeastOneresultIsSelected = true 
        this.router.navigate(['../results/form-result-items', routeParams ], { relativeTo: this.route })
      }

  }

  onShowMobileResults(){
    console.log('SHOW MOBILE RESULTS')

    const updatedParams = this.temporaryMobileParams 

    this.updateFormResultsOnChoosenFormResult.emit() 
    this.router.navigate(['../results/form-result-items', updatedParams ], { relativeTo: this.route })

  }

  isMobileDevice(): boolean {
    // return window.matchMedia('(max-width: 767px)').matches;
    const isHandset = this.breakpointObserver.isMatched(Breakpoints.Handset);
    return isHandset;
  }


  removeItemFromRouteParams(routeParams: string, valueToRemove: string): string {
    const delimiter = ',';
    const routeParamsArray = routeParams.split(delimiter);
    const updatedParamsArray = routeParamsArray.filter(item => item !== valueToRemove);
    const updatedParams = updatedParamsArray.join(delimiter);
    return updatedParams;
  }

  getLastWeeksDate(numberOfWeeks: number){

    const now = new Date()

    const weeksInDays:number = numberOfWeeks*7

    return new Date( now.getFullYear(), now.getMonth(), now.getDate()-weeksInDays )

  }

  /**
   * restart this component  / should recall again the first 
   */
  onResetFormResultIndexList(){

    this.ngZone.run(()=>{
    this.formResults = this.formResultsService.getFormResults()
    console.log(this.formResults)


     /**
      * check if there are existing forms in store
      */
     if(this.formResultsService.getFormResults().length === 0){
       this.noExistingForms = true
     } else {
       this.noExistingForms = false 
     }

      for( let i=0; i<this.formResults.length; i++){
      this.formResultsService.getFormResults()[i].isSelected = false
    }

  })

  this.cdr.detectChanges()

  };


  ngOnDestroy(): void{
  this.subscribeToRestartFormResultIndexList.unsubscribe()
  this.updateOnUserEmail.unsubscribe()
  this.firstCallOnFormResultsSubscription.unsubscribe()
  this.debounceSubscription.unsubscribe();
  this.subscriptionToSuccessiveFormResults.unsubscribe()
  this.allFormResults_haveBeenStoredSubscription.unsubscribe()
  this.onClearSelectedFormResultsSubscription.unsubscribe()
  this.timeIntervalSubscription.unsubscribe()
  }

}
