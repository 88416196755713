import { Component, Input, OnDestroy, OnInit , EventEmitter, Output, ChangeDetectorRef, NgZone } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { FormResultsService } from '../../form-results.service';

interface Subject_Paragraph_Title {
  type: string , 
  title: string 
}

@Component({
  selector: 'app-filter-subjets-paragraphs',
  templateUrl: './filter-subjets-paragraphs.component.html',
  styleUrls: ['./filter-subjets-paragraphs.component.css']
})

export class FilterSubjetsParagraphsComponent implements OnInit , OnDestroy {

  subjectsParagraphsOptions: string[] = ['subject', 'paragraph' ]

 @Input() subjectIsDeselected: boolean 
 @Input() paragraphIsDeselected: boolean 
 @Input() updatedSubject: string 
 @Input() isSubmitted: boolean

 @Output() onSubjectIsDeselected = new EventEmitter<boolean>()
 @Output() onParagraphIsDeselected = new EventEmitter<boolean>()

  subjectEditForm : FormGroup 
  placeholding: string 

  subjectTextResultSubscription: Subscription
  @Output() emitSubjectTextResult = new EventEmitter<string>()

  isLoadingForm: boolean = false 

  subscriptionToFormResultList_onCheckUpdateFilterStatus: Subscription

   subscribeToChangedIsSubmitStatus: Subscription 
   @Input() updateIsSubmitStatus: Observable<void>



  constructor( private formResultsService: FormResultsService ,
               private cdr: ChangeDetectorRef ,
               private ngZone: NgZone
    ){ }

  ngOnInit() {



    this.subjectEditForm = new FormGroup ({
      name : new FormControl ( this.updatedSubject  )
     })

     if ( this.isSubmitted === true ){ this.subjectEditForm.controls['name'].disable() }


    this.subjectTextResultSubscription= this.subjectEditForm.valueChanges
        .subscribe(
          (value)=> { 
            console.log(value)
            this.emitSubjectTextResult.emit(value.name)
        }
    )

    this.subscriptionToFormResultList_onCheckUpdateFilterStatus = this.formResultsService.checkUpdateFilterResult_fromResultList
      .subscribe((res)=>{
        console.log(res)
        const subject_Paragraph_Title: Subject_Paragraph_Title = res.subject_Paragraph_Title
        this.updateFilterLoggingInUser_onFixFilter(subject_Paragraph_Title)
    })

    this.subscribeToChangedIsSubmitStatus = this.updateIsSubmitStatus
        .subscribe(()=>{
          this.isSubmitted = true 
    })
    

  }



  onSendForm(){
    const newSubjectTextResult = this.subjectEditForm.value.name
    this.emitSubjectTextResult.emit(newSubjectTextResult)
  }


  onSelectSubject(i){
    if ( this.isSubmitted === false){
    this.subjectIsDeselected = false
    this.onSubjectIsDeselected.emit(this.subjectIsDeselected)
    }
  }

  onDeselectSubject(i){
    if ( this.isSubmitted === false){
    this.subjectIsDeselected = true 
    this.onSubjectIsDeselected.emit(this.subjectIsDeselected)
    }
   
  }

  onSelectParagraph(i){
    if ( this.isSubmitted === false){
    this.paragraphIsDeselected = false 
    this.onParagraphIsDeselected.emit(this.paragraphIsDeselected)
    }
  }

  onDeselectParagraph(i){
    if ( this.isSubmitted === false){
    this.paragraphIsDeselected = true 

    this.onParagraphIsDeselected.emit(this.paragraphIsDeselected)
    }
   
  }

  /**
   * @Input() subjectIsDeselected: boolean 
   * @Input() paragraphIsDeselected: boolean 
   */
  updateFilterLoggingInUser_onFixFilter(subject_Paragraph_Title: Subject_Paragraph_Title){
    
  this.ngZone.run(() => {
    if ( subject_Paragraph_Title.type === 'subject' ){
         this.subjectIsDeselected = false 
         this.paragraphIsDeselected = true 
         this.subjectEditForm = new FormGroup ({
          name : new FormControl ( subject_Paragraph_Title.title )
         })
         this.placeholding = subject_Paragraph_Title.title
         this.onSubjectIsDeselected.emit(this.subjectIsDeselected)
     }

    if ( subject_Paragraph_Title.type === 'paragraph'){ 
         this.subjectIsDeselected = true 
         this.paragraphIsDeselected = false
         this.subjectEditForm = new FormGroup ({
         name : new FormControl ( subject_Paragraph_Title.title )
         })
         this.placeholding = subject_Paragraph_Title.title
         this.onParagraphIsDeselected.emit(this.paragraphIsDeselected)
    }
  })

  this.cdr.detectChanges()

  }

  ngOnDestroy(){
    this.subjectTextResultSubscription.unsubscribe()
    this.subscriptionToFormResultList_onCheckUpdateFilterStatus.unsubscribe()
    this.subscribeToChangedIsSubmitStatus.unsubscribe()
  }

}

