import { Component, Input, OnInit } from '@angular/core';
import { RangeInfo } from '../range-info.model';

@Component({
  selector: 'app-inner-slider-item',
  templateUrl: './inner-slider-item.component.html',
  styleUrls: ['./inner-slider-item.component.css']
})
export class InnerSliderItemComponent implements OnInit {

  @Input() rangeInfo: RangeInfo
  @Input() isEditing: boolean 

  constructor() { }

  ngOnInit(): void {
  }

}
