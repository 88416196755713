<!-- <p>single-feature-results works!</p> -->

    <table>
        <tr> 
            <td *ngFor="let singleFeature of singleFeatureDetailResults; let i=index">

                <div [ngSwitch]="singleFeature.singleFeatureDetailResultType">
                    <app-single-word-result [singleWordDetailResult]="singleFeature?.singleFeatureDetailResultResult" *ngSwitchCase="'singleWordResult'"></app-single-word-result>
                    <app-single-toggle-word-button-result [singleToggleWordButtonDetailResult]="singleFeature?.singleFeatureDetailResultResult" *ngSwitchCase="'singleToggleWordButtonResult'"></app-single-toggle-word-button-result>
                    <app-single-word-input-result [singleWordInputDetailResult]="singleFeature?.singleFeatureDetailResultResult" *ngSwitchCase="'singleWordInputResult'"></app-single-word-input-result>
                    <app-single-drop-down-select-result [singleDropDownSelectDetailResult]="singleFeature?.singleFeatureDetailResultResult" *ngSwitchCase="'singleDropDownSelectResult'"></app-single-drop-down-select-result>
                    <app-single-slider-result [singleSliderDetailResult]="singleFeature?.singleFeatureDetailResultResult" *ngSwitchCase="'singleSliderResult'"></app-single-slider-result>
                </div>

            </td>
        </tr>
    </table>

   





