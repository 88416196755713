import { Component, EventEmitter, Input, OnDestroy, OnInit, Output  } from '@angular/core';
import { FormGroup , FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-form-subject',
  templateUrl: './form-subject.component.html',
  styleUrls: ['./form-subject.component.css']
})
export class FormSubjectComponent implements OnInit , OnDestroy {

  subjectEditForm : FormGroup ;
  @Input() formSubject: string ;
  @Input() youAreInFormEdit: boolean 

  @Input() isFixedSubject: boolean 

  subjectTextResult: string 
  subjectTextResultSubscription: Subscription
  @Output() emitSubjectTextResult = new EventEmitter<string>() 
  @Output() emitSubjectIsFixed = new EventEmitter<boolean>()

  subjectName: string 

  constructor() { }

  ngOnInit() {

    this.subjectEditForm = new FormGroup ({
      name : new FormControl ( this.formSubject , [ Validators.pattern(/^[a-zA-Z0-9\s'À-ÿ,.+\-\/]*$/), // Validators.pattern(/^[a-zA-Z0-9\s'À-ÿ,.+\-]*$/), // Allow alphanumeric characters, spaces, apostrophe, accent, comma, period, plus, and minus // Allow alphanumeric characters, spaces, apostrophe, accent, comma, and period // Validators.pattern(/^[a-zA-Z0-9]*$/) Only allow alphanumeric characters => allow strange characters Validators.pattern(/^[a-zA-Z0-9\s!@#$%^&*()]*$/)
      ] )
     })

    if ( this.isFixedSubject){ this.subjectName = 'fixed subject'}
    if ( !this.isFixedSubject){  this.subjectName = 'subject'}
    if ( this.isFixedSubject){ this.subjectEditForm.controls['name'].disable() }
    


    this.subjectTextResult = this.formSubject


    this.subjectTextResultSubscription= this.subjectEditForm.valueChanges.subscribe((value)=> { console.log(value ),  this.onSendForm()})
    


  }



  onSendForm(){
    const newSubjectTextResult = this.subjectEditForm.value.name
  
    this.emitSubjectTextResult.emit(newSubjectTextResult)
  }

  onFixSubject(){

    this.isFixedSubject = !this.isFixedSubject 
    if ( this.isFixedSubject ){ this.subjectName = 'fixed subject'}
    if ( !this.isFixedSubject){ this.subjectName = 'subject'}

    if ( this.isFixedSubject){ this.subjectEditForm.controls['name'].disable() }
    if ( !this.isFixedSubject){ this.subjectEditForm.controls['name'].enable() }



    this.emitSubjectIsFixed.emit(this.isFixedSubject)

  }

  getSubjectColor(){
    return this.isFixedSubject === true ? 'orangered': '#3f51b5';
  }

  ngOnDestroy(){
    this.subjectTextResultSubscription.unsubscribe()
  }

}
