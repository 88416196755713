import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable ({
    providedIn: 'root' 
})

export class StoreMultibaseItemPlusChildsConfirmationService {

    constructor ( 
               
                ){}

    multiFeature_id_plusChildsHasBeenStored = new Subject<string>()
    multiFeatureItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property: any[] = []

    composeMultiBaseComponentPlusChilds_idsList_plusStatus(multiFeature_plusChilds: any ){

        let component_id: string = multiFeature_plusChilds._id
        let haveBeenStored: boolean = true

        this.multiFeatureItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.push({component_id , haveBeenStored })

        for ( let i=0; i<multiFeature_plusChilds.features.length ; i++ ){

            let component_id: string = multiFeature_plusChilds.features[i].feature_id
            let haveBeenStored: boolean = false 
            this.multiFeatureItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.push({component_id , haveBeenStored })

        }
    }


    addStoredComponentConfirmationAndAdviseWhenMultiFeatureItemPlusChildsAreStored(component_id: string ){

        const index = this.multiFeatureItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.findIndex(x=> x.component_id === component_id)
        const haveBeenStored: boolean = true 
        const component_idPlusBoolean: any = { component_id , haveBeenStored }
        this.multiFeatureItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property[index] = component_idPlusBoolean 
 
        console.log(this.multiFeatureItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property)
 
        if (this.multiFeatureItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.findIndex(x=> x.haveBeenStored === false ) !== -1 ){ return }
        if (this.multiFeatureItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.findIndex(x=> x.haveBeenStored === false ) === -1 ){ 
 
         this.multiFeature_id_plusChildsHasBeenStored.next(this.multiFeatureItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property[0].component_id )
            // send notification to formItem that all childcomponents has been stored and can now load safely
            this.resetForm()
         } 

    }

    resetForm(){
        this.multiFeatureItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property = []
        
    }

    onLoggingOutSetListOfMultiFeatureItemsWithChildsEmptyAgain(){
        this.multiFeatureItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property = []

    }

    

}