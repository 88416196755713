import { Injectable } from '@angular/core';
import { FixText } from './fix-text.model';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { Subject, Subscription } from 'rxjs';
import { StoreFormItemPlusChildsConfirmationService } from 'src/app/services/get-and-store-components/store-form-item-plus-childs-confirmation.service';
import { StoreParagraphItemPlusChildsConfirmationService } from 'src/app/services/get-and-store-components/store-paragraph-item-plus-childs-confirmation.service';
import { StoreTabItemPlusChildsConfirmationService } from 'src/app/services/get-and-store-components/store-tab-item-plus-childs-confirmation.service';


@Injectable ({
    providedIn: 'root' 
})

export class FixTextService {

    constructor( 
        private initialNullSetupService: InitialNullSetupService ,
        private storeFormItemPlusChildsConfirmationService: StoreFormItemPlusChildsConfirmationService ,
        private storeParagraphItemPlusChildsConfirmationService: StoreParagraphItemPlusChildsConfirmationService ,
        private storeTabItemPlusChildsConfirmationService: StoreTabItemPlusChildsConfirmationService 
        ){}
        

    fixTexts: FixText[] = this.initialNullSetupService.initialNullFixTexts
    fixTextsHasBeenStored = new Subject<void>()



    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.fixTextsChangedBackToNull
                                                     .subscribe((res)=> {
                                                         this.fixTexts = res 
                                                         console.log('updated fixText back to null = ', res)

    });

    getFixTexts(){
        return this.fixTexts
    }

    getFixText(id: number){
        return this.fixTexts[id]
    }

    getFixTextBy_id(_id: string){
        const newFixText: FixText = this.fixTexts.find(
            (res)=>{
                return res._id === _id
            }
        )
        return newFixText
    }

    getPersonalFixTexts(userEmail: string ){
        console.log(this.fixTexts.filter(x=> x.owner === userEmail))
                const newFixTexts: FixText[] = this.fixTexts.filter(x=> x.owner === userEmail) 
        return newFixTexts
    }

onAddNew(fixText: FixText){
              this.fixTexts.push(fixText)
              this.updateInitialComponents(this.fixTexts)
}

updateFixText(updatedFixText: FixText){

    const index = this.fixTexts.findIndex(x=> x._id === updatedFixText._id)
    this.fixTexts[index] = updatedFixText 
    console.log('fixText UPDATED!')
    this.updateInitialComponents(this.fixTexts)

}


addFixTextsOnly(fixTexts: FixText[]){

    for ( let i=0; i<fixTexts.length; i++){
        if ( this.fixTexts.findIndex(x=> x._id === fixTexts[i]._id ) === -1 ){
            this.fixTexts.push(fixTexts[i])
        }
        if ( this.fixTexts.findIndex(x=> x._id === fixTexts[i]._id ) !== -1 ){
             const index = this.fixTexts.findIndex(x=> x._id === fixTexts[i]._id)
             this.fixTexts[index] = fixTexts[i]
        }

        if (i=== fixTexts.length-1){
            this.updateInitialComponents(this.fixTexts)
        }
    }

}
addFixTexts(fixTexts: FixText[]){

    for ( let i=0; i<fixTexts.length; i++){
        if ( this.fixTexts.findIndex(x=> x._id === fixTexts[i]._id ) === -1 ){
            this.fixTexts.push(fixTexts[i])
        }
        if ( this.fixTexts.findIndex(x=> x._id === fixTexts[i]._id ) !== -1 ){
            const index = this.fixTexts.findIndex(x=> x._id === fixTexts[i]._id)
            this.fixTexts[index] = fixTexts[i]
       }
        if (i=== fixTexts.length-1){
            this.updateInitialComponents(this.fixTexts)
            this.fixTextsHasBeenStored.next() // snd to fixTextList to stop loading 
        }
    }

}

addNewFixText(fixTextToAdd: FixText , isComingFromFormItemCall: boolean  , isComingFromMultiFeatures: boolean){

    if ( this.fixTexts.findIndex(x=> x._id === fixTextToAdd._id ) !== -1 ) { 
         const index = this.fixTexts.findIndex(x=> x._id === fixTextToAdd._id ) 
         this.fixTexts[index] = fixTextToAdd
         this.updateInitialComponents(this.fixTexts)
   }

    if ( this.fixTexts.findIndex(x=> x._id === fixTextToAdd._id ) === -1 ) { 
         this.fixTexts.push(fixTextToAdd) 
         this.updateInitialComponents(this.fixTexts)
    }

    if ( isComingFromFormItemCall === true  ){ this.storeFormItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenFormItemPlusChildsAreStored( fixTextToAdd._id) }
    if ( isComingFromFormItemCall === false ){ this.storeParagraphItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenParagraphItemPlusChildsAreStored( fixTextToAdd._id) }
    if ( isComingFromMultiFeatures=== false ){ this.storeTabItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenTabItemPlusChildsAreStored( fixTextToAdd._id) }

    console.log('FIX TEXTs = ', this.fixTexts)
}

updateInitialComponents(updatedFixTexts: FixText[]){

    this.initialNullSetupService.initialNullFixTexts = updatedFixTexts

}


ngOnDestroy(){
   // this.updateInitialHttpDataFromBaseComponentsService.unsubscribe()
    this.updateInitialNullDatafromInitialNullSetupService.unsubscribe()
}




}