


<!-- <app-chat-form-result-list 
                          [chat]="chat"
                          [subscribeUpdatedFormResults]="subscribeNewFormResults.asObservable()"
 >
</app-chat-form-result-list> -->



    <!-- ---------------MOBILE MODE BUTTONS SEND ------------------------------------------------------ -->
<div *ngIf="userExist" >
  <br>
    <div *ngIf="isHandset$ | async" class="row" >
      <div class="col-xs-12">
        <div class="row">

          <div class="col-xs-3">
            <button type="button"  (click)="onOpenNavBarDrawer()" style="background-color: #3f51b5" class="btn">
              <svg style="color:white" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
              </svg>
            </button>
          </div>

          <div class="d-grid gap-2 col-8 mx-auto">
              <button (click)="onAddCommentToFormResultsMobile()" style="background-color: #3f51b5 ; color: white;" type="button" class="btn">
                comment
              </button>
          </div>

        </div>
      </div>
    </div>

     <!-- ---------------END MOBILE MODE BUTTONS SEND ------------------------------------------------------ -->



      <!-- ---------------START DESKTOP MODE BUTTONS SEND ------------------------------------------------------ -->
    <div *ngIf="(isHandset$ | async) === false" class="row" style="overflow: hidden;" >
      <br>
      <div class="col-xs-12">
        <div class="row">

          <div class="col-xs-1"></div>

          <div class="d-grid gap-2 col-9 mx-auto">
              <button (click)="onAddCommentToFormResultsDesktop()" style="background-color: #3f51b5 ; color: white;" type="button" class="btn">
                comment
              </button>
          </div>

          <div class="col-xs-2"></div>


        </div>
      </div>
    </div>
 <!-- ---------------END DESKTOP MODE BUTTONS SEND ------------------------------------------------------ -->
    
</div>

 <br>






