import { Component, OnDestroy, OnInit, HostListener, ChangeDetectorRef, NgZone } from '@angular/core';
import { FormResult } from './../form-result.model';
import { FormResultsService } from '../form-results.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { Observable, Subject, Subscription , interval} from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ErrorsDialogManagerService } from 'src/app/services/other-components-services/errors-dialog-manager.service';
import { LogoutService } from 'src/app/login/logout.service';
import { debounceTime, map, shareReplay } from 'rxjs/operators';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { TimeAlignService } from 'src/app/services/other-components-services/time-align.service';
import { BreakpointObserver , Breakpoints } from '@angular/cdk/layout';
import { Title } from '@angular/platform-browser';

interface TypeMessage_Mail_FromTo {
  type: string ,
  fromTo: string[]
}

interface Subject_Paragraph_Title {
  type: string , 
  title: string 
}

interface DateType_DateInfo {
  dateType: string , 
  dateInfo: string[]
}

interface FinalRoutingCall {
  messageMailInfos: TypeMessage_Mail_FromTo , 
  dateInfos: DateType_DateInfo, 
  titleInfo: Subject_Paragraph_Title
}

/**
 * form that have been stored and are present in our storage service
 */
interface FormResultStored_MessageLasSeq_MailLastSeq {
  formResultsStored: FormResult[] ,
  messageFirstSeq: number ,
  messageLastSeq: number , 
  messageFirstDateTime: string , 
  messageLastDateTime: string,
  messagesLength: number , 
  ismessagesDone: boolean ,
  mailFirstSeq: number ,
  mailLastSeq: number , 
  mailFirstDateTime: string, 
  mailLastDateTime: string ,
  mailsLength: number, 
  isMailsDone:boolean 

}

/**
 * forms that are visible on the DOM 
 */
interface FormResults_Displaying {
  formResults_displayed: FormResult[],
  indexOfResultDisplayed_Start: number ,
  indexOfResultDisplayed_End: number , 
  triggerIndex: number , 
  count: number 
}

@Component({
  selector: 'app-form-results-list',
  templateUrl: './form-results-list.component.html',
  styleUrls: ['./form-results-list.component.css']
})
export class FormResultsListComponent implements OnInit , OnDestroy {

  user: string 

  numberOfResultTo_Call_See: number = 6

  formResults: FormResult[];
  youAreInFormResultsList: boolean = true 

  formResultsStored: FormResultStored_MessageLasSeq_MailLastSeq
  formResults_Displaying: FormResults_Displaying

  isLoading: boolean 
  isLoadingAdditionalResults: boolean = false 

  noFormsResultYet: boolean; 


  updateOnUserEmail: Subscription
  routeParameterSubscription: Subscription

  timeIntervalSubscription: Subscription 
  onCloseLoginDialogPopUpSubscription: Subscription 
  initialFormResultsListHasBeenStoredSubscription: Subscription

  arrows: { direction: string }[] = [{ direction: 'bottom' }];

  /**
   * debouncer subscriptions
   */
  debounceSubject: Subject<any> = new Subject<any>();
  debounceObservable: Observable<any>;
  debounceSubscription: Subscription;

  allFormResultList_ForThisSearch_hasBeenFoundSubscription: Subscription 
  subscriptionToSuccessiveFormResultList: Subscription

  // subscriptionFromFilter_Submit: Subscription 
 
  
  constructor( private formResultsService: FormResultsService ,
               private userObjectUpdateService: UserObjectUpdateService ,
               private logoutService: LogoutService ,
               private breakpointObserver: BreakpointObserver, 
               private timeAlignService: TimeAlignService , 
               private route: ActivatedRoute ,
               private errorsDialogManagerService: ErrorsDialogManagerService ,
               private cdr: ChangeDetectorRef ,
               private ngZone: NgZone ,
               private titleService: Title 
               ) { }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );
  
  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
      if (document.hidden){
        console.log("PAGE IS HIDDEN , make a property that set to hidden=true and stop all subscriptions " , new Date());

      } else {
            console.log("PAGE IS VISIBLE" , new Date());
      }
  }

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
    .subscribe((updatedUserEmail)=>{
     this.user = updatedUserEmail
     console.log('updated USER = ', this.user)
    })
    
  /**
   * get the formResults according to router informations 
   */
  this.isLoading = true 
    /**
     * makes form results-component close the side nav
     * with onCloseSideNavDrawerSubmittedFromRoutingCall()
     */
    this.formResultsService.onAdviseResultsPageformResultListIsSelected()

    this.routeParameterSubscription = this.route.params
        .subscribe(
          (params:Params) => {
            this.adaptResultList(params)
          }
        ) // END subscription to URL parameters 
    
        this.timeIntervalSubscription = interval(60000).subscribe(() => {
          // code to be executed every minute
            document.addEventListener("visibilitychange", () => {
              if (document.visibilityState === "visible") { 
                this.visibilitychange()
               // this.route.params.subscribe((params:Params) => this.changeFormResult_id(params)) 
              }
          });
         
          console.log('Task executed every minute updating relative times');
        });

        this.onCloseLoginDialogPopUpSubscription = this.logoutService.getClickToCloseLoginDialogPopUp()
            .subscribe(()=> {
            this.getFormResults_Null_User()
        })

       /**
        * this subscription comes as the user was logget out and is trying to go directly to a route for
        * formResultsList , so after receiving basic initial formresults we dowload the route parameters instead 
        * and subsctitute the formresults with just the ones got from parameters 
        */
       this.initialFormResultsListHasBeenStoredSubscription = this.formResultsService.firstFormResultsList_messageLastSeq_mailLastSeq_haveBeenStored
           .subscribe((firstCallOn_AllType_FormResults: FormResultStored_MessageLasSeq_MailLastSeq)=>{
            this.isLoading = true 
            console.log('CLEAR THE FORMS RESULTS AT INITIAL AND SEVICE, FROM FIRST CALL, JUST TO MAKE SURE')
            
            this.showInitial_FormResults_block(firstCallOn_AllType_FormResults)

       })

       this.debounceObservable = this.debounceSubject.asObservable().pipe(debounceTime(300)); // Adjust the debounce time as needed (e.g., 300 milliseconds)
       this.debounceSubscription = this.debounceObservable
           .subscribe(() => {
           this.callNextBlockOf_formResults(); // Replace with your function to be debounced
       });

       this.allFormResultList_ForThisSearch_hasBeenFoundSubscription = this.formResultsService.allFormResults_List_haveBeenStored
           .subscribe(()=>{
            console.log('done with all calls, show all the remaining formResults and stop loading ')
            this.showAllRemainingFormResults()
           })
    
      this.subscriptionToSuccessiveFormResultList = this.formResultsService.successive_FormResultsList_messageLastSeq_mailLastSeq_haveBeenStored
             .subscribe((successiveCallOnFormResultsList: FormResultStored_MessageLasSeq_MailLastSeq)=> {
               this.showNextFormResults_Block( successiveCallOnFormResultsList )
             })
}

onComponentVisible($event , i: number){
  // console.log($event , i ) // show false if not visible , show true when it is ... on ngOnInit it show false 

  if( this.user !== null && $event === true && i == this.formResults_Displaying.triggerIndex-1 ){ 
      console.log($event , i+1 )

      // this.isLoadingAdditionalResults = true 

      const newTriggerIndex = this.formResults_Displaying.triggerIndex + this.numberOfResultTo_Call_See

      this.formResults_Displaying.triggerIndex = newTriggerIndex
      this.debounceSubject.next(); // Trigger the debouncing logic whenever the input value changes
    
    }

};

callNextBlockOf_formResults(){
  console.log("call next block of results TO DO ")
  if ( this.user === null ){ console.log('NULL USER MAYBE DO SOME EASY FILTERS ON ALL FORM RESULTS')}
  if ( this.user !== null ){
    const isFirst_Call: boolean = false 
    /**
     * start loading next
     */
    this.ngZone.run(() => {
      this.isLoadingAdditionalResults = true 
    });
    this.cdr.detectChanges();
    this.formResultsService.isNowCalling_FormResultList = true
    this.continueToGetFormResultsList_accordingToRoutParams( isFirst_Call) 
  }
}

showNextFormResults_Block( successiveCallOnFormResultsList: FormResultStored_MessageLasSeq_MailLastSeq){

  this.formResultsStored = successiveCallOnFormResultsList
  console.log(this.formResultsStored , successiveCallOnFormResultsList )

  if ( this.formResultsStored.ismessagesDone && this.formResultsStored.isMailsDone ){
       console.log('BOTH MESSAGE AND EMAIL ARE DONE , SHOW REMAINING RESULTS LIST')
       this.showAllRemainingFormResults()
  }

  if ( !this.formResultsStored.ismessagesDone || !this.formResultsStored.isMailsDone ){
        console.log('STILL REMAIN RESULTS LIST TO CHECK')
        const newSliceStaringPoint: number = this.formResults_Displaying.formResults_displayed.length
        const newSliceEndingPoint: number = newSliceStaringPoint+this.numberOfResultTo_Call_See

        const newFormResults_Displayed: FormResult[] = this.formResultsService.getFormResultsList_SortBy_Date_fromLast_toFirst()
                                                                        .slice(newSliceStaringPoint, newSliceEndingPoint); 

  
        this.ngZone.run(() => {
             this.formResults_Displaying.formResults_displayed.push(...newFormResults_Displayed)
             this.isLoadingAdditionalResults = false
        });

        this.cdr.detectChanges()
  }


}


/**
 * 
 * @param params parameters coming from result filter or from a URL call 
 */
adaptResultList(params: Params){

  /**
   *  check ifuser is NULL 
   *  if null ask to login 
   *  if login it's denied then just show local undefined temporary results 
   *  if USER !null then check what kind of call 
   *  message / mail calls 
   *  define exact SERVER time with local jetLag to fit local 00:00 and 23:59 
   *  then send request/s
   */

  /**
   * :groupSelected/:groupName
   */

  console.log("PARAMS = ", params )
  console.log( params['type'] , params['messageToFrom'], params['emailTo'], 
               params['dateType'] ,params['dateInfoStart'],params['dateInfoEnd'], 
               params['groupSelected'], params['groupName'],
               params['subject-paragraph'],params['title'])

  if ( this.user === null ){ 
      console.log('LOGIN POP UP, CHOOSE WHETHER TO LOGIN OR it will do GetFormResults_Null_User at 315')
      /**
       * LOGIN POP UP
       */
      this.errorsDialogManagerService.LoginPopUpDialog()
       
  }
  if ( this.user !== null ){ 
                  this.isLoading = true
                  console.log("WE ARE STOPPING THE INDEX CALLS WITH THIS isNowCalling_FormResultList PROPERTY ON FORMRESULTSERVICE")
                  this.formResultsService.isNowCalling_FormResultList = true
                  const isFirst_Call = true 
                  this.continueToGetFormResultsList_accordingToRoutParams(isFirst_Call) 
  }
  
}

getFormResults_Null_User(){
              /**
               * should get the results according to the PARAM SUBSCRIPTION ANYWAY 
               * 
               */
               const newFormResults_displayed: FormResult[] = this.formResultsService.getFormResults()
               const newIndexOfResultDisplayed_Start: number = null 
               const newIndexOfResultDisplayed_End: number = null 
               const newTriggerIndex: number = null 
               const newCount: number = null 

     const newFormResults_Displaying: FormResults_Displaying = { formResults_displayed: newFormResults_displayed , 
                                                                 indexOfResultDisplayed_Start: newIndexOfResultDisplayed_Start,
                                                                 indexOfResultDisplayed_End: newIndexOfResultDisplayed_End,
                                                                 triggerIndex: newTriggerIndex, 
                                                                 count: newCount }
      console.log(this.route.snapshot.params)

      const filteredFormResults: FormResult[] = newFormResults_Displaying.formResults_displayed.filter(item=> this.getNullUser_filteredFormResult(item))

      this.formResults_Displaying = newFormResults_Displaying // make a filter here instead 
      this.formResults_Displaying.formResults_displayed = filteredFormResults

               if( this.formResults_Displaying.formResults_displayed.length === 0 ){
                   this.noFormsResultYet = true
                   } else {
                   this.noFormsResultYet = false
               }
      this.isLoading = false 

  /**
   * throw dialog is just for deemo purpose , to send real messages 
   * and keep records of your messages/prepared emails
   * you need to log in
   */

}


/**
 * NULL USER RESULTS
 */
getNullUser_filteredFormResult( formResultItem: FormResult ): boolean {
  
  let typeMessageMail: string = this.route.snapshot.params['type']


  let fromTo1: string[] = [ undefined , undefined ]
  if ( this.route.snapshot.params['messageToFrom'] === 'sent' ){ fromTo1[0] = 'send'}
  if ( this.route.snapshot.params['messageToFrom'] === 'received' ){ fromTo1[0] = 'receive'}
  if ( this.route.snapshot.params['messageToFrom'] === 'all' ){ fromTo1[0] = 'all'}

  if ( this.route.snapshot.params['emailTo'] === 'sent' ){ fromTo1[1] = 'send'}
  if ( this.route.snapshot.params['emailTo'] === 'oooooooo' ){ fromTo1[1] = 'oooooooo'}
 
  let messageMail_canSee: boolean = true 

  if ( fromTo1[0] === 'send' || fromTo1[0] === 'all' || fromTo1[0] === 'receive' ){ 
    if ( formResultItem.recipientResult.recipientList.length === 0 ){  messageMail_canSee = false  }
  }
 
  console.log(messageMail_canSee)
  if ( fromTo1[0] === 'oooooooo'){
    if ( formResultItem.recipientResult.recipientList.length > 0 ){ messageMail_canSee = false}
   }

  if ( fromTo1[1] === 'send' ){ 
    if ( formResultItem.recipientResult.toMail.length === 0 ){ messageMail_canSee = false  }
  }

  if ( fromTo1[1] === 'oooooooo'){ 
    if ( formResultItem.recipientResult.toMail.length > 0 ){ messageMail_canSee = false  }
  }

  if ( typeMessageMail === 'all'){ messageMail_canSee = true }

  if (  fromTo1[0] === 'receive'){ 
    if ( formResultItem.recipientResult.recipientList.length > 0 ){ messageMail_canSee = false  }
  }


  let typeSubjectParagraph: string = this.route.snapshot.params['subject-paragraph']
  if ( this.route.snapshot.params['subject-paragraph'] === 'paragraph'){ typeSubjectParagraph = 'paragraph'} 

  const title1 : string = this.route.snapshot.params['title']
  
  let doesSubjectParagraphFit: boolean = true

  if (typeSubjectParagraph === 'subject'){
      doesSubjectParagraphFit = formResultItem.subject.includes(title1)
      console.log(typeSubjectParagraph,formResultItem.subject, title1,  doesSubjectParagraphFit)
  }

  if (typeSubjectParagraph === 'paragraph'){ 

      doesSubjectParagraphFit = false 
      for ( let i=0; i< formResultItem.paragraphResults.length ; i++ ){
           if ( formResultItem.paragraphResults[i].paragraphResultName.includes(title1) ){
                console.log(formResultItem.paragraphResults[i].paragraphResultName.includes(title1))
                doesSubjectParagraphFit = true 
                i=formResultItem.paragraphResults.length
           }
      }
  }

  
  /**
   * according to the type of dateType need to find the right date 
   */
 
  const dateType1: string = this.route.snapshot.params['dateType']
 
  let dateInfos: string[] = []
  let isWithinRange:boolean = false;
   
  if ( dateType1 === 'range'){ 
       dateInfos.push(this.route.snapshot.params['dateInfoStart'])
       dateInfos.push(this.route.snapshot.params['dateInfoEnd']) 
       isWithinRange = this.provideIsWithinRange_givenFormResultdateTime_DateInfosDates(dateType1, formResultItem , dateInfos )
  }
  if ( dateType1 === 'fix'){
 
    /**
     * on NULL USER DO NOT ADD SERVER TIME DIFFERENCE
     */
      if (this.route.snapshot.params['dateInfoEnd'] === 'days'){ 
         const daysAgoResponse: string[] = this.timeAlignService.given_DaysAgo_Provide_DateStart_DateEnd(this.route.snapshot.params['dateInfoStart'])
         console.log(daysAgoResponse)
         dateInfos.push(...daysAgoResponse)
         console.log(dateInfos)
         isWithinRange = this.provideIsWithinRange_givenFormResultdateTime_DateInfosDates(dateType1, formResultItem , dateInfos )
      }
 
      if (this.route.snapshot.params['dateInfoEnd'] === 'weeks'){
         dateInfos.push(this.timeAlignService.given_WeeksAgo_Provide_DateStart_DateEnd(this.route.snapshot.params['dateInfoStart'])[0])
         dateInfos.push(this.timeAlignService.given_WeeksAgo_Provide_DateStart_DateEnd(this.route.snapshot.params['dateInfoStart'])[1])
         isWithinRange = this.provideIsWithinRange_givenFormResultdateTime_DateInfosDates(dateType1, formResultItem , dateInfos )
      }
 
      if (this.route.snapshot.params['dateInfoEnd'] === 'months'){
          dateInfos.push(this.timeAlignService.given_MonthsAgo_Provide_DateStart_DateEnd(this.route.snapshot.params['dateInfoStart'])[0])
          dateInfos.push(this.timeAlignService.given_MonthsAgo_Provide_DateStart_DateEnd(this.route.snapshot.params['dateInfoStart'])[1])
          isWithinRange = this.provideIsWithinRange_givenFormResultdateTime_DateInfosDates(dateType1, formResultItem , dateInfos )
      }
  }

  return messageMail_canSee && doesSubjectParagraphFit && isWithinRange
}

provideIsWithinRange_givenFormResultdateTime_DateInfosDates( type: string , formResultItem: FormResult , dateInfos: string[]): boolean{

  let isWithinRange:boolean = false;

  console.log( 'formResult time, range => ', formResultItem.dateAndTime, dateInfos)
  const formResultDate: Date = new Date(formResultItem.dateAndTime.replace(/-/g, '/'))
  console.log(formResultDate)

  if ( type === 'fix'){
  const  [year, month, day, hours, minutes, seconds] = dateInfos[0].split(/[:T]/).map(Number);
  const startRangeDate = new Date(year, month - 1, day, hours, minutes, seconds);

  const  [year1, month1, day1, hours1, minutes1, seconds1] = dateInfos[1].split(/[:T]/).map(Number);
  const endRangeDate = new Date(year1, month1 - 1, day1, hours1, minutes1, seconds1);

  console.log(startRangeDate, endRangeDate )
  isWithinRange = formResultDate >= startRangeDate && formResultDate <= endRangeDate 

  return isWithinRange
  }

  if (type === 'range'){
    const startRangeDate: Date = new Date(dateInfos[0])
    const endRangeDate: Date = new Date(dateInfos[1])

    isWithinRange = formResultDate >= startRangeDate && formResultDate <= endRangeDate 

    return isWithinRange
  }

   
}

/**
 * getting formResults according to route parameters
 * NOW SUBSTITUTE THE EXISTING ONES
 * 
 *  1- define which type of call i have to make / message / email 
 *  2- define the message type / email type 
 *  3- define the timing ( with updated timezone )
 *  4- define if there is a subject or paragraph
 *  5- define possible title 
 *  
 */
continueToGetFormResultsList_accordingToRoutParams( isFirst_Call : boolean ){

  let browserTitleToConcat: string[] = [] // this is for setting th browser title on the TAB

  let typeSubjectParagraph: string = this.route.snapshot.params['subject-paragraph']
  if ( typeSubjectParagraph === 'paragraph'){ typeSubjectParagraph = encodeURI('paragDescr')}

  const title1 : string = this.route.snapshot.params['title']
  const subject_Paragraph_Title: Subject_Paragraph_Title = { type: typeSubjectParagraph , title: title1 }
  console.log(title1)

  /**
   * little things to show on browser TAB ------------
   */
  if (this.route.snapshot.params['groupSelected'] !== 'oooooooo'){ browserTitleToConcat.push(this.route.snapshot.params['groupName'])}

  if (typeSubjectParagraph !== 'oooooooo'){  browserTitleToConcat.push(this.route.snapshot.params['subject-paragraph'])}
  if (typeSubjectParagraph !== 'oooooooo'){ browserTitleToConcat.push(' '),browserTitleToConcat.push( title1 ), browserTitleToConcat.push(' ')}

  if ( this.route.snapshot.params['messageToFrom'] === 'sent' ){ browserTitleToConcat.push('sent messages')}
  if ( this.route.snapshot.params['messageToFrom'] === 'received' ){ browserTitleToConcat.push('received messages') }
  if ( this.route.snapshot.params['messageToFrom'] === 'all' ){ browserTitleToConcat.push('all messages') }

  if ( this.route.snapshot.params['emailTo'] === 'sent' ){ browserTitleToConcat.push(' '), browserTitleToConcat.push('sent emails') }

  const newTabTitle: string = browserTitleToConcat.join('')
  this.titleService.setTitle(newTabTitle)



  /**
   *  END BRWSER TAB TITLE --------------------------------
   */
 

  console.log("WE ARE STOPPING THE INDEX CALLS WITH THIS isNowCalling_FormResultList PROPERTY ON FORMRESULTSERVICE")
  console.log('WE ARE NOW GEETING THE PARAMS FORM RESULTS AND STOP LOADING', this.route.snapshot.params)

/**
 * this type of calls coming from the route-parameter subscription are always the FIRST call 
 */ 
 let newFormResultStored: FormResultStored_MessageLasSeq_MailLastSeq
 if ( isFirst_Call ){ 
  
     const newFormResultsStored: FormResult[] = []
     const newMessageFirstSeq = undefined 
     const newMessageLastSeq = undefined
     const newMessageFirstDateTime = undefined
     const newMessageLastDateTime = undefined
     const newMessagesLength = 0
     const newIsMessagesDone = true
     const newMailFirstSeq = undefined 
     const newMailLastSeq = undefined 
     const newMailFirstDateTime = undefined 
     const newMailLastDateTime = undefined 
     const newMailsLength = 0 
     const newIsMailsDone = true
     newFormResultStored = { formResultsStored: newFormResultsStored, 
                                   messageFirstSeq: newMessageFirstSeq ,
                                   messageLastSeq: newMessageLastSeq ,
                                   messageFirstDateTime: newMessageFirstDateTime,
                                   messageLastDateTime:newMessageLastDateTime ,
                                   messagesLength: newMessagesLength , 
                                   ismessagesDone: newIsMessagesDone , 
                                   mailFirstSeq: newMailFirstSeq,
                                   mailLastSeq: newMailLastSeq,
                                   mailFirstDateTime: newMailFirstDateTime,
                                   mailLastDateTime: newMailLastDateTime , 
                                   mailsLength: newMailsLength , 
                                   isMailsDone: newIsMailsDone 
                                  } 
 }

 if ( !isFirst_Call ){ newFormResultStored = this.formResultsStored}

 let typeMessageMail: string = this.route.snapshot.params['type'] ;

 let fromTo1: string[] = [ undefined , undefined ]
 if ( this.route.snapshot.params['messageToFrom'] === 'sent' ){ fromTo1[0] = 'send'}
 if ( this.route.snapshot.params['messageToFrom'] === 'received' ){ fromTo1[0] = 'receive'}
 if ( this.route.snapshot.params['messageToFrom'] === 'all' ){ fromTo1[0] = 'all'}

 if ( this.route.snapshot.params['emailTo'] === 'sent' ){ fromTo1[1] = 'send'}
 if ( this.route.snapshot.params['emailTo'] === 'oooooooo' ){ fromTo1[1] = 'oooooooo'}
// params['groupSelected'], params['groupName'],


 const typeMessage_Mail_FromTo: TypeMessage_Mail_FromTo = { type: typeMessageMail , fromTo: fromTo1 }

//  let typeSubjectParagraph: string = this.route.snapshot.params['subject-paragraph']
//  if ( typeSubjectParagraph === 'paragraph'){ typeSubjectParagraph = encodeURI('paragDescr')}

//  const title1 : string = this.route.snapshot.params['title']
//  const subject_Paragraph_Title: Subject_Paragraph_Title = { type: typeSubjectParagraph , title: title1 }
//  console.log(title1)

 /**
  * according to the type of dateType need to find the right date 
  */

 const dateType1: string = this.route.snapshot.params['dateType']

 let dateInfos: string[]
  
 if ( dateType1 === 'range'){ dateInfos = [ this.route.snapshot.params['dateInfoStart'] , this.route.snapshot.params['dateInfoEnd'] ]}
 if ( dateType1 === 'fix'){

     if (this.route.snapshot.params['dateInfoEnd'] === 'days'){ 
        dateInfos = this.timeAlignService.given_DaysAgo_Provide_DateStart_DateEnd(this.route.snapshot.params['dateInfoStart'])
     }

     if (this.route.snapshot.params['dateInfoEnd'] === 'weeks'){
        dateInfos = this.timeAlignService.given_WeeksAgo_Provide_DateStart_DateEnd(this.route.snapshot.params['dateInfoStart'])
     }

     if (this.route.snapshot.params['dateInfoEnd'] === 'months'){
         dateInfos = this.timeAlignService.given_MonthsAgo_Provide_DateStart_DateEnd(this.route.snapshot.params['dateInfoStart'])
     }
 }

 const dateType_DateInfo: DateType_DateInfo = { dateType: dateType1 , dateInfo: dateInfos}


 const finalRoutingCall: FinalRoutingCall = { messageMailInfos: typeMessage_Mail_FromTo , dateInfos: dateType_DateInfo, titleInfo: subject_Paragraph_Title   }
 /**
  * IF IS THE FIRST CALL 
  * SET THE FORM RESULT DISPLAYNG TO STARTING POINT 
  * send a subject to formresultFilter to check if the filter has the same setting , or adjust it  
  */
 if ( isFirst_Call === true ){

  this.formResults_Displaying = { formResults_displayed: [], indexOfResultDisplayed_Start: null , indexOfResultDisplayed_End: null , triggerIndex: this.numberOfResultTo_Call_See , count: this.numberOfResultTo_Call_See }

  this.formResultsService.updateFormResultFilter_onFormResultListOutput({ typeMessage_Mail_FromTo, 
    dateType_DateInfo,
    subject_Paragraph_Title,
    finalRoutingCall
  })
 }
 


 if ( this.route.snapshot.params['type'] === 'message' ){ 
      console.log('looking for MESSAGES - ', this.route.snapshot.params['messageToFrom'] )
      const isComingFromFormList: boolean = true 
      const isFirstOf2Calls: boolean = false
      if (isFirst_Call){
        if ( typeSubjectParagraph === 'oooooooo'){ 
               if (this.route.snapshot.params['groupSelected'] === 'oooooooo'){this.formResultsService.get_FirstCallFromParam_Message_FormResultsList_ByUser_InitialDrappDate_ToLocalDateNow_Count(isFirstOf2Calls, fromTo1[0], this.user, dateInfos[0], dateInfos[1], this.formResults_Displaying.count, newFormResultStored )}
               if (this.route.snapshot.params['groupSelected'] !== 'oooooooo'){this.formResultsService.get_CHAT_FirstCallFromParam_Message_FormResultsList_ByUser_UserChatRoomId_InitialDrappDate_ToLocalDateNow_Count(fromTo1[0], this.user, this.route.snapshot.params['groupSelected'] , dateInfos[0], dateInfos[1], this.formResults_Displaying.count, newFormResultStored )}
        }
        if ( typeSubjectParagraph !== 'oooooooo'){ 
              if (this.route.snapshot.params['groupSelected'] === 'oooooooo'){this.formResultsService.get_FirstCallFromParam_Message_FormResultsList_ByUser_InitialDrappDate_ToLocalDateNow_Count_SubjectParagraph(fromTo1[0], this.user, dateInfos[0], dateInfos[1], this.formResults_Displaying.count, typeSubjectParagraph, title1, newFormResultStored )}
              if (this.route.snapshot.params['groupSelected'] !== 'oooooooo'){this.formResultsService.get_CHAT_FirstCallFromParam_Message_FormResultsList_ByUser_UserChatRoomId_InitialDrappDate_ToLocalDateNow_Count_SubjectParagraph(fromTo1[0], this.user,this.route.snapshot.params['groupSelected'],dateInfos[0], dateInfos[1], this.formResults_Displaying.count, typeSubjectParagraph, title1, newFormResultStored   )}
        }
      }
      if (!isFirst_Call){ 
        if ( typeSubjectParagraph === 'oooooooo'){ 
              if (this.route.snapshot.params['groupSelected'] === 'oooooooo'){this.formResultsService.checkNextCall_Messages_Emails_Results_DatesStartEnd_beforeGettingNewResults(isComingFromFormList, fromTo1[0], this.user, dateInfos[0], dateInfos[1], this.formResults_Displaying.count, newFormResultStored )}
              if (this.route.snapshot.params['groupSelected'] !== 'oooooooo'){this.formResultsService.get_CHAT_Message_FormResultsList_ByUser_UserChatRoomId_InitialDrappDate_ToLocalDateNow_Count_LastSeq(fromTo1[0], this.user, this.route.snapshot.params['groupSelected'], dateInfos[0], dateInfos[1], this.formResults_Displaying.count, newFormResultStored )}
        }
              
              
        if ( typeSubjectParagraph !== 'oooooooo'){
              if (this.route.snapshot.params['groupSelected'] === 'oooooooo'){ this.formResultsService.get_Message_FormResultsList_ByUser_InitialDrappDate_ToLocalDateNow_Count_SubjectParagraph_LastSeq(isFirstOf2Calls, fromTo1[0], this.user, dateInfos[0], dateInfos[1], this.formResults_Displaying.count, typeSubjectParagraph, title1, newFormResultStored ) }
              if (this.route.snapshot.params['groupSelected'] !== 'oooooooo'){ this.formResultsService.get_CHAT_Message_FormResultsList_ByUser_UserChatRoomId_InitialDrappDate_ToLocalDateNow_Count_SubjectParagraph_LastSeq(fromTo1[0], this.user, this.route.snapshot.params['groupSelected'], dateInfos[0], dateInfos[1], this.formResults_Displaying.count, typeSubjectParagraph, title1, newFormResultStored )}
        }
      }
     
 }
 /**
  * groupSelected can only be done with messages , so these calls below can only be done without group 
  */
 if ( this.route.snapshot.params['type'] === 'email' ){
      console.log('looking for EMAIL - ', this.route.snapshot.params['emailTo'] )
      const isComingFromFormList: boolean = true 
      const isFirstOf2Calls: boolean = false
      if ( this.route.snapshot.params['emailTo'] === 'sent' ){ 
        if (isFirst_Call){  
          if ( typeSubjectParagraph === 'oooooooo'){ this.formResultsService.get_FirstCallFromParam_Mail_List_FormResultsList_ByUser_InitialDrappDate_ToLocalDateNow_Count( this.user, dateInfos[0], dateInfos[1], this.formResults_Displaying.count, newFormResultStored )}
          if ( typeSubjectParagraph !== 'oooooooo'){ this.formResultsService.get_FirstCallFromParam_Mail_FormResultsList_ByUser_InitialDrappDate_ToLocalDateNow_Count_SubjectParagraph(this.user , dateInfos[0], dateInfos[1], this.formResults_Displaying.count, typeSubjectParagraph, title1, newFormResultStored)  }
        }

        if (!isFirst_Call ){ 
          if (typeSubjectParagraph === 'oooooooo'){ this.formResultsService.get_Mail_Formresults_NextTranche(isComingFromFormList, isFirstOf2Calls, fromTo1[0], this.user, dateInfos[0], dateInfos[1], this.formResults_Displaying.count, newFormResultStored  )}
          if (typeSubjectParagraph !== 'oooooooo'){ this.formResultsService.get_Mail_FormResultsList_ByUser_InitialDrappDate_ToLocalDateNow_Count_SubjectParagraph_LastSeq(isFirstOf2Calls, fromTo1[0], this.user, dateInfos[0], dateInfos[1], this.formResults_Displaying.count, typeSubjectParagraph, title1, newFormResultStored )}
        }
      }

       
      // in this case messageFromTo is always 'oooooooo' / only one call here email
 }
 if ( this.route.snapshot.params['type'] === 'all' ){ 
      console.log('looking for ALL - ', this.route.snapshot.params['messageToFrom'] )
      const isFirstOf2Calls: boolean = true
      console.log('is first call ? =>',isFirst_Call)
      this.http_GET_All_Emails_AllMessages_FormResultList(isFirst_Call, isFirstOf2Calls, fromTo1[0] , this.user, dateInfos[0], dateInfos[1] , this.formResults_Displaying.count , typeSubjectParagraph, title1, newFormResultStored  )
 }



};


/**
 * call both emails and messages
 */
http_GET_All_Emails_AllMessages_FormResultList(isFirst_Call: boolean , isFirstOf2Calls: boolean, type: string , user: string , dateFrom: string, dateTo: string , count: number , typeSubjectParagraph: string , title: string , formResultStored: FormResultStored_MessageLasSeq_MailLastSeq ){
  if ( isFirst_Call){  console.log('is first call')
    if (typeSubjectParagraph === 'oooooooo'){this.formResultsService.getInitial_List_AllFormResults_ByUser_InitialDrappDate_ToLocalDateNow_Count(type, user, dateFrom, dateTo, count)}
    if (typeSubjectParagraph !== 'oooooooo'){ this.formResultsService.getInitial_List_SubjectParagraph_AllFormResults_ByUser_InitialDrappDate_ToLocalDateNow_Count(type, user, typeSubjectParagraph, title, dateFrom, dateTo, count )}
  }
  if (!isFirst_Call){  console.log('NOT FIRST CALL')
     this.formResultsService.checkNext_List_Call_Messages_Emails_Results_DatesStartEnd_beforeGettingNewResults(isFirstOf2Calls, type, user, dateFrom, dateTo, count, typeSubjectParagraph, title, formResultStored )
    // this.formResultsService.get_AllFormResults_List_ByUser_InitialDrappDate_ToLocalDateNow_Count_SubjectParagraph( isFirst_Call, isFirstOf2Calls, type, user, dateFrom, dateTo, count, typeSubjectParagraph, title , formResultStored  )}
  }
}

onOpenFilterSideNav(){
  this.formResultsService.onOpenFiltersSideNav_fromEmptyFormResultList()
}

showInitial_FormResults_block(firstCallOn_AllType_FormResults: FormResultStored_MessageLasSeq_MailLastSeq){

  this.formResultsStored = firstCallOn_AllType_FormResults
    console.log( firstCallOn_AllType_FormResults , this.formResultsStored )

    if ( this.formResultsStored.ismessagesDone && this.formResultsStored.isMailsDone ){
      console.log('BOTH MESSAGE AND EMAIL ARE DONE , SHOW REMAINING RESULTS LIST')
      const newFormResults_Displayed: FormResult[] = this.formResultsService.getFormResultsList_SortBy_Date_fromLast_toFirst().slice(0, this.numberOfResultTo_Call_See); 
      this.formResults_Displaying.formResults_displayed = newFormResults_Displayed

      this._UpdateFormResultFilter()

      this.showAllRemainingFormResults()
    }

    if ( !this.formResultsStored.ismessagesDone || !this.formResultsStored.isMailsDone ){
       /**
     * slice the first block out of all stored formresults starting from last one
     */
    const newFormResults_Displayed: FormResult[] = this.formResultsService.getFormResultsList_SortBy_Date_fromLast_toFirst().slice(0, this.numberOfResultTo_Call_See); 

    this.formResults_Displaying.formResults_displayed = newFormResults_Displayed
   
    this._UpdateFormResultFilter()

    }

    
}

/**
   * adding the latest leftOver hidden formResults
   */
showAllRemainingFormResults(){
  const lastCurrentFormResultIndex = this.formResults_Displaying.formResults_displayed.length
  const remainingFormResults: FormResult[] =  this.formResultsService.getFormResultsList_SortBy_Date_fromLast_toFirst().slice(lastCurrentFormResultIndex)

  
  this.ngZone.run(() => {
  this.formResults_Displaying.formResults_displayed.push(...remainingFormResults)
  this.isLoading = false
  this.isLoadingAdditionalResults = false
  });
  console.log("FINAL RESULTS => ", this.formResults_Displaying.formResults_displayed )

  this.cdr.detectChanges();
  // this.isLoadingAdditionalResults = false


}

_UpdateFormResultFilter(){

  // if (this.formResults_Displaying.formResults_displayed.length === 0){ this.noFormsResultYet = true}
  // if (this.formResults_Displaying.formResults_displayed.length > 0){ this.noFormsResultYet = false }
 const typeMessageMail: string = this.route.snapshot.params['type']
 const fromTo1: string[] = [ this.route.snapshot.params['messageToFrom'], this.route.snapshot.params['emailTo'] ]
 const typeMessage_Mail_FromTo: TypeMessage_Mail_FromTo = { type: typeMessageMail , fromTo: fromTo1 }

 const typeSubjectParagraph: string = this.route.snapshot.params['subject-paragraph']
 const title1 : string = this.route.snapshot.params['title']
 const subject_Paragraph_Title: Subject_Paragraph_Title = { type: typeSubjectParagraph , title: title1 }

 /**
  * according to the type of dateType need to find the right date 
  */
 
 const dateType1: string = this.route.snapshot.params['dateType']
 const dateInfos: string[] = [ this.route.snapshot.params['dateInfoStart'] , this.route.snapshot.params['dateInfoEnd'] ]
 const dateType_DateInfo: DateType_DateInfo = { dateType: dateType1 , dateInfo: dateInfos}

 const finalRoutingCall: FinalRoutingCall = { messageMailInfos: typeMessage_Mail_FromTo , dateInfos: dateType_DateInfo, titleInfo: subject_Paragraph_Title   }

 
  this.formResultsService.updateFormResultFilter_onFormResultListOutput({ typeMessage_Mail_FromTo, 
    dateType_DateInfo,
    subject_Paragraph_Title,
    finalRoutingCall
  })


   console.log(this.formResults_Displaying.formResults_displayed.length)
  this.stopLoading()

};


stopLoading(){
  this.isLoading = false 
}


ngOnDestroy(): void {
    this.updateOnUserEmail.unsubscribe()
    this.timeIntervalSubscription.unsubscribe()
    this.routeParameterSubscription.unsubscribe()
    this.initialFormResultsListHasBeenStoredSubscription.unsubscribe()
    this.allFormResultList_ForThisSearch_hasBeenFoundSubscription.unsubscribe()
    this.subscriptionToSuccessiveFormResultList.unsubscribe()
    // this.subscriptionFromFilter_Submit.unsubscribe()
}

 

}
