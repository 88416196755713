import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Recipient } from '../../../recipient.model';


@Component({
  selector: 'app-greetings',
  templateUrl: './greetings.component.html',
  styleUrls: ['./greetings.component.css']
})
export class GreetingsComponent implements OnInit {

  greetingsEditForm: FormGroup
  @Input() recipient: Recipient

  greetingsTextResult: string 
  greetingsTextResultSubscription: Subscription


  @Output() updateGreetings = new EventEmitter<string>()

  constructor() { }

  ngOnInit(){

    this.greetingsEditForm = new FormGroup({
      greetings : new FormControl (this.recipient.greeting)

    });

    this.greetingsTextResult = this.recipient.greeting 

    this.greetingsTextResultSubscription = this.greetingsEditForm.valueChanges
        .subscribe((value)=> { console.log( value), this.onUpdateGreetings()})
  }


  onUpdateGreetings(){

    this.greetingsTextResult  = this.greetingsEditForm.value.greetings
    console.log('is updating value = ', this.greetingsTextResult )

    this.updateGreetings.emit(this.greetingsTextResult )

  }

  ngOnDestroy(){
    this.greetingsTextResultSubscription.unsubscribe()
  }



  

}
