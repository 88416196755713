<!-- <div class="ParagraphResultFrame"> -->
    <div>

   <br>
   <div class="row">
       <div class="col-xs-10">
       </div>
       <div class="col-xs-2">
          
            <span class="badge rounded-pill bg-info text-dark">{{paragraphResult.paragraphResultName | truncate:25}}</span>
        
          
       </div>
   </div>

    <div *ngFor="let feature of paragraphResult?.featureResults; let i=index">
            <app-feature-results [featureResult]="feature"></app-feature-results>
    </div>
   <br>

</div>