import { Injectable } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { UserObject } from "../../auth/user.model";
import { AuthService } from "../auth-services/auth.service";

@Injectable({
    providedIn: 'root'
})

export class UserObjectUpdateService {

    constructor( private authService: AuthService){}

    updateUserEmail = new Subject<string>()


    userObject: UserObject = new UserObject (null , null , null , new Date())

    userEmail: string = this.userObject.email


    changingUserObject: Subscription = this.authService.userObjectUpdate
                                           .subscribe((userObjectFromLogin)=> {
                                            this.userEmail = userObjectFromLogin.email
                                            this.userObject = userObjectFromLogin
                                            console.log(this.userEmail)
                                            this.updateUserEmail.next(this.userEmail)
                                           })






}