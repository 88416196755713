import { Component, Input, OnInit } from '@angular/core';
import { RangeInfo } from '../range-info.model';

@Component({
  selector: 'app-inner-rating-item',
  templateUrl: './inner-rating-item.component.html',
  styleUrls: ['./inner-rating-item.component.css']
})
export class InnerRatingItemComponent implements OnInit {


  @Input() rangeInfo: RangeInfo
  @Input() isEditing: boolean 

  constructor() { }

  ngOnInit(): void {
  }

}
