<!-- <p>single-drop-down-select-item works!</p> -->

<mat-card class="mat-elevation-z0"> 
<div class="row">
    <div class="col-xs-12">
      <div class="form-group">
      
         <div  class="row">
          <div class="col-xs-1 pointer">
            <div *ngIf="weAreInMultiFeatureEdit" >
              <svg  (click)="openDialogSingleDropDownselectEdit()" style="color: lightgrey" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
              </svg>
             </div>
           </div>
           <div class="col-xs-7"></div>
           <div class="col-xs-1 pointer">
            <div *ngIf="weAreInMultiFeatureEdit" >
             <svg  (click)="onDeleteSingleDropDownSelectItem()" style="color: lightgrey" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
             </svg>
            </div>
          </div>
           <div class="col-xs-1"></div>
         </div>
      
                
      </div>
    </div>
  </div>

<div class="row">
    <div class="col-xs-12">

                        <mat-form-field style="width: 500px" appearance="outline">
                            <mat-label> {{singleDropDownSelect.description}} </mat-label>
                            <mat-select (selectionChange)="selectDescription( $event)"  >

                                        <mat-option *ngFor="let content of singleDropDownSelect.contents" [value]="content" >
                                            {{content}}
                                        </mat-option>
                            </mat-select>

                        </mat-form-field>
           
    </div>
</div>

<hr *ngIf="weAreInMultiFeatureEdit">

</mat-card>
