import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-delete-shared-calendar-status-item-ranges',
  templateUrl: './delete-shared-calendar-status-item-ranges.component.html',
  styleUrls: ['./delete-shared-calendar-status-item-ranges.component.css']
})

export class DeleteSharedCalendarStatusItemRangesComponent implements OnInit {

  user: string 

  dateRanges: any[]
  arrayOfDateNumbers: number[]

  datesList = []
  removable = true;

  rulesForm: FormGroup;
  fb: FormBuilder = new FormBuilder();


  constructor( private dialogRef: MatDialogRef<DeleteSharedCalendarStatusItemRangesComponent>,
               @Inject(MAT_DIALOG_DATA) public editData: any ) { }

  ngOnInit(): void {

    this.dateRanges = this.editData.dateRanges 

    console.log( ' date ranges on delete pop up => ',this.dateRanges)
    this.dateRanges = this.onReorderDateRangesColors(this.dateRanges) 

    // this.dateRanges.sort((a, b) => a.start - b.start)


    this.arrayOfDateNumbers = []

    const newArrayOfDateNumbers: number[] = []
          for ( let i=0; i<this.dateRanges.length ; i++ ){
            newArrayOfDateNumbers.push(i)
          }
    this.arrayOfDateNumbers = newArrayOfDateNumbers

    console.log( this.arrayOfDateNumbers)

    this.rulesForm = this.fb.group({
      dateRanges: this.fb.array( [] , [this.validateArrayNotEmpty]),
     });
  }

  removeDateRange(dateRange: any , i: number ){

    console.log(dateRange, i )

    const newStart = dateRange.start 
    const newEnd = dateRange.end 
    const newStartingIndex = dateRange.startingIndex
    const newColor = 'range6'

    const newDateRange: any = { start: newStart, end: newEnd, startingIndex: newStartingIndex , color: newColor }

    this.dateRanges[i] = newDateRange

  }

  confirmRemoveDateRange(i){

     this.dateRanges.splice(i, 1)
     this.arrayOfDateNumbers.splice(i, 1)

     console.log(this.dateRanges, this.arrayOfDateNumbers )


  }

  onConfirmDeleteRangeAndCloseDialog(){

    const arrayOfRemainingDateRanges: number[] = []
    for (let i=0; i< this.dateRanges.length; i++ ){
      if ( this.dateRanges[i].startingIndex !== undefined){
        arrayOfRemainingDateRanges.push(this.dateRanges[i].startingIndex)
      }
    } 
    arrayOfRemainingDateRanges.sort((a, b) => a - b)

    this.dialogRef.close( arrayOfRemainingDateRanges )
  }

  private validateArrayNotEmpty(c: FormControl) {
    if (c.value && c.value.length === 0) {
      return {
        validateArrayNotEmpty: { valid: false }
      };
    }
    return null;
  }

  onReorderDateRangesColors(dateRanges: any[]): any[]{

    
    for (let i=0; i < dateRanges.length ; i++){

         const presentDate = new Date()
               presentDate.setHours(0, 1, 0, 0); // set to 1 minute so that is always included into a range
               
        dateRanges[i] = { start: dateRanges[i].start, end: dateRanges[i].end, startingIndex: dateRanges[i].startingIndex, color: 'range1' }

        console.log(presentDate)
     
         if ( dateRanges[i].start < presentDate ){ 
                if ( dateRanges[i].end < presentDate ){ dateRanges[i].color = 'range2' }
                if ( dateRanges[i].end > presentDate ){ dateRanges[i].color = 'range3' }
          }
      
         if ( dateRanges[i].start > presentDate ){ dateRanges[i].color = 'range5' }

         if ( this.isDateInRange(presentDate, dateRanges[i].start, dateRanges[i].end )){ 
              dateRanges[i].color = 'range3'
         }
    }
  

    return dateRanges

  }

  isDateInRange(date: Date, start: Date, end: Date): boolean {
    // Adjust the comparison to consider the time component as well
    const startDate = new Date(start.getFullYear(), start.getMonth(), start.getDate());
    const endDate = new Date(end.getFullYear(), end.getMonth(), end.getDate());
  
    return date >= startDate && date <= endDate;
  }

}
