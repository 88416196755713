<app-loading-spinner *ngIf="isLoadingList && !error"></app-loading-spinner>

<div *ngIf="error">
     <br>
     <div *ngIf="error && !isLoadingList" 
          class="alert alert-danger">
          <h1>OOOPS ! Check Network Connection</h1>
                <p>{{error}}</p>
      </div>
</div>

<mat-card *ngIf="!isLoadingList && !error" class="mat-elevation-z0"> 

    <mat-tab-group #tabs 
     [(selectedIndex)]="selectedIndex"
     class="remove-border-bottom"
     header-less-tabs
     (selectedTabChange)="onLinkClick($event)"    
     headerPosition="below" 
     animationDuration="0ms" >

    

           <mat-tab *ngFor="let singleDate of singleDateCalendars; index as i ">

               <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

                <app-calendar-item  *ngIf="!isLoading"
                          [isTabItem]="false"
                          [isParagraphEdit]="isParagraphEdit"
                          [feature]="feature_from_paragraphEdit"
                          [index_id]="singleDate._id"
                          [noNeeded]="false"  
                          [subscriptionToEditComponentItemIsOn]="subscriptionToEditComponentItemIsOn"
                          [editFeatureItem]="clickOnEditFeatureItem.asObservable()"
                           >

                </app-calendar-item>

                <br>



                     <ng-template mat-tab-label>
                     <a style="cursor: pointer">
                          {{i+1}} - {{singleDate.name}} </a>
                     </ng-template>
                   

               
        
           </mat-tab>  
           
           <div *ngIf="createButtonLimit">
               <mat-tab disabled > 
                    <ng-template  mat-tab-label>
                         <a (click)="onCreate()"> create new </a>
                    </ng-template>
                </mat-tab>
           </div>

           <div *ngIf="singleDateCalendars.length > 1">
               <div *ngIf="createButtonLimit">
                   <mat-tab disabled > 
                        <ng-template  mat-tab-label>
                             <a> create copy of </a>
                        </ng-template>
                    </mat-tab>
               </div>
               </div>

           

    </mat-tab-group>

</mat-card>
