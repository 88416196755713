import { Injectable } from '@angular/core';
import { Observable , Subject, BehaviorSubject } from 'rxjs';
import { CdkDragDrop, moveItemInArray, copyArrayItem  , transferArrayItem } from '@angular/cdk/drag-drop';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private subject = new Subject<any>();
  index : number ;

// click event between siblings 

sendClickEvent(){
this.subject.next();
}

getClickEvent(): Observable <any> {
return this.subject.asObservable ();
}




// messages between component 

private message = new BehaviorSubject('ciao');
  sharedMessage = this.message.asObservable();

  constructor() { }

  nextMessage(message: string) {
    this.message.next(message)
  }




// drag and drop between components 

 public drop(event: CdkDragDrop<any[]>) {

  if (event.previousContainer === event.container) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  } else {
   copyArrayItem(event.previousContainer.data,
    event.container.data,
    event.previousIndex,
    event.currentIndex);
    
}
  
  console.log(event.previousContainer.data);
  console.log(event.container.data);
  
  

}

 
}



