<!-- <p>chat-form-result-list works!</p> -->

<div *ngIf="isLoading">
    is Loading 

</div>


<div #list style="height: 600px; overflow-y: scroll" *ngIf="!isLoading">
<!-- <div #list style="height: 600px; overflow-y: scroll" *ngIf="!isLoading"> -->
    <div *ngFor="let formResult of formResults_Displaying.formResults_displayed; let i=index">
        <app-chat-form-result-item 
        [formResult]="formResult"
        (latestFormResultIsBeenAdded)="onANewFormResultHasArrived()">
        </app-chat-form-result-item>
       
    </div>
</div>


