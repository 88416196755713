import { Injectable } from "@angular/core";
import { GetComponentsHttpRequestService } from "../gets-services/get-components-http-request.sevice";
import { RecipientService } from "src/app/forms/form-list/form-item/recipient/recipient.service";
import { RecipientIndexService } from "src/app/forms/form-list/form-edit/recipient-index-list/recipient-index.service";
import { RecipientIndex } from "src/app/forms/form-list/form-edit/recipient-index-list/recipient-index.model";
import { Recipient } from "src/app/forms/form-list/form-item/recipient/recipient.model";
import { RecipientIndexListItemService } from "src/app/forms/form-list/form-edit/recipient-index-list/recipient-index-list-item.service";
import { RecipientIndexListItem } from "src/app/forms/form-list/form-edit/recipient-index-list/recipient-index-list-item.model";
import { CreateUpdateMultiComponentsService } from "../shared-services/create-update-multi-components.service";
import { RecipientFeatureService } from "src/app/forms/form-list/form-edit/recipient-index-list/recipient-feature.service";
import { Subject } from "rxjs";
import { StorePersonalFeatureIndexComponentsService } from "../store-initial-components/store-personal-feature-index-components.service";
import { RecipientIndexColumn } from "src/app/forms/form-list/form-edit/recipient-index-list/recipient-index-column.model";
import { RecipientIndexColumnService } from "src/app/forms/form-list/form-edit/recipient-index-list/recipient-index-column.service";


@Injectable ({
    providedIn: 'root' 
})

export class UpdateRecipientFeatureService {

    constructor( private getComponentsHttpRequestService: GetComponentsHttpRequestService , 
                 private recipientService: RecipientService , 
                 private recipientIndexService: RecipientIndexService ,
                 private recipientIndexListItemService: RecipientIndexListItemService , 
                 private createUpdateMultiComponentsService: CreateUpdateMultiComponentsService , 
                 private recipientFeatureService: RecipientFeatureService ,
                 private storePersonalFeatureIndexComponentsService: StorePersonalFeatureIndexComponentsService , 
                 private recipientIndexColumnService: RecipientIndexColumnService
        ){}

    /**
     * have to re-assemble recipientFeature recipient_ids 
     * have to re-assemble shared-recipient-index-list-item recipientIndex_ids 
     * have to update the shared recipient list 
     * 
     * have to delete the recipients that are not visible anymore , in case one is out 
     * to do so, it has to compare the actual recipients in use and the new ones 
     * and check which one is not there anymore 
     * call the recipient service and initial service and cancel the ones that left 
     */

    /**
     * steps:
     *  1- console.log current rcipientFeature 
     *  2- call new updated recipientFeature + childs 
     *  3- compare recipients list 
     *  4- cancel the ones not there 
     *  5- check if the respective recipientIndexes were stored in shared/preferred formItemContainers 
     *  6- if so do something 
     *  
     *  7- create new recipientIndexes of new recipients 
     *  8- assemble new recipientIndexListItems.recipientIndex_ids list 
     *  9- update current recipients to  show 
     * 
     * 
     */

    getRecipientFeatureWithChilds( userEmail: string , 
                                   currentRecipientFeature_Recipients_ids: string[] , 
                                   recipientFeature_id: string ){

        //  console.log(userEmail, currentRecipientFeature_Recipients_ids, recipientFeature_id)

         this.getComponentsHttpRequestService.httpGetUserComponentWithChildsBy_id(recipientFeature_id)
             .subscribe((recipientFeaturePlusChilds: any)=>{
        //      console.log(recipientFeaturePlusChilds)

              const newRecipientFeature_Recipient_ids: string[] = recipientFeaturePlusChilds.receivedRecipient_ids

          /**
           * update recipientFeature on its service ( so next time it has the updated recipients)
           */
          this.recipientFeatureService.recipientFeatures[0].receivedRecipient_ids = newRecipientFeature_Recipient_ids


          this.compareRecipientsList_Current_New_RecipientFeatures( userEmail,
                                                                    currentRecipientFeature_Recipients_ids , 
                                                                    newRecipientFeature_Recipient_ids ,
                                                                    recipientFeaturePlusChilds
                                                                    )
        })
    }


    updateShareRecipientColumnNotExistingNow = new Subject<void>()
    compareRecipientsList_Current_New_RecipientFeatures( userEmail: string ,
                                                         currentRecipientFeature_Recipients_ids: string[] ,
                                                         newRecipientFeature_Recipient_ids: string[],
                                                         newRecipientFeaturePlusChilds: any ){
        /**
         *  cases 
         * 1- array of new recipients is 0 , speed to set recipientListItems recipients list to 0 and update
         * 2- array A.length !== Array B.length
         * 3- array A and B are the same length && !== 0 // do the recipients are the same ? => JSON stringify 
         * 
         * 
         */

        if ( newRecipientFeature_Recipient_ids.length === 0){
            /**
             * go directly to recipientIndexListItem and set RecipientIndex_ids_list to 0 
             * update shareRecipients ( in case it just came back to 0 length)
             * in this case the share recipient column shouldn't be visible 
             */

            const currentRecipientIndexListItems: RecipientIndexListItem[] = this.recipientIndexListItemService.getRecipientIndexListItems()
            const currentRecipientIndexListItem: RecipientIndexListItem = currentRecipientIndexListItems.find(x=> x.UIID === 'sharedRecipientIndexListItem-2')
            const newRecipientIndexListItem: RecipientIndexListItem = currentRecipientIndexListItem 
                  /**
                   * giving empty and null data childs to sharedRecipientIndexListItem
                   */
                  newRecipientIndexListItem.recipientIndexList_ids = []
                  newRecipientIndexListItem.recipientIndexSelected_id = null 
            this.recipientIndexListItemService.updateWorkingRecipientIndexListItem(newRecipientIndexListItem)

            /**
             * check if our own recipientIndexes has changed also
             */
            this.checkRecipient_RecipientIndex_ListItems_Columns_Update(userEmail , newRecipientFeature_Recipient_ids)

            /**
             * return sharedRecipientColumn the empty 
             */
            this.updateShareRecipientColumnNotExistingNow.next()

        }

        if ( newRecipientFeature_Recipient_ids.length > 0 ){
              
               if ( currentRecipientFeature_Recipients_ids.length !== newRecipientFeature_Recipient_ids.length){
                   console.log('DIFFERENT LENGTH THAN BEFORE')
                   this.createFullRecipientsModels(userEmail, newRecipientFeature_Recipient_ids, newRecipientFeaturePlusChilds)
               }

              /** 
               * IF the array of strings are the same length check if the sequance is the same 
               */
              if ( currentRecipientFeature_Recipients_ids.length === newRecipientFeature_Recipient_ids.length ){
                console.log('SAME LENGTH THAN BEFORE')
                if ( JSON.stringify(currentRecipientFeature_Recipients_ids) !== JSON.stringify(newRecipientFeature_Recipient_ids) ){
                    // build again the new recipients 
                    console.log( 'starting comparing : ', currentRecipientFeature_Recipients_ids , newRecipientFeature_Recipient_ids  )
                    this.createFullRecipientsModels(userEmail, newRecipientFeature_Recipient_ids, newRecipientFeaturePlusChilds)
                }
 
                if ( JSON.stringify(currentRecipientFeature_Recipients_ids) === JSON.stringify(newRecipientFeature_Recipient_ids)){
                     console.log( 'NO CHANGES , the shared recipient_ids list are still the same')
                     this.checkRecipient_RecipientIndex_ListItems_Columns_Update(userEmail , newRecipientFeature_Recipient_ids)
                }
              }


        }

        
        /**
         * check if the newRecipientFeature is different than the previous one 
         *  IF previous is nothing and the current has recipients in 
         *  IF previous has recipients and current doesn't have // remove recipients from service 
         *  IF they both have , first check which one to remove and then which ones to add 
         */

    } 

    /**
     * this is in case no new shared recipients , but maybe new recipients from the same user 
     * in a second or third open account in different web Tab
     */
    checkRecipient_RecipientIndex_ListItems_Columns_Update(userEmail: string , newRecipientFeature_Recipient_ids: string[]){

        const currentAvailableRecipientIndexes: RecipientIndex[] = this.recipientIndexService.getRecipientIndexes()
    
        this.storePersonalFeatureIndexComponentsService.getPersonalUpdatedFeatureIndex_components(userEmail)
            .subscribe((res: any)=>{
            //    console.log(res)

                const httpFeatureIndexComponentResult: any = res 
                const newRecipientIndexes: RecipientIndex[] = httpFeatureIndexComponentResult.recipientIndex 

                /**
                 * whether is changd or not, keep updating everything in case something has changed 
                 * like a name of the index or if recipient emails has been added or deleted 
                 * we should do it for all feature-index components + recipients
                 */
              
                    /**
                     * check recipinetIndexListItems
                     * check recipientColumns
                     * call recipients to add latest recipients 
                     */
                    const newArrayRecipientIndexColumns: RecipientIndexColumn[] = httpFeatureIndexComponentResult.recipientIndexColumn
                    const newArrayRecipientIndexListItems: RecipientIndexListItem[] = httpFeatureIndexComponentResult.recipientIndexListItem 

                    this.getComponentsHttpRequestService.httpGetComponentsByType(userEmail, 'recipient')
                        .subscribe((updatedRecipientResult: any )=>{

                            const updatedRecipientList: Recipient[] = updatedRecipientResult.recipient 

                            for (let i=0; i< updatedRecipientList.length ; i++){
                                this.recipientService.addRecipient(updatedRecipientList[i])
                            }

                            this.recipientIndexColumnService.updateRecipientIndexColumns(newArrayRecipientIndexColumns)
                            this.recipientIndexService.updateRecipientIndexes(newRecipientIndexes) 


                  /**
                   * only update personal recipientIndexListItems , leave shared as it is 
                   * 
                  */
                 const indexOfSharedRecipientIndexListItem: number = newArrayRecipientIndexListItems.findIndex(x=> x.UIID === 'sharedRecipientIndexListItem-2')
                 const currentSharedRecipientIndexListItem: RecipientIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItemBy_UIID('sharedRecipientIndexListItem-2')

                 newArrayRecipientIndexListItems[indexOfSharedRecipientIndexListItem] = currentSharedRecipientIndexListItem 
                 
                 this.recipientIndexListItemService.updateRecipientIndexListItems(newArrayRecipientIndexListItems)
                 console.log('UPDATING ALL RECIPIENT INDEX COLUMNS/ LISTITEMS ETC ')
         
                 this.recipientIndexListItemService.onJustUpdateRecipientsInGenerals()

                       
                }) // close subscription 


        }) // close subscription 


    }

    createFullRecipientsModels( userEmail: string , newRecipientFeature_Recipient_ids: string[], recipientFeaturePlusChilds: any){

         /**
         * adding all new recipients to service no matter if already exist or not ( the service will check it by itself)
         */
        const newSharedRecipients: Recipient[] = []

        for ( let i=0; i<recipientFeaturePlusChilds.childList.length ; i++){
        const new_recipient_id = recipientFeaturePlusChilds.childList[i]._id
        const newComponentName = 'recipient'
        const newUIID = recipientFeaturePlusChilds.childList[i].UIID
        const newOwner = recipientFeaturePlusChilds.childList[i].owner
        const newDateAndTime = recipientFeaturePlusChilds.childList[i].dateAndTime
        const newUpdateDateAndTime = recipientFeaturePlusChilds.childList[i].updateDateAndTime
        const newChat_roomid = recipientFeaturePlusChilds.childList[i].chatRoomId
        const newAllUsersAllowed = recipientFeaturePlusChilds.childList[i].allUsersAllowed
        const newUserListAllowed = recipientFeaturePlusChilds.childList[i].userListAllowed
        const newBlockedUserList = recipientFeaturePlusChilds.childList[i].blockedUserList
        const newName = recipientFeaturePlusChilds.childList[i].name 
        const newRecipientList = recipientFeaturePlusChilds.childList[i].recipientList
        const newToMessage = recipientFeaturePlusChilds.childList[i].recipientList
        const newToMail = [];
        const newCcmail = [];
        const newBccMail = [];
        const newGreeting = '' ;

        const newRecipient = new Recipient(new_recipient_id, newComponentName, newUIID, newOwner, newDateAndTime , newUpdateDateAndTime, 
                                           newChat_roomid, newAllUsersAllowed, newUserListAllowed, newBlockedUserList , 
                                           newName, newRecipientList, newToMessage, newToMail, newCcmail, newBccMail, newGreeting   )


        newSharedRecipients.push(newRecipient) 
        console.log(newSharedRecipients)
        /**
         * adding all new recipients 
         */
        this.recipientService.addRecipient(newRecipient)

            if ( i == recipientFeaturePlusChilds.childList.length-1){
               
                this.checkExistingAndLatestUpdateOnRecipientIndexes(userEmail, newSharedRecipients , newRecipientFeature_Recipient_ids, recipientFeaturePlusChilds)
            }

        }
    }


    /**
     * 
     * @param userEmail 
     * @param new_Recipients 
     * @param newRecipientFeature_Recipient_ids 
     * @param newRecipientFeaturePlusChilds 
     */
    checkExistingAndLatestUpdateOnRecipientIndexes( userEmail: string , new_Recipients: Recipient[], newRecipientFeature_Recipient_ids: string[], newRecipientFeaturePlusChilds: any){

        const currentAvailableRecipientIndexes: RecipientIndex[] = this.recipientIndexService.getRecipientIndexes()

        /**
         * now get latest update on recipientIndexes and recipientIndexListItems and columns 
         *  IF we have more recipientIndexes than "currentAvailableRecipientIndexes" , than we 
         *  store the new items 
         * 
         *  IF the length of the recipientIndexes is the same , then we just continue normally 
         */
        this.storePersonalFeatureIndexComponentsService.getPersonalUpdatedFeatureIndex_components(userEmail)
            .subscribe((res: any)=>{
                console.log(res)

                const httpFeatureIndexComponentResult: any = res 
                const newRecipientIndexes: RecipientIndex[] = httpFeatureIndexComponentResult.recipientIndex 

                if ( currentAvailableRecipientIndexes.length === newRecipientIndexes.length ){ this.checkExistingRecipientsOrBuildNewSecondRecipients_Plus_RecipientIndex(userEmail, new_Recipients , newRecipientFeature_Recipient_ids, newRecipientFeaturePlusChilds) }

                if ( currentAvailableRecipientIndexes.length !== newRecipientIndexes.length ){
                    /**
                     * check recipinetIndexListItems
                     * check recipientColumns
                     * call recipients to add latest recipients 
                     */
                    const newArrayRecipientIndexColumns: RecipientIndexColumn[] = httpFeatureIndexComponentResult.recipientIndexColumn
                    const newArrayRecipientIndexListItems: RecipientIndexListItem[] = httpFeatureIndexComponentResult.recipientIndexListItem 

                    this.getComponentsHttpRequestService.httpGetComponentsByType(userEmail, 'recipient')
                        .subscribe((updatedRecipientResult: any )=>{

                            const updatedRecipientList: Recipient[] = updatedRecipientResult.recipient 

                            this.recipientIndexColumnService.updateRecipientIndexColumns(newArrayRecipientIndexColumns)
                            this.recipientIndexListItemService.updateRecipientIndexListItems(newArrayRecipientIndexListItems)
                            this.recipientIndexService.updateRecipientIndexes(newRecipientIndexes)
                        
                            console.log('UPDATING ALL RECIPIENT INDEX COLUMNS/ LISTITEMS ETC ')
                        
                            for (let i=0; i< updatedRecipientList.length ; i++){
                                 this.recipientService.addRecipient(updatedRecipientList[i])
                            }
                            /**
                             * only after storing all updated recipinetIndexes and recipients 
                             * we can be sure we are comparing the right amount of recipinet indexes 
                             */
                            this.checkExistingRecipientsOrBuildNewSecondRecipients_Plus_RecipientIndex(userEmail, new_Recipients , newRecipientFeature_Recipient_ids, newRecipientFeaturePlusChilds)

                        })

                 }




            })

    }


    checkExistingRecipientsOrBuildNewSecondRecipients_Plus_RecipientIndex( userEmail: string , new_Recipients: Recipient[], newRecipientFeature_Recipient_ids: string[], newRecipientFeaturePlusChilds: any){

        const currentAvailableRecipientIndexes: RecipientIndex[] = this.recipientIndexService.getRecipientIndexes()
        /**
         * here check actually the real possible latest updated recipientIndex by calling the featureIndex and check 
         * latest recipientIndexes ( especially the one with is not our recipient )
         */

        const nonPresentRecipients: Recipient[] = []

        for ( let i=0; i<new_Recipients.length ; i++ ){

            if ( currentAvailableRecipientIndexes.findIndex(x=> x.recipient_id === new_Recipients[i]._id) === -1 ){
                nonPresentRecipients.push(new_Recipients[i])

            }
            if (i === new_Recipients.length-1 ){
                console.log('NON PRESENT RECIPIENTS LIST = ', nonPresentRecipients)
                if( nonPresentRecipients.length === 0){

                    console.log('there are no RecipientIndex to build, we are adding existing recipient index to recipientIndexListItem')
                    const currentSharedRecipientIndexListItem: RecipientIndexListItem = this.recipientIndexListItemService.recipientIndexListItems.find(x=>x.UIID === 'sharedRecipientIndexListItem-2')
                    currentSharedRecipientIndexListItem.recipientIndexList_ids = []
                   /**
                    * new loop to add all new RecipientIndexes._ids to recipientIndexListItem
                    */
                    for (let i=0; i< newRecipientFeature_Recipient_ids.length ; i++){
                    console.log('for loop before getting personale base comps ')

                    const recipientIndex_id_to_add: string = this.recipientIndexService.recipientIndexes.find(x=> x.recipient_id === newRecipientFeature_Recipient_ids[i])._id
                    currentSharedRecipientIndexListItem.recipientIndexList_ids.push(recipientIndex_id_to_add)

                    if ( i === 0){ currentSharedRecipientIndexListItem.recipientIndexSelected_id = recipientIndex_id_to_add  }
                    if ( i === newRecipientFeature_Recipient_ids.length-1){ 

                    this.recipientIndexListItemService.updateWorkingRecipientIndexListItem(currentSharedRecipientIndexListItem) 
                   /**
                    * update share recipients list items 
                   */
                   this.updateSharedRecipientColumn_Exist.next()
                      } // end IF statement
                    } // end FOR loop
                }
                if (nonPresentRecipients.length > 0){
                    console.log('NON PRESENT RECIPIENTS LIST To ADD = ', nonPresentRecipients)
                    this.buildRemainingNewRecipients_beforeLoopStartToProduceIndexes( userEmail , nonPresentRecipients, newRecipientFeature_Recipient_ids, currentAvailableRecipientIndexes, newRecipientFeaturePlusChilds )
                }

            }
        }

    };

    buildRemainingNewRecipients_beforeLoopStartToProduceIndexes( userEmail: string ,
                                                                 nonPresentRecipients: Recipient[] ,
                                                                 newRecipientFeature_Recipient_ids: string[],
                                                                 currentAvailableRecipientIndexes: RecipientIndex[],
                                                                 recipientFeaturePlusChilds: any
                                                                 ){
        
               const recipientIndexListItems: RecipientIndexListItem[] = this.recipientIndexListItemService.getRecipientIndexListItems()
               const recipientIndexes_length: number = currentAvailableRecipientIndexes.length
               this.startLoopToProduceNewSharedRecipientsParents(0, userEmail, nonPresentRecipients, newRecipientFeature_Recipient_ids, currentAvailableRecipientIndexes, recipientIndexes_length, recipientIndexListItems   )

    };

    /**
 * start a loop to produce one by one the new recipientIndexes and pareents to be added to 
 * shared recipientIndexColumn 
 * @param userEmail 
 * @param newRecipientsIds 
 * @param currentRecipientIndexesLength 
 * @param current 
 */
startLoopToProduceNewSharedRecipientsParents( loopNumber: number , 
                                              userEmail: string , 
                                              newMissingSharedRecipients_Only: Recipient[],  
                                              newRecipientFeature_Recipient_ids: string[], 
                                              recipientIndexes: RecipientIndex[],
                                              currentRecipientIndexesLength: number , 
                                              currentRecipientIndexListItems: RecipientIndexListItem[]){

                        /**
                         * 
                         * prepare the multiComponentFile Starting from recipientIndex with the list of recip_id 
                         * and loop from each new recipient_id
                         * */
                        const totalNewSharedRecipientsLength: number = newMissingSharedRecipients_Only.length 
                        const newSharedRecipient_id: string = newMissingSharedRecipients_Only[loopNumber]._id

                        const newSharedRecipientsToAdd_Ids: string[] = []
                        for ( let i=0; i<newMissingSharedRecipients_Only.length ; i++){
                            newSharedRecipientsToAdd_Ids.push(newMissingSharedRecipients_Only[i]._id)
                        }

/**
* second recipient UIID we have to keep in mind some recipients might not be visible anymore if cancelled
* or if we have been outed from a chat ...
* SO THE ONLY WAY TO CHECK IS FROM THE RCIPIENTINDEXES EVER CREATED !
* each recipientIndex hold 1 of our recipients 
* 
*/

                        const newSecondRecipient_UIID: string = 'secondRecipient-'+(currentRecipientIndexesLength+1).toString()
                        const newRecipientIndex_UIID : string = 'sharedRecipientIndex-'+(currentRecipientIndexesLength+1).toString()


                        console.log(totalNewSharedRecipientsLength , newSharedRecipient_id, newSecondRecipient_UIID,  newRecipientIndex_UIID)

                        this.createSecondRecipient_CreateRecipinetIndex(  loopNumber , 
                                                                          totalNewSharedRecipientsLength , 
                                                                          newMissingSharedRecipients_Only , 
                                                                          newRecipientFeature_Recipient_ids ,
                                                                          userEmail,  
                                                                          newSharedRecipient_id,  
                                                                          newSecondRecipient_UIID, 
                                                                          recipientIndexes, 
                                                                          newRecipientIndex_UIID, 
                                                                          currentRecipientIndexesLength,  
                                                                          currentRecipientIndexListItems )
};

updateSharedRecipientColumn_Exist = new Subject<void>()

createSecondRecipient_CreateRecipinetIndex( loopNumber: number ,  
                                            totalNewSharedRecipientsLength: number , 
                                            newMissingSharedRecipients_Only: Recipient[] , 
                                            newRecipientFeature_Recipient_ids: string[], 
                                            userEmail: string , 
                                            sharedRecipient_id: string ,  
                                            secondRecipient_UIID: string, 
                                            recipientIndexes: RecipientIndex[],
                                            recipientIndex_UIID: string , 
                                            currentRecipientIndexesLength: number, 
                                            currentRecipientIndexListItems: RecipientIndexListItem[]  ){

                this.createUpdateMultiComponentsService.create_SecondRecipient_RecipientIndex( userEmail, sharedRecipient_id ,
                                                       secondRecipient_UIID ,recipientIndex_UIID )
                                                       .subscribe(
                                                       (res: any )=> {
                                                        console.log(res)
// console.log('LOOP NUMBER = ' , loopNumber , "totalNewSharedRecipientsLength = ", totalNewSharedRecipientsLength )

                                const newLoopNumber: number = loopNumber+1
                                console.log('LOOP NUMBER after add+1 = ' , newLoopNumber, "totalNewSharedRecipientsLength = ", totalNewSharedRecipientsLength )

                                /**
                                 * from here check if continue the loop or move on 
                                 */
                                const newRecipientIndex: RecipientIndex = res.featureIndex[0] // directly add to recipientIndexes here now 
                                /**
                                 * adding second recipient to service 
                                 */
                                const newSecondRecipient: Recipient = res.recipient[0]
                                this.recipientService.addRecipient(newSecondRecipient)
                                

                                this.recipientIndexService.addRecipientIndex(newRecipientIndex)

                                
                                const newRecipientIndexes: RecipientIndex[] = this.recipientIndexService.getRecipientIndexes()
                                console.log(newRecipientIndexes)

                                 const newCurrentRecipientIndexesLength: number = currentRecipientIndexesLength+1

                                 if ( newLoopNumber === totalNewSharedRecipientsLength){

                                     const currentSharedRecipientIndexListItem: RecipientIndexListItem = currentRecipientIndexListItems.find(x=>x.UIID === 'sharedRecipientIndexListItem-2')
                                     currentSharedRecipientIndexListItem.recipientIndexList_ids = []
                                    /**
                                     * new loop to add all new RecipientIndexes._ids to recipientIndexListItem
                                     */
                                     for (let i=0; i< newRecipientFeature_Recipient_ids.length ; i++){
                                     console.log('for loop before getting personale base comps ')

                                     const recipientIndex_id_to_add: string = newRecipientIndexes.find(x=> x.recipient_id === newRecipientFeature_Recipient_ids[i])._id
                                     currentSharedRecipientIndexListItem.recipientIndexList_ids.push(recipientIndex_id_to_add)

                                     if ( i === 0){ currentSharedRecipientIndexListItem.recipientIndexSelected_id = recipientIndex_id_to_add  }
                                     if ( i === newRecipientFeature_Recipient_ids.length-1){ 

                                     console.log( 'UPDATING SHARE COLUMN + RECIP_LIST_ITEM,recipIndex_ids')
                                     this.recipientIndexListItemService.updateWorkingRecipientIndexListItem(currentSharedRecipientIndexListItem) 
                                    /**
                                     * update share recipients list items 
                                    */
                                    this.updateSharedRecipientColumn_Exist.next()
                                       } // end IF statement
                                     } // end FOR loop

                                    // this.continueToSetEmptyPersonalComponents(userEmail , sharedRecipients )

                                } // END IF statement 

                                if ( newLoopNumber < totalNewSharedRecipientsLength){
                                    console.log( 'CONTINUE LOOPING N.=', newLoopNumber)
                                    this.startLoopToProduceNewSharedRecipientsParents(newLoopNumber, userEmail, newMissingSharedRecipients_Only, newRecipientFeature_Recipient_ids, newRecipientIndexes, newCurrentRecipientIndexesLength, currentRecipientIndexListItems  )
                                } // END IF statement 

                }, error =>{
                   console.log('an error occurred pop up ', error )
                })


};





}