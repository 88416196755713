<!-- <p>fix-layout-edit-number works!</p> -->

<div class="row">
    <div class="col-xs-12">
        <form [formGroup]="fixLayoutEditNumberForm">

            <div class="row">
                <mat-form-field>
                    <mat-label>label</mat-label> 
                    <input 
                       matInput 
                       placeholder='example'
                       type="string"
                       id="label"
                       formControlName="label" >
                    <mat-hint > here is the "hint"  </mat-hint>
                </mat-form-field>
            </div>
           
            <div class="row">
                <mat-form-field>
                    <mat-label>word</mat-label> 
                    <input 
                       matInput 
                       placeholder='example'
                       type="number"
                       id="number"
                       formControlName="number" >
                    <mat-hint > here is the "hint"  </mat-hint>
                </mat-form-field>
            </div>

            <br>

            <div class="row">
                <button (click)="onSumbitNumber()"  class="btn btn-primary">confirm</button>
            </div>

        </form>
    </div>
</div>
