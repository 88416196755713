import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Tab } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/tabs/tab-list/tab.model";
import { TabService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/tabs/tab-list/tab.service";
import { GetComponentsHttpRequestService } from "../gets-services/get-components-http-request.sevice";
import { AdviseIfHttpStoreComponentsCallHasBeenMadeService } from "../other-components-services/advise-if-http-store-components-has-been-made.service";
import { StoreBaseComponentsService } from "./store-base-components.service";
import { StoreTabItemPlusChildsConfirmationService } from "./store-tab-item-plus-childs-confirmation.service";

@Injectable ({
    providedIn: 'root' 
})

export class StoreTabComponentsService{

    constructor ( 
                  private getComponentsHttpRequestService: GetComponentsHttpRequestService ,
                  private adviseIfHttpStoreComponentsCallHasBeenMadeService: AdviseIfHttpStoreComponentsCallHasBeenMadeService ,
                  private storeTabItemPlusChildsConfirmationService: StoreTabItemPlusChildsConfirmationService , 
                  private storeBaseComponentsService: StoreBaseComponentsService , 
                  private tabService: TabService
                ){}

/**
 * we are going to make 2 calls : one for starter where all the components are empty "[ ]"
 * and a second one , only when the page is called , where we really get the list of components 
 * stored in the server with an http call 
 */


//  tabsChanged = new Subject<Tab[]>()
 
 
 updatePersonalTabsComponents(userEmail: string ){
 
         this.getComponentsHttpRequestService.httpGetComponentsByType(userEmail, 'tabs')
 
                    .subscribe( (res: any) => {
                     console.log(res)
 
                     const newTabList: Tab[] = res.tab
                     console.log(newTabList)
                     this.tabService.addNewTabs(newTabList)
 
                     /**
                      * advise that the base/mullti/tab features have been updated 
                      */
                     this.adviseIfHttpStoreComponentsCallHasBeenMadeService.turnBaseMultiFeatureTabsComponentsHasBeenStoredToTrue()
 
 
                    })
 
 }

 getPersonalTab_withChilds_by_id(userEmail: string , tab_id: string ){

    this.getComponentsHttpRequestService.httpGetUserComponentWithChildsBy_id(tab_id)
        .subscribe((tabResult_withChilds: any )=>{

            console.log(tabResult_withChilds)
            
            this.storeTabItemPlusChildsConfirmationService.composeTabComponentPlusChilds_idsList_plusStatus(tabResult_withChilds)
            
             /**
             * the multiFeature itself has been already stored so we can send this item first // just in case there are no childs it won't stay on loading
             */
            this.storeTabItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenTabItemPlusChildsAreStored(tabResult_withChilds._id)
            
            /**
             *  scompose childs on storeSingleBase service 
             */
            
            const featureTypeList : string[] = []
                  for (let i=0; i<tabResult_withChilds.tabDetails.length ; i++){
                      for ( let y=0; y<tabResult_withChilds.tabDetails[i].features.length ; y++ ){
                        featureTypeList.push(tabResult_withChilds.tabDetails[i].features[y].featureName )
                      }
                       
                   }
                //    marketIn.tabChildIdPN=feature_id_list
                //    marketIn.tabChildListPN=tabChild_list

            /**
             * we have to call them paragraphsChilds to make it work the same when scompose at store base components
             */
            const paragraphsChilds: any = tabResult_withChilds.tabChild_list
            const isComingFromFormItemCall = undefined 
            const isComingFromMultiFeatures = false // if false =  isComingFromTabs

            console.log( {featureTypeList, paragraphsChilds}, isComingFromFormItemCall, isComingFromMultiFeatures )
            this.storeBaseComponentsService.unpackAndDistributeBaseComponents({featureTypeList , paragraphsChilds }, isComingFromFormItemCall , isComingFromMultiFeatures )


        })

}
 


}