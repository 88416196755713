import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "src/app/app-routing.module";
import { MaterialDesignModule } from "src/app/material-design/material-design.module";
import { SharedModule } from "src/app/shared/shared.module";
import { RecipientItemComponent } from "./recipient-list/recipient-item/recipient-item.component";
import { RecipientListComponent } from "./recipient-list/recipient-list.component";
import { RecipientComponent } from "./recipient.component";
import { SubjectComponent } from './recipient-list/recipient-item/subject/subject.component';
import { SubjectListComponent } from './recipient-list/recipient-item/subject/subject-list/subject-list.component';
import { SubjectItemComponent } from './recipient-list/recipient-item/subject/subject-list/subject-item/subject-item.component';
import { ToMailAccountsComponent } from './recipient-list/recipient-item/to-mail-accounts/to-mail-accounts.component';
import { BccMailAccountsComponent } from './recipient-list/recipient-item/bcc-mail-accounts/bcc-mail-accounts.component';
import { ToMessageAccountsComponent } from './recipient-list/recipient-item/to-message-accounts/to-message-accounts.component';
import { CcMailAccountComponent } from "./recipient-list/recipient-item/cc-mail-account/cc-mail-account.component";
import { GreetingsComponent } from './recipient-list/recipient-item/greetings/greetings.component';
import { GroupNameComponent } from './recipient-list/recipient-item/group-name/group-name.component';
import { RecipientService } from "./recipient.service";
import { ContactsModule } from "src/app/contacts/contacts.module";
import { AuxiliaryComponentsModule } from "src/app/loading-spinner/auxiliary-components.module";



// import { ChatsModule } from "src/app/chats/chats.module";

@NgModule({
    declarations: [
        RecipientItemComponent,
        RecipientListComponent,
        RecipientComponent,
        SubjectComponent,
        SubjectListComponent,
        SubjectItemComponent,
        ToMailAccountsComponent,
        BccMailAccountsComponent,
        ToMessageAccountsComponent,
        CcMailAccountComponent,
        GreetingsComponent,
        GroupNameComponent,
    ],

    exports: [
        RecipientItemComponent,
        RecipientListComponent,
        RecipientComponent,
        
    ],

    imports: [
        AppRoutingModule,
        CommonModule,
        FormsModule,
        BrowserAnimationsModule,
        MaterialDesignModule, 
        ReactiveFormsModule,
        MatDialogModule,
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatExpansionModule,
        SharedModule,
        AuxiliaryComponentsModule,
        ContactsModule,
    ],



})
export class RecipientModule {}