import { Injectable } from "@angular/core";
import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from "@angular/common/http";

@Injectable ({
    providedIn: 'root' 
})

export class FormResultHttpRequestService {

    constructor (private http: HttpClient){}

    // httpStoreFormResult(formResult: FormResult){
    //     this.http.post<{form: FormResult}>(   // in a normal serve i should add /post/add right after the http address/
    //         'https://drapp-71fc8-default-rtdb.europe-west1.firebasedatabase.app/formResults.json', formResult, 
    //         {
    //           observe: 'events',
    //           responseType: 'json'
    //         }
    //          ).pipe(tap(event=>{
    //            console.log(event)
    //            if (event.type === HttpEventType.Response){
    //              console.log(event.body)
    //            }
    //          }))
    //         .subscribe(response => {
    //             console.log(response)
    //         })
    // }



    // httpFetchFormResults(){
    //   let searchParams = new HttpParams
    //   searchParams = searchParams.append('print', 'formResults')
    //   searchParams = searchParams.append('second', 'parameterFormResults')
    //    return this.http.get<{ [key: string]: FormResult }>('https://drapp-71fc8-default-rtdb.europe-west1.firebasedatabase.app/formResults.json',
    //    {
    //      headers: new HttpHeaders ({"Custom-Header":"formResults"}),
    //      params: searchParams
    //    }
    //    )
    //     .pipe(map(responseData => {
    //       const formResults: FormResult[] = [];
    //       for (const key in responseData){
    //         if (responseData.hasOwnProperty(key)){
    //           formResults.push({...responseData[key], _id: key})
    //         }
    //       }
    //         return formResults.map(responseData => {
    //         return {...responseData, isActive: responseData.isActive? responseData.isActive: null ,
    //                                  isDeleted: responseData.isDeleted? responseData.isDeleted: false,
    //                                  isRead: responseData.isRead? responseData.isRead: null,
    //                                  isForwardToMail: responseData.isForwardToMailAsCopy? responseData.isForwardToMailAsCopy: false ,
    //                                  isForwarded: responseData.isForwarded? responseData.isForwarded: false,
    //                                  isReceived: responseData.isReceived? responseData.isReceived: false,
    //                                  senderGroup: responseData.senderGroup? responseData.senderGroup: '' ,
    //                                  senderType: responseData.senderType? responseData.senderType: '',
    //                                  receiver: new Receiver ( responseData.receiver.sentRecipientGroup? responseData.receiver.sentRecipientGroup: '',
    //                                                           responseData.receiver.sentRecipientName? responseData.receiver.sentRecipientName: '',
    //                                                           responseData.receiver.sentToMessage? responseData.receiver.sentToMessage: [],
    //                                                           responseData.receiver.sentToMail? responseData.receiver.sentToMail: [],
    //                                                           responseData.receiver.sentCcMail? responseData.receiver.sentCcMail: [],
    //                                                           responseData.receiver.sentBccMail? responseData.receiver.sentBccMail: [] ,
    //                                                           responseData.receiver.sentGreetings? responseData.receiver.sentGreetings: ''
    //                                                             ) ,
    //                                  receiverGroup: responseData.receiverGroup? responseData.receiverGroup: '', 
    //                                  receiverType: responseData.receiverType? responseData.receiverType: '',
    //                                  dateAndTime: responseData.dateAndTime? responseData.dateAndTime: '', 
    //                                  subject: responseData.subject? responseData.subject: '' ,

                                
                                
    //                                 //  paragraphResults: (responseData.paragraphResults? responseData.paragraphResults: responseData.paragraphResults.map(responseData2 => {
                                                                                                                      
    //                                 //                                                                                   new ParagraphResult ( responseData2.paragraphResultName? responseData2.paragraphResultName: '',
    //                                 //                                                                                                         responseData2.featureResults? responseData2.featureResults: []  
    //                                 //                                                                                    ) } , 
    //                                 //                                                                                   )) 

    //          } 
            
    //         }) 

    //       /**
    //        * export class ParagraphResult {

    // constructor(
    //     public paragraphResultName: string ,
    //     public featureResults:FeatureResult[]
    //        */

    //       /**
    //        * export class Receiver {

    // constructor (
    //     public sentRecipientGroup: string , 
    //     public sentRecipientName: string , 
    //     public sentToMessage: string[], 
    //     public sentToMail: string[],
    //     public sentCcMail: string[],
    //     public sentBccMail: string[],
    //     public sentGreetings: string
    // ){}
    //        */


    //       /**
    //        *     constructor (
    //     public _id: string, 
    //     public chat_id: string,
    //     public chatId: number, 
    //     public isActive: boolean,
    //     public isDeleted: boolean,
    //     public isRead: boolean,
    //     public isForwardToMail: boolean ,
    //     public isSentToMessage: boolean,
    //     public isForwarded: boolean,
    //     public isReceived: boolean,
    //     public sender: Sender , 
    //     public senderGroup: string ,
    //     public senderType: string,
    //     public receiver: Receiver ,
    //     public receiverGroup:string, 
    //     public receiverType: string ,
    //     // public formDateAndTime: FormDateAndTime ,
    //     public dateAndTime: string, 
    //     public subject: string ,
    //     public paragraphResults: ParagraphResult[]
    //     ){}
    //        */
         
         
          
    //     }),
    //     catchError(errorRes => {
    //       // send to analytics
    //       return throwError(errorRes)
    //     }))
    // }






}