import { Injectable } from "@angular/core";
import { AuthService } from 'src/app/services/auth-services/auth.service';
import { GetComponentsHttpRequestService } from 'src/app/services/gets-services/get-components-http-request.sevice';
import { Subscription, Subject } from "rxjs";
import { FeatureTopic } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-topic.model";
import { RecipientIndex } from "../../forms/form-list/form-edit/recipient-index-list/recipient-index.model";
import { ParagraphTopic } from "../../forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.model";
import { FormIndexTopic } from "../../forms/form-list/form-index-topic-list/form-index-topic.model";
import { Form } from "../../forms/form.model";
import { ParagraphTopicIndex } from "src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-topic-index.model";
import { FormIndexTopicListItem } from "src/app/forms/form-list/form-index-topic-list/form-index-topic-list-item.model";
import { FormFeatureContainer } from "src/app/forms/form-list/form-index-topic-list/form-feature-container.model";
import { RecipientIndexListItem } from "src/app/forms/form-list/form-edit/recipient-index-list/recipient-index-list-item.model";
import { RecipientIndexColumn } from "src/app/forms/form-list/form-edit/recipient-index-list/recipient-index-column.model";
import { ParagraphFeatureContainer } from "src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature-container.model";
import { FeatureContainer } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-container.model";
import { FeatureContainerListItem } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-container-list-item.model";
import { FeatureIndexTopicList } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-index-topic-list.model";
// import { Group } from "src/app/chats/group.model";
import { StoreInitialEmptyPersonalComponentsService } from "./store-initial-empty-personal-components.service";
import { RecipientFeature } from "src/app/forms/form-list/form-edit/recipient-index-list/recipient-feature.model";
import { Recipient } from "src/app/forms/form-list/form-item/recipient/recipient.model";
import { CreateUpdateMultiComponentsService } from "../shared-services/create-update-multi-components.service";
import { FeatureContainerService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container/feature-container.service";
import { RecipientFeatureService } from "src/app/forms/form-list/form-edit/recipient-index-list/recipient-feature.service";
import { RecipientIndexService } from "src/app/forms/form-list/form-edit/recipient-index-list/recipient-index.service";
import { RecipientIndexListItemService } from "src/app/forms/form-list/form-edit/recipient-index-list/recipient-index-list-item.service";
import { RecipientIndexColumnService } from "src/app/forms/form-list/form-edit/recipient-index-list/recipient-index-column.service";
import { FeatureContainerListItemService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container-list-item/feature-container-list-items.service";
import { FeatureTopicService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-topic.service";
import { FeatureIndexTopicListService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-index-topic-list.service";
import { ParagraphTopicIndexService } from "src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-topic-index.service";
import { ParagraphTopicService } from "src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.service";
import { ParagraphFeatureContainerService } from "src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature-container.service";
import { FormIndexTopicService } from "src/app/forms/form-list/form-index-topic-list/form-index-topic.service";
import { FormIndexTopicListItemService } from "src/app/forms/form-list/form-index-topic-list/form-index-topic-list-item.service";
import { FormFeatureContainerService } from "src/app/forms/form-list/form-index-topic-list/form-feature-container.service";
import { AdviseIfHttpStoreComponentsCallHasBeenMadeService } from "../other-components-services/advise-if-http-store-components-has-been-made.service";
import { InitialNullSetupService } from "../starting-services/initial-null-setup.service";
import { LoginService } from "src/app/login/login.service";
import { UserProfile } from "src/app/auth/user-profile/user-profile.model";
import { Contact } from "src/app/contacts/contact.model";
import { UserProfileService } from "src/app/auth/user-profile/user-profile.service";
import { ContactService } from "src/app/contacts/contact.service";




@Injectable ({
    providedIn: 'root' 
})


export class StorePersonalFeatureIndexComponentsService {

  // getUserEmailFromDefaultService: Subscription

 

    constructor (  private initialNullSetupService: InitialNullSetupService ,
                   private featureContainerService: FeatureContainerService , 
                   private recipientFeatureService: RecipientFeatureService ,
                   private userProfileService: UserProfileService,
                   private contactService: ContactService,
                   private recipientIndexService: RecipientIndexService , 
                   private recipientIndexListItemService: RecipientIndexListItemService , 
                   private recipientIndexColumnService: RecipientIndexColumnService ,
                   private featureContainerListItemService: FeatureContainerListItemService ,
                   private featureTopicService: FeatureTopicService ,
                   private featureIndexTopicListService: FeatureIndexTopicListService ,
                   private paragraphTopicIndexService: ParagraphTopicIndexService , 
                   private paragraphTopicService: ParagraphTopicService ,
                   private paragraphFeatureContainerService: ParagraphFeatureContainerService ,
                   private formIndexTopicService: FormIndexTopicService,
                   private formIndexTopicListItemService: FormIndexTopicListItemService ,
                   private formFeatureContainerService: FormFeatureContainerService ,
                   private adviseIfHttpStoreComponentsCallHasBeenMadeService: AdviseIfHttpStoreComponentsCallHasBeenMadeService , 
                   private getComponentsHttpRequestService: GetComponentsHttpRequestService,
                   private storeInitialEmptyPersonalComponentsService: StoreInitialEmptyPersonalComponentsService 
                   
                   ){}

// startingEditForm = new Subject<Form>()  
    
    getPersonalInitialFeatureIndex(userEmail: string){
        
            this.getComponentsHttpRequestService.httpGetComponentsByType(userEmail, 'featureIndex')

                   .subscribe( res => {
                    console.log(res)

                    const httpFeatureIndexComponentResult: any = res 

                    const newGroups = httpFeatureIndexComponentResult.group 
                    console.log(newGroups)

                    this.storeUserProfile( userEmail , httpFeatureIndexComponentResult)

                   

                   }) // end featureIndex Subscription 
};

storeUserProfile(userEmail: string , httpFeatureIndexComponentResult: any){

  const newUserProfile: UserProfile = httpFeatureIndexComponentResult.userProfile[0]
  this.userProfileService.userProfile = newUserProfile
  this.initialNullSetupService.initialNullUserProfile = newUserProfile 
  console.log(newUserProfile)

  this.storeContacts(userEmail, httpFeatureIndexComponentResult )

}

storeContacts(userEmail: string , httpFeatureIndexComponentResult: any){

  const newContacts: Contact[] = httpFeatureIndexComponentResult.contact
  this.contactService.contacts = newContacts
  this.initialNullSetupService.initialNullContacts = newContacts
  console.log(newContacts)


  this.storeRecipientIndexColumns( userEmail , httpFeatureIndexComponentResult)
}


storeRecipientIndexColumns(userEmail: string , httpFeatureIndexComponentResult: any){
const newRecipientIndexColumns: RecipientIndexColumn[] = httpFeatureIndexComponentResult.recipientIndexColumn
console.log(newRecipientIndexColumns)
this.recipientIndexColumnService.recipientIndexColumns = newRecipientIndexColumns 
this.initialNullSetupService.initialNullRecipientIndexColumns = newRecipientIndexColumns
this.storeFeatureContainers(userEmail , httpFeatureIndexComponentResult)
}

storeFeatureContainers(userEmail: string , httpFeatureIndexComponentResult: any){
const newFeatureContainers: FeatureContainer[] = httpFeatureIndexComponentResult.featureContainer
console.log(newFeatureContainers)
this.featureContainerService.featureContainers = newFeatureContainers
this.initialNullSetupService.initialNullFeatureContainers = newFeatureContainers
this.storeFeatureContainerListItems(userEmail , httpFeatureIndexComponentResult)
}

storeFeatureContainerListItems(userEmail: string , httpFeatureIndexComponentResult: any){
const newFeatureContainerListItems: FeatureContainerListItem[] = httpFeatureIndexComponentResult.featureContainerListItem
                     console.log(newFeatureContainerListItems)
this.featureContainerListItemService.featureContainerListItems = newFeatureContainerListItems
this.initialNullSetupService.initialNullFeatureContainerListItems = newFeatureContainerListItems
this.storeFeatureTopics(userEmail , httpFeatureIndexComponentResult)
}

storeFeatureTopics(userEmail: string , httpFeatureIndexComponentResult: any){
const newFeatureTopics: FeatureTopic[] = httpFeatureIndexComponentResult.featureTopic
                    console.log(newFeatureTopics)
this.featureTopicService.featureTopics = newFeatureTopics 
this.initialNullSetupService.initialNullFeatureTopics = newFeatureTopics
this.storeFeatureIndexTopicList(userEmail , httpFeatureIndexComponentResult)
}

storeFeatureIndexTopicList(userEmail: string , httpFeatureIndexComponentResult: any){
const newFeatureIndexTopicListItems: FeatureIndexTopicList[] = httpFeatureIndexComponentResult.featureIndexTopicList
                    console.log(newFeatureIndexTopicListItems)
this.featureIndexTopicListService.featureIndexTopicListItems = newFeatureIndexTopicListItems 
this.initialNullSetupService.initialNullFeatureIndexTopicListItems = newFeatureIndexTopicListItems
this.storeParagraphTopicIndexes(userEmail , httpFeatureIndexComponentResult)
}     

storeParagraphTopicIndexes(userEmail: string , httpFeatureIndexComponentResult: any){
const newParagraphTopicIndexes: ParagraphTopicIndex[] = httpFeatureIndexComponentResult.paragraphTopicIndex
console.log(newParagraphTopicIndexes)
this.paragraphTopicIndexService.paragraphTopicIndexes = newParagraphTopicIndexes
this.initialNullSetupService.initialNullParagraphTopicIndexes = newParagraphTopicIndexes
this.storeParagraphTopics(userEmail , httpFeatureIndexComponentResult)
}


storeParagraphTopics(userEmail: string , httpFeatureIndexComponentResult: any){
const newParagraphTopics: ParagraphTopic[] = httpFeatureIndexComponentResult.paragraphTopic
console.log(newParagraphTopics)
this.paragraphTopicService.paragraphTopics = newParagraphTopics
this.initialNullSetupService.initialNullParagraphTopics = newParagraphTopics 
this.storeParagraphFeatureContainers(userEmail , httpFeatureIndexComponentResult)
}

storeParagraphFeatureContainers(userEmail: string , httpFeatureIndexComponentResult: any){
const newParagraphFeatureContainers: ParagraphFeatureContainer[] = httpFeatureIndexComponentResult.paragraphFeatureContainer 
console.log(newParagraphFeatureContainers)
this.paragraphFeatureContainerService.paragraphFeatureContainers = newParagraphFeatureContainers
this.initialNullSetupService.initialNullParagraphFeatureContainers = newParagraphFeatureContainers
this.storeFormIndexTopics(userEmail , httpFeatureIndexComponentResult)
}

storeFormIndexTopics(userEmail: string , httpFeatureIndexComponentResult: any){
const newFormIndexTopics: FormIndexTopic[] = httpFeatureIndexComponentResult.formIndexTopic
console.log(newFormIndexTopics)
this.formIndexTopicService.formIndexTopics = newFormIndexTopics 
this.initialNullSetupService.initialNullFormIndexTopics = newFormIndexTopics
this.storeFormIndexTopicListItems(userEmail , httpFeatureIndexComponentResult)
}

storeFormIndexTopicListItems(userEmail: string , httpFeatureIndexComponentResult: any){
const newFormIndexTopicListItems: FormIndexTopicListItem[] = httpFeatureIndexComponentResult.formIndexTopicListItem
console.log(newFormIndexTopicListItems)
this.formIndexTopicListItemService.formIndexTopicListItems = newFormIndexTopicListItems
this.initialNullSetupService.initialNullFormIndexTopicListItems = newFormIndexTopicListItems
this.storeFormFeatureContainers(userEmail , httpFeatureIndexComponentResult)
}
                   

storeFormFeatureContainers(userEmail: string , httpFeatureIndexComponentResult: any){
const newFormFeatureContainers: FormFeatureContainer[] = httpFeatureIndexComponentResult.formFeatureContainer
console.log(newFormFeatureContainers)
this.formFeatureContainerService.formFeatureContainers = newFormFeatureContainers 
this.initialNullSetupService.initialNullFormFeatureContainers = newFormFeatureContainers
this.adviseIfHttpStoreComponentsCallHasBeenMadeService.creatTabOfFormItemIdPlusRespectiveStoredIndex(newFormFeatureContainers)
this.storeRecipientFeatures(userEmail , httpFeatureIndexComponentResult)
}

storeRecipientFeatures(userEmail: string , httpFeatureIndexComponentResult: any){
  const newRecipientFeatures: RecipientFeature[] = httpFeatureIndexComponentResult.recipientFeature
  /**
   * setting recipientFeatures recipient_ids = []
   */
  newRecipientFeatures[0].receivedRecipient_ids = []
                              console.log(newRecipientFeatures)
  this.recipientFeatureService.recipientFeatures = newRecipientFeatures
  this.initialNullSetupService.initialNullRecipientFeatures = newRecipientFeatures
this.storeRecipientIndexes(userEmail , httpFeatureIndexComponentResult , newRecipientFeatures)

}

storeRecipientIndexes(userEmail: string , httpFeatureIndexComponentResult: any , recipientFeatures: RecipientFeature[]){
const newRecipientIndexes: RecipientIndex[] = httpFeatureIndexComponentResult.recipientIndex
                    console.log(newRecipientIndexes)
this.recipientIndexService.recipientIndexes = newRecipientIndexes
this.initialNullSetupService.initialNullRecipientIndexes = newRecipientIndexes

/**
 * CLEAN POSSIBLE MISTAKES ON MULTIPLE RECIPIENT_IDS ON EACH RECIPIENTINDEX
 */
  // if (Array.isArray(this.recipientIndex.recipient_id)) {
    //   if ( this.recipientIndex.recipient_id.length > 1 ){
    //     console.log("IS ARRAY OF RECIPIENT_ID")
    //     const recipientList: any = this.recipientIndex.recipient_id 
    //   const stringList: string[] = recipientList
    //   const indexOfrecipient =  this.getIndexWithLowestNumber(stringList)
    //   const recipient_id = stringList[indexOfrecipient]
    //   this.recipient = this.recipientService.getRecipientBy_id(recipient_id);
    //   }
    // }

    // if ( !Array.isArray(this.recipientIndex.recipient_id)){
    //   this.recipient = this.recipientService.getRecipientBy_id(this.recipientIndex.recipient_id);
    // }
console.log(this.recipientIndexService.getRecipientIndexes())
                    this.storeRecipientIndexListItems(userEmail , httpFeatureIndexComponentResult , recipientFeatures , newRecipientIndexes)
}

  // getIndexWithLowestNumber(strings: string[]): number {
  //   let lowestNumber = Infinity;
  //   let lowestNumberIndex = -1;
  
  //   Array.from(strings).forEach((str, index) => {
  //     const number = parseInt(str.match(/\d+$/)[0], 10);
  //     if (number < lowestNumber) {
  //       lowestNumber = number;
  //       lowestNumberIndex = index;
  //     }
  //   });
  
  //   return lowestNumberIndex;
  // }

storeRecipientIndexListItems(userEmail: string , httpFeatureIndexComponentResult: any , recipientFeatures: RecipientFeature[] , recipientIndexes: RecipientIndex[]){
const newRecipientIndexListItems: RecipientIndexListItem[] = httpFeatureIndexComponentResult.recipientIndexListItem
                     console.log(newRecipientIndexListItems)
this.recipientIndexListItemService.recipientIndexListItems = newRecipientIndexListItems
this.initialNullSetupService.initialNullRecipientIndexListItems = newRecipientIndexListItems
this.checkRecipientFeatureInnerRecipients_ifTheyHaveRecipientIndexesAlready(userEmail , recipientFeatures )
}
                
checkRecipientFeatureInnerRecipients_ifTheyHaveRecipientIndexesAlready( userEmail: string , 
                                                                        recipientFeatures:RecipientFeature[]){

  const newRecipientFeature: RecipientFeature = recipientFeatures[0] ; 

  console.log(newRecipientFeature) ;

  this.getComponentsHttpRequestService.httpGetUserComponentWithChildsBy_id(newRecipientFeature._id)
    .subscribe((recipientFeaturePlusChilds: any)=>{
     console.log(recipientFeaturePlusChilds)
 
      /**
       * build sharedRecipientIndexListItem
       */
      const currentSharedRecipientIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItemBy_UIID('sharedRecipientIndexListItem-2')
      const indexOfsharedrecipientIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItems().findIndex(x=> x._id === currentSharedRecipientIndexListItem._id)

      for ( let i=0; i<recipientFeaturePlusChilds.receivedRecipient_ids.length ; i++ ){
           /**
            * check if index exist add _id , if not add undefined first 
            */
           if ( this.recipientIndexService.getRecipientIndexes().findIndex(x=> x.recipient_id === recipientFeaturePlusChilds.receivedRecipient_ids[i] ) !== -1 ){
                const recipientIndex_index = this.recipientIndexService.getRecipientIndexes().findIndex(x=> x.recipient_id === recipientFeaturePlusChilds.receivedRecipient_ids[i] )
                const recipientIndex_id = this.recipientIndexService.getRecipientIndex(recipientIndex_index)._id 

                currentSharedRecipientIndexListItem.recipientIndexList_ids.push(recipientIndex_id)
           }
           if ( this.recipientIndexService.getRecipientIndexes().findIndex(x=> x.recipient_id === recipientFeaturePlusChilds.receivedRecipient_ids[i] ) === -1 ){
                const recipientIndex_id = undefined 
                currentSharedRecipientIndexListItem.recipientIndexList_ids.push(recipientIndex_id)
           }

           if ( i === 0)( currentSharedRecipientIndexListItem.recipientIndexSelected_id = recipientFeaturePlusChilds.receivedRecipient_ids[0] )
  
      }

      this.recipientIndexListItemService.recipientIndexListItems[indexOfsharedrecipientIndexListItem] = currentSharedRecipientIndexListItem

      /**
      * add recpients to store 
      */
      let sharedRecipients: Recipient[] = recipientFeaturePlusChilds.childList 

      // check which recipient has the userEmain its messageList  and add those only 

      sharedRecipients = sharedRecipients.filter(obj => obj.toMessage.includes(userEmail));
      /**
       * call recipientFeature with childs and store all recipients right away to start so
       * that we don't have to call them on formItem 
       */
      // const newSharedRecipients = [] ;
      this.continueToSetEmptyPersonalComponents(userEmail , sharedRecipients )

    })

} 
/**
 * 
 * @param userEmail 
 * @param newSharedRecipients 
 */
continueToSetEmptyPersonalComponents(userEmail: string , newSharedRecipients: Recipient[]){

  this.storeInitialEmptyPersonalComponentsService.storeInitialEmptyPersonalComponents(userEmail, newSharedRecipients );
}

/**
 * before adding any new reciopients or recipientIndexListItem or any other main components 
 * such paragraphs , paragraphscontainers or topic , as well for forms 
 *  we make sure we are updated 
 * we call the featureIndexes related and update the latest state 
 */
getPersonalUpdatedFeatureIndex_components(userEmail: string){

   return this.getComponentsHttpRequestService.httpGetComponentsByType(userEmail, 'featureIndex')

}



ngOnDestroy(){
}



}

