import { Injectable } from "@angular/core";
import { SingleDropDownSelect } from "./single-drop-down-select.model";
import { InitialNullSetupService } from "src/app/services/starting-services/initial-null-setup.service";
import { Subject, Subscription } from "rxjs";
import { StoreFormItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-form-item-plus-childs-confirmation.service";
import { StoreParagraphItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-paragraph-item-plus-childs-confirmation.service";
import { StoreMultibaseItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-multiBase-item-plus-childs-confirmation.service";
import { StoreTabItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-tab-item-plus-childs-confirmation.service";

@Injectable ({
    providedIn: 'root' 
})

export class SingleDropDownSelectService {

    constructor( private initialNullSetupService: InitialNullSetupService ,
                 private storeFormItemPlusChildsConfirmationService: StoreFormItemPlusChildsConfirmationService , 
                 private storeParagraphItemPlusChildsConfirmationService: StoreParagraphItemPlusChildsConfirmationService ,
                 private storeTabItemPlusChildsConfirmationService: StoreTabItemPlusChildsConfirmationService ,
                 private storeMultibaseItemPlusChildsConfirmationService: StoreMultibaseItemPlusChildsConfirmationService
                 ){}

    singleDropDownSelects: SingleDropDownSelect[] = this.initialNullSetupService.initialNullSingleDropDownSelects
    
    initialPersonalSingleDropDownSelectsHaveBeenStored = new Subject<void>()


   


    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.singleDropDownSelectsChangedBackToNull
                                                     .subscribe((res)=> {
                                                         this.singleDropDownSelects = res ,
                                                         console.log('updated singleDropDown back to null = ', res)

    })



    getSingleDropDownSelects(){
        return this.singleDropDownSelects
    };

    getSingleDropDownSelect_by_id(_id: string){
        const newSingleDropDownSelect: SingleDropDownSelect = this.singleDropDownSelects.find(
            (res)=>{
                return res._id === _id
            }
        )
        return newSingleDropDownSelect
    }

    getSingleDropDownSelect(index: number){
        return this.singleDropDownSelects[index]
    };

    addNew( singleDropDownSelect: SingleDropDownSelect){
        this.singleDropDownSelects.push(singleDropDownSelect)
        this.updateInitialComponent(this.singleDropDownSelects)
    };


    addNewSingleDropDownSelects(singleDropDownSelects: SingleDropDownSelect[]){
        for ( let i=0; i<singleDropDownSelects.length ; i++ ){
            if ( this.singleDropDownSelects.findIndex(x=> x._id === singleDropDownSelects[i]._id ) === -1 ){
                this.singleDropDownSelects.push(singleDropDownSelects[i])
            }
            if ( i === singleDropDownSelects.length-1){
                this.updateInitialComponent(this.singleDropDownSelects)
                console.log(this.singleDropDownSelects )
                this.initialPersonalSingleDropDownSelectsHaveBeenStored.next()
            }
        }
        // console.log(this.singleDropDownSelects )
        // this.initialPersonalSingleDropDownSelectsHaveBeenStored.next()
    }

    addNewSingleDropDownSelect(singleDropDownSelectToAdd: SingleDropDownSelect , isComingFromFormItemCall: boolean , isComingFromMultiFeatures: boolean ){

        if ( this.singleDropDownSelects.findIndex(x=>x._id === singleDropDownSelectToAdd._id) !== -1){ 
             const index = this.singleDropDownSelects.findIndex(x=>x._id === singleDropDownSelectToAdd._id)
             this.singleDropDownSelects[index] = singleDropDownSelectToAdd
            this.updateInitialComponent(this.singleDropDownSelects)
        }

        if ( this.singleDropDownSelects.findIndex(x=>x._id === singleDropDownSelectToAdd._id) === -1){ 
             this.singleDropDownSelects.push(singleDropDownSelectToAdd)
             this.updateInitialComponent(this.singleDropDownSelects)
        }
        
        if ( isComingFromFormItemCall === true  ){ this.storeFormItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenFormItemPlusChildsAreStored( singleDropDownSelectToAdd._id) }
        if ( isComingFromFormItemCall === false ){ this.storeParagraphItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenParagraphItemPlusChildsAreStored( singleDropDownSelectToAdd._id) }
        if ( isComingFromMultiFeatures === true ){ this.storeMultibaseItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenMultiFeatureItemPlusChildsAreStored(singleDropDownSelectToAdd._id)}
        if ( isComingFromMultiFeatures=== false ){ this.storeTabItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenTabItemPlusChildsAreStored( singleDropDownSelectToAdd._id) }
       
        console.log('SINGLEDROPDOWNSELECTs = ',this.singleDropDownSelects)
    }

    updateInitialComponent(updatedSingleDropDownSelects: SingleDropDownSelect[]){
        this.initialNullSetupService.initialNullSingleDropDownSelects = updatedSingleDropDownSelects
    }

    ngOnDestroy(){
        this.updateInitialNullDatafromInitialNullSetupService.unsubscribe()

    }

}