import { Component, OnInit } from '@angular/core';
import { SharedService } from '../shared.service';
import { Subscription } from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-sibiling2',
  templateUrl: './sibiling2.component.html',
  styleUrls: ['./sibiling2.component.css']
})
export class Sibiling2Component implements OnInit {
  clickEventSubscription:Subscription ;  

  message: string ;

  constructor(private sharedService: SharedService) {
    this.clickEventSubscription= this.sharedService.getClickEvent().subscribe(()=>{
      this.incrementCount();
    } )
   }

  ngOnInit(): void {
    this.sharedService.sharedMessage.subscribe(message => this.message = message)
  }

  count:number = 0;
  incrementCount(){
    this.count++ ;
  }

  MoviesWatched = [
    'Transformers'
  ];



  onDrop(event: CdkDragDrop<string[]>) {
    this.sharedService.drop(event);
  }

}
