
<app-find-form-or-paragraph 
   *ngIf="newParagraphIndexType.isPublic"
  [weAreInFormIndexTopicList]="weAreInFormIndexTopicList"
  (emitUserEmailFound)="onAddEmailSearch($event)"
  (emitUserEmailIndexToCancel)="onDeleteEmailOnSearch($event)"
  [foundEmailList]="foundEmailList">
</app-find-form-or-paragraph>


<div *ngIf="!isLoading">
    <div class="thinScrollBar" *ngFor="let paragraphTopic_id of paragraphTopicIndex.paragraphTopic_ids ; let i = index ">
        <app-chat-paragraph-index-topic 
        [isPersonal]="isPersonalIndex"
        [isPublic]="isPublic"
        [index_id]="paragraphTopic_id">
        <!-- 
        [chat]="chat"
        [aChatHasBeenSelected]="aChatHasBeenSelected"
        [subscribeUpdatedChatSelected]="subscribeSelectedChat.asObservable()" -->
        </app-chat-paragraph-index-topic>
    </div>
</div>

<div *ngIf="isLoading" >
  <app-loading-spinner></app-loading-spinner>
</div>
  

    
    
