<!-- <p>calendar-edit works!</p> -->

<div class="row">
    <div class="col-xs-12">
        <form [formGroup]="oneDateCalendarEditForm" (ngSubmit)="onSubmit()">

            <div class="row">
                <div class="col-xs-8">

                    <mat-form-field style="width: 250px" appearance="outline">
                        <mat-label>component name</mat-label>
                        <input 
                        matInput
                        type="text"
                        id="name"
                        formControlName="name"
                        >
                    </mat-form-field>
                    <mat-error *ngIf="!oneDateCalendarEditForm.get('name').valid"  >Please enter a valid name, maximum 30 letters </mat-error>
                </div>
    
                <div class="col-xs-4">
                   
                        <button type="button" class="btn btn-primary" (click)="onAddDetails()">  add input   </button>  
                </div>
            </div>
    
            <hr>

            <div class="row">
                <div class="col-xs-12" formArrayName="details">

                    <table>
                        <tr>
                            <td *ngFor="let detail of getDetails(oneDateCalendarEditForm); let i=index" [formGroupName]="i">

                                <div class="row">
                                    <div class="col-xs-12">
                                        <button *ngIf="getDetails(oneDateCalendarEditForm).length >1" 
                                                type="button" 
                                                class="btn btn-danger btn-sm" 
                                               (click)="onDeleteDetail(i)">-</button>
                                    </div>
                                </div>

                                <br>

                                <div class="row">
                                    <div class="col-xs-12">

                                        <mat-form-field class="example-full-width" appearance="fill">
                                            <mat-label>edit description</mat-label>
                                            <input 
                                            matInput
                                            type="text" 
                                            id="description{{i}}"
                                            formControlName="description" >
                                            <mat-hint>MM/DD/YYYY</mat-hint>
                                           
                                        </mat-form-field>

                                    </div>
                                </div>


                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <br>

            <div class="row">
                <div class="col-xs-12">
                    <button type="submit" class="btn btn-primary"> update</button>
                </div>
            </div>
        </form>
    </div>
</div>