import { FixTextDetail } from "../../texts/fix-text-list/fix-text-detail.model";
import { InnerArraySelect } from "./inner-array-select.model";
import { InnerLinkDetail } from "./inner-link-item/inner-link-detail.model";
import { UsersAccess } from "./users-access-edit/users-access.model";

export class RangeInfo {

    constructor( public type: string , 
                 public name: string , 
                 public usersAccess: UsersAccess,
                 public rangeDetails: InnerArraySelect[] |
                                      FixTextDetail[] | 
                                      InnerLinkDetail[]
               ){}
}