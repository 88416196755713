import { DateStartEnd } from "./date-start-end.model";
import { RangeInfo } from "./range-info.model";
import { UsersAccess } from "./users-access-edit/users-access.model";

export class RangeDatesInfo {

    constructor( public dateStartEnd: DateStartEnd, 
                 public rangeInfos: RangeInfo[], 
                 public usersAccess: UsersAccess
                 ){}
}