
   
   
   <div class="col" *ngIf="!isLoading">

    <!-- IF IT CAN'T BE RE SHARED  -->
    <div *ngIf="!itCanBeReshared">
        <h2 style="color:#3f51b5">the form contain a paragraph or component that can't be shared </h2>
    </div>
    <!-- END IT CAN'T BE RESHARED -->

    <!-- START IT CAN BE RESHARED -->
    <div *ngIf="itCanBeReshared">
        <div *ngIf="!isShareToPublic">

            <!-- *ngIf="listOfCurrentSelectedContactEmail.length === 0" -->
        <div  class="row">
            <button  class="btn btn-primary btn-lg"
                     type="button">
                {{form.formSubject}}
            </button>
        </div>

        <br>

        <div *ngIf="listOfCurrentSelectedContactEmail.length > 0"  class="row" >
            <button (click)="onClearSelectedContacts()" style="background-color: orange ;"  class="btn btn-lg">
                clear selected contacts
            </button>
        </div>
        <br>
        <br>
    
        <div class="row" *ngFor="let contactDetail of contactDetails; let i=index">

            <app-contact-item [weAreOnShareParagraphFormIndex]="weAreOnShareParagraphFormIndex"
                              [isContactEditMode]="isContactEditMode"
                              [contactDetail]="contactDetail"
                              [listOfCurrentSelectedContactEmail]="listOfCurrentSelectedContactEmail"
                              [deselectAllContacts]="onDeselectAllContacts.asObservable()"
                              (removeContactFromList)='onRemoveContactFromList($event)'
                              (addContactToList)='onAddContactToList($event)'>
            </app-contact-item>
        </div>
    
        <br>
    
        <div class="row">
            <div class="col-xs-9">
                <form [formGroup]="newContactName" >
                    <mat-form-field class="full-width">
                      <input
                      placeholder="add contact "
                      matInput 
                      type="email"
                      id="contactName"
                      formControlName="contactName"
                      maxlength="50">
                      <mat-hint>  email </mat-hint>
                    </mat-form-field>       
                  </form>
            </div>
    
            <div class="col-xs-1">
                <button type="button" (click)="onAddContactDetail()" style="background-color: #3f51b5; color:white" class="btn">+</button>
            </div>
            <div class="col-xs-2"></div>     
        </div>

        </div>
    
        <br>
    
        <div *ngIf="listOfCurrentSelectedContactEmail.length === 0" class="row">
            <div class="col-2" >
                <div>
                    <svg *ngIf="!isSelected" (click)="onSelectedContact()" style="color: #3f51b5" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      </svg>
                </div>
               <div>
                <svg *ngIf="isSelected" (click)="onDeselectContact()"  style="color: #3f51b5" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                  </svg>
               </div>
                
            </div>
            <div class="col-10" style="color: #3f51b5">
               <h3> SHARE TO PUBLIC </h3>

            </div>
        </div>

        <!-- <div class="row">
            <div  class="col-2" > 
                <div>
                    <svg *ngIf="true"  style="color: #3f51b5" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      </svg>
                </div>
               <div>
                <svg *ngIf="false"   style="color: #3f51b5" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                  </svg>
               </div>
                
            </div>

            <div class="col-10" style="color: #3f51b5">
                <h3> CAN BE RE-SHARED </h3>
             </div>

        </div> -->
    
        <br>
    
        <div class="row">
            <button  *ngIf="checkedIfIsBeenShared" 
                     mat-dialog-close   
                     (click)="onShareForm()" 
                     class="btn btn-danger btn-lg">
                 share 
                 <!-- <svg *ngIf="!isShareToPublic" style="color: white;"  xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                    <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
                  </svg>  
                  <svg *ngIf="listOfCurrentSelectedContactEmail.length === 0" style="color: white" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-asterisk" viewBox="0 0 16 16">
                    <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"/>
               </svg> -->
            </button>
            
            <button  *ngIf="!checkedIfIsBeenShared" 
                     mat-dialog-close   
                     (click)="onShareForm()" 
                     class="btn btn-danger btn-lg">
                 confirm not sharing
           </button>
        </div>
    </div>
    <!-- END IT CAN BE RESHARED -->
    
   </div>
    <br>
   