import { Component, OnInit, Input } from '@angular/core';
import { CalculatorMixTableDetailResult } from './calculator-mix-table-result.model';

@Component({
  selector: 'app-calculator-mix-table-result',
  templateUrl: './calculator-mix-table-result.component.html',
  styleUrls: ['./calculator-mix-table-result.component.css']
})
export class CalculatorMixTableResultComponent implements OnInit {

  @Input() calculatorMixTableDetailResult: CalculatorMixTableDetailResult;

  constructor() { }

  ngOnInit(): void {
  }

}
