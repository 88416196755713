import { Component, Input, OnInit } from '@angular/core';
import { RangeInfo } from '../range-info.model';

@Component({
  selector: 'app-inner-chart-item',
  templateUrl: './inner-chart-item.component.html',
  styleUrls: ['./inner-chart-item.component.css']
})
export class InnerChartItemComponent implements OnInit {

  @Input() isEditing: boolean 

  @Input() rangeInfo: RangeInfo
  constructor() { }

  ngOnInit(): void {
  }

}
