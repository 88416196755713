import { Component, OnInit, Inject , ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-quick-communication-pop-up',
  templateUrl: './quick-communication-pop-up.component.html',
  styleUrls: ['./quick-communication-pop-up.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class QuickCommunicationPopUpComponent implements OnInit {

  constructor( @Inject(MAT_DIALOG_DATA) public editData: any , 
                private dialogRef: MatDialogRef<QuickCommunicationPopUpComponent>
  ) { }


  quickCommunication: string    
  
  
  ngOnInit(){

    this.quickCommunication = this.editData.quickComment 

    console.log(this.editData.quickComment )

    this.closeDialogAfterDelay(3000); // 3000 milliseconds = 3 seconds
    
  }

  closeDialogAfterDelay(delay: number) {
    setTimeout(() => {
      this.dialogRef.close();
    }, delay);
  }

  


}
