
<!-- START LOCAL LOGIN ---------------------------------------------  -->

<div class="row">
  <div class="col-xs-12">

    <div class="row">
      <div class="col-xs-9"></div>
      <div class="col-xs-1 pointer" (click)="onRemoveLoginPopUp()"  >
       
          <svg  style="color: red" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
          </svg>
    
      </div>
      <div class="col-xs-1"></div>
    </div>


      <br>

      <h2 style="color: #3f51b5">login with google</h2>

      <!-- <form [formGroup]="emailEditForm" >
        <div class="form-group">
          <mat-form-field>
            <label for="email2"> email </label>
            <input type="text" 
                   id="email2" 
                   class="form-control"
                   formControlName="email2"
                   matInput >
          </mat-form-field>
    
        </div>
    </form>
    <button type="button" class="btn btn-secondary" (click)="onLoginInputEmail()"> login</button> -->
  
  </div>
</div>

<!-- <br> -->


<br>

<!-- END LOCAL LOGIN ---------------------------------------------  -->

<!-- google sign in button below -->

<div class="row">
    <div id="buttonDiv"></div> 
</div>

<!-- end google sign in button -->
