
<div *ngIf=chatIsVisible>

<div class="example-box" >
   

    <div class="col-xs-12">

        <div class="row">
            <div class="col-12">
                 <h2 style="color: #3f51b5;" >
                    <!-- {{ chatInterface.name | uppsercase }} -->
                    {{ chatInterface.name }}
                 </h2>
            </div>
              
        </div>


        <div class="row"   >
            <div class="col-12">
                <strong style="color: red">{{ chatInterface.lastSubject }}</strong>
            </div>
        </div>

        <div class="row" style="color: #3f51b5;" >
                <strong>{{ chatInterface.lastSender | truncate:30 }}</strong> 
        </div>

        <div class="row" style="color: #3f51b5;" >
            <div class="col-4 d-flex justify-content-start align-items-center">
                <small> {{getDayOfTheWeek(formResults[0].dateAndTime)}} </small>
            </div>
            <div class=" col-4 d-flex justify-content-center align-items-center">
                <small> {{ getDateTime_InDaysWeeksMonths(formResults[0].dateAndTime) }} </small>
            </div>
            <div class="col-4 d-flex justify-content-end align-items-center">
                <!-- <small>  {{ getDateTime_InMinuteHours(formResults[0].dateAndTime) | truncate:20 }}</small> -->
                <small>  {{ formResultsMinutesHoursAgoString[0] | truncate:20 }}</small>
            </div>
    
        </div>

        <div class="row" style="color: #3f51b5;" >
            <small>{{ formResults[0].dateAndTime | date : 'MMMM , dd yyyy  HH:mm'}}</small>
        </div>

        <br>

        <div *ngFor="let formResult of formResults; let i=index">
           
            <div *ngIf="i > 0 && i<formResults_VisualLimit"
                class="row" 
                style="color: red;" >
               <small>{{ formResult.subject | truncate:35 }}</small>
           </div>
           <div *ngIf="i > 0 && i<formResults_VisualLimit"
            class="row" 
            style="color: #3f51b5;" >
           <small>{{ formResult.sender.content }}</small>
           </div>

            <div *ngIf="i > 0 && i<formResults_VisualLimit"
                 class="row" 
                 style="color: #3f51b5;" >
                 <div class="col-4 d-flex justify-content-start align-items-center">
                     <small>{{getDayOfTheWeek(formResult.dateAndTime)}} </small>
                 </div>
                 <div class=" col-4 d-flex justify-content-center align-items-center">
                    <small> {{ getDateTime_InDaysWeeksMonths(formResult.dateAndTime) }} </small>
                </div>
                 <div class="col-4 d-flex justify-content-end align-items-center">
                    <!-- <small> {{ getDateTime_InMinuteHours(formResult.dateAndTime)}}</small> -->
                    <small> {{ formResultsMinutesHoursAgoString[i]}}</small>
                 </div>
            </div>
             <div *ngIf="i > 0 && i<formResults_VisualLimit"
                class="row" 
                style="color:  #3f51b5;" >
               <small>{{ formResult.dateAndTime | date : 'MMMM , dd yyyy  HH:mm' }}</small>
           </div>
           <div class="line-container">
            <div class="line"></div>
          </div>
          
           
        </div>
       
    </div>

</div>

</div>

