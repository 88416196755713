<app-triple-loading-spinner *ngIf="isLoadingList && !error"></app-triple-loading-spinner>

<div *ngIf="error">
     <br>
     <div *ngIf="error && !isLoadingList" 
          class="alert alert-danger">
          <h1>OOOPS ! Check Network Connection </h1>
                <p>{{error}}</p>
      </div>
</div>

<mat-card  *ngIf="!isLoadingList && !error" class="mat-elevation-z0"> 
<mat-tab-group #tabs 
[(selectedIndex)]="selectedIndex"
class="remove-border-bottom"
multi-features-header-less-tabs
(selectedTabChange)="onLinkClick($event)"    
headerPosition="below" 
animationDuration="0ms" >



      <mat-tab *ngFor="let singleDropDownSelect of singleDropDownSelects; index as i ">
        
          <app-triple-loading-spinner *ngIf="isLoading"></app-triple-loading-spinner>

           <app-single-drop-down-select-item
                          *ngIf="!isLoading"
                          [weAreInMultiFeatureEdit]="weAreInMultiFeatureEdit"
                          [isParagraphEdit]="isParagraphEdit" 
                          [noNeeded]="false" 
                          (deleteSingleFeatureResultEvent)='onDeleteSingleDropDownSelectItem()'
                          [index_id]="singleDropDownSelect._id">
          </app-single-drop-down-select-item>
       
          <br>

           <div>
               <ng-template mat-tab-label>
                    <a style="cursor: pointer">
                         {{i+1}} - {{singleDropDownSelect.name}} </a>
                    </ng-template>
           </div>
               
              

          
   
      </mat-tab>  
      
      <div *ngIf="createButtonLimit">
          <mat-tab disabled > 
               <ng-template  mat-tab-label>
                    <a (click)="onCreate()"> create new </a>
               </ng-template>
           </mat-tab>
      </div>

      

</mat-tab-group>


        

            <div *ngIf="singleDropDownSelects.length > 1" class="row">
               <div class="col">
                <button type="button" (click)="onPreviousButton()" class="btn btn-link pull-right"><<</button>
               </div>
        
               <div class="col">
                <button type="button" (click)="onNextButton()" class="btn btn-link">>></button>
               </div>
           </div>
          
           <div class="row">
               <div class="text-center">
                    <button disabled type="button" class="btn btn-link" >{{nextButtonIndex}} - {{nextButtonName}}</button>
               </div>
          </div>
         
          <div class="row">
               <div class="col"></div>
               <div class="col">
                    <div class="text-center">
                         <button type="button" class="btn btn-warning btn-sm pull-center" (click)="onCreate()">+</button>
                    </div>
               </div>
               <div class="col"></div>
          </div>

</mat-card>

