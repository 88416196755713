import { Component, OnInit, Input } from '@angular/core';
import { TabFeatureDetailResult } from './tab-feature-result.model';
import { FeatureResult } from '../feature-result.model';

@Component({
  selector: 'app-tab-feature-results',
  templateUrl: './tab-feature-results.component.html',
  styleUrls: ['./tab-feature-results.component.css']
})
export class TabFeatureResultsComponent implements OnInit {

  @Input() tabFeatureDetailResults: FeatureResult[]

  constructor() { }

  ngOnInit(){

    

    console.log(this.tabFeatureDetailResults)

  }

}
