import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "src/app/app-routing.module";
import { MaterialDesignModule } from "src/app/material-design/material-design.module";
import { SharedModule } from "src/app/shared/shared.module";
import { TabsComponent } from "./tabs.component";
import { TabListComponent } from './tab-list/tab-list.component';
import { TabItemComponent } from './tab-list/tab-item/tab-item.component';
import { TabEditComponent } from './tab-list/tab-edit/tab-edit.component';
import { MultiFeaturesModule } from "../multi-features-list/multi-features.module";
import { InputModule } from "../input/input.module";
import { ButtonsModule } from "../buttons/buttons.module";
import { TextsModule } from "../texts/texts.module";
import { DrappLayoutModule } from "../layout/drapp-layout.module";
import { CalendarModule } from "../calendar/calendar.module";
import { CalculatorModule } from "../calculator/calculator.module";
import { SelectModule } from "../select/select.module";
import { AuxiliaryComponentsModule } from "src/app/loading-spinner/auxiliary-components.module";

@NgModule({
    declarations: [
        TabsComponent,
        TabListComponent,
        TabItemComponent,
        TabEditComponent
    ],

    exports: [
        TabsComponent,
        TabListComponent,
        TabItemComponent,
        TabEditComponent
    ],

    imports: [
        AppRoutingModule,
        CommonModule,
        FormsModule,
        BrowserAnimationsModule,
        MaterialDesignModule, 
        ReactiveFormsModule,
        MatDialogModule,
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatExpansionModule,
        SharedModule,
        MultiFeaturesModule,
        InputModule,
        ButtonsModule,
        TextsModule,
        DrappLayoutModule,
        CalendarModule,
        CalculatorModule,
        SelectModule,
        AuxiliaryComponentsModule
    ]
})
export class TabsModule{}