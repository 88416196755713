import { NgModule } from "@angular/core";
import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { AppRoutingModule } from "../app-routing.module";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialDesignModule } from "src/app/material-design/material-design.module";
import {MatTableModule} from '@angular/material/table';
import { ContactsComponent } from "./contacts.component";
import { ContactListComponent } from './contact-list/contact-list.component';
import { ContactItemComponent } from './contact-list/contact-item/contact-item.component';
import { ContactEditComponent } from './contact-list/contact-edit/contact-edit.component';

@NgModule({

    declarations: [
        ContactsComponent,
        ContactListComponent,
        ContactItemComponent,
        ContactEditComponent,
    ],

    exports: [
        ContactsComponent, 
        ContactListComponent,
        ContactItemComponent,
        ContactEditComponent,
    ],

    imports: [

        LayoutModule,
        CommonModule,
        AppRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        MatButtonModule,
        MatDialogModule,
        MatExpansionModule,
        MatIconModule,
        MatListModule,
        MaterialDesignModule,
        BrowserAnimationsModule,
        MatTableModule,


    ]
})

export class ContactsModule {}