import { Injectable } from "@angular/core";
import { MatDialog , MatDialogConfig , MatDialogRef } from '@angular/material/dialog'; 
import { Observable } from 'rxjs' ; 
import { ShareFormIndexComponent } from "../share-form-index/share-form-index.component";


@Injectable ({
    providedIn: 'root'
})

export class FormIndexTopicDialog {

constructor ( private dialog: MatDialog ){}


    public ShareFormIndex (data: any =[]): Observable<any> {

        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass =''
        dialogConfig.disableClose = false ;
        dialogConfig.data = data;
        let dialogRef: MatDialogRef<ShareFormIndexComponent>;
        dialogRef = this.dialog.open(ShareFormIndexComponent, dialogConfig);
        return dialogRef.afterClosed();
    
    }




}