
<div *ngIf="isLoading" >

    <div class="ParagraphComponentFrame">
        <div style="color: #3f51b5; background-color: rgb(243, 250, 253); border-radius: 6px;" 
             class="col-xs-12">
             <div style="height: 120px"></div>
        </div>

        <div style="height: 400px;">
        </div>
    </div>
    <div class="ParagraphComponentFrame">
        <div style="color: #3f51b5; background-color: rgb(243, 250, 253); border-radius: 6px;" 
             class="col-xs-12">
             <div style="height: 120px"></div>
        </div>

        <div style="height: 400px;">
        </div>
    </div>

</div>

<div *ngIf="!isLoading">
<h2 *ngIf="noFormsResultYet" > 

    <div class="row" style="height: 100px"></div>
   
    <!-- IF IT'S DESKTOP POINT AT RESET BUTTON or go back to create component  -->
    <div class="d-flex justify-content-center" *ngIf="!(isHandset$ | async)">

            <div class="message-bubble" style="background-color:white ;">
                <div class="message-bubble__content">
                    no results for this search ... 
                    check filters & reset them 
                </div>
                <div *ngFor="let arrow of arrows" class="message-bubble__arrow" [ngClass]="arrow.direction">
                  <div class="message-bubble__arrow__inner" style="background-color:white;"></div>
                </div>
            </div>
    </div>

     <!-- END DESKTOP POINT ----------------------------------------------------  -->



    <!-- IF IT'S MOBILE MAKE  A RESET BUTTON with bubble saying there are no results for this search  -->

    <div *ngIf="isHandset$ | async">

        <div class="d-flex justify-content-center">

            <div class="message-bubble" style="background-color:white ;">
                <div class="message-bubble__content">
                    no results for this search ... 
                    check filters & reset
                </div>
                <div *ngFor="let arrow of arrows" class="message-bubble__arrow" [ngClass]="arrow.direction">
                  <div class="message-bubble__arrow__inner" style="background-color:white;"></div>
                </div>
            </div>
        </div>

        <div class="row footer">
            <div class="col">
                <div class="d-flex justify-content-center align-items-center" >
                    <h1 (click)="onOpenFilterSideNav()"  align="center" style="color: #3f51b5"  >
                        <svg  style="color: #3f51b5" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
                          </svg>
                          check filters
                    </h1>
                </div>
            </div>

            <div class="row" style="height:100px"></div>
        </div>
       
    </div>

     <!-- END IT'S MOBILE MAKE  A RESET BUTTON with bubble saying there are no results for this search  -->
</h2>
  
<div *ngFor="let formResult of formResults_Displaying.formResults_displayed ; let i=index">
    <app-form-result-item trackVisibility 
                    (visibile)="onComponentVisible($event , i)"
                    [formResultComingFromFormResultList]="youAreInFormResultsList"
                    [formResult]="formResult">
    </app-form-result-item>
</div>

<div *ngIf="isLoadingAdditionalResults" class="d-flex justify-content-center" >
    <app-loading-spinner></app-loading-spinner>
</div>

</div>





