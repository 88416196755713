import { Component, OnInit , Input, ViewChild, ElementRef, ChangeDetectionStrategy, 
         ChangeDetectorRef, HostListener, OnDestroy, Output, EventEmitter, NgZone} from '@angular/core';
import { Chat } from 'src/app/chats/chat.model';
import { FormResult } from 'src/app/forms/form-results/form-result.model';
import { FormResultsService } from 'src/app/forms/form-results/form-results.service';
import { ParagraphTopicService } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.service';
import { Subscription, Observable, Subject, interval } from 'rxjs';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { ChatService } from 'src/app/chats/chat.service';
import { LogoutService } from 'src/app/login/logout.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TimeAlignService } from 'src/app/services/other-components-services/time-align.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ErrorsDialogManagerService } from 'src/app/services/other-components-services/errors-dialog-manager.service';
import { Title } from '@angular/platform-browser';
import { debounceTime,map, shareReplay } from 'rxjs/operators';

interface TypeMessage_Mail_FromTo {
  type: string ,
  fromTo: string[]
}

interface Subject_Paragraph_Title {
  type: string , 
  title: string 
}

interface DateType_DateInfo {
  dateType: string , 
  dateInfo: string[]
}

interface FinalRoutingCall {
  messageMailInfos: TypeMessage_Mail_FromTo , 
  dateInfos: DateType_DateInfo, 
  titleInfo: Subject_Paragraph_Title
}

/**
 * form that have been stored and are present in our storage service
 */
interface FormResultStored_MessageLasSeq_MailLastSeq {
  formResultsStored: FormResult[] ,
  messageFirstSeq: number ,
  messageLastSeq: number , 
  messageFirstDateTime: string , 
  messageLastDateTime: string,
  messagesLength: number , 
  ismessagesDone: boolean ,
  mailFirstSeq: number ,
  mailLastSeq: number , 
  mailFirstDateTime: string, 
  mailLastDateTime: string ,
  mailsLength: number, 
  isMailsDone:boolean 

}

/**
 * forms that are visible on the DOM 
 */
interface FormResults_Displaying {
  formResults_displayed: FormResult[],
  indexOfResultDisplayed_Start: number ,
  indexOfResultDisplayed_End: number , 
  triggerIndex: number , 
  count: number 
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-chat-form-result-list',
  templateUrl: './chat-form-result-list.component.html',
  styleUrls: ['./chat-form-result-list.component.css']
})
export class ChatFormResultListComponent implements OnInit , OnDestroy {


  @ViewChild('list') list?: ElementRef<HTMLDivElement>;

  user: string 

  numberOfResultTo_Call_See: number = 6

  formResults: FormResult[];
  youAreInFormResultsList: boolean = true 

  formResultsStored: FormResultStored_MessageLasSeq_MailLastSeq
  formResults_Displaying: FormResults_Displaying

  isLoading: boolean 
  isLoadingAdditionalResults: boolean = false 

  noFormsResultYet: boolean; 


  updateOnUserEmail: Subscription
  routeParameterSubscription: Subscription

  timeIntervalSubscription: Subscription 
  onCloseLoginDialogPopUpSubscription: Subscription 
  initialFormResultsListHasBeenStoredSubscription: Subscription

  // arrows: { direction: string }[] = [{ direction: 'bottom' }];

  /**
   * debouncer subscriptions
   */
  debounceSubject: Subject<any> = new Subject<any>();
  debounceObservable: Observable<any>;
  debounceSubscription: Subscription;

  allFormResultList_ForThisSearch_hasBeenFoundSubscription: Subscription 
  subscriptionToSuccessiveFormResultList: Subscription
  formResultComponentOffsetBottom: number
  @Output() scrollFormResultToBottom = new EventEmitter<number>()



  constructor(private formResultsService: FormResultsService , 
              private paragraphTopicService: ParagraphTopicService,
              private userObjectUpdateService: UserObjectUpdateService ,
              private chatService: ChatService , 
              private logoutService: LogoutService ,
              private breakpointObserver: BreakpointObserver, 
              private timeAlignService: TimeAlignService , 
              private route: ActivatedRoute ,
              private errorsDialogManagerService: ErrorsDialogManagerService ,
              private cdr: ChangeDetectorRef ,
              private ngZone: NgZone ,
              private titleService: Title , 
              private router: Router 
              ) {}

  
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
      if (document.hidden){
            console.log("Page is hidden" , new Date());
      } else {
            console.log("Page is visible" , new Date());
      }
  }



 ngOnInit(){

  this.user = this.userObjectUpdateService.userEmail

  this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
  .subscribe((updatedUserEmail)=>{
   this.user = updatedUserEmail
   console.log('updated USER = ', this.user)
  })
   
  this.isLoading = true

  this.formResultsService.onAdviseResultsPageformResultListIsSelected()
    // { path: 'selected-group/:index/:name', component: ChatFormResultListComponent   }
  
  this.routeParameterSubscription = this.route.params
        .subscribe(
          (params:Params) => {
            this.adaptResultList(params)
          }
        ) // END subscription to URL parameters 
  
  this.timeIntervalSubscription = interval(60000).subscribe(() => {
    // code to be executed every minute
      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "visible") { 
          this.visibilitychange()
         // this.route.params.subscribe((params:Params) => this.changeFormResult_id(params)) 
        }
    });
   
    console.log('Task executed every minute updating relative times');
  });

  this.onCloseLoginDialogPopUpSubscription = this.logoutService.getClickToCloseLoginDialogPopUp()
      .subscribe(()=> {
      this.getFormResults_Null_User()
  })

 /**
  * this subscription comes as the user was logget out and is trying to go directly to a route for
  * formResultsList , so after receiving basic initial formresults we dowload the route parameters instead 
  * and subsctitute the formresults with just the ones got from parameters 
  */
 this.initialFormResultsListHasBeenStoredSubscription = this.formResultsService.firstFormResultsList_messageLastSeq_mailLastSeq_haveBeenStored
     .subscribe((firstCallOn_AllType_FormResults: FormResultStored_MessageLasSeq_MailLastSeq)=>{
      this.isLoading = true 
      console.log('CLEAR THE FORMS RESULTS AT INITIAL AND SEVICE, FROM FIRST CALL, JUST TO MAKE SURE')
      
      this.showInitial_FormResults_block(firstCallOn_AllType_FormResults)

 })

 this.debounceObservable = this.debounceSubject.asObservable().pipe(debounceTime(300)); // Adjust the debounce time as needed (e.g., 300 milliseconds)
 this.debounceSubscription = this.debounceObservable
     .subscribe(() => {
     this.callNextBlockOf_formResults(); // Replace with your function to be debounced
 });

 this.allFormResultList_ForThisSearch_hasBeenFoundSubscription = this.formResultsService.allFormResults_List_haveBeenStored
     .subscribe(()=>{
      console.log('done with all calls, show all the remaining formResults and stop loading ')
      this.showAllRemainingFormResults()
     })

this.subscriptionToSuccessiveFormResultList = this.formResultsService.successive_FormResultsList_messageLastSeq_mailLastSeq_haveBeenStored
       .subscribe((successiveCallOnFormResultsList: FormResultStored_MessageLasSeq_MailLastSeq)=> {
         this.showNextFormResults_Block( successiveCallOnFormResultsList )
       })


  }

  getFormResults_Null_User(){
    this.router.navigate(['create-form'], {relativeTo: this.route.parent});
  }

  callNextBlockOf_formResults(){
    console.log("call next block of results TO DO ")
    if ( this.user === null ){ console.log('NULL USER MAYBE DO SOME EASY FILTERS ON ALL FORM RESULTS')}
    if ( this.user !== null ){
      const isFirst_Call: boolean = false 
      /**
       * start loading next
       */
      this.ngZone.run(() => {
        this.isLoadingAdditionalResults = true 
      });
      this.cdr.detectChanges();
      this.formResultsService.isNowCalling_FormResultList = true
      this.continueToGetFormResultsList_accordingToRoutParams( isFirst_Call) 
    }
  }

  showNextFormResults_Block( successiveCallOnFormResultsList: FormResultStored_MessageLasSeq_MailLastSeq){

    this.formResultsStored = successiveCallOnFormResultsList
    console.log(this.formResultsStored , successiveCallOnFormResultsList )
  
    if ( this.formResultsStored.ismessagesDone && this.formResultsStored.isMailsDone ){
         console.log('BOTH MESSAGE AND EMAIL ARE DONE , SHOW REMAINING RESULTS LIST')
         this.showAllRemainingFormResults()
    }
  
    if ( !this.formResultsStored.ismessagesDone || !this.formResultsStored.isMailsDone ){
          console.log('STILL REMAIN RESULTS LIST TO CHECK')
          const newSliceStaringPoint: number = this.formResults_Displaying.formResults_displayed.length
          const newSliceEndingPoint: number = newSliceStaringPoint+this.numberOfResultTo_Call_See
  
          const newFormResults_Displayed: FormResult[] = this.formResultsService.getFormResultsList_SortBy_Date_fromLast_toFirst()
                                                                          .slice(newSliceStaringPoint, newSliceEndingPoint); 
  
    
          this.ngZone.run(() => {
               this.formResults_Displaying.formResults_displayed.push(...newFormResults_Displayed)
               this.isLoadingAdditionalResults = false
          });
  
          this.cdr.detectChanges()
    }
  
  
  }

  adaptResultList(params: Params){

    this.chatService.onInformChatComponent_AChatHasBeenSelected()

    /**
     *  check ifuser is NULL 
     *  if null ask to login 
     *  if login it's denied then just show local undefined temporary results 
     *  if USER !null then check what kind of call 
     *  message / mail calls 
     *  define exact SERVER time with local jetLag to fit local 00:00 and 23:59 
     *  then send request/s
     */
  
    /**
     * :groupSelected/:groupName
     */

     // { path: 'selected-group/:index/:name', component: ChatFormResultListComponent   }
  
    console.log("PARAMS = ", params )
    console.log( params['index'] , params['name'] )
  
    if ( this.user === null ){ 
        console.log('LOGIN POP UP, CHOOSE WHETHER TO LOGIN OR it will do GetFormResults_Null_User at 315')
        /**
         * LOGIN POP UP
         */
        this.errorsDialogManagerService.LoginPopUpDialog()
         
    }
    if ( this.user !== null ){ 
                    this.isLoading = true
                    console.log("WE ARE STOPPING THE INDEX CALLS WITH THIS isNowCalling_FormResultList PROPERTY ON FORMRESULTSERVICE")
                    this.formResultsService.isNowCalling_FormResultList = true
                    const isFirst_Call = true 
                    this.continueToGetFormResultsList_accordingToRoutParams(isFirst_Call) 
    }
    
  }

  continueToGetFormResultsList_accordingToRoutParams( isFirst_Call : boolean ){

    let browserTitleToConcat: string[] = [] // this is for setting th browser title on the TAB
  
    browserTitleToConcat.push( 'group: ' )
    browserTitleToConcat.push( this.route.snapshot.params['name'] )

    const newTabTitle: string = browserTitleToConcat.join('')
    this.titleService.setTitle(newTabTitle)
    /**
     *  END BRWSER TAB TITLE --------------------------------
     */
   
    console.log("WE ARE STOPPING THE INDEX CALLS WITH THIS isNowCalling_FormResultList PROPERTY ON FORMRESULTSERVICE")
    console.log('WE ARE NOW GEETING THE PARAMS FORM RESULTS AND STOP LOADING', this.route.snapshot.params)
  
  /**
   * this type of calls coming from the route-parameter subscription are always the FIRST call 
   */ 
   let newFormResultStored: FormResultStored_MessageLasSeq_MailLastSeq
   if ( isFirst_Call ){ 
    
       const newFormResultsStored: FormResult[] = []
       const newMessageFirstSeq = undefined 
       const newMessageLastSeq = undefined
       const newMessageFirstDateTime = undefined
       const newMessageLastDateTime = undefined
       const newMessagesLength = 0
       const newIsMessagesDone = true
       const newMailFirstSeq = undefined 
       const newMailLastSeq = undefined 
       const newMailFirstDateTime = undefined 
       const newMailLastDateTime = undefined 
       const newMailsLength = 0 
       const newIsMailsDone = true

             newFormResultStored = { formResultsStored: newFormResultsStored, 
                                     messageFirstSeq: newMessageFirstSeq ,
                                     messageLastSeq: newMessageLastSeq ,
                                     messageFirstDateTime: newMessageFirstDateTime,
                                     messageLastDateTime:newMessageLastDateTime ,
                                     messagesLength: newMessagesLength , 
                                     ismessagesDone: newIsMessagesDone , 
                                     mailFirstSeq: newMailFirstSeq,
                                     mailLastSeq: newMailLastSeq,
                                     mailFirstDateTime: newMailFirstDateTime,
                                     mailLastDateTime: newMailLastDateTime , 
                                     mailsLength: newMailsLength , 
                                     isMailsDone: newIsMailsDone 
                                    } 
   }
  
   if ( !isFirst_Call ){ newFormResultStored = this.formResultsStored }
  
  // params['groupSelected'], params['groupName'],
  
   /**
    * IF IS THE FIRST CALL 
    * SET THE FORM RESULT DISPLAYNG TO STARTING POINT 
    * send a subject to formresultFilter to check if the filter has the same setting , or adjust it  
    */
   if ( isFirst_Call ){
    this.formResults_Displaying = { formResults_displayed: [], indexOfResultDisplayed_Start: null , indexOfResultDisplayed_End: null , triggerIndex: this.numberOfResultTo_Call_See , count: this.numberOfResultTo_Call_See }
    this.formResultsService.get_InitalCHAT_FormResultsList_byUserChatRoomId_Count( this.user , this.route.snapshot.params['index'] , this.formResults_Displaying.count, this.formResultsStored   )
  }

   if ( !isFirst_Call ){
    this.formResultsService.get_Next_CHAT_FormResultsList_byUserChatRoomId_Count_LastSeq(this.user, this.route.snapshot.params['index'], this.formResults_Displaying.count, this.formResultsStored  )
  }

  };

  showInitial_FormResults_block(firstCallOn_AllType_FormResults: FormResultStored_MessageLasSeq_MailLastSeq){

    this.formResultsStored = firstCallOn_AllType_FormResults
      console.log( firstCallOn_AllType_FormResults , this.formResultsStored )

      // SEND TO CHAT SERVICE THE SELECTED RECIPIENT
      this.chatService.sendChatFromChatItemIsDisplayed(this.formResultsStored.formResultsStored[0].recipientResult)
  
      if ( this.formResultsStored.ismessagesDone && this.formResultsStored.isMailsDone ){
        console.log('BOTH MESSAGE AND EMAIL ARE DONE , SHOW REMAINING RESULTS LIST')
        const newFormResults_Displayed: FormResult[] = this.formResultsService.getFormResultsList_SortBy_Date_fromLast_toFirst().slice(0, this.numberOfResultTo_Call_See); 
        this.formResults_Displaying.formResults_displayed = newFormResults_Displayed
  
        this.showAllRemainingFormResults()
      }
  
      if ( !this.formResultsStored.ismessagesDone || !this.formResultsStored.isMailsDone ){
        console.log('show formresults')
         /**
       * slice the first block out of all stored formresults starting from last one
       */
      this.ngZone.run(()=>{
        const newFormResults_Displayed: FormResult[] = this.formResultsService.getFormResultsList_SortBy_Date_fromLast_toFirst().slice(0, this.numberOfResultTo_Call_See); 
         this.formResults_Displaying.formResults_displayed = newFormResults_Displayed
         this.isLoading = false
      })
      this.cdr.detectChanges()
    
      }   
  }

  /**
   * adding the latest leftOver hidden formResults
   */
showAllRemainingFormResults(){
  const lastCurrentFormResultIndex = this.formResults_Displaying.formResults_displayed.length
  const remainingFormResults: FormResult[] =  this.formResultsService.getFormResultsList_SortBy_Date_fromLast_toFirst().slice(lastCurrentFormResultIndex)

  
  this.ngZone.run(() => {
  this.formResults_Displaying.formResults_displayed.push(...remainingFormResults)
  this.isLoading = false
  this.isLoadingAdditionalResults = false
  });
  console.log("FINAL RESULTS => ", this.formResults_Displaying.formResults_displayed )

  this.cdr.detectChanges();
  // this.isLoadingAdditionalResults = false

  this.stopLoading()


}

onANewFormResultHasArrived(){

}





  ngAfterViewInit() {
    const maxScroll = this.list?.nativeElement.scrollHeight;
    this.list?.nativeElement.scrollTo({ top: maxScroll, behavior: 'smooth' });
  };



  // onANewFormResultHasArrived(){

  //   this.formResults = this.formResultsService.getFormResults()

  //   console.log('a new form Result has arrived ' , this.formResults)

  //   this.checkFormResultsSubjectsAndSendToChatParagraphIndex()

  //   // check current formResultList subject and send list to chat-paragraph-index-

  //   setTimeout(()=>{
  //     const maxScroll = this.list?.nativeElement.scrollHeight;
  //     this.list?.nativeElement.scrollTo({ top: maxScroll, behavior: 'smooth' });
  //     console.log('is scrolling to the end ')
  //   },200)
   
  // }

  stopLoading(){
    this.isLoading = false 
  }



  ngOnDestroy():void{
    this.updateOnUserEmail.unsubscribe()
    this.timeIntervalSubscription.unsubscribe()
    this.routeParameterSubscription.unsubscribe()
    this.initialFormResultsListHasBeenStoredSubscription.unsubscribe()
    this.allFormResultList_ForThisSearch_hasBeenFoundSubscription.unsubscribe()
    this.subscriptionToSuccessiveFormResultList.unsubscribe()
 
  }

}
