import { Injectable } from '@angular/core';
import { FormIndexTopic } from './form-index-topic.model';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { Subscription , Subject} from 'rxjs';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { FormFeatureContainerService } from './form-feature-container.service';



@Injectable ({
    providedIn: 'root' 
})

export class FormIndexTopicService {

    trialFormIndexTopics: FormIndexTopic[]

    formIndexTopic: FormIndexTopic ;

    updateFormIndexTopic = new Subject<FormIndexTopic[]>()

    constructor( 
                 private initialNullSetupService: InitialNullSetupService , 
                 private userObjectUpdateService: UserObjectUpdateService , 
                 private formFeatureContainerService: FormFeatureContainerService,
                 ){}

    /**
     * export class FormFeature {

    constructor ( public _id: string, 
                  public owner: string, 
                  public dateAndTime: string,
                  public isAccepted: boolean, 
                  public allUsersAllowed: boolean,
                  public userListAllowed: string[],
                  public isBeenShared: boolean, 
                  public formId: number  , 
                  public subject: string ){}
    
    }
     */
    user = this.userObjectUpdateService.userEmail

    foundEmailList: string[] = []

    updateOnUserEmail : Subscription = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
        this.user = updatedUserEmail
        console.log('updated USER = ', this.user)
    });

    public formIndexTopics : FormIndexTopic[] = this.initialNullSetupService.initialNullFormIndexTopics


    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.formIndexTopicsChangeBackToNull
   .subscribe((res)=> {
          this.formIndexTopics = res 
          console.log('updated formIndexTopics back to null = ', res)

    });
   
    
    
    getFormIndexTopic(id: number){
        return this.formIndexTopics[id];
    }

    getFormIndexTopics(){
        return this.formIndexTopics ; 
    }

    getFormIndexTopicBy_id(_id: string){
        const formIndexTopic: FormIndexTopic = this.formIndexTopics.find(
            (res)=> {
                return res._id === _id
            }
        )
        return formIndexTopic
    }

    updateFormIndexTopic_only(formIndexTopic: FormIndexTopic){

        const index = this.formIndexTopics.findIndex(x=> x._id === formIndexTopic._id)
        this.formIndexTopics[index] = formIndexTopic 

        this.updateInitialComponents(this.formIndexTopics)

    }


    addFormIndexTopic(formIndexTopic: FormIndexTopic){
        if ( this.formIndexTopics.findIndex(x=> x._id === formIndexTopic._id) === -1){
        this.formIndexTopics.push(formIndexTopic)
        this.updateInitialComponents(this.formIndexTopics)
        }
    }

    deletePublicFormIndexTopic(formIndexTopic_id: string){
        console.log('deleting => ', formIndexTopic_id)
        const formIndexTopicOfReference = this.formIndexTopics[this.formIndexTopics.findIndex(x=> x._id === formIndexTopic_id)]
        this.formFeatureContainerService.deletePublicFormFeatureContainers( formIndexTopicOfReference.formFeatureContainer_ids)
        this.formIndexTopics.splice(this.formIndexTopics.findIndex(x=> x._id === formIndexTopic_id), 1 )

    }

    updateInitialComponents(formIndexTopics: FormIndexTopic[]){
        this.initialNullSetupService.initialNullFormIndexTopics = formIndexTopics
    }

    


}