
<app-header class="mat-elevation-z6"
            (firstCheckOnUserDone)="onUserFirstCheck($event)"
            (itStartLoadingSpinner)="onStartedLoadingSpinner()"
            (itStopLoadingSpinner)="onStoppedLoadingSpinner()"  >

</app-header>
    
<router-outlet *ngIf="confirmedStatus" ></router-outlet>

<div  *ngIf="!confirmedStatus" >
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<br>
<div class="row">
    <div class="col-xs-12">
        <div class="col-xs-3"></div>
        <div class="col-xs-6">
            <app-loading-spinner></app-loading-spinner>
        </div>
        <div class="col-xs-3"></div>
    </div>
</div>
</div>


       