
import { ToggleWordButtonDetailResult } from "./toggle-word-button-result/toggle-word-button-detail-result.model";
import { WordInputDetailResult } from "./word-input-result/word-input-detail-result.model"; 
import { WordDetailResult } from "./word-result/word-detail-result.model";
import { SingleFeatureDetailResult } from "./single-feature-results/single-feature-detail-result.model";
import { TabFeatureDetailResult } from "./tab-feature-results/tab-feature-result.model";
import { FixLayoutDetailResult } from "./fix-layout-results/fix-layout-detail-result.model";
import { FixTextDetailResult } from "./fix-text-result/fix-text-detail-result.model";
import { OneDateCalendarDetailResult } from "./one-date-calendar-result/one-date-calendar-result.model";
import { MultiEntryCalendarDetailResult } from "./multi-entry-calendar-result/multi-entry-calendar-result.model";
import { CalculatorMixTableDetailResult } from "./calculator-mix-table-result/calculator-mix-table-result.model";
import { DropDownSelectDetailResult } from "./drop-down-select-result/drop-down-select-detail-result.model";
import { CheckBoxDetailResult } from "./check-box-result/check-box-result.model";
import { CommentDetailResult } from "./comment-result/comment-detail-result.model";
import { SharedCalendarStatusResult } from "./shared-calendar-status-result/shared-calendar-status-result.model";

export class FeatureResult {

    constructor (
        public featureResultType: string,
        public featureResultName: string,
        public featureDetailResults: SingleFeatureDetailResult[]|
                                     ToggleWordButtonDetailResult[]|
                                     WordInputDetailResult[] | 
                                     WordDetailResult[] |
                                     TabFeatureDetailResult[]|
                                     FixLayoutDetailResult |
                                     FixTextDetailResult[] |
                                     OneDateCalendarDetailResult[] |
                                     MultiEntryCalendarDetailResult |
                                     CalculatorMixTableDetailResult |
                                     DropDownSelectDetailResult[] |
                                     CheckBoxDetailResult |
                                     CommentDetailResult |
                                     SharedCalendarStatusResult

                                     
    ){}
}