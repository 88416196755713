import { Component, OnInit } from '@angular/core';

interface LocationData {
  city: string;
  state: string;
  country: string;
  population: number;
}

@Component({
  selector: 'app-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.css']
})
export class SelectListComponent implements OnInit {

  countries = ['USA', 'Canada', 'Mexico'];
  states = [];
  cities = [];

  selectedCountry: string;
  selectedState: string;
  selectedCity: string;
  population: number;


 

  constructor() { }

  ngOnInit(): void {
  }

  onCountrySelected() {
    // TODO: Load states for selected country
    if (this.selectedCountry === 'USA') {
      this.states = ['California', 'New York', 'Texas'];
    } else if (this.selectedCountry === 'Canada') {
      this.states = ['Ontario', 'Quebec', 'Alberta'];
    } else if (this.selectedCountry === 'Mexico') {
      this.states = ['Jalisco', 'Nuevo León', 'Veracruz'];
    } else {
      this.states = [];
    }
    this.selectedState = null;
    this.selectedCity = null;
    this.cities = [];
  }

  onStateSelected() {
    // TODO: Load cities for selected state
    if (this.selectedState === 'California') {
      this.cities = ['Los Angeles', 'San Francisco', 'San Diego'];
    } else if (this.selectedState === 'New York') {
      this.cities = ['New York City', 'Buffalo', 'Rochester'];
    } else if (this.selectedState === 'Texas') {
      this.cities = ['Houston', 'Dallas', 'Austin'];
    } else if (this.selectedState === 'Ontario') {
      this.cities = ['Toronto', 'Ottawa', 'Mississauga'];
    } else if (this.selectedState === 'Quebec') {
      this.cities = ['Montreal', 'Quebec City', 'Laval'];
    } else if (this.selectedState === 'Alberta') {
      this.cities = ['Calgary', 'Edmonton', 'Red Deer'];
    } else if (this.selectedState === 'Jalisco') {
      this.cities = ['Guadalajara', 'Zapopan', 'Tlaquepaque'];
    } else if (this.selectedState === 'Nuevo León') {
      this.cities = ['Monterrey', 'San Nicolás de los Garza', 'Escobedo'];
    } else if (this.selectedState === 'Veracruz') {
      this.cities = ['Veracruz', 'Xalapa', 'Coatzacoalcos'];
    } else {
      this.cities = [];
    }
    this.selectedCity = null;
  }

  onCitySelected() {
    // TODO: Emit selected location data
    const locationData: LocationData = {
      city: this.selectedCity,
      state: this.selectedState,
      country: this.selectedCountry,
      population: this.population
    };
    // TODO: Emit the location data for the parent component to consume
  
    // Reset the state and city dropdowns
    this.states = [];
    this.cities = [];
  
    // Load states for the selected country
    if (this.selectedCountry === 'USA') {
      this.states = ['California', 'New York', 'Texas'];
    } else if (this.selectedCountry === 'Canada') {
      this.states = ['Ontario', 'Quebec', 'British Columbia'];
    } else if (this.selectedCountry === 'Mexico') {
      this.states = ['Jalisco', 'Mexico City', 'Nuevo León'];
    }
  
    // Load cities for the selected state
    if (this.selectedState === 'California') {
      this.cities = ['Los Angeles', 'San Francisco', 'San Diego'];
    } else if (this.selectedState === 'New York') {
      this.cities = ['New York City', 'Buffalo', 'Rochester'];
    } else if (this.selectedState === 'Texas') {
      this.cities = ['Houston', 'Dallas', 'San Antonio'];
    } else if (this.selectedState === 'Ontario') {
      this.cities = ['Toronto', 'Ottawa', 'Mississauga'];
    } else if (this.selectedState === 'Quebec') {
      this.cities = ['Montreal', 'Quebec City', 'Gatineau'];
    } else if (this.selectedState === 'British Columbia') {
      this.cities = ['Vancouver', 'Victoria', 'Kelowna'];
    } else if (this.selectedState === 'Jalisco') {
      this.cities = ['Guadalajara', 'Puerto Vallarta', 'Tlaquepaque'];
    } else if (this.selectedState === 'Mexico City') {
      this.cities = ['Mexico City', 'Toluca', 'Nezahualcoyotl'];
    } else if (this.selectedState === 'Nuevo León') {
      this.cities = ['Monterrey', 'San Nicolás de los Garza', 'Apodaca'];
    }
  }
  

  // onCountrySelected() {
  //   // TODO: Load states for selected country
  //   this.states = ['California', 'New York', 'Texas'];
  // }

  // onStateSelected() {
  //   // TODO: Load cities for selected state
  //   this.cities = ['Los Angeles', 'San Francisco', 'New York City', 'Houston'];
  // }

  // onCitySelected() {
  //   // TODO: Emit selected location data
  //   const locationData: LocationData = {
  //     city: this.selectedCity,
  //     state: this.selectedState,
  //     country: this.selectedCountry,
  //     population: this.population
  //   };
  //   // TODO: Emit the location data for the parent component to consume
  // }

}
