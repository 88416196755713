import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormIndexTopicListItem } from '../form-index-topic-list/form-index-topic-list-item.model';
import { FormIndexTopic } from '../form-index-topic-list/form-index-topic.model';
import { FormFeatureContainer } from '../form-index-topic-list/form-feature-container.model';
import { ParagraphTopicIndex } from '../form-edit/form-edit-paragraphs-index/paragraph-topic-index.model';
import { ParagraphTopic } from '../form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.model';
import { ParagraphFeatureContainer } from '../form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature-container.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { Subscription } from 'rxjs';
import { AddSharedFormOrParagraphService } from './add-shared-form-or-paragraph.service';
// import { StorePersonalFeatureIndexComponentsService } from 'src/app/services/store-initial-components/store-personal-feature-index-components.service';

interface SharedParagraphForm_Dialog_BottomSheet_Data {
  typeOfComponent: string , 
  formType: FormType ,
  paragraphType: ParagraphType, 
}

interface FormType {
  formIndexTopic: FormIndexTopic ,
  formFeatureContainer: FormFeatureContainer
}

interface ParagraphType {
  paragraphTopic: ParagraphTopic,
  paragraphFeatureContainer: ParagraphFeatureContainer
}

@Component({
  selector: 'app-add-shared-form-or-paragraph',
  templateUrl: './add-shared-form-or-paragraph.component.html',
  styleUrls: ['./add-shared-form-or-paragraph.component.css']
})
export class AddSharedFormOrParagraphComponent implements OnInit , OnDestroy {

  user: string 

  updateOnUserEmail: Subscription 

  sharedParagraphForm_Dialog_BottomSheet_Data: SharedParagraphForm_Dialog_BottomSheet_Data

  updateOnSharedParagraphFormsSubscription: Subscription

  decicatedPhrase: string 

  constructor(private userObjectUpdateService: UserObjectUpdateService,
               public dialogRef: MatDialogRef<AddSharedFormOrParagraphComponent>, 
               private addSharedFormOrParagraphService: AddSharedFormOrParagraphService , 
              //  private storePersonalFeatureIndexComponentsService: StorePersonalFeatureIndexComponentsService,
              @Inject(MAT_DIALOG_DATA) public dialogData: any
              ) { }

  ngOnInit(): void {

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
        .subscribe((updatedUserEmail)=>{
         this.user = updatedUserEmail
        console.log('updated USER = ', this.user)
    })

    console.log( 'dialogData => ', this.dialogData  )

    this.sharedParagraphForm_Dialog_BottomSheet_Data = this.dialogData.sharedParagraphForm_Dialog_BottomSheet_Data

 if ( this.sharedParagraphForm_Dialog_BottomSheet_Data.typeOfComponent === 'paragraphFeatureContainer' ){ 
      this.decicatedPhrase = "accept or decline paragraph"
 }

 if ( this.sharedParagraphForm_Dialog_BottomSheet_Data.typeOfComponent === 'paragraphTopic' ){
  this.decicatedPhrase = "accept or decline paragraphs from this user"
 }

 if ( this.sharedParagraphForm_Dialog_BottomSheet_Data.typeOfComponent === 'formFeatureContainer' ){
  this.decicatedPhrase = "accept or decline page"
}

 if ( this.sharedParagraphForm_Dialog_BottomSheet_Data.typeOfComponent === 'formIndexTopic' ){
  this.decicatedPhrase = "accept or decline pages from this user"
}


    this.updateOnSharedParagraphFormsSubscription = this.addSharedFormOrParagraphService.doneAddingUpdatingSharedParagraphForm
        .subscribe((res)=> {
          console.log('close dialog')
          this.dialogRef.close()

        })
  }


  onAccept(){

    this.sharedParagraphForm_Dialog_BottomSheet_Data = this.dialogData.sharedParagraphForm_Dialog_BottomSheet_Data

    if ( this.sharedParagraphForm_Dialog_BottomSheet_Data.typeOfComponent === 'paragraphFeatureContainer' ){ 
         console.log('is paragraphFeatureContainer')
         this.addSharedFormOrParagraphService.onAcceptDeclineParagraphFeatureContainer(this.user, this.sharedParagraphForm_Dialog_BottomSheet_Data.paragraphType.paragraphTopic,  this.sharedParagraphForm_Dialog_BottomSheet_Data.paragraphType.paragraphFeatureContainer , true )
    }

    if ( this.sharedParagraphForm_Dialog_BottomSheet_Data.typeOfComponent === 'paragraphTopic' ){
         console.log('is paragraphTopic')
         this.addSharedFormOrParagraphService.onAcceptDeclineParagraphTopic(this.user, this.sharedParagraphForm_Dialog_BottomSheet_Data.paragraphType.paragraphTopic , true )
    }

    if ( this.sharedParagraphForm_Dialog_BottomSheet_Data.typeOfComponent === 'formFeatureContainer' ){
         console.log( 'is formFeatureContainer')
         this.addSharedFormOrParagraphService.onAcceptDeclineFormFeatureContainer(this.user, this.sharedParagraphForm_Dialog_BottomSheet_Data.formType.formIndexTopic, this.sharedParagraphForm_Dialog_BottomSheet_Data.formType.formFeatureContainer, true)
    }

    if ( this.sharedParagraphForm_Dialog_BottomSheet_Data.typeOfComponent === 'formIndexTopic' ){
         console.log('is formIndexTopic')
         this.addSharedFormOrParagraphService.onAcceptDeclineFormIndexTopic(this.user, this.sharedParagraphForm_Dialog_BottomSheet_Data.formType.formIndexTopic , true )
    }

  }

  onDecline(){

    this.sharedParagraphForm_Dialog_BottomSheet_Data = this.dialogData.sharedParagraphForm_Dialog_BottomSheet_Data

    if ( this.sharedParagraphForm_Dialog_BottomSheet_Data.typeOfComponent === 'paragraphFeatureContainer' ){ 
         console.log('is paragraphFeatureContainer')
         this.addSharedFormOrParagraphService.onAcceptDeclineParagraphFeatureContainer(this.user, this.sharedParagraphForm_Dialog_BottomSheet_Data.paragraphType.paragraphTopic, this.sharedParagraphForm_Dialog_BottomSheet_Data.paragraphType.paragraphFeatureContainer , false )
    }
    if ( this.sharedParagraphForm_Dialog_BottomSheet_Data.typeOfComponent === 'paragraphTopic' ){
         console.log('is paragraphTopic')
         this.addSharedFormOrParagraphService.onAcceptDeclineParagraphTopic(this.user, this.sharedParagraphForm_Dialog_BottomSheet_Data.paragraphType.paragraphTopic , false )
    }
    if ( this.sharedParagraphForm_Dialog_BottomSheet_Data.typeOfComponent === 'formFeatureContainer' ){
         console.log( 'is formFeatureContainer')
         this.addSharedFormOrParagraphService.onAcceptDeclineFormFeatureContainer(this.user, this.sharedParagraphForm_Dialog_BottomSheet_Data.formType.formIndexTopic, this.sharedParagraphForm_Dialog_BottomSheet_Data.formType.formFeatureContainer, false )
    }
    if ( this.sharedParagraphForm_Dialog_BottomSheet_Data.typeOfComponent === 'formIndexTopic' ){
         console.log('is formIndexTopic')
         this.addSharedFormOrParagraphService.onAcceptDeclineFormIndexTopic(this.user, this.sharedParagraphForm_Dialog_BottomSheet_Data.formType.formIndexTopic , false )
    }

    // this.storePersonalFeatureIndexComponentsService.getPersonalUpdatedFeatureIndex_components(this.user)
    //     .subscribe((res: any) =>{
    //      console.log(res)
         
    //      const httpFeatureIndexComponentResult: any = res 
    //      // go to nect function and chech if paragraph already exist in updated paragraphFeatureContainer
    //      // and if paragraphTopic already exist 
       
    //       const newParagraphTopicIndexes: ParagraphTopicIndex[] = httpFeatureIndexComponentResult.paragraphTopicIndex
    //       console.log(newParagraphTopicIndexes)
    //       // this.paragraphTopicIndexService.paragraphTopicIndexes = newParagraphTopicIndexes
    //       // this.initialNullSetupService.initialNullParagraphTopicIndexes = newParagraphTopicIndexes
         
    //       const newParagraphTopics: ParagraphTopic[] = httpFeatureIndexComponentResult.paragraphTopic
    //       console.log(newParagraphTopics)
    //       // this.paragraphTopicService.paragraphTopics = newParagraphTopics
    //       // this.initialNullSetupService.initialNullParagraphTopics = newParagraphTopics 
       
    //       const newParagraphFeatureContainers: ParagraphFeatureContainer[] = httpFeatureIndexComponentResult.paragraphFeatureContainer 
    //       console.log(newParagraphFeatureContainers)
    //       // this.paragraphFeatureContainerService.paragraphFeatureContainers = newParagraphFeatureContainers
    //       // this.initialNullSetupService.initialNullParagraphFeatureContainers = newParagraphFeatureContainers

    //     })
  }

  /**
   * always check on latest featureIndex before adding 
   * always correct the latest UIID complete name before adding 
   */

  ngOnDestroy(): void {
      this.updateOnUserEmail.unsubscribe()
      this.updateOnSharedParagraphFormsSubscription.unsubscribe()
  }


}
