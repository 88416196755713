<!-- <p>single-word-input-result works!</p> -->


<div class="row">
    <div class="col-xs-12">

       <form #f="ngForm">
          <div> 

          <div class="row" >
            <div class="col-xs-12">

              <div ngModelGroup="singleWordInputDetailResult"> 
                  <table class="full-width" cellspacing="0">
                    <tr>

                           <div class="form-group">
                                 <td> 
                                   <div>
                                       <mat-form-field >
                                       <mat-label [(innerText)]="singleWordInputDetailResult.singleWordInputDetailResultLabel"></mat-label> 
                                       <input 
                                       disabled
                                       matInput 
                                       ngModel="{{singleWordInputDetailResult?.singleWordInputDetailResultContent}}"
                                       name="singleWordInputDetailResultContent" >
                                       <mat-hint>  {{ singleWordInputDetailResult?.singleWordInputDetailResultHint }} </mat-hint>

                                       </mat-form-field>
                                   </div>
                                </td> 
                           </div>  

                    </tr>
                 </table>
                </div>

            </div>
          </div>
          
         </div>
       </form>

    </div>
</div>
