import { Component, OnInit , Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { isTemplateExpression } from 'typescript';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';

@Component({
  selector: 'app-calculator-mix-table-edit',
  templateUrl: './calculator-mix-table-edit.component.html',
  styleUrls: ['./calculator-mix-table-edit.component.css']
})
export class CalculatorMixTableEditComponent implements OnInit {

user: string

calculatorMixTableEditForm: FormGroup;

categoriesEditForm: FormGroup


  constructor( private _fb: FormBuilder, 
               public dialogRef: MatDialogRef<CalculatorMixTableEditComponent>,
               private userObjectUpdateService: UserObjectUpdateService ,
               @Inject(MAT_DIALOG_DATA) public editData: any) { }

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

    console.log(this.editData)

    this.calculatorMixTableEditForm = new FormGroup ({
      name: new FormControl ( this.editData.name , [Validators.maxLength(30)]),
      category: new FormControl (this.editData.details.category),
      descriptionParts: new FormArray (this.editData.details.descriptionParts.map(item2 => {
        const group2 = this.initDescriptionParts();
        group2.patchValue(item2)
        return group2
      }))
      
    });

    console.log(this.calculatorMixTableEditForm)
  

  };



  initDescriptionParts(){
    return new FormGroup ({
      category: new FormControl ('', Validators.maxLength(30)),
      description: new FormControl ('' , Validators.maxLength(50)),
      priceList: new FormControl (''),
      basicDiscount: new FormControl ('')
    })
  };

  getDescriptionParts(calculatorMixTableEditForm){
    return calculatorMixTableEditForm.controls.categories.controls
  };

  onAddDescriptionParts(){
    (<FormArray>this.calculatorMixTableEditForm.get('descriptionParts')).push(this.initDescriptionParts())
  }

  onDeleteDescriptionParts(index: number){
    (<FormArray>this.calculatorMixTableEditForm.get('descriptionParts')).removeAt(index)
  }


  onSubmit(){

    this.dialogRef.close()
  }

}
