


<div class="example-box" *ngIf="!isRenamingGroup" >

     <div class="col-12">
        <div class="row">
          <div class="col-1"></div>
            <div class="col-2" 
                 *ngIf="recipientIndexColumn.type === 'personal'" 
                 (click)="onRenameRecipientGroup()">
                <svg style="color: darkgrey;" xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16">
                    <circle cx="8" cy="8" r="8"/>
                </svg>
            </div>
            <div class="col-2" *ngIf="recipientIndexColumn.type !== 'personal'">

            </div>

            <div class="col-8"
                 (click)="onSelectRecipientIndex()"
                 style="color: darkgrey;">
                {{recipientIndexListItem.recipientIndexListName}}
            </div>
        </div>
     </div>

</div>

<div class="example-box" *ngIf="isRenamingGroup">

    <form  [formGroup]="renameRecipientForm" (ngSubmit)="onSubmit()" >
         
        <div class="row">
          <div>
            <mat-form-field class="full-width">
              <input 
              matInput 
              type="text" 
              id="paragraphIndexName"
              formControlName="name"
              placeholder="edit group name"
              maxlength="35"
              >
            </mat-form-field>
           
          </div>
          <div *ngIf="(isHandset$ | async)" class="row">
             <div class="d-flex justify-content-center" >
              <button type="button" (click)="onSubmit()"  class="btn btn-secondary">ok</button>
            </div>
          </div>
        </div>
       
       </form>

</div>




