import { SingleSliderDetail } from "./single-slider-detail.model";

export class SingleSlider {

    constructor( public _id: string , 
                 public componentName: string , 
                 public UIID: string , 
                 public owner: string,
                 public allUsersAllowed: boolean,
                 public userListAllowed: string[],
                 public dateAndTime: string ,
                 public name: string,
                 public singleSliderDetails: SingleSliderDetail[]
                 ){}
}