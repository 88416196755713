import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { SingleWordInput } from '../single-word-input.model';
import { SingleWordInputDetail } from '../single-word-input-detail.model';

import { SingleWordInputService } from '../single-word-input.service';

import { NgForm } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';


import { SingleFeatureDetailResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/single-feature-results/single-feature-detail-result.model';
import { SingleWordInputDetailResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/single-feature-results/single-word-input-result/single-word-input-detail-result.model';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { Feature } from '../../../../feature.model';
import { FormService } from 'src/app/forms/form.service';

@Component({
  selector: 'app-single-word-input-item',
  templateUrl: './single-word-input-item.component.html',
  styleUrls: ['./single-word-input-item.component.css']
})
export class SingleWordInputItemComponent implements OnInit {

  user: string


   // needed is for stuff like edit buttons to be visibles or not
   @Input() weAreInMultiFeatureEdit:boolean; 
   @Input() noNeeded : boolean  ; 
   @Input() singleWordInput : SingleWordInput;
   @Input() singleWordInputDetails : SingleWordInputDetail[]; 
   @Input() index_id: string;
   @Input() isParagraphEdit: boolean ;
   @Input() feature: Feature

  /**
   * here we start to declare the rsults forms 
   */
   singleFeatureDetailResult: SingleFeatureDetailResult;
   singleWordInputDetailResult: SingleWordInputDetailResult;
   singleWordInputDetailResults: SingleWordInputDetailResult[];


   @Output() deleteSingleFeatureResultEvent = new EventEmitter<void>()

  @ViewChild('f') singleWordInputForm: NgForm

  /**
   * to build the emailText
   */
  mailTextSingleFeatureBodyPart : string;
  @Output() updateEmailTextSingleFeatureBody = new EventEmitter<string>();

  @Output() updateBothSingleFeatureResultAndTextPlusIndex = new EventEmitter<any>()

  requestToFinalizeResultAgain: Subscription 
  valueChangesSubscription: Subscription 

  inputWidth: number = 250;
 

 
  constructor( private singleWordInputService: SingleWordInputService,
               private formService: FormService, 
               private userObjectUpdateService: UserObjectUpdateService ) { }

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    console.log('THIS IS PARAGRAPH EDIT  = ',this.isParagraphEdit)

      this.singleWordInput = this.singleWordInputService.getSingleWordInputBy_id(this.index_id);

     console.log('feature = ', this.feature, this.singleWordInput)
 

        this.requestToFinalizeResultAgain = this.formService.getRequestToFinalizeResultAgain()
            .subscribe(()=>  this.onSend() )
        
        setTimeout(()=>{
               this.letTheseFunctionsSetRightAfterStart()
        },300)
    
    
    };

    letTheseFunctionsSetRightAfterStart(){
      this.onSend()
      this.valueChangesSubscription = this.singleWordInputForm.valueChanges.subscribe(() => { this.onSend() });

    }


  openDialogSingleWordInputEdit(){

  }

  onDeleteSingleWordInputItem(){
    this.deleteSingleFeatureResultEvent.emit()
    console.log('click on single word input')
  }



  onSubmit(form: NgForm){
    console.log (form);

    const newSingleFeatureDetailResultType = 'singleWordInputResult';
    const newSingleFeatureDetailResultName = this.singleWordInput.name;
    const  newSingleWordInputDetailResultLabel = this.singleWordInput.singleWordInputDetails[0].label ;
    const  newSingleWordInputDetailResultContent =  form.value.singleWordInput.Detail[0].content;
    const  newSingleWordInputDetailResultHint= this.singleWordInput.singleWordInputDetails[0].hint;
    const newSingleWordInputDetailResult = new SingleWordInputDetailResult( newSingleWordInputDetailResultLabel , newSingleWordInputDetailResultContent, newSingleWordInputDetailResultHint);

    this.mailTextSingleFeatureBodyPart = newSingleWordInputDetailResultContent;
    const newSingleFeatureDetailResult = new SingleFeatureDetailResult (newSingleFeatureDetailResultType, newSingleFeatureDetailResultName, newSingleWordInputDetailResult )
         
    console.log(newSingleFeatureDetailResult);
    this.singleFeatureDetailResult = newSingleFeatureDetailResult;

    this.onUpdateSingleFeatureDetailResult()

  }

  onUpdateSingleFeatureDetailResult(){
    if( this.weAreInMultiFeatureEdit === false){
  
      const newFeatureDrop_Id = this.feature.dropId
      const newSingleFeatureDetailResult: SingleFeatureDetailResult = this.singleFeatureDetailResult
      const newMailTextSingleFeatureBodyPart: string = this.mailTextSingleFeatureBodyPart
      
      this.updateBothSingleFeatureResultAndTextPlusIndex.emit({ newFeatureDrop_Id, newSingleFeatureDetailResult, newMailTextSingleFeatureBodyPart   })  

    }
    }

  onSend(){
    console.log('is sending')
    if( this.weAreInMultiFeatureEdit === false){
      this.singleWordInputForm.onSubmit(undefined);
    }
  
  }

  ngOnDestroy(){
   
        this.requestToFinalizeResultAgain.unsubscribe()
        this.valueChangesSubscription.unsubscribe()
   
}




}
