

<div class="calculator">
  <div class="display">{{ operation }}<br>{{ result }}</div>
  <div class="keypad">
    <div class="key" (click)="pressKey('7')">7</div>
    <div class="key" (click)="pressKey('8')">8</div>
    <div class="key" (click)="pressKey('9')">9</div>
    <div class="key operator" (click)="pressKey('/')">/</div>
    <div class="key" (click)="pressKey('4')">4</div>
    <div class="key" (click)="pressKey('5')">5</div>
    <div class="key" (click)="pressKey('6')">6</div>
    <div class="key operator" (click)="pressKey('*')">*</div>
    <div class="key" (click)="pressKey('1')">1</div>
    <div class="key" (click)="pressKey('2')">2</div>
    <div class="key" (click)="pressKey('3')">3</div>
    <div class="key operator" (click)="pressKey('-')">-</div>
    <div class="key" (click)="pressKey('0')">0</div>
    <div class="key" (click)="pressKey('.')">.</div>
    <div class="key operator" (click)="pressKey('+')">+</div>
    <div class="key clear" (click)="pressKey('C')">C</div>
    <div class="key" (click)="pressKey('=')">=</div>
  </div>
</div>





