import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Feature } from '../../../../feature.model';
import { SingleDropDownSelect } from '../single-drop-down-select.model';
import { SingleDropDownSelectService } from '../single-drop-down-select.service';
import { SingleDropDownSelectHttpRequestService } from '../single-drop-down-select-http-request.service';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { CreateNewFeatureContainerAndAddToFeatureContainerListItemService } from 'src/app/services/shared-services/create-new-feature-container-and-update-feature-container-list-item.service';
import { Subscription } from 'rxjs';
import { AdviseIfHttpStoreComponentsCallHasBeenMadeService } from 'src/app/services/other-components-services/advise-if-http-store-components-has-been-made.service';
import { StoreBaseComponentsService } from 'src/app/services/get-and-store-components/store-base-components.service';
import { FeatureContainerListItem } from '../../../../../../paragraph-edit/feature-container-list-item.model';
import { FeatureContainerListItemService } from '../../../../../../paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container-list-item/feature-container-list-items.service';
import { FeatureContainer } from '../../../../../../paragraph-edit/feature-container.model';
import { FeatureContainerService } from '../../../../../../paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container/feature-container.service';

@Component({
  selector: 'app-single-drop-down-select-list',
  templateUrl: './single-drop-down-select-list.component.html',
  styleUrls: ['./single-drop-down-select-list.component.css']
})
export class SingleDropDownSelectListComponent implements OnInit , OnDestroy  {

  user: string 

  thisTypeOfComponent: string = 'singleDropDownSelect'

  singleDropDownSelect: SingleDropDownSelect;
  singleDropDownSelects: SingleDropDownSelect[];


  customCollapsedHeight: string = '20px';
  customExpandedHeight: string = '20px';

  index : number ;

  selectedIndex: number  ;
  @Input() selectedIndex_id: string  ;
  @Input() isParagraphEdit: boolean;
  @Input() weAreInMultiFeatureEdit: boolean;
  
  @Output() changeMultiFeatureIndex = new EventEmitter<Feature>()  ;
  @Output() eventIndex: number ;

    feature : Feature ;

    // this set as false in the DOM allow us to see the buttons to modify the component
    @Output() noNeeded : boolean ; 

    /** limit on the number of features to add */
    createButtonLimit : boolean ;
    singleDropDownSelectLimit = 25 ;

    @Input() dropId : number ;
    @Input() currentI : number ;

    @ViewChild('tabs') tabGroup: MatTabGroup

    /** properties on the next/previous button */
   nextButtonName : string;
   nextButtonIndex: number ;
   totalSingleDropDownSelect: number ;
   count: number ;

    /** this serve as new tab index when creating a new button */
    newTabIndex0: number ;
    newTabIndex: number ;

    @Output() deleteSingleFeatureItem = new EventEmitter<number>()

    isLoading: boolean
    isLoadingList: boolean 
    error = null 

    updateOnUserEmail: Subscription
    initialPersonalSingleDrpDownSelectsHaveBeenStoredSubscription: Subscription

    @Output() holdOnCreateUpdateButtons = new EventEmitter<void>()

    featureContainerPlusParentsHaveBeenStored: Subscription 




  constructor( private singleDropDownSelectService: SingleDropDownSelectService,
               private singleDropDownSelectHttpRequestService: SingleDropDownSelectHttpRequestService,
               private userObjectUpdateService: UserObjectUpdateService ,
               private storeBaseComponentsService: StoreBaseComponentsService , 
               private featureContainerListItemService: FeatureContainerListItemService ,
               private featureContainerService: FeatureContainerService ,
               private adviseIfHttpStoreComponentsCallHasBeenMadeService: AdviseIfHttpStoreComponentsCallHasBeenMadeService ,
               private postComponentsHttpRequestService: PostComponentsHttpRequestService,
               private createNewFeatureContainerAndAddToFeatureContainerListItemService: CreateNewFeatureContainerAndAddToFeatureContainerListItemService ) { }

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
  })

  this.isLoading = true 
  this.isLoadingList = true 

  if ( this.user === null ){console.log('is going directly to continue as NULL user'), this.continueInitialOnInit()}
  if ( this.user !== null ){
    if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.singleDropDownSelectsHasBeenStored === true  ){ console.log('is going direvtly to continue'),   this.continueInitialOnInit() }
    if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.singleDropDownSelectsHasBeenStored === false ){ console.log('is calling http'), this.storeBaseComponentsService.callBaseComponentsListByUserAndType(this.user , 'singleDropDownSelect') }
  };

  this.initialPersonalSingleDrpDownSelectsHaveBeenStoredSubscription = this.singleDropDownSelectService.initialPersonalSingleDropDownSelectsHaveBeenStored
  .subscribe(()=>{
    this.continueInitialOnInit()
    this.adviseIfHttpStoreComponentsCallHasBeenMadeService.singleDropDownSelectsHasBeenStored = true 
  })

  this.featureContainerPlusParentsHaveBeenStored = this.featureContainerListItemService.newFeatureContainerAndparentsHaveBeenStored
.subscribe((feature_plus_typeOfComponent: any )=> {

  const currentFeature:Feature = feature_plus_typeOfComponent.feature 
  const currentTypeOfComponent: string = feature_plus_typeOfComponent.featureContainerName

  console.log(feature_plus_typeOfComponent)
  if ( currentTypeOfComponent === this.thisTypeOfComponent){
    this.onEmitToMultiFeatureEditChangedIndex(currentFeature)
  }

})


  };

  continueInitialOnInit(){

    console.log('IT S CONTINUING INITIAL ONINIT')
    const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('singleDropDownSelect')
  
    this.singleDropDownSelects = []
    for ( let i=0; i<personalFeatureContainerListItem.featureContainer_ids.length ; i++ ){
          let currentFeatureContainer: FeatureContainer = this.featureContainerService.getFeatureContainerBy_id(personalFeatureContainerListItem.featureContainer_ids[i])
          this.singleDropDownSelects.push(this.singleDropDownSelectService.getSingleDropDownSelect_by_id(currentFeatureContainer.componentReference_id))
    }
  
    this.selectedIndex = this.singleDropDownSelects.findIndex(x=> x._id === this.selectedIndex_id)
  
    if (this.singleDropDownSelects.length < this.singleDropDownSelectLimit  ){ this.createButtonLimit = true }
    if (this.singleDropDownSelects.length >= this.singleDropDownSelectLimit ) { this.createButtonLimit = false };
  

    this.totalSingleDropDownSelect = this.singleDropDownSelects.length
    this.count = this.selectedIndex;
    this.nextButtonIndex = this.selectedIndex+1 
    this.nextButtonName = this.singleDropDownSelects[this.selectedIndex].name

    console.log(this.singleDropDownSelects)

    if ( this.user !== null ){ this.isLoadingList = false , this.isLoading = false }
    if ( this.user === null ){  setTimeout(()=>{ this.isLoadingList = false , this.isLoading = false }, 200 )  }
  


  }


    /** for each click we recreate a new feature  */
onLinkClick($event) {

  this.index = this.tabGroup.selectedIndex ; 
 
  const newOwner = this.user 
  const newIsNew = false
  const newDropId = this.currentI
  const newFeature_id = this.singleDropDownSelects[this.index]._id
  const newFeatureName = 'singleDropDownSelect' ;
  const newFeature = new Feature (  newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
 
  this.feature = newFeature;
 
  this.changeMultiFeatureIndex.emit(this.feature)
  this.eventIndex = this.currentI ;
 
  console.log('drop id =', this.currentI)
  console.log(this.feature)
 
   };

   onNextButton(){

    if ( this.tabGroup.selectedIndex+1 === this.totalSingleDropDownSelect ){
      this.index = 0

    const newOwner = this.user   
    const newIsNew = false
    const newDropId = this.currentI
    const newFeature_id = this.singleDropDownSelects[this.index]._id
    const newFeatureName = 'singleDropDownSelect' ;
    const newFeature = new Feature (  newOwner, newIsNew, newDropId  , newFeature_id, newFeatureName) ;
   
    this.feature = newFeature;
   
    this.changeMultiFeatureIndex.emit(this.feature)
    this.eventIndex = this.currentI ;
   
    console.log('drop id =', this.currentI)
    console.log(this.feature)

    } else if ( this.tabGroup.selectedIndex < this.totalSingleDropDownSelect ){
      this.index = this.tabGroup.selectedIndex+1 ; 

      const newOwner = this.user 
      const newIsNew = false
      const newDropId = this.currentI
      const newFeature_id = this.singleDropDownSelects[this.index]._id
      const newFeatureName = 'singleDropDownSelect' ;
      const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
     
      this.feature = newFeature;
     
      this.changeMultiFeatureIndex.emit(this.feature)
      this.eventIndex = this.currentI ;
     
      console.log('drop id =', this.currentI)
      console.log(this.feature)
  
    }

    console.log('this index ',this.index)

   }

   onPreviousButton(){

    if ( this.tabGroup.selectedIndex === 0){
      this.index = this.totalSingleDropDownSelect-1 ;
 
    const newOwner = this.user 
    const newIsNew = false
    const newDropId = this.currentI
    const newFeature_id = this.singleDropDownSelects[this.index]._id
    const newFeatureName = 'singleDropDownSelect' ;
    const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
   
    this.feature = newFeature;
   
    this.changeMultiFeatureIndex.emit(this.feature)
    this.eventIndex = this.currentI ;
   
    console.log('drop id =', this.currentI)
    console.log(this.feature)


  } else if ( this.tabGroup.selectedIndex > 0){
    this.index = this.tabGroup.selectedIndex-1 ; 

      const newOwner = this.user 
      const newIsNew = false
      const newDropId = this.currentI
      const newFeature_id = this.singleDropDownSelects[this.index]._id
      const newFeatureName = 'singleDropDownSelect' ;
      const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
     
      this.feature = newFeature;
     
      this.changeMultiFeatureIndex.emit(this.feature)
      this.eventIndex = this.currentI ;
     
      console.log('drop id =', this.currentI)
      console.log(this.feature)
  
    }

    console.log('this index ',this.index)

   };

   onCreate(){

    this.isLoading = true
    this.isLoadingList = true 
   this.createNewSingleDropDownSelect()
   console.log(this.singleDropDownSelects)
 
  }


   createNewSingleDropDownSelect(){
    const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('singleDropDownSelect')
    this.newTabIndex0 = personalFeatureContainerListItem.featureContainer_ids.length+1
 
    const new_id = 'singleDropDownSel'+this.newTabIndex0.toString() ;
    const newComponentName = 'singleDropDownSelect'
    const newUIID = 'singleDropDownSelect-'+this.newTabIndex0.toString()
    const newOwner = this.user ;
    const newDateAndTime = null ;
    const newAllUsersAllowed = false ;
    const newUserListAllowed = [];
    const newName = 'new single drop down select '+ this.newTabIndex0.toString();
    const newDescription = 'new label '+ this.newTabIndex0.toString()
    const newContents = ['edit this new content ']

    const newSingleDropDownSelect = new SingleDropDownSelect (new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed,
                                                             newDateAndTime, newName, newDescription, newContents )
 

    if ( this.user === null ){ 

      this.singleDropDownSelectService.addNew(newSingleDropDownSelect)
  
      const newOwner = this.user 
      const newIsNew = false
      const newDropId = this.currentI ;
      const newFeature_id = newSingleDropDownSelect._id
      const newFeatureName = 'singleDropDownSelect' ;
      const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
     
      this.feature = newFeature
      this.createNewFeatureContainerAndAddToFeatureContainerListItemService.createFeatureContainer_toAddOnFeatureContainerListItem(newFeature, this.user)
  
     }

     if ( this.user !== null ){
    /**
     * add single word input to server data with http request post
     */
    //this.singleDropDownSelectHttpRequestService.httpStoreSingleDropDownSelect(newSingleDropDownSelect).subscribe((response: SingleDropDownSelect)=> {
   this.postComponentsHttpRequestService.httpStoreComponent(this.user, 'singleDropDownSelecto', newSingleDropDownSelect )
       .subscribe((response: SingleDropDownSelect)=> {

       this.singleDropDownSelectService.addNew(response)

   const newOwner = this.user 
   const newIsNew = false
   const newDropId = this.currentI ;
    const newFeature_id = response._id
   const newFeatureName = 'singleDropDownSelect' ;
   const newFeature = new Feature (  newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
  
   this.feature = newFeature

   this.createNewFeatureContainerAndAddToFeatureContainerListItemService.createFeatureContainer_toAddOnFeatureContainerListItem(newFeature, this.user )
  
   console.log(this.feature)

  },error => {
    console.log(error)

    const errorMessage: string = "we weren't able to create the new component, check the network or try later."
    this.error = errorMessage
    this.isLoadingList = false 
    /**
     *  EMIT and event to paragraphEdit to put update or create buttons for 4,5 sec 
     */
    this.holdOnCreateUpdateButtons.emit()

    /**
     * return to previous set up without the new component after a while to be able to read the message 
     */
    setTimeout(()=>{
      this.error = null 
      this.isLoadingList = false 
      this.isLoading = false 
    },4000)
  }
  )
 
};
}

onEmitToMultiFeatureEditChangedIndex(toEmitFeature: Feature ){
  console.log('is emitting changed feature' , toEmitFeature )
  this.changeMultiFeatureIndex.emit(toEmitFeature)
  // once this feature is emitted the multiFeature Edit will change index and this whole 
  // component will restart again
}



 

  onDeleteSingleDropDownSelectItem(){
    this.deleteSingleFeatureItem.emit(this.currentI)
  }

  ngOnDestroy(): void {
    this.updateOnUserEmail.unsubscribe()
    
  }

}
