
<div *ngIf="isSentToMessage && !isSentToMail"  
     [ngStyle]="{backgroundColor: getColorForMessage()}">
    

    <div style="color: #3f51b5;" class="col-12">

        <div  class="row">
            <div class="col-1"></div>
            <div class="col-10">
                <strong> {{ formResult.recipientResult?.name | truncate:25 }}</strong>
            </div>
        </div>

        <div class="row">
            <div class="col-1"></div>
            <div class="col-10">
                <strong style="color:red">
                    {{formResult?.subject | truncate:20  }}</strong>
            </div>
        </div>
        
        <div *ngIf="isReceived">

            <div class="row">
                <div class="col-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chat-right-quote-fill" viewBox="0 0 16 16">
                        <path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9.586a1 1 0 0 1 .707.293l2.853 2.853a.5.5 0 0 0 .854-.353V2zM7.194 4.766c.087.124.163.26.227.401.428.948.393 2.377-.942 3.706a.446.446 0 0 1-.612.01.405.405 0 0 1-.011-.59c.419-.416.672-.831.809-1.22-.269.165-.588.26-.93.26C4.775 7.333 4 6.587 4 5.667 4 4.747 4.776 4 5.734 4c.271 0 .528.06.756.166l.008.004c.169.07.327.182.469.324.085.083.161.174.227.272zM11 7.073c-.269.165-.588.26-.93.26-.958 0-1.735-.746-1.735-1.666 0-.92.777-1.667 1.734-1.667.271 0 .528.06.756.166l.008.004c.17.07.327.182.469.324.085.083.161.174.227.272.087.124.164.26.228.401.428.948.392 2.377-.942 3.706a.446.446 0 0 1-.613.01.405.405 0 0 1-.011-.59c.42-.416.672-.831.81-1.22z"/>
                      </svg>
                </div>
                <div class="col-9">
                    <small *ngIf="formResult.sender.content" >
                        {{formResult?.sender?.content | truncate:25 }}
                    </small>
                    <small *ngIf="!formResult.sender.content">
                        ( DEMO PURPOSE ONLY )
                    </small>
                </div>
                <div class="col-1"></div>
            </div>
        </div>

        <div *ngIf="!isReceived">

            <div class="row" *ngIf="isSentToMessage">
                <div class="col-1"></div>
                <div class="col-9">
                    <small style="color: #3f51b5" *ngIf="formResult.sender.content" >
                        {{ formResult?.sender?.content | truncate:25 }}
                    </small>
                    <small style="color: #3f51b5" *ngIf="!formResult.sender.content">
                       ( DEMO PURPOSE ONLY )
                    </small>
                </div>
                <div class="col-1">
                      <svg style="color: #3f51b5;" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-chat-left-quote-fill" viewBox="0 0 16 16">
                        <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm7.194 2.766a1.688 1.688 0 0 0-.227-.272 1.467 1.467 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 5.734 4C4.776 4 4 4.746 4 5.667c0 .92.776 1.666 1.734 1.666.343 0 .662-.095.931-.26-.137.389-.39.804-.81 1.22a.405.405 0 0 0 .011.59c.173.16.447.155.614-.01 1.334-1.329 1.37-2.758.941-3.706a2.461 2.461 0 0 0-.227-.4zM11 7.073c-.136.389-.39.804-.81 1.22a.405.405 0 0 0 .012.59c.172.16.446.155.613-.01 1.334-1.329 1.37-2.758.942-3.706a2.466 2.466 0 0 0-.228-.4 1.686 1.686 0 0 0-.227-.273 1.466 1.466 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 10.07 4c-.957 0-1.734.746-1.734 1.667 0 .92.777 1.666 1.734 1.666.343 0 .662-.095.931-.26z"/>
                      </svg>
                </div>
            </div>
    
        </div>

        <div class="row" style="color: #3f51b5;" >
            <div class="col-1"></div>
            <div class="col-4 d-flex justify-content-start align-items-center">
                <small> {{ getDayOfTheWeek(formResult.dateAndTime) }} </small>
            </div>
            <div class=" col-4 d-flex justify-content-center align-items-center">
                <small> {{ getDateTime_InDaysWeeksMonths(formResult.dateAndTime) }} </small>
            </div>
    
        </div>


        <div class="row">
            <div class="col-1"></div>
            <div class="col-8">
                <!-- <small>{{formResult?.dateAndTime | date : 'MMMM , dd yyyy  HH:mm'}}</small> this will cause error on safari -->
                <small>{{ formResult.dateAndTime }}</small>
            </div>
            <div class="col-2 d-flex justify-content-end align-items-center">
                <small> {{ dateTimeInMinuteHours_string | truncate:20 }}</small>
            </div>
        </div>
    </div>

</div>

<!-- this below is for mails instead  -->

<div *ngIf="!isSentToMessage && isSentToMail" 
     [ngStyle]="{backgroundColor: getColorForMail()}">
   

    <div style="color: darkgrey " class="col-12">

        <div  class="row">
            <div class="col-1"></div>
            <div class="col-10">
                <strong> 
                    {{ formResult.recipientResult?.name | truncate:25 }}</strong>
            </div>
        </div>



        <div class="row">
            <div class="col-1"></div>
            <div class="col-10">
                <strong style="color:red">
                    {{formResult?.subject | truncate:20  }} </strong>
            </div>
        </div>

        <div *ngIf="!isReceived">


            <div class="row" *ngIf="isSentToMail" >
                <div class="col-1"></div>
                <div class="col-9">
                 <small style="color: darkgrey" *ngIf="formResult.sender.content">
                     {{ formResult?.sender.content | truncate:25  }}
                 </small>
                 <small style="color:  darkgrey" *ngIf="!formResult.sender.content">
                    ( DEMO PURPOSE ONLY )
                </small>
                </div>
                <div class="col-1">
                   <svg style="color: darkgrey;" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-send-fill" viewBox="0 0 16 16">
                     <path fill-rule="evenodd" d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89.471-1.178-1.178.471L5.93 9.363l.338.215a.5.5 0 0 1 .154.154l.215.338 7.494-7.494Z"/>
                   </svg>
                </div>
            </div>
     
         </div>

        <div class="row" style="color: darkgrey" >
            <div class="col-1"></div>
            <div class="col-4 d-flex justify-content-start align-items-center">
                <small> {{ getDayOfTheWeek(formResult.dateAndTime)}} </small>
            </div>
            <div class=" col-4 d-flex justify-content-center align-items-center">
                <small> {{ getDateTime_InDaysWeeksMonths(formResult.dateAndTime) }} </small>
            </div>
    
        </div>

        <div class="row" style="color: darkgrey">
            <div class="col-1"></div>
            <div class="col-8">
                <!-- <small>{{formResult?.dateAndTime | date : 'MMMM , dd yyyy  HH:mm' }}</small> this will cause error on safari -->
                <small>{{ formResult.dateAndTime }}</small>
            </div>
            <div class="col-2 d-flex justify-content-end align-items-center">
               
                <small>  {{ dateTimeInMinuteHours_string | truncate:20 }}</small>
               
            </div>
        </div>
    </div>

</div>

