import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendar-range-list',
  templateUrl: './calendar-range-list.component.html',
  styleUrls: ['./calendar-range-list.component.css']
})
export class CalendarRangeListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
