import { DropDownSelectDescription } from "./drop-down-select-description.model";

export class DropDownSelect {

    constructor( public _id: string, 
                 public componentName: string , 
                 public UIID: string , 
                 public owner: string,
                 public allUsersAllowed: boolean,
                 public userListAllowed: string[],
                 public dateAndTime: string ,
                 public name : string ,
                 public descriptionList: DropDownSelectDescription[]) {}
}