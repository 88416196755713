<!-- <p>chat-fill-form-mobile-comment works!</p> -->


<app-comment
[isParagraphEdit]="false"
(updateFeatureResultEvent)='onUpdateFeatureResult($event)' >
</app-comment>

<br>
<button  type="button" (click)="onSendForm()" class="btn btn-danger"> send </button>
<br>