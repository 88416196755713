import { Component, Input, OnInit } from '@angular/core';
import { FixLayoutDetailResult } from './fix-layout-detail-result.model';
import { FixLayoutTableTR } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/layout/fix-layout-list/fix-layout-table-tr.model';
import { FixLayoutTableTD } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/layout/fix-layout-list/fix-layout-table-td.model';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray} from '@angular/forms';
import { MatDialogRef , MAT_DIALOG_DATA, _closeDialogVia } from '@angular/material/dialog';


@Component({
  selector: 'app-fix-layout-results',
  templateUrl: './fix-layout-results.component.html',
  styleUrls: ['./fix-layout-results.component.css']
})
export class FixLayoutResultsComponent implements OnInit {

  @Input() fixLayoutDetailResult: FixLayoutDetailResult

  fixLayoutTableTRs : FixLayoutTableTR[]
  

  constructor() { }

  ngOnInit(){
    
    console.log(this.fixLayoutDetailResult)

  }

  getWidth(i: number, j:number){
    return this.fixLayoutDetailResult.fixLayoutTableTRs[i].fixLayoutTableTDs[j].tableTDDetailWidth
  }

}
