import { Injectable } from "@angular/core";
import { Subject } from "rxjs";


@Injectable ({
    providedIn: 'root' 
})

export class StoreFormItemPlusChildsConfirmationService { 

    formItem_id_ToAddToFormItem = new Subject<string>() 
    formItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property: any[] = [] // ({component_id: string , haveBeenStored: boolean})

    composeFormItemPlusChildsComponent_idsList( formItemPlusChilds: any[] ){

        console.log( formItemPlusChilds )
        const component_id: string = formItemPlusChilds[0]._id 
        const haveBeenStored: boolean = false 

        this.formItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.push({component_id , haveBeenStored})

        const paragraph_ids: string[] = [] // formItemPlusChilds[0].paragraph_ids
        const formItem_childs: any[] = [] //formItemPlusChilds[0].formItem_childs

        paragraph_ids.push(...formItemPlusChilds[0].paragraph_ids)
        formItem_childs.push(...formItemPlusChilds[0].formItem_childs)



        this.scomposeParagraphItemsPlusChildsComponent_idsList(formItem_childs , paragraph_ids)

    };

    scomposeParagraphItemsPlusChildsComponent_idsList(formItem_childs: any[] , paragraph_ids: string[]){

        console.log(formItem_childs, paragraph_ids)

        const features_id_list: string[] = []
        const paragraphs_childs: any[] = [] 

        for ( let i=0; i<formItem_childs.length ; i++ ){

            let component_id: string = formItem_childs[i]._id 
            let haveBeenStored: boolean = false 

            if ( this.formItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.findIndex(x=> x.component_id === paragraph_ids[i]) === -1 ){
              this.formItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.push({component_id , haveBeenStored})
            }
      }


        console.log(this.formItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property)

        for ( let y=0; y<formItem_childs.length ; y++ ){
            for ( let z=0; z<formItem_childs[y].paragraphChild_list.length ; z++ ){
                if ( features_id_list.findIndex(x=> x === formItem_childs[y].feature_id_list[z] ) === -1){
                     features_id_list.push(formItem_childs[y].feature_id_list[z])
                     paragraphs_childs.push(formItem_childs[y].paragraphChild_list[z])
                     
                     let component_id: string = formItem_childs[y].feature_id_list[z]
                     let haveBeenStored: boolean = false 
                     if (  this.formItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.findIndex(x=> x.component_id === component_id ) === -1 ){
                           this.formItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.push({component_id , haveBeenStored})
                     }
                    
                }
            }
        }

        console.log(features_id_list , paragraphs_childs ,  this.formItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property )
        this.scomposeFeatureItemPlusChildsComponent_idsList( paragraphs_childs)

    }

    scomposeFeatureItemPlusChildsComponent_idsList( paragraphs_childs: any[]){

        /**
         * now need to check if any of the childs have singleBase_id_list / or features 
         */
        for ( let i=0; i<paragraphs_childs.length ; i++){

             if ( paragraphs_childs[i].hasOwnProperty('multiFeatureChild_list') === true ){ 
                  console.log(paragraphs_childs[i] , 'this is a multiFeature component') 
                  for ( let y=0; y<paragraphs_childs[i].singleBase_id_list.length ; y++ ){
                        let component_id: string = paragraphs_childs[i].singleBase_id_list[y]
                        let haveBeenStored: boolean = false 
                        if (  this.formItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.findIndex(x=> x.component_id === component_id) === -1 ){
                              this.formItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.push({component_id , haveBeenStored})
                        }
                  }
                  console.log( this.formItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property )
             }

             if ( paragraphs_childs[i].hasOwnProperty('tabChild_list') === true ){
                  console.log( paragraphs_childs[i] , 'this is a tab component')
                  this.scomposeTabPlusChildsComponent(paragraphs_childs[i])
             }

             if ( paragraphs_childs[i].hasOwnProperty('multiFeatureChild_list') !== true && paragraphs_childs[i].hasOwnProperty('tabChild_list') !== true  ){ 
                  console.log(paragraphs_childs[i] , 'does not have childs components ')
             }
        }
    }

    scomposeTabPlusChildsComponent(tabComponent_plusChilds: any ){

        for ( let i=0 ; i<tabComponent_plusChilds.feature_id_list.length ; i++ ){
              let component_id: string = tabComponent_plusChilds.feature_id_list[i]
              let haveBeenStored: boolean = false 
              if (  this.formItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.findIndex(x=> x.component_id === component_id) === -1 ){
                    this.formItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.push({component_id , haveBeenStored})
              }

             if ( tabComponent_plusChilds.tabChild_list[i].hasOwnProperty('multiFeatureChild_list') === true ){
                  this.scomposeMultiFeaturePlusChildsComponent(tabComponent_plusChilds.tabChild_list[i])
             }
              

              console.log( this.formItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property )

        }

    }

    scomposeMultiFeaturePlusChildsComponent(multiFeaturePlusChilds: any ){

        for ( let i=0; i<multiFeaturePlusChilds.singleBase_id_list.length ; i++ ){
              let component_id: string = multiFeaturePlusChilds.singleBase_id_list[i]
              let haveBeenStored: boolean = false 
              if (  this.formItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.findIndex(x=> x.component_id === component_id) === -1 ){
                    this.formItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.push({component_id , haveBeenStored})
              }
        }
        console.log( this.formItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property )

    }


    addStoredComponentConfirmationAndAdviseWhenFormItemPlusChildsAreStored(component_id: string ){

       const index = this.formItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.findIndex(x=> x.component_id === component_id)
       const haveBeenStored: boolean = true 
       const component_idPlusBoolean: any = { component_id , haveBeenStored }
       this.formItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property[index] = component_idPlusBoolean 

       console.log(this.formItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property)

       if (this.formItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.findIndex(x=> x.haveBeenStored === false ) !== -1 ){ return }
       if (this.formItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.findIndex(x=> x.haveBeenStored === false ) === -1 ){ 

        
        console.log(this.formItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property)
        this.formItem_id_ToAddToFormItem.next(this.formItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property[0].component_id )
           // send notification to formItem that all childcomponents has been stored and can now load safely
           this.resetForm()
        } 
    }

    resetForm(){
        this.formItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property = []
        
    }

    onLoggingOutSetListOfFormItemsWithChildsEmptyAgain(){
        this.formItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property = []
    }

}