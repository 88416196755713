import { Injectable, Input , } from '@angular/core';
import { CdkDragDrop, moveItemInArray, copyArrayItem  } from '@angular/cdk/drag-drop';
import { Feature } from './form-list/form-item/paragraph/paragraph-list/paragraph-item/features/feature.model'
import { Paragraph }from './form-list/form-item/paragraph/paragraph.model';
import { ParagraphFeature } from './form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature.model';
import { RecipientIndex } from './form-list/form-edit/recipient-index-list/recipient-index.model';
import { RecipientIndexService } from './form-list/form-edit/recipient-index-list/recipient-index.service';

@Injectable({
  providedIn: 'root'
})

export class DragDropService {

    @Input() feature : Feature ;
    @Input() paragraph : Paragraph ;
    @Input()  paragraphFeature: ParagraphFeature ;
    index : number ;


    public dropFeature(event: CdkDragDrop<Feature[]>) {

      // this below is the drag and drop from feature-index and paragraph-edit 

        if (event.previousContainer === event.container) {
         moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
         copyArrayItem(event.previousContainer.data,
           event.container.data,        
           event.previousIndex,
          event.currentIndex )


      }
        
        console.log( 'previous container data: ',event.previousContainer.data);
        console.log( 'this container data:',event.container.data);
        console.log( 'this event Index',event.currentIndex)   
      
      }


      public dropSingleFeature(event: CdkDragDrop<Feature[]>) {

        // this below is the drag and drop from feature-index and paragraph-edit 
  
          if (event.previousContainer === event.container) {
           moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
          } else {
           copyArrayItem(event.previousContainer.data,
             event.container.data,        
             event.previousIndex,
            event.currentIndex )
  
  
        }
          
          console.log(event.previousContainer.data);
          console.log(event.container.data);
          console.log(event.currentIndex)   
        
        }

     




      // this below is the dragdrop service between paragraph-index and paragraph-list-edit


      public dropParagraphFeature(event: CdkDragDrop<ParagraphFeature[]>) {

        if (event.previousContainer === event.container) {
         moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
         copyArrayItem(event.previousContainer.data,
           event.container.data,
           event.previousIndex,
          event.currentIndex );
            
      }
        
        console.log(event.previousContainer.data);
        console.log(event.container.data);
        console.log(event.currentIndex) 
      
      }


      public dropRecipientIndex(event: CdkDragDrop<RecipientIndex[]>) {

        if (event.previousContainer === event.container) {
         moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
         copyArrayItem(event.previousContainer.data,
           event.container.data,
           event.previousIndex,
          event.currentIndex );
         
      }

        console.log(event.previousContainer.data);
        console.log(event.container.data);
        console.log(event.currentIndex)
       
      }
      
       
}



