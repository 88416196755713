<div class="example-box">
  
  <form [formGroup]="findUserEmailParagraphFormFeature" (ngSubmit)="onSubmit()">

      <mat-form-field *ngIf="!weAreInFormIndexTopicList"  style="color:#3f51b5" class="full-width">

        <mat-label style="color: #3f51b5">SEARCH EMAIL</mat-label>
        <mat-chip-list #chipList formArrayName="emails">
          <div *ngFor="let item of emailList; let i=index" >
            <mat-chip 
            *ngIf="!item.invalid"
            style="background-color: #3f51b5; color: white ;"
            selected 
            [selectable]="true" 
            [removable]="removable"
            (removed)="removeEmail(item)" 
            required name="chips">
            {{item.value}}
            <mat-icon  
             matChipRemove
             style="color: white;"
              *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <mat-chip 
          *ngIf="item.invalid"
          style="background-color: red; color: white ;"
          selected 
          [selectable]="true" 
          [removable]="removable"
          (removed)="removeEmail(item)" 
          required name="chips">
          {{item.value}}
          <mat-icon  
          matChipRemove
          style="color: black"
          *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        </div>
       
          <input 
            matInput
            type="text"
            placeholder="enter email"
            [matChipInputFor]="chipList" 
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes " 
            
            [matChipInputAddOnBlur]="true "
            (matChipInputTokenEnd)="add($event) " />
            <!-- [matAutocomplete]="auto" -->
        </mat-chip-list>
      
      </mat-form-field>
    

    
      <mat-form-field *ngIf="weAreInFormIndexTopicList"  style="color:#3f51b5" class="full-width">

        <mat-label style="color: #3f51b5">SEARCH </mat-label>
        <mat-chip-list #chipList formArrayName="emails">
          <div *ngFor="let item of formEmailList; let i=index" >
            <mat-chip 
            *ngIf="!item.invalid"
            style="background-color: #3f51b5; color: white ;"
            selected 
            [selectable]="true" 
            [removable]="removable"
            (removed)="removeEmail(item)" 
            required name="chips">
            {{item.value}}
            <mat-icon  
             matChipRemove
             style="color: white;"
              *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <mat-chip 
          *ngIf="item.invalid"
          style="background-color: red; color: white ;"
          selected 
          [selectable]="true" 
          [removable]="removable"
          (removed)="removeEmail(item)" 
          required name="chips">
          {{item.value}}
          <mat-icon  
          matChipRemove
          style="color: black"
          *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        </div>
       
          <input 
            matInput
            type="text"
            placeholder="enter email"
            [matChipInputFor]="chipList" 
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes " 
            
            [matChipInputAddOnBlur]="true "
            (matChipInputTokenEnd)="add($event) " />
            <!-- [matAutocomplete]="auto" -->
        </mat-chip-list>
      
      </mat-form-field>
    

      <svg style="color: #3f51b5" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
      </svg>

      <!-- </div>
    </div> -->

  </form>
 </div>
 
