import { Component, OnInit , Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { SingleWordDetail } from '../single-word-detail.model';
import { SingleWord} from '../single-word.model';
import { SingleWordService } from '../single-word.service';
import { Feature } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/feature.model';
import { SingleWordHttpRequestService } from '../single-word-http-request.service';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { Subscription } from 'rxjs';
import { AdviseIfHttpStoreComponentsCallHasBeenMadeService } from 'src/app/services/other-components-services/advise-if-http-store-components-has-been-made.service';
import { StoreBaseComponentsService } from 'src/app/services/get-and-store-components/store-base-components.service';
import { FeatureContainerListItem } from '../../../../../paragraph-edit/feature-container-list-item.model';
import { FeatureContainerListItemService } from '../../../../../paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container-list-item/feature-container-list-items.service';
import { FeatureContainer } from '../../../../../paragraph-edit/feature-container.model';
import { FeatureContainerService } from '../../../../../paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container/feature-container.service';
import { CreateNewFeatureContainerAndAddToFeatureContainerListItemService } from 'src/app/services/shared-services/create-new-feature-container-and-update-feature-container-list-item.service';


@Component({
  selector: 'app-single-word-list',
  templateUrl: './single-word-list.component.html',
  styleUrls: ['./single-word-list.component.css']
})
export class SingleWordListComponent implements OnInit , OnDestroy {

  user: string

  thisTypeOfComponent: string = 'singleWord'

  singleWord: SingleWord;
  singleWords: SingleWord[];

  customCollapsedHeight: string = '20px';
  customExpandedHeight: string = '20px';

  index : number ;

  @Input() selectedIndex_id: string  ;
  selectedIndex: number
  @Input() weAreInMultiFeatureEdit: boolean;
  
  
  @Output() changeMultiFeatureIndex = new EventEmitter<Feature>()  ;
  @Output() eventIndex: number ;

    feature : Feature ;
    details : SingleWordDetail[];

    // this set as false in the DOM allow us to see the buttons to modify the component
    @Output() noNeeded : boolean ; 

    /** limit on the number of features to add */
    createButtonLimit : boolean ;
    singleWordLimit = 25 ;

    @Input() currentI : number ;

    @ViewChild('tabs') tabGroup: MatTabGroup

    /** properties on the next/previous button */
   nextButtonName : string;
   nextButtonIndex: number ;
   totalSingleWords: number ;
   count: number ;

    /** this serve as new tab index when creating a new button */
    newTabIndex0: number ;
    newTabIndex: number ;

    @Input() isParagraphEdit: boolean ;

    @Output() deleteSingleFeatureItem = new EventEmitter<number>()

     /**
     * trial with http service
     */
      trialSingleWords: SingleWord[]
      isLoading : boolean
      isLoadingList: boolean 
      error = null 

    updateOnUserEmail: Subscription
    initialPersonalSingleWordHaveBeenStoredSubscription: Subscription

    @Output() holdOnCreateUpdateButtons = new EventEmitter<void>()

    featureContainerPlusParentsHaveBeenStored: Subscription 


  constructor( private singleWordService: SingleWordService,
               private singleWordHttpRequestService: SingleWordHttpRequestService ,
               private userObjectUpdateService: UserObjectUpdateService ,
               private storeBaseComponentsService: StoreBaseComponentsService ,
               private featureContainerService: FeatureContainerService ,
               private createNewFeatureContainerAndAddToFeatureContainerListItemService: CreateNewFeatureContainerAndAddToFeatureContainerListItemService ,
               private featureContainerListItemService: FeatureContainerListItemService ,
               private adviseIfHttpStoreComponentsCallHasBeenMadeService: AdviseIfHttpStoreComponentsCallHasBeenMadeService ,
               private postComponentsHttpRequestService: PostComponentsHttpRequestService) { }

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail
    
    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
  })

  this.isLoading = true 
  this.isLoadingList = true 

  if ( this.user === null ){console.log('is going directly to continue as NULL user'), this.continueInitialOnInit()}
  if ( this.user !== null ){
    if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.singleWordsHasBeenStored === true  ){ console.log('is going direvtly to continue'),   this.continueInitialOnInit() }
    if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.singleWordsHasBeenStored === false ){ console.log('is calling http'), this.storeBaseComponentsService.callBaseComponentsListByUserAndType(this.user , 'singleWord') }
  }

  this.initialPersonalSingleWordHaveBeenStoredSubscription = this.singleWordService.initialPersonalSingleWordsHaveBeenStored
  .subscribe(()=>{
    this.continueInitialOnInit()
    this.adviseIfHttpStoreComponentsCallHasBeenMadeService.singleWordsHasBeenStored = true 
})

this.featureContainerPlusParentsHaveBeenStored = this.featureContainerListItemService.newFeatureContainerAndparentsHaveBeenStored
.subscribe((feature_plus_typeOfComponent: any )=> {

  const currentFeature:Feature = feature_plus_typeOfComponent.feature 
  const currentTypeOfComponent: string = feature_plus_typeOfComponent.featureContainerName

  console.log(feature_plus_typeOfComponent)
  if ( currentTypeOfComponent === this.thisTypeOfComponent){
    this.onEmitToMultiFeatureEditChangedIndex(currentFeature)
  }

})

};

continueInitialOnInit(){

  console.log('IT S CONTINUING INITIAL ONINIT')
  const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('singleWord')

  this.singleWords = []
  for ( let i=0; i<personalFeatureContainerListItem.featureContainer_ids.length ; i++ ){
        let currentFeatureContainer: FeatureContainer = this.featureContainerService.getFeatureContainerBy_id(personalFeatureContainerListItem.featureContainer_ids[i])
        this.singleWords.push(this.singleWordService.getSingleWord_by_id(currentFeatureContainer.componentReference_id))
  }
  console.log(this.singleWords)

  this.selectedIndex = this.singleWords.findIndex(x=> x._id === this.selectedIndex_id)

  if (this.singleWords.length < this.singleWordLimit  ){ this.createButtonLimit = true }
  if (this.singleWords.length >= this.singleWordLimit ) { this.createButtonLimit = false };

  this.totalSingleWords = this.singleWords.length;
  this.count = this.selectedIndex;
  this.nextButtonIndex = this.selectedIndex+1 
  this.nextButtonName = this.singleWords[this.selectedIndex].name

  console.log(this.singleWords)

  if ( this.user !== null ){ this.isLoadingList = false , this.isLoading = false }
  if ( this.user === null ){  setTimeout(()=>{ this.isLoadingList = false , this.isLoading = false }, 200 )  }


}



  /** for each click we recreate a new feature  */
onLinkClick($event) {

  this.index = this.tabGroup.selectedIndex ; 
 
  const newOwner = this.user ; 
  const newIsNew = false;
  const newDropId = this.currentI;
 // const newFeatureId = this.index ;
  const newFeature_id = this.singleWords[this.index]._id
  const newFeatureName = 'singleWord' ;
  const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
 
  this.feature = newFeature;
 
  this.changeMultiFeatureIndex.emit(this.feature)
  this.eventIndex = this.currentI ;
 
  console.log('drop id =', this.currentI)
  console.log(this.feature)
 
   }


   onNextButton(){

    if ( this.tabGroup.selectedIndex+1 === this.totalSingleWords){
      this.index = 0
  
    const newOwner = this.user ; 
    const newIsNew = false;
    const newDropId = this.currentI;
    const newFeature_id = this.singleWords[this.index]._id
    const newFeatureName = 'singleWord' ;
    const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
   
    this.feature = newFeature;
   
    this.changeMultiFeatureIndex.emit(this.feature)
    this.eventIndex = this.currentI ;
   
    console.log('drop id =', this.currentI)
    console.log(this.feature)

    } else if ( this.tabGroup.selectedIndex < this.totalSingleWords){
      this.index = this.tabGroup.selectedIndex+1 ; 

      const newOwner = this.user ; 
      const newIsNew = false
      const newDropId = this.currentI;
      const newFeature_id = this.singleWords[this.index]._id
      const newFeatureName = 'singleWord' ;
      const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
     
      this.feature = newFeature;
     
      this.changeMultiFeatureIndex.emit(this.feature)
      this.eventIndex = this.currentI ;
     
      console.log('drop id =', this.currentI)
      console.log(this.feature)
  
    }

    console.log('this index ',this.index)

   }

   onPreviousButton(){

    if ( this.tabGroup.selectedIndex === 0){
      this.index = this.totalSingleWords-1

    const newOwner = this.user ; 
    const newIsNew = false
    const newDropId = this.currentI;
    const newFeature_id = this.singleWords[this.index]._id
    const newFeatureName = 'singleWord' ;
    const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
   
    this.feature = newFeature;
   
    this.changeMultiFeatureIndex.emit(this.feature)
    this.eventIndex = this.currentI ;
   
    console.log('drop id =', this.currentI)
    console.log(this.feature)


  } else if ( this.tabGroup.selectedIndex > 0){
    this.index = this.tabGroup.selectedIndex-1 ; 

      const newOwner = this.user ; 
      const newIsNew = false;
      const newDropId = this.currentI;
      const newFeature_id = this.singleWords[this.index]._id
      const newFeatureName = 'singleWord' ;
      const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
     
      this.feature = newFeature;
     
      this.changeMultiFeatureIndex.emit(this.feature)
      this.eventIndex = this.currentI ;
     
      console.log('drop id =', this.currentI)
      console.log(this.feature)
  
    }

    console.log('this index ',this.index)

};

onCreate(){

  this.isLoading = true
  this.isLoadingList = true 
  this.createNewSingleWord()
  console.log(this.singleWords)
 
 }
 

 // qui creo un nuovo Word Input , ma allo stesso tempo devo anche  creare un nuovo feature !! 
 createNewSingleWord(){
  const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('singleWord')
  this.newTabIndex0 = personalFeatureContainerListItem.featureContainer_ids.length+1

   const new_id = 'singleW-'+this.newTabIndex0.toString() ;
   const newComponentName = 'singleWord'
   const newUIID = 'singleWord-'+(this.newTabIndex0).toString()
   const newOwner = this.user
   const newDateAndTime = null
   const newAllUsersAllowed = false ;
   const newUserListAllowed = [];
   const newName = 'new single word '+ this.newTabIndex0.toString() ;

   const newLabel = 'new label '+this.newTabIndex0.toString() ;
   const newPlaceholder = 'edit this new word '+this.newTabIndex0.toString() ;
   const newHint = 'new hint ' +this.newTabIndex0.toString() ;
   const newMaxLenght1 = 25 ;

   const newSingleWordDetail = new SingleWordDetail (newLabel, newPlaceholder, newHint, newMaxLenght1);
   const newSingleWordDetails = [newSingleWordDetail];
   const newSingleWord = new SingleWord ( new_id, newComponentName, newUIID, newOwner, newDateAndTime, newAllUsersAllowed, newUserListAllowed, newName, newSingleWordDetails);

   if ( this.user === null ){ 

    this.singleWordService.addSingleWord(newSingleWord)

    const newOwner = this.user 
    const newIsNew = false
    const newDropId = this.currentI ;
    const newFeature_id = newSingleWord._id
    const newFeatureName = 'singleWord' ;
    const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
   
    this.feature = newFeature
    this.createNewFeatureContainerAndAddToFeatureContainerListItemService.createFeatureContainer_toAddOnFeatureContainerListItem(newFeature, this.user)

   }

   if ( this.user !== null ){ 
   this.postComponentsHttpRequestService.httpStoreComponent(this.user, 'singleWord', newSingleWord )
       .subscribe((response: SingleWord)=> {
   
    this.singleWordService.addSingleWord(response)
    console.log(response)

    const newOwner = this.user ; 
    const newIsNew = false
    const newDropId = this.currentI ;
    const newFeature_id = response._id
    const newFeatureName = 'singleWord' ;
    const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
   
    this.feature = newFeature

    this.createNewFeatureContainerAndAddToFeatureContainerListItemService.createFeatureContainer_toAddOnFeatureContainerListItem(newFeature, this.user)
    console.log(this.feature)

   }, error => {
    console.log(error)

    const errorMessage: string = "we weren't able to create the new component, check the network or try later."
    this.error = errorMessage
    this.isLoadingList = false 
    /**
     *  EMIT and event to paragraphEdit to put update or create buttons for 4,5 sec 
     */
    this.holdOnCreateUpdateButtons.emit()

    /**
     * return to previous set up without the new component after a while to be able to read the message 
     */
    setTimeout(()=>{
      this.error = null 
      this.isLoadingList = false 
      this.isLoading = false 
    },4000)
   })
   
   };

  }

onEmitToMultiFeatureEditChangedIndex(toEmitFeature: Feature ){
  console.log('is emitting changed feature' , toEmitFeature )
  this.changeMultiFeatureIndex.emit(toEmitFeature)
  // once this feature is emitted the multiFeature Edit will change index and this whole 
  // component will restart again
}


 onDeleteSingleWordItem(){
   this.deleteSingleFeatureItem.emit(this.currentI)
 }

 ngOnDestroy(){
  this.updateOnUserEmail.unsubscribe()
  this.featureContainerPlusParentsHaveBeenStored.unsubscribe()
  this.initialPersonalSingleWordHaveBeenStoredSubscription.unsubscribe()

 }


}
