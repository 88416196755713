
<div class="ParagraphComponentFrame " >

    

    <form [formGroup]="multiFeatureNameForm" (ngSubmit)="onUpdateMultiFeatureEdit()">
  
         
        <h2 style="color: #3f51b5 ">multi feature </h2>
            
            <div class="form-group">
            
              
                <mat-form-field class="almost-half-width">
                <input 
                type="text" 
                id="name"
                formControlName="name"
                matInput
                placeholder="name:"
                maxlength="40">
                </mat-form-field>
      
            
            </div>
    </form>

   


    
     

    <!-- IS DESKTOP -->
     <div *ngIf="!(isHandset$ | async)"
     cdkDropList
     cdkDropListOrientation="horizontal"
     [cdkDropListData]="features"
     class="ParagraphComponentFrameRowAzure"
     (cdkDropListDropped)="onDropSingleFeature($event)"> 

     <h2 *ngIf="droppedSingleFeature" align="center"  style="color: #3f51b5 ">select components  </h2>
    
    

           <div  
           matTooltip="DRAG & DROP"
           matTooltipPosition="above"
           *ngFor="let feature of multiFeature.features ; let i=index "    
           cdkDrag [cdkDragStartDelay]="300">
            
           
             
             <div  class="ParagraphComponentFrame "   [ngSwitch]="feature.featureName " >
               

               <div *ngSwitchCase="'noCase'">
                <div>
                    <h2 style="color: #3f51b5 ">
                        components here 
                    </h2>
                </div>
               </div>

               <app-single-word-list 
               *ngSwitchCase="'singleWord'"
               [weAreInMultiFeatureEdit]="weAreInMultiFeatureEdit"
               [isParagraphEdit]="isParagraphEdit" 
               [currentI]="i"  
               [selectedIndex_id]="feature.feature_id" 
               (changeMultiFeatureIndex)='onChangedMultiFeatureIndex($event)' 
               (holdOnCreateUpdateButtons)="onHoldCreateUpdateButtons()"
               (deleteSingleFeatureItem)='onRemoveMultiFeature($event)'>
              </app-single-word-list>

               <app-single-word-input-list 
               *ngSwitchCase="'singleWordInput'"
               [weAreInMultiFeatureEdit]="weAreInMultiFeatureEdit"
               [isParagraphEdit]="isParagraphEdit" 
               [currentI]="i"  
               [selectedIndex_id]="feature.feature_id"
               (changeMultiFeatureIndex)='onChangedMultiFeatureIndex($event)' 
               (holdOnCreateUpdateButtons)="onHoldCreateUpdateButtons()"
               (deleteSingleFeatureItem)='onRemoveMultiFeature($event)' >
              </app-single-word-input-list>

              <app-toggle-single-word-button-list 
              *ngSwitchCase="'toggleSingleWordButton'"
              [weAreInMultiFeatureEdit]="weAreInMultiFeatureEdit"
              [isParagraphEdit]="isParagraphEdit" 
              [currentI]="i"  
              [selectedIndex_id]="feature.feature_id" 
              (changeMultiFeatureIndex)='onChangedMultiFeatureIndex($event)'
              (holdOnCreateUpdateButtons)="onHoldCreateUpdateButtons()" 
              (deleteSingleFeatureItem)='onRemoveMultiFeature($event)'>
              </app-toggle-single-word-button-list>

              <app-single-drop-down-select-list 
              *ngSwitchCase="'singleDropDownSelect'"
              [weAreInMultiFeatureEdit]="weAreInMultiFeatureEdit"
              [isParagraphEdit]="isParagraphEdit" 
              [currentI]="i"  
              [selectedIndex_id]="feature.feature_id" 
              (changeMultiFeatureIndex)='onChangedMultiFeatureIndex($event)' 
              (holdOnCreateUpdateButtons)="onHoldCreateUpdateButtons()"
              (deleteSingleFeatureItem)='onRemoveMultiFeature($event)'>
              </app-single-drop-down-select-list>

              <app-single-slider-list 
              *ngSwitchCase="'singleSlider'"
              [weAreInMultiFeatureEdit]="weAreInMultiFeatureEdit"
              [isParagraphEdit]="isParagraphEdit" 
              [currentI]="i"  
              [selectedIndex_id]="feature.feature_id" 
              (changeMultiFeatureIndex)='onChangedMultiFeatureIndex($event)' 
              (holdOnCreateUpdateButtons)="onHoldCreateUpdateButtons()"
              (deleteSingleFeatureItem)='onRemoveMultiFeature($event)'>
              </app-single-slider-list>


              
             </div>
           
           </div>  
     </div>
     <!-- END IS DESKTOP -->

     <!-- IS MOBILE -->
     <div *ngIf="isHandset$ | async"
     class="ParagraphComponentFrameRowAzure"> 

     <h2 *ngIf="droppedSingleFeature" align="center"  style="color: #3f51b5 ">select components  </h2>
    
    

           <div *ngFor="let feature of multiFeature.features ; let i=index " >
            
           
             
             <div  class="ParagraphComponentFrame"  [ngSwitch]="feature.featureName " >
               

               <div *ngSwitchCase="'noCase'">
                <div>
                    <h2 style="color: #3f51b5 ">
                         components here 
                    </h2>
                </div>
               </div>

               <app-single-word-list 
               *ngSwitchCase="'singleWord'"
               [weAreInMultiFeatureEdit]="weAreInMultiFeatureEdit"
               [isParagraphEdit]="isParagraphEdit" 
               [currentI]="i"  
               [selectedIndex_id]="feature.feature_id" 
               (changeMultiFeatureIndex)='onChangedMultiFeatureIndex($event)' 
               (holdOnCreateUpdateButtons)="onHoldCreateUpdateButtons()"
               (deleteSingleFeatureItem)='onRemoveMultiFeature($event)'>
              </app-single-word-list>

               <app-single-word-input-list 
               *ngSwitchCase="'singleWordInput'"
               [weAreInMultiFeatureEdit]="weAreInMultiFeatureEdit"
               [isParagraphEdit]="isParagraphEdit" 
               [currentI]="i"  
               [selectedIndex_id]="feature.feature_id"
               (changeMultiFeatureIndex)='onChangedMultiFeatureIndex($event)' 
               (holdOnCreateUpdateButtons)="onHoldCreateUpdateButtons()"
               (deleteSingleFeatureItem)='onRemoveMultiFeature($event)' >
              </app-single-word-input-list>

              <app-toggle-single-word-button-list 
              *ngSwitchCase="'toggleSingleWordButton'"
              [weAreInMultiFeatureEdit]="weAreInMultiFeatureEdit"
              [isParagraphEdit]="isParagraphEdit" 
              [currentI]="i"  
              [selectedIndex_id]="feature.feature_id" 
              (changeMultiFeatureIndex)='onChangedMultiFeatureIndex($event)'
              (holdOnCreateUpdateButtons)="onHoldCreateUpdateButtons()" 
              (deleteSingleFeatureItem)='onRemoveMultiFeature($event)'>
              </app-toggle-single-word-button-list>

              <app-single-drop-down-select-list 
              *ngSwitchCase="'singleDropDownSelect'"
              [weAreInMultiFeatureEdit]="weAreInMultiFeatureEdit"
              [isParagraphEdit]="isParagraphEdit" 
              [currentI]="i"  
              [selectedIndex_id]="feature.feature_id" 
              (changeMultiFeatureIndex)='onChangedMultiFeatureIndex($event)' 
              (holdOnCreateUpdateButtons)="onHoldCreateUpdateButtons()"
              (deleteSingleFeatureItem)='onRemoveMultiFeature($event)'>
              </app-single-drop-down-select-list>

              <app-single-slider-list 
              *ngSwitchCase="'singleSlider'"
              [weAreInMultiFeatureEdit]="weAreInMultiFeatureEdit"
              [isParagraphEdit]="isParagraphEdit" 
              [currentI]="i"  
              [selectedIndex_id]="feature.feature_id" 
              (changeMultiFeatureIndex)='onChangedMultiFeatureIndex($event)' 
              (holdOnCreateUpdateButtons)="onHoldCreateUpdateButtons()"
              (deleteSingleFeatureItem)='onRemoveMultiFeature($event)'>
              </app-single-slider-list>


              
             </div>
           
           </div>  
     </div>
     <!-- END IS MOBILE -->



    </div>

    <app-loading-spinner *ngIf="holdCreateUpdateButtons_onLoadingVisual"></app-loading-spinner>


    <div *ngIf="multiFeature.features.length > 0">
    <div *ngIf="!holdCreateUpdateButtons_onLoadingVisual" 
          (click)="onUpdateMultiFeatureEdit()"  
          style="background-color:#3f51b5" 
          class="ParagraphComponentButton pointer" 
          >
      <h2  align="center" style="color: white;">  confirm </h2>
    </div>
    </div>


    

