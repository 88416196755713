import { Component, EventEmitter, Input, OnDestroy, OnInit , Output } from '@angular/core';
import { UsersAccess } from './users-access.model';
import { CanDo } from './who-can-do.model';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { ContactService } from 'src/app/contacts/contact.service';
import { Contact } from 'src/app/contacts/contact.model';
import { ContactDetails } from 'src/app/contacts/contact-details.model';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';

interface InputLabelInterface{

  exist: boolean ,
  name: string,
  maxLength: number,
  errorHandler: boolean 
}


@Component({
  selector: 'app-users-access-edit',
  templateUrl: './users-access-edit.component.html',
  styleUrls: ['./users-access-edit.component.css']
})
export class UsersAccessEditComponent implements OnInit, OnDestroy {

  user: string 

  @Input() usersAccess: UsersAccess 
  @Input() accessType: string 

@Output() updatedUsersAccessEvent = new EventEmitter<UsersAccess>()

  options: any[] = []
 

  
  inputLabelInterface: InputLabelInterface
  inputName: string = ''
  inputNameIsValid: boolean 
  temporaryNewInputName: string 

  emptyInputNameAfterAdded = new Subject<void>()

  updateOnUserEmail: Subscription


  constructor( private breakpointObserver: BreakpointObserver , 
               private userObjectUpdateService: UserObjectUpdateService , 
               private contactService: ContactService , 
               private initialNullSetupService: InitialNullSetupService,
               private postComponentsHttpRequestService: PostComponentsHttpRequestService ) { }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  ngOnInit(): void {

    this.user = this.userObjectUpdateService.userEmail
 
    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
    .subscribe((updatedUserEmail)=>{
     this.user = updatedUserEmail
     console.log('updated USER = ', this.user)
    })

    const inputName = 'input new access email'
    this.inputLabelInterface = { exist: true, name: inputName, maxLength: 40, errorHandler: true }

    if ( this.usersAccess.canEdit.onlyOwnerCan ){
         this.options = [
            { label: 'can view', checked: true },
            { label: 'can use', checked: false }
          ];
    }
    if ( !this.usersAccess.canEdit.onlyOwnerCan ){
         this.options = [
             { label: 'can view', checked: true },
             { label: 'can use', checked: false },
             { label: 'can edit', checked: false }
         ];
    }

    if ( this.inputName.length > 0 ){ this.inputNameIsValid = true  }
    if ( this.inputName.length === 0 ){ this.inputNameIsValid = false  }
    

  }

  handleClick(index: number): void {
    const selectedOption = this.options[index];
    const isOptionSelected = selectedOption.checked;

  if ( !this.usersAccess.canEdit.onlyOwnerCan ){
    if (index === 0)  { this.options = [
        { label: 'can view', checked: true },
        { label: 'can use', checked: false },
        { label: 'can edit', checked: false }
      ];
      console.log(this.options)
    }
    if (index === 1) {
    
      this.options = [
        { label: 'can view', checked: true },
        { label: 'can use', checked: true },
        { label: 'can edit', checked: false }
      ];
      console.log(this.options)
    }
    
    if (index === 2) {
      this.options = [
        { label: 'can view', checked: true },
        { label: 'can use', checked: true },
        { label: 'can edit', checked: true }
      ];
      console.log(this.options)
    }
  }

  if ( this.usersAccess.canEdit.onlyOwnerCan ){
    if (index === 0)  { this.options = [
        { label: 'can view', checked: true },
        { label: 'can use', checked: false }
      ];
      console.log(this.options)
    }
    if (index === 1) {
    
      this.options = [
        { label: 'can view', checked: true },
        { label: 'can use', checked: true }
      ];
      console.log(this.options)
    }
    
  }


  }

  onUpdateNameInputEvent($event){
    this.temporaryNewInputName = $event
    console.log('temporary new name => ', this.temporaryNewInputName)
  }

  

  onChoosenOption(option: string){
    // console.log(this.choosenOption); // Output the value to the console
  }

 

  onDeleteCanViewEmail(i: number){ // the email will be cancelled from canUse and canEdit if present
    console.log('delete can view ', i)

    const emailToDelete: string = this.usersAccess.canView.names[i]
    this.usersAccess.canView.names.splice(i, 1)

    if ( this.usersAccess.canUse.names.findIndex(x=> x === emailToDelete ) !== -1 ){
         const indexToDetele: number = this.usersAccess.canUse.names.findIndex(x=> x === emailToDelete )
         this.usersAccess.canUse.names.splice(indexToDetele, 1)
    }

    if ( this.usersAccess.canEdit.names.findIndex(x=> x === emailToDelete ) !== -1 ){
      const indexToDetele2: number = this.usersAccess.canEdit.names.findIndex(x=> x === emailToDelete )
      this.usersAccess.canEdit.names.splice(indexToDetele2, 1)
 }

    

  }

  onDeleteCanUseEmail(i: number){ // the email will be cancelled from can Edit also
    console.log('delete can Use ', i)

    const emailToDelete: string = this.usersAccess.canUse.names[i]
    this.usersAccess.canUse.names.splice(i, 1)

    if ( this.usersAccess.canEdit.names.findIndex(x=> x === emailToDelete ) !== -1 ){
      const indexToDetele: number = this.usersAccess.canEdit.names.findIndex(x=> x === emailToDelete )
      this.usersAccess.canEdit.names.splice(indexToDetele, 1)
    }
  }

  onDeleteCanEditEmail(i: number){ // the email will be cancel from here only 
    console.log('delete can Edit ', i)

    this.usersAccess.canEdit.names.splice(i, 1)

  }



  onConfirmUsersAccess(){
    console.log('confirm users added', this.temporaryNewInputName, this.options )

    const lowercaseValue = this.temporaryNewInputName.toLowerCase()
    this.checkIfContactExistOnContactList(lowercaseValue)


    if ( !this.options[1].checked ){ // if 1 is false only 0 position can be true

      if ( this.usersAccess.canView.names.findIndex(x=> x === this.temporaryNewInputName ) === -1 ){
      const actualCanViewNames: string[] = this.usersAccess.canView.names

      const newCanViewNames: string[] = actualCanViewNames
            newCanViewNames.push(this.temporaryNewInputName)
      const newViewDescription: string = this.usersAccess.canView.description
      const newView_OnlyOwnerCan: boolean = this.usersAccess.canView.onlyOwnerCan

      const newCanView = new CanDo (newCanViewNames, newViewDescription, newView_OnlyOwnerCan  )

      const newUserAccess: UsersAccess = this.usersAccess
            newUserAccess.canView = newCanView

      this.updatedUsersAccessEvent.emit(newUserAccess)

      }
      this.onEmptyInputNameAfterAdded()

    }

    if ( this.options[1].checked && !this.options[2]?.checked  ){

      if ( this.usersAccess.canView.names.findIndex(x=> x === this.temporaryNewInputName ) === -1 ){
      const actualCanViewNames: string[] = this.usersAccess.canView.names

      const newCanViewNames: string[] = actualCanViewNames
            newCanViewNames.push(this.temporaryNewInputName)
      const newViewDescription: string = this.usersAccess.canView.description
      const newView_OnlyOwnerCan: boolean = this.usersAccess.canView.onlyOwnerCan

      const newCanView = new CanDo (newCanViewNames, newViewDescription, newView_OnlyOwnerCan  )

      this.usersAccess.canView = newCanView
      this.updatedUsersAccessEvent.emit(this.usersAccess)
      }

      if ( this.usersAccess.canUse.names.findIndex(x=> x === this.temporaryNewInputName ) === -1 ){
      
        const actualCanUseNames: string[] = this.usersAccess.canUse.names
        const newCanUseNames: string[] = actualCanUseNames
            newCanUseNames.push(this.temporaryNewInputName)
      const newUseDescription: string = this.usersAccess.canUse.description
      const newUse_OnlyOwnerCan: boolean = this.usersAccess.canUse.onlyOwnerCan

      const newCanUse = new CanDo (newCanUseNames, newUseDescription, newUse_OnlyOwnerCan  )

    
            this.usersAccess.canUse = newCanUse

      this.updatedUsersAccessEvent.emit(this.usersAccess)
      }
      this.onEmptyInputNameAfterAdded()
      
    }

    if ( this.options[2]?.checked  ){ // for sure all 3 are checked

      if ( this.usersAccess.canView.names.findIndex(x=> x === this.temporaryNewInputName ) === -1 ){
      const actualCanViewNames: string[] = this.usersAccess.canView.names

      const newCanViewNames: string[] = actualCanViewNames
            newCanViewNames.push(this.temporaryNewInputName)
      const newViewDescription: string = this.usersAccess.canView.description
      const newView_OnlyOwnerCan: boolean = this.usersAccess.canView.onlyOwnerCan

      const newCanView = new CanDo (newCanViewNames, newViewDescription, newView_OnlyOwnerCan  )

    
            this.usersAccess.canView = newCanView
            this.updatedUsersAccessEvent.emit(this.usersAccess)
      }
      // -----------------------------------------------------------------------------------------
      
      if ( this.usersAccess.canUse.names.findIndex(x=> x === this.temporaryNewInputName ) === -1 ){
      const actualCanUseNames: string[] = this.usersAccess.canUse.names

      const newCanUseNames: string[] = actualCanUseNames
            newCanUseNames.push(this.temporaryNewInputName)
      const newUseDescription: string = this.usersAccess.canUse.description
      const newUse_OnlyOwnerCan: boolean = this.usersAccess.canUse.onlyOwnerCan

      const newCanUse = new CanDo (newCanUseNames, newUseDescription, newUse_OnlyOwnerCan  )

      this.usersAccess.canUse = newCanUse
      this.updatedUsersAccessEvent.emit(this.usersAccess)
      }

      //-----------------------------------------------------------------------------------------

      if ( this.usersAccess.canEdit.names.findIndex(x=> x === this.temporaryNewInputName ) === -1 ){
      const actualCanEditNames: string[] = this.usersAccess.canEdit.names

      const newCanEditNames: string[] = actualCanEditNames
            newCanEditNames.push(this.temporaryNewInputName)
      const newEditDescription: string = this.usersAccess.canEdit.description
      const newEdit_OnlyOwnerCan: boolean = this.usersAccess.canEdit.onlyOwnerCan

      const newCanEdit = new CanDo (newCanEditNames, newEditDescription, newEdit_OnlyOwnerCan  )


   
      this.usersAccess.canEdit = newCanEdit

      this.updatedUsersAccessEvent.emit(this.usersAccess)

      }
      this.onEmptyInputNameAfterAdded()
      
    }


  }

  checkIfContactExistOnContactList(newContact: string ){

    if ( this.contactService.contacts[0].contactDetails.findIndex( x=> x.contactEmail === newContact ) === -1 ){
      // add contact to list 
        const contactName = undefined 
        const contactEmail= newContact 
        const telNumber = undefined
        const isAConnection = true
        const isBlocked = false
        const isBeenReported= false 

        const newContactDetail = new ContactDetails (contactName, contactEmail, telNumber, isAConnection, isBlocked, isBeenReported )
         
         const contactItem: Contact = this.contactService.contacts[0] 
               contactItem.contactDetails.push(newContactDetail)

               this.contactService.contacts[0] = contactItem 
               this.initialNullSetupService.initialNullContacts[0] = contactItem

        if ( this.user !== null ){
         this.postComponentsHttpRequestService.httpUpdateComponent(this.user, 'contact', contactItem )
             .subscribe((res)=> {
              console.log(res)
             }, error => {
              console.log( error )
             })
        }
    }


  }

  onUpdateNameInputValidity($event){
    this.inputNameIsValid = $event 
  }

  onEmptyInputNameAfterAdded(){
    this.emptyInputNameAfterAdded.next()

    if ( !this.usersAccess.canEdit.onlyOwnerCan ){
    this.options = [
      { label: 'can view', checked: true },
      { label: 'can use', checked: false },
      { label: 'can edit', checked: false }
    ];
    }

    if ( this.usersAccess.canEdit.onlyOwnerCan ){
      this.options = [
        { label: 'can view', checked: true },
        { label: 'can use', checked: false }
      ];
      }



  }

  ngOnDestroy(): void {
      this.updateOnUserEmail?.unsubscribe()
  }

}
