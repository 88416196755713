<!-- <p>cc-mail-account works!</p> -->


<form [formGroup]="rulesForm">
	<mat-form-field class="chip-list">
        <mat-label style="color: grey"> CC TO </mat-label>
		<mat-chip-list #chipList formArrayName="emails">
      <div *ngFor="let item of emailList; let i=index" >
        <mat-chip 
        *ngIf="!item.invalid"
        style="background-color: grey; color: white ;"
        selected 
        [selectable]="true" 
        [removable]="removable"
			  (removed)="removeEmail(item)" 
        required name="chips">
        {{item.value}}
				<mat-icon  
         matChipRemove
         style="color: white;"
          *ngIf="removable">cancel</mat-icon>
			</mat-chip>
      <mat-chip 
      *ngIf="item.invalid"
      style="background-color: red; color: white ;"
      selected 
      [selectable]="true" 
      [removable]="removable"
      (removed)="removeEmail(item)" 
      required name="chips">
      {{item.value}}
      <mat-icon  
       matChipRemove
       style="color: black"
        *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    </div>
      <!-- placeholder="add email "  -->
			<input 
        [matChipInputFor]="chipList" 
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes " 
        [matAutocomplete]="auto"
        [matChipInputAddOnBlur]="true "
			  (matChipInputTokenEnd)="add($event) " />
		</mat-chip-list>

    <mat-autocomplete  #auto="matAutocomplete" (optionSelected)="selected($event)" >
      <mat-option *ngFor="let contact of contactEmails; let i=index" [value]="i">
        <div >
          {{contact}}
        </div>
      </mat-option>
    </mat-autocomplete>

	</mat-form-field>
  
</form>

