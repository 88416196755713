<p>numbers-add-reset-on-date works!</p>

<p>the numbe that is input will be add to the previous total , so the result will show 

    (next to add there is button + , if clicked the button turn to minus in case need to cancel 
    can be a double click button  )
    + added number 
      total in big 

      the total number will be starting again from 0 or from "to be decided number "
</p>
