import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-word-less',
  templateUrl: './word-less.component.html',
  styleUrls: ['./word-less.component.css']
})
export class WordLessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
