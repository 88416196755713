import { Component, OnInit, Input } from '@angular/core';
import { ParagraphResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/paragraph-result.model';
import { Subscription, Observable, Subject } from 'rxjs';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';

@Component({
  selector: 'app-chat-paragraph-result-list',
  templateUrl: './chat-paragraph-result-list.component.html',
  styleUrls: ['./chat-paragraph-result-list.component.css']
})
export class ChatParagraphResultListComponent implements OnInit {

  user: string 

  @Input() subject: string;
  @Input() paragraphResults: ParagraphResult[];
  @Input() dateAndTime: string; 
  @Input() sender: string;
  @Input() sentToMessage: boolean 
  @Input() isReceived: boolean
  @Input() listOfSubjects: string[]

  // updatedFormResultsSubscription: Subscription ;
  // @Input() subscribeUpdatedFormResults: Observable<void>;
  // subscribeNewFormResults: Subject<void> = new Subject<void>();




  constructor(  private userObjectUpdateService: UserObjectUpdateService) { }

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

  };





}
