import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";


@Injectable({
    providedIn: 'root'
})

export class AppLoadingService { 

    private initiateAppLoading  = new Subject<any>();
    private stopAppLoading = new Subject<any>()

    // ----------- start loading spinner / from header.ts  ------------------------------
    onInitializeAppLoading(){
        console.log('it starting loading')
        this.initiateAppLoading.next()
    }

    getAppLoadingInitiate(): Observable<any> {
        return this.initiateAppLoading.asObservable()
    }
    //----------------end -----------------------------------------------


    

    // --------------stop loading spinner /coming from wordInput or last updated component -> going to header.ts -------------------------------

    onStoppingAppLoading(){
        setTimeout(() => {
            console.log( 'STOP LOADING APP')
            this.stopAppLoading.next()
        }, 500);
       
    }

    getAppLoadingToStop(): Observable<any>{
        return this.stopAppLoading.asObservable()
    }

    //-----------------end ------------------------------------------------

    



}