import { Component, Input, OnInit } from '@angular/core';
import { ParagraphResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/paragraph-result.model';
import { FeatureResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/feature-result.model';
import { Subscription, Subject, Observable } from 'rxjs';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';


@Component({
  selector: 'app-chat-paragraph-result-item',
  templateUrl: './chat-paragraph-result-item.component.html',
  styleUrls: ['./chat-paragraph-result-item.component.css']
})
export class ChatParagraphResultItemComponent implements OnInit {

  user: string 

  @Input() subject: string
  @Input() paragraphResult: ParagraphResult;
  @Input() dateAndTime: string; 
  @Input() sender: string
  @Input() sentToMessage: boolean 
  @Input() isReceived: boolean
  

  useSubstitute: boolean ;
  subjectSubstituteToNull: string = '< --- >'

  featureResults: FeatureResult[];
  featureResult: FeatureResult ;



  // updatedFormResultsSubscription: Subscription ;
  // @Input() subscribeUpdatedFormResults: Observable<void>;
  // subscribeNewFormResults: Subject<void> = new Subject<void>();

  constructor(  private userObjectUpdateService: UserObjectUpdateService ) { }

  ngOnInit(){
    this.user = this.userObjectUpdateService.userEmail

    this.featureResults = this.paragraphResult.featureResults ; 



    if (this.subject ===''){
       this.useSubstitute = true
    } else {
      this.useSubstitute = false
    }

  //  this.updatedFormResultsSubscription = this.subscribeUpdatedFormResults.subscribe(()=> this.onSubscribeNewFormResults())
  }

  // onSubscribeNewFormResults(){
  //   this.featureResults = this.paragraphResult.featureResults ; 

  //   if (this.subject ===''){
  //      this.useSubstitute = true
  //   } else {
  //     this.useSubstitute = false
  //   }
   
  //   console.log('subscription has arrived')
  //   this.subscribeNewFormResults.next()
  // }

  // ngOnDestroy():void{
  //   this.updatedFormResultsSubscription.unsubscribe()
  // }



}
