import { SingleWordInputDetail } from "./single-word-input-detail.model"; 

export class SingleWordInput {

    constructor ( public _id: string , 
                  public componentName: string , 
                  public UIID: string , 
                  public owner: string , 
                  public dateAndTime: string ,  
                  public allUsersAllowed: boolean , 
                  public userListAllowed: string[], 
                  public name: string , 
                  public singleWordInputDetails: SingleWordInputDetail[] 
                  ){}
}