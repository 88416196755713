<!-- <p>fix-text-item works!</p> -->


<div class="row">
    <div class="col-xs-12">

        <div class="row">
            <div class="col-xs-12">
              <div class="form-group">

              
                    <h2 hidden >  {{ fixText.name }}  </h2>
                 
                 <!-- layout WTF icon https://icons.getbootstrap.com/icons/layout-wtf/ -->
                 <div *ngIf="false" class="row">
                   <div class="col-xs-4"></div>
                   <div class="col-xs-7"></div>
                   <div class="col-xs-1">
                      <svg  (click)="openDialogWordInputEdit()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-layout-wtf" viewBox="0 0 16 16">
                        <path d="M5 1v8H1V1h4zM1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1zm13 2v5H9V2h5zM9 1a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9zM5 13v2H3v-2h2zm-2-1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H3zm12-1v2H9v-2h6zm-6-1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H9z"/>
                      </svg>
                   </div>
                 </div>
                       
                        
              </div>
            </div>
          </div>

          <table>

            <div *ngFor="let detail of fixText.details; let i=index">
              
                <tr >
                {{detail.text}}
                </tr>
                <br>

            </div>
             
          </table>
        
    </div>
</div>
