import { Component, OnInit , Input, Output, EventEmitter} from '@angular/core';
import { FormIndexTopic } from 'src/app/forms/form-list/form-index-topic-list/form-index-topic.model';
import { FormIndexTopicService } from 'src/app/forms/form-list/form-index-topic-list/form-index-topic.service';
import { FormGroup, FormControl } from '@angular/forms';
import { FormService } from 'src/app/forms/form.service';
import { FormIndexTopicDialog } from './form-index-topic-dialog.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { Header } from 'src/app/header/header.model';
import { HeaderService } from 'src/app/header/header.service';
import { RecipientIndexService } from '../../form-edit/recipient-index-list/recipient-index.service';
import { Form } from 'src/app/forms/form.model';
import { FormIndexTypeInterface } from '../../form-index-type-interface.model';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-form-index-topic',
  templateUrl: './form-index-topic.component.html',
  styleUrls: ['./form-index-topic.component.css']
})
export class FormIndexTopicComponent implements OnInit {

  user: string 

  formIndexTopicEditName : FormGroup ; 

formIndexTopic: FormIndexTopic ;
formIndexTopics: FormIndexTopic[];



    @Input() index_id: string ;
    index: number ;

   /** gives paragraph Index the topic to add when add paragraph */
   topicIndex : number ;

   /** to hide the buttons and keep only the topic */
   hideForms = true ;

  /** these 2 serve to have a max limit on paragraphs to add */
   createButtonLimit : boolean ;
  formsIndexLimit = 50 ;

  count = 0;

  weGoingToComponentIndex : boolean = true ;

  isDoubleClick : boolean = false ;

  /** set timing function */
  setTimer: any ;

  /** to hide 1st click button on modify index name */
  hide1stButton = false ;
  
/** hide and show modify index button  */
  hideButton = false ;

  @Input() typeOfFormIndexTopic: FormIndexTypeInterface
  typeOfIndexTopicInUse: string 

  @Input() trialFormIndexTopic: FormIndexTopic

  @Output() closeSideNavOnFormList = new EventEmitter<void>()
  @Output() adviseFormListOnLoadingForm = new EventEmitter<void>()

  mouseEnter: boolean = false 
  isLoading: boolean = false 


  constructor( private formIndexTopicService : FormIndexTopicService,
               private formIndexTopicDialog : FormIndexTopicDialog,
               private breakpointObserver: BreakpointObserver ,
               private postComponentsHttpRequestService: PostComponentsHttpRequestService ,
               private userObjectUpdateService: UserObjectUpdateService ,
               private formService: FormService ,
               private headerService: HeaderService ,
               private recipientIndexService: RecipientIndexService) { }

  
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  ngOnInit() {
    this.user = this.userObjectUpdateService.userEmail

    this.formIndexTopic = this.formIndexTopicService.getFormIndexTopic(this.formIndexTopicService.getFormIndexTopics().findIndex(x=> x._id === this.index_id));

    this.index = (this.formIndexTopicService.getFormIndexTopics().findIndex(x=> x._id === this.index_id))
    console.log(this.formIndexTopic)



      /** here i'll have to set the length of the nonRemoved ones , or they will count also the ones removed 
     * from the list
     */
       if ( this.formIndexTopicService.getFormIndexTopics().length < this.formsIndexLimit ){
        this.createButtonLimit = true
      } else {
        this.createButtonLimit = false 
      };

    this.formIndexTopicEditName = new FormGroup({
      name: new FormControl ('') ,   
        })
  }

  onMouseEnter(){
    this.mouseEnter = true
  }

  onMouseLeave(){
    this.mouseEnter = false 
  }

  onIsDoubleClick(i){
    this.isDoubleClick = !this.isDoubleClick;

  
    /** start a new timer */
     setTimeout(() => {
      this.isDoubleClick = false ;
    }, 2000);
    // console.time(this.setTimer)
  }

/** clear timer before itstart again */
  clearTimer(){
  clearTimeout(this.setTimer)
}

  /** this serve to show the lisy of paragraphs , they are hidden when start
   * they open when user click on the topic 
   */
  onHideForms(){
    this.hideForms = !this.hideForms;

    /** clear timer before it start again */
    this.clearTimer()
    
   this.setTimer= setTimeout(() => {
      this.hideForms = true ;
    // }, 15000);
  }, 45000);
  }




  onHide1stButton(){
    this.hide1stButton = !this.hide1stButton ;
      setTimeout(() => {
        this.hide1stButton = false ;
      }, 1000);
    
  
  }

/** hide button to start editing the index name  */
  onHideButton(){
    this.hideButton = !this.hideButton;
    setTimeout(() => {
   this.hideButton = false ;
   this.hide1stButton = false;

  }, 15000);

}

  /**
   * 
   * @param i send form ID to be used and display form Item 
   */

  onCloseSideNav(){
    this.closeSideNavOnFormList.emit()
  }


  onAdviseFormListOnLoadingForm(){
    this.adviseFormListOnLoadingForm.emit()
    this.onCloseSideNav()
  }


onSubmit(){

  if ( this.user !== null ){this.onSubmitExistingUser() }
  if ( this.user === null ){ this.eventuallyChangeTheNameAnyway()}
  
}

onSubmitExistingUser(){

  const updatingThisFormIndexTopic: FormIndexTopic = this.formIndexTopic
  updatingThisFormIndexTopic.name = this.formIndexTopicEditName.value.name

  console.log(updatingThisFormIndexTopic)
  this.postComponentsHttpRequestService.httpUpdateComponent(this.user, 'formIndexTopic', updatingThisFormIndexTopic)
      .subscribe((res)=>{
        console.log(res)

        this.eventuallyChangeTheNameAnyway()

      }, error => {
        console.log(error, 'throw a message')

        this.eventuallyChangeTheNameAnyway()
      })

}

eventuallyChangeTheNameAnyway(){
  this.formIndexTopicService.getFormIndexTopic(this.index).name = this.formIndexTopicEditName.value.name

  this.formIndexTopic = this.formIndexTopicService.getFormIndexTopic(this.index)
  this.hide1stButton = false;
  this.hideButton = false ;

  this.formIndexTopicEditName.value.name = '' ;

  console.log(this.formIndexTopicService.getFormIndexTopics())
}

onAcceptFormIndexTopic(){

  this.isLoading = true 

const new_id = this.formIndexTopic._id
const newComponentName = this.formIndexTopic.componentName
const newUIID = this.formIndexTopic.UIID
const newOwner = this.formIndexTopic.owner
const newDateAndTime = this.formIndexTopic.dateAndTime
const newAllUsersAllowed = this.formIndexTopic.allUsersAllowed
const newUserListAllowed = this.formIndexTopic.userListAllowed
const newIsAccepted = true 
const newIsArchived = this.formIndexTopic.isArchived
const newName = this.formIndexTopic.name
const newFormFeatureContainer_ids = this.formIndexTopic.formFeatureContainer_ids

const newFormIndexTopic = new FormIndexTopic ( new_id, newComponentName, newUIID, newOwner, newDateAndTime, newAllUsersAllowed, newUserListAllowed,
       newIsAccepted, newIsArchived, newName, newFormFeatureContainer_ids )

      this.postComponentsHttpRequestService.httpUpdateComponent(this.user, 'formIndexTopic', newFormIndexTopic )
      .subscribe((res)=> 
      { 
        console.log(res) 
        this.formIndexTopicService.formIndexTopics[this.formIndexTopicService.getFormIndexTopics().findIndex(x=> x._id === newFormIndexTopic._id)] = newFormIndexTopic
        this.formIndexTopic = newFormIndexTopic 

        setTimeout(()=>{
          this.isLoading = false 
        },200)
      
      })

}

onWeAreGoingToCreateForm(){

  const newHeaderIndexSelection = new Header (true, false, false, false, false, false, false, false )
  this.headerService.nextIndexSelection(newHeaderIndexSelection)

   /**
   * update startingForm @ formservice  
   */
    // line 1067 - 1335  to fix on formEdit
   const startingEditForm: Form = new Form ( null , 'form', null, '', '','', false, [], false, false, false, true, false, '', this.recipientIndexService.getRecipientIndex(0)._id , [] )
   this.formService.onUpdateLatestShownForm(startingEditForm)
  


}





}
