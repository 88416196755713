import { Injectable , EventEmitter} from '@angular/core';
import { WordHintInput } from './word-hint-input.model';
import { WordHintDetail } from './word-hint-detail.model';



@Injectable ({
    providedIn: 'root' 
})

export class SharedWordHintInputService {


private wordsHintInput : WordHintInput[] = [
   new WordHintInput ( null , 'beppe@gmail.com', null,
       'first hint input' , 
       [ 
           new WordHintDetail ('city' , 'Vicenza',  'scrivi qui' ),
           new WordHintDetail ('address', 'Via ciao', 'gia qui'  ),
           new WordHintDetail ('interno', 'ex.: 3rd floor unit 3', 'solo qui' ), 
        //    new Detail ('n.', 'ex.: A3 ', 'va'), 
           new WordHintDetail ('address', 'Via ciao', 'la' ),

       ]
   ),

   new WordHintInput ( null , 'joey@gmail.com', null,
       'secondo hint input',
       [
           new WordHintDetail ('saluto', 'ciao', 'prova'),
       ]
   ),

   new WordHintInput ( null , 'kiky@gmail.com', null,
       'hint input terrrzooo',
       [
           new WordHintDetail ('ciao', 'ciao', 'si'  ),
           new WordHintDetail ('ancora ciao', 'saluto','giusto qui' ),
       ]
   ),

    new WordHintInput ( null , 'anny@gmail.com', null,
        ' forth hint input' , 
   [ 
       new WordHintDetail ('address', 'Via ciao', 'gia qui'  ),
       new WordHintDetail ('n.', 'ex.: A3 ', 'va'), 
       new WordHintDetail ('address', 'Via ciao', 'la' ),

   ]
),

new WordHintInput ( null , 'done@gmail.com', null,
    ' fifth hint input' , 
[ 
   new WordHintDetail ('halo' , 'Viterbo',  'scrivi qui' ),
   new WordHintDetail ('statale', 'Via ciao', 'gia qui'  ),
   new WordHintDetail ('cloud', 'ex.: 3rd floor unit 3', 'solo qui' ), 
   new WordHintDetail ('number', 'ex.: A3 ', 'va'), 
   new WordHintDetail ('addritto', 'Via ciao', 'la' ),

]
),


    ];



    getWordHintInputs(){
        return this.wordsHintInput.slice();
    }

    getWordHintInput(index:number){
        return this.wordsHintInput[index];
    }

    onAddNew( wordHintInput: WordHintInput){
        this.wordsHintInput.push(wordHintInput)
      }



}