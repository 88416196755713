import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AuthComponent } from "./auth.component";
import { UserProfileComponent } from './user-profile/user-profile.component';

@NgModule ({
    declarations: [
        AuthComponent,
        UserProfileComponent
      
    ],

    exports: [
        AuthComponent
       
    ],

    imports: [
        
        CommonModule,
        LayoutModule,
        
    ]

})
export class AuthModule {}