import { Component, OnInit } from '@angular/core';
import { FormArray , FormBuilder , FormControl , FormGroup, Validators } from '@angular/forms';
import { SELECT_PANEL_VIEWPORT_PADDING } from '@angular/material/select';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-email-button',
  templateUrl: './email-button.component.html',
  styleUrls: ['./email-button.component.css']
})
export class EmailButtonComponent implements OnInit {


  signupForm: FormGroup;
  genders=['male', 'female'];
  forbiddenUsernames = ['Chris', 'Anna']


  emailButton='';
  setWord =false;
  actionName = 'ciao';


  constructor() { }

onClickEmailButton(){
  this.setWord = ! this.setWord ;
}


  ngOnInit(){
    this.signupForm = new FormGroup ({
      'userData': new FormGroup ({
        'username': new FormControl (null, [Validators.required , this.forbiddenNames.bind(this)]),
      'email': new FormControl ('your.email@mailscream.com' , [Validators.required , Validators.email ], this.forbiddenEmails),
      }),
      'gender': new FormControl ('male'),
      // 'button1': new FormControl (null),
      'button2': new FormControl ('try this 2'),
      'hobbies': new FormArray([]),
    });
    // this.signupForm.valueChanges.subscribe(
    //   (value)=> console.log(value)
    // );
    this.signupForm.statusChanges.subscribe(
      (status)=> console.log(status)
    );
    this.signupForm.setValue({
      'userData': {
        'username': 'Max',
        'email': 'max@test.com'
      },
      'gender': 'male',
       'button2': 'eccolo',
      'hobbies':[]
    });
       this.signupForm.patchValue({
        'userData': {
           'username': 'Anna',
      }
     });  
  }

  onSubmit(){
    console.log(this.signupForm);
  }

  onAddHobby(){
     const control = new FormControl(null, Validators.required );
     (<FormArray>this.signupForm.get('hobbies')).push(control);
  }

  getControls(){
    return (<FormArray>this.signupForm.get('hobbies')).controls;
  }


  forbiddenNames(control: FormControl):{[s: string]: boolean } {
  if (this.forbiddenUsernames.indexOf(control.value) !==-1) {
    return {'name is forbidden': true };
     }
       return null;
  }

  forbiddenEmails(control:FormControl): Promise<any> | Observable<any>{
    const promise = new Promise<any> ((resolve, reject) => {
      setTimeout(() => {
        if ( control.value === 'Test@test.com') {
          resolve ({'EmailIsForbidden':true });
        } else {
          resolve(null);
        }
      }, 2500 );

      });
      return promise; 
    }
  

}
