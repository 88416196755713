<div class="innerItemFrame2">
    <div class="col-xs-12">

        <div class="row">
            <h2 style="color: #3f51b5"> {{accessType}} access control </h2>
        </div>

        <br>

        <div class="row">
            <div class="col-xs-4">
                <h3>can view: </h3>
            </div>
            <div class="col-xs-7">
                <div *ngFor="let email of usersAccess.canView.names; let i=index">
                    <div class="row">
                        <div class="col-9">
                            {{email}}
                        </div>
                        <div class="col-2">
                            <svg (click)="onDeleteCanViewEmail(i)" style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                            </svg>
                        </div>
                    </div>
                    <br *ngIf="isHandset$ | async">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-11">
                <p>
                    <span class="small-sentence">{{usersAccess.canView.description}}</span>
                </p>
            </div>
        </div>

        <hr>

        <div class="row">
            <div class="col-xs-4">
                <h3>can use:</h3>
              
            </div>
            <div class="col-xs-7">
                <div *ngFor="let email of usersAccess.canUse.names; let z=index">
                    <div class="row">
                        <div class="col-9">
                            {{email}}
                        </div>
                        <div class="col-2">
                            <svg (click)="onDeleteCanUseEmail(z)" style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                            </svg>
                        </div>
                    </div>
                    <br *ngIf="isHandset$ | async">
                </div>
               
            </div>
        </div>

        <div class="row">
            <div class="col-xs-11">
                <p>
                    <span class="small-sentence">{{usersAccess.canUse.description}}</span>
                </p>
            </div>
        </div>

        <hr *ngIf="!usersAccess.canEdit.onlyOwnerCan">

        <div class="row" *ngIf="!usersAccess.canEdit.onlyOwnerCan" >
            <div class="col-xs-4">
                <h3>can edit:</h3>
            </div>
            <div class="col-xs-7">
                <div *ngFor="let email of usersAccess.canEdit.names; let y=index">
                    <div class="row">
                        <div class="col-9">
                            {{email}}
                        </div>
                        <div class="col-2">
                            <svg (click)="onDeleteCanEditEmail(y)" style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                            </svg>
                        </div>
                    </div>
                    <br *ngIf="isHandset$ | async">
                </div>
                
            </div>
        </div>
        <div class="row" *ngIf="!usersAccess.canEdit.onlyOwnerCan">
            <div class="col-xs-11">
                <p>
                    <span class="small-sentence">{{usersAccess.canEdit.description}}</span>
                </p>
            </div>
        </div>

        <hr>

        <div class="row" >
           
            <div class="col-xs-12">

                <app-name-input 
                [inputName]="inputName"
                [inputLabelInterface]="inputLabelInterface"
                (updatedNameEvent)="onUpdateNameInputEvent($event)"
                [isEmptyingInputNameAfterAdded]="emptyInputNameAfterAdded.asObservable()"
                (updateNameInputValidity)="onUpdateNameInputValidity($event)">
                </app-name-input>


            </div>
        </div>

        <div class="row" >

            <div class="col-8">
               
              <div *ngFor="let option of options; let i = index" class="checkbox-wrapper">
                <input 
                       type="checkbox" 
                       name="checkboxOptions" 
                       [checked]="option.checked" 
                       (click)="handleClick(i)">
                <label >{{ option.label }}</label>
              </div>

            

        
            </div>

            <div class="col-3" *ngIf="inputNameIsValid" >
                <button type="button" 
                        class="btn btn-primary"
                        (click)="onConfirmUsersAccess()"
                        > + 
                </button>
            </div>

           
        </div>



    </div>
</div>


