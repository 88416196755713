<!-- <p>tab-edit works!</p> -->



    <form [formGroup]="tabEditForm" (ngSubmit)="onSubmit()">
    
    
   <div  [hidden]="isEditMultiFeature" class="ParagraphComponentFrame ">
    
    <h2 style="color: red">tabs group name : {{tab.name}} </h2>
  
        
        <div class="form-group">
        <!--  [(ngModel)]="tab.name" -->
            <mat-form-field class="almost-half-width">
            <input 
            type="text" 
            id="name"
            formControlName="name"
            matInput
            placeholder="edit name: "
            maxlength="40"
           
            #nameInput>
            <mat-hint> this will be shown on the tab list   </mat-hint>
          
            </mat-form-field>
  
        
        </div>
  
        <br>



   </div>
   <br>
  
  
  
  <br>

<div class="ParagraphComponentFrame">

<mat-tab-group #tabs 
[(selectedIndex)]="selectedIndex"
(selectedTabChange)="onLinkClick($event)"    
animationDuration="0ms" >



      <mat-tab *ngFor="let innerTab of tab.tabDetails; index as i ">

                <ng-template mat-tab-label>
                <a style="cursor: pointer; color: red;">
                     {{i+1}} - {{innerTab.name}}  </a>
                </ng-template>
                <div class="col-xs-12" >

                  <br>
  
                  <div class="form-group">
            
                    <div class="row">

                      <div class="col-xs-8">
                        <h2 style="color: red">tab name : {{innerTab.name}} </h2>
                      </div>

                      <div *ngIf="!lastTabDetail" class="col-xs-4">
                        <button (click)="onDeleteInnerTab()" class="btn btn-danger btn-sm"> delete {{innerTab.name}} tab </button>
                      </div>

                    </div>
            
                    <div class="row">
                      <mat-form-field class="almost-half-width">
                        <input 
                        type="text" 
                        id="description"
                        formControlName="description"
                        matInput
                      
                        maxlength="30"
                        #descInput>
                        <mat-hint>edit tab name</mat-hint>
                          
                      </mat-form-field>
            
                    </div>
                
                       
                    
                    </div>
              
               
               </div>

               <!-- IS DESKTOP -->
               <div *ngIf="!(isHandset$ | async)">
             
                <div [hidden]="isEditMultiFeature"
                cdkDropList
                [cdkDropListData]="features"
                class="col-xs-12"
                (cdkDropListDropped)="onDrop($event)"> 
            
                <!-- hide normal features completely when editing multiFeature -->
                <div [hidden]="isEditMultiFeature">
                  
            
                <h2 [hidden]="!dropped" style="color: #3f51b5" >select components</h2>
            
               
                  <div  *ngFor="let feature of features ; let i=index "    
                        cdkDrag [cdkDragStartDelay]="300"
                        matTooltip="DRAG & DROP"
                        matTooltipPosition="above">
                  
                    <div class="ParagraphComponentFrame">
            
                   <div [hidden]="!isMobile" *ngIf="isHandset$ | async" class="row" >
            
            
                    <div class="col-xs-10"></div>
            
                    <div class="col-xs-1" (click)="onRemoveFeature(i)" >
                     <svg style="color: red" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                     <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                     </svg>
                    </div>
            
                    <div class="col-xs-1"></div>
               
                   </div>
            
                   
                   <div *ngIf="(isHandset$ | async) === false" class="row">
            
            
                     <div class="col-xs-10"></div>
            
                     <div class="col-xs-1 pointer" (click)="onRemoveFeature(i)">
                      <svg style="color: red" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                      </svg>
                     </div>
            
                     <div class="col-xs-1"></div>
            
                    </div>
            
                      <div [ngSwitch]="feature.featureName " >
                     
                      <app-word-input-list 
                              [isParagraphEdit]="isParagraphEdit"
                              [currentI]="i"  
                              [feature_from_paragraphEdit]="feature" 
                              (changeIndex)='onChangedIndex($event)' 
                              *ngSwitchCase="'wordInput'"
                              [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
                              [editFeature]="clickOnEditFeature.asObservable()">
                      </app-word-input-list>  
            
                      <app-word-hint-list  
                               [isParagraphEdit]="isParagraphEdit"
                               [currentI]="i"  
                               [feature_from_paragraphEdit]="feature" 
                               (changeIndex)='onChangedIndex($event)' 
                               *ngSwitchCase="'wordInput+sentence'"
                               [editFeature]="clickOnEditFeature.asObservable(i)" >
                      </app-word-hint-list>
            
                      <app-word-less-list  
                                [isParagraphEdit]="isParagraphEdit"
                                [currentI]="i"  
                                [feature_from_paragraphEdit]="feature" 
                                (changeIndex)='onChangedIndex($event)' 
                                *ngSwitchCase="'word simple'"
                                [editFeature]="clickOnEditFeature.asObservable(i)">
                      </app-word-less-list>
            
                      <app-multi-features-list 
                                [isParagraphEdit]="isParagraphEdit"
                                [currentI]="i"  
                                [feature_from_paragraphEdit]="feature" 
                                (changeIndex)='onChangedIndex($event)'  
                                [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
                                *ngSwitchCase="'multiFeatures'" >
                      </app-multi-features-list>

                      <app-fix-layout-list
                      [isParagraphEdit]="isParagraphEdit"
                      [currentI]="i"  
                      [feature_from_paragraphEdit]="feature" 
                      (changeIndex)='onChangedIndex($event)' 
                      *ngSwitchCase="'fixLayout'"
                      [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
                      [editFeature]="clickOnEditFeature.asObservable(i)">
                      </app-fix-layout-list>
          
                      <app-fix-text-list
                      [isParagraphEdit]="isParagraphEdit"
                      [currentI]="i"  
                      [feature_from_paragraphEdit]="feature" 
                      (changeIndex)='onChangedIndex($event)' 
                      *ngSwitchCase="'fixText'"
                      [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
                      [editFeature]="clickOnEditFeature.asObservable(i)">
                      </app-fix-text-list>

                      <app-calendar-list
                      [isParagraphEdit]="isParagraphEdit"
                      [currentI]="i"  
                      [feature_from_paragraphEdit]="feature" 
                      (changeIndex)='onChangedIndex($event)' 
                      *ngSwitchCase="'oneDateCalendar'"
                      [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
                      [editFeature]="clickOnEditFeature.asObservable(i)">
                      </app-calendar-list> 

                      <app-multi-entry-calendar-list
                      [isParagraphEdit]="isParagraphEdit"
                      [currentI]="i"  
                      [feature_from_paragraphEdit]="feature" 
                      (changeIndex)='onChangedIndex($event)' 
                      *ngSwitchCase="'multiEntryCalendar'"
                      [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
                      [editFeature]="clickOnEditFeature.asObservable(i)">
                      </app-multi-entry-calendar-list>

                      <app-shared-calendar-status-list
                      [isParagraphEdit]="isParagraphEdit"
                      [currentI]="i"  
                      [feature_from_paragraphEdit]="feature" 
                      (changeIndex)='onChangedIndex($event)' 
                      *ngSwitchCase="'sharedCalendarStatus'"
                      [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
                      [editFeature]="clickOnEditFeature.asObservable(i)">
                      </app-shared-calendar-status-list>
                     
                      <app-calculator-mix-table-list
                      [isParagraphEdit]="isParagraphEdit"
                      [currentI]="i"  
                      [feature_from_paragraphEdit]="feature" 
                      (changeIndex)='onChangedIndex($event)' 
                      *ngSwitchCase="'calculatorMixTable'"
                      [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
                      [editFeature]="clickOnEditFeature.asObservable(i)">
                      </app-calculator-mix-table-list>

                      <app-drop-down-select-list
                      [isParagraphEdit]="isParagraphEdit"
                      [currentI]="i"  
                      [feature_from_paragraphEdit]="feature" 
                      (changeIndex)='onChangedIndex($event)' 
                      *ngSwitchCase="'dropDownSelect'"
                      [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
                      [editFeature]="clickOnEditFeature.asObservable(i)">
                      </app-drop-down-select-list>
                     
            
            
                      </div>
                    </div>
                  </div>
            
                    </div>
                </div>  

              </div>
              <!-- END DESKTOP  -->

              <!-- IS MOBILE -->
              <div *ngIf="isHandset$ | async">
             
                <div [hidden]="isEditMultiFeature"
                      class="col-xs-12"> 
            
                <!-- hide normal features completely when editing multiFeature -->
                <div [hidden]="isEditMultiFeature">
                  
            
                <h2 [hidden]="!dropped" style="color: #3f51b5" >select components </h2>
            
               
                  <div  *ngFor="let feature of features ; let i=index ">
                  
                    <div class="ParagraphComponentFrame">
            
                   <div [hidden]="!isMobile" *ngIf="isHandset$ | async" class="row" >
            
            
                    <div class="col-xs-10"></div>
            
                    <div class="col-xs-1" (click)="onRemoveFeature(i)" >
                     <svg style="color: red" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                     <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                     </svg>
                    </div>
            
                    <div class="col-xs-1"></div>
               
                   </div>
            
                   
                   <div *ngIf="(isHandset$ | async) === false" class="row">
            
            
                     <div class="col-xs-10"></div>
            
                     <div class="col-xs-1 pointer" (click)="onRemoveFeature(i)">
                      <svg style="color: red" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                      </svg>
                     </div>
            
                     <div class="col-xs-1"></div>
            
                    </div>
            
                      <div [ngSwitch]="feature.featureName " >
                     
                      <app-word-input-list 
                              [isParagraphEdit]="isParagraphEdit"
                              [currentI]="i"  
                              [feature_from_paragraphEdit]="feature" 
                              (changeIndex)='onChangedIndex($event)' 
                              *ngSwitchCase="'wordInput'"
                              [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
                              [editFeature]="clickOnEditFeature.asObservable()">
                      </app-word-input-list>  
            
                      <app-word-hint-list  
                               [isParagraphEdit]="isParagraphEdit"
                               [currentI]="i"  
                               [feature_from_paragraphEdit]="feature" 
                               (changeIndex)='onChangedIndex($event)' 
                               *ngSwitchCase="'wordInput+sentence'"
                               [editFeature]="clickOnEditFeature.asObservable(i)" >
                      </app-word-hint-list>
            
                      <app-word-less-list  
                                [isParagraphEdit]="isParagraphEdit"
                                [currentI]="i"  
                                [feature_from_paragraphEdit]="feature" 
                                (changeIndex)='onChangedIndex($event)' 
                                *ngSwitchCase="'word simple'"
                                [editFeature]="clickOnEditFeature.asObservable(i)">
                      </app-word-less-list>
            
                      <app-multi-features-list 
                                [isParagraphEdit]="isParagraphEdit"
                                [currentI]="i"  
                                [feature_from_paragraphEdit]="feature" 
                                (changeIndex)='onChangedIndex($event)'  
                                [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
                                *ngSwitchCase="'multiFeatures'" >
                      </app-multi-features-list>

                      <app-fix-layout-list
                      [isParagraphEdit]="isParagraphEdit"
                      [currentI]="i"  
                      [feature_from_paragraphEdit]="feature" 
                      (changeIndex)='onChangedIndex($event)' 
                      *ngSwitchCase="'fixLayout'"
                      [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
                      [editFeature]="clickOnEditFeature.asObservable(i)">
                      </app-fix-layout-list>
          
                      <app-fix-text-list
                      [isParagraphEdit]="isParagraphEdit"
                      [currentI]="i"  
                      [feature_from_paragraphEdit]="feature" 
                      (changeIndex)='onChangedIndex($event)' 
                      *ngSwitchCase="'fixText'"
                      [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
                      [editFeature]="clickOnEditFeature.asObservable(i)">
                      </app-fix-text-list>

                      <app-calendar-list
                      [isParagraphEdit]="isParagraphEdit"
                      [currentI]="i"  
                      [feature_from_paragraphEdit]="feature" 
                      (changeIndex)='onChangedIndex($event)' 
                      *ngSwitchCase="'oneDateCalendar'"
                      [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
                      [editFeature]="clickOnEditFeature.asObservable(i)">
                      </app-calendar-list> 

                      <app-multi-entry-calendar-list
                      [isParagraphEdit]="isParagraphEdit"
                      [currentI]="i"  
                      [feature_from_paragraphEdit]="feature" 
                      (changeIndex)='onChangedIndex($event)' 
                      *ngSwitchCase="'multiEntryCalendar'"
                      [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
                      [editFeature]="clickOnEditFeature.asObservable(i)">
                      </app-multi-entry-calendar-list>
                     
          
                      <app-calculator-mix-table-list
                      [isParagraphEdit]="isParagraphEdit"
                      [currentI]="i"  
                      [feature_from_paragraphEdit]="feature" 
                      (changeIndex)='onChangedIndex($event)' 
                      *ngSwitchCase="'calculatorMixTable'"
                      [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
                      [editFeature]="clickOnEditFeature.asObservable(i)">
                      </app-calculator-mix-table-list>

                      <app-drop-down-select-list
                      [isParagraphEdit]="isParagraphEdit"
                      [currentI]="i"  
                      [feature_from_paragraphEdit]="feature" 
                      (changeIndex)='onChangedIndex($event)' 
                      *ngSwitchCase="'dropDownSelect'"
                      [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
                      [editFeature]="clickOnEditFeature.asObservable(i)">
                      </app-drop-down-select-list>
                     
            
            
                      </div>
                    </div>
                  </div>
            
                    </div>
                </div>  

              </div>
              <!-- END MOBILE -->
   
   
      </mat-tab>  
      
      <div >
          <mat-tab disabled > 
               <ng-template  mat-tab-label>
                    <a (click)="onCreate()" 
                        style=" cursor: pointer; color: red"> 
                        + add inner tab 
                    </a>
               </ng-template>
           </mat-tab>
      </div>

      

</mat-tab-group>

</div>

  
  
  
  
        <br>

        <div #FeaturesEnd></div>
            <button class="btn btn-primary" type="button" (click)="onSubmit()"> update tab </button>       
 
      </form>
    
 
