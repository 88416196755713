<!-- <p> @@ chat-form-result-item works!</p> -->


       <!-- this is to show paragraphs on the sent side ------------------------------------------ -->
        <div *ngIf="!isReceived"  class="row">
            <div class="col-xs-1">
            </div>

<!-- paragraph with greetings --------------------------------------------- -->
           <div class="col-xs-11">
              <div *ngIf="noGreetings" class="ParagraphComponentFrame">
          
             <div style="background-color: rgb(243, 250, 253);" class="row">
              <!-- <div style="background-color:rgb(219, 247, 225);" class="row"> this is green --> 
               <div class="col">
                   <button type="button" class="btn btn-link btn-sm"> <h6>{{formResult.sender?.content}}</h6></button>
               </div>
               <div class="col">
                  <button *ngIf="!useSubstitute" type="button" class="btn btn-link btn-sm"> <h6 style="color: red" >{{formResult.subject}}</h6></button>
                  <button *ngIf="useSubstitute" type="button" class="btn btn-link btn-sm"> <h6 style="color: red" >{{subjectSubstituteToNull}}</h6></button>
              </div>
              <div class="col">
                  <button type="button" class="btn btn-link btn-sm"><h6>greetings</h6></button>
              </div>
              </div>

              <br>

                <p>{{formResult.recipientResult.greeting}}</p>
                <h6>{{formResult.dateAndTime}}</h6>
           
              </div>
           </div>
        </div>

        <div  *ngIf="!isReceived" class="row">
            <div class="col-xs-1">
            </div>

            <div class="col-xs-11">
                <app-chat-paragraph-result-list 
                [sentToMessage]="true"
                [isReceived]="isReceived"
                [sender]="formResult.sender.content"
                [subject]="formResult?.subject"
                [paragraphResults]="formResult?.paragraphResults"
                [dateAndTime]="formResult?.dateAndTime"
               >
               <!--  [subscribeUpdatedFormResults]="subscribeNewFormResults.asObservable()" -->
               </app-chat-paragraph-result-list>
            </div>
        </div>

        <!-- this is END  to show paragraphs on the sent side ------------------------------------------ -->

        <!-- this is to show paragraphs on the received side ------------------------------------- -->
        <div *ngIf="isReceived" class="row">
           <div class="col-xs-11">
                  <div *ngIf="noGreetings" class="ParagraphComponentFrame">
          
                     <div style="background-color:rgb(243, 250, 253);" class="row">
                        <div class="col">
                            <button type="button" class="btn btn-link btn-sm"> <h6>{{formResult.sender?.content}}</h6></button>
                        </div>
                        <div class="col">
                           <button *ngIf="!useSubstitute" type="button" class="btn btn-link btn-sm"> <h6 style="color: red" >{{formResult.subject}}</h6></button>
                           <button *ngIf="useSubstitute" type="button" class="btn btn-link btn-sm"> <h6 style="color: red" >{{subjectSubstituteToNull}}</h6></button>
                       </div>
                       <div class="col">
                           <button type="button" class="btn btn-link btn-sm"><h6>greetings</h6></button>
                       </div>
                       </div>

                       <br>

                       <p>{{formResult.recipientResult.greeting}}</p>
                       <h6>{{formResult.dateAndTime}}</h6>
           
                   </div>
           </div>
           <div class="col-xs-1"> 
           </div>
        </div>


        <div *ngIf="isReceived" class="row">
            <div class="col-xs-11">
                <app-chat-paragraph-result-list 
                [sentToMessage]="true"
                [isReceived]="isReceived"
                [sender]="formResult.sender.content"
                [subject]="formResult?.subject"
                [paragraphResults]="formResult?.paragraphResults"
                [dateAndTime]="formResult?.dateAndTime"
               >
               <!--  [subscribeUpdatedFormResults]="subscribeNewFormResults.asObservable()" -->
               </app-chat-paragraph-result-list>
            </div>
            <div class="col-xs-1">
            </div>
        </div>

         <!-- this is END to show paragraphs on the received side ------------------------------------- -->
        

       

    
