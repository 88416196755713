import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ParagraphFeatureContainer } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature-container.model';
import { ParagraphIndexTopicDialog } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-index-topic-dialog.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { ParagraphFeatureContainerService } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature-container.service';
import { Paragraph } from 'src/app/forms/form-list/form-item/paragraph/paragraph.model';
import { ParagraphService } from 'src/app/forms/form-list/form-item/paragraph/paragraph.service';
import { Observable, Subscription } from 'rxjs';
import { MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { ParagraphTopicIndexService } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-topic-index.service';
import { ParagraphTopicService } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.service';
import { DialogmanagerService } from 'src/app/services/other-components-services/dialogmanager.service';
import { FormService } from 'src/app/forms/form.service';
import { StoreParagraphItemsService } from 'src/app/services/get-and-store-components/store-paragraph-items.service';
import { ParagraphTopicIndex } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-topic-index.model';
import { ParagraphTopic } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.model';
import { ChatDialogService } from 'src/app/chats/chat-dialog.service';
import { Chat } from 'src/app/chats/chat.model';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { ChatFillFormFromBottomSheetComponent } from 'src/app/chats/chat-item/chat-edit/chat-fill-form/chat-fill-form-from-bottom-sheet/chat-fill-form-from-bottom-sheet.component';
import { ErrorsDialogManagerService } from 'src/app/services/other-components-services/errors-dialog-manager.service';
import { StoreParagraphItemPlusChildsConfirmationService } from 'src/app/services/get-and-store-components/store-paragraph-item-plus-childs-confirmation.service';
import { RecipientService } from 'src/app/forms/form-list/form-item/recipient/recipient.service';
import { ChatService } from 'src/app/chats/chat.service';
import { Recipient } from 'src/app/forms/form-list/form-item/recipient/recipient.model';

/** Custom options the configure the tooltip's default show/hide delays. */
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 2000,
  hideDelay: 100,
  touchendHideDelay: 1000,
};

@Component({
  selector: 'app-chat-paragraph-feature-container',
  templateUrl: './chat-paragraph-feature-container.component.html',
  styleUrls: ['./chat-paragraph-feature-container.component.css'],
  providers: [{provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}]
})
export class ChatParagraphFeatureContainerComponent implements OnInit , OnDestroy {

  @Input() paragraphFeatureContainer_id: string
  @Input() isPersonal: boolean  
  @Input() isPublic: boolean 
  isLoadingShareIcon: boolean = false 
  isClicked: boolean 

  recipient: Recipient

  // @Input() aChatHasBeenSelected: boolean 
  //  selectedChatSubscription: Subscription ;
  //  @Input() subscribeUpdatedChatSelected: Observable<Recipient>
  //  @Input() chat: Chat

  paragraphFeatureContainer: ParagraphFeatureContainer
  paragraph: Paragraph

  paragraphShareStatusUpdateSubsciption: Subscription

  user: string

  paragraphName: string 

  updateOnUserEmail : Subscription

  isLoading: boolean = false 
  paragraphExist: boolean = true 
  isbeenAddedToPreferred: boolean = false 
  count: number ;
  isBeenShared: boolean = false 
  isBeenShared2Public: boolean 
  isBeenShared2Contacts: boolean

  updateOnceParagraphChildsHaveBeenStored: Subscription 
  paragraphWithChildsHasBeenStored: boolean = false 

  paragraphItemPlusChildsHasBeenStoredAndCanBeDisplayedSubscription: Subscription 

  formResultsSubjectListSubscription: Subscription 

  currentSubjectListDisplaysed: string[]

  constructor( 
               private paragraphService: ParagraphService, 
               private postComponentsHttpRequestService: PostComponentsHttpRequestService,
               private paragraphFeatureContainerService: ParagraphFeatureContainerService,
               private paragraphIndexTopicDialog: ParagraphIndexTopicDialog , 
               private paragraphTopicIndexService: ParagraphTopicIndexService,
               private paragraphTopicService: ParagraphTopicService , 
               private userObjectUpdateService: UserObjectUpdateService ,
               private dms: DialogmanagerService , 
               private chatService: ChatService, 
               private formService: FormService ,
               private recipientService: RecipientService , 
               private chatDialogService: ChatDialogService , 
               private storeParagraphItemsService: StoreParagraphItemsService , 
               private _bottomSheet: MatBottomSheet ,
               private errorsDialogManagerService: ErrorsDialogManagerService,
               private storeParagraphItemPlusChildsConfirmationService: StoreParagraphItemPlusChildsConfirmationService
              ) { }

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail


    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
  })

  this.isMobile()
  console.log('THIS IS MOBILE = ', this.isMobile())

  this.count = 0

  this.isClicked = false 

  this.paragraphFeatureContainer = this.paragraphFeatureContainerService.getParagraphFeatureContainerBy_id(this.paragraphFeatureContainer_id)
  console.log(this.paragraphFeatureContainer)

  this.isLoading = true 
  this.paragraphExist = false 

  this.checkIfFormAlreadyExistOnPreferred()


  /**
   * as soon as the paragraph has been shared 
   */
  this.paragraphShareStatusUpdateSubsciption = this.paragraphService.$updateParagraphShareStatus
      .subscribe(()=> this.onUpdateParagraphShareStatus() )

  this.updateOnceParagraphChildsHaveBeenStored = this.storeParagraphItemsService.singleParagraphChildsToAddToFormEdit
      .subscribe((paragraphResult: Paragraph )=> { 
        this.stopLoadingParagraphFeatureContainer()
  })

  // this.selectedChatSubscription = this.subscribeUpdatedChatSelected
  //     .subscribe((currentRecipientOfReference: Recipient )=>{
  //       console.log( currentRecipientOfReference )
  //       this.recipient = currentRecipientOfReference
  // })

  this.paragraphItemPlusChildsHasBeenStoredAndCanBeDisplayedSubscription = this.storeParagraphItemPlusChildsConfirmationService.paragraphItemToAddToFormEdit
      .subscribe((paragraphToAdd_id : string ) => {
        if ( paragraphToAdd_id === this.paragraphFeatureContainer.paragraph_id ){
          this.continueToSendFormEditTheParagraphId(this.paragraphService.getParagraphBy_id(paragraphToAdd_id))
        }
  })

  this.formResultsSubjectListSubscription = this.chatService.formResultsSubjectList
      .subscribe((formResultsSubjectListResult)=> {
          this.currentSubjectListDisplaysed = formResultsSubjectListResult
          console.log(this.currentSubjectListDisplaysed)
      })


  }


  isMobile(){
    // credit to Timothy Huang for this regex test: 
    // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        return true
   }
   else{
        return false
   }
  } 

  checkIfFormAlreadyExistOnPreferred(){
    const paragraphTopicIndexOfReference: ParagraphTopicIndex = this.paragraphTopicIndexService.getParagraphTopicIndex_by_type('preferred-paragraphs')
    const paragraphTopicsOfReference: ParagraphTopic[] = []

          for ( let i=0; i<paragraphTopicIndexOfReference.paragraphTopic_ids.length ; i++ ){
                paragraphTopicsOfReference.push(this.paragraphTopicService.getParagraphTopicBy_id(paragraphTopicIndexOfReference.paragraphTopic_ids[i]))
          }

          this.checkIfFormExistOnPreferredParagraphFeatureContainers(paragraphTopicsOfReference)
  }

  checkIfFormExistOnPreferredParagraphFeatureContainers(paragraphTopicsOfReference: ParagraphTopic[]){
    const paragrapgFeatureContainersOfReference: ParagraphFeatureContainer[] = []
         
          for ( let i=0; i<paragraphTopicsOfReference.length ; i++  ){
                for ( let y=0; y<paragraphTopicsOfReference[i].paragraphFeatureContainer_ids.length ; y++  ){
                       paragrapgFeatureContainersOfReference.push(this.paragraphFeatureContainerService.getParagraphFeatureContainerBy_id(paragraphTopicsOfReference[i].paragraphFeatureContainer_ids[y]))
                }
          }
          this.isFormBeenAddedToPreferred(paragrapgFeatureContainersOfReference)
  }

  isFormBeenAddedToPreferred(paragrapgFeatureContainersOfReference: ParagraphFeatureContainer[]){
    if ( paragrapgFeatureContainersOfReference.findIndex(x=> x.paragraph_id === this.paragraphFeatureContainer.paragraph_id) !== -1 ){ this.isbeenAddedToPreferred = true  }
    if ( paragrapgFeatureContainersOfReference.findIndex(x=> x.paragraph_id === this.paragraphFeatureContainer.paragraph_id) === -1 ){ this.isbeenAddedToPreferred = false }
    
    this.doesParagraphExist()
  }

  doesParagraphExist(){

 
      if ( this.paragraphService.getParagraphs().findIndex( x=> x._id === this.paragraphFeatureContainer.paragraph_id) !== -1 ){ 
           this.paragraph = this.paragraphService.getParagraphBy_id(this.paragraphFeatureContainer.paragraph_id)
           this.paragraphName = this.paragraphService.getParagraphBy_id(this.paragraphFeatureContainer.paragraph_id).name
           this.paragraphExist = true 
           if ( this.paragraph.allUsersAllowed === true ){ this.isBeenShared2Public = true }
           if ( this.paragraph.allUsersAllowed === false ){ this.isBeenShared2Public = false }
           if ( this.paragraph.userListAllowed.length > 0 ){ this.isBeenShared2Contacts = true }
           if ( this.paragraph.userListAllowed.length === 0 ){ this.isBeenShared2Contacts = false }
           if ( this.isBeenShared2Public === false && this.isBeenShared2Contacts === false ){ this.isBeenShared = false }
           if ( this.isBeenShared2Public === true || this.isBeenShared2Contacts === true ){ this.isBeenShared = true }
  
           this.isLoading = false 
      };

      if ( this.paragraphService.getParagraphs().findIndex( x=> x._id === this.paragraphFeatureContainer.paragraph_id) === -1 ){
           this.paragraphExist = false 
  
           this.isLoading = false 
      };
  }


  onShareParagraphIndex(){

    this.paragraphIndexTopicDialog.ShareParagraphIndex({
      paragraph_id: this.paragraphFeatureContainer.paragraph_id
    })
  }


/**
 * 
 * @param paragraph_id this acyulally need to add paragraph to chat 
 */
  onAddParagraphToChatItem(paragraph_id: string){

    console.log(this.paragraphFeatureContainer.name)
    /**
     * if chat has been selected continue 
     */
    // if ( this.recipient === undefined ){ 
    //      console.log( 'SELECT A CHAT FIRST') 
    //      this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'SELECT A CHAT FIRST'})
    //     }
    // if ( this.recipient !== undefined ){ this.onAddParagraphToChatItemContinue(paragraph_id) }

    if ( this.chatService.selectedChat.aChatHasBeenSelected){ this.onAddParagraphToChatItemContinue(paragraph_id) }
    if ( !this.chatService.selectedChat.aChatHasBeenSelected) {console.log( 'SELECT A CHAT FIRST') 
          this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'SELECT A CHAT FIRST'})
    }
  }

  onAddParagraphToChatItemContinue(paragraph_id: string){ 

    this.isClicked = true 
    /**
     * we should let formEdit start loading .... 
     */
   // this.paragraphFeatureContainerService.sendFormEditEventToStartLoadingNewParagraph()

    console.log(paragraph_id)
    // first download paragraph + childs and once stored , add to formEdit a subject 

    if ( this.user !== null ){
      if ( this.paragraphFeatureContainer.name === 'personal'){
        if ( this.paragraphWithChildsHasBeenStored === true ){ // call and check with service instead 
          /**
           * change to check if we are in desktop and send a dialog 
           * or if we are in mobile and we open a bottom sheet 
           */
          this.continueToSendFormEditTheParagraphId(this.paragraphService.getParagraphBy_id(paragraph_id))
        }
        if ( this.paragraphWithChildsHasBeenStored === false ){
          this.storeParagraphItemsService.getAndStorePersonalParagraphAndChildsComponents(this.user , paragraph_id)
          this.paragraphWithChildsHasBeenStored = true
        }
        
      }
  
      if ( this.paragraphFeatureContainer.name !== 'personal'){
        if ( this.paragraphWithChildsHasBeenStored === true ){
          this.continueToSendFormEditTheParagraphId(this.paragraphService.getParagraphBy_id(paragraph_id))
        }
        if ( this.paragraphWithChildsHasBeenStored === false ){
          this.storeParagraphItemsService.getAndStoreSharedParagraphAndChildsComponents(this.user , this.paragraphFeatureContainer.name , paragraph_id )
          this.paragraphWithChildsHasBeenStored = true
        }
       
      }
    }

    if ( this.user === null ){

      if ( this.paragraphFeatureContainer.name === 'public'){
        if ( this.paragraphWithChildsHasBeenStored === true ){ // this has to be stored at the service 
          this.continueToSendFormEditTheParagraphId(this.paragraphService.getParagraphBy_id(paragraph_id))
        }
        if ( this.paragraphWithChildsHasBeenStored === false ){
          this.storeParagraphItemsService.getAndStoreSharedParagraphAndChildsComponents(this.user , this.paragraphFeatureContainer.name , paragraph_id )
          this.paragraphWithChildsHasBeenStored = true
        }
        
      }

      if ( this.paragraphFeatureContainer.name !== 'public' ){
       // 
       this.continueToSendFormEditTheParagraphId( this.paragraphService.getParagraphBy_id(paragraph_id))
      }


    }

  };



  continueToSendFormEditTheParagraphId(paragraph: Paragraph ){
  // the subscription to formdit will fire directly from 
  // this.formService.sendNullUserParagraphToAddToFormEdit(paragraph)
  /**
   * call chat dialog and send info to chatFill Form 
   * 
   * check if it's mobile use bottomFill Form
   * if it's desktop use chat fill form 
   */

  if ( this.isMobile() === false ){
    this.chatDialogService.ChatFillForm({
      subjectList:  this.currentSubjectListDisplaysed , // need to add the subscription for each time a result is loaded intothe list for paragraphResults 
      paragraphTopicName: 'paragraphTopicName' , 
      // dialogChat: this.chat  ,
      // recipient: this.recipientService.getRecipientByChat_id(this.chat._id) ,
      recipient: this.recipient ,
      paragraph:  paragraph 
    })
  }

  if ( this.isMobile() === true ){

    this.openBottomSheet(paragraph )
  }

    setTimeout(()=>{
      this.isClicked = false 
    },300)

  };

  stopLoadingParagraphFeatureContainer(){
   
    setTimeout(()=>{
      this.isClicked = false 
   
    },300)

  }

  openBottomSheet(paragraph: Paragraph ){
    this._bottomSheet.open(ChatFillFormFromBottomSheetComponent , {
     data: {
      subjectList:  ['brave'] , 
      paragraphTopicName: 'paragraphTopicName' , 
      // dialogChat: this.chat  ,
      //recipient: this.recipientService.getRecipientByChat_id(this.chat._id) ,
      recipient: this.chatService.selectedChat.recipientSelected,
      paragraph:  paragraph 
     }
    });
  }


  onUpdateParagraphShareStatus(){
    this.isLoadingShareIcon = true 

    this.paragraph = this.paragraphService.getParagraphBy_id(this.paragraphFeatureContainer.paragraph_id)
    console.log(this.paragraph)
    if ( this.paragraph.allUsersAllowed === true ){ this.isBeenShared2Public = true }
    if ( this.paragraph.allUsersAllowed === false ){ this.isBeenShared2Public = false }
    if ( this.paragraph.userListAllowed.length > 0 ){ this.isBeenShared2Contacts = true }
    if ( this.paragraph.userListAllowed.length === 0 ){ this.isBeenShared2Contacts = false }
    if ( this.isBeenShared2Public === false && this.isBeenShared2Contacts === false ){ this.isBeenShared = false }
     if ( this.isBeenShared2Public === true || this.isBeenShared2Contacts === true ){ this.isBeenShared = true }

    setTimeout(()=>{
      this.isLoadingShareIcon = false
    },300)

  }

  onAcceptFeatureContainer(){
    this.isLoading = true 

const new_id = this.paragraphFeatureContainer._id
const newComponentName = this.paragraphFeatureContainer.componentName
const newUIID = this.paragraphFeatureContainer.UIID
const newOwner = this.paragraphFeatureContainer.owner
const newAllUsersAllowed = this.paragraphFeatureContainer.allUsersAllowed
const newUserListAllowed = this.paragraphFeatureContainer.userListAllowed
const newDateAndTime = this.paragraphFeatureContainer.dateAndTime
const newIsActive = this.paragraphFeatureContainer.isActive
const newIsAccepted = true 
const newName = this.paragraphFeatureContainer.name
const newState = this.paragraphFeatureContainer.paragraphFeatureState
const newParagraph_id = this.paragraphFeatureContainer.paragraph_id

const newParagraphFeatureContainer = new ParagraphFeatureContainer ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed,
      newDateAndTime, newIsActive, newIsAccepted, newName, newState, newParagraph_id )

      this.postComponentsHttpRequestService.httpUpdateComponent(this.user, 'paragraphFeatureContainer', newParagraphFeatureContainer )
      .subscribe((res)=> 
      { 
        console.log(res) 
        this.paragraphFeatureContainerService.paragraphFeatureContainers[this.paragraphFeatureContainerService.getParagraphFeatureContainers().findIndex(x=> x._id === newParagraphFeatureContainer._id)] = newParagraphFeatureContainer
        this.paragraphFeatureContainer = newParagraphFeatureContainer

        setTimeout(()=>{
          this.isLoading = false 
        },200)
      
      })

  };
/**
 * these are made to add public paragraph to preferred 
 */
  onAddParagraphToPreferred(paragraph_id: string ){
  this.isbeenAddedToPreferred = true 
  /**
   * first get the paragraph ( so that we know the owner )
   */
  const newParagraph: Paragraph = this.paragraphService.getParagraphBy_id(paragraph_id) 

  if ( this.user !== null ){

    this.postComponentsHttpRequestService.httpStorePreferenceComponent( this.user , newParagraph )
        .subscribe((res)=> { 
          console.log(res)

          this.definePreferredParagraphTopicsOfReference( newParagraph )
          console.log("it's adding to preferred ", newParagraph )

        })
  }

  if ( this.user === null ){

    // get and store paragraph+ childs 
    this.storeParagraphItemsService.getAndStoreSharedParagraphAndChildsComponents(this.user , this.paragraphFeatureContainer.name , paragraph_id )
    this.paragraphWithChildsHasBeenStored = true

    this.definePreferredParagraphTopicsOfReference(newParagraph)
    console.log("it's adding to preferred ", newParagraph)
  }

};

  definePreferredParagraphTopicsOfReference( newParagraph: Paragraph ){

     /**
         * we need to check if on paragraphTopicIndex preferred type is there this paragraphFeature owner already
         * create if there is not, add paragraphTopic 
         *  if there is already add only paragrapgFeatureContainer with this paragraphFeature_id
         */
     const preferredParagraphTopicIndex: ParagraphTopicIndex =  this.paragraphTopicIndexService.getParagraphTopicIndex_by_type('preferred-paragraphs')
     // if this paragraphIndexTopic has no paragraphTopic_ids yet , we can go directly to create paragraphTopic
     if ( preferredParagraphTopicIndex.paragraphTopic_ids.length > 0 ){ 
        console.log('preferredParagraphTopicIndex.paragraphTopic_ids.length > 0')
          // now chech if paragraphTopic with name of paragraphFeature owner exist ( on the list of paragraphTopics into preferred index only )
          const preferredParagraphTopics: ParagraphTopic[] = []
          let counter: number = 0
           for ( let i=0; i<preferredParagraphTopicIndex.paragraphTopic_ids.length ; i++ ){
                preferredParagraphTopics.push(this.paragraphTopicService.getParagraphTopicBy_id(preferredParagraphTopicIndex.paragraphTopic_ids[i]) )
  
                if ( counter === preferredParagraphTopicIndex.paragraphTopic_ids.length-1 ){ this.checkIfPreferredParagraphTopicAlreadyExist(newParagraph , preferredParagraphTopics) }
                if ( counter < preferredParagraphTopicIndex.paragraphTopic_ids.length-1 ){ counter = counter+1 }
            }
      };

      if ( preferredParagraphTopicIndex.paragraphTopic_ids.length === 0 ){
       this.createPreferredParagraphTopicAndAddToParagraphTopicIndex( newParagraph )
      }; 

  }


  checkIfPreferredParagraphTopicAlreadyExist(paragraph: Paragraph , preferredParagraphTopics: ParagraphTopic[] ){

    console.log('checkIfPreferredParagraphTopicAlreadyExist')

    if ( preferredParagraphTopics.findIndex(x=> x.name === paragraph.owner ) !== -1 ) {
      console.log('createPreferredParagraphFeatureContainerAndAddToPreferredParagraphTopic( false ,  paragraphFeature, preferredParagraphTopic) ')
      const preferredParagraphTopic: ParagraphTopic = preferredParagraphTopics[preferredParagraphTopics.findIndex(x=> x.name === paragraph.owner)]
      /**
       * check if by mistake this paragraphFeature already exist into the existing paragraphTopic
       */
      let paragraphAlreadyExist: boolean = false 
      for ( let i=0; i<preferredParagraphTopic.paragraphFeatureContainer_ids.length ; i++){
        if ( this.paragraphFeatureContainerService.getParagraphFeatureContainerBy_id(preferredParagraphTopic.paragraphFeatureContainer_ids[i]).paragraph_id === paragraph._id ){ paragraphAlreadyExist = true }
      }
      if ( paragraphAlreadyExist === false ){
        this.createPreferredParagraphFeatureContainerAndAddToPreferredParagraphTopic( false ,  paragraph, preferredParagraphTopic) 
      }
    }
    
    if ( preferredParagraphTopics.findIndex(x=> x.name === paragraph.owner) === -1 ) { 
      console.log('createPreferredParagraphTopicAndAddToParagraphTopicIndex')
         this.createPreferredParagraphTopicAndAddToParagraphTopicIndex(paragraph) 
    }

  }

  createPreferredParagraphTopicAndAddToParagraphTopicIndex(paragraph: Paragraph  ){

    // add also if user = null for the trials 

            const new_id =  (this.paragraphTopicService.getParagraphTopics().length+1).toString()
            const newComponentName = 'paragraphTopic'
            const newUIID = 'paragraphTopic-'+(this.paragraphTopicService.getParagraphTopics().length+1).toString()
            const newOwner = this.user 
            const newAllUsersAllowed = false 
            const newUserListAllowed = []
            const newDateAndTime = null 
            const newIsAccepted = true 
            const newIsArchived = false 
            const newName = paragraph.owner
            const newParagraphFeatureContainer_ids = []

            const newParagraphTopic : ParagraphTopic = new ParagraphTopic ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed , 
            newUserListAllowed, newDateAndTime, newIsAccepted, newIsArchived, newName, newParagraphFeatureContainer_ids)

            console.log('createPreferredParagraphFeatureContainerAndAddToPreferredParagraphTopic( true ,  paragraphFeature, newParagraphTopic)')
            this.createPreferredParagraphFeatureContainerAndAddToPreferredParagraphTopic( true ,  paragraph, newParagraphTopic)


  }

  createPreferredParagraphFeatureContainerAndAddToPreferredParagraphTopic(stillToCreateTopic: boolean , paragraph: Paragraph , newParagraphTopic : ParagraphTopic ){

    const new_id = (this.paragraphFeatureContainerService.getParagraphFeatureContainers().length+1).toString()
    const newComponentName = 'paragraphFeatureContainer'
    const newUIID = 'paragraphFeatureContainer'+(this.paragraphFeatureContainerService.getParagraphFeatureContainers().length+1).toString()
    const newOwner = this.user 
    const newAllUsersAllowed = false
    const newUserListAllowed = []
    const newDateAndTime = null 
    const newIsActive = true 
    const newIsAccepted = true 
    const newName = 'preference'
    const newState = false 
    const newParagraph_id = paragraph._id
    
    const newParagraphFeatureContainer = new ParagraphFeatureContainer ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed,
          newDateAndTime, newIsActive, newIsAccepted, newName, newState, newParagraph_id )

          if ( this.user === null ){

            this.paragraphFeatureContainerService.paragraphFeatureContainers.push(newParagraphFeatureContainer)
           
            if ( stillToCreateTopic === true ){ // we POST NEW paragraphTopic
              console.log('we POST NEW paragraphTopic')

              newParagraphTopic.paragraphFeatureContainer_ids.push(newParagraphFeatureContainer._id)
              console.log(newParagraphTopic)

                this.paragraphTopicService.addParagraphTopic(newParagraphTopic)
                
                 const preferredParagraphTopicIndex: ParagraphTopicIndex =  this.paragraphTopicIndexService.getParagraphTopicIndex_by_type('preferred-paragraphs')
                 preferredParagraphTopicIndex.paragraphTopic_ids.push(newParagraphTopic._id)
                /**
                 * update paragraphTopicIndex 
                 */
                this.updateParagraphTopicIndex(preferredParagraphTopicIndex)
            
            };

            if ( stillToCreateTopic === false){ // we just UPDATE the paragraphTopic

             console.log('we just UPDATE the paragraphTopic')

              newParagraphTopic.paragraphFeatureContainer_ids.push(newParagraphFeatureContainer._id)
              console.log(newParagraphTopic)

                this.paragraphTopicService.paragraphTopics[this.paragraphTopicService.getParagraphTopics().findIndex(x=>x._id === newParagraphTopic._id)] === newParagraphTopic
                this.count = 0
                this.dms.QuickCommunicationPopUpComponent({
                  quickComment: 'added to preferred-paragraphs list'

              })

             }


          }

          if ( this.user !== null ){
          this.postComponentsHttpRequestService.httpStoreComponent(this.user, 'paragraphFeatureContainer', newParagraphFeatureContainer )
          .subscribe(
          (paragraphFeatureContainer: ParagraphFeatureContainer)=> { 

            this.paragraphFeatureContainerService.paragraphFeatureContainers.push(paragraphFeatureContainer)

            if ( stillToCreateTopic === true ){ // we POST NEW paragraphTopic

              newParagraphTopic.paragraphFeatureContainer_ids.push(paragraphFeatureContainer._id)
              console.log(newParagraphTopic)

              this.postComponentsHttpRequestService.httpStoreComponent( newOwner, 'paragraphTopic', newParagraphTopic )
              .subscribe((paragraphTopic: ParagraphTopic)=> { 

                this.paragraphTopicService.addParagraphTopic(paragraphTopic)
                
                 
                 const preferredParagraphTopicIndex: ParagraphTopicIndex =  this.paragraphTopicIndexService.getParagraphTopicIndex_by_type('preferred-paragraphs')
                 preferredParagraphTopicIndex.paragraphTopic_ids.push(paragraphTopic._id)
                /**
                 * update paragraphTopicIndex 
                 */
                this.updateParagraphTopicIndex(preferredParagraphTopicIndex)
               
              })
            };

            if ( stillToCreateTopic === false){ // we just UPDATE the paragraphTopic

              newParagraphTopic.paragraphFeatureContainer_ids.push(paragraphFeatureContainer._id)
              console.log(newParagraphTopic)

              this.postComponentsHttpRequestService.httpUpdateComponent(this.user , 'paragraphTopic', newParagraphTopic )
              .subscribe((res)=> { 
                console.log(res)

                this.paragraphTopicService.paragraphTopics[this.paragraphTopicService.getParagraphTopics().findIndex(x=>x._id === newParagraphTopic._id)] === newParagraphTopic
                this.count = 0
                this.dms.QuickCommunicationPopUpComponent({
                  quickComment: 'added to preferred-paragraphs list'
                })

              })

             }
          })
    }

  }


  updateParagraphTopicIndex(updatedPreferredParagraphTopicIndex: ParagraphTopicIndex){

    if ( this.user === null ){
      this.paragraphTopicIndexService.paragraphTopicIndexes[ this.paragraphTopicIndexService.getParagraphTopicIndexes().findIndex(x=> x._id === updatedPreferredParagraphTopicIndex._id )] = updatedPreferredParagraphTopicIndex
      
      this.count = 0
      this.dms.QuickCommunicationPopUpComponent({
        quickComment: 'added to preferred-paragraphs list'
      }) // END dialog communication 
    } // END IF statement

    if ( this.user !== null ){

      this.postComponentsHttpRequestService.httpUpdateComponent( this.user, 'paragraphTopicIndex', updatedPreferredParagraphTopicIndex)
      .subscribe((res)=> { 
       console.log(res) 

       this.paragraphTopicIndexService.paragraphTopicIndexes[ this.paragraphTopicIndexService.getParagraphTopicIndexes().findIndex(x=> x._id === updatedPreferredParagraphTopicIndex._id )] = updatedPreferredParagraphTopicIndex
       
       this.count = 0
       this.dms.QuickCommunicationPopUpComponent({
         quickComment: 'added to preferred-paragraphs list'
       }) // END dialog communication 
     }) // END Subscription 
    } // END IF statement

  }


  ngOnDestroy(){
    this.paragraphShareStatusUpdateSubsciption.unsubscribe()
    this.updateOnUserEmail.unsubscribe()
    this.updateOnceParagraphChildsHaveBeenStored.unsubscribe()
    // this.selectedChatSubscription.unsubscribe()
    this.paragraphItemPlusChildsHasBeenStoredAndCanBeDisplayedSubscription.unsubscribe()
    this.formResultsSubjectListSubscription.unsubscribe()
  };










}

