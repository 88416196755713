import { Component, Input, OnInit} from '@angular/core';





@Component({
  selector: 'app-recipient',
  templateUrl: './recipient.component.html',
  styleUrls: ['./recipient.component.css'],

})
export class RecipientComponent implements OnInit {

@Input() recipientType : string ;
@Input() recipientId : number ;




  constructor( ) {
  } 


  ngOnInit(): void {

    this.recipientId = this.recipientId ;
    this.recipientType = this.recipientType;

    
    // this.recipientType = this.form.recipient.recipientName ;
    // this.recipientId = this.form.recipient.recipientId;
    
  }

 
 
}
