import { Injectable } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { FormFeatureContainer } from "src/app/forms/form-list/form-index-topic-list/form-feature-container.model";
import { MultiFeatures } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/multi-features-list/multi-features.model";
import { Tab } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/tabs/tab-list/tab.model";

@Injectable({
    providedIn: 'root'
})


export class AdviseIfHttpStoreComponentsCallHasBeenMadeService {

    constructor(){}


/**
 * mayeb just use the type as per base components has been stored = false / to make it faster
 */

baseMultiFeatureTabsComponentsHasBeenStored = false 

baseMultiFeatureTabsComponentsAreNowInStore = new Subject<void>()

/**
 * these will be used when the features will be too many and we will update one by one 
 */
singleWordsHasBeenStored = false 
singleWordInputsHasBeenStored = false 
toggleSingleWordButtonsHasBeenStored = false 
singleDropDownSelectsHasBeenStored = false 
singleSlidersHasBeenStored = false 

recipientsHasBeenStored = false 

wordsInputHasBeenStored = false 
fixTextsHasBeenStored = false 
fixLayoutsHasBeenStored = false 
singleDateCalendarsHasBeenStored = false 
multiEntryCalendarsHasBeenStored = false 
calculatorMixTablesHasBeenStored = false 
dropDownSelectsHasBeenStored = false 
sharedCalendarStatusItemsHaveBeenStored = false 

multiFeaturesHasBeenStored = false 
tabsHasBeenStored = false 

// paragraphsHasBeenStored = false  // probably this we don't need as we are going to call paragraphFeature + childs 
personalParagraphFeatureHasBeenStored = false 
sharedParagraphFeatureHasBeenStored = false 
preferredParagraphFeatureHasBeenStored = false 

personalFormsHasBeenStored = false // this is different as someone could http directly the form only 
sharedFormsHasBeenStored = false
preferredFormsHasBeenStored = false

formItemPlusChildsHasBeenStoredList: any[] = []

// initialUpdateOnFormItemPlusChildHasBeenStoredSubscription : Subscription = this.storePersonalFeatureIndexComponentsService.formFeatureContainersChanged
//       .subscribe((formFeatureContainersResult: FormFeatureContainer[])=>{
//         this.creatTabOfFormItemIdPlusRespectiveStoredIndex(formFeatureContainersResult)
// })





creatTabOfFormItemIdPlusRespectiveStoredIndex(formFeatureContainersResult: FormFeatureContainer[]){

    for ( let i=0; i<formFeatureContainersResult.length ; i++){

        const form_id: string = formFeatureContainersResult[i].form_id
        const hasBeenStored: boolean = false 
        const formItemPlusChildsHasBeenStored: any = { form_id, hasBeenStored }
        if ( this.formItemPlusChildsHasBeenStoredList.findIndex(x=> x.form_id === form_id ) === -1 ){
             this.formItemPlusChildsHasBeenStoredList.push( formItemPlusChildsHasBeenStored)
             console.log( this.formItemPlusChildsHasBeenStoredList)
        }
    }
}


// call from storeTabComponents service when all base has been stored
turnBaseMultiFeatureTabsComponentsHasBeenStoredToTrue(){
    this.baseMultiFeatureTabsComponentsHasBeenStored = true 
    /**
     * add stop loading to featureTopicIndexList
     */
    this.baseMultiFeatureTabsComponentsAreNowInStore.next()
}


/**
 * refer if a type of paragraphFeature has been already stored 
 */
turnPersonalParagraphFeatureComponentsHasBeenStoredToTrue(){
    this.personalParagraphFeatureHasBeenStored = true // this don't need to turn to false 
}

turnSharedParagraphFeatureComponentsHasBeenStoredToTrue(){
    this.sharedParagraphFeatureHasBeenStored = true  // this will need to turn to false so we keep on calling updated ones 
}

turnPreferredParagraphFeatureComponentsHasBeenStoredToTrue(){
    this.preferredParagraphFeatureHasBeenStored = true 
}

/**
 * refer if a type of formFeature has been already stored __________________________
 */

turnPersonalFormsComponentsHasBeenStoredToTrue(){
    this.personalFormsHasBeenStored = true 
}

turnSharedFormsComponentsHasBeenStoredToTrue(){
    this.sharedFormsHasBeenStored = true // need to turn to false after a while 5 sec ? 
    
    setTimeout(() => {
        this.sharedFormsHasBeenStored = false
    }, 5000);
}

turnPreferredFormsComponentsHasBeenStoredToTrue(){
    this.preferredFormsHasBeenStored = true 
}
/**
 * END refer if a type of formFeature has been already stored __________________________
 */


/**
 * check which multiFeature component + childs have been stored _________________________
 */

multiFeaturePlusChildsHasBeenStoredList: any[] = []

add_MultiFeatureComponent_id_plusChilds_hasBeenStoredConfirmation( multiFeatures: MultiFeatures[] ){

    for ( let i=0; i<multiFeatures.length ; i++){

        const multiFeature_id: string = multiFeatures[i]._id
        const hasBeenStored: boolean = false 
        const multiFeaturePlusChildsHasBeenStored: any = { multiFeature_id, hasBeenStored }
        if ( this.multiFeaturePlusChildsHasBeenStoredList.findIndex(x=> x.multiFeature_id === multiFeature_id ) === -1 ){
             this.multiFeaturePlusChildsHasBeenStoredList.push( multiFeaturePlusChildsHasBeenStored)
             console.log( this.multiFeaturePlusChildsHasBeenStoredList )
        }
    }
}

add_MultiFeatureComponent_id_NotStoredYet(newCreatedMultiFeature_id: string ){

    const multiFeature_id: string = newCreatedMultiFeature_id
    const hasBeenStored: boolean = false 
    const multiFeaturePlusChildsHasBeenStored: any = { multiFeature_id, hasBeenStored }

    this.multiFeaturePlusChildsHasBeenStoredList.push(multiFeaturePlusChildsHasBeenStored)

}

/**
 *  END check which multiFeature component + childs have been stored _________________________
 */
tabListPlusChildsHasBeenStoredList: any[] = []

add_tabListComponents_ids_plusChilds_hasBeenStoredConfirmation( tabList: Tab[] ){

    for ( let i=0; i<tabList.length ; i++){

        const tab_id: string = tabList[i]._id
        const hasBeenStored: boolean = false 
        const tabPlusChildsHasBeenStored: any = { tab_id, hasBeenStored }
        if ( this.tabListPlusChildsHasBeenStoredList.findIndex(x=> x.tab_id === tab_id ) === -1 ){
             this.tabListPlusChildsHasBeenStoredList.push( tabPlusChildsHasBeenStored)
             console.log( this.tabListPlusChildsHasBeenStoredList )
        }
    }
}

add_TabComponent_id_NotStoredYet( newCreatedTab_id: string ){

    const tab_id: string = newCreatedTab_id
    const hasBeenStored: boolean = false 
    const tabPlusChildsHasBeenStored: any = { tab_id, hasBeenStored }

    this.tabListPlusChildsHasBeenStoredList.push(tabPlusChildsHasBeenStored)

}

onLoggingOutSetAllPersonalComponentsHaveBeenStoredToFalseAgain(){

this.baseMultiFeatureTabsComponentsHasBeenStored = false 

this.singleWordsHasBeenStored = false 
this.singleWordInputsHasBeenStored = false 
this.toggleSingleWordButtonsHasBeenStored = false 
this.singleDropDownSelectsHasBeenStored = false 
this.singleSlidersHasBeenStored = false 

this.recipientsHasBeenStored = false 

this.wordsInputHasBeenStored = false 
this.fixTextsHasBeenStored = false 
this.fixLayoutsHasBeenStored = false 
this.singleDateCalendarsHasBeenStored = false 
this.multiEntryCalendarsHasBeenStored = false 
this.calculatorMixTablesHasBeenStored = false 
this.dropDownSelectsHasBeenStored = false 
this.sharedCalendarStatusItemsHaveBeenStored = false

this.multiFeaturesHasBeenStored = false 
this.tabsHasBeenStored = false 

this.personalParagraphFeatureHasBeenStored = false 
this.sharedParagraphFeatureHasBeenStored = false 
this.preferredParagraphFeatureHasBeenStored = false 

this.personalFormsHasBeenStored = false
this.sharedFormsHasBeenStored = false
this.preferredFormsHasBeenStored = false

this.formItemPlusChildsHasBeenStoredList = []
this.tabListPlusChildsHasBeenStoredList = []
this.multiFeaturePlusChildsHasBeenStoredList = []

}




}