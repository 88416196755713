import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Feature } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/feature.model';
import { FeatureResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/feature-result.model';
import { MultiFeatures } from '../multi-features.model';
import { MultiFeaturesService } from '../multi-features.service';
import { SingleFeatureDetailResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/single-feature-results/single-feature-detail-result.model';
import { Observable, Subscription } from 'rxjs';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { SingleWordDetailResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/single-feature-results/single-word-result/single-word-detail-result.model';
import { AdviseIfHttpStoreComponentsCallHasBeenMadeService } from 'src/app/services/other-components-services/advise-if-http-store-components-has-been-made.service';
import { StoreMultibaseComponentsService } from 'src/app/services/get-and-store-components/store-multibase-components.service';
import { StoreMultibaseItemPlusChildsConfirmationService } from 'src/app/services/get-and-store-components/store-multiBase-item-plus-childs-confirmation.service';



@Component({
  selector: 'app-multi-features',
  templateUrl: './multi-features.component.html',
  styleUrls: ['./multi-features.component.css']
})
export class MultiFeaturesComponent implements OnInit , OnDestroy {

  user: string 

  weAreInMultiFeatureEdit: boolean = false


  multiFeature : MultiFeatures;
  multifeatures: MultiFeatures[];

  @Input() index_id: string;

  @Input() noNeeded : boolean  ; 
  @Input() isParagraphEdit: boolean ; 
  @Input() feature: Feature

  @Input() isTabItem: boolean
  @Input() tab_index: number 

  @Input() paragraphEdit_selectedIndex_id: string 

  /**
   * 
   * here we start to define the FeatureResult
   */
   featureResult: FeatureResult;
   featureResults: FeatureResult[];
   featureDetailResult: SingleFeatureDetailResult;
   featureDetailResults: SingleFeatureDetailResult[]

   subscribeClickEvent: Subject<void> = new Subject<void>();
 

    mailTextFeatureBodyPart: string;

  resultItemsToOrder: any[]
  textResultsToOrder: string[]
  initialDummyMailTextFeatureBodyPart: string 

  @Output() updateBothFeatureResultAndTextPlusIndex = new EventEmitter<any>()
  @Output() emitASubComponentIsHavingIssues = new EventEmitter<boolean>()

  isLoading: boolean = false 

  updateOnUserEmail: Subscription

  multiFeatureComponentPlusItemHaveBeenStoreConfirmation: Subscription



  constructor( private multiFeaturesService: MultiFeaturesService ,
               private userObjectUpdateService: UserObjectUpdateService , 
               private storeMultibaseComponentsService: StoreMultibaseComponentsService ,
               private adviseIfHttpStoreComponentsCallHasBeenMadeService: AdviseIfHttpStoreComponentsCallHasBeenMadeService ,
               private storeMultiBaseItemPlusChildsConfirmationService: StoreMultibaseItemPlusChildsConfirmationService
               ) { }



  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
    .subscribe((updatedUserEmail)=>{
     this.user = updatedUserEmail
     console.log('updated USER = ', this.user)
    })

    this.isLoading = true 

    this.multiFeature = this.multiFeaturesService.getMultiFeatureBy_id(this.index_id)
    console.log( this.multiFeature)
    /**
     * check if the component + childs have been stored 
     * check if id match with index_id or keep loading 
     */
    if ( this.user === null ){
      
      if ( this.isParagraphEdit &&  this.multiFeature._id === this.paragraphEdit_selectedIndex_id ){ this.continueOnInIt() }
      if ( this.isParagraphEdit &&  this.multiFeature._id !== this.paragraphEdit_selectedIndex_id ){ this.isLoading = true }
      if ( !this.isParagraphEdit &&  this.multiFeature._id === this.paragraphEdit_selectedIndex_id ){this.continueOnInIt() }
    }
    /**
     * check if 
     */
    if ( this.user !== null ){ 
        if ( this.isParagraphEdit &&  this.multiFeature._id === this.paragraphEdit_selectedIndex_id ){ 
             if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.multiFeaturePlusChildsHasBeenStoredList.find(x=> x.multiFeature_id === this.index_id).hasBeenStored === true ){
                  if ( this.multiFeature.owner === this.user ){ 
                    // and then stop loading 
                    this.continueOnInIt()
                    console.log( 'is editing personal multiFeature, keep loading for now before we get the childs components ')
                  }
                  if ( this.multiFeature.owner !== this.user ){
                    // throw an error should not be able to edit a different user component 
                    // redirect user to edit page or return to where he was 
                    console.log('fire an error message ')
                    
                  }

             }
             if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.multiFeaturePlusChildsHasBeenStoredList.find(x=> x.multiFeature_id === this.index_id).hasBeenStored !== true ){
                  // call multifeature + childs 
                  this.storeMultibaseComponentsService.getPersonalMultiFeature_withChilds_by_id(this.user , this.multiFeature._id)
                  console.log( 'is editing personal multiFeature, keep loading for now before we get the childs components ')
             }
        }

        if ( this.isParagraphEdit &&  this.multiFeature._id !== this.paragraphEdit_selectedIndex_id ){ this.isLoading = true }
        if ( !this.isParagraphEdit &&  this.multiFeature._id === this.paragraphEdit_selectedIndex_id ){ this.continueOnInIt() }

    }

    this.multiFeatureComponentPlusItemHaveBeenStoreConfirmation = this.storeMultiBaseItemPlusChildsConfirmationService.multiFeature_id_plusChildsHasBeenStored
        .subscribe((multiFeature_id_isStored: string )=>{

        if ( this.multiFeature._id === this.paragraphEdit_selectedIndex_id ){ console.log(multiFeature_id_isStored) , this.continueOnInIt() }
    })

  }



  continueOnInIt(){

    console.log('feature = ', this.feature ,this.multiFeature)

    /**
     * initialize dummies final results so they have positions in their respective array
     */
    this.initialDummyMailTextFeatureBodyPart = '';
    this.resultItemsToOrder = []
    this.featureDetailResults = []
    this.textResultsToOrder = []
  
    for (let i=0; i<this.multiFeature.features.length; i++){
      this.resultItemsToOrder.push(i)
      const newText = ''
      this.textResultsToOrder.push(newText)
      /**
       * adding initial dumb singleFeatureresult just to make the slots of initial featureDetailResults[] not empty
       */
      const newSingleFeatureDetailResultType = 'singleWordResult';
      const newSingleFeatureDetailResultName = '';
      const newSingleWordDetailResultContent = '';
  
      const newSingleWordDetailResult = new SingleWordDetailResult (newSingleWordDetailResultContent);
      const newSingleFeatureDetailResult = new SingleFeatureDetailResult (newSingleFeatureDetailResultType, newSingleFeatureDetailResultName, newSingleWordDetailResult )
    
      console.log(newSingleFeatureDetailResult);
  
      this.featureDetailResults.push(newSingleFeatureDetailResult)

    }
    
    console.log('initial dummy featureDetailResults = ',this.featureDetailResults)
    console.log(this.resultItemsToOrder)

    this.featureDetailResults = [];

    this.isLoading = false 

    if ( this.multiFeature.features.length === 0 ){ console.log( 'is emitting a subComponentIshavingIssue '), this.emitASubComponentIsHavingIssues.emit(true)}
    if ( this.multiFeature.features.length > 0 ){ console.log( 'is emitting a subComponentIshavingIssue '), this.emitASubComponentIsHavingIssues.emit(false)}

  }

 

  onSend(){

    if (this.isParagraphEdit !== true){

      if ( this.isTabItem === true){ this.tab_index = this.tab_index }
      if ( this.isTabItem !== true){ this.tab_index = null }

      const newTab_index = this.tab_index

    const newFeatureDrop_Id = this.feature.dropId
    const newFeatureResult = this.featureResult
    const newMailTextFeatureBodyPart = this.mailTextFeatureBodyPart
         
    this.updateBothFeatureResultAndTextPlusIndex.emit({newTab_index , newFeatureDrop_Id, newFeatureResult, newMailTextFeatureBodyPart})
    }
  }


  /**
   * 
   * @param $event event from all single Components to update singleFeature results at any move 
   */
  onUpdateSingleFeatureResults($event){
    console.log($event)

    /**
     * replacing the exact textDetail to its position and re-construct the full text body part
     */
    this.textResultsToOrder[$event.newFeatureDrop_Id] = $event.newMailTextSingleFeatureBodyPart
    for( let i=0; i<this.textResultsToOrder.length; i++){
      if ( i === 0){this.mailTextFeatureBodyPart = encodeURIComponent(this.textResultsToOrder[i]) + '%20%20'}
      if ( i !== 0){this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart + encodeURIComponent(this.textResultsToOrder[i]) + '%20%20'}
    }
    console.log('text body part = ', this.mailTextFeatureBodyPart)

    /**
     * replacing the exact featureDetailResult to its position 
     */
    const newFeatureResultType = 'singleFeatureResult';
    const newFeatureResultName = this.multiFeature.name;
    this.featureDetailResults[$event.newFeatureDrop_Id] = $event.newSingleFeatureDetailResult

    const newFeatureResult = new FeatureResult ( newFeatureResultType, newFeatureResultName, this.featureDetailResults);
                console.log('featureResult = ', newFeatureResult);

    this.featureResult = newFeatureResult

    this.onSend()

                
  };

  ngOnDestroy(){

    this.updateOnUserEmail.unsubscribe()
     
}
 
}


