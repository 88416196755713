import { Injectable } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { InitialNullSetupService } from "src/app/services/starting-services/initial-null-setup.service";
import { RecipientIndexListItem } from "./recipient-index-list-item.model";

@Injectable({
    providedIn: 'root'
})

export class RecipientIndexListItemService {

    constructor( 
                 private initialNullSetupService: InitialNullSetupService, 
                  ){}

    recipientIndexListItems: RecipientIndexListItem[] = this.initialNullSetupService.initialNullRecipientIndexListItems


    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.recipientIndexListItemsChangedBackToNull
    .subscribe((res)=> {
           this.recipientIndexListItems = res 
           console.log('updated recipientIndexListItems back to null = ', res)
 
    })


    getRecipientIndexListItems(){
        return this.recipientIndexListItems
    }

    getRecipientIndexListItem(i: number){
        const newRecipientIndexListItem: RecipientIndexListItem = this.recipientIndexListItems[i]
        return newRecipientIndexListItem
    }


    getRecipientIndexListItemBy_id(_id:string){
        const newRecipientIndexListItem: RecipientIndexListItem = this.recipientIndexListItems.find(
            (res)=>{
                return res._id === _id
            }
        )
        return newRecipientIndexListItem
    }

    getRecipientIndexListItemBy_UIID(_UIID:string){
        const newRecipientIndexListItem: RecipientIndexListItem = this.recipientIndexListItems.find(
            (res)=>{
                return res.UIID === _UIID
            }
        )
        return newRecipientIndexListItem
    }

    addRecipientIndexListItem(newRecipientIndexListItem: RecipientIndexListItem){
        this.recipientIndexListItems.push(newRecipientIndexListItem) 
        
       this.updateInitialComponents(this.recipientIndexListItems)
    }

    /**
     * update before starting 
     * @param updatedRecipientIndexListItem 
     */
    updateInitialRecipientIndexListItem(updatedRecipientIndexListItem: RecipientIndexListItem){

        const index: number = this.recipientIndexListItems.findIndex(x=> x._id === updatedRecipientIndexListItem._id)
        this.recipientIndexListItems[index] = updatedRecipientIndexListItem 

        this.updateInitialComponents(this.recipientIndexListItems)

    }

    updateWithLatestRecipientIndexListItems = new Subject<void>()
    updateRecipientIndexListItems(updatedRecipientIndexListItems: RecipientIndexListItem[]){
        this.recipientIndexListItems = updatedRecipientIndexListItems
        this.updateInitialComponents(this.recipientIndexListItems)

    }

    updateInitialComponents(updatedRecipientIndexListItems:RecipientIndexListItem[]){

        this.initialNullSetupService.initialNullRecipientIndexListItems = updatedRecipientIndexListItems

    }



    /**
     * this will go to the component that is showing the list of the recipients and update it
     * and all it's child compoennts till recipients . 
     */
    updateWorkingSharedRecipientIndexListItem = new Subject<void>()
    /**
     * update ON WORKING UP , so there is a subscription needed to update current in use object 
     * @param updatedRecipientIndexListItem this 
     */
    updateWorkingRecipientIndexListItem(updatedRecipientIndexListItem: RecipientIndexListItem){

        // this is only the SHARED recipientIndexListItem
        console.log(updatedRecipientIndexListItem)

        const index: number = this.recipientIndexListItems.findIndex(x=> x._id === updatedRecipientIndexListItem._id)
        this.recipientIndexListItems[index] = updatedRecipientIndexListItem

        /**
         * send a subject to recipientList item and Recipients currenlty showing in th DOM 
         * 
         * sending to 
         *  1: EDIT FORM , and check if current DOM host the same recipientIndexListItem , if so update 
         *  2: FORM ITEM , same as above 
         */

        console.log('updated WORKING recipientIndexListItem . now update recipients list ')
        this.onJustUpdateRecipientsInGenerals()

    }

    onJustUpdateRecipientsInGenerals(){
        console.log('updated WORKING recipientIndexListItem . now update recipients list ')
        this.updateWorkingSharedRecipientIndexListItem.next()
    }



    
}