import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray} from '@angular/forms';
import { MatDialogRef , MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HeaderService } from 'src/app/header/header.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';

@Component({
  selector: 'app-word-less-edit',
  templateUrl: './word-less-edit.component.html',
  styleUrls: ['./word-less-edit.component.css']
})
export class WordLessEditComponent implements OnInit {

  user: string 
  wordLessInputEditForm: FormGroup;
  DetailsArray : FormArray;
  storageData =  this.editData.DetailsArray ;
  
  
   
  

  
   constructor( private _fb: FormBuilder, 
    private headerService : HeaderService ,
    public dialogRef: MatDialogRef<WordLessEditComponent>,
    private userObjectUpdateService: UserObjectUpdateService ,
    @Inject(MAT_DIALOG_DATA) public editData: any) { this.createWordLessEditForm()}

    createWordLessEditForm(){
      this.wordLessInputEditForm = this._fb.group({
        name: [this.editData.name , Validators.compose([
          Validators.required, Validators.maxLength(50)
        ])],
       Details: this.DetailsArray
      })
    }



  ngOnInit(){
      console.log("editData", this.editData )
     
      this.user = this.userObjectUpdateService.userEmail
      
       this.wordLessInputEditForm = new FormGroup({
         name : new FormControl (this.editData.name, [Validators.required, Validators.maxLength(30) ] ),
         details : new FormArray (this.storageData.map(item =>{
         const group = this.initdetail();
         group.patchValue(item);
         return group ;
         }))
         });
        this.wordLessInputEditForm.valueChanges.subscribe(
        (value) => console.log(value)
        );
  }



  
     initdetail(){
       return new FormGroup ({
           label: new FormControl ('', Validators.maxLength(25)),
           placeholder: new FormControl ('' , Validators.maxLength(25)),
         
         
     })   
    } 
  


    getDetails (wordLessInputEditForm){
      return  wordLessInputEditForm.controls.details.controls;
    }

    onAddDetails(){
      (<FormArray>this.wordLessInputEditForm.get('details')).push(this.initdetail());
    }

    onDeleteDetail(index:number ){
       (<FormArray>this.wordLessInputEditForm.get('details')).removeAt(index );
    }




  onSubmit(){
    console.log(this.wordLessInputEditForm);
    if (this.wordLessInputEditForm.invalid) {
      return;
    }
    this.dialogRef.close(this.wordLessInputEditForm.value),
    this.editData.value;
    this.headerService.nextComponentIndexSelection(false)
  }
  



}



