import { Component, OnInit , Inject } from '@angular/core';
import { FormArray , FormBuilder , FormGroup } from '@angular/forms';
import { MatDialogRef , MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogmanagerService } from '../../../../../../../../../services/other-components-services/dialogmanager.service' ;

@Component({
  selector: 'app-word-button',
  templateUrl: './word-button.component.html',
  styleUrls: ['./word-button.component.css']
})
export class WordButtonComponent implements OnInit {

  public addwordbutton: FormGroup;

  constructor(private dms: DialogmanagerService , private _fb: FormBuilder, public dialogRef: MatDialogRef<WordButtonComponent>,
    @Inject(MAT_DIALOG_DATA) public editData: any) { }

  ngOnInit() {
    console.log("editData", this.editData )
    this.addwordbutton = this._fb.group({
      // name: [''],
      WordButtonName: [''],
    });
  }

 

  



  wordButtonName = '';
  wordButtonCreated = false;


  onWordButtonName(event:any){
   this.wordButtonName = (<HTMLInputElement>event.target).value;
    console.log(event);
  };

  onWordButton(){
    this.wordButtonCreated= !this.wordButtonCreated; 
  };

  // openDialogWordButtonDialog(){
  //   // this.dms.WordButtonDialog({ text: "passing word button data" }).subscribe(res => {
  //     this.dms.WordButtonDialog({ wordButtonName: '' }).subscribe(res => {
  //     console.log(res)
  //   });
  // }

}
