import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-recipient-index',
  templateUrl: './recipient-index.component.html',
  styleUrls: ['./recipient-index.component.css']
})
export class RecipientIndexComponent implements OnInit {


  constructor( 
               ) { }

  ngOnInit(){
  };




}
