import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RangeInfoResult } from '../../shared-calendar-status-result/range-info-result.model';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dropdown-result',
  templateUrl: './dropdown-result.component.html',
  styleUrls: ['./dropdown-result.component.css']
})
export class DropdownResultComponent implements OnInit , OnDestroy {

  user: string 

  @Input() rangeInfoResult: RangeInfoResult

  updateOnUserEmail: Subscription

  constructor( private userObjectUpdateService: UserObjectUpdateService ) { }

  ngOnInit(): void {

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
    .subscribe((updatedUserEmail)=>{
     this.user = updatedUserEmail
     console.log('updated USER = ', this.user)
    })
  }

  ngOnDestroy(): void {
      this.updateOnUserEmail?.unsubscribe()
  }

}
