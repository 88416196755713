<div class="col-xs-12">

    <div class="InnerComponentFrame">

<div class="innerItemFrame2">
    <div class="col-xs-12">

        <div class="row">
            <h2 style="color: #3f51b5">name</h2>
        </div>

            <app-name-input-only
            [inputName]="rangeInfo.name"
            [inputLabelInterface]="inputInnerCompNameInterface"
            (updatedNameEvent)="onUpdateNameInputEvent($event)"
            (updateNameInputValidity)="onUpdateNameInputValidity($event)"
            [isEmptyingInputNameAfterAdded]="emptyInputNameAfterAdded.asObservable()"
            >
            </app-name-input-only>

    </div>
</div>

    </div>

    <!-- <div class="InnerComponentFrame" *ngIf="!isAboutToDeleteRangeInfo">
        <div class="innerItemFrame2">
            <div class="col-xs-12">


    <div class="row">
        <div class="col">
            <app-users-access-edit 
              [accessType]="accessType"
              [usersAccess]="rangeInfo.usersAccess"
              (updatedUsersAccessEvent)="onUpdateUsersAccess($event)">
            </app-users-access-edit>
        </div>
    </div>

            </div>
        </div>
    </div> -->

    <div class="InnerComponentFrame" *ngIf="!isAboutToDeleteRangeInfo">
        <div class="innerItemFrame2">
            <div class="col-xs-12">
          
                <br>
                    <div class="row">
                        <h2 style="color: #3f51b5">component overview</h2>
                    </div>

                    <app-text-input
                            [inputName]="initialText"
                            [initialPlaceholder]="initialPlaceholder"
                            [inputLabelInterface]="inputTextInnerCompNameInterface">
                    </app-text-input>

                <hr>

                <div class="row">
                    <h2 style="color: #3f51b5">edit structure</h2>
                </div>
                <br>

                <app-name-input-only
                          [inputName]="initialText"
                          [inputLabelInterface]="inputTextLabelInterface1"
                          (updatedNameEvent)="onUpdateTextInputEvent($event)"
                          (updateNameInputValidity)="onUpdateTextInputValidity($event)"
                          [isEmptyingInputNameAfterAdded]="emptyInputTextAfterAdded.asObservable()">
                </app-name-input-only>

                 <app-number-input 
                           [inputName]="initialMaxLength"
                           [inputLabelInterface]="inputTextLabelInterface2"
                           (updatedNameEvent)="onUpdateMaxlengthEvent($event)"
                           (updateNameInputValidity)="onUpdateMaxlengthValidity($event)"
                           [isEmptyingInputNameAfterAdded]="emptyInputTextAfterAdded.asObservable()">
                 </app-number-input>

                 <app-name-input-only
                          [inputName]="initialPlaceholder"
                          [inputLabelInterface]="inputTextLabelInterface3"
                          (updatedNameEvent)="onUpdatePlaceholderEvent($event)"
                          (updateNameInputValidity)="onUpdatePlaceholderValidity($event)"
                          [isEmptyingInputNameAfterAdded]="emptyInputTextAfterAdded.asObservable()">
                 </app-name-input-only>


            </div>
        </div>
    </div>

    <div class="InnerComponentFrame">
        <div class="innerItemFrame2">
            <div class="col-xs-12">
                <div class="row" *ngIf="isAboutToDeleteRangeInfo">
                    <p>are you sure you want to delete this info ? </p>
                </div>
                <br>
                <div class="row">
        
                    <div class="col-xs-11" *ngIf="isAboutToDeleteRangeInfo">
                        <div class="col-xs-6">
                            <button (click)="onDoubleConfirmDeleteInnerDetail()" 
                                     class="btn btn-danger"> 
                                     confirm deleting 
                            </button>
                        </div>
                        <div class="col-xs-3">
                            <button (click)="onReturnToEditRangeInfo()" 
                                     class="btn btn-primary"> 
                                     return to edit 
                            </button>
                        </div>
                    </div>

                    <div class="col-xs-11" *ngIf="!isAboutToDeleteRangeInfo">
                        <div class="col-xs-7">
                                <button (click)="onDeleteInnerDetail()" 
                                        class="btn btn-danger"> 
                                        delete
                                </button>
                        </div>
                        <div class="col-xs-2">
                                <button (click)="onConfirmInnerDetail()" 
                                        class="btn btn-primary"> 
                                        confirm 
                                </button>
                        </div>
                    </div>
                   
                </div>
                <br>
            </div>
        </div>
    </div>

    <!-- <div class="InnerComponentFrame">
        <div class="innerItemFrame2">
            <div class="col-xs-12">
                <br>
                <div class="row">
                    <div class="d-flex justify-content-end">
                        <button (click)="onConfirmInnerDetail()" 
                                class="btn btn-primary"> 
                                done
                        </button>
                    </div>
                </div>
                <br>
            </div>
        </div>
    </div> -->

    <br>


</div>
