<!-- <p>paragraph-index-topic works!</p> -->
      
      <div *ngIf="!isLoading" class="example-container">
        <!-- class="example-box" -->
      <div *ngIf="paragraphTopic.isAccepted && !paragraphTopic.isArchived " class="example-box"  >

   <div *ngIf="isPersonal">
   <div [hidden]="hideButton">

          <div [hidden]="hide1stButton" class="pointer"  (click)="onHide1stButton()">
            <svg style="color: #3f51b5;" xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16">
              <circle cx="8" cy="8" r="8"/>
            </svg>
           </div>
       
       <div [hidden]="!hide1stButton" class="pointer" >
          <svg (click)="onHideButton()" class="pointer" style="color: red;"  xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
          </svg>
       </div>

   </div>
   </div>

      
       <form  [formGroup]="paragraphIndexNameForm" (ngSubmit)="onSubmit()" >
         
        <div class="row">
          <div *ngIf="hideButton" class="inner-box2" >
            <mat-form-field class="full-width">
              <input 
              matInput 
              type="text" 
              id="paragraphIndexName"
              formControlName="name"
              placeholder="edit topic name"
              maxlength="35"
              >
            </mat-form-field>
           
          </div>
          <div *ngIf="(isHandset$ | async)" class="row">
             <div *ngIf="hideButton" class="d-flex justify-content-center" >
              <button [hidden]="!hideButton" 
                       type="button" 
                       (click)="onSubmit()"  
                       class="btn btn-primary ">+</button>
            </div>
          </div>
        </div>
       
       </form>
  


       
       
     
      <div [hidden]="hideButton" >
    
        <div class="inner-box" (click)="onHideParagraphs()" >

            <a class="navbar-brand" 
               matBadge="{{newParagraphIndexes}}" 
              [matBadgeHidden]="newParagraphIndexes == 0"
               matBadgeColor="accent"
               style="color: #3f51b5;"  > {{ paragraphTopic?.name}}</a>
            <span [hidden]="!hideParagraphs">
              <svg style="color: #3f51b5;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
                <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
              </svg>
            </span>
            <span [hidden]="hideParagraphs">
              <svg  xmlns="http://www.w3.org/2000/svg" style="color: #3f51b5;" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
              </svg>
            </span>
         
        </div>

      </div>
   
    
      </div> 

      <div *ngIf="!paragraphTopic.isAccepted" 
           class="example-box"
           matTooltip="CLICK TO ACCEPT / DECLINE SHARED PARAGRAPHS FROM THIS USER"
           matTooltipPosition="right"  >


           <button *ngIf="!(isHandset$ | async)"
            class="btn btn-primary"
            type="button"
            matBadge="1" matBadgeColor="accent"
            (click)="onAcceptRefuseParagraphTopic()"> 
            {{ paragraphTopic?.name}}
            </button>
          
            <button *ngIf="isHandset$ | async"
            class="btn btn-primary"
            type="button"
            matBadge="1" matBadgeColor="accent"
            (click)="onAcceptRefuseParagraphTopic()"> 
            click / {{ paragraphTopic?.name}}
            </button>
        
           
     
      <!-- <br> -->
      <!-- <p  *ngIf="isHandset$ | async"
       class="half-small d-flex justify-content-end align-items-end" 
       style="color:#3f51b5" > click button to accept / refuse shared paragraphs from this user </p> -->

     </div>


         
          <div *ngIf="!hideParagraphs">
  

            <div style="overflow: hidden;" 
                class="col-12"
                 *ngFor="let paragraphFeatureContainer_id of paragraphTopic?.paragraphFeatureContainer_ids ; let i = index " >
             

             
                  <app-paragraph-feature-container
                      [paragraphTopic]="paragraphTopic"
                      [isPersonal]="isPersonal"
                      [isPublic]="isPublic"
                      [paragraphFeatureContainer_id]="paragraphFeatureContainer_id">
                  </app-paragraph-feature-container>
            
          
          </div>
   

<div *ngIf="isPersonal">
<div *ngIf="createButtonLimit" class="example-box" >
       

  <mat-chip  (click)="onSetTopicIndex()" 
             type="button"
             style="background-color: #3f51b5 ; color: white ; width:120px "> 
             + paragraph
  </mat-chip>
 
</div >
</div>

         </div>
        
      </div>





