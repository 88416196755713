<div  *ngIf="(isHandset$ | async) === false" style="background-color:rgb(242, 248, 250) ; padding: 8px ;" >


    <div *ngIf="!aFormResultHasBeenSelected">
    <div class="row">
        <div class="col-xs-12">
  
            <div class="row">
                <div class="col-xs-12">
                    <br>
                    <br>
                    <br>
                </div>
            </div>
  
            <div class="row">
  
  
                    <div class="row" style="height:198px"></div>
  
                    <div class="row">
  
                      <!-- ------------------------------------ DESKTOP ROW -------------------------------- -->
  <!-- <div class="col-xs-12" *ngIf="!(isHandset$ | async)"> -->
      <!-- <div *ngIf="!(isHandset$ | async)" class="col-12">
  
       
  
        <div class="row">
          <div class="col-3"></div>
    
          <div class="col-8">
                    <app-desktop-rapresentation [message1]="message1" 
                                                [message2]="message2"
                                                [message3]="message3"
                                                [tab1]="tab1"
                                                [tab2]="tab2"
                                                [tab3]="tab3"
                                                [tab4]="tab4">
                  </app-desktop-rapresentation>
          </div>
        </div>
    
       
     </div> -->
    
    <!-- ------------------------------------END DESKTOP ROW -------------------------------- -->
                    
      <!-- </div> -->
  
            </div> 
  
  
        </div>
    </div>
  </div>
  </div>
  
  </div>
