import { Component, OnInit, Injectable, Inject, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { FixLayout } from "../fix-layout.model"
import { FixLayoutService} from "../fix-layout.service"
import { FormGroup, FormControl, FormBuilder, Validators, FormArray} from '@angular/forms';
import { MatDialogRef , MAT_DIALOG_DATA, _closeDialogVia } from '@angular/material/dialog';
import { DialogmanagerService } from '../../../../../../../../../../services/other-components-services/dialogmanager.service' ;
import { FixLayoutTableTD } from '../fix-layout-table-td.model';
import { FixLayoutTableTR } from '../fix-layout-table-tr.model'
import { FeatureResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/feature-result.model';
import { FixLayoutDetailResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/fix-layout-results/fix-layout-detail-result.model';
import { Feature } from '../../../feature.model';
import { Subscription, Observable } from 'rxjs';
import { HeaderService } from 'src/app/header/header.service';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { FormService } from 'src/app/forms/form.service';

@Component({
  selector: 'app-fix-layout-item',
  templateUrl: './fix-layout-item.component.html',
  styleUrls: ['./fix-layout-item.component.css']
})
export class FixLayoutItemComponent implements OnInit {

  user: string 

  fixLayoutEditForm: FormGroup
  fixLayout : FixLayout

  fixLayoutResult: FixLayoutDetailResult

  isEditing: boolean 
  isEditingDetails: boolean 

  newCreatedFixLayoutTableTR: FixLayoutTableTR

  newCreatedStringFixLayoutTableTD: FixLayoutTableTD
  newCreatedNumberFixLayoutTableTD: FixLayoutTableTD
  newCreatedDescriptionFixLayoutTableTD: FixLayoutTableTD
  newCreatedChartFixLayoutTableTD: FixLayoutTableTD

  // noNeeded is for stuff like edit buttons to be visibles or not
  @Input() noNeeded : boolean  ; 
  index : number  ; 
  @Input() index_id: string ;
  @Input() isParagraphEdit: boolean
  @Input() feature: Feature

  @Input() isTabItem : boolean 
  @Input() tab_index : number 

  /**
   * output to paragraphItem the FeatureResult values
   */

  featureResult: FeatureResult

  // sendResultAgainSubscription: Subscription 
  // @Input() subscribeToChildComponentsResultsAgain: Observable<void>



  fixLayoutTableTRResults: FixLayoutTableTR[]

   /**
   * receiving the click from wordInputList to edit the component
   */
    @Input() subscriptionToEditComponentItemIsOn: boolean
    currentFeature: Feature
    @Input() editFeatureItem: Observable<void>;
    editFeatureEventFromWordInputList: Subscription; 

    /**
     * output to paragraphItem the mailTextFeatureBodyPart
     */
    mailTextFeatureBodyPart: string ;

   @Output() emitToParagraphEditIsDoneAndTurnDroppedFalse = new EventEmitter<boolean>()
  
  @Output() updateBothFeatureResultAndTextPlusIndex = new EventEmitter<any>()

  requestToFinalizeResultAgain: Subscription 

  constructor( 
               private fixLayoutService: FixLayoutService,
               private dms: DialogmanagerService,
               private headerService: HeaderService,
               private formService: FormService,
               private userObjectUpdateService: UserObjectUpdateService ,
               private postComponentsHttpRequestService: PostComponentsHttpRequestService,
               @Inject(MAT_DIALOG_DATA) public editData: any) { }

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

      this.index = this.fixLayoutService.getFixLayouts().findIndex(x => x._id === this.index_id)

    this.fixLayout = this.fixLayoutService.getFixLayout(this.index)
    console.log('feature = ',this.feature, this.fixLayout)

    this.createFixlayoutResult()

    this.fixLayoutEditForm = new FormGroup ({
      name: new FormControl ( this.fixLayout.name, [Validators.maxLength(30)])
    })

    this.isEditing = false
    this.isEditingDetails = false


      this.requestToFinalizeResultAgain = this.formService.getRequestToFinalizeResultAgain().subscribe(()=> this.onSend())

      setTimeout(()=>{
        this.onSend()
      },200)
   


      if (this.isParagraphEdit === true){
         this.editFeatureEventFromWordInputList = this.editFeatureItem.subscribe((data)=> this.checkIfMatchId(data))
      }
  }

  createFixlayoutResult(){
    this.fixLayoutTableTRResults = this.fixLayout.fixLayoutTableTRs
    const newFixlayoutResult = new FixLayoutDetailResult ( this.fixLayoutTableTRResults )

    this.fixLayoutResult = newFixlayoutResult
    console.log(this.fixLayoutResult)
  }

  getWidth(i: number, j:number){
    return this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs[j].tableTDDetailWidth
  }

  checkIfMatchId(data){
    this.currentFeature = data ;
    console.log(this.currentFeature)

    if (this.currentFeature.feature_id === this.index_id){
      this.onEditFixLayout()
    }
  }

  onEditFixLayout(){
    this.isEditing = true 
  }

  onEditDetails(i){
    console.log(i)
    this.isEditingDetails = true
  }

  onDeleteDetails(i){
    console.log(i)
    if (this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs.length >= 1 ){
      this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs.splice(this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs.length-1, 1)
      console.log(this.fixLayout)
    } else if (this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs.length === 0){
      this.fixLayout.fixLayoutTableTRs.splice(i, 1)
    }

  }

  onAddString(i){
    console.log(i)
    this.onCreateNewString()
    this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs.push(this.newCreatedStringFixLayoutTableTD)
    this.isEditingDetails = false 
    this.onEditStringForm(i, this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs.length-1 )
    
  }

  onCreateNewString(){
    
    const newTableTDType = 'string' ;
    const newTableTDDetailString = '' ;
    const newTableTDDetailStringLabel = '' ;
    const newTableTDDetailNumber = null ;
    const newTableTDDetailNumberLabel = null ;
    const newTableTDDetailDescription = null ;
    const newTableTDDetailDescriptionLabel = null ;
    const newTableTDDetailChartShare = null ;
    const newTableTDDetailChartTotal = null ;
    const newTableTDDetailWidth = '200px' 

    const newTableTDString = new FixLayoutTableTD ( newTableTDType, newTableTDDetailString, newTableTDDetailStringLabel, newTableTDDetailNumber,
                                                    newTableTDDetailNumberLabel, newTableTDDetailDescription, newTableTDDetailDescriptionLabel,
                                                    newTableTDDetailChartShare, newTableTDDetailChartTotal, newTableTDDetailWidth)

    this.newCreatedStringFixLayoutTableTD = newTableTDString
    

  }

  onEditStringForm(i, j){
    console.log(i, j)
       this.dms.FixLayoutEditString({
        indexI: i,
        indexJ: j,
        fixLayout: this.fixLayout
    }).subscribe (res => {
      console.log(res)
      this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs[j].tableTDDetailStringLabel = res.label ,
      this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs[j].tableTDDetailString = res.name
      this.fixLayoutService.onUpdateFixLayout(this.fixLayout)
    })
  }



  onAddNumber(i){
    console.log(i)
    this.onCreateNewNumber()
    this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs.push(this.newCreatedNumberFixLayoutTableTD)
    this.isEditingDetails = false 
    this.onEditNumberForm(i, this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs.length-1 )

  }

  onCreateNewNumber(){

    const newTableTDType = 'number' ;
    const newTableTDDetailString = null ;
    const newTableTDDetailStringLabel = null ;
    const newTableTDDetailNumber = 0 ;
    const newTableTDDetailNumberLabel = '' ;
    const newTableTDDetailDescription = null ;
    const newTableTDDetailDescriptionLabel = null ;
    const newTableTDDetailChartShare = null ;
    const newTableTDDetailChartTotal = null ;
    const newTableTDDetailWidth = '100px' 

    const newTableTDString = new FixLayoutTableTD ( newTableTDType, newTableTDDetailString, newTableTDDetailStringLabel,  newTableTDDetailNumber,
                                                    newTableTDDetailNumberLabel, newTableTDDetailDescription, newTableTDDetailDescriptionLabel,
                                                    newTableTDDetailChartShare, newTableTDDetailChartTotal, newTableTDDetailWidth)

    this.newCreatedNumberFixLayoutTableTD = newTableTDString

  }

  onAddDescription(i){
    console.log(i)
    this.onCreateNewDescription()
    this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs.push(this.newCreatedDescriptionFixLayoutTableTD)
    this.isEditingDetails = false 
    this.onEditDescriptionForm(i, this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs.length-1 )
  }

  onCreateNewDescription(){
    
    const newTableTDType = 'description' ;
    const newTableTDDetailString = null ;
    const newTableTDDetailStringLabel = null ;
    const newTableTDDetailNumber = null ;
    const newTableTDDetailNumberLabel = null ;
    const newTableTDDetailDescription = '' ;
    const newTableTDDetailDescriptionLabel = '' ;
    const newTableTDDetailChartShare = null ;
    const newTableTDDetailChartTotal = null ;
    const newTableTDDetailWidth = '500px' 

    const newTableTDDescription = new FixLayoutTableTD ( newTableTDType, newTableTDDetailString, newTableTDDetailStringLabel, newTableTDDetailNumber,
                                                    newTableTDDetailNumberLabel, newTableTDDetailDescription, newTableTDDetailDescriptionLabel,
                                                    newTableTDDetailChartShare, newTableTDDetailChartTotal, newTableTDDetailWidth)

    this.newCreatedDescriptionFixLayoutTableTD = newTableTDDescription
    

  }

  onAddChart(i){
    console.log(i)
    this.onCreateNewChart()
    this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs.push(this.newCreatedChartFixLayoutTableTD)
    this.isEditingDetails = false 
    this.onEditChartForm(i, this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs.length-1 )

  }

  onCreateNewChart(){

    const newTableTDType = 'chart' ;
    const newTableTDDetailString = null ;
    const newTableTDDetailStringLabel = null ;
    const newTableTDDetailNumber = null ;
    const newTableTDDetailNumberLabel = null ;
    const newTableTDDetailDescription = null ;
    const newTableTDDetailDescriptionLabel = null ;
    const newTableTDDetailChartShare = 0 ;
    const newTableTDDetailChartTotal = 100 ;
    const newTableTDDetailWidth = null ; 

    const newTableTDString = new FixLayoutTableTD ( newTableTDType, newTableTDDetailString, newTableTDDetailStringLabel, newTableTDDetailNumber,
                                                    newTableTDDetailNumberLabel, newTableTDDetailDescription, newTableTDDetailDescriptionLabel,
                                                    newTableTDDetailChartShare, newTableTDDetailChartTotal, newTableTDDetailWidth)

    this.newCreatedChartFixLayoutTableTD = newTableTDString

  }

 

  onEditNumberForm(i:number, j:number){
    console.log(i,j)
    this.dms.FixLayoutEditNumber({
      indexI: i,
      indexJ: j,
      fixLayout: this.fixLayout
    }).subscribe ( res => {
      this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs[j].tableTDDetailNumberLabel = res.label ,
      this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs[j].tableTDDetailNumber = res.number

      this.fixLayoutService.onUpdateFixLayout(this.fixLayout)

    })

  }

  onEditDescriptionForm(i: number , j: number){
    console.log(i,j)
    this.dms.FixLayoutEditDescription({
        indexI: i,
        indexJ: j,
        fixLayout: this.fixLayout
    }).subscribe ( res => {
      this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs[j].tableTDDetailDescriptionLabel = res.label ,
      this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs[j].tableTDDetailDescription = res.description
      this.fixLayoutService.onUpdateFixLayout(this.fixLayout)
    })
  }

  onEditChartForm(i:number , j:number){
    console.log(i, j)
       this.dms.FixLayoutEditChart({
        indexI: i,
        indexJ: j,
        fixLayout: this.fixLayout
    }).subscribe ( res => {
      this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs[j].tableTDDetailChartShare = res.share ,
      this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs[j].tableTDDetailChartTotal = res.total
      this.fixLayoutService.onUpdateFixLayout(this.fixLayout)
    })
  }

  onAddTRDetails(){

    const newTableTR = new FixLayoutTableTR ([])

    this.fixLayout.fixLayoutTableTRs.push(newTableTR)
  }



  onSubmitFixLayout(){
    // this should be the PUT call for the backend 
    this.fixLayout.name = this.fixLayoutEditForm.value.name
    this.postComponentsHttpRequestService.httpUpdateComponent(this.fixLayout.owner, 'fixLayout', this.fixLayout ).subscribe((response)=> {
      console.log(response)

      this.fixLayoutService.fixLayouts[this.index] = this.fixLayout
      this.isEditingDetails = false 
      this.isEditing = false 
      this.emitToParagraphEditIsDoneAndTurnDroppedFalse.emit(false)
      this.headerService.nextComponentIndexSelection(false)
    })
    
  }

  onSend(){
    console.log(this.fixLayout)

    const newFeatureResultType = 'fixLayoutFeatureResult'
    const newFeatureResultName = this.fixLayout.name

    this.fixLayoutTableTRResults = this.fixLayout.fixLayoutTableTRs
    this.fixLayoutResult = new FixLayoutDetailResult (this.fixLayoutTableTRResults)

    const newFeatureResult = new FeatureResult (newFeatureResultType, newFeatureResultName, this.fixLayoutResult )

    this.featureResult = newFeatureResult

    /**
     * build mailTextFeatureBodyPart
     */

    this.mailTextFeatureBodyPart = ''

    for ( let i=0; i<this.fixLayout.fixLayoutTableTRs.length ; i++){

      for ( let j=0; j<this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs.length; j++){

        if ( this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs[j].tableTDType === 'string'){
             this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart + encodeURIComponent(this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs[j].tableTDDetailString) + '%20%20'
        };

        if ( this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs[j].tableTDType === 'description'){
             this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart + encodeURIComponent( this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs[j].tableTDDetailDescription) + '%20%20'
        };

        if ( this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs[j].tableTDType === 'number'){
             this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart + encodeURIComponent( this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs[j].tableTDDetailNumber) + '%20%20'
        };

        if ( this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs[j].tableTDType === 'chart'){
             this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart + encodeURIComponent( this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs[j].tableTDDetailChartShare) + '%2F'+ 
                                                                           encodeURIComponent( this.fixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs[j].tableTDDetailChartTotal) +'%20%20'
        };


      }

      this.mailTextFeatureBodyPart =  this.mailTextFeatureBodyPart + '%0D%0A'

}

    this.onUpdateFeatureResultEvent()
  }


  onUpdateFeatureResultEvent(){

    if ( this.isTabItem === true){ this.tab_index = this.tab_index }
    if ( this.isTabItem !== true){ this.tab_index = null }

        const newTab_index = this.tab_index

      const newFeatureDrop_Id = this.feature.dropId
      const newFeatureResult: FeatureResult = this.featureResult
      const newMailTextFeatureBodyPart = this.mailTextFeatureBodyPart

      this.updateBothFeatureResultAndTextPlusIndex.emit({ newTab_index , newFeatureDrop_Id, newFeatureResult, newMailTextFeatureBodyPart})
      
  }
  /**
   *  constructor (
        public featureResultType: string,
        public featureResultName: string,
        public featureDetailResults: SingleFeatureDetailResult[]|
                                     ToggleWordButtonDetailResult[]|
                                     WordInputDetailResult[] | 
                                     WordDetailResult[] |
                                     TabFeatureDetailResult[]
   */

  ngOnDestroy(){
    if (this.isParagraphEdit === true){ this.editFeatureEventFromWordInputList.unsubscribe()} 
    this.requestToFinalizeResultAgain.unsubscribe()
  }


  

}

/**
 * constructor (
 * public _id: number ,
public _name: string,
public owner: string,
public dateAndTime: string, 
public fixLayoutTables: FixLayoutTable[]
){}

export class FixLayoutTable {

    constructor ( public fixLayoutTableTRs: FixLayoutTableTR[] ){}


    export class FixLayoutTableTR {

    constructor( public fixLayoutTableTH: string[],
                 public fixLayoutTableTDs: FixLayoutTableTD[]){}

    export class FixLayoutTableTD{

    constructor( public tableTDType: string , public tableTDDetail: ChartDetail |
                                                                    string |
                                                                    number ){}

     <div  [ngSwitch]="feature.featureName " >
                            
                   <app-single-word-item   
                   *ngSwitchCase="'single word'">
                   </app-single-word-item>

    export class ChartDetail {

    constructor (public type: string ,public name: string , public value: number ){}
}
}
 */
