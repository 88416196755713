import { Injectable } from "@angular/core";
import { Subject } from "rxjs";


@Injectable ({
    providedIn: 'root' 
})

export class StoreParagraphItemPlusChildsConfirmationService { 


     paragraphItemToAddToFormEdit = new Subject<string>() 
     paragraphItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property: any[] = [] // ({component_id: string , haveBeenStored: boolean})


     scomposeParagraphItemPlusChildsComponent_idsList(paragraphItem_PlusChilds: any ){

        console.log(paragraphItem_PlusChilds)

        let component_id: string = paragraphItem_PlusChilds._id 
        let haveBeenStored: boolean = false 

        this.paragraphItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.push({component_id , haveBeenStored })

        const features_id_list: string[] = []
        const paragraphs_childs: any[] = [] 
      
        console.log()

        for ( let i=0; i<paragraphItem_PlusChilds.feature_id_list.length ; i++ ){

                if ( features_id_list.findIndex(x=> x === paragraphItem_PlusChilds.feature_id_list[i] ) === -1){
                     features_id_list.push(paragraphItem_PlusChilds.feature_id_list[i])
                     paragraphs_childs.push(paragraphItem_PlusChilds.paragraphChild_list[i])
                     
                     let component_id: string = paragraphItem_PlusChilds.feature_id_list[i]
                     let haveBeenStored: boolean = false 
                     if (  this.paragraphItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.findIndex(x=> x.component_id === component_id ) === -1 ){
                           this.paragraphItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.push({component_id , haveBeenStored})
                     }
                    
                }
           
        }

        console.log(features_id_list , paragraphs_childs ,   this.paragraphItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property )
        this.scomposeFeatureItemPlusChildsComponent_idsList( paragraphs_childs)

    }

    scomposeFeatureItemPlusChildsComponent_idsList( paragraphs_childs: any[]){

        /**
         * now need to check if any of the childs have singleBase_id_list / or features 
         */
        for ( let i=0; i<paragraphs_childs.length ; i++){

             if ( paragraphs_childs[i].hasOwnProperty('multiFeatureChild_list') === true ){ 
                  console.log(paragraphs_childs[i] , 'this is a multiFeature component') 
                  for ( let y=0; y<paragraphs_childs[i].singleBase_id_list.length ; y++ ){
                        let component_id: string = paragraphs_childs[i].singleBase_id_list[y]
                        let haveBeenStored: boolean = false 
                        if (  this.paragraphItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.findIndex(x=> x.component_id === component_id) === -1 ){
                              this.paragraphItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.push({component_id , haveBeenStored})
                        }
                  }
                  console.log( this.paragraphItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property )
             }

             if ( paragraphs_childs[i].hasOwnProperty('tabChild_list') === true ){
                  console.log( paragraphs_childs[i] , 'this is a tab component')
                  this.scomposeTabPlusChildsComponent(paragraphs_childs[i])
             }

             if ( paragraphs_childs[i].hasOwnProperty('multiFeatureChild_list') !== true && paragraphs_childs[i].hasOwnProperty('tabChild_list') !== true  ){ 
                  console.log(paragraphs_childs[i] , 'does not have childs components ')
             }
        }
    }

    scomposeTabPlusChildsComponent(tabComponent_plusChilds: any ){

        for ( let i=0 ; i<tabComponent_plusChilds.feature_id_list.length ; i++ ){
              let component_id: string = tabComponent_plusChilds.feature_id_list[i]
              let haveBeenStored: boolean = false 
              if (  this.paragraphItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.findIndex(x=> x.component_id === component_id) === -1 ){
                    this.paragraphItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.push({component_id , haveBeenStored})
              }

             if ( tabComponent_plusChilds.tabChild_list[i].hasOwnProperty('multiFeatureChild_list') === true ){
                  this.scomposeMultiFeaturePlusChildsComponent(tabComponent_plusChilds.tabChild_list[i])
             }

        }
        console.log( this.paragraphItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property )

    }

    scomposeMultiFeaturePlusChildsComponent(multiFeaturePlusChilds: any ){

        for ( let i=0; i<multiFeaturePlusChilds.singleBase_id_list.length ; i++ ){
              let component_id: string = multiFeaturePlusChilds.singleBase_id_list[i]
              let haveBeenStored: boolean = false 
              if (  this.paragraphItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.findIndex(x=> x.component_id === component_id) === -1 ){
                    this.paragraphItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.push({component_id , haveBeenStored})
              }
        }
        console.log( this.paragraphItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property )

    }

     addStoredComponentConfirmationAndAdviseWhenParagraphItemPlusChildsAreStored(component_id: string ){

        const index = this.paragraphItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.findIndex(x=> x.component_id === component_id)
        const haveBeenStored: boolean = true 
        const component_idPlusBoolean: any = { component_id , haveBeenStored }
        this.paragraphItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property[index] = component_idPlusBoolean 

        console.log( this.paragraphItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property)
        if (this.paragraphItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.findIndex(x=> x.haveBeenStored === false ) !== -1 ){ return }
        if (this.paragraphItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.findIndex(x=> x.haveBeenStored === false ) === -1 ){ 

          /**
           * this send subscription to formEdit + chat fill Form 
           * 
           */
          console.log('IS SENDING SUBJECT PARAGRAPH+CHILDS HAS BEEN SAVED' , this.paragraphItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property[0].component_id)
          this.paragraphItemToAddToFormEdit.next(this.paragraphItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property[0].component_id)


          this.resetForm()


            // send notification to paragraphItem that all childcomponents has been stored and can now load safely
         } 
     }

     resetForm(){
          this.paragraphItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property = []
     }

     onLoggingOutSetListOfParagraphItemsWithChildsEmptyAgain(){
          this.paragraphItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property = []
     }
}