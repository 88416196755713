import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "src/app/app-routing.module";
import { MaterialDesignModule } from "src/app/material-design/material-design.module";
import { SharedModule } from "src/app/shared/shared.module";
import { FormInputComponent } from "./form-input/form-input.component";
import { InputComponent } from "./input.component";
import { SingleWordEditComponent } from "./single-word/single-word-list/single-word-edit/single-word-edit.component";
import { SingleWordItemComponent } from "./single-word/single-word-list/single-word-item/single-word-item.component";
import { SingleWordListComponent } from "./single-word/single-word-list/single-word-list.component";
import { SingleWordComponent } from "./single-word/single-word.component";
import { WordHintEditComponent } from "./word-hint/word-hint-list/word-hint-edit/word-hint-edit.component";
import { WordHintInputComponent } from "./word-hint/word-hint-list/word-hint-input/word-hint-input.component";
import { WordHintListComponent } from "./word-hint/word-hint-list/word-hint-list.component";
import { WordHintComponent } from "./word-hint/word-hint.component";
import { WordLessEditComponent } from "./word-less/word-less-list/word-less-edit/word-less-edit.component";
import { WordLessInputComponent } from "./word-less/word-less-list/word-less-input/word-less-input.component";
import { WordLessListComponent } from "./word-less/word-less-list/word-less-list.component";
import { WordLessComponent } from "./word-less/word-less.component";
import { WordInputEditComponent } from "./word/word-input-list/word-input-edit/word-input-edit.component";
import { WordInputListComponent } from "./word/word-input-list/word-input-list.component";
import { WordInputComponent } from "./word/word-input-list/word-input/word-input.component";
import { WordComponent } from "./word/word.component";
import { SingleWordInputListComponent } from './single-word/single-word-input-list/single-word-input-list.component';
import { SingleWordInputItemComponent } from './single-word/single-word-input-list/single-word-input-item/single-word-input-item.component';
import { SingleWordInputEditComponent } from './single-word/single-word-input-list/single-word-input-edit/single-word-input-edit.component';
import { NumbersComponent } from './numbers/numbers.component';
import { CodesComponent } from './codes/codes.component';
import { NumbersAddToPreviousComponent } from './numbers/numbers-add-to-previous/numbers-add-to-previous.component';
import { SingleNumberComponent } from './numbers/single-number/single-number.component';
import { NumbersAddResetOnDateComponent } from './numbers/numbers-add-reset-on-date/numbers-add-reset-on-date.component';
import { AuxiliaryComponentsModule } from "src/app/loading-spinner/auxiliary-components.module";
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from "@angular/material/input";
import { NameInputComponent } from './name-input/name-input.component';
import { NameInputOnlyComponent } from './name-input-only/name-input-only.component';
import { TextInputComponent } from './text-input/text-input.component';
import { NumberInputComponent } from './number-input/number-input.component';

@NgModule({
    declarations: [
    InputComponent,
    FormInputComponent,
    WordInputComponent,
    WordInputEditComponent,
    WordComponent,
    WordInputListComponent,
    WordLessComponent,
    WordLessInputComponent,
    WordLessEditComponent,
    WordHintComponent,
    WordHintInputComponent,
    WordHintEditComponent,
    WordHintListComponent,
    WordLessListComponent,
    SingleWordComponent,
    SingleWordListComponent,
    SingleWordItemComponent,
    SingleWordEditComponent,
    SingleWordInputListComponent,
    SingleWordInputItemComponent,
    SingleWordInputEditComponent,
    NumbersComponent,
    CodesComponent,
    NumbersAddToPreviousComponent,
    SingleNumberComponent,
    NumbersAddResetOnDateComponent,
    NameInputComponent,
    NameInputOnlyComponent,
    TextInputComponent,
    NumberInputComponent,
      
    ],

    imports: [
    AppRoutingModule,
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    MaterialDesignModule, 
    ReactiveFormsModule,
    MatDialogModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule,
    SharedModule,
    AuxiliaryComponentsModule,
    MatTooltipModule , 
    MatInputModule
    
    ],

    exports: [

    InputComponent,
    FormInputComponent,
    WordInputComponent,
    WordInputEditComponent,
    WordComponent,
    WordInputListComponent,
    WordLessComponent,
    WordLessInputComponent,
    WordLessEditComponent,
    WordHintComponent,
    WordHintInputComponent,
    WordHintEditComponent,
    WordHintListComponent,
    WordLessListComponent,
    SingleWordComponent,
    SingleWordListComponent,
    SingleWordItemComponent,
    SingleWordEditComponent,
    SingleWordInputListComponent,
    SingleWordInputItemComponent,
    SingleWordInputEditComponent,
    NameInputComponent,
    NameInputOnlyComponent,
    TextInputComponent,
    NumberInputComponent


    ],
})
export class InputModule{}