import { Injectable} from '@angular/core';
import { RecipientIndex } from './recipient-index.model'; 
import { RecipientIndexHttpRequestService } from './recipient-index-http-request.service';
import { Subject, Observable , Subscription} from 'rxjs';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { Form } from 'src/app/forms/form.model';
import { RecipientIndexListItem } from './recipient-index-list-item.model';

@Injectable ({
    providedIn: 'root'
})

export class RecipientIndexService {

    startingEditForm = new Subject<Form>()


    constructor( private recipientIndexHttpRequestService: RecipientIndexHttpRequestService , 
                 private initialNullSetupService: InitialNullSetupService, 
                 ){}

    recipientIndexes : RecipientIndex [] = this.initialNullSetupService.initialNullRecipientIndexes



    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.recipientIndexChangedBackToNull
   .subscribe((res)=> {
          this.recipientIndexes = res 
          console.log('updated recipientIndexes back to null = ', res)

    })

  

    getRecipientIndex (id: number){
        return this.recipientIndexes[id]
    };

    getRecipientIndexes (){
        return this.recipientIndexes ;
    }

    getRecipientIndexBy_id(_id: string){
        // return this.recipientIndexes.find(x=> x._id === _id)
        const newRecipientIndex: RecipientIndex = this.recipientIndexes.find(
            (res)=> {
                return res._id === _id
            }
        )
        return newRecipientIndex
    }

    getRecipientIndexBy_UIID(_UIID: string){
        const newRecipientIndex: RecipientIndex = this.recipientIndexes.find(
            (res)=> {
                return res.UIID === _UIID
            }
        )
        return newRecipientIndex
    }

    getOnlyUserRecipientIndexesList(user: string){
    
        const userRecipientIndexesList: RecipientIndex[] = this.recipientIndexes.filter(x=> x.owner === user )
        return userRecipientIndexesList
    }

    addRecipientIndex(recipientIndex: RecipientIndex){
        if (this.recipientIndexes.findIndex(x=> x._id === recipientIndex._id) === -1 ){
            this.recipientIndexes.push(recipientIndex)
            this.updateInitialComponents(this.recipientIndexes)
        }
    }

    updateRecipientIndexes(newRecipientIndexes: RecipientIndex[] ){
        this.recipientIndexes = newRecipientIndexes

        this.updateInitialComponents(this.recipientIndexes)
    }


    fetchRecipientIndexes(){
        console.log('http call to fetch recipient indexes')
        return this.recipientIndexHttpRequestService.httpFetchRecipientIndexes()
    }



// ------- use this subscription to change into recipient list choice on formItem  --------------------------

// private updateRecipientListOnFormItem = new Subject<{recipientIndex: RecipientIndex,  recipientIndexListItem: RecipientIndexListItem }>()
private updateRecipientListOnFormItem = new Subject<any>()


sendRecipientIndexToFormItem(recipientIndex: RecipientIndex, recipientIndexListItem: RecipientIndexListItem){
    console.log(recipientIndex, recipientIndexListItem)
    this.updateRecipientListOnFormItem.next({recipientIndex , recipientIndexListItem })
  //  this.updateRecipientIndexListItemOnFormItem.next(recipientIndexListItem)
};

getRecipientIndexFromRecipientIndexListSelected(): Observable<any> {
    return this.updateRecipientListOnFormItem.asObservable()
}

// getRecipientIndexListItem(): Observable<RecipientIndexListItem> {
//   //  return this.updateRecipientIndexListItemOnFormItem.asObservable()
// }

// ---------------------------------------------------------------------------------------------------------

updateInitialComponents(updatedRecipientIndexes: RecipientIndex[]){
    this.initialNullSetupService.initialNullRecipientIndexes = updatedRecipientIndexes
}

}
