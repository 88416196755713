<!-- here we have 2 almost identical mat-tab-group
the difference is only the arrows that always shows on mibile 
while on desktop they only show if the full width is achieved  -->

<mat-tab-group 
*ngIf="(isHandset$ | async) === false"
#tabs
(selectedTabChange)="onLinkClick($event)" 
class="remove-border-bottom mat-tab-header"
header-less-tabs
headerPosition="below" 
[(selectedIndex)]="selectedIndex"
animationDuration="0ms" >  


<mat-tab *ngFor="let recipient of recipients; let i=index" >

  <div *ngIf="isLoading">
    <br>
    <br>
    <br>
    <br>
    <!-- <app-loading-spinner> </app-loading-spinner> -->
    <br>
    <br>
    <br>
    <br>
  </div>
   
    <app-recipient-item 
    *ngIf="!isLoading"
    [isMessageSide]="isMessageSide"
    [recipientIndexListItem]="recipientIndexListItem"
    [recipientIndex]="recipientIndex"
    [youAreInFormEdit]="youAreInFormEdit"
    [paragraphIndexType]="paragraphIndexType"
    (changeRecipientName)='onChangeRecipientName($event)'
    (emitSecondRecipientResult)='onEmitSecondRecipientResult($event)'
    (emitRecipientResult)="onEmitRecipientResult($event)"
    (emitTemporaryHideRecipientsMessage)="onEmitTemporaryHideRecipientsMessage()"
    (emitTemporaryHideRecipientsMail)="onEmitTemporaryHideRecipientsMail()"
    (emitUpdatedRecipientIndexListItem)="onEmitUpdatedRecipientIndexListItem_afterDeleteMyEmail($event)">
    </app-recipient-item> 
  
             <ng-template  mat-tab-label>
             <a 
                style="cursor: pointer">
                {{i+1}} - {{recipient.name }}  </a>
             </ng-template>

 </mat-tab>


 <div *ngIf="createButtonLimit && recipientIndex.useMyOwnMailRecipient">
  <mat-tab  disabled > 
    <ng-template  mat-tab-label>
      <a (click)="onCreate()" > create new </a>
    </ng-template>
   </mat-tab>
 </div>
 
     
</mat-tab-group>

<!-- for mobile see down here , main difference is the tabs can immediately see the side arrows -->

<mat-tab-group 
*ngIf="isHandset$ | async"
#tabs
(selectedTabChange)="onLinkClick($event)" 
class="remove-border-bottom mat-tab-header mat-tab-header-pagination-controls-enabled"
header-less-tabs
headerPosition="below" 
[(selectedIndex)]="selectedIndex"
animationDuration="0ms" >  


<mat-tab *ngFor="let recipient of recipients; let i=index" >

    <div *ngIf="isLoading">
      <br>
      <br>
      <br>
      <br>
      <!-- <app-loading-spinner> </app-loading-spinner> -->
      <br>
      <br>
      <br>
      <br>
    </div>
   
  <app-recipient-item 
    *ngIf="!isLoading"
    [isMessageSide]="isMessageSide"
    [recipientIndexListItem]="recipientIndexListItem"
    [recipientIndex]="recipientIndex"
    [youAreInFormEdit]="youAreInFormEdit"
    [paragraphIndexType]="paragraphIndexType"
    (changeRecipientName)='onChangeRecipientName($event)'
    (emitSecondRecipientResult)='onEmitSecondRecipientResult($event)'
    (emitRecipientResult)="onEmitRecipientResult($event)"
    (emitTemporaryHideRecipientsMessage)="onEmitTemporaryHideRecipientsMessage()"
    (emitTemporaryHideRecipientsMail)="onEmitTemporaryHideRecipientsMail()"
    (emitUpdatedRecipientIndexListItem)="onEmitUpdatedRecipientIndexListItem_afterDeleteMyEmail($event)">
    </app-recipient-item> 
  
             <ng-template  mat-tab-label >
             <a 
                style="cursor: pointer">
                {{i+1}} - {{recipient.name }}  </a>
             </ng-template>

 </mat-tab>


 <div *ngIf="createButtonLimit && recipientIndex.useMyOwnMailRecipient">
  <mat-tab  disabled > 
    <ng-template  mat-tab-label>
      <a (click)="onCreate()" > create new </a>
    </ng-template>
</mat-tab>
 </div>
 
     
</mat-tab-group>


