import { BrowserModule , Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderComponent } from './header/header.component';
import { TimeoutInterceptor } from './timeout-interceptor.service';

import { LoginComponent } from './login/login.component';
import { SettingsComponent } from './login/settings/settings.component';

import { ButtonsComponent } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/buttons/buttons.component';
import { ParagraphComponent } from './forms/form-list/form-item/paragraph/paragraph.component';


import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { Sibiling1Component } from './sibiling1/sibiling1.component';
import { Sibiling2Component } from './sibiling2/sibiling2.component';

import { ParagraphListComponent } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-list.component';
import { ParagraphItemComponent } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/paragraph-item.component';
import { ParagraphEditComponent } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/paragraph-edit.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialDesignModule} from  './material-design/material-design.module'; 

import { DialogmanagerService} from './services/other-components-services/dialogmanager.service' ;
import { MatDialogModule , MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MyService } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/word/word-input-list/word-input/word-input.component';
 import { ParagraphIndexComponent } from './forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-index/paragraph-index.component';

import {MatExpansionModule} from '@angular/material/expansion';

import { FormsComponent } from './forms/forms.component';
import { FormListComponent } from './forms/form-list/form-list.component';
import { FormEditComponent } from './forms/form-list/form-edit/form-edit.component';
import {FormItemComponent} from './forms/form-list/form-item/form-item.component';
import { ParagraphIndexTopicComponent } from './forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-index-topic.component';
import { FilterPipe } from './forms/filter.pipe';
import { ParagraphIndexTopicListComponent } from './forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic-list.component';
import { FeatureIndexTopicComponent } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-index-topic-list/feature-index-topic/feature-index-topic.component';

import { FeatureIndexTopicListComponent } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-index-topic-list/feature-index-topic-list.component';
import { RecipientIndexListComponent } from './forms/form-list/form-edit/recipient-index-list/recipient-index-list.component';
import { RecipientIndexComponent } from './forms/form-list/form-edit/recipient-index-list/recipient-index/recipient-index.component';
import { FormIndexTopicListComponent } from './forms/form-list/form-index-topic-list/form-index-topic-list.component';
import { FormIndexTopicComponent } from './forms/form-list/form-index-topic-list/form-index-topic/form-index-topic.component';
import { AddFormDialogueComponent } from './forms/form-list/form-edit/add-form-dialogue/add-form-dialogue.component';
import { InputModule } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/input.module';
import { ButtonsModule } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/buttons/buttons.module';
import { SliderModule } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/slider/slider.module';
import { SelectModule } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/select/select.module';
import { CalendarModule } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/calendar.module';
import { OurChartsModule } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/charts/our-charts.module';
import { RatingsModule } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/ratings/ratings.module';
import { TableModule } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/table/table.module';
import { TabsModule } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/tabs/tabs.module';
import { RecipientModule } from './forms/form-list/form-item/recipient/recipient.module';
import { FeaturesComponent } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/features.component';
import { SharedModule } from './shared/shared.module';
import { GoogleChartsModule } from 'angular-google-charts'; 
import { FormResultsModule } from './forms/form-results/form-results.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAndTimeModule } from './date-and-time/date-and-time.module';
import { ContactsModule } from './contacts/contacts.module';
import { ChatsModule } from './chats/chats.module';
import { FormEditParagraphsIndexComponent } from './forms/form-list/form-edit/form-edit-paragraphs-index/form-edit-paragraphs-index.component';
import { PipesModule } from './pipes/pipes.module';
import { LongPress } from './shared/long-press-count.directive';
import { LongPressDirective } from './shared/long-press.directive';
import { MultiFeaturesModule } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/multi-features-list/multi-features.module';
import { AuthInterceptorService } from './auth-interceptor.service';
import { LoggingInterceptorService } from './logging-interceptor.service';
import { ShareParagraphIndexComponent } from './forms/form-list/form-edit/share-paragraph-index/share-paragraph-index.component';
import { TranslateHttpLoader} from '@ngx-translate/http-loader'
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DrappLayoutModule } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/layout/drapp-layout.module';
import { MatInputAutosizeModule } from 'mat-input-autosize';
import { ShareFormIndexComponent } from './forms/form-list/form-index-topic-list/share-form-index/share-form-index.component';

import { TextsModule } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/texts/texts.module';
import { CalculatorModule } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calculator/calculator.module';
import { ColorsComponent } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/colors/colors.component';
import { ColorListComponent } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/colors/color-list/color-list.component';
import { ColorItemComponent } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/colors/color-list/color-item/color-item.component';
import { ColorEditComponent } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/colors/color-list/color-edit/color-edit.component';
import { ScrollBottomToTopDirective } from './shared/scroll-bottop-top.directive';
import { MatBadgeModule} from '@angular/material/badge';

import { CommonModule } from '@angular/common';
import { StepperModule } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/stepper/stepper.module';
import { FormStartComponent } from './forms/form-list/form-start/form-start.component';
import { FormItemSentDialogComponent } from './forms/form-list/form-item/form-item-sent-dialog/form-item-sent-dialog.component';
import { FormReloadComponent } from './forms/form-list/form-reload/form-reload.component';
import { FormNotFoundComponent } from './forms/form-not-found/form-not-found.component';
import { BarQrCodesModule } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/bar-qr-codes/bar-qr-codes.module';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { NgxPrintModule } from 'ngx-print';
import { PrinterModule } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/printer/printer.module';
import { CommentModule } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/comment/comment.module';
import { MatTooltipModule} from '@angular/material/tooltip';
import { AuxiliaryComponentsModule } from './loading-spinner/auxiliary-components.module';
import { FormNameComponent } from './forms/form-list/form-index-topic-list/form-index-topic/form-name/form-name.component';
import { ParagraphDescriptionComponent } from './forms/form-list/form-edit/paragraph-description/paragraph-description.component';
import { ParagraphFeatureContainerComponent } from './forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature-container/paragraph-feature-container.component';
import { FormFeatureContainerComponent } from './forms/form-list/form-index-topic-list/form-index-topic/form-feature-container/form-feature-container.component';
import { FeatureContainerComponent } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container/feature-container.component';
import { FeatureContainerListItemComponent } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container-list-item/feature-container-list-item.component';
import { FormFeatureComponent } from './forms/form-list/form-index-topic-list/form-index-topic/form-feature/form-feature.component';
import { SharedFormIndexTopicComponent } from './forms/form-list/form-index-topic-list/shared-form-index-topic/shared-form-index-topic.component';
import { PublicPreferredFormIndexTopicComponent } from './forms/form-list/form-index-topic-list/public-preferred-form-index-topic/public-preferred-form-index-topic.component';
import { RecipientIndexColumnComponent } from './forms/form-list/form-edit/recipient-index-column/recipient-index-column.component';
import { RecipientIndexColumnItemComponent } from './forms/form-list/form-edit/recipient-index-column/recipient-index-column-item/recipient-index-column-item.component';
import { RecipientIndexListItemComponent } from './forms/form-list/form-edit/recipient-index-column/recipient-index-column-item/recipient-index-list-item/recipient-index-list-item.component';
import { FormSubjectComponent } from './forms/form-list/form-subject/form-subject.component';
import { LinkModule } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/link/link.module';
import { AuthModule } from './auth/auth.module';
import { DataConverterModule } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/data-converter/data-converter.module';
import { ExchangeRateModule } from './forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/exchange-rate/exchange-rate.module';
import { DatePipe } from '@angular/common';
import { AddFormBottomSheetComponent } from './forms/form-list/form-edit/add-form-bottom-sheet/add-form-bottom-sheet.component';
import { ComponentsPreviewsModule } from './components-previews/components-previews.module';
import { MatNativeDateModule } from '@angular/material/core';
import { AddSharedFormOrParagraphComponent } from './forms/form-list/add-shared-form-or-paragraph/add-shared-form-or-paragraph.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { AddSharedFormOrParagraphBottomSheetComponent } from './forms/form-list/add-shared-form-or-paragraph-bottom-sheet/add-shared-form-or-paragraph-bottom-sheet.component';


export function HttploaderFactory(http: HttpClient){
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}






// import { OurFormsModule } from './forms/ourForms.module';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    SettingsComponent,
    ButtonsComponent,
    ParagraphComponent,
    Sibiling1Component,
    Sibiling2Component,
    ParagraphListComponent,
    ParagraphItemComponent,
    ParagraphEditComponent,
    FormsComponent,
    FormListComponent,
    FormEditComponent,
    FormItemComponent,
    ParagraphIndexTopicComponent,
    FilterPipe,
    ParagraphIndexComponent,
    ParagraphIndexTopicListComponent,
    FeatureIndexTopicComponent,
    FeatureIndexTopicListComponent,
    RecipientIndexListComponent,
    RecipientIndexComponent,
    FormIndexTopicListComponent,
    FormIndexTopicComponent,
    AddFormDialogueComponent,
    FeaturesComponent,
    FormEditParagraphsIndexComponent,
    LongPress,
    LongPressDirective,
    ShareParagraphIndexComponent,
    ShareFormIndexComponent,
    ColorsComponent,
    ColorListComponent,
    ColorItemComponent,
    ColorEditComponent,
    ScrollBottomToTopDirective,
    FormStartComponent,
    FormItemSentDialogComponent,
    FormReloadComponent,
    FormNotFoundComponent,
    FormNameComponent,
    ParagraphDescriptionComponent,
    ParagraphFeatureContainerComponent,
    FormFeatureContainerComponent,
    FeatureContainerComponent,
    FeatureContainerListItemComponent,
    FormFeatureComponent,
    SharedFormIndexTopicComponent,
    PublicPreferredFormIndexTopicComponent,
    RecipientIndexColumnComponent,
    RecipientIndexColumnItemComponent,
    RecipientIndexListItemComponent,
    FormSubjectComponent,
    AddFormBottomSheetComponent,
    AddSharedFormOrParagraphComponent,
    AddSharedFormOrParagraphBottomSheetComponent,
    
   
  
   


    
   
  ],

  exports: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    SettingsComponent,
    ButtonsComponent,
    ParagraphComponent,
    Sibiling1Component,
    Sibiling2Component,
    ParagraphListComponent,
    ParagraphItemComponent,
    ParagraphEditComponent,
    FormsComponent,
    FormListComponent,
    FormEditComponent,
    FormItemComponent,
    ParagraphIndexTopicComponent,
    FilterPipe,
    ParagraphIndexComponent,
    ParagraphIndexTopicListComponent,
    FeatureIndexTopicComponent,
    FeatureIndexTopicListComponent,
    RecipientIndexListComponent,
    RecipientIndexComponent,
    FormIndexTopicListComponent,
    FormIndexTopicComponent,
    AddFormDialogueComponent,
    FeaturesComponent,
    HttpClientModule, 
    MatTooltipModule,
    ParagraphDescriptionComponent,
    ParagraphFeatureContainerComponent,
    FormFeatureContainerComponent,
    FeatureContainerComponent,
    FeatureContainerListItemComponent,
    AddSharedFormOrParagraphComponent

    
   
    
 
  ],


  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MaterialDesignModule, 
    ReactiveFormsModule,
    MatDialogModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule,
    InputModule,
    ButtonsModule,
    SliderModule,
    SelectModule,
    CalendarModule,
    OurChartsModule,
    RatingsModule,
    TableModule,
    TabsModule,
    RecipientModule,
    SharedModule,
    GoogleChartsModule,
    FormResultsModule,
     ChatsModule,
     DateAndTimeModule,
    ContactsModule,
    PipesModule,
    MultiFeaturesModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    DrappLayoutModule,
    MatInputAutosizeModule,
    TextsModule,
    MatDatepickerModule,
    CalculatorModule,
    CommonModule,
    MatBadgeModule,
    StepperModule,
    BarQrCodesModule,
    QRCodeModule,
    NgxScannerQrcodeModule,
    NgxPrintModule,
    PrinterModule,
    CommentModule,
    MatTooltipModule,
    AuxiliaryComponentsModule,
    LinkModule , 
    AuthModule , 
    DataConverterModule,
    ExchangeRateModule,
    ComponentsPreviewsModule,
    MatNativeDateModule,
    MatBottomSheetModule,


    TranslateModule.forRoot({
      defaultLanguage: 'en-US',
      loader: {
        provide: TranslateLoader,
        useFactory: HttploaderFactory, 
        deps: [HttpClient]
      }
    })
    
    

  ],







  providers: [
    
    { provide: MatDialogRef,
    useValue: {}
  },

  { provide: HTTP_INTERCEPTORS, 
    useClass: AuthInterceptorService , 
    multi: true },

  { provide: HTTP_INTERCEPTORS, 
    useClass: LoggingInterceptorService , 
    multi: true },

  {
    provide: HTTP_INTERCEPTORS,
    useClass: TimeoutInterceptor,
    multi: true },

  MyService, Title , DatePipe, 
  
  {provide: MAT_DIALOG_DATA, useValue:{}},
  DialogmanagerService 
  ],
  bootstrap: [AppComponent],


})
export class AppModule { }
