import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit , ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef , MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { TimeAlignService } from 'src/app/services/other-components-services/time-align.service';
import { FormResultsService } from '../../../form-results.service';

interface Date_Type_StartEnd {
  isRangeDeselected: boolean , 
  isFixDeselected: boolean , 
  startingDate: string , 
  endingDate: string , 
  typeOfRange: string , 
  numberOfType: string 
}

interface DateType_DateInfo {
  dateType: string , 
  dateInfo: string[]
}

@Component({
  selector: 'app-fix-dates-filter',
  templateUrl: './fix-dates-filter.component.html',
  styleUrls: ['./fix-dates-filter.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FixDatesFilterComponent implements OnInit , OnDestroy{

  days: string[] = ['today' , 'yesterday' , 'n. days ago']
  weeks: string[] = ['this week', 'last week', 'n. weeks ago']
  months: string[] = ['this month', 'last month', 'n. months ago']

  aNumberOfIsSelected: boolean 
  aNumberOfDaysIsSelected: boolean 
  aNumberOfWeeksIsSelected: boolean 
  aNumberOfMonthsIsSelected: boolean

  subjectEditForm : FormGroup ;

  subscriptionToFormResultList_onCheckUpdateFilterStatus: Subscription

  constructor( public dialogRef: MatDialogRef<FixDatesFilterComponent> 
                  
     ) { }

  ngOnInit(): void {

    this.aNumberOfIsSelected = false 
    this.aNumberOfDaysIsSelected = false 
    this.aNumberOfWeeksIsSelected = false 
    this.aNumberOfMonthsIsSelected = false 

    this.subjectEditForm = new FormGroup ({
      count : new FormControl ( '' )
    })

  
  }

  onTodaySelected(){
    // const todayStartEndDateTimeString = this.timeAligneService.getTodayDate_fromRelativeDate_intoStringsYYYYMMDD(new Date())
     const date_Type_StartEnd : Date_Type_StartEnd =  {isRangeDeselected: true , isFixDeselected: false , startingDate: null , endingDate: null , typeOfRange: 'days' , numberOfType: '0' }
     this.dialogRef.close(date_Type_StartEnd)
    
  }

  onYesterdaySelected(){
    // const yesterdayStartEndDateTimeString = this.timeAligneService.getYesterdayDate_fromRelativeDate_intoStringsYYYMMDD(new Date())
     const date_Type_StartEnd : Date_Type_StartEnd =  {isRangeDeselected: true , isFixDeselected: false , startingDate: null , endingDate: null , typeOfRange: 'days' , numberOfType: '1' }
     this.dialogRef.close(date_Type_StartEnd)
  }

  onDaysAgoSelected(){
    this.aNumberOfIsSelected = true 
    this.aNumberOfDaysIsSelected = true 

    // const numberDaysAgo = this.timeAligneService.getDaysAgoDate_fromRelativeDate_intoStringsYYYMMDD( new Date(), 1 )
    // this.dialogRef.close(numberDaysAgo)
  }

  

  onThisWeekSelected(){
    // const thisWeekDate_strings: string[] = this.timeAligneService.getThisWeekDates_fromRelativeDate_intoStringsYYYMMDD( new Date())
    const date_Type_StartEnd : Date_Type_StartEnd =  {isRangeDeselected: true , isFixDeselected: false , startingDate: null , endingDate: null , typeOfRange: 'weeks' , numberOfType: '0' }
    this.dialogRef.close( date_Type_StartEnd )
  }

  onLastWeekSelected(){
    // const lastWeekDate_strings: string[] = this.timeAligneService.getLastWeekDates_fromRelativeDate_intoStringsYYYMMDD( new Date())
    const date_Type_StartEnd : Date_Type_StartEnd =  {isRangeDeselected: true , isFixDeselected: false , startingDate: null , endingDate: null , typeOfRange: 'weeks' , numberOfType: '1' }
    this.dialogRef.close( date_Type_StartEnd )
  }

  onWeeksAgoSelected(){
    this.aNumberOfIsSelected = true 
    this.aNumberOfWeeksIsSelected = true 

    // const weeksAgoSelected = this.timeAligneService.getWeeksAgoDates_fromRelativeDate_intoStringsYYYMMDD( new Date(), 1)
    // this.dialogRef.close( weeksAgoSelected )
  }

  onThisMonthSelected(){
    // const thisMonthDate_Strings: String[] = this.timeAligneService.getThisMonthDates_fromRelativeDate_intoStringsYYYMMDD(new Date())
    const date_Type_StartEnd : Date_Type_StartEnd =  {isRangeDeselected: true , isFixDeselected: false , startingDate: null , endingDate: null , typeOfRange: 'months' , numberOfType: '0' }
    this.dialogRef.close( date_Type_StartEnd )
  }

  onLastMonthSelected(){
    // const lastMonthDate_Strings: String[] = this.timeAligneService.getLastMonthDates_fromRelativeDate_intoStringsYYYMMDD(new Date())
    const date_Type_StartEnd : Date_Type_StartEnd =  {isRangeDeselected: true , isFixDeselected: false , startingDate: null , endingDate: null , typeOfRange: 'months' , numberOfType: '1' }
    this.dialogRef.close( date_Type_StartEnd )

  }

  onMonthsAgoSelected(){
    this.aNumberOfIsSelected = true 
    this.aNumberOfMonthsIsSelected = true 

    // const monthsAgoSelected = this.timeAligneService.getMonthsAgoDates_fromRelativeDate_intoStringsYYYMMDD( new Date(), 1 )
    // this.dialogRef.close( monthsAgoSelected )
  }

  onSendForm(){

    console.log('is submitted' , this.subjectEditForm.value.count )
    if ( this.aNumberOfDaysIsSelected === true ){ const date_Type_StartEnd : Date_Type_StartEnd =  {isRangeDeselected: true , isFixDeselected: false , startingDate: null , endingDate: null , typeOfRange: 'days' , numberOfType: (this.subjectEditForm.value.count).toString() }
                                                  this.dialogRef.close(date_Type_StartEnd)}
    
    if ( this.aNumberOfWeeksIsSelected === true ){  const date_Type_StartEnd : Date_Type_StartEnd =  {isRangeDeselected: true , isFixDeselected: false , startingDate: null , endingDate: null , typeOfRange: 'weeks' , numberOfType: (this.subjectEditForm.value.count).toString() }
                                                    this.dialogRef.close( date_Type_StartEnd )}
   
    if ( this.aNumberOfMonthsIsSelected === true ){ const date_Type_StartEnd : Date_Type_StartEnd =  {isRangeDeselected: true , isFixDeselected: false , startingDate: null , endingDate: null , typeOfRange: 'months' , numberOfType: (this.subjectEditForm.value.count).toString() }
                                                     this.dialogRef.close( date_Type_StartEnd ) }

  }


  ngOnDestroy(): void {
  }



}
