<!-- <p>form-index-topic works!</p> -->

<div *ngIf="!isLoading" class="example-container">

  <!-- start form to create new / and see formIndexTopic -->
    <div *ngIf="formIndexTopic.isAccepted"
         class="example-box" 
         (mouseenter) = "onMouseEnter()"  
         (mouseleave) = "onMouseLeave()" >



 <div *ngIf="!hideButton">

        <div *ngIf="!hide1stButton" (click)="onHide1stButton()" class="pointer">
       
          <svg style="color: #3f51b5;" xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16">
            <circle cx="8" cy="8" r="8"/>
          </svg>
         </div>
     
     <div *ngIf="hide1stButton" class="pointer">
        <svg (click)="onHideButton()" style="color: red;"  xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16">
            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
          </svg>
     </div>

 </div>

    
     <form [formGroup]="formIndexTopicEditName" (ngSubmit)="onSubmit()" >
      <div *ngIf="hideButton" class="inner-box2" >
        <mat-form-field class="full-width">
          <input 
          matInput 
          type="text" 
          id="formIndexName"
          formControlName="name"
          placeholder="index name"
          >
        </mat-form-field>
       
    
        <!-- <button *ngIf="hideButton" type="button" (click)="onSubmit()"  class="btn btn-primary ">+</button> -->
      </div>
      <div *ngIf="(isHandset$ | async)" class="row">
        <div *ngIf="hideButton" class="d-flex justify-content-center" >
         <button type="button" (click)="onSubmit()"  class="btn btn-primary ">+</button>
       </div>
     </div>
     </form>


    <div *ngIf="!hideButton">
      <div (click)="onHideForms()"  
           class="inner-box">
        <a class="navbar-brand" style="color: #3f51b5;"  > {{formIndexTopic.name}} </a>
        <span *ngIf="hideForms">
          <svg style="color: #3f51b5;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
          </svg>
        </span>
        <span *ngIf="!hideForms">
          <svg  xmlns="http://www.w3.org/2000/svg" style="color: #3f51b5;" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
          </svg>
        </span>
      </div>
    </div>
  
 
  
</div> 

   <!-- end form to create new formIndexTopic -->

       
  <div  *ngIf="!hideForms">
        <div *ngFor="let formFeatureContainer_id of formIndexTopic.formFeatureContainer_ids ; let i = index " >
          <app-form-feature-container
                         [typeOfFormIndexTopic]="typeOfFormIndexTopic"
                         [formIndexTopic]="formIndexTopic"
                         [index]="i"
                         [formFeatureContainer_id]="formFeatureContainer_id"
                         (adviseFormListOnLoadingForm)="onAdviseFormListOnLoadingForm()">
          </app-form-feature-container>
        </div>
  </div>
 
        <div  *ngIf="createButtonLimit">
          <div  *ngIf="!hideForms" 
                >  
                <div class="row">
                  <div class="col-8"></div>
                  <div class="col-3">
                    <button mat-mini-fab style="background-color: #3f51b5;" aria-label="Example icon button with a plus one icon" (click)="onWeAreGoingToCreateForm()" routerLink="/create-form" type="button">
                      <mat-icon style="color:white">plus_one </mat-icon>
                    </button>
                  </div>
                </div>
              
          </div > 
        </div>

      
  </div>






