import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-word-hint',
  templateUrl: './word-hint.component.html',
  styleUrls: ['./word-hint.component.css']
})
export class WordHintComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
