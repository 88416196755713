import { Injectable } from '@angular/core'; 
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { Subscription , Subject} from 'rxjs';
import { AppLoadingService } from 'src/app/services/other-components-services/app-loading.service';
import { FormIndexTopicListItem } from './form-index-topic-list-item.model';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { FormFeatureContainerService } from './form-feature-container.service';
import { FormIndexTopic } from './form-index-topic.model';
import { FormIndexTopicService } from './form-index-topic.service';
import { FormFeatureContainer } from './form-feature-container.model';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';


@Injectable ({
    providedIn: 'root' 
})


export class FormIndexTopicListItemService {

    constructor( private initialNullSetupService: InitialNullSetupService,
                 private appLoadingService: AppLoadingService,
                 private formIndexTopicService: FormIndexTopicService ,
                 private userObjectUpdateService: UserObjectUpdateService ,
                 private postComponentsHttpRequestService: PostComponentsHttpRequestService , 
                 private formFeatureContainerService: FormFeatureContainerService ,
                ){}

    user = this.userObjectUpdateService.userEmail

    updatePublicFormTopicIndexListItems = new Subject<void>()

    updateOnUserEmail : Subscription = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
        this.user = updatedUserEmail
        console.log('updated USER = ', this.user)
    });


    formIndexTopicListItems: FormIndexTopicListItem[] = this.initialNullSetupService.initialNullFormIndexTopicListItems


updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.formIndexTopicListItemsChangeBackToNull
   .subscribe((res)=> {
          this.formIndexTopicListItems = res 
          console.log('updated formIndexTopicListItems back to null = ', res)
        
});

// arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex.push({newFormFeatureContainer , receivingFormFeature_owner, typeOfIndex})
updateOrCreateFormIndexTopicOnParagraphFeatureContainerCallOnAddNew: Subscription = this.formFeatureContainerService.formFeatureContainersPlusOwnerAndIndexType
.subscribe((arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex: any[])=>{
  console.log(arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex)

  const startingCount: number = 0

  this.storeThreadAndResolveOneByOne( startingCount , arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex ) // send to counter and initiate count at 0 
})

    
    getFormIndexTopicListItems(){
        return this.formIndexTopicListItems
    };

    getFormIndexTopicListItem_by_type(type: string){
        const newFormIndexTopicListItem = this.formIndexTopicListItems.find(
            (res)=> {
                return res.type === type
            }
        )
        return newFormIndexTopicListItem
    };

    getFormIndexTopicListItem_by_Id( _id: string){
        const newFormIndexTopicListItem = this.formIndexTopicListItems.find(
            (res)=> {
                return res._id === _id
            }
        )
        return newFormIndexTopicListItem

    }

    getFormIndexTopicListItem(i: number){
        return this.formIndexTopicListItems[i]
    }
  

    deleteFormIndexTopic_idOnFormIndexTopicListItem(formIndexTopic_id: string ){
        console.log('deleting => ', formIndexTopic_id)
        const formIndexTopicListItemOfReference: FormIndexTopicListItem =  this.formIndexTopicListItems[this.formIndexTopicListItems.findIndex(x=> x.type === 'public')]
        const newFormIndexTopic_ids : string[] = formIndexTopicListItemOfReference.formIndexTopic_ids

         newFormIndexTopic_ids.splice(newFormIndexTopic_ids.findIndex(x=> x === formIndexTopic_id), 1 )
         formIndexTopicListItemOfReference.formIndexTopic_ids = newFormIndexTopic_ids

        this.formIndexTopicListItems[this.formIndexTopicListItems.findIndex(x=> x.type === 'public')] = formIndexTopicListItemOfReference
        console.log(formIndexTopicListItemOfReference)

        this.updatePublicFormTopicIndexListItems.next()

    }

    storeThreadAndResolveOneByOne( count: number , arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex: any[] ){

        console.log('store thread and resolve one by one ' , arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex )

        if ( count < arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex.length ){
            this.addToOrCreateNewFormIndexTopic( arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex, count  ), 
            console.log(arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex, count)
        }

        if ( count === arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex.length ){
             this.appLoadingService.onStoppingAppLoading()
        }
    };

    addToOrCreateNewFormIndexTopic( arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex : any[] , count: number  ){

        console.log('add or create new FormIndexTopic')

        const formIndexTopicListItemOfReference: FormIndexTopicListItem = this.formIndexTopicListItems.find(x=> x.type === arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex[count].typeOfIndex )

        const formIndexTopicsOfReference: FormIndexTopic[] = []

        for ( let i=0; i<formIndexTopicListItemOfReference.formIndexTopic_ids.length ; i++ ){
            
            formIndexTopicsOfReference.push(this.formIndexTopicService.getFormIndexTopicBy_id(formIndexTopicListItemOfReference.formIndexTopic_ids[i])) 
        };
        console.log('EXISTING SHARED FORM TOPICS OF REFERENCE', formIndexTopicsOfReference)
        
        this.checkIfFormIndexTopicAlreadyExistWithin(arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex , count , formIndexTopicsOfReference  )

    }

    checkIfFormIndexTopicAlreadyExistWithin(arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex: any[] , count: number , formIndexTopicsOfReference: FormIndexTopic[]  ){

        console.log('its going ', arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex )
        const receivingFormFeature_owner = arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex[count].receivingFormFeature_owner
        const formFeatureContainer: FormFeatureContainer = arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex[count].formFeatureContainer
        const typeOfIndex = arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex[count].typeOfIndex
        console.log(formFeatureContainer)


        if ( formIndexTopicsOfReference.findIndex(x=>x.name === receivingFormFeature_owner ) !== -1){
            console.log('check existing PARAGRAPH TOPIC , and check if paragraphFeature is already there or add ')

            const existingFormIndexTopic = formIndexTopicsOfReference[formIndexTopicsOfReference.findIndex(x=> x.name === receivingFormFeature_owner) ]
            console.log(existingFormIndexTopic)
            /**
             * check if paragraphFeatureContainer_id already exist on existingParagraphTopic , if it doesn't we need to add
             */
            if ( existingFormIndexTopic.formFeatureContainer_ids.findIndex(x=>x === formFeatureContainer._id ) !== -1 ) {
                 console.log( 'formFeatureContainer already exist ')
                 // then continu the loop
                 const counter = count+1
                 console.log(counter )
                 this.storeThreadAndResolveOneByOne(  counter , arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex  )
                }

            if ( existingFormIndexTopic.formFeatureContainer_ids.findIndex(x=>x === formFeatureContainer._id ) === -1 ) {

                console.log('its building a new formIndexTopic')
            const new_id = existingFormIndexTopic._id
            const newComponentName = existingFormIndexTopic.componentName
            const newUIID = existingFormIndexTopic.UIID
            const newOwner = existingFormIndexTopic.owner
            const newDateAndTime = existingFormIndexTopic.dateAndTime
            const newAllUsersAllowed = existingFormIndexTopic.allUsersAllowed
            const newUserListAllowed = existingFormIndexTopic.userListAllowed
            const newIsAccepted = existingFormIndexTopic.isAccepted
            const newIsArchived = existingFormIndexTopic.isArchived 
            const newName = existingFormIndexTopic.name
            const newFormFeatureContainer_ids = existingFormIndexTopic.formFeatureContainer_ids

            newFormFeatureContainer_ids.push(formFeatureContainer._id)

            const newFormIndexTopic : FormIndexTopic = new FormIndexTopic ( new_id, newComponentName, newUIID, newOwner, newDateAndTime, newAllUsersAllowed , 
            newUserListAllowed, newIsAccepted, newIsArchived, newName, newFormFeatureContainer_ids)

            this.formFeatureContainerService.formFeatureContainers.push(formFeatureContainer)
            this.initialNullSetupService.initialNullFormFeatureContainers.push(formFeatureContainer)

            this.formIndexTopicService.formIndexTopics[this.formIndexTopicService.getFormIndexTopics().findIndex(x=> x._id === newFormIndexTopic._id )] = newFormIndexTopic
            this.initialNullSetupService.initialNullFormIndexTopics[this.initialNullSetupService.initialNullFormIndexTopics.findIndex(x=> x._id === newFormIndexTopic._id )] = newFormIndexTopic
            console.log( newFormIndexTopic)

            if ( typeOfIndex !== 'public' ){

                // this.formFeatureContainerService.formFeatureContainers.push(formFeatureContainer)
                // this.initialNullSetupService.initialNullFormFeatureContainers.push(formFeatureContainer)

                // this.formIndexTopicService.formIndexTopics[this.formIndexTopicService.getFormIndexTopics().findIndex(x=> x._id === newFormIndexTopic._id )] = newFormIndexTopic
                // this.initialNullSetupService.initialNullFormIndexTopics[this.initialNullSetupService.initialNullFormIndexTopics.findIndex(x=> x._id === newFormIndexTopic._id )] = newFormIndexTopic
                // console.log( newFormIndexTopic)
    
                 const counter = count+1
                 console.log(counter )
                this.storeThreadAndResolveOneByOne(  counter , arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex  )

                
                // this.postComponentsHttpRequestService.httpUpdateComponent( newOwner, 'formIndexTopic', newFormIndexTopic )
                // .subscribe((res)=> { 
                //     console.log(res)
    
                // this.formIndexTopicService.formIndexTopics[this.formIndexTopicService.getFormIndexTopics().findIndex(x=> x._id === newFormIndexTopic._id )] = newFormIndexTopic
                // console.log( newFormIndexTopic)
    
                //  const counter = count+1
                //  console.log(counter )
                // this.storeThreadAndResolveOneByOne(  counter , arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex  )
                // }); // end of subscription 
            }; // end 3rd if statement 
            if ( typeOfIndex === 'public' ){
    
                this.formIndexTopicService.formIndexTopics[this.formIndexTopicService.getFormIndexTopics().findIndex(x=> x._id === newFormIndexTopic._id )] = newFormIndexTopic
                console.log( newFormIndexTopic)
    
                 const counter = count+1
                 console.log(counter )
                this.storeThreadAndResolveOneByOne(  counter , arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex  )
                
            }; // end 3rd if statement 
           
            };// end of 2nd if statement

         }; // end 1st if statement 


        if ( formIndexTopicsOfReference.findIndex(x=>x.name === receivingFormFeature_owner ) === -1){
            
            console.log('CREATING A NEW FORMFEATURECONTAINER AND ADD TO FORMINDEXTOPIC + ADD FORMINDEXTOPIC_ID TO FORMINDEXTOPICLISTITEM')
    /**
     * creating new paragraphTopic
     */
     const new_id = 'formIndexTo-'+(this.formIndexTopicService.getFormIndexTopics().length+1).toString()
     const newComponentName2 = 'formIndexTopic'
     const newUIID2 = 'formIndexTo-'+(this.formIndexTopicService.getFormIndexTopics().length+1).toString()
     const newOwner = this.user 
     const newDateAndTime = null 
     const newAllUsersAllowed = false 
     const newUserListAllowed = []
     const newIsAccepted = false
     const newIsArchived = false
     const newName = receivingFormFeature_owner
     const newFormFeatureContainer_ids:string[] = []

     newFormFeatureContainer_ids.push(formFeatureContainer._id)

     this.formFeatureContainerService.formFeatureContainers.push(formFeatureContainer)
     this.initialNullSetupService.initialNullFormFeatureContainers.push(formFeatureContainer)

     const newFormIndexTopic : FormIndexTopic = new FormIndexTopic ( new_id, newComponentName2, newUIID2, newOwner, newDateAndTime, newAllUsersAllowed , 
     newUserListAllowed, newIsAccepted, newIsArchived, newName, newFormFeatureContainer_ids)

          if ( typeOfIndex !== 'public' ){
            // this.postComponentsHttpRequestService.httpStoreComponent( newOwner, 'formIndexTopic', newFormIndexTopic )
            // .subscribe((formIndexTopic: FormIndexTopic)=> { 

              this.formIndexTopicService.addFormIndexTopic(newFormIndexTopic)

              const formIndexTopicListItemOfReference: FormIndexTopicListItem = this.formIndexTopicListItems.find(x=> x.type === arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex[count].typeOfIndex )
        

               const formIndexTopicListItem_id = formIndexTopicListItemOfReference._id
               const newComponentName = formIndexTopicListItemOfReference.componentName
               const newUIID = formIndexTopicListItemOfReference.UIID
               const formIndexTopicListItemOwner = formIndexTopicListItemOfReference.owner
               const formIndexTopicListItemAllUsersAllowed = formIndexTopicListItemOfReference.allUsersAllowed
               const formIndexTopicListItemUserListAllowed = formIndexTopicListItemOfReference.userListAllowed
               const formIndexTopicListItemDateAndTime = formIndexTopicListItemOfReference.dateAndTime 
               const formIndexTopicListItemType = formIndexTopicListItemOfReference.type
               const formIndexTopicListItem_formIndexTopic_ids = formIndexTopicListItemOfReference.formIndexTopic_ids
               
               formIndexTopicListItem_formIndexTopic_ids.push(newFormIndexTopic._id)

               const newFormIndexTopicListItem = new FormIndexTopicListItem ( formIndexTopicListItem_id, newComponentName,newUIID, formIndexTopicListItemOwner, formIndexTopicListItemAllUsersAllowed ,
                formIndexTopicListItemUserListAllowed, formIndexTopicListItemDateAndTime, formIndexTopicListItemType , formIndexTopicListItem_formIndexTopic_ids  )

              this.formIndexTopicListItems[this.formIndexTopicListItems.findIndex(x=> x._id === newFormIndexTopicListItem._id)] = newFormIndexTopicListItem
              this.initialNullSetupService.initialNullFormIndexTopicListItems[this.initialNullSetupService.initialNullFormIndexTopicListItems.findIndex(x=> x._id === newFormIndexTopicListItem._id)] = newFormIndexTopicListItem

              const counter = count+1
              console.log(counter )
              this.storeThreadAndResolveOneByOne(  counter , arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex  )


          }

          if ( typeOfIndex === 'public'){
            this.formIndexTopicService.addFormIndexTopic(newFormIndexTopic)

            const formIndexTopicListItemOfReference: FormIndexTopicListItem = this.formIndexTopicListItems.find(x=> x.type === arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex[count].typeOfIndex )
        

               const formIndexTopicListItem_id = formIndexTopicListItemOfReference._id
               const newComponentName1 = formIndexTopicListItemOfReference.componentName
               const newUIID1 = formIndexTopicListItemOfReference.UIID
               const formIndexTopicListItemOwner = formIndexTopicListItemOfReference.owner
               const formIndexTopicListItemAllUsersAllowed = formIndexTopicListItemOfReference.allUsersAllowed
               const formIndexTopicListItemUserListAllowed = formIndexTopicListItemOfReference.userListAllowed
               const formIndexTopicListItemDateAndTime = formIndexTopicListItemOfReference.dateAndTime 
               const formIndexTopicListItemType = formIndexTopicListItemOfReference.type
               const formIndexTopicListItem_formIndexTopic_ids = formIndexTopicListItemOfReference.formIndexTopic_ids
               
               formIndexTopicListItem_formIndexTopic_ids.push(newFormIndexTopic._id)

               const newFormIndexTopicListItem = new FormIndexTopicListItem ( formIndexTopicListItem_id, newComponentName1, newUIID1, formIndexTopicListItemOwner, formIndexTopicListItemAllUsersAllowed ,
                formIndexTopicListItemUserListAllowed, formIndexTopicListItemDateAndTime, formIndexTopicListItemType , formIndexTopicListItem_formIndexTopic_ids  )


              this.formIndexTopicListItems[this.formIndexTopicListItems.findIndex(x=> x._id === newFormIndexTopicListItem._id)] = newFormIndexTopicListItem

              const counter = count+1
              console.log(counter )
              this.storeThreadAndResolveOneByOne(  counter , arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex  )
          }

         } // end if statement 
    }



    ngOnDestroy(){
        this.updateInitialNullDatafromInitialNullSetupService.unsubscribe()
    };
    
}