import { Component, Input , OnInit } from '@angular/core';
import { WordDetailResult } from './word-detail-result.model';

@Component({
  selector: 'app-word-result',
  templateUrl: './word-result.component.html',
  styleUrls: ['./word-result.component.css']
})
export class WordResultComponent implements OnInit {

  @Input() wordDetailResult: WordDetailResult

  constructor() { }

  ngOnInit(){
    this.wordDetailResult
  }

}
