import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule , CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { NgxPrintModule } from 'ngx-print'
import { PrinterComponent } from "./printer.component";


@NgModule({

    declarations: [
        PrinterComponent,

    ],

    exports: [
        PrinterComponent,

    ],

    imports: [
        NgxPrintModule

    ]


})

export class PrinterModule{}