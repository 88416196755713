import { Component, OnInit , Output, Input, EventEmitter, HostListener} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FeatureResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/feature-result.model';
import { CommentDetailResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/comment-result/comment-detail-result.model';
import { Subscription, Observable } from 'rxjs';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { Feature } from '../feature.model';
import { FormService } from 'src/app/forms/form.service';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css']
})
export class CommentComponent implements OnInit {

  user: string 
  commentTextForm: FormGroup;

  featureResult : FeatureResult 

  mailTextFeatureBodyPart: string 

  @Input() isParagraphEdit: boolean
  @Input() feature: Feature
  


  key: string = ''

  count: number = 1

 @Output() updateBothFeatureResultAndTextPlusIndex = new EventEmitter<any>()

 requestToFinalizeResultAgain: Subscription 


  constructor(  private userObjectUpdateService: UserObjectUpdateService , 
                private formService: FormService) { }

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

    this.commentTextForm = new FormGroup({
      textComment: new FormControl (this.key, [Validators.maxLength(5000)])
    })

    console.log('feature = ', this.feature)

    if (this.isParagraphEdit !== true){
      setTimeout(()=>{
        this.requestToFinalizeResultAgain = this.formService.getRequestToFinalizeResultAgain().subscribe(()=> this.onSend())

        this.onSend()
        this.commentTextForm.valueChanges.subscribe(() => { this.onSend()} );
      },200)
    } 
  };


  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    if (this.key.length >= 160 ){ this.key = '' , this.count = this.count+1}
    this.key = this.key+event.key
    console.log(event)
    console.log(this.key, this.key.length)

    this.commentTextForm = new FormGroup({
      textComment: new FormControl (this.key, [Validators.maxLength(5000)])
    })

    if (this.isParagraphEdit !== true){
      setTimeout(()=>{
        this.onSend()
        this.commentTextForm.valueChanges.subscribe(() => { this.onSend()} );
      },200)
    }
    
   
    console.log( this.count)
   
   }
  

 


  onSubmit(){
    console.log(this.commentTextForm);
    if (this.commentTextForm.invalid){ return }

    const newFeatureResultType = 'commentResult'
    const newFeatureResultName = 'comment'
    const newCommentDetailResult = new CommentDetailResult (this.commentTextForm.value.textComment) 

    this.mailTextFeatureBodyPart = encodeURIComponent(this.commentTextForm.value.textComment) 
    const newFeatureResult = new FeatureResult ( newFeatureResultType, newFeatureResultName, newCommentDetailResult  )

    this.featureResult = newFeatureResult
  };

  onUpdateFeatureResultEvent(){

    const newFeatureDrop_Id = this.feature.dropId
    const newFeatureResult: FeatureResult = this.featureResult
    const newMailTextFeatureBodyPart = this.mailTextFeatureBodyPart

    this.updateBothFeatureResultAndTextPlusIndex.emit({newFeatureDrop_Id, newFeatureResult, newMailTextFeatureBodyPart})
    
  }


   /**
       * this is fired by the wordInputForm subscription from paragraphItem component (parent)
       */
    onSend(){
      console.log('sending form')
     this.onSubmit()
     this.onUpdateFeatureResultEvent()
    }

    ngOnDestroy(){
      if ( this.isParagraphEdit === false ){ 
        this.requestToFinalizeResultAgain.unsubscribe()
      }
  
    }





}
