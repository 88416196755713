import { DescriptionFrame } from "./description-frame.model";


export class CalculatorMixTable {


constructor (public _id: string, 
        public componentName: string , 
        public UIID: string , 
        public owner: string,
        public allUsersAllowed: boolean,
        public userListAllowed: string[],
        public dateAndTime: string ,
        public name : string,
        public descriptionFrame: DescriptionFrame
        ){}
}