<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>


<br>
<div *ngIf="!isEditingOne">

<app-name-input-only
[inputName]="sharedCalendarStatusItem.name"
[inputLabelInterface]="inputSharedCalendarStatusItemNameInterface"
(updatedNameEvent)="onUpdateNameInputEvent($event)"
(updateNameInputValidity)="onUpdateNameInputValidity($event)"
[isEmptyingInputNameAfterAdded]="emptyInputNameAfterAdded.asObservable()"
>
</app-name-input-only>

<div class="row">
    <div class="d-flex justify-content-center">
        <h2 style="color: #3f51b5">basic set up for each new date range</h2>
    </div>
</div>


<br>

<!-- <div class="row">
    <div class="col-xs-12">
    <div class="InnerComponentFrame">
        <app-users-access-edit
        [accessType]="accessType"
        [usersAccess]="sharedCalendarStatusItem.rangeDatesInfoSetting.usersAccess"
        (updatedUsersAccessEvent)="onUpdateUsersAccess($event)">
        </app-users-access-edit>
    </div>
    </div>
</div>

<br> -->

</div>

<div>

    <div *ngFor="let rangeInfo of sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos; let i=index">

        <div [ngSwitch]="rangeInfo.type">

            <div *ngSwitchCase="'innerdropdown'">

                <div *ngIf="!isEditingOne" class="InnerComponentFrame" >
                    <div class="row">
                        
                        <div class="col-xs-1">
                            <svg (click)="onInEditingOne( i, rangeInfo.type)"
                             style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                             <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                        </svg>
                        </div>
                        <div class="col-xs-9">
                            <h2 style="color: #3f51b5"> {{rangeInfo.type}} - {{rangeInfo.name}}</h2> 
                        </div>
                        <div class="col-xs-1">
                            <svg (click)="onDeleteType(i)" style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                            </svg>
                        </div>
                        
                    </div>

                
                <div class="row">
                 
                    <app-inner-dropdown-item 
                           [canUseIt]="true"
                           [isEditing]="true"
                           [isAddingFeatureDropdown]="false"
                           [isParagraphEdit]="isParagraphEdit"
                           [rangeInfo]="rangeInfo"
                           [changeSelectedDatesView]="changeSelectedDates.asObservable()">
                    </app-inner-dropdown-item>
                </div>
                </div>

                <!-- is editing -->
                <div *ngIf="isEditingOne && (isEditingPositionInArray === i)">
                <!-- <div *ngIf="isEditingInnerDropdown"> -->
                    <app-inner-dropdown-edit
                    [index]="i"
                    [rangeInfo]="rangeInfo"
                    (emittingIndexRangeInfo)="onEmitUpdatedIndexRangeInfoSettings($event)"
                    (emitIndexOfDeletedRangeInfo)="onDeleteIndexRangeInfo($event)">
                   </app-inner-dropdown-edit>
                <!-- </div> -->
                </div>


            </div>

            <div *ngSwitchCase="'innercheckbox'">

                <div *ngIf="!isEditingOne" class="InnerComponentFrame" >
                    <div class="row">
                        
                        <div class="col-xs-1">
                            <svg (click)="onInEditingOne( i, rangeInfo.type)"
                             style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                             <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                        </svg>
                        </div>
                        <div class="col-xs-9">
                            <h2 style="color: #3f51b5"> {{rangeInfo.type}} - {{rangeInfo.name}}</h2> 
                        </div>
                        <div class="col-xs-1">
                            <svg (click)="onDeleteType(i)" style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                            </svg>
                        </div>
                        
                    </div>
                <div class="row">
                
                    <app-inner-checkbox-item
                          [canUseIt]="true"
                          [isEditing]="true"
                          [isParagraphEdit]="isParagraphEdit"
                          [rangeInfo]="rangeInfo"
                          [changeSelectedDatesView]="changeSelectedDates.asObservable()">
                    </app-inner-checkbox-item>
                </div>
                </div>

                <!-- is editing -->
                <div *ngIf="isEditingOne && (isEditingPositionInArray === i)">
                    <!-- <div *ngIf="isEditingInnerCheckbox"> -->
                      
                        <app-inner-checkbox-edit
                        [index]="i"
                        [rangeInfo]="rangeInfo"
                        (emittingIndexRangeInfo)="onEmitUpdatedIndexRangeInfoSettings($event)"
                        (emitIndexOfDeletedRangeInfo)="onDeleteIndexRangeInfo($event)">
                        </app-inner-checkbox-edit>
                       
                    <!-- </div> -->
                </div>


            </div>

            <div *ngSwitchCase="'innertext'">

                <div *ngIf="!isEditingOne" class="InnerComponentFrame" >
                    <div class="row">
                       
                        <div class="col-xs-1">
                            <svg (click)="onInEditingOne( i, rangeInfo.type)"
                             style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                             <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                        </svg>
                        </div>
                        <div class="col-xs-9">
                            <h2 style="color: #3f51b5"> {{rangeInfo.type}} - {{rangeInfo.name}}</h2> 
                        </div>
                        <div class="col-xs-1">
                            <svg (click)="onDeleteType(i)" style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                            </svg>
                        </div>
                        
                    </div>
                <div class="row">
                  
                    <app-inner-text-item
                         [canUseIt]="true"
                         [isEditing]="true"
                         [isParagraphEdit]="isParagraphEdit"
                         [rangeInfo]="rangeInfo"
                         [changeSelectedDatesView]="changeSelectedDates.asObservable()">
                    </app-inner-text-item>
                </div>
                </div>

                <!-- is editing -->
                <div *ngIf="isEditingOne && (isEditingPositionInArray === i)">
                    <!-- <div  *ngIf="isEditingInnerText"> -->
                  
                        <app-inner-text-edit
                        [index]="i"
                        [rangeInfo]="rangeInfo"
                        (emittingIndexRangeInfo)="onEmitUpdatedIndexRangeInfoSettings($event)"
                        (emitIndexOfDeletedRangeInfo)="onDeleteIndexRangeInfo($event)">
                        </app-inner-text-edit>

                        <br>
                      
                    <!-- </div> -->
                </div>


            </div>

            <div *ngSwitchCase="'innerinput'">

                <div *ngIf="!isEditingOne" class="InnerComponentFrame" >
                    <div class="row">
                       
                        <div class="col-xs-1">
                            <svg (click)="onInEditingOne( i, rangeInfo.type)"
                             style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                             <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                        </svg>
                        </div>
                        <div class="col-xs-9">
                            <h2 style="color: #3f51b5"> {{rangeInfo.type}} - {{rangeInfo.name}}</h2> 
                        </div>
                        <div class="col-xs-1">
                            <svg (click)="onDeleteType(i)" style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                            </svg>
                        </div>
                        
                    </div>
                <div class="row">
                  
                    <app-inner-input-item
                         [canUseIt]="true"
                         [isEditing]="true"
                         [isParagraphEdit]="isParagraphEdit"
                         [rangeInfo]="rangeInfo"
                         [changeSelectedDatesView]="changeSelectedDates.asObservable()">
                    </app-inner-input-item>
                </div>
                </div>

                <!-- is editing -->
                <div *ngIf="isEditingOne && (isEditingPositionInArray === i)">
                    <div>
                  
                        <app-inner-input-edit
                        [index]="i"
                        [rangeInfo]="rangeInfo"
                        (emittingIndexRangeInfo)="onEmitUpdatedIndexRangeInfoSettings($event)"
                        (emitIndexOfDeletedRangeInfo)="onDeleteIndexRangeInfo($event)">
                        </app-inner-input-edit>
                      
                    </div>
                </div>


            </div>

            <!-- STOP HERE FOR THE MOMENT  -->

            <div *ngSwitchCase="'innerlink'">

                <div *ngIf="!isEditingOne" class="InnerComponentFrame" >
                <div class="row">
                    <div class="col-xs-1">
                        <svg (click)="onInEditingOne( i, rangeInfo.type)"
                         style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                         <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                    </svg>
                    </div>
                    <div class="col-xs-9"></div>
                    <div class="col-xs-1">
                        <svg (click)="onDeleteType(i)" style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                        </svg>
                    </div>
                    
                </div>
                <div class="row">
                    <div class="col">
                    
                        <app-inner-link-item
                                [isEditing]="true"
                                [rangeInfo]="rangeInfo"
                                [changeSelectedDatesView]="changeSelectedDates.asObservable()">
                        </app-inner-link-item>
                    </div>
                </div>
                </div>

                <!-- is editing -->
                <div *ngIf="isEditingOne && (isEditingPositionInArray === i)">
                    <div class="col" *ngIf="isEditingInnerlink">
                        <app-inner-link-edit
                                [index]="i"
                                [rangeInfo]="rangeInfo"
                                (updatedRangeInfo)="onSubmitInnerFeature($event)">
                        </app-inner-link-edit>
                        <!-- <button class="btn btn-primary btn-sm" (click)="onSubmitInnerFeature(i, rangeInfo.type)">ok link</button> -->
                    </div>
                </div>

            </div>

            <div *ngSwitchCase="'innerslidetoggle'">

                <div *ngIf="!isEditingOne" class="InnerComponentFrame" >
                    <div class="row">
                        
                        <div class="col">
                            <svg (click)="onInEditingOne( i, rangeInfo.type)"
                             style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                             <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                        </svg>
                        </div>
                        <div class="col">
                            <svg (click)="onDeleteType( i, rangeInfo.type)" style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                            </svg>
                        </div>
                        
                    </div>
                <div class="row">
                  
                    <app-inner-slide-toggle-item
                          [isEditing]="true"
                          [rangeInfo]="rangeInfo"
                          [changeSelectedDatesView]="changeSelectedDates.asObservable()">
                    </app-inner-slide-toggle-item>
                </div>
                </div>

                <!-- is editing -->
                <div *ngIf="isEditingOne && (isEditingPositionInArray === i)">
                    <div class="InnerComponentFrame" *ngIf="isEditingInnerSlidetoggle">
               
                        <app-inner-slide-toggle-edit
                        [index]="i"
                        [rangeInfo]="rangeInfo">
                        </app-inner-slide-toggle-edit>
                        <button class="btn btn-primary btn-sm"
                                (click)="onSubmitInnerFeature(i, rangeInfo.type)">
                                ok toggle
                        </button>
                    </div>
                </div>

            </div>

            <div *ngSwitchCase="'innerbutton'">

                <div *ngIf="!isEditingOne" class="InnerComponentFrame" >
                    <div class="row">
                        
                        <div class="col">
                            <svg (click)="onInEditingOne( i, rangeInfo.type)"
                             style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                             <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                        </svg>
                        </div>
                        <div class="col">
                            <svg (click)="onDeleteType( i, rangeInfo.type)" style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                            </svg>
                        </div>
                        
                    </div>
                <div class="row">
                  
                    <app-inner-button-item
                          [isEditing]="true"
                          [rangeInfo]="rangeInfo"
                          [changeSelectedDatesView]="changeSelectedDates.asObservable()">
                    </app-inner-button-item>
                </div>
                </div>

                <!-- is editing -->
                <div *ngIf="isEditingOne && (isEditingPositionInArray === i)">
                    <div class="InnerComponentFrame" *ngIf="isEditingInnerButton">
                    
                        <app-inner-button-edit
                        [rangeInfo]="rangeInfo">
                        </app-inner-button-edit>
                        <button class="btn btn-primary btn-sm"
                                (click)="onSubmitInnerFeature(i, rangeInfo.type)">
                                ok button
                        </button>
                    </div>
                </div>

            </div>

            <div *ngSwitchCase="'innertab'">

                <div *ngIf="!isEditingOne" class="InnerComponentFrame" >
                    <div class="row">
                       
                        <div class="col">
                            <svg (click)="onInEditingOne( i, rangeInfo.type)"
                             style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                             <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                        </svg>
                        </div>
                        <div class="col">
                            <svg (click)="onDeleteType( i, rangeInfo.type)" style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                            </svg>
                        </div>
                        
                    </div>
                <div class="row">
                  
                    <app-inner-tab-item
                         [isEditing]="true"
                         [rangeInfo]="rangeInfo"
                         [changeSelectedDatesView]="changeSelectedDates.asObservable()">
                    </app-inner-tab-item>
                </div>
                </div>

                <!-- is editing -->
                <div *ngIf="isEditingOne && (isEditingPositionInArray === i)">
                    <div class="InnerComponentFrame" >
                  
                        <app-inner-tab-edit
                        [rangeInfo]="rangeInfo">
                        </app-inner-tab-edit>
                        <button class="btn btn-primary btn-sm"
                                (click)="onSubmitInnerFeature(i, rangeInfo.type)">
                                ok text
                        </button>
                    </div>
                </div>


            </div>

            <div *ngSwitchCase="'innerslider'">

                <div *ngIf="!isEditingOne" class="InnerComponentFrame" >
                    <div class="row">
                       
                        <div class="col">
                            <svg (click)="onInEditingOne( i, rangeInfo.type)"
                             style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                             <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                        </svg>
                        </div>
                        <div class="col">
                            <svg (click)="onDeleteType( i, rangeInfo.type)" style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                            </svg>
                        </div>
                        
                    </div>
                <div class="row">
                  
                    <app-inner-slider-item
                         [isEditing]="true"
                         [rangeInfo]="rangeInfo"
                         [changeSelectedDatesView]="changeSelectedDates.asObservable()">
                    </app-inner-slider-item>
                </div>
                </div>

                <!-- is editing -->
                <div *ngIf="isEditingOne && (isEditingPositionInArray === i)">
                    <div class="InnerComponentFrame" >
                  
                        <app-inner-slider-edit
                        [rangeInfo]="rangeInfo">
                        </app-inner-slider-edit>
                        <button class="btn btn-primary btn-sm"
                                (click)="onSubmitInnerFeature(i, rangeInfo.type)">
                                ok text
                        </button>
                    </div>
                </div>


            </div>

            <div *ngSwitchCase="'innerrating'">

                <div *ngIf="!isEditingOne" class="InnerComponentFrame" >
                    <div class="row">
                       
                        <div class="col">
                            <svg (click)="onInEditingOne( i, rangeInfo.type)"
                             style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                             <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                        </svg>
                        </div>
                        <div class="col">
                            <svg (click)="onDeleteType( i, rangeInfo.type)" style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                            </svg>
                        </div>
                        
                    </div>
                <div class="row">
                  
                    <app-inner-rating-item
                         [isEditing]="true"
                         [rangeInfo]="rangeInfo"
                         [changeSelectedDatesView]="changeSelectedDates.asObservable()">
                    </app-inner-rating-item>
                </div>
                </div>

                <!-- is editing -->
                <div *ngIf="isEditingOne && (isEditingPositionInArray === i)">
                    <div class="InnerComponentFrame">
                  
                        <app-inner-rating-edit
                        [rangeInfo]="rangeInfo">
                        </app-inner-rating-edit>
                        <button class="btn btn-primary btn-sm"
                                (click)="onSubmitInnerFeature(i, rangeInfo.type)">
                                ok text
                        </button>
                    </div>
                </div>


            </div>


            <div *ngSwitchCase="'innerdailyactivities'">

                <div *ngIf="!isEditingOne" class="InnerComponentFrame" >
                    <div class="row">
                       
                        <div class="col">
                            <svg (click)="onInEditingOne( i, rangeInfo.type)"
                             style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                             <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                        </svg>
                        </div>
                        <div class="col">
                            <svg (click)="onDeleteType( i, rangeInfo.type)" style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                            </svg>
                        </div>
                        
                    </div>
                <div class="row">
                  
                    <app-inner-daily-activities-item
                         [isEditing]="true"
                         [rangeInfo]="rangeInfo"
                         [changeSelectedDatesView]="changeSelectedDates.asObservable()">
                    </app-inner-daily-activities-item>
                </div>
                </div>

                <!-- is editing -->
                <div *ngIf="isEditingOne && (isEditingPositionInArray === i)">
                    <div class="InnerComponentFrame" >
                  
                        <app-inner-daily-activities-edit
                        [rangeInfo]="rangeInfo">
                        </app-inner-daily-activities-edit>
                        <button class="btn btn-primary btn-sm"
                                (click)="onSubmitInnerFeature(i, rangeInfo.type)">
                                ok text
                        </button>
                    </div>
                </div>


            </div>

            <div *ngSwitchCase="'innerchart'">

                <div *ngIf="!isEditingOne" class="InnerComponentFrame" >
                    <div class="row">
                       
                        <div class="col">
                            <svg (click)="onInEditingOne( i, rangeInfo.type)"
                             style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                             <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                        </svg>
                        </div>
                        <div class="col">
                            <svg (click)="onDeleteType( i, rangeInfo.type)" style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                            </svg>
                        </div>
                        
                    </div>
                <div class="row">
                  
                    <app-inner-chart-item
                         [isEditing]="true"
                         [rangeInfo]="rangeInfo"
                         [changeSelectedDatesView]="changeSelectedDates.asObservable()">
                    </app-inner-chart-item>
                </div>
                </div>

                <!-- is editing -->
                <div *ngIf="isEditingOne && (isEditingPositionInArray === i)">
                    <div class="InnerComponentFrame" >
                  
                        <app-inner-chart-edit
                        [rangeInfo]="rangeInfo">
                        </app-inner-chart-edit>
                        <button class="btn btn-primary btn-sm"
                                (click)="onSubmitInnerFeature(i, rangeInfo.type)">
                                ok text
                        </button>
                    </div>
                </div>


            </div>

            <div *ngSwitchCase="'innersum'">

                <div *ngIf="!isEditingOne" class="InnerComponentFrame" >
                    <div class="row">
                       
                        <div class="col">
                            <svg (click)="onInEditingOne( i, rangeInfo.type)"
                             style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                             <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                        </svg>
                        </div>
                        <div class="col">
                            <svg (click)="onDeleteType( i, rangeInfo.type)" style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                            </svg>
                        </div>
                        
                    </div>
                <div class="row">
                  
                    <app-inner-sum-item
                         [isEditing]="true"
                         [rangeInfo]="rangeInfo"
                         [changeSelectedDatesView]="changeSelectedDates.asObservable()">
                    </app-inner-sum-item>
                </div>
                </div>

                <!-- is editing -->
                <div *ngIf="isEditingOne && (isEditingPositionInArray === i)">
                    <div class="InnerComponentFrame" >
                  
                        <app-inner-sum-edit
                        [rangeInfo]="rangeInfo">
                        </app-inner-sum-edit>
                        <button class="btn btn-primary btn-sm"
                                (click)="onSubmitInnerFeature(i, rangeInfo.type)">
                                ok text
                        </button>
                    </div>
                </div>


            </div>


          

        </div>

      
       

    </div>

</div>

<br>

<div class="row"  *ngIf="!isEditingOne" >
    <div class="col-xs-1">
        <!-- <h2 style="color: #3f51b5" > + </h2> -->
    </div>
    <div class="col-xs-9">
    <div class="InnerComponentFrame" style="background-color: azure;">
       
        <app-inner-dropdown-item 
        [canUseIt]="true"
        [isEditing]="true"
        [isAddingFeatureDropdown]="true"
        [isEditing]="false"
        [isParagraphEdit]="isParagraphEdit"
        [rangeInfo]="additionalRangeInfo"
        [changeSelectedDatesView]="changeSelectedDates.asObservable()"
        (emitSelectedValues)="onGetInnerdropdownSelectedValue($event)">
        </app-inner-dropdown-item>


    </div>
    </div>
</div>


<br>

<div class="row" *ngIf="!isEditingOne">
  
    <div class="col-xs-12">
        <div class="d-flex justify-content-end">
            <button (click)="onCloseDialog()" 
                    class="btn btn-primary">
                    confirm
            </button>
        </div>
    </div>
</div>

<br>




