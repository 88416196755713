import { Component, OnInit, Inject, Input, Output, EventEmitter,  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogmanagerService } from 'src/app/services/other-components-services/dialogmanager.service';
import { SingleSlider } from '../../single-slider.model';
import { SingleSliderDetail } from '../../single-slider-detail.model';
import { SingleSliderService } from '../single-slider.service';
import { Observable, Subscription } from 'rxjs';
import { SingleFeatureDetailResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/single-feature-results/single-feature-detail-result.model';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { Feature } from '../../../../feature.model';
import { FormService } from 'src/app/forms/form.service';


@Component({
  selector: 'app-single-slider-item',
  templateUrl: './single-slider-item.component.html',
  styleUrls: ['./single-slider-item.component.css']
})
export class SingleSliderItemComponent implements OnInit {

  user: string 

    // needed is for stuff like edit buttons to be visibles or not
    @Input() weAreInMultiFeatureEdit:boolean; 
    @Input() noNeeded : boolean  ; 
    @Input() index_id: string 
    @Input() feature: Feature
    index: number

  singleSlider: SingleSlider ; 

  currentSliderDetail: SingleSliderDetail
  singleSliders: SingleSlider[];

  singleSliderResult: SingleSlider;
  

  @Output() deleteSingleFeatureResultEvent = new EventEmitter<void>()

  singleFeatureDetailResultSubscription: Subscription;
  @Input() subscribeSingleFeatureResult: Observable<void>
  @Input() isParagraphEdit: boolean ;

  singleFeatureDetailResult: SingleFeatureDetailResult;

  mailTextSingleFeatureBodyPart: string ;

  @Output() updateBothSingleFeatureResultAndTextPlusIndex = new EventEmitter<any>()

  requestToFinalizeResultAgain: Subscription


  constructor(  private singleSliderService: SingleSliderService,
                private dms: DialogmanagerService,
                private  formService: FormService,
                private dialogRef: MatDialogRef<SingleSliderItemComponent>,
                private userObjectUpdateService: UserObjectUpdateService ,
                @Inject(MAT_DIALOG_DATA) public editData: any) { }

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

    // this.index = this.singleSliderService.getSingleSliders().findIndex(x => x._id === this.index_id)
    this.singleSlider = this.singleSliderService.getSingleSliderBy_id(this.index_id)

    console.log('feature = ', this.feature,this.singleSlider)

    const newDetailName = this.singleSlider.singleSliderDetails[0].detailName
    const newUnit = this.singleSlider.singleSliderDetails[0].unit
    const newAutoTicks = this.singleSlider.singleSliderDetails[0].autoTicks
    const newDisabled = false
    // const newDisabled = this.singleSlider.singleSliderDetails[0].disabled
    const newInvert = this.singleSlider.singleSliderDetails[0].invert
    const newMax = this.singleSlider.singleSliderDetails[0].max
    const newMin = this.singleSlider.singleSliderDetails[0].min
    const newShowTicks = this.singleSlider.singleSliderDetails[0].showTicks
    const newStep = this.singleSlider.singleSliderDetails[0].step
    const newThumbLabel = this.singleSlider.singleSliderDetails[0].thumbLabel
    const newValue = this.singleSlider.singleSliderDetails[0].value
    const newVertical = this.singleSlider.singleSliderDetails[0].vertical
    const newTickInterval = this.singleSlider.singleSliderDetails[0].tickInterval
  
    const newSingleSliderDetailResult = new SingleSliderDetail (newDetailName, newUnit, newAutoTicks, newDisabled, 
      newInvert, newMax, newMin, newShowTicks, newStep, newThumbLabel, newValue, newVertical, newTickInterval )
  
    const newSingleFeatureDetailResultType = 'singleSliderResult';
    const newSingleFeatureDetailResultName = this.singleSlider.name;
    const newSingleFeatureDetailResult = new SingleFeatureDetailResult (newSingleFeatureDetailResultType, newSingleFeatureDetailResultName, newSingleSliderDetailResult )

    /**
     * try to use this to make a new object not connected to the service
     */
    this.currentSliderDetail = new SingleSliderDetail (newDetailName, newUnit, newAutoTicks, false, 
      newInvert, newMax, newMin, newShowTicks, newStep, newThumbLabel, newValue, newVertical, newTickInterval)
   
      this.singleFeatureDetailResult = newSingleFeatureDetailResult
   

    this.mailTextSingleFeatureBodyPart = newDetailName+': '+newValue+' '+newUnit

    /**
     * send initial result status
     */
    //  if ( this.weAreInMultiFeatureEdit === false){
      this.requestToFinalizeResultAgain = this.formService.getRequestToFinalizeResultAgain().subscribe(()=> this.onSend())
      setTimeout(()=>{
        this.onSend()
      },200)
    //  }



      /**
     * fire the subscription on ngOninIt only when we are not Editing the feature
     */
      //  if( this.isParagraphEdit !== true){
      //   this.singleFeatureDetailResultSubscription = this.subscribeSingleFeatureResult.subscribe(()=> this.onSend())
      // }

  }


  getSliderTickInterval(): number | 'auto' {
    // if (this.singleSlider.singleSliderDetails[0].showTicks) {
      if (this.currentSliderDetail.showTicks) {
  
      const newDetailName = this.currentSliderDetail.detailName
      const newUnit = this.currentSliderDetail.unit
      const newAutoTicks = this.currentSliderDetail.autoTicks
      const newDisabled = false
      // const newDisabled = this.singleSlider.singleSliderDetails[0].disabled
      const newInvert = this.currentSliderDetail.invert
      const newMax = this.currentSliderDetail.max
      const newMin = this.currentSliderDetail.min
      const newShowTicks = this.currentSliderDetail.showTicks
      const newStep = this.currentSliderDetail.step
      const newThumbLabel = this.currentSliderDetail.thumbLabel
      const newValue = this.currentSliderDetail.value
      const newVertical = this.currentSliderDetail.vertical
      const newTickInterval = this.currentSliderDetail.tickInterval
    
      const newSingleSliderDetailResult = new SingleSliderDetail (newDetailName, newUnit, newAutoTicks, newDisabled, 
        newInvert, newMax, newMin, newShowTicks, newStep, newThumbLabel, newValue, newVertical, newTickInterval )
    
      const newSingleFeatureDetailResultType = 'singleSliderResult';
      const newSingleFeatureDetailResultName = this.singleSlider.name;
      const newSingleFeatureDetailResult = new SingleFeatureDetailResult (newSingleFeatureDetailResultType, newSingleFeatureDetailResultName, newSingleSliderDetailResult )
  
      this.singleFeatureDetailResult = newSingleFeatureDetailResult
     
  
      this.mailTextSingleFeatureBodyPart = newDetailName+': '+newValue+' '+newUnit

      // return this.singleSlider.singleSliderDetails[0].autoTicks ? 'auto' : this.singleSlider.singleSliderDetails[0].tickInterval;
      return this.currentSliderDetail.autoTicks ? 'auto' : this.currentSliderDetail.tickInterval;

    }

    return 0;
  }

  updateSliderValue($event){
    console.log($event)
    const newDetailName = this.currentSliderDetail.detailName
      const newUnit = this.currentSliderDetail.unit
      const newAutoTicks = this.currentSliderDetail.autoTicks
      const newDisabled = false
      // const newDisabled = this.singleSlider.singleSliderDetails[0].disabled
      const newInvert = this.currentSliderDetail.invert
      const newMax = this.currentSliderDetail.max
      const newMin = this.currentSliderDetail.min
      const newShowTicks = this.currentSliderDetail.showTicks
      const newStep = this.currentSliderDetail.step
      const newThumbLabel = this.currentSliderDetail.thumbLabel
      const newValue = $event.value
      const newVertical = this.currentSliderDetail.vertical
      const newTickInterval = this.currentSliderDetail.tickInterval
    
      const newSingleSliderDetailResult = new SingleSliderDetail (newDetailName, newUnit, newAutoTicks, newDisabled, 
        newInvert, newMax, newMin, newShowTicks, newStep, newThumbLabel, newValue, newVertical, newTickInterval )
    
      const newSingleFeatureDetailResultType = 'singleSliderResult';
      const newSingleFeatureDetailResultName = this.singleSlider.name;
      const newSingleFeatureDetailResult = new SingleFeatureDetailResult (newSingleFeatureDetailResultType, newSingleFeatureDetailResultName, newSingleSliderDetailResult )
  
      this.singleFeatureDetailResult = newSingleFeatureDetailResult

      this.mailTextSingleFeatureBodyPart = newDetailName+': '+newValue+' '+newUnit
      console.log(this.singleFeatureDetailResult)
    this.onSend()
  }



  openDialogSingleSliderEdit(){
    this.dms.SingleSliderEdit({

      name: this.singleSlider.name ,
      DetailsArray : this.singleSlider.singleSliderDetails

    }).subscribe( res => {
      this.singleSlider.name = res.name,
      this.singleSlider.singleSliderDetails = res.details
    })
    console.log(this.singleSliderService.getSingleSliders())
  }

  /**
   *  }).subscribe(res => {

      console.log('the dialog was closed ');
        this.wordInput.name = res.name,
        this.wordInput.details = res.details
      } )
      console.log(this.wordInputService.getWordInput(this.index))
  }
   */

  onDeleteSingleSliderItem(){
    this.deleteSingleFeatureResultEvent.emit()
  }


  onSend(){
    if ( this.weAreInMultiFeatureEdit === false){

      const newFeatureDrop_Id = this.feature.dropId
      const newSingleFeatureDetailResult: SingleFeatureDetailResult = this.singleFeatureDetailResult
      const newMailTextSingleFeatureBodyPart: string = this.mailTextSingleFeatureBodyPart
      
      this.updateBothSingleFeatureResultAndTextPlusIndex.emit({ newFeatureDrop_Id, newSingleFeatureDetailResult, newMailTextSingleFeatureBodyPart   })

    }
     
  };

  ngOnDestroy(){
    // if (this.weAreInMultiFeatureEdit !== true){
     this.requestToFinalizeResultAgain.unsubscribe()
    // }
    // if( this.isParagraphEdit !== true){
    //   this.singleFeatureDetailResultSubscription.unsubscribe()
    // }

  }



}
