import { Directive, ElementRef, OnInit , HostListener, HostBinding } from "@angular/core";

@Directive({
  selector: "[multi-features-header-less-tabs]",
})
export class MultiFeaturesHeaderLessTabsDirective implements OnInit {
  constructor(private eleRef: ElementRef) {}

  ngOnInit(): void {
   this.eleRef.nativeElement.children[0].style.display = "none";
  }


}