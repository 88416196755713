<!-- <p>drop-down-select-result works!</p> -->

<div class="row">
    <div class="col-xs-12">

        <table>
            <tbody>
                <tr>
                    <td *ngFor="let detail of dropDownSelectDetailResults; let i=index">

                        <mat-form-field style="width: 500px" appearance="outline">
                            <mat-label> {{detail.descriptionResult}} </mat-label>
                            <input
                             disabled
                             matInput
                             ngModel="{{detail.contentResult}}"
                             type="text" >

                        </mat-form-field>

                    </td>
                </tr>
            </tbody>

        </table>
    </div>
</div>


