
<div class="row">
  <mat-form-field>
   

    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
      <input matStartDate 
             formControlName="start" 
             id="start"
             placeholder="Start date"
             (dateInput)="changeDate('start', $event)" 
             >
      <input matEndDate 
             formControlName="end" 
             id="end"
             placeholder="End date"
             (dateInput)="changeDate('end', $event)" >
    </mat-date-range-input>
    <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>

    <mat-date-range-picker #picker
            [dateClass]="dateClassCallback" >
    </mat-date-range-picker>
    <!-- [dateClass]="dateClassCallback" -->
  
    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
  </mat-form-field>
</div>

<br>

<div class="row">
  <button class="btn btn-primary"
          (click)="onAddRangeAndCloseDialog()">
    ok
  </button>
</div>

   
 

