import { Directive , OnInit, Renderer2 , ElementRef, HostListener, HostBinding} from '@angular/core';

@Directive({
  selector: '[appHeaderLessTabs2]'
})
export class HeaderLessTabs2Directive implements OnInit {

  // check lesson 98

@HostBinding('style.backgroundColor') backgroundColor: string = 'white';

  constructor(private eleRef :ElementRef) { }

  ngOnInit(): void {
    this.eleRef.nativeElement.children[0].style.display = "none";
   }
 
 
 }