export class FormIndexTopic {

    constructor ( public _id: string, 
                  public componentName: string , 
                  public UIID: string , 
                  public owner: string,  
                  public dateAndTime: string, 
                  public allUsersAllowed: boolean , 
                  public userListAllowed: string[], 
                  public isAccepted : boolean , 
                  public isArchived : boolean , 
                  public name: string , 
                  public formFeatureContainer_ids : string[]
                   ){};
};