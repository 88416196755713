import { Component, OnInit , ViewChild, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { OneDateCalendarDetailResult } from './one-date-calendar-result.model';
import { MatDatepicker } from '@angular/material/datepicker';


@Component({
  selector: 'app-one-date-calendar-result',
  templateUrl: './one-date-calendar-result.component.html',
  styleUrls: ['./one-date-calendar-result.component.css']
})
export class OneDateCalendarResultComponent implements OnInit {

  @Input() oneDateCalendarDetailResults:  OneDateCalendarDetailResult[]
 
  @ViewChild('picker', { static: true }) _picker: MatDatepicker<Date>;

  listOfDates: Date[]

  constructor() { }

  ngOnInit(){

  this.listOfDates = []
  for (let i=0; i<this.oneDateCalendarDetailResults.length; i++){
    this.listOfDates.push(this.oneDateCalendarDetailResults[i].dateResult )
  }


  }

  dateClass = (date: Date) => {
    if (this._findDate(date) !== -1) {
      return [ 'selected' ];
    }
    return [ ];
  };

  _findDate(date: Date): number {
    return this.listOfDates.map((m) => +m).indexOf(+date);
  };


}
