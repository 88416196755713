


<div class="paragraphItemFrame">

<div class="row"
     align="center" 
     style="color: #3f51b5;">
     {{paragraph.desc}}
</div>
 
<div   class="paragraphItemFrame2">
   
        <div  *ngFor="let feature of paragraph.features ; let i = index " >


       <div> 

          <div [ngSwitch]="feature.featureName">

            <!-- [index]="paragraph.featureid"  -->
          <app-multi-features 
          [isTabItem]="false"
          [isParagraphEdit]="isParagraphEdit"
          (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)' 
          [noNeeded]="true" 
          [feature]="feature"
          [index_id]="feature.feature_id"
          [paragraphEdit_selectedIndex_id]="feature.feature_id"
          *ngSwitchCase="'multiFeatures'">
          </app-multi-features>

          <app-word-input 
          [isTabItem]="false"
          [isParagraphEdit]="isParagraphEdit"
          (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)'  
          [noNeeded]="true" 
          [feature]="feature"
          [index_id]="feature.feature_id"
          *ngSwitchCase="'wordInput'">
          </app-word-input>

          <app-tab-item
          [isTabItem]="false"
          [isUnderTabs]="isUnderTabs"
          [isParagraphEdit]="isParagraphEdit"
          (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)' 
          [noNeeded]="true" 
          [feature]="feature"
          [index_id]="feature.feature_id"
          [paragraphEdit_selectedIndex_id]="feature.feature_id"
          *ngSwitchCase="'tabs'">
          </app-tab-item>

          <app-fix-layout-item
          [isTabItem]="false"
          [isParagraphEdit]="isParagraphEdit"
          (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)' 
          [noNeeded]="true" 
          [feature]="feature"
          [index_id]="feature.feature_id"
          *ngSwitchCase="'fixLayout'">
          </app-fix-layout-item>

          <app-fix-text-item
          [isTabItem]="false"
          [isParagraphEdit]="isParagraphEdit"
          (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)' 
          [noNeeded]="true" 
          [feature]="feature"
          [index_id]="feature.feature_id"
          *ngSwitchCase="'fixText'">
          </app-fix-text-item>

          
          <app-calendar-item
          [isTabItem]="false"
          [isParagraphEdit]="isParagraphEdit"
          (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)' 
          [noNeeded]="true"
          [feature]="feature"
          [index_id]="feature.feature_id"
          *ngSwitchCase="'oneDateCalendar'">
          </app-calendar-item>

          <app-multi-entry-calendar-item
          [isTabItem]="false"
          [isParagraphEdit]="isParagraphEdit"
          (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)' 
          [noNeeded]="true"
          [feature]="feature"
          [index_id]="feature.feature_id"
          *ngSwitchCase="'multiEntryCalendar'">
          </app-multi-entry-calendar-item>

          <app-calculator-mix-table-item
          [isTabItem]="false"
          [isParagraphEdit]="isParagraphEdit"
          (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)' 
          [noNeeded]="true"
          [feature]="feature"
          [index_id]="feature.feature_id"
          *ngSwitchCase="'calculatorMixTable'">
          </app-calculator-mix-table-item>

          <app-drop-down-select-item
          [isTabItem]="false"
          [isParagraphEdit]="isParagraphEdit"
          [feature]="feature"
          (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)'
          [noNeeded]="true"
          [index_id]="feature.feature_id"
          *ngSwitchCase="'dropDownSelect'">
          </app-drop-down-select-item>

          <app-check-box-select-item
          [isTabItem]="false"
          [isParagraphEdit]="isParagraphEdit"
          [feature]="feature"
          (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)' 
          [noNeeded]="true"
          [index_id]="feature.feature_id"
          *ngSwitchCase="'checkBoxSelect'">
          </app-check-box-select-item>

          <app-comment
          [isParagraphEdit]="isParagraphEdit"
          (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)' 
          *ngSwitchCase="'comment'">
          </app-comment>

          <app-word-hint-input 
          [noNeeded]="true" 
          [feature]="feature"
          [index]="feature.featureid" 
          *ngSwitchCase="'word hint'">
          </app-word-hint-input> 

          <app-word-less-input 
          [noNeeded]="true" 
          [feature]="feature"
          [index]="feature.featureid" 
          *ngSwitchCase="'word simple'">
          </app-word-less-input>


          <app-phrase-button 
          *ngSwitchCase="'phrase'">
          </app-phrase-button>

          <app-form-input    
          *ngSwitchCase="'form'">
          </app-form-input>

          <app-horizon-add   
          *ngSwitchCase="'mix form'">
         </app-horizon-add>
         </div>
  
       </div>
      
    </div>  
    
  </div>

</div>

  <!-- <app-paragraph-item [subscribeParagraphResult]="subscribeClickEvent.asObservable()"  
  [needed] 
  [id]="paragraphFeature.paragraphId"
  (updateSentParagraphResultEvent)='onUpdateSentParagraphResult($event)'
  (updateReceivedParagraphResultEvent)='onUpdateReceivedParagraphResult($event)'>
</app-paragraph-item> -->
    



