export class RecipientFeature {

    constructor( public _id: string , 
                 public componentName: string,
                 public UIID: string,
                 public owner: string,
                 public allUsersAllowed: boolean ,
                 public userListAllowed: string[], 
                 public dateAndTime: string ,
                 public receivedRecipient_ids: string[]

                ){}
}