<!-- <p>form-index-topic-list works!</p> -->


<form [formGroup]="formIndexTopicAddName" (ngSubmit)="onSubmit()">

    <app-find-form-or-paragraph
    *ngIf="typeOfFormIndexTopic.isPublic"
    [weAreInFormIndexTopicList]="weAreInFormIndexTopicList"
    (emitUserEmailFound)="onAddEmailSearch($event)"
    (emitUserEmailIndexToCancel)="onDeleteEmailOnSearch($event)">
    </app-find-form-or-paragraph>

    <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div *ngFor="let formIndexTopic_id of formIndexTopicListItem.formIndexTopic_ids ; let i = index ">
       

<!-- i have to transmit to each child component what type of topic we are in , to make sure 
we select the right components and we call the right routerLink on formFeature  -->
<div  *ngIf="typeOfFormIndexTopic.isPersonal">
   <app-form-index-topic 
  [typeOfFormIndexTopic]="typeOfFormIndexTopic"
   *ngIf="!isLoading"
  [index_id]="formIndexTopic_id"  
  (adviseFormListOnLoadingForm)='onAdviseFormListOnLoadingForm()'
  (closeSideNavOnFormList)='onCloseSideNavOnFormList()'>
   </app-form-index-topic>
</div>
       
<div *ngIf="typeOfFormIndexTopic.isShared">
     <app-shared-form-index-topic
      [typeOfFormIndexTopic]="typeOfFormIndexTopic"
      *ngIf="!isLoading"
      [index_id]="formIndexTopic_id"
      (adviseFormListOnLoadingForm)='onAdviseFormListOnLoadingForm()'
      (closeSideNavOnFormList)='onCloseSideNavOnFormList()' >
      </app-shared-form-index-topic>
</div>

<div *ngIf="typeOfFormIndexTopic.isPreferred">
  <app-public-preferred-form-index-topic
  [typeOfFormIndexTopic]="typeOfFormIndexTopic"
  *ngIf="!isLoading"
  [index_id]="formIndexTopic_id"
  (adviseFormListOnLoadingForm)='onAdviseFormListOnLoadingForm()'
  (closeSideNavOnFormList)='onCloseSideNavOnFormList()' >
  </app-public-preferred-form-index-topic>
</div>

<div  *ngIf="typeOfFormIndexTopic.isPublic">
  <app-public-preferred-form-index-topic
  [typeOfFormIndexTopic]="typeOfFormIndexTopic"
  *ngIf="!isLoading"
  [index_id]="formIndexTopic_id" 
  (adviseFormListOnLoadingForm)='onAdviseFormListOnLoadingForm()'
  (closeSideNavOnFormList)='onCloseSideNavOnFormList()' >
  </app-public-preferred-form-index-topic>
</div>


    </div>

    


  <div *ngIf="typeOfFormIndexTopic.isPersonal">
    <div *ngIf="!hideButton">
      
      <div *ngIf="createButtonLimit" class="example-box"   >
        <mat-chip (click)="onHideButton()" 
                  style="background-color: #3f51b5 ; color: white ; width:200px ">
          + WEB PAGES TOPIC
        </mat-chip>
      </div> 
    </div >   
    
    <div   *ngIf="hideButton" class="example-box">
      
          <div  class="example-box"  >
              
                    <input 
                    type="text" 
                    id="topicName"
                    formControlName="name"
                    class="form-control"
                    placeholder="topic name"
                    >
                
                    <button [hidden]="!hideButton" type="submit"  class="btn btn-primary ">+</button>
                 
              </div>
    </div> 
  </div> 
    
    </form>

    <div class="example-box" *ngIf="typeOfFormIndexTopic.isPersonal" style="height: 300px;" >

    </div>

    <div class="example-box" *ngIf="sharedFormIndexTopicsNumber == 0 && typeOfFormIndexTopic.isShared">
    
      <div class="example-box" >
        <h2 style="color: #3f51b5">no available pages yet </h2>
      </div>
    </div> 

    <div class="example-box" *ngIf="preferredFormIndexTopicsNumber == 0 && typeOfFormIndexTopic.isPreferred"  >

      <div class="example-box" >
        <h2 style="color: #3f51b5">add pages from public </h2>
      </div>
     
    </div> 

