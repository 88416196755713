import { SingleDropDownSelectDetailResult } from "./single-drop-down-select-result/single-drop-down-select-detail-result.model";
import { SingleToggleWordButtonDetailResult } from "./single-toggle-word-button-result/single-toggle-word-button-detail-result.model";
import { SingleWordInputDetailResult } from "./single-word-input-result/single-word-input-detail-result.model";
import { SingleWordDetailResult } from "./single-word-result/single-word-detail-result.model"
import { SingleSliderDetail } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/slider/single-slider/single-slider-detail.model";

export class SingleFeatureDetailResult{

    constructor (
        public singleFeatureDetailResultType: string,
        public singleFeatureDetailResultName: string,
        public singleFeatureDetailResultResult: SingleToggleWordButtonDetailResult |
                                                SingleWordInputDetailResult |
                                                SingleWordDetailResult |
                                                SingleDropDownSelectDetailResult|
                                                SingleSliderDetail
                                                
    ){}
}