import { Injectable } from "@angular/core"
import { Feature } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/feature.model"
import { FeatureContainer } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-container.model"
import { FeatureContainerListItemService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container-list-item/feature-container-list-items.service"
import { FeatureContainerListItem } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-container-list-item.model"
import { FeatureContainerService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container/feature-container.service"
import { PostComponentsHttpRequestService } from "../post-and-put-services/post-components-http-request.service"
import { Subject } from "rxjs"
import { FixTextService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/texts/fix-text-list/fix-text.service"
import { FixText } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/texts/fix-text-list/fix-text.model"
import { WordInput } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/word/word-input.model"
import { Tab } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/tabs/tab-list/tab.model"

@Injectable({
    providedIn: 'root' 
} )

export class CreateNewFeatureContainerAndAddToFeatureContainerListItemService {


    constructor( private featureContainerService: FeatureContainerService , 
                 private featureContainerListItemService: FeatureContainerListItemService , 
                 private postComponentsHttpRequestService: PostComponentsHttpRequestService 
                 ){}
/**
 * 
 * @param feature 
 * @param user 
 * 
 * export class FeatureContainer {

    constructor ( public _id: string, 
                  public componentName: string , 
                  public UIID: string , 
                  public owner: string , 
                  public allUsersAllowed: boolean , 
                  public userListAllowed: string[],
                  public dateAndTime: string , 
                  public isActive: boolean , 
                  public isAccepted: boolean , 
                  public name: string ,
                  public componentReference_id: string, 
                  public features: Feature[] // need to use array just to justify drag and drop
                  // but eventually always put just 1 feature and point to the 0 index 
                  ){

    }
export class FeatureContainerListItem {

    constructor( public _id: string , 
                 public componentName: string , 
                 public UIID: string , 
                 public owner: string , 
                 public allUsersAllowed: boolean , 
                 public userListAllowed: string [], 
                 public dateAndTime: string ,  
                 public type: string ,
                 public name: string , 
                 public featureContainer_ids: string[] , // list of featureContainer_id
                 public selectedFeatureContainer_id: string){} 
}
 */



    createFeatureContainer_toAddOnFeatureContainerListItem(feature: Feature , user: string ){

        const newFeatureContainer_id = 'featureContai-'+(this.featureContainerService.getFeatureContainers().length+1).toString()
        const newFeatureContainerComponentName= 'featureContainer'
        const newFeatureContainerUIID= 'featureContainer-'+(this.featureContainerService.getFeatureContainers().length+1).toString()
        const newFeatureContainerOwner = user
        const newFeatureContainerAllUsersAllowed = false
        const newFeatureContainerUserListAllowed = []
        const newFeatureContainerDateAndTime = null 
        const newFeatureContainerIsActive = true
        const newFeatureContainerIsAccepted = true 
        const newFeatureContainerName = feature.featureName
        const newFeatureContainerComponentReference_id = feature.feature_id
        const newnewFeatureContainerFeatures = []
        newnewFeatureContainerFeatures.push(feature)
      
        const newFeatureContainer = new FeatureContainer (newFeatureContainer_id, newFeatureContainerComponentName, newFeatureContainerUIID,  newFeatureContainerOwner, newFeatureContainerAllUsersAllowed,
            newFeatureContainerUserListAllowed, newFeatureContainerDateAndTime, newFeatureContainerIsActive, newFeatureContainerIsAccepted,
            newFeatureContainerName, newFeatureContainerComponentReference_id, newnewFeatureContainerFeatures )
      
            if ( user !== null ){
                this.postComponentsHttpRequestService.httpStoreComponent(user, 'featureContainer', newFeatureContainer )
                .subscribe(
                (featureContainer_response: FeatureContainer)=>{

                 console.log(featureContainer_response)
                 this.featureContainerService.featureContainers.push(featureContainer_response)

                 this.updateFeatureContainerListItem_featureContainer_ids(feature , featureContainer_response , user )

                 })
            }

            if ( user === null ){
               
                 this.featureContainerService.featureContainers.push(newFeatureContainer)

                 this.updateFeatureContainerListItem_featureContainer_ids(  feature ,  newFeatureContainer , user )

              
            }


      
      
      }
      
      
      updateFeatureContainerListItem_featureContainer_ids( feature: Feature ,  featureContainer: FeatureContainer , user: string ){
      
        const indexOf_featureContainerListItem_id = this.featureContainerListItemService.getFeatureContainerListItems().findIndex(x=> x.owner === user && x.type == 'personal' && x.name == featureContainer.name)
      
        const newFeatureContainerListItem_id = this.featureContainerListItemService.getFeatureContainerListItem(indexOf_featureContainerListItem_id)._id
        const newFeatureContainerListItemComponentName = this.featureContainerListItemService.getFeatureContainerListItem(indexOf_featureContainerListItem_id).componentName
        const newFeatureContainerListItemUIID = this.featureContainerListItemService.getFeatureContainerListItem(indexOf_featureContainerListItem_id).UIID
        const newFeatureContainerListItemOwner = user
        const newFeatureContainerListItemAllUsersAllowed = this.featureContainerListItemService.getFeatureContainerListItem(indexOf_featureContainerListItem_id).allUsersAllowed
        const newFeatureContainerListItemUserListAllowed = this.featureContainerListItemService.getFeatureContainerListItem(indexOf_featureContainerListItem_id).userListAllowed
        const newFeatureContainerListItemDateAndTime = this.featureContainerListItemService.getFeatureContainerListItem(indexOf_featureContainerListItem_id).dateAndTime
        const newFeatureContainerListItemType = this.featureContainerListItemService.getFeatureContainerListItem(indexOf_featureContainerListItem_id).type
        const newFeatureContainerListItemName = this.featureContainerListItemService.getFeatureContainerListItem(indexOf_featureContainerListItem_id).name
        const newFeatureContainerListItemFeatureContainer_ids = this.featureContainerListItemService.getFeatureContainerListItem(indexOf_featureContainerListItem_id).featureContainer_ids
      
        newFeatureContainerListItemFeatureContainer_ids.push(featureContainer._id)
        const newFeatureContainerListItemSelectedFeatureContainer_id = this.featureContainerListItemService.getFeatureContainerListItem(indexOf_featureContainerListItem_id).selectedFeatureContainer_id
      
        const newFeatureContainerListItem = new FeatureContainerListItem ( newFeatureContainerListItem_id, newFeatureContainerListItemComponentName, newFeatureContainerListItemUIID,  newFeatureContainerListItemOwner ,
            newFeatureContainerListItemAllUsersAllowed, newFeatureContainerListItemUserListAllowed, newFeatureContainerListItemDateAndTime, newFeatureContainerListItemType ,
            newFeatureContainerListItemName , newFeatureContainerListItemFeatureContainer_ids, newFeatureContainerListItemSelectedFeatureContainer_id   )
      
            if ( user !== null ){
                this.postComponentsHttpRequestService.httpUpdateComponent(user , 'featureContainerListItem', newFeatureContainerListItem )
                .subscribe(
                 (response) =>  { 
   
                 console.log(response)

                this.featureContainerListItemService.featureContainerListItems[indexOf_featureContainerListItem_id] = newFeatureContainerListItem
                console.log(newFeatureContainerListItem)

                this.featureContainerListItemService.adviseComponentListTheContainersHaveBeenUpdated(feature , featureContainer.name)

                  }
                )
            }

            if ( user === null ){

                this.featureContainerListItemService.featureContainerListItems[indexOf_featureContainerListItem_id] = newFeatureContainerListItem
                console.log(newFeatureContainerListItem)

                this.featureContainerListItemService.adviseComponentListTheContainersHaveBeenUpdated( feature , featureContainer.name)

                
            }
    
                      
       
      }


      createComponent_plus_FeatureComponent_and_AddToFeatureContainerListItem( componentList: FixText[], 
            component: FixText | WordInput | Tab , feature: Feature, user: string 
            ){

        /**
         * to each component and feature create featureContainer and then add featureContainer to the list 
         * of featureContainers_ids at featureContainerListItem  
         */
        // console.log( component , feature , user )

        // const newFeatureContainer_id = 'featureContai'+(this.featureContainerService.getFeatureContainers().length+1).toString()
        // const newFeatureContainerOwner = user
        // const newFeatureContainerAllUsersAllowed = false
        // const newFeatureContainerUserListAllowed = []
        // const newFeatureContainerDateAndTime = null 
        // const newFeatureContainerIsActive = true
        // const newFeatureContainerIsAccepted = true 
        // const newFeatureContainerName = feature.featureName
        // const newFeatureContainerComponentReference_id = feature.feature_id
        // const newnewFeatureContainerFeatures = []
        // newnewFeatureContainerFeatures.push(feature)
      
        // const newFeatureContainer = new FeatureContainer (newFeatureContainer_id, newFeatureContainerOwner, newFeatureContainerAllUsersAllowed,
        //       newFeatureContainerUserListAllowed, newFeatureContainerDateAndTime, newFeatureContainerIsActive, newFeatureContainerIsAccepted,
        //       newFeatureContainerName, newFeatureContainerComponentReference_id, newnewFeatureContainerFeatures )


        // const indexOf_featureContainerListItem_id = this.featureContainerListItemService.getFeatureContainerListItems().findIndex(x=> x.owner === user && x.type == 'personal' && x.name == featureContainer.name)
      
        // const newFeatureContainerListItem_id = this.featureContainerListItemService.getFeatureContainerListItem(indexOf_featureContainerListItem_id)._id
        // const newFeatureContainerListItemOwner = user
        // const newFeatureContainerListItemAllUsersAllowed = this.featureContainerListItemService.getFeatureContainerListItem(indexOf_featureContainerListItem_id).allUsersAllowed
        // const newFeatureContainerListItemUserListAllowed = this.featureContainerListItemService.getFeatureContainerListItem(indexOf_featureContainerListItem_id).userListAllowed
        // const newFeatureContainerListItemDateAndTime = this.featureContainerListItemService.getFeatureContainerListItem(indexOf_featureContainerListItem_id).dateAndTime
        // const newFeatureContainerListItemType = this.featureContainerListItemService.getFeatureContainerListItem(indexOf_featureContainerListItem_id).type
        // const newFeatureContainerListItemName = this.featureContainerListItemService.getFeatureContainerListItem(indexOf_featureContainerListItem_id).name
        // const newFeatureContainerListItemFeatureContainer_ids = this.featureContainerListItemService.getFeatureContainerListItem(indexOf_featureContainerListItem_id).featureContainer_ids
      
        // newFeatureContainerListItemFeatureContainer_ids.push(featureContainer._id)
        // const newFeatureContainerListItemSelectedFeatureContainer_id = this.featureContainerListItemService.getFeatureContainerListItem(indexOf_featureContainerListItem_id).selectedFeatureContainer_id
      
        // const newFeatureContainerListItem = new FeatureContainerListItem ( newFeatureContainerListItem_id, newFeatureContainerListItemOwner ,
        //     newFeatureContainerListItemAllUsersAllowed, newFeatureContainerListItemUserListAllowed, newFeatureContainerListItemDateAndTime, newFeatureContainerListItemType ,
        //     newFeatureContainerListItemName , newFeatureContainerListItemFeatureContainer_ids, newFeatureContainerListItemSelectedFeatureContainer_id   )
      



      }
}