<!-- <p>qr-code-generator-item works!</p> -->

<div id="print-section" >

<h2 style="color:  #3f51b5 ">code generator item </h2>
<qrcode [elementType]="'url'"  
        [qrdata]="myAngularQRcodeData" 
        [width]="256" 
        [errorCorrectionLevel]="'M'">
</qrcode>
</div>

<br>


<button class="btn btn-primary" 
        printSectionId="print-section" 
        ngxPrint>
        print qr code
</button> 

<br>
