import { Component, OnInit, Injectable, Inject, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef , MAT_DIALOG_DATA, _closeDialogVia } from '@angular/material/dialog';
import { DialogmanagerService } from '../../../../../../../../../../../services/other-components-services/dialogmanager.service' ;
import { SingleWord } from '../../single-word.model';
import { SingleWordDetail } from '../../single-word-detail.model';

import { SingleWordService } from '../../single-word.service';


import { Observable, Subscription } from 'rxjs';
import { SingleFeatureDetailResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/single-feature-results/single-feature-detail-result.model';
import { SingleWordDetailResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/single-feature-results/single-word-result/single-word-detail-result.model';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { Feature } from '../../../../feature.model';
import { FormService } from 'src/app/forms/form.service';

@Injectable()
export class MyService  {

}

@Component({
  selector: 'app-single-word-item',
  templateUrl: './single-word-item.component.html',
  styleUrls: ['./single-word-item.component.css']
})
export class SingleWordItemComponent implements OnInit , OnDestroy {

  user: string 

  // needed is for stuff like edit buttons to be visibles or not
  @Input() weAreInMultiFeatureEdit:boolean; 
  @Input() noNeeded : boolean  ; 
   singleWord : SingleWord;
   singleWordDetails : SingleWordDetail[]; 
  @Input() index_id : string ; 
  @Input() feature: Feature
  index: number ;


  @Output() deleteSingleWordEvent = new EventEmitter<void>()
    /**
   * parts for the emailText feature body creation
   */
  mailTextSingleFeatureBodyPart: string ;

  @Output() updateBothSingleFeatureResultAndTextPlusIndex = new EventEmitter<any>()

  @Input() isParagraphEdit: boolean ;

  @ViewChild('f') singleWordForm: NgForm

  singleFeatureDetailResult: SingleFeatureDetailResult;

  requestToFinalizeResultAgain: Subscription 

  valueChangesSubscription: Subscription


  constructor( private singleWordService: SingleWordService ,
               private dms: DialogmanagerService,
               private formService: FormService,
               public dialogRef: MatDialogRef<SingleWordItemComponent>,
               private userObjectUpdateService: UserObjectUpdateService , 
               @Inject(MAT_DIALOG_DATA) public editData: any) { }




  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    this.index = this.singleWordService.getSingleWords().findIndex(x => x._id === this.index_id) ;
    this.singleWord = this.singleWordService.getSingleWord(this.index);

    console.log('feature = ', this.feature, this.singleWord)


    
      this.requestToFinalizeResultAgain = this.formService.getRequestToFinalizeResultAgain().subscribe(()=> this.onSend())
    
      setTimeout(()=>{
        this.letTheseFunctionsSetRightAfterStart()
       
      },200)
    
  };

  letTheseFunctionsSetRightAfterStart(){
    this.onSend()
    this.valueChangesSubscription= this.singleWordForm.valueChanges.subscribe(() => { this.onSend()} );

  }



  openDialogSingleWordEdit(){

  }



  onSubmit(form: NgForm){

    console.log( this.singleWordService.getSingleWord(this.index))

    const newSingleFeatureDetailResultType = 'singleWordResult';
    const newSingleFeatureDetailResultName = this.singleWord.name;
    const newSingleWordDetailResultContent = form.value.singleWords.Detail[0].content ;

    const newSingleWordDetailResult = new SingleWordDetailResult (newSingleWordDetailResultContent);
    const newSingleFeatureDetailResult = new SingleFeatureDetailResult (newSingleFeatureDetailResultType, newSingleFeatureDetailResultName, newSingleWordDetailResult )
  
    console.log(newSingleFeatureDetailResult);

    this.mailTextSingleFeatureBodyPart = newSingleWordDetailResultContent
    this.singleFeatureDetailResult = newSingleFeatureDetailResult

    this.onUpdateSingleFeatureResultEvent()

  }

  onUpdateSingleFeatureResultEvent(){
    if( this.weAreInMultiFeatureEdit === false){

      const newFeatureDrop_Id = this.feature.dropId
      const newSingleFeatureDetailResult: SingleFeatureDetailResult = this.singleFeatureDetailResult
      const newMailTextSingleFeatureBodyPart: string = this.mailTextSingleFeatureBodyPart
      
      this.updateBothSingleFeatureResultAndTextPlusIndex.emit({ newFeatureDrop_Id, newSingleFeatureDetailResult, newMailTextSingleFeatureBodyPart   })
  
    }
  }


  onSend(){
    if( this.weAreInMultiFeatureEdit === false){
      console.log('is sending')
      this.singleWordForm.onSubmit(undefined);
    }
  }


   ngOnDestroy(){
   
     this.requestToFinalizeResultAgain.unsubscribe()
     this.valueChangesSubscription.unsubscribe()
   
   }

 onDeleteSingleWordItem(){
   this.deleteSingleWordEvent.emit()
 }


}
