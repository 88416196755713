

<div *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
</div>

<div *ngIf="!isLoading" >

<div *ngIf="noExistingForms">

    <!---------------- if it's desktop ------------------------------------------- -->
        <div class="col-12" *ngIf="!(isHandset$ | async)">
            <div class="row" style="height:120px">
            </div>

            <div class="d-flex justify-content-center">
                <mat-chip style="background-color: #3f51b5 ; color: white ; width:150px " 
                          class="pointer"
                          (click)="onRouteToCreateForm()">
                          START
               </mat-chip>   
            </div>
        
            
        </div>

    <!----------------  END if it's desktop ------------------------------------------- -->

    <!---------------- if it's Mobile we put the button at the bottom of the page  ------------------------------------------- -->
    <div class="col-12" *ngIf="isHandset$ | async">
        <div class="row">
            <br>
            <br>
            <br>
        </div>

        <div class="d-flex justify-content-center align-items-center">
                <h2 style="color:#3f51b5">no results atm </h2>
        </div>

        <div class="footer">
            <div class="d-flex justify-content-center">
              
                    <mat-chip style="background-color: #3f51b5 ; color: white ; width:150px " 
                              class="pointer"
                              (click)="onRouteToCreateForm()">
                        START
                    </mat-chip>
              
            </div>
            <div class="row" style="height:100px"></div>
        </div>
        
    </div>

<!----------------  END if it's Mobile ------------------------------------------- -->

    

   
</div>

<!-- class="scrollable" -->
<div *ngIf="formResults_Displaying.formResults_displayed.length > 0">
    <div *ngFor="let formResult of formResults_Displaying.formResults_displayed  ; let i=index">
    
            
        <div class="ParagraphComponentFrame">
            <app-form-result-index-item  *ngIf="user !== null"
                [isSelected]="formResult.isSelected"
                [formResult]="formResult"
                (click)="onSelectedFormResult(i)" >
            </app-form-result-index-item> 
            <!-- ONLY DIFFERENCE IS USER NULL  -->
    
            <app-form-result-index-item *ngIf="user == null" 
                [isSelected]="formResult.isSelected"
                [formResult]="formResult"
                (click)="onSelectedFormResult(i)" >
            </app-form-result-index-item> 

            <div trackVisibility (visibile)="onComponentVisible($event , i)" ></div>
        </div>
           
         
    
    </div>

</div>

<div class="row footer" *ngIf="(isHandset$ | async) && atLeastOneresultIsSelected">
    <div class="col">
        <div class="d-flex justify-content-center align-items-center" >
            <h1 (click)="onShowMobileResults()" align="center" style="color: #3f51b5" >
                  <svg style="color: #3f51b5" xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                    <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                  </svg>
            </h1>
        </div>
    </div>
    <div class="row" style="height: 60px;"></div>
</div>
  

    <div *ngIf="isLoadingAdditionalResults">
        <app-loading-spinner></app-loading-spinner>
    </div>

</div>






