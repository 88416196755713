<!-- <p>loading-spinner works!</p> -->

<div class="spinner-grow spinner-border m-5 text-primary "  role="status">
    <span class="visually-hidden">Loading...</span>
  </div>

  <div class="spinner-grow spinner-border m-5 text-secondary"  role="status">
    <span class="visually-hidden">Loading...</span>
  </div>

  <div class="spinner-grow spinner-border m-5 text-success"  role="status">
    <span class="visually-hidden">Loading...</span>
  </div>

  <div class="spinner-grow spinner-border m-5 text-danger"  role="status">
    <span class="visually-hidden">Loading...</span>
  </div>

  <div class="spinner-grow spinner-border m-5 text-warning"  role="status">
    <span class="visually-hidden">Loading...</span>
  </div>

  <div class="spinner-grow spinner-border m-5 text-info"  role="status">
    <span class="visually-hidden">Loading...</span>
  </div>

  <!-- <div class="spinner-grow spinner-border m-5 text-light" style="width: 3rem; height: 3rem;" role="status">
    <span class="visually-hidden">Loading...</span>
  </div> -->

  <div class="spinner-grow spinner-border m-5 text-dark"  role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
