import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inner-sum-edit',
  templateUrl: './inner-sum-edit.component.html',
  styleUrls: ['./inner-sum-edit.component.css']
})
export class InnerSumEditComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
