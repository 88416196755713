import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ParagraphTopic } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.model';
import { ParagraphTopicService } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.service';
import { FormGroup, FormControl} from '@angular/forms';

import { ParagraphIndexTopicHttpRequestService } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-http-request.service';
import { FormService } from 'src/app/forms/form.service';
import { IndexType } from 'src/app/forms/index-type.model';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { ParagraphTopicIndex } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-topic-index.model'; 
import { ParagraphTopicIndexService } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-topic-index.service';
import { ActivatedRoute , Params } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { Chat } from '../chat.model';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators'
import { ParagraphIndexTopicComponent } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-index-topic.component';
import { StoreParagraphItemsService } from 'src/app/services/get-and-store-components/store-paragraph-items.service';
import { ParagraphFeatureContainerService } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature-container.service';
import { ParagraphService } from 'src/app/forms/form-list/form-item/paragraph/paragraph.service';
import { Recipient } from 'src/app/forms/form-list/form-item/recipient/recipient.model';


@Component({
  selector: 'app-chat-paragraph-index-topic-list',
  templateUrl: './chat-paragraph-index-topic-list.component.html',
  styleUrls: ['./chat-paragraph-index-topic-list.component.css']
})
export class ChatParagraphIndexTopicListComponent implements OnInit , OnDestroy {

  user: string

  
  paragraphTopicIndex: ParagraphTopicIndex
  paragraphTopics: ParagraphTopic[];
  topicNameForm : FormGroup ;

  /**
   * [hidden]="(isHandset$ | async)=== false"
                           style="overflow: hidden;"
                           [sureIsMobile]="true"
                           [isPersonal]="isPersonal"
                           [isPublic]=  "isPublic"
                           [isShared]=  "isShared"
                           [isPreferred]= "isPreferred"
                           [aChatHasBeenSelected]="aChatHasBeenSelected"
                           [subscribeUpdatedChatSelected]="subscribeSelectedChat.asObservable()"
   */

  @Input() isPersonal: boolean 
  @Input() isPublic: boolean;
  @Input() isShared: boolean;
  @Input() isPreferred: boolean

  isPersonalIndex: boolean 
  
  /**
   *  just sure is mobile is just to differentiate some functions from happening at mobile or desktop 
   *  like opening the dialog or the bottom sheet 
   */
  @Input() sureIsMobile: boolean

  @Input() aChatHasBeenSelected: boolean 

  // selectedChatSubscription: Subscription;
  // @Input() subscribeUpdatedChatSelected: Observable<Recipient>
  // subscribeSelectedChat = new Subject<Recipient>()
  // @Input() chat: Chat

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  // -----------------------------------------------------------

  /** hide and show the add topic button  */
  hideButton = false ;

  /** boolean property used on ngIf to confront max topics number  */
  createButtonLimit : boolean ;

  /** limit number of Topics allowed to add  */
  topicsLimit = 19 ;

  trialParagraphTopics: ParagraphTopic[]
  isLoading: boolean = false
  error = true
  noParagraphTopicsYet = false 

  paragraphParameter: string 
  newParagraphIndexType: IndexType

  weAreInFormIndexTopicList: boolean = false 

  personalParagraphsHasBeenStored: boolean = false 
  sharedParagraphsHasBeenStored: boolean = false 
  preferredParagraphsHasBeenStored: boolean = false 

  updateOnUserEmail: Subscription 

  updatePublicParagraphTopicIndexOnRemoveItemSubscription: Subscription
  stopLoadingParagraphTopicIndexSubscription: Subscription 
  stopLoadingParagraphTopicFromStoreParagraphSubscription: Subscription 
  stopLoadingParagraphTopicFromParagraphServiceSubscription : Subscription 


  constructor(private paragraphTopicService: ParagraphTopicService,
              private paragraphTopicIndexService: ParagraphTopicIndexService,
              private userObjectUpdateService: UserObjectUpdateService ,
              private postComponentsHttpRequestService: PostComponentsHttpRequestService ,
              private breakpointObserver: BreakpointObserver , 
              private storeParagraphItemsService: StoreParagraphItemsService ,
              private formService: FormService , 
              private paragraphFeatureContainerService: ParagraphFeatureContainerService , 
              private paragraphService: ParagraphService



    ) {}

  ngOnInit(){

  this.user = this.userObjectUpdateService.userEmail
 
  this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
    this.user = updatedUserEmail
    console.log('updated USER = ', this.user)
    /**
     * should also update the data of this component with initial null data 
     */
  })

  this.isLoading = true 

  this.weAreInFormIndexTopicList = false 

  this.changeExactParagraphTopicIndexData()

 

  // this.selectedChatSubscription = this.subscribeUpdatedChatSelected.subscribe((currentRecipientOfReference: Recipient )=>{
  //   console.log( currentRecipientOfReference )
  //   this.subscribeSelectedChat.next(currentRecipientOfReference)
  // })

  this.updatePublicParagraphTopicIndexOnRemoveItemSubscription = this.paragraphTopicIndexService.updatePublicParagraphTopicIndexes
  .subscribe(()=> { this.updatePublicParagraphTopicIndexesAfterRemove()})

  this.stopLoadingParagraphTopicIndexSubscription = this.paragraphTopicIndexService.stopLoadingOnParagraphTopicIndex
  .subscribe(()=> { this.turnLoadingToFalse()  })

  this.stopLoadingParagraphTopicFromStoreParagraphSubscription = this.storeParagraphItemsService.stopLoadingParagraphTopicIndex
  .subscribe(()=> { this.turnLoadingToFalse() })

  this.stopLoadingParagraphTopicFromParagraphServiceSubscription = this.paragraphService.stopLoadingParagraphTopicIndex
  .subscribe(()=> { this.turnLoadingToFalse()})

  }


  changeExactParagraphTopicIndexData(){

    if (this.isPersonal === true  ) { this.paragraphParameter = 'personal-paragraphs', this.isPersonalIndex = true , this.newParagraphIndexType = new IndexType ( false, true, false, false ) }
    if (this.isShared === true    ) { this.paragraphParameter = 'shared-paragraphs' , this.isPersonalIndex = false , this.newParagraphIndexType = new IndexType ( false, false, true, false )}
    if (this.isPreferred === true ) { this.paragraphParameter = 'preferred-paragraphs',  this.isPersonalIndex = false , this.newParagraphIndexType = new IndexType ( false, false, false, true )}
    if (this.isPublic === true    ) { this.paragraphParameter = 'public-paragraphs' , this.isPersonalIndex = false , this.newParagraphIndexType = new IndexType ( true, false, false, false ) }
  
    this.paragraphTopicIndex = this.paragraphTopicIndexService.getParagraphTopicIndex_by_type(this.paragraphParameter)
  
    console.log(this.paragraphTopicIndex, this.paragraphTopicIndex.paragraphTopic_ids)
    
    this.topicNameForm = new FormGroup({
      name: new FormControl ('') ,   
        })
    
    if ( this.paragraphTopicService.getParagraphTopics().length < this.topicsLimit ){
      this.createButtonLimit = true ;
    } else {
      this.createButtonLimit = false ;
    };


    if ( this.paragraphParameter === 'personal-paragraphs' ){
      this.newParagraphIndexType = new IndexType ( false, true, false, false ), 
      this.isPersonal = true
      this.isPublic = false 
      //  the right http call to add paragraphFeatures with childs 
      if ( this.user !== null){
   
        if ( this.personalParagraphsHasBeenStored === true ){
             this.turnLoadingToFalse()
        }
        if ( this.personalParagraphsHasBeenStored === true ){
          this.formService.onChangeParagraphIndexType(this.newParagraphIndexType)
          this.isLoading = false
        }
        if ( this.personalParagraphsHasBeenStored === false ){
          this.storeParagraphItemsService.getAndStorePersonalParagraphItemsOfTypeOfIndex(this.user)
          this.personalParagraphsHasBeenStored = true
          this.formService.onChangeParagraphIndexType(this.newParagraphIndexType)
      }
      }  

      if ( this.user === null ){ 
        this.formService.onChangeParagraphIndexType(this.newParagraphIndexType)
        this.isLoading = false  }
     
 } 

 if ( this.paragraphParameter === 'shared-paragraphs'   ){
      this.newParagraphIndexType = new IndexType ( false, false, true, false ), 
      this.isPersonal = false 
      this.isPublic = false 

/**
* find and advise paragrap[hFeatureContainer which paragraphTopics we are taking as reference , 
* to be able to compare if paragrFeatureCiontainers already exist
*/
const paragraphTopicIndexOfReference: ParagraphTopicIndex = this.paragraphTopicIndexService.getParagraphTopicIndex_by_type(this.paragraphParameter)
console.log(paragraphTopicIndexOfReference)
const paragraphTopicsOfReference: ParagraphTopic[] = []
for ( let j=0; j<paragraphTopicIndexOfReference.paragraphTopic_ids.length ; j++ ){
    paragraphTopicsOfReference.push(this.paragraphTopicService.getParagraphTopicBy_id(paragraphTopicIndexOfReference.paragraphTopic_ids[j]))
}
console.log('EXISTING PUBLIC PARAGRAPH TOPICS OF REFERENCE',paragraphTopicsOfReference)

this.paragraphFeatureContainerService.paragraphTopicsOfReference = paragraphTopicsOfReference

    if ( this.user !== null ){
      // check if shared paragraphFeature has been already put in store 
      if ( this.sharedParagraphsHasBeenStored === false ){

        this.storeParagraphItemsService.getAndStoreSharedParagraphItemsOfTypeOfIndex(this.user, 'share')
         this.formService.onChangeParagraphIndexType(this.newParagraphIndexType)
      }
      if ( this.sharedParagraphsHasBeenStored === true ){
        this.formService.onChangeParagraphIndexType(this.newParagraphIndexType)
        this.turnLoadingToFalse()
      }
    }

    if ( this.user === null ){ 
      this.formService.onChangeParagraphIndexType(this.newParagraphIndexType)
      this.isLoading = false  }
 }

 if ( this.paragraphParameter === 'preferred-paragraphs'){ 
      this.newParagraphIndexType = new IndexType ( false, false, false, true ), 
      this.isPersonal = false 
      this.isPublic = false 

// /**
// * find and advise paragrap[hFeatureContainer which paragraphTopics we are taking as reference , 
// * to be able to compare if paragrFeatureCiontainers already exist
// */
const paragraphTopicIndexOfReference: ParagraphTopicIndex = this.paragraphTopicIndexService.getParagraphTopicIndex_by_type(this.paragraphParameter)
console.log(paragraphTopicIndexOfReference)
const paragraphTopicsOfReference: ParagraphTopic[] = []
for ( let j=0; j<paragraphTopicIndexOfReference.paragraphTopic_ids.length ; j++ ){
    paragraphTopicsOfReference.push(this.paragraphTopicService.getParagraphTopicBy_id(paragraphTopicIndexOfReference.paragraphTopic_ids[j]))
}
console.log('EXISTING PUBLIC PARAGRAPH TOPICS OF REFERENCE',paragraphTopicsOfReference)

this.paragraphFeatureContainerService.paragraphTopicsOfReference = paragraphTopicsOfReference

/**
 * now call all the preferred paragraphFeatures with childs and scompose 
 */
if ( this.user !== null ){
  // check if preference paragraphFeature has been already put in store 
  if ( this.preferredParagraphsHasBeenStored === false ){

    this.storeParagraphItemsService.getAndStoreSharedParagraphItemsOfTypeOfIndex(this.user, 'preference')
    this.preferredParagraphsHasBeenStored = true // these should be changed only once paragraphs have been stored 
    this.formService.onChangeParagraphIndexType(this.newParagraphIndexType)
  
  }
  if ( this.preferredParagraphsHasBeenStored === true ){
    this.formService.onChangeParagraphIndexType(this.newParagraphIndexType)
    this.isLoading = false
  }

}

if ( this.user === null ){ 
  this.isLoading = false
  this.formService.onChangeParagraphIndexType(this.newParagraphIndexType)
}


 } 

 if ( this.paragraphParameter === 'public-paragraphs'   ){ 

  this.newParagraphIndexType = new IndexType ( true, false, false, false ), 
  this.isPersonal = false 
  this.isPublic = true 

/**
* find and advise paragraphFeatureContainer which paragraphTopics we are taking as reference , 
* to be able to compare if paragrFeatureContainers already exist
*/
const paragraphTopicIndexOfReference: ParagraphTopicIndex = this.paragraphTopicIndexService.getParagraphTopicIndex_by_type(this.paragraphParameter)
console.log(paragraphTopicIndexOfReference)
const paragraphTopicsOfReference: ParagraphTopic[] = []
for ( let j=0; j<paragraphTopicIndexOfReference.paragraphTopic_ids.length ; j++ ){
  paragraphTopicsOfReference.push(this.paragraphTopicService.getParagraphTopicBy_id(paragraphTopicIndexOfReference.paragraphTopic_ids[j]))
}
console.log('EXISTING PUBLIC PARAGRAPH TOPICS OF REFERENCE',paragraphTopicsOfReference)
this.paragraphFeatureContainerService.paragraphTopicsOfReference = paragraphTopicsOfReference

    this.formService.onChangeParagraphIndexType(this.newParagraphIndexType)
    this.isLoading = false  }


  
    // setTimeout(()=>{
    //   this.isLoading = false
    // },300 )

  }


  turnLoadingToFalse(){
    this.isLoading = false 
  }

  onAddEmailSearch($event){ // call the service to search for paragraphFeatureIndex publicly shared from this user 
    console.log('looking for paragrapgFeatureIndex from = ', $event )
    this.isLoading = true 
    this.paragraphTopicIndexService.foundEmailList.push($event)
    // this.storeParagraphFeaturesService.getAndStorePublicParagraphFeaturesWithChildsLastSeqOwner('public', 'paragraphFeatureIndex', this.user, '10', $event  )
    this.storeParagraphItemsService.getAndStorePublicParagraphsByObjectTypeOwnerAndUser( 'paragraph' , $event , this.user )
  }

  onDeleteEmailOnSearch($event: string ){
    console.log($event)

  }





   onHideButton(){
    this.hideButton = !this.hideButton;
 /** set time limit to write the new name */
    setTimeout(() => {
      this.hideButton = false ;
      this.topicNameForm = new FormGroup({
        name: new FormControl ('') ,   
          })
    }, 10000);

    if ( this.paragraphTopicService.getParagraphTopics().length < this.topicsLimit ){
      this.createButtonLimit = true ;
    } else {
      this.createButtonLimit = false ;
    };
  }


  onSubmit(){

    // const new_id = (this.paragraphTopicService.getParagraphTopics().length+1).toString() ; 
    const new_id = null ;
    const newComponentName = 'paragraphTopic'
    const newUIID = 'paragraphTopic-'+(this.paragraphTopicService.getParagraphTopics().length+1).toString()
    const newOwner = this.user ;
    const newAllUsersAllowed = false ;
    const newUserListAllowed = [];
    const newDateAndTime = null ;
    const newIsAccepted = true ;
    const newIsArchived = false ;
  
    // const newId = this.paragraphTopicService.getParagraphTopics().length+1;
    const newName = this.topicNameForm.value.name ;

    /** we leave the 3rd property empty , tobe added on the fly */
    
    const newParagraphTopic = new ParagraphTopic ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed, 
                                                   newDateAndTime, newIsAccepted, newIsArchived ,
                                                   newName , [] );

    /**
     * calls on HTTP service to add and retrieve new list
     */
   // this.paragraphIndexTopicHttpRequestService.httpStoreFetchParagraphTopic(newParagraphTopic)
   // this.onFetchParagraphTopics()

   this.postComponentsHttpRequestService.httpStoreComponent(this.user, 'paragraphTopic', newParagraphTopic ).subscribe((response: ParagraphTopic)=> {
    console.log(response)

    this.paragraphTopicService.addParagraphTopic(response);

    /**
     * now we need to update the paragraphTopicIndex with the new paragraphTopic 
     * export class ParagraphTopicIndex {

    constructor( public _id: string , 
                 public owner: string, 
                 public allUsersAllowed: boolean , 
                 public userListAllowed: string[] ,
                 public type: string , // just the 4 : personal / shared/ preferred / public
                 public paragraphTopic_ids: string[]
               ){}
}
     */
        const new_id = this.paragraphTopicIndex._id
        const newComponentName3 = this.paragraphTopicIndex.componentName
        const newComponentUIID3 = this.paragraphTopicIndex.UIID
        const newOwner = this.user
        const newAllUsersAllowed = this.paragraphTopicIndex.allUsersAllowed
        const newUserListAllowed = this.paragraphTopicIndex.userListAllowed
        const newDateAndTime = this.paragraphTopicIndex.dateAndTime
        const newType = this.paragraphTopicIndex.type

        const newParagraphTopic_ids = this.paragraphTopicIndex.paragraphTopic_ids
        newParagraphTopic_ids.push(response._id)

        const newParagraphTopicIndex = new ParagraphTopicIndex ( new_id, newComponentName3, newComponentUIID3, newOwner, newAllUsersAllowed, newUserListAllowed,
                                            newDateAndTime, newType, newParagraphTopic_ids)


        this.postComponentsHttpRequestService.httpUpdateComponent(this.user, 'paragraphTopicIndex', newParagraphTopicIndex)
                                             .subscribe((res)=>{
                                              console.log(res)

        const newIndex = this.paragraphTopicIndexService.getParagraphTopicIndexes().findIndex(x=> x._id === this.paragraphTopicIndex._id)
        this.paragraphTopicIndexService.paragraphTopicIndexes[newIndex] = newParagraphTopicIndex

       })



   })

   

    this.topicNameForm = new FormGroup({
    name: new FormControl ('') ,   })
    this.paragraphTopics = this.paragraphTopicService.getParagraphTopics();

    console.log(this.paragraphTopics)
    
    this.hideButton = !this.hideButton ;
  }

  updatePublicParagraphTopicIndexesAfterRemove(){
    // this.route.params.subscribe(
    //   (params: Params)=> {
    //   this.isLoading = true 
    //   this.paragraphParameter = params['paragraphType']
      this.changeExactParagraphTopicIndexData()
    //   }
    // )
  }

  ngOnDestroy(): void {
      // this.selectedChatSubscription.unsubscribe()
      this.updateOnUserEmail.unsubscribe()
      this.stopLoadingParagraphTopicIndexSubscription.unsubscribe()
      this.updatePublicParagraphTopicIndexOnRemoveItemSubscription.unsubscribe()
      this.stopLoadingParagraphTopicFromStoreParagraphSubscription.unsubscribe()
      this.stopLoadingParagraphTopicFromParagraphServiceSubscription.unsubscribe()
  }

}

