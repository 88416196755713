<!-- <p>calculator-mix-table-result works!</p> -->

<br>
<div class="row">
    <div class="col-xs-12">

            <table  class="full-width" >
                <tbody>
               
                <tr *ngFor="let row of calculatorMixTableDetailResult.calculatorMixTableDescriptionDetailResult; let i=index">

                                            <td>
                                                <mat-form-field style="width: 100px; color: black" appearance="outline">
                                                    <mat-label> Q.ty </mat-label>
                                                    <input
                                                     disabled
                                                     matInput
                                                     ngModel="{{row.quantity}}"
                                                     type="number" >

                                                </mat-form-field>
                                            </td>
                                          
                                            <td>
                                                <mat-form-field style="width: 100px; color: black" appearance="outline">
                                                    <mat-label> category </mat-label>
                                                    <input
                                                    disabled
                                                    matInput
                                                    ngModel="{{row.category}}"
                                                    type="text" >
                                                  
                                                   
                                                </mat-form-field>
                                            </td>
                                         
                                            <td>
                                                <mat-form-field style="width: 500px ; color: black" appearance="outline">
                                                    <mat-label> description </mat-label>
                                                    <input
                                                    disabled
                                                    matInput
                                                    ngModel="{{row.description}}"
                                                    type="text" >
                                                   
                                                </mat-form-field>
                                            </td>
                                       
                                        <td>
                                            <mat-form-field style="width: 100px; color: black" appearance="outline">
                                                <mat-label> PL </mat-label>
                                                <input
                                                disabled
                                                matInput
                                                ngModel="{{row.priceList}}"
                                                type="number" >
                                                
                                            </mat-form-field>
                                        </td>
                                      
                                        <td>
                                            <mat-form-field style="width: 50px; color: black" appearance="outline">
                                                <mat-label> discount </mat-label>
                                                <input
                                                     disabled
                                                     matInput
                                                     ngModel="{{row.discount}}"
                                                     type="number" >
                                                    
                                            </mat-form-field>

                                        </td>
                                     
                                        <td>
                                            <mat-form-field style="width: 100px; color: black" appearance="outline">
                                                <mat-label> net price </mat-label>
                                                <input
                                                     disabled
                                                     matInput
                                                     ngModel="{{row.netPrice}}"
                                                     type="number" >
                                                    
                                            </mat-form-field>

                                        </td>
                                     
                                        <td>
                                            <mat-form-field style="width: 150px; color: black" appearance="outline">
                                                <mat-label> sub total </mat-label>
                                                <input
                                                     disabled
                                                     matInput
                                                     ngModel="{{row.subTotal}}"
                                                     type="number" >
                                                   
                                            </mat-form-field>

                                        </td>

            
                        
     
             
            </tr>
                </tbody>
        

            <tfoot>

            <tr>
                <td>
                    <mat-form-field style="width: 150px; color: black" appearance="outline">
                        <mat-label> total </mat-label>
                        <input
                            disabled
                            matInput
                            ngModel="{{calculatorMixTableDetailResult.total}}"
                            type="number" >
                       
                    </mat-form-field>

                </td>
            </tr>
            </tfoot>

            </table>

            <br>


        <!-- </form> -->

    </div>
</div>
