import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LayoutModule } from "@angular/cdk/layout";
import { LoadingSpinnerComponent } from "./loading-spinner.component";
import { SingleLoadingSpinnerComponent } from './single-loading-spinner/single-loading-spinner.component';
import { PopUpErrorDialogComponent } from './pop-up-error-dialog/pop-up-error-dialog.component';
import { TripleLoadingSpinnerComponent } from './triple-loading-spinner/triple-loading-spinner.component';
import { IsTakingFarTooLongComponent } from './is-taking-far-too-long/is-taking-far-too-long.component';
import { QuickCommunicationPopUpComponent } from './quick-communication-pop-up/quick-communication-pop-up.component';
import { FallingObjectComponent } from './falling-object/falling-object.component';
import { DuplicateFeatureParagraphFormComponent } from './duplicate-feature-paragraph-form/duplicate-feature-paragraph-form.component';
import { DuplicateFeatureParagraphFormBottomSheetComponent } from './duplicate-feature-paragraph-form-bottom-sheet/duplicate-feature-paragraph-form-bottom-sheet.component';
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { MaterialDesignModule } from "src/app/material-design/material-design.module";

@NgModule({
    declarations: [
        LoadingSpinnerComponent,
        SingleLoadingSpinnerComponent,
        PopUpErrorDialogComponent,
        TripleLoadingSpinnerComponent,
        IsTakingFarTooLongComponent,
        QuickCommunicationPopUpComponent,
        FallingObjectComponent,
        DuplicateFeatureParagraphFormComponent,
        DuplicateFeatureParagraphFormBottomSheetComponent
       
    ],

    exports: [
        LoadingSpinnerComponent,
        SingleLoadingSpinnerComponent,
        PopUpErrorDialogComponent,
        TripleLoadingSpinnerComponent,
        IsTakingFarTooLongComponent,
        FallingObjectComponent,
        DuplicateFeatureParagraphFormComponent ,
        DuplicateFeatureParagraphFormBottomSheetComponent
    ],

    imports: [
        LayoutModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialDesignModule
       
    ]
})


export class AuxiliaryComponentsModule{}