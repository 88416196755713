import { Component, OnInit, Inject , ViewEncapsulation} from '@angular/core';
import { Contact } from '../contact.model';
import { ContactService } from '../contact.service';
import { MatDialogRef , MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup , FormControl} from '@angular/forms';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { ContactDetails } from '../contact-details.model';


@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ContactListComponent implements OnInit {

  user: string 

  weAreOnShareParagraphFormIndex= false

  contact: Contact 
  contactDetails: ContactDetails[]
  isContactEditMode: false
  isSelected= false

  newContactName: FormGroup



  constructor ( public dialogRef: MatDialogRef<ContactListComponent>,
                private contactService: ContactService,
                private postComponentsHttpRequestService: PostComponentsHttpRequestService, 
                private userObjectUpdateService: UserObjectUpdateService ,
                @Inject(MAT_DIALOG_DATA) public editData: any) { }

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

    this.contact = this.contactService.getContacts()[0]
    this.contactDetails = this.contact.contactDetails

    this.newContactName = new FormGroup ({
      contactName: new FormControl ('')
    })


  }

  onAddContactDetail(){

    // const new_id= null ;
    // const newComponentName = 'contact'
    // const newUIID = 'contact-'+ (this.contactService.getContacts().length+1).toString()
    // const newOwner = this.user 
    // const newAllUsersAllowed = false
    // const newUserListAllowed = []
    // const newDateAndTime = null 
    // const newContactName = '';
    // const newContactEmail = this.newContactName.value.contactName
    // const newIsAConnection = false ;
    // const newIsBlocked = false;
    // const newIsBeenReported = false; 

    // const newContact = new Contact ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed, newDateAndTime,  newContactName, newContactEmail, 
    //                                  newIsAConnection, newIsBlocked, newIsBeenReported )
    const new_id= this.contact._id;
    const newComponentName = 'contact'
    const newUIID = this.contact.UIID
    const newOwner = this.contact.owner
    const newAllUsersAllowed = this.contact.allUsersAllowed
    const newUserListAllowed = this.contact.userListAllowed
    const newDateAndTime = this.contact.dateAndTime 
    const newUpdateDateAndTime = null
    const newContactDetails = this.contact.contactDetails

    const newContactName = '';
    const newContactEmail = this.newContactName.value.contactName
    const newContactTelNumber = ''
    const newIsAConnection = false ;
    const newIsBlocked = false;
    const newIsBeenReported = false; 

    const newContactDetail: ContactDetails = new ContactDetails( newContactName, newContactEmail, newContactTelNumber, newIsAConnection, newIsBlocked, newIsBeenReported)
    
    newContactDetails.push(newContactDetail)

    const newContact = new Contact ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed, newDateAndTime, newUpdateDateAndTime, newContactDetails )

    this.contact = newContact

    /**
     * we should check if the contact already exist with a FOR LOOP before adding it 
     */

//  this.postComponentsHttpRequestService.httpStoreComponent(this.user, 'contact', newContact )
//        .subscribe((response: Contact)=> {
//         console.log(response)

//        })

    this.newContactName = new FormGroup ({
      contactName: new FormControl ('')
    })
  }




  onSelectedContact(){
    this.isSelected = true
  }

  onDeselectContact(){
    this.isSelected = false
  }

 




}
