<!-- <p>word-input-result works!</p> -->


<div class="row">
    <div class="col-xs-12">

       <form #f="ngForm">
          <div> 

          <div class="row" >
            <div class="col-xs-12">

              <div ngModelGroup="wordInputDetailResult"> 
                  <table class="full-width" cellspacing="0">
                    <tr>

                           <div class="form-group">
                                 <td *ngFor="let detail of wordInputDetailResults; let i=index"> 
                                   <div [ngModelGroup]="i" #details="ngModelGroup">
                                       <mat-form-field class="full-width">
                                       <mat-label [(innerText)]="detail.wordInputResultLabel"></mat-label> 
                                       <input 
                                       disabled
                                       matInput 
                                       ngModel="{{detail?.wordInputResultContent}}"
                                       name="content" >
            
                                       <mat-hint>  {{ detail?.wordInputResultHint }} </mat-hint>

                                       </mat-form-field>
                                   </div>
                                </td> 
                           </div>  

                    </tr>
                 </table>
                </div>

            </div>
          </div>
          
         </div>
       </form>

    </div>
</div>
