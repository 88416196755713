import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Paragraph } from "src/app/forms/form-list/form-item/paragraph/paragraph.model";
import { ParagraphService } from "src/app/forms/form-list/form-item/paragraph/paragraph.service";
import { Form } from "src/app/forms/form.model";
import { GetComponentsHttpRequestService } from "../gets-services/get-components-http-request.sevice";
import { DialogmanagerService } from "../other-components-services/dialogmanager.service";
import { StoreBaseComponentsService } from "./store-base-components.service";
import { StoreParagraphItemPlusChildsConfirmationService } from "./store-paragraph-item-plus-childs-confirmation.service";
import { InitialNullSetupService } from "../starting-services/initial-null-setup.service";

@Injectable ({
    providedIn: 'root' 
})

export class StoreParagraphItemsService{

    sharedCall_hasBeenMade: boolean = false 
    latestSharedParagraphsList: string[] = []
    paragraphsListToremove: string[] = []

    constructor ( 
                  private storeBaseComponentsService: StoreBaseComponentsService , 
                  private paragraphService: ParagraphService , 
                  private getComponentsHttpRequestService: GetComponentsHttpRequestService ,
                  private storeParagraphItemPlusChildsConfirmationService: StoreParagraphItemPlusChildsConfirmationService ,
                  private dms: DialogmanagerService ,
                  private initialNullSetupService: InitialNullSetupService

                ){}

    singleParagraphChildsToAddToFormEdit = new Subject<Paragraph>()
    checkIfWeNeedToBuildSharedParagraphFeatureContainers = new Subject<any>()
    nowCanReconstructFormItemAndStopLoading_onFormItem = new Subject<any>() // form + typeOfIndex
    stopLoadingParagraphTopicIndex = new Subject<void>()

    getAndStorePersonalParagraphItemsOfTypeOfIndex(userEmail: string){
        this.getComponentsHttpRequestService.httpGetComponentsByType(userEmail , 'paragraph')
            .subscribe((paragraphItemsResults: any)=>{

                console.log(paragraphItemsResults)
                const paragraphsResults = paragraphItemsResults.paragraph 
                this.rebuildParagraphItemsAndStoreToParagraphService(paragraphsResults , 'personal') 

            })
    }
    // getting paragraphItemsOfTypeOfIndexFrom paragraphTopicIndexList
    getAndStoreSharedParagraphItemsOfTypeOfIndex(userEmail: string , typeOfIndex: string ){

        if ( this.sharedCall_hasBeenMade === false ){
        
        this.sharedCall_hasBeenMade = true 
        setTimeout(() => {
            this.sharedCall_hasBeenMade = false 
        }, 300);

        this.getComponentsHttpRequestService.httpGetUserComponentsByTypeOfIndexAndObjectType( userEmail , typeOfIndex , 'paragraph')
            .subscribe((paragraphItemsResults: any)=> {
        
                console.log(paragraphItemsResults)
                const paragraphsResults = paragraphItemsResults
                this.rebuildParagraphItemsAndStoreToParagraphService(paragraphsResults , typeOfIndex)

            })
        }
    }

    getAndStorePublicParagraphsByObjectTypeOwnerAndUser( objectType: string , owner: string , userEmail: string){

        this.getComponentsHttpRequestService.httpGetPublicComponentsByTypeOfObjectAndOwnerAndUser(objectType, owner , userEmail)
        .subscribe((publicParagraphItemResults: any )=> {
            console.log(publicParagraphItemResults)

            const paragraphItemsResults = publicParagraphItemResults

            this.rebuildParagraphItemsAndStoreToParagraphService(paragraphItemsResults , 'public')

        })

    }

    rebuildParagraphItemsAndStoreToParagraphService( paragraphsResults: any , typeOfIndex: string ){


        if ( typeOfIndex === 'personal' && paragraphsResults.length === 0  ){


            this.stopLoadingParagraphTopicIndex.next()

        }
         if ( typeOfIndex === 'share' || typeOfIndex === 'preference' || typeOfIndex === 'public' ){
        
            if ( paragraphsResults.length === 0 ){
                if (typeOfIndex === 'public'){
                    this.dms.QuickCommunicationPopUpComponent({
                        quickComment: 'no public paragraphs for this user , atm'
                    })
                }
            
            /**
             * make a remove list of paragraph function that remove the list which are not present anymore 
             */
                
            this.stopLoadingParagraphTopicIndex.next()

            }

        
        }


        const paragraphsItemsToStore: Paragraph[] = []
        let counter: number = 0 

      for ( let i=0; i<paragraphsResults.length ; i++ ){

      const new_id = paragraphsResults[i]._id
      const newComponentName = paragraphsResults[i].componentName
      const newUIID = paragraphsResults[i].UIID
      const newOwner = paragraphsResults[i].owner
      const newDateAndTime = paragraphsResults[i].dateAndTime 
      const newUpdateDateAndTime = paragraphsResults[i].updatedDateAndTime
      const newIsRemoved = paragraphsResults[i].isRemoved
      const newAllUsersAllowed = paragraphsResults[i].allUsersAllowed
      const newUserListAllowed = paragraphsResults[i].userListAllowed
      const newCanBeReshared = paragraphsResults[i].canBeResharedByOthers
      const newParagraphName = paragraphsResults[i].name 
      const newParagraphDesc = paragraphsResults[i].desc 
      const newFeatures = paragraphsResults[i].features 
      const newFeatures_id_list = paragraphsResults[i].feature_id_list
  
      const newParagraph = new Paragraph (  new_id,
                                            newComponentName,
                                            newUIID,
                                            newOwner,
                                            newDateAndTime,
                                            newUpdateDateAndTime,
                                            newIsRemoved ,
                                            newAllUsersAllowed,
                                            newUserListAllowed,
                                            newCanBeReshared , 
                                            newParagraphName, 
                                            newParagraphDesc, 
                                            newFeatures, 
                                            newFeatures_id_list );

        paragraphsItemsToStore.push(newParagraph)
        counter = counter+1 

        if ( counter === paragraphsResults.length ){
         
            console.log(paragraphsItemsToStore)
            this.paragraphService.addParagraphsToList(paragraphsItemsToStore)
            

            if (typeOfIndex === 'share' || typeOfIndex === 'public' ){
                const paragraphsItemsToCheckPlusIndex: any = { paragraphsItemsToStore , typeOfIndex }
                this.checkIfWeNeedToBuildSharedParagraphFeatureContainers.next(paragraphsItemsToCheckPlusIndex) 
                // to send to paragraphFeatureContainer to check if we have to create new ParagraphFeatureContainers 
                // + paragraphTopics to add at index 
            }

            if (typeOfIndex === 'preference' ){
                this.stopLoadingParagraphTopicIndex.next()
            }

        }

      }

    }

    /**
     * call personal paragraphComponent + childs and store right before add them to formEdit
     */
    getAndStorePersonalParagraphAndChildsComponents( userEmail: string , component_id: string ){

        this.getComponentsHttpRequestService.httpGetUserComponentBy_id( userEmail , component_id )
            .subscribe((paragraphItemWithChildsResult: any )=> {
                console.log(paragraphItemWithChildsResult)

                this.storeParagraphItemPlusChildsConfirmationService.scomposeParagraphItemPlusChildsComponent_idsList(paragraphItemWithChildsResult)

                this.unpackAndDistributeSingleParagraphAndChildsComponents(paragraphItemWithChildsResult)

            })

    }

     /**
     * call shared/preferred/public paragraphComponent + childs and store right before add them to formEdit
     */
    adviseParagraphWithChilds_NotAvailableAnymore = new Subject<void>()
    getAndStoreSharedParagraphAndChildsComponents(userEmail: string , typeOfIndex: string , component_id: string  ){

        this.getComponentsHttpRequestService.getComponentWithChildsByUserAndTypeOfIndexAndComponent_id( userEmail, typeOfIndex, component_id )
            .subscribe((paragraphItemWithChildsResult: any[] )=> {
                console.log(paragraphItemWithChildsResult)

                if ( paragraphItemWithChildsResult.length > 0 ){
                     const singleParagraphItemWithChildsResult: any = paragraphItemWithChildsResult[0]
                     this.storeParagraphItemPlusChildsConfirmationService.scomposeParagraphItemPlusChildsComponent_idsList(singleParagraphItemWithChildsResult)
                     this.unpackAndDistributeSingleParagraphAndChildsComponents(singleParagraphItemWithChildsResult)
                }
                if ( paragraphItemWithChildsResult.length == 0){
                     console.log('remove the paragraph from service and initial service , the paragraph is not available anymore ')
                     const indexOfParagraph = this.paragraphService.getParagraphs().findIndex(x=> x._id === component_id)
                     this.paragraphService.paragraphs.splice(indexOfParagraph, 1)
                     const indexOfInitialNullParagraph = this.initialNullSetupService.initialNullParagraphs.findIndex(x=> x._id === component_id )
                     this.initialNullSetupService.initialNullParagraphs.splice(indexOfInitialNullParagraph, 1)
                     this.adviseParagraphWithChilds_NotAvailableAnymore.next()
                     this.dms.QuickCommunicationPopUpComponent({
                        quickComment: 'this paragraph is not available anymore'
                    })
                }
                
            }, error => {
                // check if the paragraph exist on srevice storage or 
            })

    }


    unpackAndDistributeSingleParagraphAndChildsComponents(paragraphPlusChilds: any ){

        const featureTypeList: string[] = [] // help define what kind of paragraphChild we have to reconstruct 
        const paragraphsChilds: any = []

        const newChildParagraph_id = paragraphPlusChilds._id
        const newComponentName = paragraphPlusChilds.componentName
        const newUIID = paragraphPlusChilds.UIID
        const newChildParagraphOwner = paragraphPlusChilds.owner
        const newChildParagraphDateAndTime = paragraphPlusChilds.dateAndTime
        const newChildParagraphUpdateDateAndTime = paragraphPlusChilds.updatedDateAndTime
        const newChildParagraphIsRemoved =  paragraphPlusChilds.isRemoved
        const newChildParagraphAllUsersAllowed = paragraphPlusChilds.allUsersAllowed
        const newChildParagraphUserListAllowed = paragraphPlusChilds.userListAllowed
        const newChildParagraphCanBeReshared = paragraphPlusChilds.canBeResharedByOthers
        const newChildParagraphParagraphName = paragraphPlusChilds.name
        const newChildParagraphParagraphDesc = paragraphPlusChilds.desc 
        const newChildParagraphFeatures = paragraphPlusChilds.features 
        const newChildParagraphFeatures_id_list = paragraphPlusChilds.feature_id_list
    
        const newParagraph = new Paragraph (  newChildParagraph_id,
                                              newComponentName,
                                              newUIID,
                                              newChildParagraphOwner,
                                              newChildParagraphDateAndTime,
                                              newChildParagraphUpdateDateAndTime,
                                              newChildParagraphIsRemoved ,
                                              newChildParagraphAllUsersAllowed,
                                              newChildParagraphUserListAllowed,
                                              newChildParagraphCanBeReshared, 
                                              newChildParagraphParagraphName, 
                                              newChildParagraphParagraphDesc, 
                                              newChildParagraphFeatures, 
                                              newChildParagraphFeatures_id_list );

            for ( let i=0; i<paragraphPlusChilds.features.length ; i++ ){

                if ( paragraphsChilds.findIndex(x=> x._id === paragraphPlusChilds.paragraphChild_list[i] ) === -1 ){
                                      featureTypeList.push(paragraphPlusChilds.features[i].featureName )
                                      paragraphsChilds.push(paragraphPlusChilds.paragraphChild_list[i] )
                }
                

                if ( i === paragraphPlusChilds.features.length-1 ){

                    /**
                     * need to add is this is coming from paragraph call or from formItem call , so that we know 
                     * what storagePlusChildsConfrimation we have to update  once the component is stored 
                     */
                    const isComingFromFormItemCall: boolean = false
                    const isComingFromMultiFeatures: boolean = undefined
                    this.storeBaseComponentsService.unpackAndDistributeBaseComponents({featureTypeList , paragraphsChilds }, isComingFromFormItemCall , isComingFromMultiFeatures )
                    this.singleParagraphChildsToAddToFormEdit.next(newParagraph) // paragraphs has been already stored at column call , just advise paragraphFeatureContainer that paragraph Has been stored
                    
                    this.storeParagraphItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenParagraphItemPlusChildsAreStored(newParagraph._id)
                }

            }

    }

    

    // getting paragraphsPlusChilds from store shared paragraphFeatures and store shared formFeatures
    unpackAndDistributeParagraphsAndChildsComponents(paragraphsPlusChilds: any ){ // (paragraphsPlusChilds: any , fromWhichTypeOfComponent: string , newForm: Form , typeOfIndex: string )

        console.log('PARAGRAPHS WITH CHILDS ARRIVED AND TO BE SCOMPOSED = ',paragraphsPlusChilds)

         const paragraphs: Paragraph[] = []
       // const paragraphs: any[] = []
        const featureTypeList: string[] = [] // help define what kind of paragraphChild we have to reconstruct 
        const paragraphsChilds: any[] = []

        for( let i=0; i<paragraphsPlusChilds.length; i++){

            const newChildParagraph_id = paragraphsPlusChilds[i]._id
            const newComponentName = paragraphsPlusChilds[i].componentName
            const newUIID = paragraphsPlusChilds[i].UIID
            const newChildParagraphOwner = paragraphsPlusChilds[i].owner
            const newChildParagraphDateAndTime = paragraphsPlusChilds[i].dateAndTime
            const newChildParagraphUpdateDateAndTime = paragraphsPlusChilds[i].updateDateAndTime
            const newChildParagraphIsRemoved =  paragraphsPlusChilds[i].isRemoved
            const newChildParagraphAllUsersAllowed = paragraphsPlusChilds[i].allUsersAllowed
            const newChildParagraphUserListAllowed = paragraphsPlusChilds[i].userListAllowed
            const newChildParagraphCanBeReshared = paragraphsPlusChilds[i].canBeResharedByOthers
            const newChildParagraphParagraphName = paragraphsPlusChilds[i].name
            const newChildParagraphParagraphDesc = paragraphsPlusChilds[i].desc 
            const newChildParagraphFeatures = paragraphsPlusChilds[i].features 
            const newChildParagraphFeatures_id_list = paragraphsPlusChilds[i].feature_id_list
        
            const newChildParagraph = new Paragraph (  newChildParagraph_id,
                                                  newComponentName,
                                                  newUIID,
                                                  newChildParagraphOwner,
                                                  newChildParagraphDateAndTime,
                                                  newChildParagraphUpdateDateAndTime,
                                                  newChildParagraphIsRemoved ,
                                                  newChildParagraphAllUsersAllowed,
                                                  newChildParagraphUserListAllowed,
                                                  newChildParagraphCanBeReshared ,
                                                  newChildParagraphParagraphName, 
                                                  newChildParagraphParagraphDesc, 
                                                  newChildParagraphFeatures, 
                                                  newChildParagraphFeatures_id_list );
                                           
    
          if ( paragraphs.findIndex(x=> x._id === newChildParagraph._id ) === -1){
               paragraphs.push(newChildParagraph)
          }

            for ( let y=0; y<paragraphsPlusChilds[i].features.length ; y++ ){

                if ( paragraphsChilds.findIndex(x=> x._id === paragraphsPlusChilds[i].paragraphChild_list[y]._id ) === -1 ){
                                      featureTypeList.push(paragraphsPlusChilds[i].features[y].featureName )
                                      paragraphsChilds.push(paragraphsPlusChilds[i].paragraphChild_list[y] )
                }
            
            }



            if ( i === paragraphsPlusChilds.length-1 ){

                console.log( 'FEATURE TYPE LIST =', featureTypeList , "here we should do one more service that first store this featureTypeList , and then collect the ones done with a bpolean , and when all are done we stop loading the right page ")

                /**
                 * need to add is this is coming from paragraph call or from formItem call , so that we know 
                 * what storagePlusChildsConfrimation we have to update  once the component is stored 
                 */
                const isComingFromFormItemCall: boolean = true
                const isComingFromMultiFeatures: boolean = undefined 
                this.storeBaseComponentsService.unpackAndDistributeBaseComponents({featureTypeList , paragraphsChilds}, isComingFromFormItemCall , isComingFromMultiFeatures )
               
                this.paragraphService.addParagraphsToListPlusSendPossibleFormSubject(paragraphs)
                console.log( 'FEATURE TYPE LIST + PARAGRAPHS CHILDS = ', featureTypeList , paragraphsChilds)

            }
        

        }

    }


}