

  
<mat-card class="mat-elevation-z0"> 
   
                   <mat-tab-group #tabs
                    [(selectedIndex)]="selectedIndex"
                    class="remove-border-bottom"
                    header-less-tabs 
                    headerPosition="below" 
                    (selectedTabChange)="onLinkClick($event)"
                    animationDuration="50ms" >  

               
                   
                    
                    <mat-tab *ngFor="let word of wordsHintInput; let i=index" >
                        <app-word-hint-input 
                        [feature]="feature_from_paragraphEdit"
                        [noNeeded]="false" 
                        [index_id]="word._id">
                        </app-word-hint-input> 
                                 <ng-template  mat-tab-label>
                                 <a style="cursor: pointer">
                                  {{i+1}} - {{word.name}} </a>
                                 </ng-template>
                     </mat-tab>

                     <div *ngIf="createButtonLimit">
                        <mat-tab disabled > 
                            <ng-template  mat-tab-label>
                                 <a (click)="onCreate()"> create new </a>
                            </ng-template>
                        </mat-tab>
                     </div>
                     

                    
                         
                    </mat-tab-group>

</mat-card>     




