import { Component, OnInit , Output, EventEmitter, Input, NgZone, ChangeDetectorRef} from '@angular/core';
import { FormGroup , FormControl} from '@angular/forms';
import { FormIndexTopic } from './form-index-topic.model';
import { FormIndexTopicService } from './form-index-topic.service';
import { PostComponentsHttpRequestService } from '../../../services/post-and-put-services/post-components-http-request.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { Subscription } from 'rxjs';
import { FormIndexTopicListItem } from './form-index-topic-list-item.model';
import { FormIndexTopicListItemService } from './form-index-topic-list-item.service';
import { FormIndexTypeInterface } from '../form-index-type-interface.model';
import { AdviseIfHttpStoreComponentsCallHasBeenMadeService } from 'src/app/services/other-components-services/advise-if-http-store-components-has-been-made.service';
import { FormFeatureContainerService } from './form-feature-container.service';
import { StoreFormItemsService } from 'src/app/services/get-and-store-components/store-form-items.service';
import { FormService } from '../../form.service';
import { StorePersonalFeatureIndexComponentsService } from 'src/app/services/store-initial-components/store-personal-feature-index-components.service';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { FormFeatureContainer } from './form-feature-container.model';
import { CreateUpdateMultiComponentsService } from 'src/app/services/shared-services/create-update-multi-components.service';
import { AppLoadingService } from 'src/app/services/other-components-services/app-loading.service';
import { ParagraphTopicIndex } from '../form-edit/form-edit-paragraphs-index/paragraph-topic-index.model';
import { ParagraphTopic } from '../form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.model';
import { ParagraphFeatureContainer } from '../form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature-container.model';
import { AddSharedFormOrParagraphService } from '../add-shared-form-or-paragraph/add-shared-form-or-paragraph.service';

interface SharedParagraphForm_New_Old_Data {
  typeOfComponent: string , 
  formSide: FormSide ,
  paragraphSide: ParagraphSide, 
}

interface FormSide {
  oldFormIndexTopicListItem: FormIndexTopicListItem,
  updatedFormIndexTopicListItem: FormIndexTopicListItem,
  oldFormIndexTopic: FormIndexTopic ,
  updatedFormIndexTopic: FormIndexTopic,
  oldFormFeatureContainer: FormFeatureContainer,
  updatedFormFeatureContainer: FormFeatureContainer

}

interface ParagraphSide {
  oldParagraphTopicIndex: ParagraphTopicIndex,
  updatedParagraphTopicIndex: ParagraphTopicIndex,
  oldParagraphTopic: ParagraphTopic,
  updatedParagraphTopic: ParagraphTopic,
  oldParagraphFeatureContainer: ParagraphFeatureContainer,
  updatedParagraphFeatureContainer: ParagraphFeatureContainer
}


@Component({
  selector: 'app-form-index-topic-list',
  templateUrl: './form-index-topic-list.component.html',
  styleUrls: ['./form-index-topic-list.component.css']
})
export class FormIndexTopicListComponent implements OnInit {

  user: string 

  formIndexTopicListItem: FormIndexTopicListItem

  formIndexTopicAddName: FormGroup;
  formIndexTopic: FormIndexTopic;
  formIndexTopics: FormIndexTopic[];

  weAreInFormIndexTopicList: boolean = true 

   /** hide and show the add topic button  */
   hideButton = false ;

   /** boolean property used on ngIf to confront max topics number  */
   createButtonLimit : boolean ;
 
   /** limit number of Topics allowed to add  */
   topicsLimit = 19 ;
   formIndexArePresent: boolean

   trialFormIndexTopics: FormIndexTopic[]
   isLoading : boolean
   error = false

   @Output() closeSideNavOnFormList = new EventEmitter<void>()
   @Output() adviseFormListOnLoadingForm = new EventEmitter<void>()

   @Input() typeOfFormIndexTopic: FormIndexTypeInterface
   typeOfIndexTopicInUse: string 

   form_id : string

  
   updateOnUserEmail: Subscription
   stopLoadingComingFromFormService: Subscription 

   updatePublicFormIndexTopicsAfterDeletingOne: Subscription

   sharedFormIndexTopicsNumber: number 
   preferredFormIndexTopicsNumber: number 

   sharedPublicFormsHasBeenAddedSubscription: Subscription 

   updateOnSharedParagraphFormsSubscription: Subscription 
   

  constructor( private formIndexTopicService: FormIndexTopicService, 
               private storeFormItemsService: StoreFormItemsService , 
               private formFeatureContainerService: FormFeatureContainerService ,
               private formIndexTopicListItemService: FormIndexTopicListItemService,
               private userObjectUpdateService: UserObjectUpdateService ,
               private postComponentsHttpRequestService: PostComponentsHttpRequestService ,
               private adviseIfHttpStoreComponentsCallHasBeenMadeService: AdviseIfHttpStoreComponentsCallHasBeenMadeService , 
               private formService: FormService  , 
               private storePersonalFeatureIndexComponentsService: StorePersonalFeatureIndexComponentsService , 
               private initialNullSetupService : InitialNullSetupService ,
               private ngZone: NgZone , 
               private cdr: ChangeDetectorRef , 
               private appLoadingService: AppLoadingService , 
               private createUpdateMultiComponentsService: CreateUpdateMultiComponentsService,
               private addSharedFormOrParagraphService: AddSharedFormOrParagraphService
               ) { }

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
  })
    this.isLoading = true 
    
    if ( this.typeOfFormIndexTopic.isPersonal == true ){ 
         this.typeOfIndexTopicInUse = 'personal'
         this.getFormsAccordingToType(this.typeOfIndexTopicInUse )
        }

    if ( this.typeOfFormIndexTopic.isShared == true   ){ 
         this.typeOfIndexTopicInUse = 'share'
         this.getFormsAccordingToType(this.typeOfIndexTopicInUse )
        }

    if ( this.typeOfFormIndexTopic.isPreferred == true){ 
         this.typeOfIndexTopicInUse = 'preference'
         this.getFormsAccordingToType(this.typeOfIndexTopicInUse )
        }

    if ( this.typeOfFormIndexTopic.isPublic == true   ){ 
         this.typeOfIndexTopicInUse = 'public'
         this.getFormsAccordingToType(this.typeOfIndexTopicInUse )
        }
    

    this.formIndexTopicListItem = this.formIndexTopicListItemService.getFormIndexTopicListItem_by_type(this.typeOfIndexTopicInUse)
    console.log(this.formIndexTopicListItem)

    if ( this.formIndexTopicService.getFormIndexTopics().length < this.topicsLimit ){ this.createButtonLimit = true }
    if ( this.formIndexTopicService.getFormIndexTopics().length >= this.topicsLimit ){ this.createButtonLimit = false};

    this.sharedFormIndexTopicsNumber = this.formIndexTopicListItemService.getFormIndexTopicListItem_by_type('share').formIndexTopic_ids.length
    this.preferredFormIndexTopicsNumber = this.formIndexTopicListItemService.getFormIndexTopicListItem_by_type('preference').formIndexTopic_ids.length
   
   
    this.formIndexTopicAddName = new FormGroup ({
      name: new FormControl ( '')
    });

    this.stopLoadingComingFromFormService = this.formService.stopLoadingFormIndexTopic
        .subscribe(()=> { this.stopLoading() })

    this.updatePublicFormIndexTopicsAfterDeletingOne = this.formIndexTopicListItemService.updatePublicFormTopicIndexListItems
        .subscribe(()=> { 
          this.isLoading = true 
          setTimeout(()=>{
            this.stopLoading()
          },500)
    })

  this.sharedPublicFormsHasBeenAddedSubscription = this.appLoadingService.getAppLoadingToStop()
      .subscribe(()=>{
        this.stopLoading()
      })
       
    console.log(this.formIndexTopicListItem.formIndexTopic_ids.length)
    console.log(this.formIndexTopics)

    this.updateOnSharedParagraphFormsSubscription = this.addSharedFormOrParagraphService.doneAddingUpdatingSharedParagraphForm
        .subscribe((res: SharedParagraphForm_New_Old_Data)=> {
          console.log( res, 'updated paragraphIndexTopic')
          this.updateSharedFormIndexTopicListItem(res)
        })
   
  };



  updateSharedFormIndexTopicListItem( sharedParagraphForm_New_Old_Data: SharedParagraphForm_New_Old_Data){

    this.ngZone.run(()=> {
      if ( sharedParagraphForm_New_Old_Data.typeOfComponent === 'formIndexTopic' ){
        if ( sharedParagraphForm_New_Old_Data.formSide.oldFormIndexTopicListItem._id === this.formIndexTopicListItem._id ){

          this.isLoading = true 

             const currentFormIndexTopicListItem: FormIndexTopicListItem = this.formIndexTopicListItemService.getFormIndexTopicListItem_by_Id(sharedParagraphForm_New_Old_Data.formSide.oldFormIndexTopicListItem._id)
             const formIndexTopicListItem: FormIndexTopicListItem = sharedParagraphForm_New_Old_Data.formSide.updatedFormIndexTopicListItem

             const currentFormIndexTopic: FormIndexTopic = this.formIndexTopicService.getFormIndexTopicBy_id(sharedParagraphForm_New_Old_Data.formSide.oldFormIndexTopic._id)
             const formIndexTopic: FormIndexTopic = sharedParagraphForm_New_Old_Data.formSide.updatedFormIndexTopic
         
             const indexOfFormIndexTopic_id_ToSubstitute = currentFormIndexTopicListItem.formIndexTopic_ids.findIndex(x=> x === currentFormIndexTopic._id )
             currentFormIndexTopicListItem.formIndexTopic_ids[indexOfFormIndexTopic_id_ToSubstitute] = formIndexTopic._id
             console.log(currentFormIndexTopicListItem)

             const indexOfCurrentFormIndexTopicListItem = this.formIndexTopicListItemService.getFormIndexTopicListItems().findIndex(x=> x._id === currentFormIndexTopicListItem._id )
             this.formIndexTopicListItemService.formIndexTopicListItems[indexOfCurrentFormIndexTopicListItem ] = currentFormIndexTopicListItem
             const initialNullIndexListItem = this.initialNullSetupService.initialNullFormIndexTopicListItems.findIndex(x=> x._id === currentFormIndexTopicListItem._id )
             this.initialNullSetupService.initialNullFormIndexTopicListItems[initialNullIndexListItem] = currentFormIndexTopicListItem

             formIndexTopic.formFeatureContainer_ids = currentFormIndexTopic.formFeatureContainer_ids
             const indexOfCurrentFormIndexTopic = this.formIndexTopicService.getFormIndexTopics().findIndex(x=> x._id === currentFormIndexTopic._id )
             this.formIndexTopicService.formIndexTopics[indexOfCurrentFormIndexTopic] = formIndexTopic 
             const initialNullIndex = this.initialNullSetupService.initialNullFormIndexTopics.findIndex(x=> x._id === currentFormIndexTopic._id )
             this.initialNullSetupService.initialNullFormIndexTopics[initialNullIndex] = formIndexTopic


             this.formIndexTopicListItem = currentFormIndexTopicListItem
        }
      }
    })
    this.cdr.detectChanges()
    // setTimeout(() => {
      this.isLoading = false 
    // }, 150);
    

  }



  getFormsAccordingToType(type: string){

    if ( type === 'personal' ){

      this.defineFormIndexTopicOfReferenceAndLetFormFeatureContainerKnow(type)
      
      if ( this.user !== null ){
        if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.personalFormsHasBeenStored === false ){
          if ( this.isLoading === false ){ this.isLoading = true }
              
               this.storeFormItemsService.getAndStorePersonalForms(this.user, type , true, false )

               /**
                * the isLoading = false is coming from subscription from formFeatureSevice
                * once all the formFeatures will be stored 
                */
              

          }
       }
       if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.personalFormsHasBeenStored === true ){
            this.stopLoading()
       }
       if ( this.user === null ){
            this.stopLoading()
       }
    };


    if ( type === 'share'){

       this.defineFormIndexTopicOfReferenceAndLetFormFeatureContainerKnow(type)

      if ( this.user !== null ){
        if (this.adviseIfHttpStoreComponentsCallHasBeenMadeService.sharedFormsHasBeenStored === false ){
          if ( this.isLoading === false ){ this.isLoading = true }
          this.storeFormItemsService.getAndStoreSharedForms(this.user, type , true , false )

          }
        if (this.adviseIfHttpStoreComponentsCallHasBeenMadeService.sharedFormsHasBeenStored ===  true ){
            this.stopLoading()
        }
      }
      if ( this.user === null ){
           this.stopLoading()
       }
    };



    if ( type === 'preference'){

      this.defineFormIndexTopicOfReferenceAndLetFormFeatureContainerKnow(type)

      if ( this.user !== null ){
        if (this.adviseIfHttpStoreComponentsCallHasBeenMadeService.preferredFormsHasBeenStored === false ){
          if ( this.isLoading === false ){ this.isLoading = true }
             
               this.storeFormItemsService.getAndStoreSharedForms(this.user, type , true , false )
        }
        if (this.adviseIfHttpStoreComponentsCallHasBeenMadeService.preferredFormsHasBeenStored ===  true ){
            this.stopLoading()
        }
      }
      if ( this.user === null ){
        this.stopLoading()
      }

    }

    if ( type === 'public'){

      this.defineFormIndexTopicOfReferenceAndLetFormFeatureContainerKnow(type)
      this.stopLoading()

    }

  };

  defineFormIndexTopicOfReferenceAndLetFormFeatureContainerKnow(type: string ){
     /**
        * define which formIndexTopics of reference we are refering to 
        */
     const formIndexTopicListItemOfReference: FormIndexTopicListItem = this.formIndexTopicListItemService.getFormIndexTopicListItem_by_type(type)
     console.log(formIndexTopicListItemOfReference)
     const formIndexTopicsOfReference: FormIndexTopic[] = []
     for ( let i=0; i<formIndexTopicListItemOfReference.formIndexTopic_ids.length ; i++ ){
       formIndexTopicsOfReference.push(this.formIndexTopicService.getFormIndexTopicBy_id(formIndexTopicListItemOfReference.formIndexTopic_ids[i]) )
     }
     console.log('EXISTING SHARED FORM TOPICS OF REFERENCE', formIndexTopicsOfReference)
     // now advise formFeatureContainer service about this existing shared form topics of reference 
     this.formFeatureContainerService.formIndexTopicsOfReference = formIndexTopicsOfReference 

    
  }

  stopLoading(){
    console.log("it's stop loading // from formFeatureService ")

    this.sharedFormIndexTopicsNumber = this.formIndexTopicListItemService.getFormIndexTopicListItem_by_type('share').formIndexTopic_ids.length
    this.preferredFormIndexTopicsNumber = this.formIndexTopicListItemService.getFormIndexTopicListItem_by_type('preference').formIndexTopic_ids.length
   
    this.isLoading = false 

  }


  onAddEmailSearch($event){

   console.log('looking forformFeatureIndex from = ', $event )
   this.formIndexTopicService.foundEmailList.push($event)
   this.storeFormItemsService.getAndStorePublicFormsByObjectTypeOwnerAndUser('formItem', $event , this.user)
  }

  onDeleteEmailOnSearch($event: string){

    console.log($event)

  }



  onHideButton(){
    this.hideButton = !this.hideButton;
 /** set time limit to write the new name */
    setTimeout(() => {
      this.hideButton = false ;
      this.formIndexTopicAddName = new FormGroup({
        name: new FormControl ('') ,   
          })
    }, 15000);

    if ( this.formIndexTopicService.getFormIndexTopics().length < this.topicsLimit ){
      this.createButtonLimit = true ;
    } else {
      this.createButtonLimit = false ;
    };
  };

  onSubmit(){

    if (this.user !== null ){ this.onUserExistSubmit()}
    if ( this.user === null){ this.onNullUserSubmit() } 
    
}

onUserExistSubmit(){

  // this.isLoading = true

  this.storePersonalFeatureIndexComponentsService.getPersonalUpdatedFeatureIndex_components(this.user)
              .subscribe((res: any) =>{
               console.log(res)

               const httpFeatureIndexComponentResult: any = res 
               
                const newFormIndexTopics: FormIndexTopic[] = httpFeatureIndexComponentResult.formIndexTopic
                console.log(newFormIndexTopics)
                this.formIndexTopicService.formIndexTopics = newFormIndexTopics 
                this.initialNullSetupService.initialNullFormIndexTopics = newFormIndexTopics
               
                const newFormIndexTopicListItems: FormIndexTopicListItem[] = httpFeatureIndexComponentResult.formIndexTopicListItem
                console.log(newFormIndexTopicListItems)
                this.formIndexTopicListItemService.formIndexTopicListItems = newFormIndexTopicListItems
                this.initialNullSetupService.initialNullFormIndexTopicListItems = newFormIndexTopicListItems

                const newFormFeatureContainers: FormFeatureContainer[] = httpFeatureIndexComponentResult.formFeatureContainer
                console.log(newFormFeatureContainers)
                this.formFeatureContainerService.formFeatureContainers = newFormFeatureContainers 
                this.initialNullSetupService.initialNullFormFeatureContainers = newFormFeatureContainers



  const new_id = 'formIndexTo-'+( this.formIndexTopicService.getFormIndexTopics().length+1).toString() ;
  const newComponentName = 'formIndexTopic'
  const newUIID = 'formIndexTopic-'+( this.formIndexTopicService.getFormIndexTopics().length+1).toString() 
  const newOwner = this.user
  const newDateAndTime = null 
  const newAllUsersAllowed = false ;
  const newUserListAllowed = [];
  const newIsAccepted = true ; 
  const newIsArchived = false ; 
  const newName = this.formIndexTopicAddName.value.name ;

  /** we leave the 5th property empty to be added on the fly */

  const newFormIndexTopic = new FormIndexTopic ( new_id, newComponentName, newUIID, newOwner, newDateAndTime, newAllUsersAllowed, 
                           newUserListAllowed, newIsAccepted, newIsArchived, newName, [])



const new_id2 = this.formIndexTopicListItem._id
const newComponentName2 = this.formIndexTopicListItem.componentName
const newUIID2 = this.formIndexTopicListItem.UIID
const newOwner2 = this.formIndexTopicListItem.owner
const newAllUsersAllowed2 = this.formIndexTopicListItem.allUsersAllowed
const newUserListAllowed2 = this.formIndexTopicListItem.userListAllowed
const newDateAndTime2 = this.formIndexTopicListItem.dateAndTime
const newType2 = this.formIndexTopicListItem.type
const newParagraphTopic_ids2 = this.formIndexTopicListItem.formIndexTopic_ids


const newFormIndexTopicListItem = new FormIndexTopicListItem (new_id2, newComponentName2, newUIID2, newOwner2, newAllUsersAllowed2, newUserListAllowed2,
newDateAndTime2, newType2, newParagraphTopic_ids2)

console.log( this.formIndexTopicListItem)

this.createUpdateMultiComponentsService.createFormIndexTopic_UpdateFormIndexTopicListItem(this.user, newFormIndexTopic, newFormIndexTopicListItem )
    .subscribe((res: any)=> {

      console.log(res)

      this.ngZone.run(()=> {

         const newNewFormIndexTopic: FormIndexTopic = res.featureIndex[0]
         const newNewFormIndexTopicListItem: FormIndexTopicListItem = res.featureIndex[1]

         this.formIndexTopicService.formIndexTopics.push(newNewFormIndexTopic)
         this.initialNullSetupService.initialNullFormIndexTopics.push(newNewFormIndexTopic)

         const newIndex = this.formIndexTopicListItemService.getFormIndexTopicListItems().findIndex(x=> x._id === this.formIndexTopicListItem._id)
         this.formIndexTopicListItemService.formIndexTopicListItems[newIndex] = newNewFormIndexTopicListItem
         const newNullIndex = this.initialNullSetupService.initialNullFormIndexTopicListItems.findIndex(x=> x._id === newNewFormIndexTopicListItem._id)
         this.initialNullSetupService.initialNullFormIndexTopicListItems[newNullIndex] = newNewFormIndexTopicListItem
         console.log(this.formIndexTopicListItemService.getFormIndexTopicListItems())


         this.formIndexTopicListItem = newNewFormIndexTopicListItem

         if ( this.formIndexTopicService.getFormIndexTopics().length < this.topicsLimit ){ this.createButtonLimit = true }
         if ( this.formIndexTopicService.getFormIndexTopics().length >= this.topicsLimit ){ this.createButtonLimit = false};

         this.formIndexTopicAddName = new FormGroup ({
              name: new FormControl ( '')
         }); ;
         this.hideButton = !this.hideButton;

      })
      this.cdr.detectChanges()
     
      

    })

})

}

onNullUserSubmit(){

  this.isLoading = true

  const new_id = 'formIndexTo-'+( this.formIndexTopicService.getFormIndexTopics().length+1).toString() ;
  const newComponentName = 'formIndexTopic'
  const newUIID = 'formIndexTopic-'+( this.formIndexTopicService.getFormIndexTopics().length+1).toString() 
  const newOwner = this.user
  const newDateAndTime = null 
  const newAllUsersAllowed = false ;
  const newUserListAllowed = [];
  const newIsAccepted = true ; 
  const newIsArchived = false ; 
  const newName = this.formIndexTopicAddName.value.name ;

  /** we leave the 5th property empty to be added on the fly */

  const newFormIndexTopic = new FormIndexTopic ( new_id, newComponentName, newUIID, newOwner, newDateAndTime, newAllUsersAllowed, 
                           newUserListAllowed, newIsAccepted, newIsArchived, newName, [])


   this.formIndexTopicService.addFormIndexTopic( newFormIndexTopic );

     const new_id2 = this.formIndexTopicListItem._id
     const newComponentName2 = this.formIndexTopicListItem.componentName
     const newUIID2 = this.formIndexTopicListItem.UIID
     const newOwner2 = this.formIndexTopicListItem.owner
     const newAllUsersAllowed2 = this.formIndexTopicListItem.allUsersAllowed
     const newUserListAllowed2 = this.formIndexTopicListItem.userListAllowed
     const newDateAndTime2 = this.formIndexTopicListItem.dateAndTime
     const newType2 = this.formIndexTopicListItem.type
     const newParagraphTopic_ids2 = this.formIndexTopicListItem.formIndexTopic_ids
     newParagraphTopic_ids2.push(newFormIndexTopic._id)

     const newFormIndexTopicListItem = new FormIndexTopicListItem (new_id2, newComponentName2, newUIID2, newOwner2, newAllUsersAllowed2, newUserListAllowed2,
                             newDateAndTime2, newType2, newParagraphTopic_ids2)

     const newIndex = this.formIndexTopicListItemService.getFormIndexTopicListItems().findIndex(x=> x._id === this.formIndexTopicListItem._id)
     this.formIndexTopicListItemService.formIndexTopicListItems[newIndex] = newFormIndexTopicListItem

     this.formIndexTopicListItem = newFormIndexTopicListItem 

     if ( this.formIndexTopicService.getFormIndexTopics().length < this.topicsLimit ){ this.createButtonLimit = true }
     if ( this.formIndexTopicService.getFormIndexTopics().length >= this.topicsLimit ){ this.createButtonLimit = false};

     this.isLoading = false

  this.formIndexTopicAddName = new FormGroup ({
   name: new FormControl ( '')
   }); ;

  this.hideButton = !this.hideButton;


 

}

    onAdviseFormListOnLoadingForm(){
      // this.adviseFormListOnLoadingForm.emit()
    }

    onCloseSideNavOnFormList(){

      this.closeSideNavOnFormList.emit()

    };

    ngOnDestroy(){
      this.updateOnUserEmail.unsubscribe()
      this.stopLoadingComingFromFormService.unsubscribe()
      this.sharedPublicFormsHasBeenAddedSubscription.unsubscribe()
      this.updateOnSharedParagraphFormsSubscription.unsubscribe()
    }



}
