

<div class="row">
  <div class="col-md-12">
    
    <mat-toolbar  > 
      <mat-toolbar-row >

         <div [hidden]="(isHandset$ | async) === true">  
          <a routerLink="/create-form"  
             routerLinkActive="activebutton"  
             (click)="onWeAreGoingToCreateForm()" 
             class="navbar-brand" >  
             <!-- matBadge="+" matBadgeColor="warn" matBadgeSize="small" 
                   [matBadgeHidden]="hideDrappBadge" -->
             <span  [ngStyle]="{color: getDrappColor()}" 
                   class="mat-button-wrapper hide-text" >
                   drapp 
            </span>
          </a>
            </div>

         <div  *ngIf="isHandset$ | async">
          <button 
          
          type="button"
          mat-icon-button
          (click)="onWeAreGoingToCreateForm()"
          routerLink="/create-form" routerLinkActive="activebutton"  >

          <span class="hide-text">
            <!-- matBadge="+"  matBadgeColor="warn" matBadgeSize="small"   [matBadgeHidden]="hideDrappBadge"  -->
            <svg [ngStyle]="{color: getDrappColor()}" xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" class="bi bi-grid-3x3-gap-fill" viewBox="0 0 16 16">
              <path d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z"/>
            </svg>
          </span>
         
         </button> 
        </div>
     
            <div *ngIf="weAreInEditParagraph" > 
            <a *ngIf="isEditComponent === false && weAreInCreateParagraph === false" 
               class="navbar-brand">  
               <span  class="mat-button-wrapper" 
                      style="color: white" > 
                      edit paragraph
                     
               </span>
               <span style="color: white" *ngIf="isAnnouncingIsEditingAParagraphWithLoadingSpinner">
                <app-single-loading-spinner >
                </app-single-loading-spinner>
               </span>
            </a>
            <a *ngIf="isEditComponent === false && weAreInCreateParagraph === true" 
               class="navbar-brand">  
               <span   
                     class="mat-button-wrapper" 
                     style="color: white" >
                     create paragraph
                    </span>
            </a>
            <a *ngIf="isEditComponent"  
               class="navbar-brand">  
               <span class="mat-button-wrapper"  
                     style="color: white"  >
                     edit component
                     <app-single-loading-spinner></app-single-loading-spinner>
                </span>
            </a>
          
            </div>
           <!-- should also add EDIT MULTIFEATURE COMPONENT and EDIT TAB COMPONENT -->

  
            <div *ngIf="!weAreInEditParagraph" >
               
               <a [hidden]="(isHandset$ | async) === true" routerLink="/form-list" (click)="weAreGoingToForms()" routerLinkActive="activebutton" (click)="notInEditParagraph()"  class="navbar-brand">   
                <!-- matBadge="+"  matBadgeColor="warn" matBadgeSize="small"  [matBadgeHidden]="hideFormsBadge" -->
                <span [ngStyle]="{color: getFormsColor()}" class="mat-button-wrapper hide-text"> saas' </span>
              </a>
               <button 
               *ngIf="isHandset$ | async"
               type="button"
               mat-icon-button
               (click)="weAreGoingToForms()"
               routerLink="/form-list" routerLinkActive="activebutton"  >
               <span  class="hide-text" >
                <!-- matBadge="+"  matBadgeColor="warn" matBadgeSize="small"  [matBadgeHidden]="hideResultsBadge" -->
                <svg [ngStyle]="{color: getFormsColor()}" xmlns="http://www.w3.org/2000/svg" width="30" height="32" fill="currentColor" class="bi bi-list-ul" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                </svg>
               </span>
              </button> 


              
              <a [hidden]="(isHandset$ | async) === true" routerLink="/results" routerLinkActive="activebutton"  (click)="notInEditParagraph()"  (click)="weAreGoingToResults()" class="navbar-brand">   
              <!--  matBadge="+"  matBadgeColor="warn" matBadgeSize="small" [matBadgeHidden]="hideResultsBadge" -->
                <span [ngStyle]="{color: getResultsColor()}"  class="mat-button-wrapper hide-text"> results </span>
              </a>
             
              <button 
               *ngIf="isHandset$ | async"
               type="button"
               mat-icon-button
               (click)="weAreGoingToResults()"
               routerLink="/results" routerLinkActive="activebutton"
                 >
               <span class="hide-text"> 
                <!-- matBadge="+"  matBadgeColor="warn" matBadgeSize="small"  [matBadgeHidden]="hideResultsBadge" -->
                <svg [ngStyle]="{color: getResultsColor()}" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-sort-alpha-up" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M10.082 5.629 9.664 7H8.598l1.789-5.332h1.234L13.402 7h-1.12l-.419-1.371h-1.781zm1.57-.785L11 2.687h-.047l-.652 2.157h1.351z"/>
                  <path d="M12.96 14H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645V14zm-8.46-.5a.5.5 0 0 1-1 0V3.707L2.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.498.498 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L4.5 3.707V13.5z"/>
                </svg>
            
               </span>
              </button> 
  <!---------------------------- GROUP PART ------------------------------------------------------------------->
  <!-- <a *ngIf="userExist">
              <a [hidden]="(isHandset$ | async) === true" 
                 routerLink="/groups" 
                 routerLinkActive="activebutton" 
                 (click)="notInEditParagraph()" 
                 (click)="onWeAreGoingToMessages()" 
                 class="navbar-brand">  
               
                <span [ngStyle]="{color: getMessagesColor()}"   class="mat-button-wrapper hide-text"> groups  </span>
                </a>
            </a>
              <button 
              *ngIf="(isHandset$ | async) && userExist"
              type="button"
              mat-icon-button
              routerLink="/groups" routerLinkActive="activebutton" (click)="onWeAreGoingToMessages()" >
              
              <span class="hide-text">
             
                <svg [ngStyle]="{color: getMessagesColor()}" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
                  <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                  <path fill-rule="evenodd" d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/>
                  <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
                </svg>
              </span>
              </button>  -->
  <!---------------------------- END GROUP PART ------------------------------------------------------------------->
          
          </div>
      
          <span class="spacer"></span>
         
         
            <a *ngIf="(isHandset$ | async) === false" 
            (click)="onLoginLogout()"
             class="navbar-brand pointer"  >
             <span [ngStyle]="{color: getSignInColor()}" class="mat-button-wrapper hide-text"> {{signUpLogOut}} </span> </a>
        
           
         <button 
         type="button"
         mat-icon-button
         (click)="onLoginLogout()"
         *ngIf="isHandset$ | async" >
         <span >
         
          <svg *ngIf="!isLoggedIn" [ngStyle]="{color: getSignInColor()}" xmlns="http://www.w3.org/2000/svg" width="27" height="30" fill="currentColor" class="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"/>
            <path fill-rule="evenodd" d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
          </svg>
          <svg *ngIf="isLoggedIn" [ngStyle]="{color: getSignInColor()}" xmlns="http://www.w3.org/2000/svg" width="27" height="30" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"/>
            <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
          </svg>
         </span>
         </button>

    
       
      </mat-toolbar-row> 
  
    </mat-toolbar>
  </div>
</div> 

