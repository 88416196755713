

<form [formGroup]="rulesForm" *ngIf="isOurOwnRecipient">
	<mat-form-field  class="chip-list" >
        <mat-label style="color: #3f51b5;"> CREATE GROUP MESSAGE </mat-label>

		<mat-chip-list #chipList formArrayName="emails" >
      <div *ngFor="let item of emailList; let i=index" >
        <mat-chip 
        *ngIf="!item.invalid"
        style="background-color: #3f51b5; color: white ;"
        selected 
        [selectable]="true" 
        [removable]="removable"
			  (removed)="onRemoveOtherEmail(item)" 
        required name="chips">
        {{item.value}}
				<mat-icon  
         matChipRemove
         style="color: white;"
          *ngIf="removable">cancel</mat-icon>
			</mat-chip>
      <mat-chip 
      *ngIf="item.invalid"
      style="background-color: red; color: white ;"
      selected 
      [selectable]="true" 
      [removable]="removable"
      (removed)="removeEmail(item)" 
      required name="chips">
      {{item.value}}
      <mat-icon  
       matChipRemove
       style="color: black"
        *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    </div>

			<input 
        placeholder="add users email"
        [matChipInputFor]="chipList" 
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes " 
        [matAutocomplete]="auto"
        (matChipInputTokenEnd)="add($event)"
			  />
		</mat-chip-list>
    <mat-hint> MAKE SURE THEY'VE LOGGED IN ONCE </mat-hint>
  
    <mat-autocomplete  #auto="matAutocomplete" (optionSelected)="selected($event)" >
      <mat-option *ngFor="let contact of contactEmails; let i=index" [value]="i">
        <div >
          {{contact}}
        </div>
      </mat-option>
    </mat-autocomplete>

	</mat-form-field>
  
</form>

<form [formGroup]="rulesForm" *ngIf="!isOurOwnRecipient">
	<mat-form-field  class="chip-list" >
        <mat-label style="color: orangered;"> SHARED GROUP MESSAGE WITH YOU  </mat-label>

		<mat-chip-list #chipList formArrayName="emails" >
      <div *ngFor="let item of emailList; let i=index" >
        
      <mat-chip  *ngIf="!item.invalid && item.value !== user"
        style="background-color: orangered ; color: white ;"
        selected 
        [selectable]="true" 
        required name="chips">
        {{item.value}}
			</mat-chip>

      <mat-chip *ngIf="!item.invalid && item.value === user"
      style="background-color: #3f51b5; color: white ;"
      selected 
      [selectable]="true" 
      [removable]="removable"
      (removed)="removeEmail(item)" 
      required name="chips">
      {{item.value}}
      <mat-icon  
       matChipRemove
       style="color: white;"
        *ngIf="removable">cancel</mat-icon>
    </mat-chip>
    <!-- (removed)="removeMyEmailFromSharedRecipient(item)"  -->
  
    </div>

			<input disabled 
        [matChipInputFor]="chipList" 
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes " 
        [matAutocomplete]="auto"
        (matChipInputTokenEnd)="add($event)"
			  />
		</mat-chip-list>

	</mat-form-field>
  
</form>
