import { NgModule } from '@angular/core';
import { Routes , RouterModule } from '@angular/router' ;
import { FormListComponent} from './forms/form-list/form-list.component';
import { FormsComponent} from './forms/forms.component';
import { ParagraphComponent } from './forms/form-list/form-item/paragraph/paragraph.component';
import { ChatsComponent } from './chats/chats.component';
import { FormResultsComponent } from './forms/form-results/form-results.component';
import { FormStartComponent } from './forms/form-list/form-start/form-start.component';
import { FormItemComponent } from './forms/form-list/form-item/form-item.component';
import { FormReloadComponent } from './forms/form-list/form-reload/form-reload.component';
import { FormResultsListComponent } from './forms/form-results/form-results-list/form-results-list.component';
import { FormNotFoundComponent } from './forms/form-not-found/form-not-found.component';
import { ParagraphIndexTopicListComponent } from './forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic-list.component';
import { FormResultItemsComponent } from './forms/form-results/form-results-list/form-result-items/form-result-items.component';
import { FormResultStartComponent } from './forms/form-results/form-results-list/form-result-start/form-result-start.component';

const appRoutes: Routes = [

   
    // { path: '' , redirectTo: 'create-form/oooooooo/personal-paragraphs', pathMatch: 'full'}, 
    { path: '' , redirectTo: 'form-list/personal-form/2', pathMatch: 'full'}, 
   
    { path: 'create-form', redirectTo: 'create-form/oooooooo/personal-paragraphs',  pathMatch: 'full'},
    { path: 'create-form/:recipientIndex', component:FormsComponent , children: [
                                                     { path: ':paragraphType', component: ParagraphIndexTopicListComponent }

    ]},
    { path: 'edit-form/:id', redirectTo: 'edit-form/:id/personal-paragraphs', pathMatch: 'full' },
    { path: 'edit-form/:id', component: FormsComponent ,  children: [
                                                       { path: ':paragraphType', component: ParagraphIndexTopicListComponent }

    ]},
    { path: 'create-paragraph', component:ParagraphComponent },
    { path: 'edit-paragraph/:id', component: ParagraphComponent }, 
         // need to add a resolver guard lesson 152

    { path: 'results', component: FormResultsComponent , children: [
                                                            { path: '', component: FormResultStartComponent },
                                                            { path: 'form-result-list/:type/:messageToFrom/:emailTo/:dateType/:dateInfoStart/:dateInfoEnd/:groupSelected/:groupName/:subject-paragraph/:title', component: FormResultsListComponent },  // need to add a resolver guard lesson 152
                                                            { path: 'form-result-items/:form-result-ids' , component: FormResultItemsComponent },

                                                     
                                                         
                                                   ] 
    },
    
    { path: 'form-list', component:FormListComponent , children: [
                                                      { path: '', component: FormStartComponent },
                                                      { path: 'reload-form', component: FormReloadComponent },
                                                      { path: 'personal-form/:id', component: FormItemComponent },
                                                      { path: 'preferred-form/:id', component: FormItemComponent },
                                                      { path: 'public-form/:id', component: FormItemComponent },
                                                      { path: 'shared-form/:id', component: FormItemComponent },
                                                      
                                                      
                                                    
                                                      
                                                    ]
    },
/**
 * ----------  HOLD ON GRPOUP PART -----------------------------------------------------
 */
//     { path: 'groups', component: ChatsComponent , children: [ 
//            { path: 'selected-group/:index/:name', component: ChatFormResultListComponent   }  // need to add a resolver guard lesson 152
// ] 

// }, 
/**
 * ----------  HOLD ON GRPOUP PART -----------------------------------------------------
 */

                                                   
  
     // { path: 'login' , component:LoginComponent},

     { path: '**' , component: FormNotFoundComponent} // create page not found => redirect to just seen page

];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, {scrollPositionRestoration: 'enabled',anchorScrolling: 'enabled',useHash: true    })],
    exports: [RouterModule],
})
export class AppRoutingModule {
}



