import { Component, OnInit, Input, ViewChild, Output , EventEmitter, OnDestroy } from '@angular/core';
import { SingleDateCalendar } from './single-date-calendar.model';
import { SingleDateCalendarDetail } from './single-date-calendar-detail.model';
import { SingleDateCalendarService } from './single-date-calendar.service';
import { MatTabGroup } from '@angular/material/tabs';
import { Feature} from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/feature.model';
import { Observable, Subscription, Subject } from 'rxjs';
import { DateTime } from 'luxon';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { CreateNewFeatureContainerAndAddToFeatureContainerListItemService } from 'src/app/services/shared-services/create-new-feature-container-and-update-feature-container-list-item.service';
import { AdviseIfHttpStoreComponentsCallHasBeenMadeService } from 'src/app/services/other-components-services/advise-if-http-store-components-has-been-made.service';
import { StoreBaseComponentsService } from 'src/app/services/get-and-store-components/store-base-components.service';
import { FeatureContainerListItemService } from '../../../../paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container-list-item/feature-container-list-items.service';
import { FeatureIndexTopicList } from '../../../../paragraph-edit/feature-index-topic-list.model';
import { FeatureIndexTopicListService } from '../../../../paragraph-edit/feature-index-topic-list.service';
import { FeatureContainerListItem } from '../../../../paragraph-edit/feature-container-list-item.model';
import { FeatureContainer } from '../../../../paragraph-edit/feature-container.model';
import { FeatureContainerService } from '../../../../paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container/feature-container.service';

@Component({
  selector: 'app-calendar-list',
  templateUrl: './calendar-list.component.html',
  styleUrls: ['./calendar-list.component.css']
})
export class CalendarListComponent implements OnInit , OnDestroy {

  date: string;
  dates: string[];
  actualDate: Date ;
  actualDates: Date[];

  user: string 

  thisTypeOfComponent: string = 'oneDateCalendar'

  singladateCalendar: SingleDateCalendar;
  singleDateCalendars: SingleDateCalendar[];

  index: number
  selectedIndex: number  ;

  @Input() feature_from_paragraphEdit: Feature 
  @Input() isParagraphEdit: boolean;
  @Input() currentI: number;

  @Output() changeIndex = new EventEmitter<Feature>()  ;
  @Output() eventIndex: number ;

  /**
  * receiving subscription from ParagraphEdit to edit the component 
  */
  subscriptionToEditComponentItemIsOn: boolean

  feature: Feature;
  currentFeature: Feature;
  @Input() editFeature: Observable<void> ;

   /** limit on the number of features to add */
  createButtonLimit: boolean
  buttonsLimit = 25 ;

  @ViewChild('tabs') tabGroup: MatTabGroup

  /**
  * click and receive the results from paragraph Edit
  */
  editFeatureEventFromParagraphEdit: Subscription;
  clickOnEditFeatureItem: Subject<Feature> = new Subject<Feature>()
     
     
  receiveFeatureInfoFromParagraphEdit: Subscription; 
  @Input() collectFeatureId: Observable<Feature>

  newTabIndex0: number;
  newtabIndex: number;

  isLoading: boolean
  isLoadingList: boolean

  newInputCreated : boolean

  updateOnUserEmail: Subscription 
  singleDateCalendarsHaveBeenStoredSubscription: Subscription
  featureContainerPlusParentsHaveBeenStored: Subscription 

  @Output() holdOnCreateUpdateButtons = new EventEmitter<void>()
  error = null 

  matToolTipMessageOnCreateNew: string 


  constructor( private singleDateCalendarService: SingleDateCalendarService, 
               private postComponentsHttpRequestService: PostComponentsHttpRequestService , 
               private userObjectUpdateService: UserObjectUpdateService,
               private storeBaseComponentsService: StoreBaseComponentsService ,
               private featureContainerListItemService: FeatureContainerListItemService ,
               private featureIndexTopicListService: FeatureIndexTopicListService ,
               private featureContainerService: FeatureContainerService ,
               private adviseIfHttpStoreComponentsCallHasBeenMadeService: AdviseIfHttpStoreComponentsCallHasBeenMadeService ,
               private createNewFeatureContainerAndAddToFeatureContainerListItemService: CreateNewFeatureContainerAndAddToFeatureContainerListItemService ) { }

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
    .subscribe((updatedUserEmail)=>{
     this.user = updatedUserEmail
     console.log('updated USER = ', this.user)
})

console.log( 'this user is : ', this.user )

this.subscriptionToEditComponentItemIsOn = false
this.isLoadingList = true 

if ( this.user === null ){ this.continueInitialOnInit()}
if ( this.user !== null ){
  if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.singleDateCalendarsHasBeenStored === true  ){  this.continueInitialOnInit() }
  if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.singleDateCalendarsHasBeenStored === false ){ this.storeBaseComponentsService.callBaseComponentsListByUserAndType(this.user , 'oneDateCalendar') }
}

this.editFeatureEventFromParagraphEdit = this.editFeature.subscribe(() => this.onEditFeatureItem() )
this.receiveFeatureInfoFromParagraphEdit = this.collectFeatureId.subscribe((data)=> this.updateCurrentFeature(data))

this.singleDateCalendarsHaveBeenStoredSubscription = this.singleDateCalendarService.initialPersonalSingleDateCalendarsHaveBeenStored
    .subscribe(()=> { 
     this.continueInitialOnInit()
     this.adviseIfHttpStoreComponentsCallHasBeenMadeService.singleDateCalendarsHasBeenStored = true 
})

this.featureContainerPlusParentsHaveBeenStored = this.featureContainerListItemService.newFeatureContainerAndparentsHaveBeenStored
    .subscribe((feature_plus_typeOfComponent: any )=> {

     const currentFeature:Feature = feature_plus_typeOfComponent.feature 
     const currentTypeOfComponent: string = feature_plus_typeOfComponent.featureContainerName
     console.log(feature_plus_typeOfComponent)
 
     if ( currentTypeOfComponent === this.thisTypeOfComponent){
          this.onEmitToParagraphEditChangedIndex(currentFeature)
     }
})


  }

  continueInitialOnInit(){

    console.log('IT S CONTINUING INITIAL ONINIT')

    const featureIndexTopicListItem: FeatureIndexTopicList = this.featureIndexTopicListService.getFeatureIndexTopicListItem_by_type('personal')
    console.log('feature index topic list item = ',featureIndexTopicListItem)
  
    const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('oneDateCalendar')

    this.singleDateCalendars = []
    for ( let i=0; i<personalFeatureContainerListItem.featureContainer_ids.length ; i++ ){
          let currentFeatureContainer: FeatureContainer = this.featureContainerService.getFeatureContainerBy_id(personalFeatureContainerListItem.featureContainer_ids[i])
          this.singleDateCalendars.push(this.singleDateCalendarService.getSingleDatecalendar_By_id(currentFeatureContainer.componentReference_id))
    }

  this.selectedIndex =  this.singleDateCalendars.findIndex(x=> x._id === this.feature_from_paragraphEdit.feature_id)
    console.log('selected index_id + selected index : ', this.selectedIndex)

    this.matToolTipMessageOnCreateNew = " CREATE A COPY OF  '1 - "+this.singleDateCalendars[0].name+"'"

    if (this.singleDateCalendars.length < this.buttonsLimit){ this.createButtonLimit = true }
    if ( this.singleDateCalendars.length >= this.buttonsLimit ) { this.createButtonLimit = false }
     
    if (this.user === null ){ setTimeout(()=>{ this.isLoading = false , this.isLoadingList = false },200) }
    if (this.user !== null ){ this.isLoading = false , this.isLoadingList = false  }

  }




  onLinkClick(event){

    this.index = this.tabGroup.selectedIndex ; 

    const newOwner = this.user; 
    const newIsNew = false;
    const newDropId = this.currentI;
    const newFeature_id = this.singleDateCalendars[this.index]._id
    const newFeatureName = 'oneDateCalendar' ;
    const newFeature = new Feature (  newOwner, newIsNew,  newDropId , newFeature_id, newFeatureName) ;
   
    this.feature = newFeature;
   
    this.changeIndex.emit(this.feature)
    this.eventIndex = this.currentI ;
   
    console.log('drop id =', this.currentI)
    console.log(this.feature)


  }

  onCreate(){

    this.isLoading = true
    this.isLoadingList = true 
    this.onCreateNew()
        
  }


    // create  new weordInput and new feature 
    onCreateNew(){

      /**
       * public _id: string, 
                  public owner: string,
                  public allUsersAllowed: boolean,
                  public userListAllowed: string[],
                  public dateAndTime: string ,
                  public name : string,
                  public details: SingleDateCalendarDetail[] ) {}
       */
  
      const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('oneDateCalendar')
      this.newTabIndex0 = personalFeatureContainerListItem.featureContainer_ids.length+1

      const new_id = 'singleDateCalen'+this.newTabIndex0.toString()
      const newComponentName = 'oneDateCalendar'
        const newUIID ='oneDateCalendar-'+this.newTabIndex0.toString()
      const newOwner = this.user ; 
      const newAllUsersAllowed = false 
      const newUserListAllowed = []
      const newDateAndTime = null; 
      const newName =  'new tab '+ this.newTabIndex0.toString() ;

      const newDescription = 'edit new calendar'+ this.newTabIndex0.toString();
      const newDate = null

      console.log(newDate)
  
      const newDetail =  new  SingleDateCalendarDetail ( newDescription, newDate) ;
  
      const newDetails =  [newDetail] ;
      const newSingleDateCalendar = new SingleDateCalendar ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed, newDateAndTime, newName , newDetails);
  

      if ( this.user === null ){

        this.singleDateCalendarService.onAddNew(newSingleDateCalendar);

        this.newtabIndex = this.singleDateCalendarService.getSingleDateCalendars().length-1;
    
        const newOwner = this.user; 
        const newIsNew = false;
        const newDropId = this.currentI ;
        const newFeature_id = newSingleDateCalendar._id
        const newFeatureName = 'oneDateCalendar' ;
        const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
       
        this.feature = newFeature
    
        this.createNewFeatureContainerAndAddToFeatureContainerListItemService.createFeatureContainer_toAddOnFeatureContainerListItem(newFeature, this.user )
       
      }

      if ( this.user !== null ){
 
        this.postComponentsHttpRequestService.httpStoreComponent(this.user, 'oneDateCalendar', newSingleDateCalendar )
            .subscribe((response: SingleDateCalendar)=> {

    this.singleDateCalendarService.onAddNew(response);

    const newOwner = this.user; 
    const newIsNew = false;
    const newDropId = this.currentI ;
    const newFeature_id = response._id
    const newFeatureName = 'oneDateCalendar' ;
    const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
   
    this.feature = newFeature

    this.createNewFeatureContainerAndAddToFeatureContainerListItemService.createFeatureContainer_toAddOnFeatureContainerListItem(newFeature, this.user )
   
  }, error => {
    console.log(error)

    const errorMessage: string = "we weren't able to create the new component, try later, check the network"
    this.error = errorMessage
    this.isLoadingList = false 
    /**
     *  EMIT and event to paragraphEdit to put update or create buttons for 4,5 sec 
     */
    this.holdOnCreateUpdateButtons.emit()

    /**
     * return to previous set up without the new component after a while to be able to read the message 
     */
    setTimeout(()=>{
      this.error = null 
      this.isLoadingList = false 
      this.isLoading = false 
    },4000)
  })
}
  
}

onEmitToParagraphEditChangedIndex(toEmitFeature: Feature ){
  console.log('is emitting changed feature')
  this.changeIndex.emit(toEmitFeature)
  // once this feature is emitted the paragraphEdit will change index and this whole 
  // component will restart again
}

  

  onEditFeatureItem(){
    console.log('is opening another dialog from list component ')
    console.log(this.currentI)
    console.log(this.currentFeature)

      if( this.currentFeature.dropId === this.currentI){
        this.clickOnEditFeatureItem.next(this.currentFeature)
       }
   
    console.log('is arrived')
  }


   /**
   * 
   * @param data feature that is been transmitted from paragraphEdit to check which of the 
   * features list has to pass the subscription to the feature item to be edited 
   */
  updateCurrentFeature(data){
      this.currentFeature = data;
      console.log(this.currentFeature)
      }



  ngOnDestroy(){

      this.updateOnUserEmail.unsubscribe()
      this.receiveFeatureInfoFromParagraphEdit.unsubscribe();
      this.editFeatureEventFromParagraphEdit.unsubscribe();
      this.singleDateCalendarsHaveBeenStoredSubscription.unsubscribe()

      }

}
