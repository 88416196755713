import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormArray, FormBuilder} from '@angular/forms' ;
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { Feature } from '../../feature.model';

@Component({
  selector: 'app-horizon-add',
  templateUrl: './horizon-add.component.html',
  styleUrls: ['./horizon-add.component.css']
})
export class HorizonAddComponent implements OnInit {

  user: string

  @Input() feature: Feature

  constructor(private _fb: FormBuilder, public dialogref: MatDialogRef<HorizonAddComponent>,
    private userObjectUpdateService: UserObjectUpdateService ,
    @Inject(MAT_DIALOG_DATA) public editData: any) { }
  
    public addmore: FormGroup;


  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

    console.log('feature = ', this.feature)

    console.log("editData", this.editData)
    this.addmore = this._fb.group({
      title:[''],
      type:[''],
      description:[''],
      ItemRows: this._fb.array([this.initItemRows()])
    });
  }

get formArr(){
  return this.addmore.get('ItemRows') as FormArray;
}

// get f(){
//      return this.addmore.controls;
//    }

initItemRows(){
  return this._fb.group({
    timerange:[''],
    learn:[''],
    description:[''],
    suggestion:[''],
  });
}

addNewRow(){
  this.formArr.push(this.initItemRows());
}

deleteRow(index: number ){
this.formArr.removeAt(index);
}

submit(){
  console.log(this.addmore.value);
}




}
