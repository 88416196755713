import { ChangeDetectorRef, Component, Inject, NgZone, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HeaderService } from 'src/app/header/header.service';
import { SharedCalendarStatus } from '../shared-calendar-status.model';
import { RangeInfo } from '../range-info.model';
import { Subject, Subscription } from 'rxjs';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { ErrorsDialogManagerService } from 'src/app/services/other-components-services/errors-dialog-manager.service';
import { SharedCalendarStatusService } from '../shared-calendar-status.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';

interface IndexRangeInfo{
  index: number ,
  rangeInfo: RangeInfo
}

interface InputLabelInterface {

  exist: boolean ,
  name: string,
  maxLength: number,
  errorHandler: boolean 
}



@Component({
  selector: 'app-shared-calendar-status-edit',
  templateUrl: './shared-calendar-status-edit.component.html',
  styleUrls: ['./shared-calendar-status-edit.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SharedCalendarStatusEditComponent implements OnInit, OnDestroy {


  user: string 
  updateOnUserEmail: Subscription

  sharedCalendarStatusItem: SharedCalendarStatus

  isEditingOne: boolean = false 
  isLoading: boolean = false 

  isParagraphEdit: boolean = true 

  isEditingPositionInArray: number

  isEditingInnerlink: boolean = false
  isEditingInnerDropdown: boolean = false
  isEditingInnerCheckbox: boolean = false
  isEditingInnerSlidetoggle: boolean = false
  isEditingInnerButton: boolean = false
  isEditingInnerText: boolean = false 
  isEditingInnerInput: boolean = false

  rangeInfo: RangeInfo 
  accessType: string = 'date range'

  isAddingRangeInfo: boolean = false 
  additionalRangeInfo: RangeInfo 

  inputSharedCalendarStatusItemNameInterface: InputLabelInterface
  emptyInputNameAfterAdded = new Subject<void>()

  changeSelectedDates = new Subject<void>() // will never fire from here 
  

  constructor( private dialogRef: MatDialogRef<SharedCalendarStatusEditComponent>,
               private headerService: HeaderService,
               private ngZone: NgZone, 
               private cdr: ChangeDetectorRef,
               private sharedCalendarStatusService: SharedCalendarStatusService,
               private postComponentsHttpRequestService: PostComponentsHttpRequestService, 
               private errorsDialogManagerService: ErrorsDialogManagerService ,
               private userObjectUpdateService: UserObjectUpdateService,
               @Inject(MAT_DIALOG_DATA) public editData: any
             ) { }

  ngOnInit(): void {
    console.log('app-shared-calendar-status-edit', this.editData.sharedCalendarStatusItem )

    this.user = this.userObjectUpdateService.userEmail
 
    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
     .subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
    })

    this.sharedCalendarStatusItem = this.editData.sharedCalendarStatusItem

    this.inputSharedCalendarStatusItemNameInterface = { exist: true, name: 'component name', maxLength: 30, errorHandler: true }
   
    this.additionalRangeInfo = this.sharedCalendarStatusService.createAdditionalRangeInfoDropdown()

  }


  onDeleteIndexRangeInfo($event){
    this.onDeleteType($event)

  }

  onUpdateNameInputEvent($event){
    console.log($event)
    this.sharedCalendarStatusItem.name = $event.name
  }

  onUpdateNameInputValidity($event){
    console.log($event)
  }

  onSubmitName(){}

  onDeleteType( i: number ){

    console.log(i, )

    this.isEditingOne = false 

    /**
     * is editing the rangeDateInfoSetting
     */
    this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos.splice(i, 1)

  }


  onInEditingOne(i: number , type: string){

    this.isEditingOne = true 
    this.additionalRangeInfo = this.sharedCalendarStatusService.createAdditionalRangeInfoDropdown()
    console.log(i, type)

    this.isEditingPositionInArray = i

    // this.ngZone.run(()=> {
      if (type === 'innerlink'){ 
        this.isEditingInnerlink = true 
      }
      if (type === 'innerdropdown'){
        this.isEditingInnerDropdown = true
      }
      if (type === 'innercheckbox'){
        this.isEditingInnerCheckbox = true
      }
      if (type === 'innerslidetoggle'){
        this.isEditingInnerSlidetoggle = true 
      }
      if ( type === 'innerbutton'){
        this.isEditingInnerButton = true 
      }
      if ( type === 'innertext'){
        this.isEditingInnerText = true 
      }
      if ( type === 'innerinput'){
        this.isEditingInnerInput = true 
      }
    
  

  }

  onSubmitInnerFeature($event){

    const newIndexRangeInfo: IndexRangeInfo = $event
    console.log(newIndexRangeInfo)

    this.isEditingInnerlink = false
    this.isEditingInnerDropdown = false
    this.isEditingInnerCheckbox = false
    this.isEditingInnerSlidetoggle = false 
    this.isEditingInnerButton = false 
    this.isEditingInnerText = false 
    this.isEditingInnerInput = false


    this.isEditingOne = false

     

  }

  onUpdateUsersAccess($event){
    console.log($event)
    //this.sharedCalendarStatusItem.usersAccess = $event

  }


  onEmitUpdatedIndexRangeInfoSettings($event){
    console.log($event)

    const i = $event.index
    const type = $event.rangeInfo.type

    this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos[i] = $event.rangeInfo


    this.isEditingOne = false
    console.log(i, type)

     this.isEditingPositionInArray = i

      this.isEditingInnerlink = false
      this.isEditingInnerDropdown = false
      this.isEditingInnerCheckbox = false
      this.isEditingInnerSlidetoggle = false 
      this.isEditingInnerButton = false 
      this.isEditingInnerText = false 
      this.isEditingInnerInput = false 

  }

  onAddDetails(){
    console.log('adding details type')
    this.isAddingRangeInfo = true 
  }

  onAddRangeInfoType(){
    console.log('add rangeInfoType')

    this.isAddingRangeInfo = false 
  }

  onGetInnerdropdownSelectedValue($event: any){

    console.log( 'what has been selected => ',$event.rangeInfo_index, $event.newRangeInfo) //rangeInfo_index, newRangeInfo

    const selectedRangeInfo: RangeInfo = $event.newRangeInfo
    const selectedType: number = selectedRangeInfo.rangeDetails.findIndex(x=> x.selected === true )

    if ( selectedType === 0 ){ 
         console.log('is adding dropdowns') 

         const newType = "innerdropdown"
         const newRangeInfo = this.sharedCalendarStatusService.createNewInnerDropdown()

         this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos.push(newRangeInfo)

         const newIndex = this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos.length-1

         this.onInEditingOne( newIndex, newType )

    }

    if ( selectedType === 1 ){ 
         console.log('is adding checkboxes')

         const newType = "innercheckbox"
         const newRangeInfo = this.sharedCalendarStatusService.createNewInnerCheckbox()

         this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos.push(newRangeInfo)

         const newIndex = this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos.length-1
         this.onInEditingOne( newIndex, newType )


    }

    if ( selectedType === 2 ){ 
         console.log('is adding texts') 

         const newType = "innertext"
         const newRangeInfo = this.sharedCalendarStatusService.createNewInnerText()

         this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos.push(newRangeInfo)

         const newIndex = this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos.length-1
         this.onInEditingOne( newIndex, newType )

    }

    if ( selectedType === 3 ){ 
         console.log('is adding input') 

         const newType = "innerinput"
         const newRangeInfo = this.sharedCalendarStatusService.createNewInnerInput()

         this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos.push(newRangeInfo)

         const newIndex = this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos.length-1
         this.onInEditingOne( newIndex, newType )


    }
    
  }



  onCloseDialog(){

    if ( this.user !== null ){
      this.postComponentsHttpRequestService.httpUpdateComponent( this.user , 'sharedCalendarStatus', this.sharedCalendarStatusItem )
          .subscribe((response)=> {
        console.log(response) 

        this.dialogRef.close(this.sharedCalendarStatusItem),
        this.headerService.nextComponentIndexSelection(false)
    
        }, error => {
          console.log(error)

          this.dialogRef.close( undefined ),
          this.headerService.nextComponentIndexSelection(false)

          const errorMessage: string = "we weren't able to save the data on the server, maybe the network wasn't working. While we keep a local update, try again later to make it permanent"
          this.errorsDialogManagerService.PopUpErrorDialog({ textContent: errorMessage })
      
        }
        )
    }

    if ( this.user == null ){
      this.dialogRef.close(this.sharedCalendarStatusItem),
      this.headerService.nextComponentIndexSelection(false)
    }
   
  }

  ngOnDestroy(): void {
    this.updateOnUserEmail?.unsubscribe()
  }

}
