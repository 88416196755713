import { Component, OnInit, Inject } from '@angular/core';
import { FixLayout } from "../fix-layout.model"
import { FixLayoutTableTD } from '../fix-layout-table-td.model';
import { FixLayoutService} from "../fix-layout.service"
import { FormGroup, FormControl, FormBuilder, Validators, FormArray} from '@angular/forms';
import { MatDialogRef , MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-fix-layout-edit-chart',
  templateUrl: './fix-layout-edit-chart.component.html',
  styleUrls: ['./fix-layout-edit-chart.component.css']
})
export class FixLayoutEditChartComponent implements OnInit {

fixLayoutEditChartForm: FormGroup 

i = this.editData.indexI
j = this.editData.indexJ
editFixLayout: FixLayout
editFixLayoutTableTD: FixLayoutTableTD

share: number
total: number


  constructor(  public dialogRef: MatDialogRef<FixLayoutEditChartComponent>,
    @Inject(MAT_DIALOG_DATA) public editData: any ) { }


ngOnInit(): void {

this.editFixLayout = this.editData.fixLayout
console.log(this.editFixLayout)

this.editFixLayoutTableTD = this.editFixLayout.fixLayoutTableTRs[this.i].fixLayoutTableTDs[this.j]
console.log(this.editFixLayoutTableTD)

this.share = this.editFixLayout.fixLayoutTableTRs[this.i].fixLayoutTableTDs[this.j].tableTDDetailChartShare
this.total = this.editFixLayout.fixLayoutTableTRs[this.i].fixLayoutTableTDs[this.j].tableTDDetailChartTotal


this.fixLayoutEditChartForm = new FormGroup ({
  share: new FormControl (this.share),
  total: new FormControl (this.total)
})
}

onSubmitChart(){
  console.log(this.fixLayoutEditChartForm)
  if (this.fixLayoutEditChartForm.invalid){
    return
  }
  this.dialogRef.close(this.fixLayoutEditChartForm.value),
  this.editData.value
}

}
