import { Injectable } from "@angular/core";
import { CalculatorMixTable } from "./calculator-mix-table.model";
import { InitialNullSetupService } from "src/app/services/starting-services/initial-null-setup.service";
import { Subject, Subscription } from "rxjs";
import { StoreFormItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-form-item-plus-childs-confirmation.service";
import { StoreParagraphItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-paragraph-item-plus-childs-confirmation.service";
import { StoreTabItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-tab-item-plus-childs-confirmation.service";


@Injectable ({
    providedIn: 'root' 
})

export class CalculatorMixTableService {

    constructor( private initialNullSetupService: InitialNullSetupService , 
                 private storeFormItemPlusChildsConfirmationService: StoreFormItemPlusChildsConfirmationService ,
                 private storeParagraphItemPlusChildsConfirmationService: StoreParagraphItemPlusChildsConfirmationService ,
                 private storeTabItemPlusChildsConfirmationService: StoreTabItemPlusChildsConfirmationService
                ){}

    calculatorMixTables: CalculatorMixTable[] = this.initialNullSetupService.initialNullCalculatorMixTables

    initialPersonalCalculatorMixtablesHaveBeenStored = new Subject<void>()


    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.calculatorMixTablesChangedBackToNull
    .subscribe((res)=> {
          this.calculatorMixTables = res 
          console.log('updated calculatorMixTables back to null = ', res)

    });
    
   
    getCalculatorMixTable(index: number){
        return this.calculatorMixTables[index]
    };

    getCalculatorMixTable_by_id(_id: string ){
        const newCalculatorMixTable: CalculatorMixTable = this.calculatorMixTables.find(
            (res)=> {
                return res._id === _id
            }
        )
        return newCalculatorMixTable
    }

    getCalculatorMixTables(){
        return this.calculatorMixTables
    };

    getSelectedDescriptionParts(id: number , categoryName: string){
        console.log(id, categoryName)
        return this.calculatorMixTables[id].descriptionFrame.descriptionParts.filter( descriptionPart => descriptionPart.category == categoryName)
    }

    onAddNew( calculatorMixTable: CalculatorMixTable){

        this.calculatorMixTables.push(calculatorMixTable)
        this.updateInitialComponents(this.calculatorMixTables)
    };

    addNewCalculatorMixTables(calculatorMixTables: CalculatorMixTable[]){

        for ( let i=0; i<calculatorMixTables.length ; i++){
            if ( this.calculatorMixTables.findIndex(x=> x._id === calculatorMixTables[i]._id ) === -1 ){
                 this.calculatorMixTables.push(calculatorMixTables[i])
            }
            if ( i === calculatorMixTables.length-1){
                this.updateInitialComponents(this.calculatorMixTables)
                console.log(this.calculatorMixTables)
                this.initialPersonalCalculatorMixtablesHaveBeenStored.next()
            }
        }
        // console.log(this.calculatorMixTables)
        // this.initialPersonalCalculatorMixtablesHaveBeenStored.next()
    }

    addNewCalculatorMixTable(calculatorMixTableToAdd: CalculatorMixTable , isComingFromFormItemCall: boolean , isComingFromMultiFeatures: boolean ){

        if ( this.calculatorMixTables.findIndex(x=> x._id === calculatorMixTableToAdd._id ) === -1 ) { 
             this.calculatorMixTables.push(calculatorMixTableToAdd) 
             this.updateInitialComponents(this.calculatorMixTables)
             }
        if ( isComingFromFormItemCall === true  ){ this.storeFormItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenFormItemPlusChildsAreStored( calculatorMixTableToAdd._id) }
        if ( isComingFromFormItemCall === false ){ this.storeParagraphItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenParagraphItemPlusChildsAreStored( calculatorMixTableToAdd._id) }
        if ( isComingFromMultiFeatures=== false ){ this.storeTabItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenTabItemPlusChildsAreStored( calculatorMixTableToAdd._id) }
        console.log('calculatorMixTable = ', this.calculatorMixTables)
    }

    updateInitialComponents(updatedCalculatorMixTables: CalculatorMixTable[]){
        this.initialNullSetupService.initialNullCalculatorMixTables = updatedCalculatorMixTables
    }

    ngOnDestroy(){
        this.updateInitialNullDatafromInitialNullSetupService.unsubscribe()
    }
        
}

