<div *ngIf="recipientIndexColumn_exist">

<div class="example-box" (click)="onHideRecipientIndexes()" >
    <a class="navbar-brand" style="color: darkgrey;" > {{recipientIndexColumn.name}} </a>
    <span [hidden]="!hideRecipientIndexes">
        <svg style="color: darkgrey;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
          <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
        </svg>
      </span>
      <span [hidden]="hideRecipientIndexes">
        <svg xmlns="http://www.w3.org/2000/svg" style="color: darkgrey;"   width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
        </svg>
      </span>
</div>

<div *ngIf="!hideRecipientIndexes">
<div *ngFor="let recipientIndexListItem_id of recipientIndexColumn.recipientIndexListItem_ids ">

       <app-recipient-index-list-item
       [youAreInFormEdit]="youAreInFormEdit"
       [recipientIndexListItem_id]="recipientIndexListItem_id"
       [recipientIndexColumn]="recipientIndexColumn"
       (updateRecipientIndex)='onUpdateRecipientIndex($event)'>
       </app-recipient-index-list-item>

</div>

<div *ngIf="!hideButton" >
  <div *ngIf="recipientIndexColumn.type === 'personal'" class="example-box">

    <mat-chip style="background-color: grey ; color: white ; width:200px "
              (click)="onHideButton()"> 
      + recipient group
    </mat-chip>

   </div>
</div>



<form [formGroup]="recipientIndexForm" (ngSubmit)="onSubmit()" >
<div *ngIf="recipientIndexColumn.type === 'personal'">
<div *ngIf="hideButton" class="example-box"  >
      
  <input 
        type="text" 
        id="recipientIndexName"
        formControlName="recipientIndexName"
        class="form-control"
        placeholder="group name"
  >

  <button type="submit" 
          class="btn btn-secondary" >
          +
  </button>

 </div>
</div>
</form>
</div>

</div>
