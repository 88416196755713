<p>form-input works!</p>

<!-- <div class="example-container"> -->

<form class="example-form">
    <mat-form-field class="example-full-width">
      <mat-label>Company (disabled)</mat-label>
      <input matInput disabled value="Google">
    </mat-form-field>
  
    <table class="example-full-width" cellspacing="0"><tr>
      <td><mat-form-field class="example-full-width">
        <mat-label>First name</mat-label>
        <input matInput>
      </mat-form-field></td>
      <td><mat-form-field class="example-full-width">
        <mat-label>Long Last Name That Will Be Truncated</mat-label>
        <input matInput>
      </mat-form-field></td>
    </tr></table>
  
    <p>
      <mat-form-field class="example-full-width">
        <mat-label>Address</mat-label>
        <textarea matInput placeholder="Ex. 100 Main St">1600 Amphitheatre Pkwy</textarea>
      </mat-form-field>
      <mat-form-field class="half-width">
        <mat-label>Address 2</mat-label>
        <textarea matInput></textarea>
      </mat-form-field>
    </p>
  
    <table class="example-full-width" cellspacing="0"><tr>
      <td><mat-form-field class="example-full-width">
        <mat-label>City</mat-label>
        <input matInput placeholder="Ex. San Francisco">
      </mat-form-field></td>
      <td><mat-form-field class="half-width">
        <mat-label>State</mat-label>
        <input matInput placeholder="{{trial}}">
      </mat-form-field></td>
      <td><mat-form-field class="example-full-width">
        <mat-label>Postal Code</mat-label>

<!-- controllare come fare per fare il maxlength = variabile  -->
        <input matInput #postalCode maxlength="7" placeholder="Ex. 94105" value="94043">
        <mat-hint align="end">{{postalCode.value.length}} / {{maximun}}</mat-hint>
      </mat-form-field></td>
    </tr></table>

    <table cellspacing="0"><tr>
      <td><mat-form-field >
        <mat-label>City</mat-label>
        <input matInput placeholder="Ex. San Francisco">
      </mat-form-field></td>
      <td><mat-form-field >
        <mat-label>State</mat-label>
        <input matInput placeholder="{{trial}}">
      </mat-form-field></td>
      <td><mat-form-field >
        <mat-label>Postal Code</mat-label>

<!-- controllare come fare per fare il maxlength = variabile  -->
        <input matInput #postalCode maxlength="7" placeholder="Ex. 94105" value="94043">
        <mat-hint >{{postalCode.value.length}} / {{maximun}}</mat-hint>
      </mat-form-field></td>
    </tr></table>
  </form>


<!-- </div> -->