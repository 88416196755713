import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { SharedCalendarStatus } from './shared-calendar-status.model';
import { MatTabGroup } from '@angular/material/tabs';
import { Feature} from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/feature.model';
import { Observable, Subscription, Subject } from 'rxjs';
import { DateTime } from 'luxon';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { CreateNewFeatureContainerAndAddToFeatureContainerListItemService } from 'src/app/services/shared-services/create-new-feature-container-and-update-feature-container-list-item.service';
import { AdviseIfHttpStoreComponentsCallHasBeenMadeService } from 'src/app/services/other-components-services/advise-if-http-store-components-has-been-made.service';
import { StoreBaseComponentsService } from 'src/app/services/get-and-store-components/store-base-components.service';
import { FeatureContainerListItemService } from '../../../../paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container-list-item/feature-container-list-items.service';
import { FeatureIndexTopicList } from '../../../../paragraph-edit/feature-index-topic-list.model';
import { FeatureIndexTopicListService } from '../../../../paragraph-edit/feature-index-topic-list.service';
import { FeatureContainerListItem } from '../../../../paragraph-edit/feature-container-list-item.model';
import { FeatureContainer } from '../../../../paragraph-edit/feature-container.model';
import { FeatureContainerService } from '../../../../paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container/feature-container.service';
import { SharedCalendarStatusService } from './shared-calendar-status.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { CanDo } from './users-access-edit/who-can-do.model';
import { UsersAccess } from './users-access-edit/users-access.model';
import { RangeInfo } from './range-info.model';
import { InnerArraySelect } from './inner-array-select.model';
import { FixTextDetail } from '../../texts/fix-text-list/fix-text-detail.model';
import { DateStartEnd } from './date-start-end.model';
import { RangeDatesInfo } from './range-dates-info.model';
import { StorePersonalFeatureIndexComponentsService } from 'src/app/services/store-initial-components/store-personal-feature-index-components.service';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { GetComponentsHttpRequestService } from 'src/app/services/gets-services/get-components-http-request.sevice';

@Component({
  selector: 'app-shared-calendar-status-list',
  templateUrl: './shared-calendar-status-list.component.html',
  styleUrls: ['./shared-calendar-status-list.component.css']
})
export class SharedCalendarStatusListComponent implements OnInit {

  user: string

  thisTypeOfComponent: string = 'sharedCalendarStatus'

  sharedCalendarStatusItem: SharedCalendarStatus
  sharedCalendarStatusItems: SharedCalendarStatus[]

  index: number
  selectedIndex: number  ;

  @Input() feature_from_paragraphEdit: Feature 
  @Input() isParagraphEdit: boolean;
  @Input() currentI: number;

  @Output() changeIndex = new EventEmitter<Feature>()  ;
  @Output() eventIndex: number ;

  /**
  * receiving subscription from ParagraphEdit to edit the component 
  */
  subscriptionToEditComponentItemIsOn: boolean

  feature: Feature;
  currentFeature: Feature;
  @Input() editFeature: Observable<void> ;

   /** limit on the number of features to add */
  createButtonLimit: boolean
  buttonsLimit = 25 ;

  @ViewChild('tabs') tabGroup: MatTabGroup

  /**
  * click and receive the results from paragraph Edit
  */
  editFeatureEventFromParagraphEdit: Subscription;
  clickOnEditFeatureItem: Subject<Feature> = new Subject<Feature>()
     
     
  receiveFeatureInfoFromParagraphEdit: Subscription; 
  @Input() collectFeatureId: Observable<Feature>

  newTabIndex0: number;
  newtabIndex: number;

  isLoading: boolean
  isLoadingList: boolean

  newInputCreated : boolean

  updateOnUserEmail: Subscription 

  sharedCalendarStatusItemsHaveBeenStoredSubscription: Subscription
  featureContainerPlusParentsHaveBeenStored: Subscription 

  @Output() holdOnCreateUpdateButtons = new EventEmitter<void>()
  error = null 

  matToolTipMessageOnCreateNew: string
  featureContainersLength_isStillTheSame: boolean


  constructor( private userObjectUpdateService: UserObjectUpdateService , 
               private adviseIfHttpStoreComponentsCallHasBeenMadeService: AdviseIfHttpStoreComponentsCallHasBeenMadeService,
               private storeBaseComponentsService: StoreBaseComponentsService,
               private featureContainerListItemService: FeatureContainerListItemService, 
               private featureIndexTopicListService: FeatureIndexTopicListService,
               private featureContainerService: FeatureContainerService,
               private createNewFeatureContainerAndAddToFeatureContainerListItemService: CreateNewFeatureContainerAndAddToFeatureContainerListItemService,
               private postComponentsHttpRequestService: PostComponentsHttpRequestService,
               private sharedCalendarStatusService: SharedCalendarStatusService,
               private breakpointObserver: BreakpointObserver,
               private storePersonalFeatureIndexComponentsService: StorePersonalFeatureIndexComponentsService,
               private initialNullSetupService: InitialNullSetupService , 
               private getComponentsHttpRequestService: GetComponentsHttpRequestService , 
               private ngZone: NgZone, 
               private cdr: ChangeDetectorRef


    ) { }

  
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  ngOnInit(): void {

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
    .subscribe((updatedUserEmail)=>{
     this.user = updatedUserEmail
     console.log('updated USER = ', this.user)
    })

    console.log( 'this user is : ', this.user )

    this.subscriptionToEditComponentItemIsOn = false
    this.isLoadingList = true 

    if ( this.user === null ){ this.continueInitialOnInit()}
    if ( this.user !== null ){
          if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.singleDateCalendarsHasBeenStored === true  ){  this.continueInitialOnInit() }
          if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.singleDateCalendarsHasBeenStored === false ){ this.storeBaseComponentsService.callBaseComponentsListByUserAndType(this.user , 'sharedCalendarStatus') }
}

this.editFeatureEventFromParagraphEdit = this.editFeature.subscribe(() => this.onEditFeatureItem() )
this.receiveFeatureInfoFromParagraphEdit = this.collectFeatureId.subscribe((data)=> this.updateCurrentFeature(data))

this.sharedCalendarStatusItemsHaveBeenStoredSubscription = this.sharedCalendarStatusService.initialPersonalSharedCalendarStatusItemsHaveBeenStored
    .subscribe(()=> { 
     this.continueInitialOnInit()
     this.adviseIfHttpStoreComponentsCallHasBeenMadeService.sharedCalendarStatusItemsHaveBeenStored = true 
})

this.featureContainerPlusParentsHaveBeenStored = this.featureContainerListItemService.newFeatureContainerAndparentsHaveBeenStored
    .subscribe((feature_plus_typeOfComponent: any )=> {

     const currentFeature:Feature = feature_plus_typeOfComponent.feature 
     const currentTypeOfComponent: string = feature_plus_typeOfComponent.featureContainerName
     console.log(feature_plus_typeOfComponent)
 
     if ( currentTypeOfComponent === this.thisTypeOfComponent){
          this.onEmitToParagraphEditChangedIndex(currentFeature)
     }
    })
  }

  continueInitialOnInit(){

    console.log('IT S CONTINUING INITIAL ONINIT')

    const featureIndexTopicListItem: FeatureIndexTopicList = this.featureIndexTopicListService.getFeatureIndexTopicListItem_by_type('personal')
    console.log('feature index topic list item = ',featureIndexTopicListItem)
  
    const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('sharedCalendarStatus')

    this.sharedCalendarStatusItems = []
    for ( let i=0; i<personalFeatureContainerListItem.featureContainer_ids.length ; i++ ){
          let currentFeatureContainer: FeatureContainer = this.featureContainerService.getFeatureContainerBy_id(personalFeatureContainerListItem.featureContainer_ids[i])
          this.sharedCalendarStatusItems.push(this.sharedCalendarStatusService.getSharedCalendarStatus_By_id(currentFeatureContainer.componentReference_id))
    }

    if ( this.user !== null ){ this.sharedCalendarStatusItems.reverse() }
    

  this.selectedIndex =  this.sharedCalendarStatusItems.findIndex(x=> x._id === this.feature_from_paragraphEdit.feature_id)
    console.log('selected index_id + selected index : ', this.selectedIndex)

    this.matToolTipMessageOnCreateNew = " CREATE A COPY OF  '1 - "+this.sharedCalendarStatusItems[0].name+"'"

    if (this.sharedCalendarStatusItems.length < this.buttonsLimit){ this.createButtonLimit = true }
    if ( this.sharedCalendarStatusItems.length >= this.buttonsLimit ) { this.createButtonLimit = false }
     
    if (this.user === null ){ setTimeout(()=>{ this.isLoading = false , this.isLoadingList = false },200) }
    if (this.user !== null ){ this.isLoading = false , this.isLoadingList = false  }

  }

  onLinkClick(event){

    this.index = this.tabGroup.selectedIndex ; 

    const newOwner = this.user; 
    const newIsNew = false;
    const newDropId = this.currentI;
    const newFeature_id = this.sharedCalendarStatusItems[this.index]._id
    const newFeatureName = 'sharedCalendarStatus' ;
    const newFeature = new Feature (  newOwner, newIsNew,  newDropId , newFeature_id, newFeatureName) ;
   
    this.feature = newFeature;
   
    this.changeIndex.emit(this.feature)
    this.eventIndex = this.currentI ;
   
    console.log('drop id =', this.currentI)
    console.log(this.feature)


  }

  onCreate(){

    this.isLoading = true
    this.isLoadingList = true 
    this.onCreateNew()
        
  }

  onCreateNew(){

    if ( this.user !== null ){ this.onExistingUserCreateComponent()}
    if ( this.user === null ){ this.onNullUserCreateComponent() }
  }


  onNullUserCreateComponent(){

    const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('sharedCalendarStatus')
    this.newTabIndex0 = personalFeatureContainerListItem.featureContainer_ids.length+1

    const new_id = 'sharedCalendarSta-'+this.newTabIndex0.toString()
    const newComponentName = 'sharedCalendarStatus'
    const newUIID ='sharedCalendarSta-'+this.newTabIndex0.toString()
    const newOwner = this.user ; 
    const newAllUsersAllowed = false 
    const newUserListAllowed = []
    const newDateAndTime = null; 
    const newName =  'new tab '+ this.newTabIndex0.toString() ;

         const newDateStartEnd = new DateStartEnd ('', '')

         const newType1 = "innerdropdown"
         const newName1 = "set dropdown title"

         const newUsersAccess1: UsersAccess = this.sharedCalendarStatusService.createEmptyUsersAccess()
         const newInnerArraySelectDropdown1 = new InnerArraySelect ('edit dropdown', false )
         const newRangeDetails1: InnerArraySelect[] = [ newInnerArraySelectDropdown1 ]

         const newRangeInfo1 = new RangeInfo (newType1, newName1, newUsersAccess1, newRangeDetails1 )

         const newType2 = "innercheckbox"
         const newName2 = "set checkbox title"

         const newUsersAccess2: UsersAccess = this.sharedCalendarStatusService.createEmptyUsersAccess()
         const newInnerArrayCheckbox2 = new InnerArraySelect ('edit checkbox', false )
         const newRangeDetails2: InnerArraySelect[] = [ newInnerArrayCheckbox2 ]

         const newRangeInfo2 = new RangeInfo (newType2, newName2, newUsersAccess2, newRangeDetails2 )

         const newType3 = "innertext"
         const newName3 = "set text title"

         const newUsersAccess3: UsersAccess = this.sharedCalendarStatusService.createEmptyUsersAccess()
         const newInnerArrayText3 = new FixTextDetail ('edit text')
         const newRangeDetails3: FixTextDetail[] = [ newInnerArrayText3  ]

         const newRangeInfo3 = new RangeInfo (newType3, newName3, newUsersAccess3, newRangeDetails3 )

         const newType4 = "innerinput"
         const newName4 = "set input title"

         const newUsersAccess4: UsersAccess = this.sharedCalendarStatusService.createEmptyUsersAccess()
         const newInnerArrayText1 = new FixTextDetail ('rename this label')
         const newInnerArrayText2 = new FixTextDetail ('500')
         const newInnerArrayText4 = new FixTextDetail ('write an example')
         const newInnerArrayText5 = new FixTextDetail ('')
        
         const newRangeDetails4: FixTextDetail[] = [ newInnerArrayText1, newInnerArrayText2, newInnerArrayText4 , newInnerArrayText5 ]

         const newRangeInfo4 = new RangeInfo (newType4, newName4, newUsersAccess4, newRangeDetails4 )

         const newUsersAccess5: UsersAccess = this.sharedCalendarStatusService.createEmptyUsersAccess()

    const newRangeDateInfoSetting = new RangeDatesInfo ( newDateStartEnd, [newRangeInfo1, newRangeInfo2, newRangeInfo3, newRangeInfo4 ], newUsersAccess5)

    const newRangeDateInfos = []

    const newSharedCalendarStatusItem = new SharedCalendarStatus ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed, 
                                         newDateAndTime, newName , newRangeDateInfoSetting, newRangeDateInfos );


    if ( this.user === null ){

      this.sharedCalendarStatusService.onAddNew(newSharedCalendarStatusItem);

      const newOwner = this.user; 
      const newIsNew = false;
      const newDropId = this.currentI ;
      const newFeature_id = newSharedCalendarStatusItem._id
      const newFeatureName = 'sharedCalendarStatus' ;
      const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
     
      this.feature = newFeature
  
      this.createNewFeatureContainerAndAddToFeatureContainerListItemService.createFeatureContainer_toAddOnFeatureContainerListItem(newFeature, this.user )
     
    }

}


isMobile(){
  // credit to Timothy Huang for this regex test: 
  // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      return true
 }
 else{
      return false
 }
}

onExistingUserCreateComponent(){

  this.storePersonalFeatureIndexComponentsService.getPersonalUpdatedFeatureIndex_components(this.user)
  .subscribe((res: any)=> {
    console.log(res)

    const httpFeatureIndexComponentResult: any = res 

               
                  const newFeatureContainers: FeatureContainer[] = httpFeatureIndexComponentResult.featureContainer
                  console.log(newFeatureContainers)
                  this.featureContainerService.featureContainers = newFeatureContainers
                  this.initialNullSetupService.initialNullFeatureContainers = newFeatureContainers
                 
                  const newFeatureContainerListItems: FeatureContainerListItem[] = httpFeatureIndexComponentResult.featureContainerListItem
                                       console.log(newFeatureContainerListItems)
                  this.featureContainerListItemService.featureContainerListItems = newFeatureContainerListItems
                  this.initialNullSetupService.initialNullFeatureContainerListItems = newFeatureContainerListItems

    /**
     * now check the  featueContainerListItem that belong to this componentType : shareCalendarStatus
     * by filter or find index of name : wordInput
     */
     
    const latestFeatureContainers_length = newFeatureContainers.length
    const latestFeatureContainerListItems_length = newFeatureContainerListItems.length 

    const indexOfselectedFeatureContainerListItem = newFeatureContainerListItems.findIndex(x=> x.name === this.thisTypeOfComponent )
    const selectedFeatureContainerListItem = newFeatureContainerListItems[indexOfselectedFeatureContainerListItem]
    
    if ( selectedFeatureContainerListItem.featureContainer_ids.length === this.sharedCalendarStatusItems.length ){ this.featureContainersLength_isStillTheSame = true }
    if ( selectedFeatureContainerListItem.featureContainer_ids.length > this.sharedCalendarStatusItems.length ){ this.featureContainersLength_isStillTheSame = false }

    console.log(latestFeatureContainers_length, latestFeatureContainerListItems_length, selectedFeatureContainerListItem) 
                
    this.newTabIndex0 = selectedFeatureContainerListItem.featureContainer_ids.length+1
    
    const new_id = 'sharedCalendarSta-'+this.newTabIndex0.toString()
    const newComponentName = 'sharedCalendarStatus'
    const newUIID ='sharedCalendarSta-'+this.newTabIndex0.toString()
    const newOwner = this.user ; 
    const newAllUsersAllowed = false 
    const newUserListAllowed = []
    const newDateAndTime = null; 
    const newName =  'new tab '+ this.newTabIndex0.toString() ;

         const newDateStartEnd = new DateStartEnd ('', '')

         const newType1 = "innerdropdown"
         const newName1 = "set dropdown title"

         const newUsersAccess1: UsersAccess = this.sharedCalendarStatusService.createEmptyUsersAccess()
         const newInnerArraySelectDropdown1 = new InnerArraySelect ('edit dropdown', false )
         const newRangeDetails1: InnerArraySelect[] = [ newInnerArraySelectDropdown1 ]

         const newRangeInfo1 = new RangeInfo (newType1, newName1, newUsersAccess1, newRangeDetails1 )

         const newType2 = "innercheckbox"
         const newName2 = "set checkbox title"

         const newUsersAccess2: UsersAccess = this.sharedCalendarStatusService.createEmptyUsersAccess()
         const newInnerArrayCheckbox2 = new InnerArraySelect ('edit checkbox', false )
         const newRangeDetails2: InnerArraySelect[] = [ newInnerArrayCheckbox2 ]

         const newRangeInfo2 = new RangeInfo (newType2, newName2, newUsersAccess2, newRangeDetails2 )

         const newType3 = "innertext"
         const newName3 = "set text title"

         const newUsersAccess3: UsersAccess = this.sharedCalendarStatusService.createEmptyUsersAccess()
         const newInnerArrayText3 = new FixTextDetail ('edit text')
         const newRangeDetails3: FixTextDetail[] = [ newInnerArrayText3  ]

         const newRangeInfo3 = new RangeInfo (newType3, newName3, newUsersAccess3, newRangeDetails3 )

         const newType4 = "innerinput"
         const newName4 = "set input title"

         const newUsersAccess4: UsersAccess = this.sharedCalendarStatusService.createEmptyUsersAccess()
         const newInnerArrayText1 = new FixTextDetail ('rename this label')
         const newInnerArrayText2 = new FixTextDetail ('500')
         const newInnerArrayText4 = new FixTextDetail ('write an example')
         const newInnerArrayText5 = new FixTextDetail ('')
        
         const newRangeDetails4: FixTextDetail[] = [ newInnerArrayText1, newInnerArrayText2, newInnerArrayText4 , newInnerArrayText5 ]

         const newRangeInfo4 = new RangeInfo (newType4, newName4, newUsersAccess4, newRangeDetails4 )

         const newUsersAccess5: UsersAccess = this.sharedCalendarStatusService.createEmptyUsersAccess_OnlyOwnerCanEdit()

    const newRangeDateInfoSetting = new RangeDatesInfo ( newDateStartEnd, [newRangeInfo1, newRangeInfo2, newRangeInfo3, newRangeInfo4 ], newUsersAccess5)

    const newRangeDateInfos = []

    const newSharedCalendarStatusItem = new SharedCalendarStatus ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed, 
                                         newDateAndTime, newName , newRangeDateInfoSetting, newRangeDateInfos );
  

    const newFeatureContainer_UIID = 'featureContainer-'+(latestFeatureContainers_length+1).toString()


    const newSharedCalendarStatusItemAndParents = {

      "1": [ newSharedCalendarStatusItem ],
        "2": [
             {
          "componentName": "featureContainer",
          "UIID": newFeatureContainer_UIID,
          "owner":newOwner,
          "allUsersAllowed": false,
          "userListAllowed": [],
          "dateAndTime": null,
          "isActive": true ,
          "isAccepted": true,
          "name": this.thisTypeOfComponent,
          "componentReference_id": "@CHILD_ID@",
          "features": [
                       {
                       "owner": newOwner,
                       "isNew": false ,
                       "dropId": 0,
                       "feature_id": "@CHILD_ID@",
                       "featureName": this.thisTypeOfComponent
                       }
                      ]
           }
      ],
      "3": [
             {
          "componentName": "featureContainerListItem",
          "UIID": selectedFeatureContainerListItem.UIID,
          "owner":newOwner,
          "allUsersAllowed": selectedFeatureContainerListItem.allUsersAllowed,
          "userListAllowed": selectedFeatureContainerListItem.userListAllowed,
          "dateAndTime": selectedFeatureContainerListItem.dateAndTime,
          "type": selectedFeatureContainerListItem.type,
          "name": selectedFeatureContainerListItem.name,
          "featureContainer_ids": [selectedFeatureContainerListItem , "@CHILD_ID@"],
          "selectedFeatureContainer_id": selectedFeatureContainerListItem.selectedFeatureContainer_id
           }
      ]
    }

    this.postComponentsHttpRequestService.httpStoreMultiLevelComponentsWithUpdateOnExistingOnes( this.user, newSharedCalendarStatusItemAndParents )
        .subscribe((res: any)=>{
          console.log(res)

          const newSharedCalendarStatusItem2: SharedCalendarStatus = res.sharedCalendarStatus[0]
          const newFeatureContainer: FeatureContainer = res.featureIndex[0]
          const updatedFeatureContainerListItem: FeatureContainerListItem = res.featureIndex[1]

          this.sharedCalendarStatusService.onAddNew(newSharedCalendarStatusItem2)
          this.featureContainerService.addFeatueContainer(newFeatureContainer)
          this.featureContainerListItemService.updateFeatureContainerListItem(updatedFeatureContainerListItem)

          const newFeatureOwner = this.user; 
            const newIsNew = false;
            const newDropId = this.currentI ;
            const newFeature_id = newSharedCalendarStatusItem2._id
            const newFeatureName = 'sharedCalendarStatus' ;
            const newFeature = new Feature ( newFeatureOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;

            /**
             * if the featureContainerListItes was same length 
             */
            if ( this.featureContainersLength_isStillTheSame ){ this.onEmitToParagraphEditChangedIndex( newFeature )}
            if ( !this.featureContainersLength_isStillTheSame ){ 
                   
                this.getComponentsHttpRequestService.httpGetComponentsByType(this.user , 'sharedCalendarStatus' )
                    .subscribe((res: any )=> {
                      console.log(res)
                      
                      const updatedSharedCalendarStatusItems: SharedCalendarStatus[] = res.sharedCalendarStatus
                      console.log(updatedSharedCalendarStatusItems)
                      this.sharedCalendarStatusService.addSharedCalendarStatusItemsOnly(updatedSharedCalendarStatusItems)

                      const featureIndexTopicListItem: FeatureIndexTopicList = this.featureIndexTopicListService.getFeatureIndexTopicListItem_by_type('personal')
                      console.log('feature index topic list item = ',featureIndexTopicListItem)
                      const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('sharedCalendarStatus')

                      this.sharedCalendarStatusItems = []
                      for ( let i=0; i<personalFeatureContainerListItem.featureContainer_ids.length ; i++ ){
                            let currentFeatureContainer: FeatureContainer = this.featureContainerService.getFeatureContainerBy_id(personalFeatureContainerListItem.featureContainer_ids[i])
                            this.sharedCalendarStatusItems.push(this.sharedCalendarStatusService.getSharedCalendarStatus_By_id(currentFeatureContainer.componentReference_id))
                      }

                      this.sharedCalendarStatusItems.reverse()

                      this.onEmitToParagraphEditChangedIndex( newFeature )

                    }, error => {
                      console.log(error)
                    
                      const errorMessage: string = "we weren't able to create the new component, try later, check the network"
                      this.error = errorMessage
                      this.isLoadingList = false 
                      /**
                       *  EMIT and event to paragraphEdit to put update or create buttons for 4,5 sec 
                       */
                      this.holdOnCreateUpdateButtons.emit()
                    
                      /**
                       * return to previous set up without the new component after a while to be able to read the message 
                       */
                      setTimeout(()=>{
                        this.error = null 
                        this.isLoadingList = false 
                        this.isLoading = false 
                      },4000)
                    
                  }) // END 3RD SUBSCIPTION 
               

            }
            

        }, error => {
            console.log(error)
          
            const errorMessage: string = "we weren't able to create the new component, try later, check the network"
            this.error = errorMessage
            this.isLoadingList = false 
            /**
             *  EMIT and event to paragraphEdit to put update or create buttons for 4,5 sec 
             */
            this.holdOnCreateUpdateButtons.emit()
          
            /**
             * return to previous set up without the new component after a while to be able to read the message 
             */
            setTimeout(()=>{
              this.error = null 
              this.isLoadingList = false 
              this.isLoading = false 
            },4000)
          
        }) // end second subscription 



  }, error => {
    console.log( error )

    const errorMessage: string = "we weren't able to create the new component, try later, check the network"
    this.error = errorMessage
    this.isLoadingList = false 
    /**
     *  EMIT and event to paragraphEdit to put update or create buttons for 4,5 sec 
     */
    this.holdOnCreateUpdateButtons.emit()
  
    /**
     * return to previous set up without the new component after a while to be able to read the message 
     */
    setTimeout(()=>{
      this.error = null 
      this.isLoadingList = false 
      this.isLoading = false 
    },4000)

  }) // end 1st subscription to featureIndexes

}

onEmitToParagraphEditChangedIndex(toEmitFeature: Feature ){
  console.log('is emitting changed feature')
  this.changeIndex.emit(toEmitFeature)
  // once this feature is emitted the paragraphEdit will change index and this whole 
  // component will restart again
}

  

  onEditFeatureItem(){
    console.log('is opening another dialog from list component ')
    console.log(this.currentI)
    console.log(this.currentFeature)

      if( this.currentFeature.dropId === this.currentI){
        this.clickOnEditFeatureItem.next(this.currentFeature)
       }
   
    console.log('is arrived')
  }


   /**
   * 
   * @param data feature that is been transmitted from paragraphEdit to check which of the 
   * features list has to pass the subscription to the feature item to be edited 
   */
  updateCurrentFeature(data){
      this.currentFeature = data;
      console.log(this.currentFeature)
  }

  onUpdateListNamesAfterEdit(){
    this.ngZone.run(()=>{

      const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('sharedCalendarStatus')
      this.sharedCalendarStatusItems = []
    for ( let i=0; i<personalFeatureContainerListItem.featureContainer_ids.length ; i++ ){
          let currentFeatureContainer: FeatureContainer = this.featureContainerService.getFeatureContainerBy_id(personalFeatureContainerListItem.featureContainer_ids[i])
          this.sharedCalendarStatusItems.push(this.sharedCalendarStatusService.getSharedCalendarStatus_By_id(currentFeatureContainer.componentReference_id))
    }

    if ( this.user !== null ){ this.sharedCalendarStatusItems.reverse() }

    })
    this.cdr.detectChanges()
  }



  ngOnDestroy(){

      this.updateOnUserEmail.unsubscribe()
      this.receiveFeatureInfoFromParagraphEdit.unsubscribe();
      this.editFeatureEventFromParagraphEdit.unsubscribe();
      this.sharedCalendarStatusItemsHaveBeenStoredSubscription.unsubscribe()

      }

}
