

export class Form {
   
    constructor( 
        public _id: string,
        public componentName: string , 
        public UIID: string , 
        public owner: string , 
        public dateAndTime: string, 
        public updateDateAndTime: string, 
        public allUsersAllowed: boolean,
        public userListAllowed: string[],
        public canBeResharedByOthers: boolean , 
        public noNeedRecipientAndSubject: boolean ,
        public isFixedSubject: boolean , 
        public isShareble: boolean , 
        public isBeenShared: boolean, 
        public formSubject: string,
        public recipientIndex_id: string ,
        public paragraph_ids: string[]
      ){}
   
    }
