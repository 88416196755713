import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ParagraphTopic } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.model';
import { ParagraphTopicService } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.service';
import { FormGroup, FormControl} from '@angular/forms';
import { FormService } from 'src/app/forms/form.service';
import { IndexType } from 'src/app/forms/index-type.model';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { ParagraphTopicIndex } from '../paragraph-topic-index.model';
import { ParagraphTopicIndexService } from '../paragraph-topic-index.service';
import { ActivatedRoute , Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ParagraphFeatureContainerService } from './paragraph-index-topic/paragraph-feature-container.service';
import { StoreParagraphItemsService } from 'src/app/services/get-and-store-components/store-paragraph-items.service';
import { ParagraphService } from '../../../form-item/paragraph/paragraph.service';
import { take } from 'rxjs/operators';
import { CreateUpdateMultiComponentsService } from 'src/app/services/shared-services/create-update-multi-components.service';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { ParagraphFeatureContainer } from './paragraph-index-topic/paragraph-feature-container.model';
import { StorePersonalFeatureIndexComponentsService } from 'src/app/services/store-initial-components/store-personal-feature-index-components.service';
import { AddSharedFormOrParagraphService } from '../../../add-shared-form-or-paragraph/add-shared-form-or-paragraph.service';
import { FormIndexTopic } from '../../../form-index-topic-list/form-index-topic.model';
import { FormFeatureContainer } from '../../../form-index-topic-list/form-feature-container.model';
import { FormIndexTopicListItem } from '../../../form-index-topic-list/form-index-topic-list-item.model';

interface SharedParagraphForm_New_Old_Data {
  typeOfComponent: string , 
  formSide: FormSide ,
  paragraphSide: ParagraphSide, 
}

interface FormSide {
  oldFormIndexTopicListItem: FormIndexTopicListItem,
  updatedFormIndexTopicListItem: FormIndexTopicListItem,
  oldFormIndexTopic: FormIndexTopic ,
  updatedFormIndexTopic: FormIndexTopic,
  oldFormFeatureContainer: FormFeatureContainer,
  updatedFormFeatureContainer: FormFeatureContainer

}

interface ParagraphSide {
  oldParagraphTopicIndex: ParagraphTopicIndex,
  updatedParagraphTopicIndex: ParagraphTopicIndex,
  oldParagraphTopic: ParagraphTopic,
  updatedParagraphTopic: ParagraphTopic,
  oldParagraphFeatureContainer: ParagraphFeatureContainer,
  updatedParagraphFeatureContainer: ParagraphFeatureContainer
}


@Component({
  selector: 'app-paragraph-index-topic-list',
  templateUrl: './paragraph-index-topic-list.component.html',
  styleUrls: ['./paragraph-index-topic-list.component.css']
})
export class ParagraphIndexTopicListComponent implements OnInit, OnDestroy {

  user: string

  
  paragraphTopicIndex: ParagraphTopicIndex
  paragraphTopics: ParagraphTopic[];
  topicNameForm : FormGroup ;

  /** hide and show the add topic button  */
  hideButton = false ;

  /** boolean property used on ngIf to confront max topics number  */
  createButtonLimit : boolean ;

  /** limit number of Topics allowed to add  */
  topicsLimit = 19 ;

  trialParagraphTopics: ParagraphTopic[]
  isLoading = false
  error = true
  // noParagraphTopicsYet = false 
  paragraphTopic_ids_arePresent: boolean 
  

  paragraphParameter: string 
  newParagraphIndexType: IndexType

  isPersonal: boolean 
  isPublic: boolean 

  updateOnUserEmail: Subscription

  foundEmailList: string[] = []

  weAreInFormIndexTopicList: boolean = false 

  personalParagraphsHasBeenStored: boolean = false 
  sharedParagraphsHasBeenStored: boolean = false 
  preferredParagraphsHasBeenStored: boolean = false 


  updatePublicParagraphTopicIndexOnRemoveItemSubscription: Subscription
  stopLoadingParagraphTopicIndexSubscription: Subscription 
  stopLoadingParagraphTopicFromStoreParagraphSubscription: Subscription 
  stopLoadingParagraphTopicFromParagraphServiceSubscription : Subscription 

  allNewSharedParagraphTopicsHasBeenAddedSubscription: Subscription 

  updateOnSharedParagraphFormsSubscription: Subscription




  constructor(private paragraphTopicService: ParagraphTopicService,
              private formService: FormService ,
              private route: ActivatedRoute,
              private router: Router , 
              private storeParagraphItemsService: StoreParagraphItemsService,
              private paragraphService: ParagraphService , 
              private paragraphTopicIndexService: ParagraphTopicIndexService,
              private paragraphFeatureContainerService: ParagraphFeatureContainerService , 
              private userObjectUpdateService: UserObjectUpdateService ,
              private postComponentsHttpRequestService: PostComponentsHttpRequestService ,
              private cdr: ChangeDetectorRef ,
              private ngZone: NgZone , 
              private createUpdateMultiComponentsService: CreateUpdateMultiComponentsService,
              private initialNullSetupService: InitialNullSetupService , 
              private storePersonalFeatureIndexComponentsService: StorePersonalFeatureIndexComponentsService , 
              private addSharedFormOrParagraphService: AddSharedFormOrParagraphService
            
    ) {}

  ngOnInit(){

    /**
     * just a reminder as
     */
    this.user = this.userObjectUpdateService.userEmail
    console.log(this.user)

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
    })

    this.isLoading = true 

    console.log(this.paragraphTopicIndexService.getParagraphTopicIndexes())
   
    this.route.params.subscribe(
                        (params: Params)=> {
                        this.isLoading = true 
                        this.paragraphParameter = params['paragraphType']
                        this.changeExactParagraphTopicIndexData()
                        }
    )

    this.updatePublicParagraphTopicIndexOnRemoveItemSubscription = this.paragraphTopicIndexService.updatePublicParagraphTopicIndexes
    .subscribe(()=> { this.updatePublicParagraphTopicIndexesAfterRemove()})

    this.stopLoadingParagraphTopicIndexSubscription = this.paragraphTopicIndexService.stopLoadingOnParagraphTopicIndex
    .subscribe(()=> { this.turnLoadingToFalse()  })

    this.stopLoadingParagraphTopicFromStoreParagraphSubscription = this.storeParagraphItemsService.stopLoadingParagraphTopicIndex
    .subscribe(()=> { this.turnLoadingToFalse() })

    this.stopLoadingParagraphTopicFromParagraphServiceSubscription = this.paragraphService.stopLoadingParagraphTopicIndex
    .subscribe(()=> { this.turnLoadingToFalse()})

    this.stopLoadingParagraphTopicFromParagraphServiceSubscription = this.paragraphFeatureContainerService.stopLoadingParagraphTopicIndex
    .subscribe(()=> { this.turnLoadingToFalse()})

    this.allNewSharedParagraphTopicsHasBeenAddedSubscription = this.paragraphTopicIndexService.addingNewSharedParagraphAndParentsToDOM
        .subscribe(()=>{
          this.showUpdatedParagraphTopicIndexOnParameter()
    })

    this.updateOnSharedParagraphFormsSubscription = this.addSharedFormOrParagraphService.doneAddingUpdatingSharedParagraphForm
        .subscribe((res: SharedParagraphForm_New_Old_Data)=> {
          console.log( res, 'updated paragraphIndexTopicList')
          this.updateParagraphTopicIndexList(res)

        })
    

  };

  updateParagraphTopicIndexList(sharedParagraphForm_New_Old_Data: SharedParagraphForm_New_Old_Data){
    this.ngZone.run(()=>{

      if ( sharedParagraphForm_New_Old_Data.typeOfComponent === 'paragraphTopic' ){

        this.isLoading = true 


                    /**
                     * now need to add again the paragraphFeaturesContainers to the new empty paragraphTopic
                     * so that user can see the shared paragraphs ( otherwise he would see nothing )
                     * 
                     * on the paragraphTopicIndex we need to substitude the new paragraphTopic_id with the new one 
                     * 
                     */
        
          const currentParagraphTopicIndex: ParagraphTopicIndex = this.paragraphTopicIndexService.getParagraphTopicIndex_by_id(sharedParagraphForm_New_Old_Data.paragraphSide.oldParagraphTopicIndex._id)
          const newParagraphTopicIndex: ParagraphTopicIndex = sharedParagraphForm_New_Old_Data.paragraphSide.updatedParagraphTopicIndex

          const newParagraphTopic: ParagraphTopic = sharedParagraphForm_New_Old_Data.paragraphSide.updatedParagraphTopic
          const paragraphTopic: ParagraphTopic = sharedParagraphForm_New_Old_Data.paragraphSide.oldParagraphTopic

          console.log('current stored ParagraphTopicIndex => ', currentParagraphTopicIndex )
                    // now take out the old id of the old paragraphTopic from the currentParagraphTopicIndex.paragraphTopic_ids 
          const indexOfOldToNewTopic = currentParagraphTopicIndex.paragraphTopic_ids.findIndex(x=> x === paragraphTopic._id )
          currentParagraphTopicIndex.paragraphTopic_ids[indexOfOldToNewTopic] = newParagraphTopic._id

          const indexOldParagraphTopicIndex = this.paragraphTopicIndexService.getParagraphTopicIndexes().findIndex(x=> x._id === newParagraphTopicIndex._id)
          this.paragraphTopicIndexService.paragraphTopicIndexes[indexOldParagraphTopicIndex] = currentParagraphTopicIndex
          const newInitialNullIndex = this.initialNullSetupService.initialNullParagraphTopicIndexes.findIndex(x=> x._id === newParagraphTopicIndex._id)
          this.initialNullSetupService.initialNullParagraphTopicIndexes[newInitialNullIndex] = currentParagraphTopicIndex
          
          newParagraphTopic.paragraphFeatureContainer_ids = paragraphTopic.paragraphFeatureContainer_ids // add the old fake paragraphFeatureContainers
          const indexOldParagraphTopic = this.paragraphTopicService.getParagraphTopics().findIndex(x=> x._id === paragraphTopic._id )
          this.paragraphTopicService.paragraphTopics[indexOldParagraphTopic] = newParagraphTopic 
          const indexOldParagraphTopic2 = this.initialNullSetupService.initialNullParagraphTopics.findIndex(x=> x._id === paragraphTopic._id )
          this.initialNullSetupService.initialNullParagraphTopics[indexOldParagraphTopic2] = newParagraphTopic 

    
          this.paragraphTopicIndex = currentParagraphTopicIndex
      }

                    
    })
    this.cdr.detectChanges()
    this.isLoading = false
  }

  showUpdatedParagraphTopicIndexOnParameter(){

    this.ngZone.run(() => {
      this.paragraphTopicIndex = this.paragraphTopicIndexService.getParagraphTopicIndex_by_type(this.route.snapshot.params['paragraphType'])  
      if ( this.paragraphTopicIndex.paragraphTopic_ids.length === 0 ){ this.paragraphTopic_ids_arePresent = false }
      if ( this.paragraphTopicIndex.paragraphTopic_ids.length > 0 ){ this.paragraphTopic_ids_arePresent = true }
      this.topicNameForm = new FormGroup({
        name: new FormControl ('') ,   
          })
      
      if ( this.paragraphTopicService.getParagraphTopics().length < this.topicsLimit ){
        this.createButtonLimit = true ;
      } else {
        this.createButtonLimit = false ;
      };

      if ( this.paragraphParameter === 'personal-paragraphs' ){
          
        this.newParagraphIndexType = new IndexType ( false, true, false, false )
        this.isPersonal = true
        this.isPublic = false 
      }

      if ( this.paragraphParameter === 'shared-paragraphs'   ){
        this.newParagraphIndexType = new IndexType ( false, false, true, false ), 
        this.isPersonal = false 
        this.isPublic = false 
      }

      if ( this.paragraphParameter === 'preferred-paragraphs'){ 
        this.newParagraphIndexType = new IndexType ( false, false, false, true ), 
        this.isPersonal = false 
        this.isPublic = false 
      }

      if ( this.paragraphParameter === 'public-paragraphs'   ){ 

        this.newParagraphIndexType = new IndexType ( true, false, false, false ), 
        this.isPersonal = false 
        this.isPublic = true 
      }


    });

    this.isLoading = false
    this.cdr.detectChanges();

  }


  changeExactParagraphTopicIndexData(){

    this.user = this.userObjectUpdateService.userEmail
    console.log(this.user)

this.ngZone.run(() => {

    if ( this.paragraphParameter === null ){ this.paragraphParameter = 'personal-paragraphs' }
    if ( this.paragraphParameter !== 'personal-paragraphs' && this.paragraphParameter !== 'shared-paragraphs' && this.paragraphParameter !== 'preferred-paragraphs' && this.paragraphParameter !== 'public-paragraphs' ){ 
         this.paragraphParameter = 'personal-paragraphs' 
         this.router.navigate(['/create-form' ], {relativeTo: this.route.parent }) 
        }

    this.paragraphTopicIndex = this.paragraphTopicIndexService.getParagraphTopicIndex_by_type(this.paragraphParameter)

    if ( this.paragraphTopicIndex.paragraphTopic_ids.length === 0 ){ this.paragraphTopic_ids_arePresent = false }
    if ( this.paragraphTopicIndex.paragraphTopic_ids.length > 0 ){ this.paragraphTopic_ids_arePresent = true }
    
    console.log(this.paragraphParameter)
    console.log(this.paragraphTopicIndex)
    console.log(this.paragraphTopicIndex.paragraphTopic_ids)
  
      this.topicNameForm = new FormGroup({
        name: new FormControl ('') ,   
          })
      
      if ( this.paragraphTopicService.getParagraphTopics().length < this.topicsLimit ){
        this.createButtonLimit = true ;
      } else {
        this.createButtonLimit = false ;
      };



      /**
       *  constructor ( 
                  public isPublic: boolean,
                  public isPersonal: boolean,
                  public isShared: boolean ,
                  public isPreferred: boolean ){ }
       */

  
     if ( this.paragraphParameter === 'personal-paragraphs' ){
          
          this.newParagraphIndexType = new IndexType ( false, true, false, false )
          this.isPersonal = true
          this.isPublic = false 
          //  the right http call to add paragraphFeatures with childs 
          if ( this.user !== null){
       
  
            if ( this.personalParagraphsHasBeenStored === true ){
              this.formService.onChangeParagraphIndexType(this.newParagraphIndexType)
              this.isLoading = false
            }
            if ( this.personalParagraphsHasBeenStored === false ){
              this.storeParagraphItemsService.getAndStorePersonalParagraphItemsOfTypeOfIndex(this.user)
              /**
               * should confirm  "this.personalParagraphsHasBeenStored = true" only after succesful has been stored
               */
              this.personalParagraphsHasBeenStored = true
              this.formService.onChangeParagraphIndexType(this.newParagraphIndexType)
          }
          }  

          if ( this.user === null ){ 
            this.formService.onChangeParagraphIndexType(this.newParagraphIndexType)
            this.isLoading = false  }
         
     } 

     if ( this.paragraphParameter === 'shared-paragraphs' ){
          this.newParagraphIndexType = new IndexType ( false, false, true, false ), 
          this.isPersonal = false 
          this.isPublic = false 

    /**
    * find and advise paragrap[hFeatureContainer which paragraphTopics we are taking as reference , 
    * to be able to compare if paragrFeatureCiontainers already exist
    */
    const paragraphTopicIndexOfReference: ParagraphTopicIndex = this.paragraphTopicIndexService.getParagraphTopicIndex_by_type(this.paragraphParameter)
    console.log(paragraphTopicIndexOfReference)
    const paragraphTopicsOfReference: ParagraphTopic[] = []
    for ( let j=0; j<paragraphTopicIndexOfReference.paragraphTopic_ids.length ; j++ ){
        paragraphTopicsOfReference.push(this.paragraphTopicService.getParagraphTopicBy_id(paragraphTopicIndexOfReference.paragraphTopic_ids[j]))
    }
    console.log('EXISTING PARAGRAPH TOPICS OF REFERENCE',paragraphTopicsOfReference)
  
    this.paragraphFeatureContainerService.paragraphTopicsOfReference = paragraphTopicsOfReference

        if ( this.user !== null ){
          // check if shared paragraphFeature has been already put in store 
          if ( this.sharedParagraphsHasBeenStored === true ){
            this.formService.onChangeParagraphIndexType(this.newParagraphIndexType)
            this.turnLoadingToFalse()
          }

          if ( this.sharedParagraphsHasBeenStored === false ){

            this.sharedParagraphsHasBeenStored = true 
            this.storeParagraphItemsService.getAndStoreSharedParagraphItemsOfTypeOfIndex(this.user, 'share')
            this.formService.onChangeParagraphIndexType(this.newParagraphIndexType)

            setTimeout(() => {
              this.sharedParagraphsHasBeenStored = false 
            }, 10000);

          }
         
        }

        if ( this.user === null ){ 
          this.formService.onChangeParagraphIndexType(this.newParagraphIndexType)
          this.isLoading = false  }
     }

     if ( this.paragraphParameter === 'preferred-paragraphs'){ 
          this.newParagraphIndexType = new IndexType ( false, false, false, true ), 
          this.isPersonal = false 
          this.isPublic = false 

    // /**
    // * find and advise paragrap[hFeatureContainer which paragraphTopics we are taking as reference , 
    // * to be able to compare if paragrFeatureCiontainers already exist
    // */
    const paragraphTopicIndexOfReference: ParagraphTopicIndex = this.paragraphTopicIndexService.getParagraphTopicIndex_by_type(this.paragraphParameter)
    console.log(paragraphTopicIndexOfReference)
    const paragraphTopicsOfReference: ParagraphTopic[] = []
    for ( let j=0; j<paragraphTopicIndexOfReference.paragraphTopic_ids.length ; j++ ){
        paragraphTopicsOfReference.push(this.paragraphTopicService.getParagraphTopicBy_id(paragraphTopicIndexOfReference.paragraphTopic_ids[j]))
    }
    console.log('EXISTING PUBLIC PARAGRAPH TOPICS OF REFERENCE',paragraphTopicsOfReference)
  
    this.paragraphFeatureContainerService.paragraphTopicsOfReference = paragraphTopicsOfReference

    /**
     * now call all the preferred paragraphFeatures with childs and scompose 
     */
    if ( this.user !== null ){
      // check if preference paragraphFeature has been already put in store 
      if ( this.preferredParagraphsHasBeenStored === true ){
        this.formService.onChangeParagraphIndexType(this.newParagraphIndexType)
        this.isLoading = false
      }

      if ( this.preferredParagraphsHasBeenStored === false ){

        console.log( 'calling preference paragraphs items ')
        this.storeParagraphItemsService.getAndStoreSharedParagraphItemsOfTypeOfIndex(this.user, 'preference')
        this.preferredParagraphsHasBeenStored = true // these should be changed only once paragraphs have been stored 
        this.formService.onChangeParagraphIndexType(this.newParagraphIndexType)

        setTimeout(() => {
          this.preferredParagraphsHasBeenStored = false
        }, 10000);
      
      }
     

    }

    if ( this.user === null ){ 
      this.isLoading = false
      this.formService.onChangeParagraphIndexType(this.newParagraphIndexType)
    }
    

     } 

     if ( this.paragraphParameter === 'public-paragraphs'   ){ 

      this.newParagraphIndexType = new IndexType ( true, false, false, false ), 
      this.isPersonal = false 
      this.isPublic = true 

  /**
  * find and advise paragraphFeatureContainer which paragraphTopics we are taking as reference , 
  * to be able to compare if paragrFeatureContainers already exist
  */
  const paragraphTopicIndexOfReference: ParagraphTopicIndex = this.paragraphTopicIndexService.getParagraphTopicIndex_by_type(this.paragraphParameter)
  console.log(paragraphTopicIndexOfReference)
  const paragraphTopicsOfReference: ParagraphTopic[] = []
  for ( let j=0; j<paragraphTopicIndexOfReference.paragraphTopic_ids.length ; j++ ){
      paragraphTopicsOfReference.push(this.paragraphTopicService.getParagraphTopicBy_id(paragraphTopicIndexOfReference.paragraphTopic_ids[j]))
  }
  console.log('EXISTING PUBLIC PARAGRAPH TOPICS OF REFERENCE',paragraphTopicsOfReference)
  this.paragraphFeatureContainerService.paragraphTopicsOfReference = paragraphTopicsOfReference

        this.formService.onChangeParagraphIndexType(this.newParagraphIndexType)
        this.isLoading = false  }
});
this.cdr.detectChanges();

  };

  turnLoadingToFalse(){
    if ( this.paragraphTopicIndex.paragraphTopic_ids.length === 0 ){ this.paragraphTopic_ids_arePresent = false }
    if ( this.paragraphTopicIndex.paragraphTopic_ids.length > 0 ){ this.paragraphTopic_ids_arePresent = true }
    this.isLoading = false 
  }


  onAddEmailSearch($event){ // call the service to search for paragraphFeatureIndex publicly shared from this user 
    console.log('looking for paragrapgFeatureIndex from = ', $event )
    this.isLoading = true 
    this.paragraphTopicIndexService.foundEmailList.push($event)
    // this.storeParagraphFeaturesService.getAndStorePublicParagraphFeaturesWithChildsLastSeqOwner('public', 'paragraphFeatureIndex', this.user, '10', $event  )
    this.storeParagraphItemsService.getAndStorePublicParagraphsByObjectTypeOwnerAndUser( 'paragraph' , $event , this.user )
  }


  onDeleteEmailOnSearch($event: string ){
    console.log($event)

  }





   onHideButton(){
    this.hideButton = !this.hideButton;
 /** set time limit to write the new name */
    setTimeout(() => {
      this.hideButton = false ;
      this.topicNameForm = new FormGroup({
        name: new FormControl ('') ,   
          })
    }, 30000);

    if ( this.paragraphTopicService.getParagraphTopics().length < this.topicsLimit ){
      this.createButtonLimit = true ;
    } else {
      this.createButtonLimit = false ;
    };
  }


  submitOnlyOnceAtTime: boolean = true 
  onSubmit(){ 

    if ( this.submitOnlyOnceAtTime ){
         this.submitOnlyOnceAtTime = false 

      if ( this.user === null ){ this.onSubmitNullUser() }
      if ( this.user !== null ){ this.onSubmitWithUser() }
    }
    
  };

  onSubmitWithUser(){

    this.storePersonalFeatureIndexComponentsService.getPersonalUpdatedFeatureIndex_components(this.user)
    .subscribe((res: any) =>{
     console.log(res)
     
     const httpFeatureIndexComponentResult: any = res 
     // go to nect function and chech if paragraph already exist in updated paragraphFeatureContainer
     // and if paragraphTopic already exist 
   
      const newParagraphTopicIndexes: ParagraphTopicIndex[] = httpFeatureIndexComponentResult.paragraphTopicIndex
      console.log(newParagraphTopicIndexes)
      this.paragraphTopicIndexService.paragraphTopicIndexes = newParagraphTopicIndexes
      this.initialNullSetupService.initialNullParagraphTopicIndexes = newParagraphTopicIndexes
     
      const newParagraphTopics: ParagraphTopic[] = httpFeatureIndexComponentResult.paragraphTopic
      console.log(newParagraphTopics)
      this.paragraphTopicService.paragraphTopics = newParagraphTopics
      this.initialNullSetupService.initialNullParagraphTopics = newParagraphTopics 
   
      const newParagraphFeatureContainers: ParagraphFeatureContainer[] = httpFeatureIndexComponentResult.paragraphFeatureContainer 
      console.log(newParagraphFeatureContainers)
      this.paragraphFeatureContainerService.paragraphFeatureContainers = newParagraphFeatureContainers
      this.initialNullSetupService.initialNullParagraphFeatureContainers = newParagraphFeatureContainers




      const new_id = (this.paragraphTopicService.getParagraphTopics().length+1).toString() ;
      const newComponentName = 'paragraphTopic'
      const newUIID = 'paragraphTopic-'+(this.paragraphTopicService.getParagraphTopics().length+1).toString() 
      const newOwner = this.user ;
      const newAllUsersAllowed = false ;
      const newUserListAllowed = [];
      const newDateAndTime = null ;
      const newIsAccepted = true ;
      const newIsArchived = false ;
      const newName = this.topicNameForm.value.name ;

      const newParagraphTopic = new ParagraphTopic ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed, 
                                                     newDateAndTime, newIsAccepted, newIsArchived , newName , [] );
    
            const new_id2 = this.paragraphTopicIndex._id
            const newComponentName2 = this.paragraphTopicIndex.componentName
            const newComponentUIID2 = this.paragraphTopicIndex.UIID
            const newOwner2 = this.user
            const newAllUsersAllowed2 = this.paragraphTopicIndex.allUsersAllowed
            const newUserListAllowed2 = this.paragraphTopicIndex.userListAllowed
            const newDateAndTime2 = this.paragraphTopicIndex.dateAndTime
            const newType2 = this.paragraphTopicIndex.type
    
            const newParagraphTopic_ids2 = this.paragraphTopicIndex.paragraphTopic_ids
        
    
            const newParagraphTopicIndex = new ParagraphTopicIndex ( new_id2, newComponentName2, newComponentUIID2, newOwner2, newAllUsersAllowed2, newUserListAllowed2,
                                                newDateAndTime2, newType2, newParagraphTopic_ids2)
    
    
            this.createUpdateMultiComponentsService.createParagraphTopic_UpdateParagraphTopicIndex(this.user, newParagraphTopic, newParagraphTopicIndex )
                .subscribe((res2: any )=> {
                  console.log(res2)

                  const newParagraphTopic: ParagraphTopic = res2.featureIndex[0]
                  const newParagraphTopicIndex: ParagraphTopicIndex = res2.featureIndex[1]

                  this.paragraphTopicService.addParagraphTopic(newParagraphTopic);

                  const newIndex = this.paragraphTopicIndexService.getParagraphTopicIndexes().findIndex(x=> x._id === this.paragraphTopicIndex._id)
                  this.paragraphTopicIndexService.paragraphTopicIndexes[newIndex] = newParagraphTopicIndex
                  const newInitialNullIndex = this.initialNullSetupService.initialNullParagraphTopicIndexes.findIndex(x=> x._id === newParagraphTopicIndex._id)
                  this.initialNullSetupService.initialNullParagraphTopicIndexes[newInitialNullIndex] = newParagraphTopicIndex


                  this.ngZone.run(() => {

                   
                  this.paragraphTopicIndex = newParagraphTopicIndex
                
                      this.topicNameForm = new FormGroup({
                        name: new FormControl ('') ,   
                          })
                      
                      if ( this.paragraphTopicService.getParagraphTopics().length < this.topicsLimit ){
                        this.createButtonLimit = true ;
                      } else {
                        this.createButtonLimit = false ;
                      };

                 

                  this.paragraphTopics = this.paragraphTopicService.getParagraphTopics();
          
                  console.log(this.paragraphTopics)
              
                  this.hideButton = !this.hideButton ;
                  this.submitOnlyOnceAtTime = true 

                  })
                  this.cdr.detectChanges()


                }, error => {
                  console.log( error )
                  this.submitOnlyOnceAtTime = true
                })

   }, error => {
    console.log(error)
    this.submitOnlyOnceAtTime = true
   })


}

  onSubmitNullUser(){
    

      const new_id = (this.paragraphTopicService.getParagraphTopics().length+1).toString() ;
      const newComponentName = 'paragraphTopic'
      const newUIID = 'paragraphTopic-'+(this.paragraphTopicService.getParagraphTopics().length+1).toString() 
      const newOwner = this.user ;
      const newAllUsersAllowed = false ;
      const newUserListAllowed = [];
      const newDateAndTime = null ;
      const newIsAccepted = true ;
      const newIsArchived = false ;
      const newName = this.topicNameForm.value.name ;
  
      /** we leave the 3rd property empty , tobe added on the fly */
      
      const newParagraphTopic = new ParagraphTopic ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed, 
                                                     newDateAndTime, newIsAccepted, newIsArchived ,
                                                     newName , [] );
  
        this.paragraphTopicService.addParagraphTopic(newParagraphTopic);
  
        const new_id2 = this.paragraphTopicIndex._id
        const newComponentName2 = this.paragraphTopicIndex.componentName
        const newComponentUIID2 = this.paragraphTopicIndex.UIID
        const newOwner2 = this.user
        const newAllUsersAllowed2 = this.paragraphTopicIndex.allUsersAllowed
        const newUserListAllowed2 = this.paragraphTopicIndex.userListAllowed
        const newDateAndTime2 = this.paragraphTopicIndex.dateAndTime
        const newType2 = this.paragraphTopicIndex.type
  
        const newParagraphTopic_ids2 = this.paragraphTopicIndex.paragraphTopic_ids
        newParagraphTopic_ids2.push(newParagraphTopic._id)
  
        const newParagraphTopicIndex = new ParagraphTopicIndex ( new_id2, newComponentName2, newComponentUIID2, newOwner2, newAllUsersAllowed2, newUserListAllowed2,
                                            newDateAndTime2, newType2, newParagraphTopic_ids2)
  
        const newIndex = this.paragraphTopicIndexService.getParagraphTopicIndexes().findIndex(x=> x._id === this.paragraphTopicIndex._id)
        this.paragraphTopicIndexService.paragraphTopicIndexes[newIndex] = newParagraphTopicIndex
  
        this.topicNameForm = new FormGroup({
          name: new FormControl ('') ,   })
          this.paragraphTopics = this.paragraphTopicService.getParagraphTopics();
      
          console.log(this.paragraphTopics)
          
          this.hideButton = !this.hideButton ;
          this.submitOnlyOnceAtTime = true
  
      
  }

  updatePublicParagraphTopicIndexesAfterRemove(){
    this.route.params.subscribe(
      (params: Params)=> {
      this.isLoading = true 
      this.paragraphParameter = params['paragraphType']
      this.changeExactParagraphTopicIndexData()
      })
  }


  ngOnDestroy(){
   this.updateOnUserEmail.unsubscribe()
   this.stopLoadingParagraphTopicIndexSubscription.unsubscribe()
   this.updatePublicParagraphTopicIndexOnRemoveItemSubscription.unsubscribe()
   this.stopLoadingParagraphTopicFromStoreParagraphSubscription.unsubscribe()
   this.stopLoadingParagraphTopicFromParagraphServiceSubscription.unsubscribe()
   this.allNewSharedParagraphTopicsHasBeenAddedSubscription.unsubscribe()
   this.updateOnSharedParagraphFormsSubscription.unsubscribe()
  }

}
