import { Injectable } from "@angular/core";
import { RecipientIndexColumn } from "src/app/forms/form-list/form-edit/recipient-index-list/recipient-index-column.model";
import { PostComponentsHttpRequestService } from "../post-and-put-services/post-components-http-request.service";
import { Recipient } from "src/app/forms/form-list/form-item/recipient/recipient.model";
import { RecipientIndexListItem } from "src/app/forms/form-list/form-edit/recipient-index-list/recipient-index-list-item.model";
import { Paragraph } from "src/app/forms/form-list/form-item/paragraph/paragraph.model";
import { ParagraphFeatureContainer } from "src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature-container.model";
import { ParagraphTopic } from "src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.model";
import { Form } from "src/app/forms/form.model";
import { FormFeatureContainer } from "src/app/forms/form-list/form-index-topic-list/form-feature-container.model";
import { FormIndexTopic } from "src/app/forms/form-list/form-index-topic-list/form-index-topic.model";
import { ParagraphTopicIndex } from "src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-topic-index.model";
import { FormIndexTopicListItem } from "src/app/forms/form-list/form-index-topic-list/form-index-topic-list-item.model";


@Injectable ({
    providedIn: 'root' 
})

export class CreateUpdateMultiComponentsService {


    constructor( private postComponentsHttpRequestService: PostComponentsHttpRequestService ){}



	/**
	 * create on personal user files 
	 */
    create_Recipient_RecipientIndex_RecipientIndexListItem_Update_RecipientIndexColumn( userEmail: string , 
		                                                                                newName: string,  
																						recipient_UIID: string , 
																						recipientIndex_UIID: string , 
																						recipientIndexListItem_UIID: string, 
																						recipientIndexColumn: RecipientIndexColumn ){

		console.log(recipientIndexColumn)
        const newRecipientName = "recipient-1"
        const newRecipientAndParents =   {
    "1": [
		{
			"componentName": "recipient",
			"UIID": recipient_UIID ,
			"owner": userEmail ,
			"dateAndTime": null,
			"updateDateAndTime": null,
			"chatRoomId": "" , 
			"allUsersAllowed": false,
			"userListAllowed": [],
			"blockedUserList": null,
			"name": newRecipientName ,
			"recipientList": [],
			"toMessage": [],
			"toMail": [],
			"ccMail": [],
			"bccMail": [],
			"greeting": ""
		}
	  ],
	  "2": [
	     	{
	        "componentName": "recipientIndex",
			"UIID": recipientIndex_UIID,
			"owner":"",
			"allUsersAllowed": false,
			"userListAllowed": [],
			"dateAndTime": null,
			"isAccepted": true,
			"useMyOwnMailRecipient": true , 
			"dropId": 0,
			"recipient_id": "@CHILD_ID@",
			"mailRecipient_idCopy": null 
	        }
	     ],
	"3": [
	     	{
	        "componentName": "recipientIndexListItem",
			"UIID": recipientIndexListItem_UIID,
			"owner":"",
			"allUsersAllowed": false,
			"userListAllowed": [],
			"dateAndTime": null,
			"recipientIndexListName": newName ,
			"recipientIndexList_ids": ["@CHILD_ID@"],
			"recipientIndexSelected_id": "@CHILD_ID@"
	        }
	     ], 
	"4": [
	     	{
	        "componentName": "recipientIndexColumn",
			"UIID": recipientIndexColumn.UIID ,
			"owner": recipientIndexColumn.owner,
			"allUsersAllowed": recipientIndexColumn.allUsersAllowed,
			"userListAllowed": recipientIndexColumn.userListAllowed,
			"dateAndTime": recipientIndexColumn.dateAndTime,
			"type": recipientIndexColumn.type,
			"name": recipientIndexColumn.name,
			"recipientIndexListItem_ids": [ recipientIndexColumn.recipientIndexListItem_ids , "@CHILD_ID@"],
	        }
	     ]
        } // end of json to input 


       return this.postComponentsHttpRequestService.httpStoreMultiLevelComponentsWithUpdateOnExistingOnes(userEmail, newRecipientAndParents)


    }; // end create / update recipient + parents 

	/**
	 * create second recipient and create SharedrecipientIndex 
	 * @param userEmail 
	 * @param existingSharedRecipient_id 
	 * @param secondRecipient_UIID 
	 * @param recipientIndex_UIID 
	 */
	create_SecondRecipient_RecipientIndex( userEmail: string , existingSharedRecipient_id: string , 
										   secondRecipient_UIID: string, recipientIndex_UIID: string ){

		const newRecipientAndRecipientIndex =   {
			"1": [
				{
					"componentName": "recipient",
					"UIID": secondRecipient_UIID ,
					"owner": "" ,
					"dateAndTime": null,
					"updateDateAndTime": null,
					"chatRoomId": "" , 
					"allUsersAllowed": false,
					"userListAllowed": [],
					"blockedUserList": null,
					"name": "" ,
					"recipientList": [],
					"toMessage": [],
					"toMail": [],
					"ccMail": [],
					"bccMail": [],
					"greeting": ""
				}
			  ],
			  "2": [
					 {
					"componentName": "recipientIndex",
					"UIID": recipientIndex_UIID,
					"owner":"",
					"allUsersAllowed": false,
					"userListAllowed": [],
					"dateAndTime": null,
					"isAccepted": false,
					"useMyOwnMailRecipient": false , 
					"dropId": 0,
					"recipient_id": existingSharedRecipient_id,
					"mailRecipient_idCopy": "@CHILD_ID@"
					}
				 ]
				} // end of json to input 

		return this.postComponentsHttpRequestService.httpStoreMultiLevelComponentsWithUpdateOnExistingOnes(userEmail, newRecipientAndRecipientIndex)
	}


	/**
	 * adding new personal recipient + recipientIndex + UPDATE recipientIndexListItem
	 */
	createPersonal_Recipient_RecipientIndex_UPDATE_RecipientIndexListItem( userEmail: string , 
		                                                                   newRecipientDetails: Recipient , 
																		   newRecipientIndex_UIID: string , 
																		   currentRecipientIndexListItem: RecipientIndexListItem ){
	    
		const newRecipientAndParents =   {
			"1": [
				{
					"componentName": "recipient",
					"UIID": newRecipientDetails.UIID ,
					"owner": userEmail ,
					"dateAndTime": null,
					"updateDateAndTime": null,
					"chatRoomId": "" , 
					"allUsersAllowed": false,
					"userListAllowed": [],
					"blockedUserList": null,
					"name": newRecipientDetails.name,
					"recipientList": [],
					"toMessage": [],
					"toMail": [],
					"ccMail": [],
					"bccMail": [],
					"greeting": ""
				}
			  ],
			  "2": [
					 {
					"componentName": "recipientIndex",
					"UIID": newRecipientIndex_UIID,
					"owner": userEmail,
					"allUsersAllowed": false,
					"userListAllowed": [],
					"dateAndTime": null,
					"isAccepted": true,
					"useMyOwnMailRecipient": true , 
					"dropId": 0,
					"recipient_id": "@CHILD_ID@",
					"mailRecipient_idCopy": null 
					}
				 ],
			"3": [
					 {
					"componentName": "recipientIndexListItem",
					"UIID": currentRecipientIndexListItem.UIID,
					"owner": userEmail,
					"allUsersAllowed": currentRecipientIndexListItem.allUsersAllowed ,
					"userListAllowed": currentRecipientIndexListItem.userListAllowed ,
					"dateAndTime": currentRecipientIndexListItem.dateAndTime,
					"recipientIndexListName": currentRecipientIndexListItem.recipientIndexListName ,
					"recipientIndexList_ids": [ currentRecipientIndexListItem.recipientIndexList_ids , "@CHILD_ID@"],
					"recipientIndexSelected_id": currentRecipientIndexListItem.recipientIndexSelected_id
					}
				 ]
		}

		return this.postComponentsHttpRequestService.httpStoreMultiLevelComponentsWithUpdateOnExistingOnes(userEmail, newRecipientAndParents)


	}


/**
 *                       ---------------------- CREATE PARAGRAPH + PARENTS ----------------------------------
 * @param userEmail 
 * @param paragraph   creating
 * @param paragraphFeatureContainer  creating
 * @param paragraphTopic  updating
 */

	createParagraph_createParagraphFeatureContainer_UpdateParagraphTopic( userEmail: string , 
		                                                                  paragraph: Paragraph,
																          paragraphFeatureContainer: ParagraphFeatureContainer,
																          paragraphTopic: ParagraphTopic 
																   ){


	const newParagraphAndParents = {

		"1": [
			{
               "componentName": "paragraph", 
               "UIID": paragraph.UIID,
               "owner": userEmail, 
               "dateAndTime": null,
			   "updateDateAndTime": null,
               "isRemoved": paragraph.isRemoved,  
               "allUsersAllowed": false,
               "userListAllowed": [],
               "canBeResharedByOthers": paragraph.canBeResharedByOthers, 
               "name": paragraph.name , 
               "desc": paragraph.desc , 
               "features": paragraph.features,
               "feature_id_list": paragraph.feature_id_list 
			}
		  ],
		  "2": [
			  {
                 "componentName": "paragraphFeatureContainer",
                 "UIID": paragraphFeatureContainer.UIID,
                 "owner": userEmail,
                 "allUsersAllowed": false , 
                 "userListAllowed": [],
                 "dateAndTime": null , 
                 "isActive": paragraphFeatureContainer.isActive, 
                 "isAccepted": paragraphFeatureContainer.isAccepted, 
                 "name": paragraphFeatureContainer.name, 
                 "paragraphFeatureState": paragraphFeatureContainer.paragraphFeatureState , // visible or not is to change at every check
                 "paragraph_id": "@CHILD_ID@"
		      }
		  ],
		  "3": [
			{
                  "componentName": "paragraphTopic",
                  "UIID": paragraphTopic.UIID ,
                  "owner": userEmail,
                  "allUsersAllowed": paragraphTopic.allUsersAllowed,
                  "userListAllowed": paragraphTopic.userListAllowed,
                  "dateAndTime": paragraphTopic.dateAndTime ,
                  "isAccepted": paragraphTopic.isAccepted, 
                  "isArchived": paragraphTopic.isArchived, 
                  "name": paragraphTopic.name , 
                  "paragraphFeatureContainer_ids": [ paragraphTopic.paragraphFeatureContainer_ids , "@CHILD_ID@" ]

			}
		  ]

	}

	return this.postComponentsHttpRequestService.httpStoreMultiLevelComponentsWithUpdateOnExistingOnes(userEmail, newParagraphAndParents )


};



createForm_CreateFormFeatureContainer_UpdateFormIndexTopic( userEmail: string , 
	                                                        formItem: Form , 
															formFeatureContainer: FormFeatureContainer ,
															formIndexTopic: FormIndexTopic ){


const newFormItemAndParents = {

	"1": [
		{
			"componentName": "formItem", 
			"UIID": formItem.UIID, 
			"owner": userEmail, 
			"dateAndTime": null,
			"updateDateAndTime": null,
			"allUsersAllowed": false,
			"userListAllowed": [],
			"canBeResharedByOthers": formItem.canBeResharedByOthers, 
			"noNeedRecipientAndSubject": formItem.noNeedRecipientAndSubject,
			"isFixedSubject": formItem.isFixedSubject, 
			"isShareble": formItem.isShareble, 
			"isBeenShared": formItem.isBeenShared, 
		    "formSubject": formItem.formSubject,
			"recipientIndex_id": formItem.recipientIndex_id,
			"paragraph_ids": formItem.paragraph_ids
		}
	  ],
	  "2": [
		  {
			"componentName": "formFeatureContainer", 
			"UIID": formFeatureContainer.UIID, 
			"owner": userEmail, 
			"allUsersAllowed": false,
			"userListAllowed": [], 
			"dateAndTime": null, 
			"isActive": formFeatureContainer.isActive, 
			"isAccepted": formFeatureContainer.isAccepted, 
			"name": formFeatureContainer.name, 
			"formFeatureState": formFeatureContainer.formFeatureState, 
			"recipientIndex_id": formFeatureContainer.recipientIndex_id,
			"form_id": "@CHILD_ID@"
		  }
	  ],
	  "3": [
		{
			"componentName": "formIndexTopic", 
			"UIID": formIndexTopic.UIID, 
			"owner": userEmail,  
			"dateAndTime": formIndexTopic.dateAndTime, 
			"allUsersAllowed": formIndexTopic.allUsersAllowed, 
			"userListAllowed": formIndexTopic.userListAllowed, 
			"isAccepted" : formIndexTopic.isAccepted, 
			"isArchived" : formIndexTopic.isArchived, 
			"name": formIndexTopic.name, 
			"formFeatureContainer_ids" : [ formIndexTopic.formFeatureContainer_ids, "@CHILD_ID@" ]
		}
	  ]

}

return this.postComponentsHttpRequestService.httpStoreMultiLevelComponentsWithUpdateOnExistingOnes(userEmail, newFormItemAndParents )
														
};

createForm_CreateFormFeatureContainer_CreateFormIndexTopic_UpdateFormIndexTopicListItem( userEmail: string , 
	formItem: Form , 
	formFeatureContainer: FormFeatureContainer ,
	formIndexTopic: FormIndexTopic , 
	formIndexTopicListItem: FormIndexTopicListItem  ){


const newFormItemAndParents = {

"1": [
{
"componentName": "formItem", 
"UIID": formItem.UIID, 
"owner": userEmail, 
"dateAndTime": null,
"updateDateAndTime": null,
"allUsersAllowed": false,
"userListAllowed": [],
"canBeResharedByOthers": formItem.canBeResharedByOthers, 
"noNeedRecipientAndSubject": formItem.noNeedRecipientAndSubject,
"isFixedSubject": formItem.isFixedSubject, 
"isShareble": formItem.isShareble, 
"isBeenShared": formItem.isBeenShared, 
"formSubject": formItem.formSubject,
"recipientIndex_id": formItem.recipientIndex_id,
"paragraph_ids": formItem.paragraph_ids
}
],
"2": [
{
"componentName": "formFeatureContainer", 
"UIID": formFeatureContainer.UIID, 
"owner": userEmail, 
"allUsersAllowed": false,
"userListAllowed": [], 
"dateAndTime": null, 
"isActive": formFeatureContainer.isActive, 
"isAccepted": formFeatureContainer.isAccepted, 
"name": formFeatureContainer.name, 
"formFeatureState": formFeatureContainer.formFeatureState, 
"recipientIndex_id": formFeatureContainer.recipientIndex_id,
"form_id": "@CHILD_ID@"
}
],
"3": [
{
"componentName": "formIndexTopic", 
"UIID": formIndexTopic.UIID, 
"owner": userEmail,  
"dateAndTime": formIndexTopic.dateAndTime, 
"allUsersAllowed": formIndexTopic.allUsersAllowed, 
"userListAllowed": formIndexTopic.userListAllowed, 
"isAccepted" : formIndexTopic.isAccepted, 
"isArchived" : formIndexTopic.isArchived, 
"name": formIndexTopic.name, 
"formFeatureContainer_ids" : ["@CHILD_ID@" ]
}
],
"4": [
	{
		"componentName": "formIndexTopicListItem",
		"UIID": formIndexTopicListItem.UIID ,
		"owner": userEmail,
		"allUsersAllowed": formIndexTopicListItem.allUsersAllowed,
		"userListAllowed": formIndexTopicListItem.userListAllowed,
		"dateAndTime": formIndexTopicListItem.dateAndTime,
		"type": formIndexTopicListItem.type,
		"formIndexTopic_ids": [formIndexTopicListItem.formIndexTopic_ids , "@CHILD_ID@"]
	}
]

}

return this.postComponentsHttpRequestService.httpStoreMultiLevelComponentsWithUpdateOnExistingOnes(userEmail, newFormItemAndParents )

};



createShared_ParagraphFeatureContainer_UpdateParagraphTopic( userEmail: string , 
	                                                         paragraphFeatureContainer: ParagraphFeatureContainer ,
															 paragraphTopic: ParagraphTopic
															 ){

const paragraphFeatureContainerAndParent = {

	"1": [
		{
		   "componentName": "paragraphFeatureContainer",
		   "UIID": paragraphFeatureContainer.UIID,
		   "owner": userEmail,
		   "allUsersAllowed": false, 
		   "userListAllowed": [],
		   "dateAndTime": null, 
		   "isActive": paragraphFeatureContainer.isActive, 
		   "isAccepted": paragraphFeatureContainer.isAccepted, 
		   "name": paragraphFeatureContainer.name, 
		   "paragraphFeatureState": paragraphFeatureContainer.paragraphFeatureState,
		   "paragraph_id": paragraphFeatureContainer.paragraph_id
		}
	],
	"2": [
	  {
			"componentName": "paragraphTopic",
			"UIID": paragraphTopic.UIID ,
			"owner": userEmail,
			"allUsersAllowed": paragraphTopic.allUsersAllowed,
			"userListAllowed": paragraphTopic.userListAllowed,
			"dateAndTime": paragraphTopic.dateAndTime,
			"isAccepted": paragraphTopic.isAccepted, 
			"isArchived": paragraphTopic.isArchived, 
			"name": paragraphTopic.name, 
			"paragraphFeatureContainer_ids": [ paragraphTopic.paragraphFeatureContainer_ids , "@CHILD_ID@" ]
	  }
	]

};

return this.postComponentsHttpRequestService.httpStoreMultiLevelComponentsWithUpdateOnExistingOnes(userEmail, paragraphFeatureContainerAndParent )


}


/**
 * when it's first time to have a child ids we need to make a different function with ["@child@"]
 */
createShared_ParagraphFeatureContainer_CreateParagraphTopic_UpdateParagraphIndexTopic( userEmail: string , 
	                                                                                   paragraphFeatureContainer: ParagraphFeatureContainer ,
	                                                                                   paragraphTopic: ParagraphTopic , 
																					   paragraphTopicIndex: ParagraphTopicIndex ){

	console.log(paragraphFeatureContainer, paragraphTopic , paragraphTopicIndex  )																					
const newParagraphFeatureContainer_plus2_Parents = {

	"1": [
		{
		   "componentName": "paragraphFeatureContainer",
		   "UIID": paragraphFeatureContainer.UIID,
		   "owner": userEmail,
		   "allUsersAllowed": false, 
		   "userListAllowed": [],
		   "dateAndTime": null, 
		   "isActive": paragraphFeatureContainer.isActive, 
		   "isAccepted": paragraphFeatureContainer.isAccepted, 
		   "name": paragraphFeatureContainer.name, 
		   "paragraphFeatureState": paragraphFeatureContainer.paragraphFeatureState,
		   "paragraph_id": paragraphFeatureContainer.paragraph_id
		}
	],
	"2": [
		{
		"componentName": "paragraphTopic",
		"UIID": paragraphTopic.UIID ,
		"owner": userEmail,
		"allUsersAllowed": paragraphTopic.allUsersAllowed,
		"userListAllowed": paragraphTopic.userListAllowed,
		"dateAndTime": paragraphTopic.dateAndTime,
		"isAccepted": paragraphTopic.isAccepted, 
		"isArchived": paragraphTopic.isArchived, 
		"name": paragraphTopic.name, 
		"paragraphFeatureContainer_ids":["@CHILD_ID@"]
	}
	],
	"3": [
		{
		    "componentName": "paragraphTopicIndex",
            "UIID": paragraphTopicIndex.UIID,
            "owner": userEmail, 
            "allUsersAllowed": false, 
            "userListAllowed": [],
            "dateAndTime": null, 
            "type": paragraphTopicIndex.type,
            "paragraphTopic_ids": [paragraphTopicIndex.paragraphTopic_ids , "@CHILD_ID@" ]
		}
	]
}

return this.postComponentsHttpRequestService.httpStoreMultiLevelComponentsWithUpdateOnExistingOnes(userEmail, newParagraphFeatureContainer_plus2_Parents )


}

/**
 * create preferredParagraphFeatureContainer
 */
createPreferredParagraphFeatureContainer_UpdateParagraphTopic(userEmail: string , paragraphFeatureContainer: ParagraphFeatureContainer, paragraphTopic: ParagraphTopic){

	console.log(paragraphFeatureContainer, paragraphTopic )
	const paragraphFeatureContainerAndParent = {

		"1": [
			{
			   "componentName": "paragraphFeatureContainer",
			   "UIID": paragraphFeatureContainer.UIID,
			   "owner": userEmail,
			   "allUsersAllowed": false, 
			   "userListAllowed": [],
			   "dateAndTime": null, 
			   "isActive": paragraphFeatureContainer.isActive, 
			   "isAccepted": paragraphFeatureContainer.isAccepted, 
			   "name": paragraphFeatureContainer.name, 
			   "paragraphFeatureState": paragraphFeatureContainer.paragraphFeatureState,
			   "paragraph_id": paragraphFeatureContainer.paragraph_id
			}
		],
		"2": [
		  {
				"componentName": "paragraphTopic",
				"UIID": paragraphTopic.UIID ,
				"owner": userEmail,
				"allUsersAllowed": paragraphTopic.allUsersAllowed,
				"userListAllowed": paragraphTopic.userListAllowed,
				"dateAndTime": paragraphTopic.dateAndTime,
				"isAccepted": paragraphTopic.isAccepted, 
				"isArchived": paragraphTopic.isArchived, 
				"name": paragraphTopic.name, 
				"paragraphFeatureContainer_ids": [ paragraphTopic.paragraphFeatureContainer_ids , "@CHILD_ID@" ]
		  }
		]
	
	};
	
	return this.postComponentsHttpRequestService.httpStoreMultiLevelComponentsWithUpdateOnExistingOnes(userEmail, paragraphFeatureContainerAndParent )
	
}

createPreferredParagraphFeatureContainer_CreateParagraphTopic_UpdateParagraphTopicIndex( userEmail: string , paragraphFeatureContainer: ParagraphFeatureContainer, paragraphTopic: ParagraphTopic , paragraphTopicIndex: ParagraphTopicIndex){

	const newParagraphFeatureContainer_plus2_Parents = {

		"1": [
			{
			   "componentName": "paragraphFeatureContainer",
			   "UIID": paragraphFeatureContainer.UIID,
			   "owner": userEmail,
			   "allUsersAllowed": false, 
			   "userListAllowed": [],
			   "dateAndTime": null, 
			   "isActive": paragraphFeatureContainer.isActive, 
			   "isAccepted": paragraphFeatureContainer.isAccepted, 
			   "name": paragraphFeatureContainer.name, 
			   "paragraphFeatureState": paragraphFeatureContainer.paragraphFeatureState,
			   "paragraph_id": paragraphFeatureContainer.paragraph_id
			}
		],
		"2": [
			{
			"componentName": "paragraphTopic",
			"UIID": paragraphTopic.UIID ,
			"owner": userEmail,
			"allUsersAllowed": paragraphTopic.allUsersAllowed,
			"userListAllowed": paragraphTopic.userListAllowed,
			"dateAndTime": paragraphTopic.dateAndTime,
			"isAccepted": paragraphTopic.isAccepted, 
			"isArchived": paragraphTopic.isArchived, 
			"name": paragraphTopic.name, 
			"paragraphFeatureContainer_ids":["@CHILD_ID@"]
		}
		],
		"3": [
			{
				"componentName": "paragraphTopicIndex",
				"UIID": paragraphTopicIndex.UIID,
				"owner": userEmail, 
				"allUsersAllowed": paragraphTopicIndex.allUsersAllowed, 
				"userListAllowed": paragraphTopicIndex.userListAllowed,
				"dateAndTime": paragraphTopicIndex.dateAndTime, 
				"type": paragraphTopicIndex.type,
				"paragraphTopic_ids": [paragraphTopicIndex.paragraphTopic_ids , "@CHILD_ID@" ]
			}
		]
	}
	
	return this.postComponentsHttpRequestService.httpStoreMultiLevelComponentsWithUpdateOnExistingOnes(userEmail, newParagraphFeatureContainer_plus2_Parents )
	
}


createParagraphTopic_UpdateParagraphTopicIndex( userEmail: string , paragraphTopic: ParagraphTopic , paragraphTopicIndex: ParagraphTopicIndex  ){

	const newParagraphTopicAndParent = {

		"1": [
			{
			"componentName": "paragraphTopic",
			"UIID": paragraphTopic.UIID ,
			"owner": userEmail,
			"allUsersAllowed": paragraphTopic.allUsersAllowed,
			"userListAllowed": paragraphTopic.userListAllowed,
			"dateAndTime": paragraphTopic.dateAndTime,
			"isAccepted": paragraphTopic.isAccepted, 
			"isArchived": paragraphTopic.isArchived, 
			"name": paragraphTopic.name, 
			"paragraphFeatureContainer_ids":[]
		}
		],
		"2": [
			{
				"componentName": "paragraphTopicIndex",
				"UIID": paragraphTopicIndex.UIID,
				"owner": userEmail, 
				"allUsersAllowed": paragraphTopicIndex.allUsersAllowed, 
				"userListAllowed": paragraphTopicIndex.userListAllowed,
				"dateAndTime": paragraphTopicIndex.dateAndTime, 
				"type": paragraphTopicIndex.type,
				"paragraphTopic_ids": [paragraphTopicIndex.paragraphTopic_ids , "@CHILD_ID@" ]
			}
		]
	}
	
	return this.postComponentsHttpRequestService.httpStoreMultiLevelComponentsWithUpdateOnExistingOnes(userEmail, newParagraphTopicAndParent )
	
}



createFormFeatureContainer_UpdateFormIndexTopic( userEmail: string , formFeatureContainer: FormFeatureContainer , formIndexTopic: FormIndexTopic ){

	console.log(formFeatureContainer, formIndexTopic )
	const formFeatureContainerAndParent = {

		"1": [
			{
			   "componentName": "formFeatureContainer",
			   "UIID": formFeatureContainer.UIID,
			   "owner": userEmail,
			   "allUsersAllowed": false, 
			   "userListAllowed": [],
			   "dateAndTime": null, 
			   "isActive": formFeatureContainer.isActive, 
			   "isAccepted": formFeatureContainer.isAccepted, 
			   "name": formFeatureContainer.name, 
			   "formFeatureState": formFeatureContainer.formFeatureState,
			   "recipientIndex_id": formFeatureContainer.recipientIndex_id,
			   "form_id": formFeatureContainer.form_id
			}
		],
		"2": [
		  {
				"componentName": "formIndexTopic",
				"UIID": formIndexTopic.UIID ,
				"owner": userEmail,
				"dateAndTime": formIndexTopic.dateAndTime,
				"allUsersAllowed": formIndexTopic.allUsersAllowed,
				"userListAllowed": formIndexTopic.userListAllowed,
				"isAccepted": formIndexTopic.isAccepted, 
				"isArchived": formIndexTopic.isArchived, 
				"name": formIndexTopic.name, 
				"formFeatureContainer_ids": [ formIndexTopic.formFeatureContainer_ids , "@CHILD_ID@" ]
		  }
		]
	
	};
	
	return this.postComponentsHttpRequestService.httpStoreMultiLevelComponentsWithUpdateOnExistingOnes(userEmail, formFeatureContainerAndParent )
}

createFormFeatureContainer_CreateFormIndexTopic_UpdateFormIndexTopicListItem( userEmail: string , formFeatureContainer: FormFeatureContainer , formIndexTopic: FormIndexTopic , formIndexTopicListItem: FormIndexTopicListItem ){

	console.log(formFeatureContainer, formIndexTopic , formIndexTopicListItem )
	const formFeatureContainerAndParent = {

		"1": [
			{
			   "componentName": "formFeatureContainer",
			   "UIID": formFeatureContainer.UIID,
			   "owner": userEmail,
			   "allUsersAllowed": false, 
			   "userListAllowed": [],
			   "dateAndTime": null, 
			   "isActive": formFeatureContainer.isActive, 
			   "isAccepted": formFeatureContainer.isAccepted, 
			   "name": formFeatureContainer.name, 
			   "formFeatureState": formFeatureContainer.formFeatureState,
			   "recipientIndex_id": formFeatureContainer.recipientIndex_id,
			   "form_id": formFeatureContainer.form_id
			}
		],
		"2": [
		  {
				"componentName": "formIndexTopic",
				"UIID": formIndexTopic.UIID ,
				"owner": userEmail,
				"dateAndTime": null,
				"allUsersAllowed": formIndexTopic.allUsersAllowed,
				"userListAllowed": formIndexTopic.userListAllowed,
				"isAccepted": formIndexTopic.isAccepted, 
				"isArchived": formIndexTopic.isArchived, 
				"name": formIndexTopic.name, 
				"formFeatureContainer_ids": [ "@CHILD_ID@" ]
		  }
		],
		"3": [
			{
				"componentName": "formIndexTopicListItem",
				"UIID": formIndexTopicListItem.UIID ,
				"owner": userEmail,
				"allUsersAllowed": formIndexTopicListItem.allUsersAllowed,
				"userListAllowed": formIndexTopicListItem.userListAllowed,
				"dateAndTime": formIndexTopicListItem.dateAndTime,
				"type": formIndexTopicListItem.type,
				"formIndexTopic_ids": [formIndexTopicListItem.formIndexTopic_ids , "@CHILD_ID@"]
			}
		]
	
	};
	
	return this.postComponentsHttpRequestService.httpStoreMultiLevelComponentsWithUpdateOnExistingOnes(userEmail, formFeatureContainerAndParent )

}

createFormIndexTopic_UpdateFormIndexTopicListItem(userEmail: string, formIndexTopic: FormIndexTopic, formIndexTopicListItem: FormIndexTopicListItem ){

	console.log(formIndexTopic , formIndexTopicListItem )
	const formFeatureContainerAndParent = {

	
		"1": [
		  {
				"componentName": "formIndexTopic",
				"UIID": formIndexTopic.UIID ,
				"owner": userEmail,
				"dateAndTime": null,
				"allUsersAllowed": formIndexTopic.allUsersAllowed,
				"userListAllowed": formIndexTopic.userListAllowed,
				"isAccepted": formIndexTopic.isAccepted, 
				"isArchived": formIndexTopic.isArchived, 
				"name": formIndexTopic.name, 
				"formFeatureContainer_ids": []
		  }
		],
		"2": [
			{
				"componentName": "formIndexTopicListItem",
				"UIID": formIndexTopicListItem.UIID ,
				"owner": userEmail,
				"allUsersAllowed": formIndexTopicListItem.allUsersAllowed,
				"userListAllowed": formIndexTopicListItem.userListAllowed,
				"dateAndTime": formIndexTopicListItem.dateAndTime,
				"type": formIndexTopicListItem.type,
				"formIndexTopic_ids": [formIndexTopicListItem.formIndexTopic_ids , "@CHILD_ID@"]
			}
		]
	
	};
	
	return this.postComponentsHttpRequestService.httpStoreMultiLevelComponentsWithUpdateOnExistingOnes(userEmail, formFeatureContainerAndParent )

}






}