<!-- <p>tab-feature-results works!</p> -->


 <div *ngFor="let tabFeature of tabFeatureDetailResults; let i=index">

    <div [ngSwitch]="tabFeature.featureResultType">
        <app-toggle-word-button-result [toggleWordButtonDetailResults]="tabFeature?.featureDetailResults"  *ngSwitchCase="'toggleWordButtonResult'" ></app-toggle-word-button-result>
        <app-word-input-result [wordInputDetailResults]="tabFeature?.featureDetailResults" *ngSwitchCase="'wordInputResult'" ></app-word-input-result>
        <app-word-result [wordDetailResult]="tabFeature?.featureDetailResults" *ngSwitchCase="'wordResult'" ></app-word-result>
        <app-single-feature-results [singleFeatureDetailResults]="tabFeature?.featureDetailResults" *ngSwitchCase="'singleFeatureResult'" ></app-single-feature-results>
        <app-fix-layout-results [fixLayoutDetailResult]="tabFeature?.featureDetailResults" *ngSwitchCase="'fixLayoutFeatureResult'" ></app-fix-layout-results>
        <app-fix-text-result [fixTextDetailResults]="tabFeature?.featureDetailResults" *ngSwitchCase="'fixTextResult'"></app-fix-text-result>
        <app-one-date-calendar-result [oneDateCalendarDetailResults]="tabFeature?.featureDetailResults" *ngSwitchCase="'oneDateCalendarDetailResult'"></app-one-date-calendar-result>
        <app-multi-entry-calendar-result [multiEntryCalendarDetailResult]="tabFeature?.featureDetailResults" *ngSwitchCase="'multiEntryCalendarDetailResult'"></app-multi-entry-calendar-result>
        <app-calculator-mix-table-result [calculatorMixTableDetailResult]="tabFeature?.featureDetailResults" *ngSwitchCase="'calculatorMixTableDetailResult'"></app-calculator-mix-table-result>
    </div>

</div>

