

export class  FeatureTopic {



    constructor  ( public _id: string , 
                   public componentName: string , 
                   public UIID: string , 
                   public owner: string , 
                   public allUsersAllowed: boolean,
                   public userListAllowed: string[],
                   public dateAndTime: string , 
                   public type: string ,
                   public isStandardFeature: boolean, 
                   public isTab: boolean, 
                   public name : string , 
                   public featureContainerListItems_ids : string[] , // item of feature containers list 
                   ) {}
                   /**
                    * export class ParagraphFeatureContainer {

    constructor( public paragraphFeatureReference_id: string, 
                 public isActive: boolean, 
                 public isAccepted: boolean, 
                 public name: string, // can be the name of the topic at sender 
                 public paragraphFeatureState: boolean , // visible or not is to change at every check
                ){}
}
                    */


    }