import { Component , OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoginService } from './login/login.service';
import { AppLoadingService } from './services/other-components-services/app-loading.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit , OnDestroy {

  title = 'PROJECT';

  confirmedStatus: boolean 

  startLoadingOnLoginComnfirmationSubscription: Subscription
  stopLoadingOnReceivedAllUserLoggedInDataSubscription: Subscription

  constructor( private loginService: LoginService , 
               private appLoadingService: AppLoadingService
              ){

               this.startLoadingOnLoginComnfirmationSubscription = this.loginService.getStartLoadingMainPageOnLoginConfirmation()
                   .subscribe(()=> { 
                    this.onStartedLoadingSpinner() 
               })

               this.stopLoadingOnReceivedAllUserLoggedInDataSubscription = this.appLoadingService.getAppLoadingToStop()
                   .subscribe(()=> {
                    this.onStoppedLoadingSpinner()
                   })
             }


  ngOnInit(){

    this.confirmedStatus = false 



  }


  onUserFirstCheck($event){
    console.log('on first user check', $event)
    this.confirmedStatus = $event 
  }

  onStartedLoadingSpinner(){
    console.log(this.confirmedStatus)
    this.confirmedStatus = false
  }

  onStoppedLoadingSpinner(){
    this.confirmedStatus = true
  }

  ngOnDestroy(): void {
    this.startLoadingOnLoginComnfirmationSubscription.unsubscribe()
    this.stopLoadingOnReceivedAllUserLoggedInDataSubscription.unsubscribe()
      
  }





}
