<!-- <p>single-word-result works!</p> -->


<!-- need to find the way to adapt input lenght with input value -->
   <mat-form-field appearance="none">
        <input matInput disabled value="{{singleWordDetailResult?.singleWordDetailResultContent}}">
   </mat-form-field>


                       
