import { Injectable } from "@angular/core";
import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from "@angular/common/http";
import { DropDownSelect } from "./drop-down-select.model";
import { map, tap, catchError } from "rxjs/operators";
import { throwError } from "rxjs";

@Injectable ({
    providedIn: 'root' 
})

export class DropDownSelectHttpRequestService {

    constructor (private http: HttpClient ){}


    //       httpStoreDropDownSelect( dropDownSelect: DropDownSelect){
    //          return this.http.post(
    //         'http://localhost:8080/market-in-service/rest/component/toni@libero.it/dropDownSelect', dropDownSelect,
    //         )
          
    //         // .subscribe(response => {
    //         //     console.log(response)
    //         // })
    // }

}