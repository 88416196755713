import { AfterViewInit, ChangeDetectorRef, Component, Inject, NgZone, OnDestroy, OnInit , ViewEncapsulation } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { LoginHttpRequestService } from './login-http-request.service';

import { UserObject } from '../auth/user.model';
import { AuthService } from '../services/auth-services/auth.service';
import { AppLoadingService } from '../services/other-components-services/app-loading.service';
import { FormGroup , FormControl} from '@angular/forms';
import { UserObjectUpdateService } from '../services/other-components-services/user-object-update.service';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginService } from './login.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { CredentialResponse } from 'google-one-tap';
import { LogoutService } from './logout.service';
import { ParagraphTopicService } from '../forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.service';
import { ParagraphTopic } from '../forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.model';
import { FeatureIndexSelection } from '../forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-index-topic-list/feature-index-selection.model';
import { FeatureTopicService } from '../forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-topic.service';
import { StoreDefaultComponentsSevice } from '../services/get-and-store-components/store-default-components.service';
import { MatDialog } from '@angular/material/dialog';
import { QuickCommunicationPopUpComponent } from '../loading-spinner/quick-communication-pop-up/quick-communication-pop-up.component';
import { ErrorsDialogManagerService } from '../services/other-components-services/errors-dialog-manager.service';


declare var google: any


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit , AfterViewInit , OnDestroy {

  user: string 

  existingTestUserEmails: string[] = [ 'antonio.hhd@gmail.com', 
  'develop.project.files@gmail.com', 'family1.docs@gmail.com', 'kojihuu@gmail.com',
  'kiki.villa.management@gmail.com', 'maionci75@gmail.com', 'gerundio.grinton@gmail.com',
  'pt.yourbaliconcierge@gmail.com', 'lilu.rental@gmail.com', 'baligypsymoon@gmail.com', 
  'sharyati@gmail.com', 'lilurentalbali@gmail.com' , 'ccwijnia@gmail.com'] 

  language: string;

  emailEditForm: FormGroup


  userAlreadyExist: boolean
  newUserEmail: string

  /**
   * trial to send user information to auth service 
   */
  loggedInUser: UserObject
  loggedOutUser: UserObject

  updatedEmail: string 

  subscribeToDoneLogin: Subscription 




  constructor(
              private translateService: TranslateService ,
              private userObjectUpdateService: UserObjectUpdateService,
              private loginHttpRequestService: LoginHttpRequestService,
              private storeDefaultComponentsSevice: StoreDefaultComponentsSevice ,
              private authService: AuthService ,
              private appLoadingService: AppLoadingService, 
              public  dialogRef: MatDialogRef<LoginComponent>,
              private loginService: LoginService , 
              private ngZone: NgZone , 
              private logoutService: LogoutService , 
              private paragraphTopicService: ParagraphTopicService , 
              private featureTopicService: FeatureTopicService ,
              private cdr: ChangeDetectorRef, 
              private dialog: MatDialog, 
              @Inject(MAT_DIALOG_DATA) public editData: any
              ) { }


 
  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail


    this.emailEditForm = new FormGroup ({
      email2: new FormControl ('')
    });

    this.emailEditForm.valueChanges.subscribe((value) => {
      console.log(value),
      this.updatedEmail = value.email2
    });

    // const newHeaderIndexSelection = new Header (false, false, false, false, false, false, true, false )
    // this.headerService.nextIndexSelection(newHeaderIndexSelection)

    this.language = localStorage.getItem('language') || 'en' ;

    this.userAlreadyExist = false;
    this.newUserEmail = 'antoniohhd@gmailnumber234'
    if (this.userAlreadyExist){}

    this.subscribeToDoneLogin = this.loginService.adviseLoginOfCompleteLog
        .subscribe(()=>{ console.log('login is done ')})
    
    

  };

   isLoggedOut(){
    this.appLoadingService.onInitializeAppLoading()
    const newUserObject = new UserObject ( null , null , null , new Date())
    this.authService.onUpdateLoggedOutUser( newUserObject )

   }

    ngAfterViewInit(): void {
      
    
    google.accounts.id.initialize({
      client_id: "331753095341-50c26hhhmhs7kvfho2d98bs691uvi7pv.apps.googleusercontent.com",
       
      // callback: this.loginService.handleCredentialResponse
      callback: this.handleCredentialResponse.bind(this)
    })
    google.accounts.id.renderButton(
      document.getElementById("buttonDiv"),
      { theme: "outline", size: "large" }  // customization attributes
    );
    google.accounts.id.prompt(); // also display the One Tap dialog

  
   };


  handleCredentialResponse(response: CredentialResponse ){
    console.log(response , response.credential );
   
    // debugger 

    if ( response.credential ){
    
          // localStorage.setItem("token", response.credential  )
          var base64Url = response.credential.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
      
            console.log(JSON.parse(jsonPayload))
      
            const userEmailToEmit: string = JSON.parse(jsonPayload).email
            console.log(userEmailToEmit)
      
            const newUserObject = new UserObject ( userEmailToEmit , 'nuovoUser1', 'token1', new Date() )
            console.log( 'userObject is ', newUserObject )

            /**
             * adding check on our users TEST LIST 
             */

            if ( this.existingTestUserEmails.includes(userEmailToEmit)){
                 localStorage.setItem("token", response.credential  )
                 /**
                  * run these once credential are true 
                  */
                 this.ngZone.run(()=>{
                 this.loginService.startLoadingMainPageOnLogin()
                 this.onLoginUserWithGoogle(newUserObject)
                 /**
                  * send to a service the isLoading globally while remaining in the same page and same http page
                  * and stopLoading once all the user data are set 
                  */
                 // this.dialogRef.close()
                 })
            }

            if ( !this.existingTestUserEmails.includes(userEmailToEmit)){
 
              console.log('POP UP WITH WE ARE JUST TESTING !!!  CREATE A COMPONENT TO LET NEW USER SEND US HIS INFO ON A EMAIL DONE WITH OUR FORM , OBVIOUSLY CREATE THE FORM')
              
              this.ngZone.run(()=>{
              this.onRemoveLoginPopUp()
              this.loginService.popUpLimitedGroupInfo()
              })
              this.cdr.detectChanges()
              // open a pop up where basically it will help send a mail to us to 
              // let us know what they would like to do 
              

            }
      
    }

    // adding if response.credential are false 
    if ( !response.credential ){
      console.log( 'CREDENTIAL INVALID')
    }

  }

   onLoginInputEmail(){
     /**
     * start loading the full page
     * send new User email to all components and set the email to the specific service
     * send user to store default components :
     * 1- if the user is new it will create all new default components , and in response we will have the array of all new default components 
     * 2- if the user exist , the response will be an empty array []
     * 3- in case 1 , we need to create the first recipient and all parents index components and then let the system start
     * 4-in case 2 - we let the system start 
     */
    this.appLoadingService.onInitializeAppLoading()
    const newUserObject = new UserObject ( this.updatedEmail , 'nuovoUser1', 'token1', new Date() )

    /**
     *  1:  start loading main page
     */
    this.loginService.startLoadingMainPageOnLogin()
    /**
     *  2: request default components 
     */
   
    /** 
     *  3:  set paragrapgTopic reference at null in case the user is in paragraph create/edit page
     */
    this.paragraphTopicService.startingParagraphTopicReference = new ParagraphTopic (null, null, null, null, false, [], null, true, false, '', [] )
    const startingIndexSelection: FeatureIndexSelection = new FeatureIndexSelection ( true, false)
    this.featureTopicService.nextIndexSelection(startingIndexSelection)
   /**
    *  4: update logged in user to service and all components
    */
   this.authService.onUpdateLoggedInUser(newUserObject) // update user in all components 

   this.storeDefaultComponentsSevice.getUserDefaultComponentsAndSetup(newUserObject.email) // call default components 
       .subscribe(()=>{
       this.loginService.connectToPersonalFeatureIndexService(newUserObject.email)

   })
    
   
   /**
    *  5:  close the dialog login page
    */
   this.dialogRef.close()



                                                 
   }

   onLoginUserWithGoogle(userObject: UserObject ){
     /**
     * start loading the full page
     * send new User email to all components and set the email to the specific service
     * send user to store default components :
     * 1- if the user is new it will create all new default components , and in response we will have the array of all new default components 
     * 2- if the user exist , the response will be an empty array []
     * 3- in case 1 , we need to create the first recipient and all parents index components and then let the system start
     * 4-in case 2 - we let the system start 
     */
     this.appLoadingService.onInitializeAppLoading()
 
     /**
      *  1:  start loading main page
      */
     this.loginService.startLoadingMainPageOnLogin()
     /**
      *  2: request default components 
      */
     /** 
      *  3:  set paragrapgTopic reference at null in case the user is in paragraph create/edit page
      */
     this.paragraphTopicService.startingParagraphTopicReference = new ParagraphTopic (null, null, null, null, false, [], null, true, false, '', [] )
    /**
     *  4: update logged in user to service and all components
     */
    this.authService.onUpdateLoggedInUser(userObject)
   // this.authService.onUpdateAllComponentsWithThisUserEmailData(userObject.email) 
   this.storeDefaultComponentsSevice.getUserDefaultComponentsAndSetup(userObject.email)
       .subscribe(()=>{

        this.loginService.connectToPersonalFeatureIndexService(userObject.email)

       })
  //  this.startRequestDefaultsComponents.next(userObject.email)
    /**
     *  5:  close the dialog login page
     */
    this.dialogRef.close()      
   }


   onRemoveLoginPopUp(){
    console.log('Remove login popup')
    this.logoutService.onClickToCloseLoginPopUpDialog()
    this.dialogRef.close();
    this.logoutService.onContinueInitializeNullUserCreateForm()
    /**
     * send SUBJECT re-inizialize create-form with first recipientIndex at first one
     */
   

   }


  //  onLoginUserWithGoogleCredential($event){

  //   if ( this.existingUserEmails.indexOf( $event ) > -1) {  this.authService.onUpdateLoggedInUser($event) ,
  //        this.authService.onUpdateAllComponentsWithThisUserEmailData($event) 

  //        this.dialogRef.close();
  //   }

  //   if ( this.existingUserEmails.indexOf( $event) == -1) { 
  //        this.existingUserEmails.push( $event), //this page property

  //       // this.buildNewUserStartingComponentsService.buildInitialRecipientIndex(newUserObject.email)
  //       this.authService.onUpdateLoggedInUser($event)

  //       this.dialogRef.close();
  //   }          

  //  }
  



  changeLanguage(event: any ){

    this.translateService.use(event.target.value)
    // localStorage.setItem('language', language);
    // console.log(language)
   // window.location.reload();
    
  }


  onFetchAllSingleBaseComponents(){
    this.loginHttpRequestService.httpFetchAllSingleBaseComponents()
  }

  onFetchAllBaseComponents(){
    this.loginHttpRequestService.httpFetchAllBaseComponents()
  }

  onFetchAllMultiBaseComponents(){
    this.loginHttpRequestService.httpFetchAllMultiBaseComponents()
  }

  onFetchAllBasePublicComponentsWithOwnerAndUser(){
    this.loginHttpRequestService.httpFetchAllBasePublicComponentsWithOwnerAndUser()
  }

  onFetchAllBasePublicComponentsWithOwnerNoUser(){
    this.loginHttpRequestService.onFetchAllBasePublicComponentsWithOwnerNoUser()
  }

  onFetchAllComponentTypeWithSharedNameToWithoutOwner(){
    this.loginHttpRequestService.fetchAllComponentTypeWithSharedNameToWithoutOwner()
  }

  onFetchAllComponentTypeWithSharedNameToPlusOwner(){
    this.loginHttpRequestService.fetchAllComponentTypeWithSharedNameToPlusOwner()
  }

  onFetchAllSharedComponentTypeWithUser(){
    this.loginHttpRequestService.fetchAllSharedComponentTypeWithUser()
  }

  onFetchAllSharedComponentAnyTypeWithUser(){
    this.loginHttpRequestService.fetchAllSharedComponentAnyTypeWithUser()
  }

//  --------------------------  3rd colum ----------------------------------------
  onFetchAllFeatureIndexComponents(){
    this.loginHttpRequestService.httpFetchAllFeatureIndexComponents()
  }

  onFetchAllParagraphComponents(){
    this.loginHttpRequestService.httpFetchAllParagraphComponents()
  }


ngOnDestroy(): void {
    this.subscribeToDoneLogin.unsubscribe()
}


}
