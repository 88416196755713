<!-- <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>   -->

<div class="ParagraphComponentFrame" style="background-color:rgb(242, 248, 250) ;"  >

  <form [formGroup]="paragraphEditForm" (ngSubmit)="onSubmit()">
  
  
 <div  [hidden]="isEditMultiFeature" class="ParagraphComponentFrame">
  
  <h2 style="color: red">paragraph title</h2>

      
      <div class="form-group">
      
          <mat-form-field class="almost-half-width">
          <input 
          type="text" 
          id="name"
          formControlName="name"
          matInput
          placeholder="name: "
          maxlength="40"
          #nameInput>
          <mat-hint> this will be shown on the index side  </mat-hint>
        
          </mat-form-field>

      
      </div>


      <br>

    <div class="form-group">
  
          <mat-form-field class="full-width">
            <input 
            type="text" 
            id="description"
            formControlName="description"
            matInput
            placeholder="description : "
            maxlength="100"
            #descInput>
            <mat-hint>this will be shown while preparing your email and messages only</mat-hint>
              
          </mat-form-field>
      
      </div>

 
 </div>

  </form>


<br>

           <!-- use the dradDrop service to get various features -->
     
      <div  *ngIf="!(isHandset$ | async)">
      <div [hidden]="isEditMultiFeature"
           cdkDropList
           [cdkDropListData]="features"
           (cdkDropListDropped)="onDrop($event)"> 

        <div [hidden]="isEditMultiFeature">

      <h2 [hidden]="!dropped" style="color: #3f51b5" >
        <svg  style="color: #3f51b5" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
          <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
        </svg>
        select components 
      </h2>

     
        <div *ngFor="let feature of features ; let i=index " cdkDrag [cdkDragStartDelay]="300">
        
          <div class="ParagraphComponentFrame" 
               matTooltip="DRAG & DROP"
               matTooltipPosition="above"   >

         <div [hidden]="!isMobile" *ngIf="isHandset$ | async" class="row" >

           <div class="col-xs-1" >
            <div *ngIf="feature.owner === user">
              <svg (click)="onEditFeature(i)"
              style="color: lightgrey" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
             <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
             </svg>
            </div>
           </div>

          <div class="col-xs-9"></div>

          <div class="col-xs-1" (click)="onRemoveFeature(i)" >
           <svg style="color: lightgrey" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
           <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
           </svg>
          </div>

          <div class="col-xs-1"></div>
     
         </div>

         
         <div *ngIf="(isHandset$ | async) === false" class="row">

           <div class="col-xs-1 pointer"  >
            <div *ngIf="feature.owner === user" >
              <svg (click)="onEditFeature(i)" style="color: lightgrey" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
              </svg>
            </div>
           </div>

           <div class="col-xs-9"></div>

           <div class="col-xs-1 pointer" (click)="onRemoveFeature(i)">
            <svg style="color: lightgrey" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
            </svg>
           </div>

           <div class="col-xs-1"></div>
  
          </div>

          
           <div *ngIf="!isEditMultiFeature">
            <div [ngSwitch]="feature.featureName " > 
           
            <div  *ngSwitchCase="'wordInput'">
              <app-word-input-list 
              [isParagraphEdit]="isParagraphEdit"
              [currentI]="i" 
              [feature_from_paragraphEdit]="feature" 
              (changeIndex)='onChangedIndex($event)' 
              (holdOnCreateUpdateButtons)="onHoldCreateUpdateButtons()"
              [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
              [editFeature]="clickOnEditFeature.asObservable()">
            </app-word-input-list> 
            </div>
          

            <app-word-hint-list  
                     [isParagraphEdit]="isParagraphEdit"
                     [currentI]="i"  
                     [feature_from_paragraphEdit]="feature" 
                     (changeIndex)='onChangedIndex($event)' 
                     *ngSwitchCase="'wordInput+sentence'"
                     [editFeature]="clickOnEditFeature.asObservable(i)" >
            </app-word-hint-list>

            <app-word-less-list  
                      [isParagraphEdit]="isParagraphEdit"
                      [currentI]="i"  
                      [feature_from_paragraphEdit]="feature"  
                      (changeIndex)='onChangedIndex($event)' 
                      *ngSwitchCase="'word simple'"
                      [editFeature]="clickOnEditFeature.asObservable(i)">
            </app-word-less-list>

            <app-multi-features-list 
                      [isParagraphEdit]="isParagraphEdit"
                      [currentI]="i"  
                      [feature_from_paragraphEdit]="feature" 
                      (changeIndex)='onChangedIndex($event)' 
                      (holdOnCreateUpdateButtons)="onHoldCreateUpdateButtons()" 
                      (emitASubComponentIsHavingIssues)='onASubComponentIsHavingIssues($event)'
                      [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
                      *ngSwitchCase="'multiFeatures'" >
            </app-multi-features-list>

        
             <app-tab-list 
            [isParagraphEdit]="isParagraphEdit"
            [currentI]="i"  
            [feature_from_paragraphEdit]="feature"  
            (changeIndex)='onChangedIndex($event)' 
            *ngSwitchCase="'tabs'"
            [collectFeatureId]="passFeatureDataToComponentList.asObservable()">
            </app-tab-list>
           
            <!-- [editFeature]="clickOnEditFeature.asObservable(i)" -->

            <!-- this has a special emitter to show update/confirm button again because 
            this is done on the same page as paragraphEdit  -->
            <app-fix-layout-list
            [isParagraphEdit]="isParagraphEdit"
            [currentI]="i"  
            [feature_from_paragraphEdit]="feature"  
            (changeIndex)='onChangedIndex($event)' 
            *ngSwitchCase="'fixLayout'"
            (emitToParagraphEditIsDoneAndTurnDroppedFalse)='onUpdateDroppedConditionFromFixLayout($event)'
            [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
            [editFeature]="clickOnEditFeature.asObservable(i)">
            </app-fix-layout-list>

            <div *ngSwitchCase="'fixText'">
              <app-fix-text-list
              [isParagraphEdit]="isParagraphEdit"
              [currentI]="i"  
              [feature_from_paragraphEdit]="feature" 
              (changeIndex)='onChangedIndex($event)' 
              (holdOnCreateUpdateButtons)="onHoldCreateUpdateButtons()"
              [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
              [editFeature]="clickOnEditFeature.asObservable(i)">
              </app-fix-text-list>
            </div>

            <app-calendar-list
            [isParagraphEdit]="isParagraphEdit"
            [currentI]="i"  
            [feature_from_paragraphEdit]="feature"  
            (changeIndex)='onChangedIndex($event)' 
            *ngSwitchCase="'oneDateCalendar'"
            [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
            [editFeature]="clickOnEditFeature.asObservable(i)">
            </app-calendar-list> 

            <app-multi-entry-calendar-list
            [isParagraphEdit]="isParagraphEdit"
            [currentI]="i"  
            [feature_from_paragraphEdit]="feature"  
            (changeIndex)='onChangedIndex($event)' 
            *ngSwitchCase="'multiEntryCalendar'"
            [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
            [editFeature]="clickOnEditFeature.asObservable(i)">
            </app-multi-entry-calendar-list>

            <app-shared-calendar-status-list
            [isParagraphEdit]="isParagraphEdit"
            [currentI]="i"  
            [feature_from_paragraphEdit]="feature"  
            (changeIndex)='onChangedIndex($event)' 
            *ngSwitchCase="'sharedCalendarStatus'"
            [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
            [editFeature]="clickOnEditFeature.asObservable(i)">
            </app-shared-calendar-status-list>

            <app-calculator-mix-table-list
            [isParagraphEdit]="isParagraphEdit"
            [currentI]="i"  
            [feature_from_paragraphEdit]="feature"  
            (changeIndex)='onChangedIndex($event)' 
            *ngSwitchCase="'calculatorMixTable'"
            [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
            [editFeature]="clickOnEditFeature.asObservable(i)">
            </app-calculator-mix-table-list>

            <app-drop-down-select-list
            [isParagraphEdit]="isParagraphEdit"
            [currentI]="i"  
            [feature_from_paragraphEdit]="feature"  
            (changeIndex)='onChangedIndex($event)' 
            *ngSwitchCase="'dropDownSelect'"
            [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
            [editFeature]="clickOnEditFeature.asObservable(i)">
            </app-drop-down-select-list>

            <app-qr-code-generator-list
            *ngSwitchCase="'qrCodeType'">
            </app-qr-code-generator-list>

            <app-qr-code-scanner
            *ngSwitchCase="'ScanQRCode'">
            </app-qr-code-scanner>

            <app-comment
            [isParagraphEdit]="isParagraphEdit"
            *ngSwitchCase="'comment'">
            </app-comment>
           


            </div>
           </div>

          </div>
          </div> 
          <!-- end features for loop  -->

        
           </div>
      </div>   
      </div>

      <div  *ngIf="isHandset$ | async">
        <div [hidden]="isEditMultiFeature"> 
   
        <div [hidden]="isEditMultiFeature">
  
      
        <div [hidden]="!dropped">
          <br>
          <br>
         <h1 (click)="onOpenSideNav()" 
             align="center" 
             style="color: #3f51b5;">  
            
           <svg  style="color: #3f51b5" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
             <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
           </svg>
           select components
         </h1>
         </div>
  
       
          <div *ngFor="let feature of features ; let i=index ">
          
            <div class="ParagraphComponentFrame" >
  
           <div [hidden]="!isMobile" *ngIf="isHandset$ | async" class="row" >
  
             <div class="col-xs-1" >
              <div *ngIf="feature.owner === user">
                <svg (click)="onEditFeature(i)"
                style="color: lightgrey" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
               <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
               </svg>
              </div>
             </div>
  
            <div class="col-xs-9"></div>
  
            <div class="col-xs-1" (click)="onRemoveFeature(i)" >
             <svg style="color: lightgrey" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
             <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
             </svg>
            </div>
  
            <div class="col-xs-1"></div>
       
           </div>
  
           
           <div *ngIf="(isHandset$ | async) === false" class="row">
  
             <div class="col-xs-1 pointer"  >
              <div *ngIf="feature.owner === user" >
                <svg (click)="onEditFeature(i)" style="color: lightgrey" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                  <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                </svg>
              </div>
             </div>
  
             <div class="col-xs-9"></div>
  
             <div class="col-xs-1 pointer" (click)="onRemoveFeature(i)">
              <svg style="color: lightgrey" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
              </svg>
             </div>
  
             <div class="col-xs-1"></div>
    
            </div>
  
            
             <div *ngIf="!isEditMultiFeature">
              <div [ngSwitch]="feature.featureName " > 
             
              <div  *ngSwitchCase="'wordInput'">
                <app-word-input-list 
                [isParagraphEdit]="isParagraphEdit"
                [currentI]="i" 
                [feature_from_paragraphEdit]="feature" 
                (changeIndex)='onChangedIndex($event)' 
                (holdOnCreateUpdateButtons)="onHoldCreateUpdateButtons()"
                [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
                [editFeature]="clickOnEditFeature.asObservable()">
              </app-word-input-list> 
              </div>
            
  
              <app-word-hint-list  
                       [isParagraphEdit]="isParagraphEdit"
                       [currentI]="i"  
                       [feature_from_paragraphEdit]="feature" 
                       (changeIndex)='onChangedIndex($event)' 
                       *ngSwitchCase="'wordInput+sentence'"
                       [editFeature]="clickOnEditFeature.asObservable(i)" >
              </app-word-hint-list>
  
              <app-word-less-list  
                        [isParagraphEdit]="isParagraphEdit"
                        [currentI]="i"  
                        [feature_from_paragraphEdit]="feature"  
                        (changeIndex)='onChangedIndex($event)' 
                        *ngSwitchCase="'word simple'"
                        [editFeature]="clickOnEditFeature.asObservable(i)">
              </app-word-less-list>
  
              <app-multi-features-list 
                        [isParagraphEdit]="isParagraphEdit"
                        [currentI]="i"  
                        [feature_from_paragraphEdit]="feature" 
                        (changeIndex)='onChangedIndex($event)' 
                        (holdOnCreateUpdateButtons)="onHoldCreateUpdateButtons()" 
                        (emitASubComponentIsHavingIssues)='onASubComponentIsHavingIssues($event)'
                        [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
                        *ngSwitchCase="'multiFeatures'" >
              </app-multi-features-list>
  
          
               <app-tab-list 
              [isParagraphEdit]="isParagraphEdit"
              [currentI]="i"  
              [feature_from_paragraphEdit]="feature"  
              (changeIndex)='onChangedIndex($event)' 
              *ngSwitchCase="'tabs'"
              [collectFeatureId]="passFeatureDataToComponentList.asObservable()">
              </app-tab-list>
             
              <!-- [editFeature]="clickOnEditFeature.asObservable(i)" -->
  
              <!-- this has a special emitter to show update/confirm button again because 
              this is done on the same page as paragraphEdit  -->
              <app-fix-layout-list
              [isParagraphEdit]="isParagraphEdit"
              [currentI]="i"  
              [feature_from_paragraphEdit]="feature"  
              (changeIndex)='onChangedIndex($event)' 
              *ngSwitchCase="'fixLayout'"
              (emitToParagraphEditIsDoneAndTurnDroppedFalse)='onUpdateDroppedConditionFromFixLayout($event)'
              [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
              [editFeature]="clickOnEditFeature.asObservable(i)">
              </app-fix-layout-list>
  
              <div *ngSwitchCase="'fixText'">
                <app-fix-text-list
                [isParagraphEdit]="isParagraphEdit"
                [currentI]="i"  
                [feature_from_paragraphEdit]="feature" 
                (changeIndex)='onChangedIndex($event)' 
                (holdOnCreateUpdateButtons)="onHoldCreateUpdateButtons()"
                [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
                [editFeature]="clickOnEditFeature.asObservable(i)">
                </app-fix-text-list>
              </div>
  
              <app-calendar-list
              [isParagraphEdit]="isParagraphEdit"
              [currentI]="i"  
              [feature_from_paragraphEdit]="feature"  
              (changeIndex)='onChangedIndex($event)' 
              *ngSwitchCase="'oneDateCalendar'"
              [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
              [editFeature]="clickOnEditFeature.asObservable(i)">
              </app-calendar-list> 
  
              <app-multi-entry-calendar-list
              [isParagraphEdit]="isParagraphEdit"
              [currentI]="i"  
              [feature_from_paragraphEdit]="feature"  
              (changeIndex)='onChangedIndex($event)' 
              *ngSwitchCase="'multiEntryCalendar'"
              [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
              [editFeature]="clickOnEditFeature.asObservable(i)">
              </app-multi-entry-calendar-list>

              <app-shared-calendar-status-list
              [isParagraphEdit]="isParagraphEdit"
              [currentI]="i"  
              [feature_from_paragraphEdit]="feature"  
              (changeIndex)='onChangedIndex($event)' 
              *ngSwitchCase="'sharedCalendarStatus'"
              [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
              [editFeature]="clickOnEditFeature.asObservable(i)">
            </app-shared-calendar-status-list>
  
              <app-calculator-mix-table-list
              [isParagraphEdit]="isParagraphEdit"
              [currentI]="i"  
              [feature_from_paragraphEdit]="feature"  
              (changeIndex)='onChangedIndex($event)' 
              *ngSwitchCase="'calculatorMixTable'"
              [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
              [editFeature]="clickOnEditFeature.asObservable(i)">
              </app-calculator-mix-table-list>
  
              <app-drop-down-select-list
              [isParagraphEdit]="isParagraphEdit"
              [currentI]="i"  
              [feature_from_paragraphEdit]="feature"  
              (changeIndex)='onChangedIndex($event)' 
              *ngSwitchCase="'dropDownSelect'"
              [collectFeatureId]="passFeatureDataToComponentList.asObservable()"
              [editFeature]="clickOnEditFeature.asObservable(i)">
              </app-drop-down-select-list>
  
              <app-qr-code-generator-list
              *ngSwitchCase="'qrCodeType'">
              </app-qr-code-generator-list>
  
              <app-qr-code-scanner
              *ngSwitchCase="'ScanQRCode'">
              </app-qr-code-scanner>
  
              <app-comment
              [isParagraphEdit]="isParagraphEdit"
              *ngSwitchCase="'comment'">
              </app-comment>
             
  
  
              </div>
             </div>
  
            </div>
            </div> 
            <!-- end features for loop  -->
  
          
             </div>
        </div>   
        </div>


      
          <div *ngIf="isEditMultiFeature">
            <div *ngIf="!isEditTab" class="ParagraphComponentFrame" >
              <app-multi-features-edit 
              [isParagraphEdit]="isParagraphEdit" 
              (updateMultiFeature)='onUpdateMultiFeature()' 
              [multiFeature]="editMultifeature"  >
              </app-multi-features-edit>  
            </div>

            <div *ngIf="isEditTab" class="ParagraphComponentFrame" >
              <app-tab-edit
               [isParagraphEdit]="isParagraphEdit" 
               (updateTab)='onUpdateTabFeature($event)'
               (onScrollToBottomOnParentComponent)="onScrollToBottomOnParentComponentFromTabEdit($event)"
               [tabId]="tabId"
               [tab]="editTab">
              </app-tab-edit>
            </div>
            
          </div>


      <br>


      <!-- <div  class="ParagraphComponentFrame" > -->

      <app-loading-spinner *ngIf="holdCreateUpdateButtons_onLoadingVisual"></app-loading-spinner>


      <div *ngIf="!holdCreateUpdateButtons_onLoadingVisual" class="col-xs-12" >
      <div [hidden]="isEditMultiFeature">
        <div [hidden]="dropped" >
      
          <!-- <div class="row"> -->
            <br>
            <div class="row">
              <!-- <div class="d-flex justify-content-center"> -->
              <div *ngIf="!isEditMode" 
                   style="background-color:orange " 
                   class="ParagraphComponentButton pointer" 
                   (click)="onClearParagraph()"  >
                   
                <h2 align="center" style="color: white"> clear </h2>
              </div>
              <!-- </div> -->
            </div>

            <div class="row">
              <!-- <div class="d-flex justify-content-center"> -->
              <div *ngIf="!isEditMode && !aSubComponentIsHavingIssues" 
                   style="background-color:#3f51b5" 
                   class="ParagraphComponentButton pointer" 
                   (click)="onSubmit()" >
                <h2  align="center" style="color: white;">  add paragraph </h2>
              </div>
              <!-- </div> -->
            </div>
           
          <!-- </div> -->
         
          <div class="row">
            <!-- <div class="d-flex justify-content-center"> -->
            <div *ngIf="isEditMode && !aSubComponentIsHavingIssues" 
                 style="background-color:#3f51b5" 
                 class="ParagraphComponentButton pointer" 
                 (click)="onSubmit()" >
              <h2  align="center" style="color: white;">  update paragraph </h2>
            </div>
            <!-- </div> -->
          </div>
          </div>  
         
         
      </div>
      </div>

      <div #FeaturesEnd></div>

      <!-- </div> -->
      
    <!-- </form> -->
  
</div> 

<a align="end" href="https://github.com/angular/components/blob/main/LICENSE"> Code licensed under an MIT-style License.</a>
       


   
   
  
  
  