import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import {FormBuilder, FormArray,FormControl , FormGroup, Validators, CheckboxControlValueAccessor} from '@angular/forms';
import { CheckBoxSelect } from '../check-box-select.model';
import { CheckBoxGroup }from '../check-box-group.model';
import { CheckBox } from '../check-box.model'
import { CheckBoxSelectService } from '../check-box-select.service'
import { isTemplateHead } from 'typescript';
import { Subscription, Subject, Observable } from 'rxjs';
import { Feature } from '../../../../../feature.model';
import { FeatureResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/feature-result.model';
import { CheckBoxDetailResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/check-box-result/check-box-result.model';
import { createUrlResolverWithoutPackagePrefix } from '@angular/compiler';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { FormService } from 'src/app/forms/form.service';

@Component({
  selector: 'app-check-box-select-item',
  templateUrl: './check-box-select-item.component.html',
  styleUrls: ['./check-box-select-item.component.css']
})
export class CheckBoxSelectItemComponent implements OnInit {

  user: string 

 public checkBoxSelectForm: FormGroup
 
  checkBoxSelect : CheckBoxSelect

  toppings: FormGroup;

  index: number
  @Input() index_id: string
  @Input() isParagraphEdit: boolean
  @Input() feature: Feature

  @Input() isTabItem: boolean
  @Input() tab_index: number 

  featureResult: FeatureResult

  /**
   * receiving the click from fixTextList to edit the component
   */
  @Input() subscriptionToEditComponentItemIsOn: boolean
  currentFeature: Feature
  @Input() editFeatureItem: Observable<void>;
  editFeatureEventFromWordInputList: Subscription; 


  mailTextFeatureBodyPart: string

  iteration: number
  iterationValue: boolean

  @Output() updateBothFeatureResultAndTextPlusIndex = new EventEmitter<any>()

  requestToFinalizeResultAgain: Subscription 

  constructor( private _fb: FormBuilder, 
               private formService: FormService,
               private userObjectUpdateService: UserObjectUpdateService ,
               private checkBoxSelectService: CheckBoxSelectService) { 
   
  this.toppings = _fb.group({
      restaurant: false,
      bar: false,
      lounge: false,
      club: false,
      foodTruck: false 
      
    });
    
  }

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    console.log(this.toppings.value)
    console.log(this.toppings.controls)
    console.log( Object.keys(this.toppings.controls) )
    console.log(Object.values(this.toppings.controls)[1].value )

    console.log(Object.keys(this.toppings.controls)[1])

    this.iteration = Object.keys(this.toppings.controls).length

    this.checkBoxSelect = this.checkBoxSelectService.getCheckBoxSelect(0);
    console.log(this.checkBoxSelect)

    console.log('feature = ', this.feature, this.checkBoxSelect)

    this.checkBoxSelectForm = this._fb.group ({
      checkBoxArray : this._fb.array([])
    })

    if (this.isParagraphEdit !== true){
      this.requestToFinalizeResultAgain = this.formService.getRequestToFinalizeResultAgain().subscribe(()=> this.onSend())
    } 
    console.log(this.index)


      if (this.isParagraphEdit === true){
    this.editFeatureEventFromWordInputList = this.editFeatureItem.subscribe((data)=> this.checkIfMatchId(data))
   }

   this.mailTextFeatureBodyPart = ''

  this.mailTextFeatureBodyPart = '%0D%0A'+'selected venue: '+'%0D%0A%0D%0A'
   const newCheckBoxes : CheckBox[] = []

   for ( let i=0 ; i< this.iteration  ; i++ ){

    const newItem = Object.keys(this.toppings.controls)[i]
    const newValue = Object.values(this.toppings.controls)[i].value

    const newCheckBox = new CheckBox (newItem, newValue)

   this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+newItem+'%0D%0A'



    newCheckBoxes.push(newCheckBox)
   }

   const newFeatureResultType = 'checkBoxDetailResult'
   const newFeatureResultName = 'prova checkBox'
   const newFeatureDetailResult = new CheckBoxDetailResult (newCheckBoxes)

   const newFeatureResult = new FeatureResult (newFeatureResultType, newFeatureResultName, newFeatureDetailResult)
   
   this.featureResult = newFeatureResult

   console.log(this.featureResult)

    

    if (this.isParagraphEdit !== true){
      this.toppings.valueChanges.subscribe(()=> this.onSend())
      setTimeout(()=>{
        this.onSend()
      },200)}

  };

  onSend(){
    console.log('sending form')
    this.onSubmit()

  }

  onSubmit(){
    this.mailTextFeatureBodyPart = '%0D%0A%0D%0A'+'selected venue: '+'%0D%0A%0D%0A'
    const newCheckBoxes : CheckBox[] = []

   for ( let i=0 ; i< this.iteration  ; i++ ){

    const newItem = Object.keys(this.toppings.controls)[i]
    const newValue = Object.values(this.toppings.controls)[i].value

    const newCheckBox = new CheckBox (newItem, newValue)

    if (Object.values(this.toppings.controls)[i].value){
      this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+newItem+'%0D%0A'
    }

    newCheckBoxes.push(newCheckBox)
   }

   const newFeatureResultType = 'checkBoxDetailResult'
   const newFeatureResultName = 'prova checkBox'
   const newFeatureDetailResult = new CheckBoxDetailResult (newCheckBoxes)

   const newFeatureResult = new FeatureResult (newFeatureResultType, newFeatureResultName, newFeatureDetailResult)
   
   this.featureResult = newFeatureResult

   console.log(this.featureResult)
   console.log(this.mailTextFeatureBodyPart)

   this.onUpdateFeatureResultEvent()



  }

  onUpdateFeatureResultEvent(){

    if ( this.isTabItem === true){ this.tab_index = this.tab_index }
    if ( this.isTabItem !== true){ this.tab_index = null }

    const newTab_index = this.tab_index
    const newFeatureDrop_Id = this.feature.dropId
    const newFeatureResult: FeatureResult = this.featureResult
    const newMailTextFeatureBodyPart = this.mailTextFeatureBodyPart

    this.updateBothFeatureResultAndTextPlusIndex.emit({ newTab_index, newFeatureDrop_Id, newFeatureResult, newMailTextFeatureBodyPart})
  
  }

  checkIfMatchId(data){}

  ngOnDestroy(){
      
    if (this.isParagraphEdit === true){
      this.editFeatureEventFromWordInputList.unsubscribe();
    } else {
      this.requestToFinalizeResultAgain.unsubscribe()
    }
  }



}
