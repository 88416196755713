import { Component, OnInit, Inject , ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pop-up-error-dialog',
  templateUrl: './pop-up-error-dialog.component.html',
  styleUrls: ['./pop-up-error-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PopUpErrorDialogComponent implements OnInit {

  constructor( @Inject(MAT_DIALOG_DATA) public editData: any, 
               private dialogRef: MatDialogRef<PopUpErrorDialogComponent>) { }


  content: string    
  
  
  ngOnInit(){

    this.content = this.editData.textContent 

  }

}
