<!-- <p>contact-list works!</p> -->


<div class="ParagraphComponentFrame">
   
<div class="col">
   
    <div class="row" style="background-color: #3f51b5;">

        <hr>

            <div class="col"  style="text-align: center;">
                <h2 style="color: white; vertical-align: middle;">contacts</h2>                                
            </div>
    
       
    </div>
    <br>

    <div class="row" *ngFor="let contactDetail of contactDetails; let i=index">
        <app-contact-item [weAreOnShareParagraphFormIndex]="weAreOnShareParagraphFormIndex"
                          [isContactEditMode]="isContactEditMode"
                          [contactDetails]="contactDetail">
        </app-contact-item>
    </div>

    <br>

    <div class="row">
        <div class="col-xs-9">
            <form [formGroup]="newContactName" >
                <mat-form-field class="full-width">
                  <input
                  placeholder="add contact "
                  matInput 
                  type="email"
                  id="contactName"
                  formControlName="contactName"
                  maxlength="50">
                  <mat-hint>  email </mat-hint>
                </mat-form-field>       
              </form>
        </div>

        <div class="col-xs-1">
            <button type="button" (click)="onAddContactDetail()" style="background-color: #3f51b5; color:white" class="btn">+</button>
        </div>
        <div class="col-xs-2"></div>     
    </div>

    <br>

    <div class="row">
        <div class="col" >
            <div>
                <svg *ngIf="!isSelected" (click)="onSelectedContact()" style="color: #3f51b5" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  </svg>
            </div>
           <div>
            <svg *ngIf="isSelected" (click)="onDeselectContact()"  style="color: #3f51b5" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
              </svg>
           </div>
            
        </div>
        <div class="col" style="color: #3f51b5">
            SHARE TO PUBLIC 
        </div>
    </div>

    <br>

    <div class="row">
        <button class="btn btn-danger btn-lg">
             share 
             <svg style="color: white;"  xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
              </svg>  
        </button>
    </div>

</div>
<br>
</div>

   

