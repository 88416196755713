import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';

interface LocationData {
  countries: string[];
  states: { [key: string]: { [key: string]: boolean } };
  cities: { [key: string]: { [key: string]: string[] } };
}

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
export class SelectComponent implements OnInit {


  data: LocationData = { countries: [], states: {}, cities: {} };
  selectedCountry: string = 'import countries';
  selectedState: string = 'import states';
  selectedCity: string = 'import cities';
  countriesFile: File = null;
  statesFile: File = null;
  citiesFile: File = null;
 
  constructor(){
    this.data = {
      countries: [],
      states: {},
      cities: {}
    };
  }

  ngOnInit(): void {
      
  }

  onChangeCountry() {
    if (this.selectedCountry === 'import countries') {
      console.log('on change country')
      this.onCountryFileSelected();
    }
    else {
      this.selectedState = 'import states';
      this.selectedCity = 'import cities';
    }
  }

  onCountryFileSelected(event?: any) {
    console.log(event)
    const input = event ? event.target : document.getElementById('country-file-input') as HTMLInputElement;
    if (!input.files || input.files.length === 0) {
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const fileData = reader.result;
      console.log(fileData)
      const workbook = XLSX.read(fileData, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const countries = XLSX.utils.sheet_to_json(worksheet);

      console.log(workbook, sheetName, worksheet, countries)
      this.getColumnData(countries)

      this.data.countries = countries.map((country: any) => country['Country']);
      this.selectedCountry = this.data.countries[0];
      this.selectedState = 'import states';
      this.selectedCity = 'import cities';
      console.log(this.data.countries, this.selectedCountry )
    };
    reader.readAsBinaryString(input.files[0]);

  }

  getColumnData(data: any[]): any[][] {
    const columns = Object.keys(data[0]);
    const columnData: any[][] = [];
    for (const column of columns) {
      const columnValues = data.map(row => row[column]);
      columnData.push(columnValues);
    }
    console.log(columnData)


    return columnData;
    
  };

  onChangeState() {
    if (this.selectedState === 'import states') {
      this.onStateFileSelected();
    }
    else {
      this.selectedCity = 'import cities';
    }
  }

  onStateFileSelected(event?: any) {
    const input = event ? event.target : document.getElementById('state-file-input') as HTMLInputElement;
    if (!input.files || input.files.length === 0) {
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const fileData = reader.result;
      const workbook = XLSX.read(fileData, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const states = XLSX.utils.sheet_to_json(worksheet);
      const statesObj: { [key: string]: boolean } = {};
      states.forEach((state: any) => {
        const stateName = state['State'];
        if (stateName) {
          statesObj[stateName] = true;
        }
      });
      this.data.states[this.selectedCountry] = statesObj;
      this.selectedState = Object.keys(statesObj)[0];
      this.selectedCity = 'import cities';
    };
    reader.readAsBinaryString(input.files[0]);
  }

  onCityFileSelected(event?: any) {

    const input = event ? event.target : document.getElementById('city-file-input') as HTMLInputElement;
    if (!input.files || input.files.length === 0) {
      return;
    }
  
    const file = input.files[0];
    const reader = new FileReader();
    reader.readAsBinaryString(file);
  
    reader.onload = () => {
      const data = reader.result as string;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
  
      const cities: string[][] = [];
  
      for (let cell in worksheet) {
        if (cell[0] === '!') {
          continue;
        }
  
        const cellRef = XLSX.utils.decode_cell(cell);
        const row = cellRef.r.toString();
        const col = cellRef.c.toString();
  
        const value = worksheet[cell].v.toString();
        if (!cities[row]) {
          cities[row] = [];
        }
        cities[row][col] = value;
      }
  
      this.data.cities[this.selectedCountry][this.selectedState] = [];
      for (let row in cities) {
        const city = cities[row][0];
        if (city) {
          this.data.cities[this.selectedCountry][this.selectedState].push(city);
        }
      }
  
      this.selectedCity = 'import cities';
      input.value = '';
    };
  }
  

}