<!-- <p>fix-layout-edit-description works!</p> -->

<div class="row">
    <div class="col-xs-12">
        <form [formGroup]="fixLayoutEditDescriptionForm">

            <div class="row">
                <mat-form-field>
                    <mat-label>label</mat-label> 
                    <input 
                       matInput 
                       placeholder='input label description'
                       type="text"
                       id="label"
                       formControlName="label" >
                    <!-- <mat-hint >  </mat-hint> -->
                </mat-form-field>
            </div>

            <br>
           
            <div class="row">
                <mat-form-field [ngStyle]="{width: getWidth(i, j)}">
                    <mat-label>word</mat-label> 
                    <input 
                       matInput 
                       placeholder='input description'
                       type="text"
                       id="description"
                       formControlName="description">
                    <!-- <mat-hint > here is the "hint"  </mat-hint> -->
                </mat-form-field>
            </div>

            <br>

            <div class="row">
                <button (click)="onSubmitDescription()" class="btn btn-primary" >confirm</button>
            </div>

        </form>
    </div>
</div>
