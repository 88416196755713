

export class RecipientIndex {

    
    constructor ( public _id : string,
                  public componentName: string ,
                  public UIID: string,
                  public owner: string,
                  public allUsersAllowed: boolean ,
                  public userListAllowed: string[], 
                  public dateAndTime: string ,
                  public isAccepted: boolean , 
                  public useMyOwnMailRecipient: boolean ,
                  public dropId: number , 
                  public recipient_id: string , 
                  public mailRecipient_idCopy: string 
                  ) {}

                  
  }