import { ParagraphResult } from "./form-results-list/form-result-item/paragraph-results/paragraph-result.model";
import { Recipient } from "../form-list/form-item/recipient/recipient.model";
import { Receiver } from "./form-results-list/form-result-item/receiver/receiver.model";
import { Sender } from "./form-results-list/form-result-item/sender/sender.model";



export class FormResult {

    constructor (
        public _id: string, 
        public isActive: boolean,
        public isDeleted: boolean,
        public isSelected: boolean,
        public formReadFlag: boolean,
        public isShareble: boolean , 
        public isACopyOfAMessage: boolean ,
        public isForwardToMailAsCopy: boolean , // as copy 
        public isForwarded: boolean,
        public sender: Sender , 
        public senderGroup: string ,
        public senderType: string,
        public receiverGroup:string, 
        public receiverType: string ,
        public userChatRoomId: string , 
        public recipientResult: Recipient,
        public dateAndTime: string, 
        public subject: string , 
        public paragraphResults: ParagraphResult[] ,
        public seq: number , 
        public updateDateAndTime: string , 
        public hasFollowingParagraphItem_idToShow : string 
        //          => this is a possible following formItem to 
        //        add right after someone has sent a message and this will be a BOT with the next question 
        //        according to which formItem you are sending , the next paragraph wiii be different according to
        //        what you sent as string ( which will be a paragraphItem_id , better and easeir if public )
        //        this reply will exist only on CHAT 
        // if paragraph is sent from Chat , we add the check if "hasFollowingParagraphItemToShow" 
        ){}
}