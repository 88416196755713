import { Component, OnInit, Input , Output, EventEmitter, OnDestroy, NgZone, ChangeDetectorRef} from '@angular/core';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { FormIndexTypeInterface } from '../../form-index-type-interface.model';
import { FormIndexTopic } from '../form-index-topic.model';
import { FormIndexTopicService } from '../form-index-topic.service';
import { DialogmanagerService } from 'src/app/services/other-components-services/dialogmanager.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Observable, Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { FormIndexTopicListItem } from '../form-index-topic-list-item.model';
import { FormFeatureContainer } from '../form-feature-container.model';
import { ParagraphTopicIndex } from '../../form-edit/form-edit-paragraphs-index/paragraph-topic-index.model';
import { ParagraphTopic } from '../../form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.model';
import { ParagraphFeatureContainer } from '../../form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature-container.model';
import { AddSharedFormOrParagraphBottomSheetComponent } from '../../add-shared-form-or-paragraph-bottom-sheet/add-shared-form-or-paragraph-bottom-sheet.component';
import { AddSharedFormOrParagraphService } from '../../add-shared-form-or-paragraph/add-shared-form-or-paragraph.service';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { FormFeatureContainerService } from '../form-feature-container.service';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions} from '@angular/material/tooltip';

/** Custom options the configure the tooltip's default show/hide delays. */
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 300,
  touchendHideDelay: 1000,
};

interface SharedParagraphForm_Dialog_BottomSheet_Data {
  typeOfComponent: string , 
  formType: FormType ,
  paragraphType: ParagraphType, 
}

interface FormType {
  formIndexTopic: FormIndexTopic ,
  formFeatureContainer: FormFeatureContainer
}

interface ParagraphType {
  paragraphTopic: ParagraphTopic,
  paragraphFeatureContainer: ParagraphFeatureContainer
}

interface SharedParagraphForm_New_Old_Data {
  typeOfComponent: string , 
  formSide: FormSide ,
  paragraphSide: ParagraphSide, 
}

interface FormSide {
  oldFormIndexTopicListItem: FormIndexTopicListItem,
  updatedFormIndexTopicListItem: FormIndexTopicListItem,
  oldFormIndexTopic: FormIndexTopic ,
  updatedFormIndexTopic: FormIndexTopic,
  oldFormFeatureContainer: FormFeatureContainer,
  updatedFormFeatureContainer: FormFeatureContainer

}

interface ParagraphSide {
  oldParagraphTopicIndex: ParagraphTopicIndex,
  updatedParagraphTopicIndex: ParagraphTopicIndex,
  oldParagraphTopic: ParagraphTopic,
  updatedParagraphTopic: ParagraphTopic,
  oldParagraphFeatureContainer: ParagraphFeatureContainer,
  updatedParagraphFeatureContainer: ParagraphFeatureContainer
}


@Component({
  selector: 'app-shared-form-index-topic',
  templateUrl: './shared-form-index-topic.component.html',
  styleUrls: ['./shared-form-index-topic.component.css'],
  providers: [{provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}]
})

export class SharedFormIndexTopicComponent implements OnInit , OnDestroy {

  user: string 

  formIndexTopic: FormIndexTopic
  @Input() index_id: string ;
  index: number ;

  /** to hide the buttons and keep only the topic */
  hideForms = true ;
  /** set timing function */
  setTimer: any ;

  @Output() closeSideNavOnFormList = new EventEmitter<void>()
  @Output() adviseFormListOnLoadingForm = new EventEmitter<void>()

  @Input() typeOfFormIndexTopic: FormIndexTypeInterface
  typeOfIndexTopicInUse: string 

  mouseEnter: boolean = false 

  isLoading: boolean = false 

  updateOnUserEmail: Subscription
  updateOnSharedParagraphFormsSubscription: Subscription

  newFormIndexes: number

  constructor(private formIndexTopicService: FormIndexTopicService , 
              private userObjectUpdateService: UserObjectUpdateService , 
              private breakpointObserver: BreakpointObserver,
              private dms: DialogmanagerService , 
              private _bottomSheet: MatBottomSheet ,
              private ngZone: NgZone,
              private cdr: ChangeDetectorRef,
              private initialNullSetupService: InitialNullSetupService,
              private addSharedFormOrParagraphService: AddSharedFormOrParagraphService , 
              private formFeatureContainerService: FormFeatureContainerService
              ) { }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
      this.checkIfAnyNewFormFeatureContainer()
    })


    this.formIndexTopic = this.formIndexTopicService.getFormIndexTopic(this.formIndexTopicService.getFormIndexTopics().findIndex(x=> x._id === this.index_id));

    this.checkIfAnyNewFormFeatureContainer()

    this.updateOnSharedParagraphFormsSubscription = this.addSharedFormOrParagraphService.doneAddingUpdatingSharedParagraphForm
        .subscribe((res: SharedParagraphForm_New_Old_Data)=> {
          console.log( res, 'updated paragraphIndexTopic')
          this.updateSharedFormIndexTopic(res)

        })
  }

  checkIfAnyNewFormFeatureContainer(){
    if ( this.user === null ){ this.newFormIndexes = 0 }
    if ( this.user !== null ){

      let numberOfNwFormFeatureContainers: number = 0 
      if ( this.formIndexTopic.formFeatureContainer_ids.length > 0 ){
           for ( let i=0; i< this.formIndexTopic.formFeatureContainer_ids.length ; i++  ){
                if ( this.checkIfStringIncludeFormFeatureContainerWord(this.formIndexTopic.formFeatureContainer_ids[i]) ){ numberOfNwFormFeatureContainers = numberOfNwFormFeatureContainers+1}
           }
           this.newFormIndexes = this.formIndexTopic.formFeatureContainer_ids.length - numberOfNwFormFeatureContainers
      }

      if ( this.formIndexTopic.formFeatureContainer_ids.length == 0 ){ this.newFormIndexes = 0 }

    }
  }

  checkIfStringIncludeFormFeatureContainerWord( newFormFeatureContainerWord : string ){

    const searchingTerm: string = 'formFeatureContainer-'

    if ( newFormFeatureContainerWord.includes(searchingTerm) ){ return true }
    if ( !newFormFeatureContainerWord.includes(searchingTerm) ){ return false }

  }

  updateSharedFormIndexTopic( sharedParagraphForm_New_Old_Data: SharedParagraphForm_New_Old_Data){

    this.ngZone.run(()=> {
      if ( sharedParagraphForm_New_Old_Data.typeOfComponent === 'formFeatureContainer' ){
        if ( sharedParagraphForm_New_Old_Data.formSide.updatedFormIndexTopic._id === this.formIndexTopic._id ){
         
            this.isLoading = true 

             const currentFormIndexTopic: FormIndexTopic = this.formIndexTopicService.getFormIndexTopicBy_id(sharedParagraphForm_New_Old_Data.formSide.oldFormIndexTopic._id)
             const formIndexTopic: FormIndexTopic = sharedParagraphForm_New_Old_Data.formSide.updatedFormIndexTopic

             const currentFormFeatureContainer: FormFeatureContainer = sharedParagraphForm_New_Old_Data.formSide.oldFormFeatureContainer
             const newFormFeatureContainer: FormFeatureContainer = sharedParagraphForm_New_Old_Data.formSide.updatedFormFeatureContainer

             const indexOfFormFeatureContainer_id_ToSubstitute = currentFormIndexTopic.formFeatureContainer_ids.findIndex(x=> x === currentFormFeatureContainer._id )
             currentFormIndexTopic.formFeatureContainer_ids[indexOfFormFeatureContainer_id_ToSubstitute] = newFormFeatureContainer._id
             console.log(currentFormIndexTopic)

             const indexOfCurrentFormIndexTopic = this.formIndexTopicService.getFormIndexTopics().findIndex(x=> x._id === currentFormIndexTopic._id )
             this.formIndexTopicService.formIndexTopics[indexOfCurrentFormIndexTopic] = currentFormIndexTopic 
             const initialNullIndex = this.initialNullSetupService.initialNullFormIndexTopics.findIndex(x=> x._id === currentFormIndexTopic._id )
             this.initialNullSetupService.initialNullFormIndexTopics[initialNullIndex] = currentFormIndexTopic

             const indexOfFormFeatureContainer = this.formFeatureContainerService.getFormFeatureContainers().findIndex(x=> x._id === currentFormFeatureContainer._id )
             this.formFeatureContainerService.formFeatureContainers[indexOfFormFeatureContainer] = newFormFeatureContainer
             const nullIndexFormFeatureContainer = this.initialNullSetupService.initialNullFormFeatureContainers.findIndex(x=> x._id === currentFormFeatureContainer._id )
             this.initialNullSetupService.initialNullFormFeatureContainers[nullIndexFormFeatureContainer] = newFormFeatureContainer


             this.formIndexTopic = currentFormIndexTopic
             this.checkIfAnyNewFormFeatureContainer()

        }

      }
    })
    this.cdr.detectChanges()
    this.isLoading = false 
  }

      /** this serve to show the lisy of paragraphs , they are hidden when start
   * they open when user click on the topic 
   */
       onHideForms(){
        this.hideForms = !this.hideForms;
        /** clear timer before it start again */
        this.clearTimer()
        
       this.setTimer= setTimeout(() => {
          this.hideForms = true ;
        }, 15000);
      }
  
      /** clear timer before itstart again */
    clearTimer(){
      clearTimeout(this.setTimer)
    }

    onMouseEnter(){
      this.mouseEnter = true
    }
  
    onMouseLeave(){
      this.mouseEnter = false 
    }

    onAcceptFormIndexTopic(){

    // this.isLoading = true 
    
    const new_id = this.formIndexTopic._id
    const newComponentName = this.formIndexTopic.componentName 
    const newUIID = this.formIndexTopic.UIID
    const newOwner = this.formIndexTopic.owner
    const newDateAndTime = this.formIndexTopic.dateAndTime
    const newAllUsersAllowed = this.formIndexTopic.allUsersAllowed
    const newUserListAllowed = this.formIndexTopic.userListAllowed
    const newIsAccepted = true 
    const newIsArchived = this.formIndexTopic.isArchived
    const newName = this.formIndexTopic.name
    const newFormFeatureContainer_ids = this.formIndexTopic.formFeatureContainer_ids
    
    const newFormIndexTopic = new FormIndexTopic ( new_id, newComponentName, newUIID, newOwner, newDateAndTime, newAllUsersAllowed, newUserListAllowed,
           newIsAccepted, newIsArchived, newName, newFormFeatureContainer_ids )
    console.log(newFormIndexTopic)

    console.log( 'is opening window to add or refuse formIndexTopic')


      const newTypeOfComponent = 'formIndexTopic'
      const newFormType : FormType = { formIndexTopic: newFormIndexTopic ,formFeatureContainer: null }
      const newParagraphType: ParagraphType = { paragraphTopic: null, paragraphFeatureContainer: null }
      
      const newSharedParagraphForm_Dialog_BottomSheet_Data: SharedParagraphForm_Dialog_BottomSheet_Data = { typeOfComponent: newTypeOfComponent ,formType: newFormType ,paragraphType: newParagraphType }


    if ( !this.isMobileDevice()){
      this.dms.addSharedFormOrParagraphComponent({ 

        sharedParagraphForm_Dialog_BottomSheet_Data: newSharedParagraphForm_Dialog_BottomSheet_Data
  
        })
    }

    if ( this.isMobileDevice()){
         this._bottomSheet.open(AddSharedFormOrParagraphBottomSheetComponent , {
          data: {

          sharedParagraphForm_Dialog_BottomSheet_Data: newSharedParagraphForm_Dialog_BottomSheet_Data
           
          }
         });
    }
    
    }

    isMobileDevice(): boolean {
      const isHandset = this.breakpointObserver.isMatched(Breakpoints.Handset);
      return isHandset;
    }

    onCloseSideNav(){
      this.closeSideNavOnFormList.emit()
    }
  
  
    onAdviseFormListOnLoadingForm(){
      this.adviseFormListOnLoadingForm.emit()
      this.onCloseSideNav()
    }

    ngOnDestroy(): void {
        this.updateOnUserEmail.unsubscribe()
    }

}
