import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RangeInfo } from '../range-info.model';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FixTextDetail } from '../../../texts/fix-text-list/fix-text-detail.model';

interface IndexRangeInfo{
  index: number ,
  rangeInfo: RangeInfo
}

interface InputLabelInterface {

  exist: boolean ,
  name: string,
  maxLength: number,
  errorHandler: boolean 
}

@Component({
  selector: 'app-inner-input-edit',
  templateUrl: './inner-input-edit.component.html',
  styleUrls: ['./inner-input-edit.component.css']
})
export class InnerInputEditComponent implements OnInit {

  // innerInputEditFormName: FormGroup

  @Input() rangeInfo: RangeInfo
  @Input() isEditing: boolean
  @Input() index: number 

  inputInnerCompNameInterface: InputLabelInterface
  inputTextInnerCompNameInterface: InputLabelInterface
  inputTextLabelInterface: InputLabelInterface
  inputTextLabelInterface1: InputLabelInterface
  inputTextLabelInterface2: InputLabelInterface
  inputTextLabelInterface3: InputLabelInterface
  emailInput: string = ''
  accessType: string 
  textInput: string = ''
  initialText: string 
  initialMaxLength: string 
  initialPlaceholder: string 
 
  temporaryTextToAdd: string = ''
  temporaryEditedText: string = ''

  @Output() emittingIndexRangeInfo = new EventEmitter<IndexRangeInfo>()
  @Output() emitIndexOfDeletedRangeInfo = new EventEmitter<number>()
  isAboutToDeleteRangeInfo: boolean = false 

  emptyInputNameAfterAdded = new Subject<void>()
  emptyInputTextAfterAdded = new Subject<void>()
  
  // emptyInputTextAfterEdit = new Subject<void>()

  // isEditingDetail: boolean = false 
  // editingDetailPosition: number = 1000

  inputNewTextValid: boolean = false
  editedTextIsValid: boolean = true 

  isDisabled: boolean = false

  selectedValue: string;


  constructor( private breakpointObserver: BreakpointObserver) { }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  ngOnInit(): void {

    console.log(this.rangeInfo)

    this.accessType = this.rangeInfo.type

    const newInitialText: any = this.rangeInfo.rangeDetails[0]
    console.log(newInitialText)
    this.initialText = newInitialText.text 

    const newInitialMaxlength: any = this.rangeInfo.rangeDetails[1]
    this.initialMaxLength = newInitialMaxlength.text

    const newInitialPlaceholder: any = this.rangeInfo.rangeDetails[2]
    this.initialPlaceholder = newInitialPlaceholder.text 


    // this.innerInputEditFormName = new FormGroup({
    //   name : new FormControl (this.rangeInfo.name , [ Validators.required, Validators.maxLength(30) ] )
    // });

    const inputCompName = 'input name'
    this.inputInnerCompNameInterface = { exist: true, name: inputCompName, maxLength: 30, errorHandler: true }

    const newInitialMaxlengthNumber = Number(this.initialMaxLength)
    this.inputTextInnerCompNameInterface = { exist: true, name: this.initialText, maxLength: newInitialMaxlengthNumber, errorHandler: true }

    const inputName = 'add new text'
    this.inputTextLabelInterface = { exist: true, name: inputName, maxLength: 200, errorHandler: true }

    const newInputLabel = 'edit label name'
    this.inputTextLabelInterface1 = { exist: true, name: newInputLabel, maxLength: 30, errorHandler: true }

    const newInputMaxLengthNumber = 'input max length number'
    this.inputTextLabelInterface2 = { exist: true, name: newInputMaxLengthNumber, maxLength: 3, errorHandler: true }

    const newInputPlaceholder = 'input placeholder example'
    this.inputTextLabelInterface3 = { exist: true, name: newInputPlaceholder, maxLength: 30, errorHandler: true }

    // const inputEditText = 'edit existing text'
    // this.inputEditTextLabelInterface = { exist: true, name: inputEditText, maxLength: 200, errorHandler: true }
  }

  onSubmitName(){

  }

  
  onUpdateUsersAccess($event){
      console.log($event)
      this.rangeInfo.usersAccess = $event
    
  }

  onUpdateNameInputEvent($event){
    console.log($event)
    this.rangeInfo.name = $event.name
  }

  onUpdateNameInputValidity($event){
    console.log($event)
  }

  onUpdateTextInputEvent($event){
    console.log($event)
    this.initialText = $event.name
    this.inputTextLabelInterface.name = $event.name
    this.inputTextInnerCompNameInterface.name = $event.name 
    

    const newInnerArrayText = new FixTextDetail ($event.name)
    this.rangeInfo.rangeDetails[0] = newInnerArrayText



  }

  onUpdateTextInputValidity($event){
    console.log($event)
  }

  onUpdateMaxlengthEvent($event){
    console.log($event)
    this.initialMaxLength = $event.name
    this.inputTextInnerCompNameInterface.maxLength = $event.name

    const newInnerArrayText = new FixTextDetail ($event.name)
    this.rangeInfo.rangeDetails[1] = newInnerArrayText
  }

  onUpdateMaxlengthValidity($event){
    console.log($event)
  }

  onUpdatePlaceholderEvent($event){
    console.log($event)
    this.initialPlaceholder = $event.name

    const newInnerArrayText = new FixTextDetail ($event.name)
    this.rangeInfo.rangeDetails[2] = newInnerArrayText
  }

  onUpdatePlaceholderValidity($event){
    console.log($event)
  }




  onConfirmInnerDetail(){
    console.log('confirm the texts')
    const newIndex = this.index 
    const newRangeInfo = this.rangeInfo
    const newIndexRangeInfo: IndexRangeInfo = { index: newIndex , rangeInfo: newRangeInfo }
    this.emittingIndexRangeInfo.emit( newIndexRangeInfo )
  }

  onDeleteInnerDetail(){
    console.log('delete the texts ?')
    this.isAboutToDeleteRangeInfo = true 
  }

  onDoubleConfirmDeleteInnerDetail(){
    console.log('confirm delete the texts')
    this.emitIndexOfDeletedRangeInfo.emit(this.index )

  }

  onReturnToEditRangeInfo(){
    console.log('return edit texts')
    this.isAboutToDeleteRangeInfo = false
  }

}
