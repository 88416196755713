import { Component, OnInit, Input, Output, ViewChild, EventEmitter, OnDestroy } from '@angular/core';
import { DropDownSelect } from '../drop-down-select.model';
import { DropDownSelectDescription } from '../drop-down-select-description.model';
import { DropDownSelectService } from '../drop-down-select.service';
import { DropDownSelectHttpRequestService } from '../drop-down-select-http-request.service';
import { MatTabGroup } from '@angular/material/tabs';
import { Feature } from '../../../../feature.model';
import { Observable, Subscription, Subject } from 'rxjs';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { CreateNewFeatureContainerAndAddToFeatureContainerListItemService } from 'src/app/services/shared-services/create-new-feature-container-and-update-feature-container-list-item.service';
import { AdviseIfHttpStoreComponentsCallHasBeenMadeService } from 'src/app/services/other-components-services/advise-if-http-store-components-has-been-made.service';
import { StoreBaseComponentsService } from 'src/app/services/get-and-store-components/store-base-components.service';
import { FeatureContainerListItemService } from '../../../../../../paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container-list-item/feature-container-list-items.service';
import { FeatureContainerListItem } from '../../../../../../paragraph-edit/feature-container-list-item.model';
import { FeatureContainer } from '../../../../../../paragraph-edit/feature-container.model';
import { FeatureContainerService } from '../../../../../../paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container/feature-container.service';
import { StorePersonalFeatureIndexComponentsService } from 'src/app/services/store-initial-components/store-personal-feature-index-components.service';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { GetComponentsHttpRequestService } from 'src/app/services/gets-services/get-components-http-request.sevice';


@Component({
  selector: 'app-drop-down-select-list',
  templateUrl: './drop-down-select-list.component.html',
  styleUrls: ['./drop-down-select-list.component.css']
})
export class DropDownSelectListComponent implements OnInit , OnDestroy {

  user: string 

  thisTypeOfComponent: string = 'dropDownSelect'

  dropDownSelect: DropDownSelect;
  dropDownSelects: DropDownSelect[]

    // these tabsIndex are to create and re-position focus once created a new tab
    newTabIndex0: number ;
    newtabIndex : number ;
    customCollapsedHeight: string = '20px';
    customExpandedHeight: string = '20px';
  
    index : number ;
  
   selectedIndex: number  ;
   findSelectedIndex: number
  //  @Input() selectedIndex_id: string
   @Input() feature_from_paragraphEdit: Feature    
   @Output() changeIndex = new EventEmitter<Feature>()  ;
   @Output() eventIndex: number ;

   @Output() holdOnCreateUpdateButtons = new EventEmitter<void>()
  
     feature : Feature ;
  
     // this set as false in the DOM allow us to see the buttons to modify the component
     @Output() noNeeded : boolean ; 
  
     /** limit on the number of features to add */
     createButtonLimit : boolean ;
     buttonsLimit = 25 ;
  
     @Input() dropId : number ;
     @Input() currentI : number ;
     @Input() isParagraphEdit: boolean;
    
  
  
     /**
      * receiving subscription from ParagraphEdit to edit the component 
      */
     subscriptionToEditComponentItemIsOn: boolean
  
     currentFeature: Feature
     @Input() editFeature: Observable<void> 
  
     /**
      * sending another subscribtion to word item
      */
     // clickOnEditFeatureItem: Subject<void> = new Subject<void>()
  
     @ViewChild('tabs') tabGroup: MatTabGroup
  
     /**
      * click and receive the results from paragraph Edit
      */
     editFeatureEventFromParagraphEdit: Subscription;
   clickOnEditFeatureItem: Subject<Feature> = new Subject<Feature>()
  
  
    receiveFeatureInfoFromParagraphEdit: Subscription; 
    @Input() collectFeatureId: Observable<Feature>

    isLoading: boolean
    isLoadingList: boolean

    dropDownSelectsHaveBeenStoredSubscription: Subscription 

  featureContainerPlusParentsHaveBeenStored: Subscription 

  error = null 

  updateOnUserEmail : Subscription

  matToolTipMessageOnCreateNew: string 

  featureContainersLength_isStillTheSame: boolean
  
  
    


  constructor( private dropDownSelectService: DropDownSelectService ,
               private dropDownSelectHttpRequestService: DropDownSelectHttpRequestService , 
               private userObjectUpdateService: UserObjectUpdateService ,
               private storeBaseComponentsService: StoreBaseComponentsService ,
               private featureContainerListItemService: FeatureContainerListItemService ,
               private featureContainerService: FeatureContainerService ,
               private adviseIfHttpStoreComponentsCallHasBeenMadeService: AdviseIfHttpStoreComponentsCallHasBeenMadeService ,
               private postComponentsHttpRequestService: PostComponentsHttpRequestService , 
               private createNewFeatureContainerAndAddToFeatureContainerListItemService: CreateNewFeatureContainerAndAddToFeatureContainerListItemService,
               private storePersonalFeatureIndexComponentsService: StorePersonalFeatureIndexComponentsService ,
               private initialNullSetupService: InitialNullSetupService , 
               private getComponentsHttpRequestService: GetComponentsHttpRequestService
               ) { }

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
    .subscribe((updatedUserEmail)=>{
     this.user = updatedUserEmail
     console.log('updated USER = ', this.user)
    })

    console.log(this.user )

    this.subscriptionToEditComponentItemIsOn = false
    this.isLoading = true 
    this.isLoadingList = true 

    if ( this.user === null ){console.log('is going directly to continue as NULL user'), this.continueInitialOnInit()}
    if ( this.user !== null ){
      if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.dropDownSelectsHasBeenStored === true  ){ console.log('is going direvtly to continue'),   this.continueInitialOnInit() }
      if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.dropDownSelectsHasBeenStored === false ){ console.log('is calling http'), this.storeBaseComponentsService.callBaseComponentsListByUserAndType(this.user , 'dropDownSelect') }
    }

    this.editFeatureEventFromParagraphEdit = this.editFeature.subscribe(() => this.onEditFeatureItem() )
    this.receiveFeatureInfoFromParagraphEdit = this.collectFeatureId.subscribe((data)=> this.updateCurrentFeature(data))
   
    this.dropDownSelectsHaveBeenStoredSubscription = this.dropDownSelectService.initialPersonalDropDownSelectHaveBeenStored
        .subscribe(()=> { 
         this.continueInitialOnInit()
         this.adviseIfHttpStoreComponentsCallHasBeenMadeService.dropDownSelectsHasBeenStored = true 
    })

    this.featureContainerPlusParentsHaveBeenStored = this.featureContainerListItemService.newFeatureContainerAndparentsHaveBeenStored
        .subscribe((feature_plus_typeOfComponent: any )=> {

          const currentFeature:Feature = feature_plus_typeOfComponent.feature 
          const currentTypeOfComponent: string = feature_plus_typeOfComponent.featureContainerName

          console.log(feature_plus_typeOfComponent)
          if ( currentTypeOfComponent === this.thisTypeOfComponent){
            this.onEmitToParagraphEditChangedIndex(currentFeature)
          }

    })

   

  };

  continueInitialOnInit(){

    console.log('IT S CONTINUING INITIAL ONINIT')
    const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('dropDownSelect')

    this.dropDownSelects = []
    for ( let i=0; i<personalFeatureContainerListItem.featureContainer_ids.length ; i++ ){
          let currentFeatureContainer: FeatureContainer = this.featureContainerService.getFeatureContainerBy_id(personalFeatureContainerListItem.featureContainer_ids[i])
          this.dropDownSelects.push(this.dropDownSelectService.getDropDownSelect_by_id(currentFeatureContainer.componentReference_id))
    }
    console.log(this.dropDownSelects)
    this.dropDownSelects.reverse()

    this.selectedIndex = this.dropDownSelects.findIndex(x => x._id === this.feature_from_paragraphEdit.feature_id)

    this.matToolTipMessageOnCreateNew = " CREATE A COPY OF  '1 - "+this.dropDownSelects[0].name+"'"

    if (this.dropDownSelects.length < this.buttonsLimit ){ this.createButtonLimit = true }
    if (this.dropDownSelects.length >= this.buttonsLimit ){this.createButtonLimit = false}

    if (this.user === null ){ setTimeout(()=>{ this.isLoading = false , this.isLoadingList = false },200) }
    if (this.user !== null ){ this.isLoading = false , this.isLoadingList = false  }
   

  }

    /** for each click we recreate a new feature  */
onLinkClick($event) {

  this.index = this.tabGroup.selectedIndex ; 
 
  const newOwner = this.user; 
  const newIsNew = false;
  const newDropId = this.currentI;
  const newFeature_id = this.dropDownSelects[this.index]._id;
  const newFeatureName = 'dropDownSelect' ;
  const newFeature = new Feature ( newOwner, newIsNew,  newDropId , newFeature_id, newFeatureName) ;
 
  this.feature = newFeature;
 
  this.changeIndex.emit(this.feature)
  this.eventIndex = this.currentI ;
 
  console.log('drop id =', this.currentI)
  console.log(this.feature)
 
};

onCreate(){
  this.isLoading = true 
  this.isLoadingList = true 

  this.onCreateNew()
}

onCreateNew(){

  if ( this.user !== null ){ this.onExistingUserCreateComponent()}
  if ( this.user === null ){ this.onNullUserCreateComponent() }
}

onExistingUserCreateComponent(){

  this.storePersonalFeatureIndexComponentsService.getPersonalUpdatedFeatureIndex_components(this.user)
  .subscribe((res: any)=> {
    console.log(res)

    const httpFeatureIndexComponentResult: any = res 

               
                  const newFeatureContainers: FeatureContainer[] = httpFeatureIndexComponentResult.featureContainer
                  console.log(newFeatureContainers)
                  this.featureContainerService.featureContainers = newFeatureContainers
                  this.initialNullSetupService.initialNullFeatureContainers = newFeatureContainers
                 
                  const newFeatureContainerListItems: FeatureContainerListItem[] = httpFeatureIndexComponentResult.featureContainerListItem
                                       console.log(newFeatureContainerListItems)
                  this.featureContainerListItemService.featureContainerListItems = newFeatureContainerListItems
                  this.initialNullSetupService.initialNullFeatureContainerListItems = newFeatureContainerListItems

    /**
     * now check the  featueContainerListItem that belong to this componentType : wordInput
     * by filter or find index of name : wordInput
     */
     
    const latestFeatureContainers_length = newFeatureContainers.length
    const latestFeatureContainerListItems_length = newFeatureContainerListItems.length 

    const indexOfselectedFeatureContainerListItem = newFeatureContainerListItems.findIndex(x=> x.name === this.thisTypeOfComponent )
    const selectedFeatureContainerListItem = newFeatureContainerListItems[indexOfselectedFeatureContainerListItem]

    if ( selectedFeatureContainerListItem.featureContainer_ids.length === this.dropDownSelects.length ){ this.featureContainersLength_isStillTheSame = true }
    if ( selectedFeatureContainerListItem.featureContainer_ids.length > this.dropDownSelects.length ){ this.featureContainersLength_isStillTheSame = false }

    console.log(latestFeatureContainers_length, latestFeatureContainerListItems_length, selectedFeatureContainerListItem) 
                
    const newFeatureContainer_UIID = 'featureContainer-'+(latestFeatureContainers_length+1).toString()

    this.newTabIndex0 = selectedFeatureContainerListItem.featureContainer_ids.length+1

    const newComponentName = 'dropDownSelect'
    const newUIID = 'dropDownSelect-'+this.newTabIndex0.toString()
    const newOwner = this.user; 

    const newName =  'dropdown-'+ this.newTabIndex0.toString() ;

    const dropDownSelectDescriptionName = 'edit select' + this.newTabIndex0.toString() ;
    const dropDownSelectDescriptionContent = 'edit select content' + this.newTabIndex0.toString() ;
    // const newDropDownSelectDescription = new DropDownSelectDescription (dropDownSelectDescriptionName, [dropDownSelectDescriptionContent])

    const newDropDownSelectAndParents = {
      "1": [
      {
        "componentName": "dropDownSelect",
        "UIID":newUIID,
        "owner": newOwner,
        "allUsersAllowed": false,
        "userListAllowed": [],
        "dateAndTime": null,
        "name": newName,
        "descriptionList": [
                              {
                               "name":dropDownSelectDescriptionName,
                               "contents": [ dropDownSelectDescriptionContent ]
                              }
                            ]
        
      }
      ],
      "2": [
        {
     "componentName": "featureContainer",
     "UIID":newFeatureContainer_UIID,
     "owner":newOwner,
     "allUsersAllowed": false,
     "userListAllowed": [],
     "dateAndTime": null,
     "isActive": true ,
     "isAccepted": true,
     "name": newComponentName,
     "componentReference_id": "@CHILD_ID@",
     "features": [
                  {
                  "owner": "@OWNER@",
                  "isNew": false ,
                  "dropId": 0,
                  "feature_id": "@CHILD_ID@",
                  "featureName": newComponentName
                  }
     ]
      }
 ],
 "3": [
  {
"componentName": "featureContainerListItem",
"UIID":selectedFeatureContainerListItem.UIID,
"owner":selectedFeatureContainerListItem.owner,
"allUsersAllowed": selectedFeatureContainerListItem.allUsersAllowed,
"userListAllowed": selectedFeatureContainerListItem.userListAllowed,
"dateAndTime": selectedFeatureContainerListItem.dateAndTime,
"type": "personal",
"name": newComponentName,
"featureContainer_ids": [ selectedFeatureContainerListItem.featureContainer_ids, "@CHILD_ID@"],
"selectedFeatureContainer_id": selectedFeatureContainerListItem.selectedFeatureContainer_id
}
]
  }

  this.postComponentsHttpRequestService.httpStoreMultiLevelComponentsWithUpdateOnExistingOnes( this.user, newDropDownSelectAndParents)
  .subscribe((res: any)=>{
    console.log(res)

    const newDropDownSelect: DropDownSelect = res.dropDownSelect[0]
    const newFeatureContainer: FeatureContainer = res.featureIndex[0]
    const updatedFeatureContainerListItem: FeatureContainerListItem = res.featureIndex[1]

    this.dropDownSelectService.onAddNew(newDropDownSelect)
    this.featureContainerService.addFeatueContainer(newFeatureContainer)
          this.featureContainerListItemService.updateFeatureContainerListItem(updatedFeatureContainerListItem)

            const newFeatureOwner = this.user; 
            const newIsNew = false;
            const newDropId = this.currentI ;
            const newFeature_id = newDropDownSelect._id
            const newFeatureName =  this.thisTypeOfComponent;
            const newFeature = new Feature ( newFeatureOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;

            /**
             * if the featureContainerListItes was same length 
             */
            if ( this.featureContainersLength_isStillTheSame ){ this.onEmitToParagraphEditChangedIndex( newFeature )}
            if ( !this.featureContainersLength_isStillTheSame ){

              this.getComponentsHttpRequestService.httpGetComponentsByType(this.user , this.thisTypeOfComponent )
                    .subscribe((res: any )=> {
                      console.log(res)

                      const updatedDropDownSelects: DropDownSelect[] = res.dropDownSelect 
                      this.dropDownSelectService.addDropDownSelectsOnly(updatedDropDownSelects)

                      const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type(this.thisTypeOfComponent)

                      this.dropDownSelects = []
                      for ( let i=0; i<personalFeatureContainerListItem.featureContainer_ids.length ; i++ ){
                        let currentFeatureContainer: FeatureContainer = this.featureContainerService.getFeatureContainerBy_id(personalFeatureContainerListItem.featureContainer_ids[i])
                        this.dropDownSelects.push(this.dropDownSelectService.getDropDownSelect_by_id(currentFeatureContainer.componentReference_id))
                  }
                  console.log(this.dropDownSelects)

                  this.dropDownSelects.reverse()

                  this.onEmitToParagraphEditChangedIndex( newFeature )


                }, error => {
                  this.throwingError()

                }) // end 3rd subscription
            }





  }, error => { // END SECOND SUBSCRIPTION
    this.throwingError()
  })


  }, error => { // END FIRST SUBSCRIPTION 
    this.throwingError()

  })

}


throwingError(){

  const errorMessage: string = "we weren't able to create the new component, try later, check the network"
  this.error = errorMessage
  this.isLoadingList = false 
                      /**
                       *  EMIT and event to paragraphEdit to put update or create buttons for 4,5 sec 
                       */
  this.holdOnCreateUpdateButtons.emit()
                    
                      /**
                       * return to previous set up without the new component after a while to be able to read the message 
                       */
  setTimeout(()=>{
      this.error = null 
      this.isLoadingList = false 
      this.isLoading = false 
  },4000)

}


onNullUserCreateComponent(){

    console.log('CREATING NEW')
    const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('dropDownSelect')
    this.newTabIndex0 = personalFeatureContainerListItem.featureContainer_ids.length+1
    
    const new_id = 'dropDownSel'+this.newTabIndex0.toString()
    const newComponentName = 'dropDownSelect'
        const newUIID = 'dropDownSelect-'+this.newTabIndex0.toString()
    const newOwner = this.user; 
    const newAllUsersAllowed = false
    const newUserListAllowed = []
    const newDateAndTime = null; 
    const newName =  'new tab '+ this.newTabIndex0.toString() ;

    const dropDownSelectDescriptionName = 'edit select' + this.newTabIndex0.toString() ;
    const dropDownSelectDescriptionContent = 'edit select content' + this.newTabIndex0.toString() ;
    const newDropDownSelectDescription = new DropDownSelectDescription (dropDownSelectDescriptionName, [dropDownSelectDescriptionContent])

    const newDropDownSelect = new DropDownSelect (new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed,
                                     newDateAndTime, newName, [newDropDownSelectDescription])


    if ( this.user === null ){

      this.dropDownSelectService.onAddNew(newDropDownSelect);

      const newOwner = this.user; 
      const newIsNew = false;
      const newDropId = this.currentI ;
      const newFeature_id = newDropDownSelect._id
      const newFeatureName = 'dropDownSelect' ;
      const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
     
      this.feature = newFeature

      this.createNewFeatureContainerAndAddToFeatureContainerListItemService.createFeatureContainer_toAddOnFeatureContainerListItem(newFeature, this.user )

    }

    // if ( this.user !== null ){

    //   this.postComponentsHttpRequestService.httpStoreComponent( this.user, 'dropDownSelect', newDropDownSelect ).subscribe((response: DropDownSelect)=> {

    //     this.dropDownSelectService.onAddNew(response);
  
    //     const newOwner = this.user; 
    //     const newIsNew = false;
    //     const newDropId = this.currentI ;
    //     const newFeature_id = response._id
    //     const newFeatureName = 'dropDownSelect' ;
    //     const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
       
    //     this.feature = newFeature
    //     console.log(this.feature)
  
    //     this.createNewFeatureContainerAndAddToFeatureContainerListItemService.createFeatureContainer_toAddOnFeatureContainerListItem(newFeature, this.user )
  
    //   }, error => {
    //     console.log(error)

    //     const errorMessage: string = "we weren't able to create the new component, check the network or try later."
    //     this.error = errorMessage
    //     this.isLoadingList = false 
    //     /**
    //      *  EMIT and event to paragraphEdit to put update or create buttons for 4,5 sec 
    //      */
    //     this.holdOnCreateUpdateButtons.emit()

    //     /**
    //      * return to previous set up without the new component after a while to be able to read the message 
    //      */
    //     setTimeout(()=>{
    //       this.error = null 
    //       this.isLoadingList = false 
    //       this.isLoading = false 
    //     },4000)
    //   }
    //   )

    // }

  };

  onEmitToParagraphEditChangedIndex(toEmitFeature: Feature ){
    console.log('is emitting changed feature')
    this.changeIndex.emit(toEmitFeature)
    // once this feature is emitted the paragraphEdit will change index and this whole 
    // component will restart again
  }


 /**
   * 
   * @param data feature that is been transmitted from paragraphEdit to check which of the 
   * features list has to pass the subscription to the feature item to be edited 
   */
  updateCurrentFeature(data){
    this.currentFeature = data;
    console.log(this.currentFeature)
    }
  
    onEditFeatureItem(){
      console.log('is opening another dialog from list component ')
      console.log(this.currentI)
      console.log(this.currentFeature)
  
        if( this.currentFeature.dropId === this.currentI){
          this.clickOnEditFeatureItem.next(this.currentFeature)
         }
     
      console.log('is arrived')
    
    }
  
    ngOnDestroy(){
      this.receiveFeatureInfoFromParagraphEdit.unsubscribe();
      this.editFeatureEventFromParagraphEdit.unsubscribe();
  
    }

}
