import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { InnerArraySelect } from '../inner-array-select.model';
import { RangeInfo } from '../range-info.model';
import { Observable, Subscription } from 'rxjs';
import { RangeInfoResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/shared-calendar-status-result/range-info-result.model';
import { SharedCalendarStatusService } from '../shared-calendar-status.service';

interface Food {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'app-inner-dropdown-item',
  templateUrl: './inner-dropdown-item.component.html',
  styleUrls: ['./inner-dropdown-item.component.css']
})
export class InnerDropdownItemComponent implements OnInit , OnDestroy{

  @Input() rangeInfo: RangeInfo
  @Input() isAddingFeatureDropdown: boolean
  @Input() rangeInfo_index: number
  @Input() isParagraphEdit: boolean 

  @Input() changeSelectedDatesView: Observable<void>
  detectChangeOfSelectedDates: Subscription

  @Input() canUseIt: boolean 

  innerDropdownItem: InnerArraySelect
  isDisabled: boolean

  selectedValue: number;

  @Output() emitSelectedValues = new EventEmitter<any>()

  rangeInfoResult: RangeInfoResult


 
  constructor( private ngZone: NgZone , 
               private cdr: ChangeDetectorRef ,
               private sharedCalendarStatusService: SharedCalendarStatusService
             ) { }

  ngOnInit(): void { 

    console.log('this rangeInfo dropdown can be used => ', this.canUseIt)

    const newAnyInnerArraySelect: any = this.rangeInfo.rangeDetails
    const newRangeInfoDetails: InnerArraySelect[] = newAnyInnerArraySelect 
    console.log(newRangeInfoDetails)

    this.isDisabled = !this.canUseIt

    if ( newRangeInfoDetails.findIndex(x=> x.selected === true ) !== -1 ){ this.selectedValue = newRangeInfoDetails.findIndex(x=> x.selected === true ) }

    this.detectChangeOfSelectedDates = this.changeSelectedDatesView
        .subscribe(()=> {
          this.updateSelectedDatesViewInfo()
    })

  }

  onDropdownOpened($event){
    console.log($event)
    console.log('use the first click into the dropdown to initialize the component')
  }

  updateSelectedDatesViewInfo(){
    this.ngZone.run(()=>{
      this.rangeInfo = this.rangeInfo 
      
      const newAnyInnerArraySelect: any = this.rangeInfo.rangeDetails
      const newRangeInfoDetails: InnerArraySelect[] = newAnyInnerArraySelect 
      console.log(newRangeInfoDetails)

      if ( newRangeInfoDetails.findIndex(x=> x.selected === true ) !== -1 ){ 
           this.selectedValue = newRangeInfoDetails.findIndex(x=> x.selected === true )
      }
   
    })
    this.cdr.detectChanges()

  } 



  

  // Event handler to log the selected value
  onItemSelection( ): void {
    console.log(this.selectedValue);
    /**
     * check if the selectedrangeDatesInfo were still the same at the moment we are updating this 
     *  1- call the latest sharedCalendarStatusItem
     *  2- check if the same selectedrangeDatesInfo at least has still the same values? 
     *  3- if it exist and had the same setup , we can update it , 
     *  4- if it hadn't anymore the setup and the particular option didn't exist anymore , pop up error and reset with latest valid update 
     */
    // aaa aaa 

    // if ( !this.isAddingFeatureDropdown ){

    const rangeInfos: RangeInfo[] = [this.rangeInfo] 
    console.log('rangeInfos to clone  => ', rangeInfos )

    const newRangeInfos: RangeInfo[] = this.sharedCalendarStatusService.createRangeInfosClones(rangeInfos)
    let newRangeInfo: RangeInfo = newRangeInfos[0]


    if ( this.selectedValue !== undefined ){
  
    const newRangeDetails: any[] = newRangeInfo.rangeDetails
    const newIndexToTurnFalse = newRangeDetails.findIndex(x=> x.selected === true )
    if ( newIndexToTurnFalse !== -1 ){ 

      const newInnerArrayName: string = newRangeDetails[newIndexToTurnFalse].name
      const updatedRangeInfoDetail = new InnerArraySelect (newInnerArrayName, false)


        newRangeInfo.rangeDetails[newIndexToTurnFalse] = updatedRangeInfoDetail
     }

     // now turn the selected one to true 

 
     const newSelectedTrueInnerArray: any = newRangeInfo.rangeDetails[this.selectedValue]
     const newTrueSelected : InnerArraySelect = newSelectedTrueInnerArray 
     const updatedTrueSelectedArray = new InnerArraySelect ( newTrueSelected.name , true )


     newRangeInfo.rangeDetails[this.selectedValue] = updatedTrueSelectedArray
    
    const rangeInfo_index = this.rangeInfo_index
    console.log('is emitting values ', rangeInfo_index, newRangeInfo )

    this.emitSelectedValues.emit({ rangeInfo_index, newRangeInfo}) 
 
    }

    if ( this.selectedValue === undefined ){

      const newRangeInfo = this.rangeInfo
      const rangeInfo_index = this.rangeInfo_index
  
      this.emitSelectedValues.emit( { rangeInfo_index, newRangeInfo })
    }

    }

  ngOnDestroy(): void {
      this.detectChangeOfSelectedDates?.unsubscribe()
  }

}
