import { Injectable, OnDestroy } from "@angular/core";
import { ParagraphFeatureContainer } from "./paragraph-feature-container.model";
import { Observable, Subject , Subscription } from 'rxjs';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { UserObjectUpdateService } from "src/app/services/other-components-services/user-object-update.service";
import { ParagraphTopic } from "./paragraph-topic.model";
import { StoreParagraphItemsService } from "src/app/services/get-and-store-components/store-paragraph-items.service";
import { Paragraph } from "src/app/forms/form-list/form-item/paragraph/paragraph.model";


@Injectable({
    providedIn: 'root'
})

export class ParagraphFeatureContainerService implements OnDestroy {

    constructor( private initialNullSetupService: InitialNullSetupService , 
                 private userObjectUpdateService: UserObjectUpdateService,
                 private storeParagraphItemsService: StoreParagraphItemsService
                
                 ){}

    user = this.userObjectUpdateService.userEmail

    counter : number = 0

    paragraphTopicsOfReference: ParagraphTopic[]


    updateOnUserEmail : Subscription = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
        this.user = updatedUserEmail
        console.log('updated USER = ', this.user)
    })

    paragraphFeatureContainerPlusOnwerAndIndexType = new Subject<any[]>() // we send the paragraphFeatureContainer+ it's owner email to check if paragraphTopic already exist 

    paragraphFeatureContainers: ParagraphFeatureContainer[] = this.initialNullSetupService.initialNullParagraphFeatureContainers
             
             
    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.paragraphFeatureContainersChangeBackToNull
                 .subscribe((res)=> {
                        this.paragraphFeatureContainers = res 
                        console.log('updated paragraphFeatureContainers back to null = ', res)
              
                 })

    // const paragraphsItemsToCheckPlusIndex: any = { paragraphsItemsToStore , typeOfIndex }
    updateSharedParagraphFeatureContainersByCheckingIfParagraphExist: Subscription = this.storeParagraphItemsService.checkIfWeNeedToBuildSharedParagraphFeatureContainers
                  .subscribe(( paragraphsItemsToCheckPlusIndex: any ) => {
                    console.log(paragraphsItemsToCheckPlusIndex)
                    console.log(this.paragraphTopicsOfReference)

    const typeOfIndex: string = paragraphsItemsToCheckPlusIndex.typeOfIndex
    const paragraphsToAdd: Paragraph[] = paragraphsItemsToCheckPlusIndex.paragraphsItemsToStore

    if ( typeOfIndex === 'share') {
        this.processToBuildSHAREDParagraphFeatureContainerAndParentsComponents(paragraphsToAdd, 'shared-paragraphs')
        console.log('SHARED PARAGRAPH FEATURE TO ADD ', paragraphsToAdd)

    }

    if ( typeOfIndex === 'public') {
        this.processToBuildPUBLICParagraphFeatureContainerAndParentsComponents(paragraphsToAdd, 'public-paragraphs')
        console.log('SHARED PARAGRAPH FEATURE TO ADD ', paragraphsToAdd)

    }

       

    }) // end subscription 

    getParagraphFeatureContainers(){
        return this.paragraphFeatureContainers
    };

    getParagraphFeatureContainer(i: number){
        return this.paragraphFeatureContainers[i]
    };

    getParagraphFeatureContainerBy_id(_id: string){
        const newParagraphFeatureContainer: ParagraphFeatureContainer = this.paragraphFeatureContainers.find(
            (res)=> {
                return res._id === _id
            }
        )
        return newParagraphFeatureContainer
    };

      /**
     * start loading new paragraph on form Edit 
     */
      startLoadingFormEditOnWaitNewParagraphChildsToBeStored = new Subject<any>()
      sendFormEditEventToStartLoadingNewParagraph(){
          this.startLoadingFormEditOnWaitNewParagraphChildsToBeStored.next();
      }
          
      receiveSubjectFromParagraphFeatureContainerToStarLoadingNewParagraph(): Observable<any> {
          return this.startLoadingFormEditOnWaitNewParagraphChildsToBeStored.asObservable ();
      }
      // ---- END loading new paragraph ---------------------------------------------------------
  

      stopLoadingParagraphTopicIndex = new Subject<void>()
processToBuildSHAREDParagraphFeatureContainerAndParentsComponents(sharedParagraphsToAdd: Paragraph[], currentTypeOfIndex: string ){
 // if any of these sharedParagraphFeatures _id exist on any paragraphFeatureContainers 
 // tehn we leave like it is or we create a new ParagraphFeatureContainer and 
 // then check if the paragraphTopic Exist or we create a new one .. to be accepted 


 
 if ( sharedParagraphsToAdd.length > 0){

    /**
     * now for each paragraphTopic of reference we get all the paragraphFeatureContainers 
     */
    const sharedParagraphFeatureContainersOfReference: ParagraphFeatureContainer[] = []
    for ( let y=0 ; y<this.paragraphTopicsOfReference.length ; y++){
        for ( let z=0; z<this.paragraphTopicsOfReference[y].paragraphFeatureContainer_ids.length ; z++){
            sharedParagraphFeatureContainersOfReference.push(this.paragraphFeatureContainers[this.paragraphFeatureContainers.findIndex(x=> x._id === this.paragraphTopicsOfReference[y].paragraphFeatureContainer_ids[z] )] )
        }
    }
    console.log('SHARED paragraphFeatureContainers of reference ', sharedParagraphFeatureContainersOfReference)



    const arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex: any[] = []

    this.counter = 0

        for ( let i=0 ; i<sharedParagraphsToAdd.length ; i++ ){
            console.log('sharedParagraphFeatures To Add',sharedParagraphsToAdd)

    if ( sharedParagraphFeatureContainersOfReference.findIndex(x=> x.paragraph_id === sharedParagraphsToAdd[i]._id ) !== -1 ){
         this.counter = this.counter+1
        
        this.continueCheckingOnParagraphIndexTopic(arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex, sharedParagraphsToAdd, this.counter , currentTypeOfIndex )
    }

    if ( this.paragraphFeatureContainers.findIndex(x=> x.paragraph_id === sharedParagraphsToAdd[i]._id ) === -1 ){

const new_id = "paragraphFeatureContai-"+(this.paragraphFeatureContainers.length+this.counter+1).toString() 
const newComponentName = "paragraphFeatureContainer"
const newUIID = "paragraphFeatureContai-"+(this.paragraphFeatureContainers.length+this.counter+1).toString()
const newOwner = this.user 
const newAllUsersAllowed = false
const newUserListAllowed = []
const newDateAndTime = null 
const newIsActive = true 
const newIsAccepted = false
const newName = 'share'
const newState = false 
const newParagraph_id = sharedParagraphsToAdd[i]._id

const receivingParagraph_owner = sharedParagraphsToAdd[i].owner
const typeOfIndex = currentTypeOfIndex

const paragraphFeatureContainer = new ParagraphFeatureContainer ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed,
      newDateAndTime, newIsActive, newIsAccepted, newName, newState, newParagraph_id )
      
      arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex.push({ paragraphFeatureContainer , receivingParagraph_owner , typeOfIndex })
          this.counter = this.counter+1

          this.continueCheckingOnParagraphIndexTopic(arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex, sharedParagraphsToAdd, this.counter , currentTypeOfIndex)

        // }); // end subsciption 
    }; // finish the IF statement

   
   }// end For loop 
 } // END if 

 if ( sharedParagraphsToAdd.length === 0 ){
    this.stopLoadingParagraphTopicIndex.next()
 }

};


processToBuildPUBLICParagraphFeatureContainerAndParentsComponents(publicParagraphsToAdd: Paragraph[], currentTypeOfIndex: string){

    if ( publicParagraphsToAdd.length > 0){

    // /**
    //  * need to find the paragraphFeatureCointainers of reference 
    //  * and before that we look for the paragraphTopics of references and for each of them we find paragraphFeatureContainers 
    //  */

    /**
     * now for each paragraphTopic of reference we get all the paragraphFeatureContainers 
     */
    const publicParagraphFeatureContainersOfReference: ParagraphFeatureContainer[] = []
    for ( let y=0 ; y<this.paragraphTopicsOfReference.length ; y++){
        for ( let z=0; z<this.paragraphTopicsOfReference[y].paragraphFeatureContainer_ids.length ; z++){
            publicParagraphFeatureContainersOfReference.push(this.paragraphFeatureContainers[this.paragraphFeatureContainers.findIndex(x=> x._id === this.paragraphTopicsOfReference[y].paragraphFeatureContainer_ids[z] )] )
        }
    }
    console.log('PUBLIC paragraphFeatureContainers of reference ',publicParagraphFeatureContainersOfReference)


        
    const arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex: any[] = []
    this.counter = 0
            for ( let i=0 ; i<publicParagraphsToAdd.length ; i++ ){
                console.log('publicParagraphs To Add',publicParagraphsToAdd)
    
    if ( publicParagraphFeatureContainersOfReference.findIndex(x=> x.paragraph_id === publicParagraphsToAdd[i]._id ) !== -1 ){
         this.counter = this.counter+1
            
         this.continueCheckingOnParagraphIndexTopic(arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex, publicParagraphsToAdd, this.counter , currentTypeOfIndex )
    }

    if ( publicParagraphFeatureContainersOfReference.findIndex(x=> x.paragraph_id === publicParagraphsToAdd[i]._id ) === -1 ){
    
    const new_id = "paragraphFeatureContai-"+(this.paragraphFeatureContainers.length+1).toString()
    const newComponentName = "paragraphFeatureContainer"
    const newUIID = "paragraphFeatureContai-"+(this.paragraphFeatureContainers.length+1).toString()
    const newOwner = this.user 
    const newAllUsersAllowed = false
    const newUserListAllowed = []
    const newDateAndTime = null 
    const newIsActive = true 
    const newIsAccepted = true 
    const newName = 'public'
    const newState = false 
    const newParagraph_id = publicParagraphsToAdd[i]._id
    
    const receivingParagraph_owner = publicParagraphsToAdd[i].owner
    const typeOfIndex = currentTypeOfIndex
    
    const paragraphFeatureContainer = new ParagraphFeatureContainer ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed,
          newDateAndTime, newIsActive, newIsAccepted, newName, newState, newParagraph_id )
    
    /**
     * here we just add a "momentarely local done paragraphFeatureContainer with a local _id lenght " 
     * that will desappear once we logOut 
     * it only stay is we add to preferred list 
     */
    
              this.paragraphFeatureContainers.push(paragraphFeatureContainer)
              this.initialNullSetupService.initialNullParagraphFeatureContainers.push(paragraphFeatureContainer)
              console.log(paragraphFeatureContainer, this.paragraphFeatureContainers)
    
              arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex.push({paragraphFeatureContainer , receivingParagraph_owner , typeOfIndex })
    
              this.counter = this.counter+1
    
              this.continueCheckingOnParagraphIndexTopic(arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex, publicParagraphsToAdd, this.counter , currentTypeOfIndex)
    
            
        }; // finish the IF statement
    
        
       }// end For loop 
     } // END if 
}



continueCheckingOnParagraphIndexTopic(arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex: any[], sharedParagraphsToAdd: Paragraph[], counter: number , currentTypeOfIndex: string  ){

    if ( counter === sharedParagraphsToAdd.length){

            // send subject to paragraphIndexTopic service to check if indexTopic already exist 
            this.paragraphFeatureContainerPlusOnwerAndIndexType.next(arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex)
            console.log( ' NEW PARAGRAPHFEATURECONTAINER TO ADD = ', arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex.length,  arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex )
           
        

    }// end If function 
};

ngOnDestroy(): void {
    this.updateOnUserEmail.unsubscribe()
    this.updateInitialNullDatafromInitialNullSetupService.unsubscribe()
    this.updateSharedParagraphFeatureContainersByCheckingIfParagraphExist.unsubscribe()

}


};