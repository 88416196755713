import { Injectable } from "@angular/core";
import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from "@angular/common/http";
import { map, tap, catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { RecipientIndex } from "./recipient-index.model";

@Injectable ({
    providedIn: 'root' 
})

export class RecipientIndexHttpRequestService {

    constructor( private http: HttpClient ){}



    httpStoreRecipientIndexes( recipientIndexes: RecipientIndex[]){
        // let searchParams = new HttpParams
        // searchParams = searchParams.append('print', 'primo parametro')
        // searchParams = searchParams.append( 'secondo', 'secondo parametro ')
       return this.http.put<RecipientIndex[]>('https://drapp-71fc8-default-rtdb.europe-west1.firebasedatabase.app/recipientIndexes.json', recipientIndexes )
    //    {
    //     headers: new HttpHeaders ({"Custom-Header":"recipient indexes"}),
    //     params: searchParams 
    // }
    // )
    // .pipe(map(responseData => {
    //     const recipientIndexes: RecipientIndex[] = [];
    //      return recipientIndexes.map( responseData => {
    //       return {... responseData, _id: responseData._id? responseData._id: null ,
                                    
    //      }
    //  }
    //  )
    // }),
    // catchError(errorRes => {
    //     return throwError(errorRes)
    // })
    // )
    }
    /**
     * 
     *   constructor ( public _id : string,
                  public owner: string,
                  public dateAndTime: string ,
                  public dropId: number , 
                  public recipientIndexId: number , 
                  public name : string , 
                  public recipId: number , 
                  public recipientIds: RecipientId[]) {}
  }
     */



    httpFetchRecipientIndexes(){
        let searchParams = new HttpParams
        searchParams = searchParams.append('print', 'primo parametro')
        searchParams = searchParams.append( 'secondo', 'secondo parametro ')
        return this.http.get<{ [key:string]: RecipientIndex }>('https://drapp-71fc8-default-rtdb.europe-west1.firebasedatabase.app/recipientIndexes.json',
        {
            headers: new HttpHeaders ({"Custom-Header":"recipient indexes"}),
            params: searchParams 
        }
        )
        .pipe(map(responseData => {
            const recipientIndexes: RecipientIndex[] = [];
            for ( const key in responseData){
                if ( responseData.hasOwnProperty(key)){
                    recipientIndexes.push({...responseData[key], _id:key})
            }
          }
          console.log(recipientIndexes)
          return recipientIndexes
        }),
        catchError(errorRes => {
            return throwError(errorRes)
        })
        )

    }



}