import { Component, EventEmitter, Output,  OnInit, OnDestroy, ChangeDetectorRef, NgZone } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Observer, Subject, Subscription, fromEvent, merge  } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { HeaderService } from './header.service';
import { RecipientIndexService } from '../forms/form-list/form-edit/recipient-index-list/recipient-index.service';
import { FeatureTopicService } from '../forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-topic.service';
import { FeatureIndexSelection } from '../forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-index-topic-list/feature-index-selection.model';
import { Header } from './header.model';
import { HeaderBadger } from './header-badger.model';
import { HeaderBadgerService } from './header-badger.service';
import { AppLoadingService } from '../services/other-components-services/app-loading.service';
import { AuthService } from '../services/auth-services/auth.service';
import { Form } from '../forms/form.model';
import { FormService } from '../forms/form.service';
import { UserObjectUpdateService } from '../services/other-components-services/user-object-update.service';
import { ErrorsDialogManagerService } from '../services/other-components-services/errors-dialog-manager.service';
import { LoginService } from '../login/login.service';
import { UserObject } from '../auth/user.model';
import { AdviseIfHttpStoreComponentsCallHasBeenMadeService } from '../services/other-components-services/advise-if-http-store-components-has-been-made.service';
import { StoreFormItemPlusChildsConfirmationService } from '../services/get-and-store-components/store-form-item-plus-childs-confirmation.service';
import { StoreParagraphItemPlusChildsConfirmationService } from '../services/get-and-store-components/store-paragraph-item-plus-childs-confirmation.service';
import { StoreTabItemPlusChildsConfirmationService } from '../services/get-and-store-components/store-tab-item-plus-childs-confirmation.service';
import { StoreMultibaseItemPlusChildsConfirmationService } from '../services/get-and-store-components/store-multiBase-item-plus-childs-confirmation.service';
import { StorePersonalFeatureIndexComponentsService } from '../services/store-initial-components/store-personal-feature-index-components.service';
import { InitialNullSetupService } from '../services/starting-services/initial-null-setup.service';
import { IsOnlineOfflineService } from '../services/shared-services/is-online-offline.service';
import { DateTime } from 'luxon';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit , OnDestroy {

  user: string 

  signUpLogOut: string = 'login' // this actually has to be initializd from the ngOnInit and check if we are already logged in 
  isLoggedIn: boolean = false

   @Output() featureSelected = new EventEmitter<string>();

   onSelect( feature: string){
     this.featureSelected.emit(feature);
   }
  

   @Output() firstCheckOnUserDone = new EventEmitter<boolean>()
   firstCheckOnUserComponents: boolean = false 
   @Output() itStartLoadingSpinner = new EventEmitter<void>();
   @Output() itStopLoadingSpinner = new EventEmitter<boolean>();
   isSignedInConfirmation: boolean = false 


   weAreGoingToComponentIndex : boolean

   weAreInDrapp: boolean ;
   hideDrappBadge: boolean ;
   drappBadgeQuantity: number ;

   hideFormsBadge: boolean ;
   hideResultsBadge: boolean ;
   hideChatBadge: boolean ;
   hideSignInBadge: boolean ;


   weAreInEditParagraph: boolean;
   weAreInCreateParagraph: boolean;
   weAreInForms: boolean ;
   weAreInResults: boolean;
   weAreInMessages: boolean ;
   weAreInSignIn: boolean ;
   weAreLoggedIn: boolean;

   isEditComponent: boolean;

   headerIndexSelection : Header 
/**
 * coming from every button on the header
 */
  subscriptionToChangeHeaderButtonsColor: Subscription

/**
 * coming from the button on edit paragraph when choosing to edit the component 
 */
subscriptionToChangeEditParagraphIntoEditComponent: Subscription

initializingAppLoading: Subscription
stoppingAppLoading: Subscription 

updateSignInLogOutOnSigningIn: Subscription
updateSignInLogOutOnLoggingOut: Subscription

/**
 * to update badgers on all headericons
 */
headerBadger: HeaderBadger
// need to make a subscription for each time an update is made ( new results, new chat new component type/paragraphs/forms shared)
// like the other one , we have to cinstantly made a calculation on how many updates and keep the count up when a new update arrives 
// and return to 0 // we have to iterate through the paragraphs/forms/chats to check if there anyone new we add count++

key: string = ''

  count: number = 1

  isAnnouncingIsEditingAParagraphWithLoadingSpinner: boolean = false 

  /**
   * subdcription from 
   */
  // updateAllNewUserComponentsThatHasBeenStored: Subscription

  updateOnUserEmail: Subscription

  getUserEmailFromDefaultService: Subscription 

  userExist: boolean

  subscriptionToOnlineOffline: Subscription 
  isOnline: boolean
  dateTime: DateTime;



   constructor(private breakpointObserver: BreakpointObserver,
               private headerService: HeaderService ,
               private recipientIndexService: RecipientIndexService ,
               private featureTopicService: FeatureTopicService ,
               private headerBadgerService: HeaderBadgerService,
               private appLoadingService: AppLoadingService,
               private authService: AuthService,
               private formService: FormService,
               private userObjectUpdateService: UserObjectUpdateService ,
               private errorsDialogManagerService: ErrorsDialogManagerService , 
               private loginService: LoginService , 
               private storePersonalFeatureIndexComponentsService: StorePersonalFeatureIndexComponentsService ,
               private adviseIfHttpStoreComponentsCallHasBeenMadeService: AdviseIfHttpStoreComponentsCallHasBeenMadeService , 
               private storeFormItemPlusChildsConfirmationService: StoreFormItemPlusChildsConfirmationService , 
               private storeParagraphItemPlusChildsConfirmationService: StoreParagraphItemPlusChildsConfirmationService , 
               private storeTabItemPlusChildsConfirmationService: StoreTabItemPlusChildsConfirmationService , 
               private storeMultibaseItemPlusChildsConfirmationService: StoreMultibaseItemPlusChildsConfirmationService,
               private initialNullSetupService: InitialNullSetupService,
               private cdr: ChangeDetectorRef ,
               private ngZone: NgZone ,
               private isOnlineOfflineService: IsOnlineOfflineService
              
               ) {
  
   }

   ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail
 
    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
    .subscribe((updatedUserEmail)=>{
     this.user = updatedUserEmail
     this.updateSignUpLogOut(updatedUserEmail)
     console.log('updated USER = ', this.user)
    })

    if (this.user === null ){ this.userExist = false }
    if ( this.user !== null){ this.userExist = true }



    this.headerBadger = this.headerBadgerService.getHeaderBadger()
    console.log( this.headerBadger)
     /**
     * export class HeaderBadger {

    constructor ( public drappBadges:     Badges,
                  public paragraphBadges: Badges ,
                  public formBadges:      Badges,
                  public resultBadges:    Badges,
                  public groupBadges:     Badges,
                  public loginBadges:     Badges   ){}

    export class Badges {

    constructor( public badgeActive: boolean , public badgeQty: number  ){}
}
     */


    this.weAreInDrapp = false ; 
    this.hideDrappBadge = this.headerBadger.drappBadges.badgeActive ;
    this.drappBadgeQuantity = this.headerBadger.drappBadges.badgeQty ;



    this.weAreInEditParagraph = false;
    this.weAreInCreateParagraph = false; 
    


    this.weAreInForms = false ; 
    this.hideFormsBadge = false;
    


    this.weAreInResults = false ;
    this.hideResultsBadge = false;


    this.weAreInMessages = false ;
    this.hideChatBadge = false ;


    this.weAreInSignIn = false ;
    this.hideSignInBadge = false


    this.weAreLoggedIn = false ;
    
    this.isEditComponent = false;

    this.isMobile()
    console.log('THIS IS MOBILE = ',this.isMobile())
   



    this.subscriptionToChangeHeaderButtonsColor=  this.headerService.sharedHeaderIndexSelection.subscribe( headerIndexSelection => { 
      this.updateHeaderIndexSelection(headerIndexSelection)
    } )

    this.subscriptionToChangeEditParagraphIntoEditComponent = this.headerService.sharedComponentIndexSelection.subscribe( componentIndexSelection => {
      this.updatedEditParagraphComponentIndexSelection(componentIndexSelection)
    })

    this.initializingAppLoading = this.appLoadingService.getAppLoadingInitiate().subscribe(()=> { this.startLoadingSpinner()})
    this.stoppingAppLoading = this.appLoadingService.getAppLoadingToStop().subscribe(()=> { this.stopLoadingSpinner()})
    // this first check control if the user is already checked in 

    // setTimeout(()=> {
      this.firstCheckOnUserComponents = true ,
      this.firstCheckOnUserDone.emit(this.firstCheckOnUserComponents)

      // }, 300)
      // console.log('components data has been dowonloaded')
   
    this.updateSignInLogOutOnLoggingOut = this.authService.updateInitialNullDataOnAllComponents.subscribe(
      ( userEmail)=> this.updateSignUpLogOut(userEmail)
    )

    /**
     * calling all featureIndex components at storeFeatureIndex components 
     */
    this.getUserEmailFromDefaultService = this.loginService.$startGettingPersonalFeatureIndex
                       .subscribe(
                          (userEmail: string )=>{
                           console.log('subscription arrived')
                           this.storePersonalFeatureIndexComponentsService.getPersonalInitialFeatureIndex(userEmail)
    })

     /**
 * package online / offline property -------------------------------------------------
 */
   console.log('this device is online : ',)
   this.subscriptionToOnlineOffline = this.createOnline$()
       .subscribe(isOnline =>{ 
                  console.log( this.isOnline = isOnline,'this device is now online: ', this.isOnline, DateTime.now().toString() ),
                  this.isOnlineOfflineService.changeOnlineOfflineStatus(this.isOnline)
                
        });
 //  -----------------------------------------------------------------------------------

   
  };

  isMobile(){
    // credit to Timothy Huang for this regex test: 
    // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        return true
   }
   else{
        return false
   }
  } 



  startEditParagraphSingleSpinner(){
    setTimeout(()=>{
      this.isAnnouncingIsEditingAParagraphWithLoadingSpinner = true 
    }, 8000)
  }

  startLoadingSpinner(){
    this.itStartLoadingSpinner.emit()
  }

  stopLoadingSpinner(){
    this.itStopLoadingSpinner.emit()
  }


  updateSignUpLogOut(userEmail: string){
    this.ngZone.run(() => {
      if ( userEmail === null) { this.signUpLogOut = 'sign in', this.isLoggedIn = false , this.userExist = false }
      if ( userEmail !== null) { this.signUpLogOut = 'log out', this.isLoggedIn = true , this.userExist = true  }
    });

    this.cdr.detectChanges()
    
  }

  /**
   * open a dialog to login if we are logged in 
   * log out if we are already logged in 
   */
  onLoginLogout(){

    // if user === null we are logging in 
    if ( this.user === null ){ 
         console.log( 'IS LOGGIN IN') 

         // put temporarely in erroes dialog because the other will make a circle 
        this.errorsDialogManagerService.LoginPopUpDialog() 
    }

    // if user !== null we are looing out 
    if ( this.user !== null ){ 
      /**
       * logout and reset to null all data 
       */
      console.log( 'LOG OUT ')
      this.loginService.startLoadingMainPageOnLogin() // main page loading start 
      const newUserObject = new UserObject ( null , null , null , new Date())
      /**
       * have to set at false all those services that signal personal files has been store like forms/ paragraphs etc 
       * should also set to null all the pages like on editParagraph 
       */
      this.initialNullSetupService.resetAllInitialComponnetsToNullAgain()
      this.adviseIfHttpStoreComponentsCallHasBeenMadeService.onLoggingOutSetAllPersonalComponentsHaveBeenStoredToFalseAgain()
      this.storeFormItemPlusChildsConfirmationService.onLoggingOutSetListOfFormItemsWithChildsEmptyAgain()
      this.storeParagraphItemPlusChildsConfirmationService.onLoggingOutSetListOfParagraphItemsWithChildsEmptyAgain()
      this.storeTabItemPlusChildsConfirmationService.onLoggingOutSetListOfTabItemsWithChildsEmptyAgain()
      this.storeMultibaseItemPlusChildsConfirmationService.onLoggingOutSetListOfMultiFeatureItemsWithChildsEmptyAgain()
      this.authService.onUpdateLoggedOutUser( newUserObject )

    }

  }


  updateHeaderIndexSelection(headerIndexSelection: Header){
    this.headerIndexSelection = headerIndexSelection 

    this.weAreInDrapp = this.headerIndexSelection.weAreInDrapp ; 
    this.weAreInEditParagraph = this.headerIndexSelection.weAreInEditParagraph ;
    this.weAreInCreateParagraph = this.headerIndexSelection.weAreInCreateParagaph;
    this.weAreInForms = this.headerIndexSelection.weAreInForms ; 
    this.weAreInResults = this.headerIndexSelection.weAreInResults;
    this.weAreInMessages = this.headerIndexSelection.weAreInMessages ;
    this.weAreInSignIn = this.headerIndexSelection.weHaveToSignIn ;
    this.weAreLoggedIn = this.headerIndexSelection.weAreLoggedIn ;

    this.isEditComponent = false;

    if ( this.weAreInEditParagraph === true ){ this.startEditParagraphSingleSpinner() }
    if ( this.weAreInEditParagraph === false){ this.isAnnouncingIsEditingAParagraphWithLoadingSpinner = false }
    

    // need to change all the headerindex with info coming from the other places ( like paragraph , chat , )
  }

  updatedEditParagraphComponentIndexSelection(componentIndexSelection: boolean){
    this.isEditComponent = componentIndexSelection
  }




  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

 

  onWeAreGoingToCreateForm(){

    const newHeaderIndexSelection = new Header (true, false, false, false, false, false, false, false )
    this.headerService.nextIndexSelection(newHeaderIndexSelection)

    this.isEditComponent = false;
    this.hideDrappBadge = true ;
    this.hideFormsBadge = false ;
 
 
     const startingEditForm: Form = new Form ( null , 'form', null, '', '','', false, [], false, false , false, true,  false, '', this.recipientIndexService.getRecipientIndex(0)._id , [] )
     this.formService.onUpdateLatestShownForm(startingEditForm)
    


  }


  weAreGoingToForms(){
    const newHeaderIndexSelection = new Header (false, false, false, true, false, false, false, false )
    this.headerService.nextIndexSelection(newHeaderIndexSelection)

    this.isEditComponent = false;
    /**
     * badge update
     */
     this.hideFormsBadge = true
    this.hideDrappBadge = false
     /**
    * to update the feature index selection to starting point , just in case
    */
    // const newUpdateFeatureIndexSelection = new FeatureIndexSelection ( true, false  )
    // this.featureTopicService.nextIndexSelection(newUpdateFeatureIndexSelection)

    /**
     * update startingForm @ formservice  
     */
    // line 1067 - 1335  to fix on formEdit
   const startingEditForm: Form = new Form ( null , 'form', null, '', '','',  false, [], false, false, false, true , false, '', this.recipientIndexService.getRecipientIndex(0)._id , [] )
   this.formService.onUpdateLatestShownForm(startingEditForm)
  }

  weAreGoingToResults(){
    const newHeaderIndexSelection = new Header (false, false, false, false, true, false, false, false )
    this.headerService.nextIndexSelection(newHeaderIndexSelection)

    this.isEditComponent = false;

    /**
     * badge update
     */
     this.hideDrappBadge = false
     this.hideFormsBadge = false ;

     /**
    * to update the feature index selection to starting point , just in case
    */
    this.headerService.resetResultsPageWithNoSelected()
      /**
     * update startingForm @ formservice  
     */
       // line 1067 - 1335  to fix on formEdit
      const startingEditForm: Form = new Form ( null , 'form', null, '', '','',  false, [], false, false, false, true , false, '', this.recipientIndexService.getRecipientIndex(0)._id , [] )
      this.formService.onUpdateLatestShownForm(startingEditForm)

  }


  onWeAreGoingToMessages(){
    const newHeaderIndexSelection = new Header (false, false, false, false, false, true, false, false )
    this.headerService.nextIndexSelection(newHeaderIndexSelection)

    this.isEditComponent = false;

    /**
     * badge update
     */
     this.hideDrappBadge = false
     this.hideFormsBadge = false ;

     /**
    * to update the feature index selection to starting point , just in case
    */
    // const newUpdateFeatureIndexSelection = new FeatureIndexSelection ( true, false  )
    // this.featureTopicService.nextIndexSelection(newUpdateFeatureIndexSelection)

      /**
     * update startingForm @ formservice  
     */
       // line 1067 - 1335  to fix on formEdit
      const startingEditForm: Form = new Form ( null , 'form', null, '', '','',  false, [], false, false, false, true ,  false, '', this.recipientIndexService.getRecipientIndex(0)._id , [] )
      this.formService.onUpdateLatestShownForm(startingEditForm)
  }

  weAreGoingToSignIn(){

    /**
     *  constructor( public weAreInDrapp:         boolean ,  
                     public weAreInEditParagraph: boolean, 
                     public weAreInForms:         boolean,
                     public weAreInMessages:      boolean ,
                     public weHaveToSignIn:       boolean , 
                     public weAreLoggedIn:        boolean ){}
}
     */

    const newHeaderIndexSelection = new Header (false, false, false, false, false, false, true, false )
    this.headerService.nextIndexSelection(newHeaderIndexSelection)

    this.isEditComponent = false;

    /**
     * badge update
     */
     this.hideDrappBadge = false
     this.hideFormsBadge = false ;
     
     /**
    * to update the feature index selection to starting point , just in case
    */
    const newUpdateFeatureIndexSelection = new FeatureIndexSelection ( true, false  )
    this.featureTopicService.nextIndexSelection(newUpdateFeatureIndexSelection)

      /**
     * update startingForm @ formservice  
     */
       // line 1067 - 1335  to fix on formEdit
      const startingEditForm: Form = new Form ( null , 'form', null, '', '','',  false, [], false, false, false, true, false, '',this.recipientIndexService.getRecipientIndex(0)._id , [] )
      this.formService.onUpdateLatestShownForm(startingEditForm)

  }



notInEditParagraph(){
  this.isEditComponent = false;
}



  
  /**
   * 
   * @returns red color to Drapp if we are in Drapp
   */
  getDrappColor(){
    return this.weAreInDrapp === true ? 'yellow': 'white';
  }

  getSignInColor(){
    return this.weAreInSignIn === true ? 'yellow': 'white';
  }

  getResultsColor(){
    return this.weAreInResults === true ? 'yellow': 'white';
  }

  /**
   * 
   * @returns red Forms if forms is clicked 
   */
  getFormsColor(){
    return this.weAreInForms === true ? 'yellow': 'white';
  }
 /**
  * 
  * @returns red Message if message is clicked
  */
  getMessagesColor(){
    return this.weAreInMessages === true ? 'yellow': 'white';
  }


/**
 * package online / offline property -------------------------------------------------
 */

   createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  };


  ngOnDestroy() {
    this.subscriptionToChangeHeaderButtonsColor.unsubscribe()
    this.subscriptionToChangeEditParagraphIntoEditComponent.unsubscribe()
    this.initializingAppLoading.unsubscribe()
    this.stoppingAppLoading.unsubscribe()
    this.updateSignInLogOutOnSigningIn.unsubscribe()
    this.updateSignInLogOutOnLoggingOut.unsubscribe()
    this.updateOnUserEmail.unsubscribe()
    this.subscriptionToOnlineOffline.unsubscribe()
  }

  

  


}
