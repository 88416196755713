import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-edit-paragraphs-index',
  templateUrl: './form-edit-paragraphs-index.component.html',
  styleUrls: ['./form-edit-paragraphs-index.component.css']
})
export class FormEditParagraphsIndexComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
