import { Component, Input, OnInit , Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { CalculatorMixTable } from '../calculator-mix-table.model';
import { DescriptionPart } from '../description-part.model';
import { CalculatorMixTableService } from '../calculator-mix-table.service';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { Feature } from '../../../feature.model';
import { FeatureResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/feature-result.model';
import { Subscription, Observable,  } from 'rxjs';
import { DialogmanagerService } from 'src/app/services/other-components-services/dialogmanager.service';
import { MatDialogRef, MAT_DIALOG_DATA, _closeDialogVia } from '@angular/material/dialog';
import { CalculatorMixTableDetailResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/calculator-mix-table-result/calculator-mix-table-result.model';
import { CalculatorMixTableDescriptionDetailResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/calculator-mix-table-result/calculator-mix-table-description-detail-result.model';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { FormService } from 'src/app/forms/form.service';

@Component({
  selector: 'app-calculator-mix-table-item',
  templateUrl: './calculator-mix-table-item.component.html',
  styleUrls: ['./calculator-mix-table-item.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CalculatorMixTableItemComponent implements OnInit {

  user: string 

  public calculatorMixTableGroup: FormGroup

  selectedType: string;
  selectedDesciption: string;
  @Input() index_id: string 
  index: number
  calculatorMixTable: CalculatorMixTable

  @Input() isParagraphEdit: boolean
  @Input() feature: Feature

  @Input() isTabItem: boolean 
  @Input() tab_index: number 

  featureResult: FeatureResult;
  /**
   * receiving the click from fixTextList to edit the component
   */
  @Input() subscriptionToEditComponentItemIsOn: boolean
  currentFeature: Feature



  @Input() editFeatureItem: Observable<void>;
  editFeatureEventFromCalculatorMixTableListList: Subscription; 


  mailTextFeatureBodyPart: string



  /**
   * using these 2 new objects to retrive data on them 
   */
  categoryList: string[]
  descriptionPartList: DescriptionPart[]

  categoryListGroup: any[]
  descriptionPartListGroup: any[]


  minimumRowExist: boolean
  maxRowTotalNumber: number = 10
  maxRowExist: boolean

  /**
   * operations properties
   */

  calculatedNetPrice: number 
  calculatedSubTotalPrice: number 

  isSelectedCategory: boolean[]
  isSelectedDescription: boolean[]

  updatedQuantity: number
  updatedPricelist: number
  updatedDiscount: number
  updatedNetprice: number
  updatedSubtotal: number 


  isDisabled: boolean

  subTotalList: number[]
  total: number

  @Output() updateBothFeatureResultAndTextPlusIndex = new EventEmitter<any>()

  requestToFinalizeResultAgain: Subscription
  

  constructor(private calculatorMixTableService: CalculatorMixTableService,
              private _fb: FormBuilder,
              private dms: DialogmanagerService, 
              private formService: FormService,
              private userObjectUpdateService: UserObjectUpdateService ) {}

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail
    this.index = this.calculatorMixTableService.getCalculatorMixTables().findIndex(x=> x._id === this.index_id)
    this.calculatorMixTable = this.calculatorMixTableService.getCalculatorMixTable(this.index)

    console.log('feature = ', this.feature, this.calculatorMixTable)

    this.categoryList = this.calculatorMixTable.descriptionFrame.category;
    this.descriptionPartList = []

    this.categoryListGroup = [this.categoryList]
    this.descriptionPartListGroup = ['']

    this.minimumRowExist = false
    this.maxRowExist = true
    this.isSelectedCategory = [false]
    this.isSelectedDescription = [false]


    this.subTotalList = [0]
    this.total = 0

    this.isDisabled = true
    
    /**
     * thei is the formGroup in use
     */
    this.calculatorMixTableGroup = this._fb.group({
      DescriptionFrameRows: this._fb.array([this.initDescriptionFrameRows()]),
      total: new FormControl ( 0 , [Validators.required]), 
    }

    );

    this.calculatorMixTableGroup.valueChanges.subscribe(
      (value) => 
       console.log(value));

        this.requestToFinalizeResultAgain = this.formService.getRequestToFinalizeResultAgain().subscribe(()=> this.onSend())
        setTimeout(()=>{
          this.onSend()
        },200)
      
      console.log(this.index)
  
  
      if (this.isParagraphEdit === true){
      this.editFeatureEventFromCalculatorMixTableListList = this.editFeatureItem.subscribe((data)=> this.checkIfMatchId(data))
     }
   
  };

  get formArray(){
    return this.calculatorMixTableGroup.get('DescriptionFrameRows') as FormArray
  };

  initDescriptionFrameRows(){
    return this._fb.group({
      quantity: new FormControl (null , [Validators.required] ),
      category: [''],
      description: [''],
      priceList: [],
      discount: [],
      netPrice: [],
      subTotal: new FormControl (null , [Validators.required] ),
    })
    // maxLength1: new FormControl ('25',[Validators.pattern(/^[1-9]+[0-9]*$/), Validators.max(25), Validators.min(1), Validators.required ]),
 
  };



  addNewRow(){
    this.formArray.push(this.initDescriptionFrameRows())
    this.categoryListGroup.push(this.categoryList)
    this.descriptionPartListGroup.push('')
    console.log('category list groups => ', this.categoryListGroup , this.descriptionPartListGroup)
    if (this.formArray.length === this.maxRowTotalNumber ){this.maxRowExist= false}
    this.minimumRowExist = true
    this.isSelectedCategory.push(false)
    this.isSelectedDescription.push(false)
    console.log(this.isSelectedCategory, this.isSelectedDescription)

    this.subTotalList.push(0)

    if (this.isParagraphEdit !== true){
      setTimeout(()=>{
        this.onSend()
      })
   } 
    
  
  };

  deleteRow(index: number){
    this.formArray.removeAt(index)
    this.categoryListGroup.splice(index, 1)
    this.descriptionPartListGroup.splice(index, 1)
    console.log(this.calculatorMixTableGroup)
    if (this.formArray.length === 1){this.minimumRowExist = false}
    if (this.formArray.length  > 1){this.minimumRowExist = true}
    this.isSelectedCategory.splice(index, 1)
    this.isSelectedDescription.splice(index, 1)
    console.log(this.isSelectedCategory, this.isSelectedDescription)

    this.subTotalList.splice(index, 1);
    this.total = 0
    for (let i=0; i<this.subTotalList.length; i++){
      this.total = this.total+this.subTotalList[i]
    }
    this.calculatorMixTableGroup.value.total = this.total
    this.calculatorMixTableGroup.patchValue({ total: this.total})

    /**
     * usual send method
     */
    if (this.isParagraphEdit !== true){
      setTimeout(()=>{
        this.onSend()
      })
  } 
    
  };

  selectCategory(i: number, $event){
    console.log('this is the selected category', i, $event)
    this.descriptionPartList = this.calculatorMixTableService.getSelectedDescriptionParts(this.index, $event.value )

    this.descriptionPartListGroup[i] = this.descriptionPartList
    this.isSelectedCategory[i] = true

    console.log(this.isSelectedCategory)
    console.log(this.descriptionPartListGroup)
    console.log(this.calculatorMixTableGroup)

    /**
     * usual send method
     */
         if (this.isParagraphEdit !== true){
          setTimeout(()=>{
            this.onSend()
          })
      } 
  };

  selectDescriptions(i: number ,$event){
    console.log(i , $event)
    console.log(i , $event.value.description)

    this.isSelectedDescription[i] = true
    console.log( this.isSelectedDescription)
    const newQuantity = this.calculatorMixTableGroup.value.DescriptionFrameRows[i].quantity
    this.updatedQuantity = newQuantity
    if ( this.updatedQuantity === null ){ this.updatedQuantity = 1 }

    this.calculatedNetPrice = ($event.value.priceList - ($event.value.priceList*$event.value.basicDiscount/100))
    this.calculatedSubTotalPrice = (this.updatedQuantity * this.calculatedNetPrice )

    let formArr = <FormArray>this.calculatorMixTableGroup.controls.DescriptionFrameRows
    formArr.at(i).patchValue({ quantity: this.updatedQuantity,
                               priceList: $event.value.priceList,
                               discount: $event.value.basicDiscount,
                               netPrice: this.calculatedNetPrice,
                               subTotal: this.calculatedSubTotalPrice
                               })


    this.subTotalList[i] = this.calculatedSubTotalPrice
    this.total = 0
    for (let i=0; i<this.subTotalList.length; i++){
         this.total = this.total+this.subTotalList[i]
    }
    this.calculatorMixTableGroup.value.total = this.total
    this.calculatorMixTableGroup.patchValue({ total: this.total})


    console.log(this.calculatorMixTableGroup)

    /**
     * usual send method
     */
         if (this.isParagraphEdit !== true){
          setTimeout(()=>{
            this.onSend()
          })
      } 
  

  };

/**
 * 
 * @param i valueof the array line we are modifying
 * @param value  manually inputed value , we change the rest of the data accoding to this number 
 * and make them macth
 */
  getQuantityIdValue( i: number, value: number){
    console.log(i , value)

    this.updatedQuantity = value
    console.log(this.updatedQuantity)


    if (this.isSelectedCategory[i] && this.isSelectedDescription[i] === true ){
    console.log(this.calculatorMixTableGroup.value.DescriptionFrameRows[i].priceList )


    const newPriceList = this.calculatorMixTableGroup.value.DescriptionFrameRows[i].priceList
    const newDiscount = this.calculatorMixTableGroup.value.DescriptionFrameRows[i].discount
    const newNetPrice = newPriceList-(newPriceList*newDiscount/100)
     console.log(newPriceList, newDiscount , newNetPrice)
    
    this.calculatedSubTotalPrice = this.updatedQuantity * newNetPrice

    console.log(this.calculatedNetPrice)
    console.log(this.calculatedSubTotalPrice)

    let formArr = <FormArray>this.calculatorMixTableGroup.controls.DescriptionFrameRows
    formArr.at(i).patchValue({ pricelist: newPriceList ,
                               discount: newDiscount, 
                               netPrice: newNetPrice,
                               subTotal: this.calculatedSubTotalPrice
                               })
    console.log(this.calculatorMixTableGroup)

    this.subTotalList[i] = this.calculatedSubTotalPrice
    this.total = 0
    for (let i=0; i<this.subTotalList.length; i++){
         this.total = this.total+this.subTotalList[i]
    };
    this.calculatorMixTableGroup.value.total = this.total
    this.calculatorMixTableGroup.patchValue({ total: this.total})

    }

        /**
     * usual send method
     */
         if (this.isParagraphEdit !== true){
          setTimeout(()=>{
            this.onSend()
          })
      } 
  }

  getPricelistIdValue( i:number, value: number){
    console.log(i, value)

    this.updatedPricelist = value 

    if (this.isSelectedCategory[i] && this.isSelectedDescription[i] === true ){
      
      const newQuantity = this.calculatorMixTableGroup.value.DescriptionFrameRows[i].quantity
      this.updatedQuantity = newQuantity
      if (this.updatedQuantity === null){ this.updatedQuantity = 1 }
      const newPriceList = this.updatedPricelist
      const newDiscount = this.calculatorMixTableGroup.value.DescriptionFrameRows[i].discount
      const newNetPrice = newPriceList-(newPriceList*newDiscount/100)
       console.log(newPriceList, newDiscount , newNetPrice)
      
      this.calculatedSubTotalPrice = this.updatedQuantity * newNetPrice
  
      console.log(this.calculatedNetPrice)
      console.log(this.calculatedSubTotalPrice)
  
      let formArr = <FormArray>this.calculatorMixTableGroup.controls.DescriptionFrameRows
      formArr.at(i).patchValue({ quantity: this.updatedQuantity,
                                 discount: newDiscount,
                                 netPrice: newNetPrice,
                                 subTotal: this.calculatedSubTotalPrice
                                 })
      console.log(this.calculatorMixTableGroup)

      this.subTotalList[i] = this.calculatedSubTotalPrice
      this.total = 0
      for (let i=0; i<this.subTotalList.length; i++){
           this.total = this.total+this.subTotalList[i]
      };
      this.calculatorMixTableGroup.value.total = this.total
      this.calculatorMixTableGroup.patchValue({ total: this.total})
      
    }

        /**
     * usual send method
     */
         if (this.isParagraphEdit !== true){
          setTimeout(()=>{
            this.onSend()
          })
      } 
  }

  getDiscountIdValue( i: number, value: number){
    console.log(i, value)

    this.updatedDiscount = value

    if (this.isSelectedCategory[i] && this.isSelectedDescription[i] === true ){
     
      const newQuantity = this.calculatorMixTableGroup.value.DescriptionFrameRows[i].quantity
      this.updatedQuantity = newQuantity
      if (this.updatedQuantity === null){ this.updatedQuantity = 1 }
      const newPriceList = this.calculatorMixTableGroup.value.DescriptionFrameRows[i].priceList
      const newDiscount = this.updatedDiscount
      const newNetPrice = newPriceList-(newPriceList*newDiscount/100)
       console.log(newPriceList, newDiscount , newNetPrice)
      
      this.calculatedSubTotalPrice = this.updatedQuantity * newNetPrice
  
      console.log(this.calculatedNetPrice)
      console.log(this.calculatedSubTotalPrice)
  
      let formArr = <FormArray>this.calculatorMixTableGroup.controls.DescriptionFrameRows
      formArr.at(i).patchValue({ quantity: this.updatedQuantity,
                                 discount: this.updatedDiscount,
                                 netPrice: newNetPrice,
                                 subTotal: this.calculatedSubTotalPrice
                                 })
      console.log(this.calculatorMixTableGroup)

      this.subTotalList[i] = this.calculatedSubTotalPrice
      this.total = 0
      for (let i=0; i<this.subTotalList.length; i++){
           this.total = this.total+this.subTotalList[i]
      };
      this.calculatorMixTableGroup.value.total = this.total
      this.calculatorMixTableGroup.patchValue({ total: this.total})
      
    }

        /**
     * usual send method
     */
         if (this.isParagraphEdit !== true){
          setTimeout(()=>{
            this.onSend()
          })
      } 

  }

  getNetpriceIdValue( i: number, value: number){
    console.log(i, value)

    this.updatedNetprice = value

    if (this.isSelectedCategory[i] && this.isSelectedDescription[i] === true ){
      
      const newQuantity = this.calculatorMixTableGroup.value.DescriptionFrameRows[i].quantity
      this.updatedQuantity = newQuantity
      if (this.updatedQuantity === null){ this.updatedQuantity = 1 }
      const newPriceList = this.calculatorMixTableGroup.value.DescriptionFrameRows[i].priceList
      const newDiscount = ((newPriceList-this.updatedNetprice)/newPriceList)*100
      const newNetPrice = this.updatedNetprice
       console.log(newPriceList, newDiscount , value)
      
      this.calculatedSubTotalPrice = this.updatedQuantity * newNetPrice
  
      console.log(this.calculatedNetPrice)
      console.log(this.calculatedSubTotalPrice)
  
      let formArr = <FormArray>this.calculatorMixTableGroup.controls.DescriptionFrameRows
      formArr.at(i).patchValue({ quantity: this.updatedQuantity,
                                 discount: newDiscount,
                                 netPrice: this.updatedNetprice,
                                 subTotal: this.calculatedSubTotalPrice
                                 })
      console.log(this.calculatorMixTableGroup)

      this.subTotalList[i] = this.calculatedSubTotalPrice
      this.total = 0
      for (let i=0; i<this.subTotalList.length; i++){
           this.total = this.total+this.subTotalList[i]
      };
      this.calculatorMixTableGroup.value.total = this.total
      this.calculatorMixTableGroup.patchValue({ total: this.total})
      
    }

        /**
     * usual send method
     */
         if (this.isParagraphEdit !== true){
          setTimeout(()=>{
            this.onSend()
          })
      } 

  }

  getSubtotalIdValue( i: number, value: number){
    console.log(i, value)

    this.updatedSubtotal = value

    if (this.isSelectedCategory[i] && this.isSelectedDescription[i] === true ){
      
      const newQuantity = this.calculatorMixTableGroup.value.DescriptionFrameRows[i].quantity
      this.updatedQuantity = newQuantity
      if (this.updatedQuantity === null){ this.updatedQuantity = 1 }
      const newPriceList = this.calculatorMixTableGroup.value.DescriptionFrameRows[i].priceList
      const newNetPrice = this.updatedSubtotal/newQuantity
      const newDiscount = ((newPriceList-newNetPrice)/newPriceList)*100
      
      
      this.calculatedSubTotalPrice = this.updatedQuantity * newNetPrice
  
      console.log(this.calculatedNetPrice)
      console.log(this.calculatedSubTotalPrice)
  
      let formArr = <FormArray>this.calculatorMixTableGroup.controls.DescriptionFrameRows
      formArr.at(i).patchValue({ quantity: this.updatedQuantity,
                                 discount: newDiscount,
                                 netPrice: newNetPrice,
                                 subTotal: this.updatedSubtotal
                                 })
      console.log(this.calculatorMixTableGroup)

      this.subTotalList[i] = this.calculatedSubTotalPrice
      this.total = 0
      for (let i=0; i<this.subTotalList.length; i++){
           this.total = this.total+this.subTotalList[i]
      };
      this.calculatorMixTableGroup.value.total = this.total
      this.calculatorMixTableGroup.patchValue({ total: this.total})
      
    }

        /**
     * usual send method
     */
         if (this.isParagraphEdit !== true){
          setTimeout(()=>{
            this.onSend()
          })
      } 

  }

  getTotalIdValue(){
    this.total = 0
    for (let i=0; i<this.subTotalList.length; i++){
         this.total = this.total+this.subTotalList[i]
    };
    this.calculatorMixTableGroup.value.total = this.total
    this.calculatorMixTableGroup.patchValue({ total: this.total})

        /**
     * usual send method
     */
         if (this.isParagraphEdit !== true){
          setTimeout(()=>{
            this.onSend()
          })
      } 

  }


  onSend(){
    this.onSubmit()
  }


  onSubmit(){

        const newFeatureResultType = 'calculatorMixTableDetailResult';
        const newFeatureResultName = this.calculatorMixTable.name

        const newCalculatorMixTableDescriptionDetailResults: CalculatorMixTableDescriptionDetailResult[] = []

        for ( let i=0; i<this.calculatorMixTableGroup.value.DescriptionFrameRows.length ; i++ ){

          const newQuantity = this.calculatorMixTableGroup.value.DescriptionFrameRows[i].quantity
          const newCategory = this.calculatorMixTableGroup.value.DescriptionFrameRows[i].category
          const newDescription = this.calculatorMixTableGroup.value.DescriptionFrameRows[i].description.description
          const newPricelist = this.calculatorMixTableGroup.value.DescriptionFrameRows[i].priceList
          const newbasicDiscount = this.calculatorMixTableGroup.value.DescriptionFrameRows[i].discount
          const newnetPrice = this.calculatorMixTableGroup.value.DescriptionFrameRows[i].netPrice
          const newSubtotal = this.calculatorMixTableGroup.value.DescriptionFrameRows[i].subTotal





          const newCalculatorMixTableDescriptionDetailResult= new CalculatorMixTableDescriptionDetailResult ( newQuantity, newCategory, 
                                                                            newDescription, newPricelist, newbasicDiscount, newnetPrice, newSubtotal)
                                                        
          newCalculatorMixTableDescriptionDetailResults.push( newCalculatorMixTableDescriptionDetailResult)

        }
        const newTotal = this.calculatorMixTableGroup.value.total
        const newCalculatorMixTableDetailresult = new CalculatorMixTableDetailResult ( newCalculatorMixTableDescriptionDetailResults , newTotal)
        
        const newFeatureDetailResult = new FeatureResult (newFeatureResultType, newFeatureResultName, newCalculatorMixTableDetailresult )

        this.featureResult = newFeatureDetailResult

         /**
     * now build mailTextFeatureBodyPart
     */
         this.mailTextFeatureBodyPart = '%0D%0A'
     for (let j=0; j<this.calculatorMixTableGroup.value.DescriptionFrameRows.length; j++){

      /**
       * for each description type , make a function that distance ( padWord or rightPadword) the same distnace 
       * the words from the different lines , so it looks in order 
       */
       
       
       // this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent('Q.ty: ')+encodeURIComponent(this.calculatorMixTableGroup.value.DescriptionFrameRows[j].quantity)+'%20%20%20';
        // this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent('category: ')+encodeURIComponent(this.calculatorMixTableGroup.value.DescriptionFrameRows[j].category)+'%20%20%20';
        // this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent('description: ')+encodeURIComponent(this.calculatorMixTableGroup.value.DescriptionFrameRows[j].description.description)+'%20%20%20';
        // this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent('price list: ')+encodeURIComponent(this.calculatorMixTableGroup.value.DescriptionFrameRows[j].priceList)+'%20%20%20'; 
        // this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent('discount: ')+encodeURIComponent(this.calculatorMixTableGroup.value.DescriptionFrameRows[j].discount)+'%20%20%20';
        // this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent('net price: ')+encodeURIComponent(this.calculatorMixTableGroup.value.DescriptionFrameRows[j].netPrice)+'%20%20%20';
        // this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent('sub total: ')+encodeURIComponent(this.calculatorMixTableGroup.value.DescriptionFrameRows[j].subTotal)+'%20%20%20';

        for ( let y=0; y<7; y++){

          if ( y == 0){ 
            if (this.calculatorMixTableGroup.value.DescriptionFrameRows[j].quantity === null){
              this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent('Q.ty: ')+encodeURIComponent(this.padWordRight('0', 10))+'%20%20%20';
            }
            if (this.calculatorMixTableGroup.value.DescriptionFrameRows[j].quantity !== null){
              const newQtyWord = this.calculatorMixTableGroup.value.DescriptionFrameRows[j].quantity.toString()
            this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent('Q.ty: ')+encodeURIComponent(this.padWordRight(newQtyWord, 10))+'%20%20%20';
            }
          }
          if (y == 1){
            const newCategoryWord = this.calculatorMixTableGroup.value.DescriptionFrameRows[j].category.toString()
            this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent('category: ')+encodeURIComponent(this.padWordRight(newCategoryWord, 25))+'%20%20%20';
          }
          // if (y == 2){
          //   const newDescriptionWord = this.calculatorMixTableGroup.value.DescriptionFrameRows[j].description.description.toString()
          //   this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent('description: ')+encodeURIComponent(this.padDescriptionWordRight(newDescriptionWord, 70))+'%20%20%20';

          // }
          if (y == 2){
            if (this.calculatorMixTableGroup.value.DescriptionFrameRows[j].description.description == undefined ){
              this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent('description: ')+encodeURIComponent(this.padWordRight('select description', 70))+'%20%20%20';
            }
            if (this.calculatorMixTableGroup.value.DescriptionFrameRows[j].description.description !== undefined ){
              const newDescriptionWord = this.calculatorMixTableGroup.value.DescriptionFrameRows[j].description.description.toString()
              this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent('description: ')+encodeURIComponent(this.padWordRight(newDescriptionWord, 70))+'%20%20%20';
  
            }
          }
          if (y == 3){
            if (this.calculatorMixTableGroup.value.DescriptionFrameRows[j].priceList === null ){
              this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent('price list: ')+encodeURIComponent(this.padWordRight('0', 12))+'%20%20%20'; 
            }
            if (this.calculatorMixTableGroup.value.DescriptionFrameRows[j].priceList !== null ){
              const newPLword = this.calculatorMixTableGroup.value.DescriptionFrameRows[j].priceList.toString()
              this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent('price list: ')+encodeURIComponent(this.padWordRight(newPLword, 12))+'%20%20%20'; 
            
            }
           }
          if (y == 4){
            if (this.calculatorMixTableGroup.value.DescriptionFrameRows[j].discount === null ){
                this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent('discount: ')+encodeURIComponent(this.padWordRight('0', 12))+'%20%20%20';
            }
            if (this.calculatorMixTableGroup.value.DescriptionFrameRows[j].discount !== null ){
              const newDiscountWord = this.calculatorMixTableGroup.value.DescriptionFrameRows[j].discount.toString()
              this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent('discount: ')+encodeURIComponent(this.padWordRight(newDiscountWord, 12))+'%20%20%20';
          
            }
            }
          if (y == 5){
            if (this.calculatorMixTableGroup.value.DescriptionFrameRows[j].netPrice === null ){
              this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent('net price: ')+encodeURIComponent(this.padWordRight('0', 12))+'%20%20%20';
            }
            if (this.calculatorMixTableGroup.value.DescriptionFrameRows[j].netPrice !== null ){
              const newNetPriceWord = this.calculatorMixTableGroup.value.DescriptionFrameRows[j].netPrice.toString()
            this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent('net price: ')+encodeURIComponent(this.padWordRight(newNetPriceWord, 12))+'%20%20%20';
            }
          }
          if (y == 6){
            if (this.calculatorMixTableGroup.value.DescriptionFrameRows[j].subTotal === null ){
              this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent('sub total: ')+encodeURIComponent(this.padWordRight('0', 12))+'%20%20%20';
            }
            if (this.calculatorMixTableGroup.value.DescriptionFrameRows[j].subTotal !== null){
              const newSubTotalWord = this.calculatorMixTableGroup.value.DescriptionFrameRows[j].subTotal.toString()
            this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent('sub total: ')+encodeURIComponent(this.padWordRight(newSubTotalWord, 12))+'%20%20%20';
            }
          }

        }

        /**
         * return to new row
         */
        this.mailTextFeatureBodyPart =  this.mailTextFeatureBodyPart+'%0D%0A%0D%0A'
        
    }

    this.mailTextFeatureBodyPart =  this.mailTextFeatureBodyPart+'%0D%0A%0D%0A'

    if ( this.calculatorMixTableGroup.value.total === null){
      this.mailTextFeatureBodyPart =  this.mailTextFeatureBodyPart+encodeURIComponent('TOTAL: ')+encodeURIComponent(this.padWordRight('0', 240 ))
    }
    if ( this.calculatorMixTableGroup.value.total !== null){
      const newTotalWord = this.calculatorMixTableGroup.value.total.toString()
      this.mailTextFeatureBodyPart =  this.mailTextFeatureBodyPart+encodeURIComponent('TOTAL: ')+encodeURIComponent(this.padWordRight(newTotalWord, 240 ))  
    }

    this.onUpdateFeatureResultEvent()

  }

  padWord(word: string, maxLength: number): string {
    if (word.length >= maxLength) {
      return word;
    } else {
      const spacesToAdd = maxLength - word.length;
      const paddedWord = word + ' '.repeat(spacesToAdd);
      return paddedWord;
    }
  }

  padWordRight(word: string, maxLength: number): string {
    if (word.length >= maxLength) {
      return word;
    } else {
      const spacesToAdd = maxLength - word.length;
      const paddedWord = ' '.repeat(spacesToAdd) + word;
      return paddedWord;
    }
  }

  padDescriptionWordRight(word: string, maxLength: number): string {
    if (word.length >= maxLength) {
      return word;
    } else if ( word.length <= 25 ) {
      const spacesToAdd = maxLength - word.length;
      const paddedWord = '  '.repeat(spacesToAdd) + word;
      return paddedWord;
    } else if ( word.length > 25 &&  word.length < maxLength ) {
      const spacesToAdd = maxLength - word.length;
      const paddedWord = ' '.repeat(spacesToAdd) + word;
      return paddedWord;
    }
  }


  onUpdateFeatureResultEvent(){

    if ( this.isTabItem === true){ this.tab_index = this.tab_index }
    if ( this.isTabItem !== true){ this.tab_index = null }

    const newTab_index = this.tab_index

    const newFeatureDrop_Id = this.feature.dropId
    const newFeatureResult: FeatureResult = this.featureResult
    const newMailTextFeatureBodyPart = this.mailTextFeatureBodyPart

    this.updateBothFeatureResultAndTextPlusIndex.emit({ newTab_index , newFeatureDrop_Id, newFeatureResult, newMailTextFeatureBodyPart})

  }

  checkIfMatchId(data){
    this.currentFeature = data ;
    console.log(this.currentFeature)

    if (this.currentFeature.feature_id === this.index_id){
      this.openDialogWordInputEdit()
    }
    
  }

  openDialogWordInputEdit(){

    this.dms.CalculatorMixTableEdit({
      name: this.calculatorMixTable.name,
      details: this.calculatorMixTable.descriptionFrame
    })
 

  }

  ngOnDestroy(){
     this.requestToFinalizeResultAgain.unsubscribe() 
  }



}
