<!-- <p>comment-result works!</p> -->



<div class="row">
    <div class="col-xs-12">

      <br>

        <form >
          
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>comment</mat-label>
              <textarea 
                        matInput
                        disabled >
                        {{commentDetailResultContent}}
              </textarea>
              
            </mat-form-field>
            <!-- <mat-hint align="end">{{commentResult.value.length}} / 5000</mat-hint> -->
          </form>


    </div>
</div>
