import { Component, OnInit, Injectable, Inject, Input } from '@angular/core';
import {  FormBuilder, NgForm } from '@angular/forms';
import { MatDialogRef , MAT_DIALOG_DATA, _closeDialogVia } from '@angular/material/dialog';
import { DialogmanagerService } from '../../../../../../../../../../../services/other-components-services/dialogmanager.service' ;
import { WordLessInput } from '../../word-less-input.model';
import { WordLessDetail } from '../../word-less-detail.model';
import { WordLessInputService } from '../../word-less-input.service';
import { SharedWordHintInputService } from '../../../word-hint/shared-word-hint-input.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { Feature } from '../../../../feature.model';




 @Injectable()
 export class MyService  {

}





@Component({
  selector: 'app-word-less-input',
  templateUrl: './word-less-input.component.html',
  styleUrls: ['./word-less-input.component.css']
})
export class WordLessInputComponent implements OnInit {

  user: string 
  
  @Input() wordLessInput : WordLessInput;
  @Input() details : WordLessDetail[]; 
  @Input() index_id: string;
  @Input() feature: Feature
  index : number ; 

  @Input() noNeeded: boolean ;



  

  constructor(
    private wordLessInputService: WordLessInputService  , 
    private sharedWordLessInputService: SharedWordHintInputService,
    private dms: DialogmanagerService ,
    private _fb: FormBuilder,
    public dialogRef: MatDialogRef<WordLessInputComponent>,
    private userObjectUpdateService: UserObjectUpdateService , 
   @Inject(MAT_DIALOG_DATA) public editData: any) {

 }

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

  this.index = this.wordLessInputService.getWordLessInputs().findIndex(x=> x._id === this.index_id)
  this.wordLessInput = this.wordLessInputService.getWordLessInput(this.index);

  console.log('feature = ', this.feature )
  /**
   * this.wordLessInput = this.sharedWordLessInputService.getWordLessInput(this.index);
   */

 };


 

 // check on lesson 121 to make sure we update data the right way  
 // make sure we double check for the (splice) data copy so that we don't 

 openDialogWordLessEdit(){
   this.dms.WordLessEdit({ 
      index : this.index,
      name: this.wordLessInput.name ,
      DetailsArray : this.wordLessInput.details 

     }).subscribe(res => {

     console.log('the dialog was closed ');
       this.wordLessInput.name = res.name,
       this.wordLessInput.details = res.details
     } )
 }


 


     onCheckStatus(){
       console.log (this.wordLessInput)
     }
     
  //    export class WordDetailResult {

  //     constructor(public wordDetailResultContent: string){}
  // }
 

      onSubmit(form: NgForm){
       console.log (form)

     }
 }


