import { Injectable } from "@angular/core";
import { Contact } from "./contact.model";
import { ContactDetails } from "./contact-details.model";
import { InitialNullSetupService } from "../services/starting-services/initial-null-setup.service";
import { Subscription } from "rxjs";


@Injectable ({
    providedIn: 'root' 
})

export class ContactService {

    constructor( private initialNullSetupService: InitialNullSetupService){}

    contacts: Contact[] = this.initialNullSetupService.initialNullContacts
    
    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.contactsChangedBackToNull
                                                     .subscribe((res)=> {
                                                         this.contacts = res 
                                                         console.log('updated contacts back to null = ', res)
                                                     })


    getContacts(){
        return this.contacts
    }

    getContactDetails(){
        return this.contacts[0].contactDetails
    }

    getSortedContacts(){
        return this.contacts[0].contactDetails.sort((a,b) => { 
            return a.contactEmail.localeCompare(b.contactEmail) } )
        
             
    }

  


    getContact(id: number){
        return this.contacts[0].contactDetails[id]
    }

    addContact(contactDetail: ContactDetails){
        this.contacts[0].contactDetails.push(contactDetail)
    }

    
}