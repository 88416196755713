<!-- <p>form-not-found works!</p> -->

<mat-toolbar color="primary" class="mat-toolbar" >
</mat-toolbar>

<mat-toolbar color="primary" class="mat-toolbar">
<mat-toolbar-row color="primary">


</mat-toolbar-row>
</mat-toolbar>


<mat-sidenav-container >


     <mat-sidenav-content >

        <!-- START CONTENT ------------------------------------------------------- -->
        <div>
            <div class="row">
                <div class="col-xs-12">
        
                    <div class="row">
                        <div class="col-xs-12">
                            <br>
                            <br>
                            <br>
                            <br>
                            <br>
                            <br>
                            <br>
                            <br>
                            <br>
                        </div>
                    </div>
        
                    <div class="row">
                        <div class="col-xs-4"></div>
        
                        <div class="col-xs-4">
                            <div class="row">
                                <h1 style="color: #3f51b5" >
                                   this page was not found
                                </h1>
                            </div>

                            <br>

                            <div class="row">
                                <h1 style="color: #3f51b5" >
                                    <svg style="color: #3f51b5" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
                                      </svg>
                                     re-directing to our homepage 
                                </h1>

                            </div>
                           
                        </div>
                        
                        <div class="col-xs-4"></div>
                    </div>
        
        
                </div>
            </div>
        </div>

          <!-- START CONTENT ------------------------------------------------------- -->



      
     </mat-sidenav-content>  

   
</mat-sidenav-container>
