import { Injectable} from "@angular/core";
import { Observable, Subject } from "rxjs";


@Injectable({
    providedIn: 'root'
})

export class LogoutService {


    //----- start closing login dialog ------------------------------------------------------------

closeLogingDialogPopUp = new Subject<void>()

onClickToCloseLoginPopUpDialog(){
  this.closeLogingDialogPopUp.next();
}
  
getClickToCloseLoginDialogPopUp(): Observable <any> {
  return this.closeLogingDialogPopUp.asObservable ();
}

// ----- stop closing loging dialog -----------------------------------------------------------

continueCreateFormInitializationWithRecipientIndex1 = new Subject<void>()
onContinueInitializeNullUserCreateForm(){
  this.continueCreateFormInitializationWithRecipientIndex1.next()
}



}