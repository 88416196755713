import { Injectable } from "@angular/core";
import { AuthService } from 'src/app/services/auth-services/auth.service';
import { GetComponentsHttpRequestService } from 'src/app/services/gets-services/get-components-http-request.sevice';
import { Subscription, Subject } from "rxjs";
import { Chat } from "../../chats/chat.model";
import { ChatService } from "../../chats/chat.service";



@Injectable ({
    providedIn: 'root' 
})

export class StorePersonalChatRoomComponentsService {

    constructor (){}

httpChatRoomComponentResult: any

chatRoomChanged = new Subject<Chat[]>()



}