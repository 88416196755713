
<br>
<div class="row">
    <div class="col-xs-12">
        <table>
            <tr *ngFor="let checkBox of checkBoxDetailResult.checkBoxes; let i=index ">

                <mat-checkbox
                 disabled
                 [checked]="checkBox.value">
                {{checkBox.item}}
                </mat-checkbox>

            </tr>
        </table>
    </div>
</div>
