import { Component, Input, OnInit, Inject, OnDestroy , HostListener } from '@angular/core';
import { FormResult } from '../../form-result.model';
import { FormResultsService } from '../../form-results.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { Subscription , interval  } from 'rxjs';
import { ErrorsDialogManagerService } from 'src/app/services/other-components-services/errors-dialog-manager.service';
import { DateTime } from 'luxon';
import { TimeAlignService } from 'src/app/services/other-components-services/time-align.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-form-result-item',
  templateUrl: './form-result-item.component.html',
  styleUrls: ['./form-result-item.component.css']
})
export class FormResultItemComponent implements OnInit , OnDestroy {

  user: string 

 
  formResults: FormResult[];
  totalFormResults: number ;
  panelOpenState = false;
 
  @Input() formResultComingFromFormResultList: boolean
  @Input() formResult: FormResult; 


  formResult_id : string
  noGreetings : boolean ;
  seeRecipients: boolean; 

  subjectIsNull: boolean; 
  subjectSubstitute: string = '######'

  isLoading: boolean = false 

  updateOnUserEmail: Subscription 
  timeIntervalSubscription: Subscription 

  isSentToMessage: boolean 
  isSentToMail: boolean 

  isReceived: boolean 



  constructor( private formResultsService: FormResultsService,
               private router: Router,
               private datePipe:DatePipe,
               private userObjectUpdateService: UserObjectUpdateService ,
               private errorsDialogManagerService: ErrorsDialogManagerService , 
               private route: ActivatedRoute ,
               private timeAlignService: TimeAlignService
               ) { }

  
  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
      if (document.hidden){
            console.log("PAGE IS HIDDEN make a property that set to hidden=true and stop all subscriptions " , new Date());
      } else {
            
            console.log(this.formResultComingFromFormResultList, "PAGE IS VISIBLE" , new Date());
            // if (this.formResultComingFromFormResultList == undefined ){
            //   this.route.params.subscribe((params:Params) => this.changeFormResult_id(params)) 
            // }
      }
  }

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
    .subscribe((updatedUserEmail)=>{
     this.user = updatedUserEmail
     console.log('updated USER = ', this.user)
    })

    const localTime = this.timeAlignService.getLocalTime() 
    console.log(localTime)


    this.isLoading = true 

    /**
     * from router on single selected formResult
     */
    console.log( this.formResultComingFromFormResultList )
    if ( this.formResultComingFromFormResultList !== true){
      this.continueWithConfirmedFormResult(this.formResult)
    }

    if (  this.formResultComingFromFormResultList === true){
      this.continueWithConfirmedFormResult(this.formResult)
    }

    /**
     * 
     */
    this.timeIntervalSubscription = interval(180000).subscribe(() => {
      // code to be executed every minute
      if ( this.formResultComingFromFormResultList !== true){
        document.addEventListener("visibilitychange", () => {
          if (document.visibilityState === "visible") { 
            this.visibilitychange()
           // this.route.params.subscribe((params:Params) => this.changeFormResult_id(params)) 
          }
          // else {
          //   backgroundMusic.pause();
          // }
      });
      }
      console.log('Task executed every 5min, 15, 1hr, 1day, 1week, 1 month, 1 year');
      // console.log('Task executed every 24hr relative times , if relative time longer than 24 hours');
      // console.log('Task executed every hour relative times , if relative time shorter than 24 hours');
      // console.log('Task executed every minute updating relative times , if relative time shorter than 1 hour');
    });

  };



  continueWithConfirmedFormResult(formResult: FormResult){

    this.formResult = formResult 

    if ( this.formResult.sender.content === this.user ){ this.isReceived = false  }
    if ( this.formResult.sender.content !== this.user ){ this.isReceived = true  }

    if ( this.formResult.recipientResult.toMessage.length > 0){ this.isSentToMessage = true }
    if ( this.formResult.recipientResult.toMessage.length === 0){ this.isSentToMessage = false }

    if ( this.formResult.recipientResult.toMail.length > 0){ this.isSentToMail = true }
    if ( this.formResult.recipientResult.toMail.length === 0){ this.isSentToMail = false }

    const dateStr = this.formResult.dateAndTime
    const dateFormat = 'MM/dd/yyyy hh:mm:ss a';
    
    console.log(dateStr)
  
    const newDteFormat = new Date(this.formResult.dateAndTime)

    const newDateNow = new Date()
    const newDateNowNow = DateTime.fromJSDate(newDateNow).toISO()
    const newDateNowNowNow = this.datePipe.transform( new Date(), "yyyy:MM:dd'T'HH:mm:ss")
    const newDateJSFormat =  DateTime.fromJSDate(newDteFormat).toISO()

    // this.timeAtSendForm = this.datePipe.transform( new Date(), 'MM/dd/yyyy  h:mm:ss a' )
    
    console.log(newDteFormat , newDateJSFormat ,  newDateNowNow, newDateNowNowNow)

    /**
     * trial set time with -7 hours difference 
     */
    const localTime = new Date(); // Example: current local time
    const newLocalTime = this.timeAlignService.toServerTime(localTime, 7)
    const serverTime: string = this.timeAlignService.toServerTime(localTime, 7).toISO(); // Convert to server time and format as ISO string

    console.log(serverTime , newLocalTime.hour , newLocalTime.minute , newLocalTime.offset/60   )

   

    /**
     * trial on a past date 
     */
    const lastMonthDate = new Date(newDteFormat)
   
  
  
  
             this.getRelativeTime(lastMonthDate)
  



    if ( this.formResult.subject === ''){
      this.subjectIsNull = true
    } else {
      this.subjectIsNull = false 
    }

    if ( this.formResult.recipientResult.greeting.length == 0 ){ this.noGreetings = false } 
    if ( this.formResult.recipientResult.greeting.length >= 1 ){ this.noGreetings = true }

    this.seeRecipients = false ; 

    this.isLoading = false 

    // this.timeIntervalSubscription2 = interval(10000).subscribe(() => {
    //   // code to be executed every minute
    //   this.getRelativeTime(resultDateOnDateFormat)
    //   console.log('Task executed every minute updating relative times');
    // });

}

getRelativeTime(date: Date): string[] {
  const now = DateTime.local();
  const past = DateTime.fromJSDate(date);

  const diffMinutes = Math.floor(now.diff(past, 'minutes').toObject().minutes);
  const diffHours = Math.floor(now.diff(past, 'hours').toObject().hours);
  const diffDays = Math.floor(now.diff(past.startOf('day'), 'days').toObject().days);
  const diffWeeks = Math.floor(now.diff(past.startOf('week').set({ weekday: 1 }), 'weeks').toObject().weeks);
  const diffMonths = Math.floor(now.diff(past.startOf('month'), 'months').toObject().months);
  const diffYears = Math.floor(now.diff(past.startOf('year'), 'years').toObject().years);

  const dayOfWeek = past.weekdayLong;
  const month = past.monthLong;

  let relativeTime: string[] = [];

  if (diffDays === 0 ) {
    relativeTime.push(`today`);
  } else if (diffDays === 1 ) {
      relativeTime.push(`yesterday`);
  } else if (diffDays > 1 ) {
    relativeTime.push(`${diffDays}days ago`);
  }

  relativeTime.push(dayOfWeek);
  relativeTime.push(month);

  relativeTime.push((past.day).toString());
  relativeTime.push((past.year).toString());
  relativeTime.push((past.hour).toString().padStart(2, '0')+':'+(past.minute).toString().padStart(2, '0'))


  if ( diffMinutes === 0 ){ relativeTime.push(`just now`)
  } else if ( diffMinutes === 1 ) {
    relativeTime.push(`a minute ago`)
  } else if ( diffMinutes > 1 && diffMinutes < 5) {
    relativeTime.push(`${diffMinutes}minutes ago`);
  } else if (diffMinutes >=5 && diffMinutes < 20) {
    relativeTime.push(`~ last 20 minutes`);
  
  } else if (diffMinutes >=20 && diffMinutes < 45) {
    relativeTime.push(`~ half an hour ago`);
  } else if (diffMinutes >= 45 && diffMinutes < 75) {
    relativeTime.push(`~ an hour ago`);
  } else if (diffMinutes >= 75 && diffMinutes < 105) {
      relativeTime.push(`~ an hour and half ago`);
  } else if (diffMinutes >= 105 && diffHours < 2) {
        relativeTime.push(`~ 2 hours ago`);
  } else if (diffHours >= 2 && diffHours < 24) {
    relativeTime.push(`${diffHours}hours ago`);
  } 
  
  
  if (diffWeeks === 0) {
    relativeTime.push('This week');
  } else if (diffWeeks === 1) {
    relativeTime.push('Last week');
  } else {
    relativeTime.push(`${diffWeeks} weeks ago`);
  }
  
  if (diffMonths === 0) {
    relativeTime.push('This month');
  } else if (diffMonths === 1) {
    relativeTime.push('Last month');
  } else if (diffMonths > 1 ) { // && diffMonths < 12
    relativeTime.push(`${diffMonths}months ago`);
  } 

  
  if (diffYears === 0) {
    relativeTime.push('This year');
  } else if (diffYears === 1) {
    relativeTime.push('Last year');
  } else {
    relativeTime.push(`${diffYears}years ago`);
  }




  console.log( date ,  relativeTime)
  return relativeTime;
}

  

  onCheckRecipients(){
    this.seeRecipients = !this.seeRecipients ; 
  }


  ngOnDestroy(): void {
      this.updateOnUserEmail.unsubscribe()
      this.timeIntervalSubscription.unsubscribe()
     // this.timeIntervalSubscription2.unsubscribe()
  }

  

}
