import { Injectable } from "@angular/core";
import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from "@angular/common/http";
import { map, tap, catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { Recipient } from './recipient.model';


@Injectable ({
    providedIn: 'root' 
})

export class RecipientHttpRequestService {

    constructor (private http: HttpClient){}

    // httpStoreRecipient(recipient: Recipient){
    //     this.http.post<{recipient: Recipient}>(   // in a normal serve i should add /post/add right after the http address/
    //         'https://drapp-71fc8-default-rtdb.europe-west1.firebasedatabase.app/recipients.json', recipient, 
    //         {
    //           observe: 'events',
    //           responseType: 'json'
    //         }
    //          ).pipe(tap(event=>{
    //            console.log(event)
    //            if (event.type === HttpEventType.Response){
    //              console.log(event.body)
    //            }
    //          }))
    //         .subscribe(response => {
    //             console.log(response)
    //         })
    // }

    httpStoreRecipient( recipient : Recipient){
      this.http.post(
          'http://localhost:8080/market-in-service/rest/component/toni@libero.it/recipient' , recipient ,
      ).subscribe ( response => {
          console.log(response)
      })
  }



    // httpFetchRecipients(){
    //   let searchParams = new HttpParams
    //   searchParams = searchParams.append('print', 'recipients')
    //   searchParams = searchParams.append('second', 'parameterRecipients')
    //    return this.http.get<{ [key: string]: Recipient }>('https://drapp-71fc8-default-rtdb.europe-west1.firebasedatabase.app/recipients.json',
    //    {
    //      headers: new HttpHeaders ({"Custom-Header":"recipients"}),
    //      params: searchParams
    //    }
    //    )
    //     .pipe(map(responseData => {
    //       const trialRecipients: Recipient[] = [];
    //       for (const key in responseData){
    //         if (responseData.hasOwnProperty(key)){
    //           trialRecipients.push({...responseData[key], _id: key})
    //         }
    //       }
    //         return trialRecipients.map(responseData => {
    //         return {...responseData, name: responseData.name? responseData.name: '',
    //                                  chat_id: responseData.chat_id? responseData.chat_id: '',
    //                                  toMail: responseData.toMail ? responseData.toMail: [] ,
    //                                  toMessage: responseData.toMessage? responseData.toMessage: [] ,
    //                                  ccMail: responseData.ccMail? responseData.ccMail: [], 
    //                                  bccMail: responseData.bccMail? responseData.bccMail: [] ,
    //                                  greeting: responseData.greeting? responseData.greeting: '',
    //                                  }
            
    //       })
          /**
           *  constructor (public _id: string, 
                 public owner: string,  
                 public chat_id: string,
                 public id: number , 
                 public allUsersAllowed: boolean,
                 public userListAllowed: string[],
                 public blockedUserList: string[],
                 public recipientIndexId: number ,  
                 public name: string , 
                 public toMessage: string[] , 
                 public toMail: string[], 
                 public ccMail: string[] , 
                 public bccMail: string[], 
                 public greeting: string ) {}
  }
           */
         
         
          
    //     }),
    //     catchError(errorRes => {
    //       // send to analytics
    //       return throwError(errorRes)
    //     }))
    // }






}