import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RangeInfo } from '../range-info.model';

@Component({
  selector: 'app-inner-daily-activities-edit',
  templateUrl: './inner-daily-activities-edit.component.html',
  styleUrls: ['./inner-daily-activities-edit.component.css']
})
export class InnerDailyActivitiesEditComponent implements OnInit {

  innerDailyactivitiesEditFormName: FormGroup

  @Input() rangeInfo: RangeInfo
  @Input() isEditing: boolean

  constructor() { }

  ngOnInit(): void {

    this.innerDailyactivitiesEditFormName = new FormGroup({
      name : new FormControl (this.rangeInfo.name , [ Validators.required, Validators.maxLength(30) ] )
      });
  }

  onSubmitName(){

  }

}
