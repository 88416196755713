<!-- <p>feature-results works!</p>  -->
     

    <div [ngSwitch]="featureResult.featureResultType">
        <app-toggle-word-button-result [toggleWordButtonDetailResults]="featureResult?.featureDetailResults"  *ngSwitchCase="'toggleWordButtonResult'" ></app-toggle-word-button-result>
        <app-word-input-result [wordInputDetailResults]="featureResult?.featureDetailResults" *ngSwitchCase="'wordInputResult'" ></app-word-input-result>
        <app-word-result [wordDetailResult]="featureResult?.featureDetailResults" *ngSwitchCase="'wordResult'" ></app-word-result>
        <app-single-feature-results [singleFeatureDetailResults]="featureResult?.featureDetailResults" *ngSwitchCase="'singleFeatureResult'" ></app-single-feature-results>
        <app-tab-feature-results [tabFeatureDetailResults]="featureResult?.featureDetailResults" *ngSwitchCase="'tabFeatureResult'" ></app-tab-feature-results>
        <app-fix-layout-results [fixLayoutDetailResult]="featureResult?.featureDetailResults" *ngSwitchCase="'fixLayoutFeatureResult'" ></app-fix-layout-results>
        <app-fix-text-result [fixTextDetailResults]="featureResult?.featureDetailResults" *ngSwitchCase="'fixTextResult'"></app-fix-text-result>
        <app-one-date-calendar-result [oneDateCalendarDetailResults]="featureResult?.featureDetailResults" *ngSwitchCase="'oneDateCalendarDetailResult'"></app-one-date-calendar-result>
        <app-multi-entry-calendar-result [multiEntryCalendarDetailResult]="featureResult?.featureDetailResults" *ngSwitchCase="'multiEntryCalendarDetailResult'"></app-multi-entry-calendar-result>
        <app-calculator-mix-table-result [calculatorMixTableDetailResult]="featureResult?.featureDetailResults" *ngSwitchCase="'calculatorMixTableDetailResult'"></app-calculator-mix-table-result>
        <app-drop-down-select-result [dropDownSelectDetailResults]="featureResult?.featureDetailResults" *ngSwitchCase="'dropDownSelectDetailResult'"></app-drop-down-select-result>
        <app-check-box-result [checkBoxDetailResult]="featureResult?.featureDetailResults" *ngSwitchCase="'checkBoxDetailResult'"></app-check-box-result>
        <app-comment-result [commentDetailResult]="featureResult?.featureDetailResults" *ngSwitchCase="'commentResult'"></app-comment-result>
        <app-shared-calendar-status-result [featureResultName]="featureResult?.featureResultName" [sharedCalendarStatusResult]="featureResult?.featureDetailResults" *ngSwitchCase="'sharedCalendarStatusResult'" ></app-shared-calendar-status-result>
    </div>
    




