import { Component, Input, OnInit , Output, EventEmitter, OnDestroy} from '@angular/core';
import { ToggleSingleWordButton } from '../../toggle-single-word-button.model';
import { ToggleSingleWordButtonDetail } from '../../toggle-single-word-button-detail.model';

import { ToggleSingleWordButtonService } from '../../toggle-single-word-button.service';
import { SingleToggleWordButtonDetailResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/single-feature-results/single-toggle-word-button-result/single-toggle-word-button-detail-result.model';
import { SingleFeatureDetailResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/single-feature-results/single-feature-detail-result.model';
import { Observable, Subscription } from 'rxjs';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { Feature } from '../../../../feature.model';
import { FormService } from 'src/app/forms/form.service';

@Component({
  selector: 'app-toggle-single-word-button-item',
  templateUrl: './toggle-single-word-button-item.component.html',
  styleUrls: ['./toggle-single-word-button-item.component.css']
})
export class ToggleSingleWordButtonItemComponent implements OnInit {

  user: string

  toggleSingleWordButton: ToggleSingleWordButton ;
  toggleSingleWordButtons: ToggleSingleWordButton[];
  toggleSingleWordButtonDetail: ToggleSingleWordButtonDetail;
  toggleSingleWordButtonDetails: ToggleSingleWordButtonDetail[];

  /** index of the toggle button result */
  toggleIndex: number = 0 ;
  /**result to show and transfer on submit */
  toggleButtonResult : string ;

  @Input() index_id: string;
  index: number ;
  @Input() noNeeded: boolean
  @Input() weAreInMultiFeatureEdit: boolean;
  @Input() feature: Feature

  /** start building the results
   */

   @Input() isParagraphEdit: boolean ;
   @Output() deleteSingleFeatureResultEvent = new EventEmitter<void>()

  //  singleFeatureDetailResultSubscription: Subscription;
  //  @Input() subscribeSingleFeatureResult: Observable<void>


   /**
    * build emailText comonents
    */
   mailTextSingleFeatureBodyPart: string ;

   @Output() updateBothSingleFeatureResultAndTextPlusIndex = new EventEmitter<any>()
   requestToFinalizeResultAgain: Subscription 

   updateOnUserEmail: Subscription 


  constructor( private toggleSingleWordButtonService: ToggleSingleWordButtonService ,
               private userObjectUpdateService: UserObjectUpdateService , 
               private formService: FormService) { }

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
    })

    this.index = this.toggleSingleWordButtonService.getToggleSingleWordButtons().findIndex(x=> x._id === this.index_id);
    this.toggleSingleWordButton = this.toggleSingleWordButtonService.getToggleSingleWordButton(this.index);

    console.log('feature = ', this.feature, this.toggleSingleWordButton)
    this.toggleButtonResult = this.toggleSingleWordButton.toggleSingleWordDetails[this.toggleIndex].toggleDetail
  

    /**
     * advise result status at start
     */
      this.requestToFinalizeResultAgain = this.formService.getRequestToFinalizeResultAgain().subscribe(()=> this.onSend())
      setTimeout(()=>{
        this.onSend()
      },200)

   
  }

  /** toggle button till details lenght , or we fix max number */
  onToggleButton(){
    
    this.toggleIndex = this.toggleIndex+1 ;
    if (  this.toggleIndex === this.toggleSingleWordButton.toggleSingleWordDetails.length){
      this.toggleIndex = 0
    }
    
    console.log( this.toggleIndex)
  
    this.toggleButtonResult = this.toggleSingleWordButton.toggleSingleWordDetails[this.toggleIndex].toggleDetail   
  
    const newSingleFeatureDetailResultType = 'singleToggleWordButtonResult';
    const newSingleFeatureDetailResultName = this.toggleSingleWordButton.name;
  
    const newSingleToggleWordButtonDetailResult = new SingleToggleWordButtonDetailResult ( this.toggleButtonResult)
    const newSingleFeatureDetailResultResult = newSingleToggleWordButtonDetailResult;
    const newSingleFeatureDetailResult = new SingleFeatureDetailResult ( newSingleFeatureDetailResultType, newSingleFeatureDetailResultName, newSingleFeatureDetailResultResult)
  
    console.log(newSingleFeatureDetailResultResult)
  
    this.mailTextSingleFeatureBodyPart = this.toggleButtonResult
  
    if( this.weAreInMultiFeatureEdit === false){
      const newFeatureDrop_Id = this.feature.dropId;
      const newMailTextSingleFeatureBodyPart: string = this.mailTextSingleFeatureBodyPart;
      
      this.updateBothSingleFeatureResultAndTextPlusIndex.emit({ newFeatureDrop_Id, newSingleFeatureDetailResult, newMailTextSingleFeatureBodyPart   })
     

    }
    }

 

 onSubmit(){

  const newSingleFeatureDetailResultType = 'singleToggleWordButtonResult';
  const newSingleFeatureDetailResultName = this.toggleSingleWordButton.name;

  const newSingleToggleWordButtonDetailResult = new SingleToggleWordButtonDetailResult ( this.toggleButtonResult)

  const newSingleFeatureDetailResultResult = newSingleToggleWordButtonDetailResult;

  const newSingleFeatureDetailResult = new SingleFeatureDetailResult ( newSingleFeatureDetailResultType, newSingleFeatureDetailResultName, newSingleFeatureDetailResultResult)

  console.log(newSingleFeatureDetailResultResult)

  this.mailTextSingleFeatureBodyPart = this.toggleButtonResult

  if( this.weAreInMultiFeatureEdit === false){

       const newFeatureDrop_Id = this.feature.dropId
       const newMailTextSingleFeatureBodyPart: string = this.mailTextSingleFeatureBodyPart
  
       this.updateBothSingleFeatureResultAndTextPlusIndex.emit({ newFeatureDrop_Id, newSingleFeatureDetailResult, newMailTextSingleFeatureBodyPart   })

  }
  
 }

 onSend(){
   this.onSubmit() ;

 }

 onDeleteSingleWordButton(){
   this.deleteSingleFeatureResultEvent.emit()

 }

 ngOnDestroy(){
   this.requestToFinalizeResultAgain.unsubscribe()
   this.updateOnUserEmail.unsubscribe()
}




}
