import { Component, OnInit , Input, EventEmitter} from '@angular/core';
import { ParagraphService } from '../../../../../form-item/paragraph/paragraph.service';
import { Paragraph } from '../../../../../form-item/paragraph/paragraph.model';
import { ParagraphTopicService } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';





@Component({
  selector: 'app-paragraph-index',
  templateUrl: './paragraph-index.component.html',
  styleUrls: ['./paragraph-index.component.css']
})
export class ParagraphIndexComponent implements OnInit {

  user: string 

  paragraph: Paragraph ;
  

  /** hideTopic is connected to the topic of the paragraph and will not show the paragraphs not in the same topic */
  @Input() hideTopic : string ; //?
  
  /**serve to define the topic name once we add a paragraph */
  @Input() paragraph_id : string ;
  @Input() isClicked: boolean 




  

  constructor(private paragraphTopicService: ParagraphTopicService ,
              private userObjectUpdateService: UserObjectUpdateService ,
              private paragraphService: ParagraphService
              ) { }



         
  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

    console.log(this.paragraph_id)

   this.paragraph = this.paragraphService.getParagraphBy_id(this.paragraph_id) 
   console.log(this.paragraph)

  
    
  }


}
