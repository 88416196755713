

          <div class="row" >
            <div class="col-xs-12">

          
                <br>
                  <table cellspacing="0">
                    <tr>

                      
                           <div class="form-group">
                                 <td *ngFor="let detail of singleDateCalendar.details; let i=index"> 
                                     <div class="col-xs-12">

                                    <div class="row">
                                        <mat-form-field class="example-full-width" appearance="fill">
                                            <mat-label>{{detail.description}}</mat-label>
                                            <input matInput 
                                                   [matDatepicker]="picker"
                                                   (dateInput)="updateDate( i , $event)" >
                                            <mat-hint>MM/DD/YYYY</mat-hint>
                                            <mat-datepicker-toggle matSuffix [for]="picker">
                                              <!-- <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon> -->
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                   
                                </div>


                                   
                                </td> 
                           </div>  

                        
                    </tr>
                 </table>
             

            </div>
          </div>
          