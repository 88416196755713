import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef , MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DateTime } from 'luxon';
import { Subject, Observable,  Observer, fromEvent, merge, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { Recipient } from 'src/app/forms/form-list/form-item/recipient/recipient.model';
import { Paragraph } from 'src/app/forms/form-list/form-item/paragraph/paragraph.model';
import { ParagraphResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/paragraph-result.model';
import { FormResult } from 'src/app/forms/form-results/form-result.model';
import { Sender } from 'src/app/forms/form-results/form-results-list/form-result-item/sender/sender.model';
import { Receiver } from 'src/app/forms/form-results/form-results-list/form-result-item/receiver/receiver.model';
import { Chat } from 'src/app/chats/chat.model';
import { ChatService } from 'src/app/chats/chat.service';
import { FormResultsService } from 'src/app/forms/form-results/form-results.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { ParagraphFeature } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature.model';



@Component({
  selector: 'app-chat-fill-form',
  templateUrl: './chat-fill-form.component.html',
  styleUrls: ['./chat-fill-form.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ChatFillFormComponent implements OnInit {

    /**
   *  package properties for online / offline detection and mode
   */
     onlineOffline: boolean = navigator.onLine
     // ------------------------------------------------------------
   
  user: string;

  subjectList: string[];

  topicName: string;
  paragraphName: string;
 
  recipient: Recipient; 
  recipientResult: Recipient;
  chat: Chat; 
  senderChatPartecipants : string[];
  receiverChatPartecipants: string[]

  paragraph: Paragraph ; 

  paragraphFeatures : ParagraphFeature[];
  paragraphFeature : ParagraphFeature ;

  paragraphResult: ParagraphResult;
  paragraphResults: ParagraphResult[];
  sentParagraphResult: ParagraphResult;
  sentParagraphResults: ParagraphResult[];
  receivedParagraphResult: ParagraphResult;
  receivedParagraphResults: ParagraphResult[];

  finalSentParagraphResult: ParagraphResult;
 finalSentParagraphResults: ParagraphResult[] = [];

 finalReceivedParagraphResult: ParagraphResult;
 finalReceivedParagraphResults: ParagraphResult[] = [];

 finalSentFormResult: FormResult;
 finaleReceivedFormResult: FormResult; 

  timeAtSendForm: string;
  subscribeClickEvent: Subject<void> = new Subject<void>();
  subscribeFormNewSubject: Subject<void> = new Subject<void>()


   chatReceiver : Receiver ; 
   sentToMessageList: string[];

   receiver: Receiver
   isForwardToMail: boolean 
   isSentToMessage: boolean 

  // new properties to check which chats has the same subject 
 
   /**
    * possible chat numbers 
    */
    futureFormResultChat_id : string 
    futureFormResultChatId : number 
 
    chatExist : boolean 
    chatExistOnIndex: number 
    chatHasToBeCreated: boolean

    /**
     * properties to use button and select the right subject 
     */
    selectedSubject: string
    count: number

    finalSubject: string 

    // only 1 subject in the chat 

    only1Subject: boolean ;
    addSubject: boolean ; 
    giveSubjectButtonOption: boolean 

    messageHasBeenSent: boolean

    /**
     * subscription to is online or not
     */
     subscriptionToOnlineOffline: Subscription 

     updateOnUserEmail: Subscription 
   

  constructor( public  dialogRef: MatDialogRef<ChatFillFormComponent>,
               private formResultsService: FormResultsService,
               private chatService: ChatService,
               private userObjectUpdateService: UserObjectUpdateService ,
               private postComponentsHttpRequestService: PostComponentsHttpRequestService ,
               @Inject(MAT_DIALOG_DATA) public editData: any) {}





  ngOnInit(){

 
    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
        .subscribe((updatedUserEmail)=>{
         this.user = updatedUserEmail
        console.log('updated USER = ', this.user)
    })

/**
 * package online / offline property -------------------------------------------------
 */
  console.log('this device is online : ',this.onlineOffline)
  this.subscriptionToOnlineOffline = this.createOnline$().subscribe(isOnline => console.log( this.onlineOffline = isOnline,
    'this device is now online: ', this.onlineOffline, DateTime.now().toString() ));
// ------------------------------------------------------------------------------------
 

     /**
   * **********************************************************************************************
   */

     this.messageHasBeenSent = false 

    if ( this.editData.subjectList === undefined ){ this.giveSubjectButtonOption = false , this.addSubject = true }
    if ( this.editData.subjectList !== undefined ) { 
                 this.subjectList = this.editData.subjectList; 
                 console.log(this.subjectList)

                 if (this.subjectList.length === 1){ this.only1Subject = true} else { 
                  this.only1Subject = false 
                }

                 this.addSubject = false
                 this.giveSubjectButtonOption = true


                this.count = this.subjectList.length-1
                console.log(this.selectedSubject)

                this.selectedSubject = this.subjectList[this.count]
    }
     

    // if (this.subjectList.length === 1){ this.only1Subject = true} else { 
    //   this.only1Subject = false 
    // }

    // this.addSubject = false
    // this.giveSubjectButtonOption = true


    // this.count = this.subjectList.length-1
    //  console.log(this.selectedSubject)

    //  this.selectedSubject = this.subjectList[this.count]

  if (this.selectedSubject === undefined){
    this.giveSubjectButtonOption = false
    this.addSubject = true
  }
  /**
   * **********************************************************************************************
   */

    this.topicName = this.editData.paragraphTopicName;
    // this.chat = this.editData.dialogChat;
    this.recipient = this.editData.recipient

  

    this.paragraphName = this.editData.paragraph.name;
    this.paragraph = this.editData.paragraph;

    this.sentParagraphResults = [] ;
    this.receivedParagraphResults = [];
  
    this.senderChatPartecipants = [];
    this.receiverChatPartecipants = [];

    const newParagraphFeature_id = 0
    const newOwner = this.paragraph.owner
    const newDateAndTime = this.paragraph.dateAndTime
    const newIsAccepted = true ;
    const newAllUsersAllowed = this.paragraph.allUsersAllowed
    const newUserListAllowed = this.paragraph.userListAllowed
    let isBeenShared: boolean 
    if ( newAllUsersAllowed === true || newUserListAllowed.length > 0 ){ isBeenShared = true }
    if ( newAllUsersAllowed === false && newUserListAllowed.length === 0 ){ isBeenShared = false }
    const newIsBeenShared = isBeenShared
    const newCanBeReshared = this.paragraph.canBeResharedByOthers
    const newType = 'paragraphFeature'
    const newCounterId = 0 ;
    const newParagraphFeatureDropId = 0 ;
    const newParagraph_id = this.paragraph._id
    const newParagraphFeature = new ParagraphFeature ( newParagraphFeature_id, newOwner, newDateAndTime, 
                                                       newIsAccepted, newAllUsersAllowed, newUserListAllowed, 
                                                       newIsBeenShared , newCanBeReshared, newType,  newCounterId, 
                                                       newParagraphFeatureDropId , newParagraph_id )
    this.paragraphFeature = newParagraphFeature 

    this.paragraphFeatures = [this.paragraphFeature]

    /**
 *  RECREATE ! and re-compile all FEATURE RESULTS!  create initial dummy paragraphResults --------------------------------------------------
 */
 this.paragraphResults = []
 const newParagraphResultName = 'prova'
 const newParagraphResult = new ParagraphResult (  newParagraphResultName, [] ) ;

 const newParagraphResults: ParagraphResult[] = []
 //const newMailTextBodyPart = ''
 //this.mailTextBody = []
 for ( let z=0; z<this.paragraphFeatures.length; z++){
   newParagraphResults.push(newParagraphResult)
  // this.mailTextBody.push(newMailTextBodyPart)
 }
 this.paragraphResults = newParagraphResults


// console.log(this.chat)
/**
 * this chat receiver is to match chat partecipants.partecipants to form receiver 
 */
        const newSentRecipientGroup = '';
        const newSentRecipientName = this.recipient.name ;
        const newSentToMessage = this.recipient.toMessage;
        // const newSentToMessage = this.chatService.getChat(0).partecipantGroup.partecipants;
        const newSentToMail =  [];
        const newSentCcMail = [];
        const newSentBccMail = [];
        const newSentGreetings = ''
        const newChatReceiver = new Receiver ( newSentRecipientGroup, newSentRecipientName, newSentToMessage, newSentToMail, newSentCcMail, newSentBccMail, newSentGreetings)

     
        this.receiver = newChatReceiver ; 

    

    console.log('dialog is created')
    // console.log(this.chat)
    console.log(this.paragraph)
  };

   /**
 * package online / offline property -------------------------------------------------
 */

    createOnline$() {
      return merge<boolean>(
        fromEvent(window, 'offline').pipe(map(() => false)),
        fromEvent(window, 'online').pipe(map(() => true)),
        new Observable((sub: Observer<boolean>) => {
          sub.next(navigator.onLine);
          sub.complete();
        }));
    };
    
    // -----------------------------------------------------------------------------------
    


  /**
   * change subject with button click 
   *  this could change to 2 ways button if there are more than 5-6 subjects 
   * could turn into dropdown if there are more than 10 subjects 
   */
   onClickSubjectButton(){
      this.count = this.count-1
      if (this.count === -1){
        this.count = this.subjectList.length-1
      }
      this.selectedSubject = this.subjectList[this.count]
   }

   /**
    * adding subject by clicking the + button
    */
   onAddSubject(){
     this.addSubject = !this.addSubject
   }



  /**
 * start the subscriptions to on sending the form to receive the results
 */
  onSendForm(){

    this.messageHasBeenSent = true 

    // this.futureFormResultChat_id = this.chat.recipient.chatRoomId

    // console.log(this.futureFormResultChat_id)

    const new_recipient_id = this.recipient._id
    const newComponentName = 'recipient'
    const newUIID = this.recipient.UIID
    const newChat_roomid = this.recipient.chatRoomId
    const newOwner = this.recipient.owner
    const newDateAndTime1 = this.recipient.dateAndTime
    const newUpdateDateAndTime1 = this.recipient.updateDateAndTime
    const newAllUsersAllowed = this.recipient.allUsersAllowed
    const newUserListAllowed = this.recipient.userListAllowed
    const newBlockedUserList = this.recipient.blockedUserList
    const newName = this.recipient.name
    const newRecipientList = this.recipient.recipientList
    const newToMessage = this.recipient.toMessage
    const newToMail = [];
    const newCcmail = [];
    const newBccMail = [];
    const newGreeting = '' ;
    
    const newRecipientResultForMessage = new Recipient ( new_recipient_id, newComponentName, newUIID, newOwner, newDateAndTime1 , newUpdateDateAndTime1, newChat_roomid,
                                         newAllUsersAllowed, newUserListAllowed, newBlockedUserList,
                                         newName, newRecipientList , newToMessage, 
                                         newToMail, newCcmail, newBccMail , newGreeting);

    this.recipientResult = newRecipientResultForMessage

    this.timeAtSendForm = DateTime.now().toString();

    if (this.addSubject === false ) { this.finalSubject = this.selectedSubject} ;
    if (this.addSubject === true) { this.subscribeFormNewSubject.next() }

    const new_id = (this.formResultsService.getFormResults().length+1).toString() ;
    const newChatRoomId =  this.recipientResult.chatRoomId
    const newIsActive = true ; 
    const newIsDeleted = false ;
    const newIsSelected = false ;
    const newIsRead = true ;
    const newIsShareble = true ;
    const newIsACopyOfAMessage = false 
    const newIsForwardToMail = true //  this.isForwardToMail ;
    const newIsForwarded = false ;
    const newSender = new Sender ( this.user) ;
    const newSenderGroup = '' ;
    const newSenderType = '' ;
    const newReceiverGroup= '' // this.recipientIndexListItem.recipientIndexListName ;
    const newReceiverType = 'private' ;
    const newUserChatRoomId = this.recipient._id
    const newRecipientResult = this.recipientResult
    const newDateAndTime = this.timeAtSendForm ; 
    const newSubject = this.finalSubject ;
    const newParagraphResults = this.paragraphResults 
    const newSeq = null 
    const newUpdateDateAndTime = null 
    const newHasFollowingParagraphItemToShow = null 
  
  
    const newSentFormResult = new FormResult (  new_id,
                                               // newChatRoomId ,
                                                newIsActive ,
                                                newIsDeleted,
                                                newIsSelected,
                                                newIsRead,
                                                newIsShareble,
                                                newIsACopyOfAMessage ,
                                                newIsForwardToMail,
                                                newIsForwarded,
                                             
                                                newSender,
                                                newSenderGroup,
                                                newSenderType,
                                                newReceiverGroup,
                                                newReceiverType,
                                                newUserChatRoomId,
                                                newRecipientResult,
                                                newDateAndTime,
                                                newSubject,
                                                newParagraphResults ,
                                                newSeq , 
                                                newUpdateDateAndTime, 
                                                newHasFollowingParagraphItemToShow
                                                ) ;
  
  
  this.finalSentFormResult = newSentFormResult
  
  /**
   * send formResult to httpPostComponent if user !== null 
   */
  
  if ( this.user !== null ){
  
    /**
     * if send then we send the text composition after we are sure the formResult has been stored 
     * if any error we ask if we still want to send by email anyway yes or no , and deal with it 
     * with additional steps
     */
     if ( this.finalSentFormResult.recipientResult.toMessage.length > 0 ){
    this.postComponentsHttpRequestService.httpPostFormResult( this.user , this.finalSentFormResult )
        .subscribe((result: any )=> {
        console.log( result )

        const newFormResult = result.formResult
        const newUserReceiveFormResultId = result.userReceiveFormResultId
        const newWrongRecipientUserList = result.wrongRecipientUserList


        this.formResultsService.addFormResult(newFormResult)
        //console.log(this.finalSentFormResult.receiver.sentToMessage , 
        //  newFormResult, newUserReceiveFormResultId, newWrongRecipientUserList  )

        
      } , error => {
        console.log(error )

        /**
         * show error and ask to check connection 
         * showerror+ do user still want to send email composition anyway without saving 
         */

       

      }
      
      )
  }

  }
  
  if ( this.user === null ){
  
    this.formResultsService.addFormResult(this.finalSentFormResult)
     console.log(this.finalSentFormResult)
    //  console.log(this.finalSentFormResult.receiver.sentToMessage)

    console.log( 'this dialog is closing')
       this.dialogRef.close();
      
    }
  
  // line 1067 - 1335  to fix on formEdit
  // const startingEditForm: Form = new Form ( null , '', '', false, [], false, '', this.recipientIndexService.getRecipientIndex(0)._id , [], [] )
  // this.formService.onUpdateLatestShownForm(startingEditForm) 
    
  // this.dialogRef.close();
};


/**
 * 
 * @param event receiving the new Subject from newSubjectEditForm
 * we also need to update the chatFormList about the new Subject so that it can update the paragraphs
 */
  onUpdateFromSubject(event){
    this.finalSubject = event
    if (this.finalSubject === ''){ this.finalSubject = '--- --- ---'}
    console.log(this.finalSubject)
  }


onUpdateBothParagraphResultTextResultAndIndex($event){
  this.paragraphResults[$event.newParagraphFeatureDropId] = $event.newParagraphResult
  console.log(this.paragraphResults)
}


   /**
   * now if this chat exist we don't create the new CHat but we add the form only and 
   * we make the property hasNewForm = true
   * 
   * if the chat doesn't exist then we produce a new chat on the list 
   */

  // we use a *ngFor to make it for each chatExist = true , but supposed 
  // to be ONLY ONE number
  // updateChatIndex(){

  //   this.chatService.sendUpdateToChatIndexList()

  //   /**
  //    *  constructor (public _id: string ,
  //                public owner: string ,
  //                public isVisible: boolean, 
  //                public dateAndTime: string , 
  //                public recipient: Recipient, 
  //                public partecipantGroup: PartecipantGroup , 
  //                public isNewChat: boolean, 
  //                public isBeenUpdated: boolean,
  //                public isSelected: boolean, 
  //                public subject: string ){}
  //    */
  //   for (let i=0; i<this.chatService.getChats().length; i++){
  //     this.chatService.getChat(i).isSelected = false
  //   }

  //   const new_id = this.chat._id
  //   const newOwner = this.chat.owner
  //   const newAllUsersAllowed = this.chat.allUsersAllowed
  //   const newUserListAllowed = this.chat.userListAllowed
  //   const newIsVisible = true 
  //   const newDateAndTime = this.timeAtSendForm 
  //   const newUpdateDateAndTime = this.chat.updateDateAndTime
  //   const newIsNewChat = false
  //   const newIsBeenUpdated = true 
  //   const newIsSelected = true
  //   const newSubject = this.finalSubject

  //   const newChat = new Chat ( new_id, newOwner, newAllUsersAllowed, newUserListAllowed, newIsVisible, newDateAndTime,
  //                              newUpdateDateAndTime,newIsNewChat, newIsBeenUpdated, newIsSelected, newSubject)

  //   this.chatService.chats[this.chatService.getChats().findIndex(x=> x._id === newChat._id)] = newChat

  //   this.chatService.sendUpdateToChatIndexList()

  //   console.log(this.chatService.getChats())

  
  // }


ngOnDestroy(): void {
  this.subscriptionToOnlineOffline.unsubscribe()
  this.updateOnUserEmail.unsubscribe()
}


}
