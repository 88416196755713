import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-falling-object',
  templateUrl: './falling-object.component.html',
  styleUrls: ['./falling-object.component.css']
})
export class FallingObjectComponent implements OnInit {

  position = {
    x: 0,
    y: 0
  };

  // Set the speed of the animation in pixels per second
  speed = 50;

  // Set the width and height of the object in pixels
  objectWidth = 50;
  objectHeight = 50;

  constructor() { }

  ngOnInit(): void {

    const distance = Math.sqrt(window.innerWidth ** 2 + window.innerHeight ** 2);

    // Calculate the time it will take the object to travel the distance at the given speed
    const time = distance / this.speed * 1000;

    // Animate the object using CSS transitions
    const objectElement = document.getElementById('falling-object');
    objectElement.style.transition = `transform ${time}ms linear`;
    objectElement.style.transform = `translate(${window.innerWidth - this.objectWidth}px, ${window.innerHeight - this.objectHeight}px)`;
  }
  }

