<div *ngIf="!isLoading" class="example-container">

  <!-- <div *ngIf="!formIndexTopic.isAccepted"
  (click)="onAcceptFormIndexTopic()"
  class="example-box pointer">
  <a class="navbar-brand" style="color: red;"  > {{formIndexTopic?.name}} </a>

 </div> -->
  <div *ngIf="!formIndexTopic.isAccepted"
       matTooltip="CLICK TO ACCEPT / DECLINE SHARED PAGE FROM THIS USER"
       matTooltipPosition="right"
       class="example-box"
  >

  <button *ngIf="isHandset$ | async"
  class="btn btn-primary" 
  type="button"
  matBadge="1" matBadgeColor="accent"
  (click)="onAcceptFormIndexTopic()"> 
  click / {{formIndexTopic?.name}}
  </button>

  <button *ngIf="!(isHandset$ | async)"
  class="btn btn-primary" 
  type="button"
  matBadge="1" matBadgeColor="accent"
  (click)="onAcceptFormIndexTopic()"> 
  {{formIndexTopic?.name}}
  </button>
 

  </div>

   
  <div *ngIf="formIndexTopic.isAccepted && !formIndexTopic.isArchived "
           (click)="onHideForms()" 
           class="example-box pointer" 
           (mouseenter) = "onMouseEnter()"  
           (mouseleave) = "onMouseLeave()" >


        <a class="navbar-brand" 
           style="color: #3f51b5;"
           matBadge="{{newFormIndexes}}" 
          [matBadgeHidden]="newFormIndexes == 0"
           matBadgeColor="accent" > 
           {{formIndexTopic.name}} 
        </a>
        <span *ngIf="hideForms">
          <svg style="color: #3f51b5;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
          </svg>
        </span>
        <span *ngIf="!hideForms">
          <svg  xmlns="http://www.w3.org/2000/svg" style="color: #3f51b5;" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
          </svg>
        </span>
      </div>

      <div  *ngIf="!hideForms">
        <div *ngFor="let formFeatureContainer_id of formIndexTopic.formFeatureContainer_ids ; let i = index " >
               
              <app-form-feature-container
                             [typeOfFormIndexTopic]="typeOfFormIndexTopic"
                             [formIndexTopic]="formIndexTopic"
                             [index]="i"
                             [formFeatureContainer_id]="formFeatureContainer_id"
                             (adviseFormListOnLoadingForm)="onAdviseFormListOnLoadingForm()">
              </app-form-feature-container>
        </div>
    
        
      </div>


    </div>
