<p hidden >word-less-input works!</p>

<div class="row" >
    <div class="col-xs-12">

       <form (ngSubmit)="onSubmit(f)" #f="ngForm">
          <div ngModelGroup="wordsLessInput"> 

        <!-- <div *ngFor="let word of wordInput" > -->
          
          <div class="row">
            <div class="col-xs-12">
              <div class="form-group">
               
                  <h2 hidden >  {{ wordLessInput.name }}  </h2>
              
                  <!-- https://icons.getbootstrap.com/icons/layout-wtf/ -->
                  <div [hidden]= "noNeeded" class="row">
                    <div class="col-xs-4"></div>
                    <div class="col-xs-7"></div>
                    <div class="col-xs-1">
                 
                  <svg (click)="openDialogWordLessEdit()"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-layout-wtf" viewBox="0 0 16 16">
                    <path d="M5 1v8H1V1h4zM1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1zm13 2v5H9V2h5zM9 1a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9zM5 13v2H3v-2h2zm-2-1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H3zm12-1v2H9v-2h6zm-6-1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H9z"/>
                  </svg>
                  </div>
                  </div>
              <!-- <button [hidden]="noNeeded" class="btn btn-link" (click)="openDialogWordLessEdit()" > edit </button> -->
              <!-- <button hidden class="btn btn-danger" (click)="onCheckStatus()"> submit  </button> -->
              <div hidden>
                 <button class="btn btn-danger" (click)="onCheckStatus()"> submit  </button>
              </div>
              </div>
            </div>
          </div>

          <div class="row" >
            <div class="col-xs-12">

              <div ngModelGroup="Detail"> 
                  <table class="full-width" cellspacing="0">
                    <tr>
                       <!-- <br> -->

                           <div class="form-group">
                                 <td *ngFor="let detail of wordLessInput.details; let i=index"> 
                                   <div [ngModelGroup]="i" #details="ngModelGroup">
                                       <mat-form-field class="full-width">
                                       <mat-label [(innerText)]="detail.label"></mat-label> 
                                       <input 
                                       matInput 
                                       ngModel
                                       name="content" 
                                
                                       [placeholder]="detail.placeholder">

                                
                                       </mat-form-field>
                                   </div>
                                </td> 
                           </div>  

                    </tr>
                 </table>
                </div>

            </div>
          </div>
          
         </div>
       </form>
    </div>
</div>


 
