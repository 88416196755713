import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inline-multi-select',
  templateUrl: './inline-multi-select.component.html',
  styleUrls: ['./inline-multi-select.component.css']
})
export class InlineMultiSelectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
