import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "src/app/app-routing.module";
import { MaterialDesignModule } from "src/app/material-design/material-design.module";
import { FeaturesComponent } from "../features.component";
import { MultiFeaturesEditComponent } from "./multi-features-edit/multi-features-edit.component";
import { MultiFeaturesListComponent } from "./multi-features-list.component";
import { MultiFeaturesComponent } from "./multi-features/multi-features.component";
import { PipesModule } from "src/app/pipes/pipes.module";

import { InputModule } from "../input/input.module";
import { ButtonsModule } from "../buttons/buttons.module";
import { SharedModule } from "src/app/shared/shared.module";
import { SelectModule } from "../select/select.module";
import { SliderModule } from "../slider/slider.module";
import { AuxiliaryComponentsModule } from "src/app/loading-spinner/auxiliary-components.module";
import { MatTooltipModule } from '@angular/material/tooltip';




@NgModule({
    declarations: [
    MultiFeaturesComponent,
    MultiFeaturesEditComponent,
    MultiFeaturesListComponent,
    ],

    exports: [
        MultiFeaturesComponent,
        MultiFeaturesEditComponent,
        MultiFeaturesListComponent,
    ],

    imports: [
    
        AppRoutingModule,
        CommonModule,
        FormsModule,
        BrowserAnimationsModule,
        MaterialDesignModule, 
        ReactiveFormsModule,
        MatDialogModule,
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatExpansionModule,
        InputModule,
        ButtonsModule,
        PipesModule,
        SharedModule,
        SelectModule,
        SliderModule,
        AuxiliaryComponentsModule,
        MatTooltipModule
       



    ]


})
export class MultiFeaturesModule{}