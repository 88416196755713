<!-- from https://material.angular.io/components/chips/examples  -->

<mat-form-field class="example-chip-list">
    <mat-label>Favorite Fruits</mat-label>
    <mat-chip-list #chipList aria-label="Fruit selection">
      <mat-chip *ngFor="let fruit of fruits" [selectable]="selectable"
               [removable]="removable" (removed)="remove(fruit)">
        {{fruit.name}}
        <mat-icon matChipRemove *ngIf="removable"> cancel </mat-icon>
      </mat-chip>
      <input placeholder="New fruit..."
             [matChipInputFor]="chipList"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
             [matChipInputAddOnBlur]="addOnBlur"
             (matChipInputTokenEnd)="add($event)">
    </mat-chip-list>
  </mat-form-field>