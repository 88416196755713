
<div class="row" >
    <div class="col-xs-12">
      <div class="row">
        <div class="col-2">
       
         <svg style="color: orangered" 
              xmlns="http://www.w3.org/2000/svg" 
              width="23" 
              height="23" 
              fill="currentColor" 
              class="bi bi-text-paragraph" 
              viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm4-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z"/>
          </svg>
       
        </div>
        <div class="col-10">
  
              <mat-chip-list #chipList>
                <div *ngFor="let chip of subjectsParagraphsOptions; let i=index" >
                  <div *ngIf="paragraphIsDeselected">
                    <mat-chip *ngIf="subjectIsDeselected && i==0"
                              style="background-color: whitesmoke ; color:  grey ; width:150px "
                              type="button"
                              (click)="onSelectSubject(i)"> 
                              {{chip}} 
                    </mat-chip>
                    <mat-chip *ngIf="!subjectIsDeselected && i==0"
                              style="background-color: orangered ; color: white ; width:150px "
                              type="button"
                              (click)="onDeselectSubject(i)"> 
                              {{chip}} 
                    </mat-chip> 
                  </div>
                  <div *ngIf="subjectIsDeselected">
                    <mat-chip *ngIf="paragraphIsDeselected && i==1"
                              style="background-color: whitesmoke ; color:  grey ; width:150px "
                              type="button"
                              (click)="onSelectParagraph(i)"> 
                              {{chip}} 
                    </mat-chip>
                    <mat-chip *ngIf="!paragraphIsDeselected && i==1"
                              style="background-color: orangered ; color: white ; width:150px "
                              type="button"
                              (click)="onDeselectParagraph(i)"> 
                              {{chip}} 
                    </mat-chip>
                  </div>
    
                </div>
              </mat-chip-list>
           
        </div>
      </div>
    </div>
</div>

<br>

<div class="row" *ngIf="!subjectIsDeselected || !paragraphIsDeselected">
    <div class="col-xs-12" *ngIf="!isLoadingForm">

        <form  [formGroup]="subjectEditForm" (ngSubmit)="onSendForm()">
        
                    <mat-form-field class="full-width">

                    <div *ngIf="!isSubmitted">
                      <mat-label *ngIf="!subjectIsDeselected" style="color: grey;">INPUT SUBJECT</mat-label>
                      <mat-label *ngIf="!paragraphIsDeselected" style="color: grey;">INPUT PARAGRAPH </mat-label>
                    </div>
                    
                    <mat-label *ngIf="isSubmitted" style="color: grey;">TITLE</mat-label>
                    
                      <input
                      autofocus
                      matInput 
                      type="text"
                      id="name"
                      formControlName="name"
                      maxlength="150">

                    </mat-form-field>

         </form>

    </div>
</div>
  