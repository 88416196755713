export class RecipientIndexColumn {

    constructor( public _id : string,
                 public componentName: string ,
                 public UIID: string,
                 public owner: string,
                 public allUsersAllowed: boolean ,
                 public userListAllowed: string[], 
                 public dateAndTime: string ,
                 public type: string,
                 public name: string ,
                 public recipientIndexListItem_ids: string[]){}
}