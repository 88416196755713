import { Component, OnInit , ViewChild , ChangeDetectorRef , ElementRef} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { DateTime } from 'luxon';
import { RecipientIndex } from './form-list/form-edit/recipient-index-list/recipient-index.model';
import { Subject, Subscription } from 'rxjs';
import { IndexType } from './index-type.model';
import { FormService } from './form.service';
import { ActivatedRoute, Params, Route, Router } from '@angular/router';
import { UserObjectUpdateService } from '../services/other-components-services/user-object-update.service';
import { RecipientIndexListItem } from './form-list/form-edit/recipient-index-list/recipient-index-list-item.model';
import { RecipientIndexListItemService } from './form-list/form-edit/recipient-index-list/recipient-index-list-item.service';



@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.css'],
  
})
export class FormsComponent implements OnInit {

  user: string 

  form_id: string 

  editMode = false 

   /**
   *  package properties for online / offline detection and mode
   */
    onlineOffline: boolean = navigator.onLine
    // ------------------------------------------------------------
  
  @ViewChild('drawer') public sidenav: MatSidenav;
  @ViewChild('sidenavContent') sidenavContent: MatSidenavContent

  isPersonal: boolean;
  isPublic: boolean;
  isShared: boolean; 
  isPreferred: boolean; 

  // isOpenIndexDesktop: boolean;
  isOpenIndexMobile: boolean;

  weAreInMobileMode: boolean;

  recipientIndex: RecipientIndex
  paramsRecipientIndex_id: string 
  recipientIndexListItem: RecipientIndexListItem
//  updatedRecipientIndexId: number

  subscribeUpdatedRecipientIndex: Subject<RecipientIndex> = new Subject<RecipientIndex>();

 /**
   * subscription to online/offline
   */
  subscriptionToOnlineOffline : Subscription

  /**
   * Subscription from every paragraphIndex change 
   */
  paragraphIndexType: IndexType 

  subscriptionToChangeParagraphIndexButton: Subscription 

  pulsingEditSpinner: boolean = false 

  NoHideSideNavContentForASec: boolean

  updatingRecipientIndexListItemsFromFeatureIndexUpdate: Subscription


  constructor(private breakpointObserver: BreakpointObserver ,
              private recipientIndexListItemService: RecipientIndexListItemService ,
              private formService: FormService,
              private route: ActivatedRoute,
              private userObjectUpdateService: UserObjectUpdateService ,
              private readonly changeDetectorRef: ChangeDetectorRef
              ) {}

    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );


    ngOnInit(){

      this.user = this.userObjectUpdateService.userEmail

/**
 * package online / offline property -------------------------------------------------
 */
  console.log('this device is online : ',this.onlineOffline)
 this.subscriptionToOnlineOffline = this.createOnline$().subscribe(isOnline => console.log( this.onlineOffline = isOnline,
    'this device is now online: ', this.onlineOffline, DateTime.now().toString() ));
// ------------------------------------------------------------------------------------
 

      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
      {  this.weAreInMobileMode = true ,   console.log('isMobile')} 
      else { this.weAreInMobileMode = false,   console.log( 'isDesktop' )} 
      // this.isOpenIndexDesktop = true
      // this.isOpenIndexDesktop = false,

      // this.isOpenIndexDesktop = true; 
      this.isOpenIndexMobile = false; 

      this.route.params
                       .subscribe(
                        (params: Params)=> {
                          this.form_id = params['id'];
                          this.editMode = params['id'] != null;
                          this.paramsRecipientIndex_id = params['recipientIndex']


                          console.log( 'is edit form mode ',this.editMode , 'paramsRecipientIndex_id_Number', this.paramsRecipientIndex_id )

                        }
                       )

      this.subscriptionToChangeParagraphIndexButton = this.formService.changingParagraphIndexType.subscribe( paragraphIndexType => { 
        this.updateParagraphIndexType(paragraphIndexType)
      })
setTimeout(()=> {
  this.pulsingEditSpinner = true
}, 3000 )

this.NoHideSideNavContentForASec = true

this.updatingRecipientIndexListItemsFromFeatureIndexUpdate = this.recipientIndexListItemService.updateWorkingSharedRecipientIndexListItem
      .subscribe((res)=>{
        console.log('LOADING UPDATED RECIPIENT INDEX LIST ITEMS')
       
        if ( this.recipientIndexListItem !== undefined ){
          this.recipientIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItemBy_id(this.recipientIndexListItem._id)
        }
       
      })
     
};

      ngAfterViewChecked(): void {
        this.changeDetectorRef.detectChanges();
      };
/**
 * 
 * @param paragraphIndexType changing the paragraph Index button depending on what index is displayed 
 */
  updateParagraphIndexType(paragraphIndexType: IndexType){

    this.isPersonal = paragraphIndexType.isPersonal
    this.isPublic = paragraphIndexType.isPublic
    this.isShared = paragraphIndexType.isShared
    this.isPreferred = paragraphIndexType.isPreferred 
    this.paragraphIndexType = paragraphIndexType
  }



/**
 * package online / offline property -------------------------------------------------
 */

  createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  };
  
  // -----------------------------------------------------------------------------------
  

    
      onUpdateRecipientIndex($event){
        console.log($event)
        this.recipientIndex = $event.newRecipientIndex
        this.recipientIndexListItem = $event.newRecipientIndexListItem
        console.log(this.recipientIndex)

        this.subscribeUpdatedRecipientIndex.next($event)

      } 
    
      onIsOpenDesktop(){
      // this.isOpenIndexMobile = false; 
      }

      onInOpenIndexMobile(){

      // this.isOpenIndexDesktop = true; 

      }
    
    
      getColorToShared(){
        return this.isShared === true ? 'red': 'white';
      }
    
      onToggleIndexMenuDesktop(){
        this.weAreInMobileMode = false ;
        // this.isOpenIndexDesktop = !this.isOpenIndexDesktop;
        this.isOpenIndexMobile = false; 
      }

    
      onToggleIndexMenuMobile(){
        console.log('is toggling mobile menu')
        this.weAreInMobileMode = true ;
        this.isOpenIndexMobile = !this.isOpenIndexMobile;
        // this.isOpenIndexDesktop = true ;
      }

      closeSideNavOnFormCompletition(){

        if (this.weAreInMobileMode === true ){ this.sidenav.close()}

      }

      onToggleSidenavMobileFromFormEdit(){
        console.log('is opening sideNav')

        this.weAreInMobileMode = true ;
        this.isOpenIndexMobile = true ; //this doesn't change because it's always true 
        // this.isOpenIndexDesktop = true ;
        this.sidenav.open()

      }


onResetFormEditByHidingSideNavContent(){
  this.NoHideSideNavContentForASec = false
  setTimeout(()=> {
    this.NoHideSideNavContentForASec = true
  }, 800 )

}

onScrollPageToSubject($event){
  console.log( "is scrolling to Subject")
  document.getElementsByTagName('mat-sidenav-content')[0].scrollTo({top: $event-100 , behavior: 'smooth' } )
}

onScrollPageToTop(){
  console.log("is scrolling page to top / recipients")
  document.getElementsByTagName('mat-sidenav-content')[0].scrollTo({top: 0 , behavior: 'smooth'} )
}

onScrollPageToBottom($event){
  console.log("is scrolling page to bottom")
  console.log(document.scrollingElement.clientHeight , document.scrollingElement.scrollHeight , document , document.body.clientHeight , document.documentElement.clientHeight)
  document.getElementsByTagName('mat-sidenav-content')[0].scrollTo( { top: $event , behavior: 'smooth'} )
}

onScrollToRemovedParagraph($event){
  console.log( "is scrolling to Subject")
  document.getElementsByTagName('mat-sidenav-content')[0].scrollTo({top: $event-200 , behavior: 'smooth' } )
}

    
ngOnDestroy():void{
  this.subscriptionToOnlineOffline.unsubscribe()
  this.subscriptionToChangeParagraphIndexButton.unsubscribe()
  this.updatingRecipientIndexListItemsFromFeatureIndexUpdate.unsubscribe()
}
      


}
