import { PlanType } from "./plan-type.model";

export class UserProfile {

    constructor( public _id: string ,
                 public componentName: string , 
                 public UIID: string, 
                 public owner: string,
                 public allUsersAllowed: boolean , 
                 public userListAllowed: string[],
                 public dateAndTime: string,
                 public updateDateAndTime: string,
                 public name: string , 
                 public isPhisicalPerson: boolean , 
                 public isCompany: boolean , 
                 public isObject: boolean , // could be a switch or a robot 
                 public familyName: string , 
                 public telNumber: number , 
                 public address : string ,
                 public email: string , 
                 public planType: PlanType 
    ){}


}