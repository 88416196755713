
<!-- <mat-dialog-content> -->

<div class="row">
    <div class="col-xs-12">
        <form [formGroup]="wordLessInputEditForm" (ngSubmit)="onSubmit()">
        
        
        <div class="row">
            <div class="col-xs-8">
                
                <mat-form-field style="width: 250px" appearance="outline">
                    <mat-label>component name</mat-label>
                    <input 
                    matInput
                    type="text"
                    id="name"
                    formControlName="name"
                    >
                </mat-form-field>
                <label for="name">component name </label>
                <mat-error *ngIf="!wordLessInputEditForm.get('name').valid"  >Please enter a valid name, maximum 30 letters </mat-error>
            </div>

            <div class="col-xs-4"> 
                <button type="button" class="btn btn-primary" (click)="onAddDetails()">  add input   </button>  
        </div>
        </div>

        <hr>

        <div class="row">
           
                <div class="col-xs-12" formArrayName="details">
                    <table>
                        <tr>
                           
                                <td *ngFor="let detail of getDetails(wordLessInputEditForm); let i=index" [formGroupName]="i">

                                    <div class="row">
                                        <div class="col-xs-12">                               


<div class="row">
    <div class="col-xs-8">
    
         
             <mat-form-field class="full-width">
            <mat-label>label</mat-label> 
           <input matInput   placeholder='example' >
         </mat-form-field>
        
        
        
    </div>

    <div class="col-xs-2">
        <button *ngIf="getDetails(wordLessInputEditForm).length >1" type="button" class="btn btn-danger" (click)="onDeleteDetail(i)"> - </button>
      </div>

      <div class="col-xs-2">
      </div>
   
    </div>

                                        </div>
                                    </div>

<hr>

  
<div class="row">
    <div class="col-xs-12">
        
           
           
            <div class="row">
                <div class="col-xs-12">
                    <div class="form-group">
                        <label for="label"> label </label>
                        <input 
                        type="text" 
                        id="label{{i}}"
                        formControlName="label"
                        class="form-control">
                        <span *ngIf="!detail.get('label').valid"  class="help-block"> text should not exeed 25 letters </span> 
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <div class="form-group">
                        <label for="placeholder"> example  </label>
                        <input 
                        type="text" 
                        id="placeholder{{i}}"
                        formControlName= "placeholder"
                        class="form-control">
                        <span *ngIf="!detail.get('placeholder').valid"  class="help-block"> text should not exeed 25 letters </span> 
                    </div>
                </div>
            </div>
           
       
    </div>
</div> 

  

</td>
</tr>
</table>
            </div>
           
        </div>
<hr>

<div class="row">
    <div class="col-xs-12">
        <button  type="submit" class="btn btn-primary"> update   </button>
        
    </div>
</div>
</form>

</div>
</div> 


<!-- </mat-dialog-content> -->

