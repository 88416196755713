import { Component, OnInit, Inject, OnDestroy , ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HeaderService } from 'src/app/header/header.service';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { FixText } from '../fix-text.model';
import { Subscription } from 'rxjs';
import { ErrorsDialogManagerService } from 'src/app/services/other-components-services/errors-dialog-manager.service';



@Component({
  selector: 'app-fix-text-edit',
  templateUrl: './fix-text-edit.component.html',
  styleUrls: ['./fix-text-edit.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class FixTextEditComponent implements OnInit , OnDestroy {

  user: string 

  fixTextEditForm: FormGroup
  storageData = this.editData.detailsArray

  fixTextEdit: FixText

  updateOnUserEmail: Subscription

  maxTextNumbers: number = 4

  isLoading: boolean = false 
  error = null 

  constructor( public dialogRef: MatDialogRef<FixTextEditComponent>,
               private headerService: HeaderService,
               private userObjectUpdateService: UserObjectUpdateService ,
               private postComponentsHttpRequestService : PostComponentsHttpRequestService,
               private errorsDialogManagerService: ErrorsDialogManagerService ,  
               @Inject(MAT_DIALOG_DATA) public editData: any) { }



  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
        .subscribe((updatedUserEmail)=>{
         this.user = updatedUserEmail
        console.log('updated USER = ', this.user)
    })


    console.log('editData', this.editData )

    this.fixTextEdit  = this.editData.fixText

    this.fixTextEditForm = new FormGroup({
      name: new FormControl ( this.editData.name, [Validators.required, Validators.maxLength(30)]),
      details: new FormArray ( this.storageData.map(item =>{
        const group = this.initdetail();
        group.patchValue(item);
        return group ;
      }))
    });

  }


  initdetail(){
    return new FormGroup ({
      text: new FormControl ('', Validators.maxLength(500))
    })
  }

  getDetails (fixTextEditForm){
    return fixTextEditForm.controls.details.controls;
  }


  onAddDetails(){

    (<FormArray>this.fixTextEditForm.get('details')).push(this.initdetail())

  }

  onDeleteDetail(index: number){
     (<FormArray>this.fixTextEditForm.get('details')).removeAt(index)
  }

  onSubmit(){

    this.isLoading = true 

    if (this.fixTextEditForm.invalid){
      return
    }

    /**
     *  constructor ( public _id: string ,
                  public owner: string ,
                  public allUsersAllowed: boolean,
                  public userListAllowed: string[],
                  public dateAndTime: string ,
                  public name: string ,
                  public details: FixTextDetail[] ){}
     */
    const new_id = this.fixTextEdit._id
    const newComponentName = this.fixTextEdit.componentName
    const newUIID = this.fixTextEdit.UIID
    const new_Owner = this.user 
    const new_allUsersAllowed = this.fixTextEdit.allUsersAllowed
    const new_userListAlllowed = this.fixTextEdit.userListAllowed
    const new_DateAndTime = this.fixTextEdit.dateAndTime
    const new_name = this.fixTextEditForm.value.name 
    const new_details = this.fixTextEditForm.value.details 

    const new_fixText = new FixText ( new_id, 
                                      newComponentName,
                                      newUIID,
                                      new_Owner, 
                                      new_allUsersAllowed, 
                                      new_userListAlllowed, 
                                      new_DateAndTime, 
                                      new_name, 
                                      new_details)

    if ( this.user !== null ){
    this.postComponentsHttpRequestService.httpUpdateComponent( this.user , 'fixText', new_fixText )
        .subscribe( response => {

                    console.log(response )

                   }, error => {
                      console.log(error)

                      const errorMessage: string = "we weren't able to save the data on the server, maybe the network wasn't working. While we keep a local update, try again later to make it permanent"
                      this.errorsDialogManagerService.PopUpErrorDialog({ textContent: errorMessage })
                    }
    )
    }

    this.isLoading = false 
    this.dialogRef.close(this.fixTextEditForm.value),
    this.editData.value;
    this.headerService.nextComponentIndexSelection(false)
    
  }

  ngOnDestroy(){
    this.updateOnUserEmail.unsubscribe()
  }

}
