import { ToggleSingleWordButtonDetail } from "./toggle-single-word-button-detail.model";

export class ToggleSingleWordButton {

    constructor ( public _id: string, 
                  public componentName: string , 
                  public UIID: string , 
                  public owner: string, 
                  public dateAndTime: string,
                  public allUsersAllowed: boolean , 
                  public userListAllowed: string[], 
                  public name: string , 
                  public toggleSingleWordDetails: ToggleSingleWordButtonDetail[] ){}
}