import { Component, OnInit , ViewChild, ChangeDetectorRef, AfterContentChecked, OnDestroy} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subject, Observable, Observer, fromEvent, merge }  from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { Header } from 'src/app/header/header.model';
import { HeaderService } from 'src/app/header/header.service';
import { FormResult } from './form-result.model';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FormResultsService } from './form-results.service';

@Component({
  selector: 'app-form-results',
  templateUrl: './form-results.component.html',
  styleUrls: ['./form-results.component.css']
})

export class FormResultsComponent implements OnInit , AfterContentChecked , OnDestroy {

  @ViewChild('sidenavContent') sidenavContent: MatSidenavContent

  user: string 

   /**
   *  package properties for online / offline detection and mode
   */
    onlineOffline: boolean = navigator.onLine
    // ------------------------------------------------------------
  
  @ViewChild('drawer') public sidenav: MatSidenav;

  isDesktop: boolean;
  isMobile: boolean; 

  isDesktopFilterSign: boolean
  weAreInMobileMode: boolean

  formResult: FormResult

  weAreInContentItem: boolean; // to differentiate if we are in content list or content item on mobile and switch view
  // need to receive a subscription from the click on the index that turn list into content item view 

  aFormResultHasBeenSelected: boolean ;
  formResultListHasBeenSelected: boolean ; 

  hideMobileFilters: boolean

  resetFormResultIndexList = new Subject<void>()
  buttonSubmitSwitchToClear = new Subject<void>()

   /**
   * subscription to online/offline
   */
    subscriptionToOnlineOffline : Subscription

    updateOnUserEmail: Subscription
    resetResultsPageWithZeroSelectedSubscription: Subscription
    onRealizeFormResultListHasBeenSelectedSubscription: Subscription

    onSubscribeRouterEvents: Subscription 

    onOpenFiltersSideNav_fromEmptyFormResultListSubeciption: Subscription 
    // onScrollFormItemsToBottomSubscription: Subscription
    onScrollFormResultsListToTopSubscroption: Subscription


  constructor( private breakpointObserver: BreakpointObserver, 
               private formResultsService: FormResultsService , 
               private headerService: HeaderService ,
               private userObjectUpdateService : UserObjectUpdateService  ,
               private cdr: ChangeDetectorRef , 
               private titleService: Title , 
               private router: Router
               ) { 
             
               }


  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );


  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
    .subscribe((updatedUserEmail)=>{
     this.user = updatedUserEmail
     console.log('updated USER = ', this.user)
    })

    this.titleService.setTitle("results")

    console.log(this.router.getCurrentNavigation())


/**
 * package online / offline property -------------------------------------------------
 */
 console.log('this device is online : ',this.onlineOffline)
this.subscriptionToOnlineOffline = this.createOnline$().subscribe(isOnline => console.log( this.onlineOffline = isOnline,
   'this device is now online: ', this.onlineOffline, DateTime.now().toString() ));
// ------------------------------------------------------------------------------------

if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
{  this.weAreInMobileMode = true ,
   this.isDesktop = false,  
   this.isMobile = true ,  
   console.log('isMobile')} 
else { this.weAreInMobileMode = false,  
       this.isDesktop = true,  
       this.isMobile = false ,   
       console.log( 'isDesktop' )} 

    this.aFormResultHasBeenSelected = false
    this.formResultListHasBeenSelected = false 
    this.hideMobileFilters = false

    

    /**
     * set header colors to right setup
     */
    const newHeaderIndexSelection = new Header (false, false, false, false, true, false, false, false )
    this.headerService.nextIndexSelection(newHeaderIndexSelection);

    this.resetResultsPageWithZeroSelectedSubscription = this.headerService.resetResultsPageWithZeroSelected 
        .subscribe(()=> {
          console.log('subcription has arrived')
          this.onCloseSideNavDrawerCleared()
    })

    this.onRealizeFormResultListHasBeenSelectedSubscription = this.formResultsService.adviseFormResultListIsBeenSelected
        .subscribe(()=>{
          this.onCloseSideNavDrawerSubmittedFromRoutingCall()
    })

    this.onOpenFiltersSideNav_fromEmptyFormResultListSubeciption = this.formResultsService.openFiltersSideNav_fromEmptyFormResultList
        .subscribe(()=>{
          this.onOpenFiltersSideNav_fromEmptyFormResultList()
    })

    // this.onScrollFormItemsToBottomSubscription = this.formResultsService.scrollFormItemsToBottom
    //     .subscribe(()=> {
    //         this.onScrollFormResultItemsToBottom()
          
    // })

    this.onScrollFormResultsListToTopSubscroption = this.formResultsService.scrollFormListToTop
        .subscribe(()=> {
       
            this.onScrollFormResultsListToTop()
          
        })




  };

ngAfterContentChecked() {
    this.cdr.detectChanges();
}

  /**
 * package online / offline property -------------------------------------------------
 */

   createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  };
  
  // -----------------------------------------------------------------------------------
  

  onCloseSideNavDrawerSubmitted(){
    if (this.weAreInMobileMode === true){ this.sidenav.close()}
    this.isDesktop = false
    this.isMobile = false 
    this.aFormResultHasBeenSelected = true
    this.formResultListHasBeenSelected = true
    this.hideMobileFilters = false
  }

  onCloseSideNavDrawerSubmittedFromRoutingCall(){
    // if (this.weAreInMobileMode === true){ this.sidenav.close()}
    this.isDesktop = false
    this.isMobile = false 
    this.aFormResultHasBeenSelected = true
    this.formResultListHasBeenSelected = true
    this.hideMobileFilters = false
    this.buttonSubmitSwitchToClear.next()
  }




  onCloseSideNavDrawerCleared(){
    console.log('clear sideNav')
    if (this.weAreInMobileMode === true){
       this.sidenav.close()
       this.isDesktop = false,  
       this.isMobile = true 

    }

    if ( this.weAreInMobileMode === false ){
      this.isDesktop = true,  
      this.isMobile = false 
    }
   
    this.formResultListHasBeenSelected = false 
    this.aFormResultHasBeenSelected = false
    this.hideMobileFilters = false
    this.onReturnToFormResultsIndex()

  }

  onUpdateSelectedFormResult(){
    this.aFormResultHasBeenSelected = true
    // this.formResult = $event
    this.hideMobileFilters = true

    // console.log('the selected formResult is: ', this.formResult)

  }

  onReturnToFormResultsIndex(){
    this.aFormResultHasBeenSelected = false
    this.hideMobileFilters = false
    this.resetFormResultIndexList.next()

  }

  onIsDesktop(){
    this.isDesktop = true
    this.isMobile = false;

  }


  onIsMobile(){
    this.isMobile = !this.isMobile;
    this.isDesktop = false ; 
  }

  onUpdateMobileModeStatusFromFormResultsIndex(){
    this.weAreInMobileMode = true ,
    this.isDesktop = false,  
    this.isMobile = true ,
    console.log('isMobile')
  }



  onOpenFiltersSideNav_fromEmptyFormResultList(){
    this.isMobile = !this.isMobile;
    this.isDesktop = false ; 
    this.sidenav.toggle()

  }

  // onScrollFormResultItemsToBottom(){
  //     console.log("is scrolling page to bottom")
  //     console.log(document.scrollingElement.clientHeight , document.scrollingElement.scrollHeight , document , document.body.clientHeight , document.documentElement.clientHeight)
  //     const sideNavContent = document.getElementsByTagName('mat-sidenav-content')[0];
  //           const scrollHeight = sideNavContent.scrollHeight;

  //           sideNavContent.scrollTo({ top: scrollHeight, behavior: 'smooth' });
    
  // }

  onScrollFormResultsListToTop(){

    console.log("is scrolling page to TOP")
      console.log(document.scrollingElement.clientHeight , document.scrollingElement.scrollHeight , document , document.body.clientHeight , document.documentElement.clientHeight)
      const sideNavContent = document.getElementsByTagName('mat-sidenav-content')[0];
      sideNavContent.scrollTo({ top: 0, behavior: 'smooth' });

  }


  ngOnDestroy():void{
    this.subscriptionToOnlineOffline.unsubscribe()
    this.updateOnUserEmail.unsubscribe()
    this.resetResultsPageWithZeroSelectedSubscription.unsubscribe()
    this.onOpenFiltersSideNav_fromEmptyFormResultListSubeciption.unsubscribe()
    // this.onScrollFormItemsToBottomSubscription.unsubscribe()
    this.onScrollFormResultsListToTopSubscroption.unsubscribe()
  }


}
