import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators} from '@angular/forms';
import { CdkDragDrop , moveItemInArray, copyArrayItem  } from '@angular/cdk/drag-drop';
import { MatTabGroup } from '@angular/material/tabs';
import { ParagraphService } from '../../paragraph.service';
import { Paragraph} from '../../paragraph.model';
import { Feature} from '../paragraph-item/features/feature.model';
import { DragDropService } from '../../../../../dragdrop.service';
import { ParagraphTopic } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.model';
import { ParagraphTopicService } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.service';
import { ParagraphFeature } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature.model';
import { HeaderService } from 'src/app/header/header.service';
import { Header } from 'src/app/header/header.model';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable , Subject, Subscription} from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MultiFeatures } from '../paragraph-item/features/multi-features-list/multi-features.model';
import { MultiFeaturesService } from '../paragraph-item/features/multi-features-list/multi-features.service';
import { Tab } from '../paragraph-item/features/tabs/tab-list/tab.model';
import { TabService } from '../paragraph-item/features/tabs/tab-list/tab.service';
import { FeatureIndexSelection } from './feature-index-topic-list/feature-index-selection.model';
import { FeatureTopicService } from './feature-topic.service';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { Form } from 'src/app/forms/form.model';
import { FormService } from 'src/app/forms/form.service';
import { Router, ActivatedRoute  } from '@angular/router';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { ParagraphFeatureContainer } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature-container.model';
import { ParagraphFeatureContainerService } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature-container.service';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions} from '@angular/material/tooltip';
import { Title } from '@angular/platform-browser';
import { CreateUpdateMultiComponentsService } from 'src/app/services/shared-services/create-update-multi-components.service';
import { StorePersonalFeatureIndexComponentsService } from 'src/app/services/store-initial-components/store-personal-feature-index-components.service';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { GetComponentsHttpRequestService } from 'src/app/services/gets-services/get-components-http-request.sevice';
import { ParagraphTopicIndex } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-topic-index.model';
import { ParagraphTopicIndexService } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-topic-index.service';

interface Editing {
  isEditingStandard: boolean,
  isEditingMultiFeature: boolean ,
  isEditingTab: boolean
}

/** Custom options the configure the tooltip's default show/hide delays. */
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 2000,
  hideDelay: 100,
  touchendHideDelay: 1000,
};

@Component({
  selector: 'app-paragraph-edit',
  templateUrl: './paragraph-edit.component.html',
  styleUrls: ['./paragraph-edit.component.css'],
  providers: [{provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}],

})
export class ParagraphEditComponent implements OnInit , OnDestroy {

  @ViewChild('FeaturesEnd') FeaturesEnd: ElementRef

  user: string 

  paragraphEditForm: FormGroup;

  updateFeatureIndexSelection: FeatureIndexSelection



  paragraphTopics: ParagraphTopic[];
  paragraphFeature : ParagraphFeature ;
  paragraph: Paragraph ;

  features : Feature[] = [];
  feature : Feature ;

  featureName : string ;

  count = 0 ;

   /** droppoed help hide or unhide the buttons before any feature is dropped  */
   dropped: boolean ; 
   panelOpenState = false;
   customCollapsedHeight: string = '20px';
   customExpandedHeight: string = '20px';
   index : number ;
   i : number ;
  //  e = 1;
    
   @Output() selectedIndex : number ; 
   @Input() changeIndex : number ;
   @Input() addFeature : Feature ; 
  //  @Output() windex : number ;
  //  @Input() eventIndex: number;

    featureCurrentIndex: number ;
    currentIndex : number ;
    previx : number ;

  isMobile= true ;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );


   selectedFeature : string ;

   @Input() currentI : number

/** this is to change header name */
   weGoingToComponentIndex : boolean = true ;

/** this subcription number give us the id of the paragraph to be edited  */
   paragraphIndexId : string;

/** to understand if we are creating or editing a paragraph */
  @Input() isEditMode: boolean ;
  @Input() paragraph_id: string ;

   /** to send the correct id of the miultifeature */
   sendId: number

   /** it's to stop subscriptions on ngOnInIt to fire  */
   isParagraphEdit = true ; 



  @ViewChild('tabs') tabGroup: MatTabGroup

  /** HERE START THE PROPERTIES FOR THE SECOND DROP */

  multiFeature: MultiFeatures;
  multiFeatures: MultiFeatures[];

  // @Input() changeMultiFeatureIndex: number

   /** droppoed help hide or unhide the buttons before any feature is dropped  */
   droppedSingleFeature = true ;

   droppedSingleFeatureId : number ;

   @Output() windexSingleFeature : number ;

   previxSingleFeature: number ;

   countSingleFeature: 0;

   /**this serve to hide the paragraphEdit and leave fultifeature edit  */
   isEditMultiFeature : boolean = false ;

   /** to pass to multiFeatureEdit [index]="" */
   multiFeaturesEditId: number;

   /**id of feature to be changed  */
   editMultifeature: MultiFeatures ;

   /** pass id down to multiFeature to change service data precisely */
   editMultiFeatureId: number ;


   @Input() updateMultiFeature;

   /**
    * send request to edit feature
    */
   clickOnEditFeature: Subject<void> = new Subject<void>()

// this is needed to give the data to component list and component item the right 
// comperison in order to open only the right edit component 
    /**
     * this number everytime user drop a feature and change the i's 
     * 
     */
    updatedI: Subject<void> = new Subject<void>()
    passFeatureDataToComponentList: Subject<Feature> = new Subject<Feature>()

    /**
     * 
     */
    isEditTab: boolean = false 
    editTab: Tab ;
    editTabId: number;
    tabId: number;
    tabIdIndex: number

    findIndex: number ;

    latestFormSubscription : Subscription
    latestForm: Form

    isAnnouncingIsEditingAFormWithLoadingSpinner: boolean 
    aSubComponentIsHavingIssues: boolean = false 

   // ParagraphTopicReferenceToAddParagrapgFeature: Subscription 
    paragraphTopicReference: ParagraphTopic
    paragraphIndexTopicList_TypeOfReference: string 

    holdCreateUpdateButtons_onLoadingVisual: boolean = false 

    updateOnUserEmail: Subscription
    subscribeToValueChanges: Subscription

    isLoading: boolean = false 

    addNewFeatureToParagraphSubscription : Subscription

    isEditing: Editing
    paragraphComponentOffsetTop: number 
    @Output() onScrollToBottomOnParentComponent = new EventEmitter<number>()

    @Output() onOpenSideNavFromParagraphEdit = new EventEmitter<void>()


  constructor(private paragraphTopicService: ParagraphTopicService,
              private paragraphService: ParagraphService,
              private userObjectUpdateService: UserObjectUpdateService ,
              private DragDrop: DragDropService ,
              private headerService: HeaderService ,
              private paragraphFeatureContainerService: ParagraphFeatureContainerService ,
              private breakpointObserver: BreakpointObserver,
              private multiFeaturesService: MultiFeaturesService,
              private tabService: TabService, 
              private featureTopicService: FeatureTopicService ,
              private formService: FormService ,
              private postComponentsHttpRequestService: PostComponentsHttpRequestService, 
              private route: ActivatedRoute,
              private router: Router ,
              private titleService: Title , 
              private createUpdateMultiComponentService: CreateUpdateMultiComponentsService,
              private storePersonalFeatureIndexComponentsService: StorePersonalFeatureIndexComponentsService , 
              private initialNullSetupService: InitialNullSetupService , 
              private getComponentsHttpRequestService: GetComponentsHttpRequestService,
              private paragraphTopicIndexService: ParagraphTopicIndexService
              ) {  }
  
  
 


  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
        .subscribe((updatedUserEmail)=>{
         this.user = updatedUserEmail
         console.log('updated USER = ', this.user)
    })

  
    this.paragraphTopics = this.paragraphTopicService.getParagraphTopics() 

    this.latestFormSubscription = this.formService.changingForm.subscribe( receiveForm => {
      this.latestForm = receiveForm
      console.log(this.latestForm) 
    })

    this.isEditing = { isEditingStandard: true , isEditingMultiFeature: false, isEditingTab: false }

    /** to change the header name into paragraph instead of form  */
  //  this.headerService.nextMessage(this.weGoingToComponentIndex)
 
    console.log('is it EDIT MODE ? ',this.isEditMode)
  
    if (this.isEditMode === true){

      this.isAnnouncingIsEditingAFormWithLoadingSpinner = true 
      setTimeout(()=> {
        this.isAnnouncingIsEditingAFormWithLoadingSpinner = false
      }, 300)

      const newHeaderIndexSelection = new Header (false , true , false, false, false, false, false, false )
      this.headerService.nextIndexSelection(newHeaderIndexSelection)

      this.dropped = false
     
      this.paragraph = this.paragraphService.getParagraphBy_id(this.paragraph_id)
      console.log('this is the paragraph to edit:', this.paragraph)

      this.titleService.setTitle( "edit: "+this.paragraph.name )

      this.paragraphEditForm = new FormGroup({
        name : new FormControl (this.paragraph.name , [ Validators.required, Validators.pattern(/^[a-zA-Z0-9]*$/) ]) ,
        description : new FormControl (this.paragraph.desc ,[ Validators.required, Validators.pattern(/^[a-zA-Z0-9]*$/) ])
      })

      this.subscribeToValueChanges =  this.paragraphEditForm.valueChanges.subscribe(
        (value) =>  { console.log(value)

        this.titleService.setTitle( "edit: "+value.name )
        }
      );

   

      this.features = this.paragraph.features;

      console.log(newHeaderIndexSelection , 'paragraph = ' , this.paragraph )

  

    }

        /**
     * differentiating edit mode and new editing
     */
        if ( this.isEditMode === false ){

          this.titleService.setTitle( "create paragraph")

          const newHeaderIndexSelection = new Header (false , true , true, false, false, false, false, false )
          this.headerService.nextIndexSelection(newHeaderIndexSelection)
    
          this.paragraphTopicReference = this.paragraphTopicService.startingParagraphTopicReference
          console.log(this.paragraphTopicReference)                                                         
    
          this.dropped = true
    
          this.paragraphEditForm = new FormGroup({
            name : new FormControl ('' , [ Validators.required, Validators.pattern(/^[a-zA-Z0-9]*$/) ] ),
            description : new FormControl ('' , [ Validators.required, Validators.pattern(/^[a-zA-Z0-9]*$/) ])
          })

          this.subscribeToValueChanges =  this.paragraphEditForm.valueChanges.subscribe(
            (value) => { console.log(value) 
              this.titleService.setTitle( "create: "+value.name )
            }
          );

          this.features = [];
          console.log(newHeaderIndexSelection)
    
          if ( this.paragraphTopicService.startingParagraphTopicReference._id === null  ){ 

              this.router.navigate(['/create-form' ], {relativeTo: this.route.parent })
           }
          
        }

  this.addNewFeatureToParagraphSubscription = this.paragraphService.additionalFeature
      .subscribe((res: Feature)=> {
        this.onAddFeatureToParagraph(res)
      })


    console.log('name', this.paragraphEditForm.value.name ,
                'description:', this.paragraphEditForm.value.description ,
                'feature lists: ', this.features)

      
  };


  onAddFeatureToParagraph(addingFeature: Feature){
    console.log('add new feature => ',addingFeature)
    this.dropped = false ;

    const newOwner = addingFeature.owner
    const newIsNew = false ;
    const newDropId = this.count++;
    const newFeature_id = addingFeature.feature_id;
    const newFeatureName = addingFeature.featureName;
    const newFeature = new Feature ( newOwner, newIsNew,  newDropId , newFeature_id, newFeatureName) ;

    this.features.push(newFeature) ;

    console.log(this.features )

    this.count = 0 ;
      for ( let i=0 ; i<this.features.length ; i++){
             this.features[i].dropId = this.count++
        
      }

    const newFeaturesEnd : any =  this.FeaturesEnd
    console.log(newFeaturesEnd)
    this.paragraphComponentOffsetTop = newFeaturesEnd.nativeElement.offsetTop
    console.log(this.paragraphComponentOffsetTop)

    this.scrollToBottomOnParagraphSideNavContent(this.paragraphComponentOffsetTop)

  }

  onScrollToBottomOnParentComponentFromTabEdit($event){
    this.scrollToBottomOnParagraphSideNavContent($event)
  }

  scrollToBottomOnParagraphSideNavContent(newParagraphComponentOffsetTop: number){
    this.onScrollToBottomOnParentComponent.emit( newParagraphComponentOffsetTop )
  }
  
// using drag and drop service to drop the feature and then change the feature-index-FeatureIndex number 
// so that every dropped feature differ from the others and it's easy to move them around without having a copy 
  onDrop(event: CdkDragDrop<Feature[]>) {

    this.DragDrop.dropFeature(event);

    /**
     * call http and get the right component type list personal and add to service 
     * then continue to build feature 
     */
    this.dropped = false ;
    // this.windex = event.currentIndex;
    // this.previx = event.previousIndex;
    // console.log('WINDEX = ',this.windex)

    
    /** this IF prevent to chnage dropId when the feature is moved within the same container */
    if (event.previousContainer !== event.container) {
     /** newDropId has to be count++ if we don't use the forEach function */

   
    const newOwner = this.features[event.currentIndex].owner;  
    console.log('currentIndexOwner',this.features[event.currentIndex].owner)
    console.log('previousIndexOwner',this.features[event.previousIndex].owner)
    console.log(newOwner)
   

    const newIsNew = false ;
    const newDropId = this.count++;
    const newFeature_id = this.features[event.currentIndex].feature_id;
    const newFeatureName = this.features[event.currentIndex].featureName;
    const newFeature = new Feature ( newOwner, newIsNew,  newDropId , newFeature_id, newFeatureName) ;

    this.features[event.currentIndex] = newFeature ;

    console.log(this.features )
      
      }
     /** this additional function is tomake sure the dropId are always in sequences 
      */
      this.count = 0 ;
      for ( let i=0 ; i<this.features.length ; i++){
             this.features[i].dropId = this.count++
        
      }
    
    // console.log(this.windex)
    console.log(event)
    console.log (this.features );

    
  };


  /** here we define the changes for each component inside the Multi-Features
   *  featureIndexSelection is to fix the idex type on the side 
   */

  onEditFeature(i){

    this.headerService.nextComponentIndexSelection(true)

    if ( this.features[i].featureName === 'fixLayout'){
         this.dropped = true
         const newIsEditing: Editing = { isEditingStandard: true , isEditingMultiFeature: false, isEditingTab: false }
         this.paragraphService.adviseFeatureContainerIsEditing(newIsEditing)
    }

    if ( this.features[i].featureName === 'multiFeatures'){
      const newUpdateFeatureIndexSelection = new FeatureIndexSelection ( false, false )
      this.featureTopicService.nextIndexSelection(newUpdateFeatureIndexSelection)
      this.isEditMultiFeature = true ;
      this.isEditTab = false
      this.multiFeaturesEditId = i ;

      const  newIsEditing: Editing = { isEditingStandard: false , isEditingMultiFeature: true, isEditingTab: false }
      this.paragraphService.adviseFeatureContainerIsEditing(newIsEditing)
   
      this.editMultifeature = this.multiFeaturesService.getMultiFeatureBy_id(this.features[i].feature_id)
      this.editMultiFeatureId = this.multiFeaturesService.getMultiFeatures().findIndex(x=> x._id === this.features[i].feature_id)
  
    } else if (this.features[i].featureName === 'tabs' ){
      const newUpdateFeatureIndexSelection = new FeatureIndexSelection ( false, true )
      this.featureTopicService.nextIndexSelection(newUpdateFeatureIndexSelection)
      this.isEditMultiFeature = true
      this.isEditTab = true 
      this.editTabId = i; 
      const  newIsEditing: Editing = { isEditingStandard: false , isEditingMultiFeature: false, isEditingTab: true }
      this.paragraphService.adviseFeatureContainerIsEditing(newIsEditing)
      const newTabIndex = this.tabService.getTabList().findIndex(x=> x._id === this.features[i].feature_id)
      console.log(newTabIndex)
      this.editTab = this.tabService.getTab(newTabIndex)
      this.tabId = this.tabService.getTabList().findIndex(x=> x._id === this.features[i].feature_id)

    
    } else {
      // passing the feature to be able to later confront with right "i"
      this.passFeatureDataToComponentList.next(this.features[i])
      const newIsEditing: Editing = { isEditingStandard: true , isEditingMultiFeature: false, isEditingTab: false }
      this.paragraphService.adviseFeatureContainerIsEditing(newIsEditing)
      this.clickOnEditFeature.next()
    }

  
  }

  onHoldCreateUpdateButtons(){
    this.holdCreateUpdateButtons_onLoadingVisual = true 

    setTimeout(()=>{
      this.holdCreateUpdateButtons_onLoadingVisual = false 
    }, 4500)
  }

/** update the multiFeature from changes happened in MultiFeatureEdit */
  onUpdateMultiFeature(){

  //  this.multiFeaturesService.multiFeatures[this.features[this.multiFeaturesEditId].featureid] = event ;

    this.isEditMultiFeature = false ;
  }

  onUpdateTabFeature(event){
    this.tabService.tabList[this.tabId] = event
    this.isEditMultiFeature = false
    this.isEditTab = false 
  }


  onUpdateDroppedConditionFromFixLayout($event){
    console.log($event)
    this.dropped = $event
  }



  

  /** we have to add a unique id to the feature on drop , to make the object unique */ 
  /**  have to use MAP to  */

  onChangedIndex(event){
    this.feature = event;
    // this.windex = event.currentIndex;
    console.log(event);
    /** we update the features on tghe index found , with the new feature 
     * this.features[this.feature.dropId] = this.feature */  
    
    this.features[this.feature.dropId] = this.feature;

     /** this additional function is tomake sure the dropId are always different  */
    this.count = 0 ;
    for ( let i=0 ; i<this.features.length ; i++){
       this.features[i].dropId = this.count++
       }
    this.count = 0 ;

    console.log(this.features);
    
  }




  /** remove feature from the array  */
  onRemoveFeature(i){
    this.features.splice(i, 1)
    console.log( this.features);


     /** this additional function is tomake sure the dropId are always different  */
    this.count = 0 ;
     for ( let i=0 ; i<this.features.length ; i++){
        this.features[i].dropId = this.count++
        } 
     this.count = 0 ;

     /** this function remove the buttons to add paragraph when all features are removed */
    if ( this.features.length > 0){
      this.dropped = false ;
  } else if ( this.features.length === 0 ) {
     this.dropped = true ;
  }
}




/** clear the page */
  onClearParagraph(){

    this.paragraphEditForm = new FormGroup({
      name : new FormControl ('', [ Validators.required, Validators.pattern(/^[a-zA-Z0-9]*$/) ]),
      description : new FormControl ('', [ Validators.required, Validators.pattern(/^[a-zA-Z0-9]*$/) ])
    })
  
    this.features = []

    this.count = 0 ;
    this.dropped = true ;
   
  }
/** this add the paragraph to paragraph service */
  AddParagraph(){
    if ( this.user === null ){ this.onNullUser_AddParagraph()}
    if ( this.user !== null ){ this.onExistingUser_AddParagraph()}
  }

onExistingUser_AddParagraph(){

  /**
   * make sure no other paragraphs were added in the meantime 
   */
  this.storePersonalFeatureIndexComponentsService.getPersonalUpdatedFeatureIndex_components(this.user)
      .subscribe((res: any)=> {
        console.log(res)

        const httpFeatureIndexComponentResult: any = res 

        const newParagraphTopicIndexes: ParagraphTopicIndex[] = httpFeatureIndexComponentResult.paragraphTopicIndex
        console.log(newParagraphTopicIndexes)
        this.paragraphTopicIndexService.paragraphTopicIndexes = newParagraphTopicIndexes
        this.initialNullSetupService.initialNullParagraphTopicIndexes = newParagraphTopicIndexes

        const newParagraphTopics: ParagraphTopic[] = httpFeatureIndexComponentResult.paragraphTopic
        console.log(newParagraphTopics)
        this.paragraphTopicService.paragraphTopics = newParagraphTopics
        this.initialNullSetupService.initialNullParagraphTopics = newParagraphTopics 
      
        const newParagraphFeatureContainers: ParagraphFeatureContainer[] = httpFeatureIndexComponentResult.paragraphFeatureContainer 
        console.log(newParagraphFeatureContainers)
        this.paragraphFeatureContainerService.paragraphFeatureContainers = newParagraphFeatureContainers
        this.initialNullSetupService.initialNullParagraphFeatureContainers = newParagraphFeatureContainers

        const indexOfLatestParagraphTopicSelected = newParagraphTopics.findIndex(x=> x._id === this.paragraphTopicReference._id)
        const latestParagraphTopicSelected: ParagraphTopic = newParagraphTopics[indexOfLatestParagraphTopicSelected]

        /**
         * to make sure of the paragraph UIID better always double check with latest paragraphs 
         */
        this.updateWithLatestParagraphs_beforeAddingThisOne(newParagraphFeatureContainers , latestParagraphTopicSelected)
      

      },error=> {
        console.log(error)

         /**
       * SEND BACK TO FORM CREATE/UPDATE AND THROW ERROR 
       */
      if ( this.latestForm._id === null ){ this.router.navigate(['/create-form' ], {relativeTo: this.route.parent })}
      if ( this.latestForm._id !== null ){ this.router.navigate(['/edit-form', this.latestForm._id], {relativeTo: this.route.parent})}

      })

}


updateWithLatestParagraphs_beforeAddingThisOne( newParagraphFeatureContainers: ParagraphFeatureContainer[], latestParagraphTopicSelected: ParagraphTopic ){

  this.getComponentsHttpRequestService.httpGetComponentsByType( this.user , 'paragraph')
            .subscribe((paragraphItemsResults: any)=>{

                console.log(paragraphItemsResults)
                const paragraphsResults = paragraphItemsResults.paragraph 
                this.paragraphService.addParagraphsToList_Only(paragraphsResults)

                const new_id = "paragr-"+(paragraphsResults.length+1).toString()
                const newComponentName = "paragraph"
                const newUIID = "paragraph-"+(paragraphsResults.length+1).toString()
                const newOwner = this.user; 
                const newDateAndTime = null ;
                const newUpdateDateAndTime = null 
                const newIsRemoved = false ; 
                const newAllUsersAllowed = false;
                const newUserListAllowed = []
                const newCanBeReshared = false 
                const newParagraphName = this.paragraphEditForm.value.name ;
                const newParagraphDesc = this.paragraphEditForm.value.description ;
                const newFeatures = this.features ;
                const newFeatures_id_list = []
                for ( let i=0; i<this.features.length ; i++ ){
                  newFeatures_id_list.push(this.features[i].feature_id)
                }
              
                const newParagraph = new Paragraph (  new_id,
                                                      newComponentName,
                                                      newUIID,
                                                      newOwner,
                                                      newDateAndTime,
                                                      newUpdateDateAndTime,
                                                      newIsRemoved ,
                                                      newAllUsersAllowed,
                                                      newUserListAllowed,
                                                      newCanBeReshared,
                                                      newParagraphName, 
                                                      newParagraphDesc, 
                                                      newFeatures, 
                                                      newFeatures_id_list );
                  
                  if ( this.user !== null ){
              
                    const new_id2 = 'paragraphFeatureContainer-'+(newParagraphFeatureContainers.length+1).toString()
                    const newComponentName = 'paragraphFeatureContainer'
                    const newUIID = 'paragraphFeatureContainer-'+(newParagraphFeatureContainers.length+1).toString()
                    const newOwner2 = this.user
                    const newAllUsersAllowed2 = false
                    const newUserListAllowed2 = []
                    const newDateAndTime2 = null 
                    const newIsActive2 = true ;
                    const newIsAccepted2 = true ; 
                    const newName = 'personal'
                    const newState = true 
                    const newParagraph_id = null 
              
                    const newParagraphFeatureContainer = new ParagraphFeatureContainer ( new_id2, newComponentName, newUIID, newOwner2, newAllUsersAllowed2, newUserListAllowed2,
                      newDateAndTime2, newIsActive2, newIsAccepted2, newName, newState, newParagraph_id )
              
                  this.createUpdateMultiComponentService.createParagraph_createParagraphFeatureContainer_UpdateParagraphTopic(this.user, newParagraph, newParagraphFeatureContainer, latestParagraphTopicSelected  )
                      .subscribe((res: any)=> {
                        console.log(res)
              
                        const newlyCreatedParagraph: Paragraph = res.paragraph[0]
                        const newlyCreatedParagraphFeatureContainer: ParagraphFeatureContainer = res.featureIndex[0]
                        const updatedParagraphTopic: ParagraphTopic = res.featureIndex[1]
              
                        this.paragraphService.addParagraph(newlyCreatedParagraph);
              
                        this.paragraphFeatureContainerService.paragraphFeatureContainers.push(newlyCreatedParagraphFeatureContainer)
              
                        const paragraphTopicreferenceIndex = this.paragraphTopicService.getParagraphTopics().findIndex(x=> x._id === this.paragraphTopicReference._id)
                        this.paragraphTopicService.paragraphTopics[paragraphTopicreferenceIndex] = updatedParagraphTopic
              
                   this.paragraphEditForm.value.name = '';
                   this.paragraphEditForm.value.description = '';
                   this.features = [];
                   this.dropped = true;
                   this.count = 0 ;
              
                    const newHeaderIndexSelection = new Header (true, false, false, false, false, false, false , false)
                    this.headerService.nextIndexSelection(newHeaderIndexSelection)
                   
                   if ( this.latestForm._id === null ){ this.router.navigate(['/create-form' ], {relativeTo: this.route.parent })}
                   if ( this.latestForm._id !== null ){ this.router.navigate(['/edit-form', this.latestForm._id], {relativeTo: this.route.parent})}
                       
              
              
                  }, error => {
                    console.log(error , "THE PARAGRAPH + PARENTS WEREN'T SAVED PROPERLY")
                    /**
                     * SEND BACK TO FORM CREATE/UPDATE AND THROW ERROR 
                     */
                    if ( this.latestForm._id === null ){ this.router.navigate(['/create-form' ], {relativeTo: this.route.parent })}
                    if ( this.latestForm._id !== null ){ this.router.navigate(['/edit-form', this.latestForm._id], {relativeTo: this.route.parent})}
              
                  })
              
                }


            })

}

onNullUser_AddParagraph(){

   /**
     * this extra new_id is to be used in case user is not registered and let him try use paragraphs and 
     */
   const new_id = "paragr-"+(this.paragraphService.getParagraphs().length+1).toString()
   const newComponentName = "paragraph"
   const newUIID = "paragraph-"+(this.paragraphService.getUserParagraphList(this.user).length+1).toString()
   const newOwner = this.user; 
   const newDateAndTime = null ;
   const newUpdateDateAndTime = null 
   const newIsRemoved = false ; 
   const newAllUsersAllowed = false;
   const newUserListAllowed = []
   const newCanBeReshared = false 
   const newParagraphName = this.paragraphEditForm.value.name ;
   const newParagraphDesc = this.paragraphEditForm.value.description ;
   const newFeatures = this.features ;
   const newFeatures_id_list = []
   for ( let i=0; i<this.features.length ; i++ ){
     newFeatures_id_list.push(this.features[i].feature_id)
   }

   const newParagraph = new Paragraph (  new_id,
                                         newComponentName,
                                         newUIID,
                                         newOwner,
                                         newDateAndTime,
                                         newUpdateDateAndTime,
                                         newIsRemoved ,
                                         newAllUsersAllowed,
                                         newUserListAllowed,
                                         newCanBeReshared,
                                         newParagraphName, 
                                         newParagraphDesc, 
                                         newFeatures, 
                                         newFeatures_id_list );

 /**
  *  IF the user is registered we send the info to HTTP 
  *  otherwise we store the component locally 
  */



/**
*  if user is NULL --------------------------------------------------------------------------
*/
 if ( this.user === null){
   this.paragraphService.addParagraph(newParagraph); 
   console.log(newParagraph)

   const new_id2 = 'paragraphFeatureContai-'+(this.paragraphFeatureContainerService.getParagraphFeatureContainers().length+1).toString()
   const newComponentName = 'paragraphFeatureContainer'
   const newUIID = 'paragraphFeatureContainer-'+(this.paragraphFeatureContainerService.getParagraphFeatureContainers().length+1).toString()
   const newOwner2 = this.user
   const newAllUsersAllowed2 = false
   const newUserListAllowed2 = []
   const newDateAndTime2 = null 
   const newIsActive2 = true ;
   const newIsAccepted2 = true ; 
   const newName = 'personal'
   const newState = true 

   const newParagraphFeatureContainer = new ParagraphFeatureContainer ( new_id2, newComponentName, newUIID, newOwner2, newAllUsersAllowed2, newUserListAllowed2,
         newDateAndTime2, newIsActive2, newIsAccepted2, newName, newState, newParagraph._id )
   
   this.paragraphFeatureContainerService.paragraphFeatureContainers.push(newParagraphFeatureContainer)
   console.log(this.paragraphFeatureContainerService.getParagraphFeatureContainers())

   this.paragraphTopicReference.paragraphFeatureContainer_ids.push(newParagraphFeatureContainer._id)
   console.log('updated paragraphTopic',this.paragraphTopicReference)
  
   /**
    * thisis another option but it's longer , takes more time when adding the paragraph 
    * if startingParagraphTopicReference._id === null then we have to add dialog to choose which paragraphTopic
    * if there are no paragraphTopic , we have to create one 
    *  startingParagraphTopicReference: ParagraphTopic = new ParagraphTopic (null, null, false, [], null, true, 
                                  false, '', [] )
    */
   const paragraphTopicreferenceIndex = this.paragraphTopicService.getParagraphTopics().findIndex(x=> x._id === this.paragraphTopicReference._id)
   this.paragraphTopicService.paragraphTopics[paragraphTopicreferenceIndex] = this.paragraphTopicReference
                         
   console.log(this.paragraphTopics)
                         
   this.paragraphEditForm.value.name = '';
   this.paragraphEditForm.value.description = '';
   this.features = [];
                         
  /** serve to hide or show the initial indication where to drop items */
   this.dropped = true;
                         
  // here we reset the feature-index with initial state of features
  this.count = 0 ;
                                            
  // console.log(this.createParagraphEditForm())
  console.log(this.paragraphService.getParagraphs())

  const newHeaderIndexSelection = new Header (true, false, false, false, false, false, false , false)
  this.headerService.nextIndexSelection(newHeaderIndexSelection)
 
 if ( this.latestForm._id === null ){ this.router.navigate(['/create-form' ], {relativeTo: this.route.parent })}
 if ( this.latestForm._id !== null ){ this.router.navigate(['/edit-form', this.latestForm._id], {relativeTo: this.route.parent})}
 }
/**
*  END if user is NULL --------------------------------------------------------------------------
*/


}

onOpenSideNav(){
  console.log('opening sideNav on mobile')
  this.onOpenSideNavFromParagraphEdit.emit()
 }





    onSubmit(){

      if (this.isEditMode === false) { this.AddParagraph() , console.log(this.paragraphService.getParagraphs) }
  
      if ( this.isEditMode === true){

        this.findIndex = this.paragraphService.getParagraphs().findIndex(x => x._id === this.paragraphIndexId) ;

        /**
         * inreality here we have to call http PUT and update the whole paragraph , not only these part 
         */
  
    const newEdit_id = this.paragraph._id
    const newComponentName = this.paragraph.componentName
    const newUIID = this.paragraph.UIID
    const newEditOwner = this.user
    const newEditDateAndTime = this.paragraph.dateAndTime ;
    const newUpdateDateAndTime = this.paragraph.updateDateAndTime
    const newEditIsRemoved = this.paragraph.isRemoved ; 
    const newEditAllUsersAllowed = this.paragraph.allUsersAllowed;
    const newEditUserListAllowed = this.paragraph.userListAllowed;
    const newCanBeReshared = this.paragraph.canBeResharedByOthers ; 
    const newEditParagraphName = this.paragraphEditForm.value.name ;
    const newEditParagraphDesc = this.paragraphEditForm.value.description ;
    const newEditFeatures = this.features ;
    const newFeatures_id_list = []
    for ( let i=0; i<this.features.length ; i++ ){
      newFeatures_id_list.push(this.features[i].feature_id)
    }

    const newParagraph = new Paragraph (  newEdit_id,
                                          newComponentName,
                                          newUIID,
                                          newEditOwner,
                                          newEditDateAndTime,
                                          newUpdateDateAndTime,
                                          newEditIsRemoved ,
                                          newEditAllUsersAllowed,
                                          newEditUserListAllowed,
                                          newCanBeReshared , 
                                          newEditParagraphName, 
                                          newEditParagraphDesc, 
                                          newEditFeatures ,
                                          newFeatures_id_list );

    this.paragraphService.updateParagraph(newParagraph)
    /**
     * if this user is NULL ------------------------------------------------------------------
     */
    if ( this.user === null ){

      console.log(this.paragraphService.getParagraphs())

      const newHeaderIndexSelection = new Header (true, false, false, false, false, false, false , false)
      this.headerService.nextIndexSelection(newHeaderIndexSelection)
     
     if ( this.latestForm._id === null ){ this.router.navigate(['/create-form' ], {relativeTo: this.route.parent })}
     if ( this.latestForm._id !== null ){ this.router.navigate(['/edit-form', this.latestForm._id], {relativeTo: this.route.parent})}
    }
     /**
     *  END this user is NULL ------------------------------------------------------------------
     */


     /**
     * if this user is active ( !== null ) ------------------------------------------------------------------
     */

     if ( this.user !== null ){
      this.postComponentsHttpRequestService.httpUpdateComponent(newEditOwner, 'paragraph', newParagraph).subscribe(response => { 
        console.log(response )

        // this.paragraphService.updateParagraph(newParagraph)
        console.log(this.paragraphService.getParagraphs())
  
  
        const newHeaderIndexSelection = new Header (true, false, false, false, false, false, false , false)
        this.headerService.nextIndexSelection(newHeaderIndexSelection)
       
       if ( this.latestForm._id === null ){ this.router.navigate(['/create-form' ], {relativeTo: this.route.parent })}
       if ( this.latestForm._id !== null ){ this.router.navigate(['/edit-form', this.latestForm._id], {relativeTo: this.route.parent})}
     
      }, error => {
        console.log('ERROR ON UPDATE PARAGRAPH', error )
      })
     }


    // })
   /**
    * we need to send an update to make paragraphFeatureTopic - paragraphIndex update itself
    */

    // if it's NOT logged in  
   // this.paragraphService.paragraphs[this.paragraphService.getParagraphs().findIndex(x=> x._id === this.paragraph._id)]  = newParagraph
    

      }
      
  }

  onASubComponentIsHavingIssues($event){
    console.log( 'is emitting a subComponentIshavingIssue '),
    this.aSubComponentIsHavingIssues = $event

  }

ngOnDestroy(){
  this.latestFormSubscription.unsubscribe()
  this.updateOnUserEmail.unsubscribe()
  this.subscribeToValueChanges.unsubscribe()
  this.addNewFeatureToParagraphSubscription.unsubscribe()
}



  

}
