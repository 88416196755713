
import { Injectable } from "@angular/core";
import { throwError ,  Subject, Subscription } from "rxjs";
import { UserObject } from "../../auth/user.model";


interface AuthResponseData {

    kind: string ;
    idToken: string ;
    email: string;
    refreshToken: string ;
    expiresIn: string ;
    localId: string; 
}

@Injectable({
    providedIn: 'root'
})

export class AuthService {

    userObjectUpdate = new Subject<UserObject>()
    // we should create aslo a profile model where we store all the other user date 
    // nickname , full name  , address , phone number , etc etc 

    updateHttpDataOnAllComponents = new Subject<string>()
    updateInitialNullDataOnAllComponents = new Subject<string>()


    

    constructor( ){}


    /**
     * 
     * @param userObjectFromLogin goest to userObjectUpdate service 
     */
    onUpdateLoggedInUser(userObjectFromLogin: UserObject){

         const credential = sessionStorage.getItem('token')
         console.log(credential)

        this.userObjectUpdate.next(userObjectFromLogin)
        console.log('updated user = ' ,userObjectFromLogin)
    
    };


    onUpdateLoggedOutUser(userObjectFromLogin: UserObject){
        this.userObjectUpdate.next( userObjectFromLogin)
        console.log('updated user = ' , userObjectFromLogin)
        /**
         * initiate logic to initiate initialNullSetup components 
         */
        this.updateInitialNullDataOnAllComponents.next(userObjectFromLogin.email)
    }






}