import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-form-result-start',
  templateUrl: './form-result-start.component.html',
  styleUrls: ['./form-result-start.component.css']
})
export class FormResultStartComponent implements OnInit {

  message1: string ="bookmark tabs with active filters like 'today', 'yesterday', 'last week' or 'last month' etc etc.." 
  message2: string ="the subjects you look for are always updated and one click away"
  message3: string ="like this"

  tab1= "tab1: today total people served"
  tab2= "Tab 3: last month total sales"
  tab3= "Tab 2: material delivered so far"
  tab4= "Tab 4: last week quotations"

  constructor( private breakpointObserver: BreakpointObserver, ) { }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  ngOnInit(): void {
  }

}
