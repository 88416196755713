import { Component, OnInit, Input, Output, EventEmitter , ViewChild, OnDestroy } from '@angular/core';
import { ToggleSingleWordButton } from '../toggle-single-word-button.model';
import { ToggleSingleWordButtonDetail } from '../toggle-single-word-button-detail.model';
import { ToggleSingleWordButtonService } from '../toggle-single-word-button.service';
import { MatTabGroup } from '@angular/material/tabs';
import { Feature } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/feature.model';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { CreateNewFeatureContainerAndAddToFeatureContainerListItemService } from 'src/app/services/shared-services/create-new-feature-container-and-update-feature-container-list-item.service';
import { Subscription } from 'rxjs';
import { AdviseIfHttpStoreComponentsCallHasBeenMadeService } from 'src/app/services/other-components-services/advise-if-http-store-components-has-been-made.service';
import { StoreBaseComponentsService } from 'src/app/services/get-and-store-components/store-base-components.service';
import { FeatureContainerListItem } from '../../../../../paragraph-edit/feature-container-list-item.model';
import { FeatureContainerListItemService } from '../../../../../paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container-list-item/feature-container-list-items.service';
import { FeatureContainer } from '../../../../../paragraph-edit/feature-container.model';
import { FeatureContainerService } from '../../../../../paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container/feature-container.service';

@Component({
  selector: 'app-toggle-single-word-button-list',
  templateUrl: './toggle-single-word-button-list.component.html',
  styleUrls: ['./toggle-single-word-button-list.component.css']
})
export class ToggleSingleWordButtonListComponent implements OnInit , OnDestroy {

  user: string 
  // updatingUserFromAuthLogin: Subscription
  thisTypeOfComponent: string = 'toggleSingleWordButton'

  toggleSingleWordButton: ToggleSingleWordButton ;
  toggleSingleWordButtons: ToggleSingleWordButton[];
  toggleSingleWordButtonDetail: ToggleSingleWordButtonDetail;
  toggleSingleWordButtonDetails: ToggleSingleWordButtonDetail[];

   /** limit on the number of features to add */
   createButtonLimit : boolean ;
   toggleSingleWordButtonLimit = 25 ;

   @Input() dropId : number ;
   @Input() currentI : number ;
   @Input() selectedIndex_id: string ;
   selectedIndex: number  ;
   @Input() weAreInMultiFeatureEdit: boolean;

   @Output() changeMultiFeatureIndex = new EventEmitter<Feature>();
   @Output() eventIndex: number ;
   @Output() deleteSingleFeatureItem = new EventEmitter<number>();

   feature : Feature ;
   index : number ;

   @ViewChild('tabs') tabGroup: MatTabGroup

   /** properties on the next/previous button */

   nextButtonName : string;
   nextButtonIndex: number ;
   totalToggleButtons: number ;
   count: number ;

   /** this serve as new tab index when creating a new button */
   newTabIndex0: number ;
   newTabIndex: number ;

   @Input() isParagraphEdit: boolean; 

   
     isLoadingList: boolean 
     isLoading : boolean
     error = null 

     updateOnUserEmail: Subscription

     initialPersonalToggleSingleWordButtonsHaveBeenStoredSubscription: Subscription 

     @Output() holdOnCreateUpdateButtons = new EventEmitter<void>()

     featureContainerPlusParentsHaveBeenStored: Subscription


  constructor(private toggleSingleWordButtonService: ToggleSingleWordButtonService ,
              private postComponentsHttpRequestService: PostComponentsHttpRequestService , 
              private userObjectUpdateService: UserObjectUpdateService , 
              private storeBaseComponentsService: StoreBaseComponentsService ,
              private featureContainerListItemService: FeatureContainerListItemService ,
              private featureContainerService: FeatureContainerService , 
              private createNewFeatureContainerAndAddToFeatureContainerListItemService: CreateNewFeatureContainerAndAddToFeatureContainerListItemService ,
              private adviseIfHttpStoreComponentsCallHasBeenMadeService: AdviseIfHttpStoreComponentsCallHasBeenMadeService 
              ) { }

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
  })

  this.isLoading = true 
  this.isLoadingList = true 

  if ( this.user === null ){console.log('is going directly to continue as NULL user'), this.continueInitialOnInit()}
  if ( this.user !== null ){
    if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.toggleSingleWordButtonsHasBeenStored === true  ){ console.log('is going direvtly to continue'),   this.continueInitialOnInit() }
    if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.toggleSingleWordButtonsHasBeenStored === false ){ console.log('is calling http'), this.storeBaseComponentsService.callBaseComponentsListByUserAndType(this.user , 'toggleSingleWordButton') }
  }

  this.initialPersonalToggleSingleWordButtonsHaveBeenStoredSubscription = this.toggleSingleWordButtonService.toggleSingleWordButtonsHaveBeenStored
      .subscribe(()=>{
        this.continueInitialOnInit()
        this.adviseIfHttpStoreComponentsCallHasBeenMadeService.toggleSingleWordButtonsHasBeenStored = true 
  })

  this.featureContainerPlusParentsHaveBeenStored = this.featureContainerListItemService.newFeatureContainerAndparentsHaveBeenStored
  .subscribe((feature_plus_typeOfComponent: any )=> {

    const currentFeature:Feature = feature_plus_typeOfComponent.feature 
    const currentTypeOfComponent: string = feature_plus_typeOfComponent.featureContainerName

    console.log(feature_plus_typeOfComponent)
    if ( currentTypeOfComponent === this.thisTypeOfComponent){
      this.onEmitToMultiFeatureEditChangedIndex(currentFeature)
    }

})

}

continueInitialOnInit(){

  console.log('IT S CONTINUING INITIAL ONINIT')
  const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('toggleSingleWordButton')

  this.toggleSingleWordButtons = []
  for ( let i=0; i<personalFeatureContainerListItem.featureContainer_ids.length ; i++ ){
        let currentFeatureContainer: FeatureContainer = this.featureContainerService.getFeatureContainerBy_id(personalFeatureContainerListItem.featureContainer_ids[i])
        this.toggleSingleWordButtons.push(this.toggleSingleWordButtonService.getToggleSingleWordButtonBy_id(currentFeatureContainer.componentReference_id))
  }
  console.log(this.toggleSingleWordButtons)

  this.selectedIndex = this.toggleSingleWordButtons.findIndex(x=> x._id === this.selectedIndex_id)

  /** checking if the buttons number have reached the limit */
  if( this.toggleSingleWordButtons.length < this.toggleSingleWordButtonLimit  ){ this.createButtonLimit = true }
  if( this.toggleSingleWordButtons.length >= this.toggleSingleWordButtonLimit  ){ this.createButtonLimit = false}

  this.totalToggleButtons = this.toggleSingleWordButtons.length;
  this.count = this.selectedIndex;
  this.nextButtonIndex = this.selectedIndex+1 
  this.nextButtonName = this.toggleSingleWordButtons[this.selectedIndex].name

  console.log( 'total toggle buttons',this.totalToggleButtons)


  if ( this.user !== null ){ this.isLoadingList = false , this.isLoading = false }
  if ( this.user === null ){  setTimeout(()=>{ this.isLoadingList = false , this.isLoading = false }, 200 )  }

}


   /** for each click we recreate a new feature  */
onLinkClick($event) {

  this.index = this.tabGroup.selectedIndex 

  const newOwner = this.user ; 
  const newIsNew = false
  const newDropId = this.currentI;
  const newFeature_id = this.toggleSingleWordButtons[this.index]._id
  const newFeatureName = 'toggleSingleWordButton' ;
  const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
 
  this.feature = newFeature;
 
  this.changeMultiFeatureIndex.emit(this.feature)
  this.eventIndex = this.currentI ;
 
  console.log('drop id =', this.currentI)
  console.log(this.feature)

   }

   onNextButton(){

    if ( this.tabGroup.selectedIndex+1 === this.totalToggleButtons){
      this.index = 0;
    const newOwner = this.user ; 
    const newIsNew = false
    const newDropId = this.currentI;
    const newFeature_id = this.toggleSingleWordButtons[this.index]._id
    const newFeatureName = 'toggleSingleWordButton' ;
    const newFeature = new Feature (  newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
   
    this.feature = newFeature;
   
    this.changeMultiFeatureIndex.emit(this.feature)
    this.eventIndex = this.currentI ;
   
    console.log('drop id =', this.currentI)
    console.log(this.feature)

    } else if ( this.tabGroup.selectedIndex < this.totalToggleButtons){
      this.index = this.tabGroup.selectedIndex+1 
      const newOwner = this.user ; 
      const newIsNew = false
      const newDropId = this.currentI;
      const newFeature_id = this.toggleSingleWordButtons[this.index]._id
      const newFeatureName = 'toggleSingleWordButton' ;
      const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
     
      this.feature = newFeature;
     
      this.changeMultiFeatureIndex.emit(this.feature)
      this.eventIndex = this.currentI ;
     
      console.log('drop id =', this.currentI)
      console.log(this.feature)
  
    }

    console.log('this index ',this.index)

   }

   onPreviousButton(){

    if ( this.tabGroup.selectedIndex === 0){
      this.index = this.totalToggleButtons-1;

    const newOwner = this.user ; 
    const newIsNew = false
    const newDropId = this.currentI;
    const newFeature_id = this.toggleSingleWordButtons[this.index]._id
    const newFeatureName = 'toggleSingleWordButton' ;
    const newFeature = new Feature ( newOwner, newIsNew, newDropId ,newFeature_id, newFeatureName) ;
   
    this.feature = newFeature;
   
    this.changeMultiFeatureIndex.emit(this.feature)
    this.eventIndex = this.currentI ;
   
    console.log('drop id =', this.currentI)
    console.log(this.feature)


  } else if ( this.tabGroup.selectedIndex > 0){
    this.index = this.tabGroup.selectedIndex-1 ; 

      const newOwner = this.user ; 
      const newIsNew = false
      const newDropId = this.currentI;
      const newFeature_id = this.toggleSingleWordButtons[this.index]._id
      const newFeatureName = 'toggleSingleWordButton' ;
      const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
     
      this.feature = newFeature;
     
      this.changeMultiFeatureIndex.emit(this.feature)
      this.eventIndex = this.currentI ;
     
      console.log('drop id =', this.currentI)
      console.log(this.feature)
  
    }

    console.log('this index ',this.index)

}

onCreate(){

  this.isLoading = true
  this.isLoadingList = true 
  this.createNewToggleSingleWordButton()

}
 

/** creating a new prefixed base for a new toggleSingleWordButton */
   createNewToggleSingleWordButton(){
    
    const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('toggleSingleWordButton')
    this.newTabIndex0 = personalFeatureContainerListItem.featureContainer_ids.length+1

    const new_id = 'toggleSingleWordBut'+this.newTabIndex0.toString();
    const newComponentName = 'toggleSingleWordButton'
    const newUIID = 'toggleSingleWordButton-'+this.newTabIndex0.toString()
    const newOwner = this.user ; 
    const newDateAndTime = null ;
    const newAllUsersAllowed = false ;
    const newUserListAllowed = [];
    const newName = 'new toggle button name '+ this.newTabIndex0.toString();
    
    const newToggleDetail = 'new toggle button '+ this.newTabIndex0.toString();
    const newToggleSingleWordDetail = new ToggleSingleWordButtonDetail (newToggleDetail);
    const newToggleSingleWordDetails = [newToggleSingleWordDetail];
    const newToggleSingleWordButton = new ToggleSingleWordButton (new_id , newComponentName, newUIID, newOwner, newDateAndTime, newAllUsersAllowed, newUserListAllowed, newName, newToggleSingleWordDetails)

    if ( this.user === null ){

      this.toggleSingleWordButtonService.onAddToggleSingleWordButton(newToggleSingleWordButton);

      const newOwner = this.user ; 
      const newIsNew = false
      const newDropId = this.currentI ;
      const newFeature_id = newToggleSingleWordButton._id
      const newFeatureName = 'toggleSingleWordButton' ;
      const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;

      this.feature = newFeature

      this.createNewFeatureContainerAndAddToFeatureContainerListItemService.createFeatureContainer_toAddOnFeatureContainerListItem(newFeature, this.user)

    }

    if ( this.user !== null ){
       /**
     * call to http request to post new button
     */
     this.postComponentsHttpRequestService.httpStoreComponent(this.user , 'toggleSingleWordButton', newToggleSingleWordButton ).subscribe((response: ToggleSingleWordButton)=> {

      this.toggleSingleWordButtonService.onAddToggleSingleWordButton(response);
     
 const newOwner = this.user ; 
 const newIsNew = false
 const newDropId = this.currentI ;
 const newFeature_id = response._id
 const newFeatureName = 'toggleSingleWordButton' ;
 const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;

 this.feature = newFeature

 console.log(this.toggleSingleWordButtons , this.feature)

 this.createNewFeatureContainerAndAddToFeatureContainerListItemService.createFeatureContainer_toAddOnFeatureContainerListItem(newFeature, this.user)
   
     }, error => {
      console.log(error)

      const errorMessage: string = "we weren't able to create the new component, check the network or try later."
      this.error = errorMessage
      this.isLoadingList = false 
      /**
       *  EMIT and event to paragraphEdit to put update or create buttons for 4,5 sec 
       */
      this.holdOnCreateUpdateButtons.emit()

      /**
       * return to previous set up without the new component after a while to be able to read the message 
       */
      setTimeout(()=>{
        this.error = null 
        this.isLoadingList = false 
        this.isLoading = false 
      },4000)
     })
  }
}

onEmitToMultiFeatureEditChangedIndex(toEmitFeature: Feature ){
  console.log('is emitting changed feature' , toEmitFeature )
  this.changeMultiFeatureIndex.emit(toEmitFeature)
  // once this feature is emitted the multiFeature Edit will change index and this whole 
  // component will restart again
}




   onDeleteSingleWordButton(){
     this.deleteSingleFeatureItem.emit(this.currentI)
  }

  ngOnDestroy(){
    this.updateOnUserEmail.unsubscribe()
    this.featureContainerPlusParentsHaveBeenStored.unsubscribe()
    this.initialPersonalToggleSingleWordButtonsHaveBeenStoredSubscription.unsubscribe()
  }

 


}
