import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-word',
  templateUrl: './single-word.component.html',
  styleUrls: ['./single-word.component.css']
})
export class SingleWordComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
