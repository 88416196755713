import { DatePipe } from '@angular/common';
import { Injectable, OnInit } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class TimeAlignService {


    /**
     * TO CHECK AND FIX TIME DIFFERENCE BETWEEN SERVER DATE AND LOCAL DATE 
     * 
     * 1- KNOWS SERVER DATE TIMEDIFFERENCE WITH UTC 
     * 2- GET LOCAL TIME DIFFERENCE 
     * 3- GET THE TIME DIFEFRENCE BETWEEN SERVER AND LOCAL AND MAKE THE OPERATIONS NECESSARY ON 
     * 4- FORM RESULT ITEMS 
     * 5- PARAGRAPH RESULT ITEMS 
     * 
     * let currentDate = new Date();
    const newDate = new Date(currentDate.getTime() + ( timezoneOffsetInMinutes * 60 * 1000));
    const newDatePlus = new Date(currentDate.getTime() + ( 120 * 60 * 1000));

    const newDateNow = this.datePipe.transform( newDate, "yyyy/MM/dd -- HH:mm:ss")
    const newdateNowPlus = this.datePipe.transform( newDatePlus, "yyyy/MM/dd -- HH:mm:ss")
     */

    /**
     * const dateString = '2023/05/09T21:00:00'; // Replace with your date string
       const date = new Date(dateString);
       date.setHours(date.getHours() + 2); // Add two hours to the date
       const formattedDate = date.toISOString().replace('Z', ''); // Format the date as a string without the GMT time zone offset
       console.log(formattedDate); // Output the formatted date string
     */
  serverTimeOffset_inMinute = -120 // (let newDate = new Date(currentDate.getTime() + ( serverTimeOffset_inMinute  * 60 * 1000));)

  localTime: DateTime

  drappBeginningOfTime : string = "2023-04-01"

  constructor( private datePipe: DatePipe) {
   }

  getLocalTime(): DateTime {
    return DateTime.local();
  }

  convertToLocalTime(userTime: string): DateTime {
    const userDateTime = DateTime.fromISO(userTime);
    return userDateTime.toLocal();
  }

  /**
   * the toServerTime() method now takes two arguments:
   * @param localDate 
   * @param hours 
   * @returns 
   * 
   *  the local date object and the number of hours to subtract from the local time to get the server time.
   */
  toServerTime(localDate: Date, hours: number): DateTime {
    const localDateTime = DateTime.fromJSDate(localDate);
    const serverDateTime = localDateTime.minus({ hours });
    return serverDateTime;
  }

  getDayOfTheWeek(date: Date): string {
    // const now = DateTime.local();
    const past = DateTime.fromJSDate(date);

    const dayOfWeek: string = past.weekdayLong;

    return dayOfWeek

  }

  getRelativeTime(date: Date): string[] {
    const now = DateTime.local();
    const past = DateTime.fromJSDate(date);
  
    const diffMinutes = Math.floor(now.diff(past, 'minutes').toObject().minutes);
    const diffHours = Math.floor(now.diff(past, 'hours').toObject().hours);
    const diffDays = Math.floor(now.diff(past.startOf('day'), 'days').toObject().days);
    const diffWeeks = Math.floor(now.diff(past.startOf('week').set({ weekday: 1 }), 'weeks').toObject().weeks);
    const diffMonths = Math.floor(now.diff(past.startOf('month'), 'months').toObject().months);
    const diffYears = Math.floor(now.diff(past.startOf('year'), 'years').toObject().years);
  
    const dayOfWeek = past.weekdayLong;
    const month = past.monthLong;
  
    /**
     * starting to add strings to relativeTime
     */
    let relativeTime: string[] = [];

    if ( diffMinutes === 0 ){ relativeTime.push(`just now`)
    } else if ( diffMinutes === 1 ) {
      relativeTime.push(`a minute ago`)
    } else if ( diffMinutes > 1 && diffMinutes < 60) {
      relativeTime.push(`${diffMinutes}minutes ago`);
    } 
    
    if (diffHours === 1) {
      relativeTime.push(`an hour ago`);
    } else if (diffHours > 1 && diffHours < 24) {
      relativeTime.push(`${diffHours}hours ago`);
    } 
  
    if (diffDays === 0 ) {
      relativeTime.push(`today`);
    } else if (diffDays === 1 ) {
        relativeTime.push(`yesterday`);
    } else if (diffDays > 1 ) {
      relativeTime.push(`${diffDays}days ago`);
    }
  
    relativeTime.push(dayOfWeek);
    relativeTime.push(month);
  
    relativeTime.push((past.day).toString());
    relativeTime.push((past.year).toString());
    relativeTime.push((past.hour).toString().padStart(2, '0')+':'+(past.minute).toString().padStart(2, '0'))

    
    
    if (diffWeeks === 0) {
      relativeTime.push('This week');
    } else if (diffWeeks === 1) {
      relativeTime.push('Last week');
    } else {
      relativeTime.push(`${diffWeeks} weeks ago`);
    }
    
    if (diffMonths === 0) {
      relativeTime.push('This month');
    } else if (diffMonths === 1) {
      relativeTime.push('Last month');
    } else if (diffMonths > 1 ) { // && diffMonths < 12
      relativeTime.push(`${diffMonths}months ago`);
    } 
  
    
    if (diffYears === 0) {
      relativeTime.push('This year');
    } else if (diffYears === 1) {
      relativeTime.push('Last year');
    } else {
      relativeTime.push(`${diffYears}years ago`);
    }
  
  
  
  
    console.log( date ,  relativeTime)
    return relativeTime;
  } 

  getRelativeTime_DayWeekMonthYearsAgo(date: Date): string {
    const now = DateTime.local();
    const past = DateTime.fromJSDate(date);
  
    const diffDays = Math.floor(now.diff(past.startOf('day'), 'days').toObject().days);
    const diffWeeks = Math.floor(now.diff(past.startOf('week').set({ weekday: 1 }), 'weeks').toObject().weeks);
    const diffMonths = Math.floor(now.diff(past.startOf('month'), 'months').toObject().months);
  
    /**
     * starting to add strings to relativeTime
     */
    let relativeTime: string ;

  
    if (diffDays === 0 ) {
      relativeTime = (`today`);
    } else if (diffDays === 1 ) {
        relativeTime = (`yesterday`);
    } else if (diffDays > 1 ) {
      relativeTime = (`${diffDays}days`);
    }
    
    
    if (diffWeeks === 1 ) {
      relativeTime = ('Last week');
    } else if (diffWeeks > 1 ) {
      relativeTime = (`${diffWeeks} weeks`);
    }
    
    if (diffMonths === 1 ) {
      relativeTime = ('Last month');
    } else if (diffMonths > 1 ) { // && diffMonths < 12
      relativeTime = (`${diffMonths}months`);
    } 
  
    // console.log( date ,  relativeTime)
    return relativeTime;
  } 

  getRelativeTime_Minutes_Hours(date: Date): string {
    const now = DateTime.local();
    const past = DateTime.fromJSDate(date);
  
    const diffMinutes = Math.floor(now.diff(past, 'minutes').toObject().minutes);
    const diffHours = Math.floor(now.diff(past, 'hours').toObject().hours);
    const diffDays = Math.floor(now.diff(past.startOf('day'), 'days').toObject().days);
    const diffWeeks = Math.floor(now.diff(past.startOf('week').set({ weekday: 1 }), 'weeks').toObject().weeks);
    const diffMonths = Math.floor(now.diff(past.startOf('month'), 'months').toObject().months);
    const diffYears = Math.floor(now.diff(past.startOf('year'), 'years').toObject().years);

    let relativeTime: string

    if ( diffMinutes === 0 ){ 
      relativeTime = (`now`)
    }  else if 
    ( diffMinutes > 0 && diffMinutes < 60) {
      relativeTime = (`${diffMinutes}min`);
    } 
    
    if (diffHours > 0 && diffHours < 24) {
      relativeTime = (`${diffHours}hrs`);
    } else if (diffDays === 1 ) {
      relativeTime = (`yesterday`);
    } else if (diffDays > 1 ) {
      relativeTime = (`${diffDays}d`);
    }



    return relativeTime

  }



  getDaysAgoDate_fromRelativeDate_intoStringsYYYMMDD( relativeDate: Date , daysAgo: number ): string[]{

      const today = relativeDate
      const targetDate = new Date(today);
      targetDate.setDate(today.getDate() - daysAgo);

      const daysAgoStart = this.datePipe.transform( targetDate, "yyyy:MM:dd" +'T'+"00:00:00" )
      const daysAgoEnd = this.datePipe.transform( targetDate, "yyyy:MM:dd" +'T'+"23:59:59" )
      const thisDateStart_End: string[] = [ daysAgoStart , daysAgoEnd ]

    return thisDateStart_End

  }



  getWeeksAgoDates_fromRelativeDate_intoStringsYYYMMDD(relativeDate: Date , numberOfWeeks: number): string[]{

    const today = relativeDate
    const targetWeekStart = new Date(today.setDate(today.getDate() - today.getDay() + 1 - (7 * numberOfWeeks)));
    const targetWeekEnd = new Date(today.setDate(today.getDate() + 6));
    
    const weeksAgoStarted = this.datePipe.transform( targetWeekStart, "yyyy:MM:dd" +'T'+"00:00:00" )
    const weeksAgoEnded = this.datePipe.transform( targetWeekEnd, "yyyy:MM:dd" +'T'+"23:59:59" )
    const thisDateStart_End: string[] = [ weeksAgoStarted , weeksAgoEnded ]

    return thisDateStart_End

  }



  getMonthsAgoDates_fromRelativeDate_intoStringsYYYMMDD(relativeDate: Date , numberOfMonths: number): string[]{

    const today = relativeDate
    const targetMonthStart = new Date(today.getFullYear(), today.getMonth() - numberOfMonths, 1);
    const targetMonthEnd = new Date(today.getFullYear(), today.getMonth() - numberOfMonths + 1, 0);

    const monthStart = this.datePipe.transform( targetMonthStart, "yyyy:MM:dd" +'T'+"00:00:00" )
    const monthEnd = this.datePipe.transform( targetMonthEnd, "yyyy:MM:dd" +'T'+"23:59:59" )
    const thisDateStart_End: string[] = [ monthStart , monthEnd ]

    return thisDateStart_End
  }

  given_DaysAgo_Provide_DateStart_DateEnd(numberOfDaysAgo: number ){

    const dateStart_dateEnd: string[] = []
    const currentDate = new Date()
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const server_timeDifference = this.serverTimeOffset_inMinute * 60 * 1000

    const targetDate = new Date(currentDate.getTime() - ( numberOfDaysAgo * millisecondsPerDay) + (server_timeDifference));
    console.log('current date => ',currentDate, 'TARGET DATE => ', targetDate )
    /**
     * finding date at server time 
     */
    const dateStart = this.datePipe.transform( targetDate, "yyyy:MM:dd" +'T'+"00:00:00" )
    const dateEnd = this.datePipe.transform( targetDate, "yyyy:MM:dd" +'T'+"23:59:59" )
    
    dateStart_dateEnd.push(dateStart)
    dateStart_dateEnd.push(dateEnd)
    console.log('DATE START - DATE END',dateStart_dateEnd)
    return dateStart_dateEnd

  }

  given_WeeksAgo_Provide_DateStart_DateEnd(numberOfWeeksAgo: number) {

    const server_timeDifference = this.serverTimeOffset_inMinute * 60 * 1000;
    const currentDate = new Date();
    const targetDate = new Date(currentDate.getTime() + server_timeDifference);
    console.log('current date => ', currentDate, 'TARGET DATE => ', targetDate);
  
    const today = currentDate.getDay();
    const daysAgo = numberOfWeeksAgo * 7 + (today - 1);
    const startDate = new Date(currentDate);
    startDate.setDate(startDate.getDate() - daysAgo);
    startDate.setHours(0, 0, 0, 0);
    const dateStart = this.datePipe.transform( startDate, "yyyy:MM:dd" +'T'+"00:00:00" )
  
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);
    endDate.setHours(23, 59, 59, 999);
    const dateEnd = this.datePipe.transform( endDate, "yyyy:MM:dd" +'T'+"23:59:59" )
  
    const dateStart_dateEnd:string[] = [dateStart, dateEnd] 
    console.log('DATE START - DATE END', dateStart_dateEnd);
  
    return dateStart_dateEnd;
  }
 

  given_MonthsAgo_Provide_DateStart_DateEnd(numberOfMonthsAgo: number){

    const server_timeDifference = this.serverTimeOffset_inMinute * 60 * 1000
    const dateStart_dateEnd: string[] = []
    const currentDate = new Date() 
    const targetDate = new Date(currentDate.getTime() + (server_timeDifference));
    console.log('current date => ',currentDate, 'TARGET DATE => ', targetDate )

    targetDate.setMonth(targetDate.getMonth() - numberOfMonthsAgo);

    const firstDayOfMonth = new Date(targetDate.getFullYear(), targetDate.getMonth(), 1);
    const dateStart = this.datePipe.transform( firstDayOfMonth, "yyyy:MM:dd" +'T'+"00:00:00" )

    const lastDayOfMonth = new Date(targetDate.getFullYear(), targetDate.getMonth() + 1, 0);
    const dateEnd = this.datePipe.transform( lastDayOfMonth, "yyyy:MM:dd" +'T'+"23:59:59" )

    dateStart_dateEnd.push(dateStart)
    dateStart_dateEnd.push(dateEnd)
    console.log('DATE START - DATE END',dateStart_dateEnd)

    return dateStart_dateEnd
  }

  getDaysDifference(startDate: Date, endDate: Date): number {
    // Get the timestamps of the two dates
    const startTime = startDate.getTime();
    const endTime = endDate.getTime();

    // Calculate the difference in milliseconds
    const difference = endTime - startTime;

    // Convert milliseconds to days (milliseconds / (1000 ms/s * 60 s/min * 60 min/h * 24 h/day))
    const differenceInDays = Math.floor(difference / (1000 * 60 * 60 * 24));

    return differenceInDays;
  }




}