import { Component, Input, OnInit } from '@angular/core';
import { SingleDropDownSelectDetailResult } from './single-drop-down-select-detail-result.model';

@Component({
  selector: 'app-single-drop-down-select-result',
  templateUrl: './single-drop-down-select-result.component.html',
  styleUrls: ['./single-drop-down-select-result.component.css']
})
export class SingleDropDownSelectResultComponent implements OnInit {

@Input()  singleDropDownSelectDetailResult: SingleDropDownSelectDetailResult
 
  constructor() { }

  ngOnInit(): void {}

}
