<!-- <p>form-results works!</p> -->


<mat-toolbar color="primary" class="mat-toolbar" >
</mat-toolbar>

<mat-toolbar color="primary"  class="mat-toolbar">
<mat-toolbar-row color="primary" >

<!-- -------------------DESKTOP ------------------------------------------------------------------- -->
               <button
               *ngIf="(isHandset$ | async) === false"
               type="button"
               aria-label="Toggle sidenav"
               mat-icon-button
               (click)="onIsDesktop()"
               (click)="drawer.toggle()">

               <!-- <mat-icon style="color:white" aria-label="Side nav toggle icon"> menu </mat-icon> -->
               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-funnel" viewBox="0 0 16 16">
                <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z"/>
              </svg>
                </button>

            
              
                  <h5 *ngIf="(isHandset$ | async) === false" >
                    <span  
                    class="pointer"
                    (click)="onIsDesktop()"
                    (click)="drawer.toggle()" style="color: white "> 
                      filters  
                    </span>
                  </h5>
                
                
 <!-------------------------------------------------------------------------------------------  -->

 <!-- -------------MOBILE --------------------------------------------------------------------- -->
             
                <div *ngIf="!hideMobileFilters">
                <button
                *ngIf="isHandset$ | async"
                type="button"
                aria-label="Toggle sidenav"
                mat-icon-button
                (click)="onIsMobile()"
                (click)="drawer.toggle()">

                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-funnel" viewBox="0 0 16 16">
                  <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z"/>
                </svg>
  
          
                </button>
                </div>
              
      
                <div *ngIf="!hideMobileFilters">
                <div *ngIf="!isMobile">
                  <h5  *ngIf="isHandset$ | async" ><span style="color: white"> filters  </span></h5>
                </div>
                </div>
              
               
              <!-- return button -->
              <div *ngIf="hideMobileFilters">
                  <span *ngIf="isHandset$ | async">
                    <a  *ngIf="aFormResultHasBeenSelected"
                    (click)="onReturnToFormResultsIndex()" 
                    style="color: white"
                    routerLink="/results"
                    routerLinkActive="activebutton">
                    
                    <svg style="color:white" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
                      <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
                    </svg>
                     </a>
                  </span>
              </div>
              <!-- END return button -->
                 
                
   <!-- ---------------------------------------------------------------------------------------------------------  -->
              
                 <span class="spacer"></span> 

                 <div  *ngIf="isHandset$ | async" >
                  <a   *ngIf="!aFormResultHasBeenSelected"
                      
                       class="navbar-brand" >
                 <span *ngIf="isMobile" style="color: white"> result list  </span>
                 </a>
                 </div>
               
                 <div  *ngIf="(isHandset$ | async) === false" >
                  <a
                 class="navbar-brand" >
                 <span style="color: white"> result list  </span>
                 </a>
                 </div>
               

       

              </mat-toolbar-row> 
             </mat-toolbar> 



             <mat-sidenav-container class="container1">
                <mat-sidenav 
                style="overflow: hidden;"
                 #drawer 
                 [class]="(isHandset$ | async) ? 'full-mobile-sidenav' : 'sidenav'"  
                [fixedInViewport]="true"
                fixedTopGap="130"
                [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
                [mode]="(isHandset$ | async) ? 'over' : 'side'"
                [opened]="(isHandset$ | async) === false">
           
                       <mat-nav-list align="center"  style="overflow: hidden;"> 
                                 <app-form-results-filter 
                                 [resetFormResultIndexList]="resetFormResultIndexList.asObservable()"
                                 [switchToIsSubmittedOnResultListIsSelectedFromRouting]="buttonSubmitSwitchToClear.asObservable()"
                                 (closeSidenavDrawerOnSubmit)='onCloseSideNavDrawerSubmitted()'
                                 (closeSidenavDrawerOnClear)='onCloseSideNavDrawerCleared()'>
                                 </app-form-results-filter>
                       </mat-nav-list>
           
              </mat-sidenav>

             
  <mat-sidenav
  style="overflow: hidden;"
  *ngIf="!formResultListHasBeenSelected"
  #drawer 
  class="sidenav" 
  [fixedInViewport]="true"
  fixedTopGap="130"
  [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
  [mode]="(isHandset$ | async) ? 'over' : 'side'"
  [opened]="(isHandset$ | async) === false"
  position="end">

          <mat-nav-list  *ngIf="!formResultListHasBeenSelected"  style="overflow: hidden;"> 
              <app-form-results-index
               [weAreInMobileMode]="weAreInMobileMode"
               [resetFormResultIndexList]="resetFormResultIndexList.asObservable()"
               (updateFormResultsOnChoosenFormResult)="onUpdateSelectedFormResult()">
              </app-form-results-index>
          </mat-nav-list>

  </mat-sidenav>
           
                   <mat-sidenav-content #sidenavContent
                                        class="scrollable"
                                        style="background-color:rgb(242, 248, 250) ; padding: 8px ;" >


                    <!-- START ROUTER OUTLET  -->
            
                <router-outlet></router-outlet>
                    
                    <!-- END ROUTER OUTLET  -->
         


<!-- mobile application side nav content ----------------------------->
                <div  *ngIf="isHandset$ | async" >

                      <div *ngIf="!formResultListHasBeenSelected" >
                        <app-form-results-index
                         *ngIf="!aFormResultHasBeenSelected"
                         [weAreInMobileMode]="weAreInMobileMode"
                         [resetFormResultIndexList]="resetFormResultIndexList.asObservable()"
                         (updateFormResultOnBeingOnMobileMode)="onUpdateMobileModeStatusFromFormResultsIndex()"
                         (updateFormResultsOnChoosenFormResult)="onUpdateSelectedFormResult($event)">
                       </app-form-results-index>
                      </div>

                </div>



  <a align="end" href="https://github.com/angular/components/blob/main/LICENSE"> Code licensed under an MIT-style License.</a>
               
                </mat-sidenav-content>  
  <!-- END  mobile application side nav content ---------------------------------------------------------- -->
           
                 
           </mat-sidenav-container>
           
       
           
                  
 