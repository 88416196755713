<!-- <p>multi-entry-calendar-edit works!</p> -->

<div class="row">
    <div class="col-xs-12">
        <form [formGroup]="multiEntryCalendarEditForm" (ngSubmit)="onSubmit()">

            <div class="row">
                <div class="col-xs-8">

                    <mat-form-field style="width: 250px" appearance="outline">
                        <mat-label>component name</mat-label>
                        <input 
                        matInput
                        type="text"
                        id="name"
                        formControlName="name"
                        >
                    </mat-form-field>
                    <mat-error *ngIf="!multiEntryCalendarEditForm.get('name').valid"  >Please enter a valid name, maximum 30 letters </mat-error>
                </div>
    
                <div class="col-xs-4">
                   
                </div>
            </div>

            <hr>

            <div class="row">
                <div class="col-xs-12">

                    <mat-form-field style="width: 400px" appearance="outline">
                        <mat-label>description</mat-label>
                        <input 
                        matInput
                        type="text"
                        id="description"
                        formControlName="description"
                        >
                    </mat-form-field>
                    <mat-error *ngIf="!multiEntryCalendarEditForm.get('description').valid"  >Please enter a valid name, maximum 50 letters </mat-error>
                </div>
    
            </div>

            <br>

            <div class="row">
                <div class="col-xs-12">
                    <button type="submit" class="btn btn-primary"> update</button>
                </div>
            </div>


        </form>
    </div>
</div>