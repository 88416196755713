import { Injectable } from "@angular/core";

@Injectable ({
    providedIn: 'root' 
})

export class IsOnlineOfflineService {



    isOnline: boolean

    constructor(){
        this.isOnline = navigator.onLine
    }

    /**
     * coming from header component 
     */
    checkIsOnlineOffline(){
        return this.isOnline
    }

    changeOnlineOfflineStatus(isOnline: boolean){
        this.isOnline = isOnline
        console.log('the app is now Online => ', this.isOnline)
    }


}