import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-round-single-select',
  templateUrl: './round-single-select.component.html',
  styleUrls: ['./round-single-select.component.css']
})
export class RoundSingleSelectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
