<!-- <p hidden>paragraph-item works!</p> -->


<div class="paragraphItemFrame" > 
 
  <div *ngIf="isFormItem">
  <div class="row">
    <div class="col-xs-12">
       <div align="center" 
            style="color: #3f51b5;">
            {{paragraph.desc}}
       </div>
    </div>
  </div>
  <br>
  </div>
  
 

 
  <div   class="paragraphItemFrame2">
   
    <div  *ngFor="let feature of paragraph.features ; let i = index " >


       <div> 

          <div [ngSwitch]="feature.featureName">

            <div class="paragraphItemFrame3" *ngSwitchCase="'wordInput'">
              <app-word-input 
              [isTabItem]="false"
              [isParagraphEdit]="isParagraphEdit"
              [feature]="feature"
              (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)' 
              [noNeeded]="true"
              [index_id]="feature.feature_id"
              >
              </app-word-input>
            </div>

          <div class="paragraphItemFrame3" *ngSwitchCase="'multiFeatures'" >
            <app-multi-features 
          [isTabItem]="false"
          [feature]="feature"
          [isParagraphEdit]="isParagraphEdit"
          (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)'
          [noNeeded]="true"
          [index_id]="feature.feature_id"
          [paragraphEdit_selectedIndex_id]="feature.feature_id">
          </app-multi-features>
          <!--  [subscribeFeatureResultOneByOne]="subscribeToFeaturesOneByOne.asObservable()"  -->
          </div>
          

          <div class="paragraphItemFrame4" *ngSwitchCase="'tabs'">
            <app-tab-item
            [isTabItem]="false"
            [feature]="feature"
            [isUnderTabs]="isUnderTabs"
            [isParagraphEdit]="isParagraphEdit" 
            (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)'    
            [noNeeded]="true" 
            [index_id]="feature.feature_id"
            [paragraphEdit_selectedIndex_id]="feature.feature_id" >
            </app-tab-item>
          </div>

          <div class="paragraphItemFrame3" *ngSwitchCase="'fixLayout'">
            <app-fix-layout-item
            [isTabItem]="false"
            [feature]="feature"
            [isParagraphEdit]="isParagraphEdit" 
            (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)'
            [noNeeded]="true"
            [index_id]="feature.feature_id"
            >
            </app-fix-layout-item>
          </div>
        

          <div class="paragraphItemFrame3" *ngSwitchCase="'fixText'">
            <app-fix-text-item
            [isTabItem]="false"
            [feature]="feature"
            [isParagraphEdit]="isParagraphEdit"
            (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)'
            [noNeeded]="true"
            [index_id]="feature.feature_id"
            >
            </app-fix-text-item>
          </div>

          <div class="paragraphItemFrame3" *ngSwitchCase="'wordInput+sentence'">
            <app-word-hint-input 
          [isTabItem]="false"
          [feature]="feature"
          (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)'
          [noNeeded]="true" 
          [index_id]="feature.feature_id" 
          >
          </app-word-hint-input> 
          </div>
          

          <div class="paragraphItemFrame3" *ngSwitchCase="'oneDateCalendar'">
            <app-calendar-item
            [isTabItem]="false"
            [feature]="feature"
            [isParagraphEdit]="isParagraphEdit"
            (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)'
            [noNeeded]="true"
            [index_id]="feature.feature_id">
            </app-calendar-item>
          </div>
       

          <div class="paragraphItemFrame4" *ngSwitchCase="'multiEntryCalendar'">
            <app-multi-entry-calendar-item
            [isTabItem]="false"
            [feature]="feature"
            [isParagraphEdit]="isParagraphEdit"
            (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)'
            [noNeeded]="true"
            [index_id]="feature.feature_id"
            >
            </app-multi-entry-calendar-item>
          </div>

          <div class="paragraphItemFrame4" *ngSwitchCase="'sharedCalendarStatus'">
            <app-shared-calendar-status-item trackVisibility 
            (visibile)="onComponentVisible($event , i)"
            [isComponentVisible]="adviseInnerComponentIsItVisible.asObservable()"
            [isTabItem]="false"
            [feature]="feature"
            [isParagraphEdit]="isParagraphEdit"
            (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)'
            [noNeeded]="true"
            [index_id]="feature.feature_id">
            </app-shared-calendar-status-item>
          </div>

          <div class="paragraphItemFrame3" *ngSwitchCase="'calculatorMixTable'">
            <app-calculator-mix-table-item
            [isTabItem]="false"
            [feature]="feature"
            [isParagraphEdit]="isParagraphEdit"
            (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)'
            [noNeeded]="true"
            [index_id]="feature.feature_id"
            >
            </app-calculator-mix-table-item>
          </div>
         

          <div class="paragraphItemFrame3"   *ngSwitchCase="'dropDownSelect'">
            <app-drop-down-select-item
          [isTabItem]="false"
          [isParagraphEdit]="isParagraphEdit"
          [feature]="feature"
          (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)'
          [noNeeded]="true"
          [index_id]="feature.feature_id"
        >
          </app-drop-down-select-item>
          </div>
          

          <div class="paragraphItemFrame3" *ngSwitchCase="'checkBoxSelect'">
            <app-check-box-select-item 
          [isTabItem]="false"
          [isParagraphEdit]="isParagraphEdit"
          [feature]="feature"
          (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)'
          [noNeeded]="true"
          [index_id]="feature.feature_id"
          *ngSwitchCase="'checkBoxSelect'">
          </app-check-box-select-item>
          </div>
          

          <app-qr-code-generator-item
          *ngSwitchCase="'qrCodeType'"
          [feature]="feature">
          </app-qr-code-generator-item>

          <app-qr-code-scanner
          *ngSwitchCase="'ScanQRCode'"
          [feature]="feature">
          </app-qr-code-scanner>

          <div class="paragraphItemFrame4" *ngSwitchCase="'comment'">
            <app-comment
            [isTabItem]="false"
            [isParagraphEdit]="isParagraphEdit"
            [feature]="feature"
            (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)'
            >
            </app-comment>
          </div>
         


          <app-word-less-input 
          [isTabItem]="false"
          [feature]="feature"
          (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)'
          [noNeeded]="true" 
          [index_id]="feature.feature_id" 
          *ngSwitchCase="'word simple'">
          </app-word-less-input>

          <app-phrase-button 
          [isTabItem]="false"
          [feature]="feature"
          (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)'
          *ngSwitchCase="'phrase'">
          </app-phrase-button>

          <app-form-input   
          [isTabItem]="false"
          [feature]="feature" 
          (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)'
          *ngSwitchCase="'form'">
          </app-form-input>

          <app-horizon-add  
          [isTabItem]="false"
          [feature]="feature" 
          (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)'
          *ngSwitchCase="'mix form'">
          </app-horizon-add>
        </div>
  
       </div>
      
    </div>  
    
  </div>

</div>






