import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';

@Component({
  selector: 'app-form-not-found',
  templateUrl: './form-not-found.component.html',
  styleUrls: ['./form-not-found.component.css']
})
export class FormNotFoundComponent implements OnInit {

  constructor ( private router: Router,
                private route: ActivatedRoute ,) { }

  ngOnInit() {

    console.log('is LOADING')
    setTimeout(()=> {
      /**
      * navigate to reload component for then come back to form item again , serve as restarter for this component 
      */
    this.router.navigate(['create-form'], {relativeTo: this.route.parent});
    }, 750)
    console.log('timer gone')
  }

}
