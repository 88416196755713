import { Injectable } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { AuthService } from "../auth-services/auth.service";

import { SingleWord } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/single-word/single-word.model";
import { SingleWordDetail } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/single-word/single-word-detail.model";
import { SingleWordInput } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/single-word/single-word-input-list/single-word-input.model";
import { SingleWordInputDetail } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/single-word/single-word-input-list/single-word-input-detail.model";
import { ToggleSingleWordButton } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/buttons/toggle-single-word-button/toggle-single-word-button.model";
import { ToggleSingleWordButtonDetail } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/buttons/toggle-single-word-button/toggle-single-word-button-detail.model";
import { SingleDropDownSelect } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/select/select-list/single-drop-down-select/single-drop-down-select.model";
import { SingleSlider } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/slider/single-slider/single-slider.model";
import { SingleSliderDetail } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/slider/single-slider/single-slider-detail.model";

import { Recipient } from "../../forms/form-list/form-item/recipient/recipient.model";
import { Chat } from "../../chats/chat.model";

import { WordInput } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/word/word-input.model";
import { WordInputDetail } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/word/word-input-detail.model";
import { FixText } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/texts/fix-text-list/fix-text.model";
import { FixTextDetail } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/texts/fix-text-list/fix-text-detail.model";
import { FixLayout } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/layout/fix-layout-list/fix-layout.model";
import { FixLayoutTableTD } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/layout/fix-layout-list/fix-layout-table-td.model";
import { FixLayoutTableTR } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/layout/fix-layout-list/fix-layout-table-tr.model";
import { SingleDateCalendar } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/calendar-list/single-date-calendar.model";
import { SingleDateCalendarDetail } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/calendar-list/single-date-calendar-detail.model";
import { MultiEntryCalendar } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/multi-entry-calendar/multi-entry-calendar-list/multi-entry-calendar.model";
import { CalculatorMixTable } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calculator/calculator-mix-table-list/calculator-mix-table.model";
import { DescriptionFrame } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calculator/calculator-mix-table-list/description-frame.model";
import { DescriptionPart } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calculator/calculator-mix-table-list/description-part.model";
import { DropDownSelect } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/select/select-list/drop-down-select/drop-down-select.model";
import { DropDownSelectDescription } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/select/select-list/drop-down-select/drop-down-select-description.model";

import { MultiFeatures } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/multi-features-list/multi-features.model";
import { Feature } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/feature.model";

import { Tab } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/tabs/tab-list/tab.model";
import { TabDetail } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/tabs/tab-list/tab-detail.model";

import { Paragraph } from "../../forms/form-list/form-item/paragraph/paragraph.model";
import { Form } from "../../forms/form.model";
import { ParagraphFeatureContainer } from "../../forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature-container.model";
import { FeatureTopic } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-topic.model";

import { ParagraphTopic } from "../../forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.model";
import { FormIndexTopic } from "../../forms/form-list/form-index-topic-list/form-index-topic.model";
import { FormFeatureContainer } from "../../forms/form-list/form-index-topic-list/form-feature-container.model";
import { ParagraphTopicIndex } from "../../forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-topic-index.model";
import { FormIndexTopicListItem } from "../../forms/form-list/form-index-topic-list/form-index-topic-list-item.model";
import { FeatureIndexTopicList } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-index-topic-list.model";
import { FeatureContainerListItem } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-container-list-item.model";
import { FeatureContainer } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-container.model";
import { RecipientIndex } from "../../forms/form-list/form-edit/recipient-index-list/recipient-index.model";
import { RecipientIndexColumn} from "../../forms/form-list/form-edit/recipient-index-list/recipient-index-column.model";
import { RecipientIndexListItem } from "../../forms/form-list/form-edit/recipient-index-list/recipient-index-list-item.model";
import { ChatRoom } from "../../chats/chat-room.model";
// import { Group } from "../../chats/group.model";
import { FormResult } from "src/app/forms/form-results/form-result.model";
import { RecipientFeature } from "src/app/forms/form-list/form-edit/recipient-index-list/recipient-feature.model";
import { UserProfile } from "src/app/auth/user-profile/user-profile.model";
import { PlanType } from "src/app/auth/user-profile/plan-type.model";
import { PlanTimeTable } from "src/app/auth/user-profile/plan-time-table.model";
import { Contact } from "src/app/contacts/contact.model";
import { ContactDetails } from "src/app/contacts/contact-details.model";
import { SharedCalendarStatus } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/shared-calendar-status-list/shared-calendar-status.model";
import { RangeDatesInfo } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/shared-calendar-status-list/range-dates-info.model";
import { DateStartEnd } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/shared-calendar-status-list/date-start-end.model";
import { RangeInfo } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/shared-calendar-status-list/range-info.model";
import { InnerLinkDetail } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/shared-calendar-status-list/inner-link-item/inner-link-detail.model"
import { UsersAccess } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/shared-calendar-status-list/users-access-edit/users-access.model";
import { CanDo } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/shared-calendar-status-list/users-access-edit/who-can-do.model";
import { InnerArraySelect } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/shared-calendar-status-list/inner-array-select.model";



@Injectable ({
    providedIn: 'root' 
})

export class InitialNullSetupService {


userProfileChangedBackToNull = new Subject<UserProfile>()
contactsChangedBackToNull = new Subject<Contact[]>()

// --------- START base components back to null subject ----------------------------------
singleWordsChangedBackToNull = new Subject<SingleWord[]>()
singleWordInputsChangedBackToNull = new Subject<SingleWordInput[]>()
toggleSingleWordButtonsChangedBackToNull = new Subject<ToggleSingleWordButton[]>()
singleDropDownSelectsChangedBackToNull = new Subject<SingleDropDownSelect[]>()
singleSlidersChangedBackToNull = new Subject<SingleSlider[]>()

recipientsChangedBackToNull = new Subject<Recipient[]>()
chatsChangedBackToNull = new Subject<Chat[]>()
// groupsChangedBackToNull = new Subject<Group[]>()

wordsInputChangedBackToNull = new Subject<WordInput[]>()
/**
 * trial for shared items
 */
sharedWordsInputChangedBackToNull = new Subject<WordInput[]>()

fixTextsChangedBackToNull = new Subject<FixText[]>()
fixLayoutsChangedBackToNull = new Subject<FixLayout[]>()
singleDateCalendarsChangedBackToNull = new Subject<SingleDateCalendar[]>()
multiEntryCalendarsChangedBackToNull = new Subject<MultiEntryCalendar[]>()
calculatorMixTablesChangedBackToNull = new Subject<CalculatorMixTable[]>()
dropDownSelectsChangedBackToNull = new Subject<DropDownSelect[]>()
sharedCalendarStatusChangedBackToNull = new Subject<SharedCalendarStatus[]>()

// --------- END base components back to null subject ----------------------------------

// --------- START multiBase components back to null subject ----------------------------------
multiFeaturesChangedBackToNull = new Subject<MultiFeatures[]>()
// --------- END multiBase components back to null subject ------------------------------------

// --------- START Tabs components back to null subject ----------------------------------
tabsChangedBackToNull = new Subject<Tab[]>()
// --------- END tabs components back to null subject ------------------------------------

// --------- START paragraphs components back to null subject ----------------------------------
paragraphsChangedBackToNull = new Subject<Paragraph[]>()
sharedParagraphsChangedBackToNull = new Subject<Paragraph[]>()

// sharedParagraphFeaturesChangedBackToNull = new Subject<ParagraphFeature[]>()
// --------- END paragraphs components back to null subject ------------------------------------

formsChangedBackToNull = new Subject<Form[]>()

recipientIndexChangedBackToNull = new Subject<RecipientIndex[]>()
recipientIndexListItemsChangedBackToNull = new Subject<RecipientIndexListItem[]>()
recipientIndexColumnChangedBackToNull = new Subject<RecipientIndexColumn[]>()
recipientFeatureChangeBackToNull = new Subject<RecipientFeature[]>()

featureContainersChangeBackToNull = new Subject<FeatureContainer[]>()
featureContainerListItemsChangeBackToNull = new Subject< FeatureContainerListItem[]>()
featureTopicsChangeBackToNull = new Subject<FeatureTopic[]>()
featureIndexTopicListChangeBackToNull = new Subject<FeatureIndexTopicList[]>()

paragraphTopicOfReferenceChangeBackToNull = new Subject<ParagraphTopic>()

paragraphFeatureContainersChangeBackToNull = new Subject<ParagraphFeatureContainer[]>()
paragraphTopicsChangeBackToNull = new Subject<ParagraphTopic[]>()
paragraphTopicIndexesChangeBackToNull = new Subject<ParagraphTopicIndex[]>()

sharedParagraphTopicsChangeBackToNull = new Subject<ParagraphTopic[]>()

formIndexTopicsChangeBackToNull = new Subject<FormIndexTopic[]>()
formIndexTopicListItemsChangeBackToNull = new Subject<FormIndexTopicListItem[]>()
formFeatureContainerChangeBackToNull = new Subject<FormFeatureContainer[]>()

formResultChangeBackToNull = new Subject<FormResult[]>()
formResultsListChangeBackToNull = new Subject<FormResult[]>()



constructor( private authService: AuthService){}

initialNullUserProfile: UserProfile = new UserProfile( null, 'userProfile', 'userProfile-1', null, false, [], null, null, null, false, false, false, null, null, null, null, 
                                                              new PlanType (null, 
                                                                            new PlanTimeTable ( null, null ),
                                                                            null )
                                                     )

initialNullContacts: Contact[] = [
    new Contact ( 'conct-1', 'contact', 'cont-1', null, false, [], null, null, 
                  [
                   new ContactDetails ('marie', 'marie@test.test', '+1234567890', false , false, false),
                   new ContactDetails ('james', 'james@test.test', '+1234567890', false, false, false ),
                   new ContactDetails ('kayla', 'kayla@test.test', '+1234567890', true, false, false),
                   new ContactDetails ('henry' , 'henry@test.test', '+1234567890', true, false, false)

                  ]
    )
]

initialUserEmail: string = null


initialNullSingleWords: SingleWord[]= [

    new SingleWord( 'singleW-1' , 'singleWord', 'singleWord-1', 'antoniohhd@gmail.com' , null ,
        false , [] , 'first single input', 
        [
            new SingleWordDetail ( 'food', 'fruit', 'fresh', 15 )
        ]
        
    ),
    new SingleWord ( 'singleW-2',  'singleWord', 'singleWord-2', 'antoniohhd@gmail.com' , null,
        false , [] , 'Second single input', 
        [
            new SingleWordDetail ( 'car', 'Ferrari','automotive', 20 )
        ]
        
    ),
    new SingleWord( 'singleW-3', 'singleWord', 'singleWord-3', 'antoniohhd@gmail.com' , null,
        false , [] , '3rd info', 
        [
            new SingleWordDetail ( 'tools', 'black&decker','brand', 18 )
        ]
        
    ),
];

initialNullSingleWordInputs: SingleWordInput[] = [

    new SingleWordInput( 'singleWordIn-1' , 'singleWordInput', 'singleWordInput-1',  'antoniohhd@gmail.com', null , 
        false , [] , 'amount', 
        [
            new SingleWordInputDetail ( 'amount', 'in usd', '1,000.00',  15 )
        ]  
    ),
    new SingleWordInput ( 'singleWordIn-2' , 'singleWordInput', 'singleWordInput-2',  'antoniohhd@gmail.com',  null , 
        false , [] , 'Second input', 
        [
            new SingleWordInputDetail ( 'boat', 'bergetti', 'marine', 20 )
        ]  
    ),
    new SingleWordInput( 'singleWordIn-3' , 'singleWordInput', 'singleWordInput-3',  'antoniohhd@gmail.com',  null , 
        false , [] , '3rd info', 
        [
            new SingleWordInputDetail ( 'wood', 'teak', 'nature', 18 )
        ]  
    ),
];

initialNullToggleSingleWordButtons: ToggleSingleWordButton[] = [

    new ToggleSingleWordButton ( 'toggleSingleWordBut-1' , 'toggleSingleWordButton', 'toggleSingleWordButton-1', 'antoniohhd@gmail.com', null,
        false , [], 'denomination' , 
        [
            new ToggleSingleWordButtonDetail ('USD'),
            new ToggleSingleWordButtonDetail ('EURO'),
            new ToggleSingleWordButtonDetail ('CNY'),
            new ToggleSingleWordButtonDetail ( 'IDR'),
            new ToggleSingleWordButtonDetail ('INR'),

        ]
    ),
    new ToggleSingleWordButton ( 'toggleSingleWordBut-2' , 'toggleSingleWordButton', 'toggleSingleWordButton-2',  'antoniohhd@gmail.com', null,
        false, [], 'industry' ,
        [
            new ToggleSingleWordButtonDetail ('telecom'),
            new ToggleSingleWordButtonDetail ('heavy Industry'),
            new ToggleSingleWordButtonDetail ('F & B')
        ]
    ),
    new ToggleSingleWordButton ( 'toggleSingleWordBut-3' , 'toggleSingleWordButton', 'toggleSingleWordButton-3',  'antoniohhd@gmail.com', null,
        false, [], 'good food',
        [
            new ToggleSingleWordButtonDetail ('pasta'),
            new ToggleSingleWordButtonDetail ('pesce'),
            new ToggleSingleWordButtonDetail ('carne'),
            new ToggleSingleWordButtonDetail ('verdura'),
            new ToggleSingleWordButtonDetail ('frutta')
        ]
    )
];

initialNullSingleDropDownSelects: SingleDropDownSelect[] = [

    new SingleDropDownSelect ( 'singleDropDownSel-1', 'singleDropDownSelect', 'singleDropDownSel-1', 'tony@mail.com', false, [], null, 'expenses', 'type', 
                              [ 'breakfast', 'lunch', 'dinner', 'drinks', 'gasoline', 'transportation', 'ticket']
    ),
    new SingleDropDownSelect ( 'singleDropDownSel-2', 'singleDropDownSelect', 'singleDropDownSel-2', 'tony@mail.com', false, [], null, 'family expenses', 'home', 
                              [ 'electricity', 'tv', 'phone', 'water', 'garbage', 'garden']
    )                      
];

initialNullSingleSliders: SingleSlider[] = [

        new SingleSlider ( 'singleSli-1', 'singleSlider', 'singleSlider-1', 'antoniohhd@gmail.com', false, [], null, 'market share' , 
                                  [
                                  new SingleSliderDetail ('market share','%', true, false , false, 100, 0, true, 1, true, 55, false, 1 )
                                  ]
        ),

        new SingleSlider ( 'singleSli-2', 'singleSlider', 'singleSlider-2', 'antoniohhd@gmail.com', false, [], null, 'total brix' , 
        [
        new SingleSliderDetail ('brix','pcs', true, false , false, 1000, 0, true, 50, true, 350, false, 50 )
        ]
),

        
    ];
    
initialNullRecipients: Recipient[]=   [

    new Recipient ( 
         
        'recipi-1', 'recipient', 'recipient-1', null ,null, null, 'chat-room-id-101',
        null, null, null, 'to customers',
        [], // 'test@test.test' this is recipientList
        [], // 'test@test.test'
        ['test@test.test'],
        ['test@test.test'] ,
        [],
        ''
    ),

    new Recipient ( 
        //'-MvDSf9yffPZrSK-tzx-', 'antoniohhd@gmail.com', null, 'MvIbvhvA2x-BaPaQhRU',
        'recipi-2', 'recipient', 'recipient-2', null , null, null,  'chat-room-id-102',
         null, null, null, 'to cs gangoover ',
         [],
        [],
       ['test@test.test'],
       ['test@test.test'],
        [],
        ''
    ),

    new Recipient ( 
        //'-MvG3xFFG8aBX-Admgif', 'antoniohhd@gmail.com', null,  '-MvLE19xKBCCm-ruJQqX' ,
      'recipi-3', 'recipient', 'recipient-3', null , null,  null,  'chat-room-id-103' ,
        null, null, null,  'tony' ,
        [],
        [],
        ['test@test.test'],
        ['test@test.test'],
        [],
        ''
    ),

    new Recipient ( 'recipi-4', 'recipient', 'recipient-4', null , null, null,  'chat-room-id-104' ,
         null, null, null, 'to bella ',
         [],
        [],
        ['test@test.test'],
        ['test@test.test'],
        [],
        ''
    )


];

//initialNullGroups: Group[] = []



initialNullChats: Chat[] = [

    new Chat ( 'chat-room-id-101',null , false, [], true, '15', null, 
                                 false, false, false, '--- --- --- '
    ),

    
    new Chat ( 'chat-room-id-102', null , false, [], true, '16', null, 
                               false, false, false, '--- --- --- '
    ),


    new Chat ( 'chat-room-id-103', null , false, [], true, '17',null, 
                    false, false, false, '--- --- --- '
    ),

    new Chat ( 'chat-room-id-104' , null , false, [], true, '18',null, 
                     false, false, false, '--- --- --- '
     )
]

/**
 *  wordInput initial null value
 */
initialNullWordInputs: WordInput[] = [ new WordInput ( 'wordIn-1' ,'wordInput', 'wordIn-1', null ,  false, [], null,'input 1' , 
                                  [ new  WordInputDetail ('regione' , 'Vicenza', 'hey',  16 ),]
)] ;


initialNullFixTexts: FixText[] = [

    new FixText ( 'fixT-1', 'fixText','fixText-1', 'tony@mail.com', false, [], null, 'contract 1', 
    [
        new FixTextDetail ( 'Fantastic Four (sometimes stylized as Fantastic 4) is a 2005 American superhero film based on the Marvel Comics team of the same name. It was directed by Tim Story, and released by 20th Century Fox. The film stars Ioan Gruffudd, Jessica Alba, Chris Evans, Michael Chiklis, Julian McMahon and Kerry Washington.'), 
        new FixTextDetail ( 'The Daunting Task of ‘Doctor Strange in the Multiverse of Madness’ as the Direct Sequel to Multiple Marvel Properties ')
    ]),

    new FixText ( 'fixT-2','fixText','fixText-2',  'tony@gmail.com', false, [], null, 'contract 2', 
    [
        new FixTextDetail ( 'Destination D23: New Super Hero Experiences Arrive in 2022 with the Guardians of the Galaxy and Avengers'),
        new FixTextDetail ( 'Shang-Chi, Black Widow and 11 More Marvel Studios Films Available on Disney+ in IMAXs Expanded Aspect Ratio' ),
        new FixTextDetail ( '6 Reasons Why You Should Watch the MCU in IMAX’s Expanded Aspect Ratio')
    ]),

    new FixText ( 'fixT-3','fixText','fixText-3',  'tony@gmail.com', false, [], null, 'term of payment', 
    [
        new FixTextDetail ('Net 10, net 15, net 30 and net 60 are forms of trade credit which specify that the net amount is expected to be paid in full by the buyer within 10, 15, 30 or 60 days of the date when the goods are dispatched or the service is completed. Net 30 or net 60 terms are often coupled with a credit for early payment')

    ]),
    new FixText ( 'fixT-4','fixText','fixText-4',  'tony@gmail.com', false, [], null, 'term of payment', 
    [
        new FixTextDetail ('Net 30 end of the month (EOM) means that the payment is due 30 days after the end of the month in which you sent the invoice. For example, if you and your client agree to net 30 EOM and you invoice them on May 11th, that payment will be due on June 30th—in other words, 30 days after May 31st' )
    ]),
    new FixText ( 'fixT-5','fixText','fixText-5', 'tony@gmail.com', false, [], null, ' chinese term of payment', 
    [
        new FixTextDetail ('What does net 30 mean on an invoice? In the U.S., the term “net 30” is one of the most common payment terms. It refers to a payment period, meaning the customer has a 30-day length of time to pay the total amount of their invoice. Other common net terms include net 60 for 60 days and net 90 for 90 days // 发票上的净 30 是什么意思？在美国，“net 30”一词是最常见的付款方式之一。它指的是一个付款期，这意味着客户有 30 天的时间来支付其发票的总金额。其他常见的净额包括 60 天的净 60 和 90 天的净 90' )
    ]),
    new FixText ( 'fixT-6','fixText','fixText-6', 'tony@gmail.com', false, [], null, ' chinese term of payment', 
    [
        new FixTextDetail ('What does net 30 mean on an invoice? In the U.S., the term “net 30” is one of the most common payment terms. It refers to a payment period, meaning the customer has a 30-day length of time to pay the total amount of their invoice. Other common net terms include net 60 for 60 days and net 90 for 90 days // Cosa significa net 30 su una fattura? Negli Stati Uniti, il termine "net 30" è uno dei termini di pagamento più comuni. Si riferisce a un periodo di pagamento, il che significa che il cliente ha un periodo di tempo di 30 giorni per pagare limporto totale della fattura. Altri termini netti comuni includono 60 netti per 60 giorni e 90 netti per 90 giorni' )
    ])
];

initialNullFixLayouts: FixLayout[] = [

    new FixLayout ( 'fixLay-1','fixLayout','fixLayout-1', 'metler toledo', 'antoniohhd@gmail.com', false, [], null, 

            [
                new FixLayoutTableTR ( 
                    [ 
                        new FixLayoutTableTD ( 'string', 'metler toledo inc', 'customer name', null, null, null, null, null, null, '200px'  ),
                        new FixLayoutTableTD ( 'string', 'Eu / italy / vicenza', 'region / country / adddress', null, null,null, null, null, null, '200px'   )
                    ]
                ),
                

                new FixLayoutTableTR ( 
                    [ 
                        new FixLayoutTableTD ( 'string', 'stable', 'status', null, null,null, null, null, null, '250px'  ),
                        new FixLayoutTableTD ( 'description', null , null,  null, null, ' every month / mostly product type 3', 'main details', null, null, '500px'  ),
                        new FixLayoutTableTD ( 'string', 'we are trying to add type 2 ', 'improvement', null, null,null, null, null, null, '200px'  ),
                        new FixLayoutTableTD ( 'string', '330.000', 'potential USD', null, null,null, null, null, null, '200px'  ),
                        
                    ]
                ),
                new FixLayoutTableTR (
                    [ 
                        new FixLayoutTableTD ( 'string', '15.000', '2021', null, null,null, null, null, null, '200px'  ),
                        new FixLayoutTableTD ( 'string', '120.000', '2019', null, null, null, null, null, null , '200px' ),
                        new FixLayoutTableTD ( 'string', '10.000', '2020', null, null,null, null, null, null, '200px'  ),
                       
       
                    ]
                ),
                new FixLayoutTableTR (
                    [ 
                        new FixLayoutTableTD ( 'string', '3,000', 'january', null, null, null, null, null, null , '200px' ),
                        new FixLayoutTableTD ( 'string', '10.000', 'february', null, null,null, null, null, null, '200px'  ),
                        new FixLayoutTableTD ( 'string', '5.000', 'march', null, null,null, null, null, null, '200px'  ),
                        new FixLayoutTableTD ( 'string', '9.000', 'april', null, null, null, null, null, null , '200px' ),
                        new FixLayoutTableTD ( 'string', '12.000', 'may', null, null,null, null, null, null, '200px'  ),
                       ]
                ),
            ]
    ),

    new FixLayout ( 'fixLay-2', 'fixLayout','fixLayout-2', 'bassa veneta', 'antoniohhd@gmail.com', false, [], null, 

    [
        new FixLayoutTableTR ( 
            [ 
                new FixLayoutTableTD ( 'string', ' bassa veneta', 'customer name', null, null, null, null, null, null, '200px'  ),
                new FixLayoutTableTD ( 'string', 'Eu / Germany / Munich', 'region / country / adddress', null, null,null, null, null, null, '200px'   )
            ]
        ),
        

        new FixLayoutTableTR ( 
            [ 
                new FixLayoutTableTD ( 'string', 'new customer', 'status', null, null,null, null, null, null, '250px'  ),
                new FixLayoutTableTD ( 'description', null , null,  null, null, ' just ordered once, all type. ', 'main details', null, null, '500px'  ),
                new FixLayoutTableTD ( 'string', 'we can sell everything', 'improvement', null, null,null, null, null, null, '200px'  ),
                new FixLayoutTableTD ( 'string', '1.700.000', 'potential USD', null, null,null, null, null, null, '200px'  ),
                
            ]
        ),
        new FixLayoutTableTR (
            [ 
            new FixLayoutTableTD ( 'string', '115.000', '2022', null, null,null, null, null, null, '200px'  ),
            ],
        ),
        new FixLayoutTableTR (
            [ 
                new FixLayoutTableTD ( 'string', '121,000', 'january', null, null, null, null, null, null , '200px' ),
                new FixLayoutTableTD ( 'string', '20.000', 'february', null, null,null, null, null, null, '200px'  ),
                new FixLayoutTableTD ( 'string', '15.000', 'march', null, null,null, null, null, null, '200px'  ),
                new FixLayoutTableTD ( 'string', '99.000', 'april', null, null, null, null, null, null , '200px' ),
                new FixLayoutTableTD ( 'string', '6.000', 'may', null, null,null, null, null, null, '200px'  ),
               ]
        ),
    ]),

    new FixLayout ( 'fixLay-3','fixLayout','fixLayout-3', 'bisma laut pribadi inc', 'antoniohhd@gmail.com', false, [], null, 

    [
        new FixLayoutTableTR ( 
            [ 
                new FixLayoutTableTD ( 'string', 'bisma laut pribadi inc', 'customer name', null, null, null, null, null, null, '200px'  ),
                new FixLayoutTableTD ( 'string', 'Eu / indonesia / vicenza', 'region / country / adddress', null, null,null, null, null, null, '200px'   )
            ]
        ),
        

        new FixLayoutTableTR ( 
            [ 
                new FixLayoutTableTD ( 'string', 'stable customer', 'status', null, null,null, null, null, null, '250px'  ),
                new FixLayoutTableTD ( 'description', null , null,  null, null, 'purchase every 2 month / mostly product type 2', 'main details', null, null, '500px'  ),
                new FixLayoutTableTD ( 'string', 'we are trying to add type 1 ', 'improvement', null, null,null, null, null, null, '200px'  ),
                new FixLayoutTableTD ( 'string', '920.000', 'potential USD', null, null,null, null, null, null, '200px'  ),
                
            ]
        ),
        new FixLayoutTableTR (
            [ 
                new FixLayoutTableTD ( 'string', '740.000', '2019', null, null, null, null, null, null , '200px' ),
                new FixLayoutTableTD ( 'string', '820.000', '2020', null, null,null, null, null, null, '200px'  ),
                new FixLayoutTableTD ( 'string', '815.000', '2021', null, null,null, null, null, null, '200px'  ),

            ]
        ),
        new FixLayoutTableTR (
            [ 
                new FixLayoutTableTD ( 'string', '50,000', 'january', null, null, null, null, null, null , '200px' ),
                new FixLayoutTableTD ( 'string', '80.000', 'february', null, null,null, null, null, null, '200px'  ),
                new FixLayoutTableTD ( 'string', '35.000', 'march', null, null,null, null, null, null, '200px'  ),
                new FixLayoutTableTD ( 'string', '40.000', 'april', null, null, null, null, null, null , '200px' ),
                new FixLayoutTableTD ( 'string', '70.000', 'may', null, null,null, null, null, null, '200px'  ),
               ]
        ),
    ]
)]

initialNullSingleDateCalendars: SingleDateCalendar[] =  [

    new SingleDateCalendar ( 'singleDateCalen-1' ,'singleDateCalendar' ,'singleDateCalendar-1' , 'tony@gmail.com', false, [], null, 'first', 
                       [ 
                        new SingleDateCalendarDetail ( 'arrival', new Date()),
                        new SingleDateCalendarDetail ( 'departure', new Date())
                       ]
    ),

    new SingleDateCalendar ( 'singleDateCalen-2' ,'singleDateCalendar' ,'singleDateCalendar-2' , 'tony@gmail.com', false, [], null, 'second date', 
                       [ 
                        new SingleDateCalendarDetail ( 'start working on', new Date()),
                        new SingleDateCalendarDetail ( 'stop working on', new Date())
                       ]
    ),
    new SingleDateCalendar ( 'singleDateCalen-3' ,'singleDateCalendar' ,'singleDateCalendar-3' , 'tony@gmail.com', false, [], null, 'second date', 
    [ 
     new SingleDateCalendarDetail ( 'enter date', new Date()),
    
    ]
)


];

initialNullMultiEntryCalendars: MultiEntryCalendar[] =  [

    new MultiEntryCalendar ( 'multiEntryCalen-1','multiEntryCalendar','multiEntryCalendar-1', 'tony@gmail.com', false, [], null, 'primo', 'available dates', 
    [
        new Date ('5/15/2022'),
        new Date ('5/19/2022'),
        new Date ('5/26/2022')
    ]),

    new MultiEntryCalendar ('multiEntryCalen-2', 'multiEntryCalendar','multiEntryCalendar-2',  'tony@gmail.com', false, [], null, 'primo', 'we are closed on these dates ',
    [
        new Date ('6/16/2022'),
        new Date ('6/14/2022')
    ])


];

/**
 * export class UsersAccess {

    constructor( public canView: CanDo , 
                 public canEdit: CanDo,
                 public canUse: CanDo 
                 ){}
}

export class CanDo {

    constructor( public names: string[], 
                 public description: string , 
                 public onlyOwnerCan: boolean 
                 ){}
}
 */

/**
 * export class SharedCalendarStatus {

    constructor( public _id: string , 
                 public componentName: string , 
                 public UIID: string , 
                 public owner: string ,
                 public allUsersAllowed: boolean,
                 public userListAllowed: string[],
                 public dateAndTime: string ,
                 public name: string , 
                 public rangeDatesInfoSetting: RangeDatesInfo,
                 public rangeDatesInfos: RangeDatesInfo[],
                 public usersAccess: UsersAccess
                 ){}

}
 */

/**
 * export class RangeInfo {

    constructor( public type: string , 
                 public name: string , 
                 public usersAccess: UsersAccess,
                 public rangeDetails: InnerArraySelect[] |
                                      FixTextDetail[] | 
                                      InnerLinkDetail[]
               ){}
}

 {
        start: new Date('2023-06-20'),
        end: new Date('2023-07-05'),
        color: 'range1'
      },
      {
        start: new Date('2023-07-06'),
        end: new Date('2023-07-10'),
        color: 'range2'
      },
      {
        start: new Date('2023-07-12'),
        end: new Date('2023-07-18'),
        color: 'range3'
      },
      {
        start: new Date('2023-07-21'),
        end: new Date('2023-07-23'),
        color: 'range4'
      },
      {
        start: new Date('2023-07-25'),
        end: new Date('2023-07-28'),
        color: 'range5'
      }
 */

initialNullSharedCalendarStatusItems: SharedCalendarStatus[] = [

        new SharedCalendarStatus ( 'sharedCalendarSta-1', 'sharedCalendarStatus', 'sharedCalendarStatus-1', null, false, [], null, 'ESMERALDA ROOM',
                                    new RangeDatesInfo ( new DateStartEnd ( '', ''),
                                     [
                                        new RangeInfo ( 'innertext', 'client details',
                                        new UsersAccess ( 
                                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete accesses', false ),
                                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete text entries', false )
                                                        ), 
                                                 [
                                                     new FixTextDetail ('Mr. / number:'),
                                                     new FixTextDetail ('Ms.  / number:'),
                                                     new FixTextDetail ('prefer: '),
                                                     new FixTextDetail ('motorbike Nmax plate number:'),

                                                 ]),
                                        new RangeInfo ( 'innerdropdown', 'tot guests in room ', 
                                                     new UsersAccess ( 
                                                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                     ), 
                                                     [ 
                                                        new InnerArraySelect ('1', false ),
                                                        new InnerArraySelect ('2', false ),
                                                        new InnerArraySelect ('3', false ),
                                                        new InnerArraySelect ('4', false ),
                                                        new InnerArraySelect ('4 +', false ),
                                                        
                                                     ]
                                        ), 
                                       
                                        new RangeInfo ( 'innercheckbox', 'request:',
                                                     new UsersAccess ( 
                                                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                      ), 
                                                      /**
                                                       * export class InnerArraySelect {

                                                       constructor( public name: string, public selected: boolean ){}
                                                         }
                                                       */
                                                       [
                                                        new InnerArraySelect ('early check in', false ),
                                                        new InnerArraySelect ('late check out', false ),
                                                        new InnerArraySelect ('pick up from airport', false ),
                                                        new InnerArraySelect ('additional bed', false ),
                                                        ]
                                        ),
                                        new RangeInfo ( 'innerinput', 'additional info during stay ', 
                                                            new UsersAccess ( 
                                                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                                        ), 
                                                                   [
                                                                    new FixTextDetail ('add date / info'),
                                                                    new FixTextDetail ('150'),
                                                                    new FixTextDetail ('example: 25/9 / request breakfast take away'),
                                                                    new FixTextDetail ('')
                                                                   ]
                                                            ),
                                     ],
                                                 new UsersAccess ( 
                                                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can view data', false ),
                                                                new CanDo (  [], '', true ),
                                                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add/delete/edit date ranges & inner rangeInfo', false )
                                                                ), 
                                                 ),
                                                
                                   [
            
                                    new RangeDatesInfo ( new DateStartEnd ( '2023/09/15', '2023/09/19'),
                                     [
                                        new RangeInfo ( 'innertext', 'client details',
                                                           new UsersAccess ( 
                                                                            new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                            new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete accesses', false ),
                                                                            new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete text entries', false )
                                                                           ), 
                                                                    [
                                                                        new FixTextDetail ('Mr. Joe Blak / number 123456789'),
                                                                        new FixTextDetail ('Ms. Kristina Bloom / number 0987654321'),
                                                                        new FixTextDetail ('prefer seafront view'),
                                                                        new FixTextDetail ('motorbike Nmax plate number: BK234'),
    
                                                                    ]),
                                            new RangeInfo ( 'innerdropdown', 'tot guests in room ', 
                                                         new UsersAccess ( 
                                                                          new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                          new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                          new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                         ), 
                                                         [ 
                                                            new InnerArraySelect ('1', false ),
                                                            new InnerArraySelect ('2', true ),
                                                            new InnerArraySelect ('3', false ),
                                                            new InnerArraySelect ('4', false ),
                                                            new InnerArraySelect ('4 +', false ),
                                                            
                                                         ]
                                            ), 
                                           
                                            new RangeInfo ( 'innercheckbox', 'request:',
                                                         new UsersAccess ( 
                                                                           new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                           new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                           new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                          ), 
                                                          /**
                                                           * export class InnerArraySelect {
    
                                                           constructor( public name: string, public selected: boolean ){}
                                                             }
                                                           */
                                                           [
                                                            new InnerArraySelect ('early check in', false ),
                                                            new InnerArraySelect ('late check out', true ),
                                                            new InnerArraySelect ('pick up from airport', true ),
                                                            new InnerArraySelect ('additional bed', false ),
                                                            ]
                                            ),
        
                                            new RangeInfo ( 'innerinput', 'additional info during stay ', 
                                                                new UsersAccess ( 
                                                                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                                            ), 
                                                                       [
                                                                        new FixTextDetail ('add date / info'),
                                                                        new FixTextDetail ('150'),
                                                                        new FixTextDetail ('example: 25/9 / request breakfast take away'),
                                                                        new FixTextDetail ('got additional pillows : 2')
                                                                       ]
                                                                ),

                                     ],
                                                 new UsersAccess ( 
                                                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                                ), 
                                    ),
                                    new RangeDatesInfo ( new DateStartEnd ( '2023/09/19', '2023/09/24'),
                                    [
                                        new RangeInfo ( 'innertext', 'client details',
                                        new UsersAccess ( 
                                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete accesses', false ),
                                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete text entries', false )
                                                        ), 
                                                 [
                                                     new FixTextDetail ('Mr. Andy Saputra / number 123456789'),
                                                     new FixTextDetail ('Ms. Vinna Esther / number 0987654321'),
                                                     new FixTextDetail ('prefer garden view'),
                                                     new FixTextDetail ('motorbike Nmax plate number: Bk567'),

                                                 ]),
                                        new RangeInfo ( 'innerdropdown', 'tot guests in room ', 
                                                     new UsersAccess ( 
                                                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                     ), 
                                                     [ 
                                                        new InnerArraySelect ('1', false ),
                                                        new InnerArraySelect ('2', false ),
                                                        new InnerArraySelect ('3', true ),
                                                        new InnerArraySelect ('4', false ),
                                                        new InnerArraySelect ('4 +', false ),
                                                        
                                                     ]
                                        ), 
                                       
                                        new RangeInfo ( 'innercheckbox', 'request:',
                                                     new UsersAccess ( 
                                                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                      ), 
                                                      /**
                                                       * export class InnerArraySelect {

                                                       constructor( public name: string, public selected: boolean ){}
                                                         }
                                                       */
                                                       [
                                                        new InnerArraySelect ('early check in', true ),
                                                        new InnerArraySelect ('late check out', false ),
                                                        new InnerArraySelect ('pick up from airport', false ),
                                                        new InnerArraySelect ('additional bed', true ),
                                                        ]
                                        ),
                                      
                                        new RangeInfo ( 'innerinput', 'additional info during stay ', 
                                                            new UsersAccess ( 
                                                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                                        ), 
                                                                   [
                                                                    new FixTextDetail ('add date / info'),
                                                                    new FixTextDetail ('150'),
                                                                    new FixTextDetail ('example: 25/9 / request breakfast take away'),
                                                                    new FixTextDetail ('20/9 - request dinner take away for 3')
                                                                   ]
                                                            ),
                                     ],
                                   
                                                 new UsersAccess ( 
                                                                new CanDo (  ['alex.com', 'bea@bea.com'], '', false ),
                                                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                                ), 
                                    ),
                                    new RangeDatesInfo ( new DateStartEnd ( '2023/09/24', '2023/09/29'),
                                    [
                                        new RangeInfo ( 'innertext', 'client details',
                                                       new UsersAccess ( 
                                                                        new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                        new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete accesses', false ),
                                                                        new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete text entries', false )
                                                                       ), 
                                                                [
                                                                    new FixTextDetail ('Mr. Billy Joel / number 123456789'),
                                                                    new FixTextDetail ('Ms. Amanda / number 0987654321'),
                                                                    new FixTextDetail ('prefer seafront view'),
                                                                    new FixTextDetail ('motorbike: black Scoopy plate number: BK000'),

                                                                ]),
                                        new RangeInfo ( 'innerdropdown', 'tot guests in room ', 
                                                     new UsersAccess ( 
                                                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                     ), 
                                                     [ 
                                                        new InnerArraySelect ('1', false ),
                                                        new InnerArraySelect ('2', true ),
                                                        new InnerArraySelect ('3', false ),
                                                        new InnerArraySelect ('4', false ),
                                                        new InnerArraySelect ('4 +', false ),
                                                        
                                                     ]
                                        ), 
                                       
                                        new RangeInfo ( 'innercheckbox', 'request:',
                                                     new UsersAccess ( 
                                                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                      ), 
                                                      /**
                                                       * export class InnerArraySelect {

                                                       constructor( public name: string, public selected: boolean ){}
                                                         }
                                                       */
                                                       [
                                                        new InnerArraySelect ('early check in', false ),
                                                        new InnerArraySelect ('late check out', false ),
                                                        new InnerArraySelect ('pick up from airport', true ),
                                                        new InnerArraySelect ('additional bed', false ),
                                                        ]
                                        ),
                                        
                                        new RangeInfo ( 'innerinput', 'additional info during stay ', 
                                                            new UsersAccess ( 
                                                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                                        ), 
                                                                   [
                                                                    new FixTextDetail ('add date / info'),
                                                                    new FixTextDetail ('150'),
                                                                    new FixTextDetail ('example: 25/9 / request breakfast take away'),
                                                                    new FixTextDetail (' 25-9 5kg / laundry')
                                                                   ]
                                                            ),
                                     ],
                                    
                                                 new UsersAccess ( 
                                                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                                ), 
                                    ),
                                     new RangeDatesInfo ( new DateStartEnd ( '2023/10/1', '2023/10/15'),
                                     [
                                        new RangeInfo ( 'innertext', 'client details',
                                                       new UsersAccess ( 
                                                                        new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                        new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete accesses', false ),
                                                                        new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete text entries', false )
                                                                       ), 
                                                                [
                                                                    new FixTextDetail ('Mr. kamal / number 123456789'),
                                                                    new FixTextDetail ('motorbike:  number:'),

                                                                ]),
                                        new RangeInfo ( 'innerdropdown', 'tot guests in room ', 
                                                     new UsersAccess ( 
                                                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                     ), 
                                                     [ 
                                                        new InnerArraySelect ('1', true ),
                                                        new InnerArraySelect ('2', false ),
                                                        new InnerArraySelect ('3', false ),
                                                        new InnerArraySelect ('4', false ),
                                                        new InnerArraySelect ('4 +', false ),
                                                        
                                                     ]
                                        ), 
                                       
                                        new RangeInfo ( 'innercheckbox', 'request:',
                                                     new UsersAccess ( 
                                                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                      ), 
                                                      /**
                                                       * export class InnerArraySelect {

                                                       constructor( public name: string, public selected: boolean ){}
                                                         }
                                                       */
                                                       [
                                                        new InnerArraySelect ('early check in', false ),
                                                        new InnerArraySelect ('late check out', false ),
                                                        new InnerArraySelect ('pick up from airport', false ),
                                                        new InnerArraySelect ('additional bed', false ),
                                                        ]
                                        ),
                                        
                                        new RangeInfo ( 'innerinput', 'additional info during stay ', 
                                                            new UsersAccess ( 
                                                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                                        ), 
                                                                   [
                                                                    new FixTextDetail ('add date / info'),
                                                                    new FixTextDetail ('150'),
                                                                    new FixTextDetail ('example: 25/9 / request breakfast take away'),
                                                                    new FixTextDetail ('')
                                                                   ]
                                                            ),
                                     ],
                                  
                                                new UsersAccess ( 
                                                               new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                               new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                               new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                               ), 
                                   ),
                                   new RangeDatesInfo ( new DateStartEnd ( '2023/10/16', '2023/10/20'),
                                   [
                                      new RangeInfo ( 'innertext', 'client details',
                                                     new UsersAccess ( 
                                                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete accesses', false ),
                                                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete text entries', false )
                                                                     ), 
                                                              [
                                                                  new FixTextDetail ('Mr. alex / number 123456789'),
                                                                  new FixTextDetail ('motorbike:  number:'),

                                                              ]),
                                      new RangeInfo ( 'innerdropdown', 'tot guests in room ', 
                                                   new UsersAccess ( 
                                                                    new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                    new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                    new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                   ), 
                                                   [ 
                                                      new InnerArraySelect ('1', true ),
                                                      new InnerArraySelect ('2', false ),
                                                      new InnerArraySelect ('3', false ),
                                                      new InnerArraySelect ('4', false ),
                                                      new InnerArraySelect ('4 +', false ),
                                                      
                                                   ]
                                      ), 
                                     
                                      new RangeInfo ( 'innercheckbox', 'request:',
                                                   new UsersAccess ( 
                                                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                    ), 
                                                    /**
                                                     * export class InnerArraySelect {

                                                     constructor( public name: string, public selected: boolean ){}
                                                       }
                                                     */
                                                     [
                                                      new InnerArraySelect ('early check in', true ),
                                                      new InnerArraySelect ('late check out', false ),
                                                      new InnerArraySelect ('pick up from airport', false ),
                                                      new InnerArraySelect ('additional bed', false ),
                                                      ]
                                      ),
                                      
                                      new RangeInfo ( 'innerinput', 'additional info during stay ', 
                                                          new UsersAccess ( 
                                                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                                      ), 
                                                                 [
                                                                  new FixTextDetail ('add date / info'),
                                                                  new FixTextDetail ('150'),
                                                                  new FixTextDetail ('example: 25/9 / request breakfast take away'),
                                                                  new FixTextDetail ('')
                                                                 ]
                                                          ),
                                   ],
                                
                                              new UsersAccess ( 
                                                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                             ), 
                                 ),

                                   ]
        ),

        new SharedCalendarStatus ( 'sharedCalendarSta-2', 'sharedCalendarStatus', 'sharedCalendarStatus-2', null, false, [], null, 'GARDENIA ROOM',
        new RangeDatesInfo ( new DateStartEnd ( '', ''),
         [
            new RangeInfo ( 'innertext', 'client details',
            new UsersAccess ( 
                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete accesses', false ),
                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete text entries', false )
                            ), 
                     [
                         new FixTextDetail ('Mr. / number:'),
                         new FixTextDetail ('Ms.  / number:'),
                         new FixTextDetail ('prefer: '),
                         new FixTextDetail ('motorbike Nmax plate number:'),

                     ]),
            new RangeInfo ( 'innerdropdown', 'tot guests in room ', 
                         new UsersAccess ( 
                                          new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                          new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                          new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                         ), 
                         [ 
                            new InnerArraySelect ('1', false ),
                            new InnerArraySelect ('2', false ),
                            new InnerArraySelect ('3', false ),
                            new InnerArraySelect ('4', false ),
                            new InnerArraySelect ('4 +', false ),
                            
                         ]
            ), 
           
            new RangeInfo ( 'innercheckbox', 'request:',
                         new UsersAccess ( 
                                           new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                           new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                           new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                          ), 
                          /**
                           * export class InnerArraySelect {

                           constructor( public name: string, public selected: boolean ){}
                             }
                           */
                           [
                            new InnerArraySelect ('early check in', false ),
                            new InnerArraySelect ('late check out', false ),
                            new InnerArraySelect ('pick up from airport', false ),
                            new InnerArraySelect ('additional bed', false ),
                            ]
            ),
            new RangeInfo ( 'innerinput', 'additional info during stay ', 
                                new UsersAccess ( 
                                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                            ), 
                                       [
                                        new FixTextDetail ('add date / info'),
                                        new FixTextDetail ('150'),
                                        new FixTextDetail ('example: 25/9 / request breakfast take away'),
                                        new FixTextDetail ('')
                                       ]
                                ),
         ],
                     new UsersAccess ( 
                                    new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can view data', false ),
                                    new CanDo (  [], '', true ),
                                    new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add/delete/edit date ranges & inner rangeInfo', false )
                                    ), 
                     ),
                    
       [

        new RangeDatesInfo ( new DateStartEnd ( '2023/09/20', '2023/09/25'),
         [
            new RangeInfo ( 'innertext', 'client details',
                               new UsersAccess ( 
                                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete accesses', false ),
                                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete text entries', false )
                                               ), 
                                        [
                                            new FixTextDetail ('Mr. Joe Blak / number 123456789'),
                                            new FixTextDetail ('Ms. Kristina Bloom / number 0987654321'),
                                            new FixTextDetail ('prefer seafront view'),
                                            new FixTextDetail ('motorbike Nmax plate number: BK234'),

                                        ]),
                new RangeInfo ( 'innerdropdown', 'tot guests in room ', 
                             new UsersAccess ( 
                                              new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                              new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                              new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                             ), 
                             [ 
                                new InnerArraySelect ('1', false ),
                                new InnerArraySelect ('2', true ),
                                new InnerArraySelect ('3', false ),
                                new InnerArraySelect ('4', false ),
                                new InnerArraySelect ('4 +', false ),
                                
                             ]
                ), 
               
                new RangeInfo ( 'innercheckbox', 'request:',
                             new UsersAccess ( 
                                               new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                               new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                               new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                              ), 
                              /**
                               * export class InnerArraySelect {

                               constructor( public name: string, public selected: boolean ){}
                                 }
                               */
                               [
                                new InnerArraySelect ('early check in', false ),
                                new InnerArraySelect ('late check out', true ),
                                new InnerArraySelect ('pick up from airport', true ),
                                new InnerArraySelect ('additional bed', false ),
                                ]
                ),

                new RangeInfo ( 'innerinput', 'additional info during stay ', 
                                    new UsersAccess ( 
                                                 new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                 new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                 new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                ), 
                                           [
                                            new FixTextDetail ('add date / info'),
                                            new FixTextDetail ('150'),
                                            new FixTextDetail ('example: 25/9 / request breakfast take away'),
                                            new FixTextDetail ('got additional pillows : 2')
                                           ]
                                    ),

         ],
                     new UsersAccess ( 
                                    new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                    new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                    new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                    ), 
        ),
        new RangeDatesInfo ( new DateStartEnd ( '2023/09/26', '2023/09/30'),
        [
            new RangeInfo ( 'innertext', 'client details',
            new UsersAccess ( 
                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete accesses', false ),
                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete text entries', false )
                            ), 
                     [
                         new FixTextDetail ('Mr. Andy Saputra / number 123456789'),
                         new FixTextDetail ('Ms. Vinna Esther / number 0987654321'),
                         new FixTextDetail ('prefer garden view'),
                         new FixTextDetail ('motorbike Nmax plate number: Bk567'),

                     ]),
            new RangeInfo ( 'innerdropdown', 'tot guests in room ', 
                         new UsersAccess ( 
                                          new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                          new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                          new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                         ), 
                         [ 
                            new InnerArraySelect ('1', false ),
                            new InnerArraySelect ('2', false ),
                            new InnerArraySelect ('3', true ),
                            new InnerArraySelect ('4', false ),
                            new InnerArraySelect ('4 +', false ),
                            
                         ]
            ), 
           
            new RangeInfo ( 'innercheckbox', 'request:',
                         new UsersAccess ( 
                                           new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                           new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                           new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                          ), 
                          /**
                           * export class InnerArraySelect {

                           constructor( public name: string, public selected: boolean ){}
                             }
                           */
                           [
                            new InnerArraySelect ('early check in', true ),
                            new InnerArraySelect ('late check out', false ),
                            new InnerArraySelect ('pick up from airport', false ),
                            new InnerArraySelect ('additional bed', true ),
                            ]
            ),
          
            new RangeInfo ( 'innerinput', 'additional info during stay ', 
                                new UsersAccess ( 
                                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                            ), 
                                       [
                                        new FixTextDetail ('add date / info'),
                                        new FixTextDetail ('150'),
                                        new FixTextDetail ('example: 25/9 / request breakfast take away'),
                                        new FixTextDetail ('20/9 - request dinner take away for 3')
                                       ]
                                ),
         ],
       
                     new UsersAccess ( 
                                    new CanDo (  ['alex.com', 'bea@bea.com'], '', false ),
                                    new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                    new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                    ), 
        ),
        new RangeDatesInfo ( new DateStartEnd ( '2023/10/1', '2023/10/10'),
        [
            new RangeInfo ( 'innertext', 'client details',
                           new UsersAccess ( 
                                            new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                            new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete accesses', false ),
                                            new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete text entries', false )
                                           ), 
                                    [
                                        new FixTextDetail ('Mr. Billy Joel / number 123456789'),
                                        new FixTextDetail ('Ms. Amanda / number 0987654321'),
                                        new FixTextDetail ('prefer seafront view'),
                                        new FixTextDetail ('motorbike: black Scoopy plate number: BK000'),

                                    ]),
            new RangeInfo ( 'innerdropdown', 'tot guests in room ', 
                         new UsersAccess ( 
                                          new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                          new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                          new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                         ), 
                         [ 
                            new InnerArraySelect ('1', false ),
                            new InnerArraySelect ('2', true ),
                            new InnerArraySelect ('3', false ),
                            new InnerArraySelect ('4', false ),
                            new InnerArraySelect ('4 +', false ),
                            
                         ]
            ), 
           
            new RangeInfo ( 'innercheckbox', 'request:',
                         new UsersAccess ( 
                                           new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                           new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                           new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                          ), 
                          /**
                           * export class InnerArraySelect {

                           constructor( public name: string, public selected: boolean ){}
                             }
                           */
                           [
                            new InnerArraySelect ('early check in', false ),
                            new InnerArraySelect ('late check out', false ),
                            new InnerArraySelect ('pick up from airport', true ),
                            new InnerArraySelect ('additional bed', false ),
                            ]
            ),
            
            new RangeInfo ( 'innerinput', 'additional info during stay ', 
                                new UsersAccess ( 
                                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                            ), 
                                       [
                                        new FixTextDetail ('add date / info'),
                                        new FixTextDetail ('150'),
                                        new FixTextDetail ('example: 25/9 / request breakfast take away'),
                                        new FixTextDetail (' 25-9 5kg / laundry')
                                       ]
                                ),
         ],
        
                     new UsersAccess ( 
                                    new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                    new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                    new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                    ), 
        ),
         new RangeDatesInfo ( new DateStartEnd ( '2023/10/10', '2023/10/15'),
         [
            new RangeInfo ( 'innertext', 'client details',
                           new UsersAccess ( 
                                            new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                            new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete accesses', false ),
                                            new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete text entries', false )
                                           ), 
                                    [
                                        new FixTextDetail ('Mr. kamal / number 123456789'),
                                        new FixTextDetail ('motorbike:  number:'),

                                    ]),
            new RangeInfo ( 'innerdropdown', 'tot guests in room ', 
                         new UsersAccess ( 
                                          new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                          new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                          new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                         ), 
                         [ 
                            new InnerArraySelect ('1', true ),
                            new InnerArraySelect ('2', false ),
                            new InnerArraySelect ('3', false ),
                            new InnerArraySelect ('4', false ),
                            new InnerArraySelect ('4 +', false ),
                            
                         ]
            ), 
           
            new RangeInfo ( 'innercheckbox', 'request:',
                         new UsersAccess ( 
                                           new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                           new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                           new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                          ), 
                          /**
                           * export class InnerArraySelect {

                           constructor( public name: string, public selected: boolean ){}
                             }
                           */
                           [
                            new InnerArraySelect ('early check in', false ),
                            new InnerArraySelect ('late check out', false ),
                            new InnerArraySelect ('pick up from airport', false ),
                            new InnerArraySelect ('additional bed', false ),
                            ]
            ),
            
            new RangeInfo ( 'innerinput', 'additional info during stay ', 
                                new UsersAccess ( 
                                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                            ), 
                                       [
                                        new FixTextDetail ('add date / info'),
                                        new FixTextDetail ('150'),
                                        new FixTextDetail ('example: 25/9 / request breakfast take away'),
                                        new FixTextDetail ('')
                                       ]
                                ),
         ],
      
                    new UsersAccess ( 
                                   new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                   new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                   new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                   ), 
       ),
       new RangeDatesInfo ( new DateStartEnd ( '2023/10/16', '2023/10/20'),
       [
          new RangeInfo ( 'innertext', 'client details',
                         new UsersAccess ( 
                                          new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                          new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete accesses', false ),
                                          new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete text entries', false )
                                         ), 
                                  [
                                      new FixTextDetail ('Mr. alex / number 123456789'),
                                      new FixTextDetail ('motorbike:  number:'),

                                  ]),
          new RangeInfo ( 'innerdropdown', 'tot guests in room ', 
                       new UsersAccess ( 
                                        new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                        new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                        new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                       ), 
                       [ 
                          new InnerArraySelect ('1', true ),
                          new InnerArraySelect ('2', false ),
                          new InnerArraySelect ('3', false ),
                          new InnerArraySelect ('4', false ),
                          new InnerArraySelect ('4 +', false ),
                          
                       ]
          ), 
         
          new RangeInfo ( 'innercheckbox', 'request:',
                       new UsersAccess ( 
                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                        ), 
                        /**
                         * export class InnerArraySelect {

                         constructor( public name: string, public selected: boolean ){}
                           }
                         */
                         [
                          new InnerArraySelect ('early check in', true ),
                          new InnerArraySelect ('late check out', false ),
                          new InnerArraySelect ('pick up from airport', false ),
                          new InnerArraySelect ('additional bed', false ),
                          ]
          ),
          
          new RangeInfo ( 'innerinput', 'additional info during stay ', 
                              new UsersAccess ( 
                                           new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                           new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                           new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                          ), 
                                     [
                                      new FixTextDetail ('add date / info'),
                                      new FixTextDetail ('150'),
                                      new FixTextDetail ('example: 25/9 / request breakfast take away'),
                                      new FixTextDetail ('')
                                     ]
                              ),
       ],
    
                  new UsersAccess ( 
                                 new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                 new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                 new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                 ), 
     ),

       ]
),
    
        new SharedCalendarStatus ( 'sharedCalendarSta-3', 'sharedCalendarStatus', 'sharedCalendarStatus-3', null, false, [], null, 'secondSharedCalendar',
                                    new RangeDatesInfo ( new DateStartEnd ( 'yesterday', 'today'),
                                     [
                                        new RangeInfo ( 'innerinput', '13 input', 
                                        new UsersAccess ( 
                                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                    ), 
                                               [
                                                new FixTextDetail ('begonia'),
                                                new FixTextDetail ('150'),
                                                new FixTextDetail ('this is the placeholder you input'),
                                                new FixTextDetail ('')
                                               ]
                                        ),
                                        new RangeInfo ( 'innerdropdown', 'second dropdown', 
                                                     new UsersAccess ( 
                                                                      new CanDo (  ['alex.verdacchissimiodbdjvehvdbh@alex.com', 'bea@bea.com'], '', false ),
                                                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                     ), 
                                                     [ 
                                                        new InnerArraySelect ('pane', false ),
                                                        new InnerArraySelect ('pizza', false ),
                                                        new InnerArraySelect ('pasta', false ),
                                                        new InnerArraySelect ('mandolino', false )
                                                     ]
                                        ), 
                                        new RangeInfo ( 'innercheckbox', '3 check box',
                                                     new UsersAccess ( 
                                                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                      ), 
                                                       [
                                                        new InnerArraySelect ('corsa contro il tempo', false ),
                                                        new InnerArraySelect ('sfida contro le onde', false ),
                                                        new InnerArraySelect ('discesa ripida', false ),
                                                        new InnerArraySelect ('salida maliziosa', false ),
                                                        ]
                                        ),
                                        new RangeInfo ( 'innertext', '7 text',
                                                       new UsersAccess ( 
                                                                        new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                        new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete entries', false ),
                                                                        new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add/delete access', false )
                                                                       ), 
                                                                [
                                                                    new FixTextDetail ('On projection apartments unsatiable so if he entreaties appearance. '),
                                                                    new FixTextDetail ('Welcomed stronger if steepest ecstatic an suitable finished of oh.'),
                                                                    new FixTextDetail ('Chicken unknown besides attacks compact out you.')
                                                                ]),
                                       
                                     ],
                                                 new UsersAccess ( 
                                                                  new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can view the data on each date range', false ),
                                                                  new CanDo (  [], '', true ),
                                                                  new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add/delete date ranges & inner rangeInfo', false )
                                                                ), 
                                                 ),
                                                
                                   [
                                    new RangeDatesInfo ( new DateStartEnd ( '2023/10/16', '2023/10/20'),
                                     [
                                        new RangeInfo ( 'innerdropdown', 'second dropdown', 
                                                     new UsersAccess ( 
                                                                      new CanDo (  ['alex.verdacchissimiodbdjvehvdbh@alex.com', 'bea@bea.com'], '', false ),
                                                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                     ), 
                                                     [ 
                                                        new InnerArraySelect ('pane', false ),
                                                        new InnerArraySelect ('pizza', false ),
                                                        new InnerArraySelect ('pasta', false ),
                                                        new InnerArraySelect ('mandolino', false )
                                                     ]
                                        ), 
                                        new RangeInfo ( 'innercheckbox', '3 check box',
                                                     new UsersAccess ( 
                                                                       new CanDo (  ['alex.verdacchissimiodbdjvehvdbh@alex.com', 'bea@bea.com'], '', false ),
                                                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                      ), 
                                                       [
                                                        new InnerArraySelect ('corsa contro il tempo', false ),
                                                        new InnerArraySelect ('sfida contro le onde', false ),
                                                        new InnerArraySelect ('discesa ripida', false ),
                                                        new InnerArraySelect ('salida maliziosa', false ),
                                                        ]
                                        ),
                                        new RangeInfo ( 'innertext', '7 text',
                                                       new UsersAccess ( 
                                                                        new CanDo (  ['alex.verdacchissimiodbdjvehvdbh@alex.com', 'bea@bea.com'], '', false ),
                                                                        new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                        new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                                       ), 
                                                                [
                                                                    new FixTextDetail ('On projection apartments unsatiable so if he entreaties appearance. '),
                                                                    new FixTextDetail ('Welcomed stronger if steepest ecstatic an suitable finished of oh.'),
                                                                    new FixTextDetail ('Chicken unknown besides attacks compact out you.')
                                                                ]),
                                        new RangeInfo ( 'innerinput', '13 input', 
                                                            new UsersAccess ( 
                                                                         new CanDo (  ['alex.verdacchissimiodbdjvehvdbh@alex.com', 'bea@bea.com'], '', false ),
                                                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                                        ), 
                                                                   [
                                                                    new FixTextDetail ('begonia'),
                                                                    new FixTextDetail ('150'),
                                                                    new FixTextDetail ('this is the placeholder you input'),
                                                                    new FixTextDetail ('')
                                                                   ]
                                                            ),
                                     ],
                                                 new UsersAccess ( 
                                                                new CanDo (  ['alex.verdacchissimiodbdjvehvdbh@alex.com', 'bea@bea.com'], '', false ),
                                                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                                ), 
                                                 ),
                                   ]
        ),
]


initialNullCalculatorMixTables: CalculatorMixTable[] =  [

    new CalculatorMixTable ( 'calculatorMixTa-1','calculatorMixTable','calculatorMixTable-1', 'tony', false, [], null, 'primo', 
    
        new DescriptionFrame ( ['part one', 'power' ] , 
        [
            new DescriptionPart ('part one', 'hardware  ewrwe rwweretrwetetertpart diy 234 calculation that goes well', 300, 30 ),
            new DescriptionPart ('part one',  'software fdgsfdgdgf part diy 234', 500, 50),
            new DescriptionPart ('part one',  'assembler gfdsxcvcxbvcb  bcvbcb part diy 234', 400 ,40 ),
            new DescriptionPart ('power',  'hardpower diy 234', 350, 30),
            new DescriptionPart ('power',  'software power diy 234', 550, 50 ),
            new DescriptionPart ('power',  'assembler power diy 234', 450, 40 ),
        ]),

    
    ),

    new CalculatorMixTable ( 'calculatorMixTa-2','calculatorMixTable','calculatorMixTable-2', 'tony', false, [], null, 'secondo', 
    
        new DescriptionFrame ( ['lights', 'wheel'] , 
        [
            new DescriptionPart ('lights', 'lights on the table diy 234', 300, 30 ),
            new DescriptionPart ('lights',  'Led bdwjh hfgeceche evbefv diy 234', 500, 50 ),
            new DescriptionPart ('lights',  'assembler ccled line diy 234', 400, 40 ),
            new DescriptionPart ('wheel',  'alloy wheel diy 234', 300, 30 ),
            new DescriptionPart ('wheel',  'rubber syntetic 234', 500, 50 ),
            new DescriptionPart ('wheel',  'natural rubber hbcwjhdc diy 234', 400, 40 ),
        ]),

    
    )

]

initialNullDropDownSelects: DropDownSelect[] =  [

    new DropDownSelect ( 'dropDownSel-1','dropDownSelect','dropDownSelect-1', 'tony@gmail.com', false, [], null, 'primo', 
    [
        new DropDownSelectDescription ( 'visit motivation', ['courtesy', 'order', 'new project', 'current project', 'complain', 'payment', 'technical']),
         new DropDownSelectDescription ( 'duration',['week', 'daily', 'hours']),
       
    ]),

    new DropDownSelect ( 'dropDownSel-2','dropDownSelect','dropDownSelect-2', 'tony@mail.com', false, [], null, 'secondo',
    [
        new DropDownSelectDescription ( 'animale', ['maiale', 'bovino', 'equina', 'pollame', 'insect']),
        new DropDownSelectDescription ( 'religion', ['induist', 'cattolic', 'bugghist', 'muslim', 'ateist'])
    ])
];

initialNullMultiFeatures: MultiFeatures[] =  [
        
    new MultiFeatures ( 'multiFeat-0' , 'multiFeature' , 'multiFeature-0' , 'antoniohhd@gmail.com', null,
      
         false , [] ,'expenses note' ,
            [ 
               new Feature (   'antoniohhd@gmail.com' , false , 0, 'singleDropDownSel-1', 'singleDropDownSelect' ),
               new Feature (  'antoniohhd@gmail.com' , false , 1, 'singleWordIn-1', 'singleWordInput'),
               new Feature (  'antoniohhd@gmail.com' , false , 2, 'toggleSingleWordBut-1', 'toggleSingleWordButton'),
           
    
            ], []),

    new MultiFeatures ( 'multiFeat-1' , 'multiFeature' , 'multiFeature-1' , 'antoniohhd@gmail.com', null,
        false, [], 'home note ',
        [     
             new Feature (  'antoniohhd@gmail.com' , false , 0, 'singleDropDownSel-2', 'singleDropDownSelect' ),
             new Feature (  'antoniohhd@gmail.com' , false , 1, 'singleWordIn-1', 'singleWordInput'),
             new Feature (   'antoniohhd@gmail.com' , false , 2, 'toggleSingleWordBut-1', 'toggleSingleWordButton'),
           
        ],[]),
     new MultiFeatures ( 'multiFeat-2' , 'multiFeature' , 'multiFeature-2' , 'antoniohhd@gmail.com', null,
            false, [], 'crew',
            [
                new Feature (  'antoniohhd@gmail.com' , false , 0, 'toggleSingleWordBut-2', 'toggleSingleWordButton'),
                new Feature (  'antoniohhd@gmail.com' , false , 1, 'toggleSingleWordBut-1', 'toggleSingleWordButton'),
                new Feature (  'antoniohhd@gmail.com' , false , 2, 'singleW-1', 'singleWord'),
                new Feature (  'antoniohhd@gmail.com' , false , 3, 'singleW-3', 'singleWord'),
                new Feature (   'antoniohhd@gmail.com' , false , 4, 'singleW-2', 'singleWord'),
                new Feature (   'antoniohhd@gmail.com' , false , 5, 'singleW-3', 'singleWord'),
                new Feature (  'antoniohhd@gmail.com' , false , 6, 'singleW-1', 'singleWord')
        ],[]),
        new MultiFeatures ( 'multiFeat-3' , 'multiFeature' , 'multiFeature-3' , 'antoniohhd@gmail.com', null,
            false, [], 'yearly',
            [
                new Feature (   'antoniohhd@gmail.com' , false , 0, 'toggleSingleWordBut-2', 'toggleSingleWordButton'),
                new Feature ( 'antoniohhd@gmail.com' , false , 1, 'toggleSingleWordBut-1', 'toggleSingleWordButton'),
                new Feature (   'antoniohhd@gmail.com' , false , 2, 'toggleSingleWordBut-1', 'toggleSingleWordButton'),
                new Feature (   'antoniohhd@gmail.com' , false , 3, 'toggleSingleWordBut-3', 'toggleSingleWordButton'),
                new Feature (  'antoniohhd@gmail.com' , false , 4, 'toggleSingleWordBut-2', 'toggleSingleWordButton'),
                new Feature (  'antoniohhd@gmail.com' , false , 5, 'toggleSingleWordBut-3', 'toggleSingleWordButton'),
                new Feature (  'antoniohhd@gmail.com' , false , 6, 'toggleSingleWordBut-1', 'toggleSingleWordButton'),
                new Feature (  'antoniohhd@gmail.com' , false , 7, 'toggleSingleWordBut-2', 'toggleSingleWordButton'),
                new Feature ( 'antoniohhd@gmail.com' , false , 8, 'toggleSingleWordBut-1', 'toggleSingleWordButton'),
                new Feature (  'antoniohhd@gmail.com' , false , 9, 'toggleSingleWordBut-1', 'toggleSingleWordButton'),
                new Feature (  'antoniohhd@gmail.com' , false , 10, 'toggleSingleWordBut-3', 'toggleSingleWordButton'),
                new Feature (  'antoniohhd@gmail.com' , false , 11, 'toggleSingleWordBut-2', 'toggleSingleWordButton'),
                new Feature (  'antoniohhd@gmail.com' , false , 12, 'toggleSingleWordBut-3','toggleSingleWordButton'),
                new Feature (  'antoniohhd@gmail.com' , false , 13, 'toggleSingleWordBut-1', 'toggleSingleWordButton')
        ],[]),   



];

initialNullTabs: Tab[] =  [

    new Tab ( 't-1', 'tab','tab-1', 'antoniohhd@gmail.com', null, false, [], 'client USA', [

        new TabDetail (  'bisma laut pribadi', [
            new Feature ( 'antoniohhd@gmail.com' , false , 0, 'fixLay-3', 'fixLayout' ),
           // new Feature ( null ,  'antoniohhd@gmail.com', false , 1,  0,  '1', 'fixLayout'),
            // new Feature ( null ,  'antoniohhd@gmail.com', false , 2,  0,  '0', 'fixText'),

        ]),

        new TabDetail (  'metler toledo', [
            new Feature (  'antoniohhd@gmail.com' , false , 0, 'fixLay-1', 'fixLayout' ),
          //  new Feature ( null ,  'antoniohhd@gmail.com', false , 1,  1, '1', 'multiFeatures'),
           // new Feature (  'antoniohhd@gmail.com' , 2, 0, 'word hint'),
        ]),

        new TabDetail (  'bassa veneta ', [
            new Feature ( 'antoniohhd@gmail.com' , false , 0, 'fixLay-2', 'fixLayout' ),
          //  new Feature ( null ,  'antoniohhd@gmail.com', false , 1,  1, '1', 'multiFeatures'),
           // new Feature (  'antoniohhd@gmail.com' , 2, 0, 'word hint'),
        ])
    ], []),

    new Tab ( 't-2' ,'tab','tab-2',  'antoniohhd@gmail.com', null, false, [], 'suppliers', [

        new TabDetail (  'stef', [
            new Feature (  'antoniohhd@gmail.com' , false , 0, 'wordIn-1', 'wordInput' ),
            new Feature (  'antoniohhd@gmail.com' , false , 1, 'fixLay-1', 'fixLayout'),
            new Feature (  'antoniohhd@gmail.com' , false , 2, 'multiFeat-1', 'multiFeatures'),
        ]),
        new TabDetail (  'clicncg', [
            new Feature (  'antoniohhd@gmail.com' , false , 0, 'multiFeat-0', 'multiFeatures' ),
            new Feature ( 'antoniohhd@gmail.com' , false , 1, 'wordIn-1', 'wordInput'),
            new Feature (  'antoniohhd@gmail.com', false , 2,  'multiFeat-2', 'multiFeatures'),
            new Feature ( 'antoniohhd@gmail.com', false , 3,  'multiFeat-3', 'multiFeatures'),
        ]),
        new TabDetail (  'gloves', [
            new Feature (  'antoniohhd@gmail.com', false , 0,  'multiFeat-1', 'multiFeatures'),
            new Feature (  'antoniohhd@gmail.com' , false , 1, 'wordIn-1', 'wordInput' ),
            new Feature (  'antoniohhd@gmail.com' , false , 2, 'wordIn-1', 'wordInput'),
        ])
        
    ],[])
];

initialNullParagraphs: Paragraph[] = [
    new Paragraph ( '1' , "paragraph", "paragraph-1", null , null, null,
        false , true , [], false,  'customer ' , 'fill the form in all details' ,
            [ 
              // new Feature ( null ,  'antoniohhd@gmail.com', false , 0 , 1, '1', 'dropDownSelect' ),
              // new Feature ( null ,  'antoniohhd@gmail.com', false ,1, 1, '1', 'tabs'),
                 new Feature ( null , false ,0, 't-1', 'tabs'),
              // new Feature ( null ,  'antoniohhd@gmail.com', false ,2 , 2, '2', 'multiEntryCalendar' ),
              // new Feature ( null ,  'antoniohhd@gmail.com', false ,2 , 2, '2', 'wordInput' ),
              //  new Feature (  null , 'antoniohhd@gmail.com', false ,3, 1, '1', 'multiFeatures')
            
            ] ,
            []),
    new Paragraph ( '2', "paragraph", "paragraph-2", null , null,  null,
          false , false, ['tony@tony.com'], false,'daily expense' , 'description of expenses here we have to leave the things going as nothing havppeedn and see hat is foing to happen' ,  
            [ 
              // new Feature ( null, 'antoniohhd@gmail.com', false , 2, 1, '1', 'oneDateCalendar'),
              new Feature ( null , false , 0, 'wordIn-1', 'wordInput'),
              new Feature ( null, false , 1, 'wordIn-1', 'wordInput'),
              new Feature ( null , false , 2, 'wordIn-1', 'wordInput'),
              // new Feature (  null , 'antoniohhd@gmail.com', false , 0, 2 , '2','multiFeatures' ),
              // new Feature ( null ,  'antoniohhd@gmail.com', false , 1, 3, '3', 'wordInput' ),
              // // to be adjusted
              // new Feature (  null , 'antoniohhd@gmail.com', false ,3, 1, '1', 'checkBoxSelect')
            ] ,
            []),         

    new Paragraph ( '3' , "paragraph", "paragraph-3", 'owner' , null, null,
            false , true , [], false,'tab-Multi-layout' , 'blando 3' ,
             [ 
              //  new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1 , '1', 'tabs' ),
              //  new Feature ( null ,  'antoniohhd@gmail.com', false , 1, 3, '3', 'multiFeatures' ),
              //  new Feature ( null ,  'antoniohhd@gmail.com', false , 2, 1, '1', 'fixLayout' )
              new Feature (  'antoniohhd@gmail.com', false , 0, 't-1', 'tabs' ),
              new Feature (  'antoniohhd@gmail.com', false , 1, 'multiFeat-3', 'multiFeatures' ),
              new Feature (  'antoniohhd@gmail.com', false , 2, 'fixLay-1', 'fixLayout' )
             ],
             []
    ),   


new Paragraph(  '4' , "paragraph", "paragraph-4", null , null,   null,    
       false , false, [], false,'fix text' , 'forza' ,
      [ 
      //  new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1, '2', 'fixText' ),
      new Feature (   'antoniohhd@gmail.com', false , 0, 'fixT-2', 'fixText' ),
       
      ],
      []
),
new Paragraph(  '5' , "paragraph", "paragraph-5",  'owner', null,  null,     
false , false, [], false,'departure/arrival' , 'input the date of departure and arrival' ,
[ 
// new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1, '1', 'oneDateCalendar' ),
new Feature (   'antoniohhd@gmail.com', false , 0, 'singleDateCalen-1', 'oneDateCalendar' ),

],
[]
),
new Paragraph( '6' , "paragraph", "paragraph-6",  'owner', null,    null,   
false , false, [], false,'wotking period' , 'input the starting and finish working date' ,
[ 
// new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1, '2', 'oneDateCalendar' ),
new Feature (  'antoniohhd@gmail.com', false , 0, 'singleDateCalen-2', 'oneDateCalendar' ),

],
[]),
new Paragraph( '7', "paragraph", "paragraph-7",  'owner', null,     null,  
false , false, [], false,'single date' , 'input date' ,
[ 
// new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1, '3', 'oneDateCalendar' ),
new Feature (   'antoniohhd@gmail.com', false , 0, 'singledateCalen-3', 'oneDateCalendar' ),

],[]),
new Paragraph( '8' , "paragraph", "paragraph-8",  'owner', null,   null,    
     false , false, [], false,'offer from PL' , 'indert the date and select the products, and you have the optionto change quantity / price/ discount' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1 , '3', 'oneDateCalendar' ),
      // new Feature ( null, 'atny@mail.com', false, 1, 1, '1', 'calculatorMixTable')
      new Feature (  'atny@mail.com', false, 0, 'calculatorMixTa-1', 'calculatorMixTable')
                        
    ],[]
),  
new Paragraph( '9' , "paragraph", "paragraph-9", 'owner', null,     null,  
false , false, [], false,'multi date' , 'input date' ,
[ 
// new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1, '1', 'multiEntryCalendar' ),
new Feature (   'antoniohhd@gmail.com', false , 0, 'multiEntryCalen-1', 'multiEntryCalendar' ),

],[]),

new Paragraph( '10' , "paragraph", "paragraph-10", 'owner', null,  null,     
false , false, [], false,'work expenses' , '' ,
[ 
// new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '0', 'multiFeatures' ),
new Feature (   'antoniohhd@gmail.com', false , 0, 'multiFeat-0', 'multiFeatures' ),

],[]),
new Paragraph( '11' , "paragraph", "paragraph-11", 'owner', null,     null,  
false , false, [], false,'home expenses' , 'input any home expenses ' ,
[ 
// new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '1', 'multiFeatures' ),
new Feature (  'antoniohhd@gmail.com', false , 0, 'multiFeat-1', 'multiFeatures' ),

],[]),
new Paragraph(  '12' , "paragraph", "paragraph-12",  'owner', null,   null,
false , false, [], false,'issue with DRS' , 'input any home expenses ' ,
[ 
// new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '0', 'fixText' ),
new Feature (  'antoniohhd@gmail.com', false , 0, 'fixT-0', 'fixText' ),

],[]),      
     new Paragraph(  '13' , "paragraph", "paragraph-13", 'owner', null,  null,     
               false , false, [], false,'layout' , 'clone' ,
              [ 
                // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1 , '1', 'fixLayout' ),
                // new Feature ( null, 'atny@mail.com', false, 1, 1, '1', 'calculatorMixTable')
                new Feature (  'antoniohhd@gmail.com', false , 0,  'fixLay-1', 'fixLayout' ),
                new Feature (  'atny@mail.com', false, 1, 'calculatorMixTa-1', 'calculatorMixTable')
                                  
              ],[]
    ),  
    
    new Paragraph(  '14' , "paragraph", "paragraph-14",  null , null,  null,     
                 false , false, [], false,'fix text' , 'forza' ,
                [ 
                  // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1, '2', 'fixText' ),
                  new Feature (  'antoniohhd@gmail.com', false , 0, 'fixT-2', 'fixText' ),
                 
                ],[]
    ),
    new Paragraph(  '15' , "paragraph", "paragraph-15", 'owner', null,   null,    
     false , false, [], false,'departure/arrival' , 'input the date of departure and arrival' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1, '1', 'oneDateCalendar' ),
      new Feature (   'antoniohhd@gmail.com', false , 0, 'singleDateCalen-1', 'oneDateCalendar' ),
     
    ],[]
    ),
    new Paragraph( '16' , "paragraph", "paragraph-16",'owner', null,    null,   
     false , false, [], false,'wotking period' , 'input the starting and finish working date' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1, '2', 'oneDateCalendar' ),
      new Feature (  'antoniohhd@gmail.com', false , 0, 'singleDateCalen-2', 'oneDateCalendar' ),
     
    ],[]),
    new Paragraph( '17', "paragraph", "paragraph-17", 'owner', null,  null,     
     false , false, [], false,'single date' , 'input date' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1, '3', 'oneDateCalendar' ),
      new Feature (  'antoniohhd@gmail.com', false , 0, 'singleDateCalen-3', 'oneDateCalendar' ),
     
    ],[]),
    new Paragraph( '18' , "paragraph", "paragraph-18", 'owner', null,  null,     
               false , false, [], false,'offer from PL' , 'indert the date and select the products, and you have the optionto change quantity / price/ discount' ,
              [ 
                // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1 , '3', 'oneDateCalendar' ),
                // new Feature ( null, 'atny@mail.com', false, 1, 1, '1', 'calculatorMixTable')
                new Feature (  'atny@mail.com', false, 0, 'calculatorMixTa-1', 'calculatorMixTable')
                                  
              ],[]
    ),  
    new Paragraph( '19' , "paragraph", "paragraph-19", 'owner', null,  null,     
     false , false, [], false,'multi date' , 'input date' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1, '1', 'multiEntryCalendar' ),
      new Feature (  'antoniohhd@gmail.com', false , 0, 'multiEntryCalen-1', 'multiEntryCalendar' ),
     
    ],[]),

    new Paragraph( '20' , "paragraph", "paragraph-20", 'owner', null,  null,     
     false , false, [], false,'work expenses' , '' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '0', 'multiFeatures' ),
      new Feature (  'antoniohhd@gmail.com', false , 0, 'multiFeat-0', 'multiFeatures' ),
     
    ],[]),
    new Paragraph( '21' , "paragraph", "paragraph-21", 'owner' , null,  null,     
     false , false, [], false,'home expenses' , 'input any home expenses ' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '1', 'multiFeatures' ),
      new Feature (  null , false , 0, 'multiFeat-1', 'multiFeatures' ),
     
    ],[]),
    new Paragraph(  '22' , "paragraph", "paragraph-22", null , null,  null, 
     false , false, [], false,'issue with DRS' , 'input any home expenses ' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '0', 'fixText' ),
      new Feature (  null , false , 0, 'fixT-0', 'fixText' ),
     
    ],[]),
    new Paragraph( '23' , "paragraph", "paragraph-23", null , null,  null, 
     false , false, [], false,'issue with front flaps' , 'consider these solution after carefully checking the manual' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '1', 'fixText' ),
      new Feature ( null , false , 0, 'fixT-1', 'fixText' ),
     
    ],[]),
    new Paragraph( '24' , "paragraph", "paragraph-24", 'owner', null,  null, 
     false , false, [], false,'f&b select' , 'select more than one' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '1', 'checkBoxSelect' ),
      new Feature (  'antoniohhd@gmail.com', false , 0, '1', 'checkBoxSelect' ),
     
    ],[]),
    new Paragraph( '25' , "paragraph", "paragraph-25", null , null,  null, 
     false , false, [], false,'term of pay 60gg' , 'dai vediamo se siamo corretti con la lista ' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '2', 'fixText' ),
      new Feature ( null, false , 0, 'fixT-2', 'fixText' ),
     
    ],[]),
    new Paragraph( '26' , "paragraph", "paragraph-26", null , null,   null,
     false , false, [], false,'term of pay 30' , '' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '3', 'fixText' ),
      new Feature (  null , false , 0, 'fixT-3', 'fixText' ),
     
    ],[]),
    new Paragraph( '27' , "paragraph", "paragraph-27", null , null,  null, 
     false , false, [], false,' chinese term of pay 30' , '' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '4', 'fixText' ),
      new Feature (  null , false , 0, 'fixT-4', 'fixText' ),
     
    ],[]),
    new Paragraph( '28' , "paragraph", "paragraph-28", null , null,  null, 
     false , false, [], false,' italian term of pay 30' , '' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '5', 'fixText' ),
      new Feature (  null , false , 0, 'fixT-5', 'fixText' ),
     
    ],[]),
    new Paragraph( '29' , "paragraph", "paragraph-29", null, null,  null, 
     false , false, [], false,'drop down select multiple' , '' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '1', 'dropDownSelect' ),
      new Feature (  'antoniohhd@gmail.com', false , 0, 'dropDownSel-1', 'dropDownSelect' ),
     
    ],[]),
    new Paragraph( '30' , "paragraph", "paragraph-30", 'owner', null,  null, 
     false , false, [], false,'calendar multi entry' , '' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '1', 'multiEntryCalendar' ),
      new Feature (  'antoniohhd@gmail.com', false , 0, 'multiEntryCalen-1', 'multiEntryCalendar' ),
     
    ],[]),
    new Paragraph( '31' , "paragraph", "paragraph-31", null , null,  null, 
     false , false, [], false,'input personal data' , '' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '2', 'wordInput' ),
      new Feature (  null , false , 0, 'wordIn-2', 'wordInput' ),
     
    ],[]),
    new Paragraph( '32' , "paragraph", "paragraph-32", 'owner', null,  null, 
    false , false, [], false,'scan QR code' , '' ,
   [ 
     // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '2', 'wordInput' ),
     new Feature ( 'antoniohhd@gmail.com', false , 0, '0', 'ScanQRCode' ),
    
   ],[]),
   new Paragraph( '33' , "paragraph", "paragraph-33", 'owner', null,  null, 
   false , false, [], false,'basic qr code' , '' ,
  [ 
    // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '2', 'wordInput' ),
    new Feature (  'antoniohhd@gmail.com', false , 0, '0', 'qrCodeType' ),
   
  ],[]),
  new Paragraph( '34' , "paragraph", "paragraph-34", 'owner', null,   null,
  false , false, [], false,'comment' , '' ,
 [ 
   // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '2', 'wordInput' ),
   new Feature (  '', false , 0, '0', 'comment' ),
  
 ],[]),
 new Paragraph( '35' , "paragraph", "paragraph-35", 'owner' , null,   null,
  false , false, [], false,'VILLA WASABI / ESMERALDA ROOM' , '' ,
 [ 
   new Feature ( null , false , 0, 'sharedCalendarSta-1', 'sharedCalendarStatus' ),
  
 ],[]),
 new Paragraph( '36' , "paragraph", "paragraph-36", null  , null,   null,
 false , false, [], false,'VILLA WASABI / GARDENIA ROOM' , '' ,
[ 
  new Feature ( null , false , 0, 'sharedCalendarSta-2', 'sharedCalendarStatus' ),
 
],[])
                
];


initialNullForms: Form[] = [

    new Form ( '1', 'form', 'form-1', null , null, null, false , [], false,  true, false, true, false , 
            'wasabi villa', 'recipientIn-1', ['35', '36']),

    new Form ( '2' , 'form', 'form-2', null ,null, null, false , [], false, false, false, true, false , 
         'visit' , 'recipientIn-3', ['17', '1','25', '24'] ),
  
    new Form ( '3', 'form', 'form-3', null , null, null, false , [], false, false, false, true,   false , 
         'work expenses note' , 'recipientIn-3', ['17', '29', '20']),

      new Form ( '4', 'form', 'form-4', 'owner' ,null, null, false, [], false, false, false, true,  false , 
       'home expenses' , 'recipientIn-1', ['17', '1', '20']) ,
      

     new Form ( '5', 'form', 'form-5', 'owner' ,null, null, false , [], false, false, false, true , false , 
        'vacations' , 'recipientIn-1', ['30']) 

      

];



initialNullRecipientIndexColumns: RecipientIndexColumn [] = [

    new RecipientIndexColumn ( 'recipientIndexCol-1', 'recipientIndexColumn','recipientIndexColumn-1', null, false, [], null, 'shared', 'shared groups', []),
    new RecipientIndexColumn ( 'recipientIndexCol-2','recipientIndexColumn','recipientIndexColumn-2', null, false, [], null, 'personal', 'my groups', ['recipientIndexListI-1', 'recipientIndexListI-2', 'recipientIndexListI-3'])
]

initialNullRecipientFeatures: RecipientFeature[] = [new RecipientFeature ( 'recipientFeat-1', 'recipientFeature', 'recipientFeature-1'  , null , false, [], null , [])]


initialNullRecipientIndexListItems: RecipientIndexListItem[] = [

    new RecipientIndexListItem ('recipientIndexListI-1', 'recipientIndexListItem', 'recipientIndexListItem-1', null, false, [], null, 'customers', ['recipientIn-1', 'recipientIn-4'], 'recipientIn-1'),
    new RecipientIndexListItem ('recipientIndexListI-2', 'recipientIndexListItem', 'recipientIndexListItem-2', null, false, [], null, 'suppliers', ['recipientIn-2'], 'recipientIn-2'),
    new RecipientIndexListItem ('recipientIndexListI-3', 'recipientIndexListItem', 'recipientIndexListItem-3', null, false, [], null, 'R&D', ['recipientIn-3'], 'recipientIn-3')


]


initialNullRecipientIndexes: RecipientIndex[] =  [

    new RecipientIndex ( 'recipientIn-1' , 'recipientIndex', 'recipientIndex-1', null , false, [],  null, true, true, 0, 'recipi-1' , null ),
    new RecipientIndex ( 'recipientIn-2' , 'recipientIndex', 'recipientIndex-2', null , false, [],  null, true, true, 0, 'recipi-2' , null  ),
    new RecipientIndex ( 'recipientIn-3' , 'recipientIndex', 'recipientIndex-3', null , false, [],  null, true, true, 0, 'recipi-3' , null ),
    new RecipientIndex ( 'recipientIn-4' , 'recipientIndex', 'recipientIndex-4', null , false, [],  null, true, true, 0, 'recipi-4' , null )
];


initialNullFeatureContainers: FeatureContainer[] = [

    new FeatureContainer ('featureContai-1', 'featureContainer','featureContainer-1', null, false, [], null, true, true, '', 'singleW-1', 
                         [new Feature ( null , false , 0, 'singleW-1', 'singleWord')],  
    ),
    new FeatureContainer ('featureContai-2', 'featureContainer','featureContainer-2',  null, false, [], null, true, true, '', 'singleW-2', 
                         [new Feature ( null , false , 0, 'singleW-2', 'singleWord')],  
    ),
    new FeatureContainer ('featureContai-3', 'featureContainer','featureContainer-3',  null, false, [], null, true, true, '', 'singleW-3', 
                         [new Feature ( null , false , 0, 'singleW-3', 'singleWord')],  
    ),
    new FeatureContainer ('featureContai-4', 'featureContainer','featureContainer-4',  null, false, [], null, true, true, '', 'singleWordIn-1', 
                         [new Feature ( null , false , 0, 'singleWordIn-1', 'singleWordInput')],  
    ),
    new FeatureContainer ('featureContai-5', 'featureContainer','featureContainer-5',  null, false, [], null, true, true, '', 'singleWordIn-2', 
                         [new Feature ( null , false , 0, 'singleWordIn-2', 'singleWordInput')],  
    ),
    new FeatureContainer ('featureContai-6', 'featureContainer','featureContainer-6',  null, false, [], null, true, true, '', 'singleWordIn-3', 
                         [new Feature ( null , false , 0, 'singleWordIn-3', 'singleWordInput')],  
    ),
    new FeatureContainer ('featureContai-7', 'featureContainer','featureContainer-7',  null, false, [], null, true, true, '', 'toggleSingleWordBut-1', 
                         [new Feature ( null , false , 0, 'toggleSingleWordBut-1', 'toggleSingleWordButton')],  
    ),
    new FeatureContainer ('featureContai-8', 'featureContainer','featureContainer-8',  null, false, [], null, true, true, '', 'toggleSingleWordBut-2', 
                         [new Feature ( null , false , 0, 'toggleSingleWordBut-2', 'toggleSingleWordButton')],  
    ),
    new FeatureContainer ('featureContai-9', 'featureContainer','featureContainer-9',  null, false, [], null, true, true, '', 'toggleSingleWordBut-3', 
                         [new Feature ( null , false , 0, 'toggleSingleWordBut-3', 'toggleSingleWordButton')],  
    ),
    new FeatureContainer ('featureContai-10', 'featureContainer','featureContainer-10',  null, false, [], null, true, true, '', 'singleDropDownSel-1', 
                         [new Feature ( null , false , 0, 'singleDropDownSel-1', 'singleDropDownSelect')],  
    ),
    new FeatureContainer ('featureContai-11', 'featureContainer','featureContainer-11',  null, false, [], null, true, true, '', 'singleDropDownSel-2', 
                         [new Feature ( null , false , 0, 'singleDropDownSel-2', 'singleDropDownSelect')],  
    ),
    new FeatureContainer ('featureContai-12', 'featureContainer','featureContainer-12',  null, false, [], null, true, true, '', 'singleSli-1', 
                         [new Feature ( null , false , 0, 'singleSli-1', 'singleSlider')],  
    ),
    new FeatureContainer ('featureContai-13', 'featureContainer','featureContainer-13',  null, false, [], null, true, true, '', 'singleSli-2', 
                         [new Feature ( null , false , 0, 'singleSli-2', 'singleSlider' )],  
    ),
    new FeatureContainer ('featureContai-14', 'featureContainer','featureContainer-14',  null, false, [], null, true, true, '', 'wordIn-1', 
                         [new Feature ( null , false , 0, 'wordIn-1', 'wordInput')],  
    ),
    new FeatureContainer ('featureContai-15', 'featureContainer','featureContainer-15',  null, false, [], null, true, true, '', 'fixT-1', 
                         [new Feature ( null , false , 0, 'fixT-1', 'fixText')],  
    ),
    new FeatureContainer ('featureContai-16', 'featureContainer','featureContainer-16',  null, false, [], null, true, true, '', 'fixT-6', 
                         [new Feature ( null , false , 0, 'fixT-6', 'fixText')],  
    ),
    
    new FeatureContainer ('featureContai-17', 'featureContainer','featureContainer-17',  null, false, [], null, true, true, '', 'fixT-2', 
                         [new Feature ( null , false , 0, 'fixT-2', 'fixText')],  
    ),
    new FeatureContainer ('featureContai-18', 'featureContainer','featureContainer-18',  null, false, [], null, true, true, '', 'fixT-3', 
                         [new Feature ( null , false , 0, 'fixT-3', 'fixText')],  
    ),
    new FeatureContainer ('featureContai-19', 'featureContainer','featureContainer-19',  null, false, [], null, true, true, '', 'fixT-4', 
                         [new Feature ( null , false , 0, 'fixT-4', 'fixText')],  
    ),
    new FeatureContainer ('featureContai-20', 'featureContainer','featureContainer-20',  null, false, [], null, true, true, '', 'fixT-5', 
                         [new Feature ( null , false , 0, 'fixT-5', 'fixText' )],  
    ),
    new FeatureContainer ('featureContai-21', 'featureContainer','featureContainer-21',  null, false, [], null, true, true, '', 'fixT-6', 
    [new Feature ( null , false , 0, 'fixT-6', 'fixText')],  
),
new FeatureContainer ('featureContai-22', 'featureContainer','featureContainer-22',  null, false, [], null, true, true, '', 'fixLay-1', 
    [new Feature ( null , false , 0, 'fixLay-1', 'fixLayout')],  
),
new FeatureContainer ('featureContai-23', 'featureContainer','featureContainer-23',  null, false, [], null, true, true, '', 'fixLay-2', 
    [new Feature ( null , false , 0, 'fixLay-2', 'fixLayout')],  
),
new FeatureContainer ('featureContai-24', 'featureContainer','featureContainer-24',  null, false, [], null, true, true, '', 'fixLay-3', 
    [new Feature ( null , false , 0, 'fixLay-3', 'fixLayout')],  
),
new FeatureContainer ('featureContai-25', 'featureContainer','featureContainer-25',  null, false, [], null, true, true, '', 'singleDateCalen-1', 
    [new Feature ( null , false , 0, 'singleDateCalen-1', 'oneDateCalendar')],  
),
new FeatureContainer ('featureContai-26', 'featureContainer','featureContainer-26',  null, false, [], null, true, true, '', 'singleDateCalen-2', 
    [new Feature ( null , false , 0, 'singleDateCalen-2', 'oneDateCalendar')],  
),
new FeatureContainer ('featureContai-27', 'featureContainer','featureContainer-27',  null, false, [], null, true, true, '', 'singleDateCalen-3', 
    [new Feature ( null , false , 0, 'singleDateCalen-3', 'oneDateCalendar')],  
),
new FeatureContainer ('featureContai-28', 'featureContainer','featureContainer-28',  null, false, [], null, true, true, '', 'multiEntryCalen-1', 
    [new Feature ( null , false , 0, 'multiEntryCalen-1', 'multiEntryCalendar')],  
),
new FeatureContainer ('featureContai-29', 'featureContainer','featureContainer-29',  null, false, [], null, true, true, '', 'multiEntryCalen-2', 
    [new Feature ( null , false , 0, 'multiEntryCalen-2', 'multiEntryCalendar')],  
),
new FeatureContainer ('featureContai-30', 'featureContainer','featureContainer-30',  null, false, [], null, true, true, '', 'calculatorMixTa-1', 
    [new Feature ( null , false , 0, 'calculatorMixTa-1', 'calculatorMixTable')],  
),
new FeatureContainer ('featureContai-31', 'featureContainer','featureContainer-31',  null, false, [], null, true, true, '', 'calculatorMixTa-2', 
    [new Feature ( null , false , 0, 'calculatorMixTa-2', 'calculatorMixTable' )],  
),
new FeatureContainer ('featureContai-32', 'featureContainer','featureContainer-32',  null, false, [], null, true, true, '', 'dropDownSel-1', 
    [new Feature ( null , false , 0, 'dropDownSel-1', 'dropDownSelect')],  
),
new FeatureContainer ('featureContai-33', 'featureContainer','featureContainer-33',  null, false, [], null, true, true, '', 'dropDownSel-2', 
    [new Feature ( null , false , 0, 'dropDownSel-2', 'dropDownSelect' )],  
),
new FeatureContainer ('featureContai-34', 'featureContainer','featureContainer-34',  null, false, [], null, true, true, '', 'multiFeat-0', 
    [new Feature ( null , false , 0, 'multiFeat-0', 'multiFeatures')],  
),
new FeatureContainer ('featureContai-35', 'featureContainer','featureContainer-35',  null, false, [], null, true, true, '','multiFeat-1', 
    [new Feature ( null , false , 0, 'multiFeat-1', 'multiFeatures')],  
),
new FeatureContainer ('featureContai-36', 'featureContainer','featureContainer-36',  null, false, [], null, true, true, '', 'multiFeat-2', 
    [new Feature ( null , false , 0, 'multiFeat-2', 'multiFeatures')],  
),
new FeatureContainer ('featureContai-37', 'featureContainer','featureContainer-37',  null, false, [], null, true, true, '', 'multiFeat-3', 
    [new Feature ( null , false , 0, 'multiFeat-3', 'multiFeatures')],  
),
new FeatureContainer ('featureContai-38', 'featureContainer','featureContainer-38',  null, false, [], null, true, true, '', 't-1', 
    [new Feature ( null , false , 0, 't-1', 'tabs')],  
),
new FeatureContainer ('featureContai-39', 'featureContainer','featureContainer-39',  null, false, [], null, true, true, '', 't-2', 
    [new Feature ( null , false , 0, 't-2', 'tabs')],  
),
new FeatureContainer ('featureContai-40', 'featureContainer','featureContainer-40',  null, false, [], null, true, true, '', 'sharedCalendarSta-1', 
    [new Feature ( null , false , 0, 'sharedCalendarSta-1', 'sharedCalendarStatus')],  
),
new FeatureContainer ('featureContai-41', 'featureContainer','featureContainer-41',  null, false, [], null, true, true, '', 'sharedCalendarSta-2', 
    [new Feature ( null , false , 0, 'sharedCalendarSta-2', 'sharedCalendarStatus')],  
),
new FeatureContainer ('featureContai-42', 'featureContainer','featureContainer-42',  null, false, [], null, true, true, '', 'sharedCalendarSta-3', 
    [new Feature ( null , false , 0, 'sharedCalendarSta-3', 'sharedCalendarStatus')],  
)

   

    
]

/**
 * export class FeatureContainerListItem{

    constructor( public _id: string , 
                 public owner: string , 
                 public allUsersAllowed: boolean , 
                 public userListAllowed: string [], 
                 public dateAndTime: string ,  
                 public featureContainer_ids: string[] , // list of featureContainer_id
                 public selectedFeatureContainer_id: string){} 
}
 */

initialNullFeatureContainerListItems: FeatureContainerListItem[] = [

    new FeatureContainerListItem ('featureContainerListI-1', 'featureContainerListItem','featureContainerListItem-1', null, false, [], null, 'personal' , 'singleWord', 
    ['featureContai-1', 'featureContai-2', 'featureContai-3'],
     'featureContai-1' 
    ),
    new FeatureContainerListItem ( 'featureContainerListI-2', 'featureContainerListItem','featureContainerListItem-2',  null, false, [], null, 'personal' , 'singleWordInput',
        ['featureContai-4', 'featureContai-5', 'featureContai-6'],
         'featureContai-4'
    ),
    new FeatureContainerListItem ( 'featureContainerListI-3', 'featureContainerListItem','featureContainerListItem-3',  null, false, [], null, 'personal' , 'toggleSingleWordButton',
    ['featureContai-7','featureContai-8','featureContai-9'], 
     'featureContai-7' ),

    new FeatureContainerListItem ( 'featureContainerListI-4', 'featureContainerListItem','featureContainerListItem-4',  null, false, [], null, 'personal' , 'singleDropDownSelect',
    ['featureContai-10','featureContai-11'], 'featureContai-10' ),

    new FeatureContainerListItem ( 'featureContainerListI-5', 'featureContainerListItem','featureContainerListItem-5',  null, false, [], null, 'personal' , 'singleSlider',
    ['featureContai-12','featureContai-13'], 'featureContai-12' ),
    
    new FeatureContainerListItem ( 'featureContainerListI-6', 'featureContainerListItem','featureContainerListItem-6',  null, false, [], null, 'personal' , 'wordInput',
    ['featureContai-14'], 'featureContai-14' ),

    new FeatureContainerListItem ( 'featureContainerListI-7', 'featureContainerListItem','featureContainerListItem-7',  null, false, [], null, 'personal' , 'multiFeatures',
    ['featureContai-34','featureContai-35', 'featureContai-36', 'featureContai-37'], 'featureContai-34' ),

    new FeatureContainerListItem ( 'featureContainerListI-8', 'featureContainerListItem','featureContainerListItem-8',  null, false, [], null, 'personal' , 'tabs',
    ['featureContai-38', 'featureContai-39'], 'featureContai-38' ),

    new FeatureContainerListItem ( 'featureContainerListI-9', 'featureContainerListItem','featureContainerListItem-9',  null, false, [], null, 'personal' , 'fixLayout',
    ['featureContai-22', 'featureContai-23', 'featureContai-24'], 'featureContai-22' ),

    new FeatureContainerListItem ( 'featureContainerListI-10', 'featureContainerListItem','featureContainerListItem-10',  null, false, [], null, 'personal' , 'oneDateCalendar',
    ['featureContai-25', 'featureContai-26', 'featureContai-27'], 'featureContai-25' ),

    new FeatureContainerListItem ( 'featureContainerListI-11', 'featureContainerListItem','featureContainerListItem-11',  null, false, [], null, 'personal' , 'multiEntryCalendar',
     ['featureContai-28', 'featureContai-29'], 'featureContai-28' ),

    new FeatureContainerListItem ( 'featureContainerListI-12', 'featureContainerListItem','featureContainerListItem-12',  null, false, [], null, 'personal' , 'fixText',
    ['featureContai-15', 'featureContai-16', 'featureContai-17', 'featureContai-18', 'featureContai-19','featureContai-20', 'featureContai-21',], 
     'featureContai-15' ),

    new FeatureContainerListItem ( 'featureContainerListI-13', 'featureContainerListItem','featureContainerListItem-13',  null, false, [], null, 'personal' , 'calculatorMixTable',
    ['featureContai-30', 'featureContai-31'], 
     'featureContai-30' ),

    new FeatureContainerListItem ( 'featureContainerListI-14', 'featureContainerListItem','featureContainerListItem-14',  null, false, [], null, 'personal' , 'dropDownSelect',
    ['featureContai-32', 'featureContai-33'], 
     'featureContai-32' ),

    new FeatureContainerListItem ( 'featureContainerListI-15', 'featureContainerListItem','featureContainerListItem-15',  null, false, [], null, 'personal' , '' ,
    [], null ),

    new FeatureContainerListItem ( 'featureContainerListI-16', 'featureContainerListItem','featureContainerListItem-16',  null, false, [], null, 'personal' , '',
    [], null ),

    new FeatureContainerListItem ( 'featureContainerListI-17', 'featureContainerListItem','featureContainerListItem-17',  null, false, [], null, 'personal' , '',
    [], null ),

    new FeatureContainerListItem ( 'featureContainerListI-18', 'featureContainerListItem','featureContainerListItem-18',  null, false, [], null, 'personal' , 'sharedCalendarStatus',
    ['featureContai-40', 'featureContai-41', 'featureContai-42'], 
    'featureContai-40' ),

]

/**
 * export class FeatureIndexTopicList {

    constructor ( public _id: string , 
                  public owner: string , 
                  public allUsersAllowed: boolean,
                  public userListAllowed: string[],
                  public dateAndTime: string , 
                  public type: string , // personal, shared, preferred , public 
                  public featureTopic_ids: string[]){}
}
 */

initialNullFeatureIndexTopicListItems: FeatureIndexTopicList [] =
[
    new FeatureIndexTopicList ( '1', 'featureIndexTopicList', 'featureIndexTopicList-1', null, false, [], null, 'personal', ['1', '2', '3','4','5', '13', '11','8','9','10','12','14','15', '6', '7']),
    new FeatureIndexTopicList ( '2', 'featureIndexTopicList', 'featureIndexTopicList-2', null, false, [], null, 'share', ['']),
    new FeatureIndexTopicList ( '3', 'featureIndexTopicList', 'featureIndexTopicList-3', null, false, [], null, 'preference', ['']),
    new FeatureIndexTopicList ( '4', 'featureIndexTopicList', 'featureIndexTopicList-4', null, false, [], null, 'public', ['']),

]
/**
 *  constructor  ( public _id: string , 
                   public owner: string , 
                   public allUsersAllowed: boolean,
                   public userListAllowed: string[],
                   public isStandardFeature: boolean, 
                   public isTab: boolean, 
                   public name : string , 
                   public featureConta
 */


initialNullFeatureTopics: FeatureTopic[] = [

    new FeatureTopic ( '1' ,'featureTopic','featureTopic-1', null, false, [], null , 'personal' ,
    false, false, 'single words' , 
    [ 'featureContainerListI-1', 'featureContainerListI-2' ]),

    new FeatureTopic ( '2' ,'featureTopic','featureTopic-2',  'antoniohhd@gmail.com',  false, [], null , 'personal' ,
        false, false, 'single buttons' , 
           [ 'featureContainerListI-3']
    ),

    new FeatureTopic ( '3' ,'featureTopic','featureTopic-3',  null,  false, [],  null , 'personal' ,
    false, false, 'single select' , 
       [ 
        'featureContainerListI-4'
       ]
    ),

    new FeatureTopic ( '4' ,'featureTopic','featureTopic-4',  null,  false, [], null , 'personal' ,
    false, false, 'single slider' , 
       [ 
        'featureContainerListI-5'
       ]
    ),

    new FeatureTopic ( '5' ,'featureTopic','featureTopic-5',  null ,  false, [], null , 'personal' ,
    true, false, 'words' , 
       [ 
        'featureContainerListI-6'
       ]
    ),

    new FeatureTopic ( '11','featureTopic','featureTopic-11',  null ,  false, [], null , 'personal' ,
    true, false, 'multiFeature' , 
           [ 
            'featureContainerListI-7'
           ]
    ),

    new FeatureTopic ( '8' ,'featureTopic','featureTopic-8',  null ,  false, [], null , 'personal' ,
        false, true, 'tabs' , 
           [ 
            'featureContainerListI-8'
           ]
    ),

    new FeatureTopic ( '9' ,'featureTopic','featureTopic-9',  null ,  false, [], null , 'personal' ,
    true, false, 'fix layout' , 
           [ 
            'featureContainerListI-9'
           ]
    ),

    new FeatureTopic ( '10' ,'featureTopic','featureTopic-10',  null ,  false, [], null , 'personal' ,
    true, false, 'calendar' , 
           [ 
            'featureContainerListI-10' , 'featureContainerListI-11', 'featureContainerListI-18'
            
           ]
    ),

    new FeatureTopic ( '12' ,'featureTopic','featureTopic-12',  null ,  false, [], null , 'personal' ,
    true, false, 'fix text' , 
           [ 
            'featureContainerListI-12'
           ]
    ),

    new FeatureTopic ( '13' ,'featureTopic','featureTopic-13',  null ,  false, [], null , 'personal' ,
    true, false, 'table + calculation' , 
           [ 
            'featureContainerListI-13'
           ]
    ),

    new FeatureTopic ( '14' ,'featureTopic','featureTopic-14',  null ,  false, [], null , 'personal' ,
    true, false, 'select' , 
           [ 
            'featureContainerListI-14'
           ]
    ),

    new FeatureTopic ( '15' ,'featureTopic','featureTopic-15',  null ,  false, [], null , 'personal' ,
    true, false, 'comment' , 
           [ 
            'featureContainerListI-15'
           ]
    ),

    new FeatureTopic ( '6' ,'featureTopic','featureTopic-6',  null ,  false, [], null , 'personal' ,
    true, false, 'by industry sector' , 
       [ 
        'featureContainerListI-16'
       ]
),

new FeatureTopic ( '7' ,'featureTopic','featureTopic-7',  null ,  false, [], null , 'personal' ,
true, false, 'scan QR codes' , 
       [ 
        'featureContainerListI-17'
       ]
)

];

/**
 * startingParagraphTopicReference: ParagraphTopic = new ParagraphTopic (null, null, false, [], null, true, 
                                     false, '', [] )
 */

initialParagraphTopicReference: ParagraphTopic = new ParagraphTopic (null, null, null, null, false, [], null, true, false, '', [] )


initialNullParagraphTopicIndexes: ParagraphTopicIndex[]= [
    new ParagraphTopicIndex ('1', 'paragraphTopicIndex', 'paragraphTopicIndex-1', null, false, [], null,  'personal-paragraphs', ['4', '1', '2', '3']),
    new ParagraphTopicIndex ('2', 'paragraphTopicIndex', 'paragraphTopicIndex-2', null, false, [], null,  'shared-paragraphs', []), // '1'
    new ParagraphTopicIndex ('3', 'paragraphTopicIndex', 'paragraphTopicIndex-3', null, false, [], null,  'preferred-paragraphs', []), // '2','3'
    new ParagraphTopicIndex ('4', 'paragraphTopicIndex', 'paragraphTopicIndex-4', null, false, [], null,  'public-paragraphs', [])
]


initialNullParagraphFeatureContainers: ParagraphFeatureContainer[]=[

    new ParagraphFeatureContainer ( '2','paragraphFeatureContainer', 'paragraphFeatureContainer-2', null, false, [], null, true, true, 'paragraphFeature', true, '2'  ),
    new ParagraphFeatureContainer ( '4','paragraphFeatureContainer', 'paragraphFeatureContainer-4',  null, false, [], null, true, true, 'paragraphFeature', true, '17'  ),
    new ParagraphFeatureContainer ( '5','paragraphFeatureContainer', 'paragraphFeatureContainer-5',  null, false, [], null, true, true, 'paragraphFeature', true, '18'  ),
    new ParagraphFeatureContainer ( '9','paragraphFeatureContainer', 'paragraphFeatureContainer-9',  null, false, [], null, true, true, 'paragraphFeature', true, '27'  ),
    new ParagraphFeatureContainer ( '1','paragraphFeatureContainer', 'paragraphFeatureContainer-1',  null, false, [], null, true, true, 'paragraphFeature', true, '1'  ),
    new ParagraphFeatureContainer ( '11','paragraphFeatureContainer', 'paragraphFeatureContainer-11',  null, false, [], null, true, true, 'paragraphFeature', true, '24'  ),
    new ParagraphFeatureContainer ( '6','paragraphFeatureContainer', 'paragraphFeatureContainer-6',  null, false, [], null, true, true, 'paragraphFeature', true, '29'  ),
    new ParagraphFeatureContainer ( '10','paragraphFeatureContainer', 'paragraphFeatureContainer-10',  null, false, [], null, true, true, 'paragraphFeature', true, '20'  ),
    new ParagraphFeatureContainer ( '7','paragraphFeatureContainer', 'paragraphFeatureContainer-7',  null, false, [], null, true, true, 'paragraphFeature', true, '21'  ),
    new ParagraphFeatureContainer ( '8','paragraphFeatureContainer', 'paragraphFeatureContainer-8',  null, false, [], null, true, true, 'paragraphFeature', true, '34'  ),
    new ParagraphFeatureContainer ( '12','paragraphFeatureContainer', 'paragraphFeatureContainer-12',  null, false, [], null, true, true, 'paragraphFeature', true, '30'  ),
    new ParagraphFeatureContainer ( '3','paragraphFeatureContainer', 'paragraphFeatureContainer-3',  null, false, [], null, true, true, 'paragraphFeature', true, '25'  ),
    new ParagraphFeatureContainer ( '13','paragraphFeatureContainer', 'paragraphFeatureContainer-13',  null, false, [], null, true, true, 'paragraphFeature', true, '35'  ),
    new ParagraphFeatureContainer ( '14','paragraphFeatureContainer', 'paragraphFeatureContainer-14',  null, false, [], null, true, true, 'paragraphFeature', true, '36'  )

]



initialNullParagraphTopics: ParagraphTopic[] =  [

    new ParagraphTopic ( '1' , 'paragraphTopic', 'paragraphTopic-1', null , false, [], null , true, false, 'customer link', ['1','2', '3', '4' ]),
    new ParagraphTopic ( '2' , 'paragraphTopic', 'paragraphTopic-2',  null , false, [], null , true, false, 'supplier', ['5', '6', '11','7' ]),
    new ParagraphTopic ( '3' , 'paragraphTopic', 'paragraphTopic-3',  null , false, [], null , true, false, 'catalogue', ['9', '10', '12' ]),
    new ParagraphTopic ( '4' , 'paragraphTopic', 'paragraphTopic-4', null , false, [], null , true, false, 'wasabi rooms', ['13', '14' ]),
]


/**
 * export class FormIndexTopicListItem {

    constructor( public _id: string , 
        public owner: string, 
        public allUsersAllowed: boolean , 
        public userListAllowed: string[] ,
        public dateAndTime: string, 
        public type: string , // just the 4 : personal / shared/ preferred / public
        public formIndexTopic_ids: string[]
      ){}
} */ 

initialNullFormIndexTopicListItems: FormIndexTopicListItem[] = [

    new FormIndexTopicListItem ( '1', 'formIndexTopicListItem','formIndexTopicListItem-1', null, false, [], null, 'personal', ['1', '2', '3']),
    new FormIndexTopicListItem ( '2', 'formIndexTopicListItem','formIndexTopicListItem-2',  null, false, [], null, 'share', []), // '6', '3', '5', '1', '3'
    new FormIndexTopicListItem ( '3', 'formIndexTopicListItem','formIndexTopicListItem-3',  null, false, [], null, 'preference', []), // '4', '1', '3', '2'
    new FormIndexTopicListItem ( '4', 'formIndexTopicListItem','formIndexTopicListItem-4',  null, false, [], null, 'public', []),

]

initialNullFormIndexTopics: FormIndexTopic[] = [


    new FormIndexTopic (  '1', 'formIndexTopic', 'formIndexTopic-1',  null , null, 
       false ,  [] , true, false, 'villas management' ,
       ['1', '2']
    ),

     new FormIndexTopic (  '2',  'formIndexTopic', 'formIndexTopic-2',  null ,null ,
        false ,   [] , true, false,  'expenses & holidays' ,
        [ '3' ] ),

      new FormIndexTopic (  '3',  'formIndexTopic', 'formIndexTopic-3',  null , null ,
        false , [] , true, false,  'marketing' , 
         [ '5', '4'
        ]),
        new FormIndexTopic (  '4',  'formIndexTopic', 'formIndexTopic-4',  null , null, 
        false ,  [] , true, false, 'from brembo' ,
        ['1', '2']
     ),
 
      new FormIndexTopic (  '5',  'formIndexTopic', 'formIndexTopic-5',  null ,null ,
         false ,   [] , true, false,  'from hotels' ,
         [ '3', '4' ] ),
 
       new FormIndexTopic (  '6',  'formIndexTopic', 'formIndexTopic-6',  null , null ,
         false , [] , true, false,  'from admin' , 
          [ '5', '4'
         ])

] ;

initialNullFormFeatureContainers: FormFeatureContainer[] = [

    new FormFeatureContainer ( '1', 'formFeatureContainer', 'formFeatureContainer-1' , null, false, [], null, true, true, 'personal', true, null , '1'), 
    new FormFeatureContainer ( '2', 'formFeatureContainer', 'formFeatureContainer-2', null, false, [], null, true, true, 'personal', true, null,  '2'),
    new FormFeatureContainer ( '3', 'formFeatureContainer', 'formFeatureContainer-3' ,null, false, [], null, true, true, 'personal', true, null,  '3'),
    new FormFeatureContainer ( '4', 'formFeatureContainer', 'formFeatureContainer-4', null, false, [], null, true, true, 'share', true, 'recipientIn-2',  '4'),
    new FormFeatureContainer ( '5', 'formFeatureContainer', 'formFeatureContainer-5' ,null, false, [], null, true, true, 'personal', true, 'recipientIn-3' ,  '5')



]

initialNullFormResults: FormResult[] = []
initialNullFormResultsList: FormResult[] = []


/**
 * on log out => reset initian null default value instead of logged in values ... 
 */
resetAllInitialComponnetsToNullAgain(){

    this.initialNullUserProfile = this.reset_initialNullUserProfile
    this.initialNullContacts = this.reset_initialNullContacts
    this.initialUserEmail = this.reset_initialUserEmail
    this.initialNullSingleWords = this.reset_initialNullSingleWords
    this.initialNullSingleWordInputs = this.reset_initialNullSingleWordInputs
    this.initialNullToggleSingleWordButtons = this.reset_initialNullToggleSingleWordButtons
    this.initialNullSingleDropDownSelects = this.reset_initialNullSingleDropDownSelects
    this.initialNullSingleSliders = this.reset_initialNullSingleSliders
    this.initialNullRecipients = this.reset_initialNullRecipients
    this.initialNullChats = this.reset_initialNullChats
    this.initialNullWordInputs = this.reset_initialNullWordInputs
    this.initialNullFixTexts = this.reset_initialNullFixTexts
    this.initialNullFixLayouts = this.reset_initialNullFixLayouts
    this.initialNullSingleDateCalendars = this.reset_initialNullSingleDateCalendars
    this.initialNullMultiEntryCalendars = this.reset_initialNullMultiEntryCalendars
    this.initialNullSharedCalendarStatusItems = this.reset_initialNullSharedCalendarStatusItems
    this.initialNullCalculatorMixTables = this.reset_initialNullCalculatorMixTables
    this.initialNullDropDownSelects = this.reset_initialNullDropDownSelects
    this.initialNullMultiFeatures = this.reset_initialNullMultiFeatures
    this.initialNullTabs = this.reset_initialNullTabs
    this.initialNullParagraphs = this.reset_initialNullParagraphs
    this.initialNullForms = this.reset_initialNullForms
    this.initialNullRecipientIndexColumns = this.reset_initialNullRecipientIndexColumns
    this.initialNullRecipientFeatures = this.reset_initialNullRecipientFeatures
    this.initialNullRecipientIndexListItems = this.reset_initialNullRecipientIndexListItems
    this.initialNullRecipientIndexes = this.reset_initialNullRecipientIndexes
    this.initialNullFeatureContainers = this.reset_initialNullFeatureContainers
    this.initialNullFeatureContainerListItems = this.reset_initialNullFeatureContainerListItems
    this.initialNullFeatureIndexTopicListItems = this.reset_initialNullFeatureIndexTopicListItems
    this.initialNullFeatureTopics = this.reset_initialNullFeatureTopics
    this.initialParagraphTopicReference = this.reset_initialParagraphTopicReference
    this.initialNullParagraphTopicIndexes = this.reset_initialNullParagraphTopicIndexes
    this.initialNullParagraphFeatureContainers = this.reset_initialNullParagraphFeatureContainers
    this.initialNullParagraphTopics = this.reset_initialNullParagraphTopics
    this.initialNullFormIndexTopicListItems = this.reset_initialNullFormIndexTopicListItems
    this.initialNullFormIndexTopics = this.reset_initialNullFormIndexTopics
    this.initialNullFormFeatureContainers = this.reset_initialNullFormFeatureContainers
    this.initialNullFormResults = this.reset_initialNullFormResults
    this.initialNullFormResultsList = this.reset_initialNullFormResultsList


}

// send to each component servive the initial null component status after logout  
updateInitialNullDataOnAllComponents: Subscription = this.authService.updateInitialNullDataOnAllComponents
                                     .subscribe(()=> { 

                                        this.userProfileChangedBackToNull.next(this.initialNullUserProfile)
                                        this.contactsChangedBackToNull.next(this.initialNullContacts)
                                        
                                        // ----- next null base components --------------------------
                                        this.singleWordsChangedBackToNull.next(this.initialNullSingleWords)
                                        this.singleWordInputsChangedBackToNull.next(this.initialNullSingleWordInputs)
                                        this.toggleSingleWordButtonsChangedBackToNull.next(this.initialNullToggleSingleWordButtons)
                                        this.singleDropDownSelectsChangedBackToNull.next(this.initialNullSingleDropDownSelects)
                                        this.singleSlidersChangedBackToNull.next(this.initialNullSingleSliders)

                                        this.recipientsChangedBackToNull.next(this.initialNullRecipients)
                                        this.chatsChangedBackToNull.next(this.initialNullChats)
                                      //  this.groupsChangedBackToNull.next(this.initialNullGroups)

                                        this.wordsInputChangedBackToNull.next(this.initialNullWordInputs)
                                        this.fixTextsChangedBackToNull.next(this.initialNullFixTexts)
                                        this.fixLayoutsChangedBackToNull.next(this.initialNullFixLayouts)
                                        this.singleDateCalendarsChangedBackToNull.next(this.initialNullSingleDateCalendars)
                                        this.multiEntryCalendarsChangedBackToNull.next(this.initialNullMultiEntryCalendars)
                                        this.calculatorMixTablesChangedBackToNull.next(this.initialNullCalculatorMixTables)
                                        this.dropDownSelectsChangedBackToNull.next(this.initialNullDropDownSelects)
                                        this.sharedCalendarStatusChangedBackToNull.next(this.initialNullSharedCalendarStatusItems)

                                         // ----- next null multiBase components --------------------------

                                         this.multiFeaturesChangedBackToNull.next(this.initialNullMultiFeatures)
                                        
                                         // ----- next null tabs components --------------------------------

                                         this.tabsChangedBackToNull.next(this.initialNullTabs)

                                          // ----- next null paragraphs components --------------------------------

                                          this.paragraphsChangedBackToNull.next(this.initialNullParagraphs)

                                          // ----- next null paragraphs components --------------------------------

                                          this.formsChangedBackToNull.next(this.initialNullForms)

                                          this.formResultChangeBackToNull.next(this.initialNullFormResults)
                                          this.formResultsListChangeBackToNull.next(this.initialNullFormResultsList)

                                          this.featureContainersChangeBackToNull.next(this.initialNullFeatureContainers)
                                          this.featureContainerListItemsChangeBackToNull.next(this.initialNullFeatureContainerListItems)
                                          this.featureTopicsChangeBackToNull.next(this.initialNullFeatureTopics )
                                          this.featureIndexTopicListChangeBackToNull.next(this.initialNullFeatureIndexTopicListItems)

                                          this.recipientIndexChangedBackToNull.next(this.initialNullRecipientIndexes)
                                          this.recipientIndexColumnChangedBackToNull.next(this.initialNullRecipientIndexColumns)
                                          this.recipientIndexListItemsChangedBackToNull.next(this.initialNullRecipientIndexListItems)
                                          this.recipientFeatureChangeBackToNull.next(this.initialNullRecipientFeatures)


                                          this.paragraphTopicOfReferenceChangeBackToNull.next(this.initialParagraphTopicReference)
                                          this.paragraphFeatureContainersChangeBackToNull.next(this.initialNullParagraphFeatureContainers)
                                          this.paragraphTopicIndexesChangeBackToNull.next(this.initialNullParagraphTopicIndexes)
                                          this.paragraphTopicsChangeBackToNull.next(this.initialNullParagraphTopics)
                                          
                                          this.formIndexTopicsChangeBackToNull.next(this.initialNullFormIndexTopics)
                                          this.formIndexTopicListItemsChangeBackToNull.next(this.initialNullFormIndexTopicListItems)
                                          this.formFeatureContainerChangeBackToNull.next(this.initialNullFormFeatureContainers)

                                          

                                          /**
                                           * when all these components are back to null we make appComponent not loading anymore 
                                           * we need to create a service to receive all these done backToNull components and then stop loading 
                                           * just like we make sue a componentWithChilds is complete 
                                           */
                                     })


reset_initialNullUserProfile: UserProfile = new UserProfile( null, 'userProfile', 'userProfile-1', null, false, [], null, null, null, false, false, false, null, null, null, null, 
                                                              new PlanType (null, 
                                                                            new PlanTimeTable ( null, null ),
                                                                            null )
                                                     )

reset_initialNullContacts: Contact[] = [
    new Contact ( 'conct-1', 'contact', 'cont-1', null, false, [], null, null, 
                  [
                   new ContactDetails ('marie', 'marie@test.test', '+1234567890', false , false, false),
                   new ContactDetails ('james', 'james@test.test', '+1234567890', false, false, false ),
                   new ContactDetails ('kayla', 'kayla@test.test', '+1234567890', true, false, false),
                   new ContactDetails ('henry' , 'henry@test.test', '+1234567890', true, false, false)

                  ]
    )
]


reset_initialUserEmail: string = null


reset_initialNullSingleWords: SingleWord[]= [

    new SingleWord( 'singleW-1' , 'singleWord', 'singleWord-1', 'antoniohhd@gmail.com' , null ,
        false , [] , 'first single input', 
        [
            new SingleWordDetail ( 'food', 'fruit', 'fresh', 15 )
        ]
        
    ),
    new SingleWord ( 'singleW-2',  'singleWord', 'singleWord-2', 'antoniohhd@gmail.com' , null,
        false , [] , 'Second single input', 
        [
            new SingleWordDetail ( 'car', 'Ferrari','automotive', 20 )
        ]
        
    ),
    new SingleWord( 'singleW-3', 'singleWord', 'singleWord-3', 'antoniohhd@gmail.com' , null,
        false , [] , '3rd info', 
        [
            new SingleWordDetail ( 'tools', 'black&decker','brand', 18 )
        ]
        
    ),
];

reset_initialNullSingleWordInputs: SingleWordInput[] = [

    new SingleWordInput( 'singleWordIn-1' , 'singleWordInput', 'singleWordInput-1',  'antoniohhd@gmail.com', null , 
        false , [] , 'amount', 
        [
            new SingleWordInputDetail ( 'amount', 'in usd', '1,000.00',  15 )
        ]  
    ),
    new SingleWordInput ( 'singleWordIn-2' , 'singleWordInput', 'singleWordInput-2',  'antoniohhd@gmail.com',  null , 
        false , [] , 'Second input', 
        [
            new SingleWordInputDetail ( 'boat', 'bergetti', 'marine', 20 )
        ]  
    ),
    new SingleWordInput( 'singleWordIn-3' , 'singleWordInput', 'singleWordInput-3',  'antoniohhd@gmail.com',  null , 
        false , [] , '3rd info', 
        [
            new SingleWordInputDetail ( 'wood', 'teak', 'nature', 18 )
        ]  
    ),
];

reset_initialNullToggleSingleWordButtons: ToggleSingleWordButton[] = [

    new ToggleSingleWordButton ( 'toggleSingleWordBut-1' , 'toggleSingleWordButton', 'toggleSingleWordButton-1', 'antoniohhd@gmail.com', null,
        false , [], 'denomination' , 
        [
            new ToggleSingleWordButtonDetail ('USD'),
            new ToggleSingleWordButtonDetail ('EURO'),
            new ToggleSingleWordButtonDetail ('CNY'),
            new ToggleSingleWordButtonDetail ( 'IDR'),
            new ToggleSingleWordButtonDetail ('INR'),

        ]
    ),
    new ToggleSingleWordButton ( 'toggleSingleWordBut-2' , 'toggleSingleWordButton', 'toggleSingleWordButton-2',  'antoniohhd@gmail.com', null,
        false, [], 'industry' ,
        [
            new ToggleSingleWordButtonDetail ('telecom'),
            new ToggleSingleWordButtonDetail ('heavy Industry'),
            new ToggleSingleWordButtonDetail ('F & B')
        ]
    ),
    new ToggleSingleWordButton ( 'toggleSingleWordBut-3' , 'toggleSingleWordButton', 'toggleSingleWordButton-3',  'antoniohhd@gmail.com', null,
        false, [], 'good food',
        [
            new ToggleSingleWordButtonDetail ('pasta'),
            new ToggleSingleWordButtonDetail ('pesce'),
            new ToggleSingleWordButtonDetail ('carne'),
            new ToggleSingleWordButtonDetail ('verdura'),
            new ToggleSingleWordButtonDetail ('frutta')
        ]
    )
];

reset_initialNullSingleDropDownSelects: SingleDropDownSelect[] = [

    new SingleDropDownSelect ( 'singleDropDownSel-1', 'singleDropDownSelect', 'singleDropDownSel-1', 'tony@mail.com', false, [], null, 'expenses', 'type', 
                              [ 'breakfast', 'lunch', 'dinner', 'drinks', 'gasoline', 'transportation', 'ticket']
    ),
    new SingleDropDownSelect ( 'singleDropDownSel-2', 'singleDropDownSelect', 'singleDropDownSel-2', 'tony@mail.com', false, [], null, 'family expenses', 'home', 
                              [ 'electricity', 'tv', 'phone', 'water', 'garbage', 'garden']
    )                      
];

reset_initialNullSingleSliders: SingleSlider[] = [

        new SingleSlider ( 'singleSli-1', 'singleSlider', 'singleSlider-1', 'antoniohhd@gmail.com', false, [], null, 'market share' , 
                                  [
                                  new SingleSliderDetail ('market share','%', true, false , false, 100, 0, true, 1, true, 55, false, 1 )
                                  ]
        ),

        new SingleSlider ( 'singleSli-2', 'singleSlider', 'singleSlider-2', 'antoniohhd@gmail.com', false, [], null, 'total brix' , 
        [
        new SingleSliderDetail ('brix','pcs', true, false , false, 1000, 0, true, 50, true, 350, false, 50 )
        ]
),

        
    ];
    


reset_initialNullRecipients: Recipient[]=   [

    new Recipient ( 
         
        'recipi-1', 'recipient', 'recipient-1', null ,null, null, 'chat-room-id-101',
        null, null, null, 'to customers',
        [], // 'test@test.test' this is recipientList
        [], // 'test@test.test'
        ['test@test.test'],
        ['test@test.test'] ,
        [],
        ''
    ),

    new Recipient ( 
        //'-MvDSf9yffPZrSK-tzx-', 'antoniohhd@gmail.com', null, 'MvIbvhvA2x-BaPaQhRU',
        'recipi-2', 'recipient', 'recipient-2', null , null, null,  'chat-room-id-102',
         null, null, null, 'to cs gangoover ',
         [],
        [],
       ['test@test.test'],
       ['test@test.test'],
        [],
        ''
    ),

    new Recipient ( 
        //'-MvG3xFFG8aBX-Admgif', 'antoniohhd@gmail.com', null,  '-MvLE19xKBCCm-ruJQqX' ,
      'recipi-3', 'recipient', 'recipient-3', null , null,  null,  'chat-room-id-103' ,
        null, null, null,  'tony' ,
        [],
        [],
        ['test@test.test'],
        ['test@test.test'],
        [],
        ''
    ),

    new Recipient ( 'recipi-4', 'recipient', 'recipient-4', null , null, null,  'chat-room-id-104' ,
         null, null, null, 'to bella ',
         [],
        [],
        ['test@test.test'],
        ['test@test.test'],
        [],
        ''
    )


];

//initialNullGroups: Group[] = []



reset_initialNullChats: Chat[] = [

    new Chat ( 'chat-room-id-101',null , false, [], true, '15', null, 
                                 false, false, false, '--- --- --- '
    ),

    
    new Chat ( 'chat-room-id-102', null , false, [], true, '16', null, 
                               false, false, false, '--- --- --- '
    ),


    new Chat ( 'chat-room-id-103', null , false, [], true, '17',null, 
                    false, false, false, '--- --- --- '
    ),

    new Chat ( 'chat-room-id-104' , null , false, [], true, '18',null, 
                     false, false, false, '--- --- --- '
     )
]

/**
 *  wordInput initial null value
 */
reset_initialNullWordInputs: WordInput[] = [ new WordInput ( 'wordIn-1' ,'wordInput', 'wordIn-1', null ,  false, [], null,'input 1' , 
                                  [ new  WordInputDetail ('regione' , 'Vicenza', 'hey',  16 ),]
)] ;


reset_initialNullFixTexts: FixText[] = [

    new FixText ( 'fixT-1', 'fixText','fixText-1', 'tony@mail.com', false, [], null, 'contract 1', 
    [
        new FixTextDetail ( 'Fantastic Four (sometimes stylized as Fantastic 4) is a 2005 American superhero film based on the Marvel Comics team of the same name. It was directed by Tim Story, and released by 20th Century Fox. The film stars Ioan Gruffudd, Jessica Alba, Chris Evans, Michael Chiklis, Julian McMahon and Kerry Washington.'), 
        new FixTextDetail ( 'The Daunting Task of ‘Doctor Strange in the Multiverse of Madness’ as the Direct Sequel to Multiple Marvel Properties ')
    ]),

    new FixText ( 'fixT-2','fixText','fixText-2',  'tony@gmail.com', false, [], null, 'contract 2', 
    [
        new FixTextDetail ( 'Destination D23: New Super Hero Experiences Arrive in 2022 with the Guardians of the Galaxy and Avengers'),
        new FixTextDetail ( 'Shang-Chi, Black Widow and 11 More Marvel Studios Films Available on Disney+ in IMAXs Expanded Aspect Ratio' ),
        new FixTextDetail ( '6 Reasons Why You Should Watch the MCU in IMAX’s Expanded Aspect Ratio')
    ]),

    new FixText ( 'fixT-3','fixText','fixText-3',  'tony@gmail.com', false, [], null, 'term of payment', 
    [
        new FixTextDetail ('Net 10, net 15, net 30 and net 60 are forms of trade credit which specify that the net amount is expected to be paid in full by the buyer within 10, 15, 30 or 60 days of the date when the goods are dispatched or the service is completed. Net 30 or net 60 terms are often coupled with a credit for early payment')

    ]),
    new FixText ( 'fixT-4','fixText','fixText-4',  'tony@gmail.com', false, [], null, 'term of payment', 
    [
        new FixTextDetail ('Net 30 end of the month (EOM) means that the payment is due 30 days after the end of the month in which you sent the invoice. For example, if you and your client agree to net 30 EOM and you invoice them on May 11th, that payment will be due on June 30th—in other words, 30 days after May 31st' )
    ]),
    new FixText ( 'fixT-5','fixText','fixText-5', 'tony@gmail.com', false, [], null, ' chinese term of payment', 
    [
        new FixTextDetail ('What does net 30 mean on an invoice? In the U.S., the term “net 30” is one of the most common payment terms. It refers to a payment period, meaning the customer has a 30-day length of time to pay the total amount of their invoice. Other common net terms include net 60 for 60 days and net 90 for 90 days // 发票上的净 30 是什么意思？在美国，“net 30”一词是最常见的付款方式之一。它指的是一个付款期，这意味着客户有 30 天的时间来支付其发票的总金额。其他常见的净额包括 60 天的净 60 和 90 天的净 90' )
    ]),
    new FixText ( 'fixT-6','fixText','fixText-6', 'tony@gmail.com', false, [], null, ' chinese term of payment', 
    [
        new FixTextDetail ('What does net 30 mean on an invoice? In the U.S., the term “net 30” is one of the most common payment terms. It refers to a payment period, meaning the customer has a 30-day length of time to pay the total amount of their invoice. Other common net terms include net 60 for 60 days and net 90 for 90 days // Cosa significa net 30 su una fattura? Negli Stati Uniti, il termine "net 30" è uno dei termini di pagamento più comuni. Si riferisce a un periodo di pagamento, il che significa che il cliente ha un periodo di tempo di 30 giorni per pagare limporto totale della fattura. Altri termini netti comuni includono 60 netti per 60 giorni e 90 netti per 90 giorni' )
    ])
];

reset_initialNullFixLayouts: FixLayout[] = [

    new FixLayout ( 'fixLay-1','fixLayout','fixLayout-1', 'metler toledo', 'antoniohhd@gmail.com', false, [], null, 

            [
                new FixLayoutTableTR ( 
                    [ 
                        new FixLayoutTableTD ( 'string', 'metler toledo inc', 'customer name', null, null, null, null, null, null, '200px'  ),
                        new FixLayoutTableTD ( 'string', 'Eu / italy / vicenza', 'region / country / adddress', null, null,null, null, null, null, '200px'   )
                    ]
                ),
                

                new FixLayoutTableTR ( 
                    [ 
                        new FixLayoutTableTD ( 'string', 'stable', 'status', null, null,null, null, null, null, '250px'  ),
                        new FixLayoutTableTD ( 'description', null , null,  null, null, ' every month / mostly product type 3', 'main details', null, null, '500px'  ),
                        new FixLayoutTableTD ( 'string', 'we are trying to add type 2 ', 'improvement', null, null,null, null, null, null, '200px'  ),
                        new FixLayoutTableTD ( 'string', '330.000', 'potential USD', null, null,null, null, null, null, '200px'  ),
                        
                    ]
                ),
                new FixLayoutTableTR (
                    [ 
                        new FixLayoutTableTD ( 'string', '15.000', '2021', null, null,null, null, null, null, '200px'  ),
                        new FixLayoutTableTD ( 'string', '120.000', '2019', null, null, null, null, null, null , '200px' ),
                        new FixLayoutTableTD ( 'string', '10.000', '2020', null, null,null, null, null, null, '200px'  ),
                       
       
                    ]
                ),
                new FixLayoutTableTR (
                    [ 
                        new FixLayoutTableTD ( 'string', '3,000', 'january', null, null, null, null, null, null , '200px' ),
                        new FixLayoutTableTD ( 'string', '10.000', 'february', null, null,null, null, null, null, '200px'  ),
                        new FixLayoutTableTD ( 'string', '5.000', 'march', null, null,null, null, null, null, '200px'  ),
                        new FixLayoutTableTD ( 'string', '9.000', 'april', null, null, null, null, null, null , '200px' ),
                        new FixLayoutTableTD ( 'string', '12.000', 'may', null, null,null, null, null, null, '200px'  ),
                       ]
                ),
            ]
    ),

    new FixLayout ( 'fixLay-2', 'fixLayout','fixLayout-2', 'bassa veneta', 'antoniohhd@gmail.com', false, [], null, 

    [
        new FixLayoutTableTR ( 
            [ 
                new FixLayoutTableTD ( 'string', ' bassa veneta', 'customer name', null, null, null, null, null, null, '200px'  ),
                new FixLayoutTableTD ( 'string', 'Eu / Germany / Munich', 'region / country / adddress', null, null,null, null, null, null, '200px'   )
            ]
        ),
        

        new FixLayoutTableTR ( 
            [ 
                new FixLayoutTableTD ( 'string', 'new customer', 'status', null, null,null, null, null, null, '250px'  ),
                new FixLayoutTableTD ( 'description', null , null,  null, null, ' just ordered once, all type. ', 'main details', null, null, '500px'  ),
                new FixLayoutTableTD ( 'string', 'we can sell everything', 'improvement', null, null,null, null, null, null, '200px'  ),
                new FixLayoutTableTD ( 'string', '1.700.000', 'potential USD', null, null,null, null, null, null, '200px'  ),
                
            ]
        ),
        new FixLayoutTableTR (
            [ 
            new FixLayoutTableTD ( 'string', '115.000', '2022', null, null,null, null, null, null, '200px'  ),
            ],
        ),
        new FixLayoutTableTR (
            [ 
                new FixLayoutTableTD ( 'string', '121,000', 'january', null, null, null, null, null, null , '200px' ),
                new FixLayoutTableTD ( 'string', '20.000', 'february', null, null,null, null, null, null, '200px'  ),
                new FixLayoutTableTD ( 'string', '15.000', 'march', null, null,null, null, null, null, '200px'  ),
                new FixLayoutTableTD ( 'string', '99.000', 'april', null, null, null, null, null, null , '200px' ),
                new FixLayoutTableTD ( 'string', '6.000', 'may', null, null,null, null, null, null, '200px'  ),
               ]
        ),
    ]),

    new FixLayout ( 'fixLay-3','fixLayout','fixLayout-3', 'bisma laut pribadi inc', 'antoniohhd@gmail.com', false, [], null, 

    [
        new FixLayoutTableTR ( 
            [ 
                new FixLayoutTableTD ( 'string', 'bisma laut pribadi inc', 'customer name', null, null, null, null, null, null, '200px'  ),
                new FixLayoutTableTD ( 'string', 'Eu / indonesia / vicenza', 'region / country / adddress', null, null,null, null, null, null, '200px'   )
            ]
        ),
        

        new FixLayoutTableTR ( 
            [ 
                new FixLayoutTableTD ( 'string', 'stable customer', 'status', null, null,null, null, null, null, '250px'  ),
                new FixLayoutTableTD ( 'description', null , null,  null, null, 'purchase every 2 month / mostly product type 2', 'main details', null, null, '500px'  ),
                new FixLayoutTableTD ( 'string', 'we are trying to add type 1 ', 'improvement', null, null,null, null, null, null, '200px'  ),
                new FixLayoutTableTD ( 'string', '920.000', 'potential USD', null, null,null, null, null, null, '200px'  ),
                
            ]
        ),
        new FixLayoutTableTR (
            [ 
                new FixLayoutTableTD ( 'string', '740.000', '2019', null, null, null, null, null, null , '200px' ),
                new FixLayoutTableTD ( 'string', '820.000', '2020', null, null,null, null, null, null, '200px'  ),
                new FixLayoutTableTD ( 'string', '815.000', '2021', null, null,null, null, null, null, '200px'  ),

            ]
        ),
        new FixLayoutTableTR (
            [ 
                new FixLayoutTableTD ( 'string', '50,000', 'january', null, null, null, null, null, null , '200px' ),
                new FixLayoutTableTD ( 'string', '80.000', 'february', null, null,null, null, null, null, '200px'  ),
                new FixLayoutTableTD ( 'string', '35.000', 'march', null, null,null, null, null, null, '200px'  ),
                new FixLayoutTableTD ( 'string', '40.000', 'april', null, null, null, null, null, null , '200px' ),
                new FixLayoutTableTD ( 'string', '70.000', 'may', null, null,null, null, null, null, '200px'  ),
               ]
        ),
    ]
)]

reset_initialNullSingleDateCalendars: SingleDateCalendar[] =  [

    new SingleDateCalendar ( 'singleDateCalen-1' ,'singleDateCalendar' ,'singleDateCalendar-1' , 'tony@gmail.com', false, [], null, 'first', 
                       [ 
                        new SingleDateCalendarDetail ( 'arrival', new Date()),
                        new SingleDateCalendarDetail ( 'departure', new Date())
                       ]
    ),

    new SingleDateCalendar ( 'singleDateCalen-2' ,'singleDateCalendar' ,'singleDateCalendar-2' , 'tony@gmail.com', false, [], null, 'second date', 
                       [ 
                        new SingleDateCalendarDetail ( 'start working on', new Date()),
                        new SingleDateCalendarDetail ( 'stop working on', new Date())
                       ]
    ),
    new SingleDateCalendar ( 'singleDateCalen-3' ,'singleDateCalendar' ,'singleDateCalendar-3' , 'tony@gmail.com', false, [], null, 'second date', 
    [ 
     new SingleDateCalendarDetail ( 'enter date', new Date()),
    
    ]
)


];

reset_initialNullMultiEntryCalendars: MultiEntryCalendar[] =  [

    new MultiEntryCalendar ( 'multiEntryCalen-1','multiEntryCalendar','multiEntryCalendar-1', 'tony@gmail.com', false, [], null, 'primo', 'available dates', 
    [
        new Date ('5/15/2022'),
        new Date ('5/19/2022'),
        new Date ('5/26/2022')
    ]),

    new MultiEntryCalendar ('multiEntryCalen-2', 'multiEntryCalendar','multiEntryCalendar-2',  'tony@gmail.com', false, [], null, 'primo', 'we are closed on these dates ',
    [
        new Date ('6/16/2022'),
        new Date ('6/14/2022')
    ])


];



reset_initialNullSharedCalendarStatusItems: SharedCalendarStatus[] = [

    new SharedCalendarStatus ( 'sharedCalendarSta-1', 'sharedCalendarStatus', 'sharedCalendarStatus-1', null, false, [], null, 'ESMERALDA ROOM',
                                new RangeDatesInfo ( new DateStartEnd ( '', ''),
                                 [
                                    new RangeInfo ( 'innertext', 'client details',
                                    new UsersAccess ( 
                                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete accesses', false ),
                                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete text entries', false )
                                                    ), 
                                             [
                                                 new FixTextDetail ('Mr. / number:'),
                                                 new FixTextDetail ('Ms.  / number:'),
                                                 new FixTextDetail ('prefer: '),
                                                 new FixTextDetail ('motorbike Nmax plate number:'),

                                             ]),
                                    new RangeInfo ( 'innerdropdown', 'tot guests in room ', 
                                                 new UsersAccess ( 
                                                                  new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                  new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                  new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                 ), 
                                                 [ 
                                                    new InnerArraySelect ('1', false ),
                                                    new InnerArraySelect ('2', false ),
                                                    new InnerArraySelect ('3', false ),
                                                    new InnerArraySelect ('4', false ),
                                                    new InnerArraySelect ('4 +', false ),
                                                    
                                                 ]
                                    ), 
                                   
                                    new RangeInfo ( 'innercheckbox', 'request:',
                                                 new UsersAccess ( 
                                                                   new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                   new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                   new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                  ), 
                                                  /**
                                                   * export class InnerArraySelect {

                                                   constructor( public name: string, public selected: boolean ){}
                                                     }
                                                   */
                                                   [
                                                    new InnerArraySelect ('early check in', false ),
                                                    new InnerArraySelect ('late check out', false ),
                                                    new InnerArraySelect ('pick up from airport', false ),
                                                    new InnerArraySelect ('additional bed', false ),
                                                    ]
                                    ),
                                    new RangeInfo ( 'innerinput', 'additional info during stay ', 
                                                        new UsersAccess ( 
                                                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                                    ), 
                                                               [
                                                                new FixTextDetail ('add date / info'),
                                                                new FixTextDetail ('150'),
                                                                new FixTextDetail ('example: 25/9 / request breakfast take away'),
                                                                new FixTextDetail ('')
                                                               ]
                                                        ),
                                 ],
                                             new UsersAccess ( 
                                                            new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can view data', false ),
                                                            new CanDo (  [], '', true ),
                                                            new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add/delete/edit date ranges & inner rangeInfo', false )
                                                            ), 
                                             ),
                                            
                               [
        
                                new RangeDatesInfo ( new DateStartEnd ( '2023/09/15', '2023/09/19'),
                                 [
                                    new RangeInfo ( 'innertext', 'client details',
                                                       new UsersAccess ( 
                                                                        new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                        new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete accesses', false ),
                                                                        new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete text entries', false )
                                                                       ), 
                                                                [
                                                                    new FixTextDetail ('Mr. Joe Blak / number 123456789'),
                                                                    new FixTextDetail ('Ms. Kristina Bloom / number 0987654321'),
                                                                    new FixTextDetail ('prefer seafront view'),
                                                                    new FixTextDetail ('motorbike Nmax plate number: BK234'),

                                                                ]),
                                        new RangeInfo ( 'innerdropdown', 'tot guests in room ', 
                                                     new UsersAccess ( 
                                                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                     ), 
                                                     [ 
                                                        new InnerArraySelect ('1', false ),
                                                        new InnerArraySelect ('2', true ),
                                                        new InnerArraySelect ('3', false ),
                                                        new InnerArraySelect ('4', false ),
                                                        new InnerArraySelect ('4 +', false ),
                                                        
                                                     ]
                                        ), 
                                       
                                        new RangeInfo ( 'innercheckbox', 'request:',
                                                     new UsersAccess ( 
                                                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                      ), 
                                                      /**
                                                       * export class InnerArraySelect {

                                                       constructor( public name: string, public selected: boolean ){}
                                                         }
                                                       */
                                                       [
                                                        new InnerArraySelect ('early check in', false ),
                                                        new InnerArraySelect ('late check out', true ),
                                                        new InnerArraySelect ('pick up from airport', true ),
                                                        new InnerArraySelect ('additional bed', false ),
                                                        ]
                                        ),
    
                                        new RangeInfo ( 'innerinput', 'additional info during stay ', 
                                                            new UsersAccess ( 
                                                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                                        ), 
                                                                   [
                                                                    new FixTextDetail ('add date / info'),
                                                                    new FixTextDetail ('150'),
                                                                    new FixTextDetail ('example: 25/9 / request breakfast take away'),
                                                                    new FixTextDetail ('got additional pillows : 2')
                                                                   ]
                                                            ),

                                 ],
                                             new UsersAccess ( 
                                                            new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                            new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                            new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                            ), 
                                ),
                                new RangeDatesInfo ( new DateStartEnd ( '2023/09/19', '2023/09/24'),
                                [
                                    new RangeInfo ( 'innertext', 'client details',
                                    new UsersAccess ( 
                                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete accesses', false ),
                                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete text entries', false )
                                                    ), 
                                             [
                                                 new FixTextDetail ('Mr. Andy Saputra / number 123456789'),
                                                 new FixTextDetail ('Ms. Vinna Esther / number 0987654321'),
                                                 new FixTextDetail ('prefer garden view'),
                                                 new FixTextDetail ('motorbike Nmax plate number: Bk567'),

                                             ]),
                                    new RangeInfo ( 'innerdropdown', 'tot guests in room ', 
                                                 new UsersAccess ( 
                                                                  new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                  new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                  new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                 ), 
                                                 [ 
                                                    new InnerArraySelect ('1', false ),
                                                    new InnerArraySelect ('2', false ),
                                                    new InnerArraySelect ('3', true ),
                                                    new InnerArraySelect ('4', false ),
                                                    new InnerArraySelect ('4 +', false ),
                                                    
                                                 ]
                                    ), 
                                   
                                    new RangeInfo ( 'innercheckbox', 'request:',
                                                 new UsersAccess ( 
                                                                   new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                   new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                   new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                  ), 
                                                  /**
                                                   * export class InnerArraySelect {

                                                   constructor( public name: string, public selected: boolean ){}
                                                     }
                                                   */
                                                   [
                                                    new InnerArraySelect ('early check in', true ),
                                                    new InnerArraySelect ('late check out', false ),
                                                    new InnerArraySelect ('pick up from airport', false ),
                                                    new InnerArraySelect ('additional bed', true ),
                                                    ]
                                    ),
                                  
                                    new RangeInfo ( 'innerinput', 'additional info during stay ', 
                                                        new UsersAccess ( 
                                                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                                    ), 
                                                               [
                                                                new FixTextDetail ('add date / info'),
                                                                new FixTextDetail ('150'),
                                                                new FixTextDetail ('example: 25/9 / request breakfast take away'),
                                                                new FixTextDetail ('20/9 - request dinner take away for 3')
                                                               ]
                                                        ),
                                 ],
                               
                                             new UsersAccess ( 
                                                            new CanDo (  ['alex.com', 'bea@bea.com'], '', false ),
                                                            new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                            new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                            ), 
                                ),
                                new RangeDatesInfo ( new DateStartEnd ( '2023/09/24', '2023/09/29'),
                                [
                                    new RangeInfo ( 'innertext', 'client details',
                                                   new UsersAccess ( 
                                                                    new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                    new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete accesses', false ),
                                                                    new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete text entries', false )
                                                                   ), 
                                                            [
                                                                new FixTextDetail ('Mr. Billy Joel / number 123456789'),
                                                                new FixTextDetail ('Ms. Amanda / number 0987654321'),
                                                                new FixTextDetail ('prefer seafront view'),
                                                                new FixTextDetail ('motorbike: black Scoopy plate number: BK000'),

                                                            ]),
                                    new RangeInfo ( 'innerdropdown', 'tot guests in room ', 
                                                 new UsersAccess ( 
                                                                  new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                  new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                  new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                 ), 
                                                 [ 
                                                    new InnerArraySelect ('1', false ),
                                                    new InnerArraySelect ('2', true ),
                                                    new InnerArraySelect ('3', false ),
                                                    new InnerArraySelect ('4', false ),
                                                    new InnerArraySelect ('4 +', false ),
                                                    
                                                 ]
                                    ), 
                                   
                                    new RangeInfo ( 'innercheckbox', 'request:',
                                                 new UsersAccess ( 
                                                                   new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                   new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                   new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                  ), 
                                                  /**
                                                   * export class InnerArraySelect {

                                                   constructor( public name: string, public selected: boolean ){}
                                                     }
                                                   */
                                                   [
                                                    new InnerArraySelect ('early check in', false ),
                                                    new InnerArraySelect ('late check out', false ),
                                                    new InnerArraySelect ('pick up from airport', true ),
                                                    new InnerArraySelect ('additional bed', false ),
                                                    ]
                                    ),
                                    
                                    new RangeInfo ( 'innerinput', 'additional info during stay ', 
                                                        new UsersAccess ( 
                                                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                                    ), 
                                                               [
                                                                new FixTextDetail ('add date / info'),
                                                                new FixTextDetail ('150'),
                                                                new FixTextDetail ('example: 25/9 / request breakfast take away'),
                                                                new FixTextDetail (' 25-9 5kg / laundry')
                                                               ]
                                                        ),
                                 ],
                                
                                             new UsersAccess ( 
                                                            new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                            new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                            new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                            ), 
                                ),
                                 new RangeDatesInfo ( new DateStartEnd ( '2023/10/1', '2023/10/15'),
                                 [
                                    new RangeInfo ( 'innertext', 'client details',
                                                   new UsersAccess ( 
                                                                    new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                    new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete accesses', false ),
                                                                    new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete text entries', false )
                                                                   ), 
                                                            [
                                                                new FixTextDetail ('Mr. kamal / number 123456789'),
                                                                new FixTextDetail ('motorbike:  number:'),

                                                            ]),
                                    new RangeInfo ( 'innerdropdown', 'tot guests in room ', 
                                                 new UsersAccess ( 
                                                                  new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                  new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                  new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                 ), 
                                                 [ 
                                                    new InnerArraySelect ('1', true ),
                                                    new InnerArraySelect ('2', false ),
                                                    new InnerArraySelect ('3', false ),
                                                    new InnerArraySelect ('4', false ),
                                                    new InnerArraySelect ('4 +', false ),
                                                    
                                                 ]
                                    ), 
                                   
                                    new RangeInfo ( 'innercheckbox', 'request:',
                                                 new UsersAccess ( 
                                                                   new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                   new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                   new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                  ), 
                                                  /**
                                                   * export class InnerArraySelect {

                                                   constructor( public name: string, public selected: boolean ){}
                                                     }
                                                   */
                                                   [
                                                    new InnerArraySelect ('early check in', false ),
                                                    new InnerArraySelect ('late check out', false ),
                                                    new InnerArraySelect ('pick up from airport', false ),
                                                    new InnerArraySelect ('additional bed', false ),
                                                    ]
                                    ),
                                    
                                    new RangeInfo ( 'innerinput', 'additional info during stay ', 
                                                        new UsersAccess ( 
                                                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                                    ), 
                                                               [
                                                                new FixTextDetail ('add date / info'),
                                                                new FixTextDetail ('150'),
                                                                new FixTextDetail ('example: 25/9 / request breakfast take away'),
                                                                new FixTextDetail ('')
                                                               ]
                                                        ),
                                 ],
                              
                                            new UsersAccess ( 
                                                           new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                           new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                           new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                           ), 
                               ),
                               new RangeDatesInfo ( new DateStartEnd ( '2023/10/16', '2023/10/20'),
                               [
                                  new RangeInfo ( 'innertext', 'client details',
                                                 new UsersAccess ( 
                                                                  new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                  new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete accesses', false ),
                                                                  new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete text entries', false )
                                                                 ), 
                                                          [
                                                              new FixTextDetail ('Mr. alex / number 123456789'),
                                                              new FixTextDetail ('motorbike:  number:'),

                                                          ]),
                                  new RangeInfo ( 'innerdropdown', 'tot guests in room ', 
                                               new UsersAccess ( 
                                                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                               ), 
                                               [ 
                                                  new InnerArraySelect ('1', true ),
                                                  new InnerArraySelect ('2', false ),
                                                  new InnerArraySelect ('3', false ),
                                                  new InnerArraySelect ('4', false ),
                                                  new InnerArraySelect ('4 +', false ),
                                                  
                                               ]
                                  ), 
                                 
                                  new RangeInfo ( 'innercheckbox', 'request:',
                                               new UsersAccess ( 
                                                                 new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                 new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                 new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                ), 
                                                /**
                                                 * export class InnerArraySelect {

                                                 constructor( public name: string, public selected: boolean ){}
                                                   }
                                                 */
                                                 [
                                                  new InnerArraySelect ('early check in', true ),
                                                  new InnerArraySelect ('late check out', false ),
                                                  new InnerArraySelect ('pick up from airport', false ),
                                                  new InnerArraySelect ('additional bed', false ),
                                                  ]
                                  ),
                                  
                                  new RangeInfo ( 'innerinput', 'additional info during stay ', 
                                                      new UsersAccess ( 
                                                                   new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                   new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                   new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                                  ), 
                                                             [
                                                              new FixTextDetail ('add date / info'),
                                                              new FixTextDetail ('150'),
                                                              new FixTextDetail ('example: 25/9 / request breakfast take away'),
                                                              new FixTextDetail ('')
                                                             ]
                                                      ),
                               ],
                            
                                          new UsersAccess ( 
                                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                         ), 
                             ),

                               ]
    ),

    new SharedCalendarStatus ( 'sharedCalendarSta-2', 'sharedCalendarStatus', 'sharedCalendarStatus-2', null, false, [], null, 'GARDENIA ROOM',
    new RangeDatesInfo ( new DateStartEnd ( '', ''),
     [
        new RangeInfo ( 'innertext', 'client details',
        new UsersAccess ( 
                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete accesses', false ),
                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete text entries', false )
                        ), 
                 [
                     new FixTextDetail ('Mr. / number:'),
                     new FixTextDetail ('Ms.  / number:'),
                     new FixTextDetail ('prefer: '),
                     new FixTextDetail ('motorbike Nmax plate number:'),

                 ]),
        new RangeInfo ( 'innerdropdown', 'tot guests in room ', 
                     new UsersAccess ( 
                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                     ), 
                     [ 
                        new InnerArraySelect ('1', false ),
                        new InnerArraySelect ('2', false ),
                        new InnerArraySelect ('3', false ),
                        new InnerArraySelect ('4', false ),
                        new InnerArraySelect ('4 +', false ),
                        
                     ]
        ), 
       
        new RangeInfo ( 'innercheckbox', 'request:',
                     new UsersAccess ( 
                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                      ), 
                      /**
                       * export class InnerArraySelect {

                       constructor( public name: string, public selected: boolean ){}
                         }
                       */
                       [
                        new InnerArraySelect ('early check in', false ),
                        new InnerArraySelect ('late check out', false ),
                        new InnerArraySelect ('pick up from airport', false ),
                        new InnerArraySelect ('additional bed', false ),
                        ]
        ),
        new RangeInfo ( 'innerinput', 'additional info during stay ', 
                            new UsersAccess ( 
                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                        ), 
                                   [
                                    new FixTextDetail ('add date / info'),
                                    new FixTextDetail ('150'),
                                    new FixTextDetail ('example: 25/9 / request breakfast take away'),
                                    new FixTextDetail ('')
                                   ]
                            ),
     ],
                 new UsersAccess ( 
                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can view data', false ),
                                new CanDo (  [], '', true ),
                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add/delete/edit date ranges & inner rangeInfo', false )
                                ), 
                 ),
                
   [

    new RangeDatesInfo ( new DateStartEnd ( '2023/09/20', '2023/09/25'),
     [
        new RangeInfo ( 'innertext', 'client details',
                           new UsersAccess ( 
                                            new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                            new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete accesses', false ),
                                            new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete text entries', false )
                                           ), 
                                    [
                                        new FixTextDetail ('Mr. Joe Blak / number 123456789'),
                                        new FixTextDetail ('Ms. Kristina Bloom / number 0987654321'),
                                        new FixTextDetail ('prefer seafront view'),
                                        new FixTextDetail ('motorbike Nmax plate number: BK234'),

                                    ]),
            new RangeInfo ( 'innerdropdown', 'tot guests in room ', 
                         new UsersAccess ( 
                                          new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                          new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                          new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                         ), 
                         [ 
                            new InnerArraySelect ('1', false ),
                            new InnerArraySelect ('2', true ),
                            new InnerArraySelect ('3', false ),
                            new InnerArraySelect ('4', false ),
                            new InnerArraySelect ('4 +', false ),
                            
                         ]
            ), 
           
            new RangeInfo ( 'innercheckbox', 'request:',
                         new UsersAccess ( 
                                           new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                           new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                           new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                          ), 
                          /**
                           * export class InnerArraySelect {

                           constructor( public name: string, public selected: boolean ){}
                             }
                           */
                           [
                            new InnerArraySelect ('early check in', false ),
                            new InnerArraySelect ('late check out', true ),
                            new InnerArraySelect ('pick up from airport', true ),
                            new InnerArraySelect ('additional bed', false ),
                            ]
            ),

            new RangeInfo ( 'innerinput', 'additional info during stay ', 
                                new UsersAccess ( 
                                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                            ), 
                                       [
                                        new FixTextDetail ('add date / info'),
                                        new FixTextDetail ('150'),
                                        new FixTextDetail ('example: 25/9 / request breakfast take away'),
                                        new FixTextDetail ('got additional pillows : 2')
                                       ]
                                ),

     ],
                 new UsersAccess ( 
                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                ), 
    ),
    new RangeDatesInfo ( new DateStartEnd ( '2023/09/26', '2023/09/30'),
    [
        new RangeInfo ( 'innertext', 'client details',
        new UsersAccess ( 
                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete accesses', false ),
                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete text entries', false )
                        ), 
                 [
                     new FixTextDetail ('Mr. Andy Saputra / number 123456789'),
                     new FixTextDetail ('Ms. Vinna Esther / number 0987654321'),
                     new FixTextDetail ('prefer garden view'),
                     new FixTextDetail ('motorbike Nmax plate number: Bk567'),

                 ]),
        new RangeInfo ( 'innerdropdown', 'tot guests in room ', 
                     new UsersAccess ( 
                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                     ), 
                     [ 
                        new InnerArraySelect ('1', false ),
                        new InnerArraySelect ('2', false ),
                        new InnerArraySelect ('3', true ),
                        new InnerArraySelect ('4', false ),
                        new InnerArraySelect ('4 +', false ),
                        
                     ]
        ), 
       
        new RangeInfo ( 'innercheckbox', 'request:',
                     new UsersAccess ( 
                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                      ), 
                      /**
                       * export class InnerArraySelect {

                       constructor( public name: string, public selected: boolean ){}
                         }
                       */
                       [
                        new InnerArraySelect ('early check in', true ),
                        new InnerArraySelect ('late check out', false ),
                        new InnerArraySelect ('pick up from airport', false ),
                        new InnerArraySelect ('additional bed', true ),
                        ]
        ),
      
        new RangeInfo ( 'innerinput', 'additional info during stay ', 
                            new UsersAccess ( 
                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                        ), 
                                   [
                                    new FixTextDetail ('add date / info'),
                                    new FixTextDetail ('150'),
                                    new FixTextDetail ('example: 25/9 / request breakfast take away'),
                                    new FixTextDetail ('20/9 - request dinner take away for 3')
                                   ]
                            ),
     ],
   
                 new UsersAccess ( 
                                new CanDo (  ['alex.com', 'bea@bea.com'], '', false ),
                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                ), 
    ),
    new RangeDatesInfo ( new DateStartEnd ( '2023/10/1', '2023/10/10'),
    [
        new RangeInfo ( 'innertext', 'client details',
                       new UsersAccess ( 
                                        new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                        new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete accesses', false ),
                                        new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete text entries', false )
                                       ), 
                                [
                                    new FixTextDetail ('Mr. Billy Joel / number 123456789'),
                                    new FixTextDetail ('Ms. Amanda / number 0987654321'),
                                    new FixTextDetail ('prefer seafront view'),
                                    new FixTextDetail ('motorbike: black Scoopy plate number: BK000'),

                                ]),
        new RangeInfo ( 'innerdropdown', 'tot guests in room ', 
                     new UsersAccess ( 
                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                     ), 
                     [ 
                        new InnerArraySelect ('1', false ),
                        new InnerArraySelect ('2', true ),
                        new InnerArraySelect ('3', false ),
                        new InnerArraySelect ('4', false ),
                        new InnerArraySelect ('4 +', false ),
                        
                     ]
        ), 
       
        new RangeInfo ( 'innercheckbox', 'request:',
                     new UsersAccess ( 
                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                      ), 
                      /**
                       * export class InnerArraySelect {

                       constructor( public name: string, public selected: boolean ){}
                         }
                       */
                       [
                        new InnerArraySelect ('early check in', false ),
                        new InnerArraySelect ('late check out', false ),
                        new InnerArraySelect ('pick up from airport', true ),
                        new InnerArraySelect ('additional bed', false ),
                        ]
        ),
        
        new RangeInfo ( 'innerinput', 'additional info during stay ', 
                            new UsersAccess ( 
                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                        ), 
                                   [
                                    new FixTextDetail ('add date / info'),
                                    new FixTextDetail ('150'),
                                    new FixTextDetail ('example: 25/9 / request breakfast take away'),
                                    new FixTextDetail (' 25-9 5kg / laundry')
                                   ]
                            ),
     ],
    
                 new UsersAccess ( 
                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                ), 
    ),
     new RangeDatesInfo ( new DateStartEnd ( '2023/10/10', '2023/10/15'),
     [
        new RangeInfo ( 'innertext', 'client details',
                       new UsersAccess ( 
                                        new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                        new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete accesses', false ),
                                        new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete text entries', false )
                                       ), 
                                [
                                    new FixTextDetail ('Mr. kamal / number 123456789'),
                                    new FixTextDetail ('motorbike:  number:'),

                                ]),
        new RangeInfo ( 'innerdropdown', 'tot guests in room ', 
                     new UsersAccess ( 
                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                     ), 
                     [ 
                        new InnerArraySelect ('1', true ),
                        new InnerArraySelect ('2', false ),
                        new InnerArraySelect ('3', false ),
                        new InnerArraySelect ('4', false ),
                        new InnerArraySelect ('4 +', false ),
                        
                     ]
        ), 
       
        new RangeInfo ( 'innercheckbox', 'request:',
                     new UsersAccess ( 
                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                      ), 
                      /**
                       * export class InnerArraySelect {

                       constructor( public name: string, public selected: boolean ){}
                         }
                       */
                       [
                        new InnerArraySelect ('early check in', false ),
                        new InnerArraySelect ('late check out', false ),
                        new InnerArraySelect ('pick up from airport', false ),
                        new InnerArraySelect ('additional bed', false ),
                        ]
        ),
        
        new RangeInfo ( 'innerinput', 'additional info during stay ', 
                            new UsersAccess ( 
                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                         new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                        ), 
                                   [
                                    new FixTextDetail ('add date / info'),
                                    new FixTextDetail ('150'),
                                    new FixTextDetail ('example: 25/9 / request breakfast take away'),
                                    new FixTextDetail ('')
                                   ]
                            ),
     ],
  
                new UsersAccess ( 
                               new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                               new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                               new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                               ), 
   ),
   new RangeDatesInfo ( new DateStartEnd ( '2023/10/16', '2023/10/20'),
   [
      new RangeInfo ( 'innertext', 'client details',
                     new UsersAccess ( 
                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete accesses', false ),
                                      new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete text entries', false )
                                     ), 
                              [
                                  new FixTextDetail ('Mr. alex / number 123456789'),
                                  new FixTextDetail ('motorbike:  number:'),

                              ]),
      new RangeInfo ( 'innerdropdown', 'tot guests in room ', 
                   new UsersAccess ( 
                                    new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                    new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                    new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                   ), 
                   [ 
                      new InnerArraySelect ('1', true ),
                      new InnerArraySelect ('2', false ),
                      new InnerArraySelect ('3', false ),
                      new InnerArraySelect ('4', false ),
                      new InnerArraySelect ('4 +', false ),
                      
                   ]
      ), 
     
      new RangeInfo ( 'innercheckbox', 'request:',
                   new UsersAccess ( 
                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                    ), 
                    /**
                     * export class InnerArraySelect {

                     constructor( public name: string, public selected: boolean ){}
                       }
                     */
                     [
                      new InnerArraySelect ('early check in', true ),
                      new InnerArraySelect ('late check out', false ),
                      new InnerArraySelect ('pick up from airport', false ),
                      new InnerArraySelect ('additional bed', false ),
                      ]
      ),
      
      new RangeInfo ( 'innerinput', 'additional info during stay ', 
                          new UsersAccess ( 
                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                       new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                      ), 
                                 [
                                  new FixTextDetail ('add date / info'),
                                  new FixTextDetail ('150'),
                                  new FixTextDetail ('example: 25/9 / request breakfast take away'),
                                  new FixTextDetail ('')
                                 ]
                          ),
   ],

              new UsersAccess ( 
                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                             new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                             ), 
 ),

   ]
),

    new SharedCalendarStatus ( 'sharedCalendarSta-3', 'sharedCalendarStatus', 'sharedCalendarStatus-3', null, false, [], null, 'secondSharedCalendar',
                                new RangeDatesInfo ( new DateStartEnd ( 'yesterday', 'today'),
                                 [
                                    new RangeInfo ( 'innerinput', '13 input', 
                                    new UsersAccess ( 
                                                 new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                 new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                 new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                ), 
                                           [
                                            new FixTextDetail ('begonia'),
                                            new FixTextDetail ('150'),
                                            new FixTextDetail ('this is the placeholder you input'),
                                            new FixTextDetail ('')
                                           ]
                                    ),
                                    new RangeInfo ( 'innerdropdown', 'second dropdown', 
                                                 new UsersAccess ( 
                                                                  new CanDo (  ['alex.verdacchissimiodbdjvehvdbh@alex.com', 'bea@bea.com'], '', false ),
                                                                  new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                  new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                 ), 
                                                 [ 
                                                    new InnerArraySelect ('pane', false ),
                                                    new InnerArraySelect ('pizza', false ),
                                                    new InnerArraySelect ('pasta', false ),
                                                    new InnerArraySelect ('mandolino', false )
                                                 ]
                                    ), 
                                    new RangeInfo ( 'innercheckbox', '3 check box',
                                                 new UsersAccess ( 
                                                                   new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                   new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                   new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                  ), 
                                                   [
                                                    new InnerArraySelect ('corsa contro il tempo', false ),
                                                    new InnerArraySelect ('sfida contro le onde', false ),
                                                    new InnerArraySelect ('discesa ripida', false ),
                                                    new InnerArraySelect ('salida maliziosa', false ),
                                                    ]
                                    ),
                                    new RangeInfo ( 'innertext', '7 text',
                                                   new UsersAccess ( 
                                                                    new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                    new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add / delete entries', false ),
                                                                    new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add/delete access', false )
                                                                   ), 
                                                            [
                                                                new FixTextDetail ('On projection apartments unsatiable so if he entreaties appearance. '),
                                                                new FixTextDetail ('Welcomed stronger if steepest ecstatic an suitable finished of oh.'),
                                                                new FixTextDetail ('Chicken unknown besides attacks compact out you.')
                                                            ]),
                                   
                                 ],
                                             new UsersAccess ( 
                                                              new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can view the data on each date range', false ),
                                                              new CanDo (  [], '', true ),
                                                              new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add/delete date ranges & inner rangeInfo', false )
                                                            ), 
                                             ),
                                            
                               [
                                new RangeDatesInfo ( new DateStartEnd ( '2023/10/16', '2023/10/20'),
                                 [
                                    new RangeInfo ( 'innerdropdown', 'second dropdown', 
                                                 new UsersAccess ( 
                                                                  new CanDo (  ['alex.verdacchissimiodbdjvehvdbh@alex.com', 'bea@bea.com'], '', false ),
                                                                  new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                  new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                 ), 
                                                 [ 
                                                    new InnerArraySelect ('pane', false ),
                                                    new InnerArraySelect ('pizza', false ),
                                                    new InnerArraySelect ('pasta', false ),
                                                    new InnerArraySelect ('mandolino', false )
                                                 ]
                                    ), 
                                    new RangeInfo ( 'innercheckbox', '3 check box',
                                                 new UsersAccess ( 
                                                                   new CanDo (  ['alex.verdacchissimiodbdjvehvdbh@alex.com', 'bea@bea.com'], '', false ),
                                                                   new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                   new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                  ), 
                                                   [
                                                    new InnerArraySelect ('corsa contro il tempo', false ),
                                                    new InnerArraySelect ('sfida contro le onde', false ),
                                                    new InnerArraySelect ('discesa ripida', false ),
                                                    new InnerArraySelect ('salida maliziosa', false ),
                                                    ]
                                    ),
                                    new RangeInfo ( 'innertext', '7 text',
                                                   new UsersAccess ( 
                                                                    new CanDo (  ['alex.verdacchissimiodbdjvehvdbh@alex.com', 'bea@bea.com'], '', false ),
                                                                    new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                    new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                                   ), 
                                                            [
                                                                new FixTextDetail ('On projection apartments unsatiable so if he entreaties appearance. '),
                                                                new FixTextDetail ('Welcomed stronger if steepest ecstatic an suitable finished of oh.'),
                                                                new FixTextDetail ('Chicken unknown besides attacks compact out you.')
                                                            ]),
                                    new RangeInfo ( 'innerinput', '13 input', 
                                                        new UsersAccess ( 
                                                                     new CanDo (  ['alex.verdacchissimiodbdjvehvdbh@alex.com', 'bea@bea.com'], '', false ),
                                                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                                     new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                                    ), 
                                                               [
                                                                new FixTextDetail ('begonia'),
                                                                new FixTextDetail ('150'),
                                                                new FixTextDetail ('this is the placeholder you input'),
                                                                new FixTextDetail ('')
                                                               ]
                                                        ),
                                 ],
                                             new UsersAccess ( 
                                                            new CanDo (  ['alex.verdacchissimiodbdjvehvdbh@alex.com', 'bea@bea.com'], '', false ),
                                                            new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false ),
                                                            new CanDo (  ['alex@alex.com', 'bea@bea.com'], '', false )
                                                            ), 
                                             ),
                               ]
    ),
]





reset_initialNullCalculatorMixTables: CalculatorMixTable[] =  [

    new CalculatorMixTable ( 'calculatorMixTa-1','calculatorMixTable','calculatorMixTable-1', 'tony', false, [], null, 'primo', 
    
        new DescriptionFrame ( ['part one', 'power' ] , 
        [
            new DescriptionPart ('part one', 'hardware  ewrwe rwweretrwetetertpart diy 234 calculation that goes well', 300, 30 ),
            new DescriptionPart ('part one',  'software fdgsfdgdgf part diy 234', 500, 50),
            new DescriptionPart ('part one',  'assembler gfdsxcvcxbvcb  bcvbcb part diy 234', 400 ,40 ),
            new DescriptionPart ('power',  'hardpower diy 234', 350, 30),
            new DescriptionPart ('power',  'software power diy 234', 550, 50 ),
            new DescriptionPart ('power',  'assembler power diy 234', 450, 40 ),
        ]),

    
    ),

    new CalculatorMixTable ( 'calculatorMixTa-2','calculatorMixTable','calculatorMixTable-2', 'tony', false, [], null, 'secondo', 
    
        new DescriptionFrame ( ['lights', 'wheel'] , 
        [
            new DescriptionPart ('lights', 'lights on the table diy 234', 300, 30 ),
            new DescriptionPart ('lights',  'Led bdwjh hfgeceche evbefv diy 234', 500, 50 ),
            new DescriptionPart ('lights',  'assembler ccled line diy 234', 400, 40 ),
            new DescriptionPart ('wheel',  'alloy wheel diy 234', 300, 30 ),
            new DescriptionPart ('wheel',  'rubber syntetic 234', 500, 50 ),
            new DescriptionPart ('wheel',  'natural rubber hbcwjhdc diy 234', 400, 40 ),
        ]),

    
    )

]

reset_initialNullDropDownSelects: DropDownSelect[] =  [

    new DropDownSelect ( 'dropDownSel-1','dropDownSelect','dropDownSelect-1', 'tony@gmail.com', false, [], null, 'primo', 
    [
        new DropDownSelectDescription ( 'visit motivation', ['courtesy', 'order', 'new project', 'current project', 'complain', 'payment', 'technical']),
         new DropDownSelectDescription ( 'duration',['week', 'daily', 'hours']),
       
    ]),

    new DropDownSelect ( 'dropDownSel-2','dropDownSelect','dropDownSelect-2', 'tony@mail.com', false, [], null, 'secondo',
    [
        new DropDownSelectDescription ( 'animale', ['maiale', 'bovino', 'equina', 'pollame', 'insect']),
        new DropDownSelectDescription ( 'religion', ['induist', 'cattolic', 'bugghist', 'muslim', 'ateist'])
    ])
];

reset_initialNullMultiFeatures: MultiFeatures[] =  [
        
    new MultiFeatures ( 'multiFeat-0' , 'multiFeature' , 'multiFeature-0' , 'antoniohhd@gmail.com', null,
      
         false , [] ,'expenses note' ,
            [ 
               new Feature (   'antoniohhd@gmail.com' , false , 0, 'singleDropDownSel-1', 'singleDropDownSelect' ),
               new Feature (  'antoniohhd@gmail.com' , false , 1, 'singleWordIn-1', 'singleWordInput'),
               new Feature (  'antoniohhd@gmail.com' , false , 2, 'toggleSingleWordBut-1', 'toggleSingleWordButton'),
           
    
            ], []),

    new MultiFeatures ( 'multiFeat-1' , 'multiFeature' , 'multiFeature-1' , 'antoniohhd@gmail.com', null,
        false, [], 'home note ',
        [     
             new Feature (  'antoniohhd@gmail.com' , false , 0, 'singleDropDownSel-2', 'singleDropDownSelect' ),
             new Feature (  'antoniohhd@gmail.com' , false , 1, 'singleWordIn-1', 'singleWordInput'),
             new Feature (   'antoniohhd@gmail.com' , false , 2, 'toggleSingleWordBut-1', 'toggleSingleWordButton'),
           
        ],[]),
     new MultiFeatures ( 'multiFeat-2' , 'multiFeature' , 'multiFeature-2' , 'antoniohhd@gmail.com', null,
            false, [], 'crew',
            [
                new Feature (  'antoniohhd@gmail.com' , false , 0, 'toggleSingleWordBut-2', 'toggleSingleWordButton'),
                new Feature (  'antoniohhd@gmail.com' , false , 1, 'toggleSingleWordBut-1', 'toggleSingleWordButton'),
                new Feature (  'antoniohhd@gmail.com' , false , 2, 'singleW-1', 'singleWord'),
                new Feature (  'antoniohhd@gmail.com' , false , 3, 'singleW-3', 'singleWord'),
                new Feature (   'antoniohhd@gmail.com' , false , 4, 'singleW-2', 'singleWord'),
                new Feature (   'antoniohhd@gmail.com' , false , 5, 'singleW-3', 'singleWord'),
                new Feature (  'antoniohhd@gmail.com' , false , 6, 'singleW-1', 'singleWord')
        ],[]),
        new MultiFeatures ( 'multiFeat-3' , 'multiFeature' , 'multiFeature-3' , 'antoniohhd@gmail.com', null,
            false, [], 'yearly',
            [
                new Feature (   'antoniohhd@gmail.com' , false , 0, 'toggleSingleWordBut-2', 'toggleSingleWordButton'),
                new Feature ( 'antoniohhd@gmail.com' , false , 1, 'toggleSingleWordBut-1', 'toggleSingleWordButton'),
                new Feature (   'antoniohhd@gmail.com' , false , 2, 'toggleSingleWordBut-1', 'toggleSingleWordButton'),
                new Feature (   'antoniohhd@gmail.com' , false , 3, 'toggleSingleWordBut-3', 'toggleSingleWordButton'),
                new Feature (  'antoniohhd@gmail.com' , false , 4, 'toggleSingleWordBut-2', 'toggleSingleWordButton'),
                new Feature (  'antoniohhd@gmail.com' , false , 5, 'toggleSingleWordBut-3', 'toggleSingleWordButton'),
                new Feature (  'antoniohhd@gmail.com' , false , 6, 'toggleSingleWordBut-1', 'toggleSingleWordButton'),
                new Feature (  'antoniohhd@gmail.com' , false , 7, 'toggleSingleWordBut-2', 'toggleSingleWordButton'),
                new Feature ( 'antoniohhd@gmail.com' , false , 8, 'toggleSingleWordBut-1', 'toggleSingleWordButton'),
                new Feature (  'antoniohhd@gmail.com' , false , 9, 'toggleSingleWordBut-1', 'toggleSingleWordButton'),
                new Feature (  'antoniohhd@gmail.com' , false , 10, 'toggleSingleWordBut-3', 'toggleSingleWordButton'),
                new Feature (  'antoniohhd@gmail.com' , false , 11, 'toggleSingleWordBut-2', 'toggleSingleWordButton'),
                new Feature (  'antoniohhd@gmail.com' , false , 12, 'toggleSingleWordBut-3','toggleSingleWordButton'),
                new Feature (  'antoniohhd@gmail.com' , false , 13, 'toggleSingleWordBut-1', 'toggleSingleWordButton')
        ],[]),   



];

reset_initialNullTabs: Tab[] =  [

    new Tab ( 't-1', 'tab','tab-1', 'antoniohhd@gmail.com', null, false, [], 'client USA', [

        new TabDetail (  'bisma laut pribadi', [
            new Feature ( 'antoniohhd@gmail.com' , false , 0, 'fixLay-3', 'fixLayout' ),
           // new Feature ( null ,  'antoniohhd@gmail.com', false , 1,  0,  '1', 'fixLayout'),
            // new Feature ( null ,  'antoniohhd@gmail.com', false , 2,  0,  '0', 'fixText'),

        ]),

        new TabDetail (  'metler toledo', [
            new Feature (  'antoniohhd@gmail.com' , false , 0, 'fixLay-1', 'fixLayout' ),
          //  new Feature ( null ,  'antoniohhd@gmail.com', false , 1,  1, '1', 'multiFeatures'),
           // new Feature (  'antoniohhd@gmail.com' , 2, 0, 'word hint'),
        ]),

        new TabDetail (  'bassa veneta ', [
            new Feature ( 'antoniohhd@gmail.com' , false , 0, 'fixLay-2', 'fixLayout' ),
          //  new Feature ( null ,  'antoniohhd@gmail.com', false , 1,  1, '1', 'multiFeatures'),
           // new Feature (  'antoniohhd@gmail.com' , 2, 0, 'word hint'),
        ])
    ], []),

    new Tab ( 't-2' ,'tab','tab-2',  'antoniohhd@gmail.com', null, false, [], 'suppliers', [

        new TabDetail (  'stef', [
            new Feature (  'antoniohhd@gmail.com' , false , 0, 'wordIn-1', 'wordInput' ),
            new Feature (  'antoniohhd@gmail.com' , false , 1, 'fixLay-1', 'fixLayout'),
            new Feature (  'antoniohhd@gmail.com' , false , 2, 'multiFeat-1', 'multiFeatures'),
        ]),
        new TabDetail (  'clicncg', [
            new Feature (  'antoniohhd@gmail.com' , false , 0, 'multiFeat-0', 'multiFeatures' ),
            new Feature ( 'antoniohhd@gmail.com' , false , 1, 'wordIn-1', 'wordInput'),
            new Feature (  'antoniohhd@gmail.com', false , 2,  'multiFeat-2', 'multiFeatures'),
            new Feature ( 'antoniohhd@gmail.com', false , 3,  'multiFeat-3', 'multiFeatures'),
        ]),
        new TabDetail (  'gloves', [
            new Feature (  'antoniohhd@gmail.com', false , 0,  'multiFeat-1', 'multiFeatures'),
            new Feature (  'antoniohhd@gmail.com' , false , 1, 'wordIn-1', 'wordInput' ),
            new Feature (  'antoniohhd@gmail.com' , false , 2, 'wordIn-1', 'wordInput'),
        ])
        
    ],[])
];

reset_initialNullParagraphs: Paragraph[] = [
    new Paragraph ( '1' , "paragraph", "paragraph-1", 'owner' , null, null,
        false , true , [], false,  'customer ' , 'fill the form in all details' ,
            [ 
              // new Feature ( null ,  'antoniohhd@gmail.com', false , 0 , 1, '1', 'dropDownSelect' ),
              // new Feature ( null ,  'antoniohhd@gmail.com', false ,1, 1, '1', 'tabs'),
                 new Feature ( null , false ,0, 't-1', 'tabs'),
              // new Feature ( null ,  'antoniohhd@gmail.com', false ,2 , 2, '2', 'multiEntryCalendar' ),
              // new Feature ( null ,  'antoniohhd@gmail.com', false ,2 , 2, '2', 'wordInput' ),
              //  new Feature (  null , 'antoniohhd@gmail.com', false ,3, 1, '1', 'multiFeatures')
            
            ] ,
            []),
    new Paragraph ( '2', "paragraph", "paragraph-2", null , null,  null,
          false , false, ['tony@tony.com'], false,'daily expense' , 'description of expenses here we have to leave the things going as nothing havppeedn and see hat is foing to happen' ,  
            [ 
              // new Feature ( null, 'antoniohhd@gmail.com', false , 2, 1, '1', 'oneDateCalendar'),
              new Feature ( null , false , 0, 'wordIn-1', 'wordInput'),
              new Feature ( null, false , 1, 'wordIn-1', 'wordInput'),
              new Feature ( null , false , 2, 'wordIn-1', 'wordInput'),
              // new Feature (  null , 'antoniohhd@gmail.com', false , 0, 2 , '2','multiFeatures' ),
              // new Feature ( null ,  'antoniohhd@gmail.com', false , 1, 3, '3', 'wordInput' ),
              // // to be adjusted
              // new Feature (  null , 'antoniohhd@gmail.com', false ,3, 1, '1', 'checkBoxSelect')
            ] ,
            []),         

    new Paragraph ( '3' , "paragraph", "paragraph-3", 'owner' , null, null,
            false , true , [], false,'tab-Multi-layout' , 'blando 3' ,
             [ 
              //  new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1 , '1', 'tabs' ),
              //  new Feature ( null ,  'antoniohhd@gmail.com', false , 1, 3, '3', 'multiFeatures' ),
              //  new Feature ( null ,  'antoniohhd@gmail.com', false , 2, 1, '1', 'fixLayout' )
              new Feature (  'antoniohhd@gmail.com', false , 0, 't-1', 'tabs' ),
              new Feature (  'antoniohhd@gmail.com', false , 1, 'multiFeat-3', 'multiFeatures' ),
              new Feature (  'antoniohhd@gmail.com', false , 2, 'fixLay-1', 'fixLayout' )
             ],
             []
    ),   


new Paragraph(  '4' , "paragraph", "paragraph-4", null , null,   null,    
       false , false, [], false,'fix text' , 'forza' ,
      [ 
      //  new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1, '2', 'fixText' ),
      new Feature (   'antoniohhd@gmail.com', false , 0, 'fixT-2', 'fixText' ),
       
      ],
      []
),
new Paragraph(  '5' , "paragraph", "paragraph-5",  'owner', null,  null,     
false , false, [], false,'departure/arrival' , 'input the date of departure and arrival' ,
[ 
// new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1, '1', 'oneDateCalendar' ),
new Feature (   'antoniohhd@gmail.com', false , 0, 'singleDateCalen-1', 'oneDateCalendar' ),

],
[]
),
new Paragraph( '6' , "paragraph", "paragraph-6",  'owner', null,    null,   
false , false, [], false,'wotking period' , 'input the starting and finish working date' ,
[ 
// new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1, '2', 'oneDateCalendar' ),
new Feature (  'antoniohhd@gmail.com', false , 0, 'singleDateCalen-2', 'oneDateCalendar' ),

],
[]),
new Paragraph( '7', "paragraph", "paragraph-7",  'owner', null,     null,  
false , false, [], false,'single date' , 'input date' ,
[ 
// new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1, '3', 'oneDateCalendar' ),
new Feature (   'antoniohhd@gmail.com', false , 0, 'singledateCalen-3', 'oneDateCalendar' ),

],[]),
new Paragraph( '8' , "paragraph", "paragraph-8",  'owner', null,   null,    
     false , false, [], false,'offer from PL' , 'indert the date and select the products, and you have the optionto change quantity / price/ discount' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1 , '3', 'oneDateCalendar' ),
      // new Feature ( null, 'atny@mail.com', false, 1, 1, '1', 'calculatorMixTable')
      new Feature (  'atny@mail.com', false, 0, 'calculatorMixTa-1', 'calculatorMixTable')
                        
    ],[]
),  
new Paragraph( '9' , "paragraph", "paragraph-9", 'owner', null,     null,  
false , false, [], false,'multi date' , 'input date' ,
[ 
// new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1, '1', 'multiEntryCalendar' ),
new Feature (   'antoniohhd@gmail.com', false , 0, 'multiEntryCalen-1', 'multiEntryCalendar' ),

],[]),

new Paragraph( '10' , "paragraph", "paragraph-10", 'owner', null,  null,     
false , false, [], false,'work expenses' , '' ,
[ 
// new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '0', 'multiFeatures' ),
new Feature (   'antoniohhd@gmail.com', false , 0, 'multiFeat-0', 'multiFeatures' ),

],[]),
new Paragraph( '11' , "paragraph", "paragraph-11", 'owner', null,     null,  
false , false, [], false,'home expenses' , 'input any home expenses ' ,
[ 
// new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '1', 'multiFeatures' ),
new Feature (  'antoniohhd@gmail.com', false , 0, 'multiFeat-1', 'multiFeatures' ),

],[]),
new Paragraph(  '12' , "paragraph", "paragraph-12",  'owner', null,   null,
false , false, [], false,'issue with DRS' , 'input any home expenses ' ,
[ 
// new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '0', 'fixText' ),
new Feature (  'antoniohhd@gmail.com', false , 0, 'fixT-0', 'fixText' ),

],[]),      
     new Paragraph(  '13' , "paragraph", "paragraph-13", 'owner', null,  null,     
               false , false, [], false,'layout' , 'clone' ,
              [ 
                // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1 , '1', 'fixLayout' ),
                // new Feature ( null, 'atny@mail.com', false, 1, 1, '1', 'calculatorMixTable')
                new Feature (  'antoniohhd@gmail.com', false , 0,  'fixLay-1', 'fixLayout' ),
                new Feature (  'atny@mail.com', false, 1, 'calculatorMixTa-1', 'calculatorMixTable')
                                  
              ],[]
    ),  
    
    new Paragraph(  '14' , "paragraph", "paragraph-14",  null , null,  null,     
                 false , false, [], false,'fix text' , 'forza' ,
                [ 
                  // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1, '2', 'fixText' ),
                  new Feature (  'antoniohhd@gmail.com', false , 0, 'fixT-2', 'fixText' ),
                 
                ],[]
    ),
    new Paragraph(  '15' , "paragraph", "paragraph-15", 'owner', null,   null,    
     false , false, [], false,'departure/arrival' , 'input the date of departure and arrival' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1, '1', 'oneDateCalendar' ),
      new Feature (   'antoniohhd@gmail.com', false , 0, 'singleDateCalen-1', 'oneDateCalendar' ),
     
    ],[]
    ),
    new Paragraph( '16' , "paragraph", "paragraph-16",'owner', null,    null,   
     false , false, [], false,'wotking period' , 'input the starting and finish working date' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1, '2', 'oneDateCalendar' ),
      new Feature (  'antoniohhd@gmail.com', false , 0, 'singleDateCalen-2', 'oneDateCalendar' ),
     
    ],[]),
    new Paragraph( '17', "paragraph", "paragraph-17", 'owner', null,  null,     
     false , false, [], false,'single date' , 'input date' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1, '3', 'oneDateCalendar' ),
      new Feature (  'antoniohhd@gmail.com', false , 0, 'singleDateCalen-3', 'oneDateCalendar' ),
     
    ],[]),
    new Paragraph( '18' , "paragraph", "paragraph-18", 'owner', null,  null,     
               false , false, [], false,'offer from PL' , 'indert the date and select the products, and you have the optionto change quantity / price/ discount' ,
              [ 
                // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1 , '3', 'oneDateCalendar' ),
                // new Feature ( null, 'atny@mail.com', false, 1, 1, '1', 'calculatorMixTable')
                new Feature (  'atny@mail.com', false, 0, 'calculatorMixTa-1', 'calculatorMixTable')
                                  
              ],[]
    ),  
    new Paragraph( '19' , "paragraph", "paragraph-19", 'owner', null,  null,     
     false , false, [], false,'multi date' , 'input date' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 1, '1', 'multiEntryCalendar' ),
      new Feature (  'antoniohhd@gmail.com', false , 0, 'multiEntryCalen-1', 'multiEntryCalendar' ),
     
    ],[]),

    new Paragraph( '20' , "paragraph", "paragraph-20", 'owner', null,  null,     
     false , false, [], false,'work expenses' , '' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '0', 'multiFeatures' ),
      new Feature (  'antoniohhd@gmail.com', false , 0, 'multiFeat-0', 'multiFeatures' ),
     
    ],[]),
    new Paragraph( '21' , "paragraph", "paragraph-21", 'owner' , null,  null,     
     false , false, [], false,'home expenses' , 'input any home expenses ' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '1', 'multiFeatures' ),
      new Feature (  null , false , 0, 'multiFeat-1', 'multiFeatures' ),
     
    ],[]),
    new Paragraph(  '22' , "paragraph", "paragraph-22", null , null,  null, 
     false , false, [], false,'issue with DRS' , 'input any home expenses ' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '0', 'fixText' ),
      new Feature (  null , false , 0, 'fixT-0', 'fixText' ),
     
    ],[]),
    new Paragraph( '23' , "paragraph", "paragraph-23", null , null,  null, 
     false , false, [], false,'issue with front flaps' , 'consider these solution after carefully checking the manual' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '1', 'fixText' ),
      new Feature ( null , false , 0, 'fixT-1', 'fixText' ),
     
    ],[]),
    new Paragraph( '24' , "paragraph", "paragraph-24", 'owner', null,  null, 
     false , false, [], false,'f&b select' , 'select more than one' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '1', 'checkBoxSelect' ),
      new Feature (  'antoniohhd@gmail.com', false , 0, '1', 'checkBoxSelect' ),
     
    ],[]),
    new Paragraph( '25' , "paragraph", "paragraph-25", null , null,  null, 
     false , false, [], false,'term of pay 60gg' , 'dai vediamo se siamo corretti con la lista ' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '2', 'fixText' ),
      new Feature ( null, false , 0, 'fixT-2', 'fixText' ),
     
    ],[]),
    new Paragraph( '26' , "paragraph", "paragraph-26", null , null,   null,
     false , false, [], false,'term of pay 30' , '' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '3', 'fixText' ),
      new Feature (  null , false , 0, 'fixT-3', 'fixText' ),
     
    ],[]),
    new Paragraph( '27' , "paragraph", "paragraph-27", null , null,  null, 
     false , false, [], false,' chinese term of pay 30' , '' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '4', 'fixText' ),
      new Feature (  null , false , 0, 'fixT-4', 'fixText' ),
     
    ],[]),
    new Paragraph( '28' , "paragraph", "paragraph-28", null , null,  null, 
     false , false, [], false,' italian term of pay 30' , '' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '5', 'fixText' ),
      new Feature (  null , false , 0, 'fixT-5', 'fixText' ),
     
    ],[]),
    new Paragraph( '29' , "paragraph", "paragraph-29", 'owner', null,  null, 
     false , false, [], false,'drop down select multiple' , '' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '1', 'dropDownSelect' ),
      new Feature (  'antoniohhd@gmail.com', false , 0, 'dropDownSel-1', 'dropDownSelect' ),
     
    ],[]),
    new Paragraph( '30' , "paragraph", "paragraph-30", 'owner', null,  null, 
     false , false, [], false,'calendar multi entry' , '' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '1', 'multiEntryCalendar' ),
      new Feature (  'antoniohhd@gmail.com', false , 0, 'multiEntryCalen-1', 'multiEntryCalendar' ),
     
    ],[]),
    new Paragraph( '31' , "paragraph", "paragraph-31", null , null,  null, 
     false , false, [], false,'input personal data' , '' ,
    [ 
      // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '2', 'wordInput' ),
      new Feature (  null , false , 0, 'wordIn-2', 'wordInput' ),
     
    ],[]),
    new Paragraph( '32' , "paragraph", "paragraph-32", 'owner', null,  null, 
    false , false, [], false,'scan QR code' , '' ,
   [ 
     // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '2', 'wordInput' ),
     new Feature ( 'antoniohhd@gmail.com', false , 0, '0', 'ScanQRCode' ),
    
   ],[]),
   new Paragraph( '33' , "paragraph", "paragraph-33", 'owner', null,  null, 
   false , false, [], false,'basic qr code' , '' ,
  [ 
    // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '2', 'wordInput' ),
    new Feature (  'antoniohhd@gmail.com', false , 0, '0', 'qrCodeType' ),
   
  ],[]),
  new Paragraph( '34' , "paragraph", "paragraph-34", 'owner', null,   null,
  false , false, [], false,'comment' , '' ,
 [ 
   // new Feature ( null ,  'antoniohhd@gmail.com', false , 0, 0, '2', 'wordInput' ),
   new Feature (  '', false , 0, '0', 'comment' ),
  
 ],[]),
 new Paragraph( '35' , "paragraph", "paragraph-35", null , null,   null,
  false , false, [], false,'VILLA WASABI / ESMERALDA ROOM' , '' ,
 [ 
   new Feature ( null , false , 0, 'sharedCalendarSta-1', 'sharedCalendarStatus' ),
  
 ],[]),
 new Paragraph( '36' , "paragraph", "paragraph-36", null  , null,   null,
 false , false, [], false,'VILLA WASABI / GARDENIA ROOM' , '' ,
[ 
  new Feature ( null , false , 0, 'sharedCalendarSta-2', 'sharedCalendarStatus' ),
 
],[])
                
];



reset_initialNullForms: Form[] = [

    new Form ( '1', 'form', 'form-1', null , null, null, false , [], false,  true, false, true, false , 
            'wasabi villa', 'recipientIn-1', ['35', '36']),

    new Form ( '2' , 'form', 'form-2', null ,null, null, false , [], false, false, false, true, false , 
         'visit' , 'recipientIn-3', ['17', '1','25', '24'] ),
  
    new Form ( '3', 'form', 'form-3', null , null, null, false , [], false, false, false, true,   false , 
         'work expenses note' , 'recipientIn-3', ['17', '29', '20']),

      new Form ( '4', 'form', 'form-4', 'owner' ,null, null, false, [], false, false, false, true,  false , 
       'home expenses' , 'recipientIn-1', [ '17', '1', '20']) ,
      

     new Form ( '5', 'form', 'form-5', 'owner' ,null, null, false , [], false, false, false, true , false , 
        'vacations' , 'recipientIn-1', [ '30']) 

      

];


/**
 * export class recipientIndexColumn {

    constructor( public _id : string,
                 public owner: string,
                 public allUsersAllowed: boolean ,
                 public userListAllowed: string[], 
                 public dateAndTime: string ,
                 public name: string ,
                 public recipientIndexListItem_ids: string[]){}
}

export class RecipientIndexListItem {

    constructor ( public _id : string,
                  public owner: string,
                  public allUsersAllowed: boolean ,
                  public userListAllowed: string[], 
                  public dateAndTime: string ,
                  public recipientIndexListName: string,
                  public recipientIndexList_ids: string[],
                  public recipientIndexSelected_id: string 
                  ){}
}
 */

reset_initialNullRecipientIndexColumns: RecipientIndexColumn [] = [

    new RecipientIndexColumn ( 'recipientIndexCol-1', 'recipientIndexColumn','recipientIndexColumn-1', null, false, [], null, 'shared', 'shared groups', []),
    new RecipientIndexColumn ( 'recipientIndexCol-2','recipientIndexColumn','recipientIndexColumn-2', null, false, [], null, 'personal', 'my groups', ['recipientIndexListI-1', 'recipientIndexListI-2', 'recipientIndexListI-3'])
]

reset_initialNullRecipientFeatures: RecipientFeature[] = [new RecipientFeature ( 'recipientFeat-1', 'recipientFeature', 'recipientFeature-1'  , null , false, [], null , [])]


reset_initialNullRecipientIndexListItems: RecipientIndexListItem[] = [

    // new RecipientIndexListItem ('1', null, false, [], null, 'customers', ['1', '4'], '1'),
    // new RecipientIndexListItem ('2', null, false, [], null, 'suppliers', ['2'], '2'),
    // new RecipientIndexListItem ('3', null, false, [], null, 'R&D', ['3'], '3')
    new RecipientIndexListItem ('recipientIndexListI-1', 'recipientIndexListItem', 'recipientIndexListItem-1', null, false, [], null, 'customers', ['recipientIn-1', 'recipientIn-4'], 'recipientIn-1'),
    new RecipientIndexListItem ('recipientIndexListI-2', 'recipientIndexListItem', 'recipientIndexListItem-2', null, false, [], null, 'suppliers', ['recipientIn-2'], 'recipientIn-2'),
    new RecipientIndexListItem ('recipientIndexListI-3', 'recipientIndexListItem', 'recipientIndexListItem-3', null, false, [], null, 'R&D', ['recipientIn-3'], 'recipientIn-3')


]

/**
 * export class RecipientIndex {

    
    constructor ( public _id : string,
                  public owner: string,
                  public allUsersAllowed: boolean ,
                  public userListAllowed: string[], 
                  public dateAndTime: string ,
                  public isAccepted: boolean , 
                  public dropId: number , 
                  public recipient_id: string 
                  ) {}

                  
  }
 */

  reset_initialNullRecipientIndexes: RecipientIndex[] =  [

    new RecipientIndex ( 'recipientIn-1' , 'recipientIndex', 'recipientIndex-1', null , false, [],  null, true, true, 0, 'recipi-1' , null ),
    new RecipientIndex ( 'recipientIn-2' , 'recipientIndex', 'recipientIndex-2', null , false, [],  null, true, true, 0, 'recipi-2' , null  ),
    new RecipientIndex ( 'recipientIn-3' , 'recipientIndex', 'recipientIndex-3', null , false, [],  null, true, true, 0, 'recipi-3' , null ),
    new RecipientIndex ( 'recipientIn-4' , 'recipientIndex', 'recipientIndex-4', null , false, [],  null, true, true, 0, 'recipi-4' , null )
];



/**
 * export class  FeatureTopic {


    constructor  ( public _id: string , 
                   public owner: string , 
                   public allUsersAllowed: boolean,
                   public userListAllowed: string[],
                   public isStandardFeature: boolean, 
                   public isTab: boolean, 
                   public name : string , 
                   public featureContainerListItems : FeatureContainerListItem[] , // item of feature containers list 
               //    public features: Feature[]
                   ) {}
 */

/**
 * export class FeatureContainerListItem{

    constructor( public featureContainerList_ids: string[] , // list of featureContainer_id
                 public selectedFeatureContainer_id: string[]){} 
}
 */

/**
 * export class FeatureContainer {

    constructor ( public _id: string, 
                  public owner: string , 
                  public allUsersAllowed: boolean , 
                  public userListAllowed: [],
                  public dateAndTime: string , 
                  public isActive: boolean , 
                  public isAccepted: boolean , 
                  public name: string ,
                    // per esempio wordInput which i will input into the first 
                  public componentReference_id: string, 
                  public feature: Feature 
                  ){

    }
 */
    reset_initialNullFeatureContainers: FeatureContainer[] = [

    new FeatureContainer ('featureContai-1', 'featureContainer','featureContainer-1', null, false, [], null, true, true, '', 'singleW-1', 
                         [new Feature ( null , false , 0, 'singleW-1', 'singleWord')],  
    ),
    new FeatureContainer ('featureContai-2', 'featureContainer','featureContainer-2',  null, false, [], null, true, true, '', 'singleW-2', 
                         [new Feature ( null , false , 0, 'singleW-2', 'singleWord')],  
    ),
    new FeatureContainer ('featureContai-3', 'featureContainer','featureContainer-3',  null, false, [], null, true, true, '', 'singleW-3', 
                         [new Feature ( null , false , 0, 'singleW-3', 'singleWord')],  
    ),
    new FeatureContainer ('featureContai-4', 'featureContainer','featureContainer-4',  null, false, [], null, true, true, '', 'singleWordIn-1', 
                         [new Feature ( null , false , 0, 'singleWordIn-1', 'singleWordInput')],  
    ),
    new FeatureContainer ('featureContai-5', 'featureContainer','featureContainer-5',  null, false, [], null, true, true, '', 'singleWordIn-2', 
                         [new Feature ( null , false , 0, 'singleWordIn-2', 'singleWordInput')],  
    ),
    new FeatureContainer ('featureContai-6', 'featureContainer','featureContainer-6',  null, false, [], null, true, true, '', 'singleWordIn-3', 
                         [new Feature ( null , false , 0, 'singleWordIn-3', 'singleWordInput')],  
    ),
    new FeatureContainer ('featureContai-7', 'featureContainer','featureContainer-7',  null, false, [], null, true, true, '', 'toggleSingleWordBut-1', 
                         [new Feature ( null , false , 0, 'toggleSingleWordBut-1', 'toggleSingleWordButton')],  
    ),
    new FeatureContainer ('featureContai-8', 'featureContainer','featureContainer-8',  null, false, [], null, true, true, '', 'toggleSingleWordBut-2', 
                         [new Feature ( null , false , 0, 'toggleSingleWordBut-2', 'toggleSingleWordButton')],  
    ),
    new FeatureContainer ('featureContai-9', 'featureContainer','featureContainer-9',  null, false, [], null, true, true, '', 'toggleSingleWordBut-3', 
                         [new Feature ( null , false , 0, 'toggleSingleWordBut-3', 'toggleSingleWordButton')],  
    ),
    new FeatureContainer ('featureContai-10', 'featureContainer','featureContainer-10',  null, false, [], null, true, true, '', 'singleDropDownSel-1', 
                         [new Feature ( null , false , 0, 'singleDropDownSel-1', 'singleDropDownSelect')],  
    ),
    new FeatureContainer ('featureContai-11', 'featureContainer','featureContainer-11',  null, false, [], null, true, true, '', 'singleDropDownSel-2', 
                         [new Feature ( null , false , 0, 'singleDropDownSel-2', 'singleDropDownSelect')],  
    ),
    new FeatureContainer ('featureContai-12', 'featureContainer','featureContainer-12',  null, false, [], null, true, true, '', 'singleSli-1', 
                         [new Feature ( null , false , 0, 'singleSli-1', 'singleSlider')],  
    ),
    new FeatureContainer ('featureContai-13', 'featureContainer','featureContainer-13',  null, false, [], null, true, true, '', 'singleSli-2', 
                         [new Feature ( null , false , 0, 'singleSli-2', 'singleSlider' )],  
    ),
    new FeatureContainer ('featureContai-14', 'featureContainer','featureContainer-14',  null, false, [], null, true, true, '', 'wordIn-1', 
                         [new Feature ( null , false , 0, 'wordIn-1', 'wordInput')],  
    ),
    new FeatureContainer ('featureContai-15', 'featureContainer','featureContainer-15',  null, false, [], null, true, true, '', 'fixT-1', 
                         [new Feature ( null , false , 0, 'fixT-1', 'fixText')],  
    ),
    new FeatureContainer ('featureContai-16', 'featureContainer','featureContainer-16',  null, false, [], null, true, true, '', 'fixT-6', 
                         [new Feature ( null , false , 0, 'fixT-6', 'fixText')],  
    ),
    new FeatureContainer ('featureContai-17', 'featureContainer','featureContainer-17',  null, false, [], null, true, true, '', 'fixT-2', 
                         [new Feature ( null , false , 0, 'fixT-2', 'fixText')],  
    ),
    new FeatureContainer ('featureContai-18', 'featureContainer','featureContainer-18',  null, false, [], null, true, true, '', 'fixT-3', 
                         [new Feature ( null , false , 0, 'fixT-3', 'fixText')],  
    ),
    new FeatureContainer ('featureContai-19', 'featureContainer','featureContainer-19',  null, false, [], null, true, true, '', 'fixT-4', 
                         [new Feature ( null , false , 0, 'fixT-4', 'fixText')],  
    ),
    new FeatureContainer ('featureContai-20', 'featureContainer','featureContainer-20',  null, false, [], null, true, true, '', 'fixT-5', 
                         [new Feature ( null , false , 0, 'fixT-5', 'fixText' )],  
    ),
    new FeatureContainer ('featureContai-21', 'featureContainer','featureContainer-21',  null, false, [], null, true, true, '', 'fixT-6', 
    [new Feature ( null , false , 0, 'fixT-6', 'fixText')],  
),
new FeatureContainer ('featureContai-22', 'featureContainer','featureContainer-22',  null, false, [], null, true, true, '', 'fixLay-1', 
    [new Feature ( null , false , 0, 'fixLay-1', 'fixLayout')],  
),
new FeatureContainer ('featureContai-23', 'featureContainer','featureContainer-23',  null, false, [], null, true, true, '', 'fixLay-2', 
    [new Feature ( null , false , 0, 'fixLay-2', 'fixLayout')],  
),
new FeatureContainer ('featureContai-24', 'featureContainer','featureContainer-24',  null, false, [], null, true, true, '', 'fixLay-3', 
    [new Feature ( null , false , 0, 'fixLay-3', 'fixLayout')],  
),
new FeatureContainer ('featureContai-25', 'featureContainer','featureContainer-25',  null, false, [], null, true, true, '', 'singleDateCalen-1', 
    [new Feature ( null , false , 0, 'singleDateCalen-1', 'oneDateCalendar')],  
),
new FeatureContainer ('featureContai-26', 'featureContainer','featureContainer-26',  null, false, [], null, true, true, '', 'singleDateCalen-2', 
    [new Feature ( null , false , 0, 'singleDateCalen-2', 'oneDateCalendar')],  
),
new FeatureContainer ('featureContai-27', 'featureContainer','featureContainer-27',  null, false, [], null, true, true, '', 'singleDateCalen-3', 
    [new Feature ( null , false , 0, 'singleDateCalen-3', 'oneDateCalendar')],  
),
new FeatureContainer ('featureContai-28', 'featureContainer','featureContainer-28',  null, false, [], null, true, true, '', 'multiEntryCalen-1', 
    [new Feature ( null , false , 0, 'multiEntryCalen-1', 'multiEntryCalendar')],  
),
new FeatureContainer ('featureContai-29', 'featureContainer','featureContainer-29',  null, false, [], null, true, true, '', 'multiEntryCalen-2', 
    [new Feature ( null , false , 0, 'multiEntryCalen-2', 'multiEntryCalendar')],  
),
new FeatureContainer ('featureContai-30', 'featureContainer','featureContainer-30',  null, false, [], null, true, true, '', 'calculatorMixTa-1', 
    [new Feature ( null , false , 0, 'calculatorMixTa-1', 'calculatorMixTable')],  
),
new FeatureContainer ('featureContai-31', 'featureContainer','featureContainer-31',  null, false, [], null, true, true, '', 'calculatorMixTa-2', 
    [new Feature ( null , false , 0, 'calculatorMixTa-2', 'calculatorMixTable' )],  
),
new FeatureContainer ('featureContai-32', 'featureContainer','featureContainer-32',  null, false, [], null, true, true, '', 'dropDownSel-1', 
    [new Feature ( null , false , 0, 'dropDownSel-1', 'dropDownSelect')],  
),
new FeatureContainer ('featureContai-33', 'featureContainer','featureContainer-33',  null, false, [], null, true, true, '', 'dropDownSel-2', 
    [new Feature ( null , false , 0, 'dropDownSel-2', 'dropDownSelect' )],  
),
new FeatureContainer ('featureContai-34', 'featureContainer','featureContainer-34',  null, false, [], null, true, true, '', 'multiFeat-0', 
    [new Feature ( null , false , 0, 'multiFeat-0', 'multiFeatures')],  
),
new FeatureContainer ('featureContai-35', 'featureContainer','featureContainer-35',  null, false, [], null, true, true, '','multiFeat-1', 
    [new Feature ( null , false , 0, 'multiFeat-1', 'multiFeatures')],  
),
new FeatureContainer ('featureContai-36', 'featureContainer','featureContainer-36',  null, false, [], null, true, true, '', 'multiFeat-2', 
    [new Feature ( null , false , 0, 'multiFeat-2', 'multiFeatures')],  
),
new FeatureContainer ('featureContai-37', 'featureContainer','featureContainer-37',  null, false, [], null, true, true, '', 'multiFeat-3', 
    [new Feature ( null , false , 0, 'multiFeat-3', 'multiFeatures')],  
),
new FeatureContainer ('featureContai-38', 'featureContainer','featureContainer-38',  null, false, [], null, true, true, '', 't-1', 
    [new Feature ( null , false , 0, 't-1', 'tabs')],  
),
new FeatureContainer ('featureContai-39', 'featureContainer','featureContainer-39',  null, false, [], null, true, true, '', 't-2', 
    [new Feature ( null , false , 0, 't-2', 'tabs')],  
),
new FeatureContainer ('featureContai-40', 'featureContainer','featureContainer-40',  null, false, [], null, true, true, '', 'sharedCalendarSta-1', 
    [new Feature ( null , false , 0, 'sharedCalendarSta-1', 'sharedCalendarStatus')],  
),
new FeatureContainer ('featureContai-41', 'featureContainer','featureContainer-41',  null, false, [], null, true, true, '', 'sharedCalendarSta-2', 
    [new Feature ( null , false , 0, 'sharedCalendarSta-2', 'sharedCalendarStatus')],  
),
new FeatureContainer ('featureContai-42', 'featureContainer','featureContainer-42',  null, false, [], null, true, true, '', 'sharedCalendarSta-3', 
    [new Feature ( null , false , 0, 'sharedCalendarSta-3', 'sharedCalendarStatus')],  
)

   

    
]

/**
 * export class FeatureContainerListItem{

    constructor( public _id: string , 
                 public owner: string , 
                 public allUsersAllowed: boolean , 
                 public userListAllowed: string [], 
                 public dateAndTime: string ,  
                 public featureContainer_ids: string[] , // list of featureContainer_id
                 public selectedFeatureContainer_id: string){} 
}
 */


reset_initialNullFeatureContainerListItems: FeatureContainerListItem [] = [

    new FeatureContainerListItem ('featureContainerListI-1', 'featureContainerListItem','featureContainerListItem-1', null, false, [], null, 'personal' , 'singleWord', 
    ['featureContai-1', 'featureContai-2', 'featureContai-3'],
     'featureContai-1' 
    ),
    new FeatureContainerListItem ( 'featureContainerListI-2', 'featureContainerListItem','featureContainerListItem-2',  null, false, [], null, 'personal' , 'singleWordInput',
        ['featureContai-4', 'featureContai-5', 'featureContai-6'],
         'featureContai-4'
    ),
    new FeatureContainerListItem ( 'featureContainerListI-3', 'featureContainerListItem','featureContainerListItem-3',  null, false, [], null, 'personal' , 'toggleSingleWordButton',
    ['featureContai-7','featureContai-8','featureContai-9'], 
     'featureContai-7' ),

    new FeatureContainerListItem ( 'featureContainerListI-4', 'featureContainerListItem','featureContainerListItem-4',  null, false, [], null, 'personal' , 'singleDropDownSelect',
    ['featureContai-10','featureContai-11'], 'featureContai-10' ),

    new FeatureContainerListItem ( 'featureContainerListI-5', 'featureContainerListItem','featureContainerListItem-5',  null, false, [], null, 'personal' , 'singleSlider',
    ['featureContai-12','featureContai-13'], 'featureContai-12' ),
    
    new FeatureContainerListItem ( 'featureContainerListI-6', 'featureContainerListItem','featureContainerListItem-6',  null, false, [], null, 'personal' , 'wordInput',
    ['featureContai-14'], 'featureContai-14' ),

    new FeatureContainerListItem ( 'featureContainerListI-7', 'featureContainerListItem','featureContainerListItem-7',  null, false, [], null, 'personal' , 'multiFeatures',
    ['featureContai-34','featureContai-35', 'featureContai-36', 'featureContai-37'], 'featureContai-34' ),

    new FeatureContainerListItem ( 'featureContainerListI-8', 'featureContainerListItem','featureContainerListItem-8',  null, false, [], null, 'personal' , 'tabs',
    ['featureContai-38', 'featureContai-39'], 'featureContai-38' ),

    new FeatureContainerListItem ( 'featureContainerListI-9', 'featureContainerListItem','featureContainerListItem-9',  null, false, [], null, 'personal' , 'fixLayout',
    ['featureContai-22', 'featureContai-23', 'featureContai-24'], 'featureContai-22' ),

    new FeatureContainerListItem ( 'featureContainerListI-10', 'featureContainerListItem','featureContainerListItem-10',  null, false, [], null, 'personal' , 'oneDateCalendar',
    ['featureContai-25', 'featureContai-26', 'featureContai-27'], 'featureContai-25' ),

    new FeatureContainerListItem ( 'featureContainerListI-11', 'featureContainerListItem','featureContainerListItem-11',  null, false, [], null, 'personal' , 'multiEntryCalendar',
     ['featureContai-28', 'featureContai-29'], 'featureContai-28' ),

    new FeatureContainerListItem ( 'featureContainerListI-12', 'featureContainerListItem','featureContainerListItem-12',  null, false, [], null, 'personal' , 'fixText',
    ['featureContai-15', 'featureContai-16', 'featureContai-17', 'featureContai-18', 'featureContai-19','featureContai-20', 'featureContai-21',], 
     'featureContai-15' ),

    new FeatureContainerListItem ( 'featureContainerListI-13', 'featureContainerListItem','featureContainerListItem-13',  null, false, [], null, 'personal' , 'calculatorMixTable',
    ['featureContai-30', 'featureContai-31'], 
     'featureContai-30' ),

    new FeatureContainerListItem ( 'featureContainerListI-14', 'featureContainerListItem','featureContainerListItem-14',  null, false, [], null, 'personal' , 'dropDownSelect',
    ['featureContai-32', 'featureContai-33'], 
     'featureContai-32' ),

    new FeatureContainerListItem ( 'featureContainerListI-15', 'featureContainerListItem','featureContainerListItem-15',  null, false, [], null, 'personal' , '' ,
    [], null ),

    new FeatureContainerListItem ( 'featureContainerListI-16', 'featureContainerListItem','featureContainerListItem-16',  null, false, [], null, 'personal' , '',
    [], null ),

    new FeatureContainerListItem ( 'featureContainerListI-17', 'featureContainerListItem','featureContainerListItem-17',  null, false, [], null, 'personal' , '',
    [], null ),

    new FeatureContainerListItem ( 'featureContainerListI-18', 'featureContainerListItem','featureContainerListItem-18',  null, false, [], null, 'personal' , 'sharedCalendarStatus',
    ['featureContai-40', 'featureContai-41', 'featureContai-42'], 
    'featureContai-40' ),

]

/**
 * export class FeatureIndexTopicList {

    constructor ( public _id: string , 
                  public owner: string , 
                  public allUsersAllowed: boolean,
                  public userListAllowed: string[],
                  public dateAndTime: string , 
                  public type: string , // personal, shared, preferred , public 
                  public featureTopic_ids: string[]){}
}
 */

reset_initialNullFeatureIndexTopicListItems: FeatureIndexTopicList [] =
[
    new FeatureIndexTopicList ( '1', 'featureIndexTopicList', 'featureIndexTopicList-1', null, false, [], null, 'personal', ['1', '2', '3','4','5', '13', '11','8','9','10','12','14','15', '6', '7']),
    new FeatureIndexTopicList ( '2', 'featureIndexTopicList', 'featureIndexTopicList-2', null, false, [], null, 'share', ['']),
    new FeatureIndexTopicList ( '3', 'featureIndexTopicList', 'featureIndexTopicList-3', null, false, [], null, 'preference', ['']),
    new FeatureIndexTopicList ( '4', 'featureIndexTopicList', 'featureIndexTopicList-4', null, false, [], null, 'public', ['']),

]
/**
 *  constructor  ( public _id: string , 
                   public owner: string , 
                   public allUsersAllowed: boolean,
                   public userListAllowed: string[],
                   public isStandardFeature: boolean, 
                   public isTab: boolean, 
                   public name : string , 
                   public featureConta
 */
reset_initialNullFeatureTopics: FeatureTopic[] = [

    new FeatureTopic ( '1' ,'featureTopic','featureTopic-1', null, false, [], null , 'personal' ,
    false, false, 'single words' , 
    [ 'featureContainerListI-1', 'featureContainerListI-2' ]),

    new FeatureTopic ( '2' ,'featureTopic','featureTopic-2',  'antoniohhd@gmail.com',  false, [], null , 'personal' ,
        false, false, 'single buttons' , 
           [ 'featureContainerListI-3']
    ),

    new FeatureTopic ( '3' ,'featureTopic','featureTopic-3',  null,  false, [],  null , 'personal' ,
    false, false, 'single select' , 
       [ 
        'featureContainerListI-4'
       ]
    ),

    new FeatureTopic ( '4' ,'featureTopic','featureTopic-4',  null,  false, [], null , 'personal' ,
    false, false, 'single slider' , 
       [ 
        'featureContainerListI-5'
       ]
    ),

    new FeatureTopic ( '5' ,'featureTopic','featureTopic-5',  null ,  false, [], null , 'personal' ,
    true, false, 'words' , 
       [ 
        'featureContainerListI-6'
       ]
    ),

    new FeatureTopic ( '11','featureTopic','featureTopic-11',  null ,  false, [], null , 'personal' ,
    true, false, 'multiFeature' , 
           [ 
            'featureContainerListI-7'
           ]
    ),

    new FeatureTopic ( '8' ,'featureTopic','featureTopic-8',  null ,  false, [], null , 'personal' ,
        false, true, 'tabs' , 
           [ 
            'featureContainerListI-8'
           ]
    ),

    new FeatureTopic ( '9' ,'featureTopic','featureTopic-9',  null ,  false, [], null , 'personal' ,
    true, false, 'fix layout' , 
           [ 
            'featureContainerListI-9'
           ]
    ),

    new FeatureTopic ( '10' ,'featureTopic','featureTopic-10',  null ,  false, [], null , 'personal' ,
    true, false, 'calendar' , 
           [ 
            'featureContainerListI-10' , 'featureContainerListI-11', 'featureContainerListI-18'
            
           ]
    ),

    new FeatureTopic ( '12' ,'featureTopic','featureTopic-12',  null ,  false, [], null , 'personal' ,
    true, false, 'fix text' , 
           [ 
            'featureContainerListI-12'
           ]
    ),

    new FeatureTopic ( '13' ,'featureTopic','featureTopic-13',  null ,  false, [], null , 'personal' ,
    true, false, 'table + calculation' , 
           [ 
            'featureContainerListI-13'
           ]
    ),

    new FeatureTopic ( '14' ,'featureTopic','featureTopic-14',  null ,  false, [], null , 'personal' ,
    true, false, 'select' , 
           [ 
            'featureContainerListI-14'
           ]
    ),

    new FeatureTopic ( '15' ,'featureTopic','featureTopic-15',  null ,  false, [], null , 'personal' ,
    true, false, 'comment' , 
           [ 
            'featureContainerListI-15'
           ]
    ),

    new FeatureTopic ( '6' ,'featureTopic','featureTopic-6',  null ,  false, [], null , 'personal' ,
    true, false, 'by industry sector' , 
       [ 
        'featureContainerListI-16'
       ]
),

new FeatureTopic ( '7' ,'featureTopic','featureTopic-7',  null ,  false, [], null , 'personal' ,
true, false, 'scan QR codes' , 
       [ 
        'featureContainerListI-17'
       ]
)

];

/**
 * startingParagraphTopicReference: ParagraphTopic = new ParagraphTopic (null, null, false, [], null, true, 
                                     false, '', [] )
 */

reset_initialParagraphTopicReference: ParagraphTopic = new ParagraphTopic (null, null, null, null, false, [], null, true, false, '', [] )


reset_initialNullParagraphTopicIndexes: ParagraphTopicIndex[]= [
    new ParagraphTopicIndex ('1', 'paragraphTopicIndex', 'paragraphTopicIndex-1', null, false, [], null,  'personal-paragraphs', [ '4', '1', '2', '3']),
    new ParagraphTopicIndex ('2', 'paragraphTopicIndex', 'paragraphTopicIndex-2', null, false, [], null,  'shared-paragraphs', []), 
    new ParagraphTopicIndex ('3', 'paragraphTopicIndex', 'paragraphTopicIndex-3', null, false, [], null,  'preferred-paragraphs', []),
    new ParagraphTopicIndex ('4', 'paragraphTopicIndex', 'paragraphTopicIndex-4', null, false, [], null,  'public-paragraphs', [])
]


reset_initialNullParagraphFeatureContainers: ParagraphFeatureContainer[]=[

    new ParagraphFeatureContainer ( '2','paragraphFeatureContainer', 'paragraphFeatureContainer-2', null, false, [], null, true, true, 'paragraphFeature', true, '2'  ),
    new ParagraphFeatureContainer ( '4','paragraphFeatureContainer', 'paragraphFeatureContainer-4',  null, false, [], null, true, true, 'paragraphFeature', true, '17'  ),
    new ParagraphFeatureContainer ( '5','paragraphFeatureContainer', 'paragraphFeatureContainer-5',  null, false, [], null, true, true, 'paragraphFeature', true, '18'  ),
    new ParagraphFeatureContainer ( '9','paragraphFeatureContainer', 'paragraphFeatureContainer-9',  null, false, [], null, true, true, 'paragraphFeature', true, '27'  ),
    new ParagraphFeatureContainer ( '1','paragraphFeatureContainer', 'paragraphFeatureContainer-1',  null, false, [], null, true, true, 'paragraphFeature', true, '1'  ),
    new ParagraphFeatureContainer ( '11','paragraphFeatureContainer', 'paragraphFeatureContainer-11',  null, false, [], null, true, true, 'paragraphFeature', true, '24'  ),
    new ParagraphFeatureContainer ( '6','paragraphFeatureContainer', 'paragraphFeatureContainer-6',  null, false, [], null, true, true, 'paragraphFeature', true, '29'  ),
    new ParagraphFeatureContainer ( '10','paragraphFeatureContainer', 'paragraphFeatureContainer-10',  null, false, [], null, true, true, 'paragraphFeature', true, '20'  ),
    new ParagraphFeatureContainer ( '7','paragraphFeatureContainer', 'paragraphFeatureContainer-7',  null, false, [], null, true, true, 'paragraphFeature', true, '21'  ),
    new ParagraphFeatureContainer ( '8','paragraphFeatureContainer', 'paragraphFeatureContainer-8',  null, false, [], null, true, true, 'paragraphFeature', true, '34'  ),
    new ParagraphFeatureContainer ( '12','paragraphFeatureContainer', 'paragraphFeatureContainer-12',  null, false, [], null, true, true, 'paragraphFeature', true, '30'  ),
    new ParagraphFeatureContainer ( '3','paragraphFeatureContainer', 'paragraphFeatureContainer-3',  null, false, [], null, true, true, 'paragraphFeature', true, '25'  ),
    new ParagraphFeatureContainer ( '13','paragraphFeatureContainer', 'paragraphFeatureContainer-13',  null, false, [], null, true, true, 'paragraphFeature', true, '35'  ),
    new ParagraphFeatureContainer ( '14','paragraphFeatureContainer', 'paragraphFeatureContainer-14',  null, false, [], null, true, true, 'paragraphFeature', true, '36'  )

]


reset_initialNullParagraphTopics: ParagraphTopic[] =  [

    new ParagraphTopic ( '1' , 'paragraphTopic', 'paragraphTopic-1', null , false, [], null , true, false, 'customer link', ['1','2', '3', '4' ]),
    new ParagraphTopic ( '2' , 'paragraphTopic', 'paragraphTopic-2',  null , false, [], null , true, false, 'supplier', ['5', '6', '11','7' ]),
    new ParagraphTopic ( '3' , 'paragraphTopic', 'paragraphTopic-3',  null , false, [], null , true, false, 'catalogue', ['9', '10', '12' ]),
    new ParagraphTopic ( '4' , 'paragraphTopic', 'paragraphTopic-4', null , false, [], null , true, false, 'wasabi rooms', ['13', '14' ]),
]


/**
 * export class FormIndexTopicListItem {

    constructor( public _id: string , 
        public owner: string, 
        public allUsersAllowed: boolean , 
        public userListAllowed: string[] ,
        public dateAndTime: string, 
        public type: string , // just the 4 : personal / shared/ preferred / public
        public formIndexTopic_ids: string[]
      ){}
} */ 

reset_initialNullFormIndexTopicListItems: FormIndexTopicListItem[] = [

    new FormIndexTopicListItem ( '1', 'formIndexTopicListItem','formIndexTopicListItem-1', null, false, [], null, 'personal', ['1', '2', '3']),
    new FormIndexTopicListItem ( '2', 'formIndexTopicListItem','formIndexTopicListItem-2',  null, false, [], null, 'share', []), // '6', '3', '5', '1', '3'
    new FormIndexTopicListItem ( '3', 'formIndexTopicListItem','formIndexTopicListItem-3',  null, false, [], null, 'preference', []), // '4', '1', '3', '2'
    new FormIndexTopicListItem ( '4', 'formIndexTopicListItem','formIndexTopicListItem-4',  null, false, [], null, 'public', []),

]

reset_initialNullFormIndexTopics: FormIndexTopic[] = [


    new FormIndexTopic (  '1', 'formIndexTopic', 'formIndexTopic-1',  null , null, 
       false ,  [] , true, false, 'villas management' ,
       ['1', '2']
    ),

     new FormIndexTopic (  '2',  'formIndexTopic', 'formIndexTopic-2',  null ,null ,
        false ,   [] , true, false,  'expenses & holidays' ,
        [ '3' ] ),

      new FormIndexTopic (  '3',  'formIndexTopic', 'formIndexTopic-3',  null , null ,
        false , [] , true, false,  'marketing' , 
         [ '5', '4'
        ]),
        new FormIndexTopic (  '4',  'formIndexTopic', 'formIndexTopic-4',  null , null, 
        false ,  [] , true, false, 'from brembo' ,
        ['1', '2']
     ),
 
      new FormIndexTopic (  '5',  'formIndexTopic', 'formIndexTopic-5',  null ,null ,
         false ,   [] , true, false,  'from hotels' ,
         [ '3', '4' ] ),
 
       new FormIndexTopic (  '6',  'formIndexTopic', 'formIndexTopic-6',  null , null ,
         false , [] , true, false,  'from admin' , 
          [ '5', '4'
         ])

] ;

reset_initialNullFormFeatureContainers: FormFeatureContainer[] = [

    new FormFeatureContainer ( '1', 'formFeatureContainer', 'formFeatureContainer-1' , null, false, [], null, true, true, 'personal', true, null , '1'), 
    new FormFeatureContainer ( '2', 'formFeatureContainer', 'formFeatureContainer-2', null, false, [], null, true, true, 'personal', true, null,  '2'),
    new FormFeatureContainer ( '3', 'formFeatureContainer', 'formFeatureContainer-3' ,null, false, [], null, true, true, 'personal', true, null,  '3'),
    new FormFeatureContainer ( '4', 'formFeatureContainer', 'formFeatureContainer-4', null, false, [], null, true, true, 'share', true, 'recipientIn-2',  '4'),
    new FormFeatureContainer ( '5', 'formFeatureContainer', 'formFeatureContainer-5' ,null, false, [], null, true, true, 'personal', true, 'recipientIn-3' ,  '5')



]

reset_initialNullFormResults: FormResult[] = []
reset_initialNullFormResultsList: FormResult[] = []




ngOnDestroy(){

    this.updateInitialNullDataOnAllComponents.unsubscribe()
}

}