import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef , MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { FormIndexTopic } from 'src/app/forms/form-list/form-index-topic-list/form-index-topic.model';
import { FormIndexTopicService } from 'src/app/forms/form-list/form-index-topic-list/form-index-topic.service';
import { Form } from '../../../form.model';
import { FormService } from 'src/app/forms/form.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { FormFeatureContainer } from '../../form-index-topic-list/form-feature-container.model';
import { FormFeatureContainerService } from '../../form-index-topic-list/form-feature-container.service';
import { FormIndexTopicListItemService } from '../../form-index-topic-list/form-index-topic-list-item.service';
import { FormIndexTopicListItem } from '../../form-index-topic-list/form-index-topic-list-item.model';
import { AdviseIfHttpStoreComponentsCallHasBeenMadeService } from 'src/app/services/other-components-services/advise-if-http-store-components-has-been-made.service';
import { FormControl, FormGroup } from '@angular/forms';
import { CreateUpdateMultiComponentsService } from 'src/app/services/shared-services/create-update-multi-components.service';
import { StorePersonalFeatureIndexComponentsService } from 'src/app/services/store-initial-components/store-personal-feature-index-components.service';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { GetComponentsHttpRequestService } from 'src/app/services/gets-services/get-components-http-request.sevice';


@Component({
  selector: 'app-add-form-bottom-sheet',
  templateUrl: './add-form-bottom-sheet.component.html',
  styleUrls: ['./add-form-bottom-sheet.component.css']
})
export class AddFormBottomSheetComponent implements OnInit {

  user: string 

  indexTopicForm: FormGroup 

  form: Form ;
  formIndexTopic: FormIndexTopic ; 
  formIndexTopics: FormIndexTopic[];
  formIndexTopicListItem: FormIndexTopicListItem ;

  toConfirm: boolean;

  formIndexTopixExist: boolean 
  

  constructor(private _bottomSheetRef: MatBottomSheetRef<AddFormBottomSheetComponent> ,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: any , 
               private formIndexTopicService: FormIndexTopicService,
               private formIndexTopicListItemService: FormIndexTopicListItemService , 
               private formService: FormService ,
               private formFeatureContainerService: FormFeatureContainerService,
               private adviseIfHttpStoreComponentsCallHasBeenMadeService: AdviseIfHttpStoreComponentsCallHasBeenMadeService , 
               private router: Router,
               private route: ActivatedRoute,
               private userObjectUpdateService: UserObjectUpdateService ,
               private postComponentsHttpRequestService: PostComponentsHttpRequestService,
               private createUpdateMultiComponentsService: CreateUpdateMultiComponentsService,
               private storePersonalFeatureIndexComponentsService: StorePersonalFeatureIndexComponentsService , 
               private initialNullSetupService: InitialNullSetupService , 
               private getComponentsHttpRequestService: GetComponentsHttpRequestService
    ) { }

  ngOnInit(): void {

    this.user = this.userObjectUpdateService.userEmail
    this.toConfirm = false ;

    this.formIndexTopicListItem = this.formIndexTopicListItemService.getFormIndexTopicListItem_by_type('personal')

    this.formIndexTopics = []

    for ( let i=0 ; i<this.formIndexTopicListItem.formIndexTopic_ids.length ; i++){
        this.formIndexTopics.push(this.formIndexTopicService.getFormIndexTopicBy_id(this.formIndexTopicListItem.formIndexTopic_ids[i]))
    }

    if ( this.formIndexTopics.length === 0 ){ this.formIndexTopixExist = false }
    if ( this.formIndexTopics.length > 0   ){ this.formIndexTopixExist = true  }

    this.indexTopicForm = new FormGroup ({
      name : new FormControl ( '' )
     })


    this.form = this.data.form
    console.log(this.form)
  }

  onAddForm(i){
    this.formIndexTopic = this.formIndexTopics[i]
    console.log(i)
    this.addForm();

  };

 
addForm(){

  if( this.user === null ){  // ----------------------------------------------------------------------------------------
    this.formService.addForm(this.form ,'personal' )
    
    this._bottomSheetRef.dismiss()
    this.onStoreFormFeatureContainer( this.form )

  }; // ----------------------------------------------------------------------------------------

  if( this.user !== null ){ // ----------------------------------------------------------------------------------------
    this.checkIfAnyOtherFormHasBeenAddedInTheMeantime()
   
  }; // ----------------------------------------------------------------------------------------
};

checkIfAnyOtherFormHasBeenAddedInTheMeantime(){
  this._bottomSheetRef.dismiss()
  this.storePersonalFeatureIndexComponentsService.getPersonalUpdatedFeatureIndex_components(this.user)
      .subscribe((res: any)=> {
        console.log(res)

        const httpFeatureIndexComponentResult: any = res

        const newFormIndexTopics: FormIndexTopic[] = httpFeatureIndexComponentResult.formIndexTopic
        console.log(newFormIndexTopics)
        this.formIndexTopicService.formIndexTopics = newFormIndexTopics 
        this.initialNullSetupService.initialNullFormIndexTopics = newFormIndexTopics

        const newFormIndexTopicListItems: FormIndexTopicListItem[] = httpFeatureIndexComponentResult.formIndexTopicListItem
        console.log(newFormIndexTopicListItems)
        this.formIndexTopicListItemService.formIndexTopicListItems = newFormIndexTopicListItems
        this.initialNullSetupService.initialNullFormIndexTopicListItems = newFormIndexTopicListItems

        const newFormFeatureContainers: FormFeatureContainer[] = httpFeatureIndexComponentResult.formFeatureContainer
        console.log(newFormFeatureContainers)
        this.formFeatureContainerService.formFeatureContainers = newFormFeatureContainers 
        this.initialNullSetupService.initialNullFormFeatureContainers = newFormFeatureContainers 

        const indexOfTopicreference = newFormIndexTopics.findIndex(x=> x._id === this.formIndexTopic._id )
        const latestFormIndexTopicOfReference = newFormIndexTopics[indexOfTopicreference]

        /**
         * continue to check if any other formItems is been added to be sure of the UIID 
         */
        this.updateFormdata_withLatestData_AddToForms(this.user , newFormFeatureContainers, latestFormIndexTopicOfReference  )


      }, error =>{
        console.log( error, 'throw error ')
      })

}

updateFormdata_withLatestData_AddToForms(userEmail: string , formFeatureContainers: FormFeatureContainer[], formIndexTopic: FormIndexTopic  ){
        
  /**
   * get latest updated forms 
   */
  this.getComponentsHttpRequestService.httpGetComponentsByType(userEmail, 'formItem')
            .subscribe((formItemResults: any )=> {
                        console.log( formItemResults)

                       const formItemsResults: any[] = formItemResults.formItem 
                       this.formService.addFormsOnly(formItemsResults)

  const new_form_id = null
  const newComponentName = 'formItem'
  const newUIID= 'formItem-'+(formItemsResults.length+1).toString()
  const newOwner = this.user ;
  const newDateAndTime = null ;
  const newUpdateDateAndTime = null 
  const newAllUsersAllowed = false ;
  const newUserListAllowed = [];
  const newCanBeResharedByOthers = false 
  const newNoNeedecipientAndSubject = this.form.noNeedRecipientAndSubject
  const newIsFixedSubject = this.form.isFixedSubject
  const newIsSharable = this.form.isShareble
  const newIsBeenShared = this.form.isBeenShared
  const newformSubject = this.form.formSubject
  const newRecipientIndex_id = this.form.recipientIndex_id
  const newParagraph_ids = this.form.paragraph_ids
  
  const newUpdatedForm = new Form ( new_form_id,
                             newComponentName,
                             newUIID,
                             newOwner ,
                             newDateAndTime,
                             newUpdateDateAndTime,
                             newAllUsersAllowed,
                             newUserListAllowed,
                             newCanBeResharedByOthers,
                             newNoNeedecipientAndSubject ,
                             newIsFixedSubject, 
                             newIsSharable, 
                             newIsBeenShared,
                             newformSubject,
                             newRecipientIndex_id ,
                             newParagraph_ids
                             )

    

    const new_id2 = null 
    const newComponentName2 = 'formFeatureContainer'
    const newUIID2 = 'formFeatureContainer-'+(formFeatureContainers.length+1).toString()
    const newOwner2 = this.user 
    const newAllUsersAllowed2 = false 
    const newUserListAllowed2 = []
    const newDateAndTime2 = null 
    const newIsActive2 = true 
    const newIsAccepted2 = true 
    const newName2 = 'personal'
    const newFormFeatureState2 = true 
    const newRecipientIndex_id2 = null 
    const newForm_id2 = null 

    const newFormFeatureContainer = new FormFeatureContainer (new_id2, newComponentName2, newUIID2, newOwner2, newAllUsersAllowed2, newUserListAllowed2 ,
                                         newDateAndTime2, newIsActive2, newIsAccepted2, newName2, newFormFeatureState2, newRecipientIndex_id2, newForm_id2 )

   
    this.createUpdateMultiComponentsService.createForm_CreateFormFeatureContainer_UpdateFormIndexTopic(this.user, newUpdatedForm, newFormFeatureContainer, formIndexTopic )
        .subscribe((res: any)=>{
          console.log(res)
            
          const formItemResult: Form = res.formItem[0]
          const formFeatureContainerResult: FormFeatureContainer = res.featureIndex[0]
          const formIndexTopicResult: FormIndexTopic = res.featureIndex[1]

          this.formService.addForm(formItemResult , 'personal')
          this.formFeatureContainerService.addFormFeatureContainer(formFeatureContainerResult)
          
          this.formIndexTopicService.updateFormIndexTopic_only(formIndexTopicResult)
          const form_id: string = formItemResult._id
          const hasBeenStored: boolean = true
          const formItemPlusChildsHasBeenStored: any = { form_id, hasBeenStored }
          this.adviseIfHttpStoreComponentsCallHasBeenMadeService.formItemPlusChildsHasBeenStoredList.push(formItemPlusChildsHasBeenStored)
  
          this.toConfirm = true
         
          this.router.navigate( ['../form-list/personal-form', formItemResult._id ], { relativeTo: this.route })
          
        }) // end second subscription 
  }) //  end 1st  subscription

}


onStoreFormFeatureContainer(form: Form){

  const new_id = (this.formFeatureContainerService.getFormFeatureContainers().length+1).toString()
  const newComponentName = 'formFeatureContainer'
  const newUIID = 'formFeatureContainer-'+(this.formFeatureContainerService.getFormFeatureContainers().length+1).toString()
  const newOwner = this.user 
  const newAllUsersAllowed = false 
  const newUserListAllowed = []
  const newDateAndTime = null
  const newIsActive = true
  const newIsAccepted = true
  const newName = 'personal'
  const newFormFeatureState = true 
  const newRecipientIndex_id = null 
  const newForm_id = form._id

  const newFormFeatureContainer = new FormFeatureContainer ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed,
                     newDateAndTime, newIsActive, newIsAccepted, newName, newFormFeatureState, newRecipientIndex_id, newForm_id   )

  if ( this.user === null ){ // ----------------------------------------------------------------------------------------

    this.formFeatureContainerService.addFormFeatureContainer(newFormFeatureContainer)
    this.onUpdateFormIndexTopic(newFormFeatureContainer)
  
  } // ----------------------------------------------------------------------------------------


}

onUpdateFormIndexTopic(formFeatureContainer: FormFeatureContainer){

   const newFormFeaturContainer_ids = this.formIndexTopic.formFeatureContainer_ids
   newFormFeaturContainer_ids.push(formFeatureContainer._id)

   const newFormIndexTopic = new FormIndexTopic (
    this.formIndexTopic._id,
    this.formIndexTopic.componentName,
    this.formIndexTopic.UIID,
    this.formIndexTopic.owner,
    this.formIndexTopic.dateAndTime ,
    this.formIndexTopic.allUsersAllowed ,
    this.formIndexTopic.userListAllowed, 
    this.formIndexTopic.isAccepted,
    this.formIndexTopic.isArchived, 
    this.formIndexTopic.name,
    newFormFeaturContainer_ids
    )

    if ( this.user === null ){  // ----------------------------------------------------------------------------------------

      this.toConfirm = true
      this.formIndexTopicService.formIndexTopics[this.formIndexTopicService.getFormIndexTopics().findIndex(x=> x._id ==  this.formIndexTopic._id)] = newFormIndexTopic


      this.router.navigate( ['../form-list/personal-form', this.form._id ], { relativeTo: this.route })

    } // ----------------------------------------------------------------------------------------
}

onAddNewTopicName(){
  this.formIndexTopixExist = false 
}


onAddFormToNewTopic(){
  console.log( this.indexTopicForm.value.name )

  if ( this.user !== null ){
  this._bottomSheetRef.dismiss()
  this.storePersonalFeatureIndexComponentsService.getPersonalUpdatedFeatureIndex_components(this.user)
      .subscribe((res: any)=> {
        console.log(res)

        const httpFeatureIndexComponentResult: any = res

        const newFormIndexTopicListItems: FormIndexTopicListItem[] = httpFeatureIndexComponentResult.formIndexTopicListItem
        console.log(newFormIndexTopicListItems)
        this.formIndexTopicListItemService.formIndexTopicListItems = newFormIndexTopicListItems
        this.initialNullSetupService.initialNullFormIndexTopicListItems = newFormIndexTopicListItems

        const newFormIndexTopics: FormIndexTopic[] = httpFeatureIndexComponentResult.formIndexTopic
        console.log(newFormIndexTopics)
        this.formIndexTopicService.formIndexTopics = newFormIndexTopics 
        this.initialNullSetupService.initialNullFormIndexTopics = newFormIndexTopics

        const newFormFeatureContainers: FormFeatureContainer[] = httpFeatureIndexComponentResult.formFeatureContainer
        console.log(newFormFeatureContainers)
        this.formFeatureContainerService.formFeatureContainers = newFormFeatureContainers 
        this.initialNullSetupService.initialNullFormFeatureContainers = newFormFeatureContainers 

        const index = newFormIndexTopicListItems.findIndex(x=> x.type === 'personal')
        const FormIndexTopicListItemOfReference: FormIndexTopicListItem = newFormIndexTopicListItems[index]

        this.createForm_CreateFormFeatureContainer_CreateFormIndexTopic_UpdateFormIndexTopicListItem( newFormFeatureContainers, newFormIndexTopics, FormIndexTopicListItemOfReference )

      })
  }

  if ( this.user === null ){
    this.create_Add_NullUserFormIndexTopic_AddToService()
  }
}

createForm_CreateFormFeatureContainer_CreateFormIndexTopic_UpdateFormIndexTopicListItem(
                                                       newFormFeatureContainers: FormFeatureContainer[], 
                                                       newFormIndexTopics: FormIndexTopic[], 
                                                       FormIndexTopicListItemOfReference: FormIndexTopicListItem ){
    
    this.getComponentsHttpRequestService.httpGetComponentsByType( this.user, 'formItem')
    .subscribe((formItemResults: any )=> {
                console.log( formItemResults)

               const formItemsResults: any[] = formItemResults.formItem 
               this.formService.addFormsOnly(formItemsResults)

const new_form_id = null
const newComponentName = 'formItem'
const newUIID= 'formItem-'+(formItemsResults.length+1).toString()
const newOwner = this.user ;
const newDateAndTime = null ;
const newUpdateDateAndTime = null 
const newAllUsersAllowed = false ;
const newUserListAllowed = [];
const newCanBeResharedByOthers = false 
const newNoNeedecipientAndSubject = this.form.noNeedRecipientAndSubject
const newIsFixedSubject = this.form.isFixedSubject
const newIsSharable = this.form.isShareble
const newIsBeenShared = this.form.isBeenShared
const newformSubject = this.form.formSubject
const newRecipientIndex_id = this.form.recipientIndex_id
const newParagraph_ids = this.form.paragraph_ids

const newUpdatedForm = new Form ( new_form_id,
                     newComponentName,
                     newUIID,
                     newOwner ,
                     newDateAndTime,
                     newUpdateDateAndTime,
                     newAllUsersAllowed,
                     newUserListAllowed,
                     newCanBeResharedByOthers,
                     newNoNeedecipientAndSubject ,
                     newIsFixedSubject, 
                     newIsSharable, 
                     newIsBeenShared,
                     newformSubject,
                     newRecipientIndex_id ,
                     newParagraph_ids
                     )


const new_id2 = null 
const newComponentName2 = 'formFeatureContainer'
const newUIID2 = 'formFeatureContainer-'+(newFormFeatureContainers.length+1).toString()
const newOwner2 = this.user 
const newAllUsersAllowed2 = false 
const newUserListAllowed2 = []
const newDateAndTime2 = null 
const newIsActive2 = true 
const newIsAccepted2 = true 
const newName2 = 'personal'
const newFormFeatureState2 = true 
const newRecipientIndex_id2 = null 
const newForm_id2 = null 

const newFormFeatureContainer = new FormFeatureContainer (new_id2, newComponentName2, newUIID2, newOwner2, newAllUsersAllowed2, newUserListAllowed2 ,
                                 newDateAndTime2, newIsActive2, newIsAccepted2, newName2, newFormFeatureState2, newRecipientIndex_id2, newForm_id2 )


                                 const new_id3 = null
                                 const newComponentName3 = 'formIndexTopic'
                                 const newUIID3 = 'formIndexTopic-'+(newFormIndexTopics.length+1).toString()
                                 const newOwner3 = this.user 
                                 const newDateAndTime3 = null
                                 const newAllUsersAllowed3 = false
                                 const newUserListAllowed3 = []
                                 const newIsAccepted3 = true 
                                 const newIsArchived3 = false 
                                 const newName3 = this.indexTopicForm.value.name
                                 const newFormFeatureContainer_ids3 = null
                                 
                                 const newFormIndexTopic = new FormIndexTopic ( new_id3, newComponentName3, newUIID3, newOwner3, newDateAndTime3, newAllUsersAllowed3, newUserListAllowed3,
                                        newIsAccepted3, newIsArchived3, newName3, newFormFeatureContainer_ids3 )


this.createUpdateMultiComponentsService.createForm_CreateFormFeatureContainer_CreateFormIndexTopic_UpdateFormIndexTopicListItem( this.user, 
                                                                                                                                 newUpdatedForm ,
                                                                                                                                 newFormFeatureContainer,
                                                                                                                                 newFormIndexTopic , 
                                                                                                                                 FormIndexTopicListItemOfReference  )
                                       .subscribe((res: any)=>{

                                        const formItemResult: Form = res.formItem[0]
                                        const formFeatureContainerResult: FormFeatureContainer = res.featureIndex[0]
                                        const formIndexTopicResult: FormIndexTopic = res.featureIndex[1]
                                        const formIndexTopicListItem: FormIndexTopicListItem = res.featureIndex[2]
                              
                                        this.formService.addForm(formItemResult , 'personal')
                                        this.formFeatureContainerService.addFormFeatureContainer(formFeatureContainerResult)

                                        const index = this.formIndexTopicListItemService.getFormIndexTopicListItems().findIndex(x=> x._id === formIndexTopicListItem._id)
                                        this.formIndexTopicListItemService.formIndexTopicListItems[index] = formIndexTopicListItem
                                        const initialIndex = this.initialNullSetupService.initialNullFormIndexTopicListItems.findIndex(x=> x._id === formIndexTopicListItem._id )
                                        this.initialNullSetupService.initialNullFormIndexTopicListItems[initialIndex] = formIndexTopicListItem

                                        this.formIndexTopicService.updateFormIndexTopic_only(formIndexTopicResult)
                                        const form_id: string = formItemResult._id
                                        const hasBeenStored: boolean = true
                                        const formItemPlusChildsHasBeenStored: any = { form_id, hasBeenStored }
                                        this.adviseIfHttpStoreComponentsCallHasBeenMadeService.formItemPlusChildsHasBeenStoredList.push(formItemPlusChildsHasBeenStored)
                                
                                        this.toConfirm = true
                                       
                                        this.router.navigate( ['../form-list/personal-form', formItemResult._id ], { relativeTo: this.route })
                                         

                                       })

}, error => { // end subscription 
  console.log(error)
})


}

create_Add_NullUserFormIndexTopic_AddToService(){

  // this.dialogRef.close(); 

  // create form
  // create  FormFeatureContainer 
   // create a new formIndexTopic and add to current formIndexTopics
  // find formIndexTopicListItem of reference 
  
const newLengthString: string = (this.formService.getForms().length+1).toString()

const new_form_id = newLengthString
const newComponentName = 'formItem'
const newUIID= 'formItem-'+newLengthString
const newOwner = this.user ;
const newDateAndTime = null ;
const newUpdateDateAndTime = null 
const newAllUsersAllowed = false ;
const newUserListAllowed = [];
const newCanBeResharedByOthers = false 
const newNoNeedecipientAndSubject = this.form.noNeedRecipientAndSubject
const newIsFixedSubject = this.form.isFixedSubject
const newIsSharable = this.form.isShareble
const newIsBeenShared = this.form.isBeenShared
const newformSubject = this.form.formSubject
const newRecipientIndex_id = this.form.recipientIndex_id
const newParagraph_ids = this.form.paragraph_ids

const newUpdatedForm = new Form ( new_form_id,
                     newComponentName,
                     newUIID,
                     newOwner ,
                     newDateAndTime,
                     newUpdateDateAndTime,
                     newAllUsersAllowed,
                     newUserListAllowed,
                     newCanBeResharedByOthers,
                     newNoNeedecipientAndSubject ,
                     newIsFixedSubject, 
                     newIsSharable, 
                     newIsBeenShared,
                     newformSubject,
                     newRecipientIndex_id ,
                     newParagraph_ids
                     )

this.formService.addForm(newUpdatedForm, 'personal')


const new_id2 = (this.formFeatureContainerService.getFormFeatureContainers().length+1).toString()
const newComponentName2 = 'formFeatureContainer'
const newUIID2 = 'formFeatureContainer-'+new_id2
const newOwner2 = this.user 
const newAllUsersAllowed2 = false 
const newUserListAllowed2 = []
const newDateAndTime2 = null 
const newIsActive2 = true 
const newIsAccepted2 = true 
const newName2 = 'personal'
const newFormFeatureState2 = true 
const newRecipientIndex_id2 = null 
const newForm_id2 = new_form_id

const newFormFeatureContainer = new FormFeatureContainer (new_id2, newComponentName2, newUIID2, newOwner2, newAllUsersAllowed2, newUserListAllowed2 ,
                                 newDateAndTime2, newIsActive2, newIsAccepted2, newName2, newFormFeatureState2, newRecipientIndex_id2, newForm_id2 )

this.formFeatureContainerService.addFormFeatureContainer(newFormFeatureContainer)

                                 const new_id3 = (this.formIndexTopicService.getFormIndexTopics().length+1).toString()
                                 const newComponentName3 = 'formIndexTopic'
                                 const newUIID3 = 'formIndexTopic-'+new_id3
                                 const newOwner3 = this.user 
                                 const newDateAndTime3 = null
                                 const newAllUsersAllowed3 = false
                                 const newUserListAllowed3 = []
                                 const newIsAccepted3 = true 
                                 const newIsArchived3 = false 
                                 const newName3 = this.indexTopicForm.value.name
                                 const newFormFeatureContainer_ids3 = [new_id2]
                                 
                                 const newFormIndexTopic = new FormIndexTopic ( new_id3, newComponentName3, newUIID3, newOwner3, newDateAndTime3, newAllUsersAllowed3, newUserListAllowed3,
                                        newIsAccepted3, newIsArchived3, newName3, newFormFeatureContainer_ids3 )

this.formIndexTopicService.addFormIndexTopic(newFormIndexTopic) 

const formIndexTopicListItem_OfReference = this.formIndexTopicListItemService.getFormIndexTopicListItem_by_type('personal')

      formIndexTopicListItem_OfReference.formIndexTopic_ids.push(newFormIndexTopic._id)


this._bottomSheetRef.dismiss()
this.router.navigate( ['../form-list/personal-form', newUpdatedForm._id ], { relativeTo: this.route })
  
}

}
