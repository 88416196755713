<!-- <p>single-slider-edit works!</p> -->

<!-- <mat-card>
    <mat-card-content> -->
      <form [formGroup]="singleSliderEditForm" (ngSubmit)="onSubmit()">

      <div class="row">
        <div class="col-xs-8">
            <!-- <label for="name">component name </label> -->
            <input 
            type="text"
            id="name"
            formControlName="name"
            class="form-control"
            >
            <label for="name">component name </label>
           
            <mat-error *ngIf="!singleSliderEditForm.get('name').valid"  >Please enter a valid name, maximum 30 letters </mat-error>
        </div>

        <div class="col-xs-4">
           
                <!-- <button type="button" class="btn btn-primary" (click)="onAddDetails()">  add input   </button>   -->
        </div>
    </div>

  <br>

    <div formArrayName="details">
    <div *ngFor="let detail of getDetails(singleSliderEditForm); let i=index" [formGroupName]="i">



     
  
      <section class="example-section">
        <mat-form-field class="example-margin">
          <mat-label>slider description</mat-label>
          <input matInput 
                 type="text" 
                 id="detailName{{i}}"
                 formControlName="detailName">
        </mat-form-field>
        <mat-form-field class="example-margin">
          <mat-label>slider unit</mat-label>
          <input matInput 
                 type="text" 
                 id="unit{{i}}"
                 formControlName="unit">
        </mat-form-field>
  
        </section>
        
        <section>
        <mat-form-field class="example-margin" appearance="fill">
          <mat-label>Min value</mat-label>
          <input matInput 
                 type="number" 
                 id="min{{i}}"
                 formControlName="min">
        </mat-form-field>
        <mat-form-field class="example-margin" appearance="fill">
          <mat-label>Max value</mat-label>
          <input matInput 
                 type="number"
                 id="max{{i}}"
                 formControlName="max">
        </mat-form-field>
      </section>

      <section>
        <mat-form-field class="example-margin" appearance="fill">
          <mat-label>initial value</mat-label>
          <input matInput 
                 type="number" 
                 id="value{{i}}"
                 formControlName="value">
        </mat-form-field>
        <mat-form-field class="example-margin" appearance="fill">
          <mat-label>Step size</mat-label>
          <input matInput 
                 type="number" 
                 id="step{{i}}}"
                 formControlName="step">
        </mat-form-field>
      </section>
   
  
      <section class="example-section">
      
        <mat-checkbox class="example-margin" 
                      id="showTicks{{i}}"
                      formControlName="showTicks">
                      Show ticks
        </mat-checkbox>

        <mat-checkbox class="example-margin" 
        id="thumblabel"
        formControlName="thumbLabel">
        Show thumb label
        </mat-checkbox>

        <mat-checkbox *ngIf="false"
                      class="example-margin" 
                      id="autoTicks"
                      formControlName="autoTicks" >
                       Auto ticks
        </mat-checkbox>

        <mat-form-field *ngIf="false" class="example-margin" appearance="fill"  >
          <mat-label>Tick interval</mat-label>
          <input matInput 
                 type="number" 
                 id="tickInterval{{i}}"
                 formControName="tickInterval">
        </mat-form-field>
      </section>
  
  
      <section class="example-section">
        <mat-checkbox class="example-margin" 
                      id="vertical" 
                      formControlName="vertical">
                      Vertical
        </mat-checkbox>

        <mat-checkbox class="example-margin" 
                      id="invert" 
                      formControlName="invert">
                      Inverted
        </mat-checkbox>

        <mat-checkbox class="example-margin" 
        id="disabled" 
        formControlName="disabled">
        Disabled
        </mat-checkbox>
      </section>
  
    </div>
    </div>

    <button  type="submit" class="btn btn-danger">submit</button>
    </form>

  
    <!-- </mat-card-content>
  </mat-card> -->


