import { Component, OnInit , Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Feature } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/feature.model';
import { SingleWordInput } from './single-word-input.model';
import { SingleWordInputDetail } from './single-word-input-detail.model';
import { SingleWordInputService } from './single-word-input.service';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { StoreBaseComponentsService } from 'src/app/services/get-and-store-components/store-base-components.service';
import { FeatureContainerListItemService } from '../../../../../paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container-list-item/feature-container-list-items.service';
import { FeatureContainerService } from '../../../../../paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container/feature-container.service';
import { CreateNewFeatureContainerAndAddToFeatureContainerListItemService } from 'src/app/services/shared-services/create-new-feature-container-and-update-feature-container-list-item.service';
import { AdviseIfHttpStoreComponentsCallHasBeenMadeService } from 'src/app/services/other-components-services/advise-if-http-store-components-has-been-made.service';
import { Subscription } from 'rxjs';
import { FeatureContainerListItem } from '../../../../../paragraph-edit/feature-container-list-item.model';
import { FeatureContainer } from '../../../../../paragraph-edit/feature-container.model';

@Component({
  selector: 'app-single-word-input-list',
  templateUrl: './single-word-input-list.component.html',
  styleUrls: ['./single-word-input-list.component.css']
})
export class SingleWordInputListComponent implements OnInit , OnDestroy {

  user: string 

  thisTypeOfComponent: string = 'singleWordInput'


  singleWordInput: SingleWordInput;
  singleWordInputs: SingleWordInput[];

  customCollapsedHeight: string = '20px';
  customExpandedHeight: string = '20px';

  index : number ;

  selectedIndex: number  ;
  @Input() selectedIndex_id: string  ;
  @Input() isParagraphEdit: boolean;
  @Input() weAreInMultiFeatureEdit: boolean;
  
  
  @Output() changeMultiFeatureIndex = new EventEmitter<Feature>()  ;
  @Output() eventIndex: number ;

    feature : Feature ;
    details : SingleWordInputDetail[];

    // this set as false in the DOM allow us to see the buttons to modify the component
    @Output() noNeeded : boolean ; 

    /** limit on the number of features to add */
    createButtonLimit : boolean ;
    singleWordInputLimit = 25 ;

    // @Input() dropId : number ;
    @Input() currentI : number ;

    @ViewChild('tabs') tabGroup: MatTabGroup

    /** properties on the next/previous button */
   nextButtonName : string;
   nextButtonIndex: number ;
   totalSingleWordInputs: number ;
   count: number ;

    /** this serve as new tab index when creating a new button */
    newTabIndex0: number ;
    newTabIndex: number ;

    @Output() deleteSingleFeatureItem = new EventEmitter<number>()


    isLoadingList: boolean 
    isLoading : boolean
    error = null 

    updateOnUserEmail: Subscription

    initialPersonalSingleWordInputHaveBeenStoredSubscription : Subscription

    @Output() holdOnCreateUpdateButtons = new EventEmitter<void>()

    featureContainerPlusParentsHaveBeenStored: Subscription 


  constructor(private singleWordInputService: SingleWordInputService,
              private postComponentsHttpRequestService: PostComponentsHttpRequestService , 
              private userObjectUpdateService: UserObjectUpdateService , 
              private storeBaseComponentsService: StoreBaseComponentsService ,
              private featureContainerListItemService: FeatureContainerListItemService ,
              private featureContainerService: FeatureContainerService , 
              private createNewFeatureContainerAndAddToFeatureContainerListItemService: CreateNewFeatureContainerAndAddToFeatureContainerListItemService ,
              private adviseIfHttpStoreComponentsCallHasBeenMadeService: AdviseIfHttpStoreComponentsCallHasBeenMadeService 
              ) { }

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
  })

  this.isLoading = true 
  this.isLoadingList = true 

  if ( this.user === null ){console.log('is going directly to continue as NULL user'), this.continueInitialOnInit()}
  if ( this.user !== null ){
    if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.toggleSingleWordButtonsHasBeenStored === true  ){ console.log('is going direvtly to continue'),   this.continueInitialOnInit() }
    if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.toggleSingleWordButtonsHasBeenStored === false ){ console.log('is calling http'), this.storeBaseComponentsService.callBaseComponentsListByUserAndType(this.user , 'singleWordInput') }
  }

  this.initialPersonalSingleWordInputHaveBeenStoredSubscription = this.singleWordInputService.initialPersonalSingleWordInputsHaveBeenStored
      .subscribe(()=>{
        this.continueInitialOnInit()
        this.adviseIfHttpStoreComponentsCallHasBeenMadeService.singleWordInputsHasBeenStored = true 
  })

  this.featureContainerPlusParentsHaveBeenStored = this.featureContainerListItemService.newFeatureContainerAndparentsHaveBeenStored
  .subscribe((feature_plus_typeOfComponent: any )=> {

    const currentFeature:Feature = feature_plus_typeOfComponent.feature 
    const currentTypeOfComponent: string = feature_plus_typeOfComponent.featureContainerName

    console.log(feature_plus_typeOfComponent)
    if ( currentTypeOfComponent === this.thisTypeOfComponent){
      this.onEmitToMultiFeatureEditChangedIndex(currentFeature)
    }

})


};

  continueInitialOnInit(){

    console.log('IT S CONTINUING INITIAL ONINIT')
    const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('singleWordInput')
  
    this.singleWordInputs = []
    for ( let i=0; i<personalFeatureContainerListItem.featureContainer_ids.length ; i++ ){
          let currentFeatureContainer: FeatureContainer = this.featureContainerService.getFeatureContainerBy_id(personalFeatureContainerListItem.featureContainer_ids[i])
          this.singleWordInputs.push(this.singleWordInputService.getSingleWordInputBy_id(currentFeatureContainer.componentReference_id))
    }
    console.log(this.singleWordInputs)
  
   this.selectedIndex = this.singleWordInputs.findIndex(x=> x._id === this.selectedIndex_id)
    

    if (this.singleWordInputs.length < this.singleWordInputLimit  ){ this.createButtonLimit = true }
    if (this.singleWordInputs.length >= this.singleWordInputLimit ) { this.createButtonLimit = false };

    this.totalSingleWordInputs = this.singleWordInputs.length;
    this.count = this.selectedIndex;
    this.nextButtonIndex = this.selectedIndex+1 
    this.nextButtonName = this.singleWordInputs[this.selectedIndex].name

    console.log(this.singleWordInputs)

    if ( this.user !== null ){ this.isLoadingList = false , this.isLoading = false }
    if ( this.user === null ){  setTimeout(()=>{ this.isLoadingList = false , this.isLoading = false }, 200 )  }
  
  }



   /** for each click we recreate a new feature  */
onLinkClick($event) {

  this.index = this.tabGroup.selectedIndex ; 
 
  const newOwner = this.user 
  const newIsNew = false
  const newDropId = this.currentI;
  const newFeature_id = this.singleWordInputs[this.index]._id
  const newFeatureName = 'singleWordInput' ;
  const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
 
  this.feature = newFeature;
 
  this.changeMultiFeatureIndex.emit(this.feature)
  this.eventIndex = this.currentI ;
 
  console.log('drop id =', this.currentI)
  console.log(this.feature)
 
   }


   onNextButton(){

    if ( this.tabGroup.selectedIndex+1 === this.totalSingleWordInputs){
      this.index = 0

    const newOwner = this.user   
    const newIsNew = false
    const newDropId = this.currentI;
    const newFeature_id = this.singleWordInputs[this.index]._id
    const newFeatureName = 'singleWordInput' ;
    const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
   
    this.feature = newFeature;
   
    this.changeMultiFeatureIndex.emit(this.feature)
    this.eventIndex = this.currentI ;
   
    console.log('drop id =', this.currentI)
    console.log(this.feature)

    } else if ( this.tabGroup.selectedIndex < this.totalSingleWordInputs){
      this.index = this.tabGroup.selectedIndex+1 ; 

      const newOwner = this.user 
      const newIsNew = false
      const newDropId = this.currentI;
      const newFeature_id = this.singleWordInputs[this.index]._id
      const newFeatureName = 'singleWordInput' ;
      const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
     
      this.feature = newFeature;
     
      this.changeMultiFeatureIndex.emit(this.feature)
      this.eventIndex = this.currentI ;
     
      console.log('drop id =', this.currentI)
      console.log(this.feature)
  
    }

    console.log('this index ',this.index)

   }

   onPreviousButton(){

    if ( this.tabGroup.selectedIndex === 0){
      this.index = this.totalSingleWordInputs-1 ;
 
    const newOwner = this.user 
    const newIsNew = false
    const newDropId = this.currentI;
    const newFeature_id = this.singleWordInputs[this.index]._id
    const newFeatureName = 'singleWordInput' ;
    const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
   
    this.feature = newFeature;
   
    this.changeMultiFeatureIndex.emit(this.feature)
    this.eventIndex = this.currentI ;
   
    console.log('drop id =', this.currentI)
    console.log(this.feature)


  } else if ( this.tabGroup.selectedIndex > 0){
    this.index = this.tabGroup.selectedIndex-1 ; 

      const newOwner = this.user 
      const newIsNew = false
      const newDropId = this.currentI;
      const newFeature_id = this.singleWordInputs[this.index]._id
      const newFeatureName = 'singleWordInput' ;
      const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
     
      this.feature = newFeature;
     
      this.changeMultiFeatureIndex.emit(this.feature)
      this.eventIndex = this.currentI ;
     
      console.log('drop id =', this.currentI)
      console.log(this.feature)
  
    }

    console.log('this index ',this.index)

  }

  onCreate(){

    this.isLoading = true
    this.isLoadingList = true 
    this.createNewSingleWordInput()
  
   }
 

 // create new singleWordInput and its related feature
 createNewSingleWordInput(){
  const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('singleWordInput')
  this.newTabIndex0 = personalFeatureContainerListItem.featureContainer_ids.length+1


   const new_id = 'singleWordIn-'+this.newTabIndex0.toString() ;
   const newComponentName = 'singleWordInput'
    const newUIID = 'singleWordInput-'+this.newTabIndex0.toString()
   const newOwner = this.user ;
   const newDateAndTime = null ;
   const newAllUsersAllowed = false ;
   const newUserListAllowed = [];
   const newName = 'new single word Input '+ this.newTabIndex0.toString()

   const newLabel = 'new label '+this.newTabIndex0.toString()
   const newPlaceholder = 'edit this new word Input '+this.newTabIndex0.toString() ;
   const newHint = 'new hint '+ this.newTabIndex0.toString()
   const newMaxLenght1 = 25 ;

   const newSingleWordInputDetail = new SingleWordInputDetail (newLabel, newPlaceholder, newHint , newMaxLenght1);
   const newSingleWordInputDetails = [newSingleWordInputDetail];
   const newSingleWordInput = new SingleWordInput ( new_id, newComponentName, newUIID, newOwner, newDateAndTime , newAllUsersAllowed, newUserListAllowed, newName, newSingleWordInputDetails);

   if ( this.user === null ){ 

    this.singleWordInputService.addSingleWordInput(newSingleWordInput)

    const newOwner = this.user 
    const newIsNew = false
    const newDropId = this.currentI ;
    const newFeature_id = newSingleWordInput._id
    const newFeatureName = 'singleWordInput' ;
    const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
   
    this.feature = newFeature
    this.createNewFeatureContainerAndAddToFeatureContainerListItemService.createFeatureContainer_toAddOnFeatureContainerListItem(newFeature, this.user)

   }

   if ( this.user !== null ){

   /**
    * add single word input to server data with http request post
    */
   this.postComponentsHttpRequestService.httpStoreComponent(this.user , 'singleWordInput', newSingleWordInput ).subscribe((response:SingleWordInput) => {

    this.singleWordInputService.addSingleWordInput(response)

  const newOwner = this.user 
  const newIsNew = false
  const newDropId = this.currentI ;
  const newFeature_id = response._id
  const newFeatureName = 'singleWordInput' ;
  const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
 
  this.feature = newFeature
  console.log(this.feature)
  this.createNewFeatureContainerAndAddToFeatureContainerListItemService.createFeatureContainer_toAddOnFeatureContainerListItem(newFeature, this.user)

   }, error => {
    console.log(error)

      const errorMessage: string = "we weren't able to create the new component, check the network or try later."
      this.error = errorMessage
      this.isLoadingList = false 
      /**
       *  EMIT and event to paragraphEdit to put update or create buttons for 4,5 sec 
       */
      this.holdOnCreateUpdateButtons.emit()

      /**
       * return to previous set up without the new component after a while to be able to read the message 
       */
      setTimeout(()=>{
        this.error = null 
        this.isLoadingList = false 
        this.isLoading = false 
      },4000)
   })
  }
}

onEmitToMultiFeatureEditChangedIndex(toEmitFeature: Feature ){
  console.log('is emitting changed feature' , toEmitFeature )
  this.changeMultiFeatureIndex.emit(toEmitFeature)
  // once this feature is emitted the multiFeature Edit will change index and this whole 
  // component will restart again
}



 onDeleteSingleWordInputItem(){
   this.deleteSingleFeatureItem.emit(this.currentI)
   console.log('received event at single word input list')
 }

 ngOnDestroy(): void {
  this.updateOnUserEmail.unsubscribe()
  this.initialPersonalSingleWordInputHaveBeenStoredSubscription.unsubscribe()
 }





}
