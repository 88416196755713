


  <div class="row">
    <div class="col-xs-12">

      <div *ngIf="!isClicked" style="color: #3f51b5;">
        {{paragraph.name}}
      </div>

      <div *ngIf="isClicked" style="color: #3f51b5;">
        <h2>{{paragraph.name}}</h2> 
      </div>

    </div>


  </div>

     
      
    
     
   