import { Injectable } from "@angular/core";
import { InitialNullSetupService } from "src/app/services/starting-services/initial-null-setup.service";
import { FeatureContainer } from "../../../feature-container.model";
import { Subject, Observable , Subscription} from 'rxjs';



@Injectable ({
    providedIn: 'root' 
})


export class FeatureContainerService {

    constructor( private initialNullSetupService: InitialNullSetupService , 
                 ){}

    featureContainers: FeatureContainer[] = this.initialNullSetupService.initialNullFeatureContainers


    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.featureContainersChangeBackToNull
    .subscribe((res)=> {
           this.featureContainers = res 
           console.log('updated featureContainers back to null = ', res)
 
       })



    getFeatureContainers(){
        return this.featureContainers
    }

    getFeatureContainerBy_id(_id: string){
        const newFeatureContainer = this.featureContainers.find(
            (res)=>{
                return res._id === _id
            }
        )
        return newFeatureContainer
    }

    addFeatueContainer(featureContainer: FeatureContainer){
        this.featureContainers.push(featureContainer)
        this.updateInitialFeatureContainers(this.featureContainers)
    }

    updateInitialFeatureContainers(featureContainers: FeatureContainer[]){
        this.initialNullSetupService.initialNullFeatureContainers = featureContainers
    }

    



}