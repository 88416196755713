import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Form } from "src/app/forms/form.model";
import { GetComponentsHttpRequestService } from "../gets-services/get-components-http-request.sevice";
import { AdviseIfHttpStoreComponentsCallHasBeenMadeService } from "../other-components-services/advise-if-http-store-components-has-been-made.service";
import { DialogmanagerService } from "../other-components-services/dialogmanager.service";
import { StoreFormItemPlusChildsConfirmationService } from "./store-form-item-plus-childs-confirmation.service";
import { StoreParagraphItemsService } from "./store-paragraph-items.service";



@Injectable ({
    providedIn: 'root' 
})

export class StoreFormItemsService {

    constructor ( private getComponentsHttpRequestService: GetComponentsHttpRequestService ,
                  private dms: DialogmanagerService ,
                  private storeFormItemPlusChildsConfirmationService: StoreFormItemPlusChildsConfirmationService ,
                  private storeParagraphItemsService: StoreParagraphItemsService,
                  private adviseIfHttpStoreComponentsCallHasBeenMadeService: AdviseIfHttpStoreComponentsCallHasBeenMadeService
                  
                ){}

    formItemToAdd = new Subject<any>()  // formItem + typeOfIndex
    
    formItemsListToAdd = new Subject<any>() 
    formItemsResults: any[]
    stopLoadingForm = new Subject<void>() 
    askFormItemToLoginAgainOrStop = new Subject<any>()
    askToDoubleCheckIfFormPlusChildsAlreadyExistOnStore = new Subject<any>()
   

    getAndStorePersonalForms( userEmail: string , typeOfIndex: string , showExistanceInfo: boolean , comingFromFormItem: boolean ){

        this.getComponentsHttpRequestService.httpGetComponentsByType(userEmail, 'formItem')
            .subscribe((formItemResults: any )=> {
                        console.log( formItemResults)

                       const formItemsResults: any[] = formItemResults.formItem 
                       

                    this.scomposeFormsResultsAndAddToFormService( formItemsResults  , typeOfIndex , showExistanceInfo , comingFromFormItem )

            })

    };

    getAndStoreSharedForms( userEmail: string , typeOfIndex: string , showExistanceInfo: boolean , comingFromFormItem: boolean ){

        this.getComponentsHttpRequestService.httpGetUserComponentsByTypeOfIndexAndObjectType(userEmail, typeOfIndex, 'formItem')
            .subscribe((sharedFormItemResults: any )=> {
                        console.log(sharedFormItemResults)

                        const formItemsResults: any[] = sharedFormItemResults 


                this.scomposeFormsResultsAndAddToFormService( formItemsResults , typeOfIndex, showExistanceInfo , comingFromFormItem )
            

            })
    };

    getAndStorePublicFormsByObjectTypeOwnerAndUser( objectType: string , owner: string , userEmail: string ){
        this.getComponentsHttpRequestService.httpGetPublicComponentsByTypeOfObjectAndOwnerAndUser(objectType, owner , userEmail)
            .subscribe((sharedFormItemResults: any )=> {
                console.log(sharedFormItemResults)

                const formItemsResults: any[] = sharedFormItemResults

                this.scomposeFormsResultsAndAddToFormService( formItemsResults , 'public', true , false )
            })
    }
    


    scomposeFormsResultsAndAddToFormService(formsResults: any, typeOfIndex: string , showExistanceInfo: boolean , comingFromFormItem: boolean){

        console.log( formsResults)

        if ( formsResults.length === 0 && showExistanceInfo === true ){ 
                        
           if ( typeOfIndex === 'public' ){this.dms.QuickCommunicationPopUpComponent({quickComment: 'no public forms for this user , atm'})}
            
        } // END IF statement

        const formItemsResults: Form[] = []

        for ( let i=0; i<formsResults.length ; i++){

            const new_id = formsResults[i]._id
            const newComponentName = formsResults[i].componentName
            const newUIID = formsResults[i].UIID
            const newOwner = formsResults[i].owner 
            const newDateAndTime = formsResults[i].dateAndTime
            const newUpdateDateAndTime = formsResults[i].updateDateAndTime
            const newAllUsersAllowed = formsResults[i].allUsersAllowed ;
            const newUserListAllowed = formsResults[i].userListAllowed ;
            const newCanBeResharedByOthers = formsResults[i].canBeResharedByOthers ; 
            const newNoNeedecipientAndSubject = formsResults[i].noNeedRecipientAndSubject
            const newIsFixedSubject = formsResults[i].isFixedSubject 
            const newIsShareble = formsResults[i].isShareble 
            const newIsBeenShared = formsResults[i].isBeenShared ; 
            const newformSubject = formsResults[i].formSubject 
            const newRecipientIndex_id = formsResults[i].recipientIndex_id;
            const newParagraph_ids = formsResults[i].paragraph_ids
    
            const newForm = new Form ( new_id,
                                 newComponentName,
                                 newUIID,
                                 newOwner ,
                                 newDateAndTime,
                                 newUpdateDateAndTime,
                                 newAllUsersAllowed,
                                 newUserListAllowed, 
                                 newCanBeResharedByOthers , 
                                 newNoNeedecipientAndSubject ,
                                 newIsFixedSubject,
                                 newIsShareble,
                                 newIsBeenShared,
                                 newformSubject, 
                                 newRecipientIndex_id ,
                                 newParagraph_ids)
    
            console.log(newForm)

            formItemsResults.push(newForm)

        
        }

        this.formItemsListToAdd.next({ formItemsResults , typeOfIndex , comingFromFormItem }); // sending it to form service + conteiners
        console.log( formItemsResults , typeOfIndex ) 
                    
    }



    getAndStoreSharedFormItemByComponent_id( userEmail: string , typeOfIndex: string , component_id: string ){

        console.log( typeOfIndex , component_id )
        this.getComponentsHttpRequestService.getComponentWithChildsByUserAndTypeOfIndexAndComponent_id(userEmail, typeOfIndex, component_id )
            .subscribe((formItemResult: any[] )=> {
                console.log( formItemResult , formItemResult.length )

                // debugger 
                if ( formItemResult.length === 0 ){
                
                     if ( typeOfIndex === 'public' ){ 
                           
                            if ( userEmail === null ){
                                this.askFormItemToLoginAgainOrStop.next({component_id , typeOfIndex})
                            }

                            // will send the communication dms from form-item once 
                            if (userEmail !== null ){
                                this.dms.QuickCommunicationPopUpComponent({quickComment: "this form it's not public nor has been shared with you , atm" })
                                this.stopLoadingForm.next()
                                // this.askToDoubleCheckIfFormPlusChildsAlreadyExistOnStore.next( { typeOfIndex, component_id })
                            }
                           
                      
                     }
                     if ( typeOfIndex === 'preference' ){ 

                        if ( userEmail === null ){
                            this.askFormItemToLoginAgainOrStop.next({component_id , typeOfIndex})
                        }
                        
                        if (userEmail !== null ){
                            this.dms.QuickCommunicationPopUpComponent({quickComment: "this form it's not public anymore " })
                            this.stopLoadingForm.next()
                            // this.askToDoubleCheckIfFormPlusChildsAlreadyExistOnStore.next( { typeOfIndex, component_id })
                        }
                  
                     }
                     if ( typeOfIndex === 'share' ){ 

                        if ( userEmail === null ){
                            this.askFormItemToLoginAgainOrStop.next({component_id , typeOfIndex})
                        }
                        
                        if (userEmail !== null ){
                            console.log( "is NOT SHARE anymore, checking if it's public ")
                            /**
                             * check if formItem has been moved to public instead 
                             */
                            // this.dms.QuickCommunicationPopUpComponent({quickComment: "this form it's not available anymore" })
                            // this.stopLoadingForm.next()
                            this.checkIfFormHasBeenMadePublicInsteadOfShare( userEmail, 'public' , component_id)
                
                        }
                  
                     }
                }

                if ( formItemResult.length > 0 ){

                    this.storeFormItemPlusChildsConfirmationService.composeFormItemPlusChildsComponent_idsList(formItemResult)
                    this.scomposeFormItemAndChilds(userEmail, formItemResult , typeOfIndex )

                }

            } , error => {
                console.log( 'an error occurred ' , error )

                this.dms.QuickCommunicationPopUpComponent({quickComment: "an error occurred , please check the connection or try later " })
                // this.stopLoadingForm.next()
                this.askToDoubleCheckIfFormPlusChildsAlreadyExistOnStore.next( { typeOfIndex, component_id })
                
            })

    }

    /**
     * this is back up in case we don't realize the formItem has changed status before we download it 
     */
    checkIfFormHasBeenMadePublicInsteadOfShare( userEmail: string , typeOfIndex: string , component_id: string ){

        this.getComponentsHttpRequestService.getComponentWithChildsByUserAndTypeOfIndexAndComponent_id(userEmail, typeOfIndex, component_id )
            .subscribe((formItemResult: any[] )=> {
                console.log( formItemResult , formItemResult.length ) 

                if ( formItemResult.length === 0 ){

                    this.dms.QuickCommunicationPopUpComponent({quickComment: "this form it's not available anymore" })
                    this.stopLoadingForm.next()

                }

                if ( formItemResult.length > 0 ){

                    this.storeFormItemPlusChildsConfirmationService.composeFormItemPlusChildsComponent_idsList(formItemResult)
                    this.scomposeFormItemAndChilds(userEmail, formItemResult , typeOfIndex )

                }



            })

        
    }

    getAndStorePersonalFormItemPlusChildsByComponent_id(userEmail: string , component_id: string , typeOfIndex: string ){

        this.getComponentsHttpRequestService.httpGetUserComponentWithChildsBy_id(component_id)
            .subscribe((formItemResult)=> {
                console.log('this is the personal form item ',formItemResult)

                if (formItemResult !== undefined ){

                    const newFormItemResult: any[] = [formItemResult]
                    /**
                     * store formItem + childs 
                     */
                   this.storeFormItemPlusChildsConfirmationService.composeFormItemPlusChildsComponent_idsList(newFormItemResult)
                   this.scomposeFormItemAndChilds(userEmail, newFormItemResult , typeOfIndex  )
                }

                if ( formItemResult === undefined ){
                    this.dms.QuickCommunicationPopUpComponent({quickComment: "ANN ERROR HAS OCCURRED, CHECK YOUR CONNECTION OR TRY LATER " })
                    // this.stopLoadingForm.next() typeOfIndex : string , form_id: string
                    this.askToDoubleCheckIfFormPlusChildsAlreadyExistOnStore.next( { typeOfIndex, component_id })
                    
                }

            }, error => {
                console.log(error)
                this.dms.QuickCommunicationPopUpComponent({quickComment: 'ANN ERROR HAS OCCURRED, CHECK YOUR CONNECTION OR TRY LATER '})
                // this.stopLoadingForm.next()
                this.askToDoubleCheckIfFormPlusChildsAlreadyExistOnStore.next( { typeOfIndex, component_id })
                
            }
            )

    }





    scomposeFormItemAndChilds( userEmail: string , formItemResult: any , typeOfIndex: string ){
                               console.log( " it's starting to scompose formItem ")

        const new_id = formItemResult[0]._id
        const newComponentName = formItemResult[0].newComponentName
        const newUIID = formItemResult[0].UIID
        const newOwner = formItemResult[0].owner 
        const newDateAndTime = formItemResult[0].dateAndTime
        const newUpdateDateAndTime = formItemResult[0].updateDateAndTime
        const newAllUsersAllowed = formItemResult[0].allUsersAllowed ;
        const newUserListAllowed = formItemResult[0].userListAllowed ;
        const newCanBeResharedByOthers = formItemResult[0].canBeResharedByOthers
        const newNoNeedecipientAndSubject = formItemResult[0].noNeedRecipientAndSubject
        const newIsFixedSubject = formItemResult[0].isFixedSubject
        const newIsSharable = formItemResult[0].isShareble
        const newIsBeenShared = formItemResult[0].isBeenShared ; 
        const newformSubject = formItemResult[0].formSubject 
        const newRecipientIndex_id = formItemResult[0].recipientIndex_id;
        const newParagraph_ids = formItemResult[0].paragraph_ids

        const newParagraphs_plusChilds = formItemResult[0].formItem_childs

  

        const newForm = new Form ( new_id,
                             newComponentName,
                             newUIID,
                             newOwner ,
                             newDateAndTime,
                             newUpdateDateAndTime,
                             newAllUsersAllowed,
                             newUserListAllowed, 
                             newCanBeResharedByOthers , 
                             newNoNeedecipientAndSubject ,
                             newIsFixedSubject , 
                             newIsSharable,
                             newIsBeenShared,
                             newformSubject, 
                             newRecipientIndex_id ,
                             newParagraph_ids)

        console.log(newForm)

       

        // this should wait first the confirmation that paragraphs has been stored 
        this.formItemToAdd.next({ newForm , typeOfIndex })
        this.storeParagraphItemsService.unpackAndDistributeParagraphsAndChildsComponents(newParagraphs_plusChilds) // (newParagraphs_plusChilds , 'formItem' , newForm , typeOfIndex )
       

        if ( userEmail !== null ){
             const index = this.adviseIfHttpStoreComponentsCallHasBeenMadeService.formItemPlusChildsHasBeenStoredList.findIndex(x=> x.form_id === newForm._id )
              const form_id: string = newForm._id
              const hasBeenStored: boolean = true
              const formItemPlusChildsHasBeenStored: any = { form_id, hasBeenStored }
              this.adviseIfHttpStoreComponentsCallHasBeenMadeService.formItemPlusChildsHasBeenStoredList[index] = formItemPlusChildsHasBeenStored
        }

    }






    


}