import { Component, OnInit } from '@angular/core';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';

@Component({
  selector: 'app-single-word-input-edit',
  templateUrl: './single-word-input-edit.component.html',
  styleUrls: ['./single-word-input-edit.component.css']
})
export class SingleWordInputEditComponent implements OnInit {

  user: string

  constructor(  private userObjectUpdateService: UserObjectUpdateService ) { }

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail
  }

}
