import { NgModule } from "@angular/core";
import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { DataConverterComponent } from "./data-converter.component";


@NgModule({
    declarations:[
        DataConverterComponent
    ] ,
    exports: [
        DataConverterComponent
    ],
    imports: [
        LayoutModule,
        CommonModule,
    ]

})

export class DataConverterModule{}