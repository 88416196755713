<!-- <p>new-subject-form works!</p> -->

<div class="ParagraphComponentFrame">
    <form [formGroup]="subjectEditForm" >

    <div  >
      <mat-form-field class="full-width">
        <input
        placeholder="ADD NEW SUBJECT"
        matInput 
        type="text"
        id="name"
        formControlName="name"
        maxlength="100">
      </mat-form-field>
    </div>
           
    </form>

  </div>

