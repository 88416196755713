import { Component, OnInit, } from '@angular/core';

@Component({
  selector: 'app-recipient-index-list',
  templateUrl: './recipient-index-list.component.html',
  styleUrls: ['./recipient-index-list.component.css']
})
export class RecipientIndexListComponent implements OnInit {

 
  constructor(){}

  ngOnInit(){}




}
