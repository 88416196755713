import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendar-range-item',
  templateUrl: './calendar-range-item.component.html',
  styleUrls: ['./calendar-range-item.component.css']
})
export class CalendarRangeItemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
