<!-- <p>fix-layout-edit-chart works!</p> -->

<div class="row">
    <div class="col-xs-12">
        <form [formGroup]="fixLayoutEditChartForm">

            <div class="row">
                <mat-form-field>
                    <mat-label>share %</mat-label> 
                    <input 
                       matInput 
                       placeholder='enter number'
                       type="number"
                       id="share"
                       formControlName="share" >
                    <!-- <mat-hint > here is the "hint"  </mat-hint> -->
                </mat-form-field>
            </div>
           
            <div class="row">
                <mat-form-field>
                    <mat-label>total</mat-label> 
                    <input 
                       matInput 
                       placeholder='enter number'
                       type="number"
                       id="total"
                       formControlName="total" >
                    <mat-hint > total  </mat-hint>
                </mat-form-field>
            </div>

            <br>

            <div class="row">
                <button (click)="onSubmitChart()" class="btn btn-primary">confirm</button>
            </div>

        </form>
    </div>
</div>