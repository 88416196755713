import { Component, OnInit , Input} from '@angular/core';
import { Feature } from '../../feature.model';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.css']
})
export class FormInputComponent implements OnInit {

  constructor() { }

  trial = 'ex this is a trial' ;
  maximun: number = 7 ; 

  @Input() feature: Feature
  

  ngOnInit(): void {
  }

}
