import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef , MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { clone } from 'lodash'
import { Paragraph } from 'src/app/forms/form-list/form-item/paragraph/paragraph.model';

@Component({
  selector: 'app-duplicate-feature-paragraph-form-bottom-sheet',
  templateUrl: './duplicate-feature-paragraph-form-bottom-sheet.component.html',
  styleUrls: ['./duplicate-feature-paragraph-form-bottom-sheet.component.css']
})
export class DuplicateFeatureParagraphFormBottomSheetComponent implements OnInit {

  constructor( private _bottomSheetRef: MatBottomSheetRef<DuplicateFeatureParagraphFormBottomSheetComponent> , 
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any ) { }

  ngOnInit(): void {

    console.log(this.data)
    const newObject: Paragraph = clone(this.data.paragraph)

    newObject.name = "new name"
    newObject._id = null 
    newObject.UIID = null 

    console.log("new duplicate object => ",newObject)
  }

}
