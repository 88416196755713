import { Component, OnInit , ViewChild } from '@angular/core';
import * as XLSX from 'xlsx';
import { read, utils } from 'xlsx';

@Component({
  selector: 'app-data-converter',
  templateUrl: './data-converter.component.html',
  styleUrls: ['./data-converter.component.css']
})
export class DataConverterComponent implements OnInit {

  jsonArray: string;
  @ViewChild('fileInput') fileInput: any;

  constructor() { }

  ngOnInit(): void {
  }

  data: any[] = [];
  selectedFile: File;

  onFileSelected(event: any) {
    console.log(event, event.target.files[0])
    this.selectedFile = event.target.files[0];
    this.convertToJson()
  }

  convertToJson() {
    if (this.selectedFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const workbook = XLSX.read(e.target.result, { type: 'binary' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        this.data = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        console.log(this.data); // You can do something with the data here
        
        this.getColumnData(this.data)

      };
      reader.readAsBinaryString(this.selectedFile);
    } else {
      console.log('No file selected');
    }
  }

  getColumnData(data: any[]): any[][] {
    const columns = Object.keys(data[0]);
    const columnData: any[][] = [];
    for (const column of columns) {
      const columnValues = data.map(row => row[column]);
      columnData.push(columnValues);
    }
    console.log(columnData)


    return columnData;
    
  };


}