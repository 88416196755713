import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { CalculatorMixTable } from './calculator-mix-table.model';
import { DescriptionFrame } from './description-frame.model';
import { DescriptionPart } from './description-part.model';
import { CalculatorMixTableService } from './calculator-mix-table.service';
import { MatTabGroup } from '@angular/material/tabs';
import { Feature } from '../../feature.model';
import { Observable, Subscription, Subject } from 'rxjs';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { CreateNewFeatureContainerAndAddToFeatureContainerListItemService } from 'src/app/services/shared-services/create-new-feature-container-and-update-feature-container-list-item.service';
import { AdviseIfHttpStoreComponentsCallHasBeenMadeService } from 'src/app/services/other-components-services/advise-if-http-store-components-has-been-made.service';
import { StoreBaseComponentsService } from 'src/app/services/get-and-store-components/store-base-components.service';
import { FeatureContainerListItemService } from '../../../../paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container-list-item/feature-container-list-items.service';
import { FeatureContainerListItem } from '../../../../paragraph-edit/feature-container-list-item.model';
import { FeatureContainer } from '../../../../paragraph-edit/feature-container.model';
import { FeatureContainerService } from '../../../../paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container/feature-container.service';

@Component({
  selector: 'app-calculator-mix-table-list',
  templateUrl: './calculator-mix-table-list.component.html',
  styleUrls: ['./calculator-mix-table-list.component.css']
})
export class CalculatorMixTableListComponent implements OnInit , OnDestroy {

  user: string 

  thisTypeOfComponent: string = 'calculatorMixTable'


  calculatorMixTable: CalculatorMixTable;
  calculatorMixTables: CalculatorMixTable[];

  descriptionFrame: DescriptionFrame;
  descriptionPart: DescriptionPart;


  // these tabsIndex are to create and re-position focus once created a new tab
  newTabIndex0: number ;
  newtabIndex : number ;
  customCollapsedHeight: string = '20px';
  customExpandedHeight: string = '20px';

  index : number ;

 selectedIndex: number  ;
 findSelectedIndex: number
//  @Input() selectedIndex_id: string

@Input() feature_from_paragraphEdit: Feature 
 @Output() changeIndex = new EventEmitter<Feature>()  ;
 @Output() eventIndex: number ;

 @Output() holdOnCreateUpdateButtons = new EventEmitter<void>()

   feature : Feature ;

   // this set as false in the DOM allow us to see the buttons to modify the component
   @Output() noNeeded : boolean ; 

   /** limit on the number of features to add */
   createButtonLimit : boolean ;
   buttonsLimit = 25 ;

   @Input() dropId : number ;
   @Input() currentI : number ;
   @Input() isParagraphEdit: boolean;
  


   /**
    * receiving subscription from ParagraphEdit to edit the component 
    */
   subscriptionToEditComponentItemIsOn: boolean

   currentFeature: Feature
   @Input() editFeature: Observable<void> 


   @ViewChild('tabs') tabGroup: MatTabGroup

   /**
    * click and receive the results from paragraph Edit
    */
   editFeatureEventFromParagraphEdit: Subscription;
 clickOnEditFeatureItem: Subject<Feature> = new Subject<Feature>()


  receiveFeatureInfoFromParagraphEdit: Subscription; 
  @Input() collectFeatureId: Observable<Feature>

  isLoading: boolean
  isLoadingList: boolean
  calculatorMixTablesHasBeenStoredSubscription: Subscription 

  featureContainerPlusParentsHaveBeenStored: Subscription 

  error = null 

  updateOnUserEmail : Subscription

  matToolTipMessageOnCreateNew: string 


  

  constructor(private calculatorMixTableService: CalculatorMixTableService, 
              private postComponentsHttpRequestService: PostComponentsHttpRequestService,
              private userObjectUpdateService: UserObjectUpdateService , 
              private storeBaseComponentsService: StoreBaseComponentsService ,
              private featureContainerService: FeatureContainerService ,
              private featureContainerListItemService: FeatureContainerListItemService ,
              private adviseIfHttpStoreComponentsCallHasBeenMadeService:AdviseIfHttpStoreComponentsCallHasBeenMadeService , 
              private createNewFeatureContainerAndAddToFeatureContainerListItemService : CreateNewFeatureContainerAndAddToFeatureContainerListItemService ) { }

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
    .subscribe((updatedUserEmail)=>{
     this.user = updatedUserEmail
     console.log('updated USER = ', this.user)
})

console.log(this.user )

this.subscriptionToEditComponentItemIsOn = false
this.isLoading = true 
this.isLoadingList = true 

if ( this.user === null ){console.log('is going directly to continue as NULL user'), this.continueInitialOnInit()}
if ( this.user !== null ){
  if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.calculatorMixTablesHasBeenStored === true  ){ console.log('is going direvtly to continue'),   this.continueInitialOnInit() }
  if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.calculatorMixTablesHasBeenStored === false ){ console.log('is calling http'), this.storeBaseComponentsService.callBaseComponentsListByUserAndType(this.user , 'calculatorMixTable') }
}

this.editFeatureEventFromParagraphEdit = this.editFeature.subscribe(() => this.onEditFeatureItem() )
this.receiveFeatureInfoFromParagraphEdit = this.collectFeatureId.subscribe((data)=> this.updateCurrentFeature(data))

this.calculatorMixTablesHasBeenStoredSubscription = this.calculatorMixTableService.initialPersonalCalculatorMixtablesHaveBeenStored
    .subscribe(()=> { 
     this.continueInitialOnInit()
     this.adviseIfHttpStoreComponentsCallHasBeenMadeService.calculatorMixTablesHasBeenStored = true 
})

this.featureContainerPlusParentsHaveBeenStored = this.featureContainerListItemService.newFeatureContainerAndparentsHaveBeenStored
    .subscribe((feature_plus_typeOfComponent: any )=> {

      const currentFeature:Feature = feature_plus_typeOfComponent.feature 
      const currentTypeOfComponent: string = feature_plus_typeOfComponent.featureContainerName

      console.log(feature_plus_typeOfComponent)
      if ( currentTypeOfComponent === this.thisTypeOfComponent){
        this.onEmitToParagraphEditChangedIndex(currentFeature)
      }

})


   
  }

  continueInitialOnInit(){

    console.log('IT S CONTINUING INITIAL ONINIT')
    const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('calculatorMixTable')

    this.calculatorMixTables = []
    for ( let i=0; i<personalFeatureContainerListItem.featureContainer_ids.length ; i++ ){
          let currentFeatureContainer: FeatureContainer = this.featureContainerService.getFeatureContainerBy_id(personalFeatureContainerListItem.featureContainer_ids[i])
          this.calculatorMixTables.push(this.calculatorMixTableService.getCalculatorMixTable_by_id(currentFeatureContainer.componentReference_id))
    }
    console.log(this.calculatorMixTables)

    this.selectedIndex = this.calculatorMixTables.findIndex(x => x._id === this.feature_from_paragraphEdit.feature_id)

    this.matToolTipMessageOnCreateNew = " CREATE A COPY OF  '1 - "+this.calculatorMixTables[0].name+"'"

    if (this.calculatorMixTables.length < this.buttonsLimit){ this.createButtonLimit = true }
    if (this.calculatorMixTables.length >= this.buttonsLimit){this.createButtonLimit = false}

    if (this.user === null ){ setTimeout(()=>{ this.isLoading = false , this.isLoadingList = false },200) }
    if (this.user !== null ){ this.isLoading = false , this.isLoadingList = false  }
   

  }



    /** for each click we recreate a new feature  */
onLinkClick($event) {

  this.index = this.tabGroup.selectedIndex ; 
 
  const newOwner = this.user; 
  const newIsNew = false;
  const newDropId = this.currentI;
  const newFeature_id = this.calculatorMixTables[this.index]._id;
  const newFeatureName = 'calculatorMixTable' ;
  const newFeature = new Feature ( newOwner, newIsNew,  newDropId  , newFeature_id, newFeatureName) ;
 
  this.feature = newFeature;
 
  this.changeIndex.emit(this.feature)
  this.eventIndex = this.currentI ;
 
  console.log('drop id =', this.currentI)
  console.log(this.feature)
 
}

onCreate(){
  this.isLoading = true 
  this.isLoadingList = true 

  this.onCreateNew()
}

   onCreateNew(){

    const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('calculatorMixTable')
    this.newTabIndex0 = personalFeatureContainerListItem.featureContainer_ids.length+1
  
    const new_id = 'calculatorMixTa'+this.newTabIndex0.toString() ;
    const newComponentName = 'calculatorMixTable'
    const newUIID ='calculatorMixTable-'+this.newTabIndex0.toString()
    const newOwner = this.user; 
    const newAllUsersAllowed = false
    const newUserListAllowed = []
    const newDateAndTime = null; 
    const newName =  'new tab '+ this.newTabIndex0.toString() ;

    const newDescriptionFrameCategoryName = 'edit category name'+ this.newTabIndex0.toString()
    const newDescriptionFrameDescriptionPart = new DescriptionPart (newDescriptionFrameCategoryName, 'edit description', null , null )

    const newDescriptionFrame =  new  DescriptionFrame ( [newDescriptionFrameCategoryName] , [newDescriptionFrameDescriptionPart] );
    const newCalculatorMixTable = new CalculatorMixTable ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed, newDateAndTime, newName , newDescriptionFrame );

    if ( this.user === null ){

      this.calculatorMixTableService.onAddNew(newCalculatorMixTable);
     
      const newIsNew = false;
      const newDropId = this.currentI ;
      const newFeature_id = newCalculatorMixTable._id;
      const newFeatureName = 'calculatorMixTable' ;
      const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
     
      this.feature = newFeature

      this.createNewFeatureContainerAndAddToFeatureContainerListItemService.createFeatureContainer_toAddOnFeatureContainerListItem(newFeature, this.user)

    }

    if (this.user !== null ){

    /**
 * postComponentHttp service create new component 
 */
    this.postComponentsHttpRequestService.httpStoreComponent(this.user, 'calculatorMixTable', newCalculatorMixTable )
        .subscribe((response: CalculatorMixTable)=> {

      this.calculatorMixTableService.onAddNew(response);
     
      const newIsNew = false;
      const newDropId = this.currentI ;
      const newFeature_id = response._id;
      const newFeatureName = 'calculatorMixTable' ;
      const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
     
      this.feature = newFeature

      this.createNewFeatureContainerAndAddToFeatureContainerListItemService.createFeatureContainer_toAddOnFeatureContainerListItem(newFeature, this.user)

    }, error => {
      console.log(error)

      const errorMessage: string = "we weren't able to create the new component, check the network or try later."
      this.error = errorMessage
      this.isLoadingList = false 
      /**
       *  EMIT and event to paragraphEdit to put update or create buttons for 4,5 sec 
       */
      this.holdOnCreateUpdateButtons.emit()

      /**
       * return to previous set up without the new component after a while to be able to read the message 
       */
      setTimeout(()=>{
        this.error = null 
        this.isLoadingList = false 
        this.isLoading = false 
      },4000)
    }
    )
  }
}

onEmitToParagraphEditChangedIndex(toEmitFeature: Feature ){
  console.log('is emitting changed feature')
  this.changeIndex.emit(toEmitFeature)
  // once this feature is emitted the paragraphEdit will change index and this whole 
  // component will restart again
}

  /**
   * 
   * @param data feature that is been transmitted from paragraphEdit to check which of the 
   * features list has to pass the subscription to the feature item to be edited 
   */
   updateCurrentFeature(data){
    this.currentFeature = data;
    console.log(this.currentFeature)
    }
  
    onEditFeatureItem(){
      console.log('is opening another dialog from list component ')
      console.log(this.currentI)
      console.log(this.currentFeature)
  
        if( this.currentFeature.dropId === this.currentI){
          this.clickOnEditFeatureItem.next(this.currentFeature)
         }
     
      console.log('is arrived')
    
    }
  
    ngOnDestroy(){

      this.updateOnUserEmail.unsubscribe()
      this.receiveFeatureInfoFromParagraphEdit.unsubscribe();
      this.editFeatureEventFromParagraphEdit.unsubscribe();
  
    }
  


}
