<h2 style="color: #3f51b5">{{decicatedPhrase}}</h2>

<div class="col-12">
    <div class="row">
        <div class="col-6">
            <button class="btn btn-primary"
                    (click)="onAccept()"
                    type="button">
                accept
            </button>
        </div>
        <div class="col-6">
            <button class="btn btn-danger"
                    (click)="onDecline()"
                    type="button">
                decline
            </button>

        </div>
    </div>
</div>


