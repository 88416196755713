import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { FixTextDetail } from '../../../texts/fix-text-list/fix-text-detail.model';
import { RangeInfo } from '../range-info.model';
import { Observable, Subscription } from 'rxjs';
import { RangeInfoResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/shared-calendar-status-result/range-info-result.model';
import { FormService } from 'src/app/forms/form.service';

@Component({
  selector: 'app-inner-text-item',
  templateUrl: './inner-text-item.component.html',
  styleUrls: ['./inner-text-item.component.css']
})
export class InnerTextItemComponent implements OnInit , OnDestroy {


  @Input() rangeInfo: RangeInfo
  @Input() rangeInfo_index: number
  @Input() isParagraphEdit: boolean 

  @Input() changeSelectedDatesView: Observable<void>
  detectChangeOfSelectedDates: Subscription

  @Input() canUseIt: boolean 
  
  innerTextItem: FixTextDetail

  rangeInfoResult: RangeInfoResult


  @Output() emitSelectedValues = new EventEmitter<any>()
  // @Output() isUpdatingSharedCalendarStatusEvent = new EventEmitter<void>()

  constructor( private ngZone: NgZone , 
               private cdr: ChangeDetectorRef
               ) { }

  ngOnInit(): void {

    console.log('this rangeInfo text can be used => ', this.canUseIt)

    this.detectChangeOfSelectedDates = this.changeSelectedDatesView
        .subscribe(()=> {
          this.updateSelectedDatesViewInfo()
    })

  }

  updateSelectedDatesViewInfo(){
    this.ngZone.run(()=>{
      this.rangeInfo = this.rangeInfo
    })
    this.cdr.detectChanges()

  }



  onSend(){
    
    const newRangeInfo = this.rangeInfo
    const rangeInfo_index = this.rangeInfo_index 
  
    this.emitSelectedValues.emit({ rangeInfo_index, newRangeInfo })
 
  }


  ngOnDestroy(): void {
      this.detectChangeOfSelectedDates?.unsubscribe()
  }

}
