import { PartecipantGroup } from "./partecipants.model";
import { Recipient } from "../forms/form-list/form-item/recipient/recipient.model";

export class  Chat {

    constructor (public _id: string ,
                 public owner: string ,
                 public allUsersAllowed: boolean,
                 public userListAllowed: string[],
                 public isVisible: boolean, 
                 public dateAndTime: string , 
                 public updateDateAndTime: string,
                 public isNewChat: boolean, 
                 public isBeenUpdated: boolean,
                 public isSelected: boolean, 
                 public subject: string 
                 ){}
}

// when receiving  chat , i need to confirm first 
