import { Component, OnInit , Inject} from '@angular/core';
import { FixLayout } from "../fix-layout.model"
import { FixLayoutTableTD } from '../fix-layout-table-td.model';
import { FixLayoutService} from "../fix-layout.service"
import { FormGroup, FormControl, FormBuilder, Validators, FormArray} from '@angular/forms';
import { MatDialogRef , MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-fix-layout-edit-number',
  templateUrl: './fix-layout-edit-number.component.html',
  styleUrls: ['./fix-layout-edit-number.component.css']
})
export class FixLayoutEditNumberComponent implements OnInit {

  fixLayoutEditNumberForm: FormGroup 

  i = this.editData.indexI
  j = this.editData.indexJ
  editFixLayout: FixLayout
             
  editFixLayoutTableTD: FixLayoutTableTD

  label: string 
  number: number


  constructor( public dialogRef: MatDialogRef<FixLayoutEditNumberComponent>,
               @Inject(MAT_DIALOG_DATA) public editData: any) { }

 

  ngOnInit(): void {

    this.editFixLayout = this.editData.fixLayout
    console.log(this.editFixLayout)

    this.editFixLayoutTableTD = this.editFixLayout.fixLayoutTableTRs[this.i].fixLayoutTableTDs[this.j]
    console.log(this.editFixLayoutTableTD)

    this.label = this.editFixLayout.fixLayoutTableTRs[this.i].fixLayoutTableTDs[this.j].tableTDDetailNumberLabel
    this.number = this.editFixLayout.fixLayoutTableTRs[this.i].fixLayoutTableTDs[this.j].tableTDDetailNumber
  
  this.fixLayoutEditNumberForm = new FormGroup ({
    label: new FormControl (this.label),
    number: new FormControl (this.number)
  })
  
  
  }

  onSumbitNumber(){
    console.log(this.fixLayoutEditNumberForm)
    if (this.fixLayoutEditNumberForm.invalid){
      return
    }
    this.dialogRef.close(this.fixLayoutEditNumberForm.value),
    this.editData.value
  }


}
