<!-- <p>calculator-mix-table-item works!</p> -->

<br>
<div class="row">
    <div class="col-xs-12">
       
        <form [formGroup]="calculatorMixTableGroup">

            <table  class="full-width" >

                <div >
                <tbody formArrayName="DescriptionFrameRows">
               
                <tr *ngFor="let descriptionRow of calculatorMixTableGroup.controls.DescriptionFrameRows['controls']; let i=index" [formGroupName]="i">

                                            <td>
                                                <mat-form-field style="width: 100px" appearance="outline">
                                                    <mat-label> Q.ty </mat-label>
                                                    <input
                                                     matInput
                                                     formControlName="quantity"
                                                     type="number"
                                                     #quantity
                                                     (keyup)="getQuantityIdValue( i, quantity.value)">
                                                </mat-form-field>
                                            </td>
                                          
                                            <td>
                                                <mat-form-field [style.width.%]="100" appearance="outline">
                                                    <mat-label> category </mat-label>
                                                    <mat-select id="category{{i}}" formControlName="category" (selectionChange)="selectCategory( i, $event)">
                                                        <mat-option *ngFor="let category of categoryListGroup[i]" [value]="category" >
                                                            {{category}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </td>
                                         
                                            <td>
                                                <mat-form-field style="width: 500px" appearance="outline">
                                                    <mat-label> description </mat-label>
                                                    <mat-select  id="description{{i}}" 
                                                                 formControlName="description" 
                                                                 (selectionChange)="selectDescriptions( i, $event)" >
                                                        <mat-option  *ngFor="let description of descriptionPartListGroup[i]" [value]="description">
                                                            {{description.description}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </td>
                                       
                                        <td>
                                            <mat-form-field style="width: 100px" appearance="outline">
                                                <mat-label> PL </mat-label>
                                                <input 
                                                       type="number" 
                                                       matInput 
                                                       formControlName="priceList"
                                                       #priceList
                                                       (keyup)="getPricelistIdValue( i, priceList.value)">
                                            </mat-form-field>
                                        </td>
                                      
                                        <td>
                                            <mat-form-field style="width: 50px" appearance="outline">
                                                <mat-label> discount </mat-label>
                                                <input
                                                 matInput
                                                 formControlName="discount"
                                                 type="number"
                                                 #discount
                                                 (keyup)="getDiscountIdValue( i, discount.value)">
                                            </mat-form-field>

                                        </td>
                                     
                                        <td>
                                            <mat-form-field style="width: 100px" appearance="outline">
                                                <mat-label> net price </mat-label>
                                                <input
                                                 matInput
                                                 formControlName="netPrice"
                                                 type="number"
                                                 #netPrice
                                                 (keyup)="getNetpriceIdValue( i, netPrice.value)">
                                            </mat-form-field>

                                        </td>
                                     
                                        <td>
                                            <mat-form-field style="width: 150px" appearance="outline">
                                                <mat-label> sub total </mat-label>
                                                <input
                                                 matInput
                                                 formControlName="subTotal"
                                                 type="number"
                                                 #subTotal
                                                 (keyup)="getSubtotalIdValue( i, subTotal.value)">
                                            </mat-form-field>

                                        </td>

                                        <td *ngIf="minimumRowExist" >
                                            <button  (click)="deleteRow(i)" class="btn btn-danger"> delete row</button>
                                        </td>
                        

            </tr>
                </tbody>
            </div>

            <tfoot>

            <tr>
                <td *ngIf="maxRowExist">
                    <button type="button" class="btn btn-primary" (click)="addNewRow()"> + </button>
                </td>
                <td>
                    <mat-form-field style="width: 150px" appearance="outline">
                        <mat-label> total </mat-label>
                        <input
                         matInput
                         formControlName="total"
                         type="number"
                         #total
                         (keyup)="getTotalIdValue()">
                    </mat-form-field>

                </td>
            </tr>
            </tfoot>

            </table>

            <br>


        </form>

    </div>
</div>