import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { HeaderBadger } from './header-badger.model';
import { Badges } from './badges.model';
import { Header } from './header.model';



@Injectable({
  providedIn: 'root'
})
export class HeaderBadgerService { 

    /**
     * export class HeaderBadger {

    constructor ( public drappBadges:     Badges,
                  public paragraphBadges: Badges ,
                  public formBadges:      Badges,
                  public resultBadges:    Badges,
                  public groupBadges:     Badges,
                  public loginBadges:     Badges   ){}

    export class Badges {

    constructor( public badgeActive: boolean , public badgeQty: number  ){}
}
     */

public  headerBadger : HeaderBadger = new HeaderBadger (  new Badges ( true,  3 ),
                                                          new Badges ( true,  1 ),
                                                          new Badges ( false, 0 ),
                                                          new Badges ( true,  8 ), 
                                                          new Badges ( true,  8 ),
                                                          new Badges ( false, 0 )
                                                        )
                                      

getHeaderBadger(){
    return this.headerBadger 
};




}
