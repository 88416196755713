import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { DatePipe } from '@angular/common';
import { FormResultsService } from '../form-results.service';
import { BreakpointObserver , Breakpoints} from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';


interface TypeMessage_Mail_FromTo {
  type: string ,
  fromTo: string[]
}

interface Subject_Paragraph_Title {
  type: string , 
  title: string 
}

interface DateType_DateInfo {
  dateType: string , 
  dateInfo: string[]
}

interface GroupName {
  userChatRoomId: string 
  name: string,
}

interface FinalRoutingCall {
  messageMailInfos: TypeMessage_Mail_FromTo , 
  dateInfos: DateType_DateInfo, 
  titleInfo: Subject_Paragraph_Title ,
  groupInfo: GroupName
}




@Component({
  selector: 'app-form-results-filter',
  templateUrl: './form-results-filter.component.html',
  styleUrls: ['./form-results-filter.component.css']
})
export class FormResultsFilterComponent implements OnInit , OnDestroy {

  user : string 

  finalRoutingCall: FinalRoutingCall

  isRestarting: boolean = false 

  receivedMessageIsDeselected: boolean = false
  sentMessageIsDeselected: boolean = false
  sentMailIsDeselected: boolean = false
  groupIsDeselected: boolean = true
  subjectIsDeselected: boolean = true 
  paragraphIsDeselected: boolean = true 
  fixIsDeselected: boolean  = true 
  rangeIsDeselected: boolean = true 

  filterDateIsVisible: boolean = true 
  filterSubjectParagraphIsVisible: boolean = true  

  isSubmitted: boolean ;
  hasAtLeastOneSearchParameterSelected: boolean 
   

  updateOnUserEmail: Subscription

  subscribeToRestartFormResultIndexList: Subscription
  @Input() resetFormResultIndexList: Observable<void>

  subscribeToSwitchToIsSubmittedOnResultListIsSelectedFromRouting
  @Input() switchToIsSubmittedOnResultListIsSelectedFromRouting: Observable<void>

 @Output() closeSidenavDrawerOnSubmit= new EventEmitter<void>()
 @Output() closeSidenavDrawerOnClear= new EventEmitter<void>()

 resetFixDateFilter_Event = new Subject<void>()
 updateIsSubmitToTrueToChildsWithInputs = new Subject<void>()

 subscriptionToFormResultList_onCheckUpdateFilterStatus: Subscription

 updatedSubject: string 
 updatedDate: string[]

 messagePhone: string = "create multiple reports shortcuts by using fix date filters like TODAY, LAST WEEK or 12 MONTH AGO and add them to your home screen"




  constructor( private route: ActivatedRoute, 
               private breakpointObserver: BreakpointObserver,
               private userObjectUpdateService: UserObjectUpdateService , 
               private datePipe: DatePipe , 
               private formResultsService: FormResultsService  ,
               private router: Router,
               private titleService: Title ) { }

  
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
    })

    this.isSubmitted = false 

    this.updatedSubject = ''
    this.updatedDate = []

    const initialDate: string = "2023-05-01T00:00:00"
    const upToTodayDate: string = this.datePipe.transform( new Date() , "yyyy-MM-dd")+"T23:59:59"

    const initialEmptyMessageMailInfos:  TypeMessage_Mail_FromTo = { type: null , fromTo: null }
    const initialDateInfos: DateType_DateInfo = { dateType: 'range' , dateInfo: [initialDate, upToTodayDate] }
    const initialTitleInfo: Subject_Paragraph_Title = { type: null , title: null }
    const initialGroupInfo: GroupName = { userChatRoomId: null , name: null  }
    /**
     * this finalRoutingCall has to be upfdated at any sub-component update 
     */
    this.finalRoutingCall = { messageMailInfos: initialEmptyMessageMailInfos, dateInfos: initialDateInfos, titleInfo: initialTitleInfo , groupInfo: initialGroupInfo }
    console.log( this.finalRoutingCall )

  this.onCheckingIfAtLeastOneparameterIsON()

    // subscription from formResults to restart this component
  this.subscribeToRestartFormResultIndexList = this.resetFormResultIndexList
       .subscribe(()=> {
        this.onResetFormFilter()
  })

  this.subscribeToSwitchToIsSubmittedOnResultListIsSelectedFromRouting = this.switchToIsSubmittedOnResultListIsSelectedFromRouting
      .subscribe(()=>{
       this.onSwitchSubmitButtonToClear()
  })

  this.subscriptionToFormResultList_onCheckUpdateFilterStatus = this.formResultsService.checkUpdateFilterResult_fromResultList
      .subscribe((res: any )=>{
        console.log(res)

        const updatedTitle: string = res.subject_Paragraph_Title.title
        const updatedDate: string[] = res.dateType_DateInfo.dateInfo

        this.adjustStartingSubjectAndDateRange_onLoginPopUp(updatedTitle , updatedDate)
  })



  }


  adjustStartingSubjectAndDateRange_onLoginPopUp(updatedTitle: string , updatedDate: string[]){

    this.updatedSubject = decodeURI(updatedTitle)
    this.updatedDate = updatedDate
  }

  onReceivedMessageIsDeselected($event){
    this.receivedMessageIsDeselected = $event 
    console.log( 'received messages deselected = ',this.receivedMessageIsDeselected)
    this.checkTypeOfMessage()
  }

  onSentMessageIsDeselected($event){
    this.sentMessageIsDeselected = $event 
    console.log('sent messages deselected = ',this.sentMessageIsDeselected)
    this.checkTypeOfMessage()
  }

  onSentMailIsDeselected($event){
    this.sentMailIsDeselected = $event 
    if ( this.sentMailIsDeselected === false ){ this.groupIsDeselected = true }
    console.log('sent mail deselected = ', this.sentMailIsDeselected)
    this.checkTypeOfMessage()
  }

  onGroupIsDeselected($event){
    this.groupIsDeselected = $event
    if ( this.groupIsDeselected === false){ 
         this.sentMailIsDeselected = true 
         const groupName = { userChatRoomId: null , name: null }
         this.finalRoutingCall.groupInfo = groupName
    }
    
    if ( this.groupIsDeselected === true ){
         const groupName = { userChatRoomId: null , name: null }
         this.finalRoutingCall.groupInfo = groupName
    }
    console.log('group is deselected = ', this.groupIsDeselected)
    /**
     * interface GroupName {
  isSelected: boolean , 
  name: string
}
    
     */
    this.checkTypeOfMessage()
    /**
     * if this is on, we turn sentmailIsDeselected = true because we only look for message group 
     */

  }

  /**
   * check the 3 combined type of message/email result and give a unique name for message/email type 
   * between ALL/MESSAGE/EMAIL 
   */
  checkTypeOfMessage(){
    /**
     * receivedMessageIsDeselected: boolean = true
  sentMessageIsDeselected: boolean = true
  sentMailIsDeselected: boolean = true
     */
    if ( this.receivedMessageIsDeselected === false && this.sentMessageIsDeselected === false && this.sentMailIsDeselected === false  ){
         const typeOfMessageEmail: string = 'all'
         const newToFrom: string[] = ['all', 'sent'] 
         const messageMailInfos:  TypeMessage_Mail_FromTo = { type: typeOfMessageEmail , fromTo: newToFrom }

         this.finalRoutingCall.messageMailInfos = messageMailInfos
         console.log(this.finalRoutingCall)
         this.onCheckingIfAtLeastOneparameterIsON()
    }

    if ( this.receivedMessageIsDeselected === true && this.sentMessageIsDeselected === true && this.sentMailIsDeselected === false  ){
         const typeOfMessageEmail: string = 'email'
         const newToFrom: string[] = [ null , 'sent']
         const messageMailInfos:  TypeMessage_Mail_FromTo = { type: typeOfMessageEmail , fromTo: newToFrom }

         this.finalRoutingCall.messageMailInfos = messageMailInfos
         console.log(this.finalRoutingCall)
         this.onCheckingIfAtLeastOneparameterIsON()

    }

    if ( this.receivedMessageIsDeselected === true && this.sentMessageIsDeselected === false && this.sentMailIsDeselected === false  ){
         const typeOfMessageEmail: string = 'all'
         const newToFrom: string[] = [ 'sent' , 'sent']
         const messageMailInfos:  TypeMessage_Mail_FromTo = { type: typeOfMessageEmail , fromTo: newToFrom }
      
         this.finalRoutingCall.messageMailInfos = messageMailInfos
         console.log(this.finalRoutingCall)
         this.onCheckingIfAtLeastOneparameterIsON()
    }

    if ( this.receivedMessageIsDeselected === false && this.sentMessageIsDeselected === true && this.sentMailIsDeselected === false  ){
         const typeOfMessageEmail: string = 'all'
         const newToFrom: string[] = [ 'received' , 'sent']
         const messageMailInfos:  TypeMessage_Mail_FromTo = { type: typeOfMessageEmail , fromTo: newToFrom }
   
         this.finalRoutingCall.messageMailInfos = messageMailInfos
         console.log(this.finalRoutingCall)
         this.onCheckingIfAtLeastOneparameterIsON()
    }

    // -------------   END EMAIL SENT == true ----------------------------------

    if ( this.receivedMessageIsDeselected === false && this.sentMessageIsDeselected === false && this.sentMailIsDeselected === true  ){
      const typeOfMessageEmail: string = 'message'
      const newToFrom: string[] = ['all', null ] 
      const messageMailInfos:  TypeMessage_Mail_FromTo = { type: typeOfMessageEmail , fromTo: newToFrom }

      this.finalRoutingCall.messageMailInfos = messageMailInfos
      console.log(this.finalRoutingCall)
      this.onCheckingIfAtLeastOneparameterIsON()
 }

 if ( this.receivedMessageIsDeselected === true && this.sentMessageIsDeselected === true && this.sentMailIsDeselected === true  ){
      const typeOfMessageEmail: string = null
      const newToFrom: string[] = [ null , null ]
      const messageMailInfos:  TypeMessage_Mail_FromTo = { type: typeOfMessageEmail , fromTo: newToFrom }

      this.finalRoutingCall.messageMailInfos = messageMailInfos
      console.log(this.finalRoutingCall)
      this.onCheckingIfAtLeastOneparameterIsON()
 }

 if ( this.receivedMessageIsDeselected === true && this.sentMessageIsDeselected === false && this.sentMailIsDeselected === true  ){
      const typeOfMessageEmail: string = 'message'
      const newToFrom: string[] = [ 'sent' , null ]
      const messageMailInfos:  TypeMessage_Mail_FromTo = { type: typeOfMessageEmail , fromTo: newToFrom }
   
      this.finalRoutingCall.messageMailInfos = messageMailInfos
      console.log(this.finalRoutingCall)
      this.onCheckingIfAtLeastOneparameterIsON()
 }

 if ( this.receivedMessageIsDeselected === false && this.sentMessageIsDeselected === true && this.sentMailIsDeselected === true  ){
      const typeOfMessageEmail: string = 'message'
      const newToFrom: string[] = [ 'received' , null ]
      const messageMailInfos:  TypeMessage_Mail_FromTo = { type: typeOfMessageEmail , fromTo: newToFrom }

      this.finalRoutingCall.messageMailInfos = messageMailInfos
      console.log(this.finalRoutingCall)
      this.onCheckingIfAtLeastOneparameterIsON()
 }


  }

   // interface FinalRoutingCall {
    //   messageMailInfos: TypeMessage_Mail_FromTo , 
    //   dateInfos: DateType_DateInfo, 
    //   titleInfo: Subject_Paragraph_Title
    // }
    // interface Subject_Paragraph_Title {
    //   type: string , 
    //   title: string 
    // }

  onSubjectIsDeselected($event){
    this.filterSubjectParagraphIsVisible = false 
    this.subjectIsDeselected = $event 
    console.log('subject is deselected = ',this.subjectIsDeselected)
    /**
     * make final routing call null subject
     */
    const titleInfo: Subject_Paragraph_Title = { type: null, title: null }
    this.finalRoutingCall.titleInfo = titleInfo
    console.log(this.finalRoutingCall)

    setTimeout(() => {
      this.filterSubjectParagraphIsVisible = true 
    }, 100);

 
  }

  onParagraphIsDeselected($event){
    this.filterSubjectParagraphIsVisible = false 
    this.paragraphIsDeselected = $event 
    console.log('paragraph Is Deselected = ', this.paragraphIsDeselected)
    /**
     * make final routing call null subject
     */
    const titleInfo: Subject_Paragraph_Title = { type: null, title: null }
    this.finalRoutingCall.titleInfo = titleInfo
    console.log(this.finalRoutingCall)

    setTimeout(() => {
      this.filterSubjectParagraphIsVisible = true 
    }, 100);

  
  }
   


  onFixIsDeselected($event){
    this.fixIsDeselected = $event 
    console.log('Fix Is Deselected = ', this.fixIsDeselected)

   
    this.setNull_FinalDateType_info()
  }

  onRangeIsDeselected($event){
    if ($event === true ){  this.filterDateIsVisible = false }
    this.rangeIsDeselected = $event 
    console.log('range is deselected = ', this.rangeIsDeselected)

    this.setNull_FinalDateType_info()

    if ($event === true ){  
        setTimeout(()=>{
        this.filterDateIsVisible = true 
        },100)
    }

  
  }

  setNull_FinalDateType_info(){
    const initialDate: string = "2023-05-01T00:00:00"
    /**
     * these dates will all be changed to local date with server jetLag before the call 
     */
    const upToTodayDate: string = this.datePipe.transform( new Date() , "yyyy-MM-dd")+"T23:59:59"
    const initialDateInfos: DateType_DateInfo = { dateType: 'range' , dateInfo: [initialDate, upToTodayDate] }

    this.finalRoutingCall.dateInfos = initialDateInfos

    console.log(this.finalRoutingCall)
  }

  onCheckingIfAtLeastOneparameterIsON(){
  const mailMessageSelectionArray: boolean[] = [this.receivedMessageIsDeselected, this.sentMessageIsDeselected, this.sentMailIsDeselected ]
   if ( mailMessageSelectionArray.findIndex( x=> x === false ) === -1 ){ this.hasAtLeastOneSearchParameterSelected = false }
   if ( mailMessageSelectionArray.findIndex( x=> x === false ) !== -1 ){ this.hasAtLeastOneSearchParameterSelected = true }

  

}

onUpdateSubjectParagraphTextResult($event){
  console.log($event)


  if ( this.subjectIsDeselected === false ){ 
      const subjectParagraphTitle: Subject_Paragraph_Title = { type: 'subject', title: $event}
      console.log('subjectParagraphTitle = ', subjectParagraphTitle)
      this.finalRoutingCall.titleInfo = subjectParagraphTitle
      console.log(this.finalRoutingCall)

     
  }

  if ( this.paragraphIsDeselected === false ){ 
       const subjectParagraphTitle: Subject_Paragraph_Title = { type: 'paragraph', title: $event } 
       console.log('subjectParagraphTitle = ', subjectParagraphTitle)
       this.finalRoutingCall.titleInfo = subjectParagraphTitle
       console.log(this.finalRoutingCall) 

      
  }
  
};

onUpdateDateType_dateInfo($event){
  console.log($event)
  const finalDateInfos: DateType_DateInfo = $event 
  this.finalRoutingCall.dateInfos = finalDateInfos
  console.log(this.finalRoutingCall)

 

}

onUpdateSelectedGroupName($event){

  console.log($event)
  const updatedGroupName: GroupName = $event 
  this.finalRoutingCall.groupInfo.userChatRoomId = updatedGroupName.userChatRoomId
  this.finalRoutingCall.groupInfo.name = updatedGroupName.name

  console.log(this.finalRoutingCall)
}






/**
 * formulate the router route according to the results 
 */
onSubmit(){
    this.isSubmitted = true
    this.closeSidenavDrawerOnSubmit.emit()
    this.formResultsService.onScrollFormesultsListParentNatToTop()

    /**
     * { path: 'form-result-list/:type/:messageToFrom/:emailTo/:dateType/:dateInfoStart/:dateInfoEnd/:subject-paragraph/:title', component: FormResultsListComponent },  // need to add a resolver guard lesson 15                                      
     */

    const type = (this.finalRoutingCall.messageMailInfos.type)


    let messageToFrom = (this.finalRoutingCall.messageMailInfos.fromTo[0])
    if ( this.finalRoutingCall.messageMailInfos.fromTo[0] === null ){ messageToFrom = ('oooooooo')}

    let emailTo: string 
    if (this.finalRoutingCall.messageMailInfos.fromTo[1] !== null  ){ emailTo = (this.finalRoutingCall.messageMailInfos.fromTo[1])}
    if (this.finalRoutingCall.messageMailInfos.fromTo[1] === null  ){ emailTo = ('oooooooo')}
    
//groupSelected/:groupName
    let userChatRoomId: string 
    let groupName: string
    if ( this.finalRoutingCall.groupInfo.userChatRoomId === null ){ userChatRoomId = 'oooooooo' , groupName = 'oooooooo' }
    if ( this.finalRoutingCall.groupInfo.userChatRoomId !== null ){ userChatRoomId = this.finalRoutingCall.groupInfo.userChatRoomId , groupName =  this.finalRoutingCall.groupInfo.name }

    const dateType = (this.finalRoutingCall.dateInfos.dateType)
    const dateInfoStart = (this.finalRoutingCall.dateInfos.dateInfo[0])
    const dateInfoEnd = (this.finalRoutingCall.dateInfos.dateInfo[1])

    let subjectParagraphType: string 
    if ( this.finalRoutingCall.titleInfo.type !== null  ){ subjectParagraphType = (this.finalRoutingCall.titleInfo.type) }
    if ( this.finalRoutingCall.titleInfo.type === null  ){ subjectParagraphType = ('oooooooo')}

    let title: string 
    if ( this.finalRoutingCall.titleInfo.title !== null  ){ title = (this.finalRoutingCall.titleInfo.title)}
    if ( this.finalRoutingCall.titleInfo.title === null  ){ title = ('oooooooo')}

    this.router.navigate(['../results/form-result-list', type , messageToFrom ,emailTo, dateType , dateInfoStart , dateInfoEnd , userChatRoomId, groupName , subjectParagraphType , title ], {relativeTo: this.route })
  
    this.updateIsSubmitToTrueToChildsWithInputs.next()
}

onResetFormFilter(){
  this.isSubmitted = false
}


onClear(){
  this.isSubmitted = false
  this.isRestarting = true 

  this.receivedMessageIsDeselected = true
  this.sentMessageIsDeselected = true
  this.sentMailIsDeselected = true
  this.subjectIsDeselected = true 
  this.paragraphIsDeselected = true 
  this.fixIsDeselected = true 
  this.rangeIsDeselected = true 

  this.filterDateIsVisible = true 
  this.filterSubjectParagraphIsVisible = true  

  this.hasAtLeastOneSearchParameterSelected = false 

  this.updatedSubject = ''
  this.updatedDate = ['', '']

  const initialDate: string = "2023-05-01T00:00:00"
  const upToTodayDate: string = this.datePipe.transform( new Date() , "yyyy-MM-dd")+"T23:59:59"

    const initialEmptyMessageMailInfos:  TypeMessage_Mail_FromTo = { type: null , fromTo: null }
    const initialDateInfos: DateType_DateInfo = { dateType: 'range' , dateInfo: [initialDate, upToTodayDate] }
    const initialTitleInfo: Subject_Paragraph_Title = { type: null , title: null }
    const initialGroupInfo: GroupName = { userChatRoomId : null , name: null }
    /**
     * this finalRoutingCall has to be upfdated at any sub-component update 
     */
    this.finalRoutingCall = { messageMailInfos: initialEmptyMessageMailInfos, dateInfos: initialDateInfos, titleInfo: initialTitleInfo , groupInfo: initialGroupInfo }
    console.log( this.finalRoutingCall )

   // this.formResultsService.formResultsList = []
  
  this.titleService.setTitle('results')
  this.resetFixDateFilter_Event.next()
  this.closeSidenavDrawerOnClear.emit()

  this.router.navigate(['../results/'], { relativeTo: this.route.parent })

/**
 * recalling all formResults after we clear the filters 
 */
if ( this.user !== null ){
      const newDateNow = this.datePipe.transform( new Date(), "yyyy:MM:dd'T'HH:mm:ss")
      const numberOfWeeksAgo = 3
      const newDateWeeksAgo = this.datePipe.transform( this.formResultsService.getLastWeeksDate(numberOfWeeksAgo), "yyyy:MM:dd'T'HH:mm:ss"  )
      console.log( 'newDate Now + date of last week = ',newDateNow , newDateWeeksAgo)
  
     // this.formResultsService.getAndStoreAllFormResultsBy_user_dateFrom_dateTo(this.user , newDateWeeksAgo , newDateNow) 
}

 setTimeout(() => {
  this.isRestarting = false
 },150);
}

onSwitchSubmitButtonToClear(){
  this.isSubmitted = true 
}



ngOnDestroy(): void {
  this.subscribeToRestartFormResultIndexList.unsubscribe()
  this.subscribeToSwitchToIsSubmittedOnResultListIsSelectedFromRouting.unsubscribe()
  this.updateOnUserEmail.unsubscribe()
  this.subscriptionToFormResultList_onCheckUpdateFilterStatus.unsubscribe()
}



}
