import { Injectable } from "@angular/core";
import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from "@angular/common/http";
import { map, tap, catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { SingleDropDownSelect } from "./single-drop-down-select.model";

@Injectable ({
    providedIn: 'root'
})

export class SingleDropDownSelectHttpRequestService {

    constructor ( private http: HttpClient ){}


}