import { Component, OnInit , Input } from '@angular/core';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
@Component({
  selector: 'app-form-name',
  templateUrl: './form-name.component.html',
  styleUrls: ['./form-name.component.css']
})
export class FormNameComponent implements OnInit {

  user: string 

  @Input() i: number
  @Input() name: string
  @Input() isClicked: boolean



  constructor( 
               private userObjectUpdateService: UserObjectUpdateService) { }

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

}

}
