import { Injectable } from "@angular/core";
import { AuthService } from 'src/app/services/auth-services/auth.service';
import { GetComponentsHttpRequestService } from 'src/app/services/gets-services/get-components-http-request.sevice';
import { Subscription, Subject } from "rxjs";

import { Recipient } from "../../forms/form-list/form-item/recipient/recipient.model";
import { InitialNullSetupService } from "../starting-services/initial-null-setup.service";



@Injectable ({
    providedIn: 'root' 
})

export class StorePersonalRecipientComponentsService {

    constructor (  private authService: AuthService,
                   private initialNullSetupService: InitialNullSetupService ,
                   private getComponentsHttpRequestService: GetComponentsHttpRequestService ){}


recipientsChanged = new Subject<Recipient[]>()

//this is called right at the beginning when we call all featureIndex components / in sequence 
getUserStartingRecipientComponents( userEmail: string , newSharedRecipients: Recipient[]){ 

        /**
         * to each shared recipient add the recopient owner to the message list 
         */
        for ( let i=0; i< newSharedRecipients.length ; i++ ){
            newSharedRecipients[i].toMessage.unshift(newSharedRecipients[i].owner)
        }
       
        
        this.getComponentsHttpRequestService.httpGetComponentsByType(userEmail, 'recipient')

                   .subscribe( (res: any ) => {
                    console.log(res)


                    const newRecipients : Recipient[] = res.recipient
                    console.log('MY RECIPIENTS ONLY',newRecipients)

                    newRecipients.push(...newSharedRecipients)

                    console.log('RECIPIENTS INCLUDE SHARED',newRecipients)
                    this.initialNullSetupService.initialNullRecipients = newRecipients

                    // here we should check first if we have recipients under recipientFeature
                    this.recipientsChanged.next(newRecipients)

                   })

};

/**
 * thisis to get actual user recipients before adding a new one 
 * checking how many has been stored new already from a possible other device 
 * used at the same time 
 */
getUserCurrentRecipientsComponents(userEmail: string){

    return this.getComponentsHttpRequestService.httpGetComponentsByType(userEmail, 'recipient')

}

}