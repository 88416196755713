<!-- <p>one-date-calendar-result works!</p> -->

<div class="row" >
    <div class="col-xs-12">

  
        <br>
          <table class="full-width" cellspacing="0">
            <tr>
                         <td *ngFor="let detail of oneDateCalendarDetailResults; let i=index"> 
                             <div class="col-xs-12">

                            <div class="row">

                              <mat-form-field >

                                {{detail.description}} 
                                <mat-chip (click)="picker.open()">
                                  {{detail.dateResult | date}}
                                </mat-chip> 

                                <input
                                matInput
                                [matDatepicker]="picker"
                                hidden
                              />
                                <mat-datepicker-toggle 
                                 matPrefix [for]="picker">
                                </mat-datepicker-toggle>
                                <mat-datepicker
                                  #picker
                                  [startAt]="init"
                                  [dateClass]="dateClass">
                                </mat-datepicker>

                              </mat-form-field>
                               
                              
                            </div>
                           
                        </div>


                           
                        </td> 
            </tr>
         </table>
     

    </div>
  </div>
  
