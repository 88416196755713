import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-date-and-time',
  templateUrl: './form-date-and-time.component.html',
  styleUrls: ['./form-date-and-time.component.css']
})
export class FormDateAndTimeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
