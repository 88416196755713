import { Component, OnInit , Input, Output, EventEmitter , ViewChild, OnDestroy} from '@angular/core';
import { MultiFeatures } from './multi-features.model';
import { MultiFeaturesService } from './multi-features.service';
import { Feature } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/feature.model';
import { MatTabGroup } from '@angular/material/tabs';
import { Observable, Subscription } from 'rxjs';
import { MultiFeaturesHttpRequestService } from './multi-features-http-request.service';

import { SingleWordInputService } from '../input/single-word/single-word-input-list/single-word-input.service';
import { ToggleSingleWordButtonService } from '../buttons/toggle-single-word-button/toggle-single-word-button.service';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { CreateNewFeatureContainerAndAddToFeatureContainerListItemService } from 'src/app/services/shared-services/create-new-feature-container-and-update-feature-container-list-item.service';
import { AdviseIfHttpStoreComponentsCallHasBeenMadeService } from 'src/app/services/other-components-services/advise-if-http-store-components-has-been-made.service';
import { StoreBaseComponentsService } from 'src/app/services/get-and-store-components/store-base-components.service';
import { FeatureContainerListItemService } from '../../../paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container-list-item/feature-container-list-items.service';
import { FeatureIndexTopicList } from '../../../paragraph-edit/feature-index-topic-list.model';
import { FeatureIndexTopicListService } from '../../../paragraph-edit/feature-index-topic-list.service';
import { FeatureContainerListItem } from '../../../paragraph-edit/feature-container-list-item.model';
import { FeatureContainer } from '../../../paragraph-edit/feature-container.model';
import { FeatureContainerService } from '../../../paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container/feature-container.service';

@Component({
  selector: 'app-multi-features-list',
  templateUrl: './multi-features-list.component.html',
  styleUrls: ['./multi-features-list.component.css']
})
export class MultiFeaturesListComponent implements OnInit, OnDestroy {

  user: string

  thisTypeOfComponent: string = 'multiFeatures'

  weAreInMultiFeatureEdit: boolean = false 

  multiFeature: MultiFeatures;
  multiFeatures: MultiFeatures[];

  feature: Feature;

  newTabIndex0: number ;
   newtabIndex : number ;
   @ViewChild('tabs') tabGroup: MatTabGroup

  index: number ;

  selectedIndex: number  ;
  @Input() feature_from_paragraphEdit: Feature 

  
  @Output() changeIndex = new EventEmitter<Feature>()  ;
  @Output() eventIndex: number ;
  @Input() dropId : number ;
  @Input() currentI : number ;
  @Input() isParagraphEdit: boolean


  createButtonLimit : boolean ;
  multiFeaturesLimit = 10 ;

  receiveFeatureInfoFromParagraphEdit: Subscription; 
  @Input() collectFeatureId: Observable<Feature>

  currentFeature: Feature

   /**
   * trial with firebase DB
   */
    trialMultiFeatures: MultiFeatures[]
    isLoading : boolean 
    isLoadingList: boolean 
  
    /**
     * error handling
     */
    error = null 
    @Output() holdOnCreateUpdateButtons = new EventEmitter<void>()
    @Output() emitASubComponentIsHavingIssues = new EventEmitter<boolean>()

    updateOnUserEmail: Subscription
    multiFeaturesHasBeenStoredSubscription: Subscription 
    featureContainerPlusParentsHaveBeenStored: Subscription 

    matToolTipMessageOnCreateNew: string 


  constructor( private multiFeaturesService: MultiFeaturesService,
               private multiFeaturesHttpRequestService: MultiFeaturesHttpRequestService,
               private singleWordInputService: SingleWordInputService,
               private toggleSingleWordButtonService: ToggleSingleWordButtonService ,
               private postComponentsHttpRequestService: PostComponentsHttpRequestService,
               private userObjectUpdateService: UserObjectUpdateService , 
               private createNewFeatureContainerAndAddToFeatureContainerListItemService: CreateNewFeatureContainerAndAddToFeatureContainerListItemService,
               private adviseIfHttpStoreComponentsCallHasBeenMadeService: AdviseIfHttpStoreComponentsCallHasBeenMadeService,
               private storeBaseComponentsService: StoreBaseComponentsService , 
               private featureContainerListItemService: FeatureContainerListItemService , 
               private featureIndexTopicListService: FeatureIndexTopicListService ,
               private featureContainerService: FeatureContainerService 

             ) { }

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail 

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
    .subscribe((updatedUserEmail)=>{
     this.user = updatedUserEmail
     console.log('updated USER = ', this.user)
    })

    this.isLoadingList = true 

    if ( this.user === null ){ this.continueInitialOnInit()}
    if ( this.user !== null ){
      if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.multiFeaturesHasBeenStored === true  ){  this.continueInitialOnInit() }
      if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.multiFeaturesHasBeenStored === false ){ this.storeBaseComponentsService.callBaseComponentsListByUserAndType(this.user , 'multiBase') }
    }
    
    this.receiveFeatureInfoFromParagraphEdit = this.collectFeatureId.subscribe((data)=> console.log(data))

    this.multiFeaturesHasBeenStoredSubscription = this.multiFeaturesService.multiFeaturesHasBeenStored
    .subscribe(()=> { 
     this.continueInitialOnInit()
     this.adviseIfHttpStoreComponentsCallHasBeenMadeService.multiFeaturesHasBeenStored = true 
     })

    this.featureContainerPlusParentsHaveBeenStored = this.featureContainerListItemService.newFeatureContainerAndparentsHaveBeenStored
    .subscribe((feature_plus_typeOfComponent: any )=> {

     const currentFeature:Feature = feature_plus_typeOfComponent.feature 
     const currentTypeOfComponent: string = feature_plus_typeOfComponent.featureContainerName
     console.log(feature_plus_typeOfComponent)
 
     if ( currentTypeOfComponent === this.thisTypeOfComponent){
          this.onEmitToParagraphEditChangedIndex(currentFeature)
     }
    })

  };

  continueInitialOnInit(){

    const featureIndexTopicListItem: FeatureIndexTopicList = this.featureIndexTopicListService.getFeatureIndexTopicListItem_by_type('personal')
    console.log('feature index topic list item = ',featureIndexTopicListItem)
  
    const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('multiFeatures')

    this.multiFeatures = []
    for ( let i=0; i<personalFeatureContainerListItem.featureContainer_ids.length ; i++ ){
          let currentFeatureContainer: FeatureContainer = this.featureContainerService.getFeatureContainerBy_id(personalFeatureContainerListItem.featureContainer_ids[i])
          this.multiFeatures.push(this.multiFeaturesService.getMultiFeatureBy_id(currentFeatureContainer.componentReference_id))
    }
    console.log(this.multiFeatures)


    this.selectedIndex =  this.multiFeatures.findIndex(x=> x._id === this.feature_from_paragraphEdit.feature_id )
    console.log('selected index_id + selected index : ', this.selectedIndex , this.multiFeatures)

    this.matToolTipMessageOnCreateNew = " CREATE A COPY OF  '1 - "+this.multiFeatures[0].name+"'"

    if ( this.multiFeatures.length < this.multiFeaturesLimit){ this.createButtonLimit = true }
    if ( this.multiFeatures.length >= this.multiFeaturesLimit ) { this.createButtonLimit = false }
     
    if (this.user === null ){ setTimeout(()=>{ this.isLoading = false , this.isLoadingList = false },200) }
    if (this.user !== null ){ this.isLoading = false , this.isLoadingList = false  }
   
  }



  /** for each click we recreate a new feature  */
onLinkClick($event) {

  this.index = this.tabGroup.selectedIndex ; 
 
  const newOwner = this.user 
  const newIsNew = false
  const newDropId = this.currentI;
  const newFeature_id = this.multiFeatures[this.index]._id
  const newFeatureName = 'multiFeatures' ;
  const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
 
  this.feature = newFeature;
 
  this.changeIndex.emit(this.feature)
  this.eventIndex = this.currentI ;
 
  console.log('drop id =', this.currentI)
  console.log(this.feature)
 
   }

   onCreate(){
    this.isLoading = true
    this.isLoadingList = true 
    this.onCreateNew()
    console.log(this.multiFeatures)
    
  }


   onCreateNew(){

    const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('multiFeatures')
    this.newTabIndex0 = personalFeatureContainerListItem.featureContainer_ids.length+1

     const new_id = 'multiFeat'+(this.newTabIndex0).toString()
     const newComponentName = 'multiFeature'
        const newUIID ='multiFeature-'+(this.newTabIndex0).toString()
     const newOwner = this.user; 
     const newIsNew = false
     const newDateAndTime = null ;
     const newAllUsersAllowed= false ;
     const newUserListAllowed = [];
     const newName =  'new tab '+ this.newTabIndex0.toString() ;
     /**
      * here we replicate the same features of our personal first multiFeature 
      */
    //  const newFeatures = this.multiFeatures[0].features
    //  const newSingleBase_id_list = this.multiFeatures[0].singleBase_id_list
    const newFeatures = []
    const newSingleBase_id_list = []

     const newMultiFeature = new MultiFeatures ( new_id , newComponentName, newUIID, newOwner, newDateAndTime, newAllUsersAllowed, newUserListAllowed, newName, newFeatures , newSingleBase_id_list)



     if ( this.user === null ){

      this.multiFeaturesService.onAddNew(newMultiFeature)

      const newOwner = this.user; 
      const newIsNew = false
      const newDropId = this.currentI ;
      const newFeature_id = newMultiFeature._id
      const newFeatureName = 'multiFeatures' ;
      const newFeature = new Feature (  newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
  
      this.feature = newFeature

      this.multiFeatures.push(newMultiFeature)

      if ( this.multiFeatures.length < this.multiFeaturesLimit){ this.createButtonLimit = true }
      if ( this.multiFeatures.length >= this.multiFeaturesLimit ) { this.createButtonLimit = false }
       
      this.createNewFeatureContainerAndAddToFeatureContainerListItemService.createFeatureContainer_toAddOnFeatureContainerListItem(newFeature, this.user )

     }

     if ( this.user !== null ){

      this.postComponentsHttpRequestService.httpStoreComponent(this.user, 'multiFeature', newMultiFeature ).subscribe((response: MultiFeatures)=> {

        this.multiFeaturesService.onAddNew(response)

        this.adviseIfHttpStoreComponentsCallHasBeenMadeService.add_MultiFeatureComponent_id_NotStoredYet(response._id)
  
        const newOwner = this.user; 
        const newIsNew = false
        const newDropId = this.currentI ;
        const newFeature_id = response._id
        const newFeatureName = 'multiFeatures' ;
        const newFeature = new Feature (  newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
    
        this.feature = newFeature

        this.multiFeatures.push(response)

        if ( this.multiFeatures.length < this.multiFeaturesLimit){ this.createButtonLimit = true }
        if ( this.multiFeatures.length >= this.multiFeaturesLimit ) { this.createButtonLimit = false }
       
        this.createNewFeatureContainerAndAddToFeatureContainerListItemService.createFeatureContainer_toAddOnFeatureContainerListItem(newFeature, this.user )
        
     }, error => {
      console.log(error)
  
      const errorMessage: string = "we weren't able to create the new component, try later, check the network"
      this.error = errorMessage
      this.isLoadingList = false 
      /**
       *  EMIT and event to paragraphEdit to put update or create buttons for 4,5 sec 
       */
      this.holdOnCreateUpdateButtons.emit()
  
      /**
       * return to previous set up without the new component after a while to be able to read the message 
       */
      setTimeout(()=>{
        this.error = null 
        this.isLoadingList = false 
        this.isLoading = false 
      },4000)

     })

    }
  }

    onEmitToParagraphEditChangedIndex(toEmitFeature: Feature ){
      console.log('is emitting changed feature')
      this.changeIndex.emit(toEmitFeature)
      // once this feature is emitted the paragraphEdit will change index and this whole 
      // component will restart again
    }

    onASubComponentIsHavingIssues($event){
      console.log( 'is emitting a subComponentIshavingIssue ')
      this.emitASubComponentIsHavingIssues.emit($event)
    }


  ngOnDestroy() {
    this.updateOnUserEmail.unsubscribe()
    this.featureContainerPlusParentsHaveBeenStored.unsubscribe()
    this.multiFeaturesHasBeenStoredSubscription.unsubscribe()
  }

}
