import { Component, Input, OnInit } from '@angular/core';
import { RangeInfoResult } from '../../shared-calendar-status-result/range-info-result.model';

@Component({
  selector: 'app-fixtext-result',
  templateUrl: './fixtext-result.component.html',
  styleUrls: ['./fixtext-result.component.css']
})
export class FixtextResultComponent implements OnInit {

  @Input() rangeInfoResult: RangeInfoResult

  constructor() { }

  ngOnInit(): void {
  }

}
