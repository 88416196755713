import { Component, Input , OnInit } from '@angular/core';
import { ToggleWordButtonDetailResult } from './toggle-word-button-detail-result.model';

@Component({
  selector: 'app-toggle-word-button-result',
  templateUrl: './toggle-word-button-result.component.html',
  styleUrls: ['./toggle-word-button-result.component.css']
})
export class ToggleWordButtonResultComponent implements OnInit {

  toggleWordButtonDetailResult: ToggleWordButtonDetailResult;

  @Input() toggleWordButtonDetailResults:ToggleWordButtonDetailResult[]
  // [
  //   new ToggleWordButtonDetailResult ('toggle WordButton Detail result'),
  //   new ToggleWordButtonDetailResult ('second '),
  //   new ToggleWordButtonDetailResult ('terzo risultato da bottone'),
  //   new ToggleWordButtonDetailResult ('quarto')
  // ]

  constructor() { }

  ngOnInit(){

    this.toggleWordButtonDetailResults
  }

}
