<h1>Currency Exchange</h1>
    <div>
      <label for="amount">Amount:</label>
      <input type="number" id="amount" [(ngModel)]="amount">
    </div>
    <div>
      <label for="currency1">Currency 1:</label>
      <select id="currency1" [(ngModel)]="currency1">
        <option *ngFor="let currency of currencies" [value]="currency">{{ currency }}</option>
      </select>
    </div>
    <div>
      <label for="currency2">Currency 2:</label>
      <select id="currency2" [(ngModel)]="currency2">
        <option *ngFor="let currency of currencies" [value]="currency">{{ currency }}</option>
      </select>
    </div>
    <button (click)="exchange()">Exchange</button>
    <div *ngIf="exchangeRate">
      <p>{{ amount }} {{ currency1 }} = {{ amount * exchangeRate }} {{ currency2 }}</p>
    </div>
    <div *ngIf="errorMessage">{{ errorMessage }}</div>
