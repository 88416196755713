import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RangeInfoResult } from '../../shared-calendar-status-result/range-info-result.model';
import { InnerArraySelect } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/shared-calendar-status-list/inner-array-select.model';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-checkbox-result',
  templateUrl: './checkbox-result.component.html',
  styleUrls: ['./checkbox-result.component.css']
})
export class CheckboxResultComponent implements OnInit, OnDestroy {

  user: string 

  @Input() rangeInfoResult: RangeInfoResult

  isFalseEditing: boolean = false 

  updateOnUserEmail: Subscription

  constructor( private userObjectUpdateService: UserObjectUpdateService) { }

  ngOnInit(): void {

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
    .subscribe((updatedUserEmail)=>{
     this.user = updatedUserEmail
     console.log('updated USER = ', this.user)
    })
  }

  

  onCheckboxChange($event, i){
    console.log(i , ' => ', $event)

    this.isFalseEditing = true 

    setTimeout(() => {
      const newRangeDetails: any[] = this.rangeInfoResult.rangeDetailResults
      const newSelectedRangeDetail: InnerArraySelect = newRangeDetails[i]
            newSelectedRangeDetail.selected = true
      this.rangeInfoResult.rangeDetailResults[i] = newSelectedRangeDetail 

      this.isFalseEditing = false
    }, 100);

    

  }

  ngOnDestroy(): void {
      this.updateOnUserEmail?.unsubscribe()
  }

}
