import { Component, OnInit , Inject} from '@angular/core';
import { FixLayout } from "../fix-layout.model"
import { FixLayoutService} from "../fix-layout.service"
import { FormGroup, FormControl, FormBuilder, Validators, FormArray} from '@angular/forms';
import { MatDialogRef , MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';

@Component({
  selector: 'app-fix-layout-edit',
  templateUrl: './fix-layout-edit.component.html',
  styleUrls: ['./fix-layout-edit.component.css']
})
export class FixLayoutEditComponent implements OnInit {

  user: string 

  fixLayout = this.editData.fixLayoutEdit

  fixLayoutEditForm: FormGroup



  constructor( private _fb: FormBuilder ,
               private fixLayoutService: FixLayoutService,
               public dialogRef: MatDialogRef<FixLayoutEditComponent>,
               private userObjectUpdateService: UserObjectUpdateService ,
               @Inject(MAT_DIALOG_DATA) public editData: any) {
                 this.fixLayoutEditForm = this._fb.group({
                       fixlayoutTableTrs: this._fb.array([])
                 })
                }

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

    console.log(this.fixLayout)

    // this.fixLayoutEditForm = new FormGroup ({

    //   name: new FormControl (this.fixLayout.name, [Validators.required, Validators.maxLength(30)]),
    //   fixLayoutTables : new FormArray ( this.fixLayout.map( fixTable => {
    //     const fixTablegroup = this.initialFixTable();
    //     fixTablegroup.patchValue(fixTable);
    //     return fixTablegroup
    //   }))
    // })

  }

  // fixLayoutTableTrs():FormArray {
  //   return this.fixLayoutEditForm.get('fixLayoutTableTrs') as FormArray
  // }


  // newFixLayoutTableTr(): FormGroup {
  //   return this._fb.group({
  //     fixLayoutTableTds: this._fb.array([])
  //   })
  // }

  // addFixLayoutTableTr(){
  //   this.fixLayoutTableTrs().push(this.newFixLayoutTableTr())
  // }

  // remoreFixLayoutTableTr(indexTr: number){
  //   this.fixLayoutTableTrs().removeAt(indexTr)
  // }

  // fixLayoutTableTh(fixTrIndex: number): FormArray {
  //   return this.fixLayoutTableTrs().at(fixTrIndex).get("details") as FormArray
  // }


  // newFixLayoutTableTh(): FormGroup {
  //   return this._fb.group({
  //     tableTDType: '',
  //     tableTDDetail: '' 
  //   })
  // }

  // addFixLayoutTableTh(indexTh: number ){
  //   this.fixLayoutTableTds.
  // }




  


  }



/**
 * constructor (public _id: number ,
public _name: string,
public owner: string,
public dateAndTime: string, 
public fixLayoutTables: FixLayoutTable[]
){}

export class FixLayoutTable {

    constructor ( public fixLayoutTableTRs: FixLayoutTableTR[] ){}


    export class FixLayoutTableTR {

    constructor( public fixLayoutTableTH: string[],
                 public fixLayoutTableTDs: FixLayoutTableTD[]){}

    export class FixLayoutTableTD{

    constructor( public tableTDType: string , public tableTDDetail: ChartDetail |
                                                                    string |
                                                                    number ){}

     <div  [ngSwitch]="feature.featureName " >
                            
                   <app-single-word-item   
                   *ngSwitchCase="'single word'">
                   </app-single-word-item>

    export class ChartDetail {

    constructor (public type: string ,public name: string , public value: number ){}
}
}
 */
