import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { MatDialogRef , MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';


@Component({
  selector: 'app-single-slider-edit',
  templateUrl: './single-slider-edit.component.html',
  styleUrls: ['./single-slider-edit.component.css']
})
export class SingleSliderEditComponent implements OnInit {

  user: string 

  singleSliderEditForm: FormGroup
  DetailsArray: FormArray 

  storageData = this.editData.DetailsArray

 

  constructor( private _fb:FormBuilder,
               public dialogRef: MatDialogRef<SingleSliderEditComponent>,
               private userObjectUpdateService: UserObjectUpdateService ,
               @Inject(MAT_DIALOG_DATA) public editData: any) { this.createSingleSliderEditForm() }


    createSingleSliderEditForm(){
      this.singleSliderEditForm = this._fb.group({
        name: [this.editData.name , Validators.compose([
          Validators.required, Validators.maxLength(30)
        ])],
        Details: this.DetailsArray
      })
    }


  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail 
    
    console.log('editData', this.editData)

    this.singleSliderEditForm = new FormGroup ({
      name : new FormControl (this.editData.name, [Validators.required, Validators.maxLength(30)]),
      details: new FormArray (this.storageData.map(item => {
        const group = this.initialdetail();
        group.patchValue(item);
        return group
      }))
    });
    // this.singleSliderEditForm.valueChanges.subscribe(
    //   (value) => console.log(value)
    //)

  }

  initialdetail(){
    return new FormGroup ({
      detailName: new FormControl (''),
      unit: new FormControl (''),
      autoTicks: new FormControl (true),
      disabled: new FormControl ( false ),
      invert: new FormControl (false ),
      max: new FormControl (''),
      min: new FormControl (''),
      showTicks: new FormControl (false),
      step: new FormControl (''),
      thumbLabel: new FormControl (true),
      value: new FormControl (''),
      vertical: new FormControl (false),
      tickInterval: new FormControl (this.editData.tickInterval),
      
    })
  }

  getDetails (singleSliderEditForm){
    return singleSliderEditForm.controls.details.controls
  }


  onSubmit(){
    console.log(this.singleSliderEditForm);
    if (this.singleSliderEditForm.invalid){
      return 
    }
  this.dialogRef.close(this.singleSliderEditForm.value),
  this.editData.value
  }




}
