import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RangeInfo } from '../range-info.model';

@Component({
  selector: 'app-inner-slide-toggle-edit',
  templateUrl: './inner-slide-toggle-edit.component.html',
  styleUrls: ['./inner-slide-toggle-edit.component.css']
})
export class InnerSlideToggleEditComponent implements OnInit {

  innerSlidetoggleEditFormName: FormGroup

  @Input() rangeInfo: RangeInfo
  @Input() isEditing: boolean 

  constructor() { }

  ngOnInit(): void {

    this.innerSlidetoggleEditFormName = new FormGroup({
      name : new FormControl (this.rangeInfo.name , [ Validators.required, Validators.maxLength(30) ] )
      });
  }

  onSubmitName(){

  }

}
