
<app-loading-spinner *ngIf="isLoadingForm"> </app-loading-spinner>

<div *ngIf="!isLoadingForm" 
     style="background-color:rgb(242, 248, 250) ;"  
     class="ParagraphComponentFrame">
    
  
  <!-- we have to let user still be able to choose or change recipients if they want -->
<div *ngIf="user && isProduction">


<div *ngIf="!messageRecipientIsTemporaryHidden">
  <div *ngIf="!form.noNeedRecipientAndSubject" class="ParagraphComponentFrame">
      
<!--  here is in case we want to change again the recipients list -->


<div *ngIf="!aFormExist">
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
</div>

<div *ngIf="aFormExist">
  <div *ngIf="isDropped">

    <!-- <app-loading-spinner *ngIf="isLoading"> </app-loading-spinner> -->

    <div *ngIf="isLoading">
      <br>
      <br>
      <br>
      <br>
      <!-- <app-loading-spinner></app-loading-spinner> -->
      <br>
      <br>
      <br>
      <br>
    </div>

    <mat-accordion 
    *ngIf="!isLoading"
    class="example-headers-align " multi>

      <app-recipient-list 
      [youAreInFormEdit]="youAreInFormEdit"
      [isMessageSide]="isMessageSide"
      [subscribeToRestartComponent]="restartRecipientItemComponent.asObservable()"
      [recipientIndex]="recipientIndex"
      (changeIndex)='onChangeIndex($event)'
      [recipientIndexListItem]="recipientIndexListItem"
      (startLoadingOtherRecipient)="onStartLoadingOtherRecipient1()"
      [isLoadingSubRecipientItems]="startLoadRecipientsNumber1.asObservable()"
      [isPersonalRecipientIndexListItem]="isPersonalRecipientIndexListItem"
      [updateRecipiendIndexListItems]="updateSharedRecipientListItem_fromRecipientFeature.asObservable()"
      [selectedIndex]="selectedRecipient_position"
      (emitSecondRecipientResult)='onEmitSecondRecipientResult($event)'
      (emitRecipientResult)="onEmitRecipientResult($event)"
      (emitTemporaryHideRecipientsMessage)="onEmitTemporaryHideRecipientsMessage()"
      (emitTemporaryHideRecipientsMail)="onEmitTemporaryHideRecipientsMail()"
      (emitUpdatedRecipientIndexListItem)="onEmitUpdatedRecipientIndexListItem_afterDeleteToMessageEmail($event)">
      </app-recipient-list>
  </mat-accordion>

   </div>
</div>

      <!-- remove recipient item if recipients indexes are dropped here -->
      <div *ngIf="aFormExist">
        <div *ngIf="!isDropped">
        
          <div *ngIf="user == form.owner">
            <mat-accordion class="example-headers-align " multi>
              <mat-tab-group #tabs
              appHeaderLessTabs2
              headerPosition="below" 
              animationDuration="0ms">
                <mat-tab>

                  <div *ngIf="isLoading">
                    <br>
                    <br>
                    <br>
                    <br>
                    <!-- <app-loading-spinner></app-loading-spinner> -->
                    <br>
                    <br>
                    <br>
                    <br>
                  </div>
     
                 <!-- when it's on form-item the user(receiver) cannot change the recipients 
                 disable all change when on form-item  -->
                 <div *ngIf="!isLoading">
                  <!--  [subscribeToRestartComponent]="restartRecipientItemComponent.asObservable()" -->
                   <app-recipient-item 
                   [youAreInFormEdit]="youAreInFormEdit"
                   [isMessageSide]="isMessageSide"
                   [recipientListHasBeenDropped]="recipientListHasBeenDropped"
                   [recipientIndex]="recipientIndex"
                   [recipientIndexListItem]="recipientIndexListItem"
                   [recipientIndexFeature]="recipientIndexFeature"
                   (emitSecondRecipientResult)='onEmitSecondRecipientResult($event)'
                   (emitRecipientResult)="onEmitRecipientResult($event)"
                   (emitTemporaryHideRecipientsMessage)="onEmitTemporaryHideRecipientsMessage()"
                   (emitTemporaryHideRecipientsMail)="onEmitTemporaryHideRecipientsMail()"
                   (emitUpdatedRecipientIndexListItem)="onEmitUpdatedRecipientIndexListItem_afterDeleteToMessageEmail($event)">
                 </app-recipient-item>
                
                 </div>
                
               <ng-template  mat-tab-label></ng-template>
                </mat-tab>
     
                <mat-tab  disabled > 
                 <ng-template  mat-tab-label>
                 </ng-template>
                </mat-tab>
              </mat-tab-group>
              </mat-accordion>

          </div>

          <div *ngIf="user !== form.owner" >

            <div *ngIf="!aRecipientIndex_Substitute_isActive"  >
              <svg (click)="onSetFirstRecipientIndex()" style="color: orangered;" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-chat-left-quote-fill" viewBox="0 0 16 16">
                <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm7.194 2.766a1.688 1.688 0 0 0-.227-.272 1.467 1.467 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 5.734 4C4.776 4 4 4.746 4 5.667c0 .92.776 1.666 1.734 1.666.343 0 .662-.095.931-.26-.137.389-.39.804-.81 1.22a.405.405 0 0 0 .011.59c.173.16.447.155.614-.01 1.334-1.329 1.37-2.758.941-3.706a2.461 2.461 0 0 0-.227-.4zM11 7.073c-.136.389-.39.804-.81 1.22a.405.405 0 0 0 .012.59c.172.16.446.155.613-.01 1.334-1.329 1.37-2.758.942-3.706a2.466 2.466 0 0 0-.228-.4 1.686 1.686 0 0 0-.227-.273 1.466 1.466 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 10.07 4c-.957 0-1.734.746-1.734 1.667 0 .92.777 1.666 1.734 1.666.343 0 .662-.095.931-.26z"/>
              </svg>
                 
            </div>

            <!-- if we have saved a recipientIndex to this form -------------------- -->
            <div *ngIf="aRecipientIndex_Substitute_isActive">
              <mat-accordion class="example-headers-align " multi>
                <mat-tab-group #tabs
                appHeaderLessTabs2
                headerPosition="below" 
                animationDuration="0ms">
                  <mat-tab>
       
                   <!-- when it's on form-item the user(receiver) cannot change the recipients 
                   disable all change when on form-item  -->
                   <div *ngIf="!isLoading">
                     <app-recipient-item 
                     [youAreInFormEdit]="youAreInFormEdit"
                     [isMessageSide]="isMessageSide"
                     [recipientListHasBeenDropped]="recipientListHasBeenDropped"
                     [recipientIndexListItem]="recipientIndexListItem"
                     [recipientIndex]="recipientIndex"
                     [recipientGroupName]="recipientIndex.name"
                     [subscribeToRestartComponent]="restartRecipientItemComponent.asObservable()"
                     [recipientIndexFeature]="recipientIndexFeature"
                     (emitSecondRecipientResult)='onEmitSecondRecipientResult($event)'
                     (emitRecipientResult)="onEmitRecipientResult($event)"
                     (emitTemporaryHideRecipientsMessage)="onEmitTemporaryHideRecipientsMessage()"
                     (emitTemporaryHideRecipientsMail)="onEmitTemporaryHideRecipientsMail()"
                     (emitUpdatedRecipientIndexListItem)="onEmitUpdatedRecipientIndexListItem_afterDeleteToMessageEmail($event)">
                   </app-recipient-item>
                   </div>
                  
                 <ng-template  mat-tab-label></ng-template>
                  </mat-tab>
       
                  <mat-tab  disabled > 
                   <ng-template  mat-tab-label>
                   </ng-template>
                  </mat-tab>
                </mat-tab-group>
                </mat-accordion>
  
            </div>


          </div>
       
         
       
        </div>

      </div>
       
</div>
</div>

<!-- END 1st recipient-message when VISIBLE -->


<div *ngIf="messageRecipientIsTemporaryHidden && user == form.owner" >
  <div  style="overflow: hidden;"  class="ParagraphComponentFrame">
    <svg *ngIf="recipientIndex.useMyOwnMailRecipient" 
         (click)="onRestoreRecipientMessage()"  align="center" style="color: #3f51b5;" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-chat-left-quote-fill" viewBox="0 0 16 16">
      <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm7.194 2.766a1.688 1.688 0 0 0-.227-.272 1.467 1.467 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 5.734 4C4.776 4 4 4.746 4 5.667c0 .92.776 1.666 1.734 1.666.343 0 .662-.095.931-.26-.137.389-.39.804-.81 1.22a.405.405 0 0 0 .011.59c.173.16.447.155.614-.01 1.334-1.329 1.37-2.758.941-3.706a2.461 2.461 0 0 0-.227-.4zM11 7.073c-.136.389-.39.804-.81 1.22a.405.405 0 0 0 .012.59c.172.16.446.155.613-.01 1.334-1.329 1.37-2.758.942-3.706a2.466 2.466 0 0 0-.228-.4 1.686 1.686 0 0 0-.227-.273 1.466 1.466 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 10.07 4c-.957 0-1.734.746-1.734 1.667 0 .92.777 1.666 1.734 1.666.343 0 .662-.095.931-.26z"/>
    </svg>
    <svg *ngIf="!recipientIndex.useMyOwnMailRecipient" 
        (click)="onRestoreRecipientMessage()"  align="center" style="color: orangered;" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-chat-left-quote-fill" viewBox="0 0 16 16">
      <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm7.194 2.766a1.688 1.688 0 0 0-.227-.272 1.467 1.467 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 5.734 4C4.776 4 4 4.746 4 5.667c0 .92.776 1.666 1.734 1.666.343 0 .662-.095.931-.26-.137.389-.39.804-.81 1.22a.405.405 0 0 0 .011.59c.173.16.447.155.614-.01 1.334-1.329 1.37-2.758.941-3.706a2.461 2.461 0 0 0-.227-.4zM11 7.073c-.136.389-.39.804-.81 1.22a.405.405 0 0 0 .012.59c.172.16.446.155.613-.01 1.334-1.329 1.37-2.758.942-3.706a2.466 2.466 0 0 0-.228-.4 1.686 1.686 0 0 0-.227-.273 1.466 1.466 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 10.07 4c-.957 0-1.734.746-1.734 1.667 0 .92.777 1.666 1.734 1.666.343 0 .662-.095.931-.26z"/>
    </svg>
  </div>
</div>

</div>
<!-- END  1st recipient hidden replacement -->

<!-- repeat the recipient  -->
<!-- 2ndt recipient-mail when HIDDEN  -->
<div *ngIf="mailRecipientIsTemporaryHidden">
  <div  style="overflow: hidden;" class="ParagraphComponentFrame">
    <svg (click)="onRestoreRecipientMail()" align="center" style="color: grey;" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-send-fill" viewBox="0 0 16 16">
      <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"/>
    </svg>
  </div>
</div>
<!-- 2ndt recipient-mail when HIDDEN -->


<!-- second Recipients mode is NOT MESSAGE MODE  -->
<div  *ngIf="!mailRecipientIsTemporaryHidden">
<div *ngIf="!form.noNeedRecipientAndSubject" class="ParagraphComponentFrame">
      
  <div *ngIf="!aFormExist">
    <br>
    <br>
    <br>
    <br>
    <!-- <app-loading-spinner></app-loading-spinner> -->
    <br>
    <br>
    <br>
    <br>
  </div>
  
  <div *ngIf="aFormExist">
    <div *ngIf="isDropped">

      <div *ngIf="isLoading">
        <br>
        <br>
        <br>
        <br>
        <!-- <app-loading-spinner></app-loading-spinner> -->
        <br>
        <br>
        <br>
        <br>
      </div>
  
      <mat-accordion 
      *ngIf="!isLoading"
      class="example-headers-align " multi>
  
        <app-recipient-list 
        [youAreInFormEdit]="youAreInFormEdit"
        [isMessageSide]="!isMessageSide"
        [subscribeToRestartComponent]="restartRecipientItemComponent.asObservable()"
        [recipientIndex]="recipientIndex"
        (changeIndex)='onChangeIndex($event)'
        [recipientIndexListItem]="recipientIndexListItem"
        [isPersonalRecipientIndexListItem]="isPersonalRecipientIndexListItem"
        (startLoadingOtherRecipient)="onStartLoadingOtherRecipient2()"
        [isLoadingSubRecipientItems]="startLoadRecipientsNumber2.asObservable()"
        [updateRecipiendIndexListItems]="updateSharedRecipientListItem_fromRecipientFeature.asObservable()"
        [selectedIndex]="selectedRecipient_position"
        (emitSecondRecipientResult)='onEmitSecondRecipientResult($event)'
        (emitRecipientResult)="onEmitRecipientResult($event)"
        (emitTemporaryHideRecipientsMessage)="onEmitTemporaryHideRecipientsMessage()"
        (emitTemporaryHideRecipientsMail)="onEmitTemporaryHideRecipientsMail()">
        </app-recipient-list>
    </mat-accordion>
  
     </div>
  </div>
  
        <div *ngIf="aFormExist">
          <div *ngIf="!isDropped">
          
            <div *ngIf="user == form.owner">
              <mat-accordion class="example-headers-align " multi>
                <mat-tab-group #tabs
                appHeaderLessTabs2
                headerPosition="below" 
                animationDuration="0ms">
                  <mat-tab>
  
                    <div *ngIf="isLoading">
                      <br>
                      <br>
                      <br>
                      <br>
                      <!-- <app-loading-spinner></app-loading-spinner> -->
                      <br>
                      <br>
                      <br>
                      <br>
                    </div>
        
                   <div *ngIf="!isLoading">
                     <app-recipient-item 
                     [youAreInFormEdit]="youAreInFormEdit"
                     [isMessageSide]="!isMessageSide"
                     [recipientListHasBeenDropped]="recipientListHasBeenDropped"
                     [recipientIndex]="recipientIndex"
                     [recipientIndexListItem]="recipientIndexListItem"
                     [recipientIndexFeature]="recipientIndexFeature"
                     (emitSecondRecipientResult)='onEmitSecondRecipientResult($event)'
                     (emitRecipientResult)="onEmitRecipientResult($event)"
                     (emitTemporaryHideRecipientsMessage)="onEmitTemporaryHideRecipientsMessage()"
                     (emitTemporaryHideRecipientsMail)="onEmitTemporaryHideRecipientsMail()">
                   </app-recipient-item>
                  
                   </div>
                  
                 <ng-template  mat-tab-label></ng-template>
                  </mat-tab>
       
                  <mat-tab  disabled > 
                   <ng-template  mat-tab-label>
                   </ng-template>
                  </mat-tab>
                </mat-tab-group>
                </mat-accordion>
  
            </div>
  
            <div *ngIf="user !== form.owner" >
  
              <div *ngIf="!aRecipientIndex_Substitute_isActive"  >
         
                <svg (click)="onSetFirstRecipientIndex()" style="color: orange;" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-send-fill" viewBox="0 0 16 16">
                  <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"/>
                </svg>
                   
              </div>
  
              <div *ngIf="aRecipientIndex_Substitute_isActive">
                <mat-accordion class="example-headers-align " multi>
                  <mat-tab-group #tabs
                  appHeaderLessTabs2
                  headerPosition="below" 
                  animationDuration="0ms">
                    <mat-tab>
         
                     <div *ngIf="!isLoading">
                       <app-recipient-item 
                       [youAreInFormEdit]="youAreInFormEdit"
                       [isMessageSide]="!isMessageSide"
                       [recipientListHasBeenDropped]="recipientListHasBeenDropped"
                       [recipientIndexListItem]="recipientIndexListItem"
                       [recipientIndex]="recipientIndex"
                       [recipientGroupName]="recipientIndex.name"
                       [subscribeToRestartComponent]="restartRecipientItemComponent.asObservable()"
                       [recipientIndexFeature]="recipientIndexFeature"
                       (emitSecondRecipientResult)='onEmitSecondRecipientResult($event)'
                       (emitRecipientResult)="onEmitRecipientResult($event)"
                       (emitTemporaryHideRecipientsMessage)="onEmitTemporaryHideRecipientsMessage()"
                       (emitTemporaryHideRecipientsMail)="onEmitTemporaryHideRecipientsMail()">
                     </app-recipient-item>
                     </div>
                    
                   <ng-template  mat-tab-label></ng-template>
                    </mat-tab>
         
                    <mat-tab  disabled > 
                     <ng-template  mat-tab-label>
                     </ng-template>
                    </mat-tab>
                  </mat-tab-group>
                  </mat-accordion>
    
              </div>
  
  
            </div>
         
           
         
          </div>
  
        </div>
         
</div>
</div>

<!-- END SECOND RECIPIENTS is NOT MESSAGE MODE -->

      
      
  <div class="row">
      <div class="col-xs-12">

       <div *ngIf="!form.noNeedRecipientAndSubject">
          <div  class="ParagraphComponentFrame">

            <div *ngIf="isLoading">
              <h2 style="color:#3f51b5">subject</h2>
              <br>
              <br>
              <br>
            </div>

            <div *ngIf="!isLoading">
           
            <app-form-subject
            [youAreInFormEdit]="youAreInFormEdit"
            [isFixedSubject]="form.isFixedSubject"
            *ngIf="aFormExist"
            [formSubject]="form.formSubject"
            (emitSubjectTextResult)="onUpdateSubjectTextResult($event)">
            </app-form-subject>

            </div>

          </div>
       </div>

       <!-- HIDE MOMENTARELY  -->
       
       <!-- <div *ngIf="!form.noNeedRecipientAndSubject">
       <div *ngIf="!isLoading">
        <div *ngIf="mailTextRecipientGreetings.length > 0"  
             class="ParagraphComponentFrame">
             <br>
          <div *ngIf="!isLoadingReceiverResult">
       
            <div class="row">
              <mat-form-field class="custom-form-field">
                <input
                matInput 
                readonly
                type="text" 
                value="{{recipient.greeting}}"
                maxlength="150"
                class="smaller-text">
                <mat-hint  align="end"> edit greetings above on 'COMPLETE EMAIL RECIPIENT'  </mat-hint>
              </mat-form-field>
            </div>
            <br>
          </div>
        </div>
       </div>
       </div> -->

       
       
       
 
  <div *ngIf="aFormExist">
    <div *ngFor="let paragraphFeature of paragraphFeatures ; let i=index" >

       <div  *ngIf="paragraphFeature.owner === user"
             class="ParagraphComponentFrameItem">
    
          <app-paragraph-item 
                              [youAreInFormEdit]="youAreInFormEdit"
                              [paragraphFeature]="paragraphFeature"
                              [index_id]="paragraphFeature.paragraph_id"
                              [isFormItem]="isFormItem"
                              (updateBothParagraphResultAndTextPlusIndex)='onUpdateBothParagraphResultTextResultAndIndex($event)'>
         </app-paragraph-item> 
       </div> 

       <div  *ngIf="paragraphFeature.owner !== user && paragraphFeature.canBeReshared"
             class="ParagraphComponentFrameItem">
    
          <app-paragraph-item 
                              [youAreInFormEdit]="youAreInFormEdit"
                              [paragraphFeature]="paragraphFeature"
                              [index_id]="paragraphFeature.paragraph_id"
                              [isFormItem]="isFormItem"
                              (updateBothParagraphResultAndTextPlusIndex)='onUpdateBothParagraphResultTextResultAndIndex($event)'>
         </app-paragraph-item> 
       </div>

       <div  *ngIf="paragraphFeature.owner !== user && !paragraphFeature.canBeReshared"
             class="ParagraphComponentFrameSharedItem">

             <app-paragraph-item 
                        [youAreInFormEdit]="youAreInFormEdit"
                        [paragraphFeature]="paragraphFeature"
                        [index_id]="paragraphFeature.paragraph_id"
                        [isFormItem]="isFormItem"
                        (updateBothParagraphResultAndTextPlusIndex)='onUpdateBothParagraphResultTextResultAndIndex($event)'>
             </app-paragraph-item> 
        </div>


 
       <!-- <div *ngIf="paragraphFeature.owner !== user && paragraphFeature.userListAllowed.length > 0 && paragraphFeature.allUsersAllowed == false" 
            class="ParagraphComponentFrameSharedItem"> 
         <app-paragraph-item [youAreInFormEdit]="youAreInFormEdit"
                             [subscribeParagraphResult]="subscribeClickEvent.asObservable()" 
                             [subscribeToRestartComponent]="restartParagraphItemComponents.asObservable()"
                             [index_id]="paragraphFeature.paragraph_id"
                             [id]="paragraphFeature.paragraphId"
                             [isFormItem]="isFormItem"
                             (updateEmailTextParagraphBody)='onUpdateEmailTextBody($event)'
                             (updateSentParagraphResultEvent)='onUpdateSentParagraphResult($event)'>
        </app-paragraph-item> 
      </div>
 
       <div *ngIf="paragraphFeature.owner !== user && paragraphFeature.allUsersAllowed == true && paragraphFeature.userListAllowed.length == 0" 
            class="ParagraphComponentFramePublicItem"> 
         <app-paragraph-item [youAreInFormEdit]="youAreInFormEdit"
                             [subscribeParagraphResult]="subscribeClickEvent.asObservable()" 
                             [subscribeToRestartComponent]="restartParagraphItemComponents.asObservable()"
                             [index_id]="paragraphFeature.paragraph_id"
                             [id]="paragraphFeature.paragraphId"
                             [isFormItem]="isFormItem"
                             (updateEmailTextParagraphBody)='onUpdateEmailTextBody($event)'
                             (updateSentParagraphResultEvent)='onUpdateSentParagraphResult($event)'>
        </app-paragraph-item> 
      </div>
 
      <div *ngIf="paragraphFeature.owner !== user && paragraphFeature.allUsersAllowed == true && paragraphFeature.userListAllowed.length > 0" 
           class="ParagraphComponentFramePublicAndSharedItem"> 
           <app-paragraph-item [youAreInFormEdit]="youAreInFormEdit"
                       [subscribeParagraphResult]="subscribeClickEvent.asObservable()" 
                       [subscribeToRestartComponent]="restartParagraphItemComponents.asObservable()"
                       [index_id]="paragraphFeature.paragraph_id"
                       [id]="paragraphFeature.paragraphId"
                       [isFormItem]="isFormItem"
                       (updateEmailTextParagraphBody)='onUpdateEmailTextBody($event)'
                       (updateSentParagraphResultEvent)='onUpdateSentParagraphResult($event)'>
            </app-paragraph-item> 
      </div> -->
 
       
     </div>  
  </div>  
     
 <div *ngIf="!form.noNeedRecipientAndSubject">
  <div *ngIf="!isLoading">

    <div *ngIf="aFormExist"  class="row">

          <!-- <div  *ngIf="!recipientIndex_exist && !aRecipientIndex_Substitute_isActive" >
            <div style="background-color:orange " class="ParagraphComponentButton" >
              <h2  align="center" style="color: white;">  select a group  </h2>
            </div>
          </div> -->
        
    
          <app-loading-spinner *ngIf="isSendingMessageEmail"> </app-loading-spinner>
          <div *ngIf="!isSendingMessageEmail">
            <div *ngIf="recipientIndex_exist || aRecipientIndex_Substitute_isActive">

              <div *ngIf="isMessageOnly || isEmailOnly || isEmailMessage ">

                <div *ngIf="isMessageOnly" style="background-color:#3f51b5" class="ParagraphComponentButton pointer" (click)="onSendFormAndStayInFormItem()" >
                 <h2  align="center" style="color: white;" >send message</h2>
                </div>
          
                <div *ngIf="isEmailOnly" style="background-color:#3f51b5" class="ParagraphComponentButton pointer" (click)="onSendFormAndStayInFormItem()" >
                 <h2  align="center" style="color: white;" >send</h2>
                 <!-- email -->
                </div>

              <div *ngIf="isEmailMessage" style="background-color:#3f51b5" class="ParagraphComponentButton pointer"  (click)="onSendFormAndStayInFormItem()"  >
                <h2  align="center" style="color: white;">   send message + email </h2>
              </div>

              </div>

            </div>
          </div>
        
     </div>

  </div>  
 </div>
      
    
  
    </div>
    </div>

  </div>
