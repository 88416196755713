import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Chat } from "../../chats/chat.model";
import { Recipient } from "../../forms/form-list/form-item/recipient/recipient.model";
import { environment } from "src/environments/environment";



@Injectable ({
    providedIn: 'root' 
})

export class ChatHttpRequestService {

  private domain: string

    constructor (private http: HttpClient){
      this.domain = environment.domain
    }

    httpStoreChatAndRecipient( user: string,  chatRoom: Chat, recipientComponent: Recipient ){
     return this.http.post(   
      encodeURI(`${this.domain}`)+ encodeURI('market-in-service/rest/chatRoom/') + encodeURIComponent(user)
                                                                          + encodeURI('/'),
                                                                           {chatRoom, recipientComponent})
    }


    httpPutChatAndRecipient( user: string, id: string, chatRoom: Chat, recipientComponent: Recipient){
      return this.http.put(
        encodeURI(`${this.domain}`)+ encodeURI('market-in-service/rest/chatRoom/') + encodeURIComponent(user)
                                                                            + encodeURI('/')
                                                                            + encodeURIComponent(id) , 
                                                                              {chatRoom , recipientComponent}            
      )
    }

    httpRemoveRecipientOnChat(user: string, id: string, chatRoom: Chat, recipientComponent: Recipient){

      return this.http.put(
        encodeURI(`${this.domain}`)+ encodeURI('market-in-service/rest/chatRoom/removeRecipientUser/') + encodeURIComponent(user)
                                                                                                + encodeURI('/')
                                                                                                + encodeURIComponent(id) , 
                                                                                                { chatRoom , recipientComponent }   
                                                                                             
      )

    }

    httpCreateChatOnExistingRecipient( user: string , chatRoom: Chat, recipientComponent: Recipient , recipient_id: string ){
      return this.http.post(
        encodeURI(`${this.domain}`)+ encodeURI('market-in-service/rest/chatRoom/') + encodeURIComponent(user) 
                                                                            + encodeURI('/?recipientId=')
                                                                            + encodeURIComponent(recipient_id),
                                                                             { chatRoom , recipientComponent } 
                                                                            
      )
    }







}