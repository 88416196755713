export class UserObject {

    constructor ( public   email: string , 
                  public   id: string ,
                  private _token: string,
                  private _tokenExpirationDate: Date  ){}


    get token(){
        if ( !this._tokenExpirationDate || new Date() > this._tokenExpirationDate ){
            return null;
        }

        return this._token
    }
}
// lesson 300 udemy
// we should create aslo a profile model where we store all the other user date 
// nickname , full name  , address , phone number , type of user (basePlan, advance, premium , etc ) etc etc 