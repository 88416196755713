

<div cdkDropListGroup>
   

    <mat-toolbar color="primary" class="mat-toolbar" >
    </mat-toolbar>

    <mat-toolbar color="primary" class="mat-toolbar">
    <mat-toolbar-row color="primary" >

                  
                    <button *ngIf="isPersonal"
                    [hidden]="(isHandset$ | async) === true"
                    type="button"
                    aria-label="Toggle sidenav"
                    mat-icon-button
                    routerLink="shared-paragraphs"
                    routerLinkActive="activebutton">
                    <mat-icon style="color: white" aria-label="Side nav toggle icon"> menu </mat-icon>
                    </button>
                    <button *ngIf="isPublic"
                    [hidden]="(isHandset$ | async) === true"
                    type="button"
                    aria-label="Toggle sidenav"
                    mat-icon-button
                    routerLink="personal-paragraphs"
                    routerLinkActive="activebutton">
                    <mat-icon style="color: white" aria-label="Side nav toggle icon"> menu </mat-icon>
                    </button>
                    <button *ngIf="isShared"
                    [hidden]="(isHandset$ | async) === true"
                    type="button"
                    aria-label="Toggle sidenav"
                    mat-icon-button
                    routerLink="preferred-paragraphs"
                    routerLinkActive="activebutton">
                    <mat-icon style="color: white" aria-label="Side nav toggle icon"> menu </mat-icon>
                    </button>
                    <button *ngIf="isPreferred"
                    [hidden]="(isHandset$ | async) === true"
                    type="button"
                    aria-label="Toggle sidenav"
                    mat-icon-button
                    routerLink="public-paragraphs"
                    routerLinkActive="activebutton">
                    <mat-icon style="color: white" aria-label="Side nav toggle icon"> menu </mat-icon>
                    </button>

                    <button
                    *ngIf="(isHandset$ | async) && isOpenIndexMobile && isPersonal"
                    type="button"
                    aria-label="Toggle sidenav"
                    mat-icon-button
                    routerLink="shared-paragraphs"
                    routerLinkActive="activebutton">
                    <mat-icon style="color: white" aria-label="Side nav toggle icon"> menu </mat-icon>
                    </button>

                    <button
                    *ngIf="(isHandset$ | async) && isOpenIndexMobile && isPublic"
                    type="button"
                    aria-label="Toggle sidenav"
                    mat-icon-button
                    routerLink="personal-paragraphs"
                    routerLinkActive="activebutton">
                    <mat-icon style="color: white" aria-label="Side nav toggle icon"> menu </mat-icon>
                    </button>

                    <button
                    *ngIf="(isHandset$ | async) && isOpenIndexMobile && isShared"
                    type="button"
                    aria-label="Toggle sidenav"
                    mat-icon-button
                    routerLink="preferred-paragraphs"
                    routerLinkActive="activebutton">
                    <mat-icon style="color: white" aria-label="Side nav toggle icon"> menu </mat-icon>
                    </button>

                    <button
                    *ngIf="(isHandset$ | async) && isOpenIndexMobile && isPreferred"
                    type="button"
                    aria-label="Toggle sidenav"
                    mat-icon-button
                    routerLink="public-paragraphs"
                    routerLinkActive="activebutton">
                    <mat-icon style="color: white" aria-label="Side nav toggle icon"> menu </mat-icon>
                    </button>
    
                    <button
                    *ngIf="(isHandset$ | async) && !isOpenIndexMobile"
                    type="button"
                    aria-label="Toggle sidenav"
                    mat-icon-button
                    (click)="onToggleIndexMenuMobile()"
                    (click)="drawer.toggle()"
                    >
                    <mat-icon style="color: white" aria-label="Side nav toggle icon"> menu </mat-icon>
                    </button>


                   
                    <div *ngIf="isPersonal">
                      <!-- [hidden]="!isOpenIndexDesktop" -->
                      <!-- <div > -->
                     
                        <a *ngIf="(isHandset$ | async) === false" 
                           (click)="onIsOpenDesktop()"
                           class="navbar-brand"
                           routerLink="shared-paragraphs"
                           routerLinkActive="activebutton">

                           <span style="color:white" class="mat-button-wrapper">  
                            personal paragraphs  
                          </span>
                          <span style="color: white">
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg> -->
                          </span>  

                        </a>
                      <!-- </div> -->
                    
                     
                      <a [hidden]="!isOpenIndexMobile"
                      (click)="onInOpenIndexMobile()"
                      mat-icon-button
                      *ngIf="isHandset$ | async"
                      routerLink="shared-paragraphs"
                      routerLinkActive="activebutton" >
                      <span style="color:white"  
                      class="mat-button-wrapper">  personal paragraphs  </span>
                      <span style="color: white">
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg> -->
                      </span>  

                    </a>
                  
                    </div>

                    <div *ngIf="isPublic">
                      <!-- <div > -->
                        <a *ngIf="(isHandset$ | async) === false" 
                           (click)="onIsOpenDesktop()"
                           class="navbar-brand"
                           routerLink="personal-paragraphs"
                           routerLinkActive="activebutton" >

                           <span style="color:white"
                                 class="mat-button-wrapper">  
                                 public paragraphs 
                           </span>
                           <span style="color: white">
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                            </svg> -->
                          </span>  
                        </a>
                      <!-- </div> -->
                    
                   
                      <a
                      [hidden]="!isOpenIndexMobile"
                      (click)="onInOpenIndexMobile()"
                      mat-icon-button
                      routerLink="personal-paragraphs"
                      routerLinkActive="activebutton"
                      *ngIf="isHandset$ | async">
                      <span style="color:white"
                            class="mat-button-wrapper"> 
                             public paragraphs 
                      </span>
                      <span style="color: white" >
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg> -->
                      </span>  
                    </a>
                    </div>


                    <div *ngIf="isShared">
                      <!-- <div > -->
                        <a *ngIf="(isHandset$ | async) === false" 
                           (click)="onIsOpenDesktop()"
                            class="navbar-brand" 
                            routerLink="preferred-paragraphs"
                            routerLinkActive="activebutton">
                          
                            <span style="color:white" 
                                  class="mat-button-wrapper hide-text">  
                                  shared paragraphs 
                            </span>
                            <span style="color: white">
                              <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                              </svg> -->
                            </span>  
                        </a>
                      <!-- </div> -->
                     
                    
                      <a
                      [hidden]="!isOpenIndexMobile"
                      (click)="onInOpenIndexMobile()"
                      mat-icon-button
                      routerLink="preferred-paragraphs"
                      routerLinkActive="activebutton"
                      *ngIf="isHandset$ | async">
                      <!-- matBadge="+" matBadgeColor="warn" matBadgeSize="small"   [matBadgeHidden]="false"  -->
                      <span style="color:white" 
                            class="mat-button-wrapper hide-text">  
                            shared paragraphs  
                      </span>
                      <span style="color: white">
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg> -->
                      </span>  
                    </a>
                    </div>


                    <div *ngIf="isPreferred">
                      <!-- [hidden]="!isOpenIndexDesktop" -->
                      <!-- <div  > -->
                        <a  *ngIf="(isHandset$ | async) === false"
                            (click)="onIsOpenDesktop()"
                            routerLink="public-paragraphs"
                            routerLinkActive="activebutton" 
                            class="navbar-brand" >
                          
                            <span style="color:white" 
                                  class="mat-button-wrapper">  
                                  preferred paragraphs 
                            </span>
                            <span style="color: white">
                              <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                              </svg> -->
                            </span>  
                        </a>
                      <!-- </div> -->
                     
              
                      <a
                      [hidden]="!isOpenIndexMobile"
                      (click)="onInOpenIndexMobile()"
                      mat-icon-button
                      routerLink="public-paragraphs"
                      routerLinkActive="activebutton" 
                      *ngIf="isHandset$ | async">
                      <span style="color:white" 
                            class="mat-button-wrapper">  
                            preferred paragraphs 
                      </span>
                      <span style="color: white">
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                          <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                        </svg> -->
                      </span>  
                       
                    </a>
                    </div>

                    <span *ngIf="!isOpenIndexMobile" class="spacer"></span>

                    <div *ngIf="!isOpenIndexMobile"  >
                      <a class="navbar-brand" >
                        <span *ngIf="!editMode"  style="color:white" >
                          create saas 
                        </span>
                        <span *ngIf="editMode" style="color:white">
                          edit saas
                          <app-single-loading-spinner *ngIf="pulsingEditSpinner"></app-single-loading-spinner>
                        </span>
                      </a>
                    </div>
                           
                    
                  </mat-toolbar-row> 
                 </mat-toolbar> 






    <mat-sidenav-container class="container1" style="overflow: hidden;">

     <mat-sidenav
     style="overflow: hidden;"
     #drawer 
     [ngClass]="(isHandset$ | async) ? 'mobile-sidenav' : 'sidenav'" 
     [fixedInViewport]="true"
     fixedTopGap="130"
     [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
     [mode]="(isHandset$ | async) ? 'push' : 'side'"
     [opened]="(isHandset$ | async) === false"
     (closedStart)="onToggleIndexMenuMobile()">

            <mat-nav-list style="overflow: hidden;"> 

                  <app-recipient-index-column
                  [youAreInFormEdit]="true"
                  (updateRecipientIndex)='onUpdateRecipientIndex($event)'>
                  </app-recipient-index-column>
               
                   <!-- START ROUTER OUTLET TO LOAD DIFFERENT PARAGRAPHS TYPE -->
                 <router-outlet></router-outlet>
             

            </mat-nav-list>

      </mat-sidenav>


       <mat-sidenav-content #sidenavContent >

            <!-- <div style="background-color:rgb(242, 248, 250) ;" class="ParagraphComponentFrame">  -->
              <app-form-edit
              *ngIf="NoHideSideNavContentForASec"
              [editMode]="editMode"
              [form_id]="form_id"
              [paragraphIndexType]="paragraphIndexType"
              [paramsRecipientIndex_id]="paramsRecipientIndex_id"
              [subscribeUpdatedRecipientIndex]="subscribeUpdatedRecipientIndex.asObservable()"
              [changingRecipientIndexId]="updatedRecipientIndexId"
              (closeSideNavOnCompleteFormEditFunctions)="closeSideNavOnFormCompletition()"
              (closeSideNavContentOnSendingFormResult)="onResetFormEditByHidingSideNavContent()"
              (onScrollToSubjectOnParentComponent)="onScrollPageToSubject($event)"
              (onScrollToTopOnParentComponent)="onScrollPageToTop()"
              (onScrollToBottomOnParentComponent)="onScrollPageToBottom($event)"
              (onScrollToRemovedParagraph)="onScrollToRemovedParagraph($event)"
              (onOpenSideNavFromFormEdit)="onToggleSidenavMobileFromFormEdit()" >
              </app-form-edit>
          
            <!-- </div> -->

    
          <!-- <app-is-taking-far-too-long></app-is-taking-far-too-long> -->
          <a align="end" href="https://github.com/angular/components/blob/main/LICENSE"> Code licensed under an MIT-style License.</a>        
      

           
       </mat-sidenav-content>  

      
</mat-sidenav-container>

</div>









