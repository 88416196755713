<!-- <p>chat-item-content-header works!</p> -->

<!-- <div *ngIf="chatIsSelected"> -->
  <div class="ParagraphComponentFrame" style="background-color: rgb(243, 250, 253);">
    <br>
  <div class="col">
    <div class="row">
      <div class="col-xs-1">
        <svg style="color: #3f51b5;" xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
          <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
        </svg>
      </div>
      <div class="col-xs-11">
        <h2 style="color: #3f51b5;">recipientName</h2>  
      </div>
    </div>
  
    <div class="row">
      <h6 style="color: #3f51b5;"> recipient partecipants</h6> 
    </div>
  </div>

  <br>
</div>

       
    
