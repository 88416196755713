import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MultiEntryCalendarDetailResult } from './multi-entry-calendar-result.model';


@Component({
  selector: 'app-multi-entry-calendar-result',
  templateUrl: './multi-entry-calendar-result.component.html',
  styleUrls: ['./multi-entry-calendar-result.component.css']
})
export class MultiEntryCalendarResultComponent implements OnInit {

  @Input() multiEntryCalendarDetailResult : MultiEntryCalendarDetailResult

  public CLOSE_ON_SELECTED = false;
  public init = new Date();
  public resetModel = new Date(0);

  @ViewChild('picker', { static: true }) _picker: MatDatepicker<Date>;

  constructor() { }

  ngOnInit(){
  }

  dateClass = (date: Date) => {
    if (this._findDate(date) !== -1) {
      return [ 'selected' ];
    }
    return [ ];
  };


  _findDate(date: Date): number {
     return this.multiEntryCalendarDetailResult.multiEntryDetailResults.map((m) => +m).indexOf(+date)
   };

}
