import { Injectable } from "@angular/core";
import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from "@angular/common/http";
import { map, tap, catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { MultiFeatures } from "./multi-features.model";

@Injectable ({
    providedIn: 'root' 
})

export class MultiFeaturesHttpRequestService {

    constructor (private http: HttpClient){}

    httpStoreVeryFirstWordMultiFeature(multiFeature: MultiFeatures){
      return this.http.post(
      'http://localhost:8080/market-in-service/rest/component/toni@libero.it/multiFeature', multiFeature )
 
}

    httpStoreWordMultiFeature(multiFeature: MultiFeatures){
            return this.http.post(   // in a normal serve i should add /post/add right after the http address/
            // 'https://drapp-71fc8-default-rtdb.europe-west1.firebasedatabase.app/multiFeatures.json', multiFeature, 
            'http://localhost:8080/market-in-service/rest/component/toni@libero.it/multiFeature', multiFeature )
            // {
            //   observe: 'events',
            //   responseType: 'json'
            // }
            //  ).pipe(tap(event=>{
            //    console.log(event)
            //    if (event.type === HttpEventType.Response){
            //      console.log(event.body)
            //    }
            //  }))
            // .subscribe(response => {
            //     console.log(response)
            // })
    }



    httpFetchMultiFeatures(){
      let searchParams = new HttpParams
      searchParams = searchParams.append('print', 'Multi features')
      searchParams = searchParams.append('second', 'fetch')
       return this.http.get<{ [key: string]: MultiFeatures }>('https://drapp-71fc8-default-rtdb.europe-west1.firebasedatabase.app/multiFeatures.json',
       {
         headers: new HttpHeaders ({"Custom-Header":"hello"}),
         params: searchParams
       }
       )
        .pipe(map(responseData => {
          const multiFeatures: MultiFeatures[] = [];
          for (const key in responseData){
            if (responseData.hasOwnProperty(key)){
              multiFeatures.push({...responseData[key], _id: key})
            }
          }
          console.log(multiFeatures)
          return multiFeatures
          
        }),
        catchError(errorRes => {
          // send to analytics
          return throwError(errorRes)
        }))
    }


}