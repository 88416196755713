import { Component, Input, OnInit } from '@angular/core';
import { RangeInfo } from '../range-info.model';

@Component({
  selector: 'app-inner-tab-item',
  templateUrl: './inner-tab-item.component.html',
  styleUrls: ['./inner-tab-item.component.css']
})
export class InnerTabItemComponent implements OnInit {

 
  @Input() rangeInfo: RangeInfo
  @Input() isEditing: boolean 

  constructor() { }

  ngOnInit(): void {
  }

}
