import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { MatGridListModule } from '@angular/material/grid-list';
import { NgModule } from "@angular/core";
import { LinkGridListComponent } from "./link-grid-list/link-grid-list.component";
import { LinkGridItemComponent } from "./link-grid-list/link-grid-item/link-grid-item.component";
import { LinkGridEditComponent } from "./link-grid-list/link-grid-edit/link-grid-edit.component";

@NgModule({
    declarations: [
        LinkGridListComponent,
        LinkGridItemComponent,
        LinkGridEditComponent
      
    ],

    imports: [
    CommonModule,
    LayoutModule,
    MatGridListModule 
   
    
    ],

    exports: [
        LinkGridListComponent,
        LinkGridItemComponent,
        LinkGridEditComponent


    ],
})
export class LinkModule{}