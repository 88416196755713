import { Component, OnInit, Input,  ViewChild } from '@angular/core';
import { Feature } from '../../feature.model';

@Component({
  selector: 'app-qr-code-scanner',
  templateUrl: './qr-code-scanner.component.html',
  styleUrls: ['./qr-code-scanner.component.css']
})
export class QrCodeScannerComponent implements OnInit {

  output: string;
  output2: string ;
  counter : number

  isOpenVideo: boolean

  @Input() feature: Feature


  constructor() { }

  ngOnInit(){

    this.isOpenVideo = false
    this.counter = 0

    console.log('feature = ', this.feature)
  }


  // @ViewChild('action', { static: true }) action: NgxScannerQrcodeComponent;
  // TODO something this.action

  public onError(e: any): void {
    alert(e);
  }

  onOpenVideo(){
  this.counter = 0
  this.isOpenVideo = !this.isOpenVideo
  }

  onReceiveData($event){
    console.log($event)
    if (this.counter !== 0){ this.isOpenVideo = false ; return }
    if (this.counter === 0){  this.output = $event; this.counter++ ; this.isOpenVideo = false ; return }
    
  }

}
