import { Component, Input, OnInit } from '@angular/core';
import { ParagraphFeatureContainer } from '../paragraph-feature-container.model';
import { ParagraphIndexTopicDialog } from '../paragraph-index-topic-dialog.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { ParagraphFeatureContainerService } from '../paragraph-feature-container.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { ParagraphService } from 'src/app/forms/form-list/form-item/paragraph/paragraph.service';
import { ParagraphTopicIndexService } from '../../../paragraph-topic-index.service';
import { ParagraphTopicService } from '../paragraph-topic.service';
import { ParagraphTopicIndex } from '../../../paragraph-topic-index.model';
import { ParagraphTopic } from '../paragraph-topic.model';
import { DialogmanagerService } from 'src/app/services/other-components-services/dialogmanager.service';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions} from '@angular/material/tooltip';
import { Paragraph } from 'src/app/forms/form-list/form-item/paragraph/paragraph.model';
import { StoreParagraphItemsService } from 'src/app/services/get-and-store-components/store-paragraph-items.service';
import { FormService } from 'src/app/forms/form.service';
import { StorePersonalFeatureIndexComponentsService } from 'src/app/services/store-initial-components/store-personal-feature-index-components.service';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { CreateUpdateMultiComponentsService } from 'src/app/services/shared-services/create-update-multi-components.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { FormIndexTopicListItem } from 'src/app/forms/form-list/form-index-topic-list/form-index-topic-list-item.model';
import { FormIndexTopic } from 'src/app/forms/form-list/form-index-topic-list/form-index-topic.model';
import { FormFeatureContainer } from 'src/app/forms/form-list/form-index-topic-list/form-feature-container.model';
import { AddSharedFormOrParagraphBottomSheetComponent } from 'src/app/forms/form-list/add-shared-form-or-paragraph-bottom-sheet/add-shared-form-or-paragraph-bottom-sheet.component';


/** Custom options the configure the tooltip's default show/hide delays. */
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 100,
  touchendHideDelay: 1000,
};

interface SharedParagraphForm_Dialog_BottomSheet_Data {
  typeOfComponent: string , 
  formType: FormType ,
  paragraphType: ParagraphType, 
}

interface FormType {
  formIndexTopic: FormIndexTopic ,
  formFeatureContainer: FormFeatureContainer
}

interface ParagraphType {
  paragraphTopic: ParagraphTopic,
  paragraphFeatureContainer: ParagraphFeatureContainer
}

@Component({
  selector: 'app-paragraph-feature-container',
  templateUrl: './paragraph-feature-container.component.html',
  styleUrls: ['./paragraph-feature-container.component.css'],
  providers: [{provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}]
})
export class ParagraphFeatureContainerComponent implements OnInit {

  
  @Input() paragraphFeatureContainer_id: string
  @Input() isPersonal: boolean  
  @Input() isPublic: boolean 
  @Input() paragraphTopic: ParagraphTopic
  isLoadingShareIcon: boolean = false 
  isClicked: boolean 

  paragraphFeatureContainer: ParagraphFeatureContainer
  paragraph: Paragraph

  paragraphShareStatusUpdateSubsciption: Subscription

  user: string

  paragraphName: string 

  updateOnUserEmail : Subscription

  isLoading: boolean = false 
  paragraphExist: boolean = true 
  isbeenAddedToPreferred: boolean = false 
  count: number ;
  isBeenShared: boolean = false 
  isBeenShared2Public: boolean 
  isBeenShared2Contacts: boolean

  updateOnceParagraphChildsHaveBeenStored: Subscription 
  paragraphWithChildsHasBeenStored: boolean = false 

  constructor( private paragraphService: ParagraphService, 
               private postComponentsHttpRequestService: PostComponentsHttpRequestService,
               private paragraphFeatureContainerService: ParagraphFeatureContainerService,
               private paragraphIndexTopicDialog: ParagraphIndexTopicDialog , 
               private paragraphTopicIndexService: ParagraphTopicIndexService,
               private paragraphTopicService: ParagraphTopicService , 
               private userObjectUpdateService: UserObjectUpdateService ,
               private dms: DialogmanagerService ,
               private _bottomSheet: MatBottomSheet ,
               private formService: FormService ,
               private breakpointObserver: BreakpointObserver ,
               private storeParagraphItemsService: StoreParagraphItemsService , 
               private storePersonalFeatureIndexComponentsService: StorePersonalFeatureIndexComponentsService , 
               private initialNullSetupService: InitialNullSetupService , 
               private createUpdateMultiComponentsService: CreateUpdateMultiComponentsService
               ) { }

  
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );


  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
  })

  this.count = 0

  this.isClicked = false 

  this.paragraphFeatureContainer = this.paragraphFeatureContainerService.getParagraphFeatureContainerBy_id(this.paragraphFeatureContainer_id)
  console.log(this.paragraphFeatureContainer)

  this.isLoading = true 
  this.paragraphExist = false 

  if (this.paragraphFeatureContainer !== null ){this.checkIfFormAlreadyExistOnPreferred()}


  /**
   * as soon as the paragraph has been shared 
   */
  this.paragraphShareStatusUpdateSubsciption = this.paragraphService.$updateParagraphShareStatus.subscribe(()=> this.onUpdateParagraphShareStatus() )

  this.updateOnceParagraphChildsHaveBeenStored = this.storeParagraphItemsService.singleParagraphChildsToAddToFormEdit
      .subscribe((paragraphResult: Paragraph )=> { 
        this.stopLoadingParagraphFeatureContainer()
      })


  }

  checkIfFormAlreadyExistOnPreferred(){
    const paragraphTopicIndexOfReference: ParagraphTopicIndex = this.paragraphTopicIndexService.getParagraphTopicIndex_by_type('preferred-paragraphs')
    const paragraphTopicsOfReference: ParagraphTopic[] = []

          for ( let i=0; i<paragraphTopicIndexOfReference.paragraphTopic_ids.length ; i++ ){
                paragraphTopicsOfReference.push(this.paragraphTopicService.getParagraphTopicBy_id(paragraphTopicIndexOfReference.paragraphTopic_ids[i]))
          }

          this.checkIfFormExistOnPreferredParagraphFeatureContainers(paragraphTopicsOfReference)
  }

  checkIfFormExistOnPreferredParagraphFeatureContainers(paragraphTopicsOfReference: ParagraphTopic[]){
    const paragrapgFeatureContainersOfReference: ParagraphFeatureContainer[] = []
         
          for ( let i=0; i<paragraphTopicsOfReference.length ; i++  ){
                for ( let y=0; y<paragraphTopicsOfReference[i].paragraphFeatureContainer_ids.length ; y++  ){
                       paragrapgFeatureContainersOfReference.push(this.paragraphFeatureContainerService.getParagraphFeatureContainerBy_id(paragraphTopicsOfReference[i].paragraphFeatureContainer_ids[y]))
                }
          }
          this.isFormBeenAddedToPreferred(paragrapgFeatureContainersOfReference)
  }

  isFormBeenAddedToPreferred(paragrapgFeatureContainersOfReference: ParagraphFeatureContainer[]){
    if ( paragrapgFeatureContainersOfReference.findIndex(x=> x.paragraph_id === this.paragraphFeatureContainer.paragraph_id) !== -1 ){ this.isbeenAddedToPreferred = true  }
    if ( paragrapgFeatureContainersOfReference.findIndex(x=> x.paragraph_id === this.paragraphFeatureContainer.paragraph_id) === -1 ){ this.isbeenAddedToPreferred = false }
    
    this.doesParagraphExist()
  }

  doesParagraphExist(){

 
      if ( this.paragraphService.getParagraphs().findIndex( x=> x._id === this.paragraphFeatureContainer.paragraph_id) !== -1 ){ 
           this.paragraph = this.paragraphService.getParagraphBy_id(this.paragraphFeatureContainer.paragraph_id)
           this.paragraphName = this.paragraphService.getParagraphBy_id(this.paragraphFeatureContainer.paragraph_id).name
           this.paragraphExist = true 
           if ( this.paragraph.allUsersAllowed === true ){ this.isBeenShared2Public = true }
           if ( this.paragraph.allUsersAllowed === false ){ this.isBeenShared2Public = false }
           if ( this.paragraph.userListAllowed.length > 0 ){ this.isBeenShared2Contacts = true }
           if ( this.paragraph.userListAllowed.length === 0 ){ this.isBeenShared2Contacts = false }
           if ( this.isBeenShared2Public === false && this.isBeenShared2Contacts === false ){ this.isBeenShared = false }
           if ( this.isBeenShared2Public === true || this.isBeenShared2Contacts === true ){ this.isBeenShared = true }
  
           this.isLoading = false 
      };

      if ( this.paragraphService.getParagraphs().findIndex( x=> x._id === this.paragraphFeatureContainer.paragraph_id) === -1 ){
           this.paragraphExist = false 
  
           this.isLoading = false 
      };
  }


  onShareParagraphIndex(){

    this.paragraphIndexTopicDialog.ShareParagraphIndex({
      paragraph_id: this.paragraphFeatureContainer.paragraph_id
    })
  }


  onAddParagraphToFormEdit(paragraph_id: string){ 
    this.isClicked = true 
    
    /**
     * we should let formEdit start loading .... 
     */
    this.paragraphFeatureContainerService.sendFormEditEventToStartLoadingNewParagraph()

    console.log(paragraph_id)
    // first download paragraph + childs and once stored , add to formEdit a subject 

    if ( this.user !== null ){
      if ( this.paragraphFeatureContainer.name === 'personal'){
        if ( this.paragraphWithChildsHasBeenStored === true ){ // call and check with service instead 
                  this.continueToSendFormEditTheParagraphId(this.paragraphService.getParagraphBy_id(paragraph_id))
        }
        if ( this.paragraphWithChildsHasBeenStored === false ){
          this.storeParagraphItemsService.getAndStorePersonalParagraphAndChildsComponents(this.user , paragraph_id)
          this.paragraphWithChildsHasBeenStored = true
          setTimeout(() => {
            this.paragraphWithChildsHasBeenStored = false
          }, 3000);
        }
        
      }
  
      if ( this.paragraphFeatureContainer.name !== 'personal'){
        if ( this.paragraphWithChildsHasBeenStored === true ){
          if ( this.paragraphService.getParagraphs().findIndex(x=> x._id === paragraph_id ) !== -1 ){
               this.continueToSendFormEditTheParagraphId(this.paragraphService.getParagraphBy_id(paragraph_id))
          }
          if ( this.paragraphService.getParagraphs().findIndex(x=> x._id === paragraph_id ) === -1 ){
               console.log('the paragraph is not present anymore, send an INFO to page') 
          }

        }
        if ( this.paragraphWithChildsHasBeenStored === false ){
          this.storeParagraphItemsService.getAndStoreSharedParagraphAndChildsComponents(this.user , this.paragraphFeatureContainer.name , paragraph_id )
          this.paragraphWithChildsHasBeenStored = true
          setTimeout(() => {
            this.paragraphWithChildsHasBeenStored = false
          }, 3000);
        }
       
      }
    }

    if ( this.user === null ){

      if ( this.paragraphFeatureContainer.name === 'public'){
        if ( this.paragraphWithChildsHasBeenStored === true ){ // this has to be stored at the service 
          this.continueToSendFormEditTheParagraphId(this.paragraphService.getParagraphBy_id(paragraph_id))
        }
        if ( this.paragraphWithChildsHasBeenStored === false ){
          this.storeParagraphItemsService.getAndStoreSharedParagraphAndChildsComponents(this.user , this.paragraphFeatureContainer.name , paragraph_id )
          this.paragraphWithChildsHasBeenStored = true
        }
        
      }

      if ( this.paragraphFeatureContainer.name !== 'public' ){
       // 
       this.continueToSendFormEditTheParagraphId( this.paragraphService.getParagraphBy_id(paragraph_id))
      }


    }

  };

  continueToSendFormEditTheParagraphId(paragraph: Paragraph ){
  // the subscription to formdit will fire directly from 
  this.formService.sendNullUserParagraphToAddToFormEdit(paragraph)

    setTimeout(()=>{
      this.isClicked = false 
    },300)

  };

  stopLoadingParagraphFeatureContainer(){
   
    setTimeout(()=>{
      this.isClicked = false 
   
    },300)

  }


  onUpdateParagraphShareStatus(){
    this.isLoadingShareIcon = true 

    this.paragraph = this.paragraphService.getParagraphBy_id(this.paragraphFeatureContainer.paragraph_id)
    console.log(this.paragraph)
    if ( this.paragraph.allUsersAllowed === true ){ this.isBeenShared2Public = true }
    if ( this.paragraph.allUsersAllowed === false ){ this.isBeenShared2Public = false }
    if ( this.paragraph.userListAllowed.length > 0 ){ this.isBeenShared2Contacts = true }
    if ( this.paragraph.userListAllowed.length === 0 ){ this.isBeenShared2Contacts = false }
    if ( this.isBeenShared2Public === false && this.isBeenShared2Contacts === false ){ this.isBeenShared = false }
     if ( this.isBeenShared2Public === true || this.isBeenShared2Contacts === true ){ this.isBeenShared = true }

    setTimeout(()=>{
      this.isLoadingShareIcon = false
    },300)

  }

  onAcceptFeatureContainer(){

      console.log( 'is opening window to add or refuse paragraphFeatureContainer')

      const newTypeOfComponent = 'paragraphFeatureContainer'

      const paragraphTopicOfreference: ParagraphTopic = this.paragraphTopic

      const newFormType : FormType = {  formIndexTopic: null ,formFeatureContainer: null }
      const newParagraphType: ParagraphType = { paragraphTopic: paragraphTopicOfreference , paragraphFeatureContainer: this.paragraphFeatureContainer }
      
      const newSharedParagraphForm_Dialog_BottomSheet_Data: SharedParagraphForm_Dialog_BottomSheet_Data = { typeOfComponent: newTypeOfComponent ,formType: newFormType ,paragraphType: newParagraphType }
  
      if ( !this.isMobileDevice()){
        this.dms.addSharedFormOrParagraphComponent({ 

        sharedParagraphForm_Dialog_BottomSheet_Data: newSharedParagraphForm_Dialog_BottomSheet_Data

        })
      }
  
      if ( this.isMobileDevice()){
           this._bottomSheet.open(AddSharedFormOrParagraphBottomSheetComponent , {
            data: {

            sharedParagraphForm_Dialog_BottomSheet_Data: newSharedParagraphForm_Dialog_BottomSheet_Data


            }
           });
      }
  
  
    
  }

  isMobileDevice(): boolean {
    const isHandset = this.breakpointObserver.isMatched(Breakpoints.Handset);
    return isHandset;
  }



/**
 * these are made to add public paragraph to preferred 
 */
  onAddParagraphToPreferred(paragraph_id: string ){
  this.isbeenAddedToPreferred = true 

  /**
   * first get the paragraph ( so that we know the owner )
   */
   const newParagraph: Paragraph = this.paragraphService.getParagraphBy_id(paragraph_id) 
  /**
   * check again if the prefereedparagraphTopic and paragraphFeatureContainer have already this
   * paragraph id inside 
   */
  if ( this.user !== null ){
    this.storePersonalFeatureIndexComponentsService.getPersonalUpdatedFeatureIndex_components(this.user)
        .subscribe((res: any) =>{
         console.log(res)
         
         const httpFeatureIndexComponentResult: any = res 
         // go to nect function and chech if paragraph already exist in updated paragraphFeatureContainer
         // and if paragraphTopic already exist 
       
          const newParagraphTopicIndexes: ParagraphTopicIndex[] = httpFeatureIndexComponentResult.paragraphTopicIndex
          console.log(newParagraphTopicIndexes)
          this.paragraphTopicIndexService.paragraphTopicIndexes = newParagraphTopicIndexes
          this.initialNullSetupService.initialNullParagraphTopicIndexes = newParagraphTopicIndexes
         
          const newParagraphTopics: ParagraphTopic[] = httpFeatureIndexComponentResult.paragraphTopic
          console.log(newParagraphTopics)
          this.paragraphTopicService.paragraphTopics = newParagraphTopics
          this.initialNullSetupService.initialNullParagraphTopics = newParagraphTopics 
       
          const newParagraphFeatureContainers: ParagraphFeatureContainer[] = httpFeatureIndexComponentResult.paragraphFeatureContainer 
          console.log(newParagraphFeatureContainers)
          this.paragraphFeatureContainerService.paragraphFeatureContainers = newParagraphFeatureContainers
          this.initialNullSetupService.initialNullParagraphFeatureContainers = newParagraphFeatureContainers

          this.definePreferredParagraphTopicsOfReference(newParagraph)


  })
  }


  if ( this.user === null ){

    // get and store paragraph+ childs 
    this.storeParagraphItemsService.getAndStoreSharedParagraphAndChildsComponents(this.user , this.paragraphFeatureContainer.name , paragraph_id )
    this.paragraphWithChildsHasBeenStored = true

    this.definePreferredParagraphTopicsOfReference(newParagraph)
    console.log("it's adding to preferred ", newParagraph)
  }

};

continueAddingParagraphToParagraph( newParagraph: Paragraph){
    this.postComponentsHttpRequestService.httpStorePreferenceComponent( this.user , newParagraph )
        .subscribe((res)=> { 
          console.log(res)
          console.log("it's adding to preferred ", newParagraph )
        }, error =>{
          console.log(error , 'should throw a warning')
        })
}



  definePreferredParagraphTopicsOfReference( newParagraph: Paragraph ){

     /**
         * we need to check if on paragraphTopicIndex preferred type is there this paragraphFeature owner already
         * create if there is not, add paragraphTopic 
         *  if there is already add only paragrapgFeatureContainer with this paragraphFeature_id
         */
     const preferredParagraphTopicIndex: ParagraphTopicIndex =  this.paragraphTopicIndexService.getParagraphTopicIndex_by_type('preferred-paragraphs')
     // if this paragraphIndexTopic has no paragraphTopic_ids yet , we can go directly to create paragraphTopic
     if ( preferredParagraphTopicIndex.paragraphTopic_ids.length > 0 ){ 
        console.log('preferredParagraphTopicIndex.paragraphTopic_ids.length > 0')
          // now chech if paragraphTopic with name of paragraphFeature owner exist ( on the list of paragraphTopics into preferred index only )
          const preferredParagraphTopics: ParagraphTopic[] = []
          let counter: number = 0
           for ( let i=0; i<preferredParagraphTopicIndex.paragraphTopic_ids.length ; i++ ){
                preferredParagraphTopics.push(this.paragraphTopicService.getParagraphTopicBy_id(preferredParagraphTopicIndex.paragraphTopic_ids[i]) )
  
                if ( counter === preferredParagraphTopicIndex.paragraphTopic_ids.length-1 ){ this.checkIfPreferredParagraphTopicAlreadyExist(newParagraph , preferredParagraphTopics) }
                if ( counter < preferredParagraphTopicIndex.paragraphTopic_ids.length-1 ){ counter = counter+1 }
            }
      };

      if ( preferredParagraphTopicIndex.paragraphTopic_ids.length === 0 ){
           if ( this.user !== null ){
                this.continueAddingParagraphToParagraph(newParagraph) 
                this.createPreferredParagraphFeatureContainer_CreatePreferredParagraphTopic_AddToTopicIndex(newParagraph)
           }
           if ( this.user === null ){ this.createPreferredParagraphTopicAndAddToParagraphTopicIndex( newParagraph )}
       
      }; 

  }


  checkIfPreferredParagraphTopicAlreadyExist(paragraph: Paragraph , preferredParagraphTopics: ParagraphTopic[] ){

    console.log('checkIfPreferredParagraphTopicAlreadyExist')

    if ( preferredParagraphTopics.findIndex(x=> x.name === paragraph.owner ) !== -1 ) {
      console.log('createPreferredParagraphFeatureContainerAndAddToPreferredParagraphTopic( false ,  paragraphFeature, preferredParagraphTopic) ')
      const preferredParagraphTopic: ParagraphTopic = preferredParagraphTopics[preferredParagraphTopics.findIndex(x=> x.name === paragraph.owner)]
      /**
       * check if by mistake this paragraphFeature already exist into the existing paragraphTopic
       */
      let paragraphAlreadyExist: boolean = false 
      for ( let i=0; i<preferredParagraphTopic.paragraphFeatureContainer_ids.length ; i++){
        if ( this.paragraphFeatureContainerService.getParagraphFeatureContainerBy_id(preferredParagraphTopic.paragraphFeatureContainer_ids[i]).paragraph_id === paragraph._id ){ paragraphAlreadyExist = true }
             console.log( 'throw a message paragraph already exist on preferred, nothing else to do ')
        }
        if ( paragraphAlreadyExist === false ){
                 if (this.user !== null ){
                     this.continueAddingParagraphToParagraph(paragraph)
                     this.createPreferredParagraphFeatureContainer_AddToPrefereedParagraphTopic(paragraph, preferredParagraphTopic)
                     
                 }
                 if ( this.user === null){ this.createPreferredParagraphFeatureContainerAndAddToPreferredParagraphTopic( false ,  paragraph, preferredParagraphTopic) }
                
        }
    }  
    
    if ( preferredParagraphTopics.findIndex(x=> x.name === paragraph.owner) === -1 ) { 
      console.log('createPreferredParagraphTopicAndAddToParagraphTopicIndex')
         if (this.user !== null ){  
             this.continueAddingParagraphToParagraph(paragraph) 
             this.createPreferredParagraphFeatureContainer_CreatePreferredParagraphTopic_AddToTopicIndex(paragraph)
         }
         if ( this.user === null ){ this.createPreferredParagraphTopicAndAddToParagraphTopicIndex(paragraph) }
    }

  }

  createPreferredParagraphFeatureContainer_AddToPrefereedParagraphTopic( newParagraph: Paragraph , preferredParagraphTopic: ParagraphTopic ){

    const new_id = (this.paragraphFeatureContainerService.getParagraphFeatureContainers().length+1).toString()
    const newComponentName = 'paragraphFeatureContainer'
    const newUIID = 'paragraphFeatureContainer-'+(this.paragraphFeatureContainerService.getParagraphFeatureContainers().length+1).toString()
    const newOwner = this.user 
    const newAllUsersAllowed = false
    const newUserListAllowed = []
    const newDateAndTime = null 
    const newIsActive = true 
    const newIsAccepted = true 
    const newName = 'preference'
    const newState = false 
    const newParagraph_id = newParagraph._id
    
    const newParagraphFeatureContainer = new ParagraphFeatureContainer ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed,
          newDateAndTime, newIsActive, newIsAccepted, newName, newState, newParagraph_id )

    console.log(newParagraphFeatureContainer, preferredParagraphTopic )

    this.createUpdateMultiComponentsService.createPreferredParagraphFeatureContainer_UpdateParagraphTopic(this.user, newParagraphFeatureContainer, preferredParagraphTopic)
        .subscribe((res: any)=> {
          console.log( res )

          const newParagraphFeatureContainer = res.featureIndex[0]
          const newParagraphTopic = res.featureIndex[1]
          
          this.paragraphFeatureContainerService.paragraphFeatureContainers.push(newParagraphFeatureContainer)
          this.initialNullSetupService.initialNullParagraphFeatureContainers.push(newParagraphFeatureContainer)

          const paragraphTopic_index = this.paragraphTopicService.getParagraphTopics().findIndex(x=> x._id =  preferredParagraphTopic._id)
          this.paragraphTopicService.paragraphTopics[paragraphTopic_index] = newParagraphTopic

          this.dms.QuickCommunicationPopUpComponent({
            quickComment: 'added to preferred-paragraphs list'
          })

        })

  }

  createPreferredParagraphFeatureContainer_CreatePreferredParagraphTopic_AddToTopicIndex(  newParagraph: Paragraph ){

    const preferredParagraphTopicIndex: ParagraphTopicIndex =  this.paragraphTopicIndexService.getParagraphTopicIndex_by_type('preferred-paragraphs')

    const new_id = (this.paragraphFeatureContainerService.getParagraphFeatureContainers().length+1).toString()
    const newComponentName = 'paragraphFeatureContainer'
    const newUIID = 'paragraphFeatureContainer-'+(this.paragraphFeatureContainerService.getParagraphFeatureContainers().length+1).toString()
    const newOwner = this.user 
    const newAllUsersAllowed = false
    const newUserListAllowed = []
    const newDateAndTime = null 
    const newIsActive = true 
    const newIsAccepted = true 
    const newName = 'preference'
    const newState = false 
    const newParagraph_id = newParagraph._id
    
    const newParagraphFeatureContainer = new ParagraphFeatureContainer ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed,
          newDateAndTime, newIsActive, newIsAccepted, newName, newState, newParagraph_id )


            const new_id2 =  'paragraphTo-'+(this.paragraphTopicService.getParagraphTopics().length+1).toString()
            const newComponentName2 = 'paragraphTopic'
            const newUIID2 = 'paragraphTopic-'+(this.paragraphTopicService.getParagraphTopics().length+1).toString()
            const newOwner2 = this.user 
            const newAllUsersAllowed2 = false 
            const newUserListAllowed2 = []
            const newDateAndTime2 = null 
            const newIsAccepted2 = true 
            const newIsArchived2 = false 
            const newName2 = newParagraph.owner
            const newParagraphFeatureContainer_ids = []

            const newParagraphTopic : ParagraphTopic = new ParagraphTopic ( new_id2, newComponentName2, newUIID2, newOwner2, newAllUsersAllowed2 , 
            newUserListAllowed2, newDateAndTime2, newIsAccepted2, newIsArchived2, newName2, newParagraphFeatureContainer_ids)

      this.createUpdateMultiComponentsService.createPreferredParagraphFeatureContainer_CreateParagraphTopic_UpdateParagraphTopicIndex(this.user, newParagraphFeatureContainer, newParagraphTopic, preferredParagraphTopicIndex)
          .subscribe((res: any)=>{
            console.log(res)

            const newParagraphFeatureContainer = res.featureIndex[0]
            const newParagraphTopic = res.featureIndex[1]
            const newParagraphTopicIndex = res.featureIndex[2]

            this.paragraphFeatureContainerService.paragraphFeatureContainers.push(newParagraphFeatureContainer)
            this.initialNullSetupService.initialNullParagraphFeatureContainers.push(newParagraphFeatureContainer)
            this.paragraphTopicService.paragraphTopics.push(newParagraphTopic)
            this.initialNullSetupService.initialNullParagraphTopics.push(newParagraphTopic)

            const paragraphTopicIndex_index = this.paragraphTopicIndexService.getParagraphTopicIndexes().findIndex(x=> x._id === preferredParagraphTopicIndex._id )
            this.paragraphTopicIndexService.paragraphTopicIndexes[paragraphTopicIndex_index] = newParagraphTopicIndex
            const initialNull_paragraphTopicIndex_index = this.initialNullSetupService.initialNullParagraphTopicIndexes.findIndex(x=> x._id === preferredParagraphTopicIndex._id )
            this.initialNullSetupService.initialNullParagraphTopicIndexes[initialNull_paragraphTopicIndex_index] = newParagraphTopicIndex

            this.dms.QuickCommunicationPopUpComponent({
                      quickComment: 'added to preferred-paragraphs list'
            })
            
          })


  }

  createPreferredParagraphTopicAndAddToParagraphTopicIndex(paragraph: Paragraph  ){

    // add also if user = null for the trials 

            const new_id =  'paragraphTo-'+(this.paragraphTopicService.getParagraphTopics().length+1).toString()
            const newComponentName = 'paragraphTopic'
            const newUIID = 'paragraphTopic-'+(this.paragraphTopicService.getParagraphTopics().length+1).toString()
            const newOwner = this.user 
            const newAllUsersAllowed = false 
            const newUserListAllowed = []
            const newDateAndTime = null 
            const newIsAccepted = true 
            const newIsArchived = false 
            const newName = paragraph.owner
            const newParagraphFeatureContainer_ids = []

            const newParagraphTopic : ParagraphTopic = new ParagraphTopic ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed , 
            newUserListAllowed, newDateAndTime, newIsAccepted, newIsArchived, newName, newParagraphFeatureContainer_ids)

            console.log('createPreferredParagraphFeatureContainerAndAddToPreferredParagraphTopic( true ,  paragraphFeature, newParagraphTopic)')
            this.createPreferredParagraphFeatureContainerAndAddToPreferredParagraphTopic( true ,  paragraph, newParagraphTopic)


  }

  createPreferredParagraphFeatureContainerAndAddToPreferredParagraphTopic(stillToCreateTopic: boolean , paragraph: Paragraph , newParagraphTopic : ParagraphTopic ){

    const new_id = (this.paragraphFeatureContainerService.getParagraphFeatureContainers().length+1).toString()
    const newComponentName = 'paragraphFeatureContainer'
    const newUIID = 'paragraphFeatureContainer-'+(this.paragraphFeatureContainerService.getParagraphFeatureContainers().length+1).toString()
    const newOwner = this.user 
    const newAllUsersAllowed = false
    const newUserListAllowed = []
    const newDateAndTime = null 
    const newIsActive = true 
    const newIsAccepted = true 
    const newName = 'preference'
    const newState = false 
    const newParagraph_id = paragraph._id
    
    const newParagraphFeatureContainer = new ParagraphFeatureContainer ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed,
          newDateAndTime, newIsActive, newIsAccepted, newName, newState, newParagraph_id )

          if ( this.user === null ){

            this.paragraphFeatureContainerService.paragraphFeatureContainers.push(newParagraphFeatureContainer)
           
            if ( stillToCreateTopic === true ){ // we POST NEW paragraphTopic
              console.log('we POST NEW paragraphTopic')

              newParagraphTopic.paragraphFeatureContainer_ids.push(newParagraphFeatureContainer._id)
              console.log(newParagraphTopic)

                this.paragraphTopicService.addParagraphTopic(newParagraphTopic)
                
                 const preferredParagraphTopicIndex: ParagraphTopicIndex =  this.paragraphTopicIndexService.getParagraphTopicIndex_by_type('preferred-paragraphs')
                 preferredParagraphTopicIndex.paragraphTopic_ids.push(newParagraphTopic._id)
                /**
                 * update paragraphTopicIndex 
                 */
                this.updateParagraphTopicIndex(preferredParagraphTopicIndex)
            
            };

            if ( stillToCreateTopic === false){ // we just UPDATE the paragraphTopic

             console.log('we just UPDATE the paragraphTopic')

              newParagraphTopic.paragraphFeatureContainer_ids.push(newParagraphFeatureContainer._id)
              console.log(newParagraphTopic)

                this.paragraphTopicService.paragraphTopics[this.paragraphTopicService.getParagraphTopics().findIndex(x=>x._id === newParagraphTopic._id)] === newParagraphTopic
                this.count = 0
                this.dms.QuickCommunicationPopUpComponent({
                  quickComment: 'added to preferred-paragraphs list'

              })

             }


          }

  }


  updateParagraphTopicIndex(updatedPreferredParagraphTopicIndex: ParagraphTopicIndex){

    if ( this.user === null ){
      this.paragraphTopicIndexService.paragraphTopicIndexes[ this.paragraphTopicIndexService.getParagraphTopicIndexes().findIndex(x=> x._id === updatedPreferredParagraphTopicIndex._id )] = updatedPreferredParagraphTopicIndex
      
      this.count = 0
      this.dms.QuickCommunicationPopUpComponent({
        quickComment: 'added to preferred-paragraphs list'
      }) // END dialog communication 
    } // END IF statement


  }


  ngOnDestroy(){
    this.paragraphShareStatusUpdateSubsciption.unsubscribe()
    this.updateOnUserEmail.unsubscribe()
    this.updateOnceParagraphChildsHaveBeenStored.unsubscribe()
  };





}
