import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule , CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BarQrCodesComponent } from "./bar-qr-codes.component";
import { QrCodeGeneratorListComponent } from './qr-code-generator-list/qr-code-generator-list.component';
import { QrCodeGeneratorItemComponent } from './qr-code-generator-list/qr-code-generator-item/qr-code-generator-item.component';
import { QrCodeGeneratorEditComponent } from './qr-code-generator-list/qr-code-generator-edit/qr-code-generator-edit.component';
import { QRCodeModule } from "angularx-qrcode";
import { PrinterModule } from "../printer/printer.module";
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { NgxPrintModule } from "ngx-print";
import { QrCodeScannerComponent } from "./qr-code-scanner/qr-code-scanner.component";
import { BarCodeScannerComponent } from './bar-code-scanner/bar-code-scanner.component';



@NgModule({
    declarations: [
        BarQrCodesComponent,
        QrCodeGeneratorListComponent,
        QrCodeGeneratorItemComponent,
        QrCodeGeneratorEditComponent,
        QrCodeScannerComponent,
        BarCodeScannerComponent,
    ],

    exports: [
        BarQrCodesComponent,
        QrCodeGeneratorListComponent,
        QrCodeGeneratorItemComponent,
        QrCodeGeneratorEditComponent,
        QrCodeScannerComponent,
        BarCodeScannerComponent ,
        
    ],

    imports: [
        LayoutModule,
        CommonModule,
        BrowserModule,
        QRCodeModule,
        NgxScannerQrcodeModule,
        PrinterModule,
        NgxPrintModule,
    ],

    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]


})

export class BarQrCodesModule{}