import { Injectable } from "@angular/core";
import { MultiEntryCalendar } from "./multi-entry-calendar-list/multi-entry-calendar.model";
import { InitialNullSetupService } from "src/app/services/starting-services/initial-null-setup.service";
import { Subject, Subscription } from "rxjs";
import { StoreFormItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-form-item-plus-childs-confirmation.service";
import { StoreParagraphItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-paragraph-item-plus-childs-confirmation.service";
import { StoreTabItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-tab-item-plus-childs-confirmation.service";


@Injectable ({
    providedIn: 'root' 
})

export class MultiEntryCalendarService {

    constructor( private initialNullSetupService: InitialNullSetupService ,
                 private storeFormItemPlusChildsConfirmationService: StoreFormItemPlusChildsConfirmationService , 
                 private storeTabItemPlusChildsConfirmationService: StoreTabItemPlusChildsConfirmationService ,
                 private storeParagraphItemPlusChildsConfirmationService: StoreParagraphItemPlusChildsConfirmationService 
                 ){}

    multiEntryCalendars: MultiEntryCalendar[] = this.initialNullSetupService.initialNullMultiEntryCalendars

    initialPersonalMultiEntryCalendarsHaveBeenStored = new Subject<void>()
  
    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.multiEntryCalendarsChangedBackToNull
    .subscribe((res)=> {
          this.multiEntryCalendars = res 
          console.log('updated multiEntryCalendars back to null = ', res)

    });

    getMultiEntryCalendar(i: number){
        return this.multiEntryCalendars[i]
    }

    getMultiEntryCalendar_By_id(_id: string){
        const newMultiEntryCalendar: MultiEntryCalendar = this.multiEntryCalendars.find(
            (res)=>{
                return res._id === _id
            }
        )
        return newMultiEntryCalendar
    }

    getMultiEntryCalendars(){
        return this.multiEntryCalendars
    }

    onAddNew(multiEntryCalendar: MultiEntryCalendar){
        this.multiEntryCalendars.push(multiEntryCalendar)
        this.updateInitialComponents(this.multiEntryCalendars)
    }

    addNewMultiEntryCalendars(multiEntryCalendars: MultiEntryCalendar[]){

        for ( let i=0; i<multiEntryCalendars.length ; i++){
              if ( this.multiEntryCalendars.findIndex(x=> x._id === multiEntryCalendars[i]._id ) === -1 ){
                this.multiEntryCalendars.push(multiEntryCalendars[i])
              }
              if ( i === multiEntryCalendars.length-1){
                this.updateInitialComponents(this.multiEntryCalendars)
                console.log(this.multiEntryCalendars )
                this.initialPersonalMultiEntryCalendarsHaveBeenStored.next()
              }
        }
        // console.log(this.multiEntryCalendars )
        // this.initialPersonalMultiEntryCalendarsHaveBeenStored.next()

    }

    addNewMultiEntryCalendar(multiEntryCalendarToAdd: MultiEntryCalendar , isComingFromFormItemCall: boolean  , isComingFromMultiFeatures: boolean){

        if ( this.multiEntryCalendars.findIndex(x=> x._id === multiEntryCalendarToAdd._id) !== -1 ){ 
             const index = this.multiEntryCalendars.findIndex(x=> x._id === multiEntryCalendarToAdd._id)
             this.multiEntryCalendars[index] = multiEntryCalendarToAdd
            this.updateInitialComponents(this.multiEntryCalendars)
        }

        if ( this.multiEntryCalendars.findIndex(x=> x._id === multiEntryCalendarToAdd._id) === -1 ){ 
             this.multiEntryCalendars.push(multiEntryCalendarToAdd)
             this.updateInitialComponents(this.multiEntryCalendars)
        }

        if ( isComingFromFormItemCall === true  ){ this.storeFormItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenFormItemPlusChildsAreStored( multiEntryCalendarToAdd._id) }
        if ( isComingFromFormItemCall === false ){ this.storeParagraphItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenParagraphItemPlusChildsAreStored( multiEntryCalendarToAdd._id) }
        if ( isComingFromMultiFeatures=== false ){ this.storeTabItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenTabItemPlusChildsAreStored( multiEntryCalendarToAdd._id) }
   
        console.log('SHARED multiEntryCalendarToAdd has been added = ',this.multiEntryCalendars)
    }

    updateInitialComponents(updatedMultiEntryCalendars: MultiEntryCalendar[]){
        this.initialNullSetupService.initialNullMultiEntryCalendars = updatedMultiEntryCalendars
    }

    ngOnDestroy(){
        this.updateInitialNullDatafromInitialNullSetupService.unsubscribe()
    }




}