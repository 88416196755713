import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "src/app/app-routing.module";
import { MaterialDesignModule } from "src/app/material-design/material-design.module";
import { SharedModule } from "src/app/shared/shared.module";
import { CheckboxSelectComponent } from "./select-list/checkbox-select/checkbox-select.component";
import { InlineMultiSelectComponent } from "./select-list/inline-multi-select/inline-multi-select.component";
import { RadiusSelectComponent } from "./select-list/radius-select/radius-select.component";
import { RoundSingleSelectComponent } from "./select-list/round-single-select/round-single-select.component";
import { SelectListComponent } from "./select-list/select-list.component";
import { SelectComponent } from "./select.component";
import { DropDownSelectComponent } from './select-list/drop-down-select/drop-down-select.component';
import { DropDownSelectListComponent } from './select-list/drop-down-select/drop-down-select-list/drop-down-select-list.component';
import { DropDownSelectItemComponent } from './select-list/drop-down-select/drop-down-select-list/drop-down-select-item/drop-down-select-item.component';
import { DropDownSelectEditComponent } from './select-list/drop-down-select/drop-down-select-list/drop-down-select-edit/drop-down-select-edit.component';
import { SingleDropDownSelectComponent } from './select-list/single-drop-down-select/single-drop-down-select.component';
import { SingleDropDownSelectListComponent } from './select-list/single-drop-down-select/single-drop-down-select-list/single-drop-down-select-list.component';
import { SingleDropDownSelectItemComponent } from './select-list/single-drop-down-select/single-drop-down-select-list/single-drop-down-select-item/single-drop-down-select-item.component';
import { SingleDropDownSelectEditComponent } from './select-list/single-drop-down-select/single-drop-down-select-list/single-drop-down-select-edit/single-drop-down-select-edit.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { CheckBoxSelectListComponent } from './select-list/checkbox-select/check-box-select-list/check-box-select-list.component';
import { CheckBoxSelectItemComponent } from './select-list/checkbox-select/check-box-select-list/check-box-select-item/check-box-select-item.component';
import { CheckBoxSelectEditComponent } from './select-list/checkbox-select/check-box-select-list/check-box-select-edit/check-box-select-edit.component';
import { AuxiliaryComponentsModule } from "src/app/loading-spinner/auxiliary-components.module";


@NgModule({
    declarations:[
        SelectComponent,
        SelectListComponent,
        RadiusSelectComponent,
        CheckboxSelectComponent,
        InlineMultiSelectComponent,
        RoundSingleSelectComponent,
        DropDownSelectComponent,
        DropDownSelectListComponent,
        DropDownSelectItemComponent,
        DropDownSelectEditComponent,
        SingleDropDownSelectComponent,
        SingleDropDownSelectListComponent,
        SingleDropDownSelectItemComponent,
        SingleDropDownSelectEditComponent,
        CheckBoxSelectListComponent,
        CheckBoxSelectItemComponent,
        CheckBoxSelectEditComponent,

    ],

    exports:[
        SelectComponent,
        SelectListComponent,
        RadiusSelectComponent,
        CheckboxSelectComponent,
        InlineMultiSelectComponent,
        RoundSingleSelectComponent,
        DropDownSelectComponent,
        DropDownSelectListComponent,
        DropDownSelectItemComponent,
        DropDownSelectEditComponent,
        SingleDropDownSelectComponent,
        SingleDropDownSelectListComponent,
        SingleDropDownSelectItemComponent,
        SingleDropDownSelectEditComponent,
        CheckBoxSelectListComponent,
        CheckBoxSelectItemComponent,
        CheckBoxSelectEditComponent,

    ],

    imports:[
            AppRoutingModule,
            CommonModule,
            FormsModule,
            BrowserAnimationsModule,
            MaterialDesignModule, 
            ReactiveFormsModule,
            MatDialogModule,
            LayoutModule,
            MatToolbarModule,
            MatButtonModule,
            MatSidenavModule,
            MatIconModule,
            MatListModule,
            MatExpansionModule,
            SharedModule,
            MatCheckboxModule,
            AuxiliaryComponentsModule
    ],
})
export class SelectModule{}