import { Component, OnInit , Input} from '@angular/core';
import { CheckBoxDetailResult } from './check-box-result.model';

@Component({
  selector: 'app-check-box-result',
  templateUrl: './check-box-result.component.html',
  styleUrls: ['./check-box-result.component.css']
})
export class CheckBoxResultComponent implements OnInit {

  @Input() checkBoxDetailResult: CheckBoxDetailResult
  
  constructor() { }

  ngOnInit(){
  }

  onChangeEvent(){
    this.checkBoxDetailResult = this.checkBoxDetailResult
  }

}
