
  <!-- check on the lesson 202 and on for this form and theorical  -->

<div class="container">
    <div class="row">
         <div class="col-xs-12">
             <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">

                <div class="form-group">
                    <button class="btn btn-primary">edit</button>
                    <button class="btn btn-primary"  (click)="onClickEmailButton()">premi {{emailButton}}</button>
                    <p *ngIf= "setWord" >funzia </p>
                </div> 

                <div class="form-group">
                    <input type="button"
                    class="btn btn-default"
                    id="button2" 
                    formControlName="button2" >
                </div>
<!-- 
                <div class="form-group">
                    <button 
                    id="button1" 
                    class="btn btn-primary"  
                    [attr.aria-label]="actionName"
                    formControlName="button1">
                    {{actionName}} with Aria
                </button>
                </div> -->

                <div formGroupName="userData">

                    <div class="form-group">
                        <label for="username">username</label>
                        <input 
                        type="text"
                        id="username"
                        formControlName="username"
                        class="form-control">
                        <span 
                          *ngIf="!signupForm.get('userData.username').valid && signupForm.get('userData.username').touched " class="help-block" >
                        <span *ngIf="signupForm.get('userData.username').errors['name is forbidden']"> this name is invalid ! </span>
                        <span *ngIf="signupForm.get('userData.username').errors['required']"> this field is required ! </span>
                        </span>
                    </div>
    
    
                       <div class="form-group">
                           <label for="email"> email </label>
                           <input 
                           type="text"
                           id="email"
                           formControlName="email"
                           class="form-control">
                           <span 
                            *ngIf="!signupForm.get('userData.email').valid && signupForm.get('userData.email').touched " class="help-block" >please enter a valid username !
                           </span>
                       </div>

                </div>

                 

                   <div class="radio" *ngFor="let gender of genders">
                       <label>
                           <input 
                           type="radio"
                           formControlName="gender"
                           [value]="gender">
                           {{gender}}
                       </label>

                   </div>

                   <div formArrayName="hobbies">
                       <h4>your hobbies</h4>
                       <button
                       class="btn btn-default"
                       type="button"
                       (click)="onAddHobby()"> add hobby </button>

                       <div class="form-group" 
                       *ngFor="let hobbyControl of getControls(); let i=index">
                           <input type="text" class="form-control" [formControlName]="i">
                       </div>
                   </div>


 
                   <span 
                    *ngIf="!signupForm.valid && signupForm.touched " class="help-block" >please enter a valid data  !
                   </span>

                   <button class="btn btn-primary" type="submit"> submit </button>
                
             </form>
         </div>
    </div>
</div>