import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { Header } from './header.model';
import { AuthService } from '../services/auth-services/auth.service';
import { InitialNullSetupService } from '../services/starting-services/initial-null-setup.service';


@Injectable({
  providedIn: 'root'
})
export class HeaderService {


  resetResultsPageWithZeroSelected = new Subject<void>()


  constructor(){}
      

/**
 * changing the status of the buttons color  in header 
 */
 startingHeaderIndexSelection: Header = new Header ( false, false, false, false, false, false, false, false )
 /**
  * change status of FeatureIndexTopic
  */
 private headerIndexSelection = new BehaviorSubject<Header>(this.startingHeaderIndexSelection)
 sharedHeaderIndexSelection = this.headerIndexSelection.asObservable()

 nextIndexSelection(headerIndexSelection: Header){
   this.headerIndexSelection.next(headerIndexSelection)
 }

 /**
  * changing the status of paragraph edit into component edit 
  */
 startingEditComponentSelection: boolean = false
 /**
  * change the status on click edit feature / feature editing done 
  */
 private componentIndexSelection = new BehaviorSubject<boolean>(this.startingEditComponentSelection)
 sharedComponentIndexSelection = this.componentIndexSelection.asObservable()

 nextComponentIndexSelection(componentIndexSelection: boolean){
  this.componentIndexSelection.next(componentIndexSelection)
 }


 resetResultsPageWithNoSelected(){
  console.log('subscription  to reset results')
  this.resetResultsPageWithZeroSelected.next()
 }

 

 



   







}