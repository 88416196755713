<app-loading-spinner *ngIf="isLoadingList"></app-loading-spinner>

<mat-card *ngIf="!isLoadingList" class="mat-elevation-z0"> 

<mat-tab-group #tabs 
[(selectedIndex)]="selectedIndex"
class="remove-border-bottom"
header-less-tabs
(selectedTabChange)="onLinkClick($event)"    
headerPosition="below" 
animationDuration="0ms" >


<br>

      <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

      <mat-tab *ngFor="let tab of tabList; index as i ">

           <app-tab-item *ngIf="!isLoading"
                         [isParagraphEdit]="isParagraphEdit" 
                         [feature]="feature_from_paragraphEdit"
                         [isUnderTabs]="isUnderTabs"
                         [noNeeded]="false" 
                         [index_id]="tab._id"
                         [paragraphEdit_selectedIndex_id]="feature_from_paragraphEdit.feature_id">
            </app-tab-item>


                <ng-template mat-tab-label>
                <a style="cursor: pointer">
                     {{i+1}} - {{tab.name}}  </a>
                </ng-template>
              

          
   
      </mat-tab>  
      
      <div *ngIf="createButtonLimit" >
          <mat-tab disabled > 
               <ng-template  mat-tab-label>
                    <a  (click)="Create()"
                        [matTooltip]="matToolTipMessageOnCreateNew"
                         matTooltipPosition="right"> 
                         create new 
                    </a>
               </ng-template>
           </mat-tab>
      </div>

      <div *ngIf="tabList.length > 1">
          <div *ngIf="createButtonLimit">
              <mat-tab disabled > 
                   <ng-template  mat-tab-label>
                        <a> create copy of </a>
                   </ng-template>
               </mat-tab>
          </div>
     </div>

      

</mat-tab-group>

</mat-card>
