import { Injectable, OnDestroy } from '@angular/core';
import { Subject , Subscription } from 'rxjs';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { ParagraphTopicIndex } from './paragraph-topic-index.model';
import { ParagraphTopic } from './paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.model';
import { ParagraphFeatureContainerService } from './paragraph-index-topic-list/paragraph-index-topic/paragraph-feature-container.service';
import { ParagraphTopicService } from './paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.service';
import { ParagraphFeatureContainer } from './paragraph-index-topic-list/paragraph-index-topic/paragraph-feature-container.model';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';




@Injectable ({
    providedIn: 'root' 
})

export class ParagraphTopicIndexService implements OnDestroy {

    constructor( private initialNullSetupService: InitialNullSetupService,
                 private paragraphTopicService: ParagraphTopicService ,
                 private userObjectUpdateService: UserObjectUpdateService,
                 private paragraphFeatureContainerService: ParagraphFeatureContainerService
                 ){}

    
    user = this.userObjectUpdateService.userEmail

    updateOnUserEmail : Subscription = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
        this.user = updatedUserEmail
        console.log('updated USER = ', this.user)
    });
    
    foundEmailList: string[] = []
    
    updatePublicParagraphTopicIndexes = new Subject<void>()
    stopLoadingOnParagraphTopicIndex = new Subject<void>()

   

    paragraphTopicIndexes: ParagraphTopicIndex[] = this.initialNullSetupService.initialNullParagraphTopicIndexes


    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.paragraphTopicIndexesChangeBackToNull
   .subscribe((res)=> {
          this.paragraphTopicIndexes = res 
          console.log('updated paragraphTopics back to null = ', res)
    });

     updateOrCreateParagraphTopicOnParagraphFeatureContainerCallOnAddNew: Subscription = this.paragraphFeatureContainerService.paragraphFeatureContainerPlusOnwerAndIndexType
    .subscribe((arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex: any[])=>{
      console.log(arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex)

      const startingCount: number = 0

      this.storeThreadAndResolveOneByOne( startingCount , arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex ) // send to counter and initiate count at 0 
    })


    getParagraphTopicIndexes(){
        return this.paragraphTopicIndexes
    }

    getParagraphTopicIndex_by_id(_id: string){
        const newParagraphTopicIndex = this.paragraphTopicIndexes.find(
            (res)=> {
                return res._id === _id 
            }
        )
        return newParagraphTopicIndex
    }

    getParagraphTopicIndex_by_type(type: string){
        const newParagraphTopicIndex = this.paragraphTopicIndexes.find(
            (res)=> {
                return res.type === type
            }
        )
        return newParagraphTopicIndex
    }

    /**
     * beging thread of 3 functions with counter starting at 0 and return from this beginning at the 3rd function with +1 
     * and as long as counter < array.lenght then we continue to run the loop 
     */
    addingNewSharedParagraphAndParentsToDOM = new Subject<void>()
    storeThreadAndResolveOneByOne( count: number , arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex: any[]  ){

        if ( count < arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex.length ){
            console.log(arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex, arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex.length, count)
            this.addToOrCreateNewParagraphTopic( arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex, count  ) 
            
        }

        if ( count === arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex.length ){
             console.log('count / array lenght',count, arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex.length)
          
             this.addingNewSharedParagraphAndParentsToDOM.next()
              
        }
        
    };

    addToOrCreateNewParagraphTopic( arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex: any[], count: number  ){

        console.log('its going ')

        const paragraphTopicIndexOfReference: ParagraphTopicIndex = this.paragraphTopicIndexes.find(x=> x.type === arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex[count].typeOfIndex) // find paragraphTopicIndex shared / public type 
        console.log(paragraphTopicIndexOfReference)
        const paragraphTopicsOfReference: ParagraphTopic[] = []
         
        /**
         * check if paragraphTopicIndexOfReference.paragraphTopic_ids.length > 0
         */
        // if ( paragraphTopicIndexOfReference.paragraphTopic_ids.length > 0 ){
            for ( let i=0; i<paragraphTopicIndexOfReference.paragraphTopic_ids.length ; i++ ){

                paragraphTopicsOfReference.push(this.paragraphTopicService.getParagraphTopicBy_id(paragraphTopicIndexOfReference.paragraphTopic_ids[i]))
    
            }
            
        // }
        console.log('EXISTING SHARED PARAGRAPH TOPICS OF REFERENCE',paragraphTopicsOfReference)

        this.checkIfParagraphTopicAlreadyExistWithin(arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex , count , paragraphTopicsOfReference  )
     
    };



      checkIfParagraphTopicAlreadyExistWithin(arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex: any[] ,  count: number , paragraphTopicsOfReference: ParagraphTopic[]  ){

        console.log('its going ', arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex )
        const receivingParagraph_owner = arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex[count].receivingParagraph_owner
        const paragraphFeatureContainer: ParagraphFeatureContainer = arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex[count].paragraphFeatureContainer
        const typeOfIndex = arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex[count].typeOfIndex


        if ( paragraphTopicsOfReference.findIndex(x=>x.name === receivingParagraph_owner ) !== -1){
            console.log('check existing PARAGRAPH TOPIC , and check if paragraphFeature is already there or add ')

            const existingParagraphTopic = paragraphTopicsOfReference[paragraphTopicsOfReference.findIndex(x=> x.name === receivingParagraph_owner) ]
            /**
             * check if paragraphFeatureContainer_id already exist on existingParagraphTopic , if it doesn't we need to add
             */
            if ( existingParagraphTopic.paragraphFeatureContainer_ids.findIndex(x=>x === paragraphFeatureContainer._id ) !== -1 ) {
                 console.log( 'paragraphFeatureContainer already exist ')
                 // then continu the loop
                 const counter = count+1
                 console.log(counter )
                 this.storeThreadAndResolveOneByOne(  counter , arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex  )
                }

            if ( existingParagraphTopic.paragraphFeatureContainer_ids.findIndex(x=>x === paragraphFeatureContainer._id ) === -1 ) {

            console.log('its going ')
            const new_id = existingParagraphTopic._id
            const newComponentName = existingParagraphTopic.componentName
            const newUIID = existingParagraphTopic.UIID
            const newOwner = existingParagraphTopic.owner
            const newAllUsersAllowed = existingParagraphTopic.allUsersAllowed
            const newUserListAllowed = existingParagraphTopic.userListAllowed
            const newDateAndTime = existingParagraphTopic.dateAndTime
            const newIsAccepted = existingParagraphTopic.isAccepted
            const newIsArchived = existingParagraphTopic.isArchived 
            const newName = existingParagraphTopic.name
            const newParagraphFeatureContainer_ids = existingParagraphTopic.paragraphFeatureContainer_ids

            newParagraphFeatureContainer_ids.push(paragraphFeatureContainer._id)

            const newParagraphTopic : ParagraphTopic = new ParagraphTopic ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed , 
            newUserListAllowed, newDateAndTime, newIsAccepted, newIsArchived, newName, newParagraphFeatureContainer_ids)
            

            if ( typeOfIndex === 'shared-paragraphs' ){

                this.paragraphFeatureContainerService.paragraphFeatureContainers.push(paragraphFeatureContainer)
                this.initialNullSetupService.initialNullParagraphFeatureContainers.push(paragraphFeatureContainer)
                this.paragraphTopicService.paragraphTopics[this.paragraphTopicService.getParagraphTopics().findIndex(x=> x._id === newParagraphTopic._id )] = newParagraphTopic
                const indexNullParagTopic = this.initialNullSetupService.initialNullParagraphTopics.findIndex(x=> x._id === newParagraphTopic._id )
                this.initialNullSetupService.initialNullParagraphTopics[indexNullParagTopic] = newParagraphTopic

                const counter = count+1
                    
                this.storeThreadAndResolveOneByOne( counter , arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex  )
                    
            }
            if ( typeOfIndex === 'public-paragraphs' ){
                 this.paragraphTopicService.paragraphTopics[this.paragraphTopicService.getParagraphTopics().findIndex(x=> x._id === newParagraphTopic._id )] = newParagraphTopic
                 console.log( newParagraphTopic)

                 const counter = count+1
                 console.log(counter )
                 this.storeThreadAndResolveOneByOne(  counter , arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex  )
            }

            

            };// end of 2nd if statement

         }; // end 1st if statement 


    if ( paragraphTopicsOfReference.findIndex(x=>x.name === receivingParagraph_owner ) === -1){
            
            console.log('CREATING A NEW PARAGRAPHFEATURECONTAINER AND ADD TO PARAGRAPHTOPIC + ADD PARAGRAPHTOPIC_ID TO PARAGRAPHTOPICINDEX')
    /**
     * creating new paragraphTopic
     */


    if ( typeOfIndex === 'shared-paragraphs' ){
            const new_id = 'paragraphTo-'+(this.paragraphTopicService.getParagraphTopics().length+1).toString()
            const newComponentName = 'paragraphTopic'
            const newUIID = 'paragraphTo-'+(this.paragraphTopicService.getParagraphTopics().length+1).toString()
            const newOwner = this.user
            const newAllUsersAllowed = false 
            const newUserListAllowed = []
            const newDateAndTime = null 
            const newIsAccepted = false
            const newIsArchived = false
            const newName = receivingParagraph_owner 
            const newParagraphFeatureContainer_ids: string[] = []

            newParagraphFeatureContainer_ids.push(paragraphFeatureContainer._id)
            

            const newParagraphTopic : ParagraphTopic = new ParagraphTopic ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed , 
            newUserListAllowed, newDateAndTime, newIsAccepted, newIsArchived, newName, newParagraphFeatureContainer_ids)
            
            console.log(newParagraphTopic)
       
                /**
                 * updating existing paragraphTopicIndex 
                 */

               const paragraphTopicIndexReference = this.paragraphTopicIndexes[this.paragraphTopicIndexes.findIndex(x=>x.type === typeOfIndex )]

               console.log(paragraphTopicIndexReference)
               const newparagraphTopicIndex_id = paragraphTopicIndexReference._id
               const newparagraphTopicIndexComponentName = paragraphTopicIndexReference.componentName
               const newparagraphTopicIndexUIID = paragraphTopicIndexReference.UIID 
               const paragraphTopicIndexOwner = paragraphTopicIndexReference.owner
               const paragraphTopicIndexAllUsersAllowed = paragraphTopicIndexReference.allUsersAllowed
               const paragraphTopicIndexUserListAllowed = paragraphTopicIndexReference.userListAllowed
               const paragraphTopicIndexDateAndTime = null 
               const paragraphTopicIndexType = paragraphTopicIndexReference.type

               const paragraphTopicIndexParagraphTopic_ids = paragraphTopicIndexReference.paragraphTopic_ids
               paragraphTopicIndexParagraphTopic_ids.push(newParagraphTopic._id)

               console.log(paragraphTopicIndexReference)


        
               const newParagraphTopicIndex = new ParagraphTopicIndex ( newparagraphTopicIndex_id, newparagraphTopicIndexComponentName, newparagraphTopicIndexUIID, paragraphTopicIndexOwner , 
               paragraphTopicIndexAllUsersAllowed, paragraphTopicIndexUserListAllowed, paragraphTopicIndexDateAndTime, paragraphTopicIndexType, paragraphTopicIndexParagraphTopic_ids    )

               console.log(newParagraphTopicIndex)
               this.paragraphFeatureContainerService.paragraphFeatureContainers.push(paragraphFeatureContainer)
               this.initialNullSetupService.initialNullParagraphFeatureContainers.push(paragraphFeatureContainer)
               this.paragraphTopicService.addParagraphTopic(newParagraphTopic)
               this.initialNullSetupService.initialNullParagraphTopics.push(newParagraphTopic)
               this.paragraphTopicIndexes[this.paragraphTopicIndexes.findIndex(x=>x.type === typeOfIndex )] = newParagraphTopicIndex
               this.initialNullSetupService.initialNullParagraphTopicIndexes[this.initialNullSetupService.initialNullParagraphTopicIndexes.findIndex(x=>x.type === typeOfIndex)] = newParagraphTopicIndex


               const counter = count+1
               console.log(counter )

           
               this.storeThreadAndResolveOneByOne( counter , arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex  )
                

    }

    if ( typeOfIndex === 'public-paragraphs'){

            const new_id = 'paragraphTo-'+(this.paragraphTopicService.getParagraphTopics().length+1).toString()
            const newComponentName = 'paragraphTopic'
            const newUIID = 'paragraphTo-'+(this.paragraphTopicService.getParagraphTopics().length+1).toString()
            const newOwner = this.paragraphTopicIndexes[0].owner
            const newAllUsersAllowed = false 
            const newUserListAllowed = []
            const newDateAndTime = null 
            const newIsAccepted = true 
            const newIsArchived = false 
            const newName = receivingParagraph_owner 
            const newParagraphFeatureContainer_ids = [paragraphFeatureContainer._id]

            const newParagraphTopic : ParagraphTopic = new ParagraphTopic ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed , 
            newUserListAllowed, newDateAndTime, newIsAccepted, newIsArchived, newName, newParagraphFeatureContainer_ids)

            /**
             * add fake paragraphTopic 
             */
                this.paragraphTopicService.addParagraphTopic(newParagraphTopic)
                this.initialNullSetupService.initialNullParagraphTopics.push(newParagraphTopic)

                /**
                 * updating existing paragraphTopicIndex 
                 */

               const paragraphTopicIndexReference = this.paragraphTopicIndexes[this.paragraphTopicIndexes.findIndex(x=>x.type === typeOfIndex )]

               const newparagraphTopicIndex_id = paragraphTopicIndexReference._id
               const newparagraphTopicIndexComponentName = paragraphTopicIndexReference.componentName
               const newparagraphTopicIndexUIID = paragraphTopicIndexReference.UIID
               const paragraphTopicIndexOwner = paragraphTopicIndexReference.owner
               const paragraphTopicIndexAllUsersAllowed = paragraphTopicIndexReference.allUsersAllowed
               const paragraphTopicIndexUserListAllowed = paragraphTopicIndexReference.userListAllowed
               const paragraphTopicIndexDateAndTime = null 
               const paragraphTopicIndexType = paragraphTopicIndexReference.type
               const paragraphTopicIndexParagraphTopic_ids = paragraphTopicIndexReference.paragraphTopic_ids
               
               paragraphTopicIndexParagraphTopic_ids.push(newParagraphTopic._id)

               const newParagraphTopicIndex = new ParagraphTopicIndex ( newparagraphTopicIndex_id, newparagraphTopicIndexComponentName, newparagraphTopicIndexUIID, paragraphTopicIndexOwner , 
               paragraphTopicIndexAllUsersAllowed, paragraphTopicIndexUserListAllowed, paragraphTopicIndexDateAndTime, paragraphTopicIndexType, paragraphTopicIndexParagraphTopic_ids    )

            /**
             *  temporary update on paragraphTopicIndex 
             */

              this.paragraphTopicIndexes[this.paragraphTopicIndexes.findIndex(x=>x.type === typeOfIndex )] = newParagraphTopicIndex
              this.initialNullSetupService.initialNullParagraphTopicIndexes[this.initialNullSetupService.initialNullParagraphTopicIndexes.findIndex(x=>x.type === typeOfIndex )] = newParagraphTopicIndex

              const counter = count+1
              console.log(counter )
              this.storeThreadAndResolveOneByOne( counter , arrayOfParagraphFeatureContainerAndOwnerAndTypeOfIndex  )

              this.stopLoadingOnParagraphTopicIndex.next()

              }
        
         } // end if statement 
      }
    
    deletePublicParagraphTopicItem(_id: string){

     const newParagraphTopicIndex: ParagraphTopicIndex = this.paragraphTopicIndexes[this.paragraphTopicIndexes.findIndex(x=> x.type === 'public-paragraphs')]
     const newParagraphTopic_ids = newParagraphTopicIndex.paragraphTopic_ids

     newParagraphTopic_ids.splice(newParagraphTopic_ids.findIndex(x=> x === _id), 1)

     newParagraphTopicIndex.paragraphTopic_ids = newParagraphTopic_ids
     console.log( newParagraphTopicIndex)
        
     this.paragraphTopicIndexes[this.paragraphTopicIndexes.findIndex(x=> x.type === 'public-paragraphs')] = newParagraphTopicIndex

     this.updatePublicParagraphTopicIndexes.next()
    }

    ngOnDestroy(): void {
        this.updateOnUserEmail.unsubscribe()
        this.updateInitialNullDatafromInitialNullSetupService.unsubscribe()
        this.updateOrCreateParagraphTopicOnParagraphFeatureContainerCallOnAddNew.unsubscribe()

        
    }


}