<!-- GENERAL NAME INPUT FOR ANY COMPONENT EDITING ITS NAME  -->
<!-- NEED TO @INPUT() ITS LABEL TO FROM PARENT -->
<!-- NEED TO @OUTPUT() ANY CHANGE MADE  -->

<form [formGroup]="editFormName" (ngSubmit)="onSubmitName()">
    
    
    <div class="row">
        <div class="col">
            <mat-form-field style="width: 250px" appearance="outline">
                <mat-label *ngIf="inputLabelInterface.exist">{{inputLabelInterface.name}}</mat-label>
                <input 
                matInput
                type="text"
                id="name"
                formControlName="name"
                [matAutocomplete]="auto" >


                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                      {{ option }}
                    </mat-option>
                </mat-autocomplete>

            </mat-form-field>
            <mat-error *ngIf="!editFormName.get('name').valid && inputLabelInterface.errorHandler" >Please enter a valid name, maximum {{inputLabelInterface.maxLength}} letters </mat-error>
        </div>
    </div>

</form>
