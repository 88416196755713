import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { DropDownSelect } from '../../drop-down-select.model';
import { DropDownSelectDescription } from '../../drop-down-select-description.model';
import { DropDownSelectService } from '../../drop-down-select.service';
import { DropDownSelectDetailResult } from '../../../../../../../../../../../form-results/form-results-list/form-result-item/paragraph-results/feature-results/drop-down-select-result/drop-down-select-detail-result.model'
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { Feature } from '../../../../../feature.model';
import { FeatureResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/feature-result.model';
import { Subscription, Observable,  } from 'rxjs';
import { DialogmanagerService } from 'src/app/services/other-components-services/dialogmanager.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { FormService } from 'src/app/forms/form.service';





@Component({
  selector: 'app-drop-down-select-item',
  templateUrl: './drop-down-select-item.component.html',
  styleUrls: ['./drop-down-select-item.component.css']
})
export class DropDownSelectItemComponent implements OnInit {

  user: string 

  dropDownSelect: DropDownSelect
  dropDownselects: DropDownSelect[]

  @Input() index_id: string 
  index: number

  @Input() isParagraphEdit: boolean
  @Input() feature: Feature

  @Input() isTabItem: boolean 
  @Input() tab_index: number 

  featureResult: FeatureResult;
 

  /**
   * receiving the click from fixTextList to edit the component
   */
  @Input() subscriptionToEditComponentItemIsOn: boolean
  currentFeature: Feature
  @Input() editFeatureItem: Observable<void>;
  editFeatureEventFromCalculatorMixTableListList: Subscription; 


  mailTextFeatureBodyPart: string

  // new objects to let select works
  descriptionList: DropDownSelectDescription[]
  descriptionListContents: string[]

  dropDownSelectDetailResults: DropDownSelectDetailResult[]

  @Output() updateBothFeatureResultAndTextPlusIndex = new EventEmitter<any>()

  requestToFinalizeResultAgain: Subscription 


  constructor( private dropDownSelectService: DropDownSelectService,
               private userObjectUpdateService: UserObjectUpdateService ,
               private formService: FormService,
               private _fb: FormBuilder) { }

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

    this.index = this.dropDownSelectService.getDropDownSelects().findIndex( x => x._id === this.index_id)
    this.dropDownSelect = this.dropDownSelectService.getDropDownSelect(this.index)

    console.log('feature = ', this.feature, this.dropDownSelect)
    /**
     * DropDownSelectDetailResult{

    constructor (  public descriptionResult: string ,
                   public contentResult: string ){}
     */
     const newDropDownSelectDetailResults: DropDownSelectDetailResult[] = []

    for (let i=0; i<this.dropDownSelect.descriptionList.length; i++){
      const newDescriptionResult = this.dropDownSelect.descriptionList[i].name
      const newContentResult = '???'

      const newDropDownSelectDetailResult = new DropDownSelectDetailResult (newDescriptionResult, newContentResult)
      newDropDownSelectDetailResults.push(newDropDownSelectDetailResult)
    }
    this.dropDownSelectDetailResults = newDropDownSelectDetailResults

    console.log(this.dropDownSelectDetailResults);

      /**
 * preparing featureResult in case no date is selected , we use today date 
 */
       const newFeatureResultType = 'dropDownSelectDetailResult';
       const newFeatureResultName = this.dropDownSelect.name;
 
       const newFeatureResult = new FeatureResult ( newFeatureResultType, newFeatureResultName, this.dropDownSelectDetailResults )
 
       this.featureResult = newFeatureResult
       console.log(this.featureResult)

    /**
     * compose emailTextBodyPart
     */
    this.mailTextFeatureBodyPart = '%0D%0A'
    for ( let j=0; j<this.dropDownSelect.descriptionList.length; j++){
      this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent(this.dropDownSelect.descriptionList[j].name)+encodeURIComponent(':')+'%20'
      this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent('???')
      this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+'%20%20%20'
    }


      this.requestToFinalizeResultAgain = this.formService.getRequestToFinalizeResultAgain().subscribe(()=> this.onSend())
    
      setTimeout(()=>{
        this.onSend()
      })

    console.log(this.index)


    if (this.isParagraphEdit === true){
    this.editFeatureEventFromCalculatorMixTableListList = this.editFeatureItem.subscribe((data)=> this.checkIfMatchId(data))
   }


  }

  selectDescription( i: number, $event){
    console.log(i, $event.value)

    this.dropDownSelectDetailResults[i].contentResult = $event.value

    this.featureResult.featureDetailResults[i].contentResult = $event.value


      /**
     * compose emailTextBodyPart
     */
       this.mailTextFeatureBodyPart = '%0D%0A'
       for ( let j=0; j<this.dropDownSelectDetailResults.length; j++){
         this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent(this.dropDownSelectDetailResults[j].descriptionResult)+encodeURIComponent(':')+'%20'
         this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent(this.dropDownSelectDetailResults[j].contentResult)
         this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+'%20%20%20'
       }

    console.log(this.dropDownSelectDetailResults)

    if (this.isParagraphEdit !== true){
      setTimeout(()=>{
        this.onSend()
      })
   } 

  }
  

  checkIfMatchId(data){
    this.currentFeature = data ;
    console.log(this.currentFeature)

    if (this.currentFeature.feature_id === this.index_id){
      this.openDialogSingleDateCalendarEdit()
    }
  }

  openDialogSingleDateCalendarEdit(){
   

  }


  onSend(){
  this.onUpdateFeatureResultEvent();
  };


  onUpdateFeatureResultEvent(){

    if ( this.isTabItem === true){ this.tab_index = this.tab_index }
    if ( this.isTabItem !== true){ this.tab_index = null }

    const newTab_index = this.tab_index

    const newFeatureDrop_Id = this.feature.dropId
    const newFeatureResult: FeatureResult = this.featureResult
    const newMailTextFeatureBodyPart = this.mailTextFeatureBodyPart

    this.updateBothFeatureResultAndTextPlusIndex.emit({ newTab_index , newFeatureDrop_Id, newFeatureResult, newMailTextFeatureBodyPart})

  };

  ngOnDestroy(){
      
     this.requestToFinalizeResultAgain.unsubscribe()
  }


}
