import { Component, OnInit , Input} from '@angular/core';
import { Subscription } from 'rxjs';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { FeatureContainerListItem } from '../../../feature-container-list-item.model';
import { FeatureContainerListItemService } from './feature-container-list-items.service';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-feature-container-list-item',
  templateUrl: './feature-container-list-item.component.html',
  styleUrls: ['./feature-container-list-item.component.css']
})
export class FeatureContainerListItemComponent implements OnInit {

  user: string 

  isProduction: boolean 
  isFeatureContainerListItem_ForDevelopment_Only: boolean 
  featureContainerListItem_development_list: string[] = ['multiEntryCalendar', 'oneDateCalendar' ]

  @Input() featureContainerListItem_id: string
  @Input() isStandardFeature: boolean 
  @Input() isTab: boolean 
  
  featureContainerListItem: FeatureContainerListItem

  updateOnUserEmail: Subscription

  featureContainerListItemIsLoaded: boolean = false

  selectedFeatureContainer_id: string 
  selectedFeatureContainer_id_index: number = 0 

  constructor( private featureContainerListItemService: FeatureContainerListItemService,
               private userObjectUpdateService: UserObjectUpdateService) {

                this.isProduction = environment.production 
                }


  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail
    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
  });

    this.featureContainerListItemIsLoaded = true 

    this.featureContainerListItem = this.featureContainerListItemService.getFeatureContainerListItemBy_id(this.featureContainerListItem_id)
    console.log(this.featureContainerListItem)

    if ( this.isProduction ){
      if ( this.featureContainerListItem_development_list.findIndex(x=> x === this.featureContainerListItem.name ) !== -1 ){ this.isFeatureContainerListItem_ForDevelopment_Only = true }
      if ( this.featureContainerListItem_development_list.findIndex(x=> x === this.featureContainerListItem.name ) === -1 ){ this.isFeatureContainerListItem_ForDevelopment_Only = false }
    }
    if ( !this.isProduction ){ this.isFeatureContainerListItem_ForDevelopment_Only = false }

    if ( this.user === null ){ this.selectedFeatureContainer_id = this.featureContainerListItem.featureContainer_ids[this.selectedFeatureContainer_id_index] }
    if ( this.user !== null ){ 
        const lastIndex: number = this.featureContainerListItem.featureContainer_ids.length-1
        this.selectedFeatureContainer_id = this.featureContainerListItem.featureContainer_ids[lastIndex] 
    }
    
    

    setTimeout(()=>{
      this.featureContainerListItemIsLoaded = false 
    },100)
  
  };

  ngOnDestroy(){
    this.updateOnUserEmail.unsubscribe()
  }

}
