import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormResult } from 'src/app/forms/form-results/form-result.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';


@Component({
  selector: 'app-form-item-sent-dialog',
  templateUrl: './form-item-sent-dialog.component.html',
  styleUrls: ['./form-item-sent-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FormItemSentDialogComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<FormItemSentDialogComponent>,
               private router: Router,
               private route: ActivatedRoute,
               private userObjectUpdateService: UserObjectUpdateService ,
               @Inject(MAT_DIALOG_DATA) public editData: any ) { }


  user: string 
  finalSentFormResult: FormResult 
  chat_id: string
  
  
  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

    this.finalSentFormResult = this.editData.finalSentFormResult
    this.chat_id = this.editData.chat_id

    console.log(this.finalSentFormResult)
    console.log(this.chat_id)

    console.log('is waiting to close in 10" ')
    setTimeout(()=> {
      this.dialogRef.close()
    }, 12000)
    console.log('time gone')

  };


  onRouterToChatItem(){
    this.router.navigate(['../groups', this.chat_id ], { relativeTo: this.route} )
    
  };

}
