import { Component, OnInit, Inject , HostListener} from '@angular/core';
import { MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Chat } from 'src/app/chats/chat.model';
import { DateTime } from 'luxon';
import { Subscription, Subject, Observable,  Observer, fromEvent, merge, } from 'rxjs';
import { map } from 'rxjs/operators';
import { Recipient } from 'src/app/forms/form-list/form-item/recipient/recipient.model';
import { Paragraph } from 'src/app/forms/form-list/form-item/paragraph/paragraph.model';
import { ParagraphService } from 'src/app/forms/form-list/form-item/paragraph/paragraph.service';
import { ParagraphResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/paragraph-result.model';
import { FormResult } from 'src/app/forms/form-results/form-result.model';
import { Sender } from 'src/app/forms/form-results/form-results-list/form-result-item/sender/sender.model';
import { Receiver } from 'src/app/forms/form-results/form-results-list/form-result-item/receiver/receiver.model';
import { ChatService } from 'src/app/chats/chat.service';
import { FormResultsService } from 'src/app/forms/form-results/form-results.service';
import { FormResultHttpRequestService } from 'src/app/forms/form-results/form-result-http-request.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { ParagraphFeature } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature.model';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';



@Component({
  selector: 'app-chat-fill-form-from-bottom-sheet',
  templateUrl: './chat-fill-form-from-bottom-sheet.component.html',
  styleUrls: ['./chat-fill-form-from-bottom-sheet.component.css']
})
export class ChatFillFormFromBottomSheetComponent implements OnInit {



// @HostListener('document:visibilitychange', ['$event']) documentVisibility($event: VisibilityObserver){ console.log('visibility ', $event)}

user: string 

// chat: Chat = this.data.chat
recipient: Recipient = this.data.recipient
paragraph: Paragraph = this.data.paragraph
paragraphName: string = this.data.paragraph.name 

paragraphFeature: ParagraphFeature 
paragraphFeatures: ParagraphFeature[]

subjectList: string[] =  this.data.subjectList
paragraphTopicName: string =  this.data.paragraphTopicName


recipientResult: Recipient;
senderChatPartecipants : string[];
receiverChatPartecipants: string[]

paragraphResult: ParagraphResult;
paragraphResults: ParagraphResult[];
sentParagraphResult: ParagraphResult;
sentParagraphResults: ParagraphResult[];
receivedParagraphResult: ParagraphResult;
receivedParagraphResults: ParagraphResult[];

finalSentParagraphResult: ParagraphResult;
finalSentParagraphResults: ParagraphResult[] = [];

finalReceivedParagraphResult: ParagraphResult;
finalReceivedParagraphResults: ParagraphResult[] = [];

finalSentFormResult: FormResult;
finaleReceivedFormResult: FormResult; 

timeAtSendForm: string;
subscribeClickEvent: Subject<void> = new Subject<void>();
subscribeFormNewSubject: Subject<void> = new Subject<void>()


 chatReceiver : Receiver ; 
 sentToMessageList: string[];

 topicName: string;

 isForwardToMail: boolean
 isSentToMessage: boolean 
 receiver: Receiver 

// new properties to check which chats has the same subject 

 /**
  * possible chat numbers 
  */
  futureFormResultChat_id : string 
  futureFormResultChatId : number 

  chatExist : boolean 
  chatExistOnIndex: number 
  chatHasToBeCreated: boolean

  /**
   * properties to use button and select the right subject 
   */
  selectedSubject: string
  count: number

  finalSubject: string 

  // only 1 subject in the chat 

  only1Subject: boolean ;
  addSubject: boolean ; 
  giveSubjectButtonOption: boolean 

  updateOnUserEmail: Subscription

  messageHasBeenSent: boolean



  constructor( private _bottomSheetRef: MatBottomSheetRef<ChatFillFormFromBottomSheetComponent>,
               private formResultsService: FormResultsService,
               private chatService: ChatService,
               private _bottomSheet: MatBottomSheet ,
               private userObjectUpdateService: UserObjectUpdateService ,
               private postComponentsHttpRequestService: PostComponentsHttpRequestService ,
               @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }


  ngOnInit(): void {

  this.user = this.userObjectUpdateService.userEmail

  this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
        .subscribe((updatedUserEmail)=>{
         this.user = updatedUserEmail
        console.log('updated USER = ', this.user)
    })

  console.log( this.user , 
              this.data, 
              // this.chat, 
              this.recipient, 
              this.paragraphName, 
              this.paragraph, 
              this.paragraphTopicName, 
              this.subjectList )

  
    document.onvisibilitychange = function() {
    if (document.visibilityState === 'hidden') { console.log('is hidden',  DateTime.now().toString())}
    if (document.visibilityState !== 'hidden') { console.log('is visible', DateTime.now().toString())}
  };

  this.messageHasBeenSent = false 

  if (this.subjectList.length === 1){
    this.only1Subject = true
  } else { 
    this.only1Subject = false 
  }

  this.addSubject = false
  this.giveSubjectButtonOption = true


  this.count = this.subjectList.length-1
   console.log(this.selectedSubject)

   this.selectedSubject = this.subjectList[this.count]

if (this.selectedSubject === undefined){
  this.giveSubjectButtonOption = false
  this.addSubject = true
}
/**
 * **********************************************************************************************
 */

    this.topicName = this.data.paragraphTopicName;
    // this.chat = this.data.dialogChat;
    this.recipient = this.data.recipient
  /**
   * for formResult
   */
   this.recipientResult = this.recipient;

    this.paragraphName = this.data.paragraph.name;
    this.paragraph = this.data.paragraph;
    this.sentParagraphResults = [] ;
    this.receivedParagraphResults = [];
  
    this.senderChatPartecipants = [];
    this.receiverChatPartecipants = [];

    const newParagraphFeature_id = 0
    const newOwner = this.paragraph.owner
    const newDateAndTime = this.paragraph.dateAndTime
    const newIsAccepted = true ;
    const newAllUsersAllowed = this.paragraph.allUsersAllowed
    const newUserListAllowed = this.paragraph.userListAllowed
    let isBeenShared: boolean 
    if ( newAllUsersAllowed === true || newUserListAllowed.length > 0 ){ isBeenShared = true }
    if ( newAllUsersAllowed === false && newUserListAllowed.length === 0 ){ isBeenShared = false }
    const newIsBeenShared = isBeenShared
    const newCanBeReshared = this.paragraph.canBeResharedByOthers
    const newType = 'paragraphFeature'
    const newCounterId = 0 ;
    const newParagraphFeatureDropId = 0 ;
    const newParagraph_id = this.paragraph._id
    const newParagraphFeature = new ParagraphFeature ( newParagraphFeature_id, newOwner, newDateAndTime, 
                                                       newIsAccepted, newAllUsersAllowed, newUserListAllowed, 
                                                       newIsBeenShared , newCanBeReshared, newType,  newCounterId, 
                                                       newParagraphFeatureDropId , newParagraph_id )
    this.paragraphFeature = newParagraphFeature 

    this.paragraphFeatures = [this.paragraphFeature]

        /**
 *  RECREATE ! and re-compile all FEATURE RESULTS!  create initial dummy paragraphResults --------------------------------------------------
 */
 this.paragraphResults = []
 const newParagraphResultName = 'prova'
 const newParagraphResult = new ParagraphResult (  newParagraphResultName, [] ) ;

 const newParagraphResults: ParagraphResult[] = []
 //const newMailTextBodyPart = ''
 //this.mailTextBody = []
 for ( let z=0; z<this.paragraphFeatures.length; z++){
   newParagraphResults.push(newParagraphResult)
  // this.mailTextBody.push(newMailTextBodyPart)
 }
 this.paragraphResults = newParagraphResults


// console.log(this.chat)
/**
 * this chat receiver is to match chat partecipants.partecipants to form receiver 
 */
        const newSentRecipientGroup = '';
        const newSentRecipientName = this.recipient.name ;
        const newSentToMessage = this.recipient.toMessage;
        // const newSentToMessage = this.chatService.getChat(0).partecipantGroup.partecipants;
        const newSentToMail =  [];
        const newSentCcMail = [];
        const newSentBccMail = [];
        const newSentGreetings = ''
        const newChatReceiver = new Receiver ( newSentRecipientGroup, newSentRecipientName, newSentToMessage, newSentToMail, newSentCcMail, newSentBccMail, newSentGreetings)

     
        this.receiver = newChatReceiver ; 
 
  }

  /**
   * change subject with button click 
   *  this could change to 2 ways button if there are more than 5-6 subjects 
   * could turn into dropdown if there are more than 10 subjects 
   */
   onClickSubjectButton(){
    this.count = this.count-1
    if (this.count === -1){
      this.count = this.subjectList.length-1
    }
    this.selectedSubject = this.subjectList[this.count]
 }

 /**
  * adding subject by clicking the + button
  */
 onAddSubject(){
   this.addSubject = !this.addSubject
 }



/**
* start the subscriptions to on sending the form to receive the results
*/
onSendForm(){
 // this.futureFormResultChat_id = this.chat.recipient.chatRoomId
  


  console.log(this.futureFormResultChat_id)

  this.timeAtSendForm = DateTime.now().toString();


  if (this.addSubject === false ) { this.finalSubject = this.selectedSubject} ;
  if (this.addSubject === true) { this.subscribeFormNewSubject.next() }

  const new_id = (this.formResultsService.getFormResults().length+1).toString() ;
  const newChatRoomId =  this.recipientResult.chatRoomId
  const newIsActive = true ; 
  const newIsDeleted = false ;
  const newIsSelected = false ;
  const newIsRead = true ;
  const newIsShareble = true 
  const newIsACopyOfAMessage = false 
  const newIsForwardToMail = this.isForwardToMail ;
  const newIsSentToMessage = this.isSentToMessage ;
  const newIsForwarded = false ;
  const newIsReceived = false ; 
  const newSender = new Sender ( this.user) ;
  const newSenderGroup = '' ;
  const newSenderType = '' ;
  const newReceiver = this.receiver ;
  const newReceiverGroup= '' // this.recipientIndexListItem.recipientIndexListName ;
  const newReceiverType = 'private' ;
  const newSentRecipientGroup = '' // this.recipientIndexListItem.recipientIndexListName ;
  const newUserChatRoomId = this.recipient._id
  const newRecipientResult = this.recipientResult
  const newDateAndTime = this.timeAtSendForm ; 
  const newSubject = this.finalSubject ;
  const newParagraphResults = this.paragraphResults 
  const newSeq = null 
  const newUpdateDateAndTime = null 
  const newHasFollowingParagraphItemToShow = null 


  const newSentFormResult = new FormResult (  new_id,
                                              newIsActive ,
                                              newIsDeleted,
                                              newIsSelected,
                                              newIsRead, 
                                              newIsShareble,
                                              newIsACopyOfAMessage ,
                                              newIsForwardToMail,
                                              newIsForwarded,
                                              newSender,
                                              newSenderGroup,
                                              newSenderType,
                                              newReceiverGroup,
                                              newReceiverType,
                                              newUserChatRoomId,
                                              newRecipientResult,
                                              newDateAndTime,
                                              newSubject,
                                              newParagraphResults ,
                                              newSeq, 
                                              newUpdateDateAndTime,
                                              newHasFollowingParagraphItemToShow
                                              ) ;


this.finalSentFormResult = newSentFormResult

/**
 * send formResult to httpPostComponent if user !== null 
 */

if ( this.user !== null ){

  /**
   * if send then we send the text composition after we are sure the formResult has been stored 
   * if any error we ask if we still want to send by email anyway yes or no , and deal with it 
   * with additional steps
   */
  if ( this.finalSentFormResult.recipientResult.toMessage.length > 0 ){
    this.postComponentsHttpRequestService.httpPostFormResult( this.user , this.finalSentFormResult )
        .subscribe((result: any )=> {
        console.log( result )

        const newFormResult = result.formResult
        const newUserReceiveFormResultId = result.userReceiveFormResultId
        const newWrongRecipientUserList = result.wrongRecipientUserList


        this.formResultsService.addFormResult(newFormResult)
       // console.log(this.finalSentFormResult.receiver.sentToMessage , 
        //  newFormResult, newUserReceiveFormResultId, newWrongRecipientUserList  )

        
      } , error => {
        console.log(error )

        /**
         * show error and ask to check connection 
         * showerror+ do user still want to send email composition anyway without saving 
         */

       

      }
      
      )
  }

  // this.postComponentsHttpRequestService.httpPostFormResult( this.user , this.finalSentFormResult )
  //     .subscribe((result: FormResult )=> {

  //       console.log( result )
  //       this.formResultsService.addFormResult(result)
  //       console.log(this.finalSentFormResult.receiver.sentToMessage)

  //       this._bottomSheetRef.dismiss()
        
  //     } , error => {
  //       console.log(error )

  //       /**
  //        * showerror+ do user still want to send email composition anyway without saving 
  //        */

  //     }
      
  //     )
}

if ( this.user === null ){

  this.formResultsService.addFormResult(this.finalSentFormResult)
   console.log(this.finalSentFormResult)
  // console.log(this.finalSentFormResult.receiver.sentToMessage)

   this._bottomSheetRef.dismiss()

  }
  
}

/**
* 
* @param event receiving the new Subject from newSubjectEditForm
* we also need to update the chatFormList about the new Subject so that it can update the paragraphs
*/
onUpdateFromSubject(event){
  this.finalSubject = event
  if (this.finalSubject === ''){ this.finalSubject = '--- --- ---'}
  console.log(this.finalSubject)
}



onUpdateBothParagraphResultTextResultAndIndex($event){
  this.paragraphResults[$event.newParagraphFeatureDropId] = $event.newParagraphResult

  console.log(this.paragraphResults)
//  console.log(this.mailTextBody, this.finalMailTextBody)

}



 /**
 * now if this chat exist we don't create the new CHat but we add the form only and 
 * we make the property hasNewForm = true
 * 
 * if the chat doesn't exist then we produce a new chat on the list 
 */

// we use a *ngFor to make it for each chatExist = true , but supposed 
// to be ONLY ONE number
// updateChatIndex(){

//   this.chatService.sendUpdateToChatIndexList()

//   /**
//    *  constructor (public _id: string ,
//                public owner: string ,
//                public isVisible: boolean, 
//                public dateAndTime: string , 
//                public recipient: Recipient, 
//                public partecipantGroup: PartecipantGroup , 
//                public isNewChat: boolean, 
//                public isBeenUpdated: boolean,
//                public isSelected: boolean, 
//                public subject: string ){}
//    */
//   for (let i=0; i<this.chatService.getChats().length; i++){
//     this.chatService.getChat(i).isSelected = false
//   }

//   const new_id = this.chat._id
//   const newOwner = this.chat.owner
//   const newAllUsersAllowed = this.chat.allUsersAllowed
//   const newUserListAllowed = this.chat.userListAllowed
//   const newIsVisible = true 
//   const newDateAndTime = this.timeAtSendForm
//   const newUpdateDateAndTime = this.chat.updateDateAndTime
//   const newIsNewChat = false
//   const newIsBeenUpdated = true 
//   const newIsSelected = true
//   const newSubject = this.finalSubject

//   const newChat = new Chat ( new_id, newOwner, newAllUsersAllowed, newUserListAllowed, newIsVisible, newDateAndTime,
//                              newUpdateDateAndTime ,newIsNewChat, newIsBeenUpdated, newIsSelected, newSubject)

//   this.chatService.chats[this.chatService.getChats().findIndex(x=> x._id === newChat._id)] = newChat

//   this.chatService.sendUpdateToChatIndexList()

//   console.log(this.chatService.getChats())

// this._bottomSheet.dismiss();


// }




ngOnDestroy(): void {

  this.updateOnUserEmail.unsubscribe()
}

}
