import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Observable, Subscription } from 'rxjs';
import { DialogmanagerService } from 'src/app/services/other-components-services/dialogmanager.service';
import { FormResultsService } from '../../form-results.service';

interface Date_Type_StartEnd {
  isRangeDeselected: boolean , 
  isFixDeselected: boolean , 
  startingDate: string , 
  endingDate: string , 
  typeOfRange: string , 
  numberOfType: string 
}

interface DateType_DateInfo {
  dateType: string , 
  dateInfo: string[]
}


@Component({
  selector: 'app-filter-dates',
  templateUrl: './filter-dates.component.html',
  styleUrls: ['./filter-dates.component.css']
})
export class FilterDatesComponent implements OnInit , OnDestroy {

  availableDates: string[] = ['range', 'fix' , 'till today' ]

 @Input() fixIsDeselected: boolean
 @Input() rangeIsDeselected: boolean
 @Input() updatedDate: string[]
 @Input() isSubmitted: boolean

 @Input() resetFixDateFilter: Observable<void>
 subscriptionToResetFilters: Subscription

 @Output() onFixIsDeselected = new EventEmitter<boolean>()
 @Output() onRangeIsDeselected = new EventEmitter<boolean>() 
 @Output() onEmitDateType_dateInfo = new EventEmitter<DateType_DateInfo>()

  search = new FormControl();

  startingDate: string 
  endingDate: string 

  startEndDate : string []

  range : FormGroup


  subscriptionToFormResultList_onCheckUpdateFilterStatus: Subscription

  subscribeToChangedIsSubmitStatus: Subscription 
   @Input() updateIsSubmitStatus: Observable<void>


  constructor( private datePipe:DatePipe , 
               private dms: DialogmanagerService,
               private formResultsService: FormResultsService , 
               private cdr: ChangeDetectorRef ,
               private ngZone: NgZone
               ) { }

  ngOnInit(): void {

    const today = this.datePipe.transform( new Date() , "yyyy-MM-dd'T'HH:mm:ss" )
    this.startEndDate = [ null , today ]
    console.log(this.startEndDate)

    this.range = new FormGroup({
      start: new FormControl(new Date(this.updatedDate[0])),
      end: new FormControl(new Date(this.updatedDate[1]))
    });

    if ( this.isSubmitted === true ){ this.range.controls['start'].disable() , this.range.controls['end'].disable() }

    this.subscriptionToResetFilters = this.resetFixDateFilter.subscribe(()=> this.resetFilters())

    this.subscriptionToFormResultList_onCheckUpdateFilterStatus = this.formResultsService.checkUpdateFilterResult_fromResultList
      .subscribe((res)=>{
        console.log(res)
        const dateType_DateInfo : DateType_DateInfo = res.dateType_DateInfo
        this.updateDateType_DateInfo_uponFormResultListPopup(dateType_DateInfo)
    })

    this.subscribeToChangedIsSubmitStatus = this.updateIsSubmitStatus
        .subscribe(()=>{
          this.isSubmitted = true 
          this.range.controls['start'].disable() 
          this.range.controls['end'].disable()
    })

  }

  changeDate(type: string, event: MatDatepickerInputEvent<Date>) {

    if ( type === 'start'){ 
              let newStartDateString = this.datePipe.transform( event.value , "yyyy-MM-dd'T'HH:mm:ss")
              this.startEndDate[0] = newStartDateString
              console.log('start date = ' , newStartDateString , event.value , this.startEndDate ) }

              let date_Type_StartEnd : Date_Type_StartEnd =  {isRangeDeselected: false , isFixDeselected: true , startingDate: this.startEndDate[0], endingDate: this.startEndDate[1] , typeOfRange: null , numberOfType: null }
              console.log(date_Type_StartEnd)

    if ( type === 'end'){ 
              let newEndDateString = this.datePipe.transform( event.value , "yyyy-MM-dd")+"T23:59:59"
              this.startEndDate[1] = newEndDateString
              console.log('end date = ', newEndDateString, event.value , this.startEndDate ) }

              date_Type_StartEnd =  { isRangeDeselected: false , isFixDeselected: true , startingDate: this.startEndDate[0], endingDate: this.startEndDate[1] , typeOfRange: null , numberOfType: null }
              console.log(date_Type_StartEnd)

    const dateType = 'range'
    const dateInfo = [ this.startEndDate[0], this.startEndDate[1]]
    const newDateTypeDateInfo: DateType_DateInfo = { dateType, dateInfo}

    console.log( 'dateType_dateInfo = ', newDateTypeDateInfo )
    this.onEmitDateType_dateInfo.emit( newDateTypeDateInfo )

  }

 

  onSelectFix(i){
    if ( this.isSubmitted === false){
    this.fixIsDeselected = false 
    if (this.fixIsDeselected === false ){
     this.dms.FixDatesFilter()
        .subscribe((res: Date_Type_StartEnd )=>{
          console.log(res)
          
          if ( res !== undefined ){ 
            const fixResult: Date_Type_StartEnd = res
            this.onDefineFixSelectedData(fixResult)
          }

          if ( res === undefined ){ this.fixIsDeselected = true , this.onFixIsDeselected.emit(this.fixIsDeselected)}
          
        })
    }

    this.onFixIsDeselected.emit(this.fixIsDeselected)
  }
  }

  onDefineFixSelectedData( fixResult: Date_Type_StartEnd ){

    this.rangeIsDeselected = fixResult.isRangeDeselected
    this.fixIsDeselected = fixResult.isFixDeselected

    //availableDates: string[] = ['range', 'fix' , 'till today' ]

    if ( this.rangeIsDeselected === false ){}
    if ( this.fixIsDeselected === false){ 

        if ( fixResult.typeOfRange === 'days' ){
          if ( fixResult.numberOfType === '0' ){ this.availableDates[1] = 'today'}
          if ( fixResult.numberOfType === '1' ){ this.availableDates[1] = 'yesterday'}
          if ( fixResult.numberOfType !== '0' && fixResult.numberOfType !== '1'  ){ this.availableDates[1] = fixResult.numberOfType +' '+ fixResult.typeOfRange+' '+ 'ago'}
        }
        if ( fixResult.typeOfRange === 'weeks' ){
          if ( fixResult.numberOfType === '0' ){ this.availableDates[1] = 'this week'}
          if ( fixResult.numberOfType === '1' ){ this.availableDates[1] = 'last week'}
          if ( fixResult.numberOfType !== '0' && fixResult.numberOfType !== '1'  ){ this.availableDates[1] = fixResult.numberOfType +' '+ fixResult.typeOfRange+' '+ 'ago'}
          
        }
        if ( fixResult.typeOfRange === 'months' ){
          if ( fixResult.numberOfType === '0' ){ this.availableDates[1] = 'this month'}
          if ( fixResult.numberOfType === '1' ){ this.availableDates[1] = 'last month'}
          if ( fixResult.numberOfType !== '0' && fixResult.numberOfType !== '1'  ){ this.availableDates[1] = fixResult.numberOfType +' '+ fixResult.typeOfRange+' '+ 'ago'}
          
        }   
        const dateType = 'fix'
        const dateInfo = [ fixResult.numberOfType, fixResult.typeOfRange   ]
        const newDateTypeDateInfo: DateType_DateInfo = { dateType, dateInfo }

        console.log( 'dateType_dateInfo = ', newDateTypeDateInfo )
        this.onEmitDateType_dateInfo.emit( newDateTypeDateInfo ) 
    }
  
  }

  onDeselectFix(i){
    if ( this.isSubmitted === false){
    this.fixIsDeselected = true 
    this.availableDates = ['range', 'fix' , 'till today' ]
    this.onFixIsDeselected.emit(this.fixIsDeselected)
    }
  }

  onSelectRange(i){
    if ( this.isSubmitted === false){
    this.rangeIsDeselected = false 
    this.onRangeIsDeselected.emit(this.rangeIsDeselected)
    }
  }

  onDeselectRange(i){
    if ( this.isSubmitted === false){
    this.rangeIsDeselected = true 
    this.availableDates = ['range', 'fix' , 'till today' ]
    console.log(this.startEndDate)
    this.onRangeIsDeselected.emit(this.rangeIsDeselected)
    }

  }

  resetFilters(){
    this.availableDates = ['range', 'fix' , 'till today' ]
  }

  updateDateType_DateInfo_uponFormResultListPopup(dateType_DateInfo: DateType_DateInfo ){

  this.ngZone.run(() => {

    if (dateType_DateInfo.dateType === 'fix'){
      this.rangeIsDeselected = true 
      this.fixIsDeselected = false 

      if ( dateType_DateInfo.dateInfo[1] === 'days' ){
        if ( dateType_DateInfo.dateInfo[0] === '0' ){ this.availableDates[1] = 'today'}
        if ( dateType_DateInfo.dateInfo[0] === '1' ){ this.availableDates[1] = 'yesterday'}
        if ( dateType_DateInfo.dateInfo[0] !== '0' && dateType_DateInfo.dateInfo[0] !== '1'  ){ this.availableDates[1] = dateType_DateInfo.dateInfo[0] +' '+ dateType_DateInfo.dateInfo[1]+' '+ 'ago'}
        this.onEmitDateType_dateInfo.emit( dateType_DateInfo )
      }
      if ( dateType_DateInfo.dateInfo[1] === 'weeks' ){
        if ( dateType_DateInfo.dateInfo[0] === '0' ){ this.availableDates[1] = 'this week'}
        if ( dateType_DateInfo.dateInfo[0] === '1' ){ this.availableDates[1] = 'last week'}
        if ( dateType_DateInfo.dateInfo[0] !== '0' && dateType_DateInfo.dateInfo[0] !== '1'  ){ this.availableDates[1] = dateType_DateInfo.dateInfo[0] +' '+ dateType_DateInfo.dateInfo[1]+' '+ 'ago'}
        
        this.onEmitDateType_dateInfo.emit( dateType_DateInfo )
      }
      if ( dateType_DateInfo.dateInfo[1] === 'months' ){
        if ( dateType_DateInfo.dateInfo[0] === '0' ){ this.availableDates[1] = 'this month'}
        if ( dateType_DateInfo.dateInfo[0] === '1' ){ this.availableDates[1] = 'last month'}
        if ( dateType_DateInfo.dateInfo[0] !== '0' && dateType_DateInfo.dateInfo[0] !== '1'  ){ this.availableDates[1] = dateType_DateInfo.dateInfo[0] +' '+ dateType_DateInfo.dateInfo[1]+' '+ 'ago'}
        
        this.onEmitDateType_dateInfo.emit( dateType_DateInfo ) 
      } 

    }

    if (dateType_DateInfo.dateType === 'range'){

      this.rangeIsDeselected = false 
      this.fixIsDeselected = true 

      const dateType = 'range'
      const dateInfo = [ dateType_DateInfo.dateInfo[0] , dateType_DateInfo.dateInfo[1] ]
      const newDateTypeDateInfo: DateType_DateInfo = { dateType, dateInfo}

      this.startingDate = dateType_DateInfo.dateInfo[0]
      this.endingDate = dateType_DateInfo.dateInfo[1]

  
      console.log( 'dateType_dateInfo = ', newDateTypeDateInfo )
      this.onEmitDateType_dateInfo.emit( newDateTypeDateInfo )

    }

    if (dateType_DateInfo.dateType === 'till today'){

      this.rangeIsDeselected = true 
      this.fixIsDeselected = true 
      this.availableDates = ['range', 'fix' , 'till today' ]

    }

  }) // END NgZone

  // Manually trigger change detection 
  this.cdr.detectChanges();

  }

  ngOnDestroy(): void {
    this.subscriptionToResetFilters.unsubscribe()
    this.subscriptionToFormResultList_onCheckUpdateFilterStatus.unsubscribe()
    this.subscribeToChangedIsSubmitStatus.unsubscribe()
  }

}
