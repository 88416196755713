import { Injectable , EventEmitter} from '@angular/core';
import { SingleWord } from './single-word.model';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { Subject, Subscription } from 'rxjs';
import { StoreFormItemPlusChildsConfirmationService } from 'src/app/services/get-and-store-components/store-form-item-plus-childs-confirmation.service';
import { StoreParagraphItemPlusChildsConfirmationService } from 'src/app/services/get-and-store-components/store-paragraph-item-plus-childs-confirmation.service';
import { StoreMultibaseItemPlusChildsConfirmationService } from 'src/app/services/get-and-store-components/store-multiBase-item-plus-childs-confirmation.service';
import { StoreTabItemPlusChildsConfirmationService } from 'src/app/services/get-and-store-components/store-tab-item-plus-childs-confirmation.service';

@Injectable ({
    providedIn: 'root' 
})

export class SingleWordService {

    constructor( private initialNullSetupService: InitialNullSetupService , 
                 private storeFormItemPlusChildsConfirmationService: StoreFormItemPlusChildsConfirmationService,
                 private storeParagraphItemPlusChildsConfirmationService: StoreParagraphItemPlusChildsConfirmationService , 
                 private storeTabItemPlusChildsConfirmationService: StoreTabItemPlusChildsConfirmationService , 
                 private storeMultibaseItemPlusChildsConfirmationService: StoreMultibaseItemPlusChildsConfirmationService
               
                 ){}


    singleWords: SingleWord[] = this.initialNullSetupService.initialNullSingleWords 

    initialPersonalSingleWordsHaveBeenStored = new Subject<void>()


    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.singleWordsChangedBackToNull
                                                     .subscribe((res)=> {
                                                         this.singleWords = res 
                                                         console.log('updated null singleWords = ', res)

    })

 

    getSingleWord(id: number){
        return this.singleWords[id]
    };

    getSingleWord_by_id(_id: string){
        const newSingleWord: SingleWord = this.singleWords.find(
            (res)=> {
                return res._id === _id
            }
        )
        return newSingleWord

    }

    getSingleWords(){
        return this.singleWords;
    }

    addSingleWord(singleWord: SingleWord){
        this.singleWords.push(singleWord);
        this.updateInitialComponent(this.singleWords)
    }

    addNewSingleWords(singleWords: SingleWord[]){

        for ( let i=0; i<singleWords.length ; i++ ){
            if ( this.singleWords.findIndex(x=> x._id === singleWords[i]._id ) === -1 ){
                this.singleWords.push(singleWords[i])
            }
            if ( i === singleWords.length-1){
                this.updateInitialComponent(this.singleWords)
                this.initialPersonalSingleWordsHaveBeenStored.next()
            }
        }
    // this.initialPersonalSingleWordsHaveBeenStored.next()


    }

    addNewSingleWord(singleWordToAdd: SingleWord , isComingFromFormItemCall: boolean , isComingFromMultiFeatures: boolean){

        if ( this.singleWords.findIndex(x=>x._id === singleWordToAdd._id) !== -1){ 
             const index = this.singleWords.findIndex(x=>x._id === singleWordToAdd._id)
             this.singleWords[index] = singleWordToAdd
            this.updateInitialComponent(this.singleWords)
        }

        if ( this.singleWords.findIndex(x=>x._id === singleWordToAdd._id) === -1){ 
            this.singleWords.push(singleWordToAdd)
            this.updateInitialComponent(this.singleWords)
        }

        if ( isComingFromFormItemCall === true  ){ this.storeFormItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenFormItemPlusChildsAreStored( singleWordToAdd._id) }
        if ( isComingFromFormItemCall === false ){ this.storeParagraphItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenParagraphItemPlusChildsAreStored( singleWordToAdd._id) }
        if ( isComingFromMultiFeatures === true ){ this.storeMultibaseItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenMultiFeatureItemPlusChildsAreStored(singleWordToAdd._id) }
        if ( isComingFromMultiFeatures=== false ){ this.storeTabItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenTabItemPlusChildsAreStored( singleWordToAdd._id) }
        console.log(' SINGLEWORDS= ',this.singleWords)
    }

    updateInitialComponent(updatedSingleWords: SingleWord[]){
        this.initialNullSetupService.initialNullSingleWords = updatedSingleWords

    }

    ngOnDestroy(){
        this.updateInitialNullDatafromInitialNullSetupService.unsubscribe()
    }







}