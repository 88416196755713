
export class ParagraphTopicIndex {

    constructor( public _id: string , 
                 public componentName: string ,
                 public UIID: string,
                 public owner: string, 
                 public allUsersAllowed: boolean , 
                 public userListAllowed: string[] ,
                 public dateAndTime: string, 
                 public type: string , // just the 4 : personal / shared/ preferred / public
                 public paragraphTopic_ids: string[]
               ){}
}