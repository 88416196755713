import { Component, OnInit } from '@angular/core';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';

@Component({
  selector: 'app-check-box-select-list',
  templateUrl: './check-box-select-list.component.html',
  styleUrls: ['./check-box-select-list.component.css']
})
export class CheckBoxSelectListComponent implements OnInit {

  user: string 

  constructor(  private userObjectUpdateService: UserObjectUpdateService) { }

  ngOnInit(): void {

    this.user = this.userObjectUpdateService.userEmail
  }

}
