<app-loading-spinner *ngIf="isLoadingList && !error"></app-loading-spinner>

       <div *ngIf="error">
       <br>
       <div *ngIf="error && !isLoadingList" 
            class="alert alert-danger">
            <h1>OOOPS ! Check Network Connection</h1>
                  <p>{{error}}</p>
        </div>
       </div>

<mat-card *ngIf="!isLoadingList && !error" class="mat-elevation-z0"> 

<mat-tab-group #tabs
[(selectedIndex)]="selectedIndex"
class="remove-border-bottom"
header-less-tabs 
headerPosition="below" 
(selectedTabChange)="onLinkClick($event)"
animationDuration="50ms" >  




<mat-tab *ngFor="let fixLayout of fixLayouts; let i=index" >

     <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

    <app-fix-layout-item *ngIf="!isLoading"
                         [isTabItem]="false"
                         [isParagraphEdit]="isParagraphEdit"
                         [noNeeded]="false" 
                         [feature]="feature_from_paragraphEdit"
                         [index]="i"
                         [index_id]="fixLayout._id"
                         (emitToParagraphEditIsDoneAndTurnDroppedFalse)='onUpdateDroppedConditionFromFixLayout($event)'
                         [subscriptionToEditComponentItemIsOn]="subscriptionToEditComponentItemIsOn"
                         [editFeatureItem]="clickOnEditFeatureItem.asObservable()">
    </app-fix-layout-item>

    <br>
                      
             <ng-template  mat-tab-label>
             <a style="cursor: pointer">
              {{i+1}} - {{fixLayout.name}} </a>
             </ng-template>
 </mat-tab>

 <div *ngIf="createButtonLimit">
    <mat-tab disabled > 
        <ng-template  mat-tab-label>
             <a (click)="onCreate()"> create new </a>
        </ng-template>
    </mat-tab>
 </div>  

 <div *ngIf="fixLayouts.length > 1">
     <div *ngIf="createButtonLimit">
         <mat-tab disabled > 
              <ng-template  mat-tab-label>
                   <a> create copy of  </a>
              </ng-template>
          </mat-tab>
     </div>
</div>
 
 <div *ngIf="createButtonLimit">
    <mat-tab disabled > 
         <ng-template  mat-tab-label>
              <a> create complete list from excel file </a>
         </ng-template>
     </mat-tab>
</div>
 
     
</mat-tab-group> 

</mat-card>


