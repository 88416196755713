<div class="example-container">
      <div class="example-box"  >
        <!-- (mouseenter) = "onMouseEnter()"  (mouseleave) = "onMouseLeave()"  -->

 <div [hidden]="hideButton">

        <div [hidden]="hide1stButton" class="pointer" >
      
          <svg style="color: #3f51b5;" xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" class="bi bi-circle-fill" viewBox="0 0 16 16">
            <circle cx="8" cy="8" r="8"/>
          </svg>
         </div>
     
 

 </div>

  
   
    <div [hidden]="hideButton" >
      <div  class="inner-box" (click)="onHideParagraphs()" >


          <a class="navbar-brand" style="color: #3f51b5;"  > {{ paragraphTopic?.name}}</a>
          <span [hidden]="!hideParagraphs">
            <svg style="color: #3f51b5;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
              <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
            </svg>
          </span>
          <span [hidden]="hideParagraphs">
            <svg  xmlns="http://www.w3.org/2000/svg" style="color: #3f51b5;" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
            </svg>
          </span>
       
      </div>
    </div>
 
  
       </div> 

       



       <div [hidden]="hideParagraphs">

          <div style="overflow: hidden;"
               class="col-12" 
               *ngFor="let paragraphFeatureContainer_id of paragraphTopic.paragraphFeatureContainer_ids ; let i = index " >
           

            <div>
              <app-chat-paragraph-feature-container
              [isPersonal]="isPersonal"
              [paragraphFeatureContainer_id]="paragraphFeatureContainer_id">
              <!-- 
              [chat]="chat"
              [aChatHasBeenSelected]="aChatHasBeenSelected"
              [subscribeUpdatedChatSelected]="subscribeSelectedChat.asObservable()" -->
              </app-chat-paragraph-feature-container>
            </div>
        
        </div>

       </div>
      
    </div>