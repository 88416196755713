import { Injectable } from "@angular/core";
import { MatDialog , MatDialogConfig , MatDialogRef } from '@angular/material/dialog'; 
import { Observable } from 'rxjs' ; 
import { ShareParagraphIndexComponent } from "../../../share-paragraph-index/share-paragraph-index.component";

@Injectable ({
    providedIn: 'root'
})

export class ParagraphIndexTopicDialog {

constructor ( private dialog: MatDialog ){}


    public ShareParagraphIndex (data: any =[]): Observable<any> {

        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass =''
        dialogConfig.disableClose = false ;
        dialogConfig.data = data;
        let dialogRef: MatDialogRef<ShareParagraphIndexComponent>;
        dialogRef = this.dialog.open(ShareParagraphIndexComponent, dialogConfig);
        return dialogRef.afterClosed();
    
    }




}