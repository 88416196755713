import { Injectable } from "@angular/core";
import { InitialNullSetupService } from "src/app/services/starting-services/initial-null-setup.service";
import { SharedCalendarStatus } from "./shared-calendar-status.model";
import { RangeInfo } from "./range-info.model";
import { FixTextDetail } from "../../texts/fix-text-list/fix-text-detail.model";
import { Subject, Subscription } from "rxjs";
import { StoreFormItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-form-item-plus-childs-confirmation.service";
import { StoreParagraphItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-paragraph-item-plus-childs-confirmation.service";
import { StoreTabItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-tab-item-plus-childs-confirmation.service";
import { CanDo } from "./users-access-edit/who-can-do.model";
import { UsersAccess } from "./users-access-edit/users-access.model";
import { InnerArraySelect } from "./inner-array-select.model";
import { RangeDatesInfo } from "./range-dates-info.model";
import { DateStartEnd } from "./date-start-end.model";

@Injectable ({
    providedIn: 'root' 
})

export class SharedCalendarStatusService {

    constructor ( private initialNullSetupService: InitialNullSetupService , 
                  private storeFormItemPlusChildsConfirmationService: StoreFormItemPlusChildsConfirmationService , 
                  private storeParagraphItemPlusChildsConfirmationService: StoreParagraphItemPlusChildsConfirmationService,
                  private storeTabItemPlusChildsConfirmationService: StoreTabItemPlusChildsConfirmationService
      ) {}

    initialPersonalSharedCalendarStatusItemsHaveBeenStored = new Subject<void>()

    sharedCalendarStatusItems: SharedCalendarStatus[] = this.initialNullSetupService.initialNullSharedCalendarStatusItems
    
    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.sharedCalendarStatusChangedBackToNull
                              .subscribe((res)=> {
                                this.sharedCalendarStatusItems = res                       
    })


    getSharedCalendarStatusItems(){
      return this.sharedCalendarStatusItems
    }


    getSharedCalendarStatus_By_id(_id: string){
      const newSharedCalendarStatusItem: SharedCalendarStatus = this.sharedCalendarStatusItems.find(
            (res)=> {
                return res._id === _id
            }
        )
        return newSharedCalendarStatusItem
    }

    onAddNew(sharedCalendarStatusItem: SharedCalendarStatus){
      this.sharedCalendarStatusItems.push(sharedCalendarStatusItem)
      this.updateInitialComponents(this.sharedCalendarStatusItems)
    }

    updateSharedCalendarStatus(updatedSharedCalendarStatus: SharedCalendarStatus){

      const index = this.sharedCalendarStatusItems.findIndex(x=> x._id === updatedSharedCalendarStatus._id)
      this.sharedCalendarStatusItems[index] = updatedSharedCalendarStatus
      console.log('SharedCalendarStatus UPDATED')
      this.updateInitialComponents(this.sharedCalendarStatusItems)
    }

    addSharedCalendarStatusItemsOnly( sharedCalendarStatusItems: SharedCalendarStatus[]){

      for ( let i=0; i<sharedCalendarStatusItems.length; i++){
          if ( this.sharedCalendarStatusItems.findIndex(x=> x._id === sharedCalendarStatusItems[i]._id) === -1 ){
               this.sharedCalendarStatusItems.push(sharedCalendarStatusItems[i])
          }
          if ( this.sharedCalendarStatusItems.findIndex(x=> x._id === sharedCalendarStatusItems[i]._id) !== -1 ){
               this.sharedCalendarStatusItems[i] = sharedCalendarStatusItems[i]
       }

       if ( i === sharedCalendarStatusItems.length-1){
            this.updateInitialComponents(this.sharedCalendarStatusItems)
       }


      }
    }

    addNewSharedCalendarStatusItems(sharedCalendarStatusItems: SharedCalendarStatus[]){
      for ( let i=0; i<sharedCalendarStatusItems.length ; i++){
          if ( this.sharedCalendarStatusItems.findIndex(x=> x._id === sharedCalendarStatusItems[i]._id ) === -1 ){
              this.sharedCalendarStatusItems.push(sharedCalendarStatusItems[i])
          }
          if ( i === sharedCalendarStatusItems.length-1){
              this.updateInitialComponents(this.sharedCalendarStatusItems)
              console.log(this.sharedCalendarStatusItems)
              this.initialPersonalSharedCalendarStatusItemsHaveBeenStored.next()
          }
      }
    }

  addNewSharedCalendarStatusItem(sharedCalendarStatusItemToAdd: SharedCalendarStatus , isComingFromFormItemCall : boolean  , isComingFromMultiFeatures: boolean){
     
      if ( this.sharedCalendarStatusItems.findIndex(x=> x._id === sharedCalendarStatusItemToAdd._id ) !== -1 ) { 

           const index = this.sharedCalendarStatusItems.findIndex(x=> x._id === sharedCalendarStatusItemToAdd._id )
           this.sharedCalendarStatusItems[index] = sharedCalendarStatusItemToAdd 
           this.updateInitialComponents(this.sharedCalendarStatusItems)
      }

      if ( this.sharedCalendarStatusItems.findIndex(x=> x._id === sharedCalendarStatusItemToAdd._id ) === -1 ) { 
           this.sharedCalendarStatusItems.push(sharedCalendarStatusItemToAdd) 
           this.updateInitialComponents(this.sharedCalendarStatusItems)
      }

          if ( isComingFromFormItemCall === true  ){ this.storeFormItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenFormItemPlusChildsAreStored( sharedCalendarStatusItemToAdd._id) }
          if ( isComingFromFormItemCall === false ){ this.storeParagraphItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenParagraphItemPlusChildsAreStored( sharedCalendarStatusItemToAdd._id) }
          if ( isComingFromMultiFeatures=== false ){ this.storeTabItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenTabItemPlusChildsAreStored( sharedCalendarStatusItemToAdd._id) }
     
      console.log('sharedCalendarStatusItems WITH SHARED ONES ADDED = ', this.sharedCalendarStatusItems)
  };



    updateInitialComponents(updatedSharedCalendarStatusItems: SharedCalendarStatus[]){
      this.initialNullSetupService.initialNullSharedCalendarStatusItems = updatedSharedCalendarStatusItems
  }

  /**
   * 
   * @param sharedCalendarStatus 
   * export class SharedCalendarStatus {

    constructor( public _id: string , 
                 public componentName: string , 
                 public UIID: string , 
                 public owner: string ,
                 public allUsersAllowed: boolean,
                 public userListAllowed: string[],
                 public dateAndTime: string ,
                 public name: string , 
                 public rangeDatesInfoSetting: RangeDatesInfo,
                 public rangeDatesInfos: RangeDatesInfo[]
                 ){}
   */
  createSharedCalendarStatusItem_Clone( sharedCalendarStatus: SharedCalendarStatus ): SharedCalendarStatus {

    const new_id = sharedCalendarStatus._id
    const newComponentName = sharedCalendarStatus.componentName
    const newUIID = sharedCalendarStatus.UIID
    const newOwner = sharedCalendarStatus.owner
    const newAllUsersAllowed = sharedCalendarStatus.allUsersAllowed
    const newUserListAllowed = sharedCalendarStatus.userListAllowed
    const newDateAndTime = sharedCalendarStatus.dateAndTime; 
    const newName =  sharedCalendarStatus.name
    const newRangeDateInfoSetting = this.createRangeDatesInfo_Clones([sharedCalendarStatus.rangeDatesInfoSetting])[0]
    const newRangeDateInfos = this.createRangeDatesInfo_Clones(sharedCalendarStatus.rangeDatesInfos)

    const newSharedCalendarStatusItem = new SharedCalendarStatus (new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed ,
                                          newDateAndTime, newName, newRangeDateInfoSetting, newRangeDateInfos)

    return newSharedCalendarStatusItem

  }

  createEmptyUsersAccess(): UsersAccess{
    const newNames: string[] = []
    const newDescription: string = ''
    const newOnlyOwnerCan: boolean = false 
    const newCanViewAccess = new CanDo ( newNames, newDescription, newOnlyOwnerCan )
  
    const newNamesEdit: string[] = []
    const newDescriptionEdit: string = ''
    const newOnlyOwnerCanEdit: boolean = false 
    const newCanEditAccess = new CanDo ( newNamesEdit, newDescriptionEdit, newOnlyOwnerCanEdit )
  
    const newNamesUse: string[] = []
    const newDescriptionUse: string = ''
    const newOnlyOwnerCanUse: boolean = false 
    const newCanUseAccess = new CanDo ( newNamesUse, newDescriptionUse, newOnlyOwnerCanUse )
  
    const newUsersAccess = new UsersAccess ( newCanViewAccess, newCanEditAccess, newCanUseAccess )
  
    return newUsersAccess
  }

  /**
   * new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can view the data on each date range', false ),
                                                                new CanDo (  [], '', true ),
                                                                new CanDo (  ['alex@alex.com', 'bea@bea.com'], 'can add/delete date ranges & inner rangeInfo', false )
   */
  createEmptyUsersAccess_OnlyOwnerCanEdit():UsersAccess {

    const newNames: string[] = []
    const newDescription: string = 'can view the data on each date range'
    const newOnlyOwnerCan: boolean = false 
    const newCanViewAccess = new CanDo ( newNames, newDescription, newOnlyOwnerCan )
  
    const newNamesEdit: string[] = []
    const newDescriptionEdit: string = ''
    const newOnlyOwnerCanEdit: boolean = true 
    const newCanEditAccess = new CanDo ( newNamesEdit, newDescriptionEdit, newOnlyOwnerCanEdit )
  
    const newNamesUse: string[] = []
    const newDescriptionUse: string = 'can add/delete date ranges & inner rangeInfo'
    const newOnlyOwnerCanUse: boolean = false
    const newCanUseAccess = new CanDo ( newNamesUse, newDescriptionUse, newOnlyOwnerCanUse )
  
    const newUsersAccess = new UsersAccess ( newCanViewAccess, newCanEditAccess, newCanUseAccess )
  
    return newUsersAccess
  }

  createRangeDatesInfo_Clones( rangeDatesInfos: RangeDatesInfo[]):RangeDatesInfo[] {

    const newRangeDateInfos:RangeDatesInfo[] = []

    for (let i=0; i<rangeDatesInfos.length ; i++){

      newRangeDateInfos.push( this.createRangeDatesInfoClone(rangeDatesInfos[i]))
    }

    return newRangeDateInfos
  }

  createRangeDatesInfoClone(selectedRangeDatesInfo: RangeDatesInfo):RangeDatesInfo {

    const newDateStart = selectedRangeDatesInfo.dateStartEnd.dateStart
    const newDateEnd = selectedRangeDatesInfo.dateStartEnd.dateEnd

    const newDateStartEnd = new DateStartEnd( newDateStart, newDateEnd ) 
    const newUsersAccess: UsersAccess = this.createUsersAccessClone_OfDeterminedRangeInfo(selectedRangeDatesInfo.usersAccess)
    const newRangeInfos: RangeInfo[] = this.createRangeInfosClones(selectedRangeDatesInfo.rangeInfos)

    const newRangeDatesInfo = new RangeDatesInfo (newDateStartEnd, newRangeInfos, newUsersAccess )

    return newRangeDatesInfo

  }

  createUsersAccessClone_OfDeterminedRangeInfo( selectedUsersAccess: UsersAccess): UsersAccess {
    
    
    const newNames: string[] = [] 
        if ( selectedUsersAccess.canView.names.length > 0 ){
          for ( let i=0; i<selectedUsersAccess.canView.names.length ; i++){
            newNames.push(selectedUsersAccess.canView.names[i])
          }
        }
    const newDescription: string = selectedUsersAccess.canView.description
    const newOnlyOwnerCan: boolean = selectedUsersAccess.canView.onlyOwnerCan
    const newCanViewAccess = new CanDo ( newNames, newDescription, newOnlyOwnerCan )
  
    const newNamesEdit: string[] = [] 
        if ( selectedUsersAccess.canEdit.names.length > 0 ){
          for ( let i=0; i<selectedUsersAccess.canEdit.names.length ; i++){
            newNamesEdit.push(selectedUsersAccess.canEdit.names[i])
          }
        }
    const newDescriptionEdit: string = selectedUsersAccess.canEdit.description
    const newOnlyOwnerCanEdit: boolean = selectedUsersAccess.canEdit.onlyOwnerCan
    const newCanEditAccess = new CanDo ( newNamesEdit, newDescriptionEdit, newOnlyOwnerCanEdit )
  
    const newNamesUse: string[] = [] 
        if ( selectedUsersAccess.canUse.names.length > 0 ){
          for ( let i=0; i<selectedUsersAccess.canUse.names.length ; i++){
            newNamesUse.push(selectedUsersAccess.canUse.names[i])
          }
        }
    const newDescriptionUse: string = selectedUsersAccess.canUse.description
    const newOnlyOwnerCanUse: boolean = selectedUsersAccess.canUse.onlyOwnerCan
    const newCanUseAccess = new CanDo ( newNamesUse, newDescriptionUse, newOnlyOwnerCanUse )
  
    const newUsersAccess = new UsersAccess ( newCanViewAccess, newCanEditAccess, newCanUseAccess )
  
    return newUsersAccess
  }


  createRangeInfosClones( rangeInfos: RangeInfo[]): RangeInfo[] {

    const newRangeInfos: RangeInfo[] = []
    if ( rangeInfos.length > 0 ){
      for ( let i=0; i< rangeInfos.length; i++ ){
       
          const newType = rangeInfos[i].type 
          const newName = rangeInfos[i].name
          const newUserAccess = this.createUsersAccessClone_OfDeterminedRangeInfo(rangeInfos[i].usersAccess)
          const newRangeDetails = this.createRangeInfoDetailClone_OfDeterminedRangeInfo(rangeInfos[i].type, rangeInfos[i].rangeDetails)

          const newRangeInfo: RangeInfo = new RangeInfo ( newType, newName, newUserAccess, newRangeDetails )

          newRangeInfos.push(newRangeInfo)
      }
    }


    return newRangeInfos

  }

  //const dateRange = { start: startDate , end: endDate, startingIndex: i }
  createDateRanges_Clone(dateRanges: any[]):any[]{

    const newDateRanges: any[] = []

    if ( dateRanges.length > 0 ){
      for ( let i=0; i<dateRanges.length; i++ ){

          const newDateRange = { start: dateRanges[i].start , end: dateRanges[i].end, startingIndex: dateRanges[i].startingIndex }
          newDateRanges.push(newDateRange)

      }
    }

    return newDateRanges

  }

  createRangeInfoDetailClone_OfDeterminedRangeInfo( type: string,  rangeDetails: any[] ):any[] {

    if ( type === 'innerdropdown' ){
         const newRangeDetails: InnerArraySelect[] = []

         if ( rangeDetails.length > 0 ){
           for ( let i=0; i< rangeDetails.length ; i++ ){

            const newInnerArraySelectDropdown1 = new InnerArraySelect ( rangeDetails[i].name , rangeDetails[i].selected )
            newRangeDetails.push( newInnerArraySelectDropdown1 )
           }
         }
         return newRangeDetails
    }

    if ( type === 'innercheckbox' ){
         const newRangeDetails: InnerArraySelect[] = []

         if ( rangeDetails.length > 0 ){
           for ( let i=0; i< rangeDetails.length ; i++ ){
              const newInnerArrayCheckbox = new InnerArraySelect ( rangeDetails[i].name , rangeDetails[i].selected )
              newRangeDetails.push( newInnerArrayCheckbox )
           }
         }
        return newRangeDetails
    }

    if ( type === 'innertext' ){
      const newRangeDetails: FixTextDetail[] = []

      if ( rangeDetails.length > 0 ){
         for ( let i=0; i< rangeDetails.length ; i++ ){
            const newInnerArrayText = new FixTextDetail (rangeDetails[i].text )
            newRangeDetails.push(newInnerArrayText)
         }
      }
      return newRangeDetails
    }

    if ( type === 'innerinput'){
      const newRangeDetails: FixTextDetail[] = []

      if ( rangeDetails.length > 0 ){
         for ( let i=0; i< rangeDetails.length ; i++ ){
            const newInnerArrayText = new FixTextDetail (rangeDetails[i].text )
            newRangeDetails.push(newInnerArrayText)
         }
      }
      return newRangeDetails
    }

  }

  createNewInnerDropdown(): RangeInfo{
             const newType = "innerdropdown"
             const newName = "edit new dropdown"
    
             const newUsersAccess: UsersAccess = this.createEmptyUsersAccess()
             const newInnerArraySelectDropdown = new InnerArraySelect ('edit dropdown', false )
             const newRangeDetails: InnerArraySelect[] = [ newInnerArraySelectDropdown ]
    
             const newRangeInfo = new RangeInfo (newType, newName, newUsersAccess, newRangeDetails )

    return newRangeInfo
  }

  createNewInnerCheckbox(): RangeInfo{
         const newType = "innercheckbox"
         const newName = "edit new checkbox"

         const newUsersAccess: UsersAccess = this.createEmptyUsersAccess()
         const newInnerArrayCheckbox = new InnerArraySelect ('edit checkbox', false )
         const newRangeDetails: InnerArraySelect[] = [ newInnerArrayCheckbox ]

         const newRangeInfo = new RangeInfo (newType, newName, newUsersAccess, newRangeDetails )

  return newRangeInfo
  }

  createNewInnerText(): RangeInfo{
    const newType = "innertext"
         const newName = "edit new text"

         const newUsersAccess: UsersAccess = this.createEmptyUsersAccess()
         const newInnerArrayText = new FixTextDetail ('edit this new text')
         const newRangeDetails: FixTextDetail[] = [ newInnerArrayText  ]

         const newRangeInfo = new RangeInfo (newType, newName, newUsersAccess, newRangeDetails )

    return newRangeInfo
  }

  createNewInnerInput():RangeInfo{
         const newType = "innerinput"
         const newName = "edit new input"

         const newUsersAccess: UsersAccess = this.createEmptyUsersAccess()
         const newInnerArrayText1 = new FixTextDetail ('rename this label')
         const newInnerArrayText2 = new FixTextDetail ('500')
         const newInnerArrayText3 = new FixTextDetail ('write an example')
         const newInnerArrayText4 = new FixTextDetail ('')
        
         const newRangeDetails: FixTextDetail[] = [ newInnerArrayText1, newInnerArrayText2, newInnerArrayText3, newInnerArrayText4 ]

         const newRangeInfo = new RangeInfo (newType, newName, newUsersAccess, newRangeDetails )

    return newRangeInfo
  }

  createAdditionalRangeInfoDropdown():RangeInfo{
    const newType = "innerdropdown"
    const newName1 = ""
    const newUsersAccess: UsersAccess = this.createEmptyUsersAccess()

    const newInnerArraSelectDropdown = new InnerArraySelect ('+ dropdown', false )
    const newInnerArraSelectCheckbox = new InnerArraySelect ('+ checkboxes', false )
    const newInnerArraSelectText = new InnerArraySelect ('+ texts', false )
    const newInnerArraSelectInput = new InnerArraySelect ('+ input', false )

    const newRangeDetails: InnerArraySelect[] = [ newInnerArraSelectDropdown, newInnerArraSelectCheckbox, newInnerArraSelectText, newInnerArraSelectInput   ]

    const newAdditionalRangeInfo = new RangeInfo ( newType, newName1, newUsersAccess, newRangeDetails )
    return newAdditionalRangeInfo
  }



  

  ngOnDestroy(){
    this.updateInitialNullDatafromInitialNullSetupService.unsubscribe()
}



}