<!-- formEdit case rcipient name section -->
<div class="row" *ngIf="youAreInFormEdit">

    <div class="col-xs-1 pointer" *ngIf="recipientIndex.useMyOwnMailRecipient" >
            <svg (click)="onUpdate()" style="color: lightgrey" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
              </svg>
    </div>

    <div class="col-xs-1" *ngIf="!recipientIndex.useMyOwnMailRecipient">

    </div>

    <div class="col-xs-9" *ngIf="isMessageSide">
                <h2 *ngIf="recipientIndex.useMyOwnMailRecipient" 
                    style="color: #3f51b5;">
                    {{recipientGroupName}} > {{recipient.name}} 
                </h2>
                <h2 *ngIf="!recipientIndex.useMyOwnMailRecipient" style="color: orangered;">{{recipientGroupName}} > {{recipient.name}} </h2>
    </div>
    <div class="col-xs-9" *ngIf="!isMessageSide">
                <h2 style="color: grey;">{{recipientGroupName}} > {{recipient.name}} </h2>
    </div>

    <!-- hide message recipients  -->
    <div class="col-xs-1 pointer" *ngIf="isMessageSide" >

        <svg (click)="onHideMessageRecipients()" style="color:lightgrey"  xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z"/>
          </svg>
           
    </div>
    <!-- END hide message recipient  -->

     <!-- hide mail recipients  -->
     <div class="col-xs-1 pointer" *ngIf="!isMessageSide" >

      <svg (click)="onHideMailRecipients()" style="color:lightgrey"  xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z"/>
      </svg>
    </div>
    <!-- END hide mial recipient  -->

    <div class="col-xs-1"></div>

</div>
<!-- end formEdit case recipient name section -->


<!-- formItem case recipient name section  -->
<div class="row" *ngIf="!youAreInFormEdit">
    <div class="col-xs-1"></div>
    <div class="col-xs-9" *ngIf="isMessageSide">
            <h2 *ngIf="recipientIndex.useMyOwnMailRecipient" style="color: #3f51b5;">{{recipientGroupName}} > {{recipient.name}} </h2>
            <h2 *ngIf="!recipientIndex.useMyOwnMailRecipient" style="color: orangered;">{{recipientGroupName}} > {{recipient.name}} </h2>
    </div>
    <div class="col-xs-9" *ngIf="!isMessageSide">
                <h2 style="color: grey;">{{recipientGroupName}} > {{recipient.name}} </h2>
    </div>

    <div class="col-xs-1 pointer" *ngIf="isMessageSide" >
     
       <svg (click)="onHideMessageRecipients()" style="color:lightgrey"  xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z"/>
      </svg>
   </div>
  

   <div class="col-xs-1 pointer" *ngIf="!isMessageSide" >
  
    <svg (click)="onHideMailRecipients()" style="color:lightgrey"  xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-dash-circle-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z"/>
      </svg>
   </div>

    <div class="col-xs-1"></div>
</div>
<!-- end formItem case recipient name section  -->
<br>


 <form style="overflow-x: hidden;" [formGroup]="recipientForm" (ngSubmit)="onSubmit()"> 


                 <div [hidden]="update" class="form-group" > 
                     <div class="row">
                         <div class="col-xs-12">
                         <mat-form-field class="full-width" >
                         <mat-label>group name</mat-label>
                         <input matInput
                                id="nameGroup"   
                                formControlName="name"
                                type="text" 
                                placeholder="edit group name"
                                maxlength="50"
                                >
                        </mat-form-field>
                         </div>
                      
                     </div>
                 </div>
        
         <div [hidden]="!update">

            <div class="col-xs-12" *ngIf="isMessageSide">
                <div class="row">
                    <div class="col-xs-1">
                        <svg *ngIf="recipientIndex.useMyOwnMailRecipient" 
                              style="color: #3f51b5;" 
                              xmlns="http://www.w3.org/2000/svg" 
                              width="22" 
                              height="22" 
                              fill="currentColor" 
                              class="bi bi-chat-left-quote-fill" 
                              viewBox="0 0 16 16"
                              matTooltip="MAKE SURE THEY LOGIN ONCE TO GET IT"
                              matTooltipPosition="right" >
                            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm7.194 2.766a1.688 1.688 0 0 0-.227-.272 1.467 1.467 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 5.734 4C4.776 4 4 4.746 4 5.667c0 .92.776 1.666 1.734 1.666.343 0 .662-.095.931-.26-.137.389-.39.804-.81 1.22a.405.405 0 0 0 .011.59c.173.16.447.155.614-.01 1.334-1.329 1.37-2.758.941-3.706a2.461 2.461 0 0 0-.227-.4zM11 7.073c-.136.389-.39.804-.81 1.22a.405.405 0 0 0 .012.59c.172.16.446.155.613-.01 1.334-1.329 1.37-2.758.942-3.706a2.466 2.466 0 0 0-.228-.4 1.686 1.686 0 0 0-.227-.273 1.466 1.466 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 10.07 4c-.957 0-1.734.746-1.734 1.667 0 .92.777 1.666 1.734 1.666.343 0 .662-.095.931-.26z"/>
                        </svg>
                        <svg *ngIf="!recipientIndex.useMyOwnMailRecipient" style="color:orangered ;" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-chat-left-quote-fill" viewBox="0 0 16 16">
                            <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm7.194 2.766a1.688 1.688 0 0 0-.227-.272 1.467 1.467 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 5.734 4C4.776 4 4 4.746 4 5.667c0 .92.776 1.666 1.734 1.666.343 0 .662-.095.931-.26-.137.389-.39.804-.81 1.22a.405.405 0 0 0 .011.59c.173.16.447.155.614-.01 1.334-1.329 1.37-2.758.941-3.706a2.461 2.461 0 0 0-.227-.4zM11 7.073c-.136.389-.39.804-.81 1.22a.405.405 0 0 0 .012.59c.172.16.446.155.613-.01 1.334-1.329 1.37-2.758.942-3.706a2.466 2.466 0 0 0-.228-.4 1.686 1.686 0 0 0-.227-.273 1.466 1.466 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 10.07 4c-.957 0-1.734.746-1.734 1.667 0 .92.777 1.666 1.734 1.666.343 0 .662-.095.931-.26z"/>
                        </svg>
                    </div>
                    <div class="col-xs-11">
                        <app-to-message-accounts 
                        [isOurOwnRecipient]="recipientIndex.useMyOwnMailRecipient"
                        [recipient]="recipient"
                        (updateMessageMailList)='onUpdateToMessageList($event)'
                        (updateMessageMailList_AfterRemovedOne)="onUpdateToMessageListAfterRemoveOne($event)"
                        (updateRecipientIndexListItems_RecipientIndexes_id_toDelete)="onUpdateRecipientIndexListItem_afterRemoveSharedrecipient($event)">
                        </app-to-message-accounts>
                    </div>
                </div>
            </div>
      

        <div class="row" *ngIf="!isMessageSide">
        <div class="col-xs-12" >
            <div class="row">
                <div class="col-xs-1">
                    <svg style="color: grey;" 
                         xmlns="http://www.w3.org/2000/svg" 
                         width="22" 
                         height="22" 
                         fill="currentColor" 
                         class="bi bi-send-fill" 
                         viewBox="0 0 16 16"
                         matTooltip="MAKE SURE YOU HAVE AN EMAIL BROWSER"
                         matTooltipPosition="right" >
                        <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"/>
                      </svg>
                </div>
                <div class="col-xs-11">
                    <app-to-mail-accounts *ngIf="recipientIndex.useMyOwnMailRecipient"
                     [recipient]="recipient"
                     (updateToMailList)='onUpdateToMailList($event)'>
                    </app-to-mail-accounts>
                    <app-to-mail-accounts *ngIf="!recipientIndex.useMyOwnMailRecipient"
                    [recipient]="mailRecipient"
                    (updateToMailList)='onUpdateToMailList($event)'>
                    </app-to-mail-accounts>
                </div>
            </div>
        </div>
        </div>

        <br>

        <div class="row">
            <div class="col-xs-12">
            <div class="d-grid gap-2 d-md-flex justify-content-md-end" *ngIf="!isMessageSide">
                <button type="button" class="btn btn-link btn-sm" (click)="onComposeEmail()">
                    COMPLETE EMAIL RECIPIENTS
                    <svg xmlns="http://www.w3.org/2000/svg"  width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                    </svg> 
            </button>
            </div>
            </div>
        </div>
    

        <div *ngIf="!isMessageSide">
        <div *ngIf="composeEmail">
        
            <br>

            <app-cc-mail-account *ngIf="recipientIndex.useMyOwnMailRecipient"
            [recipient]="recipient"
            (updateCcMailList)='onUpdateCcMailList($event)'>
            </app-cc-mail-account>
            <app-cc-mail-account *ngIf="!recipientIndex.useMyOwnMailRecipient"
            [recipient]="mailRecipient"
            (updateCcMailList)='onUpdateCcMailList($event)'>
            </app-cc-mail-account>
            

             <app-bcc-mail-accounts *ngIf="recipientIndex.useMyOwnMailRecipient"
             [recipient]="recipient"
             (updateBccMailList)='onUpdateBccMailList($event)'>
             </app-bcc-mail-accounts>
             <app-bcc-mail-accounts *ngIf="!recipientIndex.useMyOwnMailRecipient"
             [recipient]="mailRecipient"
             (updateBccMailList)='onUpdateBccMailList($event)'>
             </app-bcc-mail-accounts>

        <br>

        <!-- HIDE FOR THE MOMENT -->
        
             <!-- <app-greetings *ngIf="recipientIndex.useMyOwnMailRecipient"
                     [recipient]="recipient"
                     (updateGreetings)='onUpdateGreetings($event)'>
             </app-greetings>
             <app-greetings *ngIf="!recipientIndex.useMyOwnMailRecipient"
             [recipient]="mailRecipient"
             (updateGreetings)='onUpdateGreetings($event)'>
             </app-greetings> -->
          

        </div>
        </div>

        <br>

       
        

   
</div>


<div *ngIf="!update" class="row">
    <div class="col-xs-12">
        <button  (click)="onSubmit()"    
                 type="button"
                 style="background-color:red; color: white"
                 class="btn"> 
                 confirm group name  
        </button>
    </div>
</div>

<br>


</form>





