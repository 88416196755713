<!-- <p>multi-entry-calendar-result works!</p> -->

<div class="row">
    <div class="col-xs-12">
  
      <br>
      
      <div class="row">
        <div class="col-xs-12">
          {{multiEntryCalendarDetailResult.description}}
        </div>
      </div>
  
      <br>
  
      <div class="row">
        <div class="col-xs-12">
  
  <mat-form-field>

    <mat-chip-list #chipList (click)="picker.open()">
      <mat-chip
        *ngFor="let value of multiEntryCalendarDetailResult.multiEntryDetailResults"
        [selectable]="false">
        {{ value | date }}
      </mat-chip>

      <input
        [value]="resetModel"
        matInput
        [matDatepicker]="picker"
        [matChipInputFor]="chipList"
        hidden
      />
    </mat-chip-list>

    <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker
      #picker
      [startAt]="init"
      [dateClass]="dateClass"
    ></mat-datepicker>
  </mat-form-field>
  
        </div>
      </div>
  
    </div>
  </div>
  