<p>printer works!</p>

<!-- Assuming you want to print the following HTML section: -->

<div>
    <!--Your html stuff that you want to print-->
  </div>
  <button>nothing to print </button> <!--Your relevant print button-->



  <!-- Now, what you have to do is tagging your wanted-to-print section by an id attribute, then link that id to a directive parameter in your button : -->

   <!--
   1)- Add an ID here
 -->
<div id="print-section"> 
    <!--Your html stuff that you want to print-->
  </div>
  
   <!--
     2)- Add the directive name in your button (ngxPrint),
     3)- Affect your ID to printSectionId
   -->
  <button printSectionId="print-section" class="btn btn-primary" ngxPrint>print</button> 


  <!-- Optional properties
You want a customized title for your printing window ? you have the choice by adding a new attribute to your print button printTitle: -->

<div  id="print-section1">

    <h2> print me </h2>
    
    </div>
    
    <button  
        class="btn btn-primary"
        printTitle="MyTitle"  
        printSectionId="print-section1"  
        ngxPrint>print</button>

<!-- Also, would you like to customize the printing window style sheet (CSS) ? Hence you can do so by adding infinite styles to another attribute called printStyle: -->

<div  id="print-section2">

    <h1>Also, would you like to customize the printing window style sheet (CSS) ? Hence you can do so by adding infinite styles to another attribute called printStyle:  </h1>
    
    </div>
    
    <button
        class="btn btn-primary"
        [printStyle]="{h1 : {'color': 'red'}, h2 : {'border': 'solid 1px'}}"
        printSectionId="print-section2"
        ngxPrint>print</button>
    
<!-- Here some simple styles were added to every h1 & h2 tags within the div where print-section is tagged to its id attribute.

If you would like to use your existing CSS with media print you can add the useExistingCss attribute: -->

<div  id="print-section3">

    <h2>Here some simple styles were added to every h1 & h2 tags within the div where print-section is tagged to its id attribute.

      If you would like to use your existing CSS with media print you can add the useExistingCss attribute: </h2>
    
    </div>
    
    <button
        class="btn btn-primary"
        [useExistingCss]="true"
        printSectionId="print-section3"
        ngxPrint>print</button>

<!-- If you want to customize the printing window style sheet (CSS) by importing the css provided in assets/css use styleSheetFile: -->

<div  id="print-section4">

    <h2>If you want to customize the printing window style sheet (CSS) by importing the css provided in assets/css use styleSheetFile: </h2>
    
    </div>
    
    <button
      class="btn btn-primary"
      styleSheetFile="assets/css/custom1.css,assets/css/custom2.css"
      printSectionId="print-section4"
      ngxPrint>print</button>

      