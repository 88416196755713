<!-- <p>link-grid-edit works!</p> -->

<mat-grid-list cols="2" rowHeight="2:1" [gutterSize]="'10px'">

    <mat-grid-tile >
        
        <span  class="pointer">
            <svg (click)="onPrimoButton()"  [ngStyle]="{color: getButtonColorOnClick()}" xmlns="http://www.w3.org/2000/svg" width="37" height="37" fill="currentColor" class="bi bi-bandaid" viewBox="0 0 16 16">
                <path d="M14.121 1.879a3 3 0 0 0-4.242 0L8.733 3.026l4.261 4.26 1.127-1.165a3 3 0 0 0 0-4.242ZM12.293 8 8.027 3.734 3.738 8.031 8 12.293 12.293 8Zm-5.006 4.994L3.03 8.737 1.879 9.88a3 3 0 0 0 4.241 4.24l.006-.006 1.16-1.121ZM2.679 7.676l6.492-6.504a4 4 0 0 1 5.66 5.653l-1.477 1.529-5.006 5.006-1.523 1.472a4 4 0 0 1-5.653-5.66l.001-.002 1.505-1.492.001-.002Z"/>
                <path d="M5.56 7.646a.5.5 0 1 1-.706.708.5.5 0 0 1 .707-.708Zm1.415-1.414a.5.5 0 1 1-.707.707.5.5 0 0 1 .707-.707ZM8.39 4.818a.5.5 0 1 1-.708.707.5.5 0 0 1 .707-.707Zm0 5.657a.5.5 0 1 1-.708.707.5.5 0 0 1 .707-.707ZM9.803 9.06a.5.5 0 1 1-.707.708.5.5 0 0 1 .707-.707Zm1.414-1.414a.5.5 0 1 1-.706.708.5.5 0 0 1 .707-.708ZM6.975 9.06a.5.5 0 1 1-.707.708.5.5 0 0 1 .707-.707ZM8.39 7.646a.5.5 0 1 1-.708.708.5.5 0 0 1 .707-.708Zm1.413-1.414a.5.5 0 1 1-.707.707.5.5 0 0 1 .707-.707Z"/>
             </svg>
        </span>
            
        <mat-grid-tile-footer style="background-color: #3f51b5; height: 20%;">
            <p class="pointer"
               [ngStyle]="{color: getButtonColorOnClick()}"
               (click)="onPrimoButton()"  > primo</p>
        </mat-grid-tile-footer>
      
        
       
    </mat-grid-tile>

    <mat-grid-tile>
        <svg style="color: white" xmlns="http://www.w3.org/2000/svg" width="37" height="37" fill="currentColor" class="bi bi-basket3-fill" viewBox="0 0 16 16">
            <path d="M5.757 1.071a.5.5 0 0 1 .172.686L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15.5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-1A.5.5 0 0 1 .5 6h1.717L5.07 1.243a.5.5 0 0 1 .686-.172zM2.468 15.426.943 9h14.114l-1.525 6.426a.75.75 0 0 1-.729.574H3.197a.75.75 0 0 1-.73-.574z"/>
          </svg>
          <mat-grid-tile-footer style="background-color: #3f51b5; height: 20%;">
            <p style="color: white"> secondo</p>
        </mat-grid-tile-footer>
    </mat-grid-tile>

    <mat-grid-tile>
        <svg style="color: white" xmlns="http://www.w3.org/2000/svg" width="37" height="37" fill="currentColor" class="bi bi-browser-chrome" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M16 8a8.001 8.001 0 0 1-7.022 7.94l1.902-7.098a2.995 2.995 0 0 0 .05-1.492A2.977 2.977 0 0 0 10.237 6h5.511A8 8 0 0 1 16 8ZM0 8a8 8 0 0 0 7.927 8l1.426-5.321a2.978 2.978 0 0 1-.723.255 2.979 2.979 0 0 1-1.743-.147 2.986 2.986 0 0 1-1.043-.7L.633 4.876A7.975 7.975 0 0 0 0 8Zm5.004-.167L1.108 3.936A8.003 8.003 0 0 1 15.418 5H8.066a2.979 2.979 0 0 0-1.252.243 2.987 2.987 0 0 0-1.81 2.59ZM8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"/>
          </svg>
          <mat-grid-tile-footer style="background-color: #3f51b5; height: 20%;">
            <p style="color: white "> terzo</p>
        </mat-grid-tile-footer>
    </mat-grid-tile>

    <mat-grid-tile>
        <svg style="color: white" xmlns="http://www.w3.org/2000/svg" width="37" height="37" fill="currentColor" class="bi bi-card-image" viewBox="0 0 16 16">
            <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
            <path d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z"/>
          </svg>
          <mat-grid-tile-footer style="background-color: #3f51b5; height: 20%;">
            <p style="color: white"> quarto </p>
        </mat-grid-tile-footer>
    </mat-grid-tile>

  </mat-grid-list>
