import { Injectable } from "@angular/core";
import { AuthService } from 'src/app/services/auth-services/auth.service';
import { GetComponentsHttpRequestService } from 'src/app/services/gets-services/get-components-http-request.sevice';
import { Subscription, Subject } from "rxjs";

// ------ base ( singleBase ) -------------------------------------
import { SingleWord } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/single-word/single-word.model";
import { SingleWordInput } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/single-word/single-word-input-list/single-word-input.model";
import { ToggleSingleWordButton } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/buttons/toggle-single-word-button/toggle-single-word-button.model";
import { SingleDropDownSelect } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/select/select-list/single-drop-down-select/single-drop-down-select.model";
import { SingleSlider } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/slider/single-slider/single-slider.model";
// ------ base  -------------------------------------
import { Recipient } from "../../forms/form-list/form-item/recipient/recipient.model";
import { WordInput } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/word/word-input.model";
import { FixText } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/texts/fix-text-list/fix-text.model";
import { FixLayout } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/layout/fix-layout-list/fix-layout.model";
import { SingleDateCalendar } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/calendar-list/single-date-calendar.model";
import { MultiEntryCalendar } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/multi-entry-calendar/multi-entry-calendar-list/multi-entry-calendar.model";
import { CalculatorMixTable } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calculator/calculator-mix-table-list/calculator-mix-table.model";
import { DropDownSelect } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/select/select-list/drop-down-select/drop-down-select.model";
import { MultiFeatures } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/multi-features-list/multi-features.model";
import { Tab } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/tabs/tab-list/tab.model";
import { Paragraph } from "src/app/forms/form-list/form-item/paragraph/paragraph.model";
import { Form } from "src/app/forms/form.model";
import { StorePersonalRecipientComponentsService } from "./store-personal-recipient-components.service";
import { ParagraphFeature } from "src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature.model";
import { FormResult } from "src/app/forms/form-results/form-result.model";
import { WordInputService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/word/word-input.service";
import { InitialNullSetupService } from "../starting-services/initial-null-setup.service";
import { SingleWordService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/single-word/single-word.service";
import { SingleWordInputService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/single-word/single-word-input-list/single-word-input.service";
import { ToggleSingleWordButtonService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/buttons/toggle-single-word-button/toggle-single-word-button.service";
import { SingleDropDownSelectService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/select/select-list/single-drop-down-select/single-drop-down-select.service";
import { SingleSliderService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/slider/single-slider/single-slider-list/single-slider.service";
import { FixTextService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/texts/fix-text-list/fix-text.service";
import { FixLayoutService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/layout/fix-layout-list/fix-layout.service";
import { SingleDateCalendarService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/calendar-list/single-date-calendar.service";
import { MultiEntryCalendarService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/multi-entry-calendar/multi-entry-calendar.service";
import { CalculatorMixTableService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calculator/calculator-mix-table-list/calculator-mix-table.service";
import { DropDownSelectService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/select/select-list/drop-down-select/drop-down-select.service";
import { MultiFeaturesService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/multi-features-list/multi-features.service";
import { TabService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/tabs/tab-list/tab.service";
import { ParagraphService } from "src/app/forms/form-list/form-item/paragraph/paragraph.service";
import { FormService } from "src/app/forms/form.service";
import { FormResultsService } from "src/app/forms/form-results/form-results.service";
import { Chat } from "src/app/chats/chat.model";
import { ChatService } from "src/app/chats/chat.service";
import { SharedCalendarStatus } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/shared-calendar-status-list/shared-calendar-status.model";
import { SharedCalendarStatusService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/shared-calendar-status-list/shared-calendar-status.service";




@Injectable ({
    providedIn: 'root' 
})

export class StoreInitialEmptyPersonalComponentsService {

    constructor ( private storePersonalRecipientComponentsService: StorePersonalRecipientComponentsService ,
                  private initialNullSetupService: InitialNullSetupService ,
                  private wordInputService: WordInputService , 
                  private singleWordService: SingleWordService , 
                  private singleWordInputService: SingleWordInputService ,
                  private toggleSingleWordButtonService: ToggleSingleWordButtonService , 
                  private singleDropDownSelectService: SingleDropDownSelectService , 
                  private singleSliderService: SingleSliderService , 
                  private fixTextService: FixTextService ,
                  private fixLayoutService: FixLayoutService ,
                  private singleDateCalendarService: SingleDateCalendarService , 
                  private multiEntryCalendarService: MultiEntryCalendarService , 
                  private sharedCalendarStatusService: SharedCalendarStatusService,
                  private calculatorMixTableService: CalculatorMixTableService , 
                  private dropDownSelectService: DropDownSelectService , 
                  private multiFeaturesService: MultiFeaturesService ,
                  private tabService: TabService ,
                  private paragraphService: ParagraphService , 
                  private formService: FormService , 
                  private formResultsService: FormResultsService ,
                  private chatService: ChatService
      
      ){}


httpBaseComponentResult: any 

/**
 * we are going to make 2 calls : one for starter where all the components are empty "[ ]"
 * and a second one , only when the page is called , where we really get the list of components 
 * stored in the server with an http call 
 */

 storeInitialEmptyPersonalComponents(userEmail: string, newSharedRecipients: Recipient[] ){

// // ----------------- sending BASE ( singleBase) updated results ------------------------------------------

                    const newSingleWords: SingleWord[] = []
                    console.log(newSingleWords)
                    this.singleWordService.singleWords = newSingleWords
                    this.initialNullSetupService.initialNullSingleWords = newSingleWords

                    const newSingleWordInputs: SingleWordInput[] = []
                    console.log(newSingleWordInputs)
                    this.singleWordInputService.singleWordInputs = newSingleWordInputs
                    this.initialNullSetupService.initialNullSingleWordInputs = newSingleWordInputs

                    const newToggleSingleWordButtons: ToggleSingleWordButton[] = []
                    console.log(newToggleSingleWordButtons)
                    this.toggleSingleWordButtonService.toggleSingleWordButtons = newToggleSingleWordButtons
                    this.initialNullSetupService.initialNullToggleSingleWordButtons = newToggleSingleWordButtons

                    const newSingleDropDownSelects: SingleDropDownSelect[] = []
                    console.log(newSingleDropDownSelects) 
                    this.singleDropDownSelectService.singleDropDownSelects = newSingleDropDownSelects 
                    this.initialNullSetupService.initialNullSingleDropDownSelects = newSingleDropDownSelects 
                   
                    const newSingleSliders: SingleSlider[] = []
                    console.log(newSingleSliders)
                    this.singleSliderService.singleSliders = newSingleSliders 
                    this.initialNullSetupService.initialNullSingleSliders = newSingleSliders




// ----------------- END sending BASE ( singleBase) updated results ------------------------------------------


// ----------------- sending BASE updated results ------------------------------------------

                    const newWordInputs: WordInput[]= []
                    this.wordInputService.wordsInput = newWordInputs
                    this.initialNullSetupService.initialNullWordInputs = newWordInputs

                    const newFixTexts : FixText[] = []
                    console.log(newFixTexts)
                    this.fixTextService.fixTexts = newFixTexts 
                    this.initialNullSetupService.initialNullFixTexts = newFixTexts

                    const newFixLayouts: FixLayout[] = []
                    console.log(newFixLayouts) 
                    this.fixLayoutService.fixLayouts = newFixLayouts 
                    this.initialNullSetupService.initialNullFixLayouts = newFixLayouts

                    const newSingleDateCalendars: SingleDateCalendar[] = []
                    console.log(newSingleDateCalendars)
                    this.singleDateCalendarService.singleDateCalendars = newSingleDateCalendars 
                    this.initialNullSetupService.initialNullSingleDateCalendars = newSingleDateCalendars

                    const newMultiEntryCalendars: MultiEntryCalendar[]= []
                    console.log(newMultiEntryCalendars)
                    this.multiEntryCalendarService.multiEntryCalendars = newMultiEntryCalendars
                    this.initialNullSetupService.initialNullMultiEntryCalendars = newMultiEntryCalendars

                    const newSharedCalendarStatusItems: SharedCalendarStatus[]= []
                    console.log(newSharedCalendarStatusItems)
                    this.sharedCalendarStatusService.sharedCalendarStatusItems = newSharedCalendarStatusItems 
                    this.initialNullSetupService.initialNullSharedCalendarStatusItems = newSharedCalendarStatusItems

                    const newCalculatorMixTables: CalculatorMixTable[] = []
                    console.log(newCalculatorMixTables)
                    this.calculatorMixTableService.calculatorMixTables = newCalculatorMixTables
                    this.initialNullSetupService.initialNullCalculatorMixTables = newCalculatorMixTables


                    const newDropDownSelects: DropDownSelect[] = []
                    console.log(newDropDownSelects)
                    this.dropDownSelectService.dropDownSelects = newDropDownSelects 
                    this.initialNullSetupService.initialNullDropDownSelects = newDropDownSelects

// ----------------- END sending BASE updated results ------------------------------------------ 
 

// ----------------- START sending MULTIFEATURES updated results ------------------------------------------ 
                    
                    const newMultiFeatures: MultiFeatures[] = []
                    console.log(newMultiFeatures)
                    this.multiFeaturesService.multiFeatures = newMultiFeatures 
                    this.initialNullSetupService.initialNullMultiFeatures = newMultiFeatures


                
                    const newTabList: Tab[] = []
                    console.log(newTabList)
                    this.tabService.tabList = newTabList 
                    this.initialNullSetupService.initialNullTabs = newTabList 

// ----------------- STOP sending MULTIFEATURES updated results ------------------------------------------ 

// ----------------- START sending PARAGRAPHS + FORMS updated results ------------------------------------------ 


                    const newParagraphs: Paragraph[] = []
                    this.paragraphService.paragraphs = newParagraphs 
                    this.initialNullSetupService.initialNullParagraphs = newParagraphs


                    const newForms: Form[] = []
                    this.formService.forms = newForms 
                    this.initialNullSetupService.initialNullForms = newForms 
                

                    const newFormResults: FormResult[] = []
                    this.formResultsService.formResults = newFormResults 
                    this.initialNullSetupService.initialNullFormResults = newFormResults 

                    const newChats: Chat[] = []
                    this.chatService.chats = newChats 
                    this.initialNullSetupService.initialNullChats = newChats 

                    

                    this.storePersonalRecipientComponentsService.getUserStartingRecipientComponents(userEmail , newSharedRecipients)

}




ngOnDestroy(){
  //  this.updateInitialHttpDataFromAuth.unsubscribe()
}



}