import { Component, OnInit , Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Feature } from '../../../feature.model';
import { SingleSlider } from '../single-slider.model';
import { SingleSliderDetail } from '../single-slider-detail.model';
import { SingleSliderService } from './single-slider.service';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { CreateNewFeatureContainerAndAddToFeatureContainerListItemService } from 'src/app/services/shared-services/create-new-feature-container-and-update-feature-container-list-item.service';
import { Subscription } from 'rxjs';
import { AdviseIfHttpStoreComponentsCallHasBeenMadeService } from 'src/app/services/other-components-services/advise-if-http-store-components-has-been-made.service';
import { StoreBaseComponentsService } from 'src/app/services/get-and-store-components/store-base-components.service';
import { FeatureContainerListItemService } from '../../../../../paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container-list-item/feature-container-list-items.service';
import { FeatureContainerListItem } from '../../../../../paragraph-edit/feature-container-list-item.model';
import { FeatureContainer } from '../../../../../paragraph-edit/feature-container.model';
import { FeatureContainerService } from '../../../../../paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container/feature-container.service';

@Component({
  selector: 'app-single-slider-list',
  templateUrl: './single-slider-list.component.html',
  styleUrls: ['./single-slider-list.component.css']
})
export class SingleSliderListComponent implements OnInit , OnDestroy {

  user: string 

  thisTypeOfComponent: string = 'singleSlider'

  singleSlider: SingleSlider;
  singleSliders: SingleSlider[];

  customCollapsedHeight: string = '20px';
  customExpandedHeight: string = '20px';

  index : number ;

  selectedIndex: number  ;
  @Input() selectedIndex_id: string  ;
  @Input() isParagraphEdit: boolean;
  @Input() weAreInMultiFeatureEdit: boolean;
  
  @Output() changeMultiFeatureIndex = new EventEmitter<Feature>()  ;
  @Output() eventIndex: number ;

    feature : Feature ;

    // this set as false in the DOM allow us to see the buttons to modify the component
    @Output() noNeeded : boolean ; 

    /** limit on the number of features to add */
    createButtonLimit : boolean ;
    singleSliderLimit = 25 ;

    @Input() dropId : number ;
    @Input() currentI : number ;

    @ViewChild('tabs') tabGroup: MatTabGroup

    /** properties on the next/previous button */
   nextButtonName : string;
   nextButtonIndex: number ;
   totalSingleSliders: number ;
   count: number ;

    /** this serve as new tab index when creating a new button */
    newTabIndex0: number ;
    newTabIndex: number ;

    @Output() deleteSingleFeatureItem = new EventEmitter<number>()

    isLoading: boolean
    isLoadingList: boolean 
      error = null 

    updateOnUserEmail: Subscription
    initialPersonalSingleSlidersHaveBeenStoredSubscription: Subscription

    @Output() holdOnCreateUpdateButtons = new EventEmitter<void>()

    featureContainerPlusParentsHaveBeenStored: Subscription 


  constructor( private singleSliderService: SingleSliderService, 
               private userObjectUpdateService: UserObjectUpdateService ,
               private postComponentsHttpRequestService: PostComponentsHttpRequestService , 
               private adviseIfHttpStoreComponentsCallHasBeenMadeService: AdviseIfHttpStoreComponentsCallHasBeenMadeService ,
               private storeBaseComponentsService: StoreBaseComponentsService ,
               private featureContainerService: FeatureContainerService ,
               private featureContainerListItemService: FeatureContainerListItemService, 
               private createNewFeatureContainerAndAddToFeatureContainerListItemService: CreateNewFeatureContainerAndAddToFeatureContainerListItemService ) { }

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
    })

    this.isLoading = true 
    this.isLoadingList = true 
  
    if ( this.user === null ){console.log('is going directly to continue as NULL user'), this.continueInitialOnInit()}
    if ( this.user !== null ){
      if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.singleSlidersHasBeenStored === true  ){ console.log('is going direvtly to continue'),   this.continueInitialOnInit() }
      if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.singleSlidersHasBeenStored === false ){ console.log('is calling http'), this.storeBaseComponentsService.callBaseComponentsListByUserAndType(this.user , 'singleSlider') }
    }
  
    this.initialPersonalSingleSlidersHaveBeenStoredSubscription = this.singleSliderService.initialPersonalSingleSlidersHaveBeenStored
    .subscribe(()=>{
      this.continueInitialOnInit()
      this.adviseIfHttpStoreComponentsCallHasBeenMadeService.singleSlidersHasBeenStored = true 
  })
  
  this.featureContainerPlusParentsHaveBeenStored = this.featureContainerListItemService.newFeatureContainerAndparentsHaveBeenStored
  .subscribe((feature_plus_typeOfComponent: any )=> {
  
    const currentFeature:Feature = feature_plus_typeOfComponent.feature 
    const currentTypeOfComponent: string = feature_plus_typeOfComponent.featureContainerName
  
    console.log(feature_plus_typeOfComponent)
    if ( currentTypeOfComponent === this.thisTypeOfComponent){
      this.onEmitToMultiFeatureEditChangedIndex(currentFeature)
    }
  
  })

   
}

continueInitialOnInit(){

  console.log('IT S CONTINUING INITIAL ONINIT')
  const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('singleSlider')

  this.singleSliders = []
  for ( let i=0; i<personalFeatureContainerListItem.featureContainer_ids.length ; i++ ){
        let currentFeatureContainer: FeatureContainer = this.featureContainerService.getFeatureContainerBy_id(personalFeatureContainerListItem.featureContainer_ids[i])
        this.singleSliders.push(this.singleSliderService.getSingleSliderBy_id(currentFeatureContainer.componentReference_id))
  }
  console.log(this.singleSliders)

  this.selectedIndex = this.singleSliders.findIndex(x=> x._id === this.selectedIndex_id)

  if (this.singleSliders.length < this.singleSliderLimit  ){ this.createButtonLimit = true }
  if (this.singleSliders.length >= this.singleSliderLimit ) { this.createButtonLimit = false };


  this.totalSingleSliders = this.singleSliders.length
  this.count = this.selectedIndex;
  this.nextButtonIndex = this.selectedIndex+1 
  this.nextButtonName = this.singleSliders[this.selectedIndex].name 

  console.log(this.singleSliders)

  if ( this.user !== null ){ this.isLoadingList = false , this.isLoading = false }
  if ( this.user === null ){  setTimeout(()=>{ this.isLoadingList = false , this.isLoading = false }, 200 )  }

}

     /** for each click we recreate a new feature  */
onLinkClick($event) {

  this.index = this.tabGroup.selectedIndex ; 
 
  const newOwner = this.user 
  const newIsNew = false
  const newDropId = this.currentI;
  const newFeature_id = this.singleSliders[this.index]._id
  const newFeatureName = 'singleSlider' ;
  const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
 
  this.feature = newFeature;
 
  this.changeMultiFeatureIndex.emit(this.feature)
  this.eventIndex = this.currentI ;
 
  console.log('drop id =', this.currentI)
  console.log(this.feature)
 
  };

  onNextButton(){

    if ( this.tabGroup.selectedIndex+1 === this.totalSingleSliders ){
      this.index = 0

    const newOwner = this.user   
    const newIsNew = false
    const newDropId = this.currentI;
    const newFeature_id = this.singleSliders[this.index]._id
    const newFeatureName = 'singleSlider' ;
    const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
   
    this.feature = newFeature;
   
    this.changeMultiFeatureIndex.emit(this.feature)
    this.eventIndex = this.currentI ;
   
    console.log('drop id =', this.currentI)
    console.log(this.feature)

    } else if ( this.tabGroup.selectedIndex < this.totalSingleSliders ){
      this.index = this.tabGroup.selectedIndex+1 ; 

      const newOwner = this.user 
      const newIsNew = false
      const newDropId = this.currentI;
      const newFeature_id = this.singleSliders[this.index]._id
      const newFeatureName = 'singleSlider' ;
      const newFeature = new Feature ( newOwner, newIsNew, newDropId, newFeature_id, newFeatureName) ;
     
      this.feature = newFeature;
     
      this.changeMultiFeatureIndex.emit(this.feature)
      this.eventIndex = this.currentI ;
     
      console.log('drop id =', this.currentI)
      console.log(this.feature)
  
    }

    console.log('this index ',this.index)

};

onPreviousButton(){

  if ( this.tabGroup.selectedIndex === 0){
    this.index = this.totalSingleSliders-1 ;

  const newOwner = this.user 
  const newIsNew = false
  const newDropId = this.currentI;
  const newFeature_id = this.singleSliders[this.index]._id
  const newFeatureName = 'singleSlider' ;
  const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
 
  this.feature = newFeature;
 
  this.changeMultiFeatureIndex.emit(this.feature)
  this.eventIndex = this.currentI ;
 
  console.log('drop id =', this.currentI)
  console.log(this.feature)


} else if ( this.tabGroup.selectedIndex > 0){
  this.index = this.tabGroup.selectedIndex-1 ; 

    const newOwner = this.user 
    const newIsNew = false
    const newDropId = this.currentI;
    const newFeature_id = this.singleSliders[this.index]._id
    const newFeatureName = 'singleSlider' ;
    const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
   
    this.feature = newFeature;
   
    this.changeMultiFeatureIndex.emit(this.feature)
    this.eventIndex = this.currentI ;
   
    console.log('drop id =', this.currentI)
    console.log(this.feature)

  }

  console.log('this index ',this.index)

 };

 onCreate(){

 this.isLoading = true 
 this.isLoadingList = true 
 this.createNewSingleSlider()
    

}

 createNewSingleSlider(){
  const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('singleSlider')
  this.newTabIndex0 = personalFeatureContainerListItem.featureContainer_ids.length+1

  const new_id = 'singleSli'+this.newTabIndex0.toString() ;
  const newComponentName = 'singleSlider'
    const newUIID = 'singleSlider-'+this.newTabIndex0.toString()
  const newOwner = this.user ;
  const newDateAndTime = null ;
  const newAllUsersAllowed = false ;
  const newUserListAllowed = [];
  const newName = 'new single slider '+ this.newTabIndex0.toString();

  const newDetailName = 'edit slider name'
  const newUnit = ''
  const newAutoTicks = true
  const newDisabled = false
  const newInvert = false
  const newMax = 1000
  const newMin = 0
  const newShowTicks = true 
  const newStep = 50
  const newThumbLabel = true
  const newValue = 800
  const newVertical = false
  const newTickInterval = 100

  const newSingleSliderDetail = new SingleSliderDetail (newDetailName, newUnit, newAutoTicks, newDisabled, 
    newInvert, newMax, newMin, newShowTicks, newStep, newThumbLabel, newValue, newVertical, newTickInterval )

  const newSingleSlider = new SingleSlider (new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed, newDateAndTime, newName, [newSingleSliderDetail] )

  if ( this.user === null ){ 

    this.singleSliderService.addNew(newSingleSlider)

    const newOwner = this.user 
    const newIsNew = false
    const newDropId = this.currentI ;
    const newFeature_id = newSingleSlider._id
    const newFeatureName = 'singleWord' ;
    const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
   
    this.feature = newFeature
    this.createNewFeatureContainerAndAddToFeatureContainerListItemService.createFeatureContainer_toAddOnFeatureContainerListItem(newFeature, this.user)

   }
 
   if ( this.user !== null ){

 this.postComponentsHttpRequestService.httpStoreComponent(this.user, 'singleSlider', newSingleSlider )
      .subscribe((response: SingleSlider)=> {

    this.singleSliderService.addNew(response)

    const newOwner = this.user 
    const newIsNew = false
    const newDropId = this.currentI ;
    const newFeature_id = response._id
    const newFeatureName = 'singleSlider' ;
    const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
   
    this.feature = newFeature

    this.createNewFeatureContainerAndAddToFeatureContainerListItemService.createFeatureContainer_toAddOnFeatureContainerListItem(newFeature,this.user )
    console.log(this.feature)

  }, error => {
    console.log(error)

    const errorMessage: string = "we weren't able to create the new component, check the network or try later."
    this.error = errorMessage
    this.isLoadingList = false 
    /**
     *  EMIT and event to paragraphEdit to put update or create buttons for 4,5 sec 
     */
    this.holdOnCreateUpdateButtons.emit()

    /**
     * return to previous set up without the new component after a while to be able to read the message 
     */
    setTimeout(()=>{
      this.error = null 
      this.isLoadingList = false 
      this.isLoading = false 
    },4000)
   })
  }

};

onEmitToMultiFeatureEditChangedIndex(toEmitFeature: Feature ){
  console.log('is emitting changed feature' , toEmitFeature )
  this.changeMultiFeatureIndex.emit(toEmitFeature)
  // once this feature is emitted the multiFeature Edit will change index and this whole 
  // component will restart again
}



onDeleteSingleDropDownSelectItem(){
  this.deleteSingleFeatureItem.emit(this.currentI)
}

ngOnDestroy() {
  this.updateOnUserEmail.unsubscribe()
  this.featureContainerPlusParentsHaveBeenStored.unsubscribe()
  this.initialPersonalSingleSlidersHaveBeenStoredSubscription.unsubscribe()
    
}





}
