import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { MultiFeatures } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/multi-features-list/multi-features.model";
import { MultiFeaturesService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/multi-features-list/multi-features.service";
import { GetComponentsHttpRequestService } from "../gets-services/get-components-http-request.sevice";
import { StoreMultibaseItemPlusChildsConfirmationService } from "./store-multiBase-item-plus-childs-confirmation.service";
import { StoreSingleBaseComponentsService } from "./store-single-base-components.service";

@Injectable ({
    providedIn: 'root' 
})

export class StoreMultibaseComponentsService{

    constructor ( private getComponentsHttpRequestService: GetComponentsHttpRequestService ,
                
                  private multiFeatureService: MultiFeaturesService , 
                  private storeSingleBaseComponentsService: StoreSingleBaseComponentsService,
                  private storeMultibaseItemPlusChildsConfirmationService: StoreMultibaseItemPlusChildsConfirmationService
               
                ){}

/**
 * we are going to make 2 calls : one for starter where all the components are empty "[ ]"
 * and a second one , only when the page is called , where we really get the list of components 
 * stored in the server with an http call 
 */


 httpMultiFeaturesComponentResult: any 

 multiFeatureChanged = new Subject<MultiFeatures[]>()


storePersonalMultiFeatures(userEmail: string ){

 this.getComponentsHttpRequestService.httpGetComponentsByType(userEmail, 'multiBase')

                   .subscribe( res => {
                    console.log(res)

                    this.httpMultiFeaturesComponentResult = res 
                    
                    const newMultiFeatures: MultiFeatures[] = this.httpMultiFeaturesComponentResult.multiFeature
                    console.log(newMultiFeatures)
                    this.multiFeatureService.addNewMultiFeatures(newMultiFeatures)

                   })

}

getPersonalMultiFeature_withChilds_by_id(userEmail: string , multiFeature_id: string ){

    this.getComponentsHttpRequestService.httpGetUserComponentWithChildsBy_id(multiFeature_id)
        .subscribe((multiFeatureResult_withChilds: any )=>{
            
            this.storeMultibaseItemPlusChildsConfirmationService.composeMultiBaseComponentPlusChilds_idsList_plusStatus(multiFeatureResult_withChilds)

            /**
             * the multiFeature itself has been already stored so we can send this item first // just in case there are no childs it won't stay on loading
             */
            this.storeMultibaseItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenMultiFeatureItemPlusChildsAreStored(multiFeatureResult_withChilds._id)
            /**
             *  scompose childs on storeSingleBase service 
             */
            const singleFeatureTypeList : string[] = []


                  for (let i=0; i<multiFeatureResult_withChilds.features.length ; i++){
                       singleFeatureTypeList.push(multiFeatureResult_withChilds.features[i].featureName)
                   }

            const multiFeatureChild_list: any = multiFeatureResult_withChilds.multiFeatureChild_list
            const isComingFromFormItemCall = undefined 
            const isComingFromMultiFeatures = true // if false =  isComingFromTabs

            this.storeSingleBaseComponentsService.unpackAndDistributeSingleBaseComponents({singleFeatureTypeList, multiFeatureChild_list }, isComingFromFormItemCall , isComingFromMultiFeatures  )

        })

}


ngOnDestroy(){
}

}
