
<app-find-form-or-paragraph 
  *ngIf="newParagraphIndexType.isPublic"
  [weAreInFormIndexTopicList]="weAreInFormIndexTopicList"
  (emitUserEmailFound)="onAddEmailSearch($event)"
  (emitUserEmailIndexToCancel)="onDeleteEmailOnSearch($event)"
  [foundEmailList]="foundEmailList">
</app-find-form-or-paragraph>

<div *ngIf="!isLoading">
<div class="thinScrollBar" *ngFor="let paragraphTopic_id of paragraphTopicIndex?.paragraphTopic_ids ; let i = index ">
    
    <app-paragraph-index-topic 
    [isPersonal]="isPersonal"
    [isPublic]="isPublic"
    [index_id]="paragraphTopic_id">
    </app-paragraph-index-topic>
</div>
</div>

<div *ngIf="isLoading" >
  <app-loading-spinner></app-loading-spinner>
</div>


<div *ngIf="isPersonal" >
<div [hidden]="hideButton"  >
  
  <div *ngIf="createButtonLimit" class="example-box">
    <mat-chip (click)="onHideButton()" 
              style="background-color: #3f51b5 ; color: white ; width:200px "> 
              + PARAGRAPH TOPIC
    </mat-chip>
     </div> 
</div >   


<form [formGroup]="topicNameForm" (ngSubmit)="onSubmit()">
      <div *ngIf="hideButton" class="example-box">
  
      <div  class="example-box"  >
          
                <input 
                type="text" 
                id="topicName"
                formControlName="name"
                class="form-control"
                placeholder="add topic name"
                >
            
                <button (click)="onSubmit()" 
                        [hidden]="!hideButton" 
                        type="button"  
                        class="btn btn-primary ">
                        +
                </button>
             
          </div>
      </div> 
</form>



</div>

<div *ngIf="!paragraphTopic_ids_arePresent" class="example-container">
    
  <div *ngIf="newParagraphIndexType.isShared" class="example-box" >
    <h2 style="color: #3f51b5"> no available files yet </h2>
  </div>
  
  <div *ngIf="newParagraphIndexType.isPreferred"  class="example-box" >
    <h2 style="color: #3f51b5">add paragraphs from public </h2>
  </div>
 
</div>

