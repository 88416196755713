import { Component, Input, OnInit, Output , EventEmitter, OnDestroy} from '@angular/core';
import { Feature } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/feature.model';
import { FeatureTopic } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-topic.model';
import { CdkDragDrop} from '@angular/cdk/drag-drop';
import { DragDropService } from 'src/app/forms/dragdrop.service';
import { FeatureTopicService } from '../../feature-topic.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { FeatureIndexSelection } from '../feature-index-selection.model';
import { Subscription } from 'rxjs';
import { environment } from "src/environments/environment";


@Component({
  selector: 'app-feature-index-topic',
  templateUrl: './feature-index-topic.component.html',
  styleUrls: ['./feature-index-topic.component.css']
})
export class FeatureIndexTopicComponent implements OnInit , OnDestroy {

  user: string 

  isProduction: boolean 
  isFeatureIndexTopic_ForDevelopment_Only: boolean 
  featureIndexTopics_development_list: string[] = ['single words', 'single buttons', 
                     'singleselect', 'singleslider', 'multiFeature', 'tabs', 'fix layout', 
                     'table + calculation', 'select', 'comment', 'by industry sector', 
                     'scan QR codes' ,'calculatorMixTable' ]

  feature : Feature ;
  features : Feature [];
  featureTopic : FeatureTopic ;

  indexSelection: FeatureIndexSelection

  @Input() featureTopic_id: string 


  hideFeatures = true;

  /** to clear timer when close automatically the box */
  setTimer : any ;

  isThisFeatureTopicVisible: boolean

  updateIndexSelectionSubscription: Subscription
  updateOnUserEmail: Subscription

  featureIndexTopicIsLoaded: boolean = false 



  constructor( private featureTopicService: FeatureTopicService,
               private userObjectUpdateService: UserObjectUpdateService ,
               private DragDrop: DragDropService ){

                this.isProduction = environment.production
                }

  ngOnInit(){

    this.featureIndexTopicIsLoaded = true 
    console.log(this.featureIndexTopicIsLoaded)

    this.user = this.userObjectUpdateService.userEmail 
    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
  });

    this.featureTopic = this.featureTopicService.getFeatureTopicBy_id(this.featureTopic_id)

    if ( this.isProduction ){
      if ( this.featureIndexTopics_development_list.findIndex(x=> x === this.featureTopic.name ) !== -1 ){ this.isFeatureIndexTopic_ForDevelopment_Only = true }
      if ( this.featureIndexTopics_development_list.findIndex(x=> x === this.featureTopic.name ) === -1 ){ this.isFeatureIndexTopic_ForDevelopment_Only = false }
    }
    if ( !this.isProduction ){ this.isFeatureIndexTopic_ForDevelopment_Only = false }


    this.updateIndexSelectionSubscription = this.featureTopicService.sharedIndexSelection.subscribe(indexSelection => { 
      this.updateIndexSelection(indexSelection)
    } )

  console.log(this.featureTopic)
     
  }

  /**
   * 
   * @param indexSelection 
   * constructor ( public weAreInStandardParagraphEdit: boolean , 
                  public weAreInTabsEdit: boolean  ){}
   */


    updateIndexSelection(indexSelection: FeatureIndexSelection){
    this.indexSelection = indexSelection

    if ( this.indexSelection.weAreInStandardParagraphEdit == true && this.indexSelection.weAreInTabsEdit == false ){
      if ( this.featureTopic.isStandardFeature == true && this.featureTopic.isTab == false  ) { this.isThisFeatureTopicVisible = true  }
      if ( this.featureTopic.isStandardFeature == false && this.featureTopic.isTab == false) { this.isThisFeatureTopicVisible = false }
      if ( this.featureTopic.isStandardFeature == false && this.featureTopic.isTab == true) { this.isThisFeatureTopicVisible = true }
     
    }

    if (  this.indexSelection.weAreInStandardParagraphEdit == false && this.indexSelection.weAreInTabsEdit == false  ){
      if ( this.featureTopic.isStandardFeature == true && this.featureTopic.isTab == false  ) { this.isThisFeatureTopicVisible = false  }
      if ( this.featureTopic.isStandardFeature == false && this.featureTopic.isTab == false) { this.isThisFeatureTopicVisible = true }
      if ( this.featureTopic.isStandardFeature == false && this.featureTopic.isTab == true) { this.isThisFeatureTopicVisible = false }
     
    }

    if (  this.indexSelection.weAreInStandardParagraphEdit == false && this.indexSelection.weAreInTabsEdit == true ){
      if ( this.featureTopic.isStandardFeature == true && this.featureTopic.isTab == false  ) { this.isThisFeatureTopicVisible = true  }
      if ( this.featureTopic.isStandardFeature == false && this.featureTopic.isTab == false) { this.isThisFeatureTopicVisible = false }
      if ( this.featureTopic.isStandardFeature == false && this.featureTopic.isTab == true) { this.isThisFeatureTopicVisible = false }
     
      
    }

    setTimeout(()=>{
      this.featureIndexTopicIsLoaded = false 
      console.log(this.featureIndexTopicIsLoaded)
    },100)
    



  }

  /** clear timer before itstart again */
  clearTimer(){
    clearTimeout(this.setTimer)
  }

  onHideFeatures(){
    
    console.log(this.featureIndexTopicIsLoaded)
    this.hideFeatures = !this.hideFeatures ;
    console.log(this.hideFeatures)
    /** clear timer */
    this.clearTimer()
    /** restart counting */
   this.setTimer =  setTimeout(() => {
      this.hideFeatures = true ;
    }, 45000);
  }

  ngOnDestroy(){
    this.updateIndexSelectionSubscription.unsubscribe()
    this.updateOnUserEmail.unsubscribe()
  }


}
