import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormResult } from 'src/app/forms/form-results/form-result.model';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';

@Component({
  selector: 'app-chat-form-result-item',
  templateUrl: './chat-form-result-item.component.html',
  styleUrls: ['./chat-form-result-item.component.css']
})
export class ChatFormResultItemComponent implements OnInit , OnDestroy{

  user: string 

  @Input() formResult: FormResult;
  
  isReceived: boolean 

  noGreetings : boolean ;
  useSubstitute: boolean ;
  subjectSubstituteToNull: string = '< --- >'

  updateOnUserEmail: Subscription

  @Output() latestFormResultIsBeenAdded = new EventEmitter()



  constructor(  private userObjectUpdateService: UserObjectUpdateService ) { }

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
    })
  
  
       if ( this.formResult.sender.content === this.user ){ this.isReceived = false }
       if ( this.formResult.sender.content !== this.user ){ this.isReceived = true }

    console.log(this.formResult, this.user , this.isReceived )
    

    if ( this.formResult.recipientResult.greeting.length == 0 ){
      this.noGreetings = false 
    } else if ( this.formResult.recipientResult.greeting.length >= 1 ){
      this.noGreetings = true
    }

    if (this.formResult.subject ===''){
      this.useSubstitute = true
   } else {
     this.useSubstitute = false
   }

   this.latestFormResultIsBeenAdded.emit()


  }

  ngOnDestroy(): void {
    this.updateOnUserEmail.unsubscribe()
  }


  // onSubscribeNewFormResults(){
  //   if ( this.formResult.receiver.sentGreetings.length == 0 ){
  //     this.noGreetings = false 
  //   } else if ( this.formResult.receiver.sentGreetings.length >= 1 ){
  //     this.noGreetings = true
  //   }

  //   if (this.formResult.subject ===''){
  //     this.useSubstitute = true
  //  } else {
  //    this.useSubstitute = false
  //  }
  //   this.subscribeNewFormResults.next()
  // }

  // ngOnDestroy():void{
  //   this.updatedFormResultsSubscription.unsubscribe()
  // }

}
