import { HttpClient} from "@angular/common/http";
import { Injectable , NgZone } from "@angular/core";
import { Observable, Subject } from "rxjs";


@Injectable({
    providedIn: 'root'
})

export class LoginService {

    startLoadingMainPage =  new Subject<any>()
    adviseLoginOfCompleteLog = new Subject<void>()
    sendingLimitedGroupTestingInfo = new Subject<void>()

    constructor(){}



    signOutExternal = () => {
        localStorage.removeItem("token")
        console.log("token removed")
    }


    startLoadingMainPageOnLogin(){
        this.startLoadingMainPage.next();
    }
        
    getStartLoadingMainPageOnLoginConfirmation(): Observable <any> {
        return this.startLoadingMainPage.asObservable ();
    }

    startGettingPersonalFeatureIndex = new Subject<string>()
    $startGettingPersonalFeatureIndex: Observable<string> = this.startGettingPersonalFeatureIndex.asObservable()
/**
 * 
 * @param userEmail going to header component, the only visible one at the moment 
 */
     connectToPersonalFeatureIndexService(userEmail: string){
        console.log('connecting')
         this.startGettingPersonalFeatureIndex.next(userEmail)  // will head to Header component 

    }

    popUpLimitedGroupInfo(){
        this.sendingLimitedGroupTestingInfo.next()
    }


}