import { Component, Input ,OnInit } from '@angular/core';
import { WordInputDetailResult } from './word-input-detail-result.model';

@Component({
  selector: 'app-word-input-result',
  templateUrl: './word-input-result.component.html',
  styleUrls: ['./word-input-result.component.css']
})
export class WordInputResultComponent implements OnInit {

  @Input() wordInputDetailResults: WordInputDetailResult[]

  constructor() { }

  ngOnInit(){
    this.wordInputDetailResults
  }

}
