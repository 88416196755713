import { WordInputDetail } from './word-input-detail.model';

export class WordInput {

    constructor ( public _id: string , 
                  public componentName: string , 
                  public UIID: string , 
                  public owner: string ,
                  public allUsersAllowed: boolean,
                  public userListAllowed: string[],
                  public dateAndTime: string ,
                  public name: string , 
                  public details:  WordInputDetail[]){

    }

}