<p>sibiling1 works!</p>

<h1>Message from Service : {{message}}<h1>
  
    <button (click)="newMessage()">Change Message</button>

<button (click)= "clickMe() ">click me </button>




<div class="container">
    <h2>Movies</h2>
    <div cdkDropList [cdkDropListData]="MoviesList"
      class="movie-list" 
      cdkDropListSortingDisabled
      (cdkDropListDropped)="onDrop($event)">
      <div class="movie-block" *ngFor="let moviesList of MoviesList" cdkDrag>{{moviesList}}</div>
    </div>
  </div>

  <label for="exampleColorInput" class="form-label">Color picker</label>
<input type="color" class="form-control form-control-color" id="exampleColorInput" value="#563d7c" title="Choose your color">

<p>https://www.npmjs.com/package/@angular-material-components/color-picker</p>
<p>for color picker check also https://stackblitz.com/edit/demo-ngx-mat-color-picker</p>

<h2>this is a color picker !!! component </h2>
<h3>https://getbootstrap.com/docs/5.0/forms/form-control/#color</h3>
  <html> 
<body>


<a [href]="mailText">send Mail</a> <br>
<!-- <span (click)="mailMe()">Mail me the links on (click)</span> -->


using Long-press-count directive 

longPress: {{longPress}}
<br>
longPressing: {{longPressing}}
<br>
<br>
<input #button type="button" 
       long-press-count 
       (onLongPress)="onLongPress()" 
       (onLongPressing)="onLongPressing()" 
       value="Test" 
       style="background: green; border-radius: 8px; color: white; cursor: pointer; outline:none;">


using LongPress directive
       <h3>
        Value: {{value}}
      </h3>
      
      
      <button longPress (mouseLongPress)="increment()" (click)="increment()">Increment</button>
      <button longPress (mouseLongPress)="decrement()" (click)="decrement()">Decrement</button>

      <br>
      Update: Add touch event for touch devices, and to make things working, make sure the button or element, on which the user wants to long-press event to trigger, is non-selected.


</body>
</html>
