import { SingleDateCalendarDetail } from "./single-date-calendar-detail.model";

export class SingleDateCalendar {

    constructor ( public _id: string, 
                  public componentName: string , 
                  public UIID: string , 
                  public owner: string,
                  public allUsersAllowed: boolean,
                  public userListAllowed: string[],
                  public dateAndTime: string ,
                  public name : string,
                  public details: SingleDateCalendarDetail[] ) {}

}