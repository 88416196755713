import { Component, OnInit, Inject , ViewEncapsulation, NgZone, ChangeDetectorRef, OnDestroy} from '@angular/core';
import { Contact } from 'src/app/contacts/contact.model';
import { ContactService } from 'src/app/contacts/contact.service';
import { MatDialogRef , MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup , FormControl} from '@angular/forms' ;
import { ParagraphTopic } from '../form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.model';
import { ParagraphFeature } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature.model';
import { Paragraph } from '../../form-item/paragraph/paragraph.model' ;
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service' ;
import { DialogmanagerService } from 'src/app/services/other-components-services/dialogmanager.service';
import { ParagraphService } from '../../form-item/paragraph/paragraph.service';
import { Subject, Subscription } from 'rxjs';
import { ContactDetails } from 'src/app/contacts/contact-details.model';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';

@Component({
  selector: 'app-share-paragraph-index',
  templateUrl: './share-paragraph-index.component.html',
  styleUrls: ['./share-paragraph-index.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ShareParagraphIndexComponent implements OnInit, OnDestroy {

  user: string 

  weAreOnShareParagraphFormIndex = true

  paragraphTopic: ParagraphTopic
  paragraph_id: string
  paragraph: Paragraph
  paragraphFeatureContainerIndex: number

  contact: Contact 
  contactDetails: ContactDetails[]
  isContactEditMode: false
  isSelected= false

  newContactName: FormGroup

  isShareToPublic = false
  listOfCurrentSelectedContactEmail : string[]

  checkedIfIsBeenShared: boolean

  onDeselectAllContacts = new Subject<void>()

  canBeReshared: boolean

  updateOnUserEmail: Subscription



  constructor ( public dialogRef: MatDialogRef<ShareParagraphIndexComponent>,
                private contactService: ContactService,
                private paragraphService: ParagraphService ,
                private postComponentsHttpRequestService: PostComponentsHttpRequestService,
                private userObjectUpdateService: UserObjectUpdateService ,
                private dms: DialogmanagerService ,
                private initialNullSetupService: InitialNullSetupService ,
                private ngZone: NgZone, 
                private cdr: ChangeDetectorRef,
                @Inject(MAT_DIALOG_DATA) public editData: any) { }

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
    .subscribe((updatedUserEmail)=>{
     this.user = updatedUserEmail
    console.log('updated USER = ', this.user)
    })
    
    this.paragraph_id = this.editData.paragraph_id
    this.paragraph = this.paragraphService.getParagraphBy_id(this.paragraph_id )

    this.canBeReshared = this.paragraph.canBeResharedByOthers

    console.log(this.paragraphTopic , this.paragraph )

    this.isShareToPublic = this.paragraph.allUsersAllowed
    if ( this.isShareToPublic === true ){ this.isSelected = true }
    if ( this.isShareToPublic === false){ this.isSelected = false }

    /**
     * we need to check and flag whic one of the contacts were already selected 
     * to make it a clone , build the new array
     */

    this.listOfCurrentSelectedContactEmail = []
    if (this.paragraph.userListAllowed.length > 0){
        for ( let y=0; y<this.paragraph.userListAllowed.length ; y++ ){
          this.listOfCurrentSelectedContactEmail.push(this.paragraph.userListAllowed[y])
        }
    }

    this.contact = this.contactService.getContacts()[0]
    const newContactDetails = this.contactService.getSortedContacts()

    const filteredContactEmails: ContactDetails[] = []
    for ( let i=0; i< newContactDetails.length ; i++ ){
         if ( filteredContactEmails.findIndex(x=> x.contactEmail === newContactDetails[i].contactEmail ) === -1 ){
          filteredContactEmails.push(newContactDetails[i])
         }
    }
    this.contactDetails = filteredContactEmails


    if ( this.paragraph.allUsersAllowed === false && this.paragraph.userListAllowed.length === 0 ){ this.checkedIfIsBeenShared = false }
    if ( this.paragraph.allUsersAllowed === true || this.paragraph.userListAllowed.length > 0 ){ this.checkedIfIsBeenShared = true }
    

    this.newContactName = new FormGroup ({
      contactName: new FormControl ('')
    })


  }

  /**
   * export class Contact {

    constructor( public _id: string,
                 public componentName: string, 
                 public UIID: string, 
                 public owner: string,
                 public allUsersAllowed: boolean,
                 public userListAllowed: string[],
                 public dateAndTime: string,
                 public contactName: string , 
                 public contactEmail: string , 
                 public isAConnection: boolean,
                 public isBlocked: boolean,
                 public isBeenReported: boolean   
                
                 ){}
   */

  onAddContactDetail(){

    const lowercaseValue = this.newContactName.value.contactName.toLowerCase()
    this.checkIfContactExistOnContactList(lowercaseValue)


    this.newContactName = new FormGroup ({
      contactName: new FormControl ('')
    })


   
  }


  checkIfContactExistOnContactList(newContact: string ){

    if ( this.contactService.contacts[0].contactDetails.findIndex( x=> x.contactEmail === newContact ) === -1 ){
      // add contact to list 
        const contactName = '' 
        const contactEmail= newContact 
        const telNumber = undefined
        const isAConnection = true
        const isBlocked = false
        const isBeenReported= false 

        const newContactDetail = new ContactDetails (contactName, contactEmail, telNumber, isAConnection, isBlocked, isBeenReported )
         
         const contactItem = this.contactService.contacts[0] 
               contactItem.contactDetails.push(newContactDetail)

               this.contactService.contacts[0] = contactItem 
               this.initialNullSetupService.initialNullContacts[0] = contactItem

               this.ngZone.run(()=>{
                const newContactDetails = this.contactService.getSortedContacts()

                const filteredContactEmails: ContactDetails[] = []
                for ( let i=0; i< newContactDetails.length ; i++ ){
                if ( filteredContactEmails.findIndex(x=> x.contactEmail === newContactDetails[i].contactEmail ) === -1 ){
                     filteredContactEmails.push(newContactDetails[i])
                }
                }
                 this.contactDetails = filteredContactEmails
               })
               this.cdr.detectChanges()

        if ( this.user !== null ){
         this.postComponentsHttpRequestService.httpUpdateComponent(this.user, 'contact', contactItem )
             .subscribe((res)=> {
              console.log(res)
             }, error => {
              console.log( error )
             })
        }
    }


  }



  onSelectedContact(){
    this.isSelected = true
    this.isShareToPublic = true
    console.log('is shared to public')
    
    this.checkedIfIsBeenShared = true

  }

  onDeselectContact(){
    this.isSelected = false
    this.isShareToPublic = false
    console.log('NOT public anymore')

    if ( this.listOfCurrentSelectedContactEmail.length > 0 ){
      this.checkedIfIsBeenShared = true
 } else {
      this.checkedIfIsBeenShared = false
 }
  }

   /**
   * 
   * @param event receiving the contact to be removed , from contact item sub component
   */
    onRemoveContactFromList(event){
      this.listOfCurrentSelectedContactEmail.forEach((element,index)=>{
        if(element == event.contactEmail) this.listOfCurrentSelectedContactEmail.splice(index, 1)
        })
      console.log(this.listOfCurrentSelectedContactEmail)
      if ( this.listOfCurrentSelectedContactEmail.length > 0 || this.isShareToPublic === true ){
        this.checkedIfIsBeenShared = true
   } else {
        this.checkedIfIsBeenShared = false
   }
     }
   
   /**
    * 
    * @param event receiving the contact from contact item sub component and adding the email to the list
    */
     onAddContactToList(event){
       this.listOfCurrentSelectedContactEmail.push(event.contactEmail)
       console.log(this.listOfCurrentSelectedContactEmail)
       if ( this.listOfCurrentSelectedContactEmail.length > 0 || this.isShareToPublic === true ){
        this.checkedIfIsBeenShared = true
   } else {
        this.checkedIfIsBeenShared = false
   }
  }

  onClearSelectedContacts(){
    this.listOfCurrentSelectedContactEmail = []
    this.isShareToPublic = false 
    this.checkedIfIsBeenShared = false 
    this.onDeselectAllContacts.next()
  }

/**
 *  update the paragraphFeature with the new updated paragraphFeature that 
 * include the shared names 
 * 
 * still need to add the function to send the actual paragraph to the receiver ,  so that it can be stored 
 * in its local service 
 * 
 * we also need to check if it's been shared with contacts , and update all the child components to be flagged with
 * 
 *  allUsersAllowed : true in case of it
 *  userListAllowed : add all the users in the property 
 * 
 * For cycle to iterate all components 
 * 
 */

  onShareParagraph(){

    const new_id = this.paragraph._id
    const newComponentName = this.paragraph.componentName
    const newUIID = this.paragraph.UIID
    const newOwner = this.paragraph.owner
    const newDateAndTime = this.paragraph.dateAndTime ;
    const newUpdateDateTime = this.paragraph.updateDateAndTime;
    const newIsRemoved = this.paragraph.isRemoved
    const newAllUsersAllowed = this.isShareToPublic
    const newUserListAllowed = this.listOfCurrentSelectedContactEmail 
    const newCanBeResharedByOthers = this.canBeReshared
    const newParagraphName = this.paragraph.name
    const newParagraphDesc = this.paragraph.desc
    const newFeatures = this.paragraph.features
    const newFeatures_id_list = this.paragraph.feature_id_list

    const newParagraph = new Paragraph (  new_id,
                                          newComponentName,
                                          newUIID,
                                          newOwner,
                                          newDateAndTime,
                                          newUpdateDateTime,
                                          newIsRemoved ,
                                          newAllUsersAllowed,
                                          newUserListAllowed,
                                          newCanBeResharedByOthers , 
                                          newParagraphName, 
                                          newParagraphDesc, 
                                          newFeatures, 
                                          newFeatures_id_list );

    if ( this.user === null ){

      this.paragraphService.paragraphs[this.paragraphService.getParagraphs().findIndex(x=> x._id === this.paragraph._id)] = newParagraph
      /**
       * advise status change at paragraphFeatureContainer trhough paragraphService 
       */
      this.paragraphService.onUpdateParagraphShareStatusOnParagraphFeatureContainer()

      this.dms.QuickCommunicationPopUpComponent({
        quickComment:  "demo purpose , login to share"
      })
    }

    if ( this.user !== null ){

      this.postComponentsHttpRequestService.httpUpdateComponent(this.user, 'paragraph', newParagraph )
          .subscribe(
            (res)=> { 
            console.log(res)

            this.paragraphService.paragraphs[this.paragraphService.getParagraphs().findIndex(x=> x._id === this.paragraph._id)] = newParagraph
            this.paragraphService.onUpdateParagraphShareStatusOnParagraphFeatureContainer()

           })

    } // END IF STATEMENT

  }

  onCanBeReshared(){
    this.canBeReshared = true

  }

  onCannotBeReshared(){
    this.canBeReshared = false
  }

  ngOnDestroy(): void {
      this.updateOnUserEmail?.unsubscribe()
  }



 




}


