<div class="innerItemFrame2">
    <div class="col-xs-12">

        <app-single-loading-spinner *ngIf="editingVisible"></app-single-loading-spinner>
   
            <app-text-input
            [inputName]="initialText"
            [canUseIt]="true"
            [initialPlaceholder]="initialPlaceholder"
            [inputLabelInterface]="inputTextInnerCompNameInterface"
            (updatedNameEvent)="onUpdateNameInputEvent($event)"
            (updateNameInputValidity)="onUpdateNameInputValidity($event)"
            >
            <!-- [canUseIt]="canUseIt" -->
            </app-text-input>
        
        <!-- </div> -->

    </div>
</div>

