import { InnerArraySelect } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/shared-calendar-status-list/inner-array-select.model";

export class RangeInfoResult {

    constructor( public type: string , 
                 public name: string , 
                 public usersAccessResult: string[] ,
                 public rangeDetailResults: InnerArraySelect[] | string[]
                                            
               ){}

    }