import { Component, OnInit , Input } from '@angular/core';
import { Chat } from 'src/app/chats/chat.model';
import { Recipient } from 'src/app/forms/form-list/form-item/recipient/recipient.model';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';

@Component({
  selector: 'app-chat-item-content-header',
  templateUrl: './chat-item-content-header.component.html',
  styleUrls: ['./chat-item-content-header.component.css']
})
export class ChatItemContentHeaderComponent implements OnInit {

  user: string 

  chatIsSelected: boolean;
  @Input() chat: Chat;

  chatSubjectExist: boolean

  constructor( private userObjectUpdateService: UserObjectUpdateService) { }

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    console.log(this.chat, this.user )

    this.chatIsSelected = this.chat.isSelected

    
  }





}
