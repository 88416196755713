import { Component, OnInit, Injectable, Inject, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup , FormControl, FormBuilder, Validators } from '@angular/forms' ; 
import { MatDialogRef , MAT_DIALOG_DATA, _closeDialogVia } from '@angular/material/dialog' ;
import { Recipient } from 'src/app/forms/form-list/form-item/recipient/recipient.model';
import { RecipientIndex } from 'src/app/forms/form-list/form-edit/recipient-index-list/recipient-index.model';
import { Subscription, Subject } from 'rxjs';
import { Form } from 'src/app/forms/form.model';
import { RecipientService } from '../../recipient.service';
import { RecipientIndexListItem } from 'src/app/forms/form-list/form-edit/recipient-index-list/recipient-index-list-item.model';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { ChatHttpRequestService } from 'src/app/services/post-and-put-services/chat-http-request.service';
import { Chat } from 'src/app/chats/chat.model';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions} from '@angular/material/tooltip';
import { RecipientFeatureService } from 'src/app/forms/form-list/form-edit/recipient-index-list/recipient-feature.service';
import { RecipientFeature } from 'src/app/forms/form-list/form-edit/recipient-index-list/recipient-feature.model';
import { RecipientIndexService } from 'src/app/forms/form-list/form-edit/recipient-index-list/recipient-index.service';
import { RecipientIndexListItemService } from 'src/app/forms/form-list/form-edit/recipient-index-list/recipient-index-list-item.service';
import { RecipientIndexColumnService } from 'src/app/forms/form-list/form-edit/recipient-index-list/recipient-index-column.service';

@Injectable()
export class MyService  {

}

/** Custom options the configure the tooltip's default show/hide delays. */
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 300,
  hideDelay: 1000,
  touchendHideDelay: 1000,
};


@Component({
  selector: 'app-recipient-item',
  templateUrl: './recipient-item.component.html',
  styles: [ './recipient-item.component.css'],
  providers: [{provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}],
})
export class RecipientItemComponent implements OnInit , OnDestroy {

  user : string 
  recipientForm : FormGroup ; 



  @Input() youAreInFormEdit: boolean
  @Input() positionReferenceForResult_Id: number 
  @Input() paragraphIndexType
  
  form: Form ;
  recipient: Recipient ;
  mailRecipient: Recipient ; 
  title: string ;

  expansionPanel = true;
  update = true ;

  /**
   * enabling the button to update the changes 
   * should have a time window to open and close so that it doesn't bother much 
   * it's enables everytime we receive a data changes from sub-components or greetings change
   */
  // requestUpdateOnChanges = false ;

  
  nameGroup : string ;
  @Output() recipientName: string;

  @Output() changeRecipientName = new EventEmitter<Recipient>()

   /** from form service SubjectBehavior */
    receivedFormId: number ;
    receivedForm_id: string;
    index: number;
    formIndex: number
   // ** this is the event when clicked
   clickEventFromFormTopicIndexSubscription: Subscription ;

   /**
   * here start to subscribe the results
   */
 
   @Input() recipientIndex: RecipientIndex;
   @Input() recipientIndexListItem: RecipientIndexListItem

   @Output() emitSecondRecipientResult = new EventEmitter<Recipient>()
   @Output() emitRecipientResult = new EventEmitter<Recipient>()

   @Output() emitTemporaryHideRecipientsMessage = new EventEmitter<void>()
   @Output() emitTemporaryHideRecipientsMail = new EventEmitter<void>() 
   @Output() emitUpdatedRecipientIndexListItem = new EventEmitter<RecipientIndexListItem>()


   recipientGroupName: string ; 
   composeEmail: boolean = false ; 

   chatGroupExist: boolean

   trialRecipient : Recipient
   isLoading = false
   error = false

   greetingsValue: string 

  @Input() recipientListHasBeenDropped: boolean

  @Input() isMessageSide: boolean 


  updateSubComponents= new Subject<void>()
  updateOnUserEmail: Subscription

  canMakeTheRequest: boolean = true 
  canMakeRecipientFeatureRequest: boolean = true 



  constructor( private recipientService: RecipientService ,
               private recipientIndexService: RecipientIndexService,
               private recipientIndexListItemService: RecipientIndexListItemService,
               private recipientIndexColumnService: RecipientIndexColumnService, 
               private recipientFeatureService: RecipientFeatureService,
               private userObjectUpdateService: UserObjectUpdateService ,
               private postComponentsHttpRequestService: PostComponentsHttpRequestService, 
               public  dialogRef: MatDialogRef<RecipientItemComponent>,
               private chatHttpRequestService: ChatHttpRequestService ,
               @Inject(MAT_DIALOG_DATA) public editData: any ) {}



  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail
    //console.log(this.recipientIndexListItem, this.recipientIndex);
    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
    })
    
    
  
    this.recipient = this.recipientService.getRecipientBy_id(this.recipientIndex.recipient_id);

   this.recipientGroupName = this.recipientIndexListItem.recipientIndexListName;
   //console.log(this.recipient);
  
   /**
    * MAIL RECIPIENT is active ONLY WHEN WE HAVE recipientIndex.useMyOnwMailRecipient === false 
    */
   if ( this.recipientIndex.useMyOwnMailRecipient === false ){
        this.mailRecipient = this.recipientService.getRecipientBy_id(this.recipientIndex.mailRecipient_idCopy)
   }
   
    if( this.recipient.toMessage.length > 0){
      this.chatGroupExist = true 
    } else { 
      this.chatGroupExist = false
    }

    this.recipientForm = new FormGroup({
      name:  new FormControl (this.recipient.name)
    })

    setTimeout(()=>{
      this.onSubscribeRecipientResult()
    },200)


  };





  onComposeEmail(){
    this.composeEmail = !this.composeEmail ; 
  };

  onUpdate(){
    this.update = !this.update ;
  };
  
   onSubmit(){
    console.log(this.recipientForm);

    this.recipient.name = this.recipientForm.value.name

     if (this.user !==null ){ this.onHTTPUpdateRecipientAndChat( true ) }

     if (this.user === null ){ this.update = true }
  

}


onHideMessageRecipients(){
  console.log( 'emit hide message recipients')
  this.emitTemporaryHideRecipientsMessage.emit()
}

onHideMailRecipients(){
  console.log( 'emit hide mail recipients')
  this.emitTemporaryHideRecipientsMail.emit()
}

onUpdateRecipientIndexListItem_afterRemoveSharedrecipient($event){
  console.log('remove recipientIndex_id from recipientIndexListItem Ids =>  ',$event  )
  
  const recipientIndexListItem = this.currentRecipientIndexListItem_containRecipientIndex_id($event)
  const index = recipientIndexListItem.recipientIndexList_ids.findIndex(x=> x === $event )
  recipientIndexListItem.recipientIndexList_ids.splice( index , 1 )

  if( recipientIndexListItem.recipientIndexSelected_id === $event ){
    if ( recipientIndexListItem.recipientIndexList_ids.length > 0 ){
         recipientIndexListItem.recipientIndexSelected_id = recipientIndexListItem.recipientIndexList_ids[0]
    }
    if ( recipientIndexListItem.recipientIndexList_ids.length === 0 ){
        // here we need to put selected at null and advise recipientIndexColumn to close 
        recipientIndexListItem.recipientIndexSelected_id = null 
    }
  }

  this.emitUpdatedRecipientIndexListItem.emit(recipientIndexListItem)
}


onUpdateToMessageList(event){
  console.log(event)

  /**
   * this can only be when recipientIndex.useMyOwnMailRecipient === true
   */

  if (this.user !== null ){

    this.recipient.toMessage = event;

    if ( this.recipientIndex.useMyOwnMailRecipient && this.recipient.toMessage.length > 0 ){
    this.recipient.recipientList = [this.user, ...this.recipient.toMessage ]
    console.log('THIS MESSAGE RECIPIENTLIST = ', this.recipient.recipientList)
    }

    if ( this.recipientIndex.useMyOwnMailRecipient && this.recipient.toMessage.length == 0 ){

      this.recipient.recipientList = []
      console.log('THIS MESSAGE RECIPIENTLIST = ', this.recipient.recipientList)
      }

    // updating NON OUR recipient mean we are just deleting ourself 
    if ( !this.recipientIndex.useMyOwnMailRecipient && this.recipient.toMessage.length > 0 ){ 
      this.recipient.toMessage = event 
      this.recipient.recipientList = [ this.recipient.owner, ...this.recipient.toMessage ]
      console.log('THIS MESSAGE RECIPIENTLIST = ', this.recipient.recipientList)
    }

    if ( !this.recipientIndex.useMyOwnMailRecipient && this.recipient.toMessage.length == 0 ){ 
      this.recipient.toMessage = []
      this.recipient.recipientList = []
      console.log('THIS MESSAGE RECIPIENTLIST = ', this.recipient.recipientList)
      }

    
   /**
   * updating the chat in order to know if it's going to be sent to messages or not 
   */
  if ( this.user !== null ){ this.onHTTPUpdateRecipientAndChat(false) }


}

if ( this.user == null ){
  this.recipient.toMessage = event;
  
  if ( this.recipient.toMessage.length > 0 ){
    this.recipient.recipientList = [this.user , ...this.recipient.toMessage ]
    console.log('THIS MESSAGE RECIPIENTLIST = ', this.recipient.recipientList)
  }
  this.onSubscribeRecipientResult()
}

  
}


onUpdateToMessageListAfterRemoveOne(event){
  if (this.user !== null ){

    this.recipient.toMessage = event 

    if ( this.recipient.toMessage.length > 0 ){
      if ( this.recipientIndex.useMyOwnMailRecipient === true){
      this.recipient.recipientList = [this.user, ...this.recipient.toMessage ]
      console.log('THIS MESSAGE RECIPIENTLIST = ', this.recipient.recipientList)
      }
      if ( this.recipientIndex.useMyOwnMailRecipient !== true){ 
        const indexOfRecipientOwner = this.recipient.toMessage.findIndex(x=> x === this.recipient.owner )
        this.recipient.toMessage.splice(indexOfRecipientOwner, 1 )
        this.recipient.recipientList = [ this.recipient.owner, ...this.recipient.toMessage ]
        console.log('THIS MESSAGE RECIPIENTLIST = ', this.recipient.recipientList)
      }
  
     /**
     * updating the chat in order to know if it's going to be sent to messages or not 
     */
         if ( this.canMakeTheRequest){
          this.canMakeTheRequest = false 
          this.onHTTPUpdateRecipientAndChat_afterRemoval()

          setTimeout(() => {
            this.canMakeTheRequest = true 
          }, 500);
         }
    
  
    }
    if ( this.recipient.toMessage.length == 0 ){
      if ( this.recipientIndex.useMyOwnMailRecipient === true){
      this.recipient.recipientList = [this.user]
      console.log('THIS MESSAGE RECIPIENTLIST = ', this.recipient.recipientList)
      }
  
     /**
     * updating the chat in order to know if it's going to be sent to messages or not 
     */
    if ( this.user !== null ){ 
         
      if ( this.canMakeTheRequest){
        this.canMakeTheRequest = false 
        this.onHTTPUpdateRecipientAndChat_afterRemoval()

        setTimeout(() => {
          this.canMakeTheRequest = true 
        }, 500);
       }
    }
  
    }
  
    if ( this.recipientIndex.useMyOwnMailRecipient === false ){
      console.log('THIS SHOULD NOT HAPPEN !!!, throw error ! except if it s my own mail only')
  
    }
  }
  
  if ( this.user == null ){
    this.recipient.toMessage = event;
    
    if ( this.recipient.toMessage.length > 0 ){
      this.recipient.recipientList = [this.user , ...this.recipient.toMessage ]
      console.log('THIS MESSAGE RECIPIENTLIST = ', this.recipient.recipientList)
    }
    this.onSubscribeRecipientResult()
  }

}

onHTTPUpdateRecipientAndChat_afterRemoval(){

            const modifiedRecipient = this.recipient

            const newRecipient_id = this.recipient._id
            const newChat_id = this.recipient.chatRoomId
            const newChatAllUsersAllowed = false ;
            const newChatUserListAllowed = []; 
            const newChatRoomDateAndTime = null 
            const newChatRoomUpdateDateAndTime = null 
            const newIsVisible = false ;
            const newIsNewChat = true
            const newIsBeenUpdated = false
            const newIsSelected = false ; 
            const newSubject = ''
  
            const newChatRoom = new Chat ( newChat_id , this.user, newChatAllUsersAllowed, newChatUserListAllowed,
                                           newIsVisible, newChatRoomDateAndTime , newChatRoomUpdateDateAndTime, 
                                           newIsNewChat, newIsBeenUpdated, newIsSelected , newSubject )

  this.chatHttpRequestService.httpRemoveRecipientOnChat(this.user , newRecipient_id , newChatRoom , modifiedRecipient )
      .subscribe((res)=> {
        console.log(res)

        if (this.recipientIndex.useMyOwnMailRecipient === true ){
          this.recipientService.updateRecipient(this.recipient)
        }

        // delete recipient_id from recipientFeature 
        // should also delete all the recipientIndexes into the formFeatures possible recipient index

        if ( this.recipientIndex.useMyOwnMailRecipient !== true){ 

          const recipientFeature: RecipientFeature = this.recipientFeatureService.getRecipientFeature()
          const indexOfrecipient_toDelete = recipientFeature.receivedRecipient_ids.findIndex(x=> x === this.recipient._id )
          recipientFeature.receivedRecipient_ids.splice(indexOfrecipient_toDelete , 1)

          if ( this.canMakeRecipientFeatureRequest ){
             this.canMakeRecipientFeatureRequest = false 

          this.postComponentsHttpRequestService.httpUpdateComponent(this.user, 'recipientFeature', recipientFeature )
              .subscribe((res)=> {
                console.log(res)

                this.recipientFeatureService.recipientFeatures = [recipientFeature]

                const indexRecipientIndex_withThisRecipient = this.recipientIndexService.getRecipientIndexes().findIndex(x=> x.recipient_id === this.recipient._id )
                const currentRecipientIndex = this.recipientIndexService.getRecipientIndex(indexRecipientIndex_withThisRecipient)
                const sharedRecipientIndexListItem = this.currentRecipientIndexListItem_containRecipientIndex_id(currentRecipientIndex._id)

                const index_ofRecipientId_ToEliminatesharedRecipientIndexListItem = sharedRecipientIndexListItem.recipientIndexList_ids.findIndex(x=> x === currentRecipientIndex._id )
                      sharedRecipientIndexListItem.recipientIndexList_ids.splice(index_ofRecipientId_ToEliminatesharedRecipientIndexListItem, 1)

                      if( sharedRecipientIndexListItem.recipientIndexSelected_id === currentRecipientIndex._id ){
                        if ( sharedRecipientIndexListItem.recipientIndexList_ids.length > 0 ){
                             sharedRecipientIndexListItem.recipientIndexSelected_id = sharedRecipientIndexListItem.recipientIndexList_ids[0]
                        }
                        if ( sharedRecipientIndexListItem.recipientIndexList_ids.length === 0 ){
                            // here we need to put selected at null and advise recipientIndexColumn to close 
                            sharedRecipientIndexListItem.recipientIndexSelected_id = null 
              
                        }
              
                      }

                this.emitUpdatedRecipientIndexListItem.emit(sharedRecipientIndexListItem)

                // in this case emit on the list or on the form that the recipinetIndex has null so close all 
                // recipients mail and messages 
              
                // check through all the formFeatureContainer to check if there is the same recipientIndex_id 
              })

            setTimeout(() => {
              this.canMakeRecipientFeatureRequest = true
            }, 500);

          } 
          }
      })
}


currentRecipientIndexListItem_containRecipientIndex_id(recipientIndex_id: string ):RecipientIndexListItem{

let foundRecipientIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItems().find(obj =>
    obj.recipientIndexList_ids.some(name => name === recipientIndex_id) );
    
return foundRecipientIndexListItem
  
}

onUpdateToMailList(event){
  
  if ( this.user !== null ){
  if ( this.recipientIndex.useMyOwnMailRecipient === true ){
       this.recipient.toMail = event

       if ( this.user !== null ){ this.onHTTPUpdateRecipientAndChat(false)  }
  
   }// end if if recipientIndex.useMyOwnMailRecipient === true 

   if ( this.recipientIndex.useMyOwnMailRecipient === false ){
        this.mailRecipient.toMail = event 

        if ( this.user !== null ){ this.onHTTPUpdateMailRecipientCopyOnly() }

   }
  }

  if ( this.user == null ){
    this.recipient.toMail = event
    this.onSubscribeRecipientResult()
  }
 
}

onUpdateCcMailList(event){

  if (this.user !== null ){
  if ( this.recipientIndex.useMyOwnMailRecipient === true ){
       this.recipient.ccMail = event
 
         if ( this.user !== null ){ this.onHTTPUpdateRecipientAndChat( false ) }

         }

  if ( this.recipientIndex.useMyOwnMailRecipient === false ){
  
          this.mailRecipient.ccMail = event 
  
          if ( this.user !== null ){ this.onHTTPUpdateMailRecipientCopyOnly() }
  
  }
}

if ( this.user == null ){
  this.recipient.ccMail = event
  this.onSubscribeRecipientResult()
}
  
};

onUpdateBccMailList(event){

  if (this.user !== null ){
  if ( this.recipientIndex.useMyOwnMailRecipient === true ){
       
       this.recipient.bccMail = event

       if ( this.user !== null ){ this.onHTTPUpdateRecipientAndChat( false ) }

       }

  if ( this.recipientIndex.useMyOwnMailRecipient === false ){
  /**
  * updating second recipient copy 
  */
        this.mailRecipient.bccMail = event  

        if ( this.user !== null ){ this.onHTTPUpdateMailRecipientCopyOnly() }

  }
}

if ( this.user == null ){
  this.recipient.bccMail = event
  this.onSubscribeRecipientResult()
}

};


onUpdateGreetings(event){
       console.log(event , 'event has arrived ')

       if ( this.user !== null ){

       if ( this.recipientIndex.useMyOwnMailRecipient === true ){
        this.recipient.greeting = event 

        /**
        * updating
        */
        if ( this.user !== null ){
         this.onHTTPUpdateRecipientAndChat( false ) 
        }
     
       console.log(this.greetingsValue)
  
       }

       if ( this.recipientIndex.useMyOwnMailRecipient === false ){
        /**
         * updating second recipient copy 
         */
        this.mailRecipient.greeting = event 

        if ( this.user !== null ){ this.onHTTPUpdateMailRecipientCopyOnly() }
       }
}

if ( this.user == null ){
  this.recipient.greeting = event 
  this.onSubscribeRecipientResult()
}

};

/**
 * update recipient and/maybe chatRoom
 * 
 *  a- define if it's our standard recipient or is someone else second recipient // with recipientIndex.useMyOwnMailRecipient
 *  b- if our check if it has chatRoomId in it , then go for option 1 or 2 
 *  c- 
 */
onHTTPUpdateRecipientAndChat( isSubmittingRecipientNameChangeOnly: boolean ){

  /**
   * this is when we are using our our recipient 
   */
  // if ( this.recipientIndex.useMyOwnMailRecipient === true ){
 
       if ( this.recipient.chatRoomId !== ""){
            /**
             * this is when chatRoom already exist on recipient 
             */
            const modifiedRecipient = this.recipient

            const newRecipient_id = this.recipient._id
            const newChat_id = this.recipient.chatRoomId
            const newChatAllUsersAllowed = false ;
            const newChatUserListAllowed = []; 
            const newChatRoomDateAndTime = null 
            const newChatRoomUpdateDateAndTime = null 
            const newIsVisible = false ;
            const newIsNewChat = true
            const newIsBeenUpdated = false
            const newIsSelected = false ; 
            const newSubject = ''
  
            const newChatRoom = new Chat ( newChat_id , this.user, newChatAllUsersAllowed, newChatUserListAllowed,
                                           newIsVisible, newChatRoomDateAndTime , newChatRoomUpdateDateAndTime, 
                                           newIsNewChat, newIsBeenUpdated, newIsSelected , newSubject )
  
  
            this.chatHttpRequestService.httpPutChatAndRecipient(this.user, newRecipient_id , newChatRoom , modifiedRecipient  )
                                       .subscribe((res)=> {
                                        console.log(res)

                                        this.recipientService.updateRecipient(this.recipient)

                                        if (isSubmittingRecipientNameChangeOnly === true ){
                                          this.changeRecipientName.emit(this.recipient)
                                          this.update = true;
                                        }
                                        this.onSubscribeRecipientResult()

                                      }, error => {
                                        console.log( error )
                                         /**
                                          * handle error on response 
                                          */
                                        })
            }
            /**
             * when chat doesn't exist yet , normally just on the very first default recipient 
             */
       if ( this.recipient.chatRoomId === "" ){
                 // create the chat the will hold the recipient 

                 const modifiedRecipient = this.recipient
                 const newRecipient_id = this.recipient._id
                 const newChat_id = null
                 const newChatAllUsersAllowed = false ;
                 const newChatUserListAllowed = []; 
                 const newChatRoomDateAndTime = null 
                 const newChatRoomUpdateDateAndTime = null
                 const newIsVisible = false ;
                 const newIsNewChat = true
                 const newIsBeenUpdated = false
                 const newIsSelected = false ; 
                 const newSubject = ''

                 const newChatRoom = new Chat ( newChat_id , this.user, newChatAllUsersAllowed, newChatUserListAllowed,
                                                newIsVisible, newChatRoomDateAndTime , newChatRoomUpdateDateAndTime,
                                                newIsNewChat, newIsBeenUpdated, newIsSelected , newSubject
                 )


                 this.chatHttpRequestService.httpCreateChatOnExistingRecipient(this.user , newChatRoom, modifiedRecipient, newRecipient_id)
                                            .subscribe((res: any )=> {
                                             console.log( res , 'start getting recipient with chatRoom,Id inside and update this on service ')

                                            this.recipient = res.recipientComponent.content
                                            this.recipientService.updateRecipient(this.recipient)

                                            if (isSubmittingRecipientNameChangeOnly === true ){
                                              this.changeRecipientName.emit(this.recipient)
                                              this.update = true;
                                            }
                                            // update new recipient to recipientService
                                            this.onSubscribeRecipientResult()

                                            // 
                                            if ( this.recipientIndex.useMyOwnMailRecipient !== true){

                                            }

                                            })
                } // end if chat doesn't exist on my own recipient 
  // } // END IF recipientIndex.useMyOwnMailRecipient === true

}



onHTTPUpdateMailRecipientCopyOnly(){

  if ( this.recipientIndex.useMyOwnMailRecipient === false  ){

    const new_recipient_id = this.mailRecipient._id
    const newComponentName = 'recipient'
    const newUIID = this.mailRecipient.UIID
    const newOwner = this.user 
    const newDateAndTime = this.mailRecipient.dateAndTime
    const newUpdateDateAndTime = this.mailRecipient.updateDateAndTime
    const newAllUsersAllowed = this.mailRecipient.allUsersAllowed
    const newUserListAllowed = this.mailRecipient.userListAllowed
    const newChat_roomid = ""
    const newBlockedUserList = this.mailRecipient.blockedUserList
    const newName = ""
    const newRecipientList = []
    const newToMessage = []
    const newToMail = this.mailRecipient.toMail;
    const newCcmail = this.mailRecipient.ccMail;
    const newBccMail = this.mailRecipient.bccMail;

    if ( this.mailRecipient.greeting == null ){ this.greetingsValue = '' }
    if ( this.mailRecipient.greeting!== null ){ this.greetingsValue = this.mailRecipient.greeting  }
    const newGreeting = this.greetingsValue  ;
    
    const updatedMailRecipient = new Recipient ( new_recipient_id, newComponentName, newUIID, newOwner, newDateAndTime , newUpdateDateAndTime, newChat_roomid, newAllUsersAllowed, 
                                             newUserListAllowed , newBlockedUserList,newName, newRecipientList , newToMessage, 
                                             newToMail, newCcmail, newBccMail , newGreeting );

    this.postComponentsHttpRequestService.httpUpdateComponent(this.user, 'recipient', updatedMailRecipient )
        .subscribe((res)=>{
          console.log(res)
        }, error => {
          console.log(error )
        })
          
        this.recipientService.updateRecipient(updatedMailRecipient)
        this.onSubscribeRecipientResult()
    }
}

onSubscribeRecipientResult(){ // send result to recipientList or formItem 

  if( this.recipientIndex.recipient_id === this.recipient._id ){
    
    this.emitRecipientResult.emit(this.recipient)
    if (this.recipientIndex.useMyOwnMailRecipient === false ){
      this.emitSecondRecipientResult.emit(this.mailRecipient )
    }
                                      
  }
}


ngOnDestroy() {
this.updateOnUserEmail?.unsubscribe()
}

    

}

