import { FixTextDetail } from "./fix-text-detail.model";

export class FixText {

    constructor ( public _id: string ,
                  public componentName: string , 
                  public UIID: string , 
                  public owner: string ,
                  public allUsersAllowed: boolean,
                  public userListAllowed: string[],
                  public dateAndTime: string ,
                  public name: string ,
                  public details: FixTextDetail[] ){}
}
