<form [formGroup]="editNumberName" (ngSubmit)="onSubmitName()">

    <div class="row">
        <div class="col-xs-12">

            <mat-form-field class="full-width" appearance="outline">
                <mat-label *ngIf="inputLabelInterface.exist">{{inputLabelInterface.name}}</mat-label>
               <div class="row" >

                <div class="col-xs-12">
                    <input 
                    matInput
                    type="text"
                    id="name"
                    formControlName="name">
                    <mat-error *ngIf="!editNumberName.get('name').valid" > <small>max length {{inputLabelInterface.maxLength}} </small>  </mat-error>
                </div>
            </div>
            </mat-form-field>


        </div>
    </div>

</form>
