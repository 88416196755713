import { Feature } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/feature.model";

export class MultiFeatures {

constructor(  public _id : string ,
              public componentName: string , 
              public UIID: string , 
              public owner : string ,
              public dateAndTime: string,
              public allUsersAllowed: boolean, 
              public userListAllowed: string[] ,  
              public name: string ,  
              public features: Feature[],
              public singleBase_id_list: string[] ){}

}