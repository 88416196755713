import { Injectable } from '@angular/core';
import { Paragraph } from './paragraph.model'
import { Subscription , Subject, Observable } from 'rxjs';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { StoreFormItemPlusChildsConfirmationService } from 'src/app/services/get-and-store-components/store-form-item-plus-childs-confirmation.service';
import { Feature } from './paragraph-list/paragraph-item/features/feature.model';

interface Editing {
  isEditingStandard: boolean,
  isEditingMultiFeature: boolean ,
  isEditingTab: boolean
}

@Injectable ({
    providedIn: 'root' 
})

export class ParagraphService {

  // trialParagraphs: Paragraph[]


  constructor( 
               private initialNullSetupService: InitialNullSetupService , 
               private storeFormItemPlusChildsConfirmationService: StoreFormItemPlusChildsConfirmationService ){}


    paragraph : Paragraph ;
    stopLoadingParagraphTopicIndex = new Subject<void>()
    nowCanReconstructFormItemAndStopLoading_onFormItem = new Subject<any>() // form + typeOfIndex
    isEditing: Editing = { isEditingStandard: true , isEditingMultiFeature: false, isEditingTab: false }

    public paragraphs : Paragraph[] = this.initialNullSetupService.initialNullParagraphs

    // updateInitialEmptyPersonalComponent : Subscription =  this.storeInitialEmptyPersonalComponentsService.paragraphsChanged
    // .subscribe((emptyRes)=> {
    // this.paragraphs = emptyRes
    // console.log('personal paragraphs= ', emptyRes)
    // })

   updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.paragraphsChangedBackToNull
      .subscribe((res)=> {
          this.paragraphs = res 
          console.log('updated paragraphs back to null = ', res)

      });
    
    


            getParagraph(id:number){
              return this.paragraphs[id];
          }

          getParagraphBy_id(_id: string){
            const newParagraph = this.paragraphs.find(
              (res)=> {
                return res._id === _id
            })
           return newParagraph
          }


          getParagraphs(){
            return this.paragraphs;
          }

          getUserParagraphList(user: string){
            const newUserParagraphList = this.paragraphs.filter(
              (res)=> {
                return res.owner === user
              }
            )
            return newUserParagraphList

          }

          paragraphLength(){
            return this.paragraphs.length ;
          }

          addParagraph(paragraph: Paragraph){
            this.paragraphs.push(paragraph);
            this.updateInitialComponents( this.paragraphs)
          
          }

          updateParagraph(updatedParagraph: Paragraph){
            const index = this.paragraphs.findIndex(x=> x._id === updatedParagraph._id)
            this.paragraphs[index]= updatedParagraph 
            console.log('paragraph UPDATED')
            this.updateInitialComponents( this.paragraphs)
          }

        

        /** this remove function let the propety hidden turn true therefore hide it
         * on the paragraph index list , but the old paragraphs will be still visible
         * on the form-items and form-list , unless we add the [hidden] property 
         * on thos html too
         */
          onRemoveParagraph(i){
             this.paragraphs[i].isRemoved = true ;
            
          }


  /**
   * 
   * @param newFeature  from featureContainer add feature to paragraphEdit
   */
  additionalFeature = new Subject<Feature>()
  onAddingFeatureToParagraphCreateEdit(newFeature: Feature ){
    this.additionalFeature.next(newFeature)
  }

   /**
    * --- start update paragraph status of share icon ----------------------------------
    */
   updateParagraphShareStatus = new Subject<void>()
   $updateParagraphShareStatus: Observable<void> = this.updateParagraphShareStatus.asObservable()

   onUpdateParagraphShareStatusOnParagraphFeatureContainer(){
    this.updateParagraphShareStatus.next()
   }

   /**
    * 
    * @param paragraphs this will not send a subject afterall , will just store 
    */
   addParagraphsToList_Only(paragraphs: Paragraph[]){
    for (let i=0; i<paragraphs.length; i++){
      // check first if the paragraph name already exist , if so NOT to add 
      if ( this.paragraphs.findIndex(x=> x._id === paragraphs[i]._id) !== -1 ){ 
           const index = this.paragraphs.findIndex(x=> x._id === paragraphs[i]._id)
           this.paragraphs[index] = paragraphs[i]
      }
      if ( this.paragraphs.findIndex(x=> x._id === paragraphs[i]._id) === -1 ){ 
           this.paragraphs.push(paragraphs[i])
      }
      
      if (i === paragraphs.length-1){
        this.updateInitialComponents( this.paragraphs)
        console.log('UPDATED PARAGRAPH LIST = ', this.paragraphs)
      }
    }
    // console.log('UPDATED PARAGRAPH LIST = ', this.paragraphs)
    // this.stopLoadingParagraphTopicIndex.next()
  }



/**
 * 
 * @param paragraphs coming from above subscription on  store Paragraph service
 */
addParagraphsToList(paragraphs: Paragraph[]){
  for (let i=0; i<paragraphs.length; i++){
    // check first if the paragraph name already exist , if so NOT to add 
    if ( this.paragraphs.findIndex(x=> x._id === paragraphs[i]._id) !== -1 ){ 
         const index = this.paragraphs.findIndex(x=> x._id === paragraphs[i]._id)
         this.paragraphs[index] = paragraphs[i]
    }
    if ( this.paragraphs.findIndex(x=> x._id === paragraphs[i]._id) === -1 ){ 
         this.paragraphs.push(paragraphs[i])
    }
    
    if (i === paragraphs.length-1){
      this.updateInitialComponents( this.paragraphs)
      console.log('UPDATED PARAGRAPH LIST = ', this.paragraphs)
      this.stopLoadingParagraphTopicIndex.next()
    }
  }
  // console.log('UPDATED PARAGRAPH LIST = ', this.paragraphs)
  // this.stopLoadingParagraphTopicIndex.next()
}

addParagraphsToListPlusSendPossibleFormSubject(paragraphsResults: Paragraph[]){

  for (let i=0; i<paragraphsResults.length; i++){
    // check first if the paragraph name already exist , if so NOT to add 
    if ( this.paragraphs.findIndex(x=> x._id === paragraphsResults[i]._id) !== -1 ){
         this.storeFormItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenFormItemPlusChildsAreStored(paragraphsResults[i]._id) 
    }
    
    if ( this.paragraphs.findIndex(x=> x._id === paragraphsResults[i]._id) === -1 ){ 
         this.paragraphs.push(paragraphsResults[i])
         this.storeFormItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenFormItemPlusChildsAreStored(paragraphsResults[i]._id) 
    }

    if ( i === paragraphsResults.length-1){
      this.updateInitialComponents( this.paragraphs)
    }
  }
  console.log('UPDATED PARAGRAPH LIST = ', this.paragraphs)

}


updateInitialComponents( updatedParagraphs: Paragraph[]){
  this.initialNullSetupService.initialNullParagraphs = updatedParagraphs
}


adviseFeatureContainerIsEditing( isEditing : Editing ){
  console.log(isEditing)
  this.isEditing = isEditing
  
}

      

}
