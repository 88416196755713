<!-- <p>multi-features-list works!</p> -->

<app-loading-spinner *ngIf="isLoadingList"></app-loading-spinner>

<mat-card *ngIf="!isLoadingList" class="mat-elevation-z0"> 

<mat-tab-group #tabs 
[(selectedIndex)]="selectedIndex"
class="remove-border-bottom"
header-less-tabs
(selectedTabChange)="onLinkClick($event)"    
headerPosition="below" 
animationDuration="0ms" >



      <mat-tab *ngFor="let multiFeature of multiFeatures; index as i ">
          
           <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

           <app-multi-features 
           *ngIf="!isLoading"
           [feature]="feature_from_paragraphEdit"
           [isTabItem]="false"
           [isParagraphEdit]="isParagraphEdit" 
           [noNeeded]="false" 
           [index_id]="multiFeature._id"
           (emitASubComponentIsHavingIssues)="onASubComponentIsHavingIssues($event)"
           [paragraphEdit_selectedIndex_id]="feature_from_paragraphEdit.feature_id">
           </app-multi-features>

           <br>

                <ng-template mat-tab-label>
                <a style="cursor: pointer">
                     {{i+1}} - {{multiFeature.name}} </a>
                </ng-template>
              

          
   
      </mat-tab>  
      
      <div *ngIf="createButtonLimit">
          <mat-tab disabled > 
               <ng-template  mat-tab-label>
                    <a (click)="onCreate()"
                       [matTooltip]="matToolTipMessageOnCreateNew"
                        matTooltipPosition="right"
                    > create new </a>
               </ng-template>
           </mat-tab>
      </div>

      <div *ngIf="multiFeatures.length > 1">
          <div *ngIf="createButtonLimit">
              <mat-tab disabled > 
                   <ng-template  mat-tab-label>
                        <a> create copy of </a>
                   </ng-template>
               </mat-tab>
          </div>
     </div>

      

</mat-tab-group>

</mat-card>
 

