
import { SingleWordInput } from "./single-word-input.model";
import { Injectable } from "@angular/core";
import { InitialNullSetupService } from "src/app/services/starting-services/initial-null-setup.service";
import { Subject, Subscription } from "rxjs";
import { StoreFormItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-form-item-plus-childs-confirmation.service";
import { StoreParagraphItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-paragraph-item-plus-childs-confirmation.service";
import { StoreMultibaseItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-multiBase-item-plus-childs-confirmation.service";
import { StoreTabItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-tab-item-plus-childs-confirmation.service";

@Injectable ({
    providedIn: 'root' 
})

export class SingleWordInputService {

    constructor (
                  private initialNullSetupService: InitialNullSetupService,
                  private storeFormItemPlusChildsConfirmationService: StoreFormItemPlusChildsConfirmationService , 
                  private storeParagraphItemPlusChildsConfirmationService: StoreParagraphItemPlusChildsConfirmationService , 
                  private storeMultibaseItemPlusChildsConfirmationService: StoreMultibaseItemPlusChildsConfirmationService,
                  private storeTabItemPlusChildsConfirmationService: StoreTabItemPlusChildsConfirmationService 
                  
                ){}


    singleWordInputs: SingleWordInput[] = this.initialNullSetupService.initialNullSingleWordInputs
    initialPersonalSingleWordInputsHaveBeenStored = new Subject<void>()


    

    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.singleWordInputsChangedBackToNull
                                                     .subscribe((res)=> {
                                                         this.singleWordInputs = res 
                                                         console.log('updated null singleWordInputs = ', res)

    })



    getSingleWordInput(id: number){
        return this.singleWordInputs[id]
    };

    getSingleWordInputBy_id(_id: string){
        const newSingleWordInput: SingleWordInput = this.singleWordInputs.find(
            (res)=> {
                return res._id === _id
            }
        )
        return newSingleWordInput
    }

    getSingleWordInputs(){
        return this.singleWordInputs;
    }

    addSingleWordInput(singleWordInput: SingleWordInput){
        this.singleWordInputs.push(singleWordInput);
        this.updateInitialComponent(this.singleWordInputs)
    }


    addNewSingleWordInputs(singleWordInputs: SingleWordInput[]){
        for ( let i=0; i<this.singleWordInputs.length; i++ ){
            if ( this.singleWordInputs.findIndex(x=> x._id === singleWordInputs[i]._id ) === -1 ){
                this.singleWordInputs.push(singleWordInputs[i])
            }
            if (i=== this.singleWordInputs.length-1){
                this.initialPersonalSingleWordInputsHaveBeenStored.next() // sending to singleWordInputList
            }
        }
        //  this.initialPersonalSingleWordInputsHaveBeenStored.next() // sending to singleWordInputList
       
    }


    addNewSingleWordInput(singleWordInputToAdd: SingleWordInput , isComingFromFormItemCall: boolean , isComingFromMultiFeatures: boolean){

        if ( this.singleWordInputs.findIndex(x=> x._id === singleWordInputToAdd._id) !== -1 ){ 
             const index = this.singleWordInputs.findIndex(x=> x._id === singleWordInputToAdd._id)
             this.singleWordInputs[index] = singleWordInputToAdd
             this.updateInitialComponent(this.singleWordInputs)
        }

        if ( this.singleWordInputs.findIndex(x=> x._id === singleWordInputToAdd._id) === -1 ){ 
            this.singleWordInputs.push(singleWordInputToAdd)
            this.updateInitialComponent(this.singleWordInputs)
        }

        if ( isComingFromFormItemCall === true  ){ this.storeFormItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenFormItemPlusChildsAreStored( singleWordInputToAdd._id) }
        if ( isComingFromFormItemCall === false ){ this.storeParagraphItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenParagraphItemPlusChildsAreStored( singleWordInputToAdd._id) }
        if ( isComingFromMultiFeatures === true ){ this.storeMultibaseItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenMultiFeatureItemPlusChildsAreStored(singleWordInputToAdd._id)}
        if ( isComingFromMultiFeatures=== false ){ this.storeTabItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenTabItemPlusChildsAreStored( singleWordInputToAdd._id) }
        console.log('SHARED SINGLEWORDINPUT has been added = ',this.singleWordInputs)

    }

    updateInitialComponent(updatedSingleWordInputs: SingleWordInput[]){
        this.initialNullSetupService.initialNullSingleWordInputs = updatedSingleWordInputs

    }

    ngOnDestroy(){
        this.updateInitialNullDatafromInitialNullSetupService.unsubscribe()
    }




}
