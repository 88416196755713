import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "src/app/app-routing.module";
import { MaterialDesignModule } from "src/app/material-design/material-design.module";
import { SharedModule } from "src/app/shared/shared.module";
import { ChartsComponent } from "./charts.component";

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartListComponent } from './chart-list/chart-list.component';
import { ChartItemComponent } from './chart-list/chart-item/chart-item.component';
import { ChartEditComponent } from './chart-list/chart-edit/chart-edit.component';
import { SliderModule } from "../slider/slider.module";

@NgModule({
    declarations:[
        ChartsComponent,
        ChartListComponent,
        ChartItemComponent,
        ChartEditComponent,
    ],
    exports: [
        ChartsComponent,
        ChartItemComponent ,
        ChartListComponent,
        ChartEditComponent,
    ],
    imports: [
        AppRoutingModule,
        CommonModule,
        FormsModule,
        BrowserAnimationsModule,
        MaterialDesignModule, 
        ReactiveFormsModule,
        MatDialogModule,
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatExpansionModule,
        SharedModule,
        NgxChartsModule,
        SliderModule
    ],

    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class OurChartsModule{}