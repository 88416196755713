import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { RangeInfo } from '../range-info.model';
import { Subscription } from 'rxjs';

interface IndexRangeInfo{
  index: number ,
  rangeInfo: RangeInfo
}

@Component({
  selector: 'app-inner-component-edit-bottom-sheet',
  templateUrl: './inner-component-edit-bottom-sheet.component.html',
  styleUrls: ['./inner-component-edit-bottom-sheet.component.css']
})
export class InnerComponentEditBottomSheetComponent implements OnInit, OnDestroy {

  user: string
  rangeInfo: RangeInfo
  index: number

  updateOnUserEmail: Subscription

  constructor(private _bottomSheetRef: MatBottomSheetRef<InnerComponentEditBottomSheetComponent>,
    private _bottomSheet: MatBottomSheet ,
    private userObjectUpdateService: UserObjectUpdateService ,
    private postComponentsHttpRequestService: PostComponentsHttpRequestService ,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }

  ngOnInit(): void {

    this.user = this.userObjectUpdateService.userEmail
 
    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
    .subscribe((updatedUserEmail)=>{
     this.user = updatedUserEmail
     console.log('updated USER = ', this.user)
    })

    this.rangeInfo = this.data.rangeInfo
    this.index = this.data.index 
    console.log(this.rangeInfo, this.index )

  }

  onEmitUpdatedIndexRangeInfos($event){

       console.log($event)
       const newIndex = $event.index
       const newRangeInfo = $event.rangeInfo
       const newIndexRangeInfo: IndexRangeInfo = { index: newIndex , rangeInfo: newRangeInfo }
       this._bottomSheetRef.dismiss(newIndexRangeInfo)
    
  }

  onDeleteIndexRangeInfo($event){
    console.log('deleting index => ',$event)
      const newIndex = $event
      const newRangeInfo = null
      const newIndexRangeInfo: IndexRangeInfo = { index: newIndex , rangeInfo: newRangeInfo }
      this._bottomSheetRef.dismiss(newIndexRangeInfo)
  }

  ngOnDestroy(): void {
      this.updateOnUserEmail?.unsubscribe()
  }

}
