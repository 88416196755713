import { Component, OnInit , Input , Output, EventEmitter, OnDestroy} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FindValueSubscriber } from 'rxjs/internal/operators/find';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { Contact } from '../../contact.model';
import { ContactDetails } from '../../contact-details.model';

@Component({
  selector: 'app-contact-item',
  templateUrl: './contact-item.component.html',
  styleUrls: ['./contact-item.component.css']
})
export class ContactItemComponent implements OnInit , OnDestroy {

  user: string 

  @Input() weAreOnShareParagraphFormIndex: boolean

  @Output() addContactToList  = new EventEmitter<ContactDetails>()
  @Output() removeContactFromList = new EventEmitter<ContactDetails>()

  @Input() contactDetail: ContactDetails; 
  @Input() isContactEditMode: boolean
  @Input() listOfCurrentSelectedContactEmail: string[]

  @Input() deselectAllContacts: Observable<void>
  subscribeToDeselectAllContacts: Subscription 

  isSelected: boolean = false
  contacts: Contact[]


  constructor(  private userObjectUpdateService: UserObjectUpdateService ) { }

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    console.log(this.listOfCurrentSelectedContactEmail)

    if (this.weAreOnShareParagraphFormIndex === true ){
    for( let i=0 ; i<this.listOfCurrentSelectedContactEmail.length ; i++){
         if ( this.contactDetail.contactEmail === this.listOfCurrentSelectedContactEmail[i] ){ this.isSelected = true }  
    }
    }

    console.log(this.contactDetail)

    this.subscribeToDeselectAllContacts = this.deselectAllContacts
        .subscribe(()=>{
          this.onDeselectContact()
    })

  }


  onSelectedContact(){
    this.isSelected = true
    this.addContactToList.emit(this.contactDetail)
  }


  onDeselectContact(){
    this.isSelected = false
    this.removeContactFromList.emit(this.contactDetail)
  }

  ngOnDestroy(): void {
      this.subscribeToDeselectAllContacts.unsubscribe()
  }



}
