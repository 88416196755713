import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { RangeInfo } from '../../calendar/shared-calendar-status-list/range-info.model';
import { startWith, map } from 'rxjs/operators';
import { ContactService } from 'src/app/contacts/contact.service';
import { ContactDetails } from 'src/app/contacts/contact-details.model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

interface InputLabelInterface{

  exist: boolean ,
  name: string,
  maxLength: number,
  errorHandler: boolean 
}

@Component({
  selector: 'app-name-input',
  templateUrl: './name-input.component.html',
  styleUrls: ['./name-input.component.css']
})
export class NameInputComponent implements OnInit, OnDestroy {

  editFormName: FormGroup

  @Input() inputLabelInterface: InputLabelInterface
  @Input() inputName: string 

  changingValuesSubscription: Subscription 

  @Output() updatedNameEvent = new EventEmitter<string>()
  @Output() updateNameInputValidity = new EventEmitter<boolean>()

  filteredOptions: Observable<string[]>;
  contactOptions: string[]

  @Input() isEmptyingInputNameAfterAdded: Observable<void>
  emptyInputNameAfterAddedSubscription: Subscription

  constructor( private contactService: ContactService ) {}

  ngOnInit(): void {

    this.editFormName = new FormGroup({
      name : new FormControl ( this.inputName , [ Validators.required, Validators.maxLength(this.inputLabelInterface.maxLength) ] )
    })
    
    this.filteredOptions = this.editFormName.get('name').valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterOptions(value))
      );
    

    this.changingValuesSubscription = this.editFormName.valueChanges.subscribe(
      (value) => {
        console.log(value.name)
        this.onUpdateNameEvent(value.name)
      }
      );

    console.log(this.inputName)

    

    this.contactOptions = []
    for (let i=0; i<this.contactService.getContacts()[0].contactDetails.length ; i++){
         this.contactOptions.push(this.contactService.getContacts()[0].contactDetails[i].contactEmail)
    }
    this.contactOptions.sort(this.compareEmails)

  

    this.emptyInputNameAfterAddedSubscription = this.isEmptyingInputNameAfterAdded
        .subscribe(()=> {
          this.onEmptyEditFormName()
        })
  }



    // Sorting function
 compareEmails(a, b) {
  // Convert emails to lowercase for case-insensitive sorting
  const emailA = a.toLowerCase();
  const emailB = b.toLowerCase();

  if (emailA < emailB) {
    return -1;
  }
  if (emailA > emailB) {
    return 1;
  }
  return 0;
}



private validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}



  filterOptions(value: string): string[] {
    const filterValue = value.toLowerCase();
    this.contactOptions.filter(option => option.toLowerCase().includes(filterValue));
    return this.contactOptions.sort(this.compareEmails)
  }

  onSubmitName(){
    console.log(this.editFormName.value.name )
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    // event.option.value contains the selected value
    const selectedValue = event.option.value;
    console.log('Selected Value:', selectedValue);
    this.onUpdateNameEvent(selectedValue)
    // You can do further processing with the selected value here
  }

  onUpdateNameEvent(updatedname: string){
    console.log('on updateNameEvent => ', updatedname)
    this.updatedNameEvent.emit(updatedname)

    if ( this.editFormName.valid && this.validateEmail(updatedname) ) { this.updateNameInputValidity.emit(true) }
    if ( !this.editFormName.valid || !this.validateEmail(updatedname) ){ this.updateNameInputValidity.emit(false) }
  }

  onEmptyEditFormName(){


     this.ngOnInit()
     this.updateNameInputValidity.emit(false)
  }

  ngOnDestroy(): void {
    this.changingValuesSubscription.unsubscribe()
    this.emptyInputNameAfterAddedSubscription.unsubscribe()
  }



}
