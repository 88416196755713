import { Component, OnInit } from '@angular/core';

interface Slider {
  id: number 
  value: number;
}

interface ChartData {
  name: string;
  value: number;
}

@Component({
  selector: 'app-chart-edit',
  templateUrl: './chart-edit.component.html',
  styleUrls: ['./chart-edit.component.css']
})
export class ChartEditComponent implements OnInit {

  sliders: Slider[] = [{ id: 0 , value: 0 }, { id: 1,  value: 100 }];
  chartData: ChartData[] = [
    { name: 'Slider 1', value: 0 },
    { name: 'Slider 2', value: 100 },
  ];

  chartDataCopy: ChartData[]
  // colorScheme = { domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'] };
  colorScheme = {
    domain: ['#1E90FF', '#FF8C00','#008080', '#A10A28','#5AA454', '#C7B42C', '#AAAAAA', '#9932CC', '#FF69B4', '#4169E1']
  };
  gradient = true;
  showLegend = true;
  totalValue = 100;

  sliderCount: number = this.sliders.length;
  //tooltipTemplate = false

  loaded: boolean 

  constructor() { }

  ngOnInit(): void {
    this.loaded = true
    const newChartDataCopy: ChartData[] = this.chartData
    this.chartDataCopy = newChartDataCopy
    this.updateSlidersAndChart()
  }

     /**
   * this works for 2 sliders only
   */
  updateSlidersAndChart(): void {
    this.loaded = false 
    let total = 0;
    const adjustValue = (100 - this.totalValue) / (this.sliderCount - 1);
  
    // Adjust the value of each slider except for the one that was just changed
    this.sliders.forEach((slider, i) => {
      const sliderElement = document.getElementById(`slider-${i}`) as HTMLInputElement;
      if (sliderElement) {
        const currentValue = Number(sliderElement.value);
        let newValue = currentValue;
        if (currentValue === 0 && adjustValue < 0) {
          // if a slider is at 0 and the total value needs to decrease,
          // set the slider to 1 to ensure it has some value
          newValue = 1;
        } else {
          // otherwise, adjust the value of the slider by the appropriate amount
          newValue = currentValue + adjustValue;
        }
        // set the new value of the slider
        newValue = Math.max(0, Math.min(100, newValue));
        sliderElement.value = newValue.toString();
        slider.value = newValue;
        // update the chart data with the new value
        this.chartData[i].value = newValue;
        // update the total value
        total += newValue;
      }
    });
  
    // adjust the last slider to make sure total value is always 100
    const lastSliderIndex = this.sliders.length - 1;
    const lastSliderElement = document.getElementById(`slider-${lastSliderIndex}`) as HTMLInputElement;
    if (lastSliderElement) {
      const currentValue = Number(lastSliderElement.value);
      let newValue = currentValue + (100 - total);
      newValue = Math.max(0, Math.min(100, newValue));
      lastSliderElement.value = newValue.toString();
      this.sliders[lastSliderIndex].value = newValue;
      this.chartData[lastSliderIndex].value = newValue;
      total += newValue - currentValue;
    }
  
    // update the total value
    this.totalValue = total;
  
    // update the chart
    this.updateChartData();
  }
 


  updateChartData(): void {
    const total = this.sliders.reduce((sum, slider) => sum + slider.value, 0);
    this.chartData = this.sliders.map((slider, i) => ({
      name: `Slider ${i + 1}`,
      value: total === 0 ? 0 : slider.value / total * 100,
    })
  );
  console.log(this.chartData, total)

  // const newChartDataCopy: ChartData[] = this.chartData
  // this.chartDataCopy = newChartDataCopy

  setTimeout(()=>{
    this.loaded = true
  },100)
  const newChartDataCopy: ChartData[] = this.chartData
  this.chartDataCopy = newChartDataCopy
 
  }
  
  addSlider(): void {
    if (this.sliders.length < 10) {
      const newSliderId = this.sliders.length + 1;
      this.sliders.push({ id: newSliderId, value: 0 });
      this.sliderCount = this.sliders.length;
      this.updateChartData();
    }
  }
  
  
  removeSlider(): void {
    if (this.sliders.length > 2) {
      this.sliders.pop();
      this.sliderCount = this.sliders.length;
      this.updateChartData();
    }
  }

 
  
  

}

   /**
   * this works for 2 sliders only
   */
  // updateSlidersAndChart(): void {
  //   let total = 0;
  //   const adjustValue = (100 - this.totalValue) / (this.sliderCount - 1);
  
  //   // Adjust the value of each slider except for the one that was just changed
  //   this.sliders.forEach((slider, i) => {
  //     const sliderElement = document.getElementById(`slider-${i}`) as HTMLInputElement;
  //     if (sliderElement) {
  //       const currentValue = Number(sliderElement.value);
  //       let newValue = currentValue;
  //       if (currentValue === 0 && adjustValue < 0) {
  //         // if a slider is at 0 and the total value needs to decrease,
  //         // set the slider to 1 to ensure it has some value
  //         newValue = 1;
  //       } else {
  //         // otherwise, adjust the value of the slider by the appropriate amount
  //         newValue = currentValue + adjustValue;
  //       }
  //       // set the new value of the slider
  //       newValue = Math.max(0, Math.min(100, newValue));
  //       sliderElement.value = newValue.toString();
  //       slider.value = newValue;
  //       // update the chart data with the new value
  //       this.chartData[i].value = newValue;
  //       // update the total value
  //       total += newValue;
  //     }
  //   });
  
  //   // adjust the last slider to make sure total value is always 100
  //   const lastSliderIndex = this.sliders.length - 1;
  //   const lastSliderElement = document.getElementById(`slider-${lastSliderIndex}`) as HTMLInputElement;
  //   if (lastSliderElement) {
  //     const currentValue = Number(lastSliderElement.value);
  //     let newValue = currentValue + (100 - total);
  //     newValue = Math.max(0, Math.min(100, newValue));
  //     lastSliderElement.value = newValue.toString();
  //     this.sliders[lastSliderIndex].value = newValue;
  //     this.chartData[lastSliderIndex].value = newValue;
  //     total += newValue - currentValue;
  //   }
  
  //   // update the total value
  //   this.totalValue = total;
  
  //   // update the chart
  //   this.updateChartData();
  // }