import { Component, OnInit , Input} from '@angular/core';
import { DropDownSelectDetailResult } from './drop-down-select-detail-result.model';

@Component({
  selector: 'app-drop-down-select-result',
  templateUrl: './drop-down-select-result.component.html',
  styleUrls: ['./drop-down-select-result.component.css']
})
export class DropDownSelectResultComponent implements OnInit {

 @Input() dropDownSelectDetailResults: DropDownSelectDetailResult[]

  constructor() { }

  ngOnInit(){

    console.log(this.dropDownSelectDetailResults)
  }

}
