import { ChangeDetectorRef, Component, OnInit , OnDestroy, ViewChild } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Form } from '../form.model';
import { MatSidenav } from '@angular/material/sidenav';
import { FormService } from '../form.service' ;
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable , Subscription, Subject, Observer, fromEvent, merge  } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Header } from 'src/app/header/header.model';
import { HeaderService } from 'src/app/header/header.service';
import { RecipientIndex } from './form-edit/recipient-index-list/recipient-index.model';
import { DateTime } from 'luxon';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { FormIndexTypeInterface } from './form-index-type-interface.model';
import { FormFeatureContainer } from './form-index-topic-list/form-feature-container.model';
import { FormFeatureContainerService } from './form-index-topic-list/form-feature-container.service';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { RecipientIndexListItem } from './form-edit/recipient-index-list/recipient-index-list-item.model';
import { Title } from '@angular/platform-browser';



@Component({
  selector: 'app-form-list',
  templateUrl: './form-list.component.html',
  styleUrls: ['./form-list.component.css'],
})
export class FormListComponent implements OnInit, OnDestroy {

  user: string 

   /**
   *  package properties for online / offline detection and mode
   */
    onlineOffline: boolean = navigator.onLine
    // ------------------------------------------------------------
  
  @ViewChild('tabs') tabGroup: MatTabGroup
  @ViewChild('drawer') sidenav: MatSidenav;
  index :number ;
  selectedIndex : number ;
  form: Form;

  recipientIndex: RecipientIndex
  recipientIndexListItem: RecipientIndexListItem
  subscribeUpdatedRecipientIndex: Subject<RecipientIndex> = new Subject<RecipientIndex>();

  changingFormId: Subscription ;

  typeOfFormIndexTopic: FormIndexTypeInterface
  isChangingFormIndexType: boolean  


  weAreInMobileMode: boolean
  isOpenIndexDesktop: boolean;
  isOpenIndexMobile: boolean;

  trialForms: Form[]
  isLoading = false 
  error = false

  youAreInFormEdit: boolean = false

  /**
   * subscription to online/offline
   */
   subscriptionToOnlineOffline : Subscription

   form_id: string 
   aFormIsSelected: boolean 

   /**
    * getting form_id from form item and note that a form is selected
    */
   subscribeToSelectedForm_id: Subscription 

  //  isLoadingForm: boolean = true 
  

   /**
    * getting to know if the selected form has a recipientIndex 
    * if it doesnt , check if one has been dropped and can be saved 
    */
  //  checkIfRecipientIndex_exist_subscription: Subscription 
   recipientIndex_exist_onCurrentSelectedFormItem: boolean 

   isThisFormFromCurrentUser: boolean 


   loadingFormIconOptions: boolean = false 

  //  updateFormFeatureContainerIndex_id: Subscription 
   formFeatureContainer: FormFeatureContainer

   updateRecipientIndex_id_formItem_subscription: Subscription 
   recipientIndexCurrentlyInUse: string 
   recipientIndexInUseAlreadyExist: boolean = false 

   formIconIsVisible: boolean = false 

  //  checkFormInUseOwner_subscription: Subscription 
   formOwnerUsedInThisFormItem: string 

   updateOnUserEmail: Subscription 

   selectedForm_formFeatContainer_recipIndex_id: Subscription 
   openingSidenav_fromFormStart_mobile_Subscription: Subscription



  constructor( private formService: FormService,
               private breakpointObserver: BreakpointObserver,
               private headerService: HeaderService,
               private readonly changeDetectorRef: ChangeDetectorRef ,
               private userObjectUpdateService: UserObjectUpdateService ,
               private formFeatureContainerService: FormFeatureContainerService ,
               private postComponentsHttpRequestService: PostComponentsHttpRequestService,
               private route: ActivatedRoute, 
               private router: Router , 
               private titleService: Title
               ){ }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );



  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
    })

    this.titleService.setTitle("my saas list")

    /**
 * package online / offline property -------------------------------------------------
 */
  console.log('this device is online : ',this.onlineOffline)
  this.subscriptionToOnlineOffline = this.createOnline$().subscribe(isOnline => console.log( this.onlineOffline = isOnline,
    'this device is now online: ', this.onlineOffline, DateTime.now().toString() ));
// ------------------------------------------------------------------------------------
 

    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    {  this.weAreInMobileMode = true ,  console.log('isMobile')} 
    else { this.weAreInMobileMode = false,  console.log( 'isDesktop' )} 


  this.isChangingFormIndexType = false
  this.typeOfFormIndexTopic = new FormIndexTypeInterface (true, false, false, false )


  this.isOpenIndexDesktop = true; 
  this.isOpenIndexMobile = false; 

  console.log('form list =', this.formService.getForms())

  /**
   * setting header to right button start
   */
  const newHeaderIndexSelection = new Header (false, false, false, true, false, false, false, false )
  this.headerService.nextIndexSelection(newHeaderIndexSelection);

  this.aFormIsSelected = false
  console.log('a formhas been selected: ', this.aFormIsSelected)

  this.selectedForm_formFeatContainer_recipIndex_id = this.formService.$selectedForm_existingFormFeatContainer_selectedRecipientIndex_id
      .subscribe((selectedForm_existingFormFeatContainer_selectedRecipientIndex_id: any )=> {

        console.log(selectedForm_existingFormFeatContainer_selectedRecipientIndex_id)
        this.setFormIconDisplay(selectedForm_existingFormFeatContainer_selectedRecipientIndex_id)
      })
  
  this.openingSidenav_fromFormStart_mobile_Subscription = this.formService.openFormListSideNav_FromStartComponent
      .subscribe(()=>{
        this.onToggleIndexMenuMobile_fromBottomButton()
      })
 
  };

   /**
 * package online / offline property -------------------------------------------------
 */

    createOnline$() {
      return merge<boolean>(
        fromEvent(window, 'offline').pipe(map(() => false)),
        fromEvent(window, 'online').pipe(map(() => true)),
        new Observable((sub: Observer<boolean>) => {
          sub.next(navigator.onLine);
          sub.complete();
        }));
    };
    
    // -----------------------------------------------------------------------------------
    ngAfterViewChecked(): void {
      this.changeDetectorRef.detectChanges();
    };



  onUpdateRecipientIndex($event){

    console.log($event)
    this.recipientIndex = $event.newRecipientIndex;
    this.recipientIndexListItem = $event.newRecipientIndexListItem;

    console.log(this.recipientIndex, this.recipientIndexListItem)

    /**
     * sidenav close on choosen recipient // also the form type has to be close 
     */
     if (this.weAreInMobileMode === true ){ this.sidenav.close()
      this.isOpenIndexMobile = false; 
      this.isOpenIndexDesktop = false ; }



  }

  onCloseSideNavOnFormList(){
     /**
     * sidenav close on choosen recipient // also the form type has to be close 
     */
    if (this.weAreInMobileMode === true ){ this.sidenav.close()
                                           this.isOpenIndexMobile = false; 
                                           this.isOpenIndexDesktop = false ; }
     
     
  }

  onCloseOpenFormItem(){
    this.isLoading = true 
    console.log('is LOADING')
    setTimeout(()=> {
      this.isLoading = false
    }, 500)
    console.log('timer gone')
  }

  setFormIconDisplay(selectedForm_existingFormFeatContainer_selectedRecipientIndex_id: any ){

    console.log(selectedForm_existingFormFeatContainer_selectedRecipientIndex_id )
    this.formIconIsVisible = true 
    this.loadingFormIconOptions = true 
    
    const newForm: Form = selectedForm_existingFormFeatContainer_selectedRecipientIndex_id.newForm 
    let selectedFormFeatureContainer: FormFeatureContainer = selectedForm_existingFormFeatContainer_selectedRecipientIndex_id.selectedFormFeatureContainer 
    let selectedRecipientIndex_id: string = selectedForm_existingFormFeatContainer_selectedRecipientIndex_id.selectedRecipientIndex_id

    console.log( newForm , selectedFormFeatureContainer , selectedRecipientIndex_id )
    this.form_id = newForm._id

    if ( newForm.owner === this.user ){ 
         this.isThisFormFromCurrentUser = true 
         this.aFormIsSelected = true 
    
        }
    if ( newForm.owner !== this.user ){ 
         this.isThisFormFromCurrentUser = false 
         this.aFormIsSelected = false 
    }

    if ( selectedRecipientIndex_id === undefined ){ this.recipientIndex_exist_onCurrentSelectedFormItem = false }
    if ( selectedRecipientIndex_id !== undefined ){ 

        /**
         * here actually need to check if it's true or not after compare the 
         */
         this.recipientIndex_exist_onCurrentSelectedFormItem = true  }

    if ( selectedFormFeatureContainer === undefined ){ 
         this.recipientIndexInUseAlreadyExist = false  
         this.recipientIndex_exist_onCurrentSelectedFormItem = false 
    }
    if ( selectedFormFeatureContainer !== undefined ){
          this.formFeatureContainer = selectedFormFeatureContainer 
          this.recipientIndexCurrentlyInUse = selectedRecipientIndex_id
         if ( selectedFormFeatureContainer.recipientIndex_id === selectedRecipientIndex_id ){ this.recipientIndexInUseAlreadyExist = true }
         if ( selectedFormFeatureContainer.recipientIndex_id !== selectedRecipientIndex_id ){ this.recipientIndexInUseAlreadyExist = false 
    }

    }

    setTimeout(()=>{
      this.loadingFormIconOptions = false
    },200)



  }





  onPersonalParagraphsDesktop(){
  this.isChangingFormIndexType = true
  this.typeOfFormIndexTopic = new FormIndexTypeInterface (true, false, false, false )
  setTimeout(()=>{
    this.isChangingFormIndexType = false
  }, 100)
  
 

  this.isOpenIndexMobile = false; 
  }


  onPublicParagraphsDesktop(){
    this.isChangingFormIndexType = true
    this.typeOfFormIndexTopic = new FormIndexTypeInterface (false, true, false, false )
    setTimeout(()=>{
      this.isChangingFormIndexType = false
    }, 100)
  
    this.isOpenIndexMobile = false; 
  }


  onSharedParagraphsDesktop(){
    this.isChangingFormIndexType = true
    this.typeOfFormIndexTopic = new FormIndexTypeInterface (false, false, true, false )
    setTimeout(()=>{
      this.isChangingFormIndexType = false
    }, 100)

    this.isOpenIndexMobile = false; 
  }

  onPreferredParagraphsDesktop(){
    this.isChangingFormIndexType = true
    this.typeOfFormIndexTopic = new FormIndexTypeInterface (false , false, false, true )
    setTimeout(()=>{
      this.isChangingFormIndexType = false
    }, 100)

    this.isOpenIndexDesktop = true; 
  }

  onPersonalParagraphsMobile(){
    this.isChangingFormIndexType = true
    this.typeOfFormIndexTopic = new FormIndexTypeInterface (true, false, false, false )
    setTimeout(()=>{
      this.isChangingFormIndexType = false
    }, 100)

    this.isOpenIndexDesktop = true; 
    }
  
  
    onPublicParagraphsMobile(){
      this.isChangingFormIndexType = true 
      this.typeOfFormIndexTopic = new FormIndexTypeInterface ( false,true, false, false )
      setTimeout(()=>{
        this.isChangingFormIndexType = false
      }, 100)

      this.isOpenIndexDesktop = true; 
    }
  
  
    onSharedParagraphsMobile(){
      this.isChangingFormIndexType = true
      this.typeOfFormIndexTopic = new FormIndexTypeInterface (false, false, true, false )
      setTimeout(()=>{
        this.isChangingFormIndexType = false
      }, 100)

      this.isOpenIndexDesktop = true; 
    }

    onPreferredParagraphsMobile(){
      this.isChangingFormIndexType = true 
      this.typeOfFormIndexTopic = new FormIndexTypeInterface ( false, false, false, true )
      setTimeout(()=>{
        this.isChangingFormIndexType = false
      }, 100)

      this.isOpenIndexDesktop = true; 
    }
  
  
   

  // getColorToShared(){
  //   return this.isShared === true ? 'red': 'white';
  // }

  onToggleIndexMenuDesktop(){
    this.weAreInMobileMode = false
    this.isOpenIndexDesktop = !this.isOpenIndexDesktop; 
    this.isOpenIndexMobile = false; 
  }

  onToggleIndexMenuMobile(){
    this.weAreInMobileMode = true
    this.isOpenIndexMobile = !this.isOpenIndexMobile;
    this.isOpenIndexDesktop = true ;
  };

  onToggleIndexMenuMobile_fromBottomButton(){
    this.weAreInMobileMode = true
    this.isOpenIndexMobile = !this.isOpenIndexMobile;
    this.isOpenIndexDesktop = true ;
    this.sidenav.toggle()
  };

 

  onEditForm(){
    if (this.aFormIsSelected === true) {
        this.formService.onUpdateLatestShownForm(this.formService.getFormBy_id(this.form_id )), 
        console.log(this.form_id)
        this.router.navigate(['../edit-form', this.form_id ], {relativeTo: this.route })}  
    };

    // this.router.navigate(['../edit-paragraph', this.paragraphFeatures[i].paragraph_id ], {relativeTo: this.route.parent})


  
  updateRecipientIndexExistanceStatus(recipientIndex_exist: boolean ){

            this.loadingFormIconOptions = true
            this.recipientIndex_exist_onCurrentSelectedFormItem = recipientIndex_exist
          
            setTimeout(()=>{
              this.loadingFormIconOptions = false 
            }, 300)
            console.log( 'RecipientIndex now exist in this form = ', this.recipientIndex_exist_onCurrentSelectedFormItem)
  }



  onSaveRecipientInThisFormFeatureContainer(){
    /**
     * export class FormFeatureContainer {

    constructor( public _id: string , 
                 public owner: string , 
                 public allUsersAllowed: boolean ,
                 public userListAllowed: string[] , 
                 public dateAndTime: string , 
                 public isActive: boolean, 
                 public isAccepted: boolean, 
                 public name: string, 
                 public formFeatureState: boolean , // visible to check at arrival : this property change all the times according if the formFeature is present or not 
               
                 public recipientIndex_id: string ,
                 //this can be stored to directly save a recipient into a shared form or a preferred form 
                 // without having to click the right recipient everytime .. this especially goes well on reposting
                 // or to store / update data on your own / if the recipient always the same 
                 public formFeature_id : string
                  ){}
}
     */


const new_id = this.formFeatureContainer._id
const newComponentName = this.formFeatureContainer.componentName
const newUIID = this.formFeatureContainer.UIID
const newOwner = this.formFeatureContainer.owner
const newAllUsersAllowed = this.formFeatureContainer.allUsersAllowed
const newUserListAllowed = this.formFeatureContainer.userListAllowed
const newDateAndTime = this.formFeatureContainer.dateAndTime
const newIsActive = this.formFeatureContainer.isActive
const newIsAccepted = this.formFeatureContainer.isAccepted
const newName = this.formFeatureContainer.name
const newFormFeatureState = this.formFeatureContainer.formFeatureState
const newRecipientIndex_id = this.recipientIndexCurrentlyInUse
const newForm_id = this.formFeatureContainer.form_id

const newFormFeatureContainer = new FormFeatureContainer ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed,
                                                           newDateAndTime, newIsActive, newIsAccepted, newName,
                                                           newFormFeatureState, newRecipientIndex_id, newForm_id )
    
if (this.user !== null){
  this.postComponentsHttpRequestService.httpUpdateComponent(this.user, 'formFeatureContainer', newFormFeatureContainer)
  .subscribe( 
    (res)=>  { 
      console.log(res)
    }, error => {
      console.log( error )
      /**
       * manage error by telling user that the recipient is not been properly saved in this form 
       */
    }
  )
}

this.formFeatureContainerService.formFeatureContainers[this.formFeatureContainerService.getFormFeatureContainers().findIndex(x=> x._id === this.formFeatureContainer._id)] = newFormFeatureContainer
console.log(this.formFeatureContainerService.getFormFeatureContainerBy_id(this.formFeatureContainer._id))
this.formFeatureContainer = newFormFeatureContainer

if (newFormFeatureContainer.recipientIndex_id === this.recipientIndexCurrentlyInUse){this.recipientIndexInUseAlreadyExist = true}
if (newFormFeatureContainer.recipientIndex_id !== this.recipientIndexCurrentlyInUse){this.recipientIndexInUseAlreadyExist = false}
console.log('recipientIndexInUseAlreadyExist = ',this.recipientIndexInUseAlreadyExist)
}




  ngOnDestroy(){
    this.subscriptionToOnlineOffline.unsubscribe()
    // this.subscribeToSelectedForm_id.unsubscribe()
    this.updateOnUserEmail.unsubscribe()
    this.selectedForm_formFeatContainer_recipIndex_id.unsubscribe()
    // this.updateRecipientIndex_id_formItem_subscription.unsubscribe()
    this.openingSidenav_fromFormStart_mobile_Subscription.unsubscribe()
  
   
  }

}
