


<app-inner-checkbox-edit *ngIf="rangeInfo.type === 'innercheckbox'"
            [rangeInfo]="rangeInfo"
            [index]="index"
            (emittingIndexRangeInfo)="onEmitUpdatedIndexRangeInfos($event)"
            (emitIndexOfDeletedRangeInfo)="onDeleteIndexRangeInfo($event)">
</app-inner-checkbox-edit>

<app-inner-dropdown-edit *ngIf="rangeInfo.type === 'innerdropdown'"
             [rangeInfo]="rangeInfo"
             [index]="index"
             (emittingIndexRangeInfo)="onEmitUpdatedIndexRangeInfos($event)"
             (emitIndexOfDeletedRangeInfo)="onDeleteIndexRangeInfo($event)">
</app-inner-dropdown-edit>

<app-inner-input-edit *ngIf="rangeInfo.type === 'innerinput'"
             [rangeInfo]="rangeInfo"
             [index]="index"
             (emittingIndexRangeInfo)="onEmitUpdatedIndexRangeInfos($event)"
             (emitIndexOfDeletedRangeInfo)="onDeleteIndexRangeInfo($event)">
</app-inner-input-edit>

<app-inner-text-edit *ngIf="rangeInfo.type === 'innertext'"
            [rangeInfo]="rangeInfo"
            [index]="index"
            (emittingIndexRangeInfo)="onEmitUpdatedIndexRangeInfos($event)"
            (emitIndexOfDeletedRangeInfo)="onDeleteIndexRangeInfo($event)">
</app-inner-text-edit>

<!-- --------------------------------------------------------------------- -->

<app-inner-slide-toggle-edit *ngIf="rangeInfo.type === 'innerslidetoggle'"
            [rangeInfo]="rangeInfo"
            [index]="index"
            (emittingIndexRangeInfo)="onEmitUpdatedIndexRangeInfos($event)">
</app-inner-slide-toggle-edit>

<app-inner-button-edit *ngIf="rangeInfo.type === 'innerbutton'"
            [rangeInfo]="rangeInfo"
            [index]="index"
            (emittingIndexRangeInfo)="onEmitUpdatedIndexRangeInfos($event)">
</app-inner-button-edit>

<app-inner-link-edit *ngIf="rangeInfo.type === 'innerlink'"
            [rangeInfo]="rangeInfo"
            [index]="index"
            (emittingIndexRangeInfo)="onEmitUpdatedIndexRangeInfos($event)">
</app-inner-link-edit>



