<!-- <p>single-toggle-word-button-result works!</p> -->


   <mat-form-field appearance="none">
       <input matInput disabled value="{{singleToggleWordButtonDetailResult?.content}}">
   </mat-form-field>

                         
                      
  
