import { Component, Input, OnInit, Output, ViewChild, EventEmitter, OnDestroy, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { MatTabGroup } from '@angular/material/tabs';
import { ParagraphService } from '../../../../../../paragraph.service';
import { Paragraph} from '../../../../../../paragraph.model';
import { Feature} from '../../../../../paragraph-item/features/feature.model';
import { DragDropService } from '../../../../../../../../../dragdrop.service';
import { ParagraphTopic } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.model';
import { ParagraphFeature } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature.model';
import { HeaderService } from 'src/app/header/header.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable , Subject, Subscription} from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MultiFeatures } from '../../../../../paragraph-item/features/multi-features-list/multi-features.model';
import { Tab } from '../tab.model';
import { TabDetail } from '../tab-detail.model';
import { TabService } from '../tab.service';
import { FeatureTopicService } from '../../../../../paragraph-edit/feature-topic.service';
import { FeatureIndexSelection } from '../../../../../paragraph-edit/feature-index-topic-list/feature-index-selection.model';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions} from '@angular/material/tooltip';


interface Editing {
  isEditingStandard: boolean,
  isEditingMultiFeature: boolean ,
  isEditingTab: boolean
}

/** Custom options the configure the tooltip's default show/hide delays. */
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 2000,
  hideDelay: 100,
  touchendHideDelay: 1000,
};

@Component({
  selector: 'app-tab-edit',
  templateUrl: './tab-edit.component.html',
  styleUrls: ['./tab-edit.component.css'],
  providers: [{provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}],

})
export class TabEditComponent implements OnInit , OnDestroy {

  @ViewChild('FeaturesEnd') FeaturesEnd: ElementRef

  user: string

  tabEditForm: FormGroup;



  paragraphTopics: ParagraphTopic[];
  paragraphFeature : ParagraphFeature ;
  paragraph: Paragraph ;

  features : Feature[] = [];
  feature : Feature ;

  featureName : string ;

  count = 0 ;

   /** droppoed help hide or unhide the buttons before any feature is dropped  */
   dropped = true ; 
   panelOpenState = false;
   customCollapsedHeight: string = '20px';
   customExpandedHeight: string = '20px';
   index : number ;
   i : number ;
  
   @Input() changeIndex : number ;
   @Input() addFeature : Feature ; 
   @Input() eventIndex: number;

    featureCurrentIndex: number ;
    currentIndex : number ;
    // previx : number ;

  isMobile= true ;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );


   selectedFeature : string ;

   @Input() currentI : number

/** this is to change header name */
   weGoingToComponentIndex : boolean = true ;

/** this subcription number give us the id of the paragraph to be edited  */
   paragraphIndexId : number

/** to understand if we are creating or editing a paragraph */
   isEditMode: boolean ;

   /** to send the correct id of the miultifeature */
   sendId: number

   /** it's to stop subscriptions on ngOnInIt to fire  */
   @Input() isParagraphEdit: boolean 



  @ViewChild('tabs') tabGroup: MatTabGroup

  /** HERE START THE PROPERTIES FOR THE SECOND DROP */

  multiFeature: MultiFeatures;
  multiFeatures: MultiFeatures[];

  // @Input() changeMultiFeatureIndex: number

   /** droppoed help hide or unhide the buttons before any feature is dropped  */
   droppedSingleFeature = true ;

   droppedSingleFeatureId : number ;

   @Output() windexSingleFeature : number ;

   previxSingleFeature: number ;

   countSingleFeature: 0;

   /**this number will serve as a indication on which item can stay visible to edit */
   isEditMultiFeature : boolean = false ;

   /** to pass to multiFeatureEdit [index]="" */
   multiFeaturesEditId: number;

   /**id of feature to be changed  */
   editMultifeature: MultiFeatures ;

   /** pass id down to multiFeature to change service data precisely */
   editMultiFeatureId: number ;


   @Input() updateMultiFeature;

   /**
    * send request to edit feature
    */
   clickOnEditFeature: Subject<void> = new Subject<void>()

// this is needed to give the data to component list and component item the right 
// comperison in order to open only the right edit component 
    /**
     * this number everytime user drop a feature and change the i's 
     * 
     */
    updatedI: Subject<void> = new Subject<void>()
    passFeatureDataToComponentList: Subject<Feature> = new Subject<Feature>()
   

selectedIndex: number = 0

  @Input() tabId: number ;
  @Input() tab: Tab; 
  tabs: Tab[]
  tabDetail: TabDetail
  tabDetails: TabDetail[]

  lastTabDetail: boolean

  @Output() updateTab  = new EventEmitter<Tab>()

  onAdditionalFeature: Subscription

  paragraphComponentOffsetTop: number 
  @Output() onScrollToBottomOnParentComponent = new EventEmitter<number>()


  constructor(
              private DragDrop: DragDropService ,
              private breakpointObserver: BreakpointObserver,
              private featureTopicService: FeatureTopicService,
              private headerService: HeaderService ,
              private tabService: TabService , 
              private userObjectUpdateService: UserObjectUpdateService ,
              private postComponentsHttpRequestService: PostComponentsHttpRequestService,
              private paragraphService: ParagraphService,
              ) { }
  

  ngOnInit(){
    console.log(this.tabId)
    console.log(this.tab)

    this.user = this.userObjectUpdateService.userEmail

    console.log('is it IEDIT MODE ? ',this.isEditMode)

      this.tabEditForm = new FormGroup({
        name : new FormControl (this.tab.name ),
        description : new FormControl (this.tab.tabDetails[this.selectedIndex].name)
      })

      this.features = this.tab.tabDetails[this.selectedIndex].features;
      this.tabDetails = this.tab.tabDetails
      console.log(this.tabDetails)
 

    /**
     * this function check and log at console every change at the form
     */
    this.tabEditForm.valueChanges.subscribe( value => {
      this.tab.name = value.name
      this.tabDetails[this.selectedIndex].name = value.description
    }
      );

      if ( this.tab.tabDetails.length === 1){ 
        this.lastTabDetail = true 
      } else {
        this.lastTabDetail = false
      }

    const  newIsEditing: Editing = { isEditingStandard: false , isEditingMultiFeature: false, isEditingTab: true }
    this.paragraphService.adviseFeatureContainerIsEditing(newIsEditing)
  
    this.onAdditionalFeature = this.tabService.additionalFeature
        .subscribe((res: Feature)=> {
          console.log(res)
          this.onAddingNewFature(res)
        })
   

      
  };

  onLinkClick($event){
    console.log(this.selectedIndex)
    console.log(this.tab)

    this.tabEditForm = new FormGroup({
      name : new FormControl (this.tab.name ),
      description : new FormControl (this.tab.tabDetails[this.selectedIndex].name)
    })

    this.features = this.tab.tabDetails[this.selectedIndex].features;

  //  this.ngOnInit()

  this.dropped = true

  this.tabEditForm.valueChanges.subscribe( value => {
    this.tab.name = value.name
    this.tabDetails[this.selectedIndex].name = value.description
  }
    );


  }

  onAddingNewFature(addingFeature: Feature ){

    this.dropped = false ;

    const newOwner = addingFeature.owner
    const newIsNew = false 
    const newDropId = this.count++;
    const newFeature_id = addingFeature.feature_id ; // not sure 
    const newFeatureName = addingFeature.featureName;
    const newFeature = new Feature (  newOwner, newIsNew,  newDropId , newFeature_id, newFeatureName) ;

    this.features.push(newFeature)

    console.log(this.features )
      
      this.count = 0 ;
      for ( let i=0 ; i<this.features.length ; i++){
             this.features[i].dropId = this.count++
      }
  
    console.log (this.features );

    this.tabDetails[this.selectedIndex].features = this.features
    console.log(this.tabDetails)

    if ( this.tabDetails.length === 1){ 
      this.lastTabDetail = true 
    } else {
      this.lastTabDetail = false
    }

    const newFeaturesEnd : any =  this.FeaturesEnd
    console.log(newFeaturesEnd)
    this.paragraphComponentOffsetTop = newFeaturesEnd.nativeElement.offsetTop
    console.log(this.paragraphComponentOffsetTop)
    this.onScrollToBottomOnParentComponent.emit(this.paragraphComponentOffsetTop)
  }



  
// using drag and drop service to drop the feature and then change the feature-index-FeatureIndex number 
// so that every dropped feature differ from the others and it's easy to move them around without having a copy 
  onDrop(event: CdkDragDrop<Feature[]>) {
   
    this.DragDrop.dropFeature(event);

    /** here we avoid to add singleFeature features with this if 
     *  just a precaution since we eliminate the single features from being available on the index
    */
    if (this.features[event.currentIndex].featureName ==='single word'){this.features.splice(event.currentIndex, 1)}
    if (this.features[event.currentIndex].featureName ==='tabs'){this.features.splice(event.currentIndex, 1)}

   

    this.dropped = false ;
    // this.windex = event.currentIndex;
    // this.previx = event.previousIndex;
    /** this IF prevent to chnage dropId when the feature is moved within the same container */
    if (event.previousContainer !== event.container) {
    // this.featureService.droppedFeature(event.previousIndex )
     /** newDropId has to be count++ if we don't use the forEach function */
    const newOwner = this.user; 
    const newIsNew = false 
    const newDropId = this.count++;
   // const newFeatureId = this.features[event.currentIndex].featureid ;
    const newFeature_id = this.features[event.currentIndex].feature_id ; // not sure 
    console.log(newFeature_id)
    const newFeatureName = this.features[event.currentIndex].featureName;
    const newFeature = new Feature (  newOwner, newIsNew,  newDropId , newFeature_id, newFeatureName) ;

    this.features[event.currentIndex] = newFeature ;

    console.log(this.features )
      
      }
     /** this additional function is tomake sure the dropId are always in sequences 
      */
      this.count = 0 ;
      for ( let i=0 ; i<this.features.length ; i++){
             this.features[i].dropId = this.count++
        
      }
    
    // console.log(this.windex)
    console.log(event)
    console.log (this.features );

    this.tabDetails[this.selectedIndex].features = this.features
    console.log(this.tabDetails)

    if ( this.tabDetails.length === 1){ 
      this.lastTabDetail = true 
    } else {
      this.lastTabDetail = false
    }
   
    
  };



  /** we have to add a unique id to the feature on drop , to make the object unique */ 
  /**  have to use MAP to  */

  onChangedIndex(event){
    this.feature = event;
    // this.windex = event.currentIndex;
    console.log(event);
    /** we update the features on tghe index found , with the new feature 
     **/  
    
    this.features[this.feature.dropId] = this.feature;

     /** this additional function is tomake sure the dropId are always different  */
    this.count = 0 ;
    for ( let i=0 ; i<this.features.length ; i++){
       this.features[i].dropId = this.count++
       }
    this.count = 0 ;

    console.log(this.features);
    
  }


  onDeleteInnerTab(){

    this.tabDetails.splice(this.selectedIndex, 1)
    const newTabDetails =  this.tabDetails
    this.tab.tabDetails = newTabDetails
    this.tabDetails = newTabDetails 

    if ( this.selectedIndex === this.tabDetails.length){
      this.selectedIndex = this.selectedIndex-1
    }

    this.features = this.tabDetails[this.selectedIndex].features

    if ( this.tabDetails.length === 1){ 
      this.lastTabDetail = true 
    } else {
      this.lastTabDetail = false
    }

    console.log(this.tabDetails)
    console.log(this.features)

    this.ngOnInit()

  }


  /** remove feature from the array  */
  onRemoveFeature(i){
    this.features.splice(i, 1)
    console.log( this.features);

    this.tabDetails[this.selectedIndex].features = this.features
    console.log(this.tabDetails)


     /** this additional function is tomake sure the dropId are always different  */
    this.count = 0 ;
     for ( let i=0 ; i<this.features.length ; i++){
        this.features[i].dropId = this.count++
        } 
     this.count = 0 ;

     /** this function remove the buttons to add paragraph when all features are removed */
    if ( this.features.length > 0){
      this.dropped = false ;
  } else if ( this.features.length === 0 ) {
     this.dropped = true ;
  }
  
}

onCreate(){

  const newName = 'edit tab '

  const newTabDetail = new TabDetail ( newName, [])

  this.tabDetails.push(newTabDetail)

  this.selectedIndex = this.tabDetails.length-1

 // this.ngOnInit()



}
/**
 * export class  TabDetail {

    constructor  ( public selectedId: number , 
                   public name: string, 
                   public features: Feature[] ) {}
 */




     onSubmit(){

      const new_id = this.tab._id
      const newComponentName = this.tab.componentName
      const newUIID = this.tab.UIID
      const newOwner = this.tab.owner
      const newDateAndTime = null 
      const newAllUsersAllowed = this.tab.allUsersAllowed
      const newUserListAllowed = this.tab.userListAllowed
      const newName = this.tabEditForm.value.name 

      const newTabDetails = this.tabDetails
      const newFeatures_id_list = []
      for ( let i=0; i<this.tabDetails.length ; i++){
        for (let j=0 ; j<this.tabDetails[i].features.length ; j++){
          newFeatures_id_list.push(this.tabDetails[i].features[j].feature_id)
        }
      }

      const newTab = new Tab ( new_id, newComponentName, newUIID, newOwner, newDateAndTime, 
                               newAllUsersAllowed, newUserListAllowed,
                               newName, newTabDetails , newFeatures_id_list)


      console.log(newTab)

      if( this.user !== null ){
        this.postComponentsHttpRequestService.httpUpdateComponent(this.user , 'tab', newTab).subscribe( response => {
          console.log(response)
  
         })
      }

       this.tab = newTab 
       this.tabService.updateTab(newTab)

        this.updateTab.emit(this.tab)
  
        const newUpdateFeatureIndexSelection = new FeatureIndexSelection ( true , false )
        this.featureTopicService.nextIndexSelection(newUpdateFeatureIndexSelection)
  
        this.headerService.nextComponentIndexSelection(false)
     
        const  newIsEditing: Editing = { isEditingStandard: true , isEditingMultiFeature: false, isEditingTab: false }
        this.paragraphService.adviseFeatureContainerIsEditing(newIsEditing)
   
      
   }

   ngOnDestroy(): void {
    this.onAdditionalFeature.unsubscribe()
   }



  

}
