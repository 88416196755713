

<div *ngIf="!isDragDroppingFeatureIndexTopic">
    <div *ngFor="let featureTopic_id of featureIndexTopicListItem.featureTopic_ids ; let i=index ">

        <!--  matTooltip="long press to move this index"        
            matTooltipPosition="right" -->
            <app-feature-index-topic
            longPress 
            (mouseLongPress)="onDragDropFeatureIndexTopic()"
            [featureTopic_id]="featureTopic_id" > 
            </app-feature-index-topic>
         
    </div> 


</div>



<!-- this serve to move indexes from their original starting position  -->
<div *ngIf="isDragDroppingFeatureIndexTopic"
     cdkDropList
     (cdkDropListDropped)="drop($event)">

     <div *ngFor="let featureTopicName of featureTopic_ids_names ; let i=index " cdkDragLockAxis="y" cdkDrag>

        <div class="example-box" >
            <a style="color: #3f51b5;" class="navbar-brand"  > {{featureTopicName}} </a>
            <svg *ngIf="isPulsing" style="color: #3f51b5;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-up" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"/>
              </svg>
        </div>
         
    </div> 
</div>