import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef , OnDestroy, NgZone, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators ,  FormBuilder,  } from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Recipient } from '../../../recipient.model';
import { Observable, Subscription } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { FormService } from 'src/app/forms/form.service';
import { Form } from 'src/app/forms/form.model';
import { RecipientService } from '../../../recipient.service';

import { Contact } from 'src/app/contacts/contact.model';
import { ContactService } from 'src/app/contacts/contact.service';
import { ContactDetails } from 'src/app/contacts/contact-details.model';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { IsOnlineOfflineService } from 'src/app/services/shared-services/is-online-offline.service';
import { ErrorsDialogManagerService } from 'src/app/services/other-components-services/errors-dialog-manager.service';
import { GetComponentsHttpRequestService } from 'src/app/services/gets-services/get-components-http-request.sevice';
import { RecipientFeatureService } from 'src/app/forms/form-list/form-edit/recipient-index-list/recipient-feature.service';
import { RecipientIndexService } from 'src/app/forms/form-list/form-edit/recipient-index-list/recipient-index.service';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';



@Component({
  selector: 'app-to-message-accounts',
  templateUrl: './to-message-accounts.component.html',
  styleUrls: ['./to-message-accounts.component.css']
})
export class ToMessageAccountsComponent implements OnInit , OnDestroy {

  public separatorKeysCodes = [ENTER, COMMA, SPACE];

  user: string 

  emailList = []
  removable = true;

  rulesForm: FormGroup;
  fb: FormBuilder = new FormBuilder();

  @Input() recipient: Recipient;
  @Input() isOurOwnRecipient: boolean 

  contact: Contact ;
  contacts: Contact[]; 

  contactEmail: string
  contactEmails: string[] 
  contactDetails: ContactDetails[]
  contactEmailCtrl = new FormControl();
  filteredContactEmails : Observable<string[]>
  @ViewChild('contactInput') contactInput: ElementRef<HTMLInputElement>;
 
  

  /**
   * subscribe new Recipient Id when coming from Form Service
   */
   @Output() updateMessageMailList = new EventEmitter<string[]>()
   @Output() updateMessageMailList_AfterRemovedOne = new EventEmitter<string[]>()

   // send recipientIndex_id to remove from recipIndexListItem list 
   @Output() updateRecipientIndexListItems_RecipientIndexes_id_toDelete = new EventEmitter<string>()
  form: Form;
  receivedForm_id: string;
  index : number;
  formIndex: number;
  clickEventFromFormTopicIndexSubscription: Subscription ;
  toMessageList : string[]

  updateOnUserEmail: Subscription

  updateAllEmailInputsOfEmailContactsSubscription: Subscription

  constructor (  private contactService: ContactService ,
                 private isOnlineOfflineService: IsOnlineOfflineService,
                 private userObjectUpdateService: UserObjectUpdateService , 
                 private errorsDialogManagerService: ErrorsDialogManagerService ,
                 private getComponentsHttpRequestService: GetComponentsHttpRequestService, 
                 private recipientFeatureService: RecipientFeatureService , 
                 private recipientIndexService: RecipientIndexService ,
                 private ngZone: NgZone, 
                 private cdr: ChangeDetectorRef, 
                 private recipientService: RecipientService,
                 private postComponentsHttpRequestService: PostComponentsHttpRequestService, 
                 private initialNullSetupService : InitialNullSetupService
    ) { }

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
    })
   
    console.log(this.recipient)
    console.log(this.isOurOwnRecipient)
    this.emailList = []

    for( let i=0 ; i<this.recipient.toMessage?.length ; i++){
      this.emailList.push({value: this.recipient.toMessage[i] , invalid: false})
    }
    console.log(this.emailList);

    this.rulesForm = this.fb.group({
     emails: this.fb.array( [] , [this.validateArrayNotEmpty]),
    });

    this.createContactEmails()

    this.updateAllEmailInputsOfEmailContactsSubscription = this.recipientService.updateAllEmailInputsOfEmailContacts
        .subscribe(()=> {

          this.ngZone.run(()=>{
            this.createContactEmails()
          })
          this.cdr.detectChanges()
        })

  };

  createContactEmails(){
    this.contactDetails = this.contactService.getContactDetails()
    console.log('all contacts list = ',this.contactDetails)
    this.contactEmails = []
    for (let i=0 ; i<this.contactDetails.length ; i++){

      if ( this.contactEmails.findIndex(x=> x === this.contactDetails[i].contactEmail ) == -1 ){
           this.contactEmails.push(this.contactDetails[i].contactEmail)
      }
    }

    this.contactEmails.sort(this.compareEmails)

  }

  // Sorting function
 compareEmails(a, b) {
  // Convert emails to lowercase for case-insensitive sorting
  const emailA = a.toLowerCase();
  const emailB = b.toLowerCase();

  if (emailA < emailB) {
    return -1;
  }
  if (emailA > emailB) {
    return 1;
  }
  return 0;
}

  /**
   * export class ContactDetails {

    constructor( public contactName: string , 
                 public contactEmail: string , 
                 public telNumber: string, 
                 public isAConnection: boolean,
                 public isBlocked: boolean,
                 public isBeenReported: boolean){}
}

export class Contact {

    constructor( public _id: string,
                 public componentName: string, 
                 public UIID: string, 
                 public owner: string,
                 public allUsersAllowed: boolean,
                 public userListAllowed: string[],
                 public dateAndTime: string,
                 public updateDateAndTime: string,
                 public contactDetails: ContactDetails[]
                
                 ){}
   */
 

  add(event: MatChipInputEvent){
    console.log(event)

    if(this.isOurOwnRecipient === true ){
    if (event.value) {
      if (this.validateEmail(event.value)) { 
        const lowercaseValue = event.value.toLowerCase()
        this.emailList.push({ value: lowercaseValue, invalid: false });
        console.log(lowercaseValue)
        this.updateToMessageMailList()
        // check if this email already exist on contacts details 
        this.checkIfContactExistOnContactList(lowercaseValue)
      } else {
        return
      }
    }
    if (event.input) {
      event.input.value = '';
    }
    console.log('list of emails = ',this.emailList)
  }
  }

  onRemoveOtherEmail(data: any){
    const isOnline: boolean = this.isOnlineOfflineService.checkIsOnlineOffline()

    if ( isOnline === true ){
      if ( this.recipient.owner === this.user){
           this.getComponentsHttpRequestService.httpGetUserComponentBy_id(this.user, this.recipient._id)
               .subscribe((res: Recipient )=>{

                console.log(res , data)
                if ( res.toMessage.includes(data.value) ){
                  // can continue to delete 
                  if (this.emailList.indexOf(data) >= 0) {
                    this.emailList.splice(this.emailList.indexOf(data), 1);
                    console.log(data)
                    console.log('Removing ' + data.value)
                  }
                  console.log(this.emailList)
                  this.updateToMessageMailList()
                }

                if ( !res.toMessage.includes(data.value) ){
                  console.log( 'the user already removed hilself , just update the recipient locally ')

                  this.ngZone.run(()=>{
                    this.emailList.splice(this.emailList.indexOf(data), 1);
                    this.recipient.toMessage = this.emailList
                    const index = this.recipientService.getRecipients().findIndex(x=> x._id === this.recipient._id)
                    this.recipientService.recipients[index] = this.recipient
                  })

                  this.cdr.detectChanges()
                   
                }

               }, error => {
                
               })
      }

    }

    if ( isOnline === false ){
      this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'YOU APPEAR TO BE OFFLINE'})
    }
  }


  removeEmail(data: any){
     
    const isOnline: boolean = this.isOnlineOfflineService.checkIsOnlineOffline()

    if ( isOnline === true ){
      // if ( this.recipient.owner === this.user){
      //      this.getComponentsHttpRequestService.httpGetUserComponentBy_id(this.user, this.recipient._id)
      //          .subscribe((res: Recipient )=>{

      //           console.log(res , data)
      //           if ( res.toMessage.includes(data.value) ){
      //             // can continue to delete 
      //             if (this.emailList.indexOf(data) >= 0) {
      //               this.emailList.splice(this.emailList.indexOf(data), 1);
      //               console.log(data)
      //               console.log('Removing ' + data.value)
      //             }
      //             console.log(this.emailList)
      //             this.removeUserFromMessageMailList()
      //           }

      //           if ( !res.toMessage.includes(data.value) ){
      //             console.log( 'the user already removed hilself , just update the recipient locally ')

      //             this.ngZone.run(()=>{
      //               this.emailList.splice(this.emailList.indexOf(data), 1);
      //               this.recipient.toMessage = this.emailList
      //               const index = this.recipientService.getRecipients().findIndex(x=> x._id === this.recipient._id)
      //               this.recipientService.recipients[index] = this.recipient
      //             })

      //             this.cdr.detectChanges()
                   
      //           }

      //          }, error => {
                
      //          })
      // }
      if ( this.recipient.owner !== this.user ){
        const newRecipientFeature_id = this.recipientFeatureService.getRecipientFeature()._id
        console.log(newRecipientFeature_id)
      
        this.getComponentsHttpRequestService.httpGetUserComponentWithChildsBy_id(newRecipientFeature_id)
            .subscribe((recipientFeaturePlusChilds: any)=>{
             console.log(recipientFeaturePlusChilds)
      
             const sharedRecipients: Recipient[] = recipientFeaturePlusChilds.childList 
              
            if ( sharedRecipients.findIndex(x=> x._id === this.recipient._id ) === -1 ){
                 console.log('recipient is not there anymore already, update by cancelling this recipient ') 

                 const indexOfrecipientIndex = this.recipientIndexService.getRecipientIndexes().findIndex(x=> x.recipient_id === this.recipient._id)
                 const recipientIndex_id = this.recipientIndexService.getRecipientIndex(indexOfrecipientIndex)._id
                 this.updateRecipientIndexListItems_RecipientIndexes_id_toDelete.emit(recipientIndex_id)

            }
            if ( sharedRecipients.findIndex(x=> x._id === this.recipient._id ) !== -1 ){
                 console.log('recipient is ready to be updated')
                 const index = sharedRecipients.findIndex(x=> x._id === this.recipient._id )

                 const newRecipient = sharedRecipients[index]

                 if ( newRecipient.toMessage.includes(data.value) ){
                  // can continue to delete 
                     if (this.emailList.indexOf(data) >= 0) {
                         this.emailList.splice(this.emailList.indexOf(data), 1);
                         console.log(data)
                         console.log('Removing ' + data.value)
                      }
                  console.log(this.emailList)
                  this.removeUserFromMessageMailList()
                }

                if ( !newRecipient.toMessage.includes(data) ){
                  console.log('email is not there anymore already') 
                }



            }
  
            },error => {
  
            } 
            
            )
  
      }
    }

    if ( isOnline === false ){
      this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'YOU APPEAR TO BE OFFLINE'})
    }
    
   
  }

  checkIfContactExistOnContactList(newContact: string ){

    if ( this.contactService.contacts[0].contactDetails.findIndex( x=> x.contactEmail === newContact ) === -1 ){
      // add contact to list 
        const contactName = undefined 
        const contactEmail= newContact 
        const telNumber = undefined
        const isAConnection = true
        const isBlocked = false
        const isBeenReported= false 

        const newContactDetail = new ContactDetails (contactName, contactEmail, telNumber, isAConnection, isBlocked, isBeenReported )
         
         const contactItem = this.contactService.contacts[0] 
               contactItem.contactDetails.push(newContactDetail)

               this.contactService.contacts[0] = contactItem 
               this.initialNullSetupService.initialNullContacts[0] = contactItem

               this.createContactEmails()
               this.recipientService.updateAllEmailInputsOfEmailContactsSubject()

        if ( this.user !== null ){
         this.postComponentsHttpRequestService.httpUpdateComponent(this.user, 'contact', contactItem )
             .subscribe((res)=> {
              console.log(res)
             }, error => {
              console.log( error )
             })
        }
    }


  }


  /**
   * updating recipient item toMessage list with event
   */
  updateToMessageMailList(){
 
    this.toMessageList = []
    for (let i=0 ; i< this.emailList.length ; i++){
      this.toMessageList.push(this.emailList[i].value )
    }
    console.log(this.toMessageList)
    this.updateMessageMailList.emit(this.toMessageList)
    this.toMessageList = []
  // }
  
  }

  removeUserFromMessageMailList(){
    // if(this.isOurOwnRecipient === true ){
      this.toMessageList = []
      for (let i=0 ; i< this.emailList.length ; i++){
        this.toMessageList.push(this.emailList[i].value )
      }
      console.log(this.toMessageList)
      this.updateMessageMailList_AfterRemovedOne.emit(this.toMessageList)
      this.toMessageList = []
    // }
  }



  selected(event: MatAutocompleteSelectedEvent) {

    console.log(this.contactEmails[event.option.value])
    if (this.contactEmails[event.option.value]) {
      if (this.validateEmail(this.contactEmails[event.option.value])) {
        this.emailList.push({ value: this.contactEmails[event.option.value], invalid: false });
        console.log(this.contactEmails[event.option.value])
      } else {
        this.emailList.push({ value: this.contactEmails[event.option.value], invalid: true });
        this.rulesForm.controls['emails'].setErrors({'incorrectEmail': true});
      }
    }
    /**
     * this last part cut out the selected email from the list 
     */
 
    console.log('list of emails = ',this.emailList)
    this.updateToMessageMailList()

    console.log(event)
  }


  private validateArrayNotEmpty(c: FormControl) {
    if (c.value && c.value.length === 0) {
      return {
        validateArrayNotEmpty: { valid: false }
      };
    }
    return null;
  }

  private validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };


  ngOnDestroy():void{
    this.updateOnUserEmail?.unsubscribe()
    this.updateAllEmailInputsOfEmailContactsSubscription?.unsubscribe()
  //  this.subscriptionToRestartComponentFromRecipientItem.unsubscribe()
  }


}
