export class IndexType {

    /**
     * 
     * these can be used for most of the paragraph/form indexes 
     * @param isPublic 
     * @param isPersonal 
     * @param isShared 
     * @param isPreferred 
     */

    constructor ( 
                  public isPublic: boolean,
                  public isPersonal: boolean,
                  public isShared: boolean ,
                  public isPreferred: boolean ){ }
}