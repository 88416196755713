import { Injectable } from "@angular/core";
import { FixLayout } from "./fix-layout.model";
import { InitialNullSetupService } from "src/app/services/starting-services/initial-null-setup.service";
import { Subject, Subscription } from "rxjs";
import { StoreInitialEmptyPersonalComponentsService } from "src/app/services/store-initial-components/store-initial-empty-personal-components.service";
import { StoreFormItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-form-item-plus-childs-confirmation.service";
import { StoreParagraphItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-paragraph-item-plus-childs-confirmation.service";
import { StoreTabItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-tab-item-plus-childs-confirmation.service";


@Injectable ({
    providedIn: 'root' 
})


export class FixLayoutService {

    constructor ( 
                  private initialNullSetupService: InitialNullSetupService, 
                  private storeFormItemPlusChildsConfirmationService: StoreFormItemPlusChildsConfirmationService , 
                  private storeParagraphItemPlusChildsConfirmationService: StoreParagraphItemPlusChildsConfirmationService , 
                  private storeTabItemPlusChildsConfirmationService: StoreTabItemPlusChildsConfirmationService
                  ){}

    fixLayouts: FixLayout[] = this.initialNullSetupService.initialNullFixLayouts

    initialPersonalFixLayoutsHaveBeenStored = new Subject<void>()
   

    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.fixLayoutsChangedBackToNull
    .subscribe((res)=> {
          this.fixLayouts = res 
          console.log('updated fixLayout back to null = ', res)

    })



    getFixLayouts(){
        return this.fixLayouts
    }

    getFixLayout_By_id(_id: string){
        const newFixLayout: FixLayout = this.fixLayouts.find(
            (res)=> {
                return res._id === _id
            }
        )
        return newFixLayout
    }

    getFixLayout(i: number){
        return this.fixLayouts[i]
    }

    onAddNew(fixLayout: FixLayout){
        this.fixLayouts.push(fixLayout)
        this.updateInitialComponents(this.fixLayouts)
    }

    onUpdateFixLayout(updatedFixlayout: FixLayout){
        const index = this.fixLayouts.findIndex(x=> x._id === updatedFixlayout._id)
        this.fixLayouts[index] = updatedFixlayout
        this.updateInitialComponents(this.fixLayouts)
    }

    addNeFixLayouts(fixLayouts: FixLayout[]){
        for ( let i=0; i<fixLayouts.length ; i++){
            if ( this.fixLayouts.findIndex(x=> x._id === fixLayouts[i]._id ) === -1 ){
                this.fixLayouts.push(fixLayouts[i])
            }
            if (i===fixLayouts.length-1){
                this.updateInitialComponents(this.fixLayouts)
                this.initialPersonalFixLayoutsHaveBeenStored.next()
            }
        }
        // this.initialPersonalFixLayoutsHaveBeenStored.next() // send to fixlayoutList.ts 
    }

    addNewFixLayout(fixLayoutToAdd: FixLayout , isComingFromFormItemCall: boolean , isComingFromMultiFeatures: boolean ){

        if ( this.fixLayouts.findIndex(x=> x._id === fixLayoutToAdd._id) !== -1 ) { 
             const index =  this.fixLayouts.findIndex(x=> x._id === fixLayoutToAdd._id)
             this.fixLayouts[index] = fixLayoutToAdd
            this.updateInitialComponents(this.fixLayouts)
        }

        if ( this.fixLayouts.findIndex(x=> x._id === fixLayoutToAdd._id) === -1 ) { 
             this.fixLayouts.push(fixLayoutToAdd)
             this.updateInitialComponents(this.fixLayouts)
        }

        if ( isComingFromFormItemCall === true  ){ this.storeFormItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenFormItemPlusChildsAreStored( fixLayoutToAdd._id) }
        if ( isComingFromFormItemCall === false ){ this.storeParagraphItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenParagraphItemPlusChildsAreStored( fixLayoutToAdd._id) }
        if ( isComingFromMultiFeatures=== false ){ this.storeTabItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenTabItemPlusChildsAreStored( fixLayoutToAdd._id) }
  
        console.log('FIXLAYOUTS = ', this.fixLayouts)

    }

    updateInitialComponents(updatedFixLayouts: FixLayout[]){
        this.initialNullSetupService.initialNullFixLayouts= updatedFixLayouts
    }

    ngOnDestroy(){
        this.updateInitialNullDatafromInitialNullSetupService.unsubscribe()
    }



}

/**
 * export class FixLayoutModel {

    constructor (public _id: number ,
                 public _name: string,
                 public owner: string,
                 public dateAndTime: string, 
                 public fixLayoutTables: FixLayoutTable[]
                 ){}
}
 */