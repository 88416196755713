import { ChartDetail } from "../../charts/chart-detail.model";

export class FixLayoutTableTD{

    constructor( public tableTDType: string , 
                 public tableTDDetailString: string,
                 public tableTDDetailStringLabel: string,
                 public tableTDDetailNumber: number,
                 public tableTDDetailNumberLabel: string,
                 public tableTDDetailDescription: string,
                 public tableTDDetailDescriptionLabel: string,
                 public tableTDDetailChartShare: number,
                 public tableTDDetailChartTotal: number,
                 public tableTDDetailWidth: string  ){}
}