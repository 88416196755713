import { Component, OnInit , ViewChild , ChangeDetectorRef } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { FeatureTopicService } from './paragraph-list/paragraph-edit/feature-topic.service';
import { FeatureIndexSelection } from './paragraph-list/paragraph-edit/feature-index-topic-list/feature-index-selection.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ParagraphService } from './paragraph.service';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';




@Component({
  selector: 'app-paragraph',
  templateUrl: './paragraph.component.html',
  styleUrls: ['./paragraph.component.css']
})
export class ParagraphComponent implements OnInit {

  @ViewChild('drawer2') public sidenav: MatSidenav;
  @ViewChild('sidenavContent') sidenavContent: MatSidenavContent

  isDesktop: boolean;
  isMobile: boolean; 
  isEditMode: boolean; 

  paragraph_id: string

  isLoadingFeatureIndexTopicList: boolean = false 

  isLoading: boolean = false 



  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

constructor(private breakpointObserver: BreakpointObserver, 
            private featureTopicService: FeatureTopicService,
            private paragraphService: ParagraphService , 
            private route: ActivatedRoute,
            private router: Router,
            private readonly changeDetectorRef: ChangeDetectorRef
            ) {}

ngOnInit(){

  this.isDesktop = true
  this.isMobile = false 
  this.isLoading = true 

      const newUpdateFeatureIndexSelection = new FeatureIndexSelection ( true, false  )
      this.featureTopicService.nextIndexSelection(newUpdateFeatureIndexSelection)

  this.isLoadingFeatureIndexTopicList = true 

  this.route.params 
                   .subscribe(
                    (params: Params)=> {
                      this.paragraph_id = params['id']
                      this.isEditMode = params['id'] != null;
                      console.log('IS EDIT MODE = ',this.isEditMode)

                      this.checkIfWeAreInValidState( this.paragraph_id , this.isEditMode)
                      /**
                       * make the case of logging out from paragraphEdit while editing and it goes into error 
                       */
                    }, error =>{
                      console.log(error.message , "there has been an error")
                    }
                   )
  setTimeout(()=>{
    this.isLoadingFeatureIndexTopicList = false 
  },100)

}



checkIfWeAreInValidState( paragraph_id: string , editMode: boolean){

  console.log('is checking ')
  if ( editMode === true ){
      if ( this.paragraphService.getParagraphs().findIndex(x=> x._id === paragraph_id ) === -1 ){  console.log('paragraph doesn t exist ') ,  setTimeout(()=>{ this.router.navigate(['/create-form' ], {relativeTo: this.route }) },1000) }
      if ( this.paragraphService.getParagraphs().findIndex(x=> x._id === paragraph_id ) !== -1 ){ console.log('paragraph exist ') ,  this.isLoading = false }
  }

  if ( editMode === false ){
       /**
        * check if a paragraphTopic was selected to start creating a new paragraph , 
        * or create and then make it possibe to choose from available paragraphTopics 
        */
       this.isLoading = false

  }



}

ngAfterViewChecked(): void {
  this.changeDetectorRef.detectChanges();
};

onIsDesktop(){
  this.isDesktop = !this.isDesktop;
  this.isMobile = false;
}


onIsMobile(){
  this.isMobile = !this.isMobile;
  this.isDesktop = true ; 
}

onOpenSideNav(){
  this.isMobile = !this.isMobile;
  this.sidenav.open()
}

onScrollParagraphSideNavContentToBottom($event){
  console.log("is scrolling page to bottom")
  document.getElementsByTagName('mat-sidenav-content')[0].scrollTo( { top: $event+100 , behavior: 'smooth'} )
}



}
