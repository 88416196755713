<div class="innerItemFrame2">
    <div class="col-xs-12">

        <form [formGroup]="innerSlidetoggleEditFormName" (ngSubmit)="onSubmitName()">
    
    
            <div class="row">
                <div class="col">
                    <mat-form-field style="width: 250px" appearance="outline">
                        <mat-label>component name</mat-label>
                        <input 
                        matInput
                        type="text"
                        id="name"
                        formControlName="name"
                        >
                    </mat-form-field>
                    <mat-error *ngIf="!innerSlidetoggleEditFormName.get('name').valid"  >Please enter a valid name, maximum 30 letters </mat-error>
                </div>
        
            
            </div>
        </form>
    </div>
</div>
