import { Component, OnInit, Input , Inject} from '@angular/core';
import { MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Chat } from 'src/app/chats/chat.model';
import { DateTime } from 'luxon';
import { Subscription, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Recipient } from 'src/app/forms/form-list/form-item/recipient/recipient.model';
import { Paragraph } from 'src/app/forms/form-list/form-item/paragraph/paragraph.model';
import { ParagraphService } from 'src/app/forms/form-list/form-item/paragraph/paragraph.service';
import { ParagraphResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/paragraph-result.model';
import { FormResult } from 'src/app/forms/form-results/form-result.model';
import { Sender } from 'src/app/forms/form-results/form-results-list/form-result-item/sender/sender.model';
import { Receiver } from 'src/app/forms/form-results/form-results-list/form-result-item/receiver/receiver.model';
import { ChatService } from 'src/app/chats/chat.service';
import { FormResultsService } from 'src/app/forms/form-results/form-results.service';
import { FormResultHttpRequestService } from 'src/app/forms/form-results/form-result-http-request.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { FeatureResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/feature-result.model';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';



@Component({
  selector: 'app-chat-fill-form-mobile-comment',
  templateUrl: './chat-fill-form-mobile-comment.component.html',
  styleUrls: ['./chat-fill-form-mobile-comment.component.css']
})
export class ChatFillFormMobileCommentComponent implements OnInit {

  constructor(  private _bottomSheetRef: MatBottomSheetRef<ChatFillFormMobileCommentComponent>,
                private formResultsService: FormResultsService,
                private chatService: ChatService,
                private _bottomSheet: MatBottomSheet ,
                private userObjectUpdateService: UserObjectUpdateService ,
                private formResultHttpRequestService : FormResultHttpRequestService ,
                @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { }

  user: string          
  chat: Chat = this.data.chat 
  recipient: Recipient = this.data.recipient


recipientResult: Recipient;
senderChatPartecipants : string[];
receiverChatPartecipants: string[]

featureResult: FeatureResult
featureResults: FeatureResult[]
paragraphResult: ParagraphResult;
paragraphResults: ParagraphResult[];
sentParagraphResult: ParagraphResult;
sentParagraphResults: ParagraphResult[];
receivedParagraphResult: ParagraphResult;
receivedParagraphResults: ParagraphResult[];

finalSentParagraphResult: ParagraphResult;
finalSentParagraphResults: ParagraphResult[] = [];

finalReceivedParagraphResult: ParagraphResult;
finalReceivedParagraphResults: ParagraphResult[] = [];

finalSentFormResult: FormResult;
finaleReceivedFormResult: FormResult; 

timeAtSendForm: string;
subscribeClickEvent: Subject<void> = new Subject<void>();
subscribeFormNewSubject: Subject<void> = new Subject<void>()


 chatReceiver : Receiver ; 
 sentToMessageList: string[];

// new properties to check which chats has the same subject 

 /**
  * possible chat numbers 
  */
  futureFormResultChat_id : string 
  futureFormResultChatId : number 

  chatExist : boolean 
  chatExistOnIndex: number 
  chatHasToBeCreated: boolean

  /**
   * properties to use button and select the right subject 
   */
  selectedSubject: string
  count: number

  finalSubject: string 

  // only 1 subject in the chat 

  only1Subject: boolean ;
  addSubject: boolean ; 
  giveSubjectButtonOption: boolean 


  ngOnInit(): void {

    this.user = this.userObjectUpdateService.userEmail

    console.log(this.chat, this.recipient);

    document.onvisibilitychange = function() {
      if (document.visibilityState === 'hidden') { console.log('is hidden',  DateTime.now().toString())}
      if (document.visibilityState !== 'hidden') { console.log('is visible', DateTime.now().toString())}
    };

    this.featureResults = []
    this.sentParagraphResults = []

    /**
 * this chat receiver is to match chat partecipants.partecipants to form receiver 
 */
     const newSentRecipientGroup = '';
     const newSentRecipientName = this.recipient.name ;
     const newSentToMessage = this.recipient.toMessage;
     // const newSentToMessage = this.chatService.getChat(0).partecipantGroup.partecipants;
     const newSentToMail =  [];
     const newSentCcMail = [];
     const newSentBccMail = [];
     const newSentGreetings = ''
     const newChatReceiver = new Receiver ( newSentRecipientGroup, newSentRecipientName, newSentToMessage, newSentToMail, newSentCcMail, newSentBccMail, newSentGreetings)

  
     this.chatReceiver = newChatReceiver ; 

   

  }

  onSendForm(){
  //  this.futureFormResultChat_id = this.chat.recipient.chatRoomId

      this.timeAtSendForm = DateTime.now().toString();
      this.finalSubject = this.chat.subject

       /**
      * start the subscriptions to on sending the form to receive the results
      */
        this.subscribeClickEvent.next();
  };

   /**
   * 
   * @param event receiving and adding the featureResults from the related features components
   */
    onUpdateFeatureResult(event){
      this.featureResults.push(event)
      console.log(this.featureResults)
  
        /**
       * preparing the  paragraphResultsto be sent to formItem 
       */
  
   
      const newParagraphResultName = 'comment'
   
       if ( this.featureResults.length === 1){
   
         const newSentParagraphResult = new ParagraphResult ( 
                                                              newParagraphResultName,
                                                              this.featureResults ) ;
   
                                                     
       /**
        * sending the paragraph to keep in user formResult
        */
        console.log(newSentParagraphResult)
      
       
       this.onUpdateSentParagraphResult(newSentParagraphResult)
   
   
       /** restart the situation */
       this.featureResults = [] 
   
   
       }
     }


  /**
* 
* @param event receiving the sentParagraphResuls from paragraphItem
*/
onUpdateSentParagraphResult(paragraphResult: ParagraphResult){
  this.sentParagraphResults.push(paragraphResult);
  console.log(this.sentParagraphResults)
  if( this.sentParagraphResults.length === 1){
    this.createfinalSentFormResult();
  }
  };


  createfinalSentFormResult(){
    const new_id = (this.formResultsService.getFormResults().length+1).toString() ;
    //  const newChat_id =  this.futureFormResultChat_id;
    const newChatRoomId =  this.recipientResult.chatRoomId
    const newIsActive = true ; 
    const newIsDeleted = false ;
    const newIsSelected = false;
    const newIsRead = true ;
    const newIsSharable = true 
    const newIsACopyOfAMessage = false 
    const newIsForwardToMail = false ;
    const newIsSentToMessage = true ;
    const newIsForwarded = false ;
    // this.isForwarded ( coming from a new function and button on formRsult )
    const newIsReceived = false ; 
    const newSender = new Sender ( this.user) ;
    const newSenderGroup = '' ;
    const newSenderType = '' ;
    const newReceiver = this.chatReceiver ;
    const newReceiverGroup= this.recipient.name ; 
    const newReceiverType = 'private' ;
    // const newFormDateAndTime = this.formDateAndTime ;
    const newSentRecipientGroup = '';
    const newUserChatRoomId = this.recipient._id
    const newRecipientResult = this.recipientResult
    const newDateAndTime = this.timeAtSendForm ;
    // const newSubject = this.chat.subject ;
    const newSubject = this.finalSubject ;
    const newParagraphResults = this.sentParagraphResults ;
    const newSeq = null 
    const newUpdateDateAndTime = null 
    const newHasFollowingParagraphItemToShow = undefined

    const newSentFormResult = new FormResult (   new_id,
                                                newIsActive ,
                                                newIsDeleted,
                                                newIsSelected,
                                                newIsRead,
                                                newIsSharable,
                                                newIsACopyOfAMessage ,
                                                newIsForwardToMail,
                                                newIsForwarded,
                                                newSender,
                                                newSenderGroup,
                                                newSenderType,
                                                newReceiverGroup,
                                                newReceiverType,
                                                newUserChatRoomId,
                                                newRecipientResult,
                                                newDateAndTime,
                                                newSubject,
                                                newParagraphResults ,
                                                newSeq , 
                                                newUpdateDateAndTime, 
                                                newHasFollowingParagraphItemToShow
                                                ) ;
    
    
    
    this.finalSentFormResult = newSentFormResult
    
    this.formResultsService.addFormResult( this.finalSentFormResult )
    console.log(newSentFormResult)
    /**
    * only when in Chat fill form we need to update the subject list
    */
    this.formResultsService.updateUniqueSubjectListToChatFormResult()
    
    this.chatService.closeSideNavOnceMobileFormHasBeenSent() // subscribe to send indication to close the sideNav
    
    //this.checkIfChatAlreadyExist()
    
    this.updateChatIndex()
    this.ngOnInit()
    
};




 /**
 * now if this chat exist we don't create the new CHat but we add the form only and 
 * we make the property hasNewForm = true
 * 
 * if the chat doesn't exist then we produce a new chat on the list 
 */

// we use a *ngFor to make it for each chatExist = true , but supposed 
// to be ONLY ONE number
updateChatIndex(){

  this.chatService.sendUpdateToChatIndexList()

  /**
   *  constructor (public _id: string ,
               public owner: string ,
               public isVisible: boolean, 
               public dateAndTime: string , 
               public recipient: Recipient, 
               public partecipantGroup: PartecipantGroup , 
               public isNewChat: boolean, 
               public isBeenUpdated: boolean,
               public isSelected: boolean, 
               public subject: string ){}
   */
  for (let i=0; i<this.chatService.getChats().length; i++){
    this.chatService.getChat(i).isSelected = false
  }

  const new_id = this.chat._id
  const newOwner = this.chat.owner
  const newAllUsersAllowed = this.chat.allUsersAllowed
  const newUserListAllowed = this.chat.userListAllowed
  const newIsVisible = true 
  const newDateAndTime = this.timeAtSendForm
  const newUpdateDateAndTime = this.chat.updateDateAndTime
  const newIsNewChat = false
  const newIsBeenUpdated = true 
  const newIsSelected = true
  const newSubject = this.finalSubject

  const newChat = new Chat ( new_id, newOwner, newAllUsersAllowed, newUserListAllowed, newIsVisible, newDateAndTime,
                             newUpdateDateAndTime ,newIsNewChat, newIsBeenUpdated, newIsSelected, newSubject)

  this.chatService.chats[this.chatService.getChats().findIndex(x=> x._id === newChat._id)] = newChat

  this.chatService.sendUpdateToChatIndexList()

  // here we have to make a PUT call to HTTP

  // have to turn all the rest NON SELECTED + 

  // this.chatService.getChat(this.chatService.getChats().findIndex(x=> x.recipient.chat_roomId === this.chat.recipient.chat_roomId)).isBeenUpdated = true ;
  // this.chatService.getChat(this.chatService.getChats().findIndex(x=> x.recipient.chat_roomId === this.chat.recipient.chat_roomId)).isSelected = true ;
  // this.chatService.getChat(this.chatService.getChats().findIndex(x=> x.recipient.chat_roomId === this.chat.recipient.chat_roomId)).subject = this.finalSubject ;
  // this.chatService.getChat(this.chatService.getChats().findIndex(x=> x.recipient.chat_roomId === this.chat.recipient.chat_roomId)).dateAndTime = this.timeAtSendForm 


  console.log(this.chatService.getChats())
   /**
  * sending the id to chat item to display correct chat item header
  */
//  this.chatService.sendUpdateToChatIndexList()

this._bottomSheet.dismiss();


};

ngOnDestroy(): void {
}
    

}
