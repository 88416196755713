import { Injectable } from "@angular/core";

@Injectable ({
    providedIn: 'root' 
})

export class DuplicateFeatureParagraphFormService {



    duplicateFeature_and_Parents(){
        console.log("duplicating a feature")
    }


    duplicateFeature_Paragraph_and_Parents(){
        console.log("duplicating feature + paragraph")

    }

    duplicateFeature_Paragraph_Form_and_Parents(){
        console.log("duplicating feature + paragraph + form")
    }
 }