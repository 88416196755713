import { Component, OnInit, Input, ViewChild , Output, EventEmitter } from '@angular/core';
import { SingleDropDownSelect } from '../../single-drop-down-select.model';
import { SingleDropDownSelectService } from '../../single-drop-down-select.service';
import { MatDialogRef, MAT_DIALOG_DATA, _closeDialogVia } from '@angular/material/dialog';
import { DialogmanagerService } from 'src/app/services/other-components-services/dialogmanager.service';
import { Observable, Subscription } from 'rxjs';
import { SingleFeatureDetailResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/single-feature-results/single-feature-detail-result.model';
import { SingleDropDownSelectDetailResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/single-feature-results/single-drop-down-select-result/single-drop-down-select-detail-result.model';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { Feature } from '../../../../../feature.model';
import { FormService } from 'src/app/forms/form.service';

@Component({
  selector: 'app-single-drop-down-select-item',
  templateUrl: './single-drop-down-select-item.component.html',
  styleUrls: ['./single-drop-down-select-item.component.css']
})
export class SingleDropDownSelectItemComponent implements OnInit {

  user: string 

   // needed is for stuff like edit buttons to be visibles or not
  @Input() weAreInMultiFeatureEdit:boolean; 
  @Input() noNeeded : boolean  ; 
  @Input() index_id: string 
  index: number
  singleDropDownSelect: SingleDropDownSelect
  singleDropDownSelectDetailResult: SingleDropDownSelectDetailResult

  @Output() deleteSingleFeatureResultEvent = new EventEmitter<void>()

  // singleFeatureDetailResultSubscription: Subscription;
  // @Input() subscribeSingleFeatureResult: Observable<void>
  @Input() isParagraphEdit: boolean ;
  @Input() feature: Feature

  singleFeatureDetailResult: SingleFeatureDetailResult;

  mailTextSingleFeatureBodyPart: string ;

  @Output() updateBothSingleFeatureResultAndTextPlusIndex = new EventEmitter<any>()

  requestToFinalizeResultAgain: Subscription 

  constructor( private singleDropDownSelectService: SingleDropDownSelectService , 
               private formService: FormService,
               private userObjectUpdateService: UserObjectUpdateService ) { }

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    this.index = this.singleDropDownSelectService.getSingleDropDownSelects().findIndex( x => x._id === this.index_id)

    this.singleDropDownSelect = this.singleDropDownSelectService.getSingleDropDownSelect(this.index)

    console.log('feature = ', this.feature, this.singleDropDownSelect)
     /**
     * compose emailTextBodyPart
     */
   
      this.mailTextSingleFeatureBodyPart = (this.singleDropDownSelect.description)+': '+'???'

      /**
       * SingleDropDownSelectDetailResult 
       */
      const newDescriptionResult = this.singleDropDownSelect.description
      const newContentResult = '???'
      const newSingleDropDownSelectDetailResult = new SingleDropDownSelectDetailResult ( newDescriptionResult, newContentResult)

      this.singleDropDownSelectDetailResult = newSingleDropDownSelectDetailResult
       /**
         * featureResult
         */
        const newSingleFeatureDetailResultType = 'singleDropDownSelectResult';
        const newSingleFeatureDetailResultName = this.singleDropDownSelect.name;
        const newSingleFeatureDetailResult = new SingleFeatureDetailResult (newSingleFeatureDetailResultType, newSingleFeatureDetailResultName, newSingleDropDownSelectDetailResult )

        this.singleFeatureDetailResult = newSingleFeatureDetailResult


      /**
       * sending initial result data
       */
        this.requestToFinalizeResultAgain = this.formService.getRequestToFinalizeResultAgain().subscribe(()=> this.onSend())
       setTimeout(()=>{
          this.onSend()
        },200)
     
      

   
  };

  selectDescription( $event){
    console.log( $event )
      /**
     * compose emailTextBodyPart
     */
      this.mailTextSingleFeatureBodyPart = this.singleDropDownSelect.description+': '+($event.value);
       /**
       * SingleDropDownSelectDetailResult 
       */
        const newDescriptionResult = this.singleDropDownSelect.description
        const newContentResult = $event.value
        const newSingleDropDownSelectDetailResult = new SingleDropDownSelectDetailResult ( newDescriptionResult, newContentResult)
  
        this.singleDropDownSelectDetailResult = newSingleDropDownSelectDetailResult

        /**
         * featureResult
         */
        const newSingleFeatureDetailResultType = 'singleDropDownSelectResult';
        const newSingleFeatureDetailResultName = this.singleDropDownSelect.name;
        const newSingleFeatureDetailResult = new SingleFeatureDetailResult (newSingleFeatureDetailResultType, newSingleFeatureDetailResultName, newSingleDropDownSelectDetailResult )

        this.singleFeatureDetailResult = newSingleFeatureDetailResult

        if ( this.weAreInMultiFeatureEdit === false){

          const newFeatureDrop_Id = this.feature.dropId
       // const newSingleFeatureDetailResult: SingleFeatureDetailResult = this.singleFeatureDetailResult
          const newMailTextSingleFeatureBodyPart: string = this.mailTextSingleFeatureBodyPart
        
          this.updateBothSingleFeatureResultAndTextPlusIndex.emit({ newFeatureDrop_Id, newSingleFeatureDetailResult, newMailTextSingleFeatureBodyPart   })
 

        }
         }

  openDialogSingleDropDownselectEdit(){

  }

  onDeleteSingleDropDownSelectItem(){
    this.deleteSingleFeatureResultEvent.emit()
  }

  onSend(){
    if ( this.weAreInMultiFeatureEdit === false){

      const newFeatureDrop_Id = this.feature.dropId
      const newSingleFeatureDetailResult: SingleFeatureDetailResult = this.singleFeatureDetailResult
      const newMailTextSingleFeatureBodyPart: string = this.mailTextSingleFeatureBodyPart
      
      this.updateBothSingleFeatureResultAndTextPlusIndex.emit({ newFeatureDrop_Id, newSingleFeatureDetailResult, newMailTextSingleFeatureBodyPart   })
 
    }
    
  };

  ngOnDestroy(){
  
     this.requestToFinalizeResultAgain.unsubscribe()
    
}



}
