
export class ParagraphTopic {

    constructor ( public _id: string, 
                  public componentName: string ,
                  public UIID: string,
                  public owner: string,
                  public allUsersAllowed: boolean,
                  public userListAllowed: string[],
                  public dateAndTime: string ,
                  public isAccepted: boolean, 
                  public isArchived: boolean, 
                  // add a substitute name for shared topics name to be changed from email  
                  public name: string , 
                  public paragraphFeatureContainer_ids: string[] 
                   ){}
    
    }

