import { TabDetail } from "./tab-detail.model";

export class Tab {

constructor(  public _id : string ,
              public componentName: string , 
              public UIID: string , 
              public owner : string ,
              public dateAndTime: string,
              public allUsersAllowed: boolean, 
              public userListAllowed: string[] ,  
              public name: string ,  
              public tabDetails: TabDetail[],
              public feature_id_list: string[] ){}

}