<!-- <p>multi-entry-calendar-item works!</p> -->
<div class="row">
  <div class="col-xs-12">

    <br>
    
    <div class="row">
      <div class="col-xs-12">
        {{multiEntryCalendar.description}}
      </div>
    </div>

    <br>

    <div class="row">
      <div class="col-xs-12">

<mat-form-field>
  <mat-chip-list #chipList aria-label="Choose dates" (click)="picker.open()">
    <mat-chip
      *ngFor="let value of multiEntryCalendar.multiEntries"
      [selectable]="false"
      [removable]="true"
      (removed)="remove(value)"
    >
      {{ value | date }}
      <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
    </mat-chip>
    <input
      [value]="resetModel"
      matInput
      [matDatepicker]="picker"
      placeholder="Choose dates"
      (dateChange)="dateChanged($event)"
      [matChipInputFor]="chipList"
      hidden
    />
  </mat-chip-list>
  <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker
    #picker
    [startAt]="init"
    [dateClass]="dateClass">
  </mat-datepicker>
</mat-form-field>

      </div>
    </div>

  </div>
</div>
