import { Component, Input, OnInit } from '@angular/core';
import { SingleWordDetailResult } from './single-word-detail-result.model';
@Component({
  selector: 'app-single-word-result',
  templateUrl: './single-word-result.component.html',
  styleUrls: ['./single-word-result.component.css']
})
export class SingleWordResultComponent implements OnInit {

 @Input() singleWordDetailResult: SingleWordDetailResult

  constructor() { }

  ngOnInit(){
    this.singleWordDetailResult
  }

}
