import { Component, OnInit, EventEmitter , Output, OnDestroy, ChangeDetectorRef, NgZone} from '@angular/core';
import { Subscription } from 'rxjs';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { Chat } from '../chat.model';
import { ChatService } from '../chat.service';
import { Recipient } from 'src/app/forms/form-list/form-item/recipient/recipient.model';
import { RecipientService } from 'src/app/forms/form-list/form-item/recipient/recipient.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormResult } from 'src/app/forms/form-results/form-result.model';

interface ChatAndInnerFormResults{
  numberOfItems: number ,
  innerChatFormResults: InnerChatFormResultNumbers[]
}

interface InnerChatFormResultNumbers{
  chat: Chat,
  isSelected: boolean,
  innerChatFormResults: FormResult[]
}

interface SelectedChat {
  aChatHasBeenSelected: boolean ,
  recipientSelected: Recipient 
}


@Component({
  selector: 'app-chat-list-index',
  templateUrl: './chat-list-index.component.html',
  styleUrls: ['./chat-list-index.component.css']
})
export class ChatListIndexComponent implements OnInit , OnDestroy {

  user: string 

  recipients: Recipient[]

  chatAndInnerFormResults: ChatAndInnerFormResults
  innerChatFormResults: InnerChatFormResultNumbers[]



  chatsExist: boolean;
  chat: Chat ;
  chats: Chat[];

  currentSelectedChat_id: number ;

  @Output() updateChatsOnChoosenChat = new EventEmitter<Recipient>() 

  /**
   * receiving the click from service and chatFillForm ( update chat index list) to update
   * also the list order once receiving a new form or everytime we send a form from chat 
   */
  receiveClickFromChatFillFormServiceToUpdateListOrder : Subscription ;

  /**
   * update the chat index list by adding the new created chat from create chat dialog 
   */
  receiveClickFromCreateNewChatDialog : Subscription ;
  /**
   * subscription from chat item when is displayed 
   */
   chatItemHasBeenDisplayed: Subscription
   updateOnUserEmail: Subscription 

  isLoading = false
  loadingItems: number[] = [1,2,3,4,5,6,7,8,9,10]
  

  loggedInChatsAreHereSubscription: Subscription 

  constructor( private chatService: ChatService ,
               private userObjectUpdateService: UserObjectUpdateService ,
               private recipientService: RecipientService , 
               private datePipe: DatePipe,
               private router: Router,
               private route: ActivatedRoute
                ) {}


  ngOnInit() {

    //  { path: 'selected/:index/:name', component: ChatItemComponent   }

  this.user = this.userObjectUpdateService.userEmail

  this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
    this.user = updatedUserEmail
    console.log('updated USER = ', this.user)
  })

this.isLoading = true 

  if ( this.user !== null ){
       const localDateNow = new Date()
       const localDateNow_String = this.datePipe.transform( localDateNow, "yyyy-MM-ddTHH:mm:ss")

       this.chatService.getLoggedInChats_FirstCall()

  }



  this.loggedInChatsAreHereSubscription = this.chatService.loggedInChatsAreHere
      .subscribe((chatAndInnerFormResults: ChatAndInnerFormResults)=>{

        this.loadLatestChats( chatAndInnerFormResults)
  })

  this.receiveClickFromChatFillFormServiceToUpdateListOrder = this.chatService.getUpdateToChatIndexList()
                  .subscribe(() => {
                    if ( this.user !== null ){  this.updateChatIndexList() }
                    
  })

  this.receiveClickFromCreateNewChatDialog = this.chatService.updateToNewChatIndexList$
                  .subscribe((updateToNewChatIndexList)=> {
                    if (this.user !== null ){ this.updateNewChatIndexList(updateToNewChatIndexList)}
                    
  })
  
  this.chatItemHasBeenDisplayed = this.chatService.getChatFromChatItemIsDisplayed()
                  .subscribe((recipientToShow: Recipient)=> {
                    if ( this.user !== null ){ this.aChatHasBeenDisplayed(recipientToShow) }
                     
  })

  };

  /**
   * interface ChatAndInnerFormResults{
  numberOfItems: number ,
  chats: Chat[],
  innerFormResults: InnerFormResultNumbers[]
}

interface InnerFormResultNumbers{
  numberOfFormresults: number,
  innerFormResults: FormResult[]
}
   * 
   */

  loadLatestChats(chatAndInnerFormResults: ChatAndInnerFormResults){

    console.log(chatAndInnerFormResults)
   
    if ( chatAndInnerFormResults.numberOfItems > 0){
         this.chatsExist = true 
 
         const innerChatFormResults: InnerChatFormResultNumbers[] = chatAndInnerFormResults.innerChatFormResults
         const rightPositionInnerChatFormResults: InnerChatFormResultNumbers[] = innerChatFormResults.sort((a,b) => {
               return <any> new Date(b.chat.updateDateAndTime) - <any> new Date(a.chat.updateDateAndTime)
         })

         this.innerChatFormResults = rightPositionInnerChatFormResults

  
          setTimeout(() => {
            this.isLoading = false
          }, 250);


    }

    if ( chatAndInnerFormResults.numberOfItems === 0){

      this.chatsExist = false 

      setTimeout(() => {
        this.isLoading = false
      }, 250);

    }

  }


  onSelectedChat(i: number){ 

    const selectedUserChatRoomId = this.innerChatFormResults[i].innerChatFormResults[0].userChatRoomId 
    const selectedGroupName = this.innerChatFormResults[i].innerChatFormResults[0].recipientResult.name

    const newSelectedChat: SelectedChat = { aChatHasBeenSelected: true , recipientSelected: this.innerChatFormResults[i].innerChatFormResults[0].recipientResult }
    this.chatService.onSelectedChat(newSelectedChat)

    this.updateChatsOnChoosenChat.emit(this.innerChatFormResults[i].innerChatFormResults[0].recipientResult)
     this.chatService.onInformChatComponent_AChatHasBeenSelected()
    
    this.router.navigate(['../groups/selected-group/', selectedUserChatRoomId, selectedGroupName   ], { relativeTo: this.route.parent })
  }

/**
 * data coming from chat item when is displayed 
 */
 aChatHasBeenDisplayed(recipientToShow: Recipient){

  if ( this.innerChatFormResults.findIndex(x=> x.chat._id === recipientToShow.chatRoomId ) === -1){
    console.log('NO ONE OF THE CURRENT CHATS IS SELECTED')
  }

  if ( this.innerChatFormResults.findIndex(x=> x.chat._id === recipientToShow.chatRoomId ) !== -1){
       const indexOfSelectedChat = this.innerChatFormResults.findIndex(x=> x.chat._id === recipientToShow.chatRoomId)
       this.innerChatFormResults[indexOfSelectedChat].chat.isSelected = true 

       this.updateChatsOnChoosenChat.emit(recipientToShow)
  }

 }

/**
 * update chatList index by reordering the list when we send a new message and re-claim the selected one
 * we will have to do the same when a new message arrive from other people . 
 */
  updateChatIndexList(){
    this.ngOnInit()

    // turn all chat.isSelected false again
    for (let i=0; i< this.chatService.getChats().length ; i++){
      this.chatService.getChats()[i].isSelected = false 
    }

    console.log('click is arrived')
   // let indexToBeFound = this.chatService.getChats().findIndex(x => x.chatId === this.currentSelectedChatId)
   // console.log(indexToBeFound)
    // this.chatService.getChat(indexToBeFound).isSelected = true 
    this.chatService.getChat(0).isSelected = true 
  }

  /**
   * reorder chat index list by adding the new created one
   */
  updateNewChatIndexList(_id: string){
    console.log(_id)
    this.ngOnInit()

     // turn all chat.isSelected false again
     for (let i=0; i< this.chatService.getChats().length ; i++){
      this.chatService.getChats()[i].isSelected = false 
    }


    let indexToBeFound = this.chatService.getChats().findIndex(x => x._id === _id)
    console.log(indexToBeFound)
    this.chatService.getChat(indexToBeFound).isSelected = true 
/**
 * send to ChatItem the selected id
 */
   this.onSelectedChat(indexToBeFound)


  }

  /**
   * update the list as above but on a subscription coming from the arrival of new formResults
   *  and new chats , or updated chats . 
   * everytime there is a http call for formResults there should also be a call for chats index . 
   */

  /**
  * unsubscribe submission
  */
   ngOnDestroy(): void {
    this.receiveClickFromChatFillFormServiceToUpdateListOrder.unsubscribe();
    this.receiveClickFromCreateNewChatDialog.unsubscribe()
    this.updateOnUserEmail.unsubscribe()
  }


}
