

    <div class="row" *ngIf="!aNumberOfIsSelected">
        <div class="col-xs-12">
            <mat-chip-list>
                <div *ngFor="let day of days; let i=index" >
                    <mat-chip *ngIf="i==0"
                              (click)="onTodaySelected()"
                              style="background-color: rgb(21, 248, 21) ; color: white ; width:150px "
                              type="button"> 
                              {{day}} 
                    </mat-chip>
                    <mat-chip *ngIf="i==1"
                              (click)="onYesterdaySelected()"
                              style="background-color: green ; color: white ; width:150px "
                              type="button"> 
                              {{day}} 
                    </mat-chip>
                    <mat-chip *ngIf="i==2"
                              (click)="onDaysAgoSelected()"
                              style="background-color: darkgreen ; color: white ; width:150px "
                              type="button"> 
                              {{day}} 
                    </mat-chip>
                </div>
            </mat-chip-list>
        </div>
    </div>
    <br>
    <div class="row" *ngIf="!aNumberOfIsSelected" >
        <div class="col-xs-12">
            <mat-chip-list>
                <div *ngFor="let week of weeks; let i=index" >
                    <mat-chip *ngIf="i==0"
                              (click)="onThisWeekSelected()"
                              style="background-color: orange ; color: white ; width:150px "
                              type="button"> 
                              {{week}} 
                    </mat-chip>
                    <mat-chip *ngIf="i==1"
                              (click)="onLastWeekSelected()"
                              style="background-color: orangered ; color: white ; width:150px "
                              type="button"> 
                              {{week}} 
                     </mat-chip>
                     <mat-chip *ngIf="i==2"
                               (click)="onWeeksAgoSelected()"
                               style="background-color: darkred ; color: white ; width:150px "
                               type="button"> 
                               {{week}} 
                     </mat-chip>

                </div>
            </mat-chip-list>
        </div>
    </div>
    <br>
    <div class="row" *ngIf="!aNumberOfIsSelected">
        <div class="col-xs-12">
            <mat-chip-list>
                <div *ngFor="let month of months; let i=index" >
                    <mat-chip *ngIf="i==0"
                              (click)="onThisMonthSelected()"
                              style="background-color: rgb(1, 177, 235) ; color: white ; width:150px "
                              type="button"> 
                              {{month}} 
                    </mat-chip>
                    <mat-chip *ngIf="i==1"
                              (click)="onLastMonthSelected()"
                              style="background-color: blue ; color: white ; width:150px "
                              type="button"> 
                              {{month}} 
                    </mat-chip>
                    <mat-chip *ngIf="i==2"
                              (click)="onMonthsAgoSelected()"
                              style="background-color: darkblue ; color: white ; width:150px "
                              type="button"> 
                              {{month}} 
                    </mat-chip>
                </div>
            </mat-chip-list>
        </div>
    </div>

    <div class="row" *ngIf="aNumberOfIsSelected">
        <div class="col-xs-12">
            <form  [formGroup]="subjectEditForm" (ngSubmit)="onSendForm()">
        
                <mat-form-field class="full-width">

                <mat-label *ngIf="aNumberOfDaysIsSelected" style="color: grey;">INPUT NUMBER OF DAYS</mat-label>
                <mat-label *ngIf="aNumberOfWeeksIsSelected"  style="color: grey;">INPUT NUMBER OF WEEKS</mat-label>
                <mat-label *ngIf="aNumberOfMonthsIsSelected" style="color: grey;">INPUT NUMBER OF MONTHS</mat-label>
                  <input
                  autofocus
                  matInput 
                  type="number"
                  id="count"
                  formControlName="count"
                  maxlength="150">
                </mat-form-field>
            </form>
        </div>
    </div>
