import { Injectable } from "@angular/core";
import { SingleSlider } from "../single-slider.model";
import { InitialNullSetupService } from "src/app/services/starting-services/initial-null-setup.service";
import { Subject, Subscription } from "rxjs";
import { StoreFormItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-form-item-plus-childs-confirmation.service";
import { StoreParagraphItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-paragraph-item-plus-childs-confirmation.service";
import { StoreMultibaseItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-multiBase-item-plus-childs-confirmation.service";
import { StoreTabItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-tab-item-plus-childs-confirmation.service";


@Injectable ({
    providedIn: 'root' 
})


/**
 *  this.sliderName = 'market share'
    this.autoTicks = false;
    this.disabled = true;
    this.invert = false;
    this.max = 100;
    this.min = 0;
    this.showTicks = true;
    this.step = 1;
    this.thumbLabel = true;
    this.value = 55;
    this.vertical = false;
    this.tickInterval = 1;
 */

export class SingleSliderService {

    constructor( private initialNullSetupService: InitialNullSetupService ,
                 private storeFormItemPlusChildsConfirmationService: StoreFormItemPlusChildsConfirmationService , 
                 private storeParagraphItemPlusChildsConfirmationService: StoreParagraphItemPlusChildsConfirmationService ,
                 private storeMultibaseItemPlusChildsConfirmationService: StoreMultibaseItemPlusChildsConfirmationService , 
                 private storeTabItemPlusChildsConfirmationService: StoreTabItemPlusChildsConfirmationService
                 ){}

    singleSliders: SingleSlider[] = this.initialNullSetupService.initialNullSingleSliders

    initialPersonalSingleSlidersHaveBeenStored = new Subject<void>()


    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.singleSlidersChangedBackToNull
                                                     .subscribe((res)=> {
                                                         this.singleSliders = res 
                                                         console.log('updated singleSliders back to null = ', res)

    })



    getSingleSlider(i: number ){
        //  const singleSlider = this.singleSliders.find( 
        //     (s) => {
        //         return s._id === _id
        //     }
        // )
        return this.singleSliders[i]
    }


    getSingleSliders(){
        return this.singleSliders
    };

    getSingleSliderBy_id(_id: string ){
        const newSingleSlider: SingleSlider = this.singleSliders.find(
            (res)=> {
                return res._id === _id
            }
        )
        return newSingleSlider

    }

    addNew( singleSlider: SingleSlider){
        this.singleSliders.push(singleSlider)
        this.updateInitialComponents(this.singleSliders)
    }

    addNewSingleSliders(singleSliders: SingleSlider[]){
        for ( let i=0; i<singleSliders.length ; i++ ){
            if ( this.singleSliders.findIndex(x=> x._id === singleSliders[i]._id ) === -1 ){
                this.singleSliders.push(singleSliders[i])
            }
            if ( i === singleSliders.length-1){
                this.updateInitialComponents(this.singleSliders)
                console.log(this.singleSliders)
                this.initialPersonalSingleSlidersHaveBeenStored.next()
            }
        }
        // console.log(this.singleSliders)
        // this.initialPersonalSingleSlidersHaveBeenStored.next()
    }

    addNewSingleSlider(singleSliderToAdd: SingleSlider , isComingFromFormItemCall: boolean , isComingFromMultiFeatures: boolean ){

        if ( this.singleSliders.findIndex(x=>x._id === singleSliderToAdd._id) !== -1){ 
             const index = this.singleSliders.findIndex(x=>x._id === singleSliderToAdd._id)
             this.singleSliders[index] = singleSliderToAdd 
            this.updateInitialComponents(this.singleSliders)
        }

        if ( this.singleSliders.findIndex(x=>x._id === singleSliderToAdd._id) === -1){ 
            this.singleSliders.push(singleSliderToAdd) 
            this.updateInitialComponents(this.singleSliders)
        }

        if ( isComingFromFormItemCall === true  ){ this.storeFormItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenFormItemPlusChildsAreStored( singleSliderToAdd._id) }
        if ( isComingFromFormItemCall === false ){ this.storeParagraphItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenParagraphItemPlusChildsAreStored( singleSliderToAdd._id) }
        if ( isComingFromMultiFeatures === true ){ this.storeMultibaseItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenMultiFeatureItemPlusChildsAreStored(singleSliderToAdd._id)} 
        if ( isComingFromMultiFeatures=== false ){ this.storeTabItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenTabItemPlusChildsAreStored( singleSliderToAdd._id) }
       
        console.log('SINGLESLIDERS = ',this.singleSliders)

    }

    updateInitialComponents(updatedSingleSliders: SingleSlider[]){
        this.initialNullSetupService.initialNullSingleSliders = updatedSingleSliders
    }

    ngOnDestroy(){
        this.updateInitialNullDatafromInitialNullSetupService.unsubscribe()

    }




}
