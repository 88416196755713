import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';

@Component({
  selector: 'app-calendar-edit',
  templateUrl: './calendar-edit.component.html',
  styleUrls: ['./calendar-edit.component.css']
})
export class CalendarEditComponent implements OnInit {

  user: string 

  oneDateCalendarEditForm: FormGroup;
  receivedDetails = this.editData.details

  constructor( private _fb: FormBuilder, 
               public dialogRef: MatDialogRef<CalendarEditComponent>,
               private userObjectUpdateService: UserObjectUpdateService ,
               @Inject(MAT_DIALOG_DATA) public editData: any ) { }

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail
    
    this.oneDateCalendarEditForm = new FormGroup({
      name: new FormControl ( this.editData.name, [Validators.required, Validators.maxLength(30)]),
      details: new FormArray (this.receivedDetails.map(item => {
        const group = this.initdetail();
        group.patchValue(item);
        return group ; 
      }))
    });

  }


  initdetail(){
    return new FormGroup ({
      description: new FormControl('', Validators.maxLength(25))
   //   date: (new Date())
    })
  }


  getDetails (oneDateCalendarEditForm){
    return oneDateCalendarEditForm.controls.details.controls
  }

  onAddDetails(){
    (<FormArray>this.oneDateCalendarEditForm.get('details')).push(this.initdetail())
  }

  onDeleteDetail(index: number){
    (<FormArray>this.oneDateCalendarEditForm.get('details')).removeAt(index)
  }


  onSubmit(){

    console.log(this.oneDateCalendarEditForm)

    if( this.oneDateCalendarEditForm.invalid) { return }

    this.dialogRef.close(this.oneDateCalendarEditForm.value),
    this.editData.value; 

  }

}
