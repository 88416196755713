import { Component, OnInit , Inject} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray} from '@angular/forms';
import { MatDialogRef , MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HeaderService } from 'src/app/header/header.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';

@Component({
  selector: 'app-word-hint-edit',
  templateUrl: './word-hint-edit.component.html',
  styleUrls: ['./word-hint-edit.component.css']
})
export class WordHintEditComponent implements OnInit {

  user: string 

  wordHintEditForm: FormGroup;
  //  DetailsArray : FormArray;
  storageData =  this.editData.DetailsArray ;
  

  
   constructor( private _fb: FormBuilder, 
    private headerService: HeaderService,
    public dialogRef: MatDialogRef<WordHintEditComponent>,
    private userObjectUpdateService: UserObjectUpdateService ,
    @Inject(MAT_DIALOG_DATA) public editData: any) { }

    // this.createWordHintEditForm()

    // createWordHintEditForm(){
    //   this.wordHintEditForm = this._fb.group({
    //     name: [this.editData.name , Validators.compose([
    //       Validators.required, Validators.maxLength(50)
    //     ])],
    //    Details: this.DetailsArray
    //   })
    // }



  ngOnInit(){
    this.user = this.userObjectUpdateService.userEmail

      console.log("editData", this.editData )
     
       this.wordHintEditForm = new FormGroup({
         name : new FormControl (this.editData.name, [Validators.required, Validators.maxLength(50) ] ),
         details : new FormArray (this.storageData.map(item =>{
         const group = this.initdetail();
         group.patchValue(item);
         return group ;
         }))
         });
        this.wordHintEditForm.valueChanges.subscribe(
        (value) => console.log(value)
        );
  }



  
     initdetail(){
       return new FormGroup ({
           label: new FormControl ('', Validators.maxLength(25)),
           placeholder: new FormControl ('' , Validators.maxLength(25)),
           hint: new FormControl ('1234567890123456789012345', Validators.maxLength(500)),
         
     })   
    } 
  


    getDetails (wordHintEditForm){
      return  wordHintEditForm.controls.details.controls;
    }

    onAddDetails(){
      (<FormArray>this.wordHintEditForm.get('details')).push(this.initdetail());
    }

    onDeleteDetail(index:number ){
      (<FormArray>this.wordHintEditForm.get('details')).removeAt(index );
   }


  onSubmit(){
    console.log(this.wordHintEditForm);
    if (this.wordHintEditForm.invalid) {
      return;
    }
    this.dialogRef.close(this.wordHintEditForm.value),
    this.editData.value;
    this.headerService.nextComponentIndexSelection(false)
  }
  



}
