
export class ParagraphFeatureContainer {

    constructor( public _id: string, 
                 public componentName: string ,
                 public UIID: string,
                 public owner: string,
                 public allUsersAllowed: boolean , 
                 public userListAllowed: string[],
                 public dateAndTime: string , 
                 public isActive: boolean, 
                 public isAccepted: boolean, 
                 public name: string, // can be the name of the topic at sender 
                 public paragraphFeatureState: boolean , // visible or not is to change at every check
                 public paragraph_id: string 
    ){}
}