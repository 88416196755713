import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';


@Component({
  selector: 'app-multi-entry-calendar-edit',
  templateUrl: './multi-entry-calendar-edit.component.html',
  styleUrls: ['./multi-entry-calendar-edit.component.css']
})
export class MultiEntryCalendarEditComponent implements OnInit {

 
  user: string 
  multiEntryCalendarEditForm: FormGroup 

  constructor( public dialogRef: MatDialogRef<MultiEntryCalendarEditComponent>,
    private userObjectUpdateService: UserObjectUpdateService ,
               @Inject(MAT_DIALOG_DATA) public editData: any) { }

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

    this.multiEntryCalendarEditForm = new FormGroup ({
      name: new FormControl (this.editData.name, [Validators.required, Validators.maxLength(30)]),
      description: new FormControl (this.editData.description, [Validators.maxLength(50)])
    })
  }

  onSubmit(){
    if ( this.multiEntryCalendarEditForm.invalid) {return}

    this.dialogRef.close(this.multiEntryCalendarEditForm.value),
    this.editData.value
  }

}
