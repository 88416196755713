import { AfterViewInit, Component, EventEmitter, OnInit, Output , NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth-services/auth.service';
import { UserObject } from './user.model';
import { CredentialResponse, PromptMomentNotification } from 'google-one-tap';

declare var google: any

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})


export class AuthComponent implements OnInit, AfterViewInit {


  user: any 
  loggedIn: any 

  @Output() emitUserEmailEvent = new EventEmitter<string>()

  constructor( private router: Router ,
               private authService: AuthService , 
               private ngZone: NgZone 
              ) { }

  ngAfterViewInit(): void {
      
    
    // google.accounts.id.initialize({
    //   client_id: "331753095341-50c26hhhmhs7kvfho2d98bs691uvi7pv.apps.googleusercontent.com",
    //    callback: this.handleCredentialResponse
    //   //callback: this.authService.handleCredentialResponse
    // })
    // google.accounts.id.renderButton(
    //   document.getElementById("buttonDiv"),
    //   { theme: "outline", size: "large" }  // customization attributes
    // );
    // google.accounts.id.prompt(); // also display the One Tap dialog
  }

  ngOnInit() {

    
  
     
  }

  handleCredentialResponse(response: any){
    console.log(response , response.credential );
    console.log(this.router)
   
    // debugger 
    
    if ( response.credential ){

      sessionStorage.setItem('token', response.credential )

      console.log( 'CAN START SENDING SUBSCRIPTION TO AUTH AND PUT USER COMPONENTS AT WORK')

      var base64Url = response.credential.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      console.log(JSON.parse(jsonPayload))

      const userEmailToEmit: string = JSON.parse(jsonPayload).email
      console.log(userEmailToEmit)

      const newUserObject = new UserObject ( userEmailToEmit , 'nuovoUser1', 'token1', new Date() )

     // this.authService.onUpdateLoggedInUser(newUserObject)
    }


  }

  emitUserEmail( userEmailToEmit: string ){

    this.emitUserEmailEvent.emit(userEmailToEmit)
      
  }

 

}
