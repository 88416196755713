<div class="location-selector">
    <select [(ngModel)]="selectedCountry" (change)="onCountrySelected()">
      <option *ngFor="let country of countries" [value]="country">{{country}}</option>
    </select>
    <select [(ngModel)]="selectedState" (change)="onStateSelected()" [disabled]="!selectedCountry">
      <option *ngFor="let state of states" [value]="state">{{state}}</option>
    </select>
    <select [(ngModel)]="selectedCity" (change)="onCitySelected()" [disabled]="!selectedState">
      <option *ngFor="let city of cities" [value]="city">{{city}}</option>
    </select>
    <input type="number" [(ngModel)]="population" placeholder="Population">
  </div>

<!-- <div class="location-selector">
    <select [(ngModel)]="selectedCountry" (change)="onCountrySelected()">
      <option *ngFor="let country of countries" [value]="country">{{country}}</option>
    </select>
    <select [(ngModel)]="selectedState" (change)="onStateSelected()">
      <option *ngFor="let state of states" [value]="state">{{state}}</option>
    </select>
    <select [(ngModel)]="selectedCity" (change)="onCitySelected()">
      <option *ngFor="let city of cities" [value]="city">{{city}}</option>
    </select>
    <input type="number" [(ngModel)]="population" placeholder="Population">
  </div> -->
