import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { FormResultsService } from '../../form-results.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions} from '@angular/material/tooltip';

/** Custom options the configure the tooltip's default show/hide delays. */
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 500,
  hideDelay: 100,
  touchendHideDelay: 1000,
};


interface GroupName {
  userChatRoomId: string
  name: string
}

interface AvailableChoice {
  name: string , 
  value: string 
}



@Component({
  selector: 'app-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.css'],
  providers: [{provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}],
})

export class FilterGroupComponent implements OnInit , OnDestroy {

  user: string 

  groupName: GroupName
  groupOptions: string[] = ['group message']

  availableChoices: AvailableChoice[]

  @Input() groupIsDeselected: boolean
  @Input() isSubmitted: boolean 

  groupNameEditForm : FormGroup 

  isLoadingForm: boolean = false

  groupResultSubscription: Subscription

  @Output() onGroupIsDeselected = new EventEmitter<boolean>()
  @Output() selectedAvailableChoice = new EventEmitter<GroupName>()

  selectedGroup: string 
  selectedUserChatRoomId: string  

  subscribeToUpdatedFormResultListIndex_GroupsNamesAndValue: Subscription
  updateOnUserEmail: Subscription

  constructor( private formResultsService: FormResultsService ,
               private cdr: ChangeDetectorRef ,
               private ngZone: NgZone ,
               private userObjectUpdateService: UserObjectUpdateService
    ) { }

  ngOnInit(): void {

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
    })

    /**
     * interface availableChoice {
  name: string , 
  userChatRoomId: string , 
  view: string 
}
     */
    this.availableChoices = []

    this.selectedGroup = ''  //this.availableChoices[1].name


    this.subscribeToUpdatedFormResultListIndex_GroupsNamesAndValue = this.formResultsService.communicateToGroupFilter_availableChoices
        .subscribe((res: AvailableChoice[])=>{
          this.ngZone.run(() => {
          this.availableChoices = res
          })
          this.cdr.detectChanges()
        })


  };

  /**
   * 
   * @param $event interface GroupName {
  userChatRoomId: string
  name: string
}
   */

  onSelectionChange($event: any ){

    const selectedIndex = this.availableChoices.findIndex(x=> x.value === $event.value)
    console.log($event.value  , $event , 'selectedIndex=> ', selectedIndex )
    const selectedUserChatRoomId = this.availableChoices[selectedIndex].value
    const selectedGroupname = this.availableChoices[selectedIndex].name 

    const newSelectedGroupName: GroupName = { userChatRoomId: selectedUserChatRoomId , name: selectedGroupname }

    this.selectedAvailableChoice.emit(newSelectedGroupName)
  }



  onSelectGroup(){
    if ( this.isSubmitted === false){
    this.groupIsDeselected = false 
    this.onGroupIsDeselected.emit(this.groupIsDeselected)
    }
  }

  onDeselectGroup(){
    if ( this.isSubmitted === false){
    this.groupIsDeselected = true 
    this.onGroupIsDeselected.emit(this.groupIsDeselected)
    }
  }

  ngOnDestroy(): void {
    this.subscribeToUpdatedFormResultListIndex_GroupsNamesAndValue?.unsubscribe()
    this.updateOnUserEmail?.unsubscribe()
  }

}
