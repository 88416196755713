<!-- <p>single-drop-down-select-result works!</p> -->

<mat-form-field style="width: 500px" appearance="outline">
    <mat-label> {{singleDropDownSelectDetailResult.descriptionResult}} </mat-label>
    <input
     disabled
     matInput
     ngModel="{{singleDropDownSelectDetailResult.contentResult}}"
     type="text" >

</mat-form-field>