import { Component, OnInit } from '@angular/core';
import { WordInputDetail } from '../../../input/word/word-input-detail.model';
import { WordInput } from '../../../input/word/word-input.model';

@Component({
  selector: 'app-qr-code-generator-edit',
  templateUrl: './qr-code-generator-edit.component.html',
  styleUrls: ['./qr-code-generator-edit.component.css']
})
export class QrCodeGeneratorEditComponent implements OnInit {

  myAngularQRcodeData: any

  constructor() { }

  ngOnInit(): void {

    this.myAngularQRcodeData = 'eco Ricky, ghe semo! il futuro xe qua !'
    
  }

}
