
<h2>{{rangeInfo.name}}</h2>

        <div *ngFor="let toggle of toggles; let i = index" >
            <mat-slide-toggle
                              (change)="onToggleChange($event.checked, i)"
                              color="primary">
                              {{ toggle.label }}
            </mat-slide-toggle>
        </div>
        <br>
        
        <div *ngFor="let toggle of toggles; let i = index" >
            <mat-slide-toggle (change)="onToggleChange($event.checked, i)"
                              [disabled]=true 
                              [ngModel]="toggle.checked"
                              [ngModelOptions]="{ standalone: true }"
                              color="primary">
                           
                              {{ toggle.label }}
            </mat-slide-toggle>
            <br>
        </div>
        <br>
        
          
 
