import { Component, OnInit } from '@angular/core';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';

@Component({
  selector: 'app-chat-comment-paragraph',
  templateUrl: './chat-comment-paragraph.component.html',
  styleUrls: ['./chat-comment-paragraph.component.css']
})
export class ChatCommentParagraphComponent implements OnInit {

  user: string 

  /**
   * export class ParagraphResult {

    constructor(
        public isRead: boolean,
        public isSent: boolean ,
        public isReceived: boolean ,
        public sender: Sender,
        public paragraphDateAndTime: ParagraphDateAndTime,
        public paragraphResultName: string ,
        public featureResults:FeatureResult[]
    ){}
   */

    /**
     * export class FeatureResult {

    constructor (
        public featureResultType: string,
        public featureResultName: string,
        public featureDetailResults: SingleFeatureDetailResult[]|
                                     ToggleWordButtonDetailResult[]|
                                     WordInputDetailResult[] | 
                                     WordDetailResult
    ){}
}
     */

  constructor(  private userObjectUpdateService: UserObjectUpdateService ) { }

  ngOnInit(): void {

    this.user = this.userObjectUpdateService.userEmail
  }

}
