import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { RangeDatesInfo } from '../range-dates-info.model';
import { RangeInfo } from '../range-info.model';
import { DialogmanagerService } from 'src/app/services/other-components-services/dialogmanager.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { InnerComponentEditBottomSheetComponent } from '../inner-component-edit-bottom-sheet/inner-component-edit-bottom-sheet.component';
import { Observable, Subject, Subscription } from 'rxjs';
import { SharedCalendarStatusService } from '../shared-calendar-status.service';
import { RangeInfoResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/shared-calendar-status-result/range-info-result.model';
import { InnerArraySelect } from '../inner-array-select.model';
import { FixTextDetail } from '../../../texts/fix-text-list/fix-text-detail.model';
import { SharedCalendarStatusResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/shared-calendar-status-result/shared-calendar-status-result.model';
import { FormService } from 'src/app/forms/form.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { GetComponentsHttpRequestService } from 'src/app/services/gets-services/get-components-http-request.sevice';
import { SharedCalendarStatus } from '../shared-calendar-status.model';
import { ErrorsDialogManagerService } from 'src/app/services/other-components-services/errors-dialog-manager.service';
import { take } from 'rxjs/operators';


interface IndexRangeInfo{
  index: number ,
  rangeInfo: RangeInfo
}

@Component({
  selector: 'app-shared-calendar-status-item-view',
  templateUrl: './shared-calendar-status-item-view.component.html',
  styleUrls: ['./shared-calendar-status-item-view.component.css']
})
export class SharedCalendarStatusItemViewComponent implements OnInit , OnDestroy {

  user: string 

  @Input() selectedRangeDatesInfo: RangeDatesInfo
  @Input() isParagraphEdit: boolean 

  @Input() changeSelectedDatesView: Observable<void>
  detectChangeOfSelectedDates: Subscription

  @Input() editJustAddedRangeInfo: Observable<void>
  editJustAddedRangeInfoSubscription: Subscription

  @Input() canModifyDateRange_date: boolean 
  @Input() sharedCalendarStatus_owner: string 


  // isLoading: boolean = false 

  changeSelectedDates = new Subject<void>()
  isChangingDate: boolean = false 

  @Input() selectedRangeDatesInfo_Index: number 
  @Input() sharedCalendarStatus_id: string 

  @Output() editRangeDateEvent = new EventEmitter<number>()
  @Output() updateRangeDatesInfo = new EventEmitter<any>()
  @Output() updateSharedCalendarStatusResult = new EventEmitter<any>()
  // @Output() emitUpdatedSelectedRangeDatesInfo_afterAdd = new EventEmitter<any>()
  @Output() emit_Update_SharedcalendarStatus = new EventEmitter<SharedCalendarStatus>()

  rangeInfoResults: RangeInfoResult[]
  sharedCalendarStatusResult: SharedCalendarStatusResult

  mailTextFeatureBodyPart: string

  requestToFinalizeResultAgain: Subscription
  updateOnUserEmail: Subscription

  additionalRangeInfo: RangeInfo
  isAddingNewRangeInfo: boolean = false 

  canView_CanUse_CanEdit: any[]
  


  constructor( private dms: DialogmanagerService ,
               private _bottomSheet: MatBottomSheet ,  
               private ngZone: NgZone, 
               private cdr: ChangeDetectorRef , 
               private sharedCalendarStatusService: SharedCalendarStatusService,
               private formService: FormService,
               private userObjectUpdateService: UserObjectUpdateService ,
               private getComponentsHttpRequestService: GetComponentsHttpRequestService , 
               private errorsDialogManagerService: ErrorsDialogManagerService
             ) {}


  ngOnInit(): void {

    console.log('selectedRangeDatesInfo',this.selectedRangeDatesInfo)

    this.user = this.userObjectUpdateService.userEmail
 
     this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
     .subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
     })

    this.additionalRangeInfo = this.sharedCalendarStatusService.createAdditionalRangeInfoDropdown()


    if ( !this.isParagraphEdit){

        // this.detectCanView_canUse_CanEdit_forEachRangeInfo()

        this.requestToFinalizeResultAgain = this.formService.getRequestToFinalizeResultAgain()
          .subscribe(()=> { 
            this.onEmitNewCalendarStatusResult_plus_NewmailTextBodyPart()
        })

        this.editJustAddedRangeInfoSubscription = this.editJustAddedRangeInfo
            .subscribe(()=> {
              // edit the last rangeInfo 
              console.log('editing last just added rangeInfo')
              const index = this.selectedRangeDatesInfo.rangeInfos.length-1
              const latestRangeInfo: RangeInfo = this.selectedRangeDatesInfo.rangeInfos[index]

              setTimeout(() => {
                this.onEditInnerComponent(latestRangeInfo, index)
              }, 500);

            })
       
        setTimeout(() => {
          this.onEmitNewCalendarStatusResult_plus_NewmailTextBodyPart()
        }, 300);
          
    }
    

    this.detectChangeOfSelectedDates = this.changeSelectedDatesView
        .subscribe(()=>{
          this.onChangeDateViews()
    })

  }



  // detectCanView_canUse_CanEdit_forEachRangeInfo(){

  //   this.canView_CanUse_CanEdit = []

  //   for ( let i=0; i< this.selectedRangeDatesInfo.rangeInfos.length; i++ ){

  //     let newcanViewIt: boolean 
  //     const viewNameList = this.selectedRangeDatesInfo.rangeInfos[i].usersAccess.canView.names
  //     if ( viewNameList.includes(this.user ) || this.sharedCalendarStatus_owner === this.user ){ newcanViewIt = true }
  //     if ( !viewNameList.includes(this.user ) && this.sharedCalendarStatus_owner !== this.user ){ newcanViewIt = false }

  //     let newcanUseIt: boolean 
  //     const useNameList = this.selectedRangeDatesInfo.rangeInfos[i].usersAccess.canUse.names
  //     if ( useNameList.includes(this.user ) || this.sharedCalendarStatus_owner === this.user ){ newcanUseIt = true }
  //     if ( !useNameList.includes(this.user ) && this.sharedCalendarStatus_owner !== this.user ){ newcanUseIt = false }

  //     let newcanEditIt: boolean 
  //     const editNameList = this.selectedRangeDatesInfo.rangeInfos[i].usersAccess.canEdit.names
  //     if ( editNameList.includes(this.user ) || this.sharedCalendarStatus_owner === this.user ){ newcanEditIt = true }
  //     if ( !editNameList.includes(this.user ) && this.sharedCalendarStatus_owner !== this.user ){ newcanEditIt = false }
       
  //     const newCanView_CanUse_CanEdit = { canViewIt: newcanViewIt , canUseIt: newcanUseIt, canEditIt: newcanEditIt}
  //     this.canView_CanUse_CanEdit.push(newCanView_CanUse_CanEdit)

  //    }
    
  // }

  onChangeDateViews(){
    
    this.isChangingDate = true 

    console.log('selectedRangeDatesInfo => ', this.selectedRangeDatesInfo)
    this.changeSelectedDates.next()

    this.ngZone.run(()=> {
      setTimeout(()=>{
        // this.detectCanView_canUse_CanEdit_forEachRangeInfo()
        console.log('canView_CanUse_CanEdit => ', this.canView_CanUse_CanEdit)
        this.isChangingDate = false
        this.onEmitNewCalendarStatusResult_plus_NewmailTextBodyPart()
      },200)
    })


    this.cdr.detectChanges()

  }



  isMobile(){
    // credit to Timothy Huang for this regex test: 
    // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        return true
   }
   else{
        return false
   }
  } 

  onEditInnerComponent(rangeInfo: RangeInfo , i: number ){

    console.log(this.selectedRangeDatesInfo)

    let newRangeInfo: RangeInfo 

    if ( rangeInfo.type === 'innerdropdown'){

      const newType = "innerdropdown"

      const newName = rangeInfo.name 
      const newUsersAccess = this.sharedCalendarStatusService.createUsersAccessClone_OfDeterminedRangeInfo(rangeInfo.usersAccess)
      const newRangeDetails: any[] = this.sharedCalendarStatusService.createRangeInfoDetailClone_OfDeterminedRangeInfo( newType, rangeInfo.rangeDetails )

      newRangeInfo = new RangeInfo (newType, newName, newUsersAccess, newRangeDetails)

  }

  if (rangeInfo.type === 'innercheckbox'){

      const newType = 'innercheckbox'
      const newName = rangeInfo.name 
      const newUsersAccess = this.sharedCalendarStatusService.createUsersAccessClone_OfDeterminedRangeInfo(rangeInfo.usersAccess)
      const newRangeDetails: any[] = this.sharedCalendarStatusService.createRangeInfoDetailClone_OfDeterminedRangeInfo( newType, rangeInfo.rangeDetails )

      newRangeInfo = new RangeInfo (newType, newName, newUsersAccess, newRangeDetails)

  }

  if ( rangeInfo.type === 'innertext'){
  
      const newType = 'innertext'
      const newName = rangeInfo.name 
      const newUsersAccess = this.sharedCalendarStatusService.createUsersAccessClone_OfDeterminedRangeInfo(rangeInfo.usersAccess)
      const newRangeDetails: any[] = this.sharedCalendarStatusService.createRangeInfoDetailClone_OfDeterminedRangeInfo( newType, rangeInfo.rangeDetails )

      newRangeInfo = new RangeInfo (newType, newName, newUsersAccess, newRangeDetails)

  }

  if ( rangeInfo.type === 'innerinput'){

      const newType = 'innerinput'
      const newName = rangeInfo.name 
      const newUsersAccess = this.sharedCalendarStatusService.createUsersAccessClone_OfDeterminedRangeInfo(rangeInfo.usersAccess)
      const newRangeDetails: any[] = this.sharedCalendarStatusService.createRangeInfoDetailClone_OfDeterminedRangeInfo( newType, rangeInfo.rangeDetails )

      newRangeInfo = new RangeInfo (newType, newName, newUsersAccess, newRangeDetails)

  }

    if ( this.isMobile() && !this.isParagraphEdit  ){ 
         console.log( newRangeInfo, i, 'opening bottom sheet')
         this.openBottomSheet(newRangeInfo, i)

    }
    if ( !this.isMobile() && !this.isParagraphEdit ){ 
          console.log( newRangeInfo, i, 'opening dialog')
          this.dms.innerComponentEditDialogComponent({
            rangeInfo: newRangeInfo , 
            index: i
          }).subscribe((res: IndexRangeInfo )=> {
            console.log(res)

            if ( res !== undefined){

              if ( res.rangeInfo !== null ){
              this.selectedRangeDatesInfo.rangeInfos[res.index] = res.rangeInfo 
              console.log('updated selected rangeDate infos => ', this.selectedRangeDatesInfo )

              const updatedSharedCalendarStatusItem = this.sharedCalendarStatusService.getSharedCalendarStatus_By_id(this.sharedCalendarStatus_id)
              const newSelectedRangeDatesInfo = this.selectedRangeDatesInfo
              const sharedCalendarStatus_id = this.sharedCalendarStatus_id
              this.updateRangeDatesInfo.emit( { updatedSharedCalendarStatusItem, sharedCalendarStatus_id,  newSelectedRangeDatesInfo} )

              this.onEmitNewCalendarStatusResult_plus_NewmailTextBodyPart()
              }

              if ( res.rangeInfo === null){

              this.selectedRangeDatesInfo.rangeInfos.splice(res.index, 1)
              console.log('updated selected rangeDate infos => ', this.selectedRangeDatesInfo )
 
              const updatedSharedCalendarStatusItem = this.sharedCalendarStatusService.getSharedCalendarStatus_By_id(this.sharedCalendarStatus_id)
              const newSelectedRangeDatesInfo = this.selectedRangeDatesInfo
              const sharedCalendarStatus_id = this.sharedCalendarStatus_id
              this.updateRangeDatesInfo.emit( { updatedSharedCalendarStatusItem, sharedCalendarStatus_id,  newSelectedRangeDatesInfo} )

              this.onEmitNewCalendarStatusResult_plus_NewmailTextBodyPart()

              }

              

            }
            if ( res === undefined){
              return
            }
          })
    }
  }

  

  openBottomSheet(rangeInfo: RangeInfo , i: number ){
   
   this._bottomSheet.open(InnerComponentEditBottomSheetComponent , {
     data: {
      rangeInfo: rangeInfo , 
      index: i
     }
    }).afterDismissed().subscribe((res) => {
      // Data received when the bottom sheet is dismissed
      console.log('Data received:', res);

      if ( res !== undefined){

        if ( res.rangeInfo !== null ){
          this.selectedRangeDatesInfo.rangeInfos[res.index] = res.rangeInfo 
          console.log('updated selected rangeDate infos => ', this.selectedRangeDatesInfo )

          const updatedSharedCalendarStatusItem = this.sharedCalendarStatusService.getSharedCalendarStatus_By_id(this.sharedCalendarStatus_id)
          const newSelectedRangeDatesInfo = this.selectedRangeDatesInfo
          const sharedCalendarStatus_id = this.sharedCalendarStatus_id
          this.updateRangeDatesInfo.emit( { updatedSharedCalendarStatusItem, sharedCalendarStatus_id,  newSelectedRangeDatesInfo} )

          this.onEmitNewCalendarStatusResult_plus_NewmailTextBodyPart()
          }

          if ( res.rangeInfo === null){

          this.selectedRangeDatesInfo.rangeInfos.splice(res.index, 1)
          console.log('updated selected rangeDate infos => ', this.selectedRangeDatesInfo )

          const updatedSharedCalendarStatusItem = this.sharedCalendarStatusService.getSharedCalendarStatus_By_id(this.sharedCalendarStatus_id)
          const newSelectedRangeDatesInfo = this.selectedRangeDatesInfo
          const sharedCalendarStatus_id = this.sharedCalendarStatus_id
          this.updateRangeDatesInfo.emit( { updatedSharedCalendarStatusItem, sharedCalendarStatus_id,  newSelectedRangeDatesInfo} )

          this.onEmitNewCalendarStatusResult_plus_NewmailTextBodyPart()

          }
      }
      if ( res === undefined){
        return
      }


    });
    
  }

  onEditRangeDate(){
    console.log('is editing rangeDate index => ', this.selectedRangeDatesInfo_Index)
    this.editRangeDateEvent.emit(this.selectedRangeDatesInfo_Index)
  }

 
  buildInitialSharedCalendarStatusResult():SharedCalendarStatusResult{

    const newStart = this.selectedRangeDatesInfo.dateStartEnd.dateStart
    const newEnd = this.selectedRangeDatesInfo.dateStartEnd.dateEnd
    const newUsersAccessResult: string[] = [] 
    //       for ( let z=0; z<this.selectedRangeDatesInfo.usersAccess.canView.names.length; z++ ){
    //         newUsersAccessResult.push(this.selectedRangeDatesInfo.usersAccess.canView.names[z] )
    //       }
    const newRangeInfoResults: RangeInfoResult[] = [] 
    // if ( !newUsersAccessResult.includes(this.sharedCalendarStatus_owner)){ newUsersAccessResult.push(this.sharedCalendarStatus_owner)}
    

    this.mailTextFeatureBodyPart = ''
    this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent( newStart )+ '%0D%0A';
    this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent( newEnd )+ '%0D%0A%0D%0A';


    for ( let i=0; i< this.selectedRangeDatesInfo.rangeInfos.length; i++ ){

    // if ( this.canView_CanUse_CanEdit[i].canViewIt ){
       const newType = this.selectedRangeDatesInfo.rangeInfos[i].type 
       const newName = this.selectedRangeDatesInfo.rangeInfos[i].name 
       const newInnerUsersAccessResult: string[] = []
    //         for ( let y=0; y< this.selectedRangeDatesInfo.rangeInfos[i].usersAccess.canView.names.length; y++ ){
    //               newInnerUsersAccessResult.push(this.selectedRangeDatesInfo.rangeInfos[i].usersAccess.canView.names[y])
    //         }

    //   if ( !newInnerUsersAccessResult.includes(this.sharedCalendarStatus_owner)){newInnerUsersAccessResult.push(this.sharedCalendarStatus_owner)}

      this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent('*****')+'%20%20' 
      this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent( this.selectedRangeDatesInfo.rangeInfos[i].name )+'%20%20'
      this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent('*****')+'%0D%0A';
        
         if ( this.selectedRangeDatesInfo.rangeInfos[i].type === 'innerdropdown' ){
             
                let newRangeDetailResults: string[] = [] 

                if ( this.selectedRangeDatesInfo.rangeInfos[i].rangeDetails.findIndex(x=> x.selected === true ) !== -1 ){

                     const index = this.selectedRangeDatesInfo.rangeInfos[i].rangeDetails.findIndex(x=> x.selected === true )
                     const newInnerArraySelect: any = this.selectedRangeDatesInfo.rangeInfos[i].rangeDetails[index]
                     const newDropdownInnerArraySelect: InnerArraySelect = newInnerArraySelect
                     const newDropdownInnerArraySelectName: string[] = [newDropdownInnerArraySelect.name]
                     
                     newRangeDetailResults = newDropdownInnerArraySelectName 
                     this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent( newDropdownInnerArraySelect.name )+ '%0D%0A';

                }

         const newRangeInfoResult = new RangeInfoResult ( newType, newName, newInnerUsersAccessResult, newRangeDetailResults   )
         newRangeInfoResults.push(newRangeInfoResult)
         this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+ '%0D%0A%0D%0A';
               
         }

         if ( this.selectedRangeDatesInfo.rangeInfos[i].type === 'innercheckbox' ){

              const newInnerArraySelects: any = this.selectedRangeDatesInfo.rangeInfos[i].rangeDetails 
              const newCheckboxInnerArraySelects: InnerArraySelect[] = newInnerArraySelects

              const newRangeDetailResults: InnerArraySelect[] = newCheckboxInnerArraySelects

              const newRangeInfoResult = new RangeInfoResult ( newType, newName, newInnerUsersAccessResult, newRangeDetailResults   )
              newRangeInfoResults.push(newRangeInfoResult)

              for ( let y=0; y<newRangeDetailResults.length ; y++ ){

                if ( newRangeDetailResults[y].selected ){
                  this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent( newRangeDetailResults[y].name  )+ '%0D%0A';
                }
                
              }

              this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+ '%0D%0A%0D%0A';

              

         }


         if ( this.selectedRangeDatesInfo.rangeInfos[i].type === 'innertext' ){

              const newFixTextDetails: any = this.selectedRangeDatesInfo.rangeInfos[i].rangeDetails

              const newTextFixTextDetails: FixTextDetail[] = newFixTextDetails
              const newRangeDetailResults: string[] = []

              for ( let i=0; i<newTextFixTextDetails.length ; i++ ){

                const newName = newTextFixTextDetails[i].text 
                const newInnerArraySelect = newName

                newRangeDetailResults.push(newInnerArraySelect)
                this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent(newTextFixTextDetails[i].text )+ '%0D%0A';

              }

              const newRangeInfoResult = new RangeInfoResult ( newType, newName, newInnerUsersAccessResult, newRangeDetailResults   )
              newRangeInfoResults.push(newRangeInfoResult)

              this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+ '%0D%0A%0D%0A';

          
         }

         if ( this.selectedRangeDatesInfo.rangeInfos[i].type === 'innerinput' ){

          const newFixTextDetails: any = this.selectedRangeDatesInfo.rangeInfos[i].rangeDetails

          const newTextFixTextDetails: FixTextDetail[] = newFixTextDetails
          const newRangeDetailResults: string[] = []

            const newName = this.selectedRangeDatesInfo.rangeInfos[i].name
            const newInputText =  newTextFixTextDetails[3].text
            const newInnerArraySelect = newInputText

            newRangeDetailResults.push(newInnerArraySelect)

          const newRangeInfoResult = new RangeInfoResult ( newType, newName, newInnerUsersAccessResult, newRangeDetailResults   )
          newRangeInfoResults.push(newRangeInfoResult)

          this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent(newTextFixTextDetails[3].text )+ '%0D%0A';
          this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+ '%0D%0A%0D%0A';
      
        }
    // }

    }

    const newSharedCalendarStatusResult = new SharedCalendarStatusResult( newStart, newEnd, newUsersAccessResult, newRangeInfoResults)


    return   newSharedCalendarStatusResult
  }


  sendingSharedCalendarStatusHttpRequest: boolean = false 
  isGettingFirstResponse: boolean = false 
  onEmitSelectedValues($event){

    console.log('rangeInfo => ',$event )

    // check if the sharedcalendar is still the same as before , call http calendar _id 
    // check if the exact selectedRangeDatesInfo is the same particularly 
    // check if the the rangeInfo in particular has had changed 
    // if it's the same , then ok for the update and update the rest of the calendar as the new updated one 
    // if it's not the same just update overall caledar 

    if ( this.user !== null && !this.sendingSharedCalendarStatusHttpRequest ){

    this.sendingSharedCalendarStatusHttpRequest = true 

    this.getComponentsHttpRequestService.httpGetUserComponentBy_id(this.user, this.sharedCalendarStatus_id)
        .pipe(
               take(1) // Take only one emitted value
         )
          .subscribe((res: SharedCalendarStatus )=> {
            console.log('updated sharedCalendarStatus => ',res) 


            const updatedSharedCalendarStatusItem = res
    // check and compare if the updatedSharedCalendarStatusItem.selectedRangedatesInfo stil, exist ( has the same date start date end )
            const present_sharedCalendarStatusItem = this.sharedCalendarStatusService.getSharedCalendarStatus_By_id(this.sharedCalendarStatus_id)

            const present_RangeDatesInfo = present_sharedCalendarStatusItem?.rangeDatesInfos[this.selectedRangeDatesInfo_Index]
            const updated_RangeDatesInfo = updatedSharedCalendarStatusItem?.rangeDatesInfos[this.selectedRangeDatesInfo_Index]
          
          if ( !this.areObjectsEqual(present_RangeDatesInfo, updated_RangeDatesInfo ) && !this.isGettingFirstResponse ){
                this.isGettingFirstResponse = true 
              console.log('somethings has changed in the meantime')
              this.emit_Update_SharedcalendarStatus.emit(updatedSharedCalendarStatusItem)
              this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'THE COMPONENT WAS UPDATING, TRY AGAIN NOW  '}) 
              
          }
          // if the rangeDatesInfo are still the same means nothing has been changed in this rangeDatesInfo and we can start changing 
          if ( this.areObjectsEqual(present_RangeDatesInfo, updated_RangeDatesInfo ) && !this.isGettingFirstResponse ){

            this.isGettingFirstResponse = true 

            console.log( 'OBJECTS ARE EQUAL SO => ', $event, this.selectedRangeDatesInfo_Index )
            this.selectedRangeDatesInfo.rangeInfos[$event.rangeInfo_index] = $event.newRangeInfo 
            console.log(this.selectedRangeDatesInfo)

            const newSelectedRangeDatesInfo = this.selectedRangeDatesInfo
            const sharedCalendarStatus_id = this.sharedCalendarStatus_id
            this.updateRangeDatesInfo.emit({ updatedSharedCalendarStatusItem, sharedCalendarStatus_id,  newSelectedRangeDatesInfo}) // add updatedSharedCalendarStatus so that is for sure aligned 

            this.onEmitNewCalendarStatusResult_plus_NewmailTextBodyPart()

          } 


          setTimeout(() => {
            this.sendingSharedCalendarStatusHttpRequest = false
            this.isGettingFirstResponse = false
          }, 1000);



    })
    }

    if (this.user === null ){

       const updatedSharedCalendarStatusItem = this.sharedCalendarStatusService.getSharedCalendarStatus_By_id(this.sharedCalendarStatus_id)

       console.log($event, this.selectedRangeDatesInfo_Index )
       this.selectedRangeDatesInfo.rangeInfos[$event.rangeInfo_index] = $event.newRangeInfo 
       console.log(this.selectedRangeDatesInfo)

       const newSelectedRangeDatesInfo = this.selectedRangeDatesInfo
       const sharedCalendarStatus_id = this.sharedCalendarStatus_id
       this.updateRangeDatesInfo.emit({ updatedSharedCalendarStatusItem, sharedCalendarStatus_id,  newSelectedRangeDatesInfo}) // add updatedSharedCalendarStatus so that is for sure aligned 

       this.onEmitNewCalendarStatusResult_plus_NewmailTextBodyPart()
    }



   

   
  }

  areObjectsEqual(obj1: any, obj2: any): boolean {

    console.log('obj1 => ',obj1)
    console.log('obj2 => ',obj2)


    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  onEmitNewCalendarStatusResult_plus_NewmailTextBodyPart(){

    // this.detectCanView_canUse_CanEdit_forEachRangeInfo()
    this.sharedCalendarStatusResult = this.buildInitialSharedCalendarStatusResult()

    const newSharedCalendarStatusResult = this.sharedCalendarStatusResult
    const newMailTextBodyPart = this.mailTextFeatureBodyPart

    this.updateSharedCalendarStatusResult.emit( { newSharedCalendarStatusResult, newMailTextBodyPart} )
  }

  // onGetInnerdropdownSelectedValue($event){
  //   console.log('adding new rangeInfo', $event )

  //   if ( this.user !== null ){
  //   this.getComponentsHttpRequestService.httpGetUserComponentBy_id(this.user, this.sharedCalendarStatus_id)
  //         .subscribe((res: SharedCalendarStatus )=> {
  //           console.log('updated sharedCalendarStatus => ',res) 

  //           const updatedSharedCalendarStatusItem = res

  //   // check and compare if the updatedSharedCalendarStatusItem.selectedRangedatesInfo stil, exist ( has the same date start date end )
  //         const present_sharedCalendarStatusItem = this.sharedCalendarStatusService.getSharedCalendarStatus_By_id(this.sharedCalendarStatus_id)
  //         const present_RangeDatesInfo = present_sharedCalendarStatusItem?.rangeDatesInfos[this.selectedRangeDatesInfo_Index]
  //         const updated_RangeDatesInfo = updatedSharedCalendarStatusItem?.rangeDatesInfos[this.selectedRangeDatesInfo_Index]
          
  //         if ( !this.areObjectsEqual(present_RangeDatesInfo, updated_RangeDatesInfo ) ){
  //           console.log('somethings has changed in the meantime')

  //           this.emit_Update_SharedcalendarStatus.emit(updatedSharedCalendarStatusItem)
  //           this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment:'THE COMPONENT WAS UPDATING, TRY AGAIN NOW '}) 
  //         }
  //         // if the rangeDatesInfo are still the same means nothing has been changed in this rangeDatesInfo and we can start changing 
  //         if ( this.areObjectsEqual(present_RangeDatesInfo, updated_RangeDatesInfo ) ){

  //   const selectedRangeInfo: RangeInfo = $event.newRangeInfo 
  //   const selectedType: number = selectedRangeInfo.rangeDetails.findIndex(x=> x.selected === true )
  //   this.isAddingNewRangeInfo = true 
  //   this.additionalRangeInfo = this.sharedCalendarStatusService.createAdditionalRangeInfoDropdown() 

  //   if ( selectedType === 0 ){ 
  //        console.log('is adding dropdowns') 

  //        const newRangeInfo = this.sharedCalendarStatusService.createNewInnerDropdown()

  //        this.selectedRangeDatesInfo.rangeInfos.push(newRangeInfo) 
  //        this.detectCanView_canUse_CanEdit_forEachRangeInfo()
  //        updatedSharedCalendarStatusItem.rangeDatesInfos[this.selectedRangeDatesInfo_Index] = this.selectedRangeDatesInfo

  //        const selectedRangeDatesInfo = this.selectedRangeDatesInfo
  //        const sharedCalendarStatus_id = this.sharedCalendarStatus_id
  //        this.emitUpdatedSelectedRangeDatesInfo_afterAdd.emit({ updatedSharedCalendarStatusItem, sharedCalendarStatus_id, selectedRangeDatesInfo})

  //   }

  //   if ( selectedType === 1 ){ 
  //        console.log('is adding checkboxes')

  //        const newRangeInfo = this.sharedCalendarStatusService.createNewInnerCheckbox()

  //        this.selectedRangeDatesInfo.rangeInfos.push(newRangeInfo)
  //        this.detectCanView_canUse_CanEdit_forEachRangeInfo()
  //        updatedSharedCalendarStatusItem.rangeDatesInfos[this.selectedRangeDatesInfo_Index] = this.selectedRangeDatesInfo
  //        const selectedRangeDatesInfo = this.selectedRangeDatesInfo
  //        const sharedCalendarStatus_id = this.sharedCalendarStatus_id
  //        this.emitUpdatedSelectedRangeDatesInfo_afterAdd.emit({ updatedSharedCalendarStatusItem, sharedCalendarStatus_id, selectedRangeDatesInfo})



  //   }

  //   if ( selectedType === 2 ){ 
  //        console.log('is adding texts') 

  //        const newRangeInfo = this.sharedCalendarStatusService.createNewInnerText()

  //        this.selectedRangeDatesInfo.rangeInfos.push(newRangeInfo)
  //        this.detectCanView_canUse_CanEdit_forEachRangeInfo()
  //        updatedSharedCalendarStatusItem.rangeDatesInfos[this.selectedRangeDatesInfo_Index] = this.selectedRangeDatesInfo
  //        const selectedRangeDatesInfo = this.selectedRangeDatesInfo
  //        const sharedCalendarStatus_id = this.sharedCalendarStatus_id
  //        this.emitUpdatedSelectedRangeDatesInfo_afterAdd.emit({updatedSharedCalendarStatusItem, sharedCalendarStatus_id, selectedRangeDatesInfo})
    

  //   }

  //   if ( selectedType === 3 ){ 
  //        console.log('is adding input') 

  //        const newRangeInfo = this.sharedCalendarStatusService.createNewInnerInput()

  //        this.selectedRangeDatesInfo.rangeInfos.push(newRangeInfo)
  //        this.detectCanView_canUse_CanEdit_forEachRangeInfo()
  //        updatedSharedCalendarStatusItem.rangeDatesInfos[this.selectedRangeDatesInfo_Index] = this.selectedRangeDatesInfo
  //        const selectedRangeDatesInfo = this.selectedRangeDatesInfo
  //        const sharedCalendarStatus_id = this.sharedCalendarStatus_id
  //        this.emitUpdatedSelectedRangeDatesInfo_afterAdd.emit({ updatedSharedCalendarStatusItem, sharedCalendarStatus_id, selectedRangeDatesInfo})
      


  //   }

  //   this.onEmitNewCalendarStatusResult_plus_NewmailTextBodyPart()

  //   setTimeout(() => {
  //     this.isAddingNewRangeInfo = false 
  //   }, 700);

  //         } // end if the rangeDatesInfo are the same statement


  //   }) // end subscription 
  //   }

  //   if ( this.user === null ){

  //     const updatedSharedCalendarStatusItem = this.sharedCalendarStatusService.getSharedCalendarStatus_By_id(this.sharedCalendarStatus_id) 

  //     console.log('updatedSharedCalendarStatusItem =>', updatedSharedCalendarStatusItem)

  //     // check and compare if the updatedSharedCalendarStatusItem.selectedRangedatesInfo stil, exist ( has the same date start date end )
  //           const present_RangeDatesInfo = this.selectedRangeDatesInfo
  //           const updated_RangeDatesInfo = updatedSharedCalendarStatusItem?.rangeDatesInfos[this.selectedRangeDatesInfo_Index]
  //           if ( !this.areObjectsEqual(present_RangeDatesInfo, updated_RangeDatesInfo ) ){
  //             console.log('somethings has changed in the meantime')
  
  //             this.emit_Update_SharedcalendarStatus.emit(updatedSharedCalendarStatusItem)
  //             this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment:'THE COMPONENT WAS UPDATING, TRY AGAIN NOW '}) 
  //           }
  //           // if the rangeDatesInfo are still the same means nothing has been changed in this rangeDatesInfo and we can start changing 
  //           if ( this.areObjectsEqual(present_RangeDatesInfo, updated_RangeDatesInfo ) ){
  
  //     const selectedRangeInfo: RangeInfo = $event.newRangeInfo
  //     const selectedType: number = selectedRangeInfo.rangeDetails.findIndex(x=> x.selected === true )
  //     this.isAddingNewRangeInfo = true 
  //     this.additionalRangeInfo = this.sharedCalendarStatusService.createAdditionalRangeInfoDropdown()
  
  //     if ( selectedType === 0 ){ 
  //          console.log('is adding dropdowns') 
  
  //          const newRangeInfo = this.sharedCalendarStatusService.createNewInnerDropdown()
  
  //          this.selectedRangeDatesInfo.rangeInfos.push(newRangeInfo)
  //          console.log( 'selectedRangeDatesInfo', this.selectedRangeDatesInfo , 'selectedRangeDatesInfo_Index => ', this.selectedRangeDatesInfo_Index)

  //          this.detectCanView_canUse_CanEdit_forEachRangeInfo()
  //         updatedSharedCalendarStatusItem.rangeDatesInfos[this.selectedRangeDatesInfo_Index] = this.selectedRangeDatesInfo
  
  //          const selectedRangeDatesInfo = this.selectedRangeDatesInfo
  //          const sharedCalendarStatus_id = this.sharedCalendarStatus_id
  //          this.emitUpdatedSelectedRangeDatesInfo_afterAdd.emit({ updatedSharedCalendarStatusItem, sharedCalendarStatus_id, selectedRangeDatesInfo})
  
  //     }
  
  //     if ( selectedType === 1 ){ 
  //          console.log('is adding checkboxes')
  
  //          const newRangeInfo = this.sharedCalendarStatusService.createNewInnerCheckbox()
  
  //          this.selectedRangeDatesInfo.rangeInfos.push(newRangeInfo)
  //          console.log( 'selectedRangeDatesInfo', this.selectedRangeDatesInfo , 'selectedRangeDatesInfo_Index => ', this.selectedRangeDatesInfo_Index , updatedSharedCalendarStatusItem)
  //          this.detectCanView_canUse_CanEdit_forEachRangeInfo()
  //          updatedSharedCalendarStatusItem.rangeDatesInfos[this.selectedRangeDatesInfo_Index] = this.selectedRangeDatesInfo

  //          console.log('updatedSharedCalendarStatusItem', updatedSharedCalendarStatusItem)
  //          const selectedRangeDatesInfo = this.selectedRangeDatesInfo
  //          const sharedCalendarStatus_id = this.sharedCalendarStatus_id
  //          this.emitUpdatedSelectedRangeDatesInfo_afterAdd.emit({ updatedSharedCalendarStatusItem, sharedCalendarStatus_id, selectedRangeDatesInfo})
  
  
  
  //     }
  
  //     if ( selectedType === 2 ){ 
  //          console.log('is adding texts') 
  
  //          const newRangeInfo = this.sharedCalendarStatusService.createNewInnerText()
  
  //          this.selectedRangeDatesInfo.rangeInfos.push(newRangeInfo)
  //          console.log( 'selectedRangeDatesInfo', this.selectedRangeDatesInfo , 'selectedRangeDatesInfo_Index => ', this.selectedRangeDatesInfo_Index, updatedSharedCalendarStatusItem)
  //          this.detectCanView_canUse_CanEdit_forEachRangeInfo()
  //         updatedSharedCalendarStatusItem.rangeDatesInfos[this.selectedRangeDatesInfo_Index] = this.selectedRangeDatesInfo
  //          const selectedRangeDatesInfo = this.selectedRangeDatesInfo
  //          const sharedCalendarStatus_id = this.sharedCalendarStatus_id
  //          this.emitUpdatedSelectedRangeDatesInfo_afterAdd.emit({updatedSharedCalendarStatusItem, sharedCalendarStatus_id, selectedRangeDatesInfo})
      
  
  //     }
  
  //     if ( selectedType === 3 ){ 
  //          console.log('is adding input') 
  
  //          const newRangeInfo = this.sharedCalendarStatusService.createNewInnerInput()
  
  //          this.selectedRangeDatesInfo.rangeInfos.push(newRangeInfo)
  //          console.log( 'selectedRangeDatesInfo', this.selectedRangeDatesInfo , 'selectedRangeDatesInfo_Index => ', this.selectedRangeDatesInfo_Index , updatedSharedCalendarStatusItem)
  //          this.detectCanView_canUse_CanEdit_forEachRangeInfo()
  //          updatedSharedCalendarStatusItem.rangeDatesInfos[this.selectedRangeDatesInfo_Index] = this.selectedRangeDatesInfo
  //          const selectedRangeDatesInfo = this.selectedRangeDatesInfo
  //          const sharedCalendarStatus_id = this.sharedCalendarStatus_id
  //          this.emitUpdatedSelectedRangeDatesInfo_afterAdd.emit({ updatedSharedCalendarStatusItem, sharedCalendarStatus_id, selectedRangeDatesInfo})
        
  
  
  //     }
  
  //     this.onEmitNewCalendarStatusResult_plus_NewmailTextBodyPart()
  
  //     setTimeout(() => {
  //       this.isAddingNewRangeInfo = false 
  //     }, 700);
  
  //           } // end if the rangeDatesInfo are the same statement

  //   }

  // }


ngOnDestroy(): void {
    this.detectChangeOfSelectedDates?.unsubscribe()
    this.requestToFinalizeResultAgain?.unsubscribe()
    this.editJustAddedRangeInfoSubscription?.unsubscribe()
    this.updateOnUserEmail?.unsubscribe()
}
 

}
