import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params  } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormService } from '../../form.service';

@Component({
  selector: 'app-form-reload',
  templateUrl: './form-reload.component.html',
  styleUrls: ['./form-reload.component.css']
})
export class FormReloadComponent implements OnInit {

  clickEventFromFormTopicIndexSubscription: Subscription
  receivedForm_id: string;

  constructor( private router: Router,
               private route: ActivatedRoute,
               private formService: FormService) {

                }

  ngOnInit(): void {

    this.clickEventFromFormTopicIndexSubscription= this.formService.changingForm_id.subscribe(()=>{
      this.saveChangingForm_idAsLatestSelectedForm_id();
     });
  }

  saveChangingForm_idAsLatestSelectedForm_id(){

    this.formService.changingForm_id.subscribe(receiveForm_id => this.receivedForm_id = receiveForm_id)
    console.log('latest form_id',this.receivedForm_id)

    console.log('IS REALODING');
    this.router.navigate([ '/form-list/personal-form' ,this.receivedForm_id], {relativeTo: this.route.parent});
    console.log('SENDING YOU BACK TO PREVIOUS FORM');
   
  };


  ngOnDestroy():void{
    this.clickEventFromFormTopicIndexSubscription.unsubscribe()

  }

}
