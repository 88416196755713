<!-- <p>single-slider-result works!</p> -->

<table >

    <tr>

      <!-- <td>
        <label id="example-name-label" class="example-name-label">{{ singleSliderDetailResult.detailName }}  </label>
      </td> -->

      <td>
        <span class="text-small">{{singleSliderDetailResult.min}}</span>
      </td>
     
      <td style="width: 500px;" >
        <input disabled 
               type="range" 
               min="singleSliderDetailResult.min" 
               [attr.max]="singleSliderDetailResult.max" 
               [(ngModel)]="singleSliderDetailResult.value"
               [value]="singleSliderDetailResult.value" >
        <!-- <mat-slider
        (change)="sliderChange($event)"
        class="example-margin"
        [disabled]="singleSliderDetailResult.disabled"
        [invert]="singleSliderDetailResult.invert"
        [max]="singleSliderDetailResult.max"
        [min]="singleSliderDetailResult.min"
        
        [step]="singleSliderDetailResult.step"
        [thumbLabel]="singleSliderDetailResult.thumbLabel"
        
        [ngModel]="singleSliderDetailResult.value"
        [vertical]="singleSliderDetailResult.vertical"
        aria-labelledby="example-name-label">
    </mat-slider> -->
    <!-- [tickInterval]="getSliderTickInterval()" -->
    <!--  [(ngModel)]="singleSliderDetailResult.value" -->
    <!-- [showTicks]="singleSliderDetailResult.showTicks" -->
    <!--  [tickInterval]="getSliderTickInterval()" -->
      </td>

      <td>
        <span class="text-small">{{singleSliderDetailResult.max}}</span>
      </td>

      <td>
        <label id="example-name-label" class="example-name-label">{{ singleSliderDetailResult.detailName }} :  </label>
      </td>

      <td>
        <label id="example-name-label" class="example-name-label"> {{ singleSliderDetailResult.value }}  </label>
      </td>

      <td>
        <label id="example-name-label" class="example-name-label"> {{ singleSliderDetailResult.unit }}  </label>
      </td>

    </tr>
  </table>
