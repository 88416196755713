<div>
    <label for="country">Country:</label>
    <select id="country" name="country" [(ngModel)]="selectedCountry" (change)="onChangeCountry()">
      <option value="import countries" selected>import countries</option>
      <option *ngFor="let country of data.countries" [value]="country">{{ country }}</option>
    </select>
    <input type="file" accept=".xlsx" #fileInput style="display: none;" (change)="onCountryFileSelected($event)">
     <button (click)="fileInput.click()">Select File</button>
  </div>
  
  <div *ngIf="selectedCountry !== 'import countries'">
    <label for="state">State:</label>
    <select id="state" name="state" [(ngModel)]="selectedState" (change)="onChangeState()">
      <option value="import states" selected>import states</option>
      <option *ngFor="let state of data.states[selectedCountry]" [value]="state">{{ state }}</option>
    </select>
   
    <input type="file" accept=".xlsx" #fileInput2 style="display: none;"(change)="onStateFileSelected($event)">
    <button (click)="fileInput2.click()">Select File</button>
  </div>
  
  <div *ngIf="selectedState !== 'import states'">
    <label for="city">City:</label>
    <select id="city" name="city" [(ngModel)]="selectedCity">
      <option value="import cities" selected>import cities</option>
      <option *ngFor="let city of data.cities[selectedCountry][selectedState]" [value]="city">{{ city }}</option>
    </select>
 
    <input type="file" accept=".xlsx" #fileInput3 style="display: none;" (change)="onCityFileSelected($event)">
    <button (click)="fileInput3.click()">Select File</button>
  </div>
  
