<p>fix-layout-edit works!</p>

<div class="row">
    <div class="col-xs-12">

        <form >
            <!-- [formGroup]="fixLayoutEditForm" -->

            <div class="row">
                <div class="col-xs-12">
               <mat-form-field style="width: 250px" appearance="outline">
                <mat-label>component name</mat-label>
                <input 
                matInput
                type="text"
                id="name"
                value="{{fixLayout.name}}"
                >
            </mat-form-field>
                
                    <mat-error >Please enter a valid name, maximum 30 letters </mat-error>
                  </div>
            </div>

            <br>

            <div class="row" >
                <div class="col-xs-12" >

                    <table>

                    <div *ngFor="let tr of fixLayout.fixLayoutTableTRs; let i=index" >
                       
                       <tr> 
                           <td style="padding-right: 1em;float:center">
                               <button class="btn btn-sm" style="background-color: red; color: white;">delete line</button>
                           </td>
                            <td
                            *ngFor="let td of tr.fixLayoutTableTDs; let i=index" 
                            style="padding-right: 4em;float:center">

                            <!--  constructor( public tableTDType: string , public tableTDDetailString: string,
                                              public tableTDDetailNumber: number,
                                              public tableTDDetailDescription: string,
                                              public tableTDDetailChartShare: number,
                                              public tableTDDetailChartTotal: number,  ){} -->
                          
                             <div [ngSwitch]="td.tableTDType" >
    
                                   <div *ngSwitchCase="'string'" >
                                   <mat-form-field   appearance="outline" class="example-full-width">
                                   <input   style="color:black" matInput value="{{td.tableTDDetailString}}">
                                   </mat-form-field>
                                   </div>
 
                                   <div *ngSwitchCase="'number'" >
                                   <mat-form-field appearance="none" class="example-full-width">
                                   <!-- <mat-label>number</mat-label> -->
                                   <input  value="{{td.tableTDDetailNumber}}">
                                   <input matInput value="{{td.tableTDDetailNumberLabel}}">
                                   </mat-form-field>
                                   </div>

                                   <div *ngSwitchCase="'description'" >
                                    <mat-form-field appearance="none" class="example-full-width">
                                    <!-- <mat-label>number</mat-label> -->
                                    <input matInput value="{{td.tableTDDetailDescriptionLabel}}">
                                    <input matInput value="{{td.tableTDDetailDescription}}">
                                    </mat-form-field>
                                    </div>
 
                                   <div *ngSwitchCase="'chart'" style="padding-right: 8em;float:center"  >
                                   <div>
                                     <button class="btn btn-danger">{{td.tableTDDetailChartShare}}</button>
                                     <button class="btn btn-danger">{{td.tableTDDetailChartTotal}}</button>
                                   </div>
                                   </div>
 
                             </div>
                            
                            </td>

                            <td  style="padding-right: 1em;float:center">
                                <button mat-mini-fab color="warn" >delete</button>
                            </td>

                            <td style="padding-right: 1em;float:center">
                                <button mat-mini-fab color="primary"> +</button>
                            </td>
                            <td style="padding-right: 1em;float:center">
                                <button mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
                                   
                                      <svg style="color: white ;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-blockquote-right" viewBox="0 0 16 16">
                                        <path d="M2.5 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm0 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm0 3a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1h-6zm0 3a.5.5 0 0 0 0 1h11a.5.5 0 0 0 0-1h-11zm10.113-5.373a6.59 6.59 0 0 0-.445-.275l.21-.352c.122.074.272.17.452.287.18.117.35.26.51.428.156.164.289.351.398.562.11.207.164.438.164.692 0 .36-.072.65-.216.873-.145.219-.385.328-.721.328-.215 0-.383-.07-.504-.211a.697.697 0 0 1-.188-.463c0-.23.07-.404.211-.521.137-.121.326-.182.569-.182h.281a1.686 1.686 0 0 0-.123-.498 1.379 1.379 0 0 0-.252-.37 1.94 1.94 0 0 0-.346-.298zm-2.168 0A6.59 6.59 0 0 0 10 6.352L10.21 6c.122.074.272.17.452.287.18.117.35.26.51.428.156.164.289.351.398.562.11.207.164.438.164.692 0 .36-.072.65-.216.873-.145.219-.385.328-.721.328-.215 0-.383-.07-.504-.211a.697.697 0 0 1-.188-.463c0-.23.07-.404.211-.521.137-.121.327-.182.569-.182h.281a1.749 1.749 0 0 0-.117-.492 1.402 1.402 0 0 0-.258-.375 1.94 1.94 0 0 0-.346-.3z"/>
                                      </svg>
                                    
                                  </button>
                            </td>

                            <td style="padding-right: 1em;float:center">
                                <button mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
                                    <svg style="color: white;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-body-text" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M0 .5A.5.5 0 0 1 .5 0h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 0 .5Zm0 2A.5.5 0 0 1 .5 2h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5Zm9 0a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5Zm-9 2A.5.5 0 0 1 .5 4h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5Zm5 0a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5Zm7 0a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5Zm-12 2A.5.5 0 0 1 .5 6h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5Zm8 0a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5Zm-8 2A.5.5 0 0 1 .5 8h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5Zm7 0a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5Zm-7 2a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5Zm0 2a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5Zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5Z"/>
                                      </svg>
                                  </button>
                            </td>
                            <td style="padding-right: 1em;float:center">
                                <button mat-mini-fab color="primary" >
                                    <svg style="color:white" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bar-chart-line-fill" viewBox="0 0 16 16">
                                        <path d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2z"/>
                                      </svg>
                                  </button>
                            </td>
                           
                        </tr>
         
                    </div> 

                    <tr>

                        <td >
                            <button class="btn btn-primary btn-sm "> add detail line</button>
                        </td>

                    </tr>    


                    </table>

                    <br>


                </div>
            </div>




        </form>



    </div>
</div>
