<div *ngIf="!(isHandset$ | async)" class="ParagraphComponentFrame"  style="width: 75vw;" >


    <div class="col-12">
 
     <div class="row">
         about this component
     </div>
 
     <br>
 
         <div class="row">
 
 
             <div class="col-6">
                 <div class="row">
                     <div class="col-12">
                         edit
                     </div>
                 </div>
                 <br>
                 <div class="row">
                     <div class="col-9">
                         <mat-form-field class="full-width">
                             <mat-label>label</mat-label> 
                            <input matInput  maxLength="10" placeholder='example' >
                            <mat-hint > "hint"  </mat-hint>
                            <mat-hint align="end"> max length : 7/10  </mat-hint>
                          </mat-form-field>
 
                     </div>
                     <div class="col-3">
                         <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                             <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                           </svg>
                     </div>
                 </div>
 
             </div>
 
             <div class="col-6">
                 <div class="row">
                     <div class="col-12">
                        message result
                     </div>
                 </div>
                 <br>
                 <div class="row">
                     <div class="col-12">
                         <mat-form-field class="full-width">
                             <mat-label>country</mat-label> 
                            <input matInput  
                                   maxLength="10" 
                                   value="Italy" 
                                   readonly >
                            <mat-hint > "european"  </mat-hint>
                            <mat-hint align="end"> length : 6/25  </mat-hint>
                          </mat-form-field>
 
                     </div>
                  
                 </div>
                 
             </div>
 
          
         </div> 
 
     </div> 
    
   
 
    
 </div>
 
 
 <!---------- START MOBILE WAY  -->
 
 <div *ngIf="isHandset$ | async" class="ParagraphComponentFrame"  style="width: 75vw;" >
 
  
     <div class="col-12">
  
      <div class="row d-flex justify-content-center align-items-center">
          about this component
      </div>
  
      <br>
  
  
              <div class="ParagraphComponentFrame">
                  <div class="row">
                      <div class="d-flex justify-content-center align-items-center">
                          edit
                      </div>
                  </div>
                  <br>
                  <div class="row">
                      <div class="col-12">
                          <mat-form-field class="full-width">
                              <mat-label>label</mat-label> 
                             <input matInput  maxLength="10" placeholder='example' >
                             <mat-hint > "hint"  </mat-hint>
                             <mat-hint align="end"> max length : 7/10  </mat-hint>
                           </mat-form-field>
  
                      </div>
                  </div>
  
              </div>
  
              <br>
          
 
 
              <div class="ParagraphComponentFrame">
                  <div class="row">
                      <div class="d-flex justify-content-center align-items-center">
                         message result
                      </div>
                  </div>
                  <br>
                  <div class="row">
                      <div class="col-12">
                          <mat-form-field class="full-width">
                              <mat-label>country</mat-label> 
                             <input matInput  
                                    maxLength="10" 
                                    value="Italy" 
                                    readonly >
                             <mat-hint > "european"  </mat-hint>
                             <mat-hint align="end"> length : 6/25  </mat-hint>
                           </mat-form-field>
  
                      </div>
                  </div>
                  
              </div>
 
              <br>
           
      </div> 
     
    
  
     
  </div>
