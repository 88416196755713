import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

interface InputLabelInterface{

  exist: boolean ,
  name: string,
  maxLength: number,
  errorHandler: boolean 
}

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.css']
})
export class TextInputComponent implements OnInit {

  commentTextForm: FormGroup

  @Input() inputLabelInterface: InputLabelInterface
  @Input() inputName: string
  @Input() initialPlaceholder: string 

  @Input() canUseIt: boolean


  changingValuesSubscription: Subscription 

  @Output() updatedNameEvent = new EventEmitter<string>()
  @Output() updateNameInputValidity = new EventEmitter<boolean>()
 

  constructor() { }

  ngOnInit(): void {

    console.log(this.inputName)


    this.commentTextForm = new FormGroup({
      textInput: new FormControl ( { value: this.inputName, disabled: !this.canUseIt },  [ Validators.required, Validators.maxLength(this.inputLabelInterface.maxLength)])
    })

    this.changingValuesSubscription = this.commentTextForm.valueChanges.subscribe(
      (value) => {
        console.log(value)
        this.onUpdateNameEvent(value.textInput)
      }
    );

  }

  onUpdateNameEvent(updatedname: string){
    this.updatedNameEvent.emit(updatedname)
    if ( this.commentTextForm.valid ) { this.updateNameInputValidity.emit(true) }
    if ( !this.commentTextForm.valid ){ this.updateNameInputValidity.emit(false) }

  }

  onSubmit(){
    console.log(this.commentTextForm.value.name )
  }

  onInputFocus(){
    console.log( 'it s initializing typing on textInput')
    
  }

  ngOnDestroy(): void {
    this.changingValuesSubscription.unsubscribe()
  }

}
