<div class="row">
    <div class="col-xs-12">

      <br>

        <form [formGroup]="commentTextForm" (onSubmit)="onSubmit()">
          
            <mat-form-field class="full-width" appearance="outline">
              <mat-label *ngIf="inputLabelInterface.exist">{{inputLabelInterface.name}}</mat-label>
              <textarea matInput
                        #textComment
                        [maxlength]="inputLabelInterface.maxLength"
                        type="text"
                        id="textComment"
                        formControlName="textInput"
                        [placeholder]="initialPlaceholder"
                        style="height: auto;"
                        (focus)="onInputFocus()">
              </textarea>
              <span matSuffix class="smaller-hint">{{textComment.value.length}} / {{inputLabelInterface.maxLength}}</span>
              <mat-error *ngIf="!commentTextForm.get('textInput').valid && inputLabelInterface.errorHandler" >Please enter a valid name, maximum {{inputLabelInterface.maxLength}} letters </mat-error>
            </mat-form-field>
          </form>


    </div>
</div>
