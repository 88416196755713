import { Injectable } from '@angular/core';
import { MatDialog , MatDialogConfig , MatDialogRef } from '@angular/material/dialog'; 
import { Observable, Subscription } from 'rxjs' ; 
import { PopUpErrorDialogComponent } from '../../loading-spinner/pop-up-error-dialog/pop-up-error-dialog.component';
import { QuickCommunicationPopUpComponent } from 'src/app/loading-spinner/quick-communication-pop-up/quick-communication-pop-up.component';
import { LoginComponent } from 'src/app/login/login.component';
import { LoginService } from 'src/app/login/login.service';

@Injectable ({
    providedIn: 'root' 
})

export class ErrorsDialogManagerService {

    isDialogOpen: boolean = false // Initialize a flag to track whether the popup is open

    


    constructor ( private dialog: MatDialog , 
                  private loginService: LoginService ){} 

    popUpLimitedGrouptestingInfo: Subscription = this.loginService.sendingLimitedGroupTestingInfo 
            .subscribe(()=> { 

             this.QuickCommunicationPopUpComponent({ quickComment: 'WE ARE CURRENTLY TESTING WITHIN A LIMITED GROUP' })
            })


    public PopUpErrorDialog (data: any=[]): Observable<any> {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'add-css-class';
        dialogConfig.disableClose = false ;
        dialogConfig.data = data;
        let dialogRef: MatDialogRef<PopUpErrorDialogComponent>;
        dialogRef = this.dialog.open( PopUpErrorDialogComponent, dialogConfig);
        return dialogRef.afterClosed();

    } 

    public QuickCommunicationPopUpComponent (data: any=[]): Observable<any> {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'add-css-class';
        dialogConfig.disableClose = false ;
        dialogConfig.data = data;
        let dialogRef: MatDialogRef<QuickCommunicationPopUpComponent>;
        dialogRef = this.dialog.open( QuickCommunicationPopUpComponent, dialogConfig);
        return dialogRef.afterClosed();

    } 
    // public QuickCommunicationPopUpComponent (data: any=[]): Observable<any> {
       
    // if (!this.isDialogOpen) {
    //         this.isDialogOpen = true;
       
    //     var dialogConfig = new MatDialogConfig();
    //     dialogConfig.panelClass = 'add-css-class';
    //     dialogConfig.disableClose = false ;
    //     dialogConfig.data = data;
    //     let dialogRef: MatDialogRef<QuickCommunicationPopUpComponent>;
    //     dialogRef = this.dialog.open( QuickCommunicationPopUpComponent, dialogConfig);
        
    //     dialogRef.afterClosed().subscribe(() => {
    //         this.isDialogOpen = false;
    //     });

    //     return dialogRef.afterClosed();
    //   } else {
    //   return new Observable(); // Return an empty observable if a dialog is already open
    // }

    // } 

    public LoginPopUpDialog(data: any=[]): Observable<any> {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'add-css-class';
        dialogConfig.disableClose = true ;
        dialogConfig.data = data;
        dialogConfig.autoFocus = false ;
        let dialogRef: MatDialogRef<LoginComponent>;
        dialogRef = this.dialog.open( LoginComponent, dialogConfig);
        return dialogRef.afterClosed();

    } 

    ngOnDestroy(){
        this.popUpLimitedGrouptestingInfo?.unsubscribe()
    }

}