import { Component, OnInit, Input, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FixText } from '../fix-text.model';
import { FixTextService } from '../fix-text.service' ;
import { FeatureResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/feature-result.model';
import { FixTextDetailResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/fix-text-result/fix-text-detail-result.model';
import { Subscription, Observable,  } from 'rxjs';
import { Feature } from '../../../feature.model';
import { DialogmanagerService } from 'src/app/services/other-components-services/dialogmanager.service';
import { MatDialogRef , MAT_DIALOG_DATA, _closeDialogVia } from '@angular/material/dialog';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { FormService } from 'src/app/forms/form.service';
import { HeaderService } from 'src/app/header/header.service';



@Component({
  selector: 'app-fix-text-item',
  templateUrl: './fix-text-item.component.html',
  styleUrls: ['./fix-text-item.component.css']
})
export class FixTextItemComponent implements OnInit , OnDestroy {

  user: string 

  fixText: FixText 

  index: number
  @Input() index_id: string
  @Input() isParagraphEdit: boolean
  @Input() feature: Feature

  @Input() isTabItem: boolean
  @Input() tab_index: number 

  featureResult: FeatureResult;
  fixTextDetailResult: FixTextDetailResult;
 

  /**
   * receiving the click from fixTextList to edit the component
   */
  @Input() subscriptionToEditComponentItemIsOn: boolean
  currentFeature: Feature
  @Input() editFeatureItem: Observable<void>;
  editFeatureEventFromWordInputList: Subscription; 


  mailTextFeatureBodyPart: string

  @Output() updateBothFeatureResultAndTextPlusIndex = new EventEmitter<any>()

  requestToFinalizeResultAgain: Subscription 
  updateOnUserEmail: Subscription 

  constructor( private fixTextService: FixTextService,
               private dms: DialogmanagerService,
               private formService: FormService,
               private dialogRef: MatDialogRef<FixTextItemComponent>,
               private userObjectUpdateService: UserObjectUpdateService ,
               private headerService: HeaderService,
               @Inject(MAT_DIALOG_DATA) public editData: any ) { }

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
        .subscribe((updatedUserEmail)=>{
         this.user = updatedUserEmail
         console.log('updated USER = ', this.user)
    })

    console.log('user is = ', this.user )

    this.fixText = this.fixTextService.getFixTextBy_id(this.index_id)

    console.log('feature = ', this.feature, this.fixText)

  
      this.requestToFinalizeResultAgain = this.formService.getRequestToFinalizeResultAgain().subscribe(()=> this.onSend())

      setTimeout(()=>{
        this.onSend()
      },200)

  


      if (this.isParagraphEdit === true){
    this.editFeatureEventFromWordInputList = this.editFeatureItem.subscribe((data)=> this.checkIfMatchId(data))
   }
  
  }


  onSend(){
    console.log('sending form')
    this.onSubmit()

  }


  onSubmit(){
    // prepare to build fixTextDetailResult + FeatureResult

    const newFeatureResultType = 'fixTextResult';
    const newFeatureResultName = this.fixText.name
    
    const newFeatureDetailResults : FixTextDetailResult[] = []

    for ( let i=0; i<this.fixText.details.length; i++){
      const newFixTextDetailResult = new FixTextDetailResult (this.fixText.details[i].text)

      newFeatureDetailResults.push(newFixTextDetailResult)
    }

    const newFeatureResult = new FeatureResult (newFeatureResultType, newFeatureResultName, newFeatureDetailResults )
    console.log(newFeatureResult)

    this.featureResult = newFeatureResult

    /**
     * now build mailTextFeatureBodyPart
     */
     this.mailTextFeatureBodyPart = ''
     for (let j=0; j<this.fixText.details.length; j++){
        this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent(this.fixText.details[j].text)+ '%0D%0A';
        console.log('mailTextFeatureBody part =>', this.mailTextFeatureBodyPart)
    }

    this.onUpdateFeatureResultEvent()
  }

  onUpdateFeatureResultEvent(){

    if ( this.isTabItem === true){ this.tab_index = this.tab_index }
    if ( this.isTabItem !== true){ this.tab_index = null }

        const newTab_index = this.tab_index

    const newFeatureDrop_Id = this.feature.dropId
    const newFeatureResult: FeatureResult = this.featureResult
    const newMailTextFeatureBodyPart = this.mailTextFeatureBodyPart

    this.updateBothFeatureResultAndTextPlusIndex.emit({ newTab_index ,newFeatureDrop_Id, newFeatureResult, newMailTextFeatureBodyPart})
    
  
  }


  checkIfMatchId(data){

    this.currentFeature = data ;
    console.log(this.currentFeature)

    if (this.currentFeature.feature_id === this.index_id){
      this.openDialogWordInputEdit()
    }
     
  }


  openDialogWordInputEdit(){
    this.dms.FixTextEdit({
      fixText: this.fixText ,
      name: this.fixText.name ,
      detailsArray: this.fixText.details
    }).subscribe(res => {
      console.log('the dialog was closed');
      if ( res !== undefined ){ 
          this.fixText.name = res.name 
          this.fixText.details = res.details 
          this.fixTextService.updateFixText(this.fixText)
        }
      if ( res === undefined ) { 
             /**
              * close the pulsing IS EDIT COMPONENT
              */
           this.headerService.nextComponentIndexSelection(false)
           return }

    })

  }


  ngOnDestroy(){

    this.updateOnUserEmail?.unsubscribe()
    this.editFeatureEventFromWordInputList?.unsubscribe()
    this.requestToFinalizeResultAgain?.unsubscribe()
  }





}
