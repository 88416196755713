<!-- <p>duplicate-feature-paragraph-form works!</p> -->

<button *ngIf="!isWritingNewname"
        class="btn btn-primary"
        (click)="onStartDuplicateParagraph()"> 
        duplicate feature + paragraph
</button>

<!-- <br *ngIf="isWritingNewname"> -->

<h2 *ngIf="isWritingNewname" 
    style="color:#3f51b5">assign a new paragraph's name 
</h2>

<br *ngIf="isWritingNewname">

<form *ngIf="isWritingNewname" [formGroup]="editFormName" (ngSubmit)="onSubmitName()">
    
    
        <div class="row">
            <div class="col-xs-12">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label *ngIf="inputLabelInterface.exist">{{inputLabelInterface.name}}</mat-label>
                    <input 
                    matInput
                    type="text"
                    id="name"
                    formControlName="name">
                </mat-form-field>
                <mat-error *ngIf="!editFormName.get('name').valid && inputLabelInterface.errorHandler" >Please enter a valid name, maximum {{inputLabelInterface.maxLength}} letters </mat-error>
            </div>
        </div>
    
    </form>




