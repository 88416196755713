   <div class="innerItemFrame2">
        <div class="col-xs-12">

            <div class="row">
                <h2>{{rangeInfo.name}}</h2>
            </div>

            <div *ngFor="let detail of rangeInfo.rangeDetails; let i=index">
               
                <div [ngSwitch]="detail.type"> 
             
                    <div *ngSwitchCase="'typeUrl'">
                        <div class="row">
                            <!-- <p style="color: blue"> url - {{detail.content}}</p> -->
                            <a href="${detail.content}"> url - {{detail.content}}</a>
                        </div>
                    </div>

                    <div *ngSwitchCase="'typeTelNumber'">
                        <div class="row">
                            <!-- <p style="color: blue"> url - {{detail.content}}</p> -->
                            <a href="tel:${detail.content}"> call tel  {{detail.content}}</a>
                        </div>
                    </div>

                    <div *ngSwitchCase="'typeWAMessage'">
                        <div class="row">
                        
                            <a href="tel:whatsapp://send?phone=${detail.content}"> WA message {{detail.content}}</a>
                
                        </div>

                    </div>

                    <div *ngSwitchCase="'typeWACall'">

                        <div class="row">
                            <a href="tel:whatsapp://call?phone=${detail.content}">Make a WhatsApp Phone Call phone= {{detail.content}}</a>
                        </div>
                        
                    </div>

                    <div *ngSwitchCase="'typeWAVideo'">
                        <div class="row">
                            <a href="https://wa.me/${detail.content}/?action=video">Make a WhatsApp Video Call {{detail.content}}</a>
                        </div>

                    </div>

                    
                </div>

            </div>

        </div>
    </div>

           




