<div *ngIf="isLoadingFullPage">

  <app-loading-spinner> </app-loading-spinner>

</div>


<div *ngIf="!isLoadingFullPage">

<mat-toolbar color="primary" class="mat-toolbar" >
</mat-toolbar>

<mat-toolbar color="primary" class="mat-toolbar">
<mat-toolbar-row color="primary">

  <!-- Mobile mode / return to chat index list view button "<" style on sub-toolbar header-->
  <div *ngIf="aChatHasBeenSelected">
   
    <div *ngIf="!aParagraphHasToBeChosen">
      <button
      (click)="onReturnToChatList()"
      type="button"
      mat-icon-button
      routerLink="../groups"
      routerLinkActive="activebutton"
      *ngIf="(isHandset$ | async) === true">
     
      <svg style="color:white" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
        <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
      </svg>
      </button>
    </div>
   
    <div *ngIf="aParagraphHasToBeChosen">
      <button
      *ngIf="isHandset$ | async"
      type="button"
      aria-label="Toggle sidenav"
      mat-icon-button
      (click)="onToggleIndexMenuMobile()"
      (click)="drawer.toggle()">
      <mat-icon style="color: white" aria-label="Side nav toggle icon"> menu </mat-icon>
      </button>
    </div>
    
  </div>

  <div  *ngIf="isHandset$ | async" >
    <a *ngIf="!aChatHasBeenSelected" class="navbar-brand">
      <span style="color:white" >
        <!-- Chats -->
        Groups
      </span>
    </a>
  </div>

    <div *ngIf="aChatHasBeenSelected">
      <!-- <div  [hidden]="!isOpenIndexMobile"> -->
        <div *ngIf="isOpenIndexMobile">
      <div *ngIf="aParagraphHasToBeChosen">
        <a *ngIf="isPersonal" 
           (click)="onSharedParagraphsMobile()" 
           [hidden]="(isHandset$ | async) === false"  
           mat-icon-button>
           <span style="color:white" 
                 class="mat-button-wrapper"> 
                 personal paragraphs
                 <svg style="color:white"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                </svg>
                </span>
          </a>

        <a *ngIf="isPublic" 
           (click)="onPersonalParagraphsMobile()" 
           [hidden]="(isHandset$ | async) === false"  
           mat-icon-button >
           <span style="color:white"
                 class="mat-button-wrapper"> 
                 public paragraphs 
                 <svg style="color:white"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                </svg>
            </span>
        </a>

        <a *ngIf="isShared" 
           (click)="onPreferredParagraphsMobile()" 
           [hidden]="(isHandset$ | async) === false"  
           mat-icon-button >
            <span style="color:white" 
                  matBadge="+" matBadgeColor="warn" matBadgeSize="small"   [matBadgeHidden]="false" 
                  class="mat-button-wrapper hide-text"> 
                  shared paragraphs 
            </span>
            <svg style="color:white"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
            </svg>
            <span>

            </span>
          </a>

          <a *ngIf="isPreferred" 
             (click)="onPublicParagraphsMobile()"
             
             [hidden]="(isHandset$ | async) === false"  
             mat-icon-button >
              <span style="color:white" 
                    class="mat-button-wrapper"> 
                    preferred paragraphs
                    <svg style="color:white"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                    </svg> 
              </span>
            </a>
      </div>
      </div>
    </div>

    <div *ngIf="isHandset$ | async">
      <div *ngIf="aChatHasBeenSelected">
        <a (click)="onShowRecipientDetails()"
                 *ngIf="showChatRecipientName">
                 <span  style="color:white" class="mat-button-wrapper">
                  {{recipient.name}}
                 </span>
        </a>
      </div>
    </div>
  
  <!-- ----------------------------------------------------------------- -->

   

  <button
  *ngIf="(isHandset$ | async) === false"
  type="button"
  aria-label="Toggle sidenav"
  mat-icon-button
  (click)="onToggleIndexMenu()"
  (click)="drawer.toggle()">
  <mat-icon style="color:white" aria-label="Side nav toggle icon"> menu </mat-icon>
  </button>

  
  <!-- <div [hidden]="!isOpenIndexDesktop" > -->
    <div *ngIf="isOpenIndexDesktop">
    <div *ngIf="isPersonal">
      <a (click)="onSharedParagraphsDesktop()" 
         [hidden]="(isHandset$ | async) === true"  
         class="navbar-brand" >
         <span style="color:white" 
               class="mat-button-wrapper">
                personal paragraphs 
                <svg style="color:white"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                </svg>
         </span>
    </a>
  
    </div>
  
    <div *ngIf="isPublic">
      <a (click)="onPersonalParagraphsDesktop()"  
         [hidden]="(isHandset$ | async) === true"  
          class="navbar-brand" >
          <span style="color:white" 
                class="mat-button-wrapper">
                 public paragraphs 
                 <svg style="color:white"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                </svg>
          </span>
    </a>
    </div>
   
    <div *ngIf="isShared">
      <a  
         (click)="onPreferredParagraphsDesktop()" 
         [hidden]="(isHandset$ | async) === true"  
         class="navbar-brand" >
         <span style="color:white" 
               matBadge="+" matBadgeColor="warn" matBadgeSize="small"  [matBadgeHidden]="false" 
               class="mat-button-wrapper hide-text"> 
               shared paragraphs 
         </span>
         <span>
          <svg style="color:white"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
            <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
          </svg>
         </span>
    </a>
    </div>

    <div *ngIf="isPreferred">
      <a 
         (click)="onPublicParagraphsDesktop()" 
         [hidden]="(isHandset$ | async) === true"  
         class="navbar-brand" >
         <span style="color:white" 
               class="mat-button-wrapper"> 
               preferred paragraphs 
               <svg style="color:white"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
              </svg>
         </span>
    </a>
    </div>
  </div>

      

                <span class="spacer"></span> 


    <a (click)="onCreateNewChatDialog()" 
     [hidden]="(isHandset$ | async) === true" class="navbar-brand" ><span style="color: #FFFFFF" class="mat-button-wrapper"> 
      <svg style="color: #FFFFFF" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
      </svg>    
      <!-- chat  -->
      group 
    </span></a>
               
                <div *ngIf="!aChatHasBeenSelected">
                  <button
                  (click)="onCreateNewChatDialog()" 
                  type="button"
                  mat-icon-button
                  *ngIf="(isHandset$ | async) === true">
                    <svg style="color: #FFFFFF" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                    </svg>    
        
                  </button>

                </div>

</mat-toolbar-row>
</mat-toolbar>

<!-- the whole mat sidenav-container is ON if it's not mobile status -->
<!-- *ngIf="(isHandset$ | async) === false" -->
<mat-sidenav-container [hasBackdrop]="false" class="container1">
    <mat-sidenav 
     #drawer 
    style="overflow-y: hidden;"
    class="sidenav" 
    [fixedInViewport]="true"
    [fixedTopGap]="130"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false">

           <mat-nav-list  style="overflow: hidden;"> 

            <div class="col">
              <div class="row">
             <!-- just show when is on mobile  -->
             <div *ngIf="!isLoading">
              <app-chat-paragraph-index-topic-list 
              *ngIf="(isHandset$ | async)=== false"
              style="overflow: hidden;"
              [sureIsMobile]="false"
              [isPersonal]="isPersonal"
              [isPublic]=  "isPublic"
              [isShared]=  "isShared"
              [isPreferred]= "isPreferred">
              <!-- [chat]="chat"
              [aChatHasBeenSelected]="aChatHasBeenSelected"
              [subscribeUpdatedChatSelected]="subscribeSelectedChat.asObservable()" -->
              </app-chat-paragraph-index-topic-list>
             </div>

              <!-- just show when is on desktop // main difference is "sureIsMobile:boolean"  -->
              <div *ngIf="!isLoading">
                <app-chat-paragraph-index-topic-list
                *ngIf="isHandset$ | async"
                style="overflow: hidden;"
                [sureIsMobile]="true"
                [isPersonal]="isPersonal"
                [isPublic]=  "isPublic"
                [isShared]=  "isShared"
                [isPreferred]= "isPreferred">
                <!-- 
                [chat]="chat"
                [aChatHasBeenSelected]="aChatHasBeenSelected"
                [subscribeUpdatedChatSelected]="subscribeSelectedChat.asObservable()" -->
                </app-chat-paragraph-index-topic-list>
              </div>

              </div>
            </div>

           </mat-nav-list>

  </mat-sidenav>

  <!-- right side nav  -->
  <mat-sidenav
  #drawer2 class="sidenav2" 
  [fixedInViewport]="true"
  [fixedTopGap]="130"
  [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
  [mode]="(isHandset$ | async) ? 'over' : 'side'"
  [opened]="(isHandset$ | async) === false"
  position="end">

  <!-- sideNav chat index list Desktop mode  -->
          <mat-nav-list> 
                      <app-chat-list-index 
                      (updateChatsOnChoosenChat)="onUpdateSelectedChat($event)">
                      </app-chat-list-index>
          </mat-nav-list>
  </mat-sidenav>

  <!-- ------------------------------------- -->

       <mat-sidenav-content style="background-color:rgb(242, 248, 250) ;">

           <!-- START ROUTER OUTLET TO SHOW CHAT ITEM  -->

           <router-outlet></router-outlet>
           
           <!-- END ROUTER OUTLET TO SHOW CHAT ITEM -->
       
<!-- sideNav content  desktop/ mobile CHAT ITEM mode -->

<div  *ngIf="(isHandset$ | async) === false" >
  <div *ngIf="!aChatHasBeenSelected" >
  <div class="row">
      <div class="col-xs-12">

          <div class="row">
              <div class="col-xs-12">
                  <br>
                  <br>
                  <br>
                  <br>
                  
              </div>
          </div>

          <div class="row">
             
                <div class="row">
                  <h1  align="center"  style="color: #3f51b5">
                       select group 
                      <svg style="color: #3f51b5" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                      </svg>
                  </h1>
              </div>

          </div>


      </div>
  </div>
</div>
</div>
       

                    <!-- START ROUTER OUTLET TO SHOW CHAT ITEM  -->

                    <!-- <router-outlet></router-outlet> -->
                     <!-- END ROUTER OUTLET TO SHOW CHAT ITEM -->

<!-- ------------ -->

<!-- SideNav content Mobile mode   -->
        <div  *ngIf="isHandset$ | async" >

          <app-chat-list-index *ngIf="!aChatHasBeenSelected"
          (updateChatsOnChoosenChat)="onUpdateSelectedChat($event)">
          </app-chat-list-index>
       

        </div>
<!-- ------------ -->


<a align="end" href="https://github.com/angular/components/blob/main/LICENSE"> Code licensed under an MIT-style License.</a>
        
       </mat-sidenav-content>  

     
</mat-sidenav-container>

</div>

