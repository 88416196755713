<!-- <p>greetings works!</p> -->

<form [formGroup]="greetingsEditForm" (ngSubmit)="onUpdateGreetings()">

        
    <mat-form-field class="full-width">
        <mat-label style="color: grey">GREETINGS</mat-label> 

        <input 
               matInput
               id="greetings" 
               formControlName="greetings"
               type="text"
               placeholder=" greetings:" >

    </mat-form-field>
   


</form>
