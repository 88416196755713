import { Injectable } from '@angular/core';
import { FeatureTopic } from './feature-topic.model';
import { FeatureIndexSelection } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-index-topic-list/feature-index-selection.model'
import { BehaviorSubject } from 'rxjs';

import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { Subscription } from 'rxjs';


@Injectable ({
    providedIn: 'root' 
})

export class FeatureTopicService {

  featureTopic : FeatureTopic ;

  feature_id : string ;

  constructor ( 
   private initialNullSetupService: InitialNullSetupService,
    ){}

 
     featureTopics : FeatureTopic[]  = this.initialNullSetupService.initialNullFeatureTopics


    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.featureTopicsChangeBackToNull
   .subscribe((res)=> {
          this.featureTopics = res 
          console.log('updated featureTopicss back to null = ', res)

      })
    




    getFeatureTopic(id: number){
        return this.featureTopics[id]
    };

    getFeatureTopics(){
      return this.featureTopics ;
    }

    getFeatureTopicBy_id(_id: string){
      const newFeatureTopic = this.featureTopics.find(
        (res)=> {
            return res._id === _id
        }
    )
    return newFeatureTopic
    }


/**
 * this serve to tell the paragraph Editis we are in paragraph edit / Tab edit or MultiFeature edit
 * and change the component index accordingly (ex: when in tab edit , no tab index ... 
 *  when in multiFeature edit => only single featur components are allowed )
 */
  startingIndexSelection: FeatureIndexSelection = new FeatureIndexSelection ( true, false)
  /**
   * change status of FeatureIndexTopic
   */
  private indexSelection = new BehaviorSubject<FeatureIndexSelection>(this.startingIndexSelection)
  sharedIndexSelection = this.indexSelection.asObservable()

  nextIndexSelection(indexSelection: FeatureIndexSelection){
    this.indexSelection.next(indexSelection)
  }

}