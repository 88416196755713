
<div class="row">
    <div class="col-1"></div>
    <div class="col-10">

        <div  class="InnerComponentFrame" *ngIf="!isParagraphEdit" >
            <div class="row">
                <div class="col-xs-12">
                    <!-- *ngIf="canModifyDateRange_date" -->
                    <svg (click)="onEditRangeDate()"
                     style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                     <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                </svg>
                </div>
            </div>
            <br>
            <div class="row" >
                <div class="col-xs-12">
                
                    <app-inner-date-range-item *ngIf="!isChangingDate"
                    [startingDate]="selectedRangeDatesInfo.dateStartEnd.dateStart"
                    [endingDate]="selectedRangeDatesInfo.dateStartEnd.dateEnd">
                    </app-inner-date-range-item>
                    <app-inner-date-range-item *ngIf="isChangingDate"
                    [startingDate]="selectedRangeDatesInfo.dateStartEnd.dateStart"
                    [endingDate]="selectedRangeDatesInfo.dateStartEnd.dateEnd">
                    </app-inner-date-range-item>
      
                </div>
            </div>
            </div>
        

            <div *ngIf="!isParagraphEdit">
            <div>
                <div *ngFor="let rangeInfo of selectedRangeDatesInfo.rangeInfos; let i=index">
        
                    <div [ngSwitch]="rangeInfo.type"> 
        
                    <div  *ngSwitchCase="'innerdropdown'">
                        <div class="InnerComponentFrame" *ngIf="!isChangingDate">
                            <!-- && canView_CanUse_CanEdit[i].canViewIt" -->
                            <div class="row" *ngIf="!isParagraphEdit">
                                <div class="col-xs-2">
                                    <!-- <svg *ngIf="canView_CanUse_CanEdit[i].canEditIt"
                                     (click)="onEditInnerComponent(rangeInfo, i)"
                                     style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                                     <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                                    </svg> -->
                                </div>
                                <div class="col-xs-9">
                                  <h2 style="color: #3f51b5"> {{rangeInfo.name}}</h2>
                                </div>
                            </div>
                            <div class="row" *ngIf="isParagraphEdit">
                                <div class="col-xs-2"></div>
                                <div class="col-xs-9">
                                    <h2 style="color: #3f51b5"> {{rangeInfo.name}}</h2> 
                                </div>
                            </div>
                        
                            <!-- [canUseIt]="canView_CanUse_CanEdit[i].canUseIt" -->
                            <app-inner-dropdown-item 
                                  [isParagraphEdit]="isParagraphEdit"
                                  [rangeInfo_index]="i"
                                  [rangeInfo]="rangeInfo"
                                  [changeSelectedDatesView]="changeSelectedDates.asObservable()"
                                  (emitSelectedValues)="onEmitSelectedValues($event)">
                            </app-inner-dropdown-item>

                         
                        </div>
                    </div>
                    
        
                    <div  *ngSwitchCase="'innercheckbox'">
                        <div class="InnerComponentFrame" *ngIf="!isChangingDate">
                            <!-- && canView_CanUse_CanEdit[i].canViewIt" -->
                            <div class="row" *ngIf="!isParagraphEdit">
                            <div class="col-xs-2">
                                <!-- <svg *ngIf="canView_CanUse_CanEdit[i].canEditIt"
                                 (click)="onEditInnerComponent(rangeInfo, i)"
                                 style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                                 <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                                </svg> -->
                            </div>
                            <div class="col-xs-9">
                                <h2 style="color: #3f51b5"> {{rangeInfo.name}}</h2> 
                            </div>
        
                            </div>
                            <div class="row" *ngIf="isParagraphEdit">
                                <div class="col-xs-2"></div>
                                <div class="col-xs-9">
                                    <h2 style="color: #3f51b5"> {{rangeInfo.name}}</h2> 
                                </div>
                            </div>
                        
                        <!-- [canUseIt]="canView_CanUse_CanEdit[i].canUseIt" -->
                        <app-inner-checkbox-item 
                                [isParagraphEdit]="isParagraphEdit"
                                [rangeInfo_index]="i"
                                [rangeInfo]="rangeInfo"
                                [changeSelectedDatesView]="changeSelectedDates.asObservable()"
                                (emitSelectedValues)="onEmitSelectedValues($event)">
                       </app-inner-checkbox-item>

                     
                        </div>
                     
                    </div>
        
        
                    <div *ngSwitchCase="'innerinput'" >
                        <div class="InnerComponentFrame" *ngIf="!isChangingDate">
                            <div class="row" *ngIf="!isParagraphEdit">
                            <div class="col-xs-2">
                                <!-- <svg *ngIf="canView_CanUse_CanEdit[i].canEditIt"
                                 (click)="onEditInnerComponent(rangeInfo, i)"
                                 style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                                 <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                                </svg> -->
                            </div>
                            <div class="col-xs-9">
                                <h2 style="color: #3f51b5"> {{rangeInfo.name}}</h2> 
                            </div>
                            </div>
                            <div class="row" *ngIf="isParagraphEdit">
                                <div class="col-xs-2"></div>
                                <div class="col-xs-9">
                                    <h2 style="color: #3f51b5"> {{rangeInfo.name}}</h2> 
                                </div>
                            </div>
                        <!-- [canUseIt]="canView_CanUse_CanEdit[i].canUseIt" -->
                        <app-inner-input-item 
                              [isParagraphEdit]="isParagraphEdit"
                              [rangeInfo_index]="i"
                              [rangeInfo]="rangeInfo"
                              [changeSelectedDatesView]="changeSelectedDates.asObservable()"
                              (emitSelectedValues)="onEmitSelectedValues($event)"
                              >
                       </app-inner-input-item>
                      
                        </div>
                       
                    </div>
                    
        
                    <div *ngSwitchCase="'innertext'" >
                        <div class="InnerComponentFrame" *ngIf="!isChangingDate">
                            <div class="row" *ngIf="!isParagraphEdit">
                            <div class="col-xs-2">
                                <!-- <svg *ngIf="canView_CanUse_CanEdit[i].canEditIt"
                                 (click)="onEditInnerComponent(rangeInfo, i)"
                                 style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                                 <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                                </svg> -->
                            </div>
                            <div class="col-xs-9">
                                <h2 style="color: #3f51b5"> {{rangeInfo.name}}</h2> 
                            </div>
                            </div>
                            <div class="row" *ngIf="isParagraphEdit">
                                <div class="col-xs-2"></div>
                                <div class="col-xs-9">
                                    <h2 style="color: #3f51b5"> {{rangeInfo.name}}</h2> 
                                </div>
                            </div>
                        <!-- [canUseIt]="canView_CanUse_CanEdit[i].canUseIt" -->
                        <app-inner-text-item 
                              [isParagraphEdit]="isParagraphEdit"
                              [rangeInfo_index]="i"
                              [rangeInfo]="rangeInfo"
                              [changeSelectedDatesView]="changeSelectedDates.asObservable()"
                              (emitSelectedValues)="onEmitSelectedValues($event)"
                              >
                       </app-inner-text-item>
                    
                        </div>
                      
                    </div>
        
        <!-- ----------- STOP HERE FOR THE MOMENT --------------------------------------------------- -->
        
        
        
                    <div  *ngSwitchCase="'innertab'" >
                        <div class="InnerComponentFrame">
                            <div class="col-xs-12">
                                <svg (click)="onEditInnerComponent(rangeInfo, i)"
                                 style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                                 <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                                </svg>
                            </div>
                        
                        <br>
                        <app-inner-tab-item 
                              [isEditing]="false"
                              [rangeInfo]="rangeInfo"
                              [changeSelectedDatesView]="changeSelectedDates.asObservable()">
                       </app-inner-tab-item>
                        </div>
                    </div>
        
                    <div *ngSwitchCase="'innerlink'">
                        <div class="InnerComponentFrame">
                            <div class="row" *ngIf="!isParagraphEdit">
                                <div class="col-xs-12">
                                    <svg (click)="onEditInnerComponent(rangeInfo, i)"
                                     style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                                     <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                                    </svg>
                                </div>
                            </div>
                            <br>
                            <app-inner-link-item 
                                             [isEditing]="false"
                                             [rangeInfo]="rangeInfo"
                                             [changeSelectedDatesView]="changeSelectedDates.asObservable()">
                            </app-inner-link-item>
                        </div>
                    </div>
        
                    <div  *ngSwitchCase="'innerslidetoggle'">
                        <div class="InnerComponentFrame" >
                            <div class="row" *ngIf="!isParagraphEdit">
                            <div class="col-xs-12">
                                <svg (click)="onEditInnerComponent(rangeInfo, i)"
                                 style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                                 <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                                </svg>
                            </div>
                            </div>
                        <br>
                        <app-inner-slide-toggle-item 
                                  [isEditing]="false"
                                  [rangeInfo]="rangeInfo"
                                  [changeSelectedDatesView]="changeSelectedDates.asObservable()">
                        </app-inner-slide-toggle-item>
                        </div>
                    </div>
                    
        
                    <div  *ngSwitchCase="'innerbutton'">
                        <div class="InnerComponentFrame">
                            <div class="row" *ngIf="!isParagraphEdit">
                            <div class="col-xs-12">
                                <svg (click)="onEditInnerComponent(rangeInfo, i)"
                                 style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                                 <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                                </svg>
                            </div>
                            </div>
                        <br>
                        <app-inner-button-item 
                                 [isEditing]="false"
                                 [rangeInfo]="rangeInfo"
                                 [changeSelectedDatesView]="changeSelectedDates.asObservable()">
                       </app-inner-button-item>
                        </div>
                    </div>
        
                    <div  *ngSwitchCase="'innerslider'" >
                        <div class="InnerComponentFrame">
                            <div class="row" *ngIf="!isParagraphEdit">
                            <div class="col-xs-12">
                                <svg (click)="onEditInnerComponent(rangeInfo, i)"
                                 style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                                 <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                                </svg>
                            </div>
                            </div>
                        <br>
                        <app-inner-slider-item 
                              [isEditing]="false"
                              [rangeInfo]="rangeInfo"
                              [changeSelectedDatesView]="changeSelectedDates.asObservable()">
                       </app-inner-slider-item>
                        </div>
                    </div>
        
                    <div  *ngSwitchCase="'innerrating'" >
                        <div class="InnerComponentFrame">
                            <div class="row" *ngIf="!isParagraphEdit">
                            <div class="col-xs-12">
                                <svg (click)="onEditInnerComponent(rangeInfo, i)"
                                 style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                                 <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                                </svg>
                            </div>
                            </div>
                        <br>
                        <app-inner-rating-item 
                              [isEditing]="false"
                              [rangeInfo]="rangeInfo"
                              [changeSelectedDatesView]="changeSelectedDates.asObservable()">
                       </app-inner-rating-item>
                        </div>
                    </div>
        
                    
                    <div  *ngSwitchCase="'innerdailyactivities'" >
                        <div class="InnerComponentFrame">
                            <div class="row" *ngIf="!isParagraphEdit">
                            <div class="col-xs-12">
                                <svg (click)="onEditInnerComponent(rangeInfo, i)"
                                 style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                                 <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                                </svg>
                            </div>
                            </div>
                        <br>
                        <app-inner-daily-activities-item 
                              [isEditing]="false"
                              [rangeInfo]="rangeInfo"
                              [changeSelectedDatesView]="changeSelectedDates.asObservable()">
                       </app-inner-daily-activities-item>
                        </div>
                    </div>
        
                    <div  *ngSwitchCase="'innerchart'" >
                        <div class="InnerComponentFrame" >
                            <div class="row" *ngIf="!isParagraphEdit">
                            <div class="col-xs-12">
                                <svg (click)="onEditInnerComponent(rangeInfo, i)"
                                 style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                                 <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                                </svg>
                            </div>
                            </div>
                        <br>
                        <app-inner-chart-item 
                              [isEditing]="false"
                              [rangeInfo]="rangeInfo"
                              [changeSelectedDatesView]="changeSelectedDates.asObservable()">
                       </app-inner-chart-item>
                        </div>
                    </div>
        
                    <div  *ngSwitchCase="'innersum'" >
                        <div class="InnerComponentFrame">
                            <div class="row" *ngIf="!isParagraphEdit">
                            <div class="col-xs-12">
                                <svg (click)="onEditInnerComponent(rangeInfo, i)"
                                 style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                                 <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                                </svg>
                            </div>
                            </div>
                        <br>
                        <app-inner-sum-item 
                              [isEditing]="false"
                              [rangeInfo]="rangeInfo"
                              [changeSelectedDatesView]="changeSelectedDates.asObservable()">
                       </app-inner-sum-item>
                        </div>
                    </div>
        
                    </div>
                </div>
                </div>
            </div>


        <div *ngIf="isParagraphEdit">
        <div *ngFor="let rangeInfo of selectedRangeDatesInfo.rangeInfos; let i=index">

            <div [ngSwitch]="rangeInfo.type"> 

            <div  *ngSwitchCase="'innerdropdown'">
                <div class="InnerComponentFrame">
                    <div class="row" *ngIf="!isParagraphEdit">
                        <div class="col-xs-2">
                            <svg 
                             (click)="onEditInnerComponent(rangeInfo, i)"
                             style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                             <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                            </svg>
                        </div>
                        <div class="col-xs-9">
                          <h2 style="color: #3f51b5"> {{rangeInfo.name}}</h2>
                        </div>
                    </div>
                    <div class="row" *ngIf="isParagraphEdit">
                        <div class="col-xs-2"></div>
                        <div class="col-xs-9">
                            <h2 style="color: #3f51b5"> {{rangeInfo.name}}</h2> 
                        </div>
                    </div>
                
                    <app-inner-dropdown-item *ngIf="!isChangingDate"
                          [isParagraphEdit]="isParagraphEdit"
                          [rangeInfo_index]="i"
                          [rangeInfo]="rangeInfo"
                          [changeSelectedDatesView]="changeSelectedDates.asObservable()"
                          (emitSelectedValues)="onEmitSelectedValues($event)">
                    </app-inner-dropdown-item>
                    <app-inner-dropdown-item *ngIf="isChangingDate"
                          [isParagraphEdit]="isParagraphEdit"
                          [rangeInfo_index]="i"
                          [rangeInfo]="rangeInfo"
                          [changeSelectedDatesView]="changeSelectedDates.asObservable()">
                    </app-inner-dropdown-item>
                </div>
            </div>
            

            <div  *ngSwitchCase="'innercheckbox'">
                <div class="InnerComponentFrame" >
                    <div class="row" *ngIf="!isParagraphEdit">
                    <div class="col-xs-2">
                        <svg (click)="onEditInnerComponent(rangeInfo, i)"
                         style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                         <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                        </svg>
                    </div>
                    <div class="col-xs-9">
                        <h2 style="color: #3f51b5"> {{rangeInfo.name}}</h2> 
                    </div>

                    </div>
                    <div class="row" *ngIf="isParagraphEdit">
                        <div class="col-xs-2"></div>
                        <div class="col-xs-9">
                            <h2 style="color: #3f51b5"> {{rangeInfo.name}}</h2> 
                        </div>
                    </div>
                
                
                <app-inner-checkbox-item *ngIf="!isChangingDate"
                        [isParagraphEdit]="isParagraphEdit"
                        [rangeInfo_index]="i"
                        [rangeInfo]="rangeInfo"
                        [changeSelectedDatesView]="changeSelectedDates.asObservable()"
                        (emitSelectedValues)="onEmitSelectedValues($event)">
               </app-inner-checkbox-item>
               <app-inner-checkbox-item *ngIf="isChangingDate"
                        [isParagraphEdit]="isParagraphEdit"
                        [rangeInfo_index]="i"
                        [rangeInfo]="rangeInfo"
                        [changeSelectedDatesView]="changeSelectedDates.asObservable()">
               </app-inner-checkbox-item>
                </div>
            </div>


            <div *ngSwitchCase="'innerinput'" >
                <div class="InnerComponentFrame">
                    <div class="row" *ngIf="!isParagraphEdit">
                    <div class="col-xs-2">
                        <svg (click)="onEditInnerComponent(rangeInfo, i)"
                         style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                         <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                        </svg>
                    </div>
                    <div class="col-xs-9">
                        <h2 style="color: #3f51b5"> {{rangeInfo.name}}</h2> 
                    </div>
                    </div>
                    <div class="row" *ngIf="isParagraphEdit">
                        <div class="col-xs-2"></div>
                        <div class="col-xs-9">
                            <h2 style="color: #3f51b5"> {{rangeInfo.name}}</h2> 
                        </div>
                    </div>
                
                <app-inner-input-item *ngIf="!isChangingDate"
                      [isParagraphEdit]="isParagraphEdit"
                      [rangeInfo_index]="i"
                      [rangeInfo]="rangeInfo"
                      [changeSelectedDatesView]="changeSelectedDates.asObservable()"
                      (emitSelectedValues)="onEmitSelectedValues($event)">
               </app-inner-input-item>
               <app-inner-input-item *ngIf="isChangingDate"
                      [isParagraphEdit]="isParagraphEdit"
                      [rangeInfo_index]="i"
                      [rangeInfo]="rangeInfo"
                      [changeSelectedDatesView]="changeSelectedDates.asObservable()">
               </app-inner-input-item>
                </div>
            </div>
            

            <div *ngSwitchCase="'innertext'" >
                <div class="InnerComponentFrame">
                    <div class="row" *ngIf="!isParagraphEdit">
                    <div class="col-xs-2">
                        <svg (click)="onEditInnerComponent(rangeInfo, i)"
                         style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                         <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                        </svg>
                    </div>
                    <div class="col-xs-9">
                        <h2 style="color: #3f51b5"> {{rangeInfo.name}}</h2> 
                    </div>
                    </div>
                    <div class="row" *ngIf="isParagraphEdit">
                        <div class="col-xs-2"></div>
                        <div class="col-xs-9">
                            <h2 style="color: #3f51b5"> {{rangeInfo.name}}</h2> 
                        </div>
                    </div>
                
                <app-inner-text-item *ngIf="!isChangingDate"
                      [isParagraphEdit]="isParagraphEdit"
                      [rangeInfo_index]="i"
                      [rangeInfo]="rangeInfo"
                      [changeSelectedDatesView]="changeSelectedDates.asObservable()"
                      (emitSelectedValues)="onEmitSelectedValues($event)">
               </app-inner-text-item>
               <app-inner-text-item *ngIf="isChangingDate"
                      [isParagraphEdit]="isParagraphEdit"
                      [rangeInfo_index]="i"
                      [rangeInfo]="rangeInfo"
                      [changeSelectedDatesView]="changeSelectedDates.asObservable()">
               </app-inner-text-item>
                </div>
            </div>

<!-- ----------- STOP HERE FOR THE MOMENT --------------------------------------------------- -->



            <div  *ngSwitchCase="'innertab'" >
                <div class="InnerComponentFrame">
                    <div class="col-xs-12">
                        <svg (click)="onEditInnerComponent(rangeInfo, i)"
                         style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                         <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                        </svg>
                    </div>
                
                <br>
                <app-inner-tab-item 
                      [isEditing]="false"
                      [rangeInfo]="rangeInfo"
                      [changeSelectedDatesView]="changeSelectedDates.asObservable()">
               </app-inner-tab-item>
                </div>
            </div>

            <div *ngSwitchCase="'innerlink'">
                <div class="InnerComponentFrame">
                    <div class="row" *ngIf="!isParagraphEdit">
                        <div class="col-xs-12">
                            <svg (click)="onEditInnerComponent(rangeInfo, i)"
                             style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                             <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                            </svg>
                        </div>
                    </div>
                    <br>
                    <app-inner-link-item 
                                     [isEditing]="false"
                                     [rangeInfo]="rangeInfo"
                                     [changeSelectedDatesView]="changeSelectedDates.asObservable()">
                    </app-inner-link-item>
                </div>
            </div>

            <div  *ngSwitchCase="'innerslidetoggle'">
                <div class="InnerComponentFrame" >
                    <div class="row" *ngIf="!isParagraphEdit">
                    <div class="col-xs-12">
                        <svg (click)="onEditInnerComponent(rangeInfo, i)"
                         style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                         <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                        </svg>
                    </div>
                    </div>
                <br>
                <app-inner-slide-toggle-item 
                          [isEditing]="false"
                          [rangeInfo]="rangeInfo"
                          [changeSelectedDatesView]="changeSelectedDates.asObservable()">
                </app-inner-slide-toggle-item>
                </div>
            </div>
            

            <div  *ngSwitchCase="'innerbutton'">
                <div class="InnerComponentFrame">
                    <div class="row" *ngIf="!isParagraphEdit">
                    <div class="col-xs-12">
                        <svg (click)="onEditInnerComponent(rangeInfo, i)"
                         style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                         <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                        </svg>
                    </div>
                    </div>
                <br>
                <app-inner-button-item 
                         [isEditing]="false"
                         [rangeInfo]="rangeInfo"
                         [changeSelectedDatesView]="changeSelectedDates.asObservable()">
               </app-inner-button-item>
                </div>
            </div>

            <div  *ngSwitchCase="'innerslider'" >
                <div class="InnerComponentFrame">
                    <div class="row" *ngIf="!isParagraphEdit">
                    <div class="col-xs-12">
                        <svg (click)="onEditInnerComponent(rangeInfo, i)"
                         style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                         <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                        </svg>
                    </div>
                    </div>
                <br>
                <app-inner-slider-item 
                      [isEditing]="false"
                      [rangeInfo]="rangeInfo"
                      [changeSelectedDatesView]="changeSelectedDates.asObservable()">
               </app-inner-slider-item>
                </div>
            </div>

            <div  *ngSwitchCase="'innerrating'" >
                <div class="InnerComponentFrame">
                    <div class="row" *ngIf="!isParagraphEdit">
                    <div class="col-xs-12">
                        <svg (click)="onEditInnerComponent(rangeInfo, i)"
                         style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                         <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                        </svg>
                    </div>
                    </div>
                <br>
                <app-inner-rating-item 
                      [isEditing]="false"
                      [rangeInfo]="rangeInfo"
                      [changeSelectedDatesView]="changeSelectedDates.asObservable()">
               </app-inner-rating-item>
                </div>
            </div>

            
            <div  *ngSwitchCase="'innerdailyactivities'" >
                <div class="InnerComponentFrame">
                    <div class="row" *ngIf="!isParagraphEdit">
                    <div class="col-xs-12">
                        <svg (click)="onEditInnerComponent(rangeInfo, i)"
                         style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                         <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                        </svg>
                    </div>
                    </div>
                <br>
                <app-inner-daily-activities-item 
                      [isEditing]="false"
                      [rangeInfo]="rangeInfo"
                      [changeSelectedDatesView]="changeSelectedDates.asObservable()">
               </app-inner-daily-activities-item>
                </div>
            </div>

            <div  *ngSwitchCase="'innerchart'" >
                <div class="InnerComponentFrame" >
                    <div class="row" *ngIf="!isParagraphEdit">
                    <div class="col-xs-12">
                        <svg (click)="onEditInnerComponent(rangeInfo, i)"
                         style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                         <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                        </svg>
                    </div>
                    </div>
                <br>
                <app-inner-chart-item 
                      [isEditing]="false"
                      [rangeInfo]="rangeInfo"
                      [changeSelectedDatesView]="changeSelectedDates.asObservable()">
               </app-inner-chart-item>
                </div>
            </div>

            <div  *ngSwitchCase="'innersum'" >
                <div class="InnerComponentFrame">
                    <div class="row" *ngIf="!isParagraphEdit">
                    <div class="col-xs-12">
                        <svg (click)="onEditInnerComponent(rangeInfo, i)"
                         style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                         <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                        </svg>
                    </div>
                    </div>
                <br>
                <app-inner-sum-item 
                      [isEditing]="false"
                      [rangeInfo]="rangeInfo"
                      [changeSelectedDatesView]="changeSelectedDates.asObservable()">
               </app-inner-sum-item>
                </div>
            </div>

            </div>
        </div>
        </div>


        <!-- <div *ngIf="canModifyDateRange_date">
        <div class="InnerComponentFrame" *ngIf="!isAddingNewRangeInfo && !isParagraphEdit && !isChangingDate" >
            <div class="row">
                <div class="col-xs-4">
                    <h2 style="color: #3f51b5"> add feature  </h2>
                </div>
                <div class="col-xs-7">
                    <app-inner-dropdown-item
                       [canUseIt]="true"
                       [isAddingFeatureDropdown]="true"
                       [isParagraphEdit]="isParagraphEdit"
                       [rangeInfo]="additionalRangeInfo"
                       [changeSelectedDatesView]="changeSelectedDates.asObservable()"
                       (emitSelectedValues)="onGetInnerdropdownSelectedValue($event)">
                    </app-inner-dropdown-item>
                </div>
            </div>
       
        </div>
        </div> -->

    </div>
</div>

