import { Injectable } from "@angular/core";
import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from "@angular/common/http";
import { map, tap, catchError } from "rxjs/operators";
import { SingleWord } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/single-word/single-word.model";
import { ParagraphTopic } from "../../forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.model";
import { Paragraph } from "../../forms/form-list/form-item/paragraph/paragraph.model";
import { Form } from "../../forms/form.model";
import { FormIndexTopic } from "../../forms/form-list/form-index-topic-list/form-index-topic.model";
import { WordInput } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/word/word-input.model";
import { ToggleSingleWordButton } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/buttons/toggle-single-word-button/toggle-single-word-button.model";
import { CalculatorMixTable } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calculator/calculator-mix-table-list/calculator-mix-table.model";
import { SingleDateCalendar } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/calendar-list/single-date-calendar.model";
import { MultiEntryCalendar } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/multi-entry-calendar/multi-entry-calendar-list/multi-entry-calendar.model";
import { FixLayout } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/layout/fix-layout-list/fix-layout.model";
import { MultiFeatures } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/multi-features-list/multi-features.model";
import { SingleWordInput } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/single-word/single-word-input-list/single-word-input.model";
import { SingleDropDownSelect } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/select/select-list/single-drop-down-select/single-drop-down-select.model";
import { SingleSlider } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/slider/single-slider/single-slider.model";
import { Tab } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/tabs/tab-list/tab.model";
import { FixText } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/texts/fix-text-list/fix-text.model";
import { Recipient } from "../../forms/form-list/form-item/recipient/recipient.model";
import { Chat } from "../../chats/chat.model";
import { DropDownSelect } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/select/select-list/drop-down-select/drop-down-select.model";
import { RecipientIndex } from "../../forms/form-list/form-edit/recipient-index-list/recipient-index.model";
import { FeatureTopic } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-topic.model";
import { ParagraphTopicIndex } from "../../forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-topic-index.model";
import { FormIndexTopicListItem } from "../../forms/form-list/form-index-topic-list/form-index-topic-list-item.model";
import { FeatureContainer } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-container.model";
import { FeatureContainerListItem } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-container-list-item.model";
import { FeatureIndexTopicList } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-index-topic-list.model";
import { FormFeatureContainer } from "../../forms/form-list/form-index-topic-list/form-feature-container.model";
import { RecipientIndexListItem } from "../../forms/form-list/form-edit/recipient-index-list/recipient-index-list-item.model";
import { RecipientIndexColumn } from "../../forms/form-list/form-edit/recipient-index-list/recipient-index-column.model";
import { ParagraphFeatureContainer } from "../../forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature-container.model";
import { FormResult } from "src/app/forms/form-results/form-result.model";
import { RecipientFeature } from "src/app/forms/form-list/form-edit/recipient-index-list/recipient-feature.model";
import { Contact } from "src/app/contacts/contact.model";
import { environment } from "src/environments/environment";
import { UserProfile } from "src/app/auth/user-profile/user-profile.model";
import { SharedCalendarStatus } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/shared-calendar-status-list/shared-calendar-status.model";


@Injectable ({
    providedIn: 'root' 
})

export class PostComponentsHttpRequestService {

    private domain: string

    constructor (private http: HttpClient ){
        this.domain = environment.domain
    }

    httpGetUserDefaultComponents( user: string , body: any){

        return this.http.post(
            encodeURI(`${this.domain}`)+ encodeURI('market-in-service/rest/component/default/') + encodeURIComponent(user)
                                                                                          + encodeURI('/'),
                                                                                            body )
    }


    httpStoreComponent ( 
        user: string , 
        type: string , 
        component: SingleWord | SingleWordInput | ToggleSingleWordButton | SingleDropDownSelect | SingleSlider |
                   SingleDateCalendar | MultiEntryCalendar | WordInput | MultiFeatures | Tab | FixLayout | 
                   DropDownSelect | FixText | CalculatorMixTable | FeatureContainer | FeatureContainerListItem |
                   FeatureIndexTopicList | Paragraph | ParagraphTopicIndex| ParagraphTopic | Form | 
                   Chat | FormIndexTopic | RecipientIndex | FeatureTopic | FormIndexTopicListItem |
                   FormFeatureContainer | RecipientIndexListItem | RecipientIndexColumn | Recipient | RecipientFeature
                   | ParagraphFeatureContainer | Contact | UserProfile | SharedCalendarStatus
                   ){

            return this.http.post(
                encodeURI(`${this.domain}`)+ encodeURI('market-in-service/rest/component/') + encodeURIComponent(user)
                                                                                     + encodeURI('/')
                                                                                     + encodeURIComponent(type), 
                                                                                       component ,
            )
  
        
    }


    httpUpdateComponent( 
        user: string , 
        type: string ,
        updatedComponent: Chat |  SingleWord | SingleWordInput | ToggleSingleWordButton | SingleDropDownSelect | 
                          SingleSlider | SingleDateCalendar | MultiEntryCalendar | WordInput | MultiFeatures |
                          FeatureTopic | FeatureContainer | FeatureContainerListItem | FeatureIndexTopicList | Recipient |
                          Tab | FixLayout | DropDownSelect | FixText | CalculatorMixTable | Paragraph |
                          ParagraphTopicIndex |ParagraphTopic | Form | FormIndexTopic | RecipientIndex |
                          RecipientFeature | FormIndexTopicListItem | FormFeatureContainer | RecipientIndexListItem | RecipientIndexColumn |
                          ParagraphFeatureContainer | UserProfile | SharedCalendarStatus | Contact | RecipientFeature
                        ){

        return this.http.put(
            encodeURI(`${this.domain}`)+ encodeURI('market-in-service/rest/component/') +encodeURIComponent(user)
                                                                                 +encodeURI('/')
                                                                                 +encodeURIComponent(type)
                                                                                 +encodeURI('/')
                                                                                 +encodeURIComponent(updatedComponent._id) ,
                                                                                  updatedComponent 
        
        )
                
    }

    
 



    httpPostFormResult(
        user: string ,
        formResult: FormResult 
        ){

         return this.http.post(
            encodeURI(`${this.domain}`)+ encodeURI('market-in-service/rest/formresult/') +encodeURIComponent(user)
                                                                                  +encodeURI('/') ,
                                                                                   formResult 
         )
        
    }




    
    httpPostMailFormResult(
        user: string ,
        emailFormResult: FormResult 
        ){

         return this.http.post(
            encodeURI(`${this.domain}`)+ encodeURI('market-in-service/rest/mailformresult/') +encodeURIComponent(user)
                                                                                      +encodeURI('/') ,
                                                                                       emailFormResult
         )
       
        
    }




  
    private request_StorePreferenceComponent_InProgress = false;
    httpStorePreferenceComponent(
        user: string ,
        component: SingleWord | SingleWordInput | ToggleSingleWordButton | SingleDropDownSelect | SingleSlider |
        SingleDateCalendar | MultiEntryCalendar | WordInput | MultiFeatures | Tab | FixLayout | SharedCalendarStatus |
        DropDownSelect | FixText | CalculatorMixTable | FeatureContainer | FeatureContainerListItem |
        FeatureIndexTopicList | Paragraph | ParagraphTopicIndex| ParagraphTopic | Form | 
        Chat | FormIndexTopic | RecipientIndex | FeatureTopic | FormIndexTopicListItem |
        FormFeatureContainer | RecipientIndexListItem | RecipientIndexColumn | Recipient | ParagraphFeatureContainer ){

        if ( !this.request_StorePreferenceComponent_InProgress ){
              this.request_StorePreferenceComponent_InProgress = true

        return this.http.post(
            encodeURI(`${this.domain}`)+ encodeURI('market-in-service/rest/preference/') + encodeURIComponent(user)
                                                                                  + encodeURI('/')
                                                                                  + encodeURIComponent(component._id),
                                                                                    component
        )
        }
        setTimeout(() => {
            this.request_StorePreferenceComponent_InProgress = false;
        }, 300);
    };



    httpStoreMultiLevelComponentsWithUpdateOnExistingOnes(
        user: string ,
        componentsJson: any 
    ){
        return this.http.post(
            encodeURI(`${this.domain}`)+ encodeURI('market-in-service/rest/component/multiWithCheckCompType/') + encodeURIComponent(user)
                                                                                                        + encodeURI('/'),
                                                                                                          componentsJson
        )
  
    };


    httpAddToAllUsers_aNewComponent(){
        return this.http.post(
            encodeURI(`${this.domain}`)+ encodeURI('market-in-service/rest/component/new'),null
        )
    }


    

}
