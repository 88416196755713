import { Injectable } from '@angular/core';
import { ParagraphTopic } from './paragraph-topic.model';
import { Subject , Subscription } from 'rxjs';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';


@Injectable ({
    providedIn: 'root' 
})

export class ParagraphTopicService {


    constructor( 
                 private userObjectUpdateService: UserObjectUpdateService,
                 private initialNullSetupService: InitialNullSetupService ,
                 ){}

  count : number = 0 ;
   /**
      *  constructor ( 
        public _id: string, 
        public owner: string , 
        public dateAndTime: string ,
        public isAccepted: boolean,
        public allUsersAllowed: boolean,
        public userListAllowed: string[],
        public isBeenShared: boolean, 
        public counterId: number ,
        public paragraphFeatureDropId: number ,  
        public paragraphId: number ){}
    
    }
      */

    user = this.userObjectUpdateService.userEmail

    paragraphTopics : ParagraphTopic[]  = this.initialNullSetupService.initialNullParagraphTopics

    updateOnUserEmail : Subscription = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
        this.user = updatedUserEmail
        console.log('updated USER = ', this.user)
    })
 


    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.paragraphTopicsChangeBackToNull
   .subscribe((res)=> {
          this.paragraphTopics = res 
          console.log('updated paragraphTopics back to null = ', res)

    });

    updateInitialParagraphTopicOfReference: Subscription = this.initialNullSetupService.paragraphTopicOfReferenceChangeBackToNull
   .subscribe((res)=> {
          this.startingParagraphTopicReference = res 
          console.log('updated paragraphTopicOfReference back to null = ', res)

    });

   
    
   

     /** setting topicName to a name otherwise it won't connect to anyone if the browser starts 
     * without any click from the add paragraph from paragraphTopicIndex
     */
   // topicName = this.paragraphTopics[0].name ;
    topicName = ''

    currentTopicIndex = 0;

    currentParagraphTopicIndex : number ;
    currentParagraphTopic_Index: string ;


   
    /**
     * 
     * @param id 
     * @returns paragraphTopic by ID
     */
    getParagraphTopic(id: number){
        return this.paragraphTopics[id]
    };

    getParagraphTopicBy_id(_id: string){
      const newParagraphTopic: ParagraphTopic = this.paragraphTopics.find(
        (res)=> {
          return res._id === _id
        }
      )
      return newParagraphTopic
    }

    /**
     * 
     * @returns return the list of paragraphTopic
     */
    getParagraphTopics(){
        return this.paragraphTopics ;
    };

    /** add paragraphTopic to paragraphTopicIndex list */
    addParagraphTopic(paragraphTopic: ParagraphTopic){
        this.paragraphTopics.push(paragraphTopic);
    };
 

    /** set the paragraph Topic name on add paragraph click on paragraph index ,
     * to be used in edit paragraph component
     * 
     * we should add the behavior subject to make it more stable , it serves as reminder to which paragraphTopic group
     * the paragraph we are creating belong to 
     */

    //  startingParagraphTopicReference: ParagraphTopic = new ParagraphTopic (null, null, false, [], null, true, 
    //                                  false, '', [] )

    startingParagraphTopicReference: ParagraphTopic = this.initialNullSetupService.initialParagraphTopicReference
     
    onChoosenParagraphTopicToBeAddedTo(choosenParagraphTopic: ParagraphTopic){
      console.log(choosenParagraphTopic)
      this.startingParagraphTopicReference = choosenParagraphTopic
    }


    

      /**
       * receiving subjects list from chat form list 
       */

      onFirstOpenSubjectList: string[] // this will give the list once opening a shared ParagraphIndex

      private subjectListFromChatFormList = new Subject<string[]>()
      public subjectListFromChatFormList$ = this.subjectListFromChatFormList.asObservable()


      sendListOfSubjects(subjectList: string[]){
          this.subjectListFromChatFormList.next(subjectList)
          this.onFirstOpenSubjectList = subjectList
      };


      deletePublicParagraphTopicItem(_id: string){
        /**
         * here we delete only the one belonging to PUBLIC type 
         */
        this.paragraphTopics.splice(this.paragraphTopics.findIndex(x=> x._id === _id), 1)
      }



 }
