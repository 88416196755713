import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdDirective } from "./ad.directive";
import { DropdownDirective } from "./dropdown.directive";
import { HeaderLessTabsDirective } from "./header-less-tabs.directive";
import { HeaderLessTabs2Directive } from "./header-less-tabs2.directive";
import { MultiFeaturesHeaderLessTabsDirective } from "./multiFeatures-header-less-tabs.directive";
import { FindFormOrParagraphComponent } from "./find-form-or-paragraph/find-form-or-paragraph.component";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatInputModule} from '@angular/material/input';
import { MatChipsModule} from '@angular/material/chips';
import { TrackVisibilityDirective } from "./track-visibility.directive";
import { HeaderTabsDirective } from "./header-tabs.directive";

@NgModule({
    declarations: [
        AdDirective,
        DropdownDirective,
        HeaderLessTabsDirective,
        HeaderLessTabs2Directive,
        HeaderTabsDirective,
        MultiFeaturesHeaderLessTabsDirective,
        FindFormOrParagraphComponent , 
        TrackVisibilityDirective
    ],

    exports: [
        AdDirective,
        DropdownDirective,
        HeaderLessTabsDirective,
        HeaderLessTabs2Directive,
        HeaderTabsDirective,
        MultiFeaturesHeaderLessTabsDirective,
        FindFormOrParagraphComponent , 
        TrackVisibilityDirective
    ] ,

    imports: [
        FormsModule,
        ReactiveFormsModule , 
        CommonModule , 
        LayoutModule,
        MatButtonModule ,
        MatDialogModule , 
        MatExpansionModule ,
        MatIconModule ,
        MatListModule , 
        MatSidenavModule ,
        MatToolbarModule,
        MatInputModule , 
        MatChipsModule

    ]
})
export class SharedModule{}