

    <div class="innerItemFrame2">
        <div class="col-xs-12">

            <form [formGroup]="innerLinkEditFormName" (ngSubmit)="onSubmitName()">
        
        
                <div class="row">
                    <div class="col">
                        <mat-form-field style="width: 250px" appearance="outline">
                            <mat-label>component name</mat-label>
                            <input 
                            matInput
                            type="text"
                            id="name"
                            formControlName="name"
                            >
                        </mat-form-field>
                        <mat-error *ngIf="!innerLinkEditFormName.get('name').valid"  >Please enter a valid name, maximum 30 letters </mat-error>
                    </div>
            
                
                </div>
        

            <div *ngFor="let detail of rangeInfo.rangeDetails; let i=index">
               
                <div [ngSwitch]="detail.type"> 
             
                    <div *ngSwitchCase="'typeUrl'">

                        <div class="row">
                            <div class="col-1">
                                <svg (click)="onDeleteLink(i)" style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                                </svg>
                            </div>
                            <div class="col-10">
                                <!-- <a href="${detail.content}"> url - {{detail.content}}</a> -->
                                <p style="color: blue"> url - {{detail.content}}</p>
                            </div>
                           
                        </div>
                    </div>

                    <div *ngSwitchCase="'typeTelNumber'">
                        <div class="row">
                            <div class="col-1">
                                <svg (click)="onDeleteLink(i)" style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                                </svg>
                            </div>
                            <div class="col-10">
                                <p style="color: blue"> call cell - {{detail.content}}</p>
                            <!-- <a href="tel:${detail.content}"> call tel  {{detail.content}}</a> -->
                            </div>
                        </div>
                    </div>

                    <div *ngSwitchCase="'typeWAMessage'">
                        <div class="row">
                            <div class="col-1">
                                <svg (click)="onDeleteLink(i)" style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                                </svg>
                            </div>
                            <div class="col-10">
                                <p style="color: blue"> WA message - {{detail.content}}</p>
                            <!-- <a href="tel:whatsapp://send?phone=${detail.content}"> WA message {{detail.content}}</a> -->
                            </div>
                        </div>

                    </div>

                    <div *ngSwitchCase="'typeWACall'">

                        <div class="row">
                            <div class="col-1">
                                <svg (click)="onDeleteLink(i)" style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                                </svg>
                            </div>
                            <div class="col-10">
                                <p style="color: blue"> WA call - {{detail.content}}</p>
                            <!-- <a href="tel:whatsapp://call?phone=${detail.content}">Make a WhatsApp Phone Call phone= {{detail.content}}</a> -->
                            </div>
                        </div>
                        
                    </div>

                    <div *ngSwitchCase="'typeWAVideo'">
                        <div class="row">
                            <div class="col-1">
                                <svg (click)="onDeleteLink(i)" style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                                </svg>
                            </div>
                            <div class="col-10">
                                <p style="color: blue"> WA video call - {{detail.content}}</p>
                            <!-- <a href="https://wa.me/${detail.content}/?action=video">Make a WhatsApp Video Call {{detail.content}}</a> -->
                            </div>
                        </div>

                    </div>

                    
                </div>

            </div>
 



            </form>
            <br>

            <div class="row">
                <div class="col">
                    <button type="button" (click)="onAddLink()" class="btn btn-primary btn-sm"> + </button>
                </div>
            </div>

            <div class="row" *ngIf="innerLinkEditFormName.get('name').valid">
                <div class="col">
                    <button type="button" (click)="onConfirmInnerComponent()"> confirm </button>
                </div>
            </div>

        </div>
    </div>

