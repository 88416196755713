import { Component, Input, OnInit } from '@angular/core';
import { RangeInfoResult } from '../../shared-calendar-status-result/range-info-result.model';

@Component({
  selector: 'app-innerinput-result',
  templateUrl: './innerinput-result.component.html',
  styleUrls: ['./innerinput-result.component.css']
})
export class InnerinputResultComponent implements OnInit {

  @Input() rangeInfoResult: RangeInfoResult

  constructor() { }

  ngOnInit(): void {
  }

}
