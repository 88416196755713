<!-- <p>fix-layout-results works!</p> -->



<div class="row">
    <div class="col-xs-12 " >
        <br>

        <table >
        <div *ngFor="let tr of fixLayoutDetailResult.fixLayoutTableTRs; let i=index"  >
               
              <tr >

                   <td *ngFor="let td of tr.fixLayoutTableTDs; let j=index" style="padding-right: 1em;float:center"  >
                 
                    <div [ngSwitch]="td.tableTDType" >

                          <div *ngSwitchCase="'string'" >
                          <mat-form-field [ngStyle]="{width: getWidth(i, j)}" appearance="outline" >
                            <mat-label>{{td.tableTDDetailStringLabel}}</mat-label>
                            <input style="color:black" matInput disabled value="{{td.tableTDDetailString}}">
    
                          </mat-form-field>
                          </div>

                          <div *ngSwitchCase="'description'" >
                            <mat-form-field  [ngStyle]="{width: getWidth(i, j)}"  appearance="outline" >
                            <mat-label>{{td.tableTDDetailDescriptionLabel}}</mat-label>
                            <input style="color:black" matInput disabled matInputAutosize value="{{td.tableTDDetailDescription}}">
                           
                            </mat-form-field>
                            </div>

                          <div *ngSwitchCase="'number'" >
                          <mat-form-field [ngStyle]="{width: getWidth(i, j)}" appearance="outline">
                            <mat-label>{{td.tableTDDetailNumberLabel}}</mat-label>
                          <input matInput style="color:black" disabled
                          value="{{td.tableTDDetailNumber}}">
                        
                          </mat-form-field>
                          </div>

                          <div *ngSwitchCase="'chart'" style="padding-right: 8em;float:center"  >
                          <div>
                            <button class="btn btn-danger" type="button">{{td.tableTDDetailChartShare}}</button>
                            <button class="btn btn-danger" type="button">
                               {{td.tableTDDetailChartTotal}}
                             
                            </button>
                          </div>
                          </div>

                    </div>
                   
                   </td>
             
               </tr>

           </div> 
        </table>


    </div>
</div>


