<div class="innerItemFrame2">
    <div class="col-xs-12">

<div *ngFor="let detail of rangeInfo.rangeDetails; let i=index">

    <p>{{detail.text}}</p>

</div>

    </div>
</div>
