import { Component, OnInit , Input , Output, EventEmitter } from '@angular/core';
import { FormService } from 'src/app/forms/form.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormIndexTopic } from '../../form-index-topic.model';
import { FormIndexTopicDialog } from '../form-index-topic-dialog.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { Subscription } from 'rxjs';
import { FormIndexTypeInterface } from '../../../form-index-type-interface.model';
import { Form } from 'src/app/forms/form.model';

@Component({
  selector: 'app-form-feature',
  templateUrl: './form-feature.component.html',
  styleUrls: ['./form-feature.component.css']
})
export class FormFeatureComponent implements OnInit {

  user: string 


  @Input() formIndexTopic: FormIndexTopic
  @Input() index: number
  @Input() form_id: string
  // formFeature: FormFeature
  form: Form
  isClicked: boolean 

  @Output() adviseFormListOnLoadingForm = new EventEmitter<void>()

  updateFormStatusSubscription: Subscription 

  @Input() typeOfFormIndexTopic: FormIndexTypeInterface
  typeOfIndexTopicInUse: string 

  isBeenShared2Public: boolean 
  isBeenShared2Contacts: boolean


  constructor( 
               private formService: FormService , 
               private route: ActivatedRoute,
               private userObjectUpdateService: UserObjectUpdateService,
               private router: Router , 
               private formIndexTopicDialog: FormIndexTopicDialog) {}

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    this.isClicked = false 
    this.form = this.formService.getFormBy_id(this.form_id)

    if ( this.form.allUsersAllowed === true ){ this.isBeenShared2Public = true }
    if ( this.form.allUsersAllowed === false ){ this.isBeenShared2Public = false }
    if ( this.form.userListAllowed.length > 0 ){ this.isBeenShared2Contacts = true }
    if ( this.form.userListAllowed.length === 0 ){ this.isBeenShared2Contacts = false }

    this.updateFormStatusSubscription = this.formService.onUpdateFormStatus
                                               .subscribe(()=> this.updateFormStatus())


  }

  updateFormStatus(){
    console.log('updateFormStaete is here!')
    this.form = this.formService.getFormBy_id(this.form_id)
    if ( this.form.allUsersAllowed === true ){ this.isBeenShared2Public = true }
    if ( this.form.allUsersAllowed === false ){ this.isBeenShared2Public = false }
    if ( this.form.userListAllowed.length > 0 ){ this.isBeenShared2Contacts = true }
    if ( this.form.userListAllowed.length === 0 ){ this.isBeenShared2Contacts = false }

    
  }


  onShareFormIndex(){

    this.formIndexTopicDialog.ShareFormIndex({
  
      formIndexTopic : this.formIndexTopic, 
      formFeatureIndex: this.index, 
      form: this.form 
  
    })
  }



        /**
       * 
       * @param i send form ID to be used when reload the form and knows this was the last 
       *          selected _id
       */
         onSendForm_id(){

          this.isClicked = true 
          this.formService.onChangedForm_id(this.form_id)
          this.adviseFormListOnLoadingForm.emit()

          if ( this.typeOfFormIndexTopic.isPersonal == true )  {  this.router.navigate(['/form-list/personal-form', this.form_id ], {relativeTo: this.route.parent })}
          if ( this.typeOfFormIndexTopic.isPublic == true )    {  this.router.navigate(['/form-list/public-form', this.form_id ], {relativeTo: this.route.parent })}
          if ( this.typeOfFormIndexTopic.isShared == true )    {  this.router.navigate(['/form-list/shared-form', this.form_id ], {relativeTo: this.route.parent })}
          if ( this.typeOfFormIndexTopic.isPreferred == true ) {  this.router.navigate(['/form-list/preferred-form', this.form_id ], {relativeTo: this.route.parent })}
          
          setTimeout(()=>{
            this.isClicked = false 
          },500)
}

ngOnDestroy(){
  this.updateFormStatusSubscription.unsubscribe()
}


     

}
