import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.css']
})
export class CalculatorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

result: number = 0;
operation: string = '';
operatorSelected: boolean = false;

clear() {
  this.result = 0;
  this.operation = '';
  this.operatorSelected = false;
}

calculate() {
  this.result = eval(this.operation);
  this.operation += '=' + this.result;
  this.operatorSelected = false;
}

pressKey(key: string) {
  if (key === 'C') {
    this.clear();
  } else if (key === '=') {
    this.calculate();
  } else if (key === '+' || key === '-' || key === '*' || key === '/') {
    if (!this.operatorSelected) {
      this.operation += key;
      this.operatorSelected = true;
    }
  } else {
    this.operation += key;
    this.operatorSelected = false;
  }
}


}




