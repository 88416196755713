import { Injectable } from "@angular/core";
import { SingleDateCalendar } from "./single-date-calendar.model";
import { InitialNullSetupService } from "src/app/services/starting-services/initial-null-setup.service";
import { Subject, Subscription } from "rxjs";
import { StoreFormItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-form-item-plus-childs-confirmation.service";
import { StoreParagraphItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-paragraph-item-plus-childs-confirmation.service";
import { StoreTabItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-tab-item-plus-childs-confirmation.service";


@Injectable ({
    providedIn: 'root' 
})


export class SingleDateCalendarService {

    constructor( private initialNullSetupService: InitialNullSetupService , 
                 private storeTabItemPlusChildsConfirmationService: StoreTabItemPlusChildsConfirmationService ,
                 private storeFormItemPlusChildsConfirmationService: StoreFormItemPlusChildsConfirmationService , 
                 private storeParagraphItemPlusChildsConfirmationService: StoreParagraphItemPlusChildsConfirmationService 
        ){}

    singleDateCalendars: SingleDateCalendar[] = this.initialNullSetupService.initialNullSingleDateCalendars

    initialPersonalSingleDateCalendarsHaveBeenStored = new Subject<void>()
   

    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.singleDateCalendarsChangedBackToNull
    .subscribe((res)=> {
          this.singleDateCalendars = res 
          console.log('updated singleDateCalendars back to null = ', res)

    });
    
    /**
     * 
     * @param index of the selected item
     * @returns 
     */
    getSingleDateCalendar(index: number){
        return this.singleDateCalendars[index]
    }

    getSingleDatecalendar_By_id(_id: string ){
        const newSingleDateCalendar: SingleDateCalendar = this.singleDateCalendars.find(
            (res)=> {
                return res._id === _id
            }
        )
        return newSingleDateCalendar
    }

    getSingleDateCalendars(){
        return this.singleDateCalendars
    }

    /**
     * 
     * @param singleDateCalendar add singleDateCalendar to the list
     */
    onAddNew(singleDateCalendar: SingleDateCalendar){
        this.singleDateCalendars.push(singleDateCalendar)
        this.updateInitialComponents(this.singleDateCalendars)
    }

    addNewSingledateCalendars(singleDateCalendars: SingleDateCalendar[]){
        for ( let i=0; i<singleDateCalendars.length ; i++){
            if ( this.singleDateCalendars.findIndex(x=> x._id === singleDateCalendars[i]._id ) === -1 ){
                this.singleDateCalendars.push(singleDateCalendars[i])
            }
            if ( i === singleDateCalendars.length-1){
                this.updateInitialComponents(this.singleDateCalendars)
                console.log(this.singleDateCalendars)
                this.initialPersonalSingleDateCalendarsHaveBeenStored.next()
            }
        }
        // console.log(this.singleDateCalendars)
        // this.initialPersonalSingleDateCalendarsHaveBeenStored.next()
    }

    addNewSingleDateCalendar(singleDateCalendarToAdd: SingleDateCalendar , isComingFromFormItemCall : boolean  , isComingFromMultiFeatures: boolean){
       
        if ( this.singleDateCalendars.findIndex(x=> x._id === singleDateCalendarToAdd._id ) !== -1 ) { 
             const index = this.singleDateCalendars.findIndex(x=> x._id === singleDateCalendarToAdd._id )
             this.singleDateCalendars[index] = singleDateCalendarToAdd
            this.updateInitialComponents(this.singleDateCalendars)
       }

        if ( this.singleDateCalendars.findIndex(x=> x._id === singleDateCalendarToAdd._id ) === -1 ) { 
             this.singleDateCalendars.push(singleDateCalendarToAdd) 
             this.updateInitialComponents(this.singleDateCalendars)
        }

            if ( isComingFromFormItemCall === true  ){ this.storeFormItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenFormItemPlusChildsAreStored( singleDateCalendarToAdd._id) }
            if ( isComingFromFormItemCall === false ){ this.storeParagraphItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenParagraphItemPlusChildsAreStored( singleDateCalendarToAdd._id) }
            if ( isComingFromMultiFeatures=== false ){ this.storeTabItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenTabItemPlusChildsAreStored( singleDateCalendarToAdd._id) }
       
        console.log('singleDateCalendars WITH SHARED ONES ADDED = ', this.singleDateCalendars)
    };

    updateInitialComponents(updatedSingleDateCalendars: SingleDateCalendar[]){
        this.initialNullSetupService.initialNullSingleDateCalendars = updatedSingleDateCalendars
    }

    ngOnDestroy(){
        this.updateInitialNullDatafromInitialNullSetupService.unsubscribe()
    }


}