import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paragraph-list',
  templateUrl: './paragraph-list.component.html',
  styleUrls: ['./paragraph-list.component.css']
})
export class ParagraphListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
