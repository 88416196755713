<!-- <p>group-name works!</p> -->

<div > 
        
    <mat-form-field class="full-width">
        <!-- <mat-label>greetings</mat-label> -->
        <input 
               matInput
               id="groupName" 
               type="text"
               placeholder="name:" >

    </mat-form-field>
   
</div>
