import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FixText } from './fix-text.model';
import { FixTextDetail } from './fix-text-detail.model';
import { FixTextService } from './fix-text.service';
import { MatTabGroup } from '@angular/material/tabs';
import { Feature } from '../../feature.model';
import { Observable, Subscription, Subject } from 'rxjs';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { CreateNewFeatureContainerAndAddToFeatureContainerListItemService } from 'src/app/services/shared-services/create-new-feature-container-and-update-feature-container-list-item.service';
import { AdviseIfHttpStoreComponentsCallHasBeenMadeService } from 'src/app/services/other-components-services/advise-if-http-store-components-has-been-made.service';
import { StoreBaseComponentsService } from 'src/app/services/get-and-store-components/store-base-components.service';
import { FeatureContainerListItemService } from '../../../../paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container-list-item/feature-container-list-items.service';
import { FeatureContainerListItem } from '../../../../paragraph-edit/feature-container-list-item.model';
import { FeatureContainerService } from '../../../../paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container/feature-container.service';
import { FeatureContainer } from '../../../../paragraph-edit/feature-container.model';
import { StorePersonalFeatureIndexComponentsService } from 'src/app/services/store-initial-components/store-personal-feature-index-components.service';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { GetComponentsHttpRequestService } from 'src/app/services/gets-services/get-components-http-request.sevice';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-fix-text-list',
  templateUrl: './fix-text-list.component.html',
  styleUrls: ['./fix-text-list.component.css']
})
export class FixTextListComponent implements OnInit , OnDestroy {

  // *ngIf="(isHandset$ | async) === false"
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  user : string 

  fixText: FixText
  fixTexts: FixText[]

  thisTypeOfComponent: string = 'fixText'

  // these tabsIndex are to create and re-position focus once created a new tab
  newTabIndex0: number ;
  newtabIndex : number ;
  customCollapsedHeight: string = '20px';
  customExpandedHeight: string = '20px';

  index : number ;

 selectedIndex: number  ;
  @Input() feature_from_paragraphEdit: Feature 
 @Output() changeIndex = new EventEmitter<Feature>()  ;
 @Output() eventIndex: number ;

 @Output() holdOnCreateUpdateButtons = new EventEmitter<void>()

   feature : Feature ;
   details :  FixTextDetail[];

   // this set as false in the DOM allow us to see the buttons to modify the component
   @Output() noNeeded : boolean ; 

   /** limit on the number of features to add */
   createButtonLimit : boolean ;
   fixTextLimit = 25 ;

   @Input() dropId : number ;
   @Input() currentI : number ;
   @Input() isParagraphEdit: boolean;
  


   /**
    * receiving subscription from ParagraphEdit to edit the component 
    */
   subscriptionToEditComponentItemIsOn: boolean

   currentFeature: Feature
   @Input() editFeature: Observable<void> 

   /**
    * sending another subscribtion to word item
    */
   // clickOnEditFeatureItem: Subject<void> = new Subject<void>()

   @ViewChild('tabs') tabGroup: MatTabGroup

   /**
    * click and receive the results from paragraph Edit
    */
   editFeatureEventFromParagraphEdit: Subscription;
 clickOnEditFeatureItem: Subject<Feature> = new Subject<Feature>()


  receiveFeatureInfoFromParagraphEdit: Subscription; 
  @Input() collectFeatureId: Observable<Feature>

  isLoading: boolean 

  isLoadingList: boolean
  fixTextsHasBeenStoredSubscription: Subscription 

  featureContainerPlusParentsHaveBeenStored: Subscription 

  error = null 

  updateOnUserEmail : Subscription

  matToolTipMessageOnCreateNew: string 

  featureContainersLength_isStillTheSame: boolean 


  constructor( private fixTextService: FixTextService,
               private createNewFeatureContainerAndAddToFeatureContainerListItemService: CreateNewFeatureContainerAndAddToFeatureContainerListItemService ,
               private userObjectUpdateService: UserObjectUpdateService ,
               private breakpointObserver: BreakpointObserver,
               private postComponentsHttpRequestService: PostComponentsHttpRequestService ,
               private adviseIfHttpStoreComponentsCallHasBeenMadeService: AdviseIfHttpStoreComponentsCallHasBeenMadeService ,
               private storeBaseComponentsService: StoreBaseComponentsService , 
               private featureContainerListItemService: FeatureContainerListItemService , 
               private featureContainerService: FeatureContainerService ,
               private storePersonalFeatureIndexComponentsService: StorePersonalFeatureIndexComponentsService ,
               private initialNullSetupService: InitialNullSetupService , 
               private getComponentsHttpRequestService: GetComponentsHttpRequestService
               ) { }

  ngOnInit(){

    console.log('INITIAL ONINIT')

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
        .subscribe((updatedUserEmail)=>{
         this.user = updatedUserEmail
         console.log('updated USER = ', this.user)
    })

    console.log(this.user )

    this.subscriptionToEditComponentItemIsOn = false
    this.isLoading = true 
    this.isLoadingList = true 

    if ( this.user === null ){console.log('is going directly to continue as NULL user'), this.continueInitialOnInit()}
    if ( this.user !== null ){
      if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.fixTextsHasBeenStored === true  ){ console.log('is going direvtly to continue'),   this.continueInitialOnInit() }
      if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.fixTextsHasBeenStored === false ){ console.log('is calling http'), this.storeBaseComponentsService.callBaseComponentsListByUserAndType(this.user , 'fixText') }
    }

    this.editFeatureEventFromParagraphEdit = this.editFeature.subscribe(() => this.onEditFeatureItem() )
    this.receiveFeatureInfoFromParagraphEdit = this.collectFeatureId.subscribe((data)=> this.updateCurrentFeature(data))
   
    this.fixTextsHasBeenStoredSubscription = this.fixTextService.fixTextsHasBeenStored
        .subscribe(()=> { 
         this.continueInitialOnInit()
         this.adviseIfHttpStoreComponentsCallHasBeenMadeService.fixTextsHasBeenStored = true 
    })

    this.featureContainerPlusParentsHaveBeenStored = this.featureContainerListItemService.newFeatureContainerAndparentsHaveBeenStored
        .subscribe((feature_plus_typeOfComponent: any )=> {

          const currentFeature:Feature = feature_plus_typeOfComponent.feature 
          const currentTypeOfComponent: string = feature_plus_typeOfComponent.featureContainerName

          console.log(feature_plus_typeOfComponent)
          if ( currentTypeOfComponent === this.thisTypeOfComponent){
            this.onEmitToParagraphEditChangedIndex(currentFeature)
          }

    })
    
  }


  continueInitialOnInit(){

    console.log('IT S CONTINUING INITIAL ONINIT')
    const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('fixText')

    this.fixTexts = []
    for ( let i=0; i<personalFeatureContainerListItem.featureContainer_ids.length ; i++ ){
          let currentFeatureContainer: FeatureContainer = this.featureContainerService.getFeatureContainerBy_id(personalFeatureContainerListItem.featureContainer_ids[i])
          this.fixTexts.push(this.fixTextService.getFixTextBy_id(currentFeatureContainer.componentReference_id))
    }
    console.log(this.fixTexts)
    if (this.user !== null ){ this.fixTexts.reverse() }
    
    this.selectedIndex = this.fixTexts.findIndex(x => x._id === this.feature_from_paragraphEdit.feature_id)

    this.matToolTipMessageOnCreateNew = " CREATE A COPY OF  '1 - "+this.fixTexts[0].name+"'"

    if (this.fixTexts.length < this.fixTextLimit){ this.createButtonLimit = true }
    if (this.fixTexts.length >= this.fixTextLimit){this.createButtonLimit = false}

    if (this.user === null ){ setTimeout(()=>{ this.isLoading = false , this.isLoadingList = false },200) }
    if (this.user !== null ){ this.isLoading = false , this.isLoadingList = false  }
   
  
   
  }

  /** for each click we recreate a new feature  */
onLinkClick($event) {

  this.index = this.tabGroup.selectedIndex ; 
 
  const newOwner = this.user; 
  const newIsNew = false;
  const newDropId = this.currentI ;
  const newFeature_id = this.fixTexts[this.index]._id;
  const newFeatureName = 'fixText' ;
  const newFeature = new Feature (  newOwner, newIsNew,  newDropId , newFeature_id, newFeatureName) ;
 
  this.feature = newFeature;
 
  this.changeIndex.emit(this.feature)
  this.eventIndex = this.currentI ;
 
  console.log('drop id =', this.currentI)
  console.log(this.feature)
 
   }

onCreate(){
    this.isLoading = true 
    this.isLoadingList = true 

    this.onCreateNew()
}


  onCreateNew(){

    if ( this.user !== null ){ this.onExistingUserCreateComponent()}
    if ( this.user === null ){ this.onNullUserCreateComponent() }
  }

  onExistingUserCreateComponent(){

    this.storePersonalFeatureIndexComponentsService.getPersonalUpdatedFeatureIndex_components(this.user)
    .subscribe((res: any)=> {
      console.log(res)

      const httpFeatureIndexComponentResult: any = res 

                 
                    const newFeatureContainers: FeatureContainer[] = httpFeatureIndexComponentResult.featureContainer
                    console.log(newFeatureContainers)
                    this.featureContainerService.featureContainers = newFeatureContainers
                    this.initialNullSetupService.initialNullFeatureContainers = newFeatureContainers
                   
                    const newFeatureContainerListItems: FeatureContainerListItem[] = httpFeatureIndexComponentResult.featureContainerListItem
                                         console.log(newFeatureContainerListItems)
                    this.featureContainerListItemService.featureContainerListItems = newFeatureContainerListItems
                    this.initialNullSetupService.initialNullFeatureContainerListItems = newFeatureContainerListItems

      /**
       * now check the  featueContainerListItem that belong to this componentType : wordInput
       * by filter or find index of name : wordInput
       */
       
      const latestFeatureContainers_length = newFeatureContainers.length
      const latestFeatureContainerListItems_length = newFeatureContainerListItems.length 

      const indexOfselectedFeatureContainerListItem = newFeatureContainerListItems.findIndex(x=> x.name === this.thisTypeOfComponent )
      const selectedFeatureContainerListItem = newFeatureContainerListItems[indexOfselectedFeatureContainerListItem]

      if ( selectedFeatureContainerListItem.featureContainer_ids.length === this.fixTexts.length ){ this.featureContainersLength_isStillTheSame = true }
      if ( selectedFeatureContainerListItem.featureContainer_ids.length > this.fixTexts.length ){ this.featureContainersLength_isStillTheSame = false }

      console.log(latestFeatureContainers_length, latestFeatureContainerListItems_length, selectedFeatureContainerListItem) 
                  
      this.newTabIndex0 = selectedFeatureContainerListItem.featureContainer_ids.length+1

   
    const newComponentName = this.thisTypeOfComponent
    const newUIID = 'fixText-'+ this.newTabIndex0.toString()
    const newOwner = this.user; 
    const newName =  'new text '+ this.newTabIndex0.toString() ;

    const newDetails =  "start editing this text - "+ this.newTabIndex0.toString();

    const newFeatureContainer_UIID = 'featureContainer-'+(latestFeatureContainers_length+1).toString()
  
        const newFixTextAndParents = {

          "1": [
            {
              "componentName": newComponentName,
              "UIID":newUIID,
              "owner": newOwner,
              "allUsersAllowed": false,
              "userListAllowed": [],
              "dateAndTime": null,
              "name": newName,
              "details": [ 
                          {
                          "text": newDetails
                          }
                         ],
            }
            ],
            "2": [
                 {
              "componentName": "featureContainer",
              "UIID":newFeatureContainer_UIID,
              "owner":newOwner,
              "allUsersAllowed": false,
              "userListAllowed": [],
              "dateAndTime": null,
              "isActive": true ,
              "isAccepted": true,
              "name": newComponentName,
              "componentReference_id": "@CHILD_ID@",
              "features": [
                           {
                           "owner": "@OWNER@",
                           "isNew": false ,
                           "dropId": 0,
                           "feature_id": "@CHILD_ID@",
                           "featureName": newComponentName
                           }
              ]
               }
          ],
          "3": [
                 {
              "componentName": "featureContainerListItem",
              "UIID":selectedFeatureContainerListItem.UIID,
              "owner":selectedFeatureContainerListItem.owner,
              "allUsersAllowed": selectedFeatureContainerListItem.allUsersAllowed,
              "userListAllowed": selectedFeatureContainerListItem.userListAllowed,
              "dateAndTime": selectedFeatureContainerListItem.dateAndTime,
              "type": "personal",
              "name": newComponentName,
              "featureContainer_ids": [ selectedFeatureContainerListItem.featureContainer_ids, "@CHILD_ID@"],
              "selectedFeatureContainer_id": selectedFeatureContainerListItem.selectedFeatureContainer_id
               }
          ]
        }

        this.postComponentsHttpRequestService.httpStoreMultiLevelComponentsWithUpdateOnExistingOnes( this.user, newFixTextAndParents)
        .subscribe((res: any)=>{
          console.log(res)

          const newFixText: FixText = res.fixText[0]
          const newFeatureContainer: FeatureContainer = res.featureIndex[0]
          const updatedFeatureContainerListItem: FeatureContainerListItem = res.featureIndex[1]

          this.fixTextService.onAddNew(newFixText)
          this.featureContainerService.addFeatueContainer(newFeatureContainer)
          this.featureContainerListItemService.updateFeatureContainerListItem(updatedFeatureContainerListItem)

          const newFeatureOwner = this.user; 
            const newIsNew = false;
            const newDropId = this.currentI ;
            const newFeature_id = newFixText._id
            const newFeatureName =  this.thisTypeOfComponent;
            const newFeature = new Feature ( newFeatureOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;

            /**
             * if the featureContainerListItes was same length 
             */
            if ( this.featureContainersLength_isStillTheSame ){ this.onEmitToParagraphEditChangedIndex( newFeature )}
            if ( !this.featureContainersLength_isStillTheSame ){ 
                   
                this.getComponentsHttpRequestService.httpGetComponentsByType(this.user , this.thisTypeOfComponent )
                    .subscribe((res: any )=> {
                      console.log(res)
                      
                      const updatedFixTexts: FixText[] = res.fixText
                      console.log(updatedFixTexts)
                      this.fixTextService.addFixTextsOnly(updatedFixTexts)

                      const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('fixText')

                     
                      this.fixTexts = []
                      for ( let i=0; i<personalFeatureContainerListItem.featureContainer_ids.length ; i++ ){
                            let currentFeatureContainer: FeatureContainer = this.featureContainerService.getFeatureContainerBy_id(personalFeatureContainerListItem.featureContainer_ids[i])
                            this.fixTexts.push(this.fixTextService.getFixTextBy_id(currentFeatureContainer.componentReference_id))
                      }
                      console.log(this.fixTexts)

                      this.fixTexts.reverse()

                      this.onEmitToParagraphEditChangedIndex( newFeature )

                    }, error => {
                      console.log(error)
                      this.throwingError()
                      // const errorMessage: string = "we weren't able to create the new component, try later, check the network"
                      // this.error = errorMessage
                      // this.isLoadingList = false 
                      // /**
                      //  *  EMIT and event to paragraphEdit to put update or create buttons for 4,5 sec 
                      //  */
                      // this.holdOnCreateUpdateButtons.emit()
                    
                      // /**
                      //  * return to previous set up without the new component after a while to be able to read the message 
                      //  */
                      // setTimeout(()=>{
                      //   this.error = null 
                      //   this.isLoadingList = false 
                      //   this.isLoading = false 
                      // },4000)
                    
                  }) // END 3RD SUBSCIPTION 
               

            }
            

        }, error => {
            console.log(error)
          
            this.throwingError()
            // const errorMessage: string = "we weren't able to create the new component, try later, check the network"
            // this.error = errorMessage
            // this.isLoadingList = false 
            // /**
            //  *  EMIT and event to paragraphEdit to put update or create buttons for 4,5 sec 
            //  */
            // this.holdOnCreateUpdateButtons.emit()
          
            // /**
            //  * return to previous set up without the new component after a while to be able to read the message 
            //  */
            // setTimeout(()=>{
            //   this.error = null 
            //   this.isLoadingList = false 
            //   this.isLoading = false 
            // },4000)
          
        }) // end second subscription 



  }, error => {
    console.log( error )

    // const errorMessage: string = "we weren't able to create the new component, try later, check the network"
    // this.error = errorMessage
    // this.isLoadingList = false 
    // /**
    //  *  EMIT and event to paragraphEdit to put update or create buttons for 4,5 sec 
    //  */
    // this.holdOnCreateUpdateButtons.emit()
  
    // /**
    //  * return to previous set up without the new component after a while to be able to read the message 
    //  */
    // setTimeout(()=>{
    //   this.error = null 
    //   this.isLoadingList = false 
    //   this.isLoading = false 
    // },4000)
    this.throwingError()



      })

  }

  throwingError(){

    const errorMessage: string = "we weren't able to create the new component, try later, check the network"
    this.error = errorMessage
    this.isLoadingList = false 
                        /**
                         *  EMIT and event to paragraphEdit to put update or create buttons for 4,5 sec 
                         */
    this.holdOnCreateUpdateButtons.emit()
                      
                        /**
                         * return to previous set up without the new component after a while to be able to read the message 
                         */
    setTimeout(()=>{
        this.error = null 
        this.isLoadingList = false 
        this.isLoading = false 
    },4000)
  
  }



  onNullUserCreateComponent(){

    console.log('CREATING NEW')

    const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('fixText')
    this.newTabIndex0 = personalFeatureContainerListItem.featureContainer_ids.length+1
    
    const new_id = 'fixT'+ this.newTabIndex0.toString() ;
    const newComponentName = 'fixText'
    const newUIID = 'fixText-'+ this.newTabIndex0.toString()
    const newOwner = this.user; 
    const newAllUsersAllowed = false
    const newUserListAllowed = []
    const newDateAndTime = null; 
    const newName =  'new tab '+ this.newTabIndex0.toString() ;

    const newDetails =  this.fixTexts[0].details ;
    const newFixText = new FixText ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed, newDateAndTime, newName , newDetails);


if ( this.user === null ){ 
  this.fixTextService.onAddNew(newFixText);
     
  const newOwner = this.user; 
  const newIsNew = false;
  const newDropId = this.currentI ;
  const newFeature_id = newFixText._id;
  const newFeatureName = 'fixText' ;
  const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
  this.feature = newFeature

  console.log(' FIX TEXTS = ', this.fixTexts, 'IT S EMITTING CHANGE INDEX FEATURE')

   console.log(this.feature)

  this.createNewFeatureContainerAndAddToFeatureContainerListItemService.createFeatureContainer_toAddOnFeatureContainerListItem(newFeature, this.user )

}

  }

  onEmitToParagraphEditChangedIndex(toEmitFeature: Feature ){
    console.log('is emitting changed feature')
    this.changeIndex.emit(toEmitFeature)
    // once this feature is emitted the paragraphEdit will change index and this whole 
    // component will restart again
  }




  /**
   * 
   * @param data feature that is been transmitted from paragraphEdit to check which of the 
   * features list has to pass the subscription to the feature item to be edited 
   */
  updateCurrentFeature(data){
    this.currentFeature = data;
    console.log(this.currentFeature)
    }
  
    onEditFeatureItem(){
      console.log('is opening another dialog from list component ')
      console.log(this.currentI)
      console.log(this.currentFeature)
  
        if( this.currentFeature.dropId === this.currentI){
          this.clickOnEditFeatureItem.next(this.currentFeature)
         }
     
      console.log('is arrived')
    
    }
  
    ngOnDestroy(){
      this.updateOnUserEmail?.unsubscribe()
      this.receiveFeatureInfoFromParagraphEdit?.unsubscribe();
      this.editFeatureEventFromParagraphEdit?.unsubscribe();
      this.fixTextsHasBeenStoredSubscription?.unsubscribe()
      this.featureContainerPlusParentsHaveBeenStored?.unsubscribe()
    }
  



}
