import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { InitialNullSetupService } from "src/app/services/starting-services/initial-null-setup.service";
import { UserProfile } from "./user-profile.model";


@Injectable ({
    providedIn: 'root'
})

export class UserProfileService {

    constructor(  private initialNullSetupService: InitialNullSetupService , 
               ){ }

    userProfile: UserProfile = this.initialNullSetupService.initialNullUserProfile

    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.userProfileChangedBackToNull
                                                     .subscribe((res)=> {
                                                         this.userProfile = res 
                                                         console.log('updated userProfile back to null = ', res)
                                                     })



}