import { Injectable } from "@angular/core";
import { FormFeatureContainer } from "./form-feature-container.model";
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { Subscription , Subject, Observable} from 'rxjs';
import { UserObjectUpdateService } from "src/app/services/other-components-services/user-object-update.service";
import { PostComponentsHttpRequestService } from "src/app/services/post-and-put-services/post-components-http-request.service";
import { AppLoadingService } from "src/app/services/other-components-services/app-loading.service";
import { FormIndexTopic } from "./form-index-topic.model";
import { StoreFormItemsService } from "src/app/services/get-and-store-components/store-form-items.service";
import { Form } from "../../form.model";


@Injectable({
    providedIn: 'root'
})

export class FormFeatureContainerService {

    constructor( private initialNullSetupService: InitialNullSetupService , 
                 private userObjectUpdateService: UserObjectUpdateService,
                 private appLoadingService: AppLoadingService ,
                 private storeFormItemsService: StoreFormItemsService , 
                 private postComponentsHttpRequestService: PostComponentsHttpRequestService ,
                 ){}


    user = this.userObjectUpdateService.userEmail
    formIndexTopicsOfReference: FormIndexTopic[] 
    counter: number

    formFeatureContainersPlusOwnerAndIndexType = new Subject<any[]>()

    updateOnUserEmail : Subscription = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
        this.user = updatedUserEmail
        console.log('updated USER = ', this.user)
    })


    public formFeatureContainers: FormFeatureContainer[] = this.initialNullSetupService.initialNullFormFeatureContainers

    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.formFeatureContainerChangeBackToNull
   .subscribe((resformFeatureContainers)=> {
          this.formFeatureContainers = resformFeatureContainers 
          console.log('updated formFeatureContainers back to null = ', resformFeatureContainers)
    
    this.appLoadingService.onStoppingAppLoading()

    })

    //  this.formItemsListToAdd.next({ formItemsResults , typeOfIndex , comingFromFormItem });
    updateFormTopicsWithFormItemsFromType: Subscription = this.storeFormItemsService.formItemsListToAdd
       .subscribe((formItemsPlusTypeOfIndex: any)=>{
        console.log(formItemsPlusTypeOfIndex.formItemsResults , formItemsPlusTypeOfIndex.typeOfIndex )

        const typeOfIndex: string = formItemsPlusTypeOfIndex.typeOfIndex
        const formItemsToAdd: Form[] = formItemsPlusTypeOfIndex.formItemsResults

        if ( typeOfIndex === 'share' ){ this.processToBuildSHAREDformFeaturesContainerAndParentsComponents( formItemsToAdd , typeOfIndex ) }
        if ( typeOfIndex === 'public'){ this.processToBuildPUBLICformFeaturesContainerAndParentsComponents( formItemsToAdd , typeOfIndex ) }
       })

    getFormFeatureContainers(){
        return this.formFeatureContainers
    }

    getFormFeatureContainer(i: number){
        return this.formFeatureContainers[i]
    }

    getFormFeatureContainerBy_id(_id: string){
        const newFormFeatureContainer: FormFeatureContainer = this.formFeatureContainers.find(
            (res)=> {
                return res._id === _id
            }
        )
        return newFormFeatureContainer
    }

    updateFormFeatureContainer(_id: string , newFormFeatureContainer: FormFeatureContainer){
       const index = this.formFeatureContainers.findIndex(x=> x._id === _id)
       this.formFeatureContainers[ index ] = newFormFeatureContainer
    }

    getFormFeatureContainersBy_type(type: string){

        let personalFeatureContainerList: FormFeatureContainer[] = this.formFeatureContainers.filter( personalFeatureContainerList=> personalFeatureContainerList.name === 'personal' )
        return personalFeatureContainerList

    }

    getAllSharedPlusPreferenceFormFeatureContainers(){
        let sharedPlusPreferenceFeatureContainerList: FormFeatureContainer[] = []
        let sharedFeatureContainerList: FormFeatureContainer[] = this.formFeatureContainers.filter( sharedFeatureContainerList=> sharedFeatureContainerList.name === 'share' )
        let preferenceFeatureContainerList: FormFeatureContainer[] = this.formFeatureContainers.filter(preferenceFeatureContainerList=> preferenceFeatureContainerList.name === 'preference')

        sharedPlusPreferenceFeatureContainerList.push(...sharedFeatureContainerList)
        sharedPlusPreferenceFeatureContainerList.push(...preferenceFeatureContainerList)
        console.log(sharedFeatureContainerList , preferenceFeatureContainerList , sharedPlusPreferenceFeatureContainerList  )

        return sharedPlusPreferenceFeatureContainerList

    }

    addFormFeatureContainer(formFeatureContainer: FormFeatureContainer){
        this.formFeatureContainers.push(formFeatureContainer)
    }

    deletePublicFormFeatureContainers(_ids: string[]){

        for ( let i=0 ; i< _ids.length ; i++){
            this.formFeatureContainers.splice( this.formFeatureContainers.findIndex(x=> x._id === _ids[i]), 1 )
        }

    }

  
    processToBuildSHAREDformFeaturesContainerAndParentsComponents( sharedFormItemsToAdd: Form[], currentTypeOfIndex: string){

        if ( sharedFormItemsToAdd.length > 0 ){

            /**
             * now for each paragraphTopic of reference we get all the paragraphFeatureContainers 
             */
             const sharedFormFeatureContainersOfReference: FormFeatureContainer[] = []
             for ( let i=0; i< this.formIndexTopicsOfReference.length ; i++ ){
                   for ( let y=0; y<this.formIndexTopicsOfReference[i].formFeatureContainer_ids.length ; y++){
                    sharedFormFeatureContainersOfReference.push(this.formFeatureContainers[this.formFeatureContainers.findIndex(x=> x._id === this.formIndexTopicsOfReference[i].formFeatureContainer_ids[y] )])
                   }
             }

             console.log(sharedFormFeatureContainersOfReference)

             const arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex: any[] = []
             this.counter = 0

             for ( let z=0; z<sharedFormItemsToAdd.length ; z++ ){

                if ( sharedFormFeatureContainersOfReference.findIndex(x=> x.form_id === sharedFormItemsToAdd[z]._id) !== -1 ){
                    this.counter = this.counter+1 
                    
                    this.continueToCheckExistanceOnFormIndexTopic(arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex, sharedFormItemsToAdd, this.counter)
                }

                if ( sharedFormFeatureContainersOfReference.findIndex(x=> x.form_id === sharedFormItemsToAdd[z]._id) === -1 ){
                    
                    const new_id = 'formFeatureContai-'+(this.formFeatureContainers.length+1+z).toString()
                    const newComponentName = 'formFeatureContainer'
                    const newUIID = 'formFeatureContai-'+(this.formFeatureContainers.length+1+z).toString()
                    const newOwner = this.user 
                    const newAllUsersAllowed = false
                    const newUserListAllowed = []
                    const newDateAndTime = null 
                    const newIsActive = true 
                    const newIsAccepted = false 
                    const newName = currentTypeOfIndex
                    const newFormFeatureState = true
                    const newRecipientIndex_id = null 
                    const newFormItem_id = sharedFormItemsToAdd[z]._id 
            
                    const formFeatureContainer = new FormFeatureContainer ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed, 
                          newDateAndTime, newIsActive, newIsAccepted, newName, newFormFeatureState , newRecipientIndex_id, newFormItem_id )
            
                    const receivingFormFeature_owner = sharedFormItemsToAdd[z].owner
                    const typeOfIndex = currentTypeOfIndex

                    arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex.push({ formFeatureContainer , receivingFormFeature_owner, typeOfIndex}),
                    console.log('ARRAY OF FORMFEATURE + OWNER + TYPE ',arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex)
                            
                    this.counter = this.counter+1
                    console.log(this.counter)

                    this.continueToCheckExistanceOnFormIndexTopic(arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex, sharedFormItemsToAdd, this.counter)
            
                    // this.postComponentsHttpRequestService.httpStoreComponent(this.user, 'formFeatureContainer', newFormFeatureContainer)
                    //     .subscribe(
                    //         (formFeatureContainer: FormFeatureContainer)=>{
                                
                    //             this.formFeatureContainers.push(formFeatureContainer),
                    //             console.log(formFeatureContainer, this.formFeatureContainers),
            
                    //             arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex.push({formFeatureContainer , receivingFormFeature_owner, typeOfIndex}),
                    //             console.log('ARRAY OF FORMFEATURE + OWNER + TYPE ',arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex)
                            
                    //             this.counter = this.counter+1
            
                    //             console.log(this.counter)

                    //             this.continueToCheckExistanceOnFormIndexTopic(arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex, sharedFormItemsToAdd, this.counter)

                    //     })
                }
             }
        }
    }

    processToBuildPUBLICformFeaturesContainerAndParentsComponents( sharedFormItemsToAdd: Form[], currentTypeOfIndex: string){

        if ( sharedFormItemsToAdd.length > 0 ){

            /**
             * now for each paragraphTopic of reference we get all the paragraphFeatureContainers 
             */
             console.log("FORM INDEX TOPIC OF REFERENCE = ",this.formIndexTopicsOfReference)
             const sharedFormFeatureContainersOfReference: FormFeatureContainer[] = []
             for ( let i=0; i< this.formIndexTopicsOfReference.length ; i++ ){
                   for ( let y=0; y<this.formIndexTopicsOfReference[i].formFeatureContainer_ids.length ; y++){
                    sharedFormFeatureContainersOfReference.push(this.formFeatureContainers[this.formFeatureContainers.findIndex(x=> x._id === this.formIndexTopicsOfReference[i].formFeatureContainer_ids[y] )])
                   }
             }

             console.log(sharedFormFeatureContainersOfReference)

             const arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex: any[] = []
             this.counter = 0

             for ( let z=0; z<sharedFormItemsToAdd.length ; z++ ){

                    const new_id = 'formFeatureContai-'+(this.formFeatureContainers.length+1).toString()
                    const newComponentName = 'formFeatureContainer'
                    const newUIID = 'formFeatureContainer-'+(this.formFeatureContainers.length+1).toString()
                    const newOwner = this.user 
                    const newAllUsersAllowed = false
                    const newUserListAllowed = []
                    const newDateAndTime = null 
                    const newIsActive = true 
                    const newIsAccepted = true 
                    const newName = currentTypeOfIndex
                    const newFormFeatureState = true
                    const newRecipientIndex_id = null 
                    const newFormItem_id = sharedFormItemsToAdd[z]._id 
            
                    const formFeatureContainer = new FormFeatureContainer ( new_id,newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed, 
                          newDateAndTime, newIsActive, newIsAccepted, newName, newFormFeatureState , newRecipientIndex_id, newFormItem_id )
            
                    const receivingFormFeature_owner = sharedFormItemsToAdd[z].owner
                    const typeOfIndex = currentTypeOfIndex
            
                                
                                this.formFeatureContainers.push(formFeatureContainer),
                                this.initialNullSetupService.initialNullFormFeatureContainers.push(formFeatureContainer)
                                console.log(formFeatureContainer, this.formFeatureContainers),
            
                                arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex.push({formFeatureContainer , receivingFormFeature_owner, typeOfIndex}),
                                console.log('ARRAY OF FORMFEATURE + OWNER + TYPE ',arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex)
                            
                                this.counter = this.counter+1
            
                                console.log(this.counter)

                                this.continueToCheckExistanceOnFormIndexTopic(arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex, sharedFormItemsToAdd, this.counter)

             }
        }
    }


    continueToCheckExistanceOnFormIndexTopic( arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex: any[], sharedFormItemsAdded:Form[], counter: number ){

        if ( counter === sharedFormItemsAdded.length ){

            this.formFeatureContainersPlusOwnerAndIndexType.next(arrayOfFormFeatureContainerAndOwnerAndTypeOfIndex)

        }
    }



}