
<div style="background-color:rgb(242, 248, 250) ;" class="ParagraphComponentFrame">
<!-- <div style="background-color:rgb(242, 248, 250) ;"  class="ParagraphComponentFrame">  -->
   <!-- START  RECIPIENT ---------------------------------------------------------  -->
<!-- 1st recipient-message when hidden => replacemens -->

<!-- 1st recipient-message when VISIBLE -->

  <div *ngIf="user && isProduction"> 

<div *ngIf="!messageRecipientIsTemporaryHidden">
<div *ngIf="!noNeedRecipientAndSubject" 
     style="overflow: hidden;" 
     class="ParagraphComponentFrame">
  
     <div *ngIf="isLoading">
      <br>
      <br>
      <br>
      <!-- <app-loading-spinner></app-loading-spinner> -->
      <br>
      <br>
      <br>
      <br>
      <br>
    </div>

    <div *ngIf="!isLoading">
  <div *ngIf="!recipientIndexListItemExist"
       style="background-color:orangered " 
       class="ParagraphComponentButton" >
    <h2 align="center" style="color: white;">  select a group of recipients </h2>
  </div>
    </div>

  <div *ngIf="recipientIndexListItemExist && user">
  <mat-accordion 
  *ngIf="!isLoading"
  class="example-headers-align " 
  multi>
  <!--  probably i have to communicate the dropId i'm in so to check the result position  -->
    <app-recipient-list 
     [isMessageSide]="isMessageSide"
     [isEditMode]="editMode"
     [recipientIndex]="recipientIndex"
     [recipientIndexListItem]="recipientIndexListItem"
     [isPersonalRecipientIndexListItem]="isPersonalRecipientIndexListItem"
     [paragraphIndexType]="paragraphIndexType"
     [selectedIndex]="selectedRecipient_position"
     (changeIndex)='onChangeIndex($event)'
     [youAreInFormEdit]="youAreInFormEdit"
     (startLoadingOtherRecipient)="onStartLoadingOtherRecipient1()"
     [isLoadingSubRecipientItems]="startLoadRecipientsNumber1.asObservable()"
     [updateRecipiendIndexListItems]="updateSharedRecipientListItem_fromRecipientFeature.asObservable()"
     (emitSecondRecipientResult)='onEmitSecondRecipientResult($event)'
     (emitRecipientResult)="onEmitRecipientResult($event)"
     (emitTemporaryHideRecipientsMessage)="onEmitTemporaryHideRecipientsMessage()"
     (emitTemporaryHideRecipientsMail)="onEmitTemporaryHideRecipientsMail()"
     (emitUpdatedRecipientIndexListItem)="onEmitUpdatedRecipientIndexListItem_afterDeleteToMessageEmail($event)">
     </app-recipient-list>
  </mat-accordion> 

  </div>

</div>
</div>

<!-- </div> -->
<!-- END 1st recipient-message when VISIBLE -->
<div *ngIf="user && isProduction">

<div *ngIf="messageRecipientIsTemporaryHidden && !isLoading" >
  <div  style="overflow: hidden;"  class="ParagraphComponentFrame">
    <svg *ngIf="recipientIndex.useMyOwnMailRecipient" (click)="onRestoreRecipientMessage()"  align="center" style="color: #3f51b5;cursor: pointer;" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-chat-left-quote-fill" viewBox="0 0 16 16">
      <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm7.194 2.766a1.688 1.688 0 0 0-.227-.272 1.467 1.467 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 5.734 4C4.776 4 4 4.746 4 5.667c0 .92.776 1.666 1.734 1.666.343 0 .662-.095.931-.26-.137.389-.39.804-.81 1.22a.405.405 0 0 0 .011.59c.173.16.447.155.614-.01 1.334-1.329 1.37-2.758.941-3.706a2.461 2.461 0 0 0-.227-.4zM11 7.073c-.136.389-.39.804-.81 1.22a.405.405 0 0 0 .012.59c.172.16.446.155.613-.01 1.334-1.329 1.37-2.758.942-3.706a2.466 2.466 0 0 0-.228-.4 1.686 1.686 0 0 0-.227-.273 1.466 1.466 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 10.07 4c-.957 0-1.734.746-1.734 1.667 0 .92.777 1.666 1.734 1.666.343 0 .662-.095.931-.26z"/>
    </svg>
    <svg *ngIf="!recipientIndex.useMyOwnMailRecipient" (click)="onRestoreRecipientMessage()"  align="center" style="color: orangered;cursor: pointer;" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-chat-left-quote-fill" viewBox="0 0 16 16">
      <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4.414a1 1 0 0 0-.707.293L.854 15.146A.5.5 0 0 1 0 14.793V2zm7.194 2.766a1.688 1.688 0 0 0-.227-.272 1.467 1.467 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 5.734 4C4.776 4 4 4.746 4 5.667c0 .92.776 1.666 1.734 1.666.343 0 .662-.095.931-.26-.137.389-.39.804-.81 1.22a.405.405 0 0 0 .011.59c.173.16.447.155.614-.01 1.334-1.329 1.37-2.758.941-3.706a2.461 2.461 0 0 0-.227-.4zM11 7.073c-.136.389-.39.804-.81 1.22a.405.405 0 0 0 .012.59c.172.16.446.155.613-.01 1.334-1.329 1.37-2.758.942-3.706a2.466 2.466 0 0 0-.228-.4 1.686 1.686 0 0 0-.227-.273 1.466 1.466 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 10.07 4c-.957 0-1.734.746-1.734 1.667 0 .92.777 1.666 1.734 1.666.343 0 .662-.095.931-.26z"/>
    </svg>
  </div>
</div>
</div>
<!-- END  1st recipient hidden replacement -->

</div> 
<!-- END OF MESSAGE RECIPIENT PART -->

<!-- repeat the recipient  -->
<!-- 2ndt recipient-mail when HIDDEN  -->
<div *ngIf="mailRecipientIsTemporaryHidden">
  <div  style="overflow: hidden;" class="ParagraphComponentFrame">
    <svg (click)="onRestoreRecipientMail()" align="center" style="color: grey;cursor: pointer;" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-send-fill" viewBox="0 0 16 16">
      <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"/>
    </svg>
  </div>
</div>
<!-- 2ndt recipient-mail when HIDDEN -->

    
<!-- 2ndt recipient-mail when VISIBLE -->
<div *ngIf="!mailRecipientIsTemporaryHidden">
<div *ngIf="!noNeedRecipientAndSubject" 
      style="overflow: hidden;" 
      class="ParagraphComponentFrame">
      
      <div *ngIf="isLoading">
       <br>
       <br>
       <br>
       <!-- <app-loading-spinner></app-loading-spinner> -->
       <br>
       <br>
       <br>
       <br>
       <br>
      </div>
      
      <div *ngIf="!isLoading">
      <div *ngIf="!recipientIndexListItemExist"
        style="background-color:orange " 
        class="ParagraphComponentButton" >
      <h2 align="center" style="color: white;">  select a group of recipients </h2>
      </div>
      </div>
      
      
      <div *ngIf="recipientIndexListItemExist">
      <mat-accordion 
      *ngIf="!isLoading"
      class="example-headers-align " 
      multi>
      
      <app-recipient-list 
      [isMessageSide]="!isMessageSide"
      [isEditMode]="editMode"
      [recipientIndex]="recipientIndex"
      [recipientIndexListItem]="recipientIndexListItem"
      [isPersonalRecipientIndexListItem]="isPersonalRecipientIndexListItem"
      [selectedIndex]="selectedRecipient_position"
      (changeIndex)='onChangeIndex($event)'
      [youAreInFormEdit]="youAreInFormEdit"
      (startLoadingOtherRecipient)="onStartLoadingOtherRecipient2()"
      [isLoadingSubRecipientItems]="startLoadRecipientsNumber2.asObservable()"
      [updateRecipiendIndexListItems]="updateSharedRecipientListItem_fromRecipientFeature.asObservable()"
      (emitSecondRecipientResult)='onEmitSecondRecipientResult($event)'
      (emitRecipientResult)="onEmitRecipientResult($event)"
      (emitTemporaryHideRecipientsMessage)="onEmitTemporaryHideRecipientsMessage()"
      (emitTemporaryHideRecipientsMail)="onEmitTemporaryHideRecipientsMail()"
      (emitUpdatedRecipientIndexListItem)="onEmitUpdatedRecipientIndexListItem_afterDeleteToMessageEmail($event)">
      </app-recipient-list>
      </mat-accordion> 
      
      </div>
      
</div>
</div>  
<!-- END 2ndt recipient-mail when VISIBLE -->
      <!-- end copy recipient  -->





 <!-- END RECIPIENT ------ ---------------------------------------------------------  -->

 <div *ngIf="editMode && isAnnouncingIsEditingAFormWithLoadingSpinner" >
  <br>
  <div class="row">
    <div class="col-xs-12">
      <div class="col-xs-2"></div>
      <div class="col-xs-1">
        <app-single-loading-spinner style="color: #3f51b5;"></app-single-loading-spinner>
      </div>
      <div class="col-xs-5">
        <div class="row">
          <h2 align="center" style="color: #3f51b5;">editing saas  </h2>
        </div>
       
      </div>
      <div class="col-xs-2">
        <app-single-loading-spinner style="color: #3f51b5;"></app-single-loading-spinner>
      </div>
      <div class="col-xs-2"></div>
    </div>
  </div>

 </div>


     <!-- START  SUBJECT ---------------------------------------------------------  -->
     
      <div class="ParagraphComponentFrame">

        <app-form-subject 
        [isFixedSubject]="isFixedSubject"
        [youAreInFormEdit]="youAreInFormEdit"
        [formSubject]="form.formSubject"
        (emitSubjectTextResult)="onUpdateSubjectTextResult($event)"
        (emitSubjectIsFixed)="onEmitIfSubjectIsFixed($event)">
        </app-form-subject>
    
      </div>
    
     <!-- END  SUBJECT ---------------------------------------------------------  -->



    <!-- START  greetings HIDE MOMENTARELY ---------------------------------------------------------  -->
    <!-- <div  *ngIf="!noNeedRecipientAndSubject" >
     <div *ngIf="!isLoading" >
      <div *ngIf="mailTextRecipientGreetings.length > 0 && isEmailMessage || isEmailOnly "
            class="ParagraphComponentFrame" >
            <br>
          <div *ngIf="!isLoadingReceiverResult">
            <div class="row">
             
                <mat-form-field class="custom-form-field">
                  <input
                  matInput 
                  readonly
                  type="text" 
                  value="{{recipient.greeting}}"
                  maxlength="150"
                  class="smaller-text">
                  <mat-hint  align="end"> edit greetings above on 'COMPLETE EMAIL RECIPIENT'  </mat-hint>
                </mat-form-field>
            </div>
            <br>
          </div>
      </div>
     </div>
    </div> -->
    
     <!-- END  greetings ---------------------------------------------------------  -->





     <!-- START  PARAGRAPHS ---------------------------------------------------------  -->

     <!-- this is for mobile version where paragraphs can't be dragged and dropped 
    the space is too little and it doesn't work well on mobile to scroll because of the dragdrop i guess  -->
    <!-- START MOBILE PARAGRAPH VERSION NO DRAG DROP -->

    <div *ngIf="isHandset$ | async">
     
     <div [hidden]="!dropped" >
     
       <div *ngIf="isHandset$ | async">
        <br>
        <br>
       <h1 (click)="onOpenSideNav()" 
           align="center" 
           [hidden]="!onlyParagraph" 
           style="color: #3f51b5;">  
          
         <svg  style="color: #3f51b5" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
           <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
         </svg>
         select paragraphs
       </h1>
       </div>
     
       <!-- <div *ngIf="(isHandset$ | async) === false">
       <h1 align="center" [hidden]="!onlyParagraph" style="color: #3f51b5;">
         <svg style="color: #3f51b5" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
           <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
         </svg>
         select paragraphs
       </h1>
       </div> -->
      
      
     </div>
     
     <!-- <div *ngIf="isLoadingANewParagraphFeature">
       <app-loading-spinner *ngIf="(isHandset$ | async) === false"></app-loading-spinner>
       <app-triple-loading-spinner *ngIf="isHandset$ | async"></app-triple-loading-spinner>
     </div> -->
     
     <!-- <div *ngFor="let paragraphFeature of paragraphFeatures ; let i=index" cdkDrag [cdkDragStartDelay]="300"> -->
    <div *ngFor="let paragraphFeature of paragraphFeatures ; let i=index">
     
     <div class="ParagraphComponentFrame">
     
       <!-- here below on desktop mode -->
     <!-- <div *ngIf="(isHandset$ | async) === false" class="row">
     
       <div class="col-xs-1 pointer" *ngIf="paragraphFeature.owner === user" >
         <div>
           <svg (click)="onEditParagraph(i)" 
            style="color:  lightgrey" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
           <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
           </svg>
         </div>
       </div>
     
       <div class="col-xs-1" *ngIf="paragraphFeature.owner !== user" ></div>
         
       <div class="col-xs-9"  style="color: #3f51b5;" >
                      <app-paragraph-description
                       [paragraphFeature]="paragraphFeature"> 
                      </app-paragraph-description>
            
       </div>
     
     
       <div class="col-xs-1 pointer"  >
         <svg  (click)="onRemoveParagraphFeature(i)"
         style="color: lightgrey" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
         <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
         </svg>
       </div>
     
       <div class="col-xs-1"></div>
     
      
     </div> -->
     
     <!-- here below in mobile mode -->
     <div *ngIf="isHandset$ | async" class="row">
     
     
       <div class="col-1" *ngIf="paragraphFeature.owner === user" >
         <div >
           <svg (click)="onEditParagraph(i)" style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
             <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
           </svg>
         </div>
       </div>
     
       <div class="col-1" *ngIf="paragraphFeature.owner !== user"></div>
     
       <div class="col-9"  
            style="color: #3f51b5;">
                <app-paragraph-description
                 [paragraphFeature]="paragraphFeature"> 
                </app-paragraph-description>
       </div>
     
       <div class="col-1" (click)="onRemoveParagraphFeature(i)" >
         <svg 
         style="color: lightgray; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
         <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
         </svg>
       </div>
     
       <div class="col-xs-1"></div>
     
     </div> 
     
     <br>
     <app-paragraph-item 
       [youAreInFormEdit]="youAreInFormEdit"
       [paragraphFeature]="paragraphFeature"
       [isFormItem]="isFormItem"
       [index_id]="paragraphFeature.paragraph_id"
       (updateBothParagraphResultAndTextPlusIndex)='onUpdateBothParagraphResultTextResultAndIndex($event)'
       (paragraphHasBeenUploadedStopLoadingSpinner)='onStopParagraphItemLoadingSpinner()'>
     </app-paragraph-item>

     <br>

     <!-- start duplicate paragraph button  -->
     <svg *ngIf="paragraphFeature.owner === user"
          (click)="onDuplicateParagraph(i)"
          style="color: lightgrey;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16">
         <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
     </svg>
     <!-- end duplicate button -->
     
     
     </div>
     </div>
     <!-- close the list of paragraphs  -->
     
     
     <div #newParagraphLoadingArea></div>
     
     <div *ngIf="isLoadingANewParagraphFeature" >
       <!-- <app-loading-spinner *ngIf="(isHandset$ | async) === false"></app-loading-spinner> -->
       <app-triple-loading-spinner *ngIf="isHandset$ | async"></app-triple-loading-spinner>
     </div>

    </div>
     
     
      <!-- END MOBILE PARAGRAPH VERSION NO DRAG DROP -->
      
      


      <!-- START PARAGRAPH DESKTOP version with drag and drop available  -->
<div *ngIf="!(isHandset$ | async)"
id="list-5"
cdkDropList
[cdkDropListData]="paragraphFeatures"
(cdkDropListDropped)="onDropParagraphFeature($event)">

<div [hidden]="!dropped" >

  <div *ngIf="(isHandset$ | async) === false">
    <br>
    <br>
  <h1 align="center" [hidden]="!onlyParagraph" style="color: #3f51b5;">
    <svg style="color: #3f51b5" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
      <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
    </svg>
    select paragraphs
  </h1>
  </div>
 
 
</div>


<div *ngFor="let paragraphFeature of paragraphFeatures ; let i=index" cdkDrag [cdkDragStartDelay]="300">

<div class="ParagraphComponentFrame"
     matTooltip="DRAG & DROP"
     matTooltipPosition="above">

  <!-- here below on desktop mode -->
<div *ngIf="(isHandset$ | async) === false" class="row">

  <div class="col-xs-1" *ngIf="paragraphFeature.owner === user" >
    <div>
      <svg (click)="onEditParagraph(i)" 
       style="color:  lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
      <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
      </svg>
    </div>
  </div>

  <div class="col-xs-1" *ngIf="paragraphFeature.owner !== user" ></div>
    
  <div class="col-xs-9"  style="color: #3f51b5;" >
                 <app-paragraph-description
                  [paragraphFeature]="paragraphFeature"> 
                 </app-paragraph-description>
       
  </div>


  <div class="col-xs-1"  >
    <svg  (click)="onRemoveParagraphFeature(i)"
    style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
    </svg>
  </div>

  <div class="col-xs-1"></div>

 
</div>

<!-- here below in mobile mode -->
<!-- <div *ngIf="isHandset$ | async" class="row">


  <div class="col-xs-1" *ngIf="paragraphFeature.owner === user" >
    <div >
      <svg (click)="onEditParagraph(i)" style="color: lightgrey" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
        <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
      </svg>
    </div>
  </div>

  <div class="col-xs-1" *ngIf="paragraphFeature.owner !== user"></div>

  <div class="col-xs-9"  
       style="color: #3f51b5;">
           <app-paragraph-description
            [paragraphFeature]="paragraphFeature"> 
           </app-paragraph-description>
  </div>

  <div class="col-xs-1" (click)="onRemoveParagraphFeature(i)" >
    <svg 
    style="color: lightgray" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
    </svg>
  </div>

  <div class="col-xs-1"></div>

</div>  -->

<br>

<app-paragraph-item 
  [youAreInFormEdit]="youAreInFormEdit"
  [paragraphFeature]="paragraphFeature"
  [isFormItem]="isFormItem"
  [index_id]="paragraphFeature.paragraph_id"
  (updateBothParagraphResultAndTextPlusIndex)='onUpdateBothParagraphResultTextResultAndIndex($event)'
  (paragraphHasBeenUploadedStopLoadingSpinner)='onStopParagraphItemLoadingSpinner()'>
</app-paragraph-item>

<br>

  <!-- start duplicate paragraph button  -->
<!-- <svg *ngIf="paragraphFeature.owner === user"
     (click)="onDuplicateParagraph(i)"
     matTooltip=" add paragraph's duplicate"
     matTooltipPosition="right"
     style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
</svg> -->
 <!-- end duplicate paragraph button  -->

</div>
</div>
<!-- close the list of paragraphs  -->


<div #newParagraphLoadingArea></div>

<div *ngIf="isLoadingANewParagraphFeature" >
  <app-loading-spinner *ngIf="(isHandset$ | async) === false"></app-loading-spinner>
</div>

</div>

 <!-- END PARAGRAPH DESKTOP version with drag and drop available  -->
  


 <!-- START SEND BUTTONS ---------------------------------------------------------  -->
 <div *ngIf="!editMode">


  <div  *ngIf="droppedRecipientAndParagraphs">
    <div *ngIf="!isSendingMessageEmail">

    <div class="row">
      <div class="col-xs-12">
        <div style="background-color:orange " class="ParagraphComponentButton pointer" (click)="onClearForm()" >
          <h2 align="center" style="color: white"> clear </h2>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">

        <div *ngIf="finalSubjectIsPresent" style="background-color:forestgreen " class="ParagraphComponentButton pointer" (click)="onOpenAddFormDialog()" >
          <div *ngIf="!(messageRecipientIsTemporaryHidden && mailRecipientIsTemporaryHidden)">
           
            <div *ngIf="isFixedSubject">
              <h2 *ngIf="isMessageOnly || isEmailOnly || isEmailMessage || !isEmailMessage"  align="center" style="color: white;"> save fixed subject saas </h2>
            </div>

            <div *ngIf="!isFixedSubject">
              <h2 *ngIf="isMessageOnly || isEmailOnly || isEmailMessage || !isEmailMessage"  align="center" style="color: white;"> save saas </h2>
            </div>
         
          </div>
          <h2 *ngIf="messageRecipientIsTemporaryHidden && mailRecipientIsTemporaryHidden"  align="center" style="color: white;"> save read-only saas </h2>
        </div>

        <div *ngIf="!finalSubjectIsPresent" style="background-color: white " class="ParagraphComponentButton pointer" (click)="onScrollToSubject()" >
          <h2 align="center" style="color: #3f51b5 ;"> set subject to save saas </h2>
        </div>
      </div>
    </div>

    </div>
  </div>

  <!-- <br> -->

  <app-loading-spinner *ngIf="isSendingMessageEmail"> </app-loading-spinner>
  <div *ngIf="!isSendingMessageEmail">
  <div  *ngIf="droppedRecipientAndParagraphs"  class="row">
    <div *ngIf="!noNeedRecipientAndSubject" >
      <div *ngIf="isMessageOnly || isEmailOnly || isEmailMessage " class="col-xs-12">

      <div *ngIf="isMessageOnly" 
           style="background-color:#3f51b5" 
           class="ParagraphComponentButton pointer" 
           (click)="onSendForm()" >
       <h2  align="center" style="color: white;" >send message</h2>
      </div>

      <div *ngIf="isEmailOnly" 
           style="background-color:#3f51b5" 
           class="ParagraphComponentButton pointer" 
           (click)="onSendForm()" >
       <h2  align="center" style="color: white;" >send </h2>
       <!-- email -->
      </div>

      <div *ngIf="isEmailMessage"  
           style="background-color:#3f51b5" 
           class="ParagraphComponentButton pointer" 
           (click)="onSendForm()" >
        <h2  align="center" style="color: white;">  send message + email </h2>
      </div>

      </div>

      <div *ngIf="!isMessageOnly && !isEmailOnly && !isEmailMessage">
        <div style="background-color: white" class="ParagraphComponentButton pointer" (click)="onScrollToTop()"   >
          <h2  align="center" style="color: #3f51b5;" >set recipients to send </h2>
         </div>
      </div>

    </div>
  </div>
  </div>


</div>

<!-- END SEND BUTTONS ---------------------------------------------------------  -->

<!-- START UPDATE FORM BUTTONS ---------------------------------------------------------  -->

<!-- <div *ngIf="editMode === true && dropped === false" > -->
  <div *ngIf="editMode && !dropped" >

  <div *ngIf="!recipientIndexListItemExist"
       style="background-color:orange " 
       class="ParagraphComponentButton" >
       <h2  align="center" style="color: white;">  select a group of recipients </h2>
  </div>
 
  <div *ngIf="finalSubjectIsPresent">
  <div *ngIf="recipientIndexListItemExist"
       style="background-color:#3f51b5" 
       class="ParagraphComponentButton pointer" 
       (click)="onUpdateForm()" >
    <h2  align="center" style="color: white;">  update saas</h2>
  </div>
  </div>
  <div *ngIf="!finalSubjectIsPresent">
    <div *ngIf="recipientIndexListItemExist"
         style="background-color:white" 
         class="ParagraphComponentButton"
         (click)="onScrollToSubject()" >
      <h2  align="center" style="color: #3f51b5;"> set a subject to update saas </h2>
    </div>
    </div>

</div>

</div>

<!-- --------------------------------------------------------------------------------------- -->

<!-- whatsapp desktop and mobile input  -->
<!-- <div class="row">
  <div class="col-xs-12">
    <a href="https://web.whatsapp.com/send?text=ghe semo" data-text="Take a look at this awesome website:" class="wa_btn wa_btn_s" >Share WA desktop</a>

    <span class="spacer"></span>
    <a href="whatsapp://send?text=ghe semo" data-text="Take a look at this awesome website:" class="wa_btn wa_btn_s">share mobile WA</a>
  
  
    
  </div>
</div> -->

<!-- END  whatsapp desktop and mobile input  -->

<!-- <div class="ParagraphComponentFrame">
    <app-shared-calendar-status-item></app-shared-calendar-status-item>
</div> -->





<!-- <app-select></app-select>
<app-data-converter></app-data-converter>

<app-select-list></app-select-list> -->

<!-- <div class="row">
  <div class="col">
    <app-exchange-rate></app-exchange-rate>
  </div>
</div> -->

<!-- <div class="row">
  <div class="col">
    <app-chart-edit></app-chart-edit>
  </div>
</div> -->

<!-- <app-charts></app-charts> -->

<!-- <div class="row">
  <div class="col">
    <app-data-converter></app-data-converter>
  </div>
</div> -->



<!-- <div class="ParagraphComponentFrame">
  <app-link-grid-item></app-link-grid-item>
</div>
<div class="ParagraphComponentFrame">
  <app-link-grid-edit></app-link-grid-edit>
</div>
<div class="ParagraphComponentFrame">
  <app-link-grid-item></app-link-grid-item>
</div> -->

<!-- <app-qr-code-generator-edit></app-qr-code-generator-edit> -->

<!-- <app-qr-code-scanner></app-qr-code-scanner>


<app-qr-code-generator-item></app-qr-code-generator-item> -->

<!-- <app-printer></app-printer> -->

<!-- <app-auth></app-auth> -->

<!-- <div class="row">
  <app-calculator></app-calculator>
</div>

<br> -->


<!-- <svg style="background-color: blue ; color: yellow" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>
<svg style="background-color: blue ;color: yellow" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>
<svg style="background-color: blue ;color: yellow" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>
<svg style="color: yellow" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg>
<svg style="color: yellow" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16">
  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
</svg> -->



<!-- 
<app-single-slider-edit></app-single-slider-edit> -->

<!-- <app-tab-item [index]="1"></app-tab-item> -->



<!-- <app-tab-list></app-tab-list>

<hr>

<app-single-slider-item></app-single-slider-item> -->

<!-- <app-contact-list></app-contact-list> -->

<!-- </form> -->

<!-- <br>


N.Y. time {{timeNY | date }} using date pipe 
<br>
indonesia time {{timeHere }} without pipe


<hr>

{{date }} -->



<!-- </div> -->
<!-- 
<app-drop-down-select-item></app-drop-down-select-item> -->
<!-- <app-single-drop-down-select-item></app-single-drop-down-select-item> -->
<!-- <div class="row">
  <div class="col-xs-12">
    <div class="ParagraphComponentItem">
      <app-check-box-select-item></app-check-box-select-item>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-xs-12">
       <div  class="ParagraphComponentItem">
        <app-charts></app-charts>
       </div>
  </div>
</div> -->
<!-- 
<app-single-slider-item></app-single-slider-item> -->
<!-- <app-multi-entry-calendar-item></app-multi-entry-calendar-item> -->
<!-- <app-calendar-item></app-calendar-item> -->
<!-- <app-calendar-list></app-calendar-list> -->
<!-- <app-one-date-calendar-result></app-one-date-calendar-result> -->


<!-- <div class="row">
  <div class="col-xs-12">
    <div class="ParagraphComponentItem">
      <app-horizon-add></app-horizon-add>
    </div>
  </div>
</div> -->

<!-- <div class="row">
  <div class="col-xs-12">
    <div class="ParagraphComponentItem">
      <app-calculator-mix-table-item></app-calculator-mix-table-item>
    </div>
  </div>
</div> -->



<!-- <div class="row">
  <div class="col-xs-12">
    <div class="ParagraphComponentItem">
      <app-fix-layout-edit></app-fix-layout-edit>
    </div>
  </div>
</div> -->


<!-- <div class="row">
  <div class="col-xs-12">
    <div class="ParagraphComponentItem">
      <app-fix-layout-item></app-fix-layout-item>
    </div>
  </div>
</div> -->





<!-- <div class="ParagraphComponentItem">
  <app-form-input></app-form-input>
</div> -->




 <!-- <app-sibiling1></app-sibiling1> 
 <app-sibiling2></app-sibiling2>  -->
<!--
 <app-form-input></app-form-input>

 <select class="form-select" size="5" aria-label="size 3 select example">
  <option selected>Open this select menu</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>

<app-slider-item></app-slider-item> -->

<!-- angular google map  -->
<!-- https://stackblitz.com/edit/angular-google-maps-demo?file=app%2Fapp.component.html -->



