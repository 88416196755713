export class FeatureContainerListItem {

    constructor( public _id: string , 
                 public componentName: string , 
                 public UIID: string , 
                 public owner: string , 
                 public allUsersAllowed: boolean , 
                 public userListAllowed: string [], 
                 public dateAndTime: string ,  
                 public type: string ,
                 public name: string , 
                 public featureContainer_ids: string[] , // list of featureContainer_id
                 public selectedFeatureContainer_id: string){} 
}