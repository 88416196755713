import { Component, OnInit , ViewEncapsulation} from '@angular/core';
import { FormGroup , FormControl} from '@angular/forms';

import { RecipientIndex } from 'src/app/forms/form-list/form-edit/recipient-index-list/recipient-index.model';
import { RecipientIndexService } from 'src/app/forms/form-list/form-edit/recipient-index-list/recipient-index.service';
import { RecipientId } from 'src/app/forms/form-list/form-edit/recipient-index-list/recipient-id.model';

import { Contact } from 'src/app/contacts/contact.model';
import { ContactService } from 'src/app/contacts/contact.service';

import { Recipient } from 'src/app/forms/form-list/form-item/recipient/recipient.model';
import { RecipientService } from 'src/app/forms/form-list/form-item/recipient/recipient.service';

import { Chat } from '../chat.model';
import { ChatService } from '../chat.service';
import { PartecipantGroup } from '../partecipants.model';
import { Sender } from 'src/app/forms/form-results/form-results-list/form-result-item/sender/sender.model';

import { DateTime } from 'luxon';

import { ChatHttpRequestService } from '../../services/post-and-put-services/chat-http-request.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { RecipientIndexListItem } from 'src/app/forms/form-list/form-edit/recipient-index-list/recipient-index-list-item.model';

@Component({
  selector: 'app-new-chat-dialog',
  templateUrl: './new-chat-dialog.component.html',
  styleUrls: ['./new-chat-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NewChatDialogComponent implements OnInit {

  user: string 

  weAreOnShareParagraphFormIndex = false

  newChatGroupName: FormGroup
  newContactName: FormGroup

  recipient: Recipient;
  recipients: Recipient[];
  newRecipient: Recipient

  recipientIndex: RecipientIndex;
  recipeintIndexes: RecipientIndex[];
  recipientId: RecipientId
  recipientIndexListItem: RecipientIndexListItem

  contact: Contact;
  contacts: Contact[];

  chat: Chat;
  newChat: Chat;
  partecipantGroup: PartecipantGroup
  sender: Sender 
  senderChatPartecipants: string[];

  isContactEditMode: false

  recipientIndexName: string 
  recipientIndexIndex: number
  count: number
  new_recipient_id : string ;

  contactList: string[]

  timeAtCreateChat: string 


  constructor( private recipientIndexService: RecipientIndexService,
               private recipientService: RecipientService,
               private contactService: ContactService,
               private chatService: ChatService,
               private userObjectUpdateService: UserObjectUpdateService ,
               private chatHttpRequestService: ChatHttpRequestService) { }

  ngOnInit(): void {

    this.user = this.userObjectUpdateService.userEmail

    this.newChatGroupName = new FormGroup ({
      name : new FormControl (''),
    })

    this.newContactName = new FormGroup ({
      contactName: new FormControl ('')
    })

    this.recipeintIndexes = this.recipientIndexService.getRecipientIndexes()
    this.recipients = this.recipientService.getRecipients()
    this.contacts = this.contactService.getContacts()

    // this.recipientIndexName = this.recipeintIndexes[0].name
    this.recipientIndexIndex = 0

    this.count = 1;

    this.contactList = []


  }


  onToggleRecipientIndexName(){

   // this.recipientIndexName = this.recipeintIndexes[this.count++].name
    if(this.count === this.recipientIndexService.getRecipientIndexes().length){this.count = 0}

    this.recipientIndexIndex = this.recipientIndexIndex+1
    if(this.recipientIndexIndex === this.recipientIndexService.getRecipientIndexes().length ){
      this.recipientIndexIndex = 0
    }
    console.log(this.recipientIndexIndex)

  }

  /**
   * 
   * @param event receiving the contact to be removed , from contact item sub component
   */
  onRemoveContactFromList(event){
   this.contactList.forEach((element,index)=>{
     if(element == event.contactEmail) this.contactList.splice(index, 1)
     })
   console.log(this.contactList)
  }

/**
 * 
 * @param event receiving the contact from contact item sub component and adding the email to the list
 */
  onAddContactToList(event){
    this.contactList.push(event.contactEmail)
    console.log(this.contactList)
  }

  /**
   * creating a new recipient , new recipientIndex , and chat 
   */
  onCreateNewChatRecipient(){

    /**
     * to fix we need to know the recipientIndexListItem !! here is 
     */

    this.timeAtCreateChat = DateTime.now().toString();

    this.onCreateNewRecipient()
    this.onUpdateRecipientIndex()
    this.onCreateNewChat()
    this.chatService.sendUpdateToNewChatIndexList(this.newChat._id)

  }

  onCreateNewRecipient(){

    const new_recipient_id = 'recipi'+(this.recipientIndexListItem.recipientIndexList_ids.length+1).toString()
    const newComponentName = 'recipient'
    const newUIID = 'recipient-'+(this.recipientIndexListItem.recipientIndexList_ids.length+1).toString()
    //this.new_recipient_id = new_id
    const newOwner = this.user
    const newDateAndTime = null;
    const newUpdateDateAndTime = null 
    const newChat_roomId = 'chat-room-id-'+(this.recipientService.getRecipients().length+101).toString();
    const newAllUsersAllowed = null;
    const newUserListAllowed = null; 
    const newBlockedUserList = null;
    const newRecipientIndex_id = this.recipientIndexService.getRecipientIndex(this.recipientIndexIndex)._id
    const newName = this.newChatGroupName.value.name ;
    const newToMessage = this.contactList ;
    const newRecipientList = []
    const newToMail = [];
    const newCcmail = [];
    const newBccMail = [];
    const newGreeting = '' ;

const newRecipient = new Recipient ( new_recipient_id, newComponentName, newUIID, newOwner, newDateAndTime, newUpdateDateAndTime, newChat_roomId,
                                     newAllUsersAllowed, newUserListAllowed, newBlockedUserList
                                      ,newName, newRecipientList, newToMessage, 
                                     newToMail, newCcmail, newBccMail , newGreeting);

this.newRecipient = newRecipient;
this.recipientService.addRecipient(this.newRecipient);
 

  }

  onUpdateRecipientIndex(){

     /** creating a new recipientId  */
    //  const newId =  this.new_recipient_id
    //  const newRecipientIdName =  this.newChatGroupName.value.name ;
    //  const newRecipientId = new RecipientId  (newId, newRecipientIdName) ;
     
   //  this.recipientIndexService.getRecipientIndex(this.recipientIndexIndex).recipientIds.push(newRecipientId)

  }

  onCreateNewChat(){

    const newChatSender = new Sender ( this.user );
  
    this.senderChatPartecipants = [];
    this.senderChatPartecipants.push(this.user)


    for ( let i=0; i<this.contactList.length ; i++){
      this.senderChatPartecipants.push(this.contactList[i])
    }
    // const newPartecipants = this.senderChatPartecipants.sort()
    const newPartecipants = this.senderChatPartecipants
    console.log('new partecipants = ',newPartecipants) ;
  
    const newPartecipantGroup = new PartecipantGroup (newChatSender , newPartecipants) ;
    console.log(newPartecipantGroup)

    const new_id = (this.chatService.getChats().length+1).toString()
    const newOwner = this.user ;
    const newAllUsersAllowed = false ;
    const newUserListAllowed = [];
    const newIsVisible = true; 
    const newDateAndTime = this.timeAtCreateChat;
    const newUpdateDateAndTime = null
    const newIsNewChat = true ;
    const newIsBeenUpdated = false;
    const newIsSelected = false;
    const newSubject = '--- --- ---' ;
  
    const newSentChat = new Chat ( 
                                   new_id, 
                                   newOwner, 
                                   newAllUsersAllowed, 
                                   newUserListAllowed ,
                                   newIsVisible,
                                   newDateAndTime , 
                                   newUpdateDateAndTime,
                                   newIsNewChat ,  
                                   newIsBeenUpdated , 
                                   newIsSelected , 
                                   newSubject)
   
    console.log(newSentChat)

    this.newChat = newSentChat
    // this.chatHttpRequestService.httpStoreChat(newSentChat)
    this.chatService.addChat(this.newChat)

  }




}
