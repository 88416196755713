<div>
  <!-- cdkDropListGroup -->

<mat-toolbar color="primary" class="mat-toolbar" >
</mat-toolbar>

<mat-toolbar color="primary" class="mat-toolbar">

<mat-toolbar-row color="primary" >

  
                <button *ngIf="typeOfFormIndexTopic.isPersonal"
                [hidden]="(isHandset$ | async)"
                type="button"
                aria-label="Toggle sidenav"
                mat-icon-button
                (click)="onSharedParagraphsDesktop()">
                <mat-icon style="color: white" aria-label="Side nav toggle icon"> menu </mat-icon>
                </button>
                <button *ngIf="typeOfFormIndexTopic.isPublic"
                [hidden]="(isHandset$ | async)"
                type="button"
                aria-label="Toggle sidenav"
                mat-icon-button
                (click)="onPersonalParagraphsDesktop()">
                <mat-icon style="color: white" aria-label="Side nav toggle icon"> menu </mat-icon>
                </button>
                <button *ngIf="typeOfFormIndexTopic.isShared"
                [hidden]="(isHandset$ | async)"
                type="button"
                aria-label="Toggle sidenav"
                mat-icon-button
                (click)=onPreferredParagraphsDesktop()>
                <mat-icon style="color: white" aria-label="Side nav toggle icon"> menu </mat-icon>
                </button>
                <button *ngIf="typeOfFormIndexTopic.isPreferred"
                [hidden]="(isHandset$ | async)"
                type="button"
                aria-label="Toggle sidenav"
                mat-icon-button
                (click)="onPublicParagraphsDesktop()">
                <mat-icon style="color: white" aria-label="Side nav toggle icon"> menu </mat-icon>
                </button>

                <button
                *ngIf="isHandset$ | async"
                type="button"
                aria-label="Toggle sidenav"
                mat-icon-button
                (click)="onToggleIndexMenuMobile()"
                (click)="drawer.toggle()">
               
                <mat-icon style="color: white" aria-label="Side nav toggle icon"> menu </mat-icon>
                </button>


                <div *ngIf="typeOfFormIndexTopic.isPersonal">
                  <div  [hidden]="!isOpenIndexDesktop"> 
                    <a [hidden]="isHandset$ | async" (click)="onSharedParagraphsDesktop()" class="navbar-brand" ><span style="color: white"  class="mat-button-wrapper" >  personal  </span>
                    
                    </a>
                  </div>
                 
                  <a mat-icon-button
                  [hidden]="!isOpenIndexMobile"
                  (click)="onSharedParagraphsMobile()"
                  *ngIf="isHandset$ | async">
             
                <span style="color:white"   class="mat-button-wrapper">  personal </span>
               
              </a>
                </div>


                <div *ngIf="typeOfFormIndexTopic.isPublic">
                  <div [hidden]="!isOpenIndexDesktop">
                    <a [hidden]="isHandset$ | async" (click)="onPersonalParagraphsDesktop()"  class="navbar-brand" ><span style="color: white" class="mat-button-wrapper">  public  </span>
                    
                    </a>
                  </div>
                 
                  <a mat-icon-button
                  [hidden]="!isOpenIndexMobile"
                  (click)="onPersonalParagraphsMobile()"
                  *ngIf="isHandset$ | async">
                  
                    <span style="color:white" class="mat-button-wrapper hide-text">  public  </span>
               
                  </a>
                </div>


                <div *ngIf="typeOfFormIndexTopic.isShared">
                  <div [hidden]="!isOpenIndexDesktop">
                    <a [hidden]="isHandset$ | async" 
                       (click)=onPreferredParagraphsDesktop()  
                       class="navbar-brand" >
                       <span style="color:white"   class="mat-button-wrapper">  shared </span>
                    </a>
                  </div>
                 
                  <a
                  [hidden]="!isOpenIndexMobile"
                  mat-icon-button
                  (click)="onPreferredParagraphsMobile()"
                  *ngIf="isHandset$ | async">
                  <span style="color:white"   class="mat-button-wrapper">  shared </span>
                   
                  </a>
                </div>

                <div *ngIf="typeOfFormIndexTopic.isPreferred">
                  <div [hidden]="!isOpenIndexDesktop">
                    <a [hidden]="isHandset$ | async" (click)="onPublicParagraphsDesktop()" class="navbar-brand" ><span style="color: white"  class="mat-button-wrapper">  preferred </span>
                    </a>
                  </div>
                 
                  <a mat-icon-button
                  [hidden]="!isOpenIndexMobile"
                  (click)="onPublicParagraphsMobile()"
                  *ngIf="isHandset$ | async">
                 
                  <span style="color:white" class="mat-button-wrapper"> preferred  </span>
                </a>
                </div>

                <span *ngIf="!isOpenIndexMobile" class="spacer"></span>

                <div *ngIf="formIconIsVisible" >
                <div *ngIf="!isOpenIndexMobile"  >
                  <a *ngIf="!loadingFormIconOptions" class="navbar-brand" >


                    <span  *ngIf="isThisFormFromCurrentUser"
                           (click)="onEditForm()" 
                           style="color: white"
                           class="mat-button-wrapper">
                    <!-- have to add IF IS SELECTED -->
                      <svg *ngIf="aFormIsSelected" style="color: red" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                      <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                      </svg>

                      saas
                     
                    </span>

                    
                    <span style="color:white" 
                          class="pointer"
                          (click)="onSaveRecipientInThisFormFeatureContainer()"
                          *ngIf="isThisFormFromCurrentUser === false && recipientIndex_exist_onCurrentSelectedFormItem === true && recipientIndexInUseAlreadyExist === false">
                    <svg style="color:red"  xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-save2" viewBox="0 0 16 16">
                      <path d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v4.5h2a.5.5 0 0 1 .354.854l-2.5 2.5a.5.5 0 0 1-.708 0l-2.5-2.5A.5.5 0 0 1 5.5 6.5h2V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z"/>
                    </svg>
                    save recipient in 
                    </span>
                    
              
                    <span  *ngIf="!isThisFormFromCurrentUser" style="color:white" >
                      saas
                    </span>


                  </a>
                </div>
                </div>
            
                       
                

              </mat-toolbar-row> 
             </mat-toolbar> 


             <mat-sidenav-container class="container1"  style="overflow: hidden;">
              <mat-sidenav 
              style="overflow: hidden;"
               #drawer [ngClass]="(isHandset$ | async) ? 'full-mobile-sidenav' : 'sidenav'" 
              [fixedInViewport]="true"
              fixedTopGap="130"
              [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
              [mode]="(isHandset$ | async) ? 'side' : 'side'"
              [opened]="(isHandset$ | async) === false">
         
                     <mat-nav-list  style="overflow: hidden;"> 

                      <div class="col">
                      <div class="row">
                        <app-recipient-index-column
                        [youAreInFormEdit]="false"
                        (updateRecipientIndex)='onUpdateRecipientIndex($event)'>
                        </app-recipient-index-column>
                      </div>
                              

                      <div class="row">
                                <div *ngIf="!isChangingFormIndexType">
                                  <app-form-index-topic-list
                                  [typeOfFormIndexTopic]="typeOfFormIndexTopic"
                                  (closeSideNavOnFormList)='onCloseSideNavOnFormList()'
                                  >
                               
                                  </app-form-index-topic-list>
                                </div>
                      </div>
                      </div>
                         
                     </mat-nav-list>
         
               </mat-sidenav>
         
         
                 <mat-sidenav-content style="background-color:rgb(242, 248, 250) ;" >
                  
                
                      <router-outlet></router-outlet>
                  
                     


<a *ngIf="!(isHandset$ | async)" align="end" href="https://github.com/angular/components/blob/main/LICENSE"> Code licensed under an MIT-style License.</a>
                 </mat-sidenav-content>  
         
               
         </mat-sidenav-container>

</div>        



          
   






