import { Component, Input, OnInit } from '@angular/core';
import { ParagraphResult } from './paragraph-result.model';


@Component({
  selector: 'app-paragraph-results',
  templateUrl: './paragraph-results.component.html',
  styleUrls: ['./paragraph-results.component.css']
})
export class ParagraphResultsComponent implements OnInit {

  @Input()  paragraphResult: ParagraphResult;
  @Input()  dateAndTime: string; 
  @Input()  isReceived: boolean

  constructor() { }

  ngOnInit(){

    this.paragraphResult

  }

}
