import { ContactDetails } from "./contact-details.model";

export class Contact {

    constructor( public _id: string,
                 public componentName: string, 
                 public UIID: string, 
                 public owner: string,
                 public allUsersAllowed: boolean,
                 public userListAllowed: string[],
                 public dateAndTime: string,
                 public updateDateAndTime: string,
                 public contactDetails: ContactDetails[]
                
                 ){}
}

// need to add list of blocked accounts 
// need to add list of reported accounts 

// need to add is GOLDEN, SILVER , BRONZE golden e' un ufficial account tipo administration/ sales 
// from certified company with company email 
// silver could be email with name and company domain 
// bronze just basic gmail ( but can turn gold if many followers / connection / user of paragraphs )