import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { FormIndexTypeInterface } from '../../form-index-type-interface.model';
import { FormIndexTopic } from '../form-index-topic.model';
import { FormIndexTopicService } from '../form-index-topic.service';

@Component({
  selector: 'app-public-preferred-form-index-topic',
  templateUrl: './public-preferred-form-index-topic.component.html',
  styleUrls: ['./public-preferred-form-index-topic.component.css']
})
export class PublicPreferredFormIndexTopicComponent implements OnInit {

  user: string 
  formIndexTopic: FormIndexTopic
  @Input() index_id: string ;
  index: number ;

    /** to hide the buttons and keep only the topic */
    hideForms = true ;
      /** set timing function */
  setTimer: any ;

  @Output() closeSideNavOnFormList = new EventEmitter<void>()
  @Output() adviseFormListOnLoadingForm = new EventEmitter<void>()

  @Input() typeOfFormIndexTopic: FormIndexTypeInterface
  typeOfIndexTopicInUse: string 

  mouseEnter: boolean = false 

  constructor( private formIndexTopicService: FormIndexTopicService , 
               private userObjectUpdateService: UserObjectUpdateService ) { }

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

    this.formIndexTopic = this.formIndexTopicService.getFormIndexTopic(this.formIndexTopicService.getFormIndexTopics().findIndex(x=> x._id === this.index_id));
  }

    /** this serve to show the lisy of paragraphs , they are hidden when start
   * they open when user click on the topic 
   */
     onHideForms(){
      this.hideForms = !this.hideForms;
      /** clear timer before it start again */
      this.clearTimer()
      
     this.setTimer= setTimeout(() => {
        this.hideForms = true ;
      }, 15000);
}

onMouseEnter(){
  this.mouseEnter = true
}

onMouseLeave(){
  this.mouseEnter = false 
}

    /** clear timer before itstart again */
  clearTimer(){
    clearTimeout(this.setTimer)
  }

  onCloseSideNav(){
    this.closeSideNavOnFormList.emit()
  }


  onAdviseFormListOnLoadingForm(){
    this.adviseFormListOnLoadingForm.emit()
    this.onCloseSideNav()
  }


}
