import { Injectable } from '@angular/core';
import { ParagraphTopic } from '../form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.model';
import { Subject } from 'rxjs';
import { ParagraphFeatureContainer } from '../form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature-container.model';
import { FormIndexTopic } from '../form-index-topic-list/form-index-topic.model';
import { FormFeatureContainer } from '../form-index-topic-list/form-feature-container.model';
import { ParagraphTopicIndex } from '../form-edit/form-edit-paragraphs-index/paragraph-topic-index.model';
import { GetComponentsHttpRequestService } from 'src/app/services/gets-services/get-components-http-request.sevice';
import { FormIndexTopicListItem } from '../form-index-topic-list/form-index-topic-list-item.model';
import { CreateUpdateMultiComponentsService } from 'src/app/services/shared-services/create-update-multi-components.service';


interface SharedParagraphForm_New_Old_Data {
    typeOfComponent: string , 
    formSide: FormSide ,
    paragraphSide: ParagraphSide, 
  }
  
  interface FormSide {
    oldFormIndexTopicListItem: FormIndexTopicListItem,
    updatedFormIndexTopicListItem: FormIndexTopicListItem,
    oldFormIndexTopic: FormIndexTopic ,
    updatedFormIndexTopic: FormIndexTopic,
    oldFormFeatureContainer: FormFeatureContainer,
    updatedFormFeatureContainer: FormFeatureContainer

  }
  
  interface ParagraphSide {
    oldParagraphTopicIndex: ParagraphTopicIndex,
    updatedParagraphTopicIndex: ParagraphTopicIndex,
    oldParagraphTopic: ParagraphTopic,
    updatedParagraphTopic: ParagraphTopic,
    oldParagraphFeatureContainer: ParagraphFeatureContainer,
    updatedParagraphFeatureContainer: ParagraphFeatureContainer
  }


@Injectable ({
    providedIn: 'root' 
})

export class AddSharedFormOrParagraphService {

    

    constructor( private getComponentsHttpRequestService: GetComponentsHttpRequestService , 
                 private createUpdateMultiComponentsService: CreateUpdateMultiComponentsService 

        ){}

    doneAddingUpdatingSharedParagraphForm = new Subject<SharedParagraphForm_New_Old_Data>()

   
    onAcceptDeclineParagraphTopic(userEmail: string, paragraphTopic: ParagraphTopic , accepted: boolean ){

        this.getComponentsHttpRequestService.httpGetComponentsByType(userEmail, 'featureIndex')
            .subscribe((res)=> {
                console.log(res)

                const httpFeatureIndexComponentResult: any = res 

                const newParagraphTopicIndexes: ParagraphTopicIndex[] = httpFeatureIndexComponentResult.paragraphTopicIndex
                const newParagraphTopics: ParagraphTopic[] = httpFeatureIndexComponentResult.paragraphTopic
                console.log( 'to update=>', newParagraphTopics, newParagraphTopicIndexes, 'to add =>', paragraphTopic )

            const new_id = null
            const newComponentName = 'paragraphTopic'
            const newUIID = 'paragraphTopic-'+(newParagraphTopics.length+1).toString()
            const newOwner = userEmail
            const newAllUsersAllowed = false 
            const newUserListAllowed = []
            const newDateAndTime = null 
            const newIsAccepted = true
            const newIsArchived = !accepted
            const newName = paragraphTopic.name
            const newParagraphFeatureContainer_ids: string[] = []
            
            const newParagraphTopic : ParagraphTopic = new ParagraphTopic ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed , 
            newUserListAllowed, newDateAndTime, newIsAccepted, newIsArchived, newName, newParagraphFeatureContainer_ids)
            
            console.log(newParagraphTopic)
             
                /**
                 * updating existing paragraphTopicIndex 
                 */

               const paragraphTopicIndexReference = newParagraphTopicIndexes[newParagraphTopicIndexes.findIndex(x=>x.type === "shared-paragraphs" )]

               console.log(paragraphTopicIndexReference) 
               const newparagraphTopicIndex_id = paragraphTopicIndexReference._id
               const newparagraphTopicIndexComponentName = paragraphTopicIndexReference.componentName
               const newparagraphTopicIndexUIID = paragraphTopicIndexReference.UIID 
               const paragraphTopicIndexOwner = paragraphTopicIndexReference.owner
               const paragraphTopicIndexAllUsersAllowed = paragraphTopicIndexReference.allUsersAllowed
               const paragraphTopicIndexUserListAllowed = paragraphTopicIndexReference.userListAllowed
               const paragraphTopicIndexDateAndTime = null 
               const paragraphTopicIndexType = paragraphTopicIndexReference.type
               const paragraphTopicIndexParagraphTopic_ids: string[] = paragraphTopicIndexReference.paragraphTopic_ids
               console.log(paragraphTopicIndexReference)
               

               const newParagraphTopicIndex = new ParagraphTopicIndex ( newparagraphTopicIndex_id, newparagraphTopicIndexComponentName, newparagraphTopicIndexUIID, paragraphTopicIndexOwner , 
               paragraphTopicIndexAllUsersAllowed, paragraphTopicIndexUserListAllowed, paragraphTopicIndexDateAndTime, paragraphTopicIndexType, paragraphTopicIndexParagraphTopic_ids    )

               this.createUpdateMultiComponentsService.createParagraphTopic_UpdateParagraphTopicIndex(userEmail,newParagraphTopic, newParagraphTopicIndex  )
                   .subscribe((res2: any )=> {
                    console.log(res2)

                    const newParagraphTopic: ParagraphTopic = res2.featureIndex[0]
                    const newParagraphTopicIndex: ParagraphTopicIndex = res2.featureIndex[1]
                    

                    console.log('paragraphTopic => ', newParagraphTopic )
                    console.log('paragraphTopicIndex => ', newParagraphTopicIndex )

                    const newTypeOfComponent = 'paragraphTopic'

                    const newFormSide: FormSide = { oldFormIndexTopicListItem: null , updatedFormIndexTopicListItem: null , 
                                                    oldFormIndexTopic: null , updatedFormIndexTopic: null,
                                                    oldFormFeatureContainer: null , updatedFormFeatureContainer: null 
                                                }
                    
                    const newParagraphSide: ParagraphSide = { oldParagraphTopicIndex: paragraphTopicIndexReference, updatedParagraphTopicIndex: newParagraphTopicIndex , 
                                                              oldParagraphTopic: paragraphTopic, updatedParagraphTopic: newParagraphTopic,
                                                              oldParagraphFeatureContainer: null, updatedParagraphFeatureContainer: null 
                                                            }
                    const newSharedParagraphForm_New_Old_Data: SharedParagraphForm_New_Old_Data = {typeOfComponent: newTypeOfComponent , formSide: newFormSide, paragraphSide: newParagraphSide }

                    this.doneAddingUpdatingSharedParagraphForm.next(newSharedParagraphForm_New_Old_Data)


                   }, error => {
                    console.log(error)
                })
   
   
            }, error => {
                console.log(error)
            })

    }

    onAcceptDeclineParagraphFeatureContainer(userEmail: string, paragraphTopic: ParagraphTopic, paragraphFeatureContainer: ParagraphFeatureContainer , accepted: boolean){
       
        this.getComponentsHttpRequestService.httpGetComponentsByType(userEmail, 'featureIndex')
            .subscribe((res)=> {
                console.log(res)
                const httpFeatureIndexComponentResult: any = res
        

          const newParagraphTopics: ParagraphTopic[] = httpFeatureIndexComponentResult.paragraphTopic
          const newParagraphFeatureContainers: ParagraphFeatureContainer[] = httpFeatureIndexComponentResult.paragraphFeatureContainer 
          console.log(newParagraphFeatureContainers, 'to update=>', newParagraphTopics, 'to add =>', paragraphFeatureContainer )

          const new_id = null
          const newComponentName = "paragraphFeatureContainer"
          const newUIID = "paragraphFeatureContainer-"+(newParagraphFeatureContainers.length+1).toString()
          const newOwner = userEmail
          const newAllUsersAllowed = false
          const newUserListAllowed = []
          const newDateAndTime = null 
          const newIsActive = accepted
          const newIsAccepted = true
          const newName = 'share'
          const newState = false 
          const newParagraph_id = paragraphFeatureContainer.paragraph_id
          
          const newParagraphFeatureContainer = new ParagraphFeatureContainer ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed,
                                             newDateAndTime, newIsActive, newIsAccepted, newName, newState, newParagraph_id )

        const indexOfParagraphTopic = newParagraphTopics.findIndex(x=> x._id === paragraphTopic._id)
        const ParagraphTopicOfReference = newParagraphTopics[indexOfParagraphTopic]
        
        const new_id2 = ParagraphTopicOfReference._id
        const newComponentName2 = ParagraphTopicOfReference.componentName
        const newUIID2 = ParagraphTopicOfReference.UIID
        const newOwner2 = userEmail
        const newAllUsersAllowed2 = ParagraphTopicOfReference.allUsersAllowed
        const newUserListAllowed2 = ParagraphTopicOfReference.userListAllowed
        const newDateAndTime2 = ParagraphTopicOfReference.dateAndTime
        const newIsAccepted2 = ParagraphTopicOfReference.isAccepted
        const newIsArchived2 = ParagraphTopicOfReference.isArchived 
        const newName2 = ParagraphTopicOfReference.name
        const newParagraphFeatureContainer_ids = ParagraphTopicOfReference.paragraphFeatureContainer_ids

        const newParagraphTopic : ParagraphTopic = new ParagraphTopic ( new_id2, newComponentName2, newUIID2, newOwner2, newAllUsersAllowed2 , 
                                  newUserListAllowed2, newDateAndTime2, newIsAccepted2, newIsArchived2, newName2, newParagraphFeatureContainer_ids)

        this.createUpdateMultiComponentsService.createShared_ParagraphFeatureContainer_UpdateParagraphTopic(userEmail, newParagraphFeatureContainer, newParagraphTopic )
            .subscribe((res: any)=> {
                console.log(res)

                const newParagraphFeatureContainer2: ParagraphFeatureContainer = res.featureIndex[0]
                const newParagraphTopic2: ParagraphTopic = res.featureIndex[1]

                console.log('new ParagraphFeatureContainer => ', newParagraphFeatureContainer2, 'new paragraphTopic => ', newParagraphTopic2 )

                const newTypeOfComponent = 'paragraphFeatureContainer'

                    const newFormSide: FormSide = { oldFormIndexTopicListItem: null , updatedFormIndexTopicListItem: null , 
                                                    oldFormIndexTopic: null , updatedFormIndexTopic: null,
                                                    oldFormFeatureContainer: null , updatedFormFeatureContainer: null 
                                                }
                    
                    const newParagraphSide: ParagraphSide = { oldParagraphTopicIndex: null, updatedParagraphTopicIndex: null , 
                                                              oldParagraphTopic: paragraphTopic, updatedParagraphTopic: newParagraphTopic2,
                                                              oldParagraphFeatureContainer: paragraphFeatureContainer, updatedParagraphFeatureContainer: newParagraphFeatureContainer2
                                                            }
                    const newSharedParagraphForm_New_Old_Data: SharedParagraphForm_New_Old_Data = {typeOfComponent: newTypeOfComponent , formSide: newFormSide, paragraphSide: newParagraphSide }

                    console.log(newSharedParagraphForm_New_Old_Data)
                    this.doneAddingUpdatingSharedParagraphForm.next(newSharedParagraphForm_New_Old_Data)


            })


        })
    }

    onAcceptDeclineFormIndexTopic(userEmail: string,formIndexTopic: FormIndexTopic, accepted: boolean){
        this.getComponentsHttpRequestService.httpGetComponentsByType(userEmail, 'featureIndex')
            .subscribe((res)=> {
                console.log(res)

                const httpFeatureIndexComponentResult: any = res 

                const newFormIndexTopics: FormIndexTopic[] = httpFeatureIndexComponentResult.formIndexTopic
                const newFormIndexTopicListItems: FormIndexTopicListItem[] = httpFeatureIndexComponentResult.formIndexTopicListItem
                console.log(newFormIndexTopics, 'to update=>', newFormIndexTopicListItems, 'to add =>', formIndexTopic  )

                const new_id = null
                const newComponentName2 = 'formIndexTopic'
                const newUIID2 = 'formIndexTopic-'+(newFormIndexTopics.length+1).toString()
                const newOwner = userEmail
                const newDateAndTime = null 
                const newAllUsersAllowed = false 
                const newUserListAllowed = []
                const newIsAccepted = true
                const newIsArchived = !accepted
                const newName = formIndexTopic.name
                const newFormFeatureContainer_ids = []

                const newFormIndexTopic = new FormIndexTopic ( new_id, newComponentName2, newUIID2, newOwner, newDateAndTime, newAllUsersAllowed , 
                                          newUserListAllowed, newIsAccepted, newIsArchived, newName, newFormFeatureContainer_ids)

               const formIndexTopicListItemOfReference: FormIndexTopicListItem = newFormIndexTopicListItems[newFormIndexTopicListItems.findIndex(x=> x.type === 'share')]
            
               this.createUpdateMultiComponentsService.createFormIndexTopic_UpdateFormIndexTopicListItem( userEmail, newFormIndexTopic, formIndexTopicListItemOfReference )
                   .subscribe((res: any)=> {

                    const newFormIndexTopic2: FormIndexTopic = res.featureIndex[0]
                    const newFormIndexTopicListItem2: FormIndexTopicListItem = res.featureIndex[1]

                    console.log('new FormIndexTopic => ', newFormIndexTopic2, 'new formIndexTopicListItem => ', newFormIndexTopicListItem2 )

                            const newTypeOfComponent = 'formIndexTopic'

                            const newFormSide: FormSide = { oldFormIndexTopicListItem: formIndexTopicListItemOfReference  , updatedFormIndexTopicListItem: newFormIndexTopicListItem2 , 
                                                            oldFormIndexTopic: formIndexTopic , updatedFormIndexTopic: newFormIndexTopic2,
                                                            oldFormFeatureContainer: null , updatedFormFeatureContainer: null 
                                                          }
                    
                            const newParagraphSide: ParagraphSide = { oldParagraphTopicIndex: null, updatedParagraphTopicIndex: null , 
                                                                      oldParagraphTopic: null, updatedParagraphTopic: null,
                                                                      oldParagraphFeatureContainer: null, updatedParagraphFeatureContainer: null
                                                                    }
                            const newSharedParagraphForm_New_Old_Data: SharedParagraphForm_New_Old_Data = {typeOfComponent: newTypeOfComponent , formSide: newFormSide, paragraphSide: newParagraphSide }

                            console.log(newSharedParagraphForm_New_Old_Data)
                            this.doneAddingUpdatingSharedParagraphForm.next(newSharedParagraphForm_New_Old_Data)


                   })

            })

    }

    onAcceptDeclineFormFeatureContainer(userEmail: string, formIndexTopic: FormIndexTopic,  formFeatureContainer: FormFeatureContainer, accepted: boolean){
        this.getComponentsHttpRequestService.httpGetComponentsByType(userEmail, 'featureIndex')
            .subscribe((res)=> {
                console.log(res)

                const httpFeatureIndexComponentResult: any = res 

                const newFormIndexTopics: FormIndexTopic[] = httpFeatureIndexComponentResult.formIndexTopic
                const newFormFeatureContainers: FormFeatureContainer[] = httpFeatureIndexComponentResult.formFeatureContainer

                console.log('to update=>',newFormIndexTopics, newFormFeatureContainers, 'to add =>', formFeatureContainer  )

                    const new_id = null
                    const newComponentName = 'formFeatureContainer'
                    const newUIID = 'formFeatureContainer-'+(newFormFeatureContainers.length+1).toString()
                    const newOwner = userEmail
                    const newAllUsersAllowed = false
                    const newUserListAllowed = []
                    const newDateAndTime = null 
                    const newIsActive = accepted
                    const newIsAccepted = true
                    const newName = formFeatureContainer.name
                    const newFormFeatureState = formFeatureContainer.formFeatureState
                    const newRecipientIndex_id = formFeatureContainer.recipientIndex_id
                    const newFormItem_id = formFeatureContainer.form_id
            
                    const newFormFeatureContainer = new FormFeatureContainer ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed, 
                          newDateAndTime, newIsActive, newIsAccepted, newName, newFormFeatureState , newRecipientIndex_id, newFormItem_id )
            
                    const indexOfFormIndexTopic = newFormIndexTopics.findIndex(x=> x._id === formIndexTopic._id)
                    const formIndexTopicOfReference = newFormIndexTopics[indexOfFormIndexTopic]
         
                    this.createUpdateMultiComponentsService.createFormFeatureContainer_UpdateFormIndexTopic(userEmail, newFormFeatureContainer, formIndexTopicOfReference )
                        .subscribe((res: any)=>{
                            console.log(res)

                            const newFormFeatureContainer2: FormFeatureContainer = res.featureIndex[0]
                            const newFormIndexTopic2: FormIndexTopic = res.featureIndex[1]

                            console.log('new FormFeatureContainer => ', newFormFeatureContainer2, 'new formIndexTopic => ', newFormIndexTopic2 )

                            const newTypeOfComponent = 'formFeatureContainer'

                            const newFormSide: FormSide = { oldFormIndexTopicListItem: null , updatedFormIndexTopicListItem: null , 
                                                            oldFormIndexTopic: formIndexTopic , updatedFormIndexTopic: newFormIndexTopic2,
                                                            oldFormFeatureContainer: formFeatureContainer , updatedFormFeatureContainer: newFormFeatureContainer2 
                                                          }
                    
                            const newParagraphSide: ParagraphSide = { oldParagraphTopicIndex: null, updatedParagraphTopicIndex: null , 
                                                                      oldParagraphTopic: null, updatedParagraphTopic: null,
                                                                      oldParagraphFeatureContainer: null, updatedParagraphFeatureContainer: null
                                                                    }
                            const newSharedParagraphForm_New_Old_Data: SharedParagraphForm_New_Old_Data = {typeOfComponent: newTypeOfComponent , formSide: newFormSide, paragraphSide: newParagraphSide }

                            console.log(newSharedParagraphForm_New_Old_Data)
                            this.doneAddingUpdatingSharedParagraphForm.next(newSharedParagraphForm_New_Old_Data)
                            

                        })
                    


            })

    }

}