<!-- <p>triple-loading-spinner works!</p> -->



<div class="spinner-grow spinner-border m-5 text-primary "  role="status">
    <span class="visually-hidden">Loading...</span>
  </div>

  <div class="spinner-grow spinner-border m-5 text-danger"  role="status">
    <span class="visually-hidden">Loading...</span>
  </div>

  <div class="spinner-grow spinner-border m-5 text-warning"  role="status">
    <span class="visually-hidden">Loading...</span>
  </div>

  <!-- <div class="spinner-grow spinner-border m-5 text-info"  role="status">
    <span class="visually-hidden">Loading...</span>
  </div> -->
