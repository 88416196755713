import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RangeInfo } from '../range-info.model';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { FixTextDetail } from '../../../texts/fix-text-list/fix-text-detail.model';
import { InnerArraySelect } from '../inner-array-select.model';

interface IndexRangeInfo{
  index: number ,
  rangeInfo: RangeInfo
}


interface InputLabelInterface {

  exist: boolean ,
  name: string,
  maxLength: number,
  errorHandler: boolean 
}

@Component({
  selector: 'app-inner-dropdown-edit',
  templateUrl: './inner-dropdown-edit.component.html',
  styleUrls: ['./inner-dropdown-edit.component.css']
})
export class InnerDropdownEditComponent implements OnInit {

  @Input() rangeInfo: RangeInfo
  @Input() isEditing: boolean
  @Input() index: number 

  inputDropdownInnerCompNameInterface: InputLabelInterface
  inputTextLabelInterface: InputLabelInterface
  inputEditTextLabelInterface: InputLabelInterface
  innerTextItem: FixTextDetail
  emailInput: string = ''
  accessType: string 
  textInput: string = ''
  initialText: string = ''
 
  temporaryTextToAdd: string = ''
  temporaryEditedText: string = ''

  @Output() emittingIndexRangeInfo = new EventEmitter<IndexRangeInfo>()
  @Output() emitIndexOfDeletedRangeInfo = new EventEmitter<number>()
  isAboutToDeleteRangeInfo: boolean = false 

  emptyInputNameAfterAdded = new Subject<void>()
  emptyInputTextAfterAdded = new Subject<void>()
  emptyInputTextAfterEdit = new Subject<void>()

  isEditingDetail: boolean = false 
  editingDetailPosition: number = 1000

  inputNewTextValid: boolean = false
  editedTextIsValid: boolean = true 

  isDisabled: boolean = false

  selectedValue: string;


 

  constructor( private breakpointObserver: BreakpointObserver) { }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  ngOnInit(): void {

    console.log(this.rangeInfo)

    this.accessType = this.rangeInfo.type


    const inputCompName = 'input name'
    this.inputDropdownInnerCompNameInterface = { exist: true, name: inputCompName, maxLength: 30, errorHandler: true }

    const inputName = 'add dropdown text line'
    this.inputTextLabelInterface = { exist: true, name: inputName, maxLength: 200, errorHandler: true }

    const inputEditText = 'edit existing text'
    this.inputEditTextLabelInterface = { exist: true, name: inputEditText, maxLength: 200, errorHandler: true }
  }


  onUpdateUsersAccess($event){
    console.log($event)
    this.rangeInfo.usersAccess = $event

  }

  onUpdateTextInputEvent($event){
    console.log($event)
    this.temporaryTextToAdd = $event.name

  }

  onUpdateNameInputEvent($event){
    console.log($event)
    this.rangeInfo.name = $event.name

  }

  onUpdateNameInputValidity($event){
    console.log('NameInputValidity', $event)


  }

  onUpdateTextEditValidity($event){
    console.log($event)
    this.editedTextIsValid = $event
  }

  onUpdateTextEditEvent($event){
    console.log($event)
    this.temporaryEditedText = $event.name
  }

  onUpdateTextInputValidity($event){
    console.log($event)
    this.inputNewTextValid = $event
    
  }

  onAddText(){

    const existingTexts: any[] = []

    for ( let i=0; i<this.rangeInfo.rangeDetails.length ; i++){
      existingTexts.push(this.rangeInfo.rangeDetails[i] )
    }

    const newRangeDetail = new InnerArraySelect ( this.temporaryTextToAdd , false )

    existingTexts.push(newRangeDetail)

    this.rangeInfo.rangeDetails = existingTexts
    console.log(this.rangeInfo)

    this.onEmptyInputTextAfterAdded()
    this.inputNewTextValid = false

  }

  onDeleteDetail(i){
    this.rangeInfo.rangeDetails.splice(i,1)
  }

  onItemSelection(){
    console.log(this.selectedValue);
  }

  onEditDetail(i){
    console.log('edit detail => ', i)
    this.editingDetailPosition = i
    this.isEditingDetail = true 

  }

  onConfirmText(i){
    console.log('confirm ', i)

    const existingTexts: any[] = []

    for ( let i=0; i<this.rangeInfo.rangeDetails.length ; i++){
      existingTexts.push(this.rangeInfo.rangeDetails[i] )
    }

    const editedRangeDetail = new InnerArraySelect ( this.temporaryEditedText, false )
         
    existingTexts[i] = editedRangeDetail

    this.rangeInfo.rangeDetails = existingTexts
    console.log(this.rangeInfo)

    this.onResetEditTextInput()
  }



  onResetEditTextInput(){
    this.emptyInputTextAfterEdit.next()
    this.editingDetailPosition = 1000
    this.isEditingDetail = false 
  }

  onEmptyInputTextAfterAdded(){
    this.emptyInputTextAfterAdded.next()
  }

  onConfirmInnerDetail(){
    console.log('confirm the texts')
    const newIndex = this.index 
    const newRangeInfo = this.rangeInfo
    const newIndexRangeInfo: IndexRangeInfo = { index: newIndex , rangeInfo: newRangeInfo }
    this.emittingIndexRangeInfo.emit( newIndexRangeInfo )
  }

  onDeleteInnerDetail(){
    console.log('delete the texts ?')
    this.isAboutToDeleteRangeInfo = true 
  }

  onDoubleConfirmDeleteInnerDetail(){
    console.log('confirm delete the texts')
    this.emitIndexOfDeletedRangeInfo.emit(this.index )

  }

  onReturnToEditRangeInfo(){
    console.log('return edit texts')
    this.isAboutToDeleteRangeInfo = false
  }







}
