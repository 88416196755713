<!-- <p>forms works!</p> -->
<div cdkDropListGroup >



<mat-toolbar color="primary" class="mat-toolbar" >
</mat-toolbar>


<mat-toolbar color="primary" class="mat-toolbar" >


<mat-toolbar-row >

  <!--  [hidden]="(isHandset$ | async) === true" -->
  <!-- <button
  *ngIf="!(isHandset$ | async)"
  type="button"
  aria-label="Toggle sidenav"
  mat-icon-button
  (click)="onIsDesktop()"
  (click)="drawer2.toggle()">
 
  <mat-icon style="color: white" aria-label="Side nav toggle icon"> menu </mat-icon>
  </button> -->

<div *ngIf="isDesktop">
  <a [hidden]="(isHandset$ | async) === true" class="navbar-brand" ><span style="color:white"> components </span></a>
</div>


<button
type="button"
aria-label="Toggle sidenav"
mat-icon-button
*ngIf="(isHandset$ | async) && isMobile">
<mat-icon style="color: white" aria-label="Side nav toggle icon">menu</mat-icon>
</button>

<button
type="button"
aria-label="Toggle sidenav"
mat-icon-button
(click)="onIsMobile()"
(click)="drawer2.toggle()"
*ngIf="(isHandset$ | async) && !isMobile">
<mat-icon style="color: white" aria-label="Side nav toggle icon">menu</mat-icon>
</button>


<div *ngIf="isMobile">
  <a *ngIf="isHandset$ | async" class="navbar-brand" ><span style="color:white"> components </span></a>
</div>


</mat-toolbar-row>
</mat-toolbar>

<div >
<mat-sidenav-container class="container1" >
         

<!-- [mode]="(isHandset$ | async) ? 'side' : 'side'" -->
<mat-sidenav 
style="overflow: hidden;"
#drawer2
[ngClass]="(isHandset$ | async) ? 'mobile-sidenav' : 'sidenav'" 
[fixedInViewport]="true"
fixedTopGap="130"
[attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
[mode]="(isHandset$ | async) ? 'push' : 'side'"
[opened]="(isHandset$ | async) === false"
(closedStart)="onIsMobile()">

            <mat-nav-list *ngIf="!isLoadingFeatureIndexTopicList" 
                           style="overflow: hidden;">
                          <app-feature-index-topic-list></app-feature-index-topic-list>
            </mat-nav-list>

</mat-sidenav>

<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>  

<mat-sidenav-content *ngIf="!isLoading" #sidenavContent >
      <!-- WHEN IS HANDSET AND WHEN WE CLICK THE SIDENAV , THE DELETE AND EDIT COMPONENT BUTTON
        SHOULD DISAPPEAR   
    BY ADDING A SUBSCRIPTION IT WILL WORK -->
<app-paragraph-edit 
[isEditMode]="isEditMode"
[paragraph_id]="paragraph_id"
(onScrollToBottomOnParentComponent)="onScrollParagraphSideNavContentToBottom($event)"
(onOpenSideNavFromParagraphEdit)="onOpenSideNav()">
</app-paragraph-edit>



</mat-sidenav-content>  

           
</mat-sidenav-container>
</div>
        

</div> 


