
 <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

       <div *ngIf="!isLoading" class="row">
           <div hidden class="col-xs-12">
              {{multiFeature.name}}
           </div>
       </div>

       <h2 *ngIf=" !isLoading && multiFeature.features.length === 0 " align="center" style="color: #3f51b5 "> edit this component " {{multiFeature.name}} "  </h2>

       <div *ngIf="!isLoading && multiFeature.features.length > 0" class="ParagraphHorizontal">
       <div  *ngFor="let feature of multiFeature.features ; let i=index ">
 

            <div  [ngSwitch]="feature.featureName " >
                     
                <app-single-word-item 
                *ngSwitchCase="'singleWord'"
                [feature]="feature"
                [isParagraphEdit]="isParagraphEdit"
                [weAreInMultiFeatureEdit]="weAreInMultiFeatureEdit"
                (updateBothSingleFeatureResultAndTextPlusIndex)="onUpdateSingleFeatureResults($event)"
                [index_id]="feature.feature_id">
                </app-single-word-item>

                <app-single-word-input-item 
                *ngSwitchCase="'singleWordInput'"
                [feature]="feature"
                [isParagraphEdit]="isParagraphEdit" 
                [weAreInMultiFeatureEdit]="weAreInMultiFeatureEdit"
                (updateBothSingleFeatureResultAndTextPlusIndex)="onUpdateSingleFeatureResults($event)"
                [index_id]="feature.feature_id">
                </app-single-word-input-item>

                <app-toggle-single-word-button-item 
                *ngSwitchCase="'toggleSingleWordButton'"
                [feature]="feature"
                [isParagraphEdit]="isParagraphEdit"
                [weAreInMultiFeatureEdit]="weAreInMultiFeatureEdit"
                (updateBothSingleFeatureResultAndTextPlusIndex)="onUpdateSingleFeatureResults($event)"
                [index_id]="feature.feature_id"  >
               </app-toggle-single-word-button-item>

               <app-single-drop-down-select-item  
               *ngSwitchCase="'singleDropDownSelect'"
               [feature]="feature"
               [isParagraphEdit]="isParagraphEdit"
               [weAreInMultiFeatureEdit]="weAreInMultiFeatureEdit"
               (updateBothSingleFeatureResultAndTextPlusIndex)="onUpdateSingleFeatureResults($event)"
               [index_id]="feature.feature_id"  >
               </app-single-drop-down-select-item>

               <app-single-slider-item  
               *ngSwitchCase="'singleSlider'"
               [feature]="feature"
               [isParagraphEdit]="isParagraphEdit"
               [weAreInMultiFeatureEdit]="weAreInMultiFeatureEdit"
               (updateBothSingleFeatureResultAndTextPlusIndex)="onUpdateSingleFeatureResults($event)"
               [index_id]="feature.feature_id"  >
               </app-single-slider-item>


              
    </div>
           
          </div>
       </div>





