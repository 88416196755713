import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable ({
    providedIn: 'root' 
})

export class StoreTabItemPlusChildsConfirmationService {

    constructor ( 
               
                ){}

    tab_id_plusChildsHasBeenStored = new Subject<string>()
    tabItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property: any[] = []

    composeTabComponentPlusChilds_idsList_plusStatus(tab_plusChilds: any ){

        let component_id: string = tab_plusChilds._id
        let haveBeenStored: boolean = true

        this.tabItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.push({component_id , haveBeenStored })

        for ( let i=0; i<tab_plusChilds.feature_id_list.length ; i++ ){

            let component_id: string = tab_plusChilds.feature_id_list[i]
            let haveBeenStored: boolean = false 
            this.tabItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.push({component_id , haveBeenStored })

        }

        console.log(this.tabItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property)
    }


    addStoredComponentConfirmationAndAdviseWhenTabItemPlusChildsAreStored(component_id: string ){

       /**
        * loop through all the items and check which ones have the same _id and change the "haveBeenStored to true "
        */
        for (let i=0; i<this.tabItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.length ; i++ ){

            if ( this.tabItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property[i].component_id === component_id ){

                let haveBeenStored: boolean = true 
                let component_idPlusBoolean: any = { component_id , haveBeenStored }
                this.tabItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property[i] = component_idPlusBoolean 
            }
        }
        
        console.log(this.tabItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property)
 
        if (this.tabItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.findIndex(x=> x.haveBeenStored === false ) !== -1 ){ return }
        if (this.tabItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property.findIndex(x=> x.haveBeenStored === false ) === -1 ){ 
 
         this.tab_id_plusChildsHasBeenStored.next(this.tabItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property[0].component_id )
            // send notification to tabItem that all childcomponents has been stored and can now load safely
            this.resetForm()
         } 

    }

    resetForm(){
        this.tabItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property = []
        
    }

    onLoggingOutSetListOfTabItemsWithChildsEmptyAgain(){
        this.tabItemPlusChildComponent_idsAndIfTheyHaveBeenStored_Property = []
    }



}