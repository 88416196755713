
export class Header {

    constructor( public weAreInDrapp:         boolean ,  
                 public weAreInEditParagraph: boolean, 
                 public weAreInCreateParagaph:boolean,
                 public weAreInForms:         boolean,
                 public weAreInResults:       boolean,
                 public weAreInMessages:      boolean ,
                 public weHaveToSignIn:       boolean , 
                 public weAreLoggedIn:        boolean ){}
}

/**
 * for each property we need to add 2 child properties that define the status of the Badge: 
 * 
 * 1- exist: boolean 
 * 2- quantity : number
 */