import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, NgZone, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators ,  FormBuilder,  } from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Subscription , Observable} from 'rxjs';
import { Recipient } from '../../../recipient.model';
import { RecipientService } from '../../../recipient.service';
import { Form } from 'src/app/forms/form.model';
import { FormService } from 'src/app/forms/form.service';


import { Contact } from 'src/app/contacts/contact.model';
import { ContactService } from 'src/app/contacts/contact.service';
import { ContactDetails } from 'src/app/contacts/contact-details.model';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';


@Component({
  selector: 'app-cc-mail-account',
  templateUrl: './cc-mail-account.component.html',
  styleUrls: ['./cc-mail-account.component.css']
})
export class CcMailAccountComponent implements OnInit, OnDestroy {

  user: string 

  public separatorKeysCodes = [ENTER, COMMA, SPACE];
  public emailList = [];
  removable = true;

  rulesForm: FormGroup;
  fb: FormBuilder = new FormBuilder();

  @Input() recipient: Recipient

  @Output() updateCcMailList = new EventEmitter<string[]>()

   /**
   * subscribe new Recipient Id when coming from Form Item
   */
    form: Form;
    receivedFormId: number ;
    receivedForm_id: string;
    index: number 
    formIndex: number ;
    clickEventFromFormTopicIndexSubscription: Subscription ;
    ccMailList : string[];


  contact: Contact ;
  contacts: Contact[]; 
  contactDetails: ContactDetails[]

  contactEmail: string
  contactEmails: string[] 
  contactEmailCtrl = new FormControl();
  filteredContactEmails : Observable<string[]>
  @ViewChild('contactInput') contactInput: ElementRef<HTMLInputElement>;

  updateOnUserEmail: Subscription
  updateAllEmailInputsOfEmailContactsSubscription: Subscription
 

  constructor( 
               private contactService: ContactService,
               private userObjectUpdateService: UserObjectUpdateService,
               private postComponentsHttpRequestService: PostComponentsHttpRequestService, 
               private initialNullSetupService : InitialNullSetupService ,
               private recipientService: RecipientService , 
               private ngZone: NgZone, 
               private cdr: ChangeDetectorRef 
                ) {}

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
    })

    this.emailList = []
    for( let i=0 ; i<this.recipient.ccMail?.length ; i++){
      this.emailList.push({value: this.recipient.ccMail[i] , invalid: false})
    }
    console.log(this.emailList);

    this.rulesForm = this.fb.group({
      emails: this.fb.array([], [this.validateArrayNotEmpty]),
    });

    this.createContactEmails()

    this.updateAllEmailInputsOfEmailContactsSubscription = this.recipientService.updateAllEmailInputsOfEmailContacts
    .subscribe(()=> {

      this.ngZone.run(()=>{
        this.createContactEmails()
      })
      this.cdr.detectChanges()
    })
  }

  createContactEmails(){
    this.contactDetails = this.contactService.getContactDetails()
    console.log('all contacts list = ',this.contactDetails)
    this.contactEmails = []
    for (let i=0 ; i<this.contactDetails.length ; i++){

      if ( this.contactEmails.findIndex(x=> x === this.contactDetails[i].contactEmail ) == -1 ){
           this.contactEmails.push(this.contactDetails[i].contactEmail)
      }
    }

    this.contactEmails.sort(this.compareEmails)

  }

  // Sorting function
 compareEmails(a, b) {
  // Convert emails to lowercase for case-insensitive sorting
  const emailA = a.toLowerCase();
  const emailB = b.toLowerCase();

  if (emailA < emailB) {
    return -1;
  }
  if (emailA > emailB) {
    return 1;
  }
  return 0;
}
  
 

/**
 * 
 * @param event add CC Mail and emit event to recipient item to add email there too
 */
  add(event){
    console.log('email = ',event.value)
    if (event.value) {
      if (this.validateEmail(event.value)) {
        const lowercaseValue = event.value.toLowerCase()
        this.emailList.push({ value: lowercaseValue, invalid: false });
        this.onUpdateCcMailList()
        this.checkIfContactExistOnContactList(lowercaseValue)
      } else {
        return
      }
    }
    if (event.input) {
      event.input.value = '';
    }
    console.log(this.emailList)
  }

  checkIfContactExistOnContactList(newContact: string ){

    if ( this.contactService.contacts[0].contactDetails.findIndex( x=> x.contactEmail === newContact ) === -1 ){
      // add contact to list 
        const contactName = undefined 
        const contactEmail= newContact 
        const telNumber = undefined
        const isAConnection = true
        const isBlocked = false
        const isBeenReported= false 

        const newContactDetail = new ContactDetails (contactName, contactEmail, telNumber, isAConnection, isBlocked, isBeenReported )
         
         const contactItem = this.contactService.contacts[0] 
               contactItem.contactDetails.push(newContactDetail)

               this.contactService.contacts[0] = contactItem 
               this.initialNullSetupService.initialNullContacts[0] = contactItem

               this.createContactEmails()
               this.recipientService.updateAllEmailInputsOfEmailContactsSubject()

        if ( this.user !== null ){
         this.postComponentsHttpRequestService.httpUpdateComponent(this.user, 'contact', contactItem )
             .subscribe((res)=> {
              console.log(res)
             }, error => {
              console.log( error )
             })
        }
    }


  }

/**
 * 
 * @param data removing CCmail and emitting event to recipient to remove the email there too
 */
  removeEmail(data: any){
    if (this.emailList.indexOf(data) >= 0) {
      this.emailList.splice(this.emailList.indexOf(data), 1);
      console.log('Removing ' + data.value)
    }
    console.log(this.emailList)
    this.onUpdateCcMailList()
  }


  onUpdateCcMailList(){
    this.ccMailList = []
    for (let i=0 ; i< this.emailList.length ; i++){
      this.ccMailList.push(this.emailList[i].value)
    }
    console.log(this.ccMailList)
    this.updateCcMailList.emit(this.ccMailList)
    this.ccMailList = []
  }

  selected(event: MatAutocompleteSelectedEvent) {

    console.log(this.contactEmails[event.option.value])
    if (this.contactEmails[event.option.value]) {
      if (this.validateEmail(this.contactEmails[event.option.value])) {
        this.emailList.push({ value: this.contactEmails[event.option.value], invalid: false });
        console.log(this.contactEmails[event.option.value])
      } else {
        this.emailList.push({ value: this.contactEmails[event.option.value], invalid: true });
        this.rulesForm.controls['emails'].setErrors({'incorrectEmail': true});
      }
    }
    /**
     * this last part cut out the selected email from the list 
     */
    // if (this.contactEmails[event.option.value]) {
    //   this.contactEmails[event.option.value] = '';
    // }
    console.log('list of emails = ',this.emailList)
    this.onUpdateCcMailList()

    console.log(event)
  }



  private validateArrayNotEmpty(c: FormControl) {
    if (c.value && c.value.length === 0) {
      return {
        validateArrayNotEmpty: { valid: false }
      };
    }
    return null;
  }

  private validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  ngOnDestroy(): void {
      this.updateOnUserEmail?.unsubscribe()
      this.updateAllEmailInputsOfEmailContactsSubscription?.unsubscribe()
  }


}

