
        <div *ngFor="let feature of featureContainer.features ; let i = index ">
     
             <div *ngIf="!featureContainerIsLoaded" 
                  style="color: #3f51b5; background-color: azure;" 
                  class="example-box pointer"
                  (click)="onAddFeatureToParagraph(feature)">
        
                {{feature.featureName}}

                <!-- MAKE ON HOVER A POP UP DIALOG WITH THE PREVIEWS ACCORDING TO THE FEATURE.FEATURENAME
                     THE DIALOG ITSELF WILL HAVE ALL THE *NGSWITCH CASES , SENDING TH EFEATURE.FEATURENAME 
                     AS DATA FOR THE DIALOG TO GET 
                -->

                <!-- <div *cdkDragPreview> 

                  <div [ngSwitch]="feature.featureName " > 
                  <app-word-input-preview *ngSwitchCase="'wordInput'"></app-word-input-preview>
                  <app-dropdowns-preview *ngSwitchCase="'dropDownSelect'"></app-dropdowns-preview>
                  <app-fix-text-preview *ngSwitchCase="'fixText'"></app-fix-text-preview>
                  <app-multi-features-preview *ngSwitchCase="'multiFeatures'"></app-multi-features-preview>
                  <app-single-slider-preview *ngSwitchCase="'singleSlider'"></app-single-slider-preview>
                  </div>
              

              
                </div> -->

    
              </div>
      
          
        </div>


