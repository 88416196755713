
<div class="row">
   <div class="col-xs-12">

       <div class="ParagraphComponentFrame">

        <div style="background-color: rgb(243, 250, 253);" class="row">
               <div class="col">
                   <button type="button" class="btn btn-link btn-sm"> <h6 >{{sender}}</h6></button>
               </div>
               <div class="col">
                   <button *ngIf="!useSubstitute" type="button" class="btn btn-link btn-sm"> <h6 style="color: red" >{{subject}}</h6></button>
                   <button *ngIf="useSubstitute" type="button" class="btn btn-link btn-sm"> <h6 style="color: red" >{{subjectSubstituteToNull}}</h6></button>
               </div>
               <div class="col">
                   <button type="button" class="btn btn-link btn-sm"><h6>{{paragraphResult.paragraphResultName}}</h6></button>
               </div>
           </div>


           <div *ngFor="let featureResult of paragraphResult?.featureResults; let i=index">
               <app-feature-results  [featureResult]="featureResult"></app-feature-results>
           </div>

           <h6>{{dateAndTime}}</h6>

       </div>
   </div>
      
</div>




