import { FixLayoutTableTR } from "./fix-layout-table-tr.model";

export class FixLayout {

    constructor (public _id: string ,
                 public componentName: string , 
                 public UIID: string , 
                 public name: string,
                 public owner: string,
                 public allUsersAllowed: boolean,
                 public userListAllowed: string[],
                 public dateAndTime: string, 
                 public fixLayoutTableTRs: FixLayoutTableTR[]
                 ){}
}