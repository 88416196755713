
<app-loading-spinner *ngIf="isLoading"> </app-loading-spinner>

<div *ngIf="!isLoading" >



<div class="row" *ngIf="isHandset$ | async">
    <div class="col">


         
                    <div class="row" >
                        <div class="d-flex justify-content-center align-items-center">
                            <h2 style="color: #3f51b5"> {{sharedCalendarStatusItem.name}}</h2>
                        </div>
                    </div>
                
                    <div class="custom-calendar">
                        <!-- *ngIf="!isUpdatingCalendar" -->
                      <mat-calendar #calendar 
                                    *ngIf="!isAddingRange"
                                    [selected]="selectedDates" 
                                    [dateClass]="dateClassCallback"
                                    (selectedChange)="onDateSelection($event)"
                                    >
                      </mat-calendar>

                      <mat-calendar #calendar
                                    *ngIf="isAddingRange"
                                    [selected]="selectedDates" 
                                    [dateClass]="dateClassCallback"
                                    (selectedChange)="onDateSelection($event)"
                      >
                      </mat-calendar>
                    </div>
                
                    <hr *ngIf="!isAddingRange">
                
                    <div class="row" *ngIf="!isAddingRange && !isParagraphEdit">
                        <div class="col">
                            <div class="row">
                                <div class="col">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <button class="btn btn-danger btn-sm"
                                                (click)="onDeleteRanges()">
                                            delete
                                        </button>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <button class="btn btn-primary btn-sm" 
                                                (click)="onAddRange()">
                                            add
                                        </button>
                                    </div>
                                </div>
                            </div>
                
                        </div>
                    </div>
                
    

        <!-- END side card -->
    </div>

    <div class="row">
        <br>
    </div>

    <div class="col" > 
        <!-- *ngIf="canViewIt" -->
         <!-- START SECOND side card -->
            <div *ngIf="!isParagraphEdit && aRangeDateInfoExist">
                <app-shared-calendar-status-item-view *ngIf="!isUpdatingCalendar"
                       [sharedCalendarStatus_id]="sharedCalendarStatusItem._id"
                       [sharedCalendarStatus_owner]="sharedCalendarStatus_owner"
                       [canModifyDateRange_date]="canUseIt"
                       [isParagraphEdit]="isParagraphEdit"
                       [selectedRangeDatesInfo]="selectedRangeDatesInfo"
                       [selectedRangeDatesInfo_Index]="selectedRangeDatesInfo_Index"
                       (editRangeDateEvent)="onEditRangeDate_index($event)"
                       [changeSelectedDatesView]="changeSelectedDates.asObservable()"
                       (updateRangeDatesInfo)="onUpdateRangeDatesInfo($event)"
                       (updateSharedCalendarStatusResult)="onUpdateSharedCalendarStatusResult($event)"
                       [editJustAddedRangeInfo]="editJustAddedRangeInfo.asObservable()"
                       (emit_Update_SharedcalendarStatus)="onEmit_Update_SharedcalendarStatus($event)">
                </app-shared-calendar-status-item-view>
                <!-- (emitUpdatedSelectedRangeDatesInfo_afterAdd)="onUpdateRangeDatesInfo_afterAdd($event)" -->

                <app-loading-spinner *ngIf="isUpdatingCalendar"> </app-loading-spinner>
                <!-- <app-shared-calendar-status-item-view *ngIf="isUpdatingCalendar"
                       [sharedCalendarStatus_id]="sharedCalendarStatusItem._id"
                       [sharedCalendarStatus_owner]="sharedCalendarStatus_owner"
                       [canModifyDateRange_date]="canUseIt"
                       [isParagraphEdit]="isParagraphEdit"
                       [selectedRangeDatesInfo]="selectedRangeDatesInfo"
                       [selectedRangeDatesInfo_Index]="selectedRangeDatesInfo_Index"
                       (editRangeDateEvent)="onEditRangeDate_index($event)"
                       [changeSelectedDatesView]="changeSelectedDates.asObservable()"
                       (updateRangeDatesInfo)="onUpdateRangeDatesInfo($event)"
                       (updateSharedCalendarStatusResult)="onUpdateSharedCalendarStatusResult($event)"
                       (emitUpdatedSelectedRangeDatesInfo_afterAdd)="onUpdateRangeDatesInfo_afterAdd($event)"
                       [editJustAddedRangeInfo]="editJustAddedRangeInfo.asObservable()"
                       (emit_Update_SharedcalendarStatus)="onEmit_Update_SharedcalendarStatus($event)">
                </app-shared-calendar-status-item-view> -->
            </div>

            <div *ngIf="!isParagraphEdit && !aRangeDateInfoExist">
                <br>
                <!-- <br>
                <br>
                <br>
                <br>
                <br>
                <br> -->
                <!-- <div class="row"> -->
                    <div class="col-xs-12">
                        <!-- <div class="d-flex justify-content-center align-items-center"> -->
                            <div class="row">

                                <h2 align="center" style="color:#3f51b5"> select an existing date range </h2>
                            </div>
                            <div class="row">
                                <h2 align="center" style="color:#3f51b5"> or add a new one</h2>
                            </div>
                        <!-- </div> -->
                    </div>
                <!-- </div> -->
            </div>

            <div *ngIf="isParagraphEdit">
                <div class="col-xs-12">
                    <div class="row" >
                        <h2 style="color: #3f51b5" 
                            class="d-flex justify-content-center">
                            base setting 
                        </h2>
                    </div>
                </div>
                <div class="row">
                    <app-shared-calendar-status-item-view *ngIf="!isUpdatingCalendar"
                       [sharedCalendarStatus_id]="sharedCalendarStatusItem._id"
                       [sharedCalendarStatus_owner]="sharedCalendarStatus_owner"
                       [isParagraphEdit]="isParagraphEdit"
                       [selectedRangeDatesInfo]="sharedCalendarStatusItem.rangeDatesInfoSetting"
                       [selectedRangeDatesInfo_Index]="selectedRangeDatesInfo_Index"
                       (editRangeDateEvent)="onEditRangeDate_index($event)"
                       [changeSelectedDatesView]="changeSelectedDates.asObservable()"
                       (updateRangeDatesInfo)="onUpdateRangeDatesInfo($event)"
                       (updateSharedCalendarStatusResult)="onUpdateSharedCalendarStatusResult($event)"
                       [editJustAddedRangeInfo]="editJustAddedRangeInfo.asObservable()"
                       (emit_Update_SharedcalendarStatus)="onEmit_Update_SharedcalendarStatus($event)">
                    </app-shared-calendar-status-item-view>
                </div>
            </div>
        <!-- END SECOND side card -->
    </div>
<br>
</div>

<!-- IF IS DESKTOP ------------------------------------------------------------------------------ -->

<div class="row" *ngIf="!(isHandset$ | async)">
    <div class="col-4">


         
                    <div class="row" >
                        <div class="d-flex justify-content-center align-items-center">
                            <h2 style="color: #3f51b5"> {{sharedCalendarStatusItem.name}}</h2>
                        </div>
                    </div>
                
                    <div class="custom-calendar">
                        <!-- *ngIf="!isUpdatingCalendar" -->
                      <mat-calendar #calendar
                                     *ngIf="!isAddingRange"
                                    [selected]="selectedDates" 
                                    [dateClass]="dateClassCallback"
                                    (selectedChange)="onDateSelection($event)"
                                    >
                      </mat-calendar>
                    
                      <mat-calendar #calendar
                                    *ngIf="isAddingRange"
                                    [selected]="selectedDates" 
                                    [dateClass]="dateClassCallback"
                                    (selectedChange)="onDateSelection($event)"
                      >
                      </mat-calendar>
                    </div>
                
                    <hr *ngIf="!isAddingRange">
                
                    <div class="row" *ngIf="!isAddingRange && !isParagraphEdit">
                        <div class="col">
                            <div class="row">
                                <div class="col">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <button *ngIf="sharedCalendarStatusItem.rangeDatesInfos.length > 0"
                                                class="btn btn-danger btn-sm"
                                                type="button"
                                                (click)="onDeleteRanges()">
                                            delete
                                        </button>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="d-flex justify-content-center align-items-center">
                                        <button class="btn btn-primary btn-sm" 
                                                (click)="onAddRange()"
                                                type="button">
                                            add
                                        </button>
                                    </div>
                                </div>
                            </div>
                
                        </div>
                    </div>
                
    

        <!-- END side card -->
    </div>

   

    <!-- *ngIf="aRangeDateInfoExist" -->
    <div class="col-7" >
         <!-- *ngIf="canViewIt"  -->
         <!-- START SECOND side card -->
            <div *ngIf="!isParagraphEdit && aRangeDateInfoExist">
                <app-shared-calendar-status-item-view *ngIf="!isUpdatingCalendar"
                [sharedCalendarStatus_id]="sharedCalendarStatusItem._id"
                [sharedCalendarStatus_owner]="sharedCalendarStatus_owner"
                [canModifyDateRange_date]="canUseIt"
                [isParagraphEdit]="isParagraphEdit"
                [selectedRangeDatesInfo]="selectedRangeDatesInfo"
                [selectedRangeDatesInfo_Index]="selectedRangeDatesInfo_Index"
                (editRangeDateEvent)="onEditRangeDate_index($event)"
                [changeSelectedDatesView]="changeSelectedDates.asObservable()"
                (updateRangeDatesInfo)="onUpdateRangeDatesInfo($event)"
                (updateSharedCalendarStatusResult)="onUpdateSharedCalendarStatusResult($event)"
                [editJustAddedRangeInfo]="editJustAddedRangeInfo.asObservable()"
                (emit_Update_SharedcalendarStatus)="onEmit_Update_SharedcalendarStatus($event)">
                </app-shared-calendar-status-item-view>
                <!-- (emitUpdatedSelectedRangeDatesInfo_afterAdd)="onUpdateRangeDatesInfo_afterAdd($event)" -->

                <app-loading-spinner *ngIf="isUpdatingCalendar"> </app-loading-spinner>
                <!-- <app-shared-calendar-status-item-view *ngIf="isUpdatingCalendar"
                       [sharedCalendarStatus_id]="sharedCalendarStatusItem._id"
                       [sharedCalendarStatus_owner]="sharedCalendarStatus_owner"
                       [canModifyDateRange_date]="canUseIt"
                       [isParagraphEdit]="isParagraphEdit"
                       [selectedRangeDatesInfo]="selectedRangeDatesInfo"
                       [selectedRangeDatesInfo_Index]="selectedRangeDatesInfo_Index"
                       (editRangeDateEvent)="onEditRangeDate_index($event)"
                       [changeSelectedDatesView]="changeSelectedDates.asObservable()"
                       (updateRangeDatesInfo)="onUpdateRangeDatesInfo($event)"
                       (updateSharedCalendarStatusResult)="onUpdateSharedCalendarStatusResult($event)"
                       (emitUpdatedSelectedRangeDatesInfo_afterAdd)="onUpdateRangeDatesInfo_afterAdd($event)"
                       [editJustAddedRangeInfo]="editJustAddedRangeInfo.asObservable()"
                       (emit_Update_SharedcalendarStatus)="onEmit_Update_SharedcalendarStatus($event)">
                </app-shared-calendar-status-item-view> -->
            </div>

            <div *ngIf="!isParagraphEdit && !aRangeDateInfoExist">
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
                <div class="row">
                    <div class="col-xs-12">
                        <h2 align="center" style="color:#3f51b5">select an existing date range</h2>
                        <br>
                        <h2 align="center" style="color:#3f51b5"> or add a new one </h2>
                    </div>
                </div>
            </div>



            <div *ngIf="isParagraphEdit">
                <div class="row">
                    <h2 style="color: #3f51b5"
                        class="d-flex justify-content-center">
                        base setting
                    </h2>
                </div>
                <div class="row">
                    <app-shared-calendar-status-item-view *ngIf="!isUpdatingCalendar"
                       [sharedCalendarStatus_id]="sharedCalendarStatusItem._id"
                       [sharedCalendarStatus_owner]="sharedCalendarStatus_owner"
                       [isParagraphEdit]="isParagraphEdit"
                       [selectedRangeDatesInfo]="sharedCalendarStatusItem.rangeDatesInfoSetting"
                       [selectedRangeDatesInfo_Index]="selectedRangeDatesInfo_Index"
                       (editRangeDateEvent)="onEditRangeDate_index($event)"
                       [changeSelectedDatesView]="changeSelectedDates.asObservable()"
                       (updateRangeDatesInfo)="onUpdateRangeDatesInfo($event)"
                       (updateSharedCalendarStatusResult)="onUpdateSharedCalendarStatusResult($event)"
                       [editJustAddedRangeInfo]="editJustAddedRangeInfo.asObservable()"
                       (emit_Update_SharedcalendarStatus)="onEmit_Update_SharedcalendarStatus($event)">
                    </app-shared-calendar-status-item-view>
                </div>
            </div>
        <!-- END SECOND side card -->
    </div>
<br>
</div>



</div>







