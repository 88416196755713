import { Injectable } from "@angular/core";
import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from "@angular/common/http";
import { map, tap, catchError } from "rxjs/operators";
import { throwError } from "rxjs";

import { SingleWord } from "./single-word.model";
import { SingleWordInput } from "./single-word-input-list/single-word-input.model";


@Injectable ({
    providedIn: 'root' 
})

export class SingleWordHttpRequestService {

    constructor( private http: HttpClient ){}


    httpStoreVeryFirstSingleWord(singleWord: SingleWord ){
        return this.http.post(
        'http://localhost:8080/market-in-service/rest/component/toni@libero.it/singleWord', singleWord )
  }

    // httpStoreSingleWord(singleWord: SingleWord){
    //       return this.http.post(
    //       'http://localhost:8080/market-in-service/rest/component/toni@libero.it/singleWord', singleWord )
    //     //     {
    //     //         observe: 'events',
    //     //         responseType: 'json'
    //     //     }
    //     // ).pipe(tap(event => {
    //     //     console.log(event)
    //     //     if (event.type === HttpEventType.Response){
    //     //         console.log(event.body)
    //     //     }
    //     // }))
    //     // .subscribe(response => {
    //     //     console.log(response)
    //     // })
    // }



    httpFetchSingleWords(){
        let searchParams = new HttpParams
        searchParams = searchParams.append('print', 'primo parametro')
        searchParams = searchParams.append( 'secondo', 'secondo parametro ')
        return this.http.get<{ [key:string]: SingleWord}>('https://drapp-71fc8-default-rtdb.europe-west1.firebasedatabase.app/singleWords.json',
        {
            headers: new HttpHeaders ({"Custom-Header":"single word fetch"}),
            params: searchParams 
        }
        )
        .pipe(map(responseData => {
            const singleWords: SingleWord[] = [];
            for ( const key in responseData){
                if ( responseData.hasOwnProperty(key)){
                singleWords.push({...responseData[key], _id:key})
            }
          }
          console.log(singleWords)
          return singleWords
        }),
        catchError(errorRes => {
            return throwError(errorRes)
        })
        )

    }



    
    


}