import { Injectable } from "@angular/core";
import { FormResult } from "src/app/forms/form-results/form-result.model";
import { Subject, Subscription } from "rxjs";
import { InitialNullSetupService } from "src/app/services/starting-services/initial-null-setup.service";
import { DatePipe } from "@angular/common";
import { GetComponentsHttpRequestService } from "src/app/services/gets-services/get-components-http-request.sevice";
import { ErrorsDialogManagerService } from "src/app/services/other-components-services/errors-dialog-manager.service";

interface FormResultStored_MessageLasSeq_MailLastSeq {
          formResultsStored: FormResult[] ,
          messageFirstSeq: number ,
          messageLastSeq: number , 
          messageFirstDateTime: string , 
          messageLastDateTime: string,
          messagesLength: number , 
          ismessagesDone: boolean ,
          mailFirstSeq: number ,
          mailLastSeq: number , 
          mailFirstDateTime: string, 
          mailLastDateTime: string ,
          mailsLength: number , 
          isMailsDone: boolean ,
}

/**
 * forms that are visible on the DOM 
 */
interface FormResults_Displaying {
    formResults_displayed: FormResult[],
    indexOfResultDisplayed_Start: number ,
    indexOfResultDisplayed_End: number , 
    triggerIndex: number , 
    count: number 
}

interface AvailableChoice {
    name: string , 
    value: string 
  }


@Injectable ({
    providedIn: 'root' 
})

export class FormResultsService {

    adviseFormResultListIsBeenSelected = new Subject<void>()
    checkUpdateFilterResult_fromResultList = new Subject<any>()
    openFiltersSideNav_fromEmptyFormResultList = new Subject<void>()

    // scrollFormItemsToBottom = new Subject<void>()
    scrollFormListToTop = new Subject<void>()

    params: any;


    constructor( private initialNullSetupService: InitialNullSetupService , 
                 private datePipe: DatePipe , 
                 private getComponentsHttpRequestService: GetComponentsHttpRequestService,
                 private errorsDialogManagerService: ErrorsDialogManagerService

                ){}

    /**
     * SERVE TO MAKE SURE THE CALLS FROM LIST TO INDEX DON'T ADD TO THEMSELVES ESPECIALLY ON DIRECT LOGIN 
     * WHEN URL CHANGE RAPIDLY AND BOTH CALLS START ALMOST INSTANTANTLY 
     */
    isNowCalling_FormResultList: boolean 

    /**
     * serve to know if a first call has already been made , if it's been made the initial call for 
     * formresults is going to be starting from zero ... 
     * if it's true , then we just need to check the latest formResults to add at the top 
     */
    
    formResults : FormResult[] = this.initialNullSetupService.initialNullFormResults

    formResultsList: FormResult[] = this.initialNullSetupService.initialNullFormResultsList

    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.formResultChangeBackToNull
    .subscribe((res)=> {
        this.formResults = res 
        console.log('updated formResults back to null = ', res)

    });

    updateInitialNullDatafromInitialNullSetupService2: Subscription = this.initialNullSetupService.formResultsListChangeBackToNull
    .subscribe((res)=>{
        this.formResultsList = res 
        console.log('updated formResultsList = ', res)
    })
    

   
    getFormResults(){
        return this.formResults;
    };
   

    getFormResult(id: number){
        return this.formResults[id]
    };

    getFormResultBy_id(_id:string){
        return this.formResults.find(x=> x._id === _id)
    }


    aNewFormResultHasBeenAddded = new Subject<void>()
    addFormResult(formResult: FormResult){
        this.formResults.push(formResult)
        console.log(this.formResults)


        this.updateInitialComponents(this.formResults)
        this.aNewFormResultHasBeenAddded.next() // advise what to do @ formResultList / chatFormResultList
    }  // the same also when the new formResult is received 

    setParams(params: any) {
        this.params = params;
    }
    
    getParams() {
        return this.params;
    }

    /**
     * new available choises from formResultsIndexList to groupFilter 
     */
    communicateToGroupFilter_availableChoices = new Subject<AvailableChoice[]>()
    communicateToGroupFilter_newUpdatedAvailableChoices(newAvailableChoices: AvailableChoice[]){
        this.communicateToGroupFilter_availableChoices.next(newAvailableChoices)
    }

    clearSelectedFormResultsItems_fromFormResultsItems = new Subject<void>()
    onClearSelectedFormResultsItemsOnIndexList_FromResultItems(){
        this.clearSelectedFormResultsItems_fromFormResultsItems.next()
    }

    /**
     * -------------START FORM RESULT INDEX CALLS ONLY -----------------------------------------------------------------------------------------------------------------------
     */

    /**
     * 
     * getting all formResults message/email 
     * confront initial/ final dateTime of messageResults and initial/final dateTime of emails 
     * 
     * @param type 
     * @param user 
     * @param dateFrom 
     * @param dateTo 
     * @param count 
     */

    firstFormResults_messageLastSeq_mailLastSeq_haveBeenStored = new Subject<FormResultStored_MessageLasSeq_MailLastSeq>()
    getInitial_AllFormResults_ByUser_InitialDrappDate_ToLocalDateNow_Count( type: string , user: string , dateFrom: string, dateTo: string , count: number ){

    if ( this.isNowCalling_FormResultList === false ){
        const startingFormResults: FormResult[] = []
        this.getComponentsHttpRequestService.getMessageFormResults_Type_User_DateFrom_DateTo_Count( type , user , dateFrom , dateTo , count )
            .subscribe((messageFormResults: FormResult[])=>{
               
                 if ( this.isNowCalling_FormResultList === false ){

                    console.log( messageFormResults)

                    let newMessageFirstSeq: number
                    let newMessageLastSeq: number
                    let newMessageFirstDateTime: string 
                    let newMessageLastDateTime : string 
                    let newMessagesLength : number
                    let newIsMessagesDone: boolean 


                if (messageFormResults.length > 0 ){
                    newMessageFirstSeq = messageFormResults[0].seq
                    newMessageLastSeq = messageFormResults[ messageFormResults.length-1].seq
                    const localDateNow = new Date(messageFormResults[0].dateAndTime)
                    const localDateNow_String = this.datePipe.transform( localDateNow, "yyyy-MM-ddTHH:mm:ss")
                    console.log(localDateNow_String)
                    newMessageFirstDateTime = localDateNow_String
                    const localDateNow1 = new Date(messageFormResults[ messageFormResults.length-1].dateAndTime)
                    const localDateNow_String1 = this.datePipe.transform( localDateNow1, "yyyy-MM-ddTHH:mm:ss")
                    newMessageLastDateTime = localDateNow_String1
                    newMessagesLength = messageFormResults.length 
                    if ( messageFormResults.length >= count ){ newIsMessagesDone = false }
                    if ( messageFormResults.length < count ){ newIsMessagesDone = true }
                }

                if (messageFormResults.length === 0 ){
                    newMessageFirstSeq = undefined 
                    newMessageLastSeq = undefined
                    newMessageFirstDateTime = undefined
                    newMessageLastDateTime = undefined
                    newMessagesLength = 0
                    newIsMessagesDone = true
                }
                
                

                startingFormResults.push(...messageFormResults)

                // always get the lastSeq of the latest message/email so that we know which one to call next call 
                // but always show only 20 results at the time max with the closest date so we are never wrong on calling 
                // and mixing 
                // if the results.length < count  = advise or make this operation on the component so next will call 
                // only the other one  

                this.getComponentsHttpRequestService.getMailFormResultsWith_User_DateFrom_DateTo_Count( user , dateFrom , dateTo , count )
                    .subscribe((newMailFormResults: FormResult[])=>{
                        console.log(newMailFormResults)
                        // console.log( 'last seq = ', newMailFormResults[newMailFormResults.length-1].seq )
                    if ( this.isNowCalling_FormResultList === false ){
                        startingFormResults.push(...newMailFormResults)
                        this.formResults = startingFormResults
                        console.log('UPDATED form results list = ',this.formResults)

                        this.updateInitialComponents(this.formResults)
                        /**
                         * interface FormResultList_MessageLasSeq_MailLastSeq {
                           formresultStored: FormResult[] ,
                           messageFirstSeq: number ,
                           messageLastSeq: number , 
                           mailFirstSeq: number ,
                           mailLastSeq: number 
                           }
                         */
                           let newMailFirstSeq: number
                           let newMailLastSeq: number 
                           let newMailFirstDateTime : string 
                           let newMailLastDateTime : string 
                           let newMailsLength : number 
                           let newIsMailsDone: boolean

                        if ( newMailFormResults.length > 0 ){
                             newMailFirstSeq = newMailFormResults[0].seq
                             newMailLastSeq = newMailFormResults[newMailFormResults.length-1].seq
                             const localDateNow2 = new Date(newMailFormResults[0].dateAndTime)
                             const localDateNow_String2 = this.datePipe.transform( localDateNow2, "yyyy-MM-ddTHH:mm:ss")
                             newMailFirstDateTime = localDateNow_String2
                             const localDateNow3 = new Date(newMailFormResults[newMailFormResults.length-1].dateAndTime)
                             const localDateNow_String3 = this.datePipe.transform( localDateNow3, "yyyy-MM-ddTHH:mm:ss")
                             newMailLastDateTime = localDateNow_String3
                             newMailsLength = newMailFormResults.length 
                             if ( newMailFormResults.length >= count ){ newIsMailsDone = false }
                             if ( newMailFormResults.length < count ){ newIsMailsDone = true }

                        }
                        if ( newMailFormResults.length === 0 ){
                              newMailFirstSeq = undefined 
                              newMailLastSeq = undefined 
                              newMailFirstDateTime = undefined 
                              newMailLastDateTime = undefined 
                              newMailsLength = 0 
                              newIsMailsDone = true
                        }
    

                        const newFormResultStored_MessageLasSeq_MailLastSeq: FormResultStored_MessageLasSeq_MailLastSeq = { formResultsStored: startingFormResults, 
                                                                                                                            messageFirstSeq: newMessageFirstSeq , 
                                                                                                                            messageLastSeq: newMessageLastSeq,
                                                                                                                            messageFirstDateTime: newMessageFirstDateTime,
                                                                                                                            messageLastDateTime: newMessageLastDateTime,
                                                                                                                            messagesLength: newMessagesLength , 
                                                                                                                            ismessagesDone: newIsMessagesDone , 
                                                                                                                            mailFirstSeq: newMailFirstSeq , 
                                                                                                                            mailLastSeq: newMailLastSeq , 
                                                                                                                            mailFirstDateTime: newMailFirstDateTime , 
                                                                                                                            mailLastDateTime: newMailLastDateTime , 
                                                                                                                            mailsLength: newMailsLength , 
                                                                                                                            isMailsDone: newIsMailsDone
                                                                                                                           }

                        console.log(newFormResultStored_MessageLasSeq_MailLastSeq)
                        this.firstFormResults_messageLastSeq_mailLastSeq_haveBeenStored.next(newFormResultStored_MessageLasSeq_MailLastSeq) 
                        
                    } // END SECOND isNowCalling_FormResultList === false
                    }, error => {
                        console.log( error , 'AN ERROR OCCURRED! THROWING ERROR')
                        this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'manage formResultService error 282'})
                    })

                }// END IF isNowCalling_FormResultList === false 
            }, error => {
                console.log( error , 'AN ERROR OCCURRED! THROWING ERROR')
                this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'manage formResultService error 288'})

            })

        }  // END IF isNowCalling_FormResultList
    };


    /**
     * this check if stored formResults email/messages has much difference in time , 
     * and if it's the case to call one before the other (both ways)
     * and which oce call first 
     * 
     * @param type 
     * @param user 
     * @param dateFrom 
     * @param dateTo 
     * @param count 
     * @param currentFormResultStored 
     */
    allFormResults_haveBeenStored = new Subject<void>()
    checkNextCall_Messages_Emails_Results_DatesStartEnd_beforeGettingNewResults(isComingFromFormList : boolean ,type: string , user: string , dateFrom: string, dateTo: string , count: number , currentFormResultStored: FormResultStored_MessageLasSeq_MailLastSeq){
        console.log(currentFormResultStored)


        // const isComingFromFormList: boolean = false 
        // if BOTH ARE DONE , we just pop upa message saying list is finish , still need to add the non added ones yet
        // we just signal both are done and no calls anymore . 
        if ( currentFormResultStored.ismessagesDone && currentFormResultStored.isMailsDone   ) {
            console.log( 'BOTH MESSAGES + EMAILS ARE DONE !')
            /**
             * SEND A SUBJECT TO SHOW ALL THE REMAINING STORED DATA 
             */

            this.allFormResults_haveBeenStored.next()
        }

        // if all message formresults are done and ONLY REMAIN mail formResult to get 
        if ( currentFormResultStored.ismessagesDone && !currentFormResultStored.isMailsDone  ) {
            console.log( 'MESSAGES ARE DONE !- GETTING ONLY EMAILS NOW')
            const isFirstOf2Calls: boolean = false 
            this.get_Mail_Formresults_NextTranche(isComingFromFormList, isFirstOf2Calls, type, user, dateFrom, dateTo, count, currentFormResultStored)
        }

        // if all mail formresults are done and ONLY REMAIN message formResult to get
        if ( !currentFormResultStored.ismessagesDone && currentFormResultStored.isMailsDone  ) {
            console.log( 'EMAILS ARE DONE ! - GETTING ONLY MESSAGES NOW')
            const isFirstOf2Calls: boolean = false
            this.get_Message_FormResults_NextTranche(isComingFromFormList, isFirstOf2Calls, type, user, dateFrom, dateTo, count, currentFormResultStored )
        }

        // if both message and mail have stil to get 
        if ( !currentFormResultStored.ismessagesDone && !currentFormResultStored.isMailsDone ) {
            console.log( 'BOTH STILL HAVE , checking which one first')
            // in this case check if one start end much earlier than the other , so we check which one to 
            // co we check which one has the earlier date of finish , and according to that we call that one 
            // if the date of Finish of the new tranche is earlier than the beginning of the other one 
            // we don't need to call the other one ... 
            // if the last dateTime of the first get has already passed the beginning of dateTime of the other one , 
            // then we get also the second one and mix all of them into formresults Storage 

            const messageLastDate = new Date(currentFormResultStored.messageLastDateTime)
            const mailLastdate = new Date(currentFormResultStored.mailLastDateTime)

            console.log(" MESSAGE EMAIL LAST DATES => ", messageLastDate, mailLastdate )

            const isFirstOf2Calls = true 

            if ( messageLastDate <= mailLastdate ){ 
                 console.log("MESSAGE LAST DATE COMES EARLIER, calling messages first") 
                 this.get_Message_FormResults_NextTranche(isComingFromFormList, isFirstOf2Calls, type, user, dateFrom, dateTo, count, currentFormResultStored  )
            
            }
            if ( mailLastdate < messageLastDate ){ 
                 console.log("MAIL LAST DATE COMES EARLIER, calling email first") 
                 this.get_Mail_Formresults_NextTranche(isComingFromFormList, isFirstOf2Calls, type, user, dateFrom, dateTo, count, currentFormResultStored)
            }


            
        }



    }


    /**
     * this is getting FormResults_Messages_type is sent received in this case "all" 
     * 
     * @param type 
     * @param user 
     * @param dateFrom 
     * @param dateTo 
     * @param count 
     * @param currentFormResultStored_MessageLasSeq_MailLastSeq 
     */

    /**
     * this subject is for FORM INDEX SIDE
     */
    successiveFormResults_messageLastSeq_mailLastSeq_haveBeenStored = new Subject<FormResultStored_MessageLasSeq_MailLastSeq>()
    /**
     * this subject is for FORM LIST SIDE
     */
    successive_FormResultsList_messageLastSeq_mailLastSeq_haveBeenStored = new Subject<FormResultStored_MessageLasSeq_MailLastSeq>()
    get_Message_FormResults_NextTranche( isComingFromFormList: boolean, isFirstOf2Calls: boolean ,  type: string , user: string , dateFrom: string, dateTo: string , count: number , currentFormResultStored: FormResultStored_MessageLasSeq_MailLastSeq){
        console.log(currentFormResultStored)

        this.getComponentsHttpRequestService.getMessageFormResults_Type_User_DateFrom_DateTo_Count_LastSeq( type , user , dateFrom , dateTo, count, currentFormResultStored.messageLastSeq)
            .subscribe((messageFormResults: FormResult[])=>{
                console.log(messageFormResults , 'ADDING MESSAGE FORM RESULTS TO STORAGE')

                if (!isComingFromFormList){
                    this.formResults.push(...messageFormResults)
                    this.updateInitialComponents(this.formResults)
                }
                if ( isComingFromFormList ){this.formResultsList.push(...messageFormResults) }
            

                let newMessageFirstSeq: number
                    let newMessageLastSeq: number
                    let newMessageFirstDateTime: string 
                    let newMessageLastDateTime : string 
                    let newMessagesLength : number
                    let newIsMessagesDone: boolean 


                if (messageFormResults.length > 0 ){
                    newMessageFirstSeq = messageFormResults[0].seq
                    newMessageLastSeq = messageFormResults[messageFormResults.length-1].seq
                    const localDateNow = new Date(messageFormResults[0].dateAndTime)
                    const localDateNow_String = this.datePipe.transform( localDateNow, "yyyy-MM-ddTHH:mm:ss")
                    console.log(localDateNow_String)
                    newMessageFirstDateTime = localDateNow_String
                    const localDateNow1 = new Date(messageFormResults[ messageFormResults.length-1].dateAndTime)
                    const localDateNow_String1 = this.datePipe.transform( localDateNow1, "yyyy-MM-ddTHH:mm:ss")
                    newMessageLastDateTime = localDateNow_String1
                    newMessagesLength = messageFormResults.length 
                    if ( messageFormResults.length >= count ){ newIsMessagesDone = false }
                    if ( messageFormResults.length < count ){ newIsMessagesDone = true }
                }

                if (messageFormResults.length === 0 ){
                    newMessageFirstSeq = undefined 
                    newMessageLastSeq = undefined
                    newMessageFirstDateTime = undefined
                    newMessageLastDateTime = undefined
                    newMessagesLength = 0
                    newIsMessagesDone = true
                }

                const newFormResultStored_MessageLasSeq_MailLastSeq: FormResultStored_MessageLasSeq_MailLastSeq = { formResultsStored: this.formResults, 
                                                                                                                    messageFirstSeq: newMessageFirstSeq , 
                                                                                                                    messageLastSeq: newMessageLastSeq,
                                                                                                                    messageFirstDateTime: newMessageFirstDateTime,
                                                                                                                    messageLastDateTime: newMessageLastDateTime,
                                                                                                                    messagesLength: newMessagesLength , 
                                                                                                                    ismessagesDone: newIsMessagesDone , 
                                                                                                                    mailFirstSeq: currentFormResultStored.mailFirstSeq , 
                                                                                                                    mailLastSeq: currentFormResultStored.mailLastSeq , 
                                                                                                                    mailFirstDateTime: currentFormResultStored.mailFirstDateTime , 
                                                                                                                    mailLastDateTime: currentFormResultStored.mailLastDateTime , 
                                                                                                                    mailsLength: currentFormResultStored.mailsLength , 
                                                                                                                    isMailsDone: currentFormResultStored.isMailsDone
                }

                console.log(newFormResultStored_MessageLasSeq_MailLastSeq)

                if ( isFirstOf2Calls ){ console.log('ADD CALL FOR MAILS')

                /**
                 * still check if the new latest date of messages is earlier than earlier date of email 
                 * is it's still earlier than that , we just show these first tranches to DOM and stop without calling emails
                 */
                    if ( messageFormResults.length === count ){
                         const messageLastDate = new Date(newFormResultStored_MessageLasSeq_MailLastSeq.messageLastDateTime)
                         const mailFirstdate = new Date(currentFormResultStored.mailFirstDateTime)
                         console.log(" MESSAGE EMAIL LAST DATES => ", messageLastDate, mailFirstdate )
         
                         if ( messageLastDate < mailFirstdate  ){
                              console.log('LAST MESSAGE TIME IS EARLIER THAN MAIL FIRST TIME, SO NO NEED TO CALL MAIL')
                              if (!isComingFromFormList){ this.successiveFormResults_messageLastSeq_mailLastSeq_haveBeenStored.next(newFormResultStored_MessageLasSeq_MailLastSeq)}
                              if (isComingFromFormList){this.successive_FormResultsList_messageLastSeq_mailLastSeq_haveBeenStored.next(newFormResultStored_MessageLasSeq_MailLastSeq)}

                         }
                         if ( mailFirstdate < messageLastDate){
                              console.log('LAST MESSAGE TIME IS LATER THAN MAIL FIRST TIME, NEED TO CALL MAIL')
                              const newIsFirstOf2Calls = false 
                              this.get_Mail_Formresults_NextTranche(isComingFromFormList, newIsFirstOf2Calls , type, user, dateFrom, dateTo, count, newFormResultStored_MessageLasSeq_MailLastSeq )
                         }
                    }   
                    if ( messageFormResults.length < count){
                              console.log('MESSAGES ARE FINISHED , CHECK FOR EMAILS')
                              const newIsFirstOf2Calls = false 
                              this.get_Mail_Formresults_NextTranche(isComingFromFormList, newIsFirstOf2Calls , type, user, dateFrom, dateTo, count, newFormResultStored_MessageLasSeq_MailLastSeq )
                    }
                }

                if ( !isFirstOf2Calls ){ 
                                    console.log('SEND SUBJECT TO ADD FORMRESULTS AT FORM RESULTS INDEX, is NOT comingFormFormList')
                                    if (!isComingFromFormList){ this.successiveFormResults_messageLastSeq_mailLastSeq_haveBeenStored.next(newFormResultStored_MessageLasSeq_MailLastSeq)}
                                    if (isComingFromFormList){this.successive_FormResultsList_messageLastSeq_mailLastSeq_haveBeenStored.next(newFormResultStored_MessageLasSeq_MailLastSeq)}
                }

            }, error => {
                console.log( error , 'AN ERROR OCCURRED! THROWING ERROR')
                this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'manage formResultService error 495'})
                
            })
    };

    get_Mail_Formresults_NextTranche(isComingFromFormList: boolean, isFirstOf2Calls: boolean , type: string, user: string , dateFrom: string, dateTo: string , count: number , currentFormResultStored: FormResultStored_MessageLasSeq_MailLastSeq){
        console.log(currentFormResultStored)
        this.getComponentsHttpRequestService.getMailFormResultsWith_User_DateFrom_DateTo_Count_LastSeq( user , dateFrom , dateTo , count, currentFormResultStored.mailLastSeq )
            .subscribe((newMailFormResults: FormResult[])=>{
                console.log(newMailFormResults)
                // console.log( 'last seq = ', newMailFormResults[newMailFormResults.length-1].seq )


                if (!isComingFromFormList){
                    this.formResults.push(...newMailFormResults)
                    this.updateInitialComponents(this.formResults)
                }
                if ( isComingFromFormList ){this.formResultsList.push(...newMailFormResults)}

                // this.updateInitialComponents(this.formResults)
                /**
                 * interface FormResultList_MessageLasSeq_MailLastSeq {
                   formresultStored: FormResult[] ,
                   messageFirstSeq: number ,
                   messageLastSeq: number , 
                   mailFirstSeq: number ,
                   mailLastSeq: number 
                   }
                 */
                   let newMailFirstSeq: number
                   let newMailLastSeq: number 
                   let newMailFirstDateTime : string 
                   let newMailLastDateTime : string 
                   let newMailsLength : number 
                   let newIsMailsDone: boolean

                if ( newMailFormResults.length > 0 ){
                     newMailFirstSeq = newMailFormResults[0].seq
                     newMailLastSeq = newMailFormResults[newMailFormResults.length-1].seq
                     const localDateNow2 = new Date(newMailFormResults[0].dateAndTime)
                     const localDateNow_String2 = this.datePipe.transform( localDateNow2, "yyyy-MM-ddTHH:mm:ss")
                     newMailFirstDateTime = localDateNow_String2
                     const localDateNow3 = new Date(newMailFormResults[newMailFormResults.length-1].dateAndTime)
                     const localDateNow_String3 = this.datePipe.transform( localDateNow3, "yyyy-MM-ddTHH:mm:ss")
                     newMailLastDateTime = localDateNow_String3
                     newMailsLength = newMailFormResults.length 
                     if ( newMailFormResults.length >= count ){ newIsMailsDone = false }
                     if ( newMailFormResults.length < count ){ newIsMailsDone = true }

                }
                if ( newMailFormResults.length === 0 ){
                      console.log( " THERE WERE NO MORE MAIL AFTER THAT LAST ONE ")
                      newMailFirstSeq = undefined 
                      newMailLastSeq = undefined 
                      newMailFirstDateTime = undefined 
                      newMailLastDateTime = undefined 
                      newMailsLength = 0 
                      newIsMailsDone = true
                }


                const newFormResultStored_MessageLasSeq_MailLastSeq: FormResultStored_MessageLasSeq_MailLastSeq = { formResultsStored: this.formResults, 
                                                                                                                    messageFirstSeq: currentFormResultStored.messageFirstSeq , 
                                                                                                                    messageLastSeq: currentFormResultStored.messageLastSeq ,
                                                                                                                    messageFirstDateTime: currentFormResultStored.messageFirstDateTime,
                                                                                                                    messageLastDateTime: currentFormResultStored.messageLastDateTime,
                                                                                                                    messagesLength: currentFormResultStored.messagesLength , 
                                                                                                                    ismessagesDone: currentFormResultStored.ismessagesDone , 
                                                                                                                    mailFirstSeq: newMailFirstSeq , 
                                                                                                                    mailLastSeq: newMailLastSeq , 
                                                                                                                    mailFirstDateTime: newMailFirstDateTime , 
                                                                                                                    mailLastDateTime: newMailLastDateTime , 
                                                                                                                    mailsLength: newMailsLength , 
                                                                                                                    isMailsDone: newIsMailsDone
                                                                                                                   }

                console.log(newFormResultStored_MessageLasSeq_MailLastSeq)

                if ( isFirstOf2Calls === true ){ 
                    /**
                     * still check if the new latest date of messages is earlier than earlier date of email 
                     * is it's still earlier than that , we just show these first tranches to DOM and stop without calling messages 
                     */
                    if ( newMailFormResults.length === count ){
                        const mailLastDate = new Date(newFormResultStored_MessageLasSeq_MailLastSeq.mailLastDateTime)
                        const messageFirstdate = new Date(currentFormResultStored.messageFirstDateTime)
                        console.log(" MESSAGE EMAIL LAST DATES => ", mailLastDate, messageFirstdate )
         
                        if ( mailLastDate < messageFirstdate  ){
                             console.log('LAST MAIL TIME IS EARLIER THAN MAIL FIRST TIME, SO NO NEED TO CALL MESSAGE')
                             if (!isComingFromFormList){ this.successiveFormResults_messageLastSeq_mailLastSeq_haveBeenStored.next(newFormResultStored_MessageLasSeq_MailLastSeq)}
                             if (isComingFromFormList){this.successive_FormResultsList_messageLastSeq_mailLastSeq_haveBeenStored.next(newFormResultStored_MessageLasSeq_MailLastSeq)}
                        }

                        if ( messageFirstdate < mailLastDate){
                             console.log('LAST MAIL TIME IS LATER THAN MAIL FIRST TIME, NEED TO CALL MESSAGE')
                             const newIsFirstOf2Calls = false 
                             this.get_Message_FormResults_NextTranche( isComingFromFormList, newIsFirstOf2Calls , type, user, dateFrom, dateTo, count, newFormResultStored_MessageLasSeq_MailLastSeq )
                        }
                    }
                    if ( newMailFormResults.length < count ){
                        console.log('THE MAIL ARE FINISHED , CHECK MESSAGES')
                             const newIsFirstOf2Calls = false 
                             this.get_Message_FormResults_NextTranche(isComingFromFormList, newIsFirstOf2Calls , type, user, dateFrom, dateTo, count, newFormResultStored_MessageLasSeq_MailLastSeq )
                    }
                }

                if ( isFirstOf2Calls === false ){ 
                           console.log('SEND SUBJECT TO ADD FORMRESULTS AT FORM RESULTS INDEX')
                           if (!isComingFromFormList){ this.successiveFormResults_messageLastSeq_mailLastSeq_haveBeenStored.next(newFormResultStored_MessageLasSeq_MailLastSeq)}
                           if (isComingFromFormList){this.successive_FormResultsList_messageLastSeq_mailLastSeq_haveBeenStored.next(newFormResultStored_MessageLasSeq_MailLastSeq)}
                }

            }, error => {
                console.log( error , 'AN ERROR OCCURRED! THROWING ERROR')
                this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'manage formResultService error 610'})
                
            })

            

    };

    /**
     * -------------END FORM RESULT INDEX CALLS -----------------------------------------------------------------------------------------------------------------------
     */

    /**
     * -------------START FORM RESULT LIST CALLS -----------------------------------------------------------------------------------------------------------------------
     */


    /**
     * same as before but this is for the list 
     * we divide completely the 2 list of formResults 
     * so that we can keep the formresultIndex and not call the new all the times 
     */
    firstFormResultsList_messageLastSeq_mailLastSeq_haveBeenStored = new Subject<FormResultStored_MessageLasSeq_MailLastSeq>()
    allFormResults_List_haveBeenStored = new Subject<void>()

    checkNext_List_Call_Messages_Emails_Results_DatesStartEnd_beforeGettingNewResults( isFirstOf2Calls: boolean ,
                                                                                       type: string , 
                                                                                       user: string , 
                                                                                       dateFrom: string, 
                                                                                       dateTo: string , 
                                                                                       count: number , 
                                                                                       subjParag_type: string, 
                                                                                       subject_Paragraph_Title: string , 
                                                                                       currentFormResultStored: FormResultStored_MessageLasSeq_MailLastSeq ){
        console.log(currentFormResultStored)

        const isComingFromFormList: boolean = true 
        // if BOTH ARE DONE , we just pop upa message saying list is finish , still need to add the non added ones yet
        // we just signal both are done and no calls anymore . 
        if ( currentFormResultStored.ismessagesDone && currentFormResultStored.isMailsDone   ) {
            console.log( 'BOTH MESSAGES + EMAILS ARE DONE !')
            /**
             * SEND A SUBJECT TO SHOW ALL THE REMAINING STORED DATA 
             */
            this.allFormResults_List_haveBeenStored.next()
        }

        // if all message formresults are done and ONLY REMAIN mail formResult to get 
        if ( currentFormResultStored.ismessagesDone && !currentFormResultStored.isMailsDone  ) {
            console.log( 'MESSAGES ARE DONE !- GETTING ONLY EMAILS NOW')
            isFirstOf2Calls = false
            
            if ( subjParag_type !== 'oooooooo'){ this.get_Mail_FormResultsList_ByUser_InitialDrappDate_ToLocalDateNow_Count_SubjectParagraph_LastSeq(isFirstOf2Calls, type, user, dateFrom, dateTo, count, subjParag_type, subject_Paragraph_Title, currentFormResultStored)   }
            if ( subjParag_type === 'oooooooo'){ this.get_Mail_Formresults_NextTranche(isComingFromFormList, isFirstOf2Calls, type, user, dateFrom, dateTo, count, currentFormResultStored) }  //this.get_Mail_List_FormResultsList_ByUser_InitialDrappDate_ToLocalDateNow_Count(isFirstOf2Calls, type, user, dateFrom, dateTo, count, currentFormResultStored) }
        }

        // if all mail formresults are done and ONLY REMAIN message formResult to get
        if ( !currentFormResultStored.ismessagesDone && currentFormResultStored.isMailsDone  ) {
            console.log( 'EMAILS ARE DONE ! - GETTING ONLY MESSAGES NOW')
            isFirstOf2Calls = false
            if ( subjParag_type !== 'oooooooo'){ this.get_Message_FormResultsList_ByUser_InitialDrappDate_ToLocalDateNow_Count_SubjectParagraph_LastSeq( isFirstOf2Calls, type, user, dateFrom, dateTo, count, subjParag_type, subject_Paragraph_Title, currentFormResultStored)}
            if ( subjParag_type === 'oooooooo'){ this.get_Message_FormResults_NextTranche(isComingFromFormList, isFirstOf2Calls, type, user, dateFrom, dateTo, count, currentFormResultStored ) } //this.get_Message_FormResultsList_ByUser_InitialDrappDate_ToLocalDateNow_Count( isFirstOf2Calls, type, user, dateFrom, dateTo, count, currentFormResultStored )}
            
        }

        // if both message and mail have stil to get 
        if ( !currentFormResultStored.ismessagesDone && !currentFormResultStored.isMailsDone ) {
            console.log( 'BOTH STILL HAVE , checking which one first')
            isFirstOf2Calls = true
            const messageLastDate = new Date(currentFormResultStored.messageLastDateTime)
            const mailLastdate = new Date(currentFormResultStored.mailLastDateTime)
            console.log(" MESSAGE LAST DATES => ", messageLastDate," EMAIL LAST DATES => ", mailLastdate )


            if ( messageLastDate <= mailLastdate ){ 
                 console.log("MESSAGE LAST DATE COMES EARLIER, calling messages first") 
                 if ( subjParag_type !== 'oooooooo'){ this.get_Message_FormResultsList_ByUser_InitialDrappDate_ToLocalDateNow_Count_SubjectParagraph_LastSeq(isFirstOf2Calls, type, user, dateFrom, dateTo, count, subjParag_type, subject_Paragraph_Title, currentFormResultStored)}
                 if ( subjParag_type === 'oooooooo'){ this.get_Message_FormResults_NextTranche(isComingFromFormList, isFirstOf2Calls, type, user, dateFrom, dateTo, count, currentFormResultStored )}
            }

            if ( mailLastdate < messageLastDate ){ 
                 console.log("MAIL LAST DATE COMES EARLIER, calling email first") 

                    if ( subjParag_type !== 'oooooooo'){ this.get_Mail_FormResultsList_ByUser_InitialDrappDate_ToLocalDateNow_Count_SubjectParagraph_LastSeq(isFirstOf2Calls, type, user, dateFrom, dateTo, count, subjParag_type, subject_Paragraph_Title, currentFormResultStored)  }
                    if ( subjParag_type === 'oooooooo'){ this.get_Mail_Formresults_NextTranche(isComingFromFormList, isFirstOf2Calls, type, user, dateFrom, dateTo, count, currentFormResultStored) }  // this.get_Mail_List_FormResultsList_ByUser_InitialDrappDate_ToLocalDateNow_Count(isFirstOf2Calls, type, user, dateFrom, dateTo, count, currentFormResultStored) }
                 
                
            }
        }
    }
 
    /**
     * FIRST CALL MESSAGE NO SUBJECT
     */
    get_FirstCallFromParam_Message_FormResultsList_ByUser_InitialDrappDate_ToLocalDateNow_Count(  isFirstOf2Calls: boolean ,
                                                                                                  type: string , 
                                                                                                  user: string , 
                                                                                                  dateFrom: string, 
                                                                                                  dateTo: string , 
                                                                                                  count: number , 
                                                                                                  currentFormResultStored: FormResultStored_MessageLasSeq_MailLastSeq ){

    const startingFormResults: FormResult[] = []
    this.getComponentsHttpRequestService.getMessageFormResults_Type_User_DateFrom_DateTo_Count( type , user , dateFrom , dateTo , count )
        .subscribe((messageFormResults: FormResult[])=>{
            console.log( messageFormResults)

                let newMessageFirstSeq: number
                let newMessageLastSeq: number
                let newMessageFirstDateTime: string 
                let newMessageLastDateTime : string 
                let newMessagesLength : number
                let newIsMessagesDone: boolean 


            if (messageFormResults.length > 0 ){
                newMessageFirstSeq = messageFormResults[0].seq
                newMessageLastSeq = messageFormResults[ messageFormResults.length-1].seq
                const localDateNow = new Date(messageFormResults[0].dateAndTime)
                const localDateNow_String = this.datePipe.transform( localDateNow, "yyyy-MM-ddTHH:mm:ss")
                console.log(localDateNow_String)
                newMessageFirstDateTime = localDateNow_String
                const localDateNow1 = new Date(messageFormResults[ messageFormResults.length-1].dateAndTime)
                const localDateNow_String1 = this.datePipe.transform( localDateNow1, "yyyy-MM-ddTHH:mm:ss")
                newMessageLastDateTime = localDateNow_String1
                newMessagesLength = messageFormResults.length 
                if ( messageFormResults.length >= count ){ newIsMessagesDone = false }
                if ( messageFormResults.length < count ){ newIsMessagesDone = true }
            }

            if (messageFormResults.length === 0 ){
                newMessageFirstSeq = undefined 
                newMessageLastSeq = undefined
                newMessageFirstDateTime = undefined
                newMessageLastDateTime = undefined
                newMessagesLength = 0
                newIsMessagesDone = true
            }
            
            startingFormResults.push(...messageFormResults)
            this.formResultsList = startingFormResults

            const newFormResultStored_MessageLasSeq_MailLastSeq: FormResultStored_MessageLasSeq_MailLastSeq = { formResultsStored: startingFormResults, 
                messageFirstSeq: newMessageFirstSeq , 
                messageLastSeq: newMessageLastSeq,
                messageFirstDateTime: newMessageFirstDateTime,
                messageLastDateTime: newMessageLastDateTime,
                messagesLength: newMessagesLength , 
                ismessagesDone: newIsMessagesDone , 
                mailFirstSeq: currentFormResultStored.mailFirstSeq , 
                mailLastSeq: currentFormResultStored.mailLastSeq , 
                mailFirstDateTime: currentFormResultStored.mailFirstDateTime , 
                mailLastDateTime: currentFormResultStored.mailLastDateTime , 
                mailsLength: currentFormResultStored.mailsLength , 
                isMailsDone: currentFormResultStored.isMailsDone
               }

console.log(newFormResultStored_MessageLasSeq_MailLastSeq)
this.firstFormResultsList_messageLastSeq_mailLastSeq_haveBeenStored.next(newFormResultStored_MessageLasSeq_MailLastSeq)

            
        }, error => {
                console.log( error , 'AN ERROR OCCURRED! THROWING ERROR')
                this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'manage formResultService error 773'})
            })



}

/**
 * FIRST CALL CHAT MESSAGE NO SUBJECT
 * params['groupSelected'], params['groupName'],
 */
get_CHAT_FirstCallFromParam_Message_FormResultsList_ByUser_UserChatRoomId_InitialDrappDate_ToLocalDateNow_Count(  type: string , 
                                                                                                                  user: string , 
                                                                                                                  userChatRoomId: string,
                                                                                                                  dateFrom: string, 
                                                                                                                  dateTo: string , 
                                                                                                                  count: number , 
                                                                                                                  currentFormResultStored: FormResultStored_MessageLasSeq_MailLastSeq ){
        const startingFormResults: FormResult[] = []

        this.getComponentsHttpRequestService.get_CHAT_MessageFormResults_Type_User_DateFrom_DateTo_Count_UserChatRoomId(type, user, userChatRoomId, dateFrom, dateTo, count )
            .subscribe((messageFormResults: FormResult[])=>{
                console.log( messageFormResults)
    
    
                    let newMessageFirstSeq: number
                    let newMessageLastSeq: number
                    let newMessageFirstDateTime: string 
                    let newMessageLastDateTime : string 
                    let newMessagesLength : number
                    let newIsMessagesDone: boolean 
    
    
                if (messageFormResults.length > 0 ){
                    newMessageFirstSeq = messageFormResults[0].seq
                    newMessageLastSeq = messageFormResults[ messageFormResults.length-1].seq
                    const localDateNow = new Date(messageFormResults[0].dateAndTime)
                    const localDateNow_String = this.datePipe.transform( localDateNow, "yyyy-MM-ddTHH:mm:ss")
                    console.log(localDateNow_String)
                    newMessageFirstDateTime = localDateNow_String
                    const localDateNow1 = new Date(messageFormResults[ messageFormResults.length-1].dateAndTime)
                    const localDateNow_String1 = this.datePipe.transform( localDateNow1, "yyyy-MM-ddTHH:mm:ss")
                    newMessageLastDateTime = localDateNow_String1
                    newMessagesLength = messageFormResults.length 
                    if ( messageFormResults.length >= count ){ newIsMessagesDone = false }
                    if ( messageFormResults.length < count ){ newIsMessagesDone = true }
                }
    
                if (messageFormResults.length === 0 ){
                    newMessageFirstSeq = undefined 
                    newMessageLastSeq = undefined
                    newMessageFirstDateTime = undefined
                    newMessageLastDateTime = undefined
                    newMessagesLength = 0
                    newIsMessagesDone = true
                }
                
                startingFormResults.push(...messageFormResults)
                this.formResultsList = startingFormResults
    
                const newFormResultStored_MessageLasSeq_MailLastSeq: FormResultStored_MessageLasSeq_MailLastSeq = { formResultsStored: startingFormResults, 
                    messageFirstSeq: newMessageFirstSeq , 
                    messageLastSeq: newMessageLastSeq,
                    messageFirstDateTime: newMessageFirstDateTime,
                    messageLastDateTime: newMessageLastDateTime,
                    messagesLength: newMessagesLength , 
                    ismessagesDone: newIsMessagesDone , 
                    mailFirstSeq: currentFormResultStored.mailFirstSeq , 
                    mailLastSeq: currentFormResultStored.mailLastSeq , 
                    mailFirstDateTime: currentFormResultStored.mailFirstDateTime , 
                    mailLastDateTime: currentFormResultStored.mailLastDateTime , 
                    mailsLength: currentFormResultStored.mailsLength , 
                    isMailsDone: currentFormResultStored.isMailsDone
                   }
    
    console.log(newFormResultStored_MessageLasSeq_MailLastSeq)
    this.firstFormResultsList_messageLastSeq_mailLastSeq_haveBeenStored.next(newFormResultStored_MessageLasSeq_MailLastSeq)
    
                
            }, error => {
                    console.log( error , 'AN ERROR OCCURRED! THROWING ERROR')
                    this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'manage formResultService error 854'})
                    
                })
    
}

  /**
     * FIRST CALL MESSAGE WITH SUBJECT / PARAGDESCRIPTION
     */
get_FirstCallFromParam_Message_FormResultsList_ByUser_InitialDrappDate_ToLocalDateNow_Count_SubjectParagraph( 
                     type: string , 
                     user: string , 
                     dateFrom: string, 
                     dateTo: string , 
                     count: number , 
                     subjParag_type: string, 
                     subject_Paragraph_Title: string , 
                     currentFormResultStored: FormResultStored_MessageLasSeq_MailLastSeq ){

const startingFormResults: FormResult[] = []
this.getComponentsHttpRequestService.getMessageFormResults_Type_User_SubjectParagraph_DateFrom_DateTo_Count(type, user, subjParag_type, subject_Paragraph_Title, dateFrom, dateTo, count )
    .subscribe((messageFormResults: FormResult[])=>{
        console.log( messageFormResults)


            let newMessageFirstSeq: number
            let newMessageLastSeq: number
            let newMessageFirstDateTime: string 
            let newMessageLastDateTime : string 
            let newMessagesLength : number
            let newIsMessagesDone: boolean 


        if (messageFormResults.length > 0 ){
            newMessageFirstSeq = messageFormResults[0].seq
            newMessageLastSeq = messageFormResults[ messageFormResults.length-1].seq
            const localDateNow = new Date(messageFormResults[0].dateAndTime)
            const localDateNow_String = this.datePipe.transform( localDateNow, "yyyy-MM-ddTHH:mm:ss")
            console.log(localDateNow_String)
            newMessageFirstDateTime = localDateNow_String
            const localDateNow1 = new Date(messageFormResults[ messageFormResults.length-1].dateAndTime)
            const localDateNow_String1 = this.datePipe.transform( localDateNow1, "yyyy-MM-ddTHH:mm:ss")
            newMessageLastDateTime = localDateNow_String1
            newMessagesLength = messageFormResults.length 
            if ( messageFormResults.length >= count ){ newIsMessagesDone = false }
            if ( messageFormResults.length < count ){ newIsMessagesDone = true }
        }

        if (messageFormResults.length === 0 ){
            newMessageFirstSeq = undefined 
            newMessageLastSeq = undefined
            newMessageFirstDateTime = undefined
            newMessageLastDateTime = undefined
            newMessagesLength = 0
            newIsMessagesDone = true
        }
        
        startingFormResults.push(...messageFormResults)
        this.formResultsList = startingFormResults

        const newFormResultStored_MessageLasSeq_MailLastSeq: FormResultStored_MessageLasSeq_MailLastSeq = { formResultsStored: startingFormResults, 
            messageFirstSeq: newMessageFirstSeq , 
            messageLastSeq: newMessageLastSeq,
            messageFirstDateTime: newMessageFirstDateTime,
            messageLastDateTime: newMessageLastDateTime,
            messagesLength: newMessagesLength , 
            ismessagesDone: newIsMessagesDone , 
            mailFirstSeq: currentFormResultStored.mailFirstSeq , 
            mailLastSeq: currentFormResultStored.mailLastSeq , 
            mailFirstDateTime: currentFormResultStored.mailFirstDateTime , 
            mailLastDateTime: currentFormResultStored.mailLastDateTime , 
            mailsLength: currentFormResultStored.mailsLength , 
            isMailsDone: currentFormResultStored.isMailsDone
           }

console.log(newFormResultStored_MessageLasSeq_MailLastSeq)
this.firstFormResultsList_messageLastSeq_mailLastSeq_haveBeenStored.next(newFormResultStored_MessageLasSeq_MailLastSeq)

        
    }, error => {
        console.log( error , 'AN ERROR OCCURRED! THROWING ERROR')
        this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'manage formResultService error 953'})
        
    })



}

  /**
     * FIRST CALL MESSAGE WITH SUBJECT / PARAGDESCRIPTION
     */
  get_CHAT_FirstCallFromParam_Message_FormResultsList_ByUser_UserChatRoomId_InitialDrappDate_ToLocalDateNow_Count_SubjectParagraph( 
                                                                                                                                    type: string , 
                                                                                                                                    user: string , 
                                                                                                                                    userChatRoomId: string ,
                                                                                                                                    dateFrom: string, 
                                                                                                                                    dateTo: string , 
                                                                                                                                    count: number , 
                                                                                                                                    subjParag_type: string, 
                                                                                                                                    subject_Paragraph_Title: string , 
                                                                                                                                    currentFormResultStored: FormResultStored_MessageLasSeq_MailLastSeq ){

const startingFormResults: FormResult[] = []
this.getComponentsHttpRequestService.get_CHAT_MessageFormResults_Type_User_DateFrom_DateTo_Count_UserChatRoomId__SubjectParagraph(type, user, userChatRoomId, subjParag_type, subject_Paragraph_Title, dateFrom, dateTo, count )
.subscribe((messageFormResults: FormResult[])=>{
console.log( messageFormResults)


let newMessageFirstSeq: number
let newMessageLastSeq: number
let newMessageFirstDateTime: string 
let newMessageLastDateTime : string 
let newMessagesLength : number
let newIsMessagesDone: boolean 


if (messageFormResults.length > 0 ){
newMessageFirstSeq = messageFormResults[0].seq
newMessageLastSeq = messageFormResults[ messageFormResults.length-1].seq
const localDateNow = new Date(messageFormResults[0].dateAndTime)
const localDateNow_String = this.datePipe.transform( localDateNow, "yyyy-MM-ddTHH:mm:ss")
console.log(localDateNow_String)
newMessageFirstDateTime = localDateNow_String
const localDateNow1 = new Date(messageFormResults[ messageFormResults.length-1].dateAndTime)
const localDateNow_String1 = this.datePipe.transform( localDateNow1, "yyyy-MM-ddTHH:mm:ss")
newMessageLastDateTime = localDateNow_String1
newMessagesLength = messageFormResults.length 
if ( messageFormResults.length >= count ){ newIsMessagesDone = false }
if ( messageFormResults.length < count ){ newIsMessagesDone = true }
}

if (messageFormResults.length === 0 ){
newMessageFirstSeq = undefined 
newMessageLastSeq = undefined
newMessageFirstDateTime = undefined
newMessageLastDateTime = undefined
newMessagesLength = 0
newIsMessagesDone = true
}

startingFormResults.push(...messageFormResults)
this.formResultsList = startingFormResults

const newFormResultStored_MessageLasSeq_MailLastSeq: FormResultStored_MessageLasSeq_MailLastSeq = { formResultsStored: startingFormResults, 
messageFirstSeq: newMessageFirstSeq , 
messageLastSeq: newMessageLastSeq,
messageFirstDateTime: newMessageFirstDateTime,
messageLastDateTime: newMessageLastDateTime,
messagesLength: newMessagesLength , 
ismessagesDone: newIsMessagesDone , 
mailFirstSeq: currentFormResultStored.mailFirstSeq , 
mailLastSeq: currentFormResultStored.mailLastSeq , 
mailFirstDateTime: currentFormResultStored.mailFirstDateTime , 
mailLastDateTime: currentFormResultStored.mailLastDateTime , 
mailsLength: currentFormResultStored.mailsLength , 
isMailsDone: currentFormResultStored.isMailsDone
}

console.log(newFormResultStored_MessageLasSeq_MailLastSeq)
this.firstFormResultsList_messageLastSeq_mailLastSeq_haveBeenStored.next(newFormResultStored_MessageLasSeq_MailLastSeq)


}, error => {
console.log( error , 'AN ERROR OCCURRED! THROWING ERROR')
this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'manage formResultService error 1019'})

})



}


  /**
     * FIRST CALL MAIL NO SUBJECT
     */
get_FirstCallFromParam_Mail_List_FormResultsList_ByUser_InitialDrappDate_ToLocalDateNow_Count( 
         user: string , 
         dateFrom: string, 
         dateTo: string , 
         count: number , 
         currentFormResultStored: FormResultStored_MessageLasSeq_MailLastSeq ){

    const startingFormResults: FormResult[] = []
    this.getComponentsHttpRequestService.getMailFormResultsWith_User_DateFrom_DateTo_Count(user, dateFrom, dateTo, count )
    .subscribe((newMailFormResults: FormResult[])=>{
        console.log(newMailFormResults)
        // console.log( 'last seq = ', newMailFormResults[newMailFormResults.length-1].seq )
    // if ( this.isNowCalling_FormResultList === true ){
        startingFormResults.push(...newMailFormResults)
        this.formResultsList = startingFormResults
        console.log('UPDATED form results list = ',this.formResultsList)

        this.updateInitialComponents(this.formResults)
        /**
         * interface FormResultList_MessageLasSeq_MailLastSeq {
           formresultStored: FormResult[] ,
           messageFirstSeq: number ,
           messageLastSeq: number , 
           mailFirstSeq: number ,
           mailLastSeq: number 
           }
         */
           let newMailFirstSeq: number
           let newMailLastSeq: number 
           let newMailFirstDateTime : string 
           let newMailLastDateTime : string 
           let newMailsLength : number 
           let newIsMailsDone: boolean

        if ( newMailFormResults.length > 0 ){
             newMailFirstSeq = newMailFormResults[0].seq
             newMailLastSeq = newMailFormResults[newMailFormResults.length-1].seq
             const localDateNow2 = new Date(newMailFormResults[0].dateAndTime)
             const localDateNow_String2 = this.datePipe.transform( localDateNow2, "yyyy-MM-ddTHH:mm:ss")
             newMailFirstDateTime = localDateNow_String2
             const localDateNow3 = new Date(newMailFormResults[newMailFormResults.length-1].dateAndTime)
             const localDateNow_String3 = this.datePipe.transform( localDateNow3, "yyyy-MM-ddTHH:mm:ss")
             newMailLastDateTime = localDateNow_String3
             newMailsLength = newMailFormResults.length 
             if ( newMailFormResults.length >= count ){ newIsMailsDone = false }
             if ( newMailFormResults.length < count ){ newIsMailsDone = true }

        }
        if ( newMailFormResults.length === 0 ){
              newMailFirstSeq = undefined 
              newMailLastSeq = undefined 
              newMailFirstDateTime = undefined 
              newMailLastDateTime = undefined 
              newMailsLength = 0 
              newIsMailsDone = true
        }


        const newFormResultStored_MessageLasSeq_MailLastSeq: FormResultStored_MessageLasSeq_MailLastSeq = { formResultsStored: startingFormResults, 
                                                                                                            messageFirstSeq: currentFormResultStored.messageFirstSeq , 
                                                                                                            messageLastSeq: currentFormResultStored.messageLastSeq,
                                                                                                            messageFirstDateTime: currentFormResultStored.messageFirstDateTime,
                                                                                                            messageLastDateTime: currentFormResultStored.messageLastDateTime,
                                                                                                            messagesLength: currentFormResultStored.messagesLength , 
                                                                                                            ismessagesDone: currentFormResultStored.ismessagesDone , 
                                                                                                            mailFirstSeq: newMailFirstSeq , 
                                                                                                            mailLastSeq: newMailLastSeq , 
                                                                                                            mailFirstDateTime: newMailFirstDateTime , 
                                                                                                            mailLastDateTime: newMailLastDateTime , 
                                                                                                            mailsLength: newMailsLength , 
                                                                                                            isMailsDone: newIsMailsDone
                                                                                                           }

        console.log(newFormResultStored_MessageLasSeq_MailLastSeq)
        this.firstFormResultsList_messageLastSeq_mailLastSeq_haveBeenStored.next(newFormResultStored_MessageLasSeq_MailLastSeq) 
        
    // } // END SECOND isNowCalling_FormResultList === true
    }, error => {
        console.log( error , 'AN ERROR OCCURRED! THROWING ERROR')

        this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'manage formResultService error 1109'})
        
    })


}
  /**
     * FIRST CALL MAIL WITH SUBJECT
     */
get_FirstCallFromParam_Mail_FormResultsList_ByUser_InitialDrappDate_ToLocalDateNow_Count_SubjectParagraph( user: string , 
                                                                                                           dateFrom: string, 
                                                                                                           dateTo: string , 
                                                                                                           count: number , 
                                                                                                           subjParag_type: string, 
                                                                                                           subject_Paragraph_Title: string , 
                                                                                                           currentFormResultStored: FormResultStored_MessageLasSeq_MailLastSeq ){

const startingFormResults: FormResult[] = []
this.getComponentsHttpRequestService.getMailFormResultsWith_User_SubjectParagraph_DateFrom_DateTo_Count(user, subjParag_type, subject_Paragraph_Title, dateFrom, dateTo, count )
    .subscribe((newMailFormResults: FormResult[])=>{
    console.log(newMailFormResults)
  
    startingFormResults.push(...newMailFormResults)
    this.formResultsList = startingFormResults
    console.log('UPDATED form results list = ',this.formResultsList)

  
       let newMailFirstSeq: number
       let newMailLastSeq: number 
       let newMailFirstDateTime : string 
       let newMailLastDateTime : string 
       let newMailsLength : number 
       let newIsMailsDone: boolean

    if ( newMailFormResults.length > 0 ){
         newMailFirstSeq = newMailFormResults[0].seq
         newMailLastSeq = newMailFormResults[newMailFormResults.length-1].seq
         const localDateNow2 = new Date(newMailFormResults[0].dateAndTime)
         const localDateNow_String2 = this.datePipe.transform( localDateNow2, "yyyy-MM-ddTHH:mm:ss")
         newMailFirstDateTime = localDateNow_String2
         const localDateNow3 = new Date(newMailFormResults[newMailFormResults.length-1].dateAndTime)
         const localDateNow_String3 = this.datePipe.transform( localDateNow3, "yyyy-MM-ddTHH:mm:ss")
         newMailLastDateTime = localDateNow_String3
         newMailsLength = newMailFormResults.length 
         if ( newMailFormResults.length >= count ){ newIsMailsDone = false }
         if ( newMailFormResults.length < count ){ newIsMailsDone = true }

    }
    if ( newMailFormResults.length === 0 ){
          newMailFirstSeq = undefined 
          newMailLastSeq = undefined 
          newMailFirstDateTime = undefined 
          newMailLastDateTime = undefined 
          newMailsLength = 0 
          newIsMailsDone = true
    }


    const newFormResultStored_MessageLasSeq_MailLastSeq: FormResultStored_MessageLasSeq_MailLastSeq = { formResultsStored: startingFormResults, 
                                                                                                        messageFirstSeq: currentFormResultStored.messageFirstSeq , 
                                                                                                        messageLastSeq: currentFormResultStored.messageLastSeq,
                                                                                                        messageFirstDateTime: currentFormResultStored.messageFirstDateTime,
                                                                                                        messageLastDateTime: currentFormResultStored.messageLastDateTime,
                                                                                                        messagesLength: currentFormResultStored.messagesLength , 
                                                                                                        ismessagesDone: currentFormResultStored.ismessagesDone , 
                                                                                                        mailFirstSeq: newMailFirstSeq , 
                                                                                                        mailLastSeq: newMailLastSeq , 
                                                                                                        mailFirstDateTime: newMailFirstDateTime , 
                                                                                                        mailLastDateTime: newMailLastDateTime , 
                                                                                                        mailsLength: newMailsLength , 
                                                                                                        isMailsDone: newIsMailsDone
                                                                                                       }

    console.log(newFormResultStored_MessageLasSeq_MailLastSeq)
    this.firstFormResultsList_messageLastSeq_mailLastSeq_haveBeenStored.next(newFormResultStored_MessageLasSeq_MailLastSeq) 
    

}, error => {
    console.log( error , 'AN ERROR OCCURRED! THROWING ERROR')
    this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'manage formResultService error 1190'})
    
})


}

    /**
     * GET MESSAGE ON SECOND CALL WITH SUBJECT 
     */
    get_Message_FormResultsList_ByUser_InitialDrappDate_ToLocalDateNow_Count_SubjectParagraph_LastSeq( 
                                                                                            isFirstOf2Calls: boolean ,
                                                                                            type: string , 
                                                                                            user: string , 
                                                                                            dateFrom: string, 
                                                                                            dateTo: string , 
                                                                                            count: number , 
                                                                                            subjParag_type: string, 
                                                                                            subject_Paragraph_Title: string , 
                                                                                            currentFormResultStored: FormResultStored_MessageLasSeq_MailLastSeq ){
    console.log('we are getting messages list with subjects , is first call out of 2 =>', isFirstOf2Calls, currentFormResultStored )


        this.getComponentsHttpRequestService.getMessageFormResults_Type_User_SubjectParagraph_DateFrom_DateTo_Count_LastSeq(type, user, subjParag_type, subject_Paragraph_Title, dateFrom, dateTo, count, currentFormResultStored.messageLastSeq )
        .subscribe((messageFormResults: FormResult[])=>{
            console.log(messageFormResults , 'ADDING MESSAGE FORM RESULTS TO STORAGE')

        
            this.formResultsList.push(...messageFormResults) 
        

            let newMessageFirstSeq: number
                let newMessageLastSeq: number
                let newMessageFirstDateTime: string 
                let newMessageLastDateTime : string 
                let newMessagesLength : number
                let newIsMessagesDone: boolean 


            if (messageFormResults.length > 0 ){
                newMessageFirstSeq = messageFormResults[0].seq
                newMessageLastSeq = messageFormResults[messageFormResults.length-1].seq
                const localDateNow = new Date(messageFormResults[0].dateAndTime)
                const localDateNow_String = this.datePipe.transform( localDateNow, "yyyy-MM-ddTHH:mm:ss")
                console.log(localDateNow_String)
                newMessageFirstDateTime = localDateNow_String
                const localDateNow1 = new Date(messageFormResults[ messageFormResults.length-1].dateAndTime)
                const localDateNow_String1 = this.datePipe.transform( localDateNow1, "yyyy-MM-ddTHH:mm:ss")
                newMessageLastDateTime = localDateNow_String1
                newMessagesLength = messageFormResults.length 
                if ( messageFormResults.length >= count ){ newIsMessagesDone = false }
                if ( messageFormResults.length < count ){ newIsMessagesDone = true }
            }

            if (messageFormResults.length === 0 ){
                newMessageFirstSeq = undefined 
                newMessageLastSeq = undefined
                newMessageFirstDateTime = undefined
                newMessageLastDateTime = undefined
                newMessagesLength = 0
                newIsMessagesDone = true
            }

            const newFormResultStored_MessageLasSeq_MailLastSeq: FormResultStored_MessageLasSeq_MailLastSeq = { formResultsStored: this.formResultsList, 
                                                                                                                messageFirstSeq: newMessageFirstSeq , 
                                                                                                                messageLastSeq: newMessageLastSeq,
                                                                                                                messageFirstDateTime: newMessageFirstDateTime,
                                                                                                                messageLastDateTime: newMessageLastDateTime,
                                                                                                                messagesLength: newMessagesLength , 
                                                                                                                ismessagesDone: newIsMessagesDone , 
                                                                                                                mailFirstSeq: currentFormResultStored.mailFirstSeq , 
                                                                                                                mailLastSeq: currentFormResultStored.mailLastSeq , 
                                                                                                                mailFirstDateTime: currentFormResultStored.mailFirstDateTime , 
                                                                                                                mailLastDateTime: currentFormResultStored.mailLastDateTime , 
                                                                                                                mailsLength: currentFormResultStored.mailsLength , 
                                                                                                                isMailsDone: currentFormResultStored.isMailsDone
            }

            console.log(newFormResultStored_MessageLasSeq_MailLastSeq)

            if ( isFirstOf2Calls ){ console.log('ADD CALL FOR MAILS')

            /**
             * still check if the new latest date of messages is earlier than earlier date of email 
             * is it's still earlier than that , we just show these first tranches to DOM and stop without calling emails
             */
                if ( messageFormResults.length === count ){
                     const messageLastDate = new Date(newFormResultStored_MessageLasSeq_MailLastSeq.messageLastDateTime)
                     const mailFirstdate = new Date(currentFormResultStored.mailFirstDateTime)
                     console.log(" MESSAGE EMAIL LAST DATES => ", messageLastDate, mailFirstdate )
     
                     if ( messageLastDate < mailFirstdate  ){
                          console.log('LAST MESSAGE TIME IS EARLIER THAN MAIL FIRST TIME, SO NO NEED TO CALL MAIL')
                          this.successive_FormResultsList_messageLastSeq_mailLastSeq_haveBeenStored.next(newFormResultStored_MessageLasSeq_MailLastSeq)

                     }
                     if ( mailFirstdate < messageLastDate){
                          console.log('LAST MESSAGE TIME IS LATER THAN MAIL FIRST TIME, NEED TO CALL MAIL')
                          const newIsFirstOf2Calls = false 
                          this.get_Mail_FormResultsList_ByUser_InitialDrappDate_ToLocalDateNow_Count_SubjectParagraph_LastSeq(newIsFirstOf2Calls, type, user, dateFrom, dateTo, count, subjParag_type, subject_Paragraph_Title, newFormResultStored_MessageLasSeq_MailLastSeq)

                     }
                }   
                if ( messageFormResults.length < count){
                          console.log('MESSAGES ARE FINISHED , CHECK FOR EMAILS')
                          const newIsFirstOf2Calls = false 
                          this.get_Mail_FormResultsList_ByUser_InitialDrappDate_ToLocalDateNow_Count_SubjectParagraph_LastSeq(newIsFirstOf2Calls, type, user, dateFrom, dateTo, count, subjParag_type, subject_Paragraph_Title, newFormResultStored_MessageLasSeq_MailLastSeq)
                          
                }
            }

            if ( !isFirstOf2Calls ){ 
                                console.log('SEND SUBJECT TO ADD FORMRESULTS AT FORM RESULTS INDEX, is NOT comingFormFormList')
                                
                                this.successive_FormResultsList_messageLastSeq_mailLastSeq_haveBeenStored.next(newFormResultStored_MessageLasSeq_MailLastSeq)
            }

        }, error => {
            console.log( error , 'AN ERROR OCCURRED! THROWING ERROR')
            this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'manage formResultService error 1309'})
            
        })
      
    
    };

         /**
     * GET CHAT MESSAGE ON SECOND CALL & LAST SEQ , NO SUBJECT
     */
         get_CHAT_Message_FormResultsList_ByUser_UserChatRoomId_InitialDrappDate_ToLocalDateNow_Count_LastSeq( 
                                                                                                              type: string , 
                                                                                                              user: string , 
                                                                                                              userChatRoomId: string , 
                                                                                                              dateFrom: string, 
                                                                                                              dateTo: string , 
                                                                                                              count: number , 
                                                                                                              currentFormResultStored: FormResultStored_MessageLasSeq_MailLastSeq ){


            this.getComponentsHttpRequestService.get_CHAT_MessageFormResults_Type_User_UserChatRoomId_DateFrom_DateTo_Count_LastSeq(type, user,userChatRoomId, dateFrom, dateTo, count, currentFormResultStored.messageLastSeq )
                .subscribe((messageFormResults: FormResult[])=>{
                 console.log(messageFormResults , 'ADDING MESSAGE FORM RESULTS TO STORAGE')


                this.formResultsList.push(...messageFormResults) 


                let newMessageFirstSeq: number
                let newMessageLastSeq: number
                let newMessageFirstDateTime: string 
                let newMessageLastDateTime : string 
                let newMessagesLength : number
                let newIsMessagesDone: boolean 


                if (messageFormResults.length > 0 ){
                    newMessageFirstSeq = messageFormResults[0].seq
                    newMessageLastSeq = messageFormResults[messageFormResults.length-1].seq
                    const localDateNow = new Date(messageFormResults[0].dateAndTime)
                    const localDateNow_String = this.datePipe.transform( localDateNow, "yyyy-MM-ddTHH:mm:ss")
                    console.log(localDateNow_String)
                    newMessageFirstDateTime = localDateNow_String
                    const localDateNow1 = new Date(messageFormResults[ messageFormResults.length-1].dateAndTime)
                    const localDateNow_String1 = this.datePipe.transform( localDateNow1, "yyyy-MM-ddTHH:mm:ss")
                    newMessageLastDateTime = localDateNow_String1
                    newMessagesLength = messageFormResults.length 
                    if ( messageFormResults.length >= count ){ newIsMessagesDone = false }
                    if ( messageFormResults.length < count ){ newIsMessagesDone = true }
                }

                if (messageFormResults.length === 0 ){
                    newMessageFirstSeq = undefined 
                    newMessageLastSeq = undefined
                    newMessageFirstDateTime = undefined
                    newMessageLastDateTime = undefined
                    newMessagesLength = 0
                    newIsMessagesDone = true
                }
                
                const newFormResultStored_MessageLasSeq_MailLastSeq: FormResultStored_MessageLasSeq_MailLastSeq = { formResultsStored: this.formResultsList, 
                      messageFirstSeq: newMessageFirstSeq , 
                      messageLastSeq: newMessageLastSeq,
                      messageFirstDateTime: newMessageFirstDateTime,
                      messageLastDateTime: newMessageLastDateTime,
                      messagesLength: newMessagesLength , 
                      ismessagesDone: newIsMessagesDone , 
                      mailFirstSeq: currentFormResultStored.mailFirstSeq , 
                      mailLastSeq: currentFormResultStored.mailLastSeq , 
                      mailFirstDateTime: currentFormResultStored.mailFirstDateTime , 
                      mailLastDateTime: currentFormResultStored.mailLastDateTime , 
                      mailsLength: currentFormResultStored.mailsLength , 
                      isMailsDone: currentFormResultStored.isMailsDone
                    }

                    console.log(newFormResultStored_MessageLasSeq_MailLastSeq)
                    console.log('SEND SUBJECT TO ADD FORMRESULTS AT FORM RESULTS INDEX, is NOT comingFormFormList')

                   this.successive_FormResultsList_messageLastSeq_mailLastSeq_haveBeenStored.next(newFormResultStored_MessageLasSeq_MailLastSeq)
               
               
                }, error => {
                console.log( error , 'AN ERROR OCCURRED! THROWING ERROR')
                this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'manage formResultService error 1392'})

     })


         }

     /**
     * GET CHAT MESSAGE ON SECOND CALL WITH SUBJECT & LAST SEQ
     */
     get_CHAT_Message_FormResultsList_ByUser_UserChatRoomId_InitialDrappDate_ToLocalDateNow_Count_SubjectParagraph_LastSeq( type: string , 
                                                                                                                            user: string , 
                                                                                                                            userChatRoomId: string , 
                                                                                                                            dateFrom: string, 
                                                                                                                            dateTo: string , 
                                                                                                                            count: number , 
                                                                                                                            subjParag_type: string, 
                                                                                                                            subject_Paragraph_Title: string , 
                                                                                                                            currentFormResultStored: FormResultStored_MessageLasSeq_MailLastSeq ){


            this.getComponentsHttpRequestService.get_CHAT_MessageFormResults_Type_User_UserChatRoomId_SubjectParagraph_DateFrom_DateTo_Count_LastSeq(type, user,userChatRoomId, subjParag_type, subject_Paragraph_Title, dateFrom, dateTo, count, currentFormResultStored.messageLastSeq )
                .subscribe((messageFormResults: FormResult[])=>{
                 console.log(messageFormResults , 'ADDING MESSAGE FORM RESULTS TO STORAGE')


this.formResultsList.push(...messageFormResults) 


let newMessageFirstSeq: number
let newMessageLastSeq: number
let newMessageFirstDateTime: string 
let newMessageLastDateTime : string 
let newMessagesLength : number
let newIsMessagesDone: boolean 


if (messageFormResults.length > 0 ){
newMessageFirstSeq = messageFormResults[0].seq
newMessageLastSeq = messageFormResults[messageFormResults.length-1].seq
const localDateNow = new Date(messageFormResults[0].dateAndTime)
const localDateNow_String = this.datePipe.transform( localDateNow, "yyyy-MM-ddTHH:mm:ss")
console.log(localDateNow_String)
newMessageFirstDateTime = localDateNow_String
const localDateNow1 = new Date(messageFormResults[ messageFormResults.length-1].dateAndTime)
const localDateNow_String1 = this.datePipe.transform( localDateNow1, "yyyy-MM-ddTHH:mm:ss")
newMessageLastDateTime = localDateNow_String1
newMessagesLength = messageFormResults.length 
if ( messageFormResults.length >= count ){ newIsMessagesDone = false }
if ( messageFormResults.length < count ){ newIsMessagesDone = true }
}

if (messageFormResults.length === 0 ){
newMessageFirstSeq = undefined 
newMessageLastSeq = undefined
newMessageFirstDateTime = undefined
newMessageLastDateTime = undefined
newMessagesLength = 0
newIsMessagesDone = true
}

const newFormResultStored_MessageLasSeq_MailLastSeq: FormResultStored_MessageLasSeq_MailLastSeq = { formResultsStored: this.formResultsList, 
                            messageFirstSeq: newMessageFirstSeq , 
                            messageLastSeq: newMessageLastSeq,
                            messageFirstDateTime: newMessageFirstDateTime,
                            messageLastDateTime: newMessageLastDateTime,
                            messagesLength: newMessagesLength , 
                            ismessagesDone: newIsMessagesDone , 
                            mailFirstSeq: currentFormResultStored.mailFirstSeq , 
                            mailLastSeq: currentFormResultStored.mailLastSeq , 
                            mailFirstDateTime: currentFormResultStored.mailFirstDateTime , 
                            mailLastDateTime: currentFormResultStored.mailLastDateTime , 
                            mailsLength: currentFormResultStored.mailsLength , 
                            isMailsDone: currentFormResultStored.isMailsDone
}

console.log(newFormResultStored_MessageLasSeq_MailLastSeq)
console.log('SEND SUBJECT TO ADD FORMRESULTS AT FORM RESULTS INDEX, is NOT comingFormFormList')

this.successive_FormResultsList_messageLastSeq_mailLastSeq_haveBeenStored.next(newFormResultStored_MessageLasSeq_MailLastSeq)


}, error => {
console.log( error , 'AN ERROR OCCURRED! THROWING ERROR')
this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'manage formResultService error 1476'})

})


}


/**
 *  CHAT-ITEM PAGE - FIRST FORM RESULTS CALL 
 * @param userEmail 
 * @param userChatRoomId 
 * @param count 
 * @param currentFormResultStored 
 */
get_InitalCHAT_FormResultsList_byUserChatRoomId_Count( userEmail: string , userChatRoomId: string , count: number , currentFormResultStored: FormResultStored_MessageLasSeq_MailLastSeq  ){

    const startingFormResults: FormResult[] = []
    this.getComponentsHttpRequestService.get_CHAT_InitialFormResults_UserChatRoomId_Count(userEmail, userChatRoomId, count)
        .subscribe((messageFormResults: FormResult[])=>{
            console.log( messageFormResults)


                let newMessageFirstSeq: number
                let newMessageLastSeq: number
                let newMessageFirstDateTime: string 
                let newMessageLastDateTime : string 
                let newMessagesLength : number
                let newIsMessagesDone: boolean 


            if (messageFormResults.length > 0 ){
                newMessageFirstSeq = messageFormResults[0].seq
                newMessageLastSeq = messageFormResults[ messageFormResults.length-1].seq
                const localDateNow = new Date(messageFormResults[0].dateAndTime)
                const localDateNow_String = this.datePipe.transform( localDateNow, "yyyy-MM-ddTHH:mm:ss")
                console.log(localDateNow_String)
                newMessageFirstDateTime = localDateNow_String
                const localDateNow1 = new Date(messageFormResults[ messageFormResults.length-1].dateAndTime)
                const localDateNow_String1 = this.datePipe.transform( localDateNow1, "yyyy-MM-ddTHH:mm:ss")
                newMessageLastDateTime = localDateNow_String1
                newMessagesLength = messageFormResults.length 
                if ( messageFormResults.length >= count ){ newIsMessagesDone = false }
                if ( messageFormResults.length < count ){ newIsMessagesDone = true }
            }

            if (messageFormResults.length === 0 ){
                newMessageFirstSeq = undefined 
                newMessageLastSeq = undefined
                newMessageFirstDateTime = undefined
                newMessageLastDateTime = undefined
                newMessagesLength = 0
                newIsMessagesDone = true
            }
            
            startingFormResults.push(...messageFormResults)
            this.formResultsList = startingFormResults

            const newFormResultStored_MessageLasSeq_MailLastSeq: FormResultStored_MessageLasSeq_MailLastSeq = { formResultsStored: startingFormResults, 
                messageFirstSeq: newMessageFirstSeq , 
                messageLastSeq: newMessageLastSeq,
                messageFirstDateTime: newMessageFirstDateTime,
                messageLastDateTime: newMessageLastDateTime,
                messagesLength: newMessagesLength , 
                ismessagesDone: newIsMessagesDone , 
                mailFirstSeq: null , 
                mailLastSeq: null , 
                mailFirstDateTime: null , 
                mailLastDateTime: null , 
                mailsLength: null , 
                isMailsDone: true
               }

console.log(newFormResultStored_MessageLasSeq_MailLastSeq)
this.firstFormResultsList_messageLastSeq_mailLastSeq_haveBeenStored.next(newFormResultStored_MessageLasSeq_MailLastSeq)

            
        }, error => {
                console.log( error , 'AN ERROR OCCURRED! THROWING ERROR')
                this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'manage formResultService error 1555'})
            })

}

get_Next_CHAT_FormResultsList_byUserChatRoomId_Count_LastSeq(userEmail: string , userChatRoomId: string , count: number , currentFormResultStored: FormResultStored_MessageLasSeq_MailLastSeq){

    this.getComponentsHttpRequestService.get_CHAT_Following_FormResults_UserChatRoomId_Count_LastSeq(userEmail, userChatRoomId, count, currentFormResultStored.messageLastSeq )
        .subscribe((messageFormResults: FormResult[])=>{
        console.log(messageFormResults , 'ADDING MESSAGE FORM RESULTS TO STORAGE')


this.formResultsList.push(...messageFormResults) 


let newMessageFirstSeq: number
let newMessageLastSeq: number
let newMessageFirstDateTime: string 
let newMessageLastDateTime : string 
let newMessagesLength : number
let newIsMessagesDone: boolean 


if (messageFormResults.length > 0 ){
newMessageFirstSeq = messageFormResults[0].seq
newMessageLastSeq = messageFormResults[messageFormResults.length-1].seq
const localDateNow = new Date(messageFormResults[0].dateAndTime)
const localDateNow_String = this.datePipe.transform( localDateNow, "yyyy-MM-ddTHH:mm:ss")
console.log(localDateNow_String)
newMessageFirstDateTime = localDateNow_String
const localDateNow1 = new Date(messageFormResults[ messageFormResults.length-1].dateAndTime)
const localDateNow_String1 = this.datePipe.transform( localDateNow1, "yyyy-MM-ddTHH:mm:ss")
newMessageLastDateTime = localDateNow_String1
newMessagesLength = messageFormResults.length 
if ( messageFormResults.length >= count ){ newIsMessagesDone = false }
if ( messageFormResults.length < count ){ newIsMessagesDone = true }
}

if (messageFormResults.length === 0 ){
newMessageFirstSeq = undefined 
newMessageLastSeq = undefined
newMessageFirstDateTime = undefined
newMessageLastDateTime = undefined
newMessagesLength = 0
newIsMessagesDone = true
}

const newFormResultStored_MessageLasSeq_MailLastSeq: FormResultStored_MessageLasSeq_MailLastSeq = { formResultsStored: this.formResultsList, 
                   messageFirstSeq: newMessageFirstSeq , 
                   messageLastSeq: newMessageLastSeq,
                   messageFirstDateTime: newMessageFirstDateTime,
                   messageLastDateTime: newMessageLastDateTime,
                   messagesLength: newMessagesLength , 
                   ismessagesDone: newIsMessagesDone , 
                   mailFirstSeq: null , 
                   mailLastSeq: null , 
                   mailFirstDateTime: null , 
                   mailLastDateTime: null , 
                   mailsLength: null , 
                   isMailsDone: true
}

console.log(newFormResultStored_MessageLasSeq_MailLastSeq)
console.log('SEND SUBJECT TO ADD FORMRESULTS AT FORM RESULTS INDEX, is NOT comingFormFormList')

this.successive_FormResultsList_messageLastSeq_mailLastSeq_haveBeenStored.next(newFormResultStored_MessageLasSeq_MailLastSeq)


}, error => {
console.log( error , 'AN ERROR OCCURRED! THROWING ERROR')
this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'manage formResultService error 1625'})

})

}

    /**
     * GET MAIL ON SECOND CALL WITH SUBJECT AND LAST SEQ
     */
   
    get_Mail_FormResultsList_ByUser_InitialDrappDate_ToLocalDateNow_Count_SubjectParagraph_LastSeq( 
                                                                                         isFirstOf2Calls: boolean ,
                                                                                         type: string , 
                                                                                         user: string , 
                                                                                         dateFrom: string, 
                                                                                         dateTo: string , 
                                                                                         count: number , 
                                                                                         subjParag_type: string, 
                                                                                         subject_Paragraph_Title: string , 
                                                                                         currentFormResultStored: FormResultStored_MessageLasSeq_MailLastSeq ){
    console.log('we are getting emails list with subjects , is first call out of 2 =>', isFirstOf2Calls, currentFormResultStored )
    
    
    this.getComponentsHttpRequestService.getMailFormResultsWith_User_SubjectParagraph_DateFrom_DateTo_Count_LastSeq(user,subjParag_type, subject_Paragraph_Title, dateFrom, dateTo, count, currentFormResultStored.mailLastSeq )
                                        .subscribe((newMailFormResults: FormResult[])=>{
                                         console.log(newMailFormResults)


       this.formResultsList.push(...newMailFormResults)

           let newMailFirstSeq: number
           let newMailLastSeq: number 
           let newMailFirstDateTime : string 
           let newMailLastDateTime : string 
           let newMailsLength : number 
           let newIsMailsDone: boolean

        if ( newMailFormResults.length > 0 ){
             newMailFirstSeq = newMailFormResults[0].seq
             newMailLastSeq = newMailFormResults[newMailFormResults.length-1].seq
             const localDateNow2 = new Date(newMailFormResults[0].dateAndTime)
             const localDateNow_String2 = this.datePipe.transform( localDateNow2, "yyyy-MM-ddTHH:mm:ss")
             newMailFirstDateTime = localDateNow_String2
             const localDateNow3 = new Date(newMailFormResults[newMailFormResults.length-1].dateAndTime)
             const localDateNow_String3 = this.datePipe.transform( localDateNow3, "yyyy-MM-ddTHH:mm:ss")
             newMailLastDateTime = localDateNow_String3
             newMailsLength = newMailFormResults.length 
             if ( newMailFormResults.length >= count ){ newIsMailsDone = false }
             if ( newMailFormResults.length < count ){ newIsMailsDone = true }

        }
        if ( newMailFormResults.length === 0 ){
              console.log( " THERE WERE NO MORE MAIL AFTER THAT LAST ONE ")
              newMailFirstSeq = undefined 
              newMailLastSeq = undefined 
              newMailFirstDateTime = undefined 
              newMailLastDateTime = undefined 
              newMailsLength = 0 
              newIsMailsDone = true
        }


        const newFormResultStored_MessageLasSeq_MailLastSeq: FormResultStored_MessageLasSeq_MailLastSeq = { formResultsStored: this.formResults, 
                                                                                                            messageFirstSeq: currentFormResultStored.messageFirstSeq , 
                                                                                                            messageLastSeq: currentFormResultStored.messageLastSeq ,
                                                                                                            messageFirstDateTime: currentFormResultStored.messageFirstDateTime,
                                                                                                            messageLastDateTime: currentFormResultStored.messageLastDateTime,
                                                                                                            messagesLength: currentFormResultStored.messagesLength , 
                                                                                                            ismessagesDone: currentFormResultStored.ismessagesDone , 
                                                                                                            mailFirstSeq: newMailFirstSeq , 
                                                                                                            mailLastSeq: newMailLastSeq , 
                                                                                                            mailFirstDateTime: newMailFirstDateTime , 
                                                                                                            mailLastDateTime: newMailLastDateTime , 
                                                                                                            mailsLength: newMailsLength , 
                                                                                                            isMailsDone: newIsMailsDone
                                                                                                           }

        console.log(newFormResultStored_MessageLasSeq_MailLastSeq)

        if ( isFirstOf2Calls === true ){ 
            /**
             * still check if the new latest date of messages is earlier than earlier date of email 
             * is it's still earlier than that , we just show these first tranches to DOM and stop without calling messages 
             */
            if ( newMailFormResults.length === count ){
                const mailLastDate = new Date(newFormResultStored_MessageLasSeq_MailLastSeq.mailLastDateTime)
                const messageFirstdate = new Date(currentFormResultStored.messageFirstDateTime)
                console.log(" MESSAGE EMAIL LAST DATES => ", mailLastDate, messageFirstdate )
 
                if ( mailLastDate < messageFirstdate  ){
                     console.log('LAST MAIL TIME IS EARLIER THAN MAIL FIRST TIME, SO NO NEED TO CALL MESSAGE')
                    this.successive_FormResultsList_messageLastSeq_mailLastSeq_haveBeenStored.next(newFormResultStored_MessageLasSeq_MailLastSeq)
                }

                if ( messageFirstdate < mailLastDate){
                     console.log('LAST MAIL TIME IS LATER THAN MAIL FIRST TIME, NEED TO CALL MESSAGE')
                     const newIsFirstOf2Calls = false 
                     this.get_Message_FormResultsList_ByUser_InitialDrappDate_ToLocalDateNow_Count_SubjectParagraph_LastSeq(newIsFirstOf2Calls, type, user, dateFrom, dateTo, count, subjParag_type, subject_Paragraph_Title, newFormResultStored_MessageLasSeq_MailLastSeq)

                    
                }
            }
            if ( newMailFormResults.length < count ){
                console.log('THE MAIL ARE FINISHED , CHECK MESSAGES')
                     const newIsFirstOf2Calls = false 
                     this.get_Message_FormResultsList_ByUser_InitialDrappDate_ToLocalDateNow_Count_SubjectParagraph_LastSeq(newIsFirstOf2Calls, type, user, dateFrom, dateTo, count, subjParag_type, subject_Paragraph_Title, newFormResultStored_MessageLasSeq_MailLastSeq)
                   
                    
            }
        }

        if ( isFirstOf2Calls === false ){ 
                   console.log('SEND SUBJECT TO ADD FORMRESULTS AT FORM RESULTS INDEX')
                 this.successive_FormResultsList_messageLastSeq_mailLastSeq_haveBeenStored.next(newFormResultStored_MessageLasSeq_MailLastSeq)
        }

    }, error => {
        console.log( error , 'AN ERROR OCCURRED! THROWING ERROR')
        this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'manage formResultService error 1743'})
    })

    
    }



    getInitial_List_AllFormResults_ByUser_InitialDrappDate_ToLocalDateNow_Count( type: string , user: string , dateFrom: string, dateTo: string , count: number ){

        const startingFormResults: FormResult[] = []
        this.getComponentsHttpRequestService.getMessageFormResults_Type_User_DateFrom_DateTo_Count( type , user , dateFrom , dateTo , count )
            .subscribe((messageFormResults: FormResult[])=>{
                console.log( messageFormResults)

                // if ( this.isNowCalling_FormResultList === true ){

                    let newMessageFirstSeq: number
                    let newMessageLastSeq: number
                    let newMessageFirstDateTime: string 
                    let newMessageLastDateTime : string 
                    let newMessagesLength : number
                    let newIsMessagesDone: boolean 


                if (messageFormResults.length > 0 ){
                    newMessageFirstSeq = messageFormResults[0].seq
                    newMessageLastSeq = messageFormResults[ messageFormResults.length-1].seq
                    const localDateNow = new Date(messageFormResults[0].dateAndTime)
                    const localDateNow_String = this.datePipe.transform( localDateNow, "yyyy-MM-ddTHH:mm:ss")
                    console.log(localDateNow_String)
                    newMessageFirstDateTime = localDateNow_String
                    const localDateNow1 = new Date(messageFormResults[ messageFormResults.length-1].dateAndTime)
                    const localDateNow_String1 = this.datePipe.transform( localDateNow1, "yyyy-MM-ddTHH:mm:ss")
                    newMessageLastDateTime = localDateNow_String1
                    newMessagesLength = messageFormResults.length 
                    if ( messageFormResults.length >= count ){ newIsMessagesDone = false }
                    if ( messageFormResults.length < count ){ newIsMessagesDone = true }
                }

                if (messageFormResults.length === 0 ){
                    newMessageFirstSeq = undefined 
                    newMessageLastSeq = undefined
                    newMessageFirstDateTime = undefined
                    newMessageLastDateTime = undefined
                    newMessagesLength = 0
                    newIsMessagesDone = true
                }
                
                

                startingFormResults.push(...messageFormResults)

                // always get the lastSeq of the latest message/email so that we know which one to call next call 
                // but always show only 20 results at the time max with the closest date so we are never wrong on calling 
                // and mixing 
                // if the results.length < count  = advise or make this operation on the component so next will call 
                // only the other one  

                this.getComponentsHttpRequestService.getMailFormResultsWith_User_DateFrom_DateTo_Count( user , dateFrom , dateTo , count )
                    .subscribe((newMailFormResults: FormResult[])=>{
                        console.log(newMailFormResults)
                        // console.log( 'last seq = ', newMailFormResults[newMailFormResults.length-1].seq )
                    // if ( this.isNowCalling_FormResultList === true ){
                        startingFormResults.push(...newMailFormResults)
                        this.formResultsList = startingFormResults
                        console.log('UPDATED form results list = ',this.formResultsList)

                        this.updateInitialComponents(this.formResults)
                        /**
                         * interface FormResultList_MessageLasSeq_MailLastSeq {
                           formresultStored: FormResult[] ,
                           messageFirstSeq: number ,
                           messageLastSeq: number , 
                           mailFirstSeq: number ,
                           mailLastSeq: number 
                           }
                         */
                           let newMailFirstSeq: number
                           let newMailLastSeq: number 
                           let newMailFirstDateTime : string 
                           let newMailLastDateTime : string 
                           let newMailsLength : number 
                           let newIsMailsDone: boolean

                        if ( newMailFormResults.length > 0 ){
                             newMailFirstSeq = newMailFormResults[0].seq
                             newMailLastSeq = newMailFormResults[newMailFormResults.length-1].seq
                             const localDateNow2 = new Date(newMailFormResults[0].dateAndTime)
                             const localDateNow_String2 = this.datePipe.transform( localDateNow2, "yyyy-MM-ddTHH:mm:ss")
                             newMailFirstDateTime = localDateNow_String2
                             const localDateNow3 = new Date(newMailFormResults[newMailFormResults.length-1].dateAndTime)
                             const localDateNow_String3 = this.datePipe.transform( localDateNow3, "yyyy-MM-ddTHH:mm:ss")
                             newMailLastDateTime = localDateNow_String3
                             newMailsLength = newMailFormResults.length 
                             if ( newMailFormResults.length >= count ){ newIsMailsDone = false }
                             if ( newMailFormResults.length < count ){ newIsMailsDone = true }

                        }
                        if ( newMailFormResults.length === 0 ){
                              newMailFirstSeq = undefined 
                              newMailLastSeq = undefined 
                              newMailFirstDateTime = undefined 
                              newMailLastDateTime = undefined 
                              newMailsLength = 0 
                              newIsMailsDone = true
                        }
    

                        const newFormResultStored_MessageLasSeq_MailLastSeq: FormResultStored_MessageLasSeq_MailLastSeq = { formResultsStored: startingFormResults, 
                                                                                                                            messageFirstSeq: newMessageFirstSeq , 
                                                                                                                            messageLastSeq: newMessageLastSeq,
                                                                                                                            messageFirstDateTime: newMessageFirstDateTime,
                                                                                                                            messageLastDateTime: newMessageLastDateTime,
                                                                                                                            messagesLength: newMessagesLength , 
                                                                                                                            ismessagesDone: newIsMessagesDone , 
                                                                                                                            mailFirstSeq: newMailFirstSeq , 
                                                                                                                            mailLastSeq: newMailLastSeq , 
                                                                                                                            mailFirstDateTime: newMailFirstDateTime , 
                                                                                                                            mailLastDateTime: newMailLastDateTime , 
                                                                                                                            mailsLength: newMailsLength , 
                                                                                                                            isMailsDone: newIsMailsDone
                                                                                                                           }

                        console.log(newFormResultStored_MessageLasSeq_MailLastSeq)
                        this.firstFormResultsList_messageLastSeq_mailLastSeq_haveBeenStored.next(newFormResultStored_MessageLasSeq_MailLastSeq) 
                        
                    // } // END SECOND isNowCalling_FormResultList === true
                    }, error => {
                        console.log( error , 'AN ERROR OCCURRED! THROWING ERROR')
                        this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'manage formResultService error 1873'})
                        
                    })

                // }// END IF isNowCalling_FormResultList === true 
            }, error => {
                console.log( error , 'AN ERROR OCCURRED! THROWING ERROR')
                this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'manage formResultService error 1880'})
                
            })

        
    };

    getInitial_List_SubjectParagraph_AllFormResults_ByUser_InitialDrappDate_ToLocalDateNow_Count( type: string , user: string ,subjParag_type: string, subjParag_title: string, dateFrom: string, dateTo: string , count: number ){

        const startingFormResults: FormResult[] = []
        
        this.getComponentsHttpRequestService.getMessageFormResults_Type_User_SubjectParagraph_DateFrom_DateTo_Count( type, user ,subjParag_type, subjParag_title,  dateFrom , dateTo , count )
            .subscribe((messageFormResults: FormResult[])=>{
                console.log( messageFormResults)

                // if ( this.isNowCalling_FormResultList === true ){

                    let newMessageFirstSeq: number
                    let newMessageLastSeq: number
                    let newMessageFirstDateTime: string 
                    let newMessageLastDateTime : string 
                    let newMessagesLength : number
                    let newIsMessagesDone: boolean 


                if (messageFormResults.length > 0 ){
                    newMessageFirstSeq = messageFormResults[0].seq
                    newMessageLastSeq = messageFormResults[ messageFormResults.length-1].seq
                    const localDateNow = new Date(messageFormResults[0].dateAndTime)
                    const localDateNow_String = this.datePipe.transform( localDateNow, "yyyy-MM-ddTHH:mm:ss")
                    console.log(localDateNow_String)
                    newMessageFirstDateTime = localDateNow_String
                    const localDateNow1 = new Date(messageFormResults[ messageFormResults.length-1].dateAndTime)
                    const localDateNow_String1 = this.datePipe.transform( localDateNow1, "yyyy-MM-ddTHH:mm:ss")
                    newMessageLastDateTime = localDateNow_String1
                    newMessagesLength = messageFormResults.length 
                    if ( messageFormResults.length >= count ){ newIsMessagesDone = false }
                    if ( messageFormResults.length < count ){ newIsMessagesDone = true }
                }

                if (messageFormResults.length === 0 ){
                    newMessageFirstSeq = undefined 
                    newMessageLastSeq = undefined
                    newMessageFirstDateTime = undefined
                    newMessageLastDateTime = undefined
                    newMessagesLength = 0
                    newIsMessagesDone = true
                }
                
                

                startingFormResults.push(...messageFormResults)

                // always get the lastSeq of the latest message/email so that we know which one to call next call 
                // but always show only 20 results at the time max with the closest date so we are never wrong on calling 
                // and mixing 
                // if the results.length < count  = advise or make this operation on the component so next will call 
                // only the other one  

                this.getComponentsHttpRequestService.getMailFormResultsWith_User_SubjectParagraph_DateFrom_DateTo_Count(user, subjParag_type, subjParag_title, dateFrom, dateTo, count)
                    .subscribe((newMailFormResults: FormResult[])=>{
                        console.log(newMailFormResults)
                        // console.log( 'last seq = ', newMailFormResults[newMailFormResults.length-1].seq )
                    // if ( this.isNowCalling_FormResultList === true ){
                        startingFormResults.push(...newMailFormResults)
                        this.formResultsList = startingFormResults
                        console.log('UPDATED form results list = ',this.formResultsList)

                        this.updateInitialComponents(this.formResults)
                        /**
                         * interface FormResultList_MessageLasSeq_MailLastSeq {
                           formresultStored: FormResult[] ,
                           messageFirstSeq: number ,
                           messageLastSeq: number , 
                           mailFirstSeq: number ,
                           mailLastSeq: number 
                           }
                         */
                           let newMailFirstSeq: number
                           let newMailLastSeq: number 
                           let newMailFirstDateTime : string 
                           let newMailLastDateTime : string 
                           let newMailsLength : number 
                           let newIsMailsDone: boolean

                        if ( newMailFormResults.length > 0 ){
                             newMailFirstSeq = newMailFormResults[0].seq
                             newMailLastSeq = newMailFormResults[newMailFormResults.length-1].seq
                             const localDateNow2 = new Date(newMailFormResults[0].dateAndTime)
                             const localDateNow_String2 = this.datePipe.transform( localDateNow2, "yyyy-MM-ddTHH:mm:ss")
                             newMailFirstDateTime = localDateNow_String2
                             const localDateNow3 = new Date(newMailFormResults[newMailFormResults.length-1].dateAndTime)
                             const localDateNow_String3 = this.datePipe.transform( localDateNow3, "yyyy-MM-ddTHH:mm:ss")
                             newMailLastDateTime = localDateNow_String3
                             newMailsLength = newMailFormResults.length 
                             if ( newMailFormResults.length >= count ){ newIsMailsDone = false }
                             if ( newMailFormResults.length < count ){ newIsMailsDone = true }

                        }
                        if ( newMailFormResults.length === 0 ){
                              newMailFirstSeq = undefined 
                              newMailLastSeq = undefined 
                              newMailFirstDateTime = undefined 
                              newMailLastDateTime = undefined 
                              newMailsLength = 0 
                              newIsMailsDone = true
                        }
    

                        const newFormResultStored_MessageLasSeq_MailLastSeq: FormResultStored_MessageLasSeq_MailLastSeq = { formResultsStored: startingFormResults, 
                                                                                                                            messageFirstSeq: newMessageFirstSeq , 
                                                                                                                            messageLastSeq: newMessageLastSeq,
                                                                                                                            messageFirstDateTime: newMessageFirstDateTime,
                                                                                                                            messageLastDateTime: newMessageLastDateTime,
                                                                                                                            messagesLength: newMessagesLength , 
                                                                                                                            ismessagesDone: newIsMessagesDone , 
                                                                                                                            mailFirstSeq: newMailFirstSeq , 
                                                                                                                            mailLastSeq: newMailLastSeq , 
                                                                                                                            mailFirstDateTime: newMailFirstDateTime , 
                                                                                                                            mailLastDateTime: newMailLastDateTime , 
                                                                                                                            mailsLength: newMailsLength , 
                                                                                                                            isMailsDone: newIsMailsDone
                                                                                                                           }

                        console.log(newFormResultStored_MessageLasSeq_MailLastSeq)
                        this.firstFormResultsList_messageLastSeq_mailLastSeq_haveBeenStored.next(newFormResultStored_MessageLasSeq_MailLastSeq) 
                        
                    // } // END SECOND isNowCalling_FormResultList === true
                    }, error => {
                        console.log( error , 'AN ERROR OCCURRED! THROWING ERROR')
                        this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'manage formResultService error 2010'})
                    })

                // }// END IF isNowCalling_FormResultList === true 
            }, error => {
                console.log( error , 'AN ERROR OCCURRED! THROWING ERROR')
                this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'manage formResultService error 2016'})
                
            })

        
    };




    getFormResultsSortBy_Date() {

        const sortedFormResultsBy_latestDateFirst: FormResult[] = this.formResults
        return  sortedFormResultsBy_latestDateFirst.sort((a, b) => {
          return <any>new Date(a.dateAndTime) - <any>new Date(b.dateAndTime);
        });
    }

    /**
     * 
     * @returns formResultsLIST by date 
     */
    getFormResultsList_SortByDate(){
        const sortedFormResultsBy_latestDateFirst: FormResult[] = this.formResultsList
        return  sortedFormResultsBy_latestDateFirst.sort((a, b) => {
          return <any>new Date(a.dateAndTime) - <any>new Date(b.dateAndTime);
        });
    }

    getFormResultsList_SortBy_Date_fromLast_toFirst() {

        const sortedFormResultsBy_latestDateFirst: FormResult[] = this.formResultsList
        return  sortedFormResultsBy_latestDateFirst.sort((a, b) => {
          return <any>new Date(b.dateAndTime) - <any>new Date(a.dateAndTime);
        });
    }

    getFormResultsSortBy_Date_fromLast_toFirst() {

        const sortedFormResultsBy_latestDateFirst: FormResult[] = this.formResults
        return  sortedFormResultsBy_latestDateFirst.sort((a, b) => {
          return <any>new Date(b.dateAndTime) - <any>new Date(a.dateAndTime);
        });
      }

 
getLastWeeksDate(numberOfWeeks: number){

    const now = new Date()
    const weeksInDays:number = numberOfWeeks*7
    return new Date( now.getFullYear(), now.getMonth(), now.getDate()-weeksInDays )
  }


private updateNewUniqueSubjectList = new Subject<any>()

updateUniqueSubjectListToChatFormResult(){
    this.updateNewUniqueSubjectList.next()
}

getUpdateUniqueSubjectListToChatFormResult(){
    return this.updateNewUniqueSubjectList.asObservable()
}

/**
 * makes form results-component close the side nav
 * with onCloseSideNavDrawerSubmittedFromRoutingCall()
 */
onAdviseResultsPageformResultListIsSelected(){
    this.adviseFormResultListIsBeenSelected.next()
}

updateInitialComponents(updatedFormResults: FormResult[]){
    this.initialNullSetupService.initialNullFormResults = updatedFormResults
}

updateFormResultFilter_onFormResultListOutput( finalRoutingCall_andAllChilds: any ){
    this.checkUpdateFilterResult_fromResultList.next(finalRoutingCall_andAllChilds)
}

/**
 * opening filters sideNav from empty formResultList bottom Button 
 */
onOpenFiltersSideNav_fromEmptyFormResultList(){
    this.openFiltersSideNav_fromEmptyFormResultList.next()
}

// onScrollFormItemsParentNavToBottom(){
//     this.scrollFormItemsToBottom.next()
// }

onScrollFormesultsListParentNatToTop(){
    this.scrollFormListToTop.next()
}


ngOnDestroy(){
    this.updateInitialNullDatafromInitialNullSetupService.unsubscribe()
    this.updateInitialNullDatafromInitialNullSetupService2.unsubscribe()
}


}

