import { Injectable } from "@angular/core";
import { PostComponentsHttpRequestService } from "../post-and-put-services/post-components-http-request.service";



@Injectable ({
    providedIn: 'root' 
})

export class StoreDefaultComponentsSevice{

    constructor(  private postComponentsHttpRequestService: PostComponentsHttpRequestService
        ){}


    /**
     * 
     * @param userEmail get this userEmail from the login . 
     */
    getUserDefaultComponentsAndSetup(userEmail: string){
      return  this.postComponentsHttpRequestService.httpGetUserDefaultComponents(userEmail, null  )
    }


}