
import { Injectable } from '@angular/core';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { Subscription } from 'rxjs';
import { FeatureIndexTopicList } from './feature-index-topic-list.model';


@Injectable ({
    providedIn: 'root' 
})

export class FeatureIndexTopicListService {

    constructor ( private initialNullSetupService: InitialNullSetupService 
                  ){}
  
   
    public featureIndexTopicListItems: FeatureIndexTopicList[] = this.initialNullSetupService.initialNullFeatureIndexTopicListItems  
  

    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.featureIndexTopicListChangeBackToNull
   .subscribe((res)=> {
          this.featureIndexTopicListItems = res 
          console.log('updated featureIndexTopicsListItems back to null = ', res)

      })



    
    getFeatureIndexTopicListItems(){
        return this.featureIndexTopicListItems
    }

    getFeatureIndexTopicListItem_by_type(type: string){
        const newFeatureIndexTopicListItem = this.featureIndexTopicListItems.find(
            (res)=> {
                return res.type === type
            }
        )
        return newFeatureIndexTopicListItem
    }
}