import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable} from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExchangeRateService {
  private baseUrl = 'https://api.exchangeratesapi.io/latest';

  private readonly apiUrl = 'https://data.fixer.io/api/latest';
  private readonly accessKey = 'fyhoTbr0DoLnmjqA2wgsVUtLG00u0jRI';

  constructor(private http: HttpClient) { }

  getExchangeRate(baseCurrency: string, targetCurrency: string): Observable<number> {
    const url = `${this.baseUrl}?base=${baseCurrency}&symbols=${targetCurrency}`;
    return this.http.get<{ rates: { [key: string]: number } }>(url).pipe(
      map(response => response.rates[targetCurrency])
    );
  }

  getExchangeRates(): Observable<any> {
    const url = `${this.apiUrl}?access_key=${this.accessKey}`;
    return this.http.get(url);
  }
}