<div class="row">

<form [formGroup]="rulesForm" >
	<mat-form-field  class="chip-list" >

		<mat-chip-list #chipList formArrayName="dateRanges" >
      <div *ngFor="let item of dateRanges; let i=index" >

         <mat-chip *ngIf="item.color == 'range2'" 
         style="background-color:   rgb(32, 198, 32);; color: white ;"
         selected 
         [selectable]="true" 
         [removable]="removable"
           (removed)="removeDateRange(item , i)" 
         required name="chips">
         {{item.start | date: 'MM/dd' }} - {{item.end | date: 'MM/dd'}}
                   <mat-icon  
                  matChipRemove
                  style="color: white;"
                  *ngIf="removable">
                  cancel
               </mat-icon>
          </mat-chip>

      <mat-chip *ngIf="item.color == 'range3'"
      style="background-color: orange; color: white ;"
      selected 
      [selectable]="true" 
      [removable]="removable"
      (removed)="removeDateRange(item , i)" 
      required name="chips">
      {{item.start | date: 'MM/dd' }} - {{item.end | date: 'MM/dd'}}
            <mat-icon  
               matChipRemove
               style="color: white;"
               *ngIf="removable">
               cancel
            </mat-icon>
      </mat-chip>

      <mat-chip *ngIf="item.color == 'range5'"
      style="background-color:  rgb(102, 161, 232); color: white ;"
      selected 
      [selectable]="true" 
      [removable]="removable"
      (removed)="removeDateRange(item , i)" 
      required name="chips">
      {{item.start | date: 'MM/dd' }} - {{item.end | date: 'MM/dd'}}
            <mat-icon  
               matChipRemove
               style="color: white;"
               *ngIf="removable">
               cancel
            </mat-icon>
      </mat-chip>

      <mat-chip *ngIf="item.color == 'range6'"
      style="background-color: red; color: white ;"
      selected 
      [selectable]="true" 
      [removable]="removable"
      (removed)="confirmRemoveDateRange(i)" 
      required name="chips">
      confirm delete {{item.start | date: 'MM/dd' }} - {{item.end | date: 'MM/dd'}}
            <mat-icon  
               matChipRemove
               style="color: white;"
               *ngIf="removable">
               cancel
            </mat-icon>
    </mat-chip>

   
    </div>

		</mat-chip-list>
  

	</mat-form-field>
  
</form>

</div>

<br>

<div class="row">
   <button class="btn btn-primary"
           (click)="onConfirmDeleteRangeAndCloseDialog()">
           ok
   </button>
</div>


