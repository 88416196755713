<div class="row">
  <div class="col-xs-12">
    <div class="row">
      <div class="col-2">
    
       <svg style="color: #3f51b5;" 
            xmlns="http://www.w3.org/2000/svg" 
            width="22" 
            height="22" 
            fill="currentColor" 
            class="bi bi-calendar3" 
            viewBox="0 0 16 16">
        <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"/>
        <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
      </svg>

      </div>
      <div class="col-10">

            <mat-chip-list #chipList>
              <div *ngFor="let chip of availableDates; let i=index" >

                <div *ngIf="fixIsDeselected">
                  <mat-chip style="background-color: #3f51b5 ; color: white ; width:150px "
                            type="button"
                            *ngIf="!rangeIsDeselected && i==0"
                            (click)="onDeselectRange(i)" > 
                            {{chip}} 
                  </mat-chip>
                  <mat-chip style="background-color: whitesmoke ; color:grey ; width:150px "
                            type="button"
                            *ngIf="rangeIsDeselected && i==0"
                            (click)="onSelectRange(i)"
                            (click)="picker.open()" > 
                            {{chip}} 
                  </mat-chip>
                  </div>

                <div *ngIf="rangeIsDeselected">
                <mat-chip style="background-color: #3f51b5 ; color: white ; width:150px "
                          type="button"
                          *ngIf="!fixIsDeselected && i==1"
                          (click)="onDeselectFix(i)" > 
                          {{chip}} 
                </mat-chip>
                <mat-chip style="background-color: whitesmoke ; color:grey ; width:150px "
                          type="button"
                          *ngIf="fixIsDeselected && i==1"
                          (click)="onSelectFix(i)" > 
                          {{chip}} 
                </mat-chip>
                </div>
              
                <div>
                  <mat-chip style="background-color: #3f51b5 ; color: white ; width:150px "
                            *ngIf="rangeIsDeselected && fixIsDeselected && i==2"
                             > 
                            {{chip}} 
                  </mat-chip>
                </div>
              </div>
            </mat-chip-list>
         
      </div>
    </div>
  </div>
</div>

<br>

<mat-form-field [hidden]="rangeIsDeselected" >
  <mat-date-range-picker #picker></mat-date-range-picker>
  <mat-label *ngIf="!isSubmitted">INPUT DATE RANGE</mat-label>
  <mat-label *ngIf="isSubmitted" style="color:grey">FROM DATE  -  TO DATE</mat-label>
  <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
    <!-- formControlName="start"  -->
    <input matStartDate 
           formControlName="start"
           id="start"
           placeholder="ex: 01/18/2023"
           (dateInput)="changeDate('start', $event)" 
           >
    <!-- formControlName="end" -->
    <input matEndDate 
           formControlName="end"
           id="end"
           placeholder="03/26/2023" 
           [value]
           (dateInput)="changeDate('end', $event)" 
           >
  </mat-date-range-input>
  <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>

  <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid') || range.controls.end.hasError('matEndDateInvalid')">Invalid dates</mat-error>
  <!-- <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error> -->
</mat-form-field>



