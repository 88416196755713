<div *ngIf="isRestarting">
  <div [hidden]="!user" class="ParagraphFilterFrame" style="height: 90px"></div>
  <div class="ParagraphFilterFrame" style="height: 50px"></div>
  <div class="ParagraphFilterFrame" style="height: 100px"></div>
  <div class="ParagraphFilterFrame" style="height: 130px"></div>
</div>

<div *ngIf="!isRestarting">

<div [hidden]="!user" class="ParagraphFilterFrame">
    <app-filter-group
    [isSubmitted]="isSubmitted"
    [groupIsDeselected]="groupIsDeselected"
    (onGroupIsDeselected)="onGroupIsDeselected($event)"
    (selectedAvailableChoice)="onUpdateSelectedGroupName($event)"
    >
    </app-filter-group>
</div>

<div [hidden]="!user" class="ParagraphFilterFrame">
  <app-filter-from-to-copies 
  [isSubmitted]="isSubmitted"
  [receivedMessageIsDeselected]="receivedMessageIsDeselected"
  [sentMessageIsDeselected]="sentMessageIsDeselected"
  (onReceivedMessageIsDeselected)="onReceivedMessageIsDeselected($event)"
  (onSentMessageIsDeselected)="onSentMessageIsDeselected($event)">
  </app-filter-from-to-copies>
</div>

<div class="ParagraphFilterFrame">
  <app-filter-mail-sent-to
  [isSubmitted]="isSubmitted"
  [sentMailIsDeselected]="sentMailIsDeselected"
  (onSentMailIsDeselected)="onSentMailIsDeselected($event)">
  </app-filter-mail-sent-to>
</div>

<!-- <div class="ParagraphFilterFrame">
  <app-filter-group
  [isSubmitted]="isSubmitted"
  [groupIsDeselected]="groupIsDeselected"
  (onGroupIsDeselected)="onGroupIsDeselected($event)"
  (selectedAvailableChoice)="onUpdateSelectedGroupName($event)"
  >
  </app-filter-group>
</div> -->

<div class="ParagraphFilterFrame">
  <div *ngIf="!filterSubjectParagraphIsVisible">
    <br>
    <br>
    <br>
    <br>
    <br>
  </div>
  <app-filter-subjets-paragraphs *ngIf="filterSubjectParagraphIsVisible"
  [isSubmitted]="isSubmitted"
  [subjectIsDeselected]="subjectIsDeselected"
  [paragraphIsDeselected]="paragraphIsDeselected"
  (onSubjectIsDeselected)="onSubjectIsDeselected($event)"
  (onParagraphIsDeselected)="onParagraphIsDeselected($event)"
  (emitSubjectTextResult)="onUpdateSubjectParagraphTextResult($event)"
  [updatedSubject]="updatedSubject"
  [updateIsSubmitStatus]="updateIsSubmitToTrueToChildsWithInputs.asObservable()">
  </app-filter-subjets-paragraphs>
</div>

<div class="ParagraphFilterFrame">
  <div *ngIf="!filterDateIsVisible">
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
  </div>
  <app-filter-dates *ngIf="filterDateIsVisible"
  [isSubmitted]="isSubmitted"
  [fixIsDeselected]="fixIsDeselected"
  [rangeIsDeselected]="rangeIsDeselected"
  [resetFixDateFilter]="resetFixDateFilter_Event.asObservable()"
  (onFixIsDeselected)="onFixIsDeselected($event)"
  (onRangeIsDeselected)="onRangeIsDeselected($event)"
  (onEmitDateType_dateInfo)="onUpdateDateType_dateInfo($event)"
  [updatedDate]="updatedDate"
  [updateIsSubmitStatus]="updateIsSubmitToTrueToChildsWithInputs.asObservable()">
  </app-filter-dates>
</div>

</div>


  <!----------------------------- SUBMIT AND CLEAR BUTTONS ----------------------------------------------------- -->
  <div class="ParagraphFilterFrame pointer"
       *ngIf="!isSubmitted && !hasAtLeastOneSearchParameterSelected" 
       style="background-color: white ; color: #3f51b5 ;"
       >
       SUBMIT
  </div>

  <div class="ParagraphFilterFrame pointer" 
     *ngIf="!isSubmitted && hasAtLeastOneSearchParameterSelected" 
     style="background-color: #3f51b5 ;color: white"
     (click)="onSubmit()">
     SUBMIT
  </div>

<div class="ParagraphFilterFrame pointer"
     *ngIf="isSubmitted"  
     style="background-color: orangered; color: white "    
     (click)="onClear()"
    >
    <!--  routerLink="../results"
     routerLinkActive="activebutton" -->
     CLEAR
</div>

<!-- <div class="row" style="height: 150px;"></div> -->

<!-- <div class="col-12">
  <div class="row">
    <div class="col-1" *ngIf="!(isHandset$ | async)" ></div>
    <div class="col-2" *ngIf="isHandset$ | async"></div>

    <div class="col-8">
      <app-phone-rapresentation [messagePhone]="messagePhone"></app-phone-rapresentation>
    </div>
    <div class="col-2"></div>
  </div>
</div> -->

<!-- <div class="row" style="height: 30px;"></div> -->






  <!-- <mat-form-field  appearance="fill" >
    <mat-label>date range</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
      <input matStartDate formControlName="start" placeholder="Start">
      <input matEndDate formControlName="end" placeholder="End">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  
    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
  </mat-form-field> -->




   