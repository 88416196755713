

 <div *ngIf="!isClicked" style="color: #3f51b5; background-color: azure;"> 
    {{name}}
  </div>

  <div *ngIf="isClicked" style="color: #3f51b5; background-color: azure;">
    <h2>{{name}}</h2> 
  </div>




