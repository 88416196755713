import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Paragraph } from 'src/app/forms/form-list/form-item/paragraph/paragraph.model';
import { clone } from 'lodash'
import { FeatureContainer } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-container.model';
import { FeatureContainerListItem } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-container-list-item.model';
import { FeatureTopic } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-topic.model';
import { FeatureIndexTopicList } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-index-topic-list.model';
import { ParagraphFeatureContainer } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature-container.model';
import { ParagraphTopic } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.model';
import { ParagraphTopicIndex } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-topic-index.model';
import { GetFeatureByTypeAndIdService } from 'src/app/services/shared-services/get-feature-by-type-and-id.service';
import { GetComponentsHttpRequestService } from 'src/app/services/gets-services/get-components-http-request.sevice';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { Subject, Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FeatureContainerService } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container/feature-container.service';
import { FeatureContainerListItemService } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container-list-item/feature-container-list-items.service';
import { FeatureTopicService } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-topic.service';
import { FeatureIndexTopicListService } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-index-topic-list.service';
import { WordInput } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/word/word-input.model';
import { FixText } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/texts/fix-text-list/fix-text.model';
import { SharedCalendarStatus } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/shared-calendar-status-list/shared-calendar-status.model';
import { WordInputService } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/word/word-input.service';
import { CreateNewFeatureContainerAndAddToFeatureContainerListItemService } from 'src/app/services/shared-services/create-new-feature-container-and-update-feature-container-list-item.service';


interface InputLabelInterface {

  exist: boolean ,
  name: string,
  maxLength: number,
  errorHandler: boolean 
}

@Component({
  selector: 'app-duplicate-feature-paragraph-form',
  templateUrl: './duplicate-feature-paragraph-form.component.html',
  styleUrls: ['./duplicate-feature-paragraph-form.component.css']
})

export class DuplicateFeatureParagraphFormComponent implements OnInit {

  user: string 
  updateOnUserEmail: Subscription

   featureContainer: FeatureContainer
   featureContainerListItem: FeatureContainerListItem
   featureTopic: FeatureTopic
   featureIndexTopicList: FeatureIndexTopicList

   paragraphFeatureContainer: ParagraphFeatureContainer
   paragraphTopic: ParagraphTopic
   paragraphTopicIndex: ParagraphTopicIndex

   oldParagraph: Paragraph 
   newParagraph: Paragraph

   editFormName: FormGroup

   inputLabelInterface: InputLabelInterface
   inputName: string = ''

  //  inputSharedCalendarStatusItemNameInterface: InputLabelInterface
  //  emptyInputNameAfterAdded = new Subject<void>()

   isWritingNewname: boolean

   updatingFeature: FixText | WordInput | SharedCalendarStatus 

   newTabIndex0: number 

  constructor( private dialogRef: MatDialogRef<DuplicateFeatureParagraphFormComponent> ,
               private getFeatureByTypeAndIdService: GetFeatureByTypeAndIdService,
               private getComponentsHttpRequestService: GetComponentsHttpRequestService,
               private userObjectUpdateService: UserObjectUpdateService,
               private featureContainerService: FeatureContainerService,
               private featureContainerListItemService: FeatureContainerListItemService , 
               private featureTopicService: FeatureTopicService, 
               private featureIndexTopicListService: FeatureIndexTopicListService, 
               private wordInputService: WordInputService,
               private createNewFeatureContainerAndAddToFeatureContainerListItemService: CreateNewFeatureContainerAndAddToFeatureContainerListItemService,
               @Inject(MAT_DIALOG_DATA) public editdata: any
               ){}

    

  ngOnInit(): void {

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
    .subscribe((updatedUserEmail)=>{
     this.user = updatedUserEmail
     console.log('updated USER = ', this.user)
    });

    this.isWritingNewname = false 

    this.inputLabelInterface = { exist: true, name: "input paragraph's new name", maxLength: 30, errorHandler: true }

    this.editFormName = new FormGroup({
      name : new FormControl ( this.inputName , [ Validators.required, Validators.maxLength(this.inputLabelInterface.maxLength) ] )
    })

    console.log( this.editdata)

    this.oldParagraph = this.editdata.paragraph
    this.newParagraph = clone(this.editdata.paragraph)

    // this.inputLabelInterface = { exist: true, name: 'component name', maxLength: 30, errorHandler: true }

    this.newParagraph.name = "new name"
    this.newParagraph._id = null 
    this.newParagraph.UIID = null 

    console.log("new duplicate object => ", this.newParagraph)

  
  }


  // in order to duplicate we need to 
  // 1- create the same base component + index parents 
  // 2- create the same base + paragraph + index parents 

  // in order to place the base and paragraph in the same topic , find also the index parent for both


  onSubmitName(){
    console.log(this.editFormName.value.name )

    const newName: string = this.editFormName.value.name
    this.onStartDuplicateParagraph(newName)
    
  }

  onStartDuplicateParagraph( newParagraphName: string ){

    this.isWritingNewname = true 

    // before duplicate we need to make sure which one of the feature is the last UIID so we can make the 
    // next UIID to both the feature and the paragraph 

    // get latest feature UIID 
    // add the next UIID to the new feature and paragraph 


    const newType = this.newParagraph.features[0].featureName
    const new_id =  this.newParagraph.features[0].feature_id

    // get clone of the feature in use in this paragraph
    const selectedFeature: any = this.getFeatureItem( newType, new_id)

    selectedFeature.name = newParagraphName 
    console.log('new selected Feature with new name => ', selectedFeature)

    if (this.user !== null ){
    this.getComponentsHttpRequestService.httpGetComponentsByType( this.user , newType )
        .subscribe((res) => {
          console.log(res)
          // check latest feature UIID number and add 
        })
    }

    if (this.user === null ){
      // no ned to call http to check latest 
      // get all the local components and start creating the new ones 
      // const selectedFeature = this.getFeatureItem( newType, new_id )
      // console.log("adjusted selected feature => ", selectedFeature )

      this.create_NullUser_ComponentPlusFeature( selectedFeature, newType )



    }

  }

  getFeatureItem( type: string, _id: string ){
      return this.getFeatureByTypeAndIdService.getFeature(type, _id)
  }


  // create_NullUser_ComponentPlusFeature( newNullFeature: WordInput | FixText | SharedCalendarStatus , type: string ){ 
    create_NullUser_ComponentPlusFeature( newNullFeature: any , type: string  ){ 

    // get feature and select type of feature 
    // modify and save feature 
    // create new featureContainers + parents 

    console.log('type => ', type , 'component => ', newNullFeature)

    const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type(type)
    this.newTabIndex0 = personalFeatureContainerListItem.featureContainer_ids.length+1

    console.log('personalFeatureContainerListItem => ', personalFeatureContainerListItem, 'newTabIndex0 => ', this.newTabIndex0 )
    
    const new_id = 'wordIn-' + this.newTabIndex0.toString()
    const newComponentName = 'wordInput'
    const newUIID = 'wordInput-'+ (this.newTabIndex0).toString()
    const newOwner = this.user; 
    const newAllUsersAllowed = false 
    const newUserListAllowed = []
    const newDateAndTime = null; 
    const newName =  'new tab '+ this.newTabIndex0 ;


    // const newLabel = 'edit label-'+this.newTabIndex0.toString()
    // const newPlaceholder = 'edit placeholder-'+this.newTabIndex0.toString()
    // const newHint = 'edit hint-'+this.newTabIndex0.toString()
    // const newMaxLength = 25
    // const newDetail = new WordInputDetail (newLabel, newPlaceholder, newHint, newMaxLength )

    // const newDetails =  [newDetail]
    // const newWordInput = new WordInput ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed, newDateAndTime, newName , newDetails);

    //   this.wordInputService.onAddNew(newWordInput)
  
    //   const newFeatureOwner = this.user; 
    //   const newIsNew = false;
    //   const newDropId = this.currentI ;
    //   const newFeature_id = newWordInput._id
    //   const newFeatureName = 'wordInput' ;
    //   const newFeature = new Feature ( newFeatureOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
  
    //   this.feature = newFeature
    
    //   this.createNewFeatureContainerAndAddToFeatureContainerListItemService.createFeatureContainer_toAddOnFeatureContainerListItem(newFeature, this.user )
  
   
  }



  
  






}

/**
 * {
"1":[
{
"componentName":"sharedCalendarStatus",
"UIID":"sharedCalendarStatus-1",
"owner":"",
"allUsersAllowed":false,
"userListAllowed":[],
"dateAndTime":null,
"name":"1st sharecCalendarStatus",
"rangeDatesInfoSetting":{
"dateStartEnd":{
"dateStart":"",
"dateEnd":""
},
"rangeInfos":[
{
 "type":"innerdropdown",
 "name":"set dropdown title",
 "usersAccess":{
 "canView":{
 "names":[],
 "description":"",
 "onlyOwnerCan":false
 },
 "canEdit":{
 "names":[],
 "description":"",
 "onlyOwnerCan":false
 },
 "canUse":{
 "names":[],
 "description":"",
 "onlyOwnerCan":false
 }
 },
 "rangeDetails":[
 {
 "name":"edit dropdown",
 "selected":false
 }
 ]
 },
 {
 "type":"innercheckbox",
 "name":"set checkbox title",
 "usersAccess":{
 "canView":{
 "names":[],
 "description":"",
 "onlyOwnerCan":false
 },
 "canEdit":{
 "names":[],
 "description":"",
 "onlyOwnerCan":false
 },
 "canUse":{
 "names":[],
 "description":"",
 "onlyOwnerCan":false
 }
 },
 "rangeDetails":[
 {
 "name":"edit checkbox",
 "selected":false
 }
 ]
 },
 {
 "type":"innertext",
 "name":"set text title",
 "usersAccess":{
 "canView":{
 "names":[],
 "description":"",
 "onlyOwnerCan":false
 },
 "canEdit":{
 "names":[],
 "description":"",
 "onlyOwnerCan":false
 },
 "canUse":{
 "names":[],
 "description":"",
 "onlyOwnerCan":false
 }
 },
 "rangeDetails":[
 {
 "text":"edit text"
 }
 ]
 },
 {
 "type":"innerinput",
 "name":"set input title",
 "usersAccess":{
 "canView":{
 "names":[],
 "description":"",
 "onlyOwnerCan":false
 },
 "canEdit":{
 "names":[],
 "description":"",
 "onlyOwnerCan":false
 },
 "canUse":{
 "names":[],
 "description":"",
 "onlyOwnerCan":false
 }
 },
 "rangeDetails":[
 {
 "text":"rename this label"
 },
 {
 "text":"500"
 },
 {
 "text":"write an example"
 },
 {
 "text":""
 }
 ]
 }
 ],
 "usersAccess":{
 "canView":{
 "names":[],
 "description":"can view the data on each date range",
 "onlyOwnerCan":false
 },
 "canEdit":{
 "names":[],
 "description":"",
 "onlyOwnerCan":true
 },
 "canUse":{
 "names":[],
 "description":"can add/delete date ranges & inner rangeInfo",
 "onlyOwnerCan":false
 }
 }
 },
 "rangeDatesInfos":[]
 }
 ],
 "2":[
 {
 "componentName":"featureContainer",
 "UIID":"featureContainer-15",
 "owner":"",
 "allUsersAllowed":false,
 "userListAllowed":[],
 "dateAndTime":null,
 "isActive":true,
 "isAccepted":true,
 "name":"sharedCalendarStatus",
 "componentReference_id":"@CHILD_ID@",
 "features":[
 {
 "owner":"@OWNER@",
 "isNew":false,
 "dropId":0,
 "feature_id":"@CHILD_ID@",
 "featureName":"sharedCalendarStatus"
 }
 ]
 }
 ],
 "3":[
 {
 "componentName":"featureContainerListItem",
 "UIID":"featureContainerListItem-15",
 "owner":"",
 "allUsersAllowed":false,
 "userListAllowed":[],
 "dateAndTime":null,
 "type":"personal",
 "name":"sharedCalendarStatus",
 "featureContainer_ids":["@CHILD_ID@"],
 "selectedFeatureContainer_id":"@CHILD_ID@"
 }
 ],
 "4":[
 {
 "componentName":"featureTopic",
 "UIID":"featureTopic-12",
 "owner":"",
 "allUsersAllowed":false,
 "userListAllowed":[],
 "dateAndTime":null,
 "type":"personal",
 "isStandardFeature":true,
 "isTab":false,
 "name":"calendar",
 "featureContainerListItems_ids":["@CHILD_ID@"]
 }
 ],
 "5":[
 {
 "componentName":"featureIndexTopicList",
 "UIID":"featureIndexTopicList-1",
 "owner":"",
 "allUsersAllowed":false,
 "userListAllowed":[],
 "dateAndTime":null,
 "type":"personal",
 "featureTopic_ids":["@CHILD_ID@"]
 }
 ]
 }
 */

/**
 * "2":[
 {
 "componentName":"paragraph",
"UIID":"paragraph-1",
"owner":"",
"dateAndTime":null,
"updateDateAndTime":null,
"isRemoved":false,
"allUsersAllowed":false,
"userListAllowed":[],
"canBeResharedByOthers":false,
"name":"villa",
"desc":"",
"features":[
{
"owner":"@OWNER@",
"isNew":false,
"dropId":0,
"feature_id":"@FEATURES_CHILD_ID@",
"featureName":"sharedCalendarStatus"
}
],
"feature_id_list":["@CHILD_ID@"]
}
],
"3":[
{
"componentName":"paragraphFeatureContainer",
"UIID":"paragraphFeatureContainer-1",
"owner":"",
"allUsersAllowed":false,
"userListAllowed":[],
"dateAndTime":null,
"isActive":true,
"isAccepted":true,
"name":"personal",
"paragraphFeatureState":true,
"paragraph_id":"@CHILD_ID@"
}
],
"4":[
{
"componentName":"paragraphTopic",
"UIID":"paragraphTopic-1",
"owner":"",
"allUsersAllowed":false,
"userListAllowed":[],
"dateAndTime": null,
"isAccepted":true,
"isArchived":false,
"name":"villa",
"paragraphFeatureContainer_ids":["@CHILD_ID@"]
}
],
"5":[
{
"componentName":"paragraphTopicIndex",
"UIID":"paragraphTopicIndex-1",
"owner":"",
"allUsersAllowed":false,
"userListAllowed":[],
"dateAndTime":null,
"type":"personal-paragraphs",
"paragraphTopic_ids":["@CHILD_ID@"]
}
]
 */

