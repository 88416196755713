<!-- <p>fix-text-result works!</p> -->
<br>
<table>
    <div *ngFor="let detail of fixTextDetailResults; let i=index">
        <tr>
            {{detail.text}}
          </tr>
          <br>
    </div>
</table>


