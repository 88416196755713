import { Component, OnInit } from '@angular/core';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';

@Component({
  selector: 'app-chat-comment-features',
  templateUrl: './chat-comment-features.component.html',
  styleUrls: ['./chat-comment-features.component.css']
})
export class ChatCommentFeaturesComponent implements OnInit {

  user: string 

  constructor(  private userObjectUpdateService: UserObjectUpdateService ) { }

  ngOnInit(): void {
    this.user = this.userObjectUpdateService.userEmail
  }

}
