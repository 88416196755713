import { Component, Input , OnInit } from '@angular/core';
import { FeatureResult } from "./feature-result.model";
import { Subscription, Observable } from 'rxjs';

@Component({
  selector: 'app-feature-results',
  templateUrl: './feature-results.component.html',
  styleUrls: ['./feature-results.component.css']
})
export class FeatureResultsComponent implements OnInit {

  @Input() featureResult: FeatureResult

  // updatedFormResultsSubscription: Subscription ;
  // @Input() subscribeUpdatedFormResults: Observable<void>;

  constructor() { }

  ngOnInit(){

    this.featureResult

    //this.updatedFormResultsSubscription = this.subscribeUpdatedFormResults.subscribe(()=> this.onSubscribeUpdatedFeatureResults())
  }

  // onSubscribeUpdatedFeatureResults(){
  //   console.log('subescription has arrived to feature results')
    
  // }

  // ngOnDestroy():void{
  //   this.updatedFormResultsSubscription.unsubscribe()
  // }

}
