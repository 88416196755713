<div *ngIf="isLoading">
    <div class="ParagraphComponentFrame">
        <div style="color: #3f51b5; background-color: rgb(243, 250, 253); border-radius: 6px;" 
             class="col-xs-12">
             <div style="height: 120px"></div>
        </div>

        <div style="height: 400px;">
        </div>
    </div>
    <div class="ParagraphComponentFrame">
        <div style="color: #3f51b5; background-color: rgb(243, 250, 253); border-radius: 6px;" 
             class="col-xs-12">
             <div style="height: 120px"></div>
        </div>

        <div style="height: 400px;">
        </div>
    </div>
</div>

<div *ngIf="!isLoading">
<div *ngFor="let formResult of formResults; let i=index">
    <app-form-result-item trackVisibility 
                    (visibile)="onComponentVisible($event , i)"
                    [formResultComingFromFormResultList]="youAreInFormResultsList"
                    [formResult]="formResult">
    </app-form-result-item>
</div>

<div #latestComponent></div>


<div *ngIf="(isHandset$ | async) === false">
<div class="row"
     *ngIf="formResults.length > 0">
    <div class="col-xs-12" (click)="onClearFormResultList()">
      <div style="background-color:orange " class="ParagraphComponentButton pointer"  >
        <h2 align="center" style="color: white"> clear selection </h2>
      </div>
    </div>
</div>
</div>

</div>

