import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendar-expire-date',
  templateUrl: './calendar-expire-date.component.html',
  styleUrls: ['./calendar-expire-date.component.css']
})
export class CalendarExpireDateComponent implements OnInit {

  ngOnInit(): void {
      
  }

}
