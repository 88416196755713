import { Component, OnInit } from '@angular/core';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

@Component({
  selector: 'app-link-grid-item',
  templateUrl: './link-grid-item.component.html',
  styleUrls: ['./link-grid-item.component.css']
})
export class LinkGridItemComponent {

  constructor() { }

  /**  OPEN A NEW PAGE ON LINK 
   * Use window.open(). It's pretty straightforward !

In your component.html file-

<a (click)="goToLink('www.example.com')">page link</a>
In your component.ts file-

goToLink(url: string){
    window.open(url, "_blank");
}
   */

 

    // tiles: Tile[] = [
    //   {text: 'One', cols: 1, rows: 2, color: 'lightblue'},
    //   {text: 'Two', cols: 3, rows: 1, color: 'lightgreen'},
    //   {text: 'Three', cols: 1, rows: 1, color: 'lightpink'},
    //   {text: 'Four', cols: 2, rows: 1, color: '#DDBDF1'},
    // ];

    tiles: Tile[] = [
      {text: 'stanza allegra ', cols: 1, rows: 2, color: 'red'},
      {text: 'stanza beluga', cols: 3, rows: 1, color: 'lightgreen'},
      {text: 'stanza cilantro', cols: 1, rows: 1, color: 'red'},
      {text: 'stanza domodossola ', cols: 1, rows: 1, color: 'lightgreen'},
      {text: 'stanza emisfero', cols: 1, rows: 1, color: 'red'},
      {text: 'stanza feltrinelli', cols: 1, rows: 1, color: 'lightgreen'},
      {text: 'stanza galletto', cols: 1, rows: 1, color: 'red'},
      {text: 'stanza histeric', cols: 1, rows: 1, color: 'lightgreen'},
      {text: 'stanza isidora', cols: 1, rows: 1, color: 'lightgreen'},



    ];

    /**  OPEN A NEW PAGE ON LINK 
   * Use window.open(). It's pretty straightforward !

In your component.html file-

<a (click)="goToLink('www.example.com')">page link</a>
In your component.ts file-

goToLink(url: string){
    window.open(url, "_blank");
}
   */
  

}
