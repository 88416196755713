<p>sibiling2 works!</p>
<h2> {{ count }} </h2>

<h1>Message from Service : {{message}}<h1>


<div class="container">
    <h2>Movies Watched</h2>
    <div cdkDropList [cdkDropListData]="MoviesWatched"
      class="movie-list" (cdkDropListDropped)="onDrop($event)">
      <div class="movie-block" *ngFor="let moviesWatched of MoviesWatched" cdkDrag>{{moviesWatched}}</div>
    </div>
    
  </div>