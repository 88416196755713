import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { InitialNullSetupService } from "src/app/services/starting-services/initial-null-setup.service";
import { RecipientFeature } from "./recipient-feature.model";

@Injectable ({
    providedIn: 'root'
})

export class RecipientFeatureService {

    constructor ( private initialNullSetupService: InitialNullSetupService ,
              
        ){}

    recipientFeatures: RecipientFeature[] = this.initialNullSetupService.initialNullRecipientFeatures

    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.recipientFeatureChangeBackToNull
    .subscribe((res: RecipientFeature[])=> {
          this.recipientFeatures = res 
          console.log('updated recipientFeature back to null = ', res)

    })

     /**
     * need to add subject to update status of recipientIndexColumn_exist once recipientFature change 
     */

    getRecipientFeature(){
        return this.recipientFeatures[0]
    }



    
}