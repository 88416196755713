import { Component, OnInit, Input } from '@angular/core';
import { ParagraphFeature } from '../form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature.model';
import { ParagraphService } from '../../form-item/paragraph/paragraph.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
@Component({
  selector: 'app-paragraph-description',
  templateUrl: './paragraph-description.component.html',
  styleUrls: ['./paragraph-description.component.css']
})
export class ParagraphDescriptionComponent implements OnInit {

  user: string 
  @Input() paragraphFeature: ParagraphFeature
  description: string 

  constructor(
               private paragraphService: ParagraphService , 
               private userObjectUpdateService: UserObjectUpdateService ) { }

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

    this.description = this.paragraphService.getParagraphBy_id(this.paragraphFeature.paragraph_id).desc
  
  }; 
   


}
