import { ChangeDetectorRef, Component, Inject, NgZone, OnDestroy, OnInit , ViewEncapsulation} from '@angular/core';
import { FormIndexTopic } from 'src/app/forms/form-list/form-index-topic-list/form-index-topic.model';
import { MatDialogRef , MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup , FormControl} from '@angular/forms';
import { Contact } from 'src/app/contacts/contact.model';
import { ContactService } from 'src/app/contacts/contact.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { DialogmanagerService } from 'src/app/services/other-components-services/dialogmanager.service';
import { FormService } from 'src/app/forms/form.service';
import { Form } from 'src/app/forms/form.model';
import { Subject, Subscription } from 'rxjs';
import { ContactDetails } from 'src/app/contacts/contact-details.model';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { StoreFormItemsService } from 'src/app/services/get-and-store-components/store-form-items.service';
import { StoreFormItemPlusChildsConfirmationService } from 'src/app/services/get-and-store-components/store-form-item-plus-childs-confirmation.service';
import { Paragraph } from '../../form-item/paragraph/paragraph.model';
import { ParagraphService } from '../../form-item/paragraph/paragraph.service';


@Component({
  selector: 'app-share-form-index',
  templateUrl: './share-form-index.component.html',
  styleUrls: ['./share-form-index.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ShareFormIndexComponent implements OnInit, OnDestroy {

  user: string 


  formIndexTopic: FormIndexTopic
  form: Form
  formFeatureIndex: number
  formFeature_id: string 

  weAreOnShareParagraphFormIndex = true

  contact: Contact 
  contactDetails: ContactDetails[]
  isContactEditMode: false
  isSelected= false

  newContactName: FormGroup

  isShareToPublic = false
  listOfCurrentSelectedContactEmail : string[]

  checkedIfIsBeenShared: boolean

  onDeselectAllContacts = new Subject<void>()

  isLoading: boolean
  reconstructFormItemAfterParagraphsHasbeenStoredSubscription: Subscription
  itCanBeReshared: boolean = true

  constructor( public dialogRef: MatDialogRef<ShareFormIndexComponent>,
               private contactService: ContactService,
               private userObjectUpdateService: UserObjectUpdateService ,
               private postComponentsHttpRequestService: PostComponentsHttpRequestService, 
               private formService: FormService ,
               private paragraphService: ParagraphService,
               private dms: DialogmanagerService ,
               private initialNullSetupService: InitialNullSetupService ,
               private ngZone: NgZone, 
               private cdr: ChangeDetectorRef,
               private storeFormItemService: StoreFormItemsService,
               private storeFormItemPlusChildsConfirmationService: StoreFormItemPlusChildsConfirmationService,
               @Inject(MAT_DIALOG_DATA) public editData: any) { }


  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

    if ( this.user !== null ){  this.isLoading = true } // then check if paragraphFeature has any can be shared 
   
    this.formIndexTopic = this.editData.formIndexTopic
    this.form = this.editData.form

    this.formFeatureIndex = this.editData.formFeatureIndex

    console.log(this.formIndexTopic, this.form)

    this.isShareToPublic = this.form.allUsersAllowed
    if ( this.isShareToPublic === true ){  this.isSelected = true }
    if ( this.isShareToPublic === false){ this.isSelected = false }

     /**
     * we need to check and flag whic one of the contacts were already selected 
     */
      this.listOfCurrentSelectedContactEmail = []
      if (this.form.userListAllowed.length > 0){
          for ( let y=0; y<this.form.userListAllowed.length; y++){
            this.listOfCurrentSelectedContactEmail.push(this.form.userListAllowed[y] )
          }
      }

      this.contact = this.contactService.getContacts()[0]

      const newContactDetails = this.contactService.getSortedContacts()

    const filteredContactEmails: ContactDetails[] = []
    for ( let i=0; i< newContactDetails.length ; i++ ){
         if ( filteredContactEmails.findIndex(x=> x.contactEmail === newContactDetails[i].contactEmail ) === -1 ){
          filteredContactEmails.push(newContactDetails[i])
         }
    }
    this.contactDetails = filteredContactEmails

      this.checkedIfIsBeenShared = this.form.isBeenShared

      this.newContactName = new FormGroup ({
      contactName: new FormControl ('')
      })

      if ( this.user !== null ){
      if ( this.form.owner === this.user ){ this.storeFormItemService.getAndStorePersonalFormItemPlusChildsByComponent_id(this.user, this.form._id, 'personal')}
      if ( this.form.owner !== this.user ){ this.storeFormItemService.getAndStoreSharedFormItemByComponent_id(this.user , 'share', this.form._id )}
      }

      this.reconstructFormItemAfterParagraphsHasbeenStoredSubscription = this.storeFormItemPlusChildsConfirmationService.formItem_id_ToAddToFormItem
          .subscribe((formItem_id: string )=> {

      const updatedFormItem: Form = this.formService.getFormBy_id(formItem_id)
      // const currentTypeOfIndex: string = updatedFormPlusTypeOfIndex.typeOfIndex 
      const currentTypeOfIndex: string = 'public'

      console.log('IS RECOMPOSING FORM ON SHARING POP UP PAGE')
      this.recomposeForm( updatedFormItem , currentTypeOfIndex )
     })
  }


  onAddContactDetail(){

    const lowercaseValue = this.newContactName.value.contactName.toLowerCase()
    this.checkIfContactExistOnContactList(lowercaseValue)

    this.newContactName = new FormGroup ({
      contactName: new FormControl ('')
    })
  }

  checkIfContactExistOnContactList(newContact: string ){

    if ( this.contactService.contacts[0].contactDetails.findIndex( x=> x.contactEmail === newContact ) === -1 ){
      // add contact to list 
        const contactName = undefined 
        const contactEmail= newContact 
        const telNumber = undefined
        const isAConnection = true
        const isBlocked = false
        const isBeenReported= false 

        const newContactDetail = new ContactDetails (contactName, contactEmail, telNumber, isAConnection, isBlocked, isBeenReported )
         
         const contactItem = this.contactService.contacts[0] 
               contactItem.contactDetails.push(newContactDetail)

               this.contactService.contacts[0] = contactItem 
               this.initialNullSetupService.initialNullContacts[0] = contactItem

               this.ngZone.run(()=>{
                const newContactDetails = this.contactService.getSortedContacts()

                const filteredContactEmails: ContactDetails[] = []
                for ( let i=0; i< newContactDetails.length ; i++ ){
                if ( filteredContactEmails.findIndex(x=> x.contactEmail === newContactDetails[i].contactEmail ) === -1 ){
                     filteredContactEmails.push(newContactDetails[i])
                }
                }
                 this.contactDetails = filteredContactEmails
               })
               this.cdr.detectChanges()

        if ( this.user !== null ){
         this.postComponentsHttpRequestService.httpUpdateComponent(this.user, 'contact', contactItem )
             .subscribe((res)=> {
              console.log(res)
             }, error => {
              console.log( error )
             })
        }
    }


  }

  onSelectedContact(){
    this.isSelected = true
    this.isShareToPublic = true
    
    this.checkedIfIsBeenShared = true

  }

  onDeselectContact(){
    this.isSelected = false
    this.isShareToPublic = false

    if ( this.listOfCurrentSelectedContactEmail.length > 0 ){
      this.checkedIfIsBeenShared = true
 } else {
      this.checkedIfIsBeenShared = false
 }
  }


    /**
   * 
   * @param event receiving the contact to be removed , from contact item sub component
   */
     onRemoveContactFromList(event){
      this.listOfCurrentSelectedContactEmail.forEach((element,index)=>{
        if(element == event.contactEmail) this.listOfCurrentSelectedContactEmail.splice(index, 1)
        })
      console.log(this.listOfCurrentSelectedContactEmail)
      if ( this.listOfCurrentSelectedContactEmail.length > 0 || this.isShareToPublic === true ){
        this.checkedIfIsBeenShared = true
   } else {
        this.checkedIfIsBeenShared = false
   }
     }


      /**
    * 
    * @param event receiving the contact from contact item sub component and adding the email to the list
    */
  onAddContactToList(event){
        this.listOfCurrentSelectedContactEmail.push(event.contactEmail)
        console.log(this.listOfCurrentSelectedContactEmail)
        if ( this.listOfCurrentSelectedContactEmail.length > 0 || this.isShareToPublic === true ){
             this.checkedIfIsBeenShared = true
        } else {
         this.checkedIfIsBeenShared = false
        }
  }

  onClearSelectedContacts(){
    this.listOfCurrentSelectedContactEmail = []
    this.isShareToPublic = false 
    this.checkedIfIsBeenShared = false 
    this.onDeselectAllContacts.next()
  }


onShareForm(){

  const new_form_id = this.form._id
  const newComponentName = this.form.componentName
  const newUIID = this.form.UIID
  const newOwner = this.form.owner;
  const newDateAndTime = this.form.dateAndTime ;
  const newUpdateDateAndTime = this.form.updateDateAndTime
  const newAllUsersAllowed = this.isShareToPublic
  const newUserListAllowed = this.listOfCurrentSelectedContactEmail
  const newCanBeResharedByOthers = false 
  const newNoNeedecipientAndSubject = this.form.noNeedRecipientAndSubject
  const newIsFixedSubject = this.form.isFixedSubject
  const newIsShareble = true 

  if ( this.listOfCurrentSelectedContactEmail.length > 0 || this.isShareToPublic === true ){
       this.checkedIfIsBeenShared = true
  } else { this.checkedIfIsBeenShared = false} 

  const newIsBeenShared = this.checkedIfIsBeenShared
  const newformSubject = this.form.formSubject
  const newRecipientIndex_id = this.form.recipientIndex_id
  const newParagraph_ids = this.form.paragraph_ids


 
  const newFormItem = new Form ( new_form_id,
                             newComponentName,
                             newUIID,
                             newOwner ,
                             newDateAndTime,
                             newUpdateDateAndTime,
                             newAllUsersAllowed,
                             newUserListAllowed,
                             newCanBeResharedByOthers,
                             newNoNeedecipientAndSubject ,
                             newIsFixedSubject ,
                             newIsShareble, 
                             newIsBeenShared,
                             newformSubject,
                             newRecipientIndex_id ,
                             newParagraph_ids
                            )

if(this.user === null ){ 
  this.formService.onUpdateFormSharingStatus(newFormItem)
  this.formService.forms[this.formService.getForms().findIndex(x=>x._id === newFormItem._id) ] = newFormItem 
 
  /**
   * add a pop up note that say this is only for demo , the form it's not been actually shared 
   * you need to login to share your content 
   */
  this.dms.QuickCommunicationPopUpComponent({
    quickComment:  "demo purpose , login to share"

})


}

if( this.user !== null ){

this.postComponentsHttpRequestService.httpUpdateComponent(this.user, 'formItem', newFormItem )
            .subscribe(
                       (res)=> {
                        console.log(res)

            this.formService.forms[this.formService.getForms().findIndex(x=> x._id === newFormItem._id)] = newFormItem
            this.formService.onUpdateFormSharingStatus(newFormItem)

             }) // END FORM-ITEM subscription
}
}

recomposeForm(form: Form , typeOfIndex: string ){

  this.form = form 

  /**
   * check if any paragraphFeature cannot be shared , if so the form cannot be shared 
   * check if any of the paragraphs contain a shared component
   * export class Form {
   
    constructor( 
        public _id: string,
        public componentName: string , 
        public UIID: string , 
        public owner: string , 
        public dateAndTime: string, 
        public updateDateAndTime: string, 
        public allUsersAllowed: boolean,
        public userListAllowed: string[],
        public canBeResharedByOthers: boolean , 
        public noNeedRecipientAndSubject: boolean ,
        public isFixedSubject: boolean , 
        public isShareble: boolean , 
        public isBeenShared: boolean, 
        public formSubject: string,
        public recipientIndex_id: string ,
        public paragraph_ids: string[]
      ){}
   
    }

    export class Paragraph {

constructor( 
    public _id: string,
    public componentName: string , 
    public UIID: string ,
    public owner: string, 
    public dateAndTime: string,
    public updateDateAndTime: string,
    public isRemoved: boolean ,  
    public allUsersAllowed: boolean,
    public userListAllowed: string[],
    public canBeResharedByOthers: boolean , 
    public name: string , 
    public desc: string , 
    public features: Feature[],
    public feature_id_list: string[] ){}

}
   */
  
  for ( let i=0; i<this.form.paragraph_ids.length ; i++ ){

    // check if any paragraphFeature cannot be shared , if so the form cannot be shared 
    const newParagraph: Paragraph = this.paragraphService.getParagraphBy_id(this.form.paragraph_ids[i])
    if ( !newParagraph.canBeResharedByOthers && newParagraph.owner !== this.user ){ 
         console.log(i, "newParagraph.canBeResharedByOthers => ", newParagraph.canBeResharedByOthers )
         this.itCanBeReshared = false }
    // check if any of the paragraphs contain a shared component that doesn't belong to user 
    if ( newParagraph.features.some( feature=> feature.featureName === 'sharedCalendarStatus') && newParagraph.owner !== this.user ){
      this.itCanBeReshared = false
      console.log(i, "sharedCalendarStatus => ", newParagraph.canBeResharedByOthers )
    }

  }



  this.isLoading = false 
}

ngOnDestroy(): void {
    this.reconstructFormItemAfterParagraphsHasbeenStoredSubscription?.unsubscribe()
}






}


