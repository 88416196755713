import { Component, Input,  OnInit } from '@angular/core';
import { SingleFeatureDetailResult } from './single-feature-detail-result.model';


@Component({
  selector: 'app-single-feature-results',
  templateUrl: './single-feature-results.component.html',
  styleUrls: ['./single-feature-results.component.css']
})
export class SingleFeatureResultsComponent implements OnInit {

  @Input() singleFeatureDetailResults: SingleFeatureDetailResult[]

  constructor() { }

  ngOnInit(){

    this.singleFeatureDetailResults
  }

}
