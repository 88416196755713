import { Component, OnInit , Input , Output , EventEmitter} from '@angular/core';

import { ParagraphResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/paragraph-result.model';
import { FeatureResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/feature-result.model';
import { Observable , Subject, Subscription } from 'rxjs';
import { Paragraph } from 'src/app/forms/form-list/form-item/paragraph/paragraph.model';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { ParagraphFeature } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature.model';


@Component({
  selector: 'app-chat-fill-paragraph',
  templateUrl: './chat-fill-paragraph.component.html',
  styleUrls: ['./chat-fill-paragraph.component.css']
})
export class ChatFillParagraphComponent implements OnInit {

  user: string 

  @Input() paragraphFeature: ParagraphFeature
  @Input() paragraph : Paragraph ; 

  featureResult: FeatureResult;
  featureResults: FeatureResult[];

  isParagraphEdit = false ; 

  paragraphResult: ParagraphResult;

  subscribeClickEvent: Subject<void> = new Subject<void>();
 

  /**
   * to make the subscriptiuons of normal components works precisely if under tab item or under paragraph edit 
   * and paragraphItem
   */
   isUnderTabs = false

  /**
   * we don't need text result here for the moment  // we might want to forward a message to email 
   * but that will be done from result side  
   */
  //  featureResultTextBodyParts: string[]
  //  mailTextParagraphBodyPart: string;
   featureCounter: number

   updateOnUserEmail: Subscription

  @Output() updateBothParagraphResultAndTextPlusIndex = new EventEmitter<any>()



  constructor(  private userObjectUpdateService: UserObjectUpdateService ) {}

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    console.log(this.paragraph);

    this.featureResults = [];

 }

/**
 * 
 * @param $event receiving result data from featureComponents  
 */
onUpdateBothFeatureResultTextResultAndIndex($event){
  console.log($event , this.paragraphFeature)

  this.featureResults[$event.newFeatureDrop_Id] = $event.newFeatureResult

  const newParagraphResultName = this.paragraph.name
  const newParagraphResult = new ParagraphResult (  newParagraphResultName, this.featureResults )

  this.paragraphResult = newParagraphResult

  console.log(this.paragraphResult)

  /**
   * now preparing the paragraph by TEXT 
   */
  // this.featureResultTextBodyParts[$event.newFeatureDrop_Id] = $event.newMailTextFeatureBodyPart

  // for ( let i= 0; i<this.paragraph.features.length; i++){
  //   if ( i === 0 ){ this.mailTextParagraphBodyPart = this.featureResultTextBodyParts[i] +'%0D%0A' }
  //   if ( i !== 0 ){ this.mailTextParagraphBodyPart = this.mailTextParagraphBodyPart + this.featureResultTextBodyParts[i] +'%0D%0A' }

  // }
  // console.log(this.mailTextParagraphBodyPart)

  this.onSend()


}

onSend(){

  const newParagraphFeatureDropId = this.paragraphFeature.paragraphFeatureDropId
  const newParagraphResult = this.paragraphResult
  const newMailTextParagraphBodyPart = null // this.mailTextParagraphBodyPart

  this.updateBothParagraphResultAndTextPlusIndex.emit({newParagraphFeatureDropId, newParagraphResult, newMailTextParagraphBodyPart})
  
 

};


 /**
  * unsubscribe submission
  */
  ngOnDestroy() {
    // this.paragraphResultSubscription.unsubscribe();
  }

  /**
   * 
   * @param event receiving and adding the featureResults from the related features components
   */
  //  onUpdateFeatureResult(event){
  //   this.featureResults.push(event)
  //   console.log(this.featureResults)

  //     /**
  //    * preparing the  paragraphResultsto be sent to formItem 
  //    */

 
  //   const newParagraphResultName = this.paragraph.name
 
  //    if ( this.featureResults.length === this.paragraph.features.length){
 
  //      const newSentParagraphResult = new ParagraphResult ( 
  //                                                           newParagraphResultName,
  //                                                           this.featureResults ) ;
 
                                                   
  //    /**
  //     * sending the paragraph to keep in user formResult
  //     */
  //     console.log(newSentParagraphResult)
  //    this.updateSentParagraphResultEvent.emit(newSentParagraphResult);
     
 
 
  //    /** restart the situation */
  //    this.featureResults = [] 
 
 
  //    }
  //  }


}
