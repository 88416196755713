import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qr-code-generator-item',
  templateUrl: './qr-code-generator-item.component.html',
  styleUrls: ['./qr-code-generator-item.component.css']
})
export class QrCodeGeneratorItemComponent implements OnInit {

  myAngularQRcodeData: any 

  constructor() { }

  ngOnInit(): void {

    this.myAngularQRcodeData = '-1 toiletpaperwasabihotelreturnjuly-25th-2022at1030amwasabihoteltotaldailyusage1weekly38monthly356/yearly2256overallstock349/mistock300'
  }

}
