import { RangeDatesInfo } from "./range-dates-info.model";

export class SharedCalendarStatus {

    constructor( public _id: string , 
                 public componentName: string , 
                 public UIID: string , 
                 public owner: string ,
                 public allUsersAllowed: boolean,
                 public userListAllowed: string[],
                 public dateAndTime: string ,
                 public name: string , 
                 public rangeDatesInfoSetting: RangeDatesInfo,
                 public rangeDatesInfos: RangeDatesInfo[]
                 ){}

}