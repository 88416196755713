import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RangeInfo } from '../range-info.model';

interface IndexRangeInfo{
  index: number ,
  rangeInfo: RangeInfo
}

@Component({
  selector: 'app-inner-button-edit',
  templateUrl: './inner-button-edit.component.html',
  styleUrls: ['./inner-button-edit.component.css']
})
export class InnerButtonEditComponent implements OnInit {

  innerButtonEditFormName: FormGroup

  @Input() rangeInfo: RangeInfo
  @Input() isEditing: boolean

  constructor() { }

  ngOnInit(): void {

    this.innerButtonEditFormName = new FormGroup({
      name : new FormControl (this.rangeInfo.name , [ Validators.required, Validators.maxLength(30) ] )
      });
  }

  onSubmitName(){

  }

}
