import { Injectable } from '@angular/core';
import { Form } from './form.model';
import { BehaviorSubject, Observable, Subject , Subscription} from 'rxjs';
import { IndexType } from './index-type.model';
import { InitialNullSetupService } from '../services/starting-services/initial-null-setup.service';
import { RecipientIndexService } from './form-list/form-edit/recipient-index-list/recipient-index.service';
import { StoreFormItemsService } from '../services/get-and-store-components/store-form-items.service';
import { AdviseIfHttpStoreComponentsCallHasBeenMadeService } from '../services/other-components-services/advise-if-http-store-components-has-been-made.service';
import { Paragraph } from './form-list/form-item/paragraph/paragraph.model';
import { StoreFormItemPlusChildsConfirmationService } from '../services/get-and-store-components/store-form-item-plus-childs-confirmation.service';
import { RecipientIndexColumnService } from './form-list/form-edit/recipient-index-list/recipient-index-column.service';
import { RecipientIndexListItemService } from './form-list/form-edit/recipient-index-list/recipient-index-list-item.service';
import { RecipientIndexColumn } from './form-list/form-edit/recipient-index-list/recipient-index-column.model';
import { RecipientIndexListItem } from './form-list/form-edit/recipient-index-list/recipient-index-list-item.model';


@Injectable ({
    providedIn: 'root' 
})

export class FormService {

  constructor( 
               private initialNullSetupService: InitialNullSetupService,
               private recipientIndexService: RecipientIndexService,
               private recipientIndexColumnService: RecipientIndexColumnService , 
               private recipientIndexListItemService: RecipientIndexListItemService , 
               private storeFormItemsService: StoreFormItemsService,
               private storeFormItemPlusChildsConfirmationService: StoreFormItemPlusChildsConfirmationService ,
               private adviseIfHttpStoreComponentsCallHasBeenMadeService: AdviseIfHttpStoreComponentsCallHasBeenMadeService
    ){}


  public forms : Form[] = this.initialNullSetupService.initialNullForms

  onUpdateFormStatus = new Subject<void>()
  stopLoadingFormIndexTopic = new Subject<void>()
  openFormListSideNav_FromStartComponent = new Subject<void>()

 updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.formsChangedBackToNull
    .subscribe((res)=> {
        this.forms = res 
        console.log('updated forms back to null = ', res)

});

updateFormsFromStoreFormItems: Subscription = this.storeFormItemsService.formItemToAdd
     .subscribe((formItemToAddPlusTypeOfIndex: any) => {
      const formItemToAdd: Form = formItemToAddPlusTypeOfIndex.newForm
      const typeOfIndex: string = formItemToAddPlusTypeOfIndex.typeOfIndex
      // this.addForm( formItemToAdd , typeOfIndex )
      this.addFormAndAdviseStoreFormPlusChilds(formItemToAdd )
     })

/**
 * subscription coming from storeFormItems
 * this.formItemsListToAdd.next({ formItemsResults , typeOfIndex , comingFromFormItem }); 
 */
updateFormsToAdd : Subscription = this.storeFormItemsService.formItemsListToAdd
.subscribe((formsToAdd: any)=> {
  console.log(formsToAdd)

  const formItemsToAdd: Form[] = formsToAdd.formItemsResults
  const newTypeOfIndex: string = formsToAdd.typeOfIndex
  const newComingFromFormItem: boolean = formsToAdd.comingFromFormItem

  this.addFormsToList( formItemsToAdd, newTypeOfIndex, newComingFromFormItem )
});





updateStartingEditFormFromStorePersonalFeatureIndexComponentsService: Subscription = this.recipientIndexService.startingEditForm
   .subscribe((res: Form)=> { this.onUpdateLatestShownForm(res) , console.log( res , 'subdcription to change Initial Form is arrived ')})

 


     /**
      * 
      * @param id 
      * @returns form with id
      */
    getForm(id:number){
        return this.forms[id];
    }


    getFormBy_id(_id: string){
      return this.forms.find(x => x._id === _id)
    }

    /**
     * 
     * @returns form list
     */
    getForms(){
        return this.forms ;
    }

    getUserForms(user: string){
      const newUserFormList = this.forms.filter(
        (res)=> {
          return res.owner === user
        }
      )
      return newUserFormList
    }

    addForm(form: Form , typeOfIndex: string ){
     if ( this.forms.findIndex(x=> x._id === form._id) !== -1 ){
          const index = this.forms.findIndex(x=> x._id === form._id)
          this.forms[index] = form
          this.updateInitialComponents(this.forms)
      }
     if ( this.forms.findIndex(x=> x._id === form._id) === -1 ){ 
          this.forms.push(form)
          this.updateInitialComponents(this.forms)
          // confirm to formItem what type of form has been stored 
          console.log('added formItem: ', form , 'of this type = ', typeOfIndex) 
          
        }
        
    }

    addFormsOnly(forms: Form[]){
      for (let i=0 ; i<forms.length ; i++ ){
        if ( this.forms.findIndex(x=> x._id === forms[i]._id ) !== -1  ){
             const index = this.forms.findIndex(x=> x._id === forms[i]._id )
             this.forms[index] = forms[i]
             this.updateInitialComponents(this.forms)
        }
        if ( this.forms.findIndex(x=> x._id === forms[i]._id ) === -1  ){
          this.forms.push(forms[i])
          this.updateInitialComponents(this.forms)
        }

      }

    }

    updateForm(updatedForm: Form){
      const index = this.forms.findIndex(x=> x._id === updatedForm._id)
      this.forms[index] = updatedForm 
      this.updateInitialComponents(this.forms)
    }

    addFormAndAdviseStoreFormPlusChilds(form: Form){
      if ( this.forms.findIndex(x=> x._id === form._id) !== -1 ){ 
           this.storeFormItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenFormItemPlusChildsAreStored(form._id) }
     
           if ( this.forms.findIndex(x=> x._id === form._id) !== -1 ){ 
            const index = this.forms.findIndex(x=> x._id === form._id)
            this.forms[index] = form
            this.updateInitialComponents(this.forms)
            this.storeFormItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenFormItemPlusChildsAreStored(form._id) 
            // confirm to formItem what type of form has been stored 
            console.log('added formItem: ', form ) 
          }

           if ( this.forms.findIndex(x=> x._id === form._id) === -1 ){ 
           this.forms.push(form)
           this.updateInitialComponents(this.forms)
           this.storeFormItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenFormItemPlusChildsAreStored(form._id) 
           // confirm to formItem what type of form has been stored 
           console.log('added formItem: ', form ) 
           }
        
     }
    // const form_id: string = formsToAdd[i]._id
    // const hasBeenStored: boolean = false 
    // const formItemPlusChildsHasBeenStored: any = { form_id, hasBeenStored }
    // this.adviseIfHttpStoreComponentsCallHasBeenMadeService.formItemPlusChildsHasBeenStoredList.push(formItemPlusChildsHasBeenStored)

    removeForm(i){
      this.forms.splice( i , 1 )
    }

    onUpdateFormSharingStatus(form: Form){
      this.forms[this.forms.findIndex(x=> x._id === form._id)] = form 
      this.onUpdateFormStatus.next()
    }

  
    /**
     * receiving the receiveForm_id from form Index Topic List
     * set the very first form_id as starterForm_id 
     * 
     * this will be the last sent form , we change the property on the sendForm click
     */
  starterForm_id: string = '1'

   onSettingStarterForm_id(form_id: string ){
   this.starterForm_id = form_id
   console.log(form_id)
   }

// ------------------- use subscription to pass id form-reload / form-indextopiclist-form-feature / add-form-dialog  -------
   private receiveForm_id = new BehaviorSubject<string>(this.starterForm_id)
   changingForm_id= this.receiveForm_id.asObservable()
   /**
    * 
    * @param receiveForm_id sending this paramater to: Form-item , recipient-item and 
    * all sub components for message and mails 
    */
  

  onChangedForm_id(_id: string){
    console.log(_id)
    this.receiveForm_id.next(_id)
   
  }

// -----------------------------------------------------------------------------------------------------------


// --------------- START --------------------------------------------------------------------
  /**
   * use indexType model to define what type of paragraph index we are showing 
   */
  starterIndexType : IndexType = new IndexType ( false, true, false, false )

  private receiveParagraphIndexType = new BehaviorSubject<IndexType>(this.starterIndexType)
  changingParagraphIndexType = this.receiveParagraphIndexType.asObservable()

  onChangeParagraphIndexType(indexType: IndexType){
    this.receiveParagraphIndexType.next(indexType)
  }

// ---------------- STOP ----------------------------------------------------------------------





// inform formList about the changed form and if a recipientIndex can be saved or form can be edited __________

selectedForm_existingFormFeatContainer_selectedRecipientIndex_id = new Subject<any>()
$selectedForm_existingFormFeatContainer_selectedRecipientIndex_id : Observable<any> = this.selectedForm_existingFormFeatContainer_selectedRecipientIndex_id.asObservable()

sendForm_featFormContainer_selRecipIndex_id(form_formFeatContainer_recipIndex_id: any ){
  this.selectedForm_existingFormFeatContainer_selectedRecipientIndex_id.next(form_formFeatContainer_recipIndex_id)
}

//_____________________________________________________________________________________________________________




paragraphOfNullUserToAdd = new Subject<Paragraph>()
$paragraphOfNullUserToAdd : Observable<Paragraph> = this.paragraphOfNullUserToAdd.asObservable()

sendNullUserParagraphToAddToFormEdit(paragraph: Paragraph ){
  this.paragraphOfNullUserToAdd.next(paragraph);
  }
// ------ end  inform form list if RecipientIndex_exist on form Item -------------------------- 


    // // line - 1247 - 1404  to fix on formEdit
    /**
     * here we get the personal recipientTopicIndex and from there the 
     * first recipientIndexListItem and then the first recipientIndex_id 
     */
    startingRecipientIndexColumn: RecipientIndexColumn = this.recipientIndexColumnService.getRecipientIndexColumnBy_type('personal')
    startingRecipientIndexListItem: RecipientIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItemBy_id(this.startingRecipientIndexColumn.recipientIndexListItem_ids[0] )
    startingRecipientIndex_id: string = this.startingRecipientIndexListItem.recipientIndexSelected_id
    startingEditForm: Form = new Form ( null , 'form', null, '', '','', false, [], false,  false, false, true, false, '' , this.startingRecipientIndex_id , [] )

 

    onSettingInitialEditForm(form: Form ){
      this.startingEditForm = form 
      console.log(this.startingEditForm)
    }


    private receiveForm = new BehaviorSubject<Form>( this.startingEditForm )
   
    changingForm = this.receiveForm.asObservable()

    onUpdateLatestShownForm(latestForm: Form ){
      console.log(latestForm )
      this.receiveForm.next(latestForm )
    }

    // ------------------ end behavior subject ----------------------------------------------------------

  
     /**
      *  start asking for all components to resend the RESULT --------------------------------------------
      */

     requestComponentFinalResult = new Subject<void>()

     sendRequestToFinalizeResultAgain(){
      this.requestComponentFinalResult.next();
      }
      
      getRequestToFinalizeResultAgain(): Observable <any> {
      return this.requestComponentFinalResult.asObservable();
      }

     /**
      *  END asking for all components to resend the RESULT --------------------------------------------
      */

      addFormsToList(formsToAdd: Form[] , typeOfIndex: string , comingFromFormItem: boolean ){
        for(let i=0; i<formsToAdd.length; i++){
          if ( this.forms.findIndex(x=> x._id === formsToAdd[i]._id )=== -1)
          this.forms.push(formsToAdd[i])
          // let advise if http has been store know about which form
          const form_id: string = formsToAdd[i]._id
          const hasBeenStored: boolean = false 
          const formItemPlusChildsHasBeenStored: any = { form_id, hasBeenStored }
          if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.formItemPlusChildsHasBeenStoredList.findIndex(x=> x.form_id === form_id ) === -1){
            this.adviseIfHttpStoreComponentsCallHasBeenMadeService.formItemPlusChildsHasBeenStoredList.push(formItemPlusChildsHasBeenStored)
          }
          if ( i === formsToAdd.length-1){
            this.updateInitialComponents(this.forms)
          }
        
        }
        console.log('UPDATED FORMS WITH SHARED / PUBLIC / PREFERRED FORMS',this.forms)

        if (typeOfIndex === 'personal'){ this.adviseIfHttpStoreComponentsCallHasBeenMadeService.turnPersonalFormsComponentsHasBeenStoredToTrue()}
        if (typeOfIndex === 'share'){this.adviseIfHttpStoreComponentsCallHasBeenMadeService.turnSharedFormsComponentsHasBeenStoredToTrue()}
        if (typeOfIndex === 'preference'){this.adviseIfHttpStoreComponentsCallHasBeenMadeService.turnPreferredFormsComponentsHasBeenStoredToTrue()}

        this.stopLoadingFormIndexTopic.next()
  }

  updateInitialComponents(updatedForms: Form[]){
    this.initialNullSetupService.initialNullForms = updatedForms
  }

  /**
   * from starting component to opening sideVan on formList
   */
  onOpenFormListSideNav_FromStartComponent(){
    this.openFormListSideNav_FromStartComponent.next()
  }

    
  ngOnDestroy(){
    this.updateInitialNullDatafromInitialNullSetupService.unsubscribe()
    this.updateFormsFromStoreFormItems.unsubscribe()
    this.updateFormsToAdd.unsubscribe()
  }

}