import { Component, OnInit } from '@angular/core';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';

interface Slider {
  id: number;
  value: number;
}

@Component({
  selector: 'app-slider-item',
  templateUrl: './slider-item.component.html',
  styleUrls: ['./slider-item.component.css']
})
export class SliderItemComponent implements OnInit {

    sliders: Slider[] = [{ id: 1, value: 50 }, { id: 2, value: 50 }];
    nextId = 3;
  
    ngOnInit() {
      this.adjustSliders();
    }
  
    updateSlider(slider: Slider, event: any) {
      const newValue = parseInt(event.target.value);
      const totalValue = this.sliders.reduce((total, s) => total + s.value, 0);
      const remainingValue = 100 - totalValue + slider.value;
      if (newValue > slider.value && newValue > remainingValue) {
        const adjustment = Math.min(newValue - slider.value, remainingValue);
        slider.value += adjustment;
        const nextSlider = this.sliders.find(s => s.id === slider.id + 1);
        if (nextSlider) {
          nextSlider.value -= adjustment;
        }
      } else if (newValue < slider.value && newValue < slider.value - remainingValue) {
        const adjustment = Math.min(slider.value - newValue, slider.value - remainingValue);
        slider.value -= adjustment;
        const previousSlider = this.sliders.find(s => s.id === slider.id - 1);
        if (previousSlider) {
          previousSlider.value += adjustment;
        }
      } else {
        slider.value = newValue;
        this.adjustSliders();
      }
    }
  
    addSlider() {
      const newSlider: Slider = { id: this.nextId++, value: 0 };
      this.sliders.push(newSlider);
      this.adjustSliders();
    }
  
    adjustSliders() {
      const totalValue = this.sliders.reduce((total, slider) => total + slider.value, 0);
      if (totalValue === 0) {
        this.sliders.forEach(slider => slider.value = 100 / this.sliders.length);
      } else {
        let remainingValue = 100;
        let remainingSliders = this.sliders.length;
        this.sliders.forEach(slider => {
          const proportion = slider.value / totalValue;
          const newValue = proportion * 100;
          slider.value = newValue;
          remainingValue -= newValue;
          remainingSliders--;
          if (remainingSliders === 0) {
            slider.value += remainingValue;
          }
        });
      }
    }
  
  
  
  }
  
  