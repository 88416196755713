<!-- <p>drop-down-select-item works!</p> -->

<br>
<br>

<div class="row">
    <div class="col-xs-12">

           <table>
               <tbody >
                 
                   <tr>

                        <td *ngFor="let description of dropDownSelect.descriptionList; let i=index">
                        <mat-form-field style="width: 500px" appearance="outline">
                            <mat-label>{{description.name}}</mat-label>
                            <mat-select 
                                        (selectionChange)="selectDescription( i, $event)"  >

                                         <mat-option *ngFor="let content of description.contents" [value]="content">
                                            {{content}}
                                        </mat-option>
                            </mat-select>

                        </mat-form-field>

                    </td>

                   </tr>
               </tbody>
           </table>

    </div>
</div>

<br>
<br>

