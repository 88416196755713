import { Component, Input, OnDestroy, OnInit} from '@angular/core';
import { ParagraphTopic } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.model';
import { ParagraphTopicService } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.service';
import { Paragraph } from 'src/app/forms/form-list/form-item/paragraph/paragraph.model';
import { ParagraphFeature } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature.model';
import { Observable , Subject, BehaviorSubject, Subscription } from 'rxjs';
import { HeaderService } from 'src/app/header/header.service';
import { Header } from 'src/app/header/header.model';
import { FormGroup, FormControl } from '@angular/forms';
import { ParagraphService } from 'src/app/forms/form-list/form-item/paragraph/paragraph.service';
import { ParagraphIndexTopicDialog } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-index-topic-dialog.service';
import { FeatureTopicService } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-topic.service';
import { FeatureIndexSelection } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-index-topic-list/feature-index-selection.model';
import { FormService } from 'src/app/forms/form.service';
import { IndexType } from 'src/app/forms/index-type.model';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { ParagraphFeatureContainer } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature-container.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Chat } from '../../chat.model';
import { Recipient } from 'src/app/forms/form-list/form-item/recipient/recipient.model';

@Component({
  selector: 'app-chat-paragraph-index-topic',
  templateUrl: './chat-paragraph-index-topic.component.html',
  styleUrls: ['./chat-paragraph-index-topic.component.css']
})
export class ChatParagraphIndexTopicComponent implements OnInit , OnDestroy {
  user: string 

  paragraph: Paragraph ;
  paragraphTopic : ParagraphTopic ;
  paragraphFeatures : ParagraphFeature[];
  paragraphFeature: ParagraphFeature;

  // paragraphIndexNameForm : FormGroup;
 

   @Input() isPersonal: boolean  
   @Input() isPublic: boolean 
   @Input() index_id: string ;
   index: number 

  //  @Input() aChatHasBeenSelected: boolean 
  //  selectedChatSubscription: Subscription ;
  //  @Input() subscribeUpdatedChatSelected: Observable<Recipient>
  //  subscribeSelectedChat = new Subject<Recipient>()
  //  @Input() chat: Chat

   /** gives paragraph Index the topic to add when add paragraph */
   topicIndex : number ;

   /** to hide the buttons and keep only the topic */
   hideParagraphs = true ;

  /** these 2 serve to have a max limit on paragraphs to add */
   createButtonLimit : boolean ;
  paragraphsIndexLimit = 50 ;

  count = 0;

  weGoingToComponentIndex : boolean = true ;

  isDoubleClick : boolean = false ;
  

  /** set timing function */
  setTimer: any ;

  /** to hide 1st click button on modify index name */
  hide1stButton = false ;
  

/** hide and show modify index button  */
  hideButton = false ;

  mouseEnter: boolean = false 

  

  constructor( private paragraphTopicService : ParagraphTopicService,
               private paragraphService: ParagraphService,
               private headerService: HeaderService,
               private featureTopicService: FeatureTopicService,
               private userObjectUpdateService: UserObjectUpdateService , 
               private formService: FormService ,
               private router: Router ,
               private route: ActivatedRoute
                ) { }

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail
    
    this.paragraphTopic = this.paragraphTopicService.getParagraphTopicBy_id(this.index_id) 
    console.log(this.paragraphTopic)

    
   
    this.index = this.paragraphTopicService.getParagraphTopics().findIndex(x=> x._id === this.index_id)
      /** here i'll have to set the length of the nonRemoved ones , or they will count also the ones removed 
     * from the list
     */
    if ( this.paragraphService.getParagraphs().length < this.paragraphsIndexLimit ){
      this.createButtonLimit = true
    } else {
      this.createButtonLimit = false 
    };

    // this.paragraphIndexNameForm = new FormGroup({
    //   name: new FormControl ('') ,   
    //  });

    //  this.selectedChatSubscription = this.subscribeUpdatedChatSelected.subscribe((currentRecipientOfReference: Recipient )=>{
    //   console.log( currentRecipientOfReference )
    //   this.subscribeSelectedChat.next(currentRecipientOfReference)
    // })
    
  }

  noReturnPredicate() {
    return false;
  }

  // onMouseEnter(){
  //   this.mouseEnter = true
  // }

  // onMouseLeave(){
  //   this.mouseEnter = false 
  // }


  onIsDoubleClick(i){
    this.isDoubleClick = !this.isDoubleClick;

  
    /** start a new timer */
     setTimeout(() => {
      this.isDoubleClick = false ;
    }, 2000);
    // console.time(this.setTimer)
  }

/** clear timer before itstart again */
  clearTimer(){
  clearTimeout(this.setTimer)
}

  /** this serve to show the lisy of paragraphs , they are hidden when start
   * they open when user click on the topic 
   */
  onHideParagraphs(){
    this.hideParagraphs = !this.hideParagraphs;
    /** clear timer before it start again */
    this.clearTimer()
    
   this.setTimer= setTimeout(() => {
      this.hideParagraphs = true ;
    }, 30000);
  }

  // onSetParagraphTopic(){
  //   this.paragraphTopicService.currentParagraphTopicIndex = this.index }


      /** set topicname so when create paragraph and click submit , the app knows which 
      * topic to add the paragraph to 
      * 
      * we should add the behavior subject to make it more stable , it serves as reminder to which paragraphTopic group
      * the paragraph we are creating belong to 
      */
   
    onSetTopicIndex(){ // make behavior subject on paragraphTopicService 
      // this.paragraphTopicService.setCurrentTopicIndex(this.index); 
      // this.paragraphTopicService.setTopicName(this.index);
      this.paragraphTopicService.onChoosenParagraphTopicToBeAddedTo(this.paragraphTopic)
      this.onGoingToParagraphEdit()
    };


    onGoingToParagraphEdit(){
    
      const newHeaderIndexSelection = new Header (false, true, false, false, false, false, false, false )
      this.headerService.nextIndexSelection(newHeaderIndexSelection)

      /**
       * setting up feature index selection to start
       */
      const newUpdateFeatureIndexSelection = new FeatureIndexSelection ( true, false  )
      this.featureTopicService.nextIndexSelection(newUpdateFeatureIndexSelection)

      this.router.navigate(['/create-paragraph' ], {relativeTo: this.route.parent })
    }

    /**
     *  /**
   * export class Header {

    constructor( public weAreInDrapp:         boolean ,  
                 public weAreInEditParagraph: boolean, 
                 public weAreInForms:         boolean,
                 public weAreInMessages:      boolean ,
                 public weHaveToSignIn:       boolean , 
                 public weAreLoggedIn:        boolean ){}
   */
 

  // onWeAreGoingToCreateForm(){

  //   const newHeaderIndexSelection = new Header (true, false, false, false, false, false )
  //   this.headerService.nextIndexSelection(newHeaderIndexSelection)}
    

    // onHide1stButton(){
    //   this.hide1stButton = !this.hide1stButton ;
    //     setTimeout(() => {
    //       this.hide1stButton = false ;
    //     }, 1000);
      
    
    // }

/** hide button to start editing the index name  */
    // onHideButton(){
    //   this.hideButton = !this.hideButton;
    //   setTimeout(() => {
    //  this.hideButton = false ;
    //  this.hide1stButton = false;
    //  this.paragraphIndexNameForm = new FormGroup({
    //   name: new FormControl ('') ,   
    //     })
    // }, 9000);
    // }


    // onSubmit(){


      
    //   this.paragraphTopicService.getParagraphTopic(this.index).name = this.paragraphIndexNameForm.value.name ;
    //   /**
    //    * need to make a Http call to PUT the new name 
    //    */

    //   this.paragraphTopic = this.paragraphTopicService.getParagraphTopic(this.index);

    //   this.hide1stButton = false;
    //   this.hideButton = false ;

    //   this.paragraphIndexNameForm.value.name = '' ;

    //   console.log(this.paragraphTopicService.getParagraphTopics())

    // }


    ngOnDestroy(): void {
      // this.selectedChatSubscription.unsubscribe()
    }

    


}


