import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { RangeInfo } from '../range-info.model';
import { Subscription } from 'rxjs';

interface IndexRangeInfo{
  index: number ,
  rangeInfo: RangeInfo
}

@Component({
  selector: 'app-inner-component-edit-dialog',
  templateUrl: './inner-component-edit-dialog.component.html',
  styleUrls: ['./inner-component-edit-dialog.component.css'],
  encapsulation: ViewEncapsulation.None 
})
export class InnerComponentEditDialogComponent implements OnInit, OnDestroy {

  user: string 
  rangeInfo: RangeInfo
  index: number

  updateOnUserEmail: Subscription

  constructor( public dialogRef: MatDialogRef<InnerComponentEditDialogComponent>,
               public postComponentsHttpRequestService: PostComponentsHttpRequestService,
               private userObjectUpdateService: UserObjectUpdateService, 
    @Inject(MAT_DIALOG_DATA) public editData: any) { }

  ngOnInit(): void {

    this.user = this.userObjectUpdateService.userEmail
 
     this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
     .subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
     })


    this.rangeInfo = this.editData.rangeInfo 
    this.index = this.editData.index 
    console.log(this.rangeInfo, this.index)

  }

  onEmitUpdatedIndexRangeInfos($event){

      console.log('index + rangeInfo => ',$event)
      const newIndex = $event.index
      const newRangeInfo = $event.rangeInfo
      const newIndexRangeInfo: IndexRangeInfo = { index: newIndex , rangeInfo: newRangeInfo }
      this.dialogRef.close(newIndexRangeInfo)
    
  }

  onDeleteIndexRangeInfo($event){
    console.log('deleting index => ',$event)
      const newIndex = $event
      const newRangeInfo = null
      const newIndexRangeInfo: IndexRangeInfo = { index: newIndex , rangeInfo: newRangeInfo }
      this.dialogRef.close(newIndexRangeInfo)
  }

  ngOnDestroy(): void {
    this.updateOnUserEmail?.unsubscribe()
  }

}
