<div class="innerItemFrame2">
    <div class="col-xs-12">

            <div class="row">
                <div class="d-flex justify-content-center align-items-center">
                    <mat-chip 
                             class="center-text" style="background-color:  #3f51b5; color: white; width: 300px">
                             {{startingDate | date: 'EEEE'}} , {{ startingDate | date: 'MMMM' }} {{startingDate | date: 'dd'}} {{ startingDate | date: 'yyyy'}}
                    </mat-chip>
                </div>
            </div>

            <br>

            <div class="row">
                <div class="d-flex justify-content-center align-items-center">
                    <mat-chip 
                              class="center-text" style="background-color:  #3f51b5 ; color: white; width: 300px">
                              {{ endingDate | date: 'EEEE'}} , {{ endingDate | date: 'MMMM' }} {{ endingDate | date: 'dd'}} {{ endingDate | date: 'yyyy'}}
                    </mat-chip>
                </div>
            </div>

            <br>

    </div>

</div>
