import { Component, Input, OnInit } from '@angular/core';
import { FixTextDetail } from '../../../texts/fix-text-list/fix-text-detail.model';
import { RangeInfo } from '../range-info.model';
import { InnerLinkDetail } from './inner-link-detail.model';

@Component({
  selector: 'app-inner-link-item',
  templateUrl: './inner-link-item.component.html',
  styleUrls: ['./inner-link-item.component.css']
})
export class InnerLinkItemComponent implements OnInit {

  @Input() rangeInfo: RangeInfo
  @Input() isEditing: boolean 


  innerLinkDetails: InnerLinkDetail[]

  typeUrl: string 
  typeTelNumber: string
  typeWAMessage: string 
  typeWACall: string
  typeWAVideo: string 

  constructor() { }

  ngOnInit(): void {

    const newTypeUrlDetail = new InnerLinkDetail ('typeUrl', 'www.google.com' )
    const newTypeTelNumber = new InnerLinkDetail ('typeTelNumber', "+39234567890" )
    const newTypeWAMessage = new InnerLinkDetail ('typeWAMessage', "+1234567890")
    const newTypeWACall = new InnerLinkDetail ('typeWACall', "+1234567890" )
    const newTypeWAVideo = new InnerLinkDetail ( 'typeWAVideo' , "+1234567890" )


    this.typeUrl = newTypeUrlDetail.content 
    this.typeTelNumber = newTypeTelNumber.content
    this.typeWAMessage = newTypeWAMessage.content  
    
    this.innerLinkDetails = [ newTypeUrlDetail, newTypeTelNumber, newTypeWAMessage, newTypeWACall, newTypeWAVideo ] 
  }

}
