import { Component, OnInit, Input } from '@angular/core';
import { CommentDetailResult } from './comment-detail-result.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-comment-result',
  templateUrl: './comment-result.component.html',
  styleUrls: ['./comment-result.component.css']
})

export class CommentResultComponent implements OnInit {

  //  commentTextFormResult: FormGroup
  @Input()  commentDetailResult: CommentDetailResult

  commentDetailResultContent: string


 

  constructor() { }

  ngOnInit(){

    this.commentDetailResultContent = this.commentDetailResult.commentDetailResultContent

    console.log(this.commentDetailResult)
 

  }

  //  this.commentTextFormResult = new FormGroup({
  //   textCommentResult: new FormControl ( { value:this.commentDetailResult, disabled:true })
  //   // { value:this.commentDetailResult, disabled:true }
  //   // name : new FormControl (this.editData.name, [Validators.required, Validators.maxLength(30) ] )
  //  })



}

