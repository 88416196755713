import { ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit , ElementRef, ViewChild } from '@angular/core';
import { FormResult } from '../../form-result.model';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { Observable, Subscription } from 'rxjs';
import { FormResultsService } from '../../form-results.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-form-result-items',
  templateUrl: './form-result-items.component.html',
  styleUrls: ['./form-result-items.component.css']
})
export class FormResultItemsComponent implements OnInit, OnDestroy {
  @ViewChild('latestComponent') latestComponent: ElementRef;

  user: string 
  formResults: FormResult[]
  isLoading: boolean = false 

  youAreInFormResultsList: boolean = false 

  updateOnUserEmail: Subscription

  paragraphComponentOffsetTop: number

  constructor( private userObjectUpdateService: UserObjectUpdateService ,
               private formResultsService: FormResultsService,
               private router: Router,
               private route: ActivatedRoute ,
               private cdr: ChangeDetectorRef ,
               private ngZone: NgZone , 
               private breakpointObserver: BreakpointObserver
    
    ) { }

    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  ngOnInit(): void {

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
    .subscribe((updatedUserEmail)=>{
     this.user = updatedUserEmail
     console.log('updated USER = ', this.user)
    });

    this.formResults = []


 
      this.route.params
                .subscribe((params:Params) =>  { 
                  this.isLoading = true 
                  this.formResultsService.setParams(params)
                  this.displayFormItemsAsParams(params)
                })

  };


  displayFormItemsAsParams(params: Params ){
    console.log(params)

  const idsString: string = params['form-result-ids']
  const ids: number[] = idsString.split(',').map(Number) ; // // Convert the string back to an array of numbers
  const storedFormResults: FormResult[] =  this.formResultsService.getFormResultsSortBy_Date_fromLast_toFirst()

  console.log(storedFormResults)


  if ( ids.length > 0 ){ // IF USER IS VALID AND ids are present
    
  
    if ( storedFormResults.length === 0){
      this.router.navigate(['../create-form/oooooooo/personal-paragraphs'])
      this.isLoading = false 
    }

    if ( storedFormResults.length > 0 ){
      this.ngZone.run(() => {
        let newFormResults: FormResult[] = []
          for (let i=0; i<ids.length ; i++){
               newFormResults.push(storedFormResults[ids[i]])
          }
    
          this.formResults = newFormResults 
         });

        console.log("FINAL RESULTS => ", this.formResults )
      
        this.isLoading = false 
        this.cdr.detectChanges();

        // this.formResultsService.onScrollFormItemsParentNavToBottom()

    }
  }
  };

  onClearFormResultList(){
    this.formResultsService.onClearSelectedFormResultsItemsOnIndexList_FromResultItems()
    this.router.navigate(['../results/'], { relativeTo: this.route.parent })
    
  }

  onComponentVisible($event , i){
    console.log($event , i )
  }


  ngOnDestroy(): void {
      this.updateOnUserEmail.unsubscribe()
  }

}
