import { SingleWordDetail } from "./single-word-detail.model";

export class SingleWord {

    constructor ( public _id: string , 
                  public componentName: string , 
                  public UIID: string , 
                  public owner: string ,
                  public dateAndTime: string , 
                  public allUsersAllowed: boolean , 
                  public userListAllowed: string[], 
                  public name: string , 
                  public singleWordDetails: SingleWordDetail[] ){}
}