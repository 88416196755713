import { Component, OnInit, Input , Output, EventEmitter , HostListener, OnDestroy } from '@angular/core';
import { Chat } from '../chat.model';
import { ChatService } from '../chat.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { Recipient } from 'src/app/forms/form-list/form-item/recipient/recipient.model';
import { RecipientService } from 'src/app/forms/form-list/form-item/recipient/recipient.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ChatFillFormFromBottomSheetComponent } from './chat-edit/chat-fill-form/chat-fill-form-from-bottom-sheet/chat-fill-form-from-bottom-sheet.component';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { ChatFillFormMobileCommentComponent } from './chat-edit/chat-fill-form/chat-fill-form-mobile-comment/chat-fill-form-mobile-comment.component';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';

@Component({
  selector: 'app-chat-item',
  templateUrl: './chat-item.component.html',
  styleUrls: ['./chat-item.component.css']
})
export class ChatItemComponent implements OnInit , OnDestroy {

  user: string 
  userExist: boolean = false 

  chat: Chat

  recipient: Recipient

  id: number ;
  _id: string ;
  

  weAreInMobileMode: boolean;
  chatExist: boolean; 

  updateOnUserEmail: Subscription 

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );


  constructor(private breakpointObserver: BreakpointObserver,
              private chatService: ChatService ,
              private recipientService: RecipientService,
              private _bottomSheet: MatBottomSheet ,
              private userObjectUpdateService: UserObjectUpdateService, 
              private router: Router,
              private route: ActivatedRoute) { }


  
  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
      if (document.hidden){
            console.log("Page is hidden");
      } else {
            console.log("Page is visible");
      }
  }

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
    .subscribe((updatedUserEmail)=>{
         this.user = updatedUserEmail

       this.updateUserData(this.user)
       console.log('updated USER = ', this.user)
     })

     if( this.user === null){ this.userExist = false }
     if( this.user !== null){ this.userExist = true }



    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    { console.log('isMobile')} 
    else { console.log( 'isDesktop' )} 

    //  { path: 'selected/:index/:name', component: ChatItemComponent   }

    this.route.params
              .subscribe((params: Params ) => {

                if( this.userExist ){ this.changeChat_id(params)}
                if( !this.userExist ){ this.router.navigate( ['../create-form'], { relativeTo: this.route.parent })  }

                         
              })


  };

  updateUserData(updatedUserEmail: string){

    this.user = updatedUserEmail

    if( this.user === null){ this.userExist = false }
    if( this.user !== null){ this.userExist = true }

  }

  changeChat_id(params: Params){
    console.log(params)

    this._id = params['id']
    this.chat = this.chatService.getChatBy_id(this._id)
    console.log(this.chat)
    this.chatExist = true

    console.log('IS REALODING')
    setTimeout(()=> {
    // this.chatService.sendChatFromChatItemIsDisplayed(this.chat)
    }, 300)
    console.log('SENDING YOU BACK TO PREVIOUS FORM')
    
  }

  onSendFastCommentMobile(){
    console.log('sending fast Mobile comment')
  }

  // onSendFastCommentDesktop(){
  //   console.log('sending fast Desktop comment')
  // }

  onOpenNavBarDrawer(){
    console.log('click to open nav bar drawer')
    this.chatService.sendClickEventToOpenSideNavOnChats()


    // need to change to subscription to chats component 
   // this.openNavBarDrawerParagraphIndexOnMobile.emit()

  }

  onAddCommentToFormResultsMobile(){
    console.log( 'sending regular Mobile comments')
    this._bottomSheet.open( ChatFillFormMobileCommentComponent  , 
                                                        { data: {
                                                          chat: this.chat , 
                                                          recipient: this.recipientService.getRecipientByChat_id(this.chat._id) } })
  }

  onAddCommentToFormResultsDesktop(){
    console.log( 'sending regular Desktop comments')
  }

  ngOnDestroy(): void {
      this.updateOnUserEmail.unsubscribe()
  }




}
