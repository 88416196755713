import { Badges } from "./badges.model";

export class HeaderBadger {

    constructor ( public drappBadges:     Badges,
                  public paragraphBadges: Badges ,
                  public formBadges:      Badges,
                  public resultBadges:    Badges,
                  public groupBadges:     Badges,
                  public loginBadges:     Badges   ){}
}

