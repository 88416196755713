import { Injectable , EventEmitter} from '@angular/core';
import { WordLessInput } from './word-less-input.model';
import { WordLessDetail } from './word-less-detail.model';
import { Observable } from 'rxjs';


@Injectable ({
    providedIn: 'root' 
})

export class WordLessInputService {


private wordsLessInput : WordLessInput[] = [
   new WordLessInput ( '0' , 'antoniohhd@gmail.com', null,
       'first easy input' , 
       [ 
           new WordLessDetail ('city' , 'Vicenza' ),
           new WordLessDetail ('address', 'Via ciao'),
           new WordLessDetail ('interno', 'ex.: 3rd floor unit 3'), 
        //    new Detail ('n.', 'ex.: A3 '), 
        //    new Detail ('address', 'Via ciao' ),

       ]
   ),

   new WordLessInput ( '1' , 'antoniohhd@gmail.com', null,
       'secondo input',
       [
           new WordLessDetail ('saluto', 'ciao'),
       ]
   ),

   new WordLessInput ( '2' , 'antoniohhd@gmail.com', null,
       'third input ',
       [
           new WordLessDetail ('ciao', 'ciao' ),
           new WordLessDetail ('ancora ciao', 'saluto' ),
       ]
   )
    ];


    buildInitialDefaultComponent(newUserEmail: string){

        const new_id = null ;
        const newOwner = newUserEmail ; 
        const newDateAndTime = null ;
        const newName =  '1st word simple' ;
        const newLabel = 'edit label';
        const newPlaceholder = 'edit placeholder';
       
    
        const newDetail =  new WordLessDetail (newLabel, newPlaceholder) ;
    
        const newDetails =  [newDetail] ;
        const newWordLessInput = new WordLessInput ( new_id, newOwner, newDateAndTime, newName , newDetails);
    
        console.log(newWordLessInput)
        //this.wordLessInputService.onAddNew(newWordLessInput);
    }



    getWordLessInputs(){
        return this.wordsLessInput;
    }

    getWordLessInput(index:number){
        return this.wordsLessInput[index];
    }

    onAddNew( wordLessInput: WordLessInput){
        this.wordsLessInput.push(wordLessInput)
      }

    

}