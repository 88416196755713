<div *ngIf="isLoading">
    <div *ngFor="let item of loadingItems">
        <div class="example-box">
            <div class="row" style="height: 150px;"></div>

        </div>
    </div>
</div>

<div *ngIf="!isLoading">
<div  *ngIf="!chatsExist">
          <div class="col-xs-12">
            <div class="row">
                <br>
                <br>
                <br>
                <br>
            </div>
            <div class="row">
              
            
                    <div class="row">
                            <h1 align="center" style="color: #3f51b5"> no content yet</h1>
                    </div>
              
              
            </div>
        </div>
</div>

<div *ngIf="chatsExist">
    <div *ngFor="let innerChatFormResult of innerChatFormResults; let i=index">
        <app-chat-item-index 
         (click)="onSelectedChat(i)"
         [innerChatFormResultNumbers]="innerChatFormResult"
         >
        </app-chat-item-index>
        <!-- [innerChatFormResultNumbers]="chatAndInnerFormResults.innerChatFormResults[i]" -->
       <!-- [routerLink]="recipient._id" -->
    </div>
</div>
</div>



