import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ChatService } from 'src/app/chats/chat.service';
import { DragDropService } from 'src/app/forms/dragdrop.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { Recipient } from '../../../form-item/recipient/recipient.model';
import { RecipientService } from '../../../form-item/recipient/recipient.service';
import { RecipientIndexColumn } from '../../recipient-index-list/recipient-index-column.model';
import { RecipientIndex } from '../../recipient-index-list/recipient-index.model';
import { RecipientIndexService } from '../../recipient-index-list/recipient-index.service';
import { Sender } from 'src/app/forms/form-results/form-results-list/form-result-item/sender/sender.model';
import { PartecipantGroup } from 'src/app/chats/partecipants.model';
import { Chat } from 'src/app/chats/chat.model';
import { RecipientIndexListItem } from '../../recipient-index-list/recipient-index-list-item.model';
import { RecipientIndexListItemService } from '../../recipient-index-list/recipient-index-list-item.service';
import { RecipientIndexColumnService } from '../../recipient-index-list/recipient-index-column.service';
import { Subscription } from 'rxjs';
import { CreateUpdateMultiComponentsService } from 'src/app/services/shared-services/create-update-multi-components.service';
import { RecipientFeatureService } from '../../recipient-index-list/recipient-feature.service';
import { UpdateRecipientFeatureService } from 'src/app/services/updates-on-working-app/update-recipient-feature.service';
import { StorePersonalFeatureIndexComponentsService } from 'src/app/services/store-initial-components/store-personal-feature-index-components.service';
import { GetComponentsHttpRequestService } from 'src/app/services/gets-services/get-components-http-request.sevice';
import { DatePipe, Location } from '@angular/common';
import { Router } from '@angular/router';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';


@Component({
  selector: 'app-recipient-index-column-item',
  templateUrl: './recipient-index-column-item.component.html',
  styleUrls: ['./recipient-index-column-item.component.css']
})
export class RecipientIndexColumnItemComponent implements OnInit , OnDestroy {



  @Input() recipientIndexColumn: RecipientIndexColumn


  user : string 
  recipientIndexColumn_exist: boolean = true 

  recipientIndexForm: FormGroup ;

  recipientIndex : RecipientIndex;
  recipientIndexes: RecipientIndex[];
  recipientIndexListItem: RecipientIndexListItem

  recipient: Recipient;
  newlyCreatedRecipient: Recipient;
  recipients :Recipient[];

  @Output() updateRecipientIndex = new EventEmitter<any>()
  
  @Input() youAreInFormEdit: boolean
  
  hideRecipientIndexes = true ;
  recipientTopic = 'my recipients';

  hideButton = false ;

  topicsLimit = 10

  setTimer: any ;

//  trialRecipientIndexes: RecipientIndex[]
  isLoading : boolean 
  error = false 

  nameResult: string 

  updateOnUserEmail: Subscription
  updateNonExistingRecipientColumn: Subscription
  updateExistingSharedRecipientColumn_Exist: Subscription 

  resetRecipientIndexColumnSharedRecipientsSubscription: Subscription 

  constructor( private recipientIndexService: RecipientIndexService ,
               private recipientService: RecipientService,
               private DragDrop : DragDropService ,
               private storePersonalFeatureIndexComponentsService: StorePersonalFeatureIndexComponentsService ,
               private createUpdateMultiComponentsService: CreateUpdateMultiComponentsService , 
               private recipientFeatureService: RecipientFeatureService , 
               private chatService: ChatService,
               private getComponentsHttpRequestService: GetComponentsHttpRequestService ,
               private userObjectUpdateService: UserObjectUpdateService ,
               private recipientIndexListItemService: RecipientIndexListItemService , 
               private recipientIndexColumnService : RecipientIndexColumnService , 
               private updateRecipientFeatureService: UpdateRecipientFeatureService ,
               private datePipe: DatePipe , 
               private router: Router , 
               private location: Location,
               private initialNullSetupService: InitialNullSetupService
    ) { }

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
     .subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
     })

    this.isLoading = false 


    /**
     * need to add subscription to update status of recipientIndexColumn_exist once recipientFeature change 
     */
    if ( this.recipientIndexColumn.type === 'shared' && this.recipientFeatureService.recipientFeatures[0].receivedRecipient_ids.length === 0 ){
         this.recipientIndexColumn_exist = false 
    } 

    /**
    *  sort the recipientIndexes array in reverse order 
    */
    // this.recipientIndexColumn.recipientIndexListItem_ids
    // this.recipientIndexColumn.recipientIndexListItem_ids.reverse()


    this.recipientIndexes = this.recipientIndexService.getRecipientIndexes() ;


    /**
     *   this.recipientIndexes = this.sharedRecipientIndexService.getRecipientIndexes() ;
     * 
     * need to change this and all the functions that are connected to add a new recipient
     */

    this.recipientIndexForm = new FormGroup({
      recipientIndexName: new FormControl ('') ,   
    });

    this.updateNonExistingRecipientColumn = this.updateRecipientFeatureService.updateShareRecipientColumnNotExistingNow
    .subscribe(()=>{

      this.turnSharedColumnTo_NonExisting()
    })

    this.updateExistingSharedRecipientColumn_Exist = this.updateRecipientFeatureService.updateSharedRecipientColumn_Exist
    .subscribe(()=>{
      this.turnSharedColumnTo_Exist()
    })

    this.resetRecipientIndexColumnSharedRecipientsSubscription = this.recipientIndexColumnService.resetRecipientIndexColumnSharedRecipients 
    .subscribe(()=>{

      
      this.recipientIndexColumn_exist = false 
      setTimeout(() => {
        this.recipientIndexColumn_exist = true 
      }, 250);

    })


  };

    /**
     * when suddenly we are thrown out of all chats 
     */
    turnSharedColumnTo_NonExisting(){
      if( this.recipientIndexColumn.type === 'shared' ){
        this.recipientIndexColumn_exist = false 
        console.log( 'we are not in other people chats')
      }
    }

    /**
     * when suddenlly or confirm that we are added to other people chats 
     */
    turnSharedColumnTo_Exist(){
      if( this.recipientIndexColumn.type === 'shared' ){
        this.recipientIndexColumn_exist = true 
        console.log( 'we are in some people chats ')
      }
    }




    onUpdateRecipientIndex($event){
     
      console.log($event )
    
      if (this.youAreInFormEdit === true) {  
        this.updateRecipientIndex.emit($event);
        this.hideRecipientIndexes = !this.hideRecipientIndexes ;
     };

      if (this.youAreInFormEdit === false ) { 
         this.recipientIndexService.sendRecipientIndexToFormItem( $event.newRecipientIndex , $event.newRecipientIndexListItem  )
         this.hideRecipientIndexes = !this.hideRecipientIndexes 
         this.updateRecipientIndex.emit($event)
        }
    }

  onHideButton(){
    this.hideButton = !this.hideButton;

    this.clearTimer()
    
    this.setTimer = setTimeout(() => {
      this.hideRecipientIndexes = true ;
    }, 60000);

    /** close button in 20 seconds */
    setTimeout(() => {
      this.hideButton = false ;
      this.recipientIndexForm = new FormGroup({
        recipientIndexName: new FormControl ('') ,   
          });
    }, 60000);
            
  }

  clearTimer(){
    clearTimeout(this.setTimer)
  }


  onHideRecipientIndexes(){
    this.hideRecipientIndexes = !this.hideRecipientIndexes

      this.clearTimer()
    
     this.setTimer = setTimeout(() => {
        this.hideRecipientIndexes = true ;
      }, 60000);
    
    

  };

  /** adding a new recipientIndexGroup to the list */

// HERE I HAVE TO CREATE FIRST THE NEW RECIPIENT AND CHAT, 
// AND RIGHT AFTER I USE THE DATA TO CREATE THE RECIPIENTINDEX WITH RECIPIENT IDS  
// OTHERWISE I WON'T KNOW THE RECIPIENTINDEX_ID 
// ALSO I HAVE TO BUILD THE CHAT COMPONENT WITH THE RECIPIENT 
onSubmit(){

  this.isLoading = true 

  /**
   * call the updated http recipient list first to see if we have the latest recipients numbers
   * probably have to call also all the other featureIndexes to check the rec ipient index and indexListItem
   */
  

  this.nameResult = this.recipientIndexForm.value.recipientIndexName 

  if ( this.user !== null ){ this.checkLatestRecipientFeaturePossibleUpdates(this.nameResult)  }
  if ( this.user === null ){ this.createNewRecipientIndexThenRecipientAndChat_Null_User() }
 

};

checkLatestRecipientFeaturePossibleUpdates(recipientIndexListItemName: string ){

  const currentAvailableRecipientIndexes: RecipientIndex[] = this.recipientIndexService.getRecipientIndexes()

  this.storePersonalFeatureIndexComponentsService.getPersonalUpdatedFeatureIndex_components(this.user)
      .subscribe((res: any)=>{
      console.log(res)

      const httpFeatureIndexComponentResult: any = res 
      const newRecipientIndexes: RecipientIndex[] = httpFeatureIndexComponentResult.recipientIndex 

      if ( currentAvailableRecipientIndexes.length === newRecipientIndexes.length ){ this.createNewRecipientIndexThenRecipientAndChat_User(recipientIndexListItemName) }

      if ( currentAvailableRecipientIndexes.length !== newRecipientIndexes.length ){
          /**
           * check recipinetIndexListItems
           * check recipientColumns
           * call recipients to add latest recipients 
           */
          const newArrayRecipientIndexColumns: RecipientIndexColumn[] = httpFeatureIndexComponentResult.recipientIndexColumn
          const newArrayRecipientIndexListItems: RecipientIndexListItem[] = httpFeatureIndexComponentResult.recipientIndexListItem 

          this.getComponentsHttpRequestService.httpGetComponentsByType(this.user, 'recipient')
              .subscribe((updatedRecipientResult: any )=>{

                  const updatedRecipientList: Recipient[] = updatedRecipientResult.recipient 

                  this.recipientIndexColumnService.updateRecipientIndexColumns(newArrayRecipientIndexColumns)
                  this.recipientIndexService.updateRecipientIndexes(newRecipientIndexes)
              
                  console.log('UPDATING ALL RECIPIENT INDEX COLUMNS/ LISTITEMS ETC ')
              
                  for (let i=0; i< updatedRecipientList.length ; i++){
                       this.recipientService.addRecipient(updatedRecipientList[i])
                  }
                  /**
                   * only update personal recipientIndexListItems , leave shared as it is 
                   * 
                  */
                 const indexOfSharedRecipientIndexListItem: number = newArrayRecipientIndexListItems.findIndex(x=> x.UIID === 'sharedRecipientIndexListItem-2')
                 const currentSharedRecipientIndexListItem: RecipientIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItemBy_UIID('sharedRecipientIndexListItem-2')

                 newArrayRecipientIndexListItems[indexOfSharedRecipientIndexListItem] = currentSharedRecipientIndexListItem 

                 this.recipientIndexListItemService.updateRecipientIndexListItems(newArrayRecipientIndexListItems)

                  this.createNewRecipientIndexThenRecipientAndChat_User(recipientIndexListItemName)


              }) // END "updatedRecipientResult" subscription 

            } // END IF "currentAvailableRecipientIndexes.length !== newRecipientIndexes.length" STATEMENT

          }) // END very "getPersonalUpdatedFeatureIndex_components" Subscription 

            
}
/**
 * first call check if the recipientIndexListItems we have in store are the latest ones 
 */
createNewRecipientIndexThenRecipientAndChat_User( recipientIndexListItemName: string  ){



  const newRecipientUIID_length = this.recipientIndexService.getRecipientIndexes().length+1
  const newRecipientUIID: string = 'recipient-'+(newRecipientUIID_length).toString()

  const newRecipientIndexesUIID_length = this.recipientIndexService.getRecipientIndexes().length+1
  const newRecipientIndexUIID: string = 'recipientIndex-'+(newRecipientIndexesUIID_length).toString()

  const newRecipientIndexListItemsUIID_length = this.recipientIndexListItemService.getRecipientIndexListItems().length+1 
  const newRecipientIndexListItemsUIID: string = 'recipientIndexListItem-'+(newRecipientIndexListItemsUIID_length).toString()

  const newRecipientIndexColumn: RecipientIndexColumn = this.recipientIndexColumnService.getRecipientIndexColumnBy_type('personal')

  this.createUpdateMultiComponentsService
      .create_Recipient_RecipientIndex_RecipientIndexListItem_Update_RecipientIndexColumn( this.user ,
                                                                                           recipientIndexListItemName,
                                                                                           newRecipientUIID,
                                                                                           newRecipientIndexUIID,
                                                                                           newRecipientIndexListItemsUIID,
                                                                                           newRecipientIndexColumn
                                                                                          )
      .subscribe((res: any)=> {
        console.log(res)

        const newRecipient: Recipient = res.recipient[0]
        const newRecipientIndex: RecipientIndex = res.featureIndex[0]
        const newRecipientIndexListItem: RecipientIndexListItem = res.featureIndex[1]
        const newRecipientIndexColumn: RecipientIndexColumn = res.featureIndex[2]

        this.recipientService.addRecipient(newRecipient)
        this.initialNullSetupService.initialNullRecipients.push(newRecipient)

        this.recipientIndexService.addRecipientIndex(newRecipientIndex)
        this.initialNullSetupService.initialNullRecipientIndexes.push(newRecipientIndex)

        this.recipientIndexListItemService.addRecipientIndexListItem(newRecipientIndexListItem)
        this.initialNullSetupService.initialNullRecipientIndexListItems.push(newRecipientIndexListItem)

        this.recipientIndexColumnService.updateRecipientIndexColumn(newRecipientIndexColumn)
        const indexOfrecipientIndexListColumn = this.initialNullSetupService.initialNullRecipientIndexColumns.findIndex(x=> x._id === newRecipientIndexColumn._id)
        this.initialNullSetupService.initialNullRecipientIndexColumns[indexOfrecipientIndexListColumn] = newRecipientIndexColumn

 

        this.recipientIndexColumn = newRecipientIndexColumn

          /** re-set the parameters  */
           this.recipientIndexForm = new FormGroup({
           recipientIndexName: new FormControl ('') ,   
           });

           if ( this.youAreInFormEdit ){ 
                const isEditMode = this.isEditMode()
                if (!isEditMode){this.changeUserUrlParams(newRecipientIndex)}
          }
          
           this.onHideButton()
           this.isLoading = false 
           this.onSelectRecipientIndex(newRecipientIndex._id , newRecipientIndexListItem._id)


      })
	
};

isEditMode(): boolean {
  let isEditMode: boolean 
  
            var url = window.location.href;
            // Define a regular expression pattern to match "create-form" or "edit-form"
            var pattern = /(create-form|edit-form)/;
           // Extract the path from the URL
            var match = url.match(pattern);
        
          if (match) {
              console.log("Found:", match[0]);
              if (match[0] === 'create-form'){ isEditMode = false }
              if (match[0] === 'edit-form'){ isEditMode = true }
        
            } else {
               console.log("Not found");
           }
  return isEditMode
}

createNewRecipientIndexThenRecipientAndChat_Null_User(){

    const new_recipient_id = 'recipi-'+(this.recipientService.getRecipients().length+1).toString()
    const newComponentName = 'recipient'
    const newUIID = 'recipient-'+(this.recipientService.getRecipients().length+1).toString()

const newChat_roomid = (this.chatService.getChats().length+1).toString()
const newBlockedUserList = [];
const newName = 'recipient-1'
const newRecipientList = []
const newToMessage = [];
const newToMail = [];
const newCcmail = [];
const newBccMail = [];
const newGreeting = '' ;

const newRecipient = new Recipient ( new_recipient_id, newComponentName, newUIID, this.user, null , null, newChat_roomid,
                                   false, [], newBlockedUserList ,newName, newRecipientList,  newToMessage, 
                                   newToMail, newCcmail, newBccMail , newGreeting);




const newChatSender = new Sender ( this.user) 
const newPartecipants = []
const newPartecipantGroup = new PartecipantGroup (newChatSender, newPartecipants) ;
console.log(newPartecipantGroup)
const new_id2 = 'chat-'+(this.chatService.getChats().length+1).toString()
const newChatAllUsersAllowed = false ;
const newChatUserListAllowed = []; 
const newIsVisible = false ;
const localDateNow = new Date()
const localDateNow_String = this.datePipe.transform( localDateNow, "yyyy-MM-ddTHH:mm:ss")
const newChatRecipient = newRecipient
const newIsNewChat = true
const newIsBeenUpdated = false
const newIsSelected = false ; 
const newSubject = ''

const newChat = new Chat (  new_id2, this.user, newChatAllUsersAllowed, newChatUserListAllowed, newIsVisible, localDateNow_String, 
                            localDateNow_String, newIsNewChat, newIsBeenUpdated, newIsSelected, newSubject  )

console.log(newChat)
console.log(newChatRecipient)


  this.recipientService.addRecipient(newRecipient)
  this.chatService.addChat(newChat)

  this.recipients = this.recipientService.getRecipients(),
  console.log('recipisnts list = ', this.recipients, 'chats list = ', this.chatService.getChats())


  const newRecipientIndex_id = 'recipientIn-'+(this.recipientIndexService.getRecipientIndexes().length+1).toString()
  const newComponentName1= 'recipientIndex'
  const newUIID1 = 'recipientIndex-'+ (this.recipientIndexService.getRecipientIndexes().length+1).toString()
  const newOwner = this.user
  const newAllUsersAllowed = false;
  const newUserListAllowed = [];
  const newDateAndTime = null ;
  const newIsAccepted = true 
  const newUseMyOwnMailRecipient = true
  const newDropId = 0 ;
  const newRecipient_id = newRecipient._id
  const newMailRecipient_idCopy = null


  const newRecipientIndex = new RecipientIndex (newRecipientIndex_id, newComponentName1, newUIID1, newOwner , newAllUsersAllowed, newUserListAllowed,
                                                newDateAndTime , newIsAccepted,newUseMyOwnMailRecipient, newDropId, newRecipient_id, newMailRecipient_idCopy  )

 
    this.recipientIndexService.recipientIndexes.push(newRecipientIndex)

    console.log(this.recipientIndexes)

    const new_id3 = 'recipientIndexListItem-'+(this.recipientIndexListItemService.getRecipientIndexListItems().length+1).toString()
    const newComponentName2 =  'recipientIndexListItem'
    const newUIID2 = 'recipientIndexListItem-'+(this.recipientIndexListItemService.getRecipientIndexListItems().length+1).toString()
    const newOwner2 = this.user
    const newAllUsersAllowed2 = false
    const newUserListAllowed2 = []
    const newDateAndTime2 = null 
    const newRecipientIndexListName2 = this.nameResult
    const newRecipientIndexList_ids2 = [newRecipientIndex._id]
   

    const newRecipientIndexListItem = new RecipientIndexListItem ( new_id3, newComponentName2, newUIID2, newOwner2, newAllUsersAllowed2, newUserListAllowed2 , 
                           newDateAndTime2, newRecipientIndexListName2, newRecipientIndexList_ids2, newRecipientIndex._id )
        
    this.recipientIndexListItemService.recipientIndexListItems.push(newRecipientIndexListItem)
  
    const new_id4 = this.recipientIndexColumn._id
    const newComponentName4 = this.recipientIndexColumn.componentName
    const newUIID4 = this.recipientIndexColumn.UIID 
    const newOwner4 = this.user
    const newAllUsersAllowed4 = this.recipientIndexColumn.allUsersAllowed
    const newUserListAllowed4 = this.recipientIndexColumn.userListAllowed
    const newDateAndTime4 = this.recipientIndexColumn.dateAndTime
    const newType4 = this.recipientIndexColumn.type
    const newName4 = this.recipientIndexColumn.name
    const newRecipientIndexListItem_ids = this.recipientIndexColumn.recipientIndexListItem_ids

    newRecipientIndexListItem_ids.push(newRecipientIndexListItem._id)


    const newRecipientIndexColumn = new RecipientIndexColumn (new_id4, newComponentName4, newUIID4, newOwner4, newAllUsersAllowed4, newUserListAllowed4, 
                                 newDateAndTime4, newType4, newName4, newRecipientIndexListItem_ids ) 

    this.recipientIndexColumn = newRecipientIndexColumn


    this.recipientIndexColumnService.recipientIndexColumns[this.recipientIndexColumnService.getRecipientIndexColums().findIndex(x=> x._id === this.recipientIndexColumn._id)] = newRecipientIndexColumn

    console.log(this.recipientIndexColumn._id)

  console.log(this.recipientIndexService.getRecipientIndexes());
  console.log(this.recipientService.getRecipients())

  
  if (this.youAreInFormEdit){
    const isEditMode = this.isEditMode()
    if (!isEditMode){ this.changeNullUserUrlParams(newRecipientIndex)  }
    
    }
  
  /** re-set the parameters  */
  this.recipientIndexForm = new FormGroup({
    recipientIndexName: new FormControl ('') ,   
      });
  this.onHideButton()


 this.isLoading = false 

 this.onSelectRecipientIndex(newRecipientIndex._id , newRecipientIndexListItem._id)


}

changeUserUrlParams(recipientIndex: RecipientIndex){

 
  this.recipientIndex = recipientIndex
  const newRecipientIndex_id: string = this.recipientIndex._id ;

  const base = 'recipientIndex-';
  const extractedString = newRecipientIndex_id.split(base)[1];
  console.log(extractedString);

  const currentUrl = this.location.path();
  console.log(currentUrl)
  const lastIndex = currentUrl.lastIndexOf('/');
  const extractedString2 = currentUrl.substring(lastIndex + 1);

  console.log(extractedString2);

  this.router.navigate([ 'create-form', extractedString, extractedString2 ] )

}

changeNullUserUrlParams(recipientIndex: RecipientIndex){



  this.recipientIndex = recipientIndex
  const newRecipientIndex_id: string = this.recipientIndex._id ;

  const base = 'recipientIn-';
  const extractedString = newRecipientIndex_id.split(base)[1];
  console.log(extractedString);

  const currentUrl = this.location.path();
  console.log(currentUrl)
  const lastIndex = currentUrl.lastIndexOf('/');
  const extractedString2 = currentUrl.substring(lastIndex + 1);

  console.log(extractedString2);

  this.router.navigate([ 'create-form', extractedString, extractedString2 ] )
}



onSelectRecipientIndex(recipientIndex_id: string , recipientIndexListItem_id: string){

  const newRecipientIndex: RecipientIndex = this.recipientIndexService.getRecipientIndexBy_id(recipientIndex_id)
  const newRecipientIndexListItem: RecipientIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItemBy_id(recipientIndexListItem_id)


  if (this.youAreInFormEdit === true) {  this.updateRecipientIndex.emit( { newRecipientIndex , newRecipientIndexListItem } );
                                         this.hideRecipientIndexes = !this.hideRecipientIndexes ;
                                      };
  
  if (this.youAreInFormEdit === false ) { this.recipientIndexService.sendRecipientIndexToFormItem( newRecipientIndex, newRecipientIndexListItem )
                                          this.hideRecipientIndexes = !this.hideRecipientIndexes 
                                          this.updateRecipientIndex.emit({newRecipientIndex , newRecipientIndexListItem })
                                         }
                                         

}

ngOnDestroy(): void {
    this.updateOnUserEmail.unsubscribe()
    this.updateNonExistingRecipientColumn.unsubscribe()
    this.updateExistingSharedRecipientColumn_Exist.unsubscribe()
    this.resetRecipientIndexColumnSharedRecipientsSubscription?.unsubscribe()
}

}
