<div class="innerItemFrame2">
  <div class="col-xs-12">

    <app-single-loading-spinner *ngIf="debounceHasStarted"></app-single-loading-spinner>

  <form #form="ngForm" >
    <section>

      <!-- <div *ngFor="let detail of rangeInfo.rangeDetails; let i=index"> -->
      <div *ngFor="let detail of copyOfDetails; let i=index">
        <div class="checkbox-row">
          <p>
            <input type="checkbox" 
                   [(ngModel)]="detail.selected" 
                   [name]="'checkbox-' + i"
                   (ngModelChange)="onCheckboxChange($event, i)" 
                   >
            <!-- [disabled]="isDisabled" -->
                 
            {{ detail.name }}
          </p>
         
        </div>
      </div>
      
    </section>
  
  </form>
  


  </div>
</div>
  
