<!-- <p>form-start works!</p> -->


        <div class="col-12">


<!-- ------------------------------------ ROW DISTANCE ---------------------------------- -->
<div class="row" style="height: 40px;">

</div>
<!-- ------------------------------------ ROW DISTANCE ---------------------------------- -->

<!-- ------------------------------------ PHONE ROW -------------------------------- -->


<div class="col-12"  *ngIf="isHandset$ | async" >


                <div class="row" style="height: 40px"></div>

                <!-- <div class="d-flex justify-content-center">
                        <app-phone-rapresentation [messagePhone]="messagePhone"></app-phone-rapresentation>
                </div> -->

                <div>
                <div class="row footer">
                    <div class="col">
                        <div class="d-flex justify-content-center align-items-center" >
                            <h1 (click)="onOpenFormListSideNav_FromStartComponent()" align="center" style="color: #3f51b5" *ngIf="isHandset$ | async" >
                                <svg  style="color: #3f51b5" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                                    <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
                                  </svg>
                                 select a web page
                            </h1>
                        </div>
                    </div>
                    <div class="row" style="height: 60px;">
                </div>
               
                    <a align="end" > Code licensed under an MIT-style License.</a>
                    <!-- href="https://github.com/angular/components/blob/main/LICENSE" -->
                </div>
                </div>
</div>

<!-- ------------------------------------ END PHONE ROW -------------------------------- -->


<!-- ------------------------------------ DESKTOP ROW -------------------------------- -->

    <div *ngIf="!(isHandset$ | async)" class="col-12">

        <div class="row" style="height: 226px;">
            
        </div>

    </div>
<!-- </div> -->

<!-- ------------------------------------END DESKTOP ROW -------------------------------- -->
           
<!-- ------------------------------------ ROW DISTANCE ---------------------------------- -->
<div class="row" style="height: 50px;">

</div>
<!-- ------------------------------------ ROW DISTANCE ---------------------------------- -->

            <!-- </div> -->

        </div>
 
