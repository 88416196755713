import { Component, OnInit, Inject } from '@angular/core';
import { FixLayout } from "../fix-layout.model"
import { FixLayoutTableTD } from '../fix-layout-table-td.model';
import { FixLayoutService} from "../fix-layout.service"
import { FormGroup, FormControl, FormBuilder, Validators, FormArray} from '@angular/forms';
import { MatDialogRef , MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-fix-layout-edit-description',
  templateUrl: './fix-layout-edit-description.component.html',
  styleUrls: ['./fix-layout-edit-description.component.css']
})
export class FixLayoutEditDescriptionComponent implements OnInit {

fixLayoutEditDescriptionForm: FormGroup

i = this.editData.indexI
j = this.editData.indexJ
editFixLayout: FixLayout
editFixLayoutTableTD: FixLayoutTableTD

label: string
description: string


  constructor( public dialogRef: MatDialogRef<FixLayoutEditDescriptionComponent>,
               @Inject(MAT_DIALOG_DATA) public editData: any) { }



  ngOnInit(): void {

    this.editFixLayout = this.editData.fixLayout
    console.log(this.editFixLayout)

    this.editFixLayoutTableTD = this.editFixLayout.fixLayoutTableTRs[this.i].fixLayoutTableTDs[this.j]
    console.log(this.editFixLayoutTableTD)

    this.label = this.editFixLayout.fixLayoutTableTRs[this.i].fixLayoutTableTDs[this.j].tableTDDetailDescriptionLabel
    this.description = this.editFixLayout.fixLayoutTableTRs[this.i].fixLayoutTableTDs[this.j].tableTDDetailDescription

    this.fixLayoutEditDescriptionForm = new FormGroup ({
      label: new FormControl (this.label),
      description: new FormControl (this.description)
    })

  }

  getWidth(i: number, j:number){
    return this.editFixLayout.fixLayoutTableTRs[i].fixLayoutTableTDs[j].tableTDDetailWidth
  }

  onSubmitDescription(){
    console.log(this.fixLayoutEditDescriptionForm)
    if (this.fixLayoutEditDescriptionForm.invalid){
      return
    }
    this.dialogRef.close(this.fixLayoutEditDescriptionForm.value),
    this.editData.value

  }

}
