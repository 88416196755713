import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ComponentsPreviewsComponent } from "./components-previews.component";
import { WordInputPreviewComponent } from "./word-input-preview/word-input-preview.component";
import { MatButtonModule } from "@angular/material/button";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialDesignModule } from "src/app/material-design/material-design.module";
import { SharedModule } from "src/app/shared/shared.module";
import { FixTextPreviewComponent } from './fix-text-preview/fix-text-preview.component';
import { MultiFeaturesPreviewComponent } from './multi-features-preview/multi-features-preview.component';
import { SingleSliderPreviewComponent } from './single-slider-preview/single-slider-preview.component';
import { DropdownsPreviewComponent } from './dropdowns-preview/dropdowns-preview.component';


@NgModule ({
    declarations: [
        ComponentsPreviewsComponent , 
        WordInputPreviewComponent, 
        FixTextPreviewComponent, 
        MultiFeaturesPreviewComponent, 
        SingleSliderPreviewComponent, 
        DropdownsPreviewComponent
    ],

    exports:[
        ComponentsPreviewsComponent,
        WordInputPreviewComponent,
        FixTextPreviewComponent, 
        MultiFeaturesPreviewComponent, 
        SingleSliderPreviewComponent, 
        DropdownsPreviewComponent
    ],

    imports:[
        MatButtonModule , 
        ReactiveFormsModule, 
        LayoutModule,
        CommonModule,
        FormsModule,
        MatDialogModule,
        MatExpansionModule,
        MatIconModule, 
        MatListModule,
        MatSidenavModule,
        MatToolbarModule,
        BrowserAnimationsModule,
        MaterialDesignModule,
        SharedModule













    ]

})


export class ComponentsPreviewsModule {}