import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

interface InputLabelInterface{

  exist: boolean ,
  name: string,
  maxLength: number,
  errorHandler: boolean 
}


@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.css']
})
export class NumberInputComponent implements OnInit , OnDestroy {

  editNumberName: FormGroup

  @Input() inputLabelInterface: InputLabelInterface
  @Input() inputName: string 

  changingValuesSubscription: Subscription 

  @Output() updatedNameEvent = new EventEmitter<string>()
  @Output() updateNameInputValidity = new EventEmitter<boolean>()

  filteredOptions: Observable<string[]>;
  contactOptions: string[]

  @Input() isEmptyingInputNameAfterAdded: Observable<void>
  emptyInputNameAfterAddedSubscription: Subscription 


  constructor( ) {}

  ngOnInit(): void {

    this.editNumberName = new FormGroup({
      name : new FormControl ( this.inputName , [ Validators.required, Validators.maxLength(this.inputLabelInterface.maxLength) ] )
    })
  

    this.changingValuesSubscription = this.editNumberName.valueChanges.subscribe(
      (value) => {
        console.log(value)
        this.onUpdateNameEvent(value)
      }
      );

    // console.log(this.inputName)

    this.emptyInputNameAfterAddedSubscription = this.isEmptyingInputNameAfterAdded
        .subscribe(()=> {
          this.onEmptyEditFormName()
        })

    
  }

  onUpdateNameEvent(updatedname: string){
    this.updatedNameEvent.emit(updatedname)
    if ( this.editNumberName.valid ) { this.updateNameInputValidity.emit(true) }
    if ( !this.editNumberName.valid ){ this.updateNameInputValidity.emit(false) }

  }

  onSubmitName(){
    console.log(this.editNumberName.value.name )
  }

  onEmptyEditFormName(){

    this.ngOnInit()
    this.updateNameInputValidity.emit(false)
 }

  ngOnDestroy(): void {
    this.changingValuesSubscription.unsubscribe()
    this.emptyInputNameAfterAddedSubscription.unsubscribe()
  }

}
