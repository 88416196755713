import { Injectable } from "@angular/core";
import { Chat } from "./chat.model";
import {  Subject, Observable, Subscription } from "rxjs";
import { ChatHttpRequestService } from "../services/post-and-put-services/chat-http-request.service";
import { RecipientService } from "../forms/form-list/form-item/recipient/recipient.service";
import { InitialNullSetupService } from "../services/starting-services/initial-null-setup.service";
import { StorePersonalChatRoomComponentsService } from "../services/store-initial-components/store-personal-chat-room-components.service";
import { GetComponentsHttpRequestService } from "../services/gets-services/get-components-http-request.sevice";
import { FormResult } from "../forms/form-results/form-result.model";
import { Recipient } from "../forms/form-list/form-item/recipient/recipient.model";

interface ChatAndInnerFormResults{
    numberOfItems: number ,
    innerChatFormResults: InnerChatFormResultNumbers[]
}

interface InnerChatFormResultNumbers{
    chat: Chat,
    isSelected: boolean ,
    innerChatFormResults: FormResult[]
}

interface SelectedChat {
    aChatHasBeenSelected: boolean ,
    recipientSelected: Recipient 
}


@Injectable ({
    providedIn: 'root'
})

export class ChatService {


    constructor( private chatHttpRequestService: ChatHttpRequestService ,
                 private recipientService: RecipientService ,
                 private initialNullSetupService: InitialNullSetupService , 
                 private storePersonalChatRoomComponentsService: StorePersonalChatRoomComponentsService , 
                 private getComponentsHttpRequestService: GetComponentsHttpRequestService
                 ){}

    selectedChat: SelectedChat = { aChatHasBeenSelected: false , recipientSelected: null }

    chats: Chat[] = this.initialNullSetupService.initialNullChats

    updateInitialHttpDataFromChatsComponentsService: Subscription = this.storePersonalChatRoomComponentsService.chatRoomChanged
                                                   .subscribe((res)=> {
                                                    this.chats = res
                                                    console.log('updated personal chats = ', res)
                                                   })

    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.chatsChangedBackToNull
                                                     .subscribe((res)=> {
                                                         this.chats = res 
                                                         console.log('updated chats back to null = ', res)

                                                     })


    getChat(id: number){
      return this.chats[id]
    };

    getChats(){
        return this.chats
    }

    /**
     * @RequestParam(value = "lastSeq", required = false) String lastSeq,
     * @RequestParam(value = "paragDescr", required = false) String paraDescription,
     * @RequestParam(value = "recipient", required = false) String recipient,
     * @RequestParam(value = "chatRoomId", required = true) String chatRoomId,
     * @RequestParam(value = "user", required = true) String user,
     * @RequestParam(value = "subject", required = false) String subject,
     * @RequestParam(value = "dateFrom", required = false) String dateFrom,
     * @RequestParam(value = "dateTo", required = false) String dateTo,
     * @RequestParam(value = "countCR", required = false) Integer countCR,
     * @RequestParam(value = "countFR", required = false) Integer countFR)
     */
    loggedInChatsAreHere = new Subject<ChatAndInnerFormResults>()
    getLoggedInChats_FirstCall(){
        this.getComponentsHttpRequestService.getAllChats()
            .subscribe((newChatsIncludeFormresults: any[])=> {
                console.log(newChatsIncludeFormresults) 


                const innerChatFormResultNumbers: InnerChatFormResultNumbers[] = []
                const newChats: Chat[] = []

                if ( newChatsIncludeFormresults.length > 0 ){
                for ( let i=0; i<newChatsIncludeFormresults.length ; i++){

                    const new_id = newChatsIncludeFormresults[i]._id 
                    const newOwner = newChatsIncludeFormresults[i].owner
                    const newAllUsersAllowed = newChatsIncludeFormresults[i].allUsersAllowed
                    const newUserListAllowed = newChatsIncludeFormresults[i].userListAllowed
                    const newIsVisible = newChatsIncludeFormresults[i].isVisible
                    const newDateAndTime = newChatsIncludeFormresults[i].dateAndTime
                    const newUpdateDateAndTime = newChatsIncludeFormresults[i].updateDateTime
                    const newIsNewChat = newChatsIncludeFormresults[i].isNewChat
                    const newIsBeenUpdated = newChatsIncludeFormresults[i].isBeenUpdated
                    const newIsSelected = newChatsIncludeFormresults[i].isSelected
                    const newSubject = newChatsIncludeFormresults[i].subject 

                    const newChat = new Chat (new_id, newOwner, newAllUsersAllowed, newUserListAllowed, newIsVisible ,
                                              newDateAndTime, newUpdateDateAndTime, newIsNewChat, newIsBeenUpdated, newIsSelected , 
                                              newSubject)


                    const newFormResults: FormResult[] = []

                    for ( let y=0; y<newChatsIncludeFormresults[i].formResultList.length ; y++){
                          newFormResults.push(newChatsIncludeFormresults[i].formResultList[y])
                    }

                    const newInnerChatFormResultNumber: InnerChatFormResultNumbers  = { chat: newChat , isSelected: false, innerChatFormResults: newFormResults  }
                    innerChatFormResultNumbers.push(newInnerChatFormResultNumber) 

                    if ( i === newChatsIncludeFormresults.length-1 ){

                        const chatAndInnerFormResults: ChatAndInnerFormResults = { numberOfItems: i, innerChatFormResults: innerChatFormResultNumbers   }
                        console.log("chatAndInnerFormResults => ", chatAndInnerFormResults)
                        this.chats = newChats
                        this.loggedInChatsAreHere.next(chatAndInnerFormResults)

                    }

                }
                } // END IF STATEMENT

                if (newChatsIncludeFormresults.length === 0){
                    const chatAndInnerFormResults: ChatAndInnerFormResults = { numberOfItems: 0, innerChatFormResults:[]  }
                    this.loggedInChatsAreHere.next(chatAndInnerFormResults)
                }

            })
    }

    getChat_isVisible(){
        return this.chats.find(x=> x.isVisible === true )
    }

    getChatBy_id(_id: string){
        const newChat = this.chats.find(
            (res) => {
              return  res._id === _id
            })
        return newChat
    }

    // getChatBy_Recipient_id(recipient_id: string){
    //     return this.chats.find(x=> x.recipient._id === recipient_id)
    // }

    addChat(chat: Chat){

        this.chats.push(chat)
    }

    sortChatsByDate(){
        return this.chats.sort((a,b) => {
            return <any> new Date(b.dateAndTime) - <any> new Date(a.dateAndTime);
        });
    }


    /**
     * subject to close the sidenav on obile chat once the form is been sent 
     */
    private closeSideNavOnceFormHasBeenSentOnMobile = new Subject<any>()

    closeSideNavOnceMobileFormHasBeenSent(){
        this.closeSideNavOnceFormHasBeenSentOnMobile.next()
    }

    getCloseSideNavOnceMobileFormHasBeenSent(): Observable<any>{
        return this.closeSideNavOnceFormHasBeenSentOnMobile.asObservable()
    }

    /**
     * sending signal to update the chat list and place the updated one to the top 
     */
     private updateToChatIndexList = new Subject<any>()
     
     sendUpdateToChatIndexList(){
       this.updateToChatIndexList.next()
     }
     getUpdateToChatIndexList(): Observable<any> {
        return this.updateToChatIndexList.asObservable()
    }


    aChatHasBeenSelectedFromChatFormResultList = new Subject<void>()
    onInformChatComponent_AChatHasBeenSelected(){
        this.aChatHasBeenSelectedFromChatFormResultList.next()
    }

    /**
     * selectedChat: SelectedChat = { aChatHasBeenSelected: false , recipientSelected: null }
     */
    onSelectedChat(newSelectedChat: SelectedChat){
        this.selectedChat = newSelectedChat

    }



   
/**
 * 
 * @returns return a click to let them know they have to update the list order
 * from chat create new chat dialog
 */

     private updateToNewChatIndexList = new Subject<string>()
     public  updateToNewChatIndexList$ = this.updateToNewChatIndexList.asObservable()
/**
 * 
 * @param newId new recipient ID created with the creation of new chat in chat create dialog
 */
     sendUpdateToNewChatIndexList(new_id: string ){
         this.updateToNewChatIndexList.next(new_id)
     }


/**
 * receive and send click event to open the sideNav Paragraph ----------------------------
 */
private openSideNavParagraph = new Subject<any>()

getClickEventToOpenSideNavFromChatItem(): Observable<any>{
    return this.openSideNavParagraph.asObservable();
};

sendClickEventToOpenSideNavOnChats(){
    console.log('the click has arrived')
    this.openSideNavParagraph.next()
};
//-----------------------------------------------------------------------------------------

/**
 * when chat item show up , tell all related components ( paragraph/ chats/ index ) all the data 
 * needed : aChatIsSelected / 
 */

private informComponentsThisChatIsSelected = new Subject<Recipient>()

getChatFromChatItemIsDisplayed(): Observable<Recipient>{
    return this.informComponentsThisChatIsSelected.asObservable()
};

sendChatFromChatItemIsDisplayed(recipientResult: Recipient){
    this.informComponentsThisChatIsSelected.next(recipientResult)
};

/**
 * send updated formRewsultSubjectsList to chatParagraphIndex
 */

formResultsSubjectList = new Subject<string[]>()

sendingChatParagraphFeatureContainerUpdatedFormResultsList( subjectList: string[]){
    this.formResultsSubjectList.next(subjectList)
}





//      constructor (
//         public _id: string ,
//         public owner: string ,
//         public chat_id: string,
//         public chatId: number, 
//         public isVisible: boolean, 
//         public dateAndTime: string , 
//         public partecipantGroup: PartecipantGroup , 
//         public isNewChat: boolean, 
//         public isBeenUpdated: boolean,
//         public isSelected: boolean, 
//         public subject: string ){}
// }


    //  buildInitialDefaultChat(userEmail){

    //    // build partecipantGroup first
    //     const newConstent = ''
    //     const newSender = new Sender (newConstent)
    //     const newPartecipants = []

    //     const new_id = null
    //     const newOwner = userEmail
    //     const newChat_id = new_id
    //     const newAllUsersAllowed = false 
    //     const newUserListAllowed = []
    //    // const newChatId = 1 // this id is going to disappear once we use _id only 
    //     const newIsVisible = false 
    //     const newDateAndTime = null 
    //     const newRecipient = this.recipientService.getRecipient(0)
    //     const newPartecipantGroup = new PartecipantGroup ( newSender , newPartecipants )
    //     const newIsNewChat = true
    //     const newIsBeenUpdated = false
    //     const newIsSelected = false 
    //     const newSubject = ''

    //     const newChat = new Chat (new_id, newOwner, newAllUsersAllowed, newUserListAllowed, 
    //                               newIsVisible, newDateAndTime, newRecipient,  newPartecipantGroup,
    //                               newIsNewChat, newIsBeenUpdated, newIsSelected, newSubject)

    //     this.chatHttpRequestService.httpStoreChatAndRecipient( newOwner ,newChat, newRecipient )



    //  }







}