import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "src/app/app-routing.module";
import { MaterialDesignModule } from "src/app/material-design/material-design.module";
import { FeaturesComponent } from "../features.component";

import { PipesModule } from "src/app/pipes/pipes.module";

import { InputModule } from "../input/input.module";
import { ButtonsModule } from "../buttons/buttons.module";
import { SharedModule } from "src/app/shared/shared.module";
import { OurChartsModule } from "../charts/our-charts.module";
import { LayoutComponent } from "./layout.component";
import { FixLayoutListComponent } from './fix-layout-list/fix-layout-list.component';
import { FixLayoutItemComponent } from './fix-layout-list/fix-layout-item/fix-layout-item.component';
import { FixLayoutEditComponent } from './fix-layout-list/fix-layout-edit/fix-layout-edit.component';
import { FixLayoutEditStringComponent } from './fix-layout-list/fix-layout-edit-string/fix-layout-edit-string.component';
import { FixLayoutEditDescriptionComponent } from './fix-layout-list/fix-layout-edit-description/fix-layout-edit-description.component';
import { FixLayoutEditChartComponent } from './fix-layout-list/fix-layout-edit-chart/fix-layout-edit-chart.component';
import { MatInputAutosizeModule } from 'mat-input-autosize';
import { FixLayoutEditNumberComponent } from './fix-layout-list/fix-layout-edit-number/fix-layout-edit-number.component'
import { AuxiliaryComponentsModule } from "src/app/loading-spinner/auxiliary-components.module";


@NgModule({
    declarations: [
        LayoutComponent,
        FixLayoutListComponent,
        FixLayoutItemComponent,
        FixLayoutEditComponent,
        FixLayoutEditStringComponent,
        FixLayoutEditDescriptionComponent,
        FixLayoutEditChartComponent,
        FixLayoutEditNumberComponent
    ],

    exports: [
        LayoutComponent,
        FixLayoutListComponent,
        FixLayoutItemComponent,
        FixLayoutEditComponent,
        FixLayoutEditStringComponent,
        FixLayoutEditDescriptionComponent,
        FixLayoutEditNumberComponent,
        FixLayoutEditChartComponent
    ],

    imports: [
        AppRoutingModule,
        CommonModule,
        FormsModule,
        BrowserAnimationsModule,
        MaterialDesignModule, 
        ReactiveFormsModule,
        MatDialogModule,
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatExpansionModule,
        InputModule,
        ButtonsModule,
        PipesModule,
        SharedModule,
        OurChartsModule,
        MatInputAutosizeModule,
        AuxiliaryComponentsModule 
    ]

})


export class DrappLayoutModule {}