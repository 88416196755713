import { Component, OnInit, Input , Output, EventEmitter } from '@angular/core';
import { FormFeatureContainer } from '../../form-feature-container.model';
import { FormIndexTopic } from '../../form-index-topic.model';
import { FormService } from 'src/app/forms/form.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { FormFeatureContainerService } from '../../form-feature-container.service';
import { FormIndexTypeInterface } from '../../../form-index-type-interface.model';
import { Subscription } from 'rxjs';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { Form } from 'src/app/forms/form.model';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions} from '@angular/material/tooltip';
import { FormIndexTopicListItem } from '../../form-index-topic-list-item.model';
import { FormIndexTopicListItemService } from '../../form-index-topic-list-item.service';
import { FormIndexTopicService } from '../../form-index-topic.service';
import { DialogmanagerService } from 'src/app/services/other-components-services/dialogmanager.service';
import { StorePersonalFeatureIndexComponentsService } from 'src/app/services/store-initial-components/store-personal-feature-index-components.service';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { CreateUpdateMultiComponentsService } from 'src/app/services/shared-services/create-update-multi-components.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { AddSharedFormOrParagraphComponent } from '../../../add-shared-form-or-paragraph/add-shared-form-or-paragraph.component'; 
import { ParagraphTopicIndex } from '../../../form-edit/form-edit-paragraphs-index/paragraph-topic-index.model';
import { ParagraphTopic } from '../../../form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.model';
import { ParagraphFeatureContainer } from '../../../form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature-container.model';
import { AddSharedFormOrParagraphBottomSheetComponent } from '../../../add-shared-form-or-paragraph-bottom-sheet/add-shared-form-or-paragraph-bottom-sheet.component';



/** Custom options the configure the tooltip's default show/hide delays. */
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 300,
  touchendHideDelay: 1000,
};

interface SharedParagraphForm_Dialog_BottomSheet_Data {
  typeOfComponent: string , 
  formType: FormType ,
  paragraphType: ParagraphType, 
}

interface FormType {
  formIndexTopic: FormIndexTopic ,
  formFeatureContainer: FormFeatureContainer
}

interface ParagraphType {
  paragraphTopic: ParagraphTopic,
  paragraphFeatureContainer: ParagraphFeatureContainer
}



@Component({
  selector: 'app-form-feature-container',
  templateUrl: './form-feature-container.component.html',
  styleUrls: ['./form-feature-container.component.css'],
  providers: [{provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}]
})
export class FormFeatureContainerComponent implements OnInit {

  user: string

  formFeatureContainer : FormFeatureContainer

  @Input() formIndexTopic: FormIndexTopic
  @Input() index: number
  @Input() formFeatureContainer_id: string


  @Output() closeSideNavOnFormList = new EventEmitter<void>()
  @Output() adviseFormListOnLoadingForm = new EventEmitter<void>()

  @Input() typeOfFormIndexTopic: FormIndexTypeInterface
  isBeenAddedToPreferred: boolean = false 
  /**
   * export class FormIndexTypeInterface {

    constructor ( public isPersonal: boolean , 
                  public isPublic: boolean,
                  public isShared: boolean,
                  public isPreferred: boolean ){}
}
   */
  typeOfIndexTopicInUse: string 

  updateOnUserEmail: Subscription

  isLoading: boolean = false 
  formName: string 
  form: Form
  formExist: boolean  


  constructor( private formService: FormService , 
               private postComponentsHttpRequestService: PostComponentsHttpRequestService ,
               private formFeatureContainerService: FormFeatureContainerService, 
               private userObjectUpdateService: UserObjectUpdateService ,
               private formIndexTopicListItemService: FormIndexTopicListItemService , 
               private formIndexTopicService: FormIndexTopicService ,
               private dms: DialogmanagerService ,
               private storePersonalFeatureIndexComponentsService: StorePersonalFeatureIndexComponentsService,
               private initialNullSetupService: InitialNullSetupService ,
               private createUpdateMultiComponentsService: CreateUpdateMultiComponentsService , 
               private breakpointObserver: BreakpointObserver,
               private _bottomSheet: MatBottomSheet ,
               ) { }

  
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );


  ngOnInit() {


    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
    })

  this.isLoading = true 
  this.formExist = false 

    this.formFeatureContainer = this.formFeatureContainerService.getFormFeatureContainerBy_id(this.formFeatureContainer_id)
    console.log(this.formIndexTopic, this.formFeatureContainer)

    this.firstCheckOnFormExist()
    this.checkIfPublicFormHasBeenPreviouslyAddedToPreferred() // thisis for the case of public forms / can now use all the formFeatureContainers directly 
  }

  firstCheckOnFormExist(){

    if ( this.formService.getForms().findIndex(x=> x._id === this.formFeatureContainer.form_id ) === -1){
         this.ifFormDoesntExist()
    }

    if ( this.formService.getForms().findIndex(x=> x._id === this.formFeatureContainer.form_id ) !== -1){ this.ifFormExist()    }

  }

  ifFormExist(){

    this.form = this.formService.getFormBy_id(this.formFeatureContainer.form_id)
    console.log(this.form )
    this.formName = this.form.formSubject
    this.formExist = true
    this.isLoading = false 

  }

  
  ifFormDoesntExist(){  
    // this must be coming after we have double checked with find public formItem by _id 
    // this different if we are logged in and it's personal it shoudl exist , if it doesn't we should call it 
    // if it's not personal then it just doesn't exist anymore 

    
    this.formExist = false 
    this.isLoading = false 
  }


  checkIfPublicFormHasBeenPreviouslyAddedToPreferred(){

    const formIndexTopicListItemOfReference: FormIndexTopicListItem = this.formIndexTopicListItemService.getFormIndexTopicListItem_by_type('preference')
    const formIndexTopicsOfReference: FormIndexTopic[] = []
    for ( let i=0 ; i<formIndexTopicListItemOfReference.formIndexTopic_ids.length ; i++ ){
      formIndexTopicsOfReference.push(this.formIndexTopicService.getFormIndexTopicBy_id(formIndexTopicListItemOfReference.formIndexTopic_ids[i]) )
    } 

    this.defineIfPublicFormHasBeenPreviouslyAddedToPreferred( formIndexTopicsOfReference)

  }

  defineIfPublicFormHasBeenPreviouslyAddedToPreferred( formIndexTopicsOfReference : FormIndexTopic[]){

    const formFeatureContainersOfReference: FormFeatureContainer[] = []
    for ( let i=0; i<formIndexTopicsOfReference.length ; i++ ){
          for ( let y=0; y< formIndexTopicsOfReference[i].formFeatureContainer_ids.length ; y++ ){
            formFeatureContainersOfReference.push(this.formFeatureContainerService.getFormFeatureContainerBy_id(formIndexTopicsOfReference[i].formFeatureContainer_ids[y]))
          }
    }

    if ( formFeatureContainersOfReference.findIndex(x=> x.form_id === this.formFeatureContainer.form_id ) === -1 ){ this.isBeenAddedToPreferred = false }
    if ( formFeatureContainersOfReference.findIndex(x=> x.form_id === this.formFeatureContainer.form_id ) !== -1 ){ this.isBeenAddedToPreferred = true  }


  }

  onAdviseFormListOnLoadingForm(){
    this.adviseFormListOnLoadingForm.emit()
  }


  onAcceptFormContainer(){
    // this.isLoading = true 

const new_id = this.formFeatureContainer._id
const newComponentName = this.formFeatureContainer.componentName
const newUIID = this.formFeatureContainer.UIID
const newOwner = this.formFeatureContainer.owner
const newAllUsersAllowed = this.formFeatureContainer.allUsersAllowed
const newUserListAllowed = this.formFeatureContainer.userListAllowed
const newDateAndTime = this.formFeatureContainer.dateAndTime
const newIsActive = this.formFeatureContainer.isActive
const newIsAccepted = true 
const newName = this.formFeatureContainer.name
const newState = this.formFeatureContainer.formFeatureState
const newRecipientIndex_id = this.formFeatureContainer.recipientIndex_id
const newform_id = this.formFeatureContainer.form_id

const newFormFeatureContainer = new FormFeatureContainer ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed,
      newDateAndTime, newIsActive, newIsAccepted, newName, newState, newRecipientIndex_id, newform_id )

      console.log('is opening window to add or refuse formFeatureContainer')

      const newTypeOfComponent = 'formFeatureContainer'
      const newFormType : FormType = { formIndexTopic: this.formIndexTopic ,formFeatureContainer: newFormFeatureContainer }
      const newParagraphType: ParagraphType = { paragraphTopic: null, paragraphFeatureContainer: null }
      
      const newSharedParagraphForm_Dialog_BottomSheet_Data: SharedParagraphForm_Dialog_BottomSheet_Data = { typeOfComponent: newTypeOfComponent ,formType: newFormType ,paragraphType: newParagraphType }

      if ( !this.isMobileDevice()){
        this.dms.addSharedFormOrParagraphComponent({ 

          sharedParagraphForm_Dialog_BottomSheet_Data: newSharedParagraphForm_Dialog_BottomSheet_Data
        
          })
      }
  
      if ( this.isMobileDevice()){
           this._bottomSheet.open(AddSharedFormOrParagraphBottomSheetComponent , {
            data: {

            sharedParagraphForm_Dialog_BottomSheet_Data: newSharedParagraphForm_Dialog_BottomSheet_Data
              
            }
           });
      }
    

  };

  isMobileDevice(): boolean {
    const isHandset = this.breakpointObserver.isMatched(Breakpoints.Handset);
    return isHandset;
  }

  onAddFormToPreferred( form_id: string ){

        this.isBeenAddedToPreferred = true 


        if ( this.user !== null ){

          this.storePersonalFeatureIndexComponentsService.getPersonalUpdatedFeatureIndex_components(this.user)
              .subscribe((res: any) =>{
               console.log(res)

               const httpFeatureIndexComponentResult: any = res 
               
                const newFormIndexTopics: FormIndexTopic[] = httpFeatureIndexComponentResult.formIndexTopic
                console.log(newFormIndexTopics)
                this.formIndexTopicService.formIndexTopics = newFormIndexTopics 
                this.initialNullSetupService.initialNullFormIndexTopics = newFormIndexTopics
               
                const newFormIndexTopicListItems: FormIndexTopicListItem[] = httpFeatureIndexComponentResult.formIndexTopicListItem
                console.log(newFormIndexTopicListItems)
                this.formIndexTopicListItemService.formIndexTopicListItems = newFormIndexTopicListItems
                this.initialNullSetupService.initialNullFormIndexTopicListItems = newFormIndexTopicListItems
                
                const newFormFeatureContainers: FormFeatureContainer[] = httpFeatureIndexComponentResult.formFeatureContainer
                console.log(newFormFeatureContainers)
                this.formFeatureContainerService.formFeatureContainers = newFormFeatureContainers 
                this.initialNullSetupService.initialNullFormFeatureContainers = newFormFeatureContainers


                this.buildFormFeatureContainer(form_id)
              
        })

        }

        if ( this.user === null ){
          this.buildFormFeatureContainer(form_id)
        }

  }

  addFormToPreferred(form_id: string){
    const newFormItem: Form = this.formService.getFormBy_id(form_id)

                this.postComponentsHttpRequestService.httpStorePreferenceComponent( this.user , newFormItem  )
                    .subscribe((res)=> { 
                      console.log( 'added form to preferred',res)
                },error => {
                  console.log( error, 'there was an error while adding form to preferred')
                })
  }


  buildFormFeatureContainer(form_id){

    const newFormFeatureContainer_id = 'formFeatureContai-'+(this.formFeatureContainerService.getFormFeatureContainers().length+1).toString()
    const newComponentName = 'formFeatureContainer'
    const newUIID = 'formFeatureContainer-'+(this.formFeatureContainerService.getFormFeatureContainers().length+1).toString()
    const newFormFeatureContainer_owner = this.user 
    const newFormFeatureContainer_allUsersAllowed = false 
    const newFormFeatureContainer_userListAllowed = []
    const newFormFeatureContainer_dateAndTime = null 
    const newFormFeatureContainer_isActive = true 
    const newFormFeatureContainer_isAccepted = true 
    const newFormFeatureContainer_name = 'preference'
    const newFormFeatureContainer_formFeatureState = true 
    const newFormFeatureContainer_recipientIndex_id = null 
    const newFormFeatureContainer_form_id = form_id 

    const newFormFeatureContainer = new FormFeatureContainer ( newFormFeatureContainer_id, newComponentName, newUIID, newFormFeatureContainer_owner ,
      newFormFeatureContainer_allUsersAllowed ,newFormFeatureContainer_userListAllowed , newFormFeatureContainer_dateAndTime ,
      newFormFeatureContainer_isActive , newFormFeatureContainer_isAccepted, newFormFeatureContainer_name , 
      newFormFeatureContainer_formFeatureState , newFormFeatureContainer_recipientIndex_id , newFormFeatureContainer_form_id  )

      this.saveFormFeatureContainerAndCheckIfFormIndexTopicAlreadyExist( form_id, newFormFeatureContainer )

  }



  saveFormFeatureContainerAndCheckIfFormIndexTopicAlreadyExist( form_id: string,  newFormFeatureContainer: FormFeatureContainer ){

    if ( this.user !== null ){

      const preferredFormIndexTopicListItem: FormIndexTopicListItem = this.formIndexTopicListItemService.getFormIndexTopicListItem_by_type('preference')

      if ( preferredFormIndexTopicListItem.formIndexTopic_ids.length > 0 ){

           const preferredFormIndexTopics: FormIndexTopic[] = []

           for (let i=0; i<preferredFormIndexTopicListItem.formIndexTopic_ids.length ; i++ ){
                 preferredFormIndexTopics.push(this.formIndexTopicService.getFormIndexTopicBy_id(preferredFormIndexTopicListItem.formIndexTopic_ids[i]))
                 if ( i === preferredFormIndexTopicListItem.formIndexTopic_ids.length-1 ){ this.checkIfPreferredFormIndexTopicAlreadyExist( form_id, newFormFeatureContainer, preferredFormIndexTopics)  }
           }

      }

      if ( preferredFormIndexTopicListItem.formIndexTopic_ids.length == 0 ){
           this.addFormToPreferred(form_id)
           this.createFormFeatureContainer_CreateFormIndexTopic_AddToFormIndexTopicsListItems(form_id, newFormFeatureContainer, preferredFormIndexTopicListItem)
      }

    
    }

    if ( this.user === null ){

      this.formFeatureContainerService.addFormFeatureContainer( newFormFeatureContainer)
      this.checkIfFormIndexTopicAlreadyExistAndAddFormFeatureContainer(newFormFeatureContainer)
    }
  };

  checkIfPreferredFormIndexTopicAlreadyExist(form_id: string , formFeatureContainer: FormFeatureContainer, preferredFormIndexTopics: FormIndexTopic[]){
    const newFormItem: Form = this.formService.getFormBy_id(form_id)

    if ( preferredFormIndexTopics.findIndex(x=> x.name === newFormItem.owner ) !== -1){
         const preferredFormIndexTopic: FormIndexTopic = this.formIndexTopicService.getFormIndexTopic( preferredFormIndexTopics.findIndex(x=> x.name === newFormItem.owner ))

         const preferredFormFeatureContainers: FormFeatureContainer[] = []
         for ( let i=0 ; i<preferredFormIndexTopic.formFeatureContainer_ids.length ; i++ ){
               preferredFormFeatureContainers.push(this.formFeatureContainerService.getFormFeatureContainerBy_id(preferredFormIndexTopic.formFeatureContainer_ids[i]))

               if (i== preferredFormIndexTopic.formFeatureContainer_ids.length-1 ){
                   if ( preferredFormFeatureContainers.findIndex(x=> x.form_id === form_id ) !== -1 ){
                        console.log( 'should be impossible, but it already exist')
                   }
                   if ( preferredFormFeatureContainers.findIndex(x=> x.form_id === form_id ) === -1 ){
                        console.log( 'create formFeatureContainer and add to formIndexTopic')
                        this.addFormToPreferred(form_id)
                        this.createFormFeatureContainer_AddToFormIndexTopic( formFeatureContainer, preferredFormIndexTopic )
                   }
               }
         }
         

    }


    if ( preferredFormIndexTopics.findIndex(x=> x.name === newFormItem.owner ) === -1){
         const preferredFormIndexTopicListItem: FormIndexTopicListItem = this.formIndexTopicListItemService.getFormIndexTopicListItem_by_type('preference')
         this.createFormFeatureContainer_CreateFormIndexTopic_AddToFormIndexTopicsListItems(form_id, formFeatureContainer, preferredFormIndexTopicListItem )
    }
        
  }

  createFormFeatureContainer_AddToFormIndexTopic( formFeatureContainer: FormFeatureContainer, preferredFormIndexTopic: FormIndexTopic ){

    this.createUpdateMultiComponentsService.createFormFeatureContainer_UpdateFormIndexTopic(this.user, formFeatureContainer, preferredFormIndexTopic )
        .subscribe((res: any)=>{
          console.log(res )

          const newFormFeatureContainer: FormFeatureContainer = res.featureIndex[0] // add to list
          const newFormIndexTopic: FormIndexTopic = res.featureIndex[1] // update 

          this.formFeatureContainerService.formFeatureContainers.push(newFormFeatureContainer)
          this.initialNullSetupService.initialNullFormFeatureContainers.push(newFormFeatureContainer)

          const indexFormIndexTopic = this.formIndexTopicService.formIndexTopics.findIndex(x=> x._id === newFormIndexTopic._id)
          this.formIndexTopicService.formIndexTopics[indexFormIndexTopic] = newFormIndexTopic 

          const indexNullFormIndexTopic = this.initialNullSetupService.initialNullFormIndexTopics.findIndex(x=> x._id === newFormIndexTopic._id )
          this.initialNullSetupService.initialNullFormIndexTopics[indexNullFormIndexTopic] = newFormIndexTopic

          this.dms.QuickCommunicationPopUpComponent({
            quickComment: 'added to preferred-form list'
          }) // END dialog communication 
        })
  }


  createFormFeatureContainer_CreateFormIndexTopic_AddToFormIndexTopicsListItems(form_id: string, formFeatureContainer: FormFeatureContainer, preferredFormIndexTopicListItem: FormIndexTopicListItem){

    const newFormItem: Form = this.formService.getFormBy_id(form_id)

      const new_id = null 
      const newComponentName = 'formIndexTopic'
      const newUIID = 'formIndexTopic-'+ (this.formIndexTopicService.getFormIndexTopics().length+1).toString()
      const newOwner = this.user 
      const newDateAndTime = null 
      const newAllUsersAllowed = false 
      const newUserListAllowed = []
      const isAccepted = true 
      const isArchived = false 
      const newname = newFormItem.owner
      const newFormFeatureContainer_ids = null 

      const newFormIndexTopic = new FormIndexTopic( new_id, newComponentName, newUIID, newOwner, newDateAndTime, newAllUsersAllowed, 
                                                    newUserListAllowed, isAccepted, isArchived, newname, newFormFeatureContainer_ids )

      this.createUpdateMultiComponentsService.createFormFeatureContainer_CreateFormIndexTopic_UpdateFormIndexTopicListItem(this.user, formFeatureContainer, newFormIndexTopic, preferredFormIndexTopicListItem )
          .subscribe((res: any)=> {
            console.log(res)

            const newFormFeatureContainer: FormFeatureContainer = res.featureIndex[0] // add to list
            const newFormIndexTopic: FormIndexTopic = res.featureIndex[1] // add to list
            const newFormIndexTopicListItem: FormIndexTopicListItem = res.featureIndex[2] // update

            this.formFeatureContainerService.formFeatureContainers.push(newFormFeatureContainer)
            this.initialNullSetupService.initialNullFormFeatureContainers.push(newFormFeatureContainer)

            this.formIndexTopicService.formIndexTopics.push(newFormIndexTopic)
            this.initialNullSetupService.initialNullFormIndexTopics.push(newFormIndexTopic)

            const indexOfFormIndexTopicListItem = this.formIndexTopicListItemService.formIndexTopicListItems.findIndex(x=> x._id === newFormIndexTopicListItem._id )
            this.formIndexTopicListItemService.formIndexTopicListItems[indexOfFormIndexTopicListItem] = newFormIndexTopicListItem

            const indexOfNullFormIndexTopicListItem = this.initialNullSetupService.initialNullFormIndexTopicListItems.findIndex(x=> x._id === newFormIndexTopicListItem._id)
            this.initialNullSetupService.initialNullFormIndexTopicListItems[indexOfNullFormIndexTopicListItem] = newFormIndexTopicListItem

            this.dms.QuickCommunicationPopUpComponent({
              quickComment: 'added to preferred-form list'
            }) // END dialog communication 
            
          }, error => {
            console.log(error , 'throw an error !')
          })
      }



  checkIfFormIndexTopicAlreadyExistAndAddFormFeatureContainer( formFeatureContainer: FormFeatureContainer){

    const formOfReference = this.formService.getFormBy_id(this.formFeatureContainer.form_id)
    const newFormIndexTopicListItemOfReference: FormIndexTopicListItem = this.formIndexTopicListItemService.getFormIndexTopicListItem_by_type('preference')

    const newFormIndexTopicsOfReference: FormIndexTopic[] = []
    for (let i=0 ; i<newFormIndexTopicListItemOfReference.formIndexTopic_ids.length ; i++ ){
      newFormIndexTopicsOfReference.push(this.formIndexTopicService.getFormIndexTopicBy_id(newFormIndexTopicListItemOfReference.formIndexTopic_ids[i]))
    }

    this.checkIfFormIndexTopicAlreadyExist(newFormIndexTopicsOfReference, formOfReference, formFeatureContainer, newFormIndexTopicListItemOfReference )
  };

  checkIfFormIndexTopicAlreadyExist(formIndexTopicsOfReference: FormIndexTopic[], formOfReference: Form , formFeatureContainer: FormFeatureContainer , formIndexTopicListItemOfReference: FormIndexTopicListItem  ){

    if ( formIndexTopicsOfReference.findIndex(x=> x.name === formOfReference.owner ) === -1 ){
         this.createNewFormIndexTopic( formOfReference, formIndexTopicListItemOfReference, formFeatureContainer )
    }


    if ( formIndexTopicsOfReference.findIndex(x=> x.name === formOfReference.owner ) !== -1 ){
       const formIndexTopic_id_ofReference: string = formIndexTopicsOfReference[formIndexTopicsOfReference.findIndex(x=> x.name === formOfReference.owner)]._id
       this.addFormFeatureContainerToExistingFormIndexTopic(formIndexTopicListItemOfReference , formFeatureContainer, formIndexTopic_id_ofReference )
    }
  };

  createNewFormIndexTopic( formOfReference: Form, formIndexTopicListItemOfReference: FormIndexTopicListItem , formFeatureContainer: FormFeatureContainer){


        const newFormIndexTopic_id = 'formIndexTo-'+(this.formIndexTopicService.getFormIndexTopics().length+1).toString()
        const newComponentName1 = 'formIndexTopic'
        const newUIID1 = 'formIndexTopic-'+(this.formIndexTopicService.getFormIndexTopics().length+1).toString()
        const newFormIndexTopic_owner = this.user 
        const newFormIndexTopic_dateAndTime = null 
        const newFormIndexTopic_allUsersAllowed = false 
        const newFormIndexTopic_userListAllowed = []
        const newFormIndexTopic_isAccepted = true 
        const newFormIndexTopic_isArchived = false 
        const newFormIndexTopic_name = formOfReference.owner
        const newFormIndexTopic_formFeatureContainer_ids = [formFeatureContainer._id]

        const newFormIndexTopic = new FormIndexTopic ( newFormIndexTopic_id , newComponentName1, newUIID1, newFormIndexTopic_owner , newFormIndexTopic_dateAndTime , 
              newFormIndexTopic_allUsersAllowed , newFormIndexTopic_userListAllowed, newFormIndexTopic_isAccepted, newFormIndexTopic_isArchived , 
              newFormIndexTopic_name , newFormIndexTopic_formFeatureContainer_ids )

        if ( this.user === null ){ 
                                   this.formIndexTopicService.addFormIndexTopic(newFormIndexTopic)
                                   this.addFormIndexTopic_id_toExistingFormIndexTopicListItem(newFormIndexTopic._id , formIndexTopicListItemOfReference )
                                  }
        
       
  }

  addFormFeatureContainerToExistingFormIndexTopic( formIndexTopicListItemOfReference: FormIndexTopicListItem,  formFeatureContainer: FormFeatureContainer , formIndexTopic_id: string ){

       const formIndexTopicOfReference: FormIndexTopic = this.formIndexTopicService.getFormIndexTopicBy_id(formIndexTopic_id)
       formIndexTopicOfReference.formFeatureContainer_ids.push(formFeatureContainer._id)

       if ( this.user === null ){ 
           this.formIndexTopicService.formIndexTopics[this.formIndexTopicService.getFormIndexTopics().findIndex(x=> x._id === formIndexTopicOfReference._id)] = formIndexTopicOfReference
          this.dms.QuickCommunicationPopUpComponent({
            quickComment: 'added to preferred-form list'
          }) // END dialog communication 
       }
  


  }

  addFormIndexTopic_id_toExistingFormIndexTopicListItem(formIndexTopic_id: string , formIndexTopicListItemOfReference: FormIndexTopicListItem  ){

    const newFormIndexTopicListItemOfreference = formIndexTopicListItemOfReference
          newFormIndexTopicListItemOfreference.formIndexTopic_ids.push(formIndexTopic_id)

    if ( this.user === null ){
         this.formIndexTopicListItemService.formIndexTopicListItems[this.formIndexTopicListItemService.getFormIndexTopicListItems().findIndex(x=> x._id === newFormIndexTopicListItemOfreference._id)] = newFormIndexTopicListItemOfreference
        
    }


  }

  ngOnDestroy(){
    this.updateOnUserEmail.unsubscribe()
  }

}
