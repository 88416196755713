import { Injectable } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { InitialNullSetupService } from "src/app/services/starting-services/initial-null-setup.service";
import { RecipientIndexColumn } from "./recipient-index-column.model";
import { RecipientIndexHttpRequestService } from "./recipient-index-http-request.service";
import { UpdateRecipientFeatureService } from "src/app/services/updates-on-working-app/update-recipient-feature.service";


@Injectable({
    providedIn: 'root'
})

export class RecipientIndexColumnService {

    constructor( private recipientIndexHttpRequestService: RecipientIndexHttpRequestService , 
                 private initialNullSetupService: InitialNullSetupService 
                 ){}

    sharedRecipientIndexListItem_isVisibleNow: boolean = false 

    resetRecipientIndexColumnSharedRecipients = new Subject<void>()
    hideSharedRecipientIndexColumn = new Subject<void>()

    recipientIndexColumns: RecipientIndexColumn[] = this.initialNullSetupService.initialNullRecipientIndexColumns


    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.recipientIndexColumnChangedBackToNull
    .subscribe((res)=> {
           this.recipientIndexColumns = res 
           console.log('updated recipientIndexeColumns back to null = ', res)
 
    })



    
    getRecipientIndexColums(){
        return this.recipientIndexColumns
    }

    getRecipientIndexColumn(i: number){
        const newRecipientIndexColumn: RecipientIndexColumn = this.recipientIndexColumns[i]
        return newRecipientIndexColumn
    }

    getRecipientIndexColumnBy_id(_id:string){
        const newRecipientIndexColum: RecipientIndexColumn = this.recipientIndexColumns.find(
            (res)=> {
                return res._id === _id
            }
        )
        return newRecipientIndexColum
    }

    getRecipientIndexColumnBy_type(type: string ){
        const newRecipientIndexColum: RecipientIndexColumn = this.recipientIndexColumns.find(
            (res)=> {
                return res.type === type
            }
        )
        return newRecipientIndexColum
    }

    getRecipientIndexColumns_SortByName(){
        const sortedColumnsByName: RecipientIndexColumn[] = this.recipientIndexColumns
        return sortedColumnsByName.sort((a,b) => {
            return <any>(a.name) - <any>(b.name)
        })
    }

    getRecipientIndexColumns_SortByType(){
        const sortedColumnsByName: RecipientIndexColumn[] = this.recipientIndexColumns
        return sortedColumnsByName.sort((a,b) => {
            return <any>(b.type) - <any>(a.type)
        })
    }



    updateRecipientIndexColumn(updatedRecipientIndexColumn: RecipientIndexColumn){

        const index = this.recipientIndexColumns.findIndex(x=> x._id === updatedRecipientIndexColumn._id)
        this.recipientIndexColumns[index] = updatedRecipientIndexColumn

        this.updateInitialComponents(this.recipientIndexColumns)

    }

    updateWithLatestRecipientIndexColumns = new Subject<RecipientIndexColumn[]>()
    updateRecipientIndexColumns(updatedRecipientIndexColumns: RecipientIndexColumn[]){
        this.recipientIndexColumns = updatedRecipientIndexColumns
        this.updateInitialComponents(this.recipientIndexColumns)
        this.updateWithLatestRecipientIndexColumns.next(this.recipientIndexColumns)
    }

    updateInitialComponents(updatedRecipientIndexColumns: RecipientIndexColumn[]){
        this.initialNullSetupService.initialNullRecipientIndexColumns = updatedRecipientIndexColumns
    }

    onResetMomentarelySharedColumn(){
        this.resetRecipientIndexColumnSharedRecipients.next()
    }

    onHideSharedRecipientIndexColumn(){
        this.hideSharedRecipientIndexColumn.next()
    }


}