<!-- <p>toggle-word-button-result works!</p> -->

<div class="row" >
    <div class="col-xs-12">

          <table >
            <tr>
             

                         <td *ngFor="let detail of toggleWordButtonDetailResults "> 
                          <mat-form-field appearance="none">
                            <input matInput disabled value=" {{detail.content}}">
                          </mat-form-field>
                        
                         </td> 
             
            </tr>
         </table>
      

    </div>
  </div>
