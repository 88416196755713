<!-- <p>qr-code-scanner works!</p> -->

<div [style.padding.px]="20">
    <h1> ngx-scanner-qrcode </h1>

<ngx-scanner-qrcode [hidden]="!isOpenVideo"
                     #action="scanner" 
                     (data)="onReceiveData($event)">
</ngx-scanner-qrcode>
<!-- (data)="output = $event" -->


<span>{{output}}</span>
<button type="button" 
        (click)="onOpenVideo()" 
        (click)="action.toggleCamera()" 
        [disabled]="action.isLoading">
        {{action.isStart ? 'Stop' : 'Start'}}
</button>
<!--  {{action.isStart ? 'Stop' : 'Start'}} -->


  <!-- data  -->
  <!-- <p class="data">{{ output }}</p> -->

  <!-- Loading -->
  <!-- <p *ngIf="action.isLoading">⌛ Loading video...</p> -->

  <!-- toggleCamera -->
  <!-- <button (click)="action.toggleCamera()" class="btn" [class.btn-info]="!action.isStart" [class.btn-warning]="action.isStart" [disabled]="action.isLoading">
    <img *ngIf="!action.isStart" src="https://img.icons8.com/ios/50/000000/no-camera--v2.png" width="30px"/>
    <img *ngIf="action.isStart" src="https://img.icons8.com/ios/50/000000/no-camera--v1.png" width="30px"/>
  </button>

  <h5>Author: DaiDH</h5> -->
</div>
<p>{{counter}}</p>
<p>{{output}}</p>