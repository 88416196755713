import { Component, OnInit, ViewEncapsulation , ViewChild, AfterViewInit, OnDestroy, Input, ChangeDetectorRef, NgZone, Output, EventEmitter, HostListener } from '@angular/core';
import { DialogmanagerService } from 'src/app/services/other-components-services/dialogmanager.service';
import { MatCalendar } from '@angular/material/datepicker';
import { Observable, Subject, Subscription, SubscriptionLike, interval } from 'rxjs';
import { SharedCalendarStatus } from '../shared-calendar-status.model';
import { SharedCalendarStatusService } from '../shared-calendar-status.service';
import { RangeDatesInfo } from '../range-dates-info.model';
import { Feature } from '../../../feature.model';
import { HeaderService } from 'src/app/header/header.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { SharedCalendarStatusEditBottomSheetComponent } from '../shared-calendar-status-edit-bottom-sheet/shared-calendar-status-edit-bottom-sheet.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DateStartEnd } from '../date-start-end.model';
import { RangeInfo } from '../range-info.model';
import { UsersAccess } from '../users-access-edit/users-access.model';
import { TimeAlignService } from 'src/app/services/other-components-services/time-align.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { SharedCalendarStatusResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/shared-calendar-status-result/shared-calendar-status-result.model';
import { FeatureResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/feature-result.model';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { ErrorsDialogManagerService } from 'src/app/services/other-components-services/errors-dialog-manager.service';
import { IsOnlineOfflineService } from 'src/app/services/shared-services/is-online-offline.service';
import { GetComponentsHttpRequestService } from 'src/app/services/gets-services/get-components-http-request.sevice';

const today = new Date();


@Component({
  selector: 'app-shared-calendar-status-item',
  templateUrl: './shared-calendar-status-item.component.html',
  styleUrls: ['./shared-calendar-status-item.component.css'],
  encapsulation:ViewEncapsulation.None
})
export class SharedCalendarStatusItemComponent implements OnInit , AfterViewInit , OnDestroy{

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  user: string 

  @Input() noNeeded : boolean  ; 
  @Input() index_id : string  ; // from the feature_id
  @Input() isParagraphEdit: boolean
  @Input() feature: Feature

 
  @Input() tab_index: number 
  @Input() isTabItem: boolean 
 
 

 featureResult: FeatureResult 

  @ViewChild('calendar') calendar: MatCalendar<any>;
  
  selectedDates: Date[] = [];
  isAddingRange: boolean = false 

  calendarMonthSubscription: Subscription

  sharedCalendarStatusItem: SharedCalendarStatus
 
  selectedRangeDatesInfo: RangeDatesInfo
  selectedRangeDatesInfo_Index: number
  latestSelectedRangeDatesInfo_Index: number 
  canViewStatus: string[]
  canEditStatus: string[]

  isRestartingComponent: boolean = false

  currentFeature: Feature
  @Input() editFeatureItem: Observable<void>;
  editFeatureEventFromSharedCalendarStatusList: Subscription; 

  maxDetails: number = 32 // index 0 is the setting that is copied all the times which is set by owner

  dateRanges: any[]
  selected: { date: Date, id: number, isSingleRange: boolean }[] = [];

  isUpdatingCalendar: boolean = false 
  aRangeDateInfoExist: boolean 

  changeSelectedDates = new Subject<void>()

  updateOnUserEmail: Subscription 

  selectedDatesArraytrial: Date[] = []
  counter: number = 0

  isEditingRangeDate: boolean

  sharedCalendarStatusResult: SharedCalendarStatusResult
  @Output() updateBothFeatureResultAndTextPlusIndex = new EventEmitter<any>()

  editJustAddedRangeInfo = new Subject<void>()

  canViewIt: boolean = false
  canUseIt: boolean = false 
  isLoading: boolean

  sharedCalendarStatus_owner: string 

  intervalLong: any; // to keep interval unique 
  updateTimeInterval: number = 60000
  canUpdate: boolean = true 
  itHasBeenMoreThanTimeInterval: boolean = false 

  observeIfComponentIsVisible: Subscription 
  @Input() isComponentVisible: Observable<boolean>
  componentIsActuallyVisible: boolean 

  @Output() updateListNamesAfterEdit = new EventEmitter<void>()
 

  constructor( private dms: DialogmanagerService , 
               private breakpointObserver: BreakpointObserver,
               private sharedCalendarStatusService: SharedCalendarStatusService , 
               private headerService: HeaderService,
               private _bottomSheet: MatBottomSheet,
               private ngZone: NgZone , 
               private cdr: ChangeDetectorRef ,
               private timeAlignService: TimeAlignService,
               private userObjectUpdateService: UserObjectUpdateService , 
               private initialNullSetupService: InitialNullSetupService , 
               private postComponentsHttpRequestService: PostComponentsHttpRequestService, 
               private errorsDialogManagerService: ErrorsDialogManagerService , 
               private isOnlineOfflineService: IsOnlineOfflineService , 
               private getComponentsHttpRequestService: GetComponentsHttpRequestService

               ) { }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
     if (document.hidden){
           console.log("SHARED CALENDAR IS HIDDEN " , new Date(), this.sharedCalendarStatusItem.name);
     } else {
           console.log("SHARED CALENDAR IS VISIBLE" , new Date(), this.sharedCalendarStatusItem.name);
           if ( this.itHasBeenMoreThanTimeInterval && this.componentIsActuallyVisible && this.user !== null ){
            console.log('it has been more than', this.updateTimeInterval , ' sec' )
        
             
              // this.updateThisSharedcalendarInfo_http() // updatecomponent 
              // this.setupUpdateInterval() // restart time interval
            
  
           }
     }
  }
 

  ngOnInit(): void { 

    console.log('today date => ', today)

    this.user = this.userObjectUpdateService.userEmail
 
     this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
     .subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
     })
     
    this.sharedCalendarStatusItem = this.sharedCalendarStatusService.getSharedCalendarStatus_By_id(this.index_id)
    
   // if ( this.user !== null ){this.setupUpdateInterval()} // activate interval to check on updating component every 10 sec ? while is visible and online 

    console.log('sharedCalendarStatusItem => ', this.sharedCalendarStatusItem)

    console.log( 'can view it => ', this.canViewIt, 'can use it => ', this.canUseIt )
    
    
    const newDate= new Date()
    this.selectedDates.push(newDate)
    /**
     * set selectedRangeDatesInfo to the one that fit today Date() or the first in line after in this month 
     * or a previous month 
     * 
     * 1- check if there is one existing 
     * 2- if non existent then show something 
     */
    this.onDateRangeInfoExist()

    const isSameDateAsClickedBefore = false 

    if ( !this.isParagraphEdit){
      if ( this.aRangeDateInfoExist ){ this.checkWhichDateRangeItBelongTo(today , isSameDateAsClickedBefore, 0 ) }
      if ( !this.aRangeDateInfoExist ){ 
                 this.selectedRangeDatesInfo = undefined, 
                 this.selectedRangeDatesInfo_Index = undefined,
                 this.latestSelectedRangeDatesInfo_Index = undefined 
      }

      this.selected = this.updateSelectedDates()

      console.log('INDEX => ', this.findPresentDateExistingRange_Index(this.dateRanges).index )
      if ( this.findPresentDateExistingRange_Index(this.dateRanges).index !== undefined ){

        const newIndex = this.dateRanges[this.findPresentDateExistingRange_Index(this.dateRanges).index].startingIndex
        this.isUpdatingCalendar = true 
        if ( !this.isRestartingComponent){this.setInitialSelectedDateRangeInfo(newIndex)}
        if (  this.isRestartingComponent){this.setInitialSelectedDateRangeInfo(this.latestSelectedRangeDatesInfo_Index)}
        
       
      }
    }
 
  
    if (this.isParagraphEdit){
      this.componentIsActuallyVisible = false 
        this.editFeatureEventFromSharedCalendarStatusList =  this.editFeatureItem
        .subscribe((data)=> {
          this.checkIfMatchId(data)
        })
    }

    this.observeIfComponentIsVisible = this.isComponentVisible?.subscribe((newIsVisible: boolean)=> {
      this.onComponentIsActuallyVisible(newIsVisible)
    })
 
  }

  onComponentIsActuallyVisible(isVisible: boolean ) {
    this.componentIsActuallyVisible = isVisible 
    console.log(this.sharedCalendarStatusItem._id, this.sharedCalendarStatusItem.name, 'IS VISIBLE => ', this.componentIsActuallyVisible )
    if ( this.componentIsActuallyVisible && this.itHasBeenMoreThanTimeInterval ){
         this.updateThisSharedcalendarInfo_http() // updatecomponent 
         this.setupUpdateInterval() // restart time interval
    }
  }


  setupUpdateInterval() {

   this.clearInterval() // Clear existing interval, if any

   if ( this.user !== null ){

   this.intervalLong = setInterval(()=> {

    if ( document.hidden ){
        // if document is hidden and it has been more than time = false it has to become true 
        // but if it has been true it doesn't have to become false , it remain true 
        if ( !this.itHasBeenMoreThanTimeInterval ){
          this.itHasBeenMoreThanTimeInterval = !this.itHasBeenMoreThanTimeInterval
        }
    }
    if ( !document.hidden && this.componentIsActuallyVisible ){ 
        this.updateThisSharedcalendarInfo_http()
        this.itHasBeenMoreThanTimeInterval = false 
    }
    if ( !document.hidden && !this.componentIsActuallyVisible ){ 
         if ( !this.itHasBeenMoreThanTimeInterval ){
               this.itHasBeenMoreThanTimeInterval = !this.itHasBeenMoreThanTimeInterval
         }
  }


   },this.updateTimeInterval)
  }

  }

  clearInterval() {
    clearInterval(this.intervalLong);
  }



  updateThisSharedcalendarInfo_http(){
    console.log('IS UPDATING THIS SHARED_CALENDAR_STATUS', this.sharedCalendarStatusItem.name , 'is online ', this.isOnlineOfflineService.isOnline)

    if ( this.isOnlineOfflineService.isOnline ){

    this.itHasBeenMoreThanTimeInterval = false 
    

    if ( this.user !== null ){ 
      this.ngZone.run(()=> {
      //  http call this component 
      console.log('IS UPDATING CALENDAR STATUS')
      this.getComponentsHttpRequestService.httpGetUserComponentBy_id(this.user, this.sharedCalendarStatusItem._id)
          .subscribe((res: SharedCalendarStatus )=> {
            console.log('update component with => ',res) 

            const newSharedCalendarStatusItem = res
            console.log("newSharedCalendarStatusItem => ", newSharedCalendarStatusItem )

            this.sharedCalendarStatusItem = res 

            const indexShared= this.sharedCalendarStatusService.sharedCalendarStatusItems.findIndex(x=> x._id === newSharedCalendarStatusItem._id )
            this.sharedCalendarStatusService.sharedCalendarStatusItems[indexShared] = newSharedCalendarStatusItem 

            const nullIndex = this.initialNullSetupService.initialNullSharedCalendarStatusItems.findIndex(x=> x._id === newSharedCalendarStatusItem._id )
            this.initialNullSetupService.initialNullSharedCalendarStatusItems[nullIndex] = newSharedCalendarStatusItem

            this.isRestartingComponent = true 
            this.ngOnInit()

            // with latestSelectedRangeDatesInfo_Index check which Date() it was 
            // make sure latestSelectedRangeDatesInfo_Index still exist

            if ( !this.onDateRangeInfoExist()){
              this.selectedRangeDatesInfo = undefined, 
              this.selectedRangeDatesInfo_Index = undefined,
              this.latestSelectedRangeDatesInfo_Index = undefined 
            }
            
            if ( this.onDateRangeInfoExist() ){

                if ( this.sharedCalendarStatusItem.rangeDatesInfos.length > this.latestSelectedRangeDatesInfo_Index ){
                     const previousViewingDate = new Date( this.sharedCalendarStatusItem.rangeDatesInfos[this.latestSelectedRangeDatesInfo_Index].dateStartEnd.dateEnd);
                     console.log('latestSelectedRangeDatesInfo_Index => ',this.latestSelectedRangeDatesInfo_Index)
                     this.onDateSelection(previousViewingDate)

                    //  this.changeSelectedMonth(previousViewingDate)
                     this.goToDate(previousViewingDate)
                 
                }
                if ( this.sharedCalendarStatusItem.rangeDatesInfos.length <= this.latestSelectedRangeDatesInfo_Index ){
                     const previousViewingDate = new Date(today);
                     console.log('latestSelectedRangeDatesInfo_Index => ',this.latestSelectedRangeDatesInfo_Index)
                     this.onDateSelection(previousViewingDate)
                     
                    //  this.changeSelectedMonth(previousViewingDate)
                     this.goToDate(previousViewingDate)
     
                }
                
            }

          this.isRestartingComponent = false 


          }, error=> {
            console.log(error )
          })

      })

      
      this.cdr.detectChanges()

      
      


    }

    if ( this.user === null ){ 
      this.ngZone.run(()=> {
        console.log('IS UPDATING NULL USER CALENDAR STATUS')
        this.sharedCalendarStatusItem = this.sharedCalendarStatusService.getSharedCalendarStatus_By_id(this.index_id)
      })
      this.cdr.detectChanges()

      setTimeout(()=>{
        this.isUpdatingCalendar = false 
      },200)

    }

  }

  if ( !this.isOnlineOfflineService.isOnline ){
    console.log( 'throw error on is OFFLINE')


  }
    

  }

  setInitialSelectedDateRangeInfo(index: number ){
  
      
      // console.log('running found index =>', index )
      this.selectedRangeDatesInfo_Index = index
      if ( !this.isRestartingComponent){ this.latestSelectedRangeDatesInfo_Index = index } 

       this.selectedRangeDatesInfo = this.sharedCalendarStatusItem.rangeDatesInfos[index]

      setTimeout(() => {
        this.isUpdatingCalendar = false
      }, 200);

  }

 

  updateDateRanges(){  // GET OUT COLOR

    this.dateRanges = []
    let dateRanges:any[] = []
    for ( let i=0; i<this.sharedCalendarStatusItem.rangeDatesInfos.length ; i++ ){
          
          const newStart: string =  this.sharedCalendarStatusItem.rangeDatesInfos[i].dateStartEnd.dateStart
          const newEnd: string = this.sharedCalendarStatusItem.rangeDatesInfos[i].dateStartEnd.dateEnd 
          
          const startDate = new Date(newStart)
          startDate.setHours(0, 0, 0, 0);
          const endDate = new Date(newEnd)
          endDate.setHours(23, 59, 59, 999);
   
          const dateRange = { start: startDate , end: endDate, startingIndex: i }
      
    
          dateRanges.push(dateRange)

          dateRanges.sort((a, b) => a.start - b.start); // finally sort the ranges 
    }

       this.dateRanges = dateRanges

    return dateRanges
  }

  updateSelectedDates(){ 


    this.dateRanges =  this.updateDateRanges()

    const allDates: Date[] = [];

    for (const range of this.dateRanges) {
         const currentDate = new Date(range.start);
               while (currentDate <= range.end) {
                      allDates.push(new Date(currentDate));
                      currentDate.setDate(currentDate.getDate() + 1);
               }
    }

    
    const presentDateRange_Index: any = this.findPresentDateExistingRange_Index(this.dateRanges)

    const newSelectedDates: any[] = []
 
    for ( let i=0; i<allDates.length ; i++ ){
          newSelectedDates.push( { date: allDates[i], id: this.allocateSelectedDateColor(allDates[i], this.dateRanges, presentDateRange_Index.presentDateRange , presentDateRange_Index.index) } ) 
    }

   return newSelectedDates

  }

  findPresentDateExistingRange_Index(dateRanges: any[]): any { // check if present date belong to any dateRanges and which index

    const presentDate = new Date()
    let foundPresentDateRange = false 
    let indexOfDateRangeWhichIncludePresentDate: number = undefined
    for (let i = 0; i < dateRanges.length; i++) { 
         const { start, end } = dateRanges[i];
                 if (presentDate >= start && presentDate <= end) {
                     // The presentDate is within this dateRange
                     // const indexOfDateRangeWhichIncludePresentDate: number = i // will find the first dateRange with it inside and we will give that 
                                                             // dateRange the orangecolor 
                     foundPresentDateRange = true
                     indexOfDateRangeWhichIncludePresentDate = i
                     console.log(`presentDate is within dateRange at index ${indexOfDateRangeWhichIncludePresentDate}`, foundPresentDateRange);

                    break; // Exit loop since we found the index
                 }
                 if ( i == dateRanges.length-1 && !foundPresentDateRange){
                     foundPresentDateRange = false
                     indexOfDateRangeWhichIncludePresentDate = undefined
                     console.log( `presentDate is within dateRange =`, foundPresentDateRange)
                 }
    }

    return { presentDateRange: foundPresentDateRange , index: indexOfDateRangeWhichIncludePresentDate }
  }

  allocateSelectedDateColor( selectedDate: Date , dateRanges: any[], foundPresentDateRange: boolean, indexOfDateRangeWhichIncludePresentDate: number  ): number { // return id of the color + if belong to single range 

    const arrayIndexOfDateRangesFound: number[] = []

    let colorRangeNumber: number = undefined 

    /**
     * this for loop find the dateRange/s in which selectedDate is present 
     */
    for ( let i=0 ; i< dateRanges.length ; i++ ){
      if ( this.isDateInRange(selectedDate, dateRanges[i].start , dateRanges[i].end ) ){ 
            arrayIndexOfDateRangesFound.push(i) 
      }
    }

    if ( foundPresentDateRange === true ){ // make reference color as before/after start date of presentDateRange 

          if ( arrayIndexOfDateRangesFound.length === 1 ){
            if ( arrayIndexOfDateRangesFound[0] < indexOfDateRangeWhichIncludePresentDate ){
               if ( arrayIndexOfDateRangesFound[0]%2 === 0 ){
                    colorRangeNumber = 1
               }
               if ( arrayIndexOfDateRangesFound[0]%2 !== 0 ){
                    colorRangeNumber = 2
               }
            }
            if ( arrayIndexOfDateRangesFound[0] > indexOfDateRangeWhichIncludePresentDate ){
               if ( arrayIndexOfDateRangesFound[0]%2 !== 0 ){
                    colorRangeNumber = 4
               }
               if ( arrayIndexOfDateRangesFound[0]%2 === 0 ){
                    colorRangeNumber = 5
               }
            }
            if ( arrayIndexOfDateRangesFound[0] === indexOfDateRangeWhichIncludePresentDate ){
                   colorRangeNumber = 3
            }

          }

          if ( arrayIndexOfDateRangesFound.length > 1 ){
            if ( arrayIndexOfDateRangesFound[0] < indexOfDateRangeWhichIncludePresentDate && arrayIndexOfDateRangesFound[1] < indexOfDateRangeWhichIncludePresentDate  ){
                 colorRangeNumber = 6
            }
            if ( arrayIndexOfDateRangesFound[0] < indexOfDateRangeWhichIncludePresentDate && arrayIndexOfDateRangesFound[1] == indexOfDateRangeWhichIncludePresentDate  ){
                 
                 if ( arrayIndexOfDateRangesFound[0]%2 !== 0 ){ colorRangeNumber = 10 }
                 if ( arrayIndexOfDateRangesFound[0]%2 === 0 ){ colorRangeNumber = 7 }
            }
            if ( arrayIndexOfDateRangesFound[0] === indexOfDateRangeWhichIncludePresentDate && arrayIndexOfDateRangesFound[1] > indexOfDateRangeWhichIncludePresentDate ){
                 
                 if ( arrayIndexOfDateRangesFound[0]%2 !== 0 ){ colorRangeNumber = 11 }
                 if ( arrayIndexOfDateRangesFound[0]%2 === 0 ){ colorRangeNumber = 8 }
            }
            if ( arrayIndexOfDateRangesFound[0] > indexOfDateRangeWhichIncludePresentDate && arrayIndexOfDateRangesFound[1] > indexOfDateRangeWhichIncludePresentDate ){
                 colorRangeNumber = 9
            }

          }


    }

    if ( foundPresentDateRange === false ){ // make reference as before / after presentDate 

      if ( arrayIndexOfDateRangesFound.length === 1 ){ // found in only 1 dateRange 
        if ( dateRanges[arrayIndexOfDateRangesFound[0]].start < today ){ // if that dateRange start date is < of present date 
          if ( arrayIndexOfDateRangesFound[0]%2 === 0 ){
               colorRangeNumber = 1
          }
          if ( arrayIndexOfDateRangesFound[0]%2 !== 0 ){
               colorRangeNumber = 2
          }
        }
      }

      if ( arrayIndexOfDateRangesFound.length > 1 ){ // found in multiple dateRanges
        if ( dateRanges[arrayIndexOfDateRangesFound[0]].start < today ){ // if that dateRange start date is < of present date 
               colorRangeNumber = 6
        }
      }

      if ( arrayIndexOfDateRangesFound.length === 1 ){ // found in only 1 dateRange 
        if ( dateRanges[arrayIndexOfDateRangesFound[0]].start > today ){ // if that dateRange start date is < of present date 
          if ( arrayIndexOfDateRangesFound[0]%2 === 0 ){
               colorRangeNumber = 4
          }
          if ( arrayIndexOfDateRangesFound[0]%2 !== 0 ){
               colorRangeNumber = 5
          }
        }
      }

      if ( arrayIndexOfDateRangesFound.length > 1 ){ // found in multiple dateRanges
        if ( dateRanges[arrayIndexOfDateRangesFound[0]].start > today ){ // if that dateRange start date is < of present date 
               colorRangeNumber = 9
        }
      }



    }


    return colorRangeNumber // color type and boolean if it's single range or double range 

  }

  checkWhichDateRangeItBelongTo(dateToCheck: Date , isSameDateAsClickedBefore: boolean , counter: number ){ // find which range the date belong to , or find the nearest

    let count = 0
    const arrayOfFoundRangeDates: number[] = []

    let counterTrial = 0

    console.log('sharedCalendarStatusItem rangeDatesInfos => ',this.sharedCalendarStatusItem.rangeDatesInfos)
    console.log('is same click as befor ? => ', isSameDateAsClickedBefore )
    for ( let i=0 ; i<this.sharedCalendarStatusItem.rangeDatesInfos.length ; i++ ){
          
          const startDate = new Date(this.sharedCalendarStatusItem.rangeDatesInfos[i].dateStartEnd.dateStart)
          const endDate = new Date(this.sharedCalendarStatusItem.rangeDatesInfos[i].dateStartEnd.dateEnd)

          startDate.setHours(0, 0, 0, 0);
          endDate.setHours(23, 59, 59, 999);

                       if ( !isSameDateAsClickedBefore ){
                            if ( this.isDateInRange( dateToCheck , startDate, endDate ) === true ){
                                 if ( count === 0 ){
                                      console.log(this.isDateInRange(dateToCheck , startDate, endDate ), 'index => ', i)
                                      this.selectedRangeDatesInfo_Index = i
                                      if ( !this.isRestartingComponent){ this.latestSelectedRangeDatesInfo_Index = i } 
                                      this.setSelectedDateRange(i)
                                      count = 1
                                      counterTrial++
                                 }
                             }

                             // if it's not found in range 
                             if ( this.isDateInRange(dateToCheck , startDate, endDate ) === false  ){

                              // for each dateRange find how many days of difference compare to today



                                  if ( count === 0 ){
                                       if ( i == this.sharedCalendarStatusItem.rangeDatesInfos.length-1){
                                      //  console.log( this.findDaysDifferenceFromSelectedDate(dateToCheck) )
                                      //  console.log(this.findSmallerAbsoluteValue(this.findDaysDifferenceFromSelectedDate(dateToCheck)))
                                      //  const closestDateRangeIndex = this.findSmallerAbsoluteValue(this.findDaysDifferenceFromSelectedDate(dateToCheck))
                                      console.log( "A RANGE DATE DOESN'T EXIST")
                                      //  this.selectedRangeDatesInfo_Index = closestDateRangeIndex 
                                      this.aRangeDateInfoExist = false 
                                      //  if ( !this.isRestartingComponent){ this.latestSelectedRangeDatesInfo_Index = closestDateRangeIndex } 
                                      //  this.setSelectedDateRange(closestDateRangeIndex)
                                      //  counterTrial++
                                      }
                                  }
                             }
                       }

                       if ( isSameDateAsClickedBefore ){
                        
                        if ( this.isDateInRange( dateToCheck , startDate, endDate ) === true ){
                        
                          arrayOfFoundRangeDates.push(i)

                        }

                        console.log(arrayOfFoundRangeDates)

                        if ( this.isDateInRange(dateToCheck , startDate, endDate ) === false  ){

                          // for each dateRange find how many days of difference compare to today

                              if ( count === 0 ){
                                   if ( i == this.sharedCalendarStatusItem.rangeDatesInfos.length-1){
                                   console.log( this.findDaysDifferenceFromSelectedDate(dateToCheck) )
                                   console.log(this.findSmallerAbsoluteValue(this.findDaysDifferenceFromSelectedDate(dateToCheck)))
                                   const closestDateRangeIndex = this.findSmallerAbsoluteValue(this.findDaysDifferenceFromSelectedDate(dateToCheck))

                                   this.selectedRangeDatesInfo_Index = closestDateRangeIndex
                                   if ( !this.isRestartingComponent){ this.latestSelectedRangeDatesInfo_Index = closestDateRangeIndex } 

                                   this.setSelectedDateRange(closestDateRangeIndex)
                                   }
                              }
                         }
                        }

    }
    if ( isSameDateAsClickedBefore && this.onDateRangeInfoExist() ){
        
      if ( this.checkifDateBelongToAnyRange(dateToCheck)){
        const remaining = this.remainingToClosestMultiple(counter, arrayOfFoundRangeDates.length );
        console.log('referring index => ', arrayOfFoundRangeDates[remaining])
        this.selectedRangeDatesInfo_Index = arrayOfFoundRangeDates[remaining]
        if ( !this.isRestartingComponent){ this.latestSelectedRangeDatesInfo_Index = arrayOfFoundRangeDates[remaining] } 
        this.setSelectedDateRange(arrayOfFoundRangeDates[remaining])
      }

      if ( !this.checkifDateBelongToAnyRange(dateToCheck)){
        const closestDateRangeIndex = this.findSmallerAbsoluteValue(this.findDaysDifferenceFromSelectedDate(dateToCheck))
        this.selectedRangeDatesInfo_Index = closestDateRangeIndex
        if ( !this.isRestartingComponent){ this.latestSelectedRangeDatesInfo_Index = closestDateRangeIndex } 
        this.setSelectedDateRange(closestDateRangeIndex)
      }
    }

    if ( isSameDateAsClickedBefore && !this.onDateRangeInfoExist() ){
        this.selectedRangeDatesInfo = undefined
    }

    console.log('counterTrial = ', counterTrial)



  }

  checkifDateBelongToAnyRange(dateToCheck: Date ): boolean {

    let exist: boolean = false 
    for ( let i=0 ; i<this.sharedCalendarStatusItem.rangeDatesInfos.length ; i++ ){
          
      const startDate = new Date(this.sharedCalendarStatusItem.rangeDatesInfos[i].dateStartEnd.dateStart)
      const endDate = new Date(this.sharedCalendarStatusItem.rangeDatesInfos[i].dateStartEnd.dateEnd)

      if ( this.isDateInRange( dateToCheck , startDate, endDate ) === true ){ 
        exist = true
        return exist
      }

    }

  }

  remainingToClosestMultiple(number, divisor) {
    return number % divisor;
  }

  setSelectedDateRange(index: number ){

    this.aRangeDateInfoExist = true

    this.ngZone.run(()=>{
         this.selectedRangeDatesInfo = this.sharedCalendarStatusItem.rangeDatesInfos[index]
         this.selectedRangeDatesInfo_Index = index 
         if ( !this.isRestartingComponent){ this.latestSelectedRangeDatesInfo_Index = index } 
         console.log(' selected rangeDateInfo + selected index => ',this.selectedRangeDatesInfo, this.selectedRangeDatesInfo_Index  )

         this.changeSelectedDates.next()
    })
    this.cdr.detectChanges()
    console.log('selectedRangeDatesInfo_Index => ', this.selectedRangeDatesInfo_Index, 'selectedRangeDatesInfo = ', this.selectedRangeDatesInfo )

    if ( !this.isRestartingComponent ){
      this.calendar.activeDate = new Date( this.sharedCalendarStatusItem.rangeDatesInfos[index].dateStartEnd.dateEnd);
    }
    if ( this.isRestartingComponent ){
      if ( this.sharedCalendarStatusItem.rangeDatesInfos.length > this.latestSelectedRangeDatesInfo_Index ){
           this.calendar.activeDate = new Date( this.sharedCalendarStatusItem.rangeDatesInfos[this.latestSelectedRangeDatesInfo_Index].dateStartEnd.dateEnd);
      }
      if ( this.sharedCalendarStatusItem.rangeDatesInfos.length <= this.latestSelectedRangeDatesInfo_Index ){
        this.calendar.activeDate = new Date( this.sharedCalendarStatusItem.rangeDatesInfos[index].dateStartEnd.dateEnd);
   }
    }
    
  }

  findDaysDifferenceFromSelectedDate(selectedDate: Date ): number[] {

    let dateRangesDaysDifference: number[] = []

    for ( let i=0 ; i<this.sharedCalendarStatusItem.rangeDatesInfos.length ; i++ ){

      const startDate = new Date(this.sharedCalendarStatusItem.rangeDatesInfos[i].dateStartEnd.dateStart)
      dateRangesDaysDifference.push( this.timeAlignService.getDaysDifference( selectedDate , startDate ))

    }

    return dateRangesDaysDifference

  }


  findSmallerAbsoluteValue( arrayOfDatesNumber: number[] ): number {
    let smallest = Math.abs(arrayOfDatesNumber[0]);
    let smallestNumberIndex: number = 0

    for (let i = 1; i < arrayOfDatesNumber.length; i++) {
      const currentAbsValue = Math.abs(arrayOfDatesNumber[i]);
      if (currentAbsValue < smallest) {
          smallest = currentAbsValue;
          smallestNumberIndex = i
      }
    }

    return smallestNumberIndex  ;
  }



  onDateRangeInfoExist(){

    if ( this.sharedCalendarStatusItem.rangeDatesInfos.length > 0 ){ 
         this.aRangeDateInfoExist = true 
         return this.aRangeDateInfoExist
    }
    if ( this.sharedCalendarStatusItem.rangeDatesInfos.length == 0 ){ 
         this.aRangeDateInfoExist = false 
         return this.aRangeDateInfoExist
    }
  }

  // changeSelectedMonth(newDate: Date) {
  //   this.calendar.monthSelected.next(newDate);
  //   this.cdr.detectChanges();
  // }

  goToDate(newDate: Date) {
    this.calendar._goToDateInView( newDate , 'month')
 }

  ngAfterViewInit(): void {
    this.calendarMonthSubscription = this.calendar.monthSelected.subscribe((date: Date) => {
      const month = date.getMonth();
      const year = date.getFullYear();
      console.log(`Currently displayed month: ${month}, Year: ${year}`);
    });


  }

  dateClassCallback = (date: Date): string => {
 
    this.selected = this.updateSelectedDates() // update selected  

    // for each selected allocate a color depending on which daterange they belong to 
    // if they belong to more than 1 dateRange give them bicolor 

    const selectedDate = this.selected.find(item => this.isSameDay(item.date, date));
    if (selectedDate) {
      return `range-${selectedDate.id}`
    }
    return '';
  };


  triggerDateClassCallback() {
    this.dateClassCallback(new Date()); // Call the function with a new Date or any other date you want to check
    this.cdr.markForCheck();
  }
  
  isDateInRange(date: Date, start: Date, end: Date): boolean {
    // Adjust the comparison to consider the time component as well
    const startDate = new Date(start.getFullYear(), start.getMonth(), start.getDate());
    const endDate = new Date(end.getFullYear(), end.getMonth(), end.getDate());
  
    return date >= startDate && date <= endDate;
  }

  isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  }

  isDateAfterToday( date: Date ): boolean {

    if ( date > today ) { return true }
    if ( date <= today ) { return false }

  }

 
  
  onDateSelection(selectedDate: Date){

    console.log(selectedDate)
    let isSameDateAsClickedBefore: boolean = false 

    this.selectedDatesArraytrial.push(selectedDate)
    console.log(this.selectedDatesArraytrial) 

    this.counter = this.counter+1


    if ( this.selectedDatesArraytrial.length>1 && this.selectedDatesArraytrial[this.selectedDatesArraytrial.length-1].getTime() === this.selectedDatesArraytrial[this.selectedDatesArraytrial.length-2].getTime() ){
      isSameDateAsClickedBefore = true 
      if( this.selectedDatesArraytrial.length == 2 ){this.selectedDatesArraytrial.splice(0, 1)}
      console.log('isSameDateAsClickedBefore = ', isSameDateAsClickedBefore )
    }

    if ( this.selectedDatesArraytrial.length>1 && this.selectedDatesArraytrial[this.selectedDatesArraytrial.length-1].getTime() !== this.selectedDatesArraytrial[this.selectedDatesArraytrial.length-2].getTime() ){
      isSameDateAsClickedBefore =  false 
      this.counter = 0
      if( this.selectedDatesArraytrial.length == 2 ){this.selectedDatesArraytrial.splice(0, 1)}
      console.log('isSameDateAsClickedBefore = ', isSameDateAsClickedBefore )
    }

      console.log('counter = ', this.counter)
      this.checkWhichDateRangeItBelongTo(selectedDate , isSameDateAsClickedBefore, this.counter ) // add a counter to this function
      // add a counter to this function so we know if we are checking for second possible dateRange 
      // so that by clicking a second time the view show the other dateRange 
  }


  onAddRange(){
    this.isAddingRange = true 
    console.log('is adding range')
    this.isEditingRangeDate = false

    this.dms.addSharedCalendarStatusItemRangeComponent({
      sharedCalendarStatusItem: this.sharedCalendarStatusItem ,
      isEditingRangeDate: this.isEditingRangeDate,
      selectedRangeDatesInfo_Index: this.selectedRangeDatesInfo_Index
    }).subscribe((res: any )=> {

      console.log('new dateRange to add  => ',res)

      if ( res !== undefined ){

        this.isUpdatingCalendar = true 
  
       const newStartDate = res.start // Replace this with your Date object
             newStartDate.setHours(0, 0, 0, 0);
      

       const startYear = newStartDate.getFullYear().toString();
       const startMonth = (newStartDate.getMonth() + 1).toString(); // Add 1 to the month to get the correct value (1-12)
       const startDay = newStartDate.getDate().toString();

       const newEndDate = res.end // Replace this with your Date object
             newEndDate.setHours(23, 59, 59, 999);

       const endYear = newEndDate.getFullYear().toString();
       const endMonth = (newEndDate.getMonth() + 1).toString(); // Add 1 to the month to get the correct value (1-12)
       const endDay = newEndDate.getDate().toString();

      const newStartDateInput: string = startYear+'/'+startMonth+'/'+startDay 
      const newEndDateInput: string = endYear+'/'+endMonth+'/'+endDay
      console.log(newStartDateInput, newEndDateInput )

        const newDateStartEnd = new DateStartEnd ( newStartDateInput , newEndDateInput )
        
        const newRangeInfos: RangeInfo[] = this.sharedCalendarStatusService.createRangeInfosClones(this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos)
        const newUsersAccess: UsersAccess = this.sharedCalendarStatusService.createUsersAccessClone_OfDeterminedRangeInfo( this.sharedCalendarStatusItem.rangeDatesInfoSetting.usersAccess) 
        const newRangeDatesInfo = new RangeDatesInfo ( newDateStartEnd, newRangeInfos, newUsersAccess )

               // reorder the rangeDateInfos including the new range 
        const currentrangeDatesInfos: RangeDatesInfo[] = this.sharedCalendarStatusItem.rangeDatesInfos 
              // find index right after we can add the new rangeDateInfo 

              if ( currentrangeDatesInfos.length === 0 ){
                   currentrangeDatesInfos.push(newRangeDatesInfo)
                   this.sharedCalendarStatusItem.rangeDatesInfos = currentrangeDatesInfos

              }
              if ( currentrangeDatesInfos.length == 1 ){
                 
                const currentStartingDate = new Date(currentrangeDatesInfos[0].dateStartEnd.dateStart)
                const newStartingDate = new Date (newStartDateInput)
                   if ( currentStartingDate > newStartingDate  ){
                        currentrangeDatesInfos.splice(0,0, newRangeDatesInfo )
                        this.sharedCalendarStatusItem.rangeDatesInfos = currentrangeDatesInfos
                   }
                   if ( currentStartingDate < newStartingDate  ){
                        currentrangeDatesInfos.push(newRangeDatesInfo)
                        this.sharedCalendarStatusItem.rangeDatesInfos = currentrangeDatesInfos
                   }
                   
              }
              if ( currentrangeDatesInfos.length > 1 ){
                   const firstStartingDate = new Date(currentrangeDatesInfos[0].dateStartEnd.dateStart)
                   const lastStartingDate = new Date(currentrangeDatesInfos[currentrangeDatesInfos.length-1].dateStartEnd.dateStart)
                   const newStartingDate = new Date (newStartDateInput)

                   console.log('newStartingDate', newStartingDate, 'firstStartingDate', firstStartingDate, 'lastStartingDate', lastStartingDate)
                   if ( lastStartingDate < newStartingDate ){
                        currentrangeDatesInfos.push(newRangeDatesInfo)
                        this.sharedCalendarStatusItem.rangeDatesInfos = currentrangeDatesInfos
                   }
                   if ( firstStartingDate > newStartingDate ){
                        currentrangeDatesInfos.splice(0,0, newRangeDatesInfo )
                        this.sharedCalendarStatusItem.rangeDatesInfos = currentrangeDatesInfos
                   }
                   if ( lastStartingDate > newStartingDate && firstStartingDate < newStartingDate ){

                    console.log('currentrangeDatesInfos.length => ',currentrangeDatesInfos.length)
                    const currentrangeDatesInfosLength = currentrangeDatesInfos.length-1
                    for ( let i=0; i < currentrangeDatesInfosLength  ; i++){

                      console.log('starting loop')
                      const newFirstStartingDate = new Date (currentrangeDatesInfos[i].dateStartEnd.dateStart)
                      const nextIndex = i+1
                      const newSecondStartingDate = new Date (currentrangeDatesInfos[nextIndex].dateStartEnd.dateStart)

                  
                      if ( this.isDateBetweenRanges(newStartingDate, newFirstStartingDate, newSecondStartingDate  ) ){
                           currentrangeDatesInfos.splice( nextIndex ,0, newRangeDatesInfo )

                           this.sharedCalendarStatusItem.rangeDatesInfos = currentrangeDatesInfos
                          
                      }
                    }

                   }
              }
              


        this.ngZone.run(()=>{


       /**
       *  if user is logged in ( user = 'something' )
       */
       if ( this.user !== null ){
        this.postComponentsHttpRequestService.httpUpdateComponent( this.user , 'sharedCalendarStatus', this.sharedCalendarStatusItem )
            .subscribe((response: SharedCalendarStatus)=> {
             console.log(response) 

             this.sharedCalendarStatusService.sharedCalendarStatusItems[ this.sharedCalendarStatusService.sharedCalendarStatusItems.findIndex(x=> x._id === this.sharedCalendarStatusItem._id)] = this.sharedCalendarStatusItem 
             this.initialNullSetupService.initialNullSharedCalendarStatusItems[ this.initialNullSetupService.initialNullSharedCalendarStatusItems.findIndex(x=> x._id === this.sharedCalendarStatusItem._id)] = this.sharedCalendarStatusItem
      
          }, error => {
            console.log(error)

            // in case of error we keep the same rangeInfo as before using presentRangedatesInfo again 
            // const indexOfLastrangeAdded = currentrangeDatesInfos.length-1
            // currentrangeDatesInfos.splice(indexOfLastrangeAdded, 1)
            // this.sharedCalendarStatusItem.rangeDatesInfos = currentrangeDatesInfos
            // this.sharedCalendarStatusService.sharedCalendarStatusItems[ this.sharedCalendarStatusService.sharedCalendarStatusItems.findIndex(x=> x._id === this.sharedCalendarStatusItem._id)] = this.sharedCalendarStatusItem 
            // this.initialNullSetupService.initialNullSharedCalendarStatusItems[ this.initialNullSetupService.initialNullSharedCalendarStatusItems.findIndex(x=> x._id === this.sharedCalendarStatusItem._id)] = this.sharedCalendarStatusItem

  

            const errorMessage: string = "we weren't able to save the data on the server, maybe the network wasn't working. While we keep a local update, try again later to make it permanent"
            this.errorsDialogManagerService.PopUpErrorDialog({ textContent: errorMessage })
        
          }
          )
       }

       if ( this.user === null ){
            this.sharedCalendarStatusItem.rangeDatesInfos = currentrangeDatesInfos
            this.sharedCalendarStatusService.sharedCalendarStatusItems[ this.sharedCalendarStatusService.sharedCalendarStatusItems.findIndex(x=> x._id === this.sharedCalendarStatusItem._id)] = this.sharedCalendarStatusItem 
            this.initialNullSetupService.initialNullSharedCalendarStatusItems[ this.initialNullSetupService.initialNullSharedCalendarStatusItems.findIndex(x=> x._id === this.sharedCalendarStatusItem._id)] = this.sharedCalendarStatusItem
       }

      //  aaa aaa 
        this.selected = this.updateSelectedDates()

        this.onDateRangeInfoExist()

        this.aRangeDateInfoExist = true 
        this.onDateSelection( new Date (newEndDate))
 
        const newIndex = this.sharedCalendarStatusItem.rangeDatesInfos.length-1
        this.setInitialSelectedDateRangeInfo(newIndex)
      
        this.isUpdatingCalendar = false
        this.aRangeDateInfoExist = true 
        
        console.log(this.sharedCalendarStatusItem)
        this.goToDate( new Date (newEndDate)  )

        this.isAddingRange = false

        })
        this.cdr.detectChanges()

        this.goToDate( new Date (newEndDate) )
        this.changeSelectedDates.next()
     
  
      }

      if ( res === undefined ){

        
        this.ngZone.run(()=> {
          this.sharedCalendarStatusItem = this.sharedCalendarStatusService.getSharedCalendarStatus_By_id(this.index_id)
          this.selected = this.updateSelectedDates()
          this.isAddingRange = false 
        })
        this.cdr.detectChanges()
      }


    })
  }

  isDateBetweenRanges(date: Date, start: Date, secondStartDate: Date): boolean {
    // Adjust the comparison to consider the time component as well
    const startDate = new Date(start.getFullYear(), start.getMonth(), start.getDate());
    const endDate = new Date(secondStartDate.getFullYear(), secondStartDate.getMonth(), secondStartDate.getDate());
  
    return date > startDate && date < endDate;
  }


 

  onEditSharedCalendar(){

    const new_id = this.sharedCalendarStatusItem._id
    const newComponentName = 'sharedCalendarStatus'
    const newUIID = this.sharedCalendarStatusItem.UIID
    const newOwner = this.sharedCalendarStatusItem.owner
    const newAllUsersAllowed = this.sharedCalendarStatusItem.allUsersAllowed
    const newUserListAllowed = this.sharedCalendarStatusItem.userListAllowed
    const newDateAndTime = this.sharedCalendarStatusItem.dateAndTime
    const newName =  this.sharedCalendarStatusItem.name

    const newDateStartEnd = new DateStartEnd ('', '') 
    const newRangeInfos: RangeInfo[] = []

         for ( let i=0; i< this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos.length; i++ ){

               if (this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos[i].type === 'innerdropdown'){

                   const newType = "innerdropdown"
                   const newName = this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos[i].name 
                   const newUsersAccess = this.sharedCalendarStatusService.createUsersAccessClone_OfDeterminedRangeInfo(this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos[i].usersAccess)
                   const newRangeDetails: any[] = this.sharedCalendarStatusService.createRangeInfoDetailClone_OfDeterminedRangeInfo( newType, this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos[i].rangeDetails )

                   const newRangeInfo = new RangeInfo (newType, newName, newUsersAccess, newRangeDetails)

                   newRangeInfos.push(newRangeInfo)
               }

               if (this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos[i].type === 'innercheckbox'){

                   const newType = 'innercheckbox'
                   const newName = this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos[i].name 
                   const newUsersAccess = this.sharedCalendarStatusService.createUsersAccessClone_OfDeterminedRangeInfo(this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos[i].usersAccess)
                   const newRangeDetails: any[] = this.sharedCalendarStatusService.createRangeInfoDetailClone_OfDeterminedRangeInfo( newType, this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos[i].rangeDetails )

                   const newRangeInfo = new RangeInfo (newType, newName, newUsersAccess, newRangeDetails)

                   newRangeInfos.push(newRangeInfo)
               }

               if (this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos[i].type === 'innertext'){
               
                   const newType = 'innertext'
                   const newName = this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos[i].name 
                   const newUsersAccess = this.sharedCalendarStatusService.createUsersAccessClone_OfDeterminedRangeInfo(this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos[i].usersAccess)
                   const newRangeDetails: any[] = this.sharedCalendarStatusService.createRangeInfoDetailClone_OfDeterminedRangeInfo( newType, this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos[i].rangeDetails )

                   const newRangeInfo = new RangeInfo (newType, newName, newUsersAccess, newRangeDetails)

                   newRangeInfos.push(newRangeInfo)
               }

               if (this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos[i].type === 'innerinput'){

                   const newType = 'innerinput'
                   const newName = this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos[i].name 
                   const newUsersAccess = this.sharedCalendarStatusService.createUsersAccessClone_OfDeterminedRangeInfo(this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos[i].usersAccess)
                   const newRangeDetails: any[] = this.sharedCalendarStatusService.createRangeInfoDetailClone_OfDeterminedRangeInfo( newType, this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos[i].rangeDetails )

                   const newRangeInfo = new RangeInfo (newType, newName, newUsersAccess, newRangeDetails)

                   newRangeInfos.push(newRangeInfo)
               }

         }

         const newUsersAccess: UsersAccess = this.sharedCalendarStatusService.createUsersAccessClone_OfDeterminedRangeInfo(this.sharedCalendarStatusItem.rangeDatesInfoSetting.usersAccess)

         const newRangeDateInfoSetting = new RangeDatesInfo ( newDateStartEnd, newRangeInfos , newUsersAccess)

         const newRangeDateInfos = this.sharedCalendarStatusItem.rangeDatesInfos


    const newSharedCalendarStatusItem = new SharedCalendarStatus ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed, 
                                                                   newDateAndTime, newName , newRangeDateInfoSetting, newRangeDateInfos );


    if ( !this.isMobile()){

   
    this.dms.sharedCalendarStatusEditComponent({ 

      sharedCalendarStatusItem: newSharedCalendarStatusItem

      })
    .subscribe((res: SharedCalendarStatus) => {

      console.log('the dialog was closed ', res);

        if ( res !== undefined ){  
        console.log('updating shared calendar')

            this.ngZone.run(()=> {
              this.sharedCalendarStatusItem = res
              console.log( 'updatedSharedCalendarStatusItem', this.sharedCalendarStatusItem)
              this.selectedRangeDatesInfo = this.sharedCalendarStatusItem.rangeDatesInfos[0]
              console.log( 'selectedRangeDatesInfo', this.selectedRangeDatesInfo )
      
              this.sharedCalendarStatusService.sharedCalendarStatusItems[this.sharedCalendarStatusService.sharedCalendarStatusItems.findIndex(x=> x._id === this.index_id )] = res 
              this.initialNullSetupService.initialNullSharedCalendarStatusItems[this.initialNullSetupService.initialNullSharedCalendarStatusItems.findIndex(x=> x._id === this.index_id)] = res
             
              // this.ngOnInit()
              const canViewList = this.sharedCalendarStatusItem.rangeDatesInfoSetting.usersAccess.canView.names
              if ( canViewList.findIndex(x=> x == this.user) !== -1 || this.sharedCalendarStatusItem.owner == this.user ){ this.canViewIt = true } 

              const canUseList = this.sharedCalendarStatusItem.rangeDatesInfoSetting.usersAccess.canUse.names
              if ( canUseList.findIndex(x=> x == this.user) !== -1 || this.sharedCalendarStatusItem.owner == this.user  ){ this.canUseIt = true }

               console.log( 'can view it => ', this.canViewIt, 'can use it => ', this.canUseIt )
        
              this.updateListNamesAfterEdit.emit()
            })
            this.cdr.detectChanges() 
           

        }
        if ( res === undefined ){ 
             /**
              * close the pulsing IS EDIT COMPONENT
              */
             console.log('get the old calendar', this.sharedCalendarStatusItem = this.sharedCalendarStatusService.getSharedCalendarStatus_By_id(this.index_id) )

            this.sharedCalendarStatusItem = this.sharedCalendarStatusService.getSharedCalendarStatus_By_id(this.index_id)
            this.selectedRangeDatesInfo = this.sharedCalendarStatusItem.rangeDatesInfos[0]


             this.headerService.nextComponentIndexSelection(false)

             this.cdr.detectChanges()
        }    
       
      },error => {

      })
    }

    if (this.isMobile()){
      this.openBottomSheet(newSharedCalendarStatusItem)
    }
  }



  isMobile(){
    // credit to Timothy Huang for this regex test: 
    // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        return true
   }
   else{
        return false
   }
  }

  openBottomSheet(sharedCalendarStatusItem: SharedCalendarStatus ){
   
    this._bottomSheet.open( SharedCalendarStatusEditBottomSheetComponent , {
     data: {
      sharedCalendarStatusItem: sharedCalendarStatusItem
     }
    }).afterDismissed().subscribe((res: SharedCalendarStatus ) => {
      // Data received when the bottom sheet is dismissed
      console.log('Data received:', res);

      if ( res !== undefined ){  
        console.log('updating shared calendar')

        this.ngZone.run(()=> {
        this.sharedCalendarStatusItem = res
        this.selectedRangeDatesInfo = this.sharedCalendarStatusItem.rangeDatesInfos[0]

        this.sharedCalendarStatusService.sharedCalendarStatusItems[this.sharedCalendarStatusService.sharedCalendarStatusItems.findIndex(x=> x._id === this.index_id )] = res 
        this.initialNullSetupService.initialNullSharedCalendarStatusItems[this.initialNullSetupService.initialNullSharedCalendarStatusItems.findIndex(x=> x._id === this.index_id)] = res

        // this.ngOnInit()
        const canViewList = this.sharedCalendarStatusItem.rangeDatesInfoSetting.usersAccess.canView.names
        if ( canViewList.findIndex(x=> x == this.user) !== -1 || this.sharedCalendarStatusItem.owner == this.user ){ this.canViewIt = true }
        
        const canUseList = this.sharedCalendarStatusItem.rangeDatesInfoSetting.usersAccess.canUse.names
        if ( canUseList.findIndex(x=> x == this.user) !== -1 || this.sharedCalendarStatusItem.owner == this.user  ){ this.canUseIt = true }

        console.log( 'can view it => ', this.canViewIt, 'can use it => ', this.canUseIt )

        this.updateListNamesAfterEdit.emit()
        })
        this.cdr.detectChanges()

        }
        if ( res === undefined ){ 
             /**
              * close the pulsing IS EDIT COMPONENT
              */
             console.log('get the old calendar', this.sharedCalendarStatusItem = this.sharedCalendarStatusService.getSharedCalendarStatus_By_id(this.index_id) )

            this.sharedCalendarStatusItem = this.sharedCalendarStatusService.getSharedCalendarStatus_By_id(this.index_id)
            this.selectedRangeDatesInfo = this.sharedCalendarStatusItem.rangeDatesInfos[0]

             this.headerService.nextComponentIndexSelection(false)
             this.cdr.detectChanges()
        }    
       
      },error => {
        console.log(error)
      })
      
  }


  onDeleteRanges(){

    const newDateRanges: any[] =  this.sharedCalendarStatusService.createDateRanges_Clone(this.dateRanges) 
    this.isAddingRange = true 

    this.dms.deleteSharedCalendarStatusItemRangesComponent({

      dateRanges: newDateRanges , 
   

    }).subscribe((res: any[]) => {
      console.log( 'array of remaining index ',res)

      if (res === undefined ){

        this.ngZone.run(()=> {
          this.sharedCalendarStatusItem = this.sharedCalendarStatusService.getSharedCalendarStatus_By_id(this.index_id)
          this.selected = this.updateSelectedDates()
          this.isAddingRange = false 
        })
        this.cdr.detectChanges()
        
      }


      if ( res !== undefined ){
        console.log( res ) // array of numbers of dateRanges raemaining 

        console.log('showing date ranges = ', this.dateRanges)

          const new_id = this.sharedCalendarStatusItem._id
          const newComponentName = this.sharedCalendarStatusItem.componentName
          const newUIID = this.sharedCalendarStatusItem.UIID
          const newOwner = this.sharedCalendarStatusItem.owner
          const newAllUsersAllowed = this.sharedCalendarStatusItem.allUsersAllowed
          const newUserListAllowed = this.sharedCalendarStatusItem.userListAllowed
          const newdateAndTime = this.sharedCalendarStatusItem.dateAndTime
          const newName = this.sharedCalendarStatusItem.name 
          const newRangeDatesInfoSetting = this.sharedCalendarStatusItem.rangeDatesInfoSetting
          const newRangeDatesInfo: RangeDatesInfo[] = []

          for ( let i=0; i< res.length; i++ ){ 
            newRangeDatesInfo.push(this.sharedCalendarStatusItem.rangeDatesInfos[res[i]])
          }


          const newSharedCalendarStatusItem = new SharedCalendarStatus ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, 
                                                                         newUserListAllowed, newdateAndTime, newName, newRangeDatesInfoSetting, 
                                                                         newRangeDatesInfo ) 

       
        if ( this.user === null ){

          this.isUpdatingCalendar = true
          // this.isAddingRange = true 

          this.ngZone.run(()=>{
                                          
          this.sharedCalendarStatusItem = newSharedCalendarStatusItem
          console.log('sharedCalendarStatusItem => ', this.sharedCalendarStatusItem)

          this.sharedCalendarStatusService.sharedCalendarStatusItems[this.sharedCalendarStatusService.sharedCalendarStatusItems.findIndex(x=> x._id === this.sharedCalendarStatusItem._id )] = newSharedCalendarStatusItem 
          this.initialNullSetupService.initialNullSharedCalendarStatusItems[this.initialNullSetupService.initialNullSharedCalendarStatusItems.findIndex(x=> x._id === this.sharedCalendarStatusItem._id)] = newSharedCalendarStatusItem

          this.selected = this.updateSelectedDates()

          this.onDateRangeInfoExist()
          const isSameDateAsClickedBefore = false 
          if ( this.aRangeDateInfoExist ){ this.checkWhichDateRangeItBelongTo(today, isSameDateAsClickedBefore, 0 ) }
          if ( !this.aRangeDateInfoExist ){ this.selectedRangeDatesInfo = undefined }
          
              setTimeout(() => {
                  this.isUpdatingCalendar = false
                  this.isAddingRange = false 
                  }, 300);
              })
              this.cdr.detectChanges()
        }

        if ( this.user !== null ){

          this.isUpdatingCalendar = true
          

          this.ngZone.run(()=>{

            this.postComponentsHttpRequestService.httpUpdateComponent( this.user , 'sharedCalendarStatus', newSharedCalendarStatusItem )
            .subscribe((response: SharedCalendarStatus)=> {
             console.log(response) 

             this.sharedCalendarStatusItem = newSharedCalendarStatusItem
             this.sharedCalendarStatusService.sharedCalendarStatusItems[ this.sharedCalendarStatusService.sharedCalendarStatusItems.findIndex(x=> x._id === this.sharedCalendarStatusItem._id)] = this.sharedCalendarStatusItem 
             this.initialNullSetupService.initialNullSharedCalendarStatusItems[ this.initialNullSetupService.initialNullSharedCalendarStatusItems.findIndex(x=> x._id === this.sharedCalendarStatusItem._id)] = this.sharedCalendarStatusItem
      

          }, error => {
            console.log(error)

            // in case of error we keep the same rangeInfo as before using presentRangedatesInfo again 
         
            const errorMessage: string = "we weren't able to save the data on the server, maybe the network wasn't working. While we keep a local update, try again later to make it permanent"
            this.errorsDialogManagerService.PopUpErrorDialog({ textContent: errorMessage })


        
          }
          )
                                          
          this.selected = this.updateSelectedDates()

          this.onDateRangeInfoExist()
          const isSameDateAsClickedBefore = false 
          if ( this.aRangeDateInfoExist ){ this.checkWhichDateRangeItBelongTo(today, isSameDateAsClickedBefore, 0 ) }
          if ( !this.aRangeDateInfoExist ){ this.selectedRangeDatesInfo = undefined }
          
              setTimeout(() => {
                  this.isUpdatingCalendar = false 
                  this.isAddingRange = false
                  }, 300);
          })
          this.cdr.detectChanges()

        
        }


       }

      
   })
    
  }

  checkIfMatchId(data){

    this.currentFeature = data ;
    console.log(this.currentFeature)

    if (this.currentFeature.feature_id === this.index_id){
      this.onEditSharedCalendar()
    }

  };

  
  onEditRangeDate_index($event){
    console.log('editing rangeDate index => ', $event) 
    this.isEditingRangeDate = true 
    // send a clone of sharedCalendarStatusItem 

    this.dms.addSharedCalendarStatusItemRangeComponent({
      sharedCalendarStatusItem: this.sharedCalendarStatusItem ,
      isEditingRangeDate: this.isEditingRangeDate,
      selectedRangeDatesInfo_Index: $event 
      
    }).subscribe((res)=> { 

      console.log('new dateRange to add  => ',res)

      if ( res !== undefined ){

        this.isUpdatingCalendar = true 
        this.isAddingRange = true 

       const newStartDate = res.start // Replace this with your Date object
             newStartDate.setHours(0, 0, 0, 0);
      

       const startYear = newStartDate.getFullYear().toString();
       const startMonth = (newStartDate.getMonth() + 1).toString(); // Add 1 to the month to get the correct value (1-12)
       const startDay = newStartDate.getDate().toString();

       const newEndDate = res.end // Replace this with your Date object
             newEndDate.setHours(23, 59, 59, 999);

       const endYear = newEndDate.getFullYear().toString();
       const endMonth = (newEndDate.getMonth() + 1).toString(); // Add 1 to the month to get the correct value (1-12)
       const endDay = newEndDate.getDate().toString();

      const newStartDateInput: string = startYear+'/'+startMonth+'/'+startDay 
      const newEndDateInput: string = endYear+'/'+endMonth+'/'+endDay
      console.log(newStartDateInput, newEndDateInput )

        const newDateStartEnd = new DateStartEnd ( newStartDateInput , newEndDateInput )
 
        const newRangeInfos: RangeInfo[] = this.sharedCalendarStatusItem.rangeDatesInfos[$event].rangeInfos
        const newUsersAccess: UsersAccess = this.sharedCalendarStatusItem.rangeDatesInfoSetting.usersAccess 
        const newRangeDatesInfo = new RangeDatesInfo ( newDateStartEnd, newRangeInfos, newUsersAccess )

        // reorder the rangeDateInfos including the new range 
        const currentrangeDatesInfos: RangeDatesInfo[] = this.sharedCalendarStatusItem.rangeDatesInfos 
             
        // splice and add in the same position the new item
              currentrangeDatesInfos.splice($event, 1, newRangeDatesInfo )
          

        this.ngZone.run(()=>{

        // use a clone of sharedCalendarStatusItem 
        const newSharedCalendarStatusItem = this.sharedCalendarStatusService.createSharedCalendarStatusItem_Clone(this.sharedCalendarStatusItem)
        newSharedCalendarStatusItem.rangeDatesInfos = currentrangeDatesInfos

        if ( this.user !== null ){
          
          this.postComponentsHttpRequestService.httpUpdateComponent( this.user , 'sharedCalendarStatus', newSharedCalendarStatusItem  )
            .subscribe((response: SharedCalendarStatus)=> {
             console.log(response) 

             this.sharedCalendarStatusService.sharedCalendarStatusItems[ this.sharedCalendarStatusService.sharedCalendarStatusItems.findIndex(x=> x._id === this.sharedCalendarStatusItem._id)] = newSharedCalendarStatusItem 
             this.initialNullSetupService.initialNullSharedCalendarStatusItems[ this.initialNullSetupService.initialNullSharedCalendarStatusItems.findIndex(x=> x._id === this.sharedCalendarStatusItem._id)] = newSharedCalendarStatusItem
      
             this.selected = this.updateSelectedDates()
  
              this.onDateRangeInfoExist()
  
               const isSameDateAsClickedBefore = false 
  
            if ( this.aRangeDateInfoExist ){ this.checkWhichDateRangeItBelongTo(today, isSameDateAsClickedBefore, 0 ) }
            if ( !this.aRangeDateInfoExist ){ this.selectedRangeDatesInfo = undefined }
  
          this.onDateSelection( new Date( this.sharedCalendarStatusItem.rangeDatesInfos[$event].dateStartEnd.dateEnd ))
        
  
          this.isUpdatingCalendar = false 
          setTimeout(() => {
            this.isAddingRange = false 
          }, 200);
          
          }, error => {
            console.log(error)

            const errorMessage: string = "we weren't able to save the data , check your connection or try later "
            this.errorsDialogManagerService.PopUpErrorDialog({ textContent: errorMessage })
        
          }
          )
 
        }

        if ( this.user === null ){
          this.sharedCalendarStatusService.sharedCalendarStatusItems[this.sharedCalendarStatusService.sharedCalendarStatusItems.findIndex(x=> x._id === this.sharedCalendarStatusItem._id )] = this.sharedCalendarStatusItem 
          this.initialNullSetupService.initialNullSharedCalendarStatusItems[this.initialNullSetupService.initialNullSharedCalendarStatusItems.findIndex(x=> x._id === this.sharedCalendarStatusItem._id)] = this.sharedCalendarStatusItem
        
          this.selected = this.updateSelectedDates()
  
          this.onDateRangeInfoExist()
  
          const isSameDateAsClickedBefore = false 
  
            if ( this.aRangeDateInfoExist ){ this.checkWhichDateRangeItBelongTo(today, isSameDateAsClickedBefore, 0 ) }
            if ( !this.aRangeDateInfoExist ){ this.selectedRangeDatesInfo = undefined }
  
          this.onDateSelection( new Date( this.sharedCalendarStatusItem.rangeDatesInfos[$event].dateStartEnd.dateEnd ))
        
            this.isUpdatingCalendar = false
            this.isAddingRange = false 

          console.log(this.sharedCalendarStatusItem)
        }

      
        })
        this.cdr.detectChanges()

        console.log("index of event date range => ",$event)
        this.calendar.activeDate = new Date(this.sharedCalendarStatusItem.rangeDatesInfos[$event].dateStartEnd.dateEnd); 
      }

    })

  }


  onUpdateRangeDatesInfo($event){

    const updatedSharedCalendarStatusItem = $event.updatedSharedCalendarStatusItem
    const sharedCalendarStatusItem_id = $event.sharedCalendarStatus_id 
    const RangeDatesInfo = $event.newSelectedRangeDatesInfo
// aaa cannot update all together at start , too quick 

  if ( this.sharedCalendarStatusItem._id === sharedCalendarStatusItem_id ){
    this.ngZone.run(()=>{

          const newSharedCalendarStatusItem = this.createSharedCalendarStatus_Clone_WithNewRangeDateInfo( updatedSharedCalendarStatusItem, RangeDatesInfo) 
          // const newSharedCalendarStatusItem = updatedSharedCalendarStatusItem

       /**
       *  if user is logged in ( user = 'something' )
       */
       if ( this.user !== null ){
        this.postComponentsHttpRequestService.httpUpdateComponent( this.user , 'sharedCalendarStatus', newSharedCalendarStatusItem  )
            .subscribe((response: SharedCalendarStatus)=> {
             console.log(response) 

             this.sharedCalendarStatusService.sharedCalendarStatusItems[ this.sharedCalendarStatusService.sharedCalendarStatusItems.findIndex(x=> x._id === sharedCalendarStatusItem_id)] = newSharedCalendarStatusItem 
             this.initialNullSetupService.initialNullSharedCalendarStatusItems[ this.initialNullSetupService.initialNullSharedCalendarStatusItems.findIndex(x=> x._id === sharedCalendarStatusItem_id)] = newSharedCalendarStatusItem
      
          }, error => {
            console.log(error)

            const errorMessage: string = "we weren't able to save the data on the server, maybe the network wasn't working. While we keep a local update, try again later to make it permanent"
            this.errorsDialogManagerService.PopUpErrorDialog({ textContent: errorMessage })
        
          }
          )
       }

       if ( this.user === null ){

            this.sharedCalendarStatusItem.rangeDatesInfos[this.selectedRangeDatesInfo_Index] = RangeDatesInfo 

            this.sharedCalendarStatusItem = updatedSharedCalendarStatusItem
            console.log(this.sharedCalendarStatusItem)

            const index= this.sharedCalendarStatusService.sharedCalendarStatusItems.findIndex(x=> x._id === sharedCalendarStatusItem_id)
            this.sharedCalendarStatusService.sharedCalendarStatusItems[index] = this.sharedCalendarStatusItem 

            const indexNullService =  this.initialNullSetupService.initialNullSharedCalendarStatusItems.findIndex(x=> x._id === sharedCalendarStatusItem_id)
            this.initialNullSetupService.initialNullSharedCalendarStatusItems[indexNullService] = this.sharedCalendarStatusItem

            console.log( 'index = ',index, 'null index = ', indexNullService , 'UPDATED SHAREDCALENDARSTATUS ON SERVICES => ',  this.sharedCalendarStatusService.sharedCalendarStatusItems , this.initialNullSetupService.initialNullSharedCalendarStatusItems  )
       }
      
    })
    this.cdr.detectChanges()

    console.log('rangeDateInfo => ',RangeDatesInfo , 'sharedCalendarStatusItem => ', this.sharedCalendarStatusItem)
    
  }
  }


  // after add will need to send observable to edit the  new rangeIngo after added 
//   onUpdateRangeDatesInfo_afterAdd($event){ 

//     const updatedSharedCalendarStatusItem = $event.updatedSharedCalendarStatusItem
//     const sharedCalendarStatusItem_id = $event.sharedCalendarStatus_id 
//     const RangeDatesInfo = $event.newSelectedRangeDatesInfo


//     if ( this.sharedCalendarStatusItem._id === sharedCalendarStatusItem_id){

//     this.ngZone.run(()=>{

//     const newSharedCalendarStatusItem = updatedSharedCalendarStatusItem

//    /**
//    *  if user is logged in ( user = 'something' )
//    */
//    if ( this.user !== null ){
//     this.postComponentsHttpRequestService.httpUpdateComponent( this.user , 'sharedCalendarStatus', newSharedCalendarStatusItem  )
//         .subscribe((response: SharedCalendarStatus)=> {
//          console.log(response) 

//          this.sharedCalendarStatusService.sharedCalendarStatusItems[ this.sharedCalendarStatusService.sharedCalendarStatusItems.findIndex(x=> x._id === this.sharedCalendarStatusItem._id)] = newSharedCalendarStatusItem 
//          this.initialNullSetupService.initialNullSharedCalendarStatusItems[ this.initialNullSetupService.initialNullSharedCalendarStatusItems.findIndex(x=> x._id === this.sharedCalendarStatusItem._id)] = newSharedCalendarStatusItem
  
//          this.editJustAddedRangeInfo.next()

//       }, error => {
//         console.log(error)

//         const errorMessage: string = "we weren't able to save the data on the server, maybe the network wasn't working. While we keep a local update, try again later to make it permanent"
//         this.errorsDialogManagerService.PopUpErrorDialog({ textContent: errorMessage })
    
//       }
//       )
//    }

//    if ( this.user === null ){
       
//         this.sharedCalendarStatusItem = updatedSharedCalendarStatusItem 

//         console.log( this.sharedCalendarStatusItem)

//         this.sharedCalendarStatusService.sharedCalendarStatusItems[ this.sharedCalendarStatusService.sharedCalendarStatusItems.findIndex(x=> x._id === this.sharedCalendarStatusItem._id)] = this.sharedCalendarStatusItem 
//         this.initialNullSetupService.initialNullSharedCalendarStatusItems[ this.initialNullSetupService.initialNullSharedCalendarStatusItems.findIndex(x=> x._id === this.sharedCalendarStatusItem._id)] = this.sharedCalendarStatusItem

//         this.editJustAddedRangeInfo.next()
//    }
  
// })
// this.cdr.detectChanges()
    
// console.log('rangeDateInfo => ',$event , 'sharedCalendarStatusItem => ', this.sharedCalendarStatusItem)

// }

// }


onEmit_Update_SharedcalendarStatus($event){

  console.log( 'is updating sharedCalendarStatusItem ', $event )
  
  this.ngZone.run(()=>{
    this.sharedCalendarStatusItem = $event 
    const index = this.sharedCalendarStatusService.getSharedCalendarStatusItems().findIndex(x=> x._id === $event._id)
    this.sharedCalendarStatusService.sharedCalendarStatusItems[index]= $event

    const nullIndex = this.initialNullSetupService.initialNullSharedCalendarStatusItems.findIndex(x=> x._id === $event)
    this.initialNullSetupService.initialNullSharedCalendarStatusItems[nullIndex] = $event
   
    this.isRestartingComponent = true
    this.ngOnInit()
 
    // with latestSelectedRangeDatesInfo_Index check which Date() it was 
    // make sure latestSelectedRangeDatesInfo_Index still exist

    if ( !this.onDateRangeInfoExist()){
      this.selectedRangeDatesInfo = undefined, 
      this.selectedRangeDatesInfo_Index = undefined,
      this.latestSelectedRangeDatesInfo_Index = undefined 
    }
    
    if ( this.onDateRangeInfoExist() ){

    if ( this.sharedCalendarStatusItem.rangeDatesInfos.length > this.latestSelectedRangeDatesInfo_Index ){
         const previousViewingDate = new Date( this.sharedCalendarStatusItem.rangeDatesInfos[this.latestSelectedRangeDatesInfo_Index].dateStartEnd.dateEnd);
         console.log('latestSelectedRangeDatesInfo_Index => ',this.latestSelectedRangeDatesInfo_Index)
         this.onDateSelection(previousViewingDate)
         
    }
    if ( this.sharedCalendarStatusItem.rangeDatesInfos.length <= this.latestSelectedRangeDatesInfo_Index ){
         const previousViewingDate = new Date(today);
         console.log('latestSelectedRangeDatesInfo_Index => ',this.latestSelectedRangeDatesInfo_Index)
         this.onDateSelection(previousViewingDate)
       
    }
    
    }

    this.isRestartingComponent = false 
  })
  this.cdr.detectChanges()


}



  createSharedCalendarStatus_Clone_WithNewRangeDateInfo( updatedSharedCalendarStatusItem: SharedCalendarStatus, rangeDatesInfo: RangeDatesInfo): SharedCalendarStatus {

      const new_id = updatedSharedCalendarStatusItem._id
      const newComponentName = updatedSharedCalendarStatusItem.componentName
      const newUIID = updatedSharedCalendarStatusItem.UIID
      const newOwner = updatedSharedCalendarStatusItem.owner
      const newAllUsersAllowed = updatedSharedCalendarStatusItem.allUsersAllowed
      const newUserListAllowed = updatedSharedCalendarStatusItem.userListAllowed
      const newdateAndTime = updatedSharedCalendarStatusItem.dateAndTime
      const newName = updatedSharedCalendarStatusItem.name 
      const newRangeDatesInfoSetting = updatedSharedCalendarStatusItem.rangeDatesInfoSetting
      const newRangeDatesInfo: RangeDatesInfo[] = this.sharedCalendarStatusService.createRangeDatesInfo_Clones(updatedSharedCalendarStatusItem.rangeDatesInfos)

      newRangeDatesInfo[this.selectedRangeDatesInfo_Index] = rangeDatesInfo
      const newSharedCalendarStatusItem = new SharedCalendarStatus ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, 
                                                                     newUserListAllowed, newdateAndTime, newName, newRangeDatesInfoSetting, 
                                                                     newRangeDatesInfo ) 

    return newSharedCalendarStatusItem


  }


  /**
   * 
   * export class FeatureResult {

    constructor (
        public featureResultType: string,
        public featureResultName: string,
        public featureDetailResults: SingleFeatureDetailResult[]|
                                     SharedCalendarStatusResult
                  
    ){}
   */


  onUpdateSharedCalendarStatusResult($event){
    console.log($event)
    this.onUpdateFeatureResultEvent($event.newSharedCalendarStatusResult, $event.newMailTextBodyPart)
  }

  onUpdateFeatureResultEvent( sharedCalendarStatusResult: SharedCalendarStatusResult, newtextBodyPart: string ){

    if ( this.isTabItem === true){ this.tab_index = this.tab_index }
    if ( this.isTabItem !== true){ this.tab_index = null }

    const newTab_index = this.tab_index
    const newFeatureDrop_Id = this.feature.dropId

    const newFeatureResultType = 'sharedCalendarStatusResult' ; 
    const newFeatureResultName =  this.sharedCalendarStatusItem.name 
    const newFeatureDetailResults = sharedCalendarStatusResult

    const newFeatureResult = new FeatureResult (  newFeatureResultType , newFeatureResultName , newFeatureDetailResults )
    console.log(newFeatureResult)

    const newBodyPartName = encodeURIComponent( newFeatureResultName)+ '%0D%0A%0D%0A';
    const newMailTextFeatureBodyPart = newBodyPartName + newtextBodyPart

    this.updateBothFeatureResultAndTextPlusIndex.emit({ newTab_index, newFeatureDrop_Id, newFeatureResult, newMailTextFeatureBodyPart})
    
  }


  ngOnDestroy(): void {
      this.calendarMonthSubscription?.unsubscribe()
      this.editFeatureEventFromSharedCalendarStatusList?.unsubscribe()
      this.updateOnUserEmail?.unsubscribe()
      this.clearInterval();
      this.observeIfComponentIsVisible?.unsubscribe()
  }

 

 

  
  

}
