<!-- <p>link-grid-item works!</p> -->
<mat-grid-list cols="6" rowHeight="100px" [gutterSize]="'10px'">
    <mat-grid-tile
        *ngFor="let tile of tiles"
        [colspan]="tile.cols"
        [rowspan]="tile.rows"
        [style.background]="tile.color">
      {{tile.text}}

      <!-- <mat-grid-tile-footer >
   
        {{tile.text}}
      </mat-grid-tile-footer> -->
    </mat-grid-tile>

   

  </mat-grid-list>

  <!-- <object data="https://www.google.com" width="600" height="400">
    <embed src="https://www.google.com" width="600" height="400"/>
  </object> -->

  <!-- /**   OPEN A NEW PAGE ON LINK 
   * Use window.open(). It's pretty straightforward !

In your component.html file-

<a (click)="goToLink('www.example.com')">page link</a>
In your component.ts file-

goToLink(url: string){
    window.open(url, "_blank");
}
   */ -->