export class ParagraphFeature {
    
    constructor ( 
        public _id: number , 
        public owner: string ,  
        public dateAndTime: string ,
        public isAccepted: boolean,  
        public allUsersAllowed: boolean,
        public userListAllowed: string[],
        public isBeenShared: boolean,
        public canBeReshared: boolean ,
        public type: string, 
        public counterId: number , 
        public paragraphFeatureDropId: number ,
        public paragraph_id: string 
        ){}  

//         // if owner is different than user + allUsersAllowed is False , then if paragraph is inside a Form 
//         // the form cannot be shared or made public 
        
    }

    /** type will define if the paragraph is pubblico / privato / condiviso and according to this 
     * it could be edited or not by third party where is been shared ( normally it can't be edited), 
     * so it has to change TYPE condition when it's been shared from private person to shared .
     */