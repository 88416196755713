
  <div class="sliders">
    <div class="slider" *ngFor="let slider of sliders; let i = index">
      <input type="range" 
             id="slider-{{i}}" 
             name="slider-{{i}}" 
             [(ngModel)]="slider.value" 
             (input)="updateSlidersAndChart(i)" 
             step="0.5" 
             max="100">

      <span class="slider-value">{{slider.value}}%</span>
      <button *ngIf="sliders.length > 2" 
              (click)="removeSlider(i)">-</button>
    </div>
    <button (click)="addSlider()">+</button>
  </div>

     <!-- <ng-template #tooltipTemplate let-tooltip="tooltip">
      <div class="tooltip-container">
        <div class="tooltip-header">{{tooltip.data.name}}</div>
        <div class="tooltip-body">{{tooltip.data.value}}%</div>
      </div>
    </ng-template> -->
  <ngx-charts-pie-chart *ngIf="loaded"
    [view]="[400, 400]"
    [scheme]="colorScheme"
    [results]="chartData"
    [gradient]="gradient"
    [legend]="showLegend"
    [legendPosition]="'below'"
    [explodeSlices]="false"
    [doughnut]="true"
    [arcWidth]="0.4"
    [labels]="true"
    [maxLabelLength]="30"
    [tooltipDisabled]="false"
    [animations]="false">
 
  </ngx-charts-pie-chart>
 
  <ngx-charts-pie-chart *ngIf="!loaded"
    [view]="[400, 400]"
    [scheme]="colorScheme"
    [results]="chartDataCopy"
    [gradient]="gradient"
    [legend]="showLegend"
    [legendPosition]="'below'"
    [explodeSlices]="false"
    [doughnut]="true"
    [arcWidth]="0.4"
    [labels]="true"
    [maxLabelLength]="30"
    [tooltipDisabled]="false"
    [animations]="false">
  
  </ngx-charts-pie-chart>

   <!-- using chartDataCopy to keep the results fixed and not throwing error  -->

  <h1>1.)single</h1>
 <ngx-charts-bar-vertical *ngIf="loaded"
              [view]="[800, 400]"
              [scheme]="colorScheme"
              [results]="chartData"
              [gradient]="gradient"
              [legend]="showLegend"
             
             >
</ngx-charts-bar-vertical>
<!-- it doesn't work with this vertical chartto hide it , it throws error   -->
<!-- <ngx-charts-bar-vertical *ngIf="!loaded"
[view]="[800, 400]"
[scheme]="colorScheme"
[results]="chartDataCopy"
[gradient]="gradient"
[legend]="showLegend"

>
</ngx-charts-bar-vertical> -->
