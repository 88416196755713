import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, NgZone, OnInit} from '@angular/core';
import { ParagraphTopic } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.model';
import { ParagraphTopicService } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-topic.service';
import { Paragraph } from '../../../../form-item/paragraph/paragraph.model';
import { ParagraphFeature } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature.model';
import { Observable , Subject, BehaviorSubject, Subscription } from 'rxjs';
import { HeaderService } from 'src/app/header/header.service';
import { Header } from 'src/app/header/header.model';
import { FormGroup, FormControl } from '@angular/forms';
import { ParagraphService } from '../../../../form-item/paragraph/paragraph.service';
import { ParagraphIndexTopicDialog } from './paragraph-index-topic-dialog.service';
import { FeatureTopicService } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-topic.service';
import { FeatureIndexSelection } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-index-topic-list/feature-index-selection.model';
import { FormService } from 'src/app/forms/form.service';
import { IndexType } from 'src/app/forms/index-type.model';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { ParagraphFeatureContainer } from './paragraph-feature-container.model';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { ParagraphTopicIndexService } from '../../paragraph-topic-index.service';
import { DialogmanagerService } from 'src/app/services/other-components-services/dialogmanager.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AddSharedFormOrParagraphComponent } from 'src/app/forms/form-list/add-shared-form-or-paragraph/add-shared-form-or-paragraph.component';
import { ParagraphTopicIndex } from '../../paragraph-topic-index.model';
import { FormIndexTopicListItem } from 'src/app/forms/form-list/form-index-topic-list/form-index-topic-list-item.model';
import { FormIndexTopic } from 'src/app/forms/form-list/form-index-topic-list/form-index-topic.model';
import { FormFeatureContainer } from 'src/app/forms/form-list/form-index-topic-list/form-feature-container.model';
import { AddSharedFormOrParagraphBottomSheetComponent } from 'src/app/forms/form-list/add-shared-form-or-paragraph-bottom-sheet/add-shared-form-or-paragraph-bottom-sheet.component';
import { AddSharedFormOrParagraphService } from 'src/app/forms/form-list/add-shared-form-or-paragraph/add-shared-form-or-paragraph.service';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { ParagraphFeatureContainerService } from './paragraph-feature-container.service';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions} from '@angular/material/tooltip';


/** Custom options the configure the tooltip's default show/hide delays. */
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 100,
  touchendHideDelay: 1000,
};

interface SharedParagraphForm_Dialog_BottomSheet_Data {
  typeOfComponent: string , 
  formType: FormType ,
  paragraphType: ParagraphType, 
}

interface FormType {
  formIndexTopic: FormIndexTopic ,
  formFeatureContainer: FormFeatureContainer
}

interface ParagraphType {
  paragraphTopic: ParagraphTopic,
  paragraphFeatureContainer: ParagraphFeatureContainer
}

interface SharedParagraphForm_New_Old_Data {
  typeOfComponent: string , 
  formSide: FormSide ,
  paragraphSide: ParagraphSide, 
}

interface FormSide {
  oldFormIndexTopicListItem: FormIndexTopicListItem,
  updatedFormIndexTopicListItem: FormIndexTopicListItem,
  oldFormIndexTopic: FormIndexTopic ,
  updatedFormIndexTopic: FormIndexTopic,
  oldFormFeatureContainer: FormFeatureContainer,
  updatedFormFeatureContainer: FormFeatureContainer

}

interface ParagraphSide {
  oldParagraphTopicIndex: ParagraphTopicIndex,
  updatedParagraphTopicIndex: ParagraphTopicIndex,
  oldParagraphTopic: ParagraphTopic,
  updatedParagraphTopic: ParagraphTopic,
  oldParagraphFeatureContainer: ParagraphFeatureContainer,
  updatedParagraphFeatureContainer: ParagraphFeatureContainer
}


@Component({
  selector: 'app-paragraph-index-topic',
  templateUrl: './paragraph-index-topic.component.html',
  styleUrls: ['./paragraph-index-topic.component.css'],
  providers: [{provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}]
})
export class ParagraphIndexTopicComponent implements OnInit {

  user: string 

  paragraph: Paragraph ;
  paragraphTopic : ParagraphTopic ;
  paragraphFeatures : ParagraphFeature[];
  paragraphFeature: ParagraphFeature;

  paragraphIndexNameForm : FormGroup;
 

   @Input() isPersonal: boolean  
   @Input() isPublic: boolean 
   @Input() index_id: string ;
   index: number 

   /** gives paragraph Index the topic to add when add paragraph */
   topicIndex : number ;

   /** to hide the buttons and keep only the topic */
   hideParagraphs = true ;

  /** these 2 serve to have a max limit on paragraphs to add */
   createButtonLimit : boolean ;
  paragraphsIndexLimit = 50 ;

  count = 0;

  weGoingToComponentIndex : boolean = true ;

  isDoubleClick : boolean = false ;
  

  /** set timing function */
  setTimer: any ;

  /** to hide 1st click button on modify index name */
  hide1stButton = false ;
  

/** hide and show modify index button  */
  hideButton = false ;

  mouseEnter: boolean = false 

  isLoading: boolean = false 

  updateOnUserEmail: Subscription

  allNewSharedParagraphTopicsHasBeenAddedSubscription: Subscription 
  updateOnSharedParagraphFormsSubscription: Subscription

  newParagraphIndexes: number 
  

  constructor( private paragraphTopicService : ParagraphTopicService,
               private paragraphService: ParagraphService,
               private headerService: HeaderService,
               private breakpointObserver: BreakpointObserver ,
               private featureTopicService: FeatureTopicService,
               private userObjectUpdateService: UserObjectUpdateService , 
               private postComponentsHttpRequestService: PostComponentsHttpRequestService,
               private router: Router,
               private route: ActivatedRoute,
               private paragraphTopicIndexService: ParagraphTopicIndexService , 
               private ngZone: NgZone , 
               private cdr: ChangeDetectorRef , 
               private dms: DialogmanagerService , 
               private _bottomSheet: MatBottomSheet ,
               private addSharedFormOrParagraphService: AddSharedFormOrParagraphService,
               private initialNullSetupService: InitialNullSetupService ,
               private paragraphFeatureContainerService: ParagraphFeatureContainerService
              
                ) { }

  
 isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
.pipe(
  map(result => result.matches),
  shareReplay()
);

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
    })
    
    this.paragraphTopic = this.paragraphTopicService.getParagraphTopicBy_id(this.index_id) 
    console.log(this.paragraphTopic)

    this.checkIfAnyNewParagraphFeatureContainer()

    this.index = this.paragraphTopicService.getParagraphTopics().findIndex(x=> x._id === this.index_id)
      /** here i'll have to set the length of the nonRemoved ones , or they will count also the ones removed 
     * from the list
     */
    if ( this.paragraphService.getParagraphs().length < this.paragraphsIndexLimit ){
      this.createButtonLimit = true
    } else {
      this.createButtonLimit = false 
    };

    this.paragraphIndexNameForm = new FormGroup({
      name: new FormControl ('') ,   
     });

     this.allNewSharedParagraphTopicsHasBeenAddedSubscription = this.paragraphTopicIndexService.addingNewSharedParagraphAndParentsToDOM
         .subscribe(()=>{
          this.isLoading = true 
          this.updateParagraphTopic()
     })

     this.updateOnSharedParagraphFormsSubscription = this.addSharedFormOrParagraphService.doneAddingUpdatingSharedParagraphForm
        .subscribe((res: SharedParagraphForm_New_Old_Data)=> {
          console.log( res, 'updated paragraphIndexTopic')
          this.updateSharedParagraphTopic(res)

        })

    
  }

  checkIfAnyNewParagraphFeatureContainer(){
    if ( this.user === null ){ this.newParagraphIndexes = 0 }
    if ( this.user !== null ){

      let numberOfNewParagraphFeatureContainers: number = 0 
      if ( this.paragraphTopic.paragraphFeatureContainer_ids.length > 0 ){
           for ( let i=0; i< this.paragraphTopic.paragraphFeatureContainer_ids.length ; i++  ){
                if ( this.checkIfStringIncludeParagraphFeatureContainerWord(this.paragraphTopic.paragraphFeatureContainer_ids[i]) ){ numberOfNewParagraphFeatureContainers = numberOfNewParagraphFeatureContainers+1}
           }
           this.newParagraphIndexes = this.paragraphTopic.paragraphFeatureContainer_ids.length - numberOfNewParagraphFeatureContainers
      }

      if ( this.paragraphTopic.paragraphFeatureContainer_ids.length == 0 ){ this.newParagraphIndexes = 0 }

    }
  }

  checkIfStringIncludeParagraphFeatureContainerWord( newParagraphFeatureContainerWord : string ){

    const searchingTerm: string = 'paragraphFeatureContainer-'

    if ( newParagraphFeatureContainerWord.includes(searchingTerm) ){ return true }
    if ( !newParagraphFeatureContainerWord.includes(searchingTerm) ){ return false }

  }

  updateSharedParagraphTopic( sharedParagraphForm_New_Old_Data: SharedParagraphForm_New_Old_Data){

    console.log(sharedParagraphForm_New_Old_Data.typeOfComponent , sharedParagraphForm_New_Old_Data.paragraphSide.updatedParagraphTopic._id )
    this.ngZone.run(()=> {
      if ( sharedParagraphForm_New_Old_Data.typeOfComponent === 'paragraphFeatureContainer' ){

        if ( this.paragraphTopic._id === sharedParagraphForm_New_Old_Data.paragraphSide.updatedParagraphTopic._id){

             this.isLoading = true 

             const currentParagraphTopic: ParagraphTopic = this.paragraphTopicService.getParagraphTopicBy_id(sharedParagraphForm_New_Old_Data.paragraphSide.oldParagraphTopic._id)
             const paragraphTopic: ParagraphTopic = sharedParagraphForm_New_Old_Data.paragraphSide.updatedParagraphTopic

             const currentParagraphFeatureContainer: ParagraphFeatureContainer = sharedParagraphForm_New_Old_Data.paragraphSide.oldParagraphFeatureContainer
             const newParagraphFeatureContainer: ParagraphFeatureContainer = sharedParagraphForm_New_Old_Data.paragraphSide.updatedParagraphFeatureContainer

             const indexOfParagraphFeatureContainer_id_ToSubstitute = currentParagraphTopic.paragraphFeatureContainer_ids.findIndex(x=> x === currentParagraphFeatureContainer._id )
             currentParagraphTopic.paragraphFeatureContainer_ids[indexOfParagraphFeatureContainer_id_ToSubstitute] = newParagraphFeatureContainer._id
             console.log(currentParagraphTopic)

             const indexOfCurrentparagraphTopic = this.paragraphTopicService.getParagraphTopics().findIndex(x=> x._id === currentParagraphTopic._id )
             this.paragraphTopicService.paragraphTopics[indexOfCurrentparagraphTopic] = currentParagraphTopic 
             const initialNullIndex = this.initialNullSetupService.initialNullParagraphTopics.findIndex(x=> x._id === currentParagraphTopic._id )
             this.initialNullSetupService.initialNullParagraphTopics[initialNullIndex] = currentParagraphTopic

             const indexOfParagraphFeatureContainer = this.paragraphFeatureContainerService.getParagraphFeatureContainers().findIndex(x=> x._id === currentParagraphFeatureContainer._id )
             this.paragraphFeatureContainerService.paragraphFeatureContainers[indexOfParagraphFeatureContainer] = newParagraphFeatureContainer
             const nullIndexParagraphFeatureContainer = this.initialNullSetupService.initialNullParagraphFeatureContainers.findIndex(x=> x._id === currentParagraphFeatureContainer._id )
             this.initialNullSetupService.initialNullParagraphFeatureContainers[nullIndexParagraphFeatureContainer] = newParagraphFeatureContainer


             this.paragraphTopic = currentParagraphTopic

             this.checkIfAnyNewParagraphFeatureContainer()
        }
        
   }
    })
    this.cdr.detectChanges()
    // setTimeout(() => {
      this.isLoading = false
    // }, 200);
    
   
  }

  updateParagraphTopic(){
    this.ngZone.run(()=>{
      this.paragraphTopic = this.paragraphTopicService.getParagraphTopicBy_id(this.index_id) 
      console.log(this.paragraphTopic)
    })
    this.cdr.detectChanges()
    setTimeout(() => {
      this.isLoading = false 
    }, 300);
    

  }



  // isMobileDevice(): boolean {
  //   return window.matchMedia('(max-width: 767px)').matches;
  // }

  noReturnPredicate() {
    return false;
  }

  // onMouseEnter(){
  //   this.mouseEnter = true
  // }

  // onMouseLeave(){
  //   this.mouseEnter = false 
  // }

  /**
   * add dialog to accept or refuse paragraph Topic
   */
  onAcceptRefuseParagraphTopic(){

      /**
          * interface SharedParagraphForm_Dialog_BottomSheet_Data {
  typeOfComponent: string , 
  formType: FormType ,
  paragraphType: ParagraphType, 
}

interface FormType {
  formIndexTopicListItem: FormIndexTopicListItem,
  formIndexTopic: FormIndexTopic ,
  formFeatureContainer: FormFeatureContainer
}

interface ParagraphType {
  paragraphTopicIndex: ParagraphTopicIndex,
  paragraphTopic: ParagraphTopic,
  paragraphFeatureContainer: ParagraphFeatureContainer
}
          */
    console.log('is opening window to add or refuse paragraphTopic')


      const newTypeOfComponent = 'paragraphTopic'
      const newFormType : FormType = { formIndexTopic: null ,formFeatureContainer: null }
      const newParagraphType: ParagraphType = { paragraphTopic: this.paragraphTopic, paragraphFeatureContainer: null }
      
      const newSharedParagraphForm_Dialog_BottomSheet_Data: SharedParagraphForm_Dialog_BottomSheet_Data = { typeOfComponent: newTypeOfComponent ,formType: newFormType ,paragraphType: newParagraphType }

    if ( !this.isMobileDevice()){
      this.dms.addSharedFormOrParagraphComponent({ 

        sharedParagraphForm_Dialog_BottomSheet_Data: newSharedParagraphForm_Dialog_BottomSheet_Data
      })
     
    }

    if ( this.isMobileDevice()){
         this._bottomSheet.open(AddSharedFormOrParagraphBottomSheetComponent , {
          data: {

          sharedParagraphForm_Dialog_BottomSheet_Data: newSharedParagraphForm_Dialog_BottomSheet_Data
          }
         });
    }


  }

  isMobileDevice(): boolean {
    const isHandset = this.breakpointObserver.isMatched(Breakpoints.Handset);
    return isHandset;
  }

  // this must be rlegate to pop up window
  onAcceptParagraphTopic(){
    this.isLoading = true 
    console.log('ACCEPTING THIS PARAGRAPH TOPIC')
    const new_id = this.paragraphTopic._id
    const newComponentName = this.paragraphTopic.componentName
    const newUIID = this.paragraphTopic.UIID
    const newOwner = this.paragraphTopic.owner
    const newAllUsersAllowed =  this.paragraphTopic.allUsersAllowed
    const newUserListAllowed = this.paragraphTopic.userListAllowed
    const newDateAndTime =  this.paragraphTopic.dateAndTime
    const newIsAccepted =  true 
    const newIsArchived = this.paragraphTopic.isArchived
    const newName = this.paragraphTopic.name ;
    const newParagraphFeatureContainer_ids= this.paragraphTopic.paragraphFeatureContainer_ids

    const newParagraphTopic = new ParagraphTopic ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed, 
      newDateAndTime, newIsAccepted, newIsArchived ,
      newName , newParagraphFeatureContainer_ids  );
    
    this.postComponentsHttpRequestService.httpUpdateComponent(this.user, 'paragraphTopic', newParagraphTopic )
    .subscribe((response: ParagraphTopic)=> {
      console.log(response)
      this.paragraphTopicService.paragraphTopics[this.paragraphTopicService.getParagraphTopics().findIndex(x=> x._id === this.paragraphTopic._id)] = newParagraphTopic
      this.paragraphTopic = this.paragraphTopicService.getParagraphTopicBy_id(newParagraphTopic._id)
      setTimeout(()=>{
        this.isLoading = false 
      }, 200 )
    })

   


  }


  onIsDoubleClick(i){
    this.isDoubleClick = !this.isDoubleClick;

  
    /** start a new timer */
     setTimeout(() => {
      this.isDoubleClick = false ;
    }, 2000);
    // console.time(this.setTimer)
  }

/** clear timer before itstart again */
  clearTimer(){
  clearTimeout(this.setTimer)
}

  /** this serve to show the lisy of paragraphs , they are hidden when start
   * they open when user click on the topic 
   */
  onHideParagraphs(){
    this.hideParagraphs = !this.hideParagraphs;
    /** clear timer before it start again */
    this.clearTimer()
    
   this.setTimer= setTimeout(() => {
      this.hideParagraphs = true ;
    }, 30000);
  };

      /** set topicname so when create paragraph and click submit , the app knows which 
      * topic to add the paragraph to 
      * 
      * we should add the behavior subject to make it more stable , it serves as reminder to which paragraphTopic group
      * the paragraph we are creating belong to 
      */
   
    onSetTopicIndex(){ // make behavior subject on paragraphTopicService 
      // this.paragraphTopicService.setCurrentTopicIndex(this.index); 
      //  const newUpdateFeatureIndexSelection = new FeatureIndexSelection ( true, false  )
      //  this.featureTopicService.nextIndexSelection(newUpdateFeatureIndexSelection)
      // this.paragraphTopicService.setTopicName(this.index);
      this.paragraphTopicService.onChoosenParagraphTopicToBeAddedTo(this.paragraphTopic)
      this.onGoingToParagraphEdit()
    };


    onGoingToParagraphEdit(){
    
      const newHeaderIndexSelection = new Header (false, true, false, false, false, false, false, false )
      this.headerService.nextIndexSelection(newHeaderIndexSelection)

      /**
       * setting up feature index selection to start
       */
      const newUpdateFeatureIndexSelection = new FeatureIndexSelection ( true, false  )
      this.featureTopicService.nextIndexSelection(newUpdateFeatureIndexSelection)

      this.router.navigate(['/create-paragraph' ], {relativeTo: this.route.parent })
    }


    onHide1stButton(){
      this.hide1stButton = !this.hide1stButton ;
        setTimeout(() => {
          this.hide1stButton = false ;
        }, 1000);
      
    
    }

/** hide button to start editing the index name  */
    onHideButton(){
      this.hideButton = !this.hideButton;
      setTimeout(() => {
     this.hideButton = false ;
     this.hide1stButton = false;
     this.paragraphIndexNameForm = new FormGroup({
      name: new FormControl ('') ,   
        })
    }, 30000);
    }


    onSubmit(){

      if ( this.user !== null ){ this.submitWithExistingUser() }
      if ( this.user === null ){ this.eventuallyChangeTheNameAnyway() }

    };

    submitWithExistingUser(){ 

      const updatingThisParagraphTopic: ParagraphTopic = this.paragraphTopic 
      updatingThisParagraphTopic.name = this.paragraphIndexNameForm.value.name

      console.log(updatingThisParagraphTopic)

      this.postComponentsHttpRequestService.httpUpdateComponent(this.user, 'paragraphTopic' , updatingThisParagraphTopic )
          .subscribe((res)=>{
            console.log(res)

            this.eventuallyChangeTheNameAnyway()

          }, error => {
            console.log(error, 'throw an error message' )

            /**
             * temporarely change the name anyway but tell userit's just temporary
             */
            this.eventuallyChangeTheNameAnyway()
          })
    }

    eventuallyChangeTheNameAnyway(){
      this.paragraphTopicService.getParagraphTopic(this.index).name = this.paragraphIndexNameForm.value.name ;
      this.paragraphTopic = this.paragraphTopicService.getParagraphTopic(this.index);
      this.hide1stButton = false;
      this.hideButton = false ;

      this.paragraphIndexNameForm.value.name = '' ;
      console.log(this.paragraphTopicService.getParagraphTopics())
    }

  

    ngOnDestroy(){
      this.updateOnUserEmail.unsubscribe()
      this.allNewSharedParagraphTopicsHasBeenAddedSubscription.unsubscribe()
      this.updateOnSharedParagraphFormsSubscription.unsubscribe()
    }

    


}

