import { Component, OnInit } from '@angular/core';
import StringBuilder from '@zxing/library/esm/core/util/StringBuilder';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';

@Component({
  selector: 'app-toggle-single-word-button-edit',
  templateUrl: './toggle-single-word-button-edit.component.html',
  styleUrls: ['./toggle-single-word-button-edit.component.css']
})
export class ToggleSingleWordButtonEditComponent implements OnInit {

  user: String

  constructor(  private userObjectUpdateService: UserObjectUpdateService) { }

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail
  }

}
