import { Component, OnInit } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';

export interface Fruit {
  name: string;
}


@Component({
  selector: 'app-phrase-button',
  templateUrl: './phrase-button.component.html',
  styleUrls: ['./phrase-button.component.css']
})
export class PhraseButtonComponent implements OnInit {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  fruits: Fruit[] = [
    {name: 'email when push goes to receiver'},
    {name: 'each email for company in mat-tabs'},
    {name: 'CS = customer service '},
    {name: 'antonio under spain mat-tab '},
  ];

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.fruits.push({name: value.trim()});
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(fruit: Fruit): void {
    const index = this.fruits.indexOf(fruit);

    if (index >= 0) {
      this.fruits.splice(index, 1);
    }
  }

  
   

    constructor() { }

  ngOnInit(): void {
  }
}

