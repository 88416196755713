import { Component, Input, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InnerArraySelect } from '../inner-array-select.model';
import { RangeInfo } from '../range-info.model';

interface Toggle {
  label: string;
  checked: boolean;
}

@Component({
  selector: 'app-inner-slide-toggle-item',
  templateUrl: './inner-slide-toggle-item.component.html',
  styleUrls: ['./inner-slide-toggle-item.component.css']
})
export class InnerSlideToggleItemComponent implements OnInit {

  @Input() rangeInfo: RangeInfo
  @Input() isEditing: boolean 

  innerSlideToggle: InnerArraySelect

  toggles: Toggle[] = [
    { label: 'primo', checked: false },
    { label: 'secondo', checked: true },
    { label: 'terzo', checked: false }
  ];

  onToggleChange(checked: boolean, index: number): void {
    this.toggles[index].checked = checked;
    console.log('toggle ', this.toggles[index].label , ' => ', this.toggles[index].checked  )
    // Perform any other actions based on the value...
  }

  constructor(){}



  // isChecked = true;
  // formGroup: FormGroup;
  

  // constructor(private _formBuilder: FormBuilder) {
  //   this.formGroup = this._formBuilder.group({
  //     enableWifi: '',
  //     acceptTerms: ['', Validators.requiredTrue],
  //   });
  // }

 
  // alertFormValues(formGroup: FormGroup) {
  //   // alert(JSON.stringify(formGroup.value, null, 2));
  //   console.log('is clicking')
  //   console.log(formGroup.value.enableWifi); // Get the value of enableWifi toggle
  //   console.log(formGroup.value.acceptTerms); // Get the value of acceptTerms toggle
  
  // }

  ngOnInit(): void {
  }

}
