import { Injectable } from '@angular/core';
import { Recipient } from './recipient.model';
import { RecipientHttpRequestService } from './recipient-http-request.service';
import { Chat } from 'src/app/chats/chat.model';
import { ChatHttpRequestService } from 'src/app/services/post-and-put-services/chat-http-request.service';
import { InitialNullSetupService } from 'src/app/services/starting-services/initial-null-setup.service';
import { StorePersonalRecipientComponentsService  } from 'src/app/services/store-initial-components/store-personal-recipient-components.service';
import { Subject, Subscription } from 'rxjs';
import { AppLoadingService } from 'src/app/services/other-components-services/app-loading.service';

@Injectable ({
    providedIn: 'root' 
})

export class RecipientService {

    constructor( private recipientHttpRequestService: RecipientHttpRequestService , 
                 private chatHttpRequestService: ChatHttpRequestService ,
                 private initialNullSetupService: InitialNullSetupService,
                 private appLoadingService: AppLoadingService ,
                 private storePersonalRecipientComponentsService: StorePersonalRecipientComponentsService ){}


    recipient: Recipient ;
    updateAllEmailInputsOfEmailContacts = new Subject<void>()
    userRecipientsHaveBeenStored: boolean 
    

    public recipients : Recipient[] = this.initialNullSetupService.initialNullRecipients
    
    updateInitialHttpDataFromRecipientComponentsService: Subscription = this.storePersonalRecipientComponentsService.recipientsChanged
                                                   .subscribe((res)=> {
                                                    this.recipients = res
                                                    console.log('updated personal recipients = ', res)

                                                    this.userRecipientsHaveBeenStored = true 

                                                    this.appLoadingService.onStoppingAppLoading()

                                                   })

    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.recipientsChangedBackToNull
                                                     .subscribe((res)=> {
                                                         this.recipients = res 
                                                         console.log('updated recipients back to null = ', res)

                                                     })

   

    getRecipient(index:number){
        return this.recipients[index];
    }

    getRecipients(){
        return this.recipients;
    }

    getRecipientBy_id(_id: string){
        // return this.recipients.find(x=> x._id === _id)
        const newRecipient: Recipient = this.recipients.find(
            (res)=>{
                return res._id === _id
            }
        )
        return newRecipient
    }


    getRecipientByChat_id(chat_id: string){
        const newRecipient: Recipient = this.recipients.find(
            (res)=>{
                return res.chatRoomId === chat_id
            }
        )
        return newRecipient
    }

    getOnlyUserRecipientsList(user: string){

        const recipientsWithSameOwner: Recipient[] = []
        for ( let i=0; i<this.recipients.length ; i++){
            if (this.recipients[i].owner === user ){ recipientsWithSameOwner.push(this.recipients[i])}
        
        }
        return recipientsWithSameOwner

    }

    addRecipient(recipient: Recipient){

        if ( this.recipients.findIndex(x=> x._id === recipient._id ) !== -1 ){
             this.updateRecipient(recipient)
        }

        if (this.recipients.findIndex(x=> x._id === recipient._id ) === -1){
            this.recipients.push(recipient)
            this.updateInitialRecipients(this.recipients)
        }
       

    }

    updateRecipient(recipient: Recipient){
        const newRecip_index = this.recipients.findIndex(x=> x._id === recipient._id)
        this.recipients[newRecip_index] = recipient 

        const updateInitialRecipient_index =  this.initialNullSetupService.initialNullRecipients.findIndex(y=> y._id === recipient._id )
        this.initialNullSetupService.initialNullRecipients[updateInitialRecipient_index] = recipient
    }


    deleteArrayOfRecipients(recipients_ids: string[]){

        for (let i=0; i<recipients_ids.length; i++ ){

             let indexToRemove = this.recipients.findIndex(x=>x._id === recipients_ids[i])
             this.recipients.splice(indexToRemove, 1)

             if ( i=== recipients_ids.length-1 ){
                console.log('remaining recipients = '+this.recipients)
                this.updateInitialRecipients(this.recipients)
             }


            
        }
    }

    updateInitialRecipients(updatedRecipients: Recipient[]){
        this.initialNullSetupService.initialNullRecipients = updatedRecipients
    }

    updateAllEmailInputsOfEmailContactsSubject(){
        this.updateAllEmailInputsOfEmailContacts.next()
    }

   
}

