

<!-- go to lesson 30 for the basic  -->
<!-- go to lesson 54-58 to check how it's done  -->
<!-- also go to lesson 117 to check how tomanage the services  -->

<!-- this section supposed to produce a button that will be able 
to consol.log the word that is written in the button , as a 
text of an email , and to be placed almost everywhere  -->

<div class="row">
    <div class="col-xs-12">
        <!-- <form [formGroup]="addwordbutton" > -->
        <a>
                        <div class="col-xs-6">
                            <!-- each component will have 2 buttons , one to create a completely new 
                            component , and the other for editing the exhisting form  -->
                            <!-- edit the current form  -->
                            <!-- <button (click)="openDialogWordButtonDialog()"> <mat-icon>add </mat-icon> edit</button> -->
                            <!-- create a new form from zero -->
                            <button mat-raised-button> <mat-icon>add </mat-icon> create</button>
                        
                            <mat-chip (click)="onWordButton()" type="button"> {{ wordButtonName }}  </mat-chip>
                            <br>
                            <p *ngIf="wordButtonCreated" class="text-center"> {{wordButtonName }} </p>


                        </div>
        </a>

                
        <!-- <label> input word button's name </label>
        <br>
        <input class="form-control" type="text" name="word button"  placeholder="max 30 letters" (input)= "onWordButtonName($event)"> 
        <input class="form-control" type="text" name ="word button"  placeholder="max 30 letters" [(ngModel)]= "wordButtonName">  
        <mat-chip (click)="onWordButton()" type="button" class="btn default"> {{ wordButtonName }}  </mat-chip>
        <p *ngIf="wordButtonCreated"> {{wordButtonName }} </p>
        <br>
        <button type="button" class="btn default"> cancel   </button>
        <button type="button" class="btn default"> save button  </button> -->


        
        <!-- </form> -->

    </div>
</div>


