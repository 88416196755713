import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { RangeInfo } from '../range-info.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedCalendarStatus } from '../shared-calendar-status.model';
import { HeaderService } from 'src/app/header/header.service';
import { InnerArraySelect } from '../inner-array-select.model';
import { UsersAccess } from '../users-access-edit/users-access.model';
import { FixTextDetail } from '../../../texts/fix-text-list/fix-text-detail.model';
import { CanDo } from '../users-access-edit/who-can-do.model';
import { Subject, Subscription } from 'rxjs';
import { SharedCalendarStatusService } from '../shared-calendar-status.service';
import { ErrorsDialogManagerService } from 'src/app/services/other-components-services/errors-dialog-manager.service';

interface IndexRangeInfo{
  index: number ,
  rangeInfo: RangeInfo
}

interface InputLabelInterface {

  exist: boolean ,
  name: string,
  maxLength: number,
  errorHandler: boolean 
}

@Component({
  selector: 'app-shared-calendar-status-edit-bottom-sheet',
  templateUrl: './shared-calendar-status-edit-bottom-sheet.component.html',
  styleUrls: ['./shared-calendar-status-edit-bottom-sheet.component.css']
})
export class SharedCalendarStatusEditBottomSheetComponent implements OnInit , OnDestroy{

  user: string 
  updateOnUserEmail: Subscription

  sharedCalendarStatusItem: SharedCalendarStatus

  isEditingOne: boolean = false 
  isLoading: boolean = false 

  isParagraphEdit: boolean = true 

  isEditingPositionInArray: number

  isEditingInnerlink: boolean = false
  isEditingInnerDropdown: boolean = false
  isEditingInnerCheckbox: boolean = false
  isEditingInnerSlidetoggle: boolean = false
  isEditingInnerButton: boolean = false
  isEditingInnerText: boolean = false 
  isEditingInnerInput: boolean = false

  accessType: string = 'date range'

  isAddingRangeInfo: boolean = false 
  additionalRangeInfo: RangeInfo 

  inputSharedCalendarStatusItemNameInterface: InputLabelInterface
  emptyInputNameAfterAdded = new Subject<void>()

  changeSelectedDates = new Subject<void>() // will never fire from here 


  constructor( private _bottomSheetRef: MatBottomSheetRef<SharedCalendarStatusEditBottomSheetComponent>,
               private headerService: HeaderService,
               private sharedCalendarStatusService: SharedCalendarStatusService,
               private postComponentsHttpRequestService: PostComponentsHttpRequestService ,
               private errorsDialogManagerService: ErrorsDialogManagerService,
               private userObjectUpdateService: UserObjectUpdateService,
               @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
              ) { }

  

  ngOnInit(): void {
    console.log('app-shared-calendar-status-edit', this.data.sharedCalendarStatusItem )

    this.user = this.userObjectUpdateService.userEmail
 
    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
     .subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
    })
            
    this.sharedCalendarStatusItem = this.data.sharedCalendarStatusItem

    this.inputSharedCalendarStatusItemNameInterface = { exist: true, name: 'component name', maxLength: 30, errorHandler: true }
   
    this.additionalRangeInfo = this.sharedCalendarStatusService.createAdditionalRangeInfoDropdown()
            
 
  }
            

    onUpdateNameInputEvent($event){
      console.log($event)
      this.sharedCalendarStatusItem.name = $event.name
    }
  
    onUpdateNameInputValidity($event){

      console.log($event)
    }
            
    onCloseDialog(){

      if ( this.user !== null ){
        this.postComponentsHttpRequestService.httpUpdateComponent( this.user , 'sharedCalendarStatus', this.sharedCalendarStatusItem )
            .subscribe((response)=> {
          console.log(response) 
  
          this.headerService.nextComponentIndexSelection(false)
          this._bottomSheetRef.dismiss(this.sharedCalendarStatusItem)
      
          }, error => {
            console.log(error)
  
            this.headerService.nextComponentIndexSelection(false)
            this._bottomSheetRef.dismiss(undefined)
  
            const errorMessage: string = "we weren't able to save the data on the server, maybe the network wasn't working. While we keep a local update, try again later to make it permanent"
            this.errorsDialogManagerService.PopUpErrorDialog({ textContent: errorMessage })
        
          }
          )
      }
      if ( this.user == null ){
        this.headerService.nextComponentIndexSelection(false)
        this._bottomSheetRef.dismiss(this.sharedCalendarStatusItem)
      }
     
    }

    onDeleteIndexRangeInfo($event){

      this.onDeleteType($event)
    }
            
    onDeleteType( i: number ){
            
                console.log(i, )
            
                this.isEditingOne = false 
            
                /**
                 * is editing the rangeDateInfoSetting
                 */
                this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos.splice(i, 1)
            
                console.log(this.sharedCalendarStatusItem)
            
    }
            
    onInEditingOne(i: number , type: string){
            
                this.isEditingOne = true 
                this.additionalRangeInfo = this.sharedCalendarStatusService.createAdditionalRangeInfoDropdown()
                console.log(i, type)
            
                this.isEditingPositionInArray = i
            
                if (type === 'innerlink'){ 
                  this.isEditingInnerlink = true 
                }
                if (type === 'innerdropdown'){
                  this.isEditingInnerDropdown = true
                }
                if (type === 'innercheckbox'){
                  this.isEditingInnerCheckbox = true
                }
                if (type === 'innerslidetoggle'){
                  this.isEditingInnerSlidetoggle = true 
                }
                if ( type === 'innerbutton'){
                  this.isEditingInnerButton = true 
                }
                if ( type === 'innertext'){
                  this.isEditingInnerText = true 
                }
                if ( type === 'innerinput'){
                  this.isEditingInnerInput = true 
                }
              
            
    }
            
    onSubmitInnerFeature($event){
            
                const newIndexRangeInfo: IndexRangeInfo = $event
                console.log(newIndexRangeInfo)
            
                this.isEditingOne = false
            
                  this.isEditingInnerlink = false
                  this.isEditingInnerDropdown = false
                  this.isEditingInnerCheckbox = false
                  this.isEditingInnerSlidetoggle = false 
                  this.isEditingInnerButton = false 
                  this.isEditingInnerText = false 
                  this.isEditingInnerInput = false 
            
    }

    onUpdateUsersAccess($event){
      console.log($event)
    }

    onEmitUpdatedIndexRangeInfoSettings($event){
      console.log($event)
  
      const i = $event.index
      const type = $event.rangeInfo.type
  
      this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos[i] = $event.rangeInfo
  
  
      this.isEditingOne = false
      console.log(i, type)
  
      this.isEditingPositionInArray = i
  
      this.isEditingInnerlink = false
      this.isEditingInnerDropdown = false
      this.isEditingInnerCheckbox = false
      this.isEditingInnerSlidetoggle = false 
      this.isEditingInnerButton = false 
      this.isEditingInnerText = false 
      this.isEditingInnerInput = false 
  
    }


    onAddDetails(){
      console.log('adding details type')
      this.isAddingRangeInfo = true 
    }

    onAddRangeInfoType(){
      console.log('add rangeInfoType')
  
      this.isAddingRangeInfo = false 
    }
     

    onGetInnerdropdownSelectedValue($event: any){

      console.log( 'what has been selected => ',$event.rangeInfo_index, $event.newRangeInfo) //rangeInfo_index, newRangeInfo
  
      const selectedRangeInfo: RangeInfo = $event.newRangeInfo
      const selectedType: number = selectedRangeInfo.rangeDetails.findIndex(x=> x.selected === true )
  
      if ( selectedType === 0 ){ 
           console.log('is adding dropdowns') 
  
           const newType = "innerdropdown"
           const newRangeInfo = this.sharedCalendarStatusService.createNewInnerDropdown()
  
           this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos.push(newRangeInfo)
  
           const newIndex = this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos.length-1
  
           this.onInEditingOne( newIndex, newType )
  
      }
  
      if ( selectedType === 1 ){ 
           console.log('is adding checkboxes')
  
           const newType = "innercheckbox"
           const newRangeInfo = this.sharedCalendarStatusService.createNewInnerCheckbox()
  
           this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos.push(newRangeInfo)
  
           const newIndex = this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos.length-1
           this.onInEditingOne( newIndex, newType )
  
  
      }
  
      if ( selectedType === 2 ){ 
           console.log('is adding texts') 
  
           const newType = "innertext"
           const newRangeInfo = this.sharedCalendarStatusService.createNewInnerText()
  
           this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos.push(newRangeInfo)
  
           const newIndex = this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos.length-1
           this.onInEditingOne( newIndex, newType )
  
      }
  
      if ( selectedType === 3 ){ 
           console.log('is adding input') 
  
           const newType = "innerinput"
           const newRangeInfo = this.sharedCalendarStatusService.createNewInnerInput()
  
           this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos.push(newRangeInfo)
  
           const newIndex = this.sharedCalendarStatusItem.rangeDatesInfoSetting.rangeInfos.length-1
           this.onInEditingOne( newIndex, newType )
  
  
      }

      
    }

    ngOnDestroy(): void {
      this.updateOnUserEmail?.unsubscribe()
    }

    


}
