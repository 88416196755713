import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable , Subscription, Subject, Observer, fromEvent, merge  } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { FormService } from '../../form.service';
import { RecipientIndexColumnService } from '../form-edit/recipient-index-list/recipient-index-column.service';

@Component({
  selector: 'app-form-start',
  templateUrl: './form-start.component.html',
  styleUrls: ['./form-start.component.css']
})
export class FormStartComponent implements OnInit {

  openSideNavOnMobileFormList = new Subject

  message1: string 
  message2: string 
  message3: string 

  tab1: string
  tab2: string
  tab3: string
  tab4: string

  messagePhone: string = "create multiple shortcuts by selecting often used forms and add them to your home screen"

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  constructor( private breakpointObserver: BreakpointObserver,
               private recipientIndexColumnService: RecipientIndexColumnService ,
               private formService: FormService) { }

  ngOnInit(): void {

    this.recipientIndexColumnService.sharedRecipientIndexListItem_isVisibleNow = false 

    this.message1  = "bookmark recurrent forms"
    this.message2 =  "so they are always ONE CLICK AWAY "
    this.message3 = "just like this.."

    this.tab1= "tab1:drapp shared calendar update"
    this.tab2= "Tab 2: drapp expense note"
    this.tab3= "Tab 3: quotations "
    this.tab4= "Tab 4: shared product 'A' q.ty balance"
  }

  onOpenFormListSideNav_FromStartComponent(){
    this.formService.onOpenFormListSideNav_FromStartComponent()
  }


}
