import { Injectable } from "@angular/core";
import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from "@angular/common/http";
import { map, tap, catchError } from "rxjs/operators";
import { throwError } from "rxjs";

@Injectable ({
    providedIn: 'root' 
})

export class LoginHttpRequestService {

    constructor (private http: HttpClient){}

/**
 * Il servizio ti restituisce tutti i component creati dallo user di un certo tipo (per esempio base o feature, ecc)
Devi fare una chiamata di tipo GET
Dopo il punto “?” ci sono i parametri di ricerca user e objectType che sono entrambi obbligatori 
Nella chiama che ho messo ti restituisce tutti i component di toni@libero.it di tipo base 
Il servizio ti restituisce un JSON con i component suddivisi per nome del component 

 */

    httpFetchAllSingleBaseComponents(){
    this.http.get(
        'http://localhost:8080/market-in-service/rest/component?objectType=singleBase&user=garageworld@garageartist'
    )
    .subscribe((response)=> {
        console.log(response)
    })
   };



    httpFetchAllBaseComponents(){
        this.http.get(
            'http://localhost:8080/market-in-service/rest/component?objectType=base&user=garageworld@garageartist'
        )
        .subscribe((response)=> {
            console.log(response)
        })
    };

    httpFetchAllMultiBaseComponents(){
        this.http.get(
            'http://localhost:8080/market-in-service/rest/component?objectType=multiBase&user=garageworld@garageartist'
        )
        .subscribe((response)=> {
            console.log(response)
        })
    };


        httpFetchAllBasePublicComponentsWithOwnerAndUser(){
        this.http.get(
            'http://localhost:8080/market-in-service/rest/public/components?objectType=base&user='+'tony@libero.it'+'&owner='+'toni@libero.it'
        )
        .subscribe((response)=> {
            console.log(response)
        })
       };


       onFetchAllBasePublicComponentsWithOwnerNoUser(){
        this.http.get(
            'http://localhost:8080/market-in-service/rest/public/components?objectType=base&user=garageworld@garageartist'
        )
        .subscribe((response)=> {
            console.log(response)
        })
       };

       fetchAllComponentTypeWithSharedNameToWithoutOwner(){
        this.http.get(
            'http://localhost:8080/market-in-service/rest/share/components?objectType=base&user=garageworld@garageartist'
        )
        .subscribe((response)=>{
            console.log(response)
        })
       };

       fetchAllComponentTypeWithSharedNameToPlusOwner(){
        this.http.get(
            'http://localhost:8080/market-in-service/rest/share/components?objectType=base&user=dathao@libero.it&owner=garageworld@garageartist'
        )
        .subscribe((response)=>{
            console.log(response)
        })
       };

       fetchAllSharedComponentTypeWithUser(){
        this.http.get(
            'http://localhost:8080/market-in-service/rest/share/components?objectType=feature&user=garageworld@garageartist'
        )
        .subscribe((response)=>{
            console.log(response)
        })
       };

       fetchAllSharedComponentAnyTypeWithUser(){
        this.http.get(
            'http://localhost:8080/market-in-service/rest/share/components?user=garageworld@garageartist'
        )
        .subscribe((response)=>{
            console.log(response)
        })
       }


       httpFetchAllFeatureIndexComponents(){
        this.http.get(
            'http://localhost:8080/market-in-service/rest/component?objectType=featureIndex&user=garageworld@garageartist'
        )
        .subscribe((response)=> {
            console.log(response)
        })
       }

       httpFetchAllParagraphComponents(){
        this.http.get(
            'http://localhost:8080/market-in-service/rest/component?objectType=paragraph&user=toni@libero.it'
        )
        .subscribe((response)=> {
            console.log(response)
        })
       }

}