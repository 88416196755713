import { Component, OnInit, Inject, OnDestroy, ViewChild , AfterViewInit } from '@angular/core';
import { FormGroup , FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedCalendarStatus } from '../shared-calendar-status.model';
import { Subscription } from 'rxjs';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { ErrorsDialogManagerService } from 'src/app/services/other-components-services/errors-dialog-manager.service';

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();

@Component({
  selector: 'app-add-shared-calendar-status-item-range',
  templateUrl: './add-shared-calendar-status-item-range.component.html',
  styleUrls: ['./add-shared-calendar-status-item-range.component.css']
})
export class AddSharedCalendarStatusItemRangeComponent implements OnInit , AfterViewInit, OnDestroy {

  @ViewChild('picker') picker: MatDatepicker<Date>;
  user: string 

  // selectedDates: Date[] = [];

  sharedCalendarStatusItem: SharedCalendarStatus

  dateRanges: any[] 

  range: FormGroup

  isChangingStartDateEntrySubscription: Subscription
  isChangingEndDateEntrySubscription: Subscription



  constructor( private dialogRef: MatDialogRef<AddSharedCalendarStatusItemRangeComponent>,
               private errorsDialogManagerService: ErrorsDialogManagerService , 
               @Inject(MAT_DIALOG_DATA) public editData: any ,
               private datePipe:DatePipe ) { }


  ngOnInit(): void {

    this.sharedCalendarStatusItem = this.editData.sharedCalendarStatusItem
    console.log(this.sharedCalendarStatusItem)

    console.log( 'isEditingRangeDate => ',this.editData.isEditingRangeDate, 'selectedRangeDatesInfo_Index => ', this.editData.selectedRangeDatesInfo_Index)

    this.dateRanges = this.updateDateRanges()


    if ( this.editData.isEditingRangeDate ){
      this.range = new FormGroup({
        start: new FormControl(new Date(this.sharedCalendarStatusItem.rangeDatesInfos[this.editData.selectedRangeDatesInfo_Index].dateStartEnd.dateStart)),
        end: new FormControl(new Date(this.sharedCalendarStatusItem.rangeDatesInfos[this.editData.selectedRangeDatesInfo_Index].dateStartEnd.dateEnd)),
    });

    }

    if ( !this.editData.isEditingRangeDate ){

          this.range = new FormGroup({
               start: new FormControl(new Date(this.findFirstAvailableDate())),
               end: new FormControl(new Date(this.findSecondAvailableDate())),
          });
    }


     // Get the FormControl instances for the start and end inputs
     const startControl = this.range.controls['start'];
     const endControl = this.range.controls['end'];

    this.isChangingStartDateEntrySubscription = startControl.valueChanges.subscribe((value) => {
      // transform the new Date() into the string to possibly save 
      this.getStringDateOutOfNewStartingDate(value)

    });

    this.isChangingEndDateEntrySubscription = endControl.valueChanges.subscribe((value) => {
      // transform the new end date into string and confirm new rangeDate
      this.confirmNewRangeDateInput(value)
    });
  }

  ngAfterViewInit(): void {
    // Open the date range picker programmatically after the view has been initialized
    setTimeout(() => {
    this.picker.open();
    })
  }

  dateClassCallback = (date: Date): string => {

    this.dateRanges = this.updateDateRanges()

    if ( this.editData.isEditingRangeDate ){
      return ''
    }

    if ( !this.editData.isEditingRangeDate ){
          // this.dateRanges = this.updateDateRanges()
  
    // Iterate through the date ranges to find a matching range for the given date
          for (const range of this.dateRanges) {
               if (this.isDateInRange(date, range.start, range.end)) { 
                   return range.color;
               }
          }

          return ''
    }
  
  };
  
  isDateInRange(date: Date, start: Date, end: Date): boolean {
    // Adjust the comparison to consider the time component as well
    const startDate = new Date(start.getFullYear(), start.getMonth(), start.getDate());
    const endDate = new Date(end.getFullYear(), end.getMonth(), end.getDate());
  
    return date >= startDate && date <= endDate;
  }

  updateDateRanges(){

    let dateRanges:any[] = []
    for ( let i=0; i<this.sharedCalendarStatusItem.rangeDatesInfos.length ; i++ ){
          
          const newStart: string =  this.sharedCalendarStatusItem.rangeDatesInfos[i].dateStartEnd.dateStart
          const newEnd: string = this.sharedCalendarStatusItem.rangeDatesInfos[i].dateStartEnd.dateEnd 
          
          // define color type according to 
          let newColor: string = 'range-4'

          // const presentDate = new Date()
          const startDate = new Date(newStart)
          startDate.setHours(0, 0, 0, 0);
          const endDate = new Date(newEnd)
          endDate.setHours(23, 59, 59, 999);

          const dateRange = { start: new Date(newStart) , end: new Date(newEnd), color: newColor }

          dateRanges.push(dateRange)
    }
    
    return dateRanges
  }
  
 
  /**
   * find the first available date from all the dateranges we already have
   */
  findFirstAvailableDate(): Date {
    const today = new Date();
    let currentDay = new Date(today);

    while (this.isSingleDateInRange(currentDay)) {
      currentDay.setDate(currentDay.getDate()+1);
    }
    console.log(currentDay)
    return currentDay;
  }

  findSecondAvailableDate(): Date {
    const today = new Date();
    let currentDay = new Date(today);

    while (this.isSingleDateInRange(currentDay)) {
      currentDay.setDate(currentDay.getDate()+2);
    }
    console.log(currentDay)
    return currentDay;
  }

  /**
   * check if the new currentDay exist in any of the dateRanges 
   */
  isSingleDateInRange(date: Date): boolean {
    return this.dateRanges.some(range => this.isDateBetween(date, range));
  }

  /**
   * 
   * @param date 
   * @param range 
   * @returns  check if the date exist in a dateRange
   */
  isDateBetween(date: Date, range: any): boolean {
    return date >= range.start && date <= range.end;
  }

  getStringDateOutOfNewStartingDate(newStartingDate: Date){
    console.log(newStartingDate)
  }

  confirmNewRangeDateInput(newEndingDate: Date){
     console.log(newEndingDate)
    if ( newEndingDate === null ){
      console.log('nothing to do')
    }
    if ( newEndingDate !== null ){
      console.log('add new range input and close the dialog')
    }


  }

  onAddRangeAndCloseDialog(){
    console.log('closing Dialog')

    const newStartDateString = this.datePipe.transform( this.range.value.start , "yyyy-MM-dd'T'HH:mm:ss")
    const newEndDateString = this.datePipe.transform( this.range.value.end , "yyyy-MM-dd'T'23:59:59")

     if ( newStartDateString == null  ){
                this.dialogRef.close(undefined)
                this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'STARTING DATE IS INVALID'})

     }
     if ( newEndDateString == null ){
      this.dialogRef.close(undefined)
      this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'ENDING DATE IS INVALID'})

     }
     if ( this.range.value.start > this.range.value.end ){
      this.dialogRef.close(undefined)
      this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'DATE RANGE IS INVALID'})
     }
     if ( newStartDateString !== null && newEndDateString !== null && this.range.value.start < this.range.value.end ){
      this.dialogRef.close(this.range.value)
     }
   

  }

  changeDate(type: string, event: MatDatepickerInputEvent<Date>) {
    /**
     * make sure the start cannot be when a present selected date is starting 
     * 
     */

    if ( type === 'start'){ 

              let newStartDateString = this.datePipe.transform( event.value , "yyyy-MM-dd'T'HH:mm:ss")
              console.log('start date = ' , newStartDateString , event.value ) 

        
               if ( this.isNewStartDateInArray(event.value) && !this.editData.isEditingRangeDate ){ // and it's different than the curent editing date range 
                console.log('THIS START DATE ALREADY EXIST, RETURN TO START OPERATION')
                this.dialogRef.close(undefined)
                this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'A DATE RANGE STARTING THIS DATE ALREADY EXIST '})
               }
           
    }

          

    if ( type === 'end'){ 
              
              let newEndDateString = this.datePipe.transform( event.value , "yyyy-MM-dd'T'23:59:59")
          
              console.log('end date = ', newEndDateString, event.value  ) }

           

  }

  isNewStartDateInArray(newStartDate: Date): boolean {

    const startDates: Date[] = []
    for ( let i=0; i<this.dateRanges.length ; i++ ){
      startDates.push(this.dateRanges[i].start )
    }

    for (const startDate of startDates) {
      const startDateOnly = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
      const newStartDateOnly = new Date(newStartDate.getFullYear(), newStartDate.getMonth(), newStartDate.getDate());
  
      if (newStartDateOnly.getTime() === startDateOnly.getTime()) {
        return true; // Found a match
      }
    }
  
    return false; // No match found
  }

  ngOnDestroy(): void {
    this.isChangingStartDateEntrySubscription.unsubscribe()
    this.isChangingEndDateEntrySubscription.unsubscribe()
  }


}
