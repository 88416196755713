<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<div *ngIf="!isLoading" class="ParagraphComponentFrame">

<div *ngIf="isSentToMessage && !isSentToMail" style="color: #3f51b5; background-color: rgb(243, 250, 253); border-radius: 6px;"  class="col-xs-12">
   

<br>

    <div class="row">
        <div class="col-xs-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
              </svg>
        </div>
        <div class="col-xs-7">
            <h3><b>{{formResult.sender?.content | uppercase }}</b></h3>
        </div>
        <div class="col-xs-4">
            <!-- <p>{{formResult.dateAndTime | date : 'MMMM , dd yyyy  HH:mm:ss'}} this will cause pipe error on safari</p> -->
            <p>{{formResult.dateAndTime }}</p>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12">
            <p *ngIf="!subjectIsNull" style="color:red">{{formResult.subject | uppercase }}</p>
            <p *ngIf="subjectIsNull" style="color:red">{{ subjectSubstitute }}</p>
        </div>
    </div>

    <div class="row">
        <div  class="col-xs-12">
            <a  style="color:#3f51b5" (click)="onCheckRecipients()">
                to : {{formResult.recipientResult.name}}
                <svg xmlns="http://www.w3.org/2000/svg"  width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                    <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                  </svg> 
            </a>
        </div>
    </div>
               
            <div *ngIf="seeRecipients" class="row">
                <div class="col-xs-12">
                    <br *ngIf="isSentToMessage ">
                        <p *ngIf="isSentToMessage ">chat group : {{formResult.recipientResult?.recipientList }}</p>
        
                        <div *ngIf="isSentToMail">

                            <hr>
                    
                        <p>mail to : {{formResult.recipientResult?.toMail}}</p>
                        <p>cc to : {{formResult.recipientResult?.ccMail}}</p>
                        <p>bcc to : {{formResult.recipientResult?.bccMail}}</p>
            
                        </div>
                      
                </div>
        
            </div>
           

    <!-- <hr> -->
    <br>

</div>

<div *ngIf="!isSentToMessage && isSentToMail" style="color: #3f51b5; background-color: rgb(247, 247, 247); border-radius: 6px;"  class="col-xs-12">

    <br>
    
        <div class="row">
            <div class="col-xs-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-app" viewBox="0 0 16 16">
                    <path d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4H5z"/>
                  </svg>
            </div>
            <div class="col-xs-7">
                <h3><b>{{formResult.sender?.content | uppercase }}</b></h3>
            </div>
            <div class="col-xs-4">
                <!-- <p>{{formResult.dateAndTime | date : 'MMMM , dd yyyy  HH:mm:ss'}} this cause pipe error on safari</p> -->
                <p>{{formResult.dateAndTime }}</p>
            </div>
        </div>
    
        <div class="row">
            <div class="col-xs-12">
                <p *ngIf="!subjectIsNull" style="color:red">{{formResult.subject | uppercase }}</p>
                <p *ngIf="subjectIsNull" style="color:red">{{subjectSubstitute}}</p>
            </div>
        </div>
    
        <div class="row">
            <div  class="col-xs-12">
                <a  style="color:#3f51b5" (click)="onCheckRecipients()">
                    to : {{formResult.recipientResult.name}}
                    <svg xmlns="http://www.w3.org/2000/svg"  width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                      </svg> 
                </a>
            </div>
        </div>
                   
                <div *ngIf="seeRecipients" class="row">
                    <div class="col-xs-12">
                        <br *ngIf="isSentToMessage ">
                            <p *ngIf="isSentToMessage ">chat group : {{formResult.recipientResult?.toMessage }}</p>
            
                            <div *ngIf="isSentToMail">
    
                                <hr>
                        
                            <p>mail to : {{formResult.recipientResult?.toMail}}</p>
                            <p>cc to : {{formResult.recipientResult?.ccMail}}</p>
                            <p>bcc to : {{formResult.recipientResult?.bccMail}}</p>
                
                            </div>
                          
                    </div>
            
                </div>
               
    
        <!-- <hr> -->
        <br>
    
    </div>

<hr>


  
        <div class="overflowFrame">
        <br>
        <div *ngIf="noGreetings" >
          
            <div class="row">
                <div class="col-xs-10">
                    <p>{{formResult.recipientResult?.greeting}}</p>
                </div>
                <div class="col-xs-2">
                    <span class="badge rounded-pill bg-info text-dark">greetings</span>
                </div>
            </div>
           
        </div>
        
    
        <div *ngFor="let paragraph of formResult?.paragraphResults; let i=index">
            <app-paragraph-results 
                   [isReceived]="isReceived"
                   [paragraphResult]="paragraph"
                   [dateAndTime]="formResult.dateAndTime">
            </app-paragraph-results>
        </div>

    </div>
  

</div>


   


