import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormResultsService } from '../../form-results.service';

interface TypeMessage_Mail_FromTo {
  type: string ,
  fromTo: string[]
}

@Component({
  selector: 'app-filter-from-to-copies',
  templateUrl: './filter-from-to-copies.component.html',
  styleUrls: ['./filter-from-to-copies.component.css']
})
export class FilterFromToCopiesComponent implements OnInit , OnDestroy {


  availableColors: string[] = [ 'received' , 'sent' ]

    
 @Input() receivedMessageIsDeselected: boolean 
 @Input() sentMessageIsDeselected: boolean 
 @Input() isSubmitted: boolean

 @Output() onReceivedMessageIsDeselected = new EventEmitter<boolean>()
 @Output() onSentMessageIsDeselected = new EventEmitter<boolean>()

 subscriptionToFormResultList_onCheckUpdateFilterStatus: Subscription

  constructor( private formResultsService: FormResultsService , 
               private cdr: ChangeDetectorRef ,
               private ngZone: NgZone
    
    ) { }

  ngOnInit(): void {

    this.onReceivedMessageIsDeselected.emit(this.receivedMessageIsDeselected)
    this.onSentMessageIsDeselected.emit(this.sentMessageIsDeselected)



    this.subscriptionToFormResultList_onCheckUpdateFilterStatus = this.formResultsService.checkUpdateFilterResult_fromResultList
      .subscribe((res)=>{
        console.log(res)
        const message_fromTo: TypeMessage_Mail_FromTo = res.typeMessage_Mail_FromTo
        this.onUpdateStatusOnFormResultListPopUp(message_fromTo)
      })
  }

  onSelectedReceiveMessageType(i:number ){
    console.log('user selected: ', this.availableColors[i])
    if ( this.isSubmitted === false){
    this.receivedMessageIsDeselected = false
    this.onReceivedMessageIsDeselected.emit(this.receivedMessageIsDeselected)
    }
  }

  onDeselectedReceiveMessageType(i: number){
    console.log('user DEselected: ', this.availableColors[i])
    if ( this.isSubmitted === false){
    this.receivedMessageIsDeselected = true
    this.onReceivedMessageIsDeselected.emit(this.receivedMessageIsDeselected)
    }
  }

  onSelectedSentMessageType(i: number ){
    console.log('user selected: ', this.availableColors[i])
    if ( this.isSubmitted === false){
    this.sentMessageIsDeselected = false
    this.onSentMessageIsDeselected.emit(this.sentMessageIsDeselected)
    }
  }

  onDeselectedSentMessageType(i: number ){
    console.log('user DEselected: ', this.availableColors[i])
    if ( this.isSubmitted === false){
    this.sentMessageIsDeselected = true
    this.onSentMessageIsDeselected.emit(this.sentMessageIsDeselected)
    }
  }



  /**
   *  @Input() receivedMessageIsDeselected: boolean 
   *  @Input() sentMessageIsDeselected: boolean 
   * @param message_fromTo 
   */
  onUpdateStatusOnFormResultListPopUp(message_fromTo: TypeMessage_Mail_FromTo ){

  this.ngZone.run(() => {

    if (message_fromTo.type === 'all'){
        if (message_fromTo.fromTo[0] === 'oooooooo'){
            console.log(' THIS IS IMPOSSIBLE TRHROW AN ERROR ')
        }
        if (message_fromTo.fromTo[0] === 'sent'){
            this.receivedMessageIsDeselected = true
            this.sentMessageIsDeselected = false 
        }
        if (message_fromTo.fromTo[0] === 'received'){
            this.receivedMessageIsDeselected = false
            this.sentMessageIsDeselected = true 
            
        }
        if (message_fromTo.fromTo[0] === 'all'){
            this.receivedMessageIsDeselected = false
            this.sentMessageIsDeselected = false 
        }
    }
    if (message_fromTo.type === 'message'){
        if (message_fromTo.fromTo[0] === 'oooooooo'){
           
        }
        if (message_fromTo.fromTo[0] === 'sent'){
            this.receivedMessageIsDeselected = true
            this.sentMessageIsDeselected = false  
        }
        if (message_fromTo.fromTo[0] === 'received'){
            this.receivedMessageIsDeselected = false 
            this.sentMessageIsDeselected = true
        }
        if (message_fromTo.fromTo[0] === 'all'){
            this.receivedMessageIsDeselected = false
            this.sentMessageIsDeselected = false 
        }
    }
    if (message_fromTo.type === 'email'){
        // NOT ANY MESSAGE TYPE 
            this.receivedMessageIsDeselected = true 
            this.sentMessageIsDeselected = true 

    }

  })

  // Manually trigger change detection 
  this.cdr.detectChanges();

  this.onSentMessageIsDeselected.emit(this.sentMessageIsDeselected)

  }

  ngOnDestroy(): void {
    this.subscriptionToFormResultList_onCheckUpdateFilterStatus.unsubscribe()
  }




}
