import { ChangeDetectorRef, Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { FormResult } from '../../form-result.model';
import { TimeAlignService } from 'src/app/services/other-components-services/time-align.service';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions} from '@angular/material/tooltip';
import { DateTime } from 'luxon';


/** Custom options the configure the tooltip's default show/hide delays. */
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 500,
  hideDelay: 100,
  touchendHideDelay: 1000,
};

@Component({
  selector: 'app-form-result-index-item',
  templateUrl: './form-result-index-item.component.html',
  styleUrls: ['./form-result-index-item.component.css'],
  providers: [{provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}]
})
export class FormResultIndexItemComponent implements OnInit , OnDestroy {

  user: string 

  @Input() formResult: FormResult;
  @Input() isSelected: boolean

  isSentToMessage: boolean 
  isSentToMail: boolean 

  sentToMailString: string ; 
  sentToMessageString: string ; 

  isReceived: boolean 

  updateOnUserEmail: Subscription

  dateTimeInMinuteHours_string: string
  timeIntervalSubscription: Subscription 

  constructor( private userObjectUpdateService: UserObjectUpdateService ,
               private timeAlignService: TimeAlignService , 
               private cdr: ChangeDetectorRef,
               private ngZone: NgZone
    ) { }

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

  this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
    this.user = updatedUserEmail
    console.log('updated USER = ', this.user)
  })

    this.formResult 
     console.log(this.formResult)

     this.ngZone.run(()=>{
     if ( this.formResult.sender.content === this.user ){ this.isReceived = false }
     if ( this.formResult.sender.content !== this.user ){ this.isReceived = true }

     if ( this.formResult.recipientResult.toMessage.length > 0){ this.isSentToMessage = true }
     if ( this.formResult.recipientResult.toMessage.length === 0){ this.isSentToMessage = false }

     if ( this.formResult.recipientResult.toMail.length > 0){ this.isSentToMail = true }
     if ( this.formResult.recipientResult.toMail.length === 0){ this.isSentToMail = false }

     console.log(this.isReceived, this.isSentToMessage, this.isSentToMail)



    this.sentToMailString = this.formResult.recipientResult.toMail.toString()

    this.sentToMessageString = this.formResult.recipientResult.toMessage.toString() 

    console.log( this.sentToMailString , this.sentToMessageString )

    // this.ngZone.run(()=>{
      /**
       * check if it's more thsn 1 hour ago 
       */
       const dateTimeInMinHoursString = this.getDateTime_InMinuteHours(this.formResult.dateAndTime)
       this.dateTimeInMinuteHours_string = dateTimeInMinHoursString
    }) // end ngZone 
    this.cdr.detectChanges()

    this.timeIntervalSubscription = interval(60000).subscribe(() => {
      this.ngZone.run(()=>{
        const dateTimeInMinHoursString = this.getDateTime_InMinuteHours(this.formResult.dateAndTime)
        this.dateTimeInMinuteHours_string = dateTimeInMinHoursString
     }) // end ngZone 
     this.cdr.detectChanges()
    })

  }


getColorForMessage(){
  return this.isSelected === true ? 'rgb(0, 153, 255)' : 'white' ; 
}

getColorForMail(){
  return this.isSelected === true ? 'rgba(11, 247, 255, 0.87)' : 'white' ; 
}

getDayOfTheWeek(dateInString: string){

  // console.log(dateInString)
   const newDateString = new Date(dateInString.replace(/-/g, "/"))
   const dayOfWeek: string = this.timeAlignService.getDayOfTheWeek(newDateString)

  // console.log( dayOfWeek)

  return dayOfWeek


}

getTransformedReadeableDate(dateInString: string){

const formattedDateString = dateInString.replace(/-/g, '/');
const luxonDate = DateTime.fromFormat(formattedDateString, 'MM/dd/yyyy hh:mm:ss a');
const transformedDateString = luxonDate.toFormat('MMMM , dd yyyy  HH:mm');

console.log(transformedDateString)
return transformedDateString
}

getDateTime_InMinuteHours(dateInString: string){

  const newDateString = new Date(dateInString.replace(/-/g, "/"))
  let result: string 
  if ( this.timeAlignService.getRelativeTime_Minutes_Hours(newDateString) !== undefined ){ result = this.timeAlignService.getRelativeTime_Minutes_Hours(newDateString) }
  if ( this.timeAlignService.getRelativeTime_Minutes_Hours(newDateString) === undefined ){ result = '' }

  return result 

  
};

getDateTime_InDaysWeeksMonths(dateInString: string){
 
  const newDateString = new Date(dateInString.replace(/-/g, "/"))
  let result: string = this.timeAlignService.getRelativeTime_DayWeekMonthYearsAgo(newDateString)
 
  return result 

}

ngOnDestroy(): void {
  this.updateOnUserEmail.unsubscribe()
  this.timeIntervalSubscription.unsubscribe()
}
  

}
