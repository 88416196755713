
    <div class="example-container" >
    <div *ngFor="let recipientIndexColumn of recipientIndexColumns; let i=index">
        
            <app-recipient-index-column-item
            [recipientIndexColumn]="recipientIndexColumn"
            [youAreInFormEdit]="youAreInFormEdit"
            (updateRecipientIndex)='onUpdateRecipientIndex($event)'>
            </app-recipient-index-column-item>

        </div>
    </div>