<div class="innerItemFrame2">
  <div class="col-xs-12">

<form>
  <mat-form-field class="full-width" appearance="outline">
    <mat-select [(ngModel)]="selectedValue" 
                name="item" 
                
                (selectionChange)="onItemSelection()"
                (openedChange)="onDropdownOpened($event)">
                <!-- [disabled]="isDisabled" -->
      <mat-option *ngFor="let detail of rangeInfo.rangeDetails; let i=index" [value]="i" >
        {{detail.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>



  </div>
</div>


