import { NgModule } from '@angular/core';
import { TruncatePipe } from './truncate.pipe';
import { ReversePipe } from './reverse.pipe';

@NgModule({
    declarations: [ 

        TruncatePipe, 
        ReversePipe

    ],

    imports: [],

    exports: [
        TruncatePipe,
        ReversePipe
    ],

    providers: [],

})
export class PipesModule {}

