import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inner-chart-edit',
  templateUrl: './inner-chart-edit.component.html',
  styleUrls: ['./inner-chart-edit.component.css']
})
export class InnerChartEditComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
