<!-- <p>new-chat-dialog works!</p> -->

<div class="ParagraphComponentFrame">
<div class="row">
    <div class="col-xs-12">
        <div class="row">
            <!--  style="background-color:rgb(242, 248, 250); color: #3f51b5 "  -->
            <button  (click)="onToggleRecipientIndexName()"
                     type="button"
                     class="btn btn-primary btn-lg">
                     {{recipientIndexName}} 
                     <svg style="color: white;"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-down" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                        <path fill-rule="evenodd" d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                      </svg>
             </button>
        </div>
        <br>

        <!-- <div> -->

        <div class="row">

               <form [formGroup]="newChatGroupName" >
                  <mat-form-field class="full-width">
                    <input
                    placeholder="INPUT NEW GROUP NAME"
                    matInput 
                    type="text"
                    id="name"
                    formControlName="name"
                    maxlength="30">
                    <mat-hint>  required </mat-hint>
                  </mat-form-field>       
                </form>
        </div>

        <br>
        <br>
    
            <div class="row" *ngFor="let contact of contacts; let i=index">
                <app-contact-item 
                           [weAreOnShareParagraphFormIndex]="weAreOnShareParagraphFormIndex"
                           [isContactEditMode]="isContactEditMode"
                           [contact]="contact"
                           (removeContactFromList)='onRemoveContactFromList($event)'
                           (addContactToList)='onAddContactToList($event)'>
            </app-contact-item>
            </div> 
       

        <br>

        <div class="row">
            <div class="col-xs-9">
                <form [formGroup]="newContactName" >
                    <mat-form-field class="full-width">
                      <input
                      placeholder="add email contact "
                      matInput 
                      type="email"
                      id="contactName"
                      formControlName="contactName"
                      maxlength="50">
                      <mat-hint>  required </mat-hint>
                    </mat-form-field>       
                  </form>
            </div>

            <div class="col-xs-1">
                <button class="btn btn-primary">+</button>
            </div>
            <div class="col-xs-2"></div>

           
     </div>
        

            <br>

            <div class="row">
                <button (click)="onCreateNewChatRecipient()" 
                        mat-dialog-close 
                        type="button" 
                        class="btn btn-danger btn-lg" > create new group </button>
            </div>

        <!-- </div> -->
           
    </div>
</div>
    
</div>