import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "../app-routing.module";
import { ContactsModule } from "../contacts/contacts.module";
import { DateAndTimeModule } from "../date-and-time/date-and-time.module";
import { MaterialDesignModule } from "../material-design/material-design.module";
import { SharedModule } from "../shared/shared.module";
import { ChatItemComponent } from "./chat-item/chat-item.component";
import { ChatsComponent } from "./chats.component";
import { ChatEditComponent } from './chat-item/chat-edit/chat-edit.component';
import { ChatItemContentComponent } from './chat-item/chat-item-content/chat-item-content.component';
import { ChatParagraphResultListComponent } from './chat-item/chat-item-content/chat-form-result-list/chat-form-result-item/chat-paragraph-result-list/chat-paragraph-result-list.component';
import { ChatItemContentHeaderComponent } from './chat-item/chat-item-content/chat-item-content-header/chat-item-content-header.component';
import { ChatParagraphResultItemComponent } from './chat-item/chat-item-content/chat-form-result-list/chat-form-result-item/chat-paragraph-result-list/chat-paragraph-result-item/chat-paragraph-result-item.component';
import { ChatListIndexComponent } from './chat-list-index/chat-list-index.component';
import { ChatItemIndexComponent } from './chat-list-index/chat-item-index/chat-item-index.component';
import { ChatFormResultListComponent } from './chat-item/chat-item-content/chat-form-result-list/chat-form-result-list.component';
import { ChatFormResultItemComponent } from './chat-item/chat-item-content/chat-form-result-list/chat-form-result-item/chat-form-result-item.component';
import { FormResultsModule } from "../forms/form-results/form-results.module";
import { PipesModule } from "../pipes/pipes.module";
import { ChatFillParagraphComponent } from './chat-item/chat-edit/chat-fill-form/chat-fill-paragraph/chat-fill-paragraph.component';
import { ChatFillFormComponent } from './chat-item/chat-edit/chat-fill-form/chat-fill-form.component';

import { InputModule } from "../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/input.module"; 
import { ButtonsModule } from "../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/buttons/buttons.module";
import { MultiFeaturesModule } from "../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/multi-features-list/multi-features.module";
import { ChatCommentFormComponent } from './chat-item/chat-edit/chat-comment-form/chat-comment-form.component';
import { ChatCommentParagraphComponent } from './chat-item/chat-edit/chat-comment-form/chat-comment-paragraph/chat-comment-paragraph.component';
import { ChatCommentFeaturesComponent } from './chat-item/chat-edit/chat-comment-form/chat-comment-paragraph/chat-comment-features/chat-comment-features.component';
import { ChatCommentInputComponent } from './chat-item/chat-edit/chat-comment-form/chat-comment-paragraph/chat-comment-features/chat-comment-input/chat-comment-input.component';

import { RecipientModule } from "../forms/form-list/form-item/recipient/recipient.module";
import { NewSubjectFormComponent } from './chat-item/chat-edit/chat-fill-form/new-subject-form/new-subject-form.component';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { NewChatDialogComponent } from './new-chat-dialog/new-chat-dialog.component';

import { DrappLayoutModule } from "../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/layout/drapp-layout.module";
import { TabsModule } from '../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/tabs/tabs.module' ;
import { TextsModule } from "../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/texts/texts.module";
import { CalendarModule } from "../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/calendar.module";
import { CalculatorModule } from "../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calculator/calculator.module";
import { SelectModule } from "../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/select/select.module";
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { ChatFillFormFromBottomSheetComponent } from "./chat-item/chat-edit/chat-fill-form/chat-fill-form-from-bottom-sheet/chat-fill-form-from-bottom-sheet.component";
import { ChatFillFormMobileComponent } from './chat-item/chat-edit/chat-fill-form/chat-fill-form-mobile/chat-fill-form-mobile.component';
import { ChatFillFormMobileLightningComponent } from './chat-item/chat-edit/chat-fill-form/chat-fill-form-mobile-lightning/chat-fill-form-mobile-lightning.component';
import { ChatFillFormMobileCommentComponent } from './chat-item/chat-edit/chat-fill-form/chat-fill-form-mobile-comment/chat-fill-form-mobile-comment.component';
import { ChatReloadItemComponent } from './chat-reload-item/chat-reload-item.component';
import { CommentModule } from "../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/comment/comment.module";
import { ChatParagraphIndexTopicListComponent } from './chat-paragraph-index-topic-list/chat-paragraph-index-topic-list.component';
import { ChatParagraphIndexTopicComponent } from './chat-paragraph-index-topic-list/chat-paragraph-index-topic/chat-paragraph-index-topic.component';
import { ChatParagraphFeatureContainerComponent } from './chat-paragraph-index-topic-list/chat-paragraph-index-topic/chat-paragraph-feature-container/chat-paragraph-feature-container.component';
import { ChatParagraphIndexComponent } from './chat-paragraph-index-topic-list/chat-paragraph-index-topic/chat-paragraph-feature-container/chat-paragraph-index/chat-paragraph-index.component';
import { AuxiliaryComponentsModule } from "../loading-spinner/auxiliary-components.module";


@NgModule ({
    declarations: [
        ChatsComponent,
        ChatItemComponent,
        ChatEditComponent,
        ChatItemContentComponent,
        ChatParagraphResultListComponent,
        ChatItemContentHeaderComponent,
        ChatParagraphResultItemComponent,
        ChatListIndexComponent,
        ChatItemIndexComponent,
        ChatFormResultListComponent,
        ChatFormResultItemComponent,
        ChatFillParagraphComponent,
        ChatFillFormComponent,
        ChatCommentFormComponent,
        ChatCommentParagraphComponent,
        ChatCommentFeaturesComponent,
        ChatCommentInputComponent,
        NewSubjectFormComponent,
        NewChatDialogComponent,
        ChatFillFormFromBottomSheetComponent,
        ChatFillFormMobileComponent,
        ChatFillFormMobileLightningComponent,
        ChatFillFormMobileCommentComponent,
        ChatReloadItemComponent,
        ChatParagraphIndexTopicListComponent,
        ChatParagraphIndexTopicComponent,
        ChatParagraphFeatureContainerComponent,
        ChatParagraphIndexComponent,
      
      
    ],

    exports: [
        ChatsComponent,
        ChatItemComponent,
        ChatParagraphIndexTopicListComponent,
        ChatParagraphIndexTopicComponent,
        ChatParagraphFeatureContainerComponent,
        ChatParagraphIndexComponent,
    ],

    imports: [
      
        AppRoutingModule,
        CommonModule,
        FormsModule,
        BrowserAnimationsModule,
        MaterialDesignModule, 
        ReactiveFormsModule,
        MatDialogModule,
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatExpansionModule,
        SharedModule,
        DateAndTimeModule,
        ContactsModule,
        FormResultsModule,
        PipesModule,
        InputModule,
        ButtonsModule,
        MultiFeaturesModule,
        RecipientModule,
        MatAutocompleteModule,
        DrappLayoutModule,
        TabsModule,
        TextsModule,
        RecipientModule,
        CalendarModule,
        CalculatorModule,
        SelectModule,
        ScrollingModule,
        MatBottomSheetModule,
        CommentModule,
        AuxiliaryComponentsModule
        
    ]
})
export class ChatsModule {}