import { Component, OnInit , ViewChild, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { FixLayoutService } from './fix-layout.service';
import { FixLayout } from './fix-layout.model';
import { FixLayoutTableTR } from './fix-layout-table-tr.model';
import { FixLayoutTableTD } from './fix-layout-table-td.model';
import { Feature } from '../../feature.model';
import { Observable , Subject, Subscription} from 'rxjs';
import { FixLayoutHttpRequestService } from './fix-layout-http-request.service';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { CreateNewFeatureContainerAndAddToFeatureContainerListItemService } from 'src/app/services/shared-services/create-new-feature-container-and-update-feature-container-list-item.service';
import { AdviseIfHttpStoreComponentsCallHasBeenMadeService } from 'src/app/services/other-components-services/advise-if-http-store-components-has-been-made.service';
import { StoreBaseComponentsService } from 'src/app/services/get-and-store-components/store-base-components.service';
import { FeatureContainerListItemService } from '../../../../paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container-list-item/feature-container-list-items.service';
import { FeatureContainerListItem } from '../../../../paragraph-edit/feature-container-list-item.model';
import { FeatureContainer } from '../../../../paragraph-edit/feature-container.model';
import { FeatureContainerService } from '../../../../paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container/feature-container.service';

@Component({
  selector: 'app-fix-layout-list',
  templateUrl: './fix-layout-list.component.html',
  styleUrls: ['./fix-layout-list.component.css']
})
export class FixLayoutListComponent implements OnInit , OnDestroy {

  user: string 

  thisTypeOfComponent: string = 'fixLayout'

  fixLayout: FixLayout
  fixLayouts: FixLayout[]

  newTabIndex0: number ;
  newtabIndex : number ;
   panelOpenState = false;
  customCollapsedHeight: string = '20px';
  customExpandedHeight: string = '20px';
  index : number ;
  i : number ;

   

  // @Input() selectedIndex : number   ; 
  selectedIndex: number  ;
  findSelectedIndex: number
  // @Input() selectedIndex_id: string
  @Input() feature_from_paragraphEdit: Feature 
  @Input() dropId : number ;
  @Input() isParagraphEdit: boolean
  @Input() editFeature: Observable<void> 

  receiveFeatureInfoFromParagraphEdit: Subscription; 
  @Input() collectFeatureId: Observable<Feature>

    /**
     * receiving subscription from ParagraphEdit to edit the component 
     */
     subscriptionToEditComponentItemIsOn: boolean


  currentFeature: Feature
  feature : Feature ;
  //details : WordHintDetail[];

  @Output() noNeeded: boolean ;

  /** emit the event to praragraph edit to change the array list */
  @Output() changeIndex = new EventEmitter<Feature>()  ; 
  @Output() holdOnCreateUpdateButtons = new EventEmitter<void>()
  @Output() eventIndex: number

  /** limit on wordHint to be added */
  createButtonLimit: boolean;
  fixLayoutButtonsLimit = 15
  
  @Input() currentI : number ;
  
 @ViewChild('tabs') tabGroup: MatTabGroup

  /**
     * click and receive the results from paragraph Edit
     */
   editFeatureEventFromParagraphEdit: Subscription;
   clickOnEditFeatureItem: Subject<Feature> = new Subject<Feature>()

   @Output() emitToParagraphEditIsDoneAndTurnDroppedFalse = new EventEmitter<boolean>()

   isLoading: boolean
   isLoadingList: boolean
  fixLayoutsHasBeenStoredSubscription: Subscription 

  featureContainerPlusParentsHaveBeenStored: Subscription 

  error = null 

  updateOnUserEmail : Subscription

  matToolTipMessageOnCreateNew: string 

  


  constructor( private fixlayoutService: FixLayoutService,
               private postComponentsHttpRequestService: PostComponentsHttpRequestService , 
               private userObjectUpdateService: UserObjectUpdateService , 
               private adviseIfHttpStoreComponentsCallHasBeenMadeService: AdviseIfHttpStoreComponentsCallHasBeenMadeService ,
               private storeBaseComponentsService: StoreBaseComponentsService ,
               private featureContainerService: FeatureContainerService ,
               private featureContainerListItemService: FeatureContainerListItemService , 
               private createNewFeatureContainerAndAddToFeatureContainerListItemService: CreateNewFeatureContainerAndAddToFeatureContainerListItemService ) { }

  ngOnInit(): void {

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
    .subscribe((updatedUserEmail)=>{
     this.user = updatedUserEmail
     console.log('updated USER = ', this.user)
})
console.log(this.user )

this.subscriptionToEditComponentItemIsOn = false
this.isLoading = true 
this.isLoadingList = true 

if ( this.user === null ){console.log('is going directly to continue as NULL user'), this.continueInitialOnInit()}
if ( this.user !== null ){
  if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.fixLayoutsHasBeenStored === true  ){ console.log('is going direvtly to continue'),   this.continueInitialOnInit() }
  if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.fixLayoutsHasBeenStored === false ){ console.log('is calling http'), this.storeBaseComponentsService.callBaseComponentsListByUserAndType(this.user , 'fixLayout') }
}

this.editFeatureEventFromParagraphEdit = this.editFeature.subscribe(() => this.onEditFeatureItem() )
this.receiveFeatureInfoFromParagraphEdit = this.collectFeatureId.subscribe((data)=> this.updateCurrentFeature(data))

this.fixLayoutsHasBeenStoredSubscription = this.fixlayoutService.initialPersonalFixLayoutsHaveBeenStored
    .subscribe(()=> { 
     this.continueInitialOnInit()
     this.adviseIfHttpStoreComponentsCallHasBeenMadeService.fixLayoutsHasBeenStored = true 
})

this.featureContainerPlusParentsHaveBeenStored = this.featureContainerListItemService.newFeatureContainerAndparentsHaveBeenStored
    .subscribe((feature_plus_typeOfComponent: any )=> {

      const currentFeature:Feature = feature_plus_typeOfComponent.feature 
      const currentTypeOfComponent: string = feature_plus_typeOfComponent.featureContainerName

      console.log(feature_plus_typeOfComponent)
      if ( currentTypeOfComponent === this.thisTypeOfComponent){
        this.onEmitToParagraphEditChangedIndex(currentFeature)
      }

})
    

  }

  continueInitialOnInit(){

    console.log('IT S CONTINUING INITIAL ONINIT')
    const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('fixLayout')

    this.fixLayouts = []
    for ( let i=0; i<personalFeatureContainerListItem.featureContainer_ids.length ; i++ ){
          let currentFeatureContainer: FeatureContainer = this.featureContainerService.getFeatureContainerBy_id(personalFeatureContainerListItem.featureContainer_ids[i])
          this.fixLayouts.push(this.fixlayoutService.getFixLayout_By_id(currentFeatureContainer.componentReference_id))
    }
    console.log(this.fixLayouts)

    this.selectedIndex = this.fixLayouts.findIndex(x => x._id === this.feature_from_paragraphEdit.feature_id)

    this.matToolTipMessageOnCreateNew = " CREATE A COPY OF  '1 - "+this.fixLayouts[0].name+"'"

    if (this.fixLayouts.length < this.fixLayoutButtonsLimit){ this.createButtonLimit = true }
    if (this.fixLayouts.length >= this.fixLayoutButtonsLimit){this.createButtonLimit = false}

    if (this.user === null ){ setTimeout(()=>{ this.isLoading = false , this.isLoadingList = false },200) }
    if (this.user !== null ){ this.isLoading = false , this.isLoadingList = false  }
   

  }


  /** for each click we recreate a new feature  */
onLinkClick($event) {

  this.index = this.tabGroup.selectedIndex ; 

  const newOwner = this.user; 
  const newIsNew = false
  const newDropId = this.currentI;
  const newFeature_id = this.fixLayouts[this.index]._id
  const newFeatureName = 'fixLayout' ;
  const newFeature = new Feature ( newOwner, newIsNew,  newDropId , newFeature_id, newFeatureName) ;
 
  this.feature = newFeature;
 
  this.changeIndex.emit(this.feature)
  this.eventIndex = this.currentI ;
 
  console.log('drop id =', this.currentI)
  console.log(this.feature)
 
  };

  onCreate(){

  this.isLoading = true
  this.isLoadingList = true 
  this.onCreateNew()


  }

   // create a new base fixLayout 
  onCreateNew(){

    const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('fixLayout')
    this.newTabIndex0 = personalFeatureContainerListItem.featureContainer_ids.length+1
  

   const new_id = 'fixLay'+ this.newTabIndex0.toString() ; ;
   const newComponentName = 'fixLayout'
   const newUIID ='fixLayout-'+ this.newTabIndex0.toString()
   const newName = 'new tab '+ this.newTabIndex0.toString() ;
   const newOwner = this.user ;
   const newAllUsersAllowed = false ;
   const newUserListAllowed = [];
   const newDateAndTime = null ;

    const newTableTDType1 = 'string' ;
    const newTableTDDetailString1 = 'edit word '+this.newTabIndex0.toString() ;
    const newTableTDDetailStringLabel1 = 'edit label '+this.newTabIndex0.toString() ;
    const newTableTDDetailNumber1 = null ;
    const newTableTDDetailNumberLabel1 = null ;
    const newTableTDDetailDescription1 = null ;
    const newTableTDDetailDescriptionLabel1 = null ;
    const newTableTDDetailChartShare1 = null ;
    const newTableTDDetailChartTotal1 = null ;
    const newTableTDDetailWidth1 = '200px' 

    const newTableTDString = new FixLayoutTableTD ( newTableTDType1, newTableTDDetailString1, newTableTDDetailStringLabel1, newTableTDDetailNumber1,
                                                    newTableTDDetailNumberLabel1, newTableTDDetailDescription1, newTableTDDetailDescriptionLabel1,
                                                    newTableTDDetailChartShare1, newTableTDDetailChartTotal1, newTableTDDetailWidth1 )

    const newTableTDType = 'description' ;
    const newTableTDDetailString = null ;                                   
    const newTableTDDetailStringLabel = null ;                       
    const newTableTDDetailNumber = null ;
    const newTableTDDetailNumberLabel = null ;
    const newTableTDDetailDescription = 'edit sentence '+this.newTabIndex0.toString() ;
    const newTableTDDetailDescriptionLabel = 'edit label '+this.newTabIndex0.toString() ;
    const newTableTDDetailChartShare = null ;
    const newTableTDDetailChartTotal = null ;
    const newTableTDDetailWidth = '500px' 
                                                
    const newTableTDDescription = new FixLayoutTableTD ( newTableTDType, newTableTDDetailString, newTableTDDetailStringLabel, newTableTDDetailNumber,
                                                                                                    newTableTDDetailNumberLabel, newTableTDDetailDescription, newTableTDDetailDescriptionLabel,
                                                                                                    newTableTDDetailChartShare, newTableTDDetailChartTotal, newTableTDDetailWidth)
    const newFixlayoutTableTr1 = new FixLayoutTableTR ([(newTableTDString)])
    const newFixlayoutTableTr2 = new FixLayoutTableTR ([(newTableTDDescription)])

    const newFixLayoutTableTRs = [ newFixlayoutTableTr1, newFixlayoutTableTr2  ] 

    const newFixLayout = new FixLayout ( new_id, newComponentName, newUIID, newName, newOwner, newAllUsersAllowed,
                                         newUserListAllowed, newDateAndTime, newFixLayoutTableTRs)
    
  
    if ( this.user === null ){ 
      this.fixlayoutService.onAddNew(newFixLayout);
         
      const newOwner = this.user; 
      const newIsNew = false;
      const newDropId = this.currentI ;
      const newFeature_id = newFixLayout._id;
      const newFeatureName = 'fixLayout' ;
      const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
      this.feature = newFeature
    
      console.log(' FIX TEXTS = ', this.fixLayouts, 'IT S EMITTING CHANGE INDEX FEATURE')
    
       console.log(this.feature)
      this.createNewFeatureContainerAndAddToFeatureContainerListItemService.createFeatureContainer_toAddOnFeatureContainerListItem(newFeature, this.user )
    
    }
   
    if ( this.user !== null ){
    this.postComponentsHttpRequestService.httpStoreComponent(this.user, 'fixLayout', newFixLayout )
        .subscribe((response: FixLayout) => {

      this.fixlayoutService.onAddNew(response);

      this.newtabIndex = this.fixLayouts.length+1;

      const newOwner = this.user; 
      const newIsNew = false
      const newDropId = this.currentI ;
      const newFeature_id = response._id
      const newFeatureName = 'fixLayout' ;
      const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
     
      this.feature = newFeature

      this.createNewFeatureContainerAndAddToFeatureContainerListItemService.createFeatureContainer_toAddOnFeatureContainerListItem(newFeature, this.user )

    },  error => {

      console.log(error)

      const errorMessage: string = "we weren't able to create the new component, check the network or try later."
      this.error = errorMessage
      this.isLoadingList = false 
      /**
       *  EMIT and event to paragraphEdit to put update or create buttons for 4,5 sec 
       */
      this.holdOnCreateUpdateButtons.emit()

      /**
       * return to previous set up without the new component after a while to be able to read the message 
       */
      setTimeout(()=>{
        this.error = null 
        this.isLoadingList = false 
        this.isLoading = false 
      },4000)

    }
    )
  }
   
  };

  onEmitToParagraphEditChangedIndex(toEmitFeature: Feature ){
    console.log('is emitting changed feature')
    this.changeIndex.emit(toEmitFeature)
    // once this feature is emitted the paragraphEdit will change index and this whole 
    // component will restart again
  }



   /**
   * 
   * @param data feature that is been transmitted from paragraphEdit to check which of the 
   * features list has to pass the subscription to the feature item to be edited 
   */
    updateCurrentFeature(data){
      this.currentFeature = data;
      console.log(this.currentFeature)
      }


  onEditFeatureItem(){
    console.log('is opening another dialog from list component ')
    console.log(this.currentI)
    console.log(this.currentFeature)

      if( this.currentFeature.dropId === this.currentI){
        this.clickOnEditFeatureItem.next(this.currentFeature)
       }
   
    console.log('is arrived')
  }

  onUpdateDroppedConditionFromFixLayout($event){
    this.emitToParagraphEditIsDoneAndTurnDroppedFalse.emit($event)
  }

  ngOnDestroy(){
    this.receiveFeatureInfoFromParagraphEdit.unsubscribe();
    this.editFeatureEventFromParagraphEdit.unsubscribe();
    this.fixLayoutsHasBeenStoredSubscription.unsubscribe()
  }

}
