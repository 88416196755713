import { Component, OnInit , Input, NgZone, ChangeDetectorRef} from '@angular/core';
import { Chat } from '../../chat.model';
import { ChatService } from '../../chat.service';
import { FormResult } from 'src/app/forms/form-results/form-result.model';
import { FormResultsService } from 'src/app/forms/form-results/form-results.service';
import { ParagraphService } from 'src/app/forms/form-list/form-item/paragraph/paragraph.service';
import { Recipient } from 'src/app/forms/form-list/form-item/recipient/recipient.model';
import { RecipientService } from 'src/app/forms/form-list/form-item/recipient/recipient.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { TimeAlignService } from 'src/app/services/other-components-services/time-align.service';
import { Subscription, interval } from 'rxjs';

interface ChatInterface {
  name: string, 
  lastDateTime: string,
  lastSubject: string,
  lastSender: string
}

// interface ListFormResult{
//   totNum: number,
//   innerResults: InnerFormResultInterface[]
// }

// interface InnerFormResultInterface {
//   sender: string
//   dateTime: string
//   subject: string
// }

// interface ChatAndInnerFormResults{
//   numberOfItems: number ,
//   innerChatFormResults: InnerChatFormResultNumbers[]
// }

interface InnerChatFormResultNumbers{
  chat: Chat,
  isSelected: boolean,
  innerChatFormResults: FormResult[]
}





@Component({
  selector: 'app-chat-item-index',
  templateUrl: './chat-item-index.component.html',
  styleUrls: ['./chat-item-index.component.css']
})

export class ChatItemIndexComponent implements OnInit {

  user : string

 
 @Input() innerChatFormResultNumbers: InnerChatFormResultNumbers

 chatInterface: ChatInterface


 sender: string ; 

 chatSubjectExist: boolean;
 chatIsVisible: boolean

 chat: Chat ;
 formResults: FormResult[]
 formResults_VisualLimit: number = 3
 formResultsMinutesHoursAgoString: string[]

 timeIntervalSubscription: Subscription


  constructor(private chatService: ChatService , 
              private recipientService: RecipientService,
              private formResultsService: FormResultsService,
              private userObjectUpdateService: UserObjectUpdateService ,
              private paragraphService: ParagraphService , 
              private timeAlignService: TimeAlignService,
              private cdr: ChangeDetectorRef ,
               private ngZone: NgZone
              ) { }

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    this.chat = this.innerChatFormResultNumbers.chat 

    this.formResults = this.innerChatFormResultNumbers.innerChatFormResults 

    let minHoursAgo: string
    this.formResultsMinutesHoursAgoString = []
    for ( let i=0 ; i< this.formResults.length ; i++ ){
          minHoursAgo = this.getDateTime_InMinuteHours(this.formResults[i].dateAndTime) 
          this.formResultsMinutesHoursAgoString.push(minHoursAgo)
    }
    
    console.log(this.chat, ' formResults =>',this.formResults , 'number of formResults =>', this.formResults.length)

    this.chatInterface = { name: this.formResults[0].recipientResult.name , 
                           lastDateTime: this.formResults[0].dateAndTime , 
                           lastSubject: this.formResults[0].subject ,
                           lastSender: this.formResults[0].sender.content,
                          }

    if ( this.chat !== undefined ){ this.chatIsVisible = true}
    
    this.chatSubjectExist = true 
    console.log(this.chat)

    this.timeIntervalSubscription = interval(60000).subscribe(() => {

      /**
       * at the moment create a string[]with the same number as the formResults 
       * and each formresult will have it's own minute/hour changed 
       * into a 
       */
      this.ngZone.run(()=>{
        let minHoursAgo2: string
        this.formResultsMinutesHoursAgoString = []
        for ( let i=0 ; i< this.formResults.length ; i++ ){
              minHoursAgo2 = this.getDateTime_InMinuteHours(this.formResults[i].dateAndTime) 
          this.formResultsMinutesHoursAgoString.push(minHoursAgo2)
        }
      }) // end ngZone 
      this.cdr.detectChanges()

      
      console.log('make a child component for each formResult , then subscribe to change each min/hour eveyr minute ');
    });

  }

  getDateTimeInWords(dateInString: string){
    const newDateString = new Date(dateInString)
    return this.timeAlignService.getRelativeTime(newDateString)
  };

  getDayOfTheWeek(dateInString: string){
    const newDateString = new Date(dateInString)
    return this.timeAlignService.getDayOfTheWeek(newDateString)
  }




  getDateTime_InMinuteHours(dateInString: string){
    const newDateString = new Date(dateInString)
    let result: string 
    if ( this.timeAlignService.getRelativeTime_Minutes_Hours(newDateString) !== undefined ){ result = this.timeAlignService.getRelativeTime_Minutes_Hours(newDateString) }
    if ( this.timeAlignService.getRelativeTime_Minutes_Hours(newDateString) === undefined ){ result = '' }

    return result 
  };

  getDateTime_InDaysWeeksMonths(dateInString: string){
    const newDateString = new Date(dateInString)
    let result: string = this.timeAlignService.getRelativeTime_DayWeekMonthYearsAgo(newDateString)

    return result 
  }


}
