import { Injectable } from "@angular/core";
import { SharedCalendarStatus } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/shared-calendar-status-list/shared-calendar-status.model";
import { SharedCalendarStatusService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/shared-calendar-status-list/shared-calendar-status.service";
import { WordInput } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/word/word-input.model";
import { WordInputService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/word/word-input.service";
import { FixText } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/texts/fix-text-list/fix-text.model";
import { clone } from 'lodash'
import { FixTextService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/texts/fix-text-list/fix-text.service";

@Injectable ({
    providedIn: 'root' 
})

export class GetFeatureByTypeAndIdService {

    constructor( private sharedCalendarStatusService: SharedCalendarStatusService , 
                 private wordInputService: WordInputService,
                 private fixTextService: FixTextService 
        ){}


    getFeature( type: string , _id: string ){

     let selectedFeature: SharedCalendarStatus | WordInput | FixText 
     
     if (type === "sharedCalendarStatus" ){
         const sharedCalendarStatusBase: SharedCalendarStatus = this.sharedCalendarStatusService.getSharedCalendarStatus_By_id(_id)
         console.log("sharedCalendarStatus Base => ",sharedCalendarStatusBase )


         const newSharedCalendarStatusBase: SharedCalendarStatus = clone(sharedCalendarStatusBase)
               newSharedCalendarStatusBase.rangeDatesInfos = []
               newSharedCalendarStatusBase.allUsersAllowed = false 
               newSharedCalendarStatusBase.userListAllowed = []
               
               selectedFeature = newSharedCalendarStatusBase
         
     }

     if (type === "wordInput" ){
        const wordInput: WordInput = this.wordInputService.getWordInputBy_id(_id)
        console.log("wordInput Base => ", wordInput )

        selectedFeature = clone(wordInput) 
    }

    // if ( type === "fixText"){
    //     const fixText: FixText = this.fixTextService.getFixTextBy_id(_id)
    //     console.log("fix text base => ", fixText )

    //     selectedFeature = clone(fixText) 
    // }

    if ( type === "fixText"){
        const fixText: FixText = this.fixTextService.getFixTextBy_id(_id)
        console.log("fix text base => ", fixText )

        selectedFeature = clone(fixText) 
    }

    
            selectedFeature._id = null 
            selectedFeature.UIID = null 
     return selectedFeature 


    }



}