import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ExchangeRateService } from './exchange-rate.service';

@Component({
  selector: 'app-exchange-rate',
  templateUrl: './exchange-rate.component.html',
  styleUrls: ['./exchange-rate.component.css']
})
export class ExchangeRateComponent implements OnInit {

  amount: number;
  currency1: string;
  currency2: string;
  currencies: string[] = [
    'USD', 'EUR', 'GBP', 'JPY', 'CAD', 'AUD', 'CHF', 'CNY', 'HKD', 'NZD',
    'SEK', 'KRW', 'SGD', 'NOK', 'MXN', 'INR', 'RUB', 'ZAR', 'TRY', 'BRL',
    'TWD', 'DKK', 'PLN', 'THB', 'IDR', 'HUF', 'CZK', 'ILS', 'CLP', 'PHP'
  ];
  exchangeRates: {[key: string]: number} = {
    'USD': 1.2,
    'EUR': 1.0,
    'GBP': 0.88,
    'JPY': 130.4,
    'CAD': 1.45,
    'AUD': 1.65,
    'CHF': 0.92,
    'CNY': 8.0,
    'HKD': 9.7,
    'NZD': 1.78,
    'SEK': 10.2,
    'KRW': 1120.9,
    'SGD': 1.58,
    'NOK': 10.5,
    'MXN': 22.5,
    'INR': 87.6,
    'RUB': 78.3,
    'ZAR': 14.5,
    'TRY': 8.8,
    'BRL': 4.4,
    'TWD': 29.4,
    'DKK': 6.3,
    'PLN': 4.0,
    'THB': 36.2,
    'IDR': 14250.0,
    'HUF': 312.0,
    'CZK': 23.0,
    'ILS': 3.3,
    'CLP': 700.0,
    'PHP': 47.5
  };
  exchangeRate: number;

  errorMessage = null 

  constructor( private exchangeRateService: ExchangeRateService,
               private http: HttpClient ) { }

  ngOnInit(): void {
    this.currency1 = this.currencies[0];
    this.currency2 = this.currencies[1];
  }

  // exchange(): void {
  //   this.exchangeRateService.getExchangeRate(this.currency1, this.currency2).subscribe(
  //     exchangeRate => this.exchangeRate = exchangeRate

  //     /**
  //      * need to register to get the get the api working 
  //      * https://exchangeratesapi.io/
  //      * https://openexchangerates.org/signup
  //      */
  //   );
  // }

  // exchange() {
  //   if (this.currency1 === this.currency2) {
  //     this.exchangeRate = 1;
  //   } else {
  //     const url = `http://data.fixer.io/api/latest?access_key=<fyhoTbr0DoLnmjqA2wgsVUtLG00u0jRI>&base=${this.currency1}&symbols=${this.currency2}`;
  //     this.http.get(url).subscribe((data: any) => {
  //       this.exchangeRate = data.rates[this.currency2];
  //     });
  //   }
  // }

  // exchange(): void {
  //   if (!this.amount || !this.currency1 || !this.currency2) {
  //     return;
  //   }

  //   const rate1 = this.exchangeRates[this.currency1];
  //   const rate2 = this.exchangeRates[this.currency2];

  //   this.exchangeRate = rate1 / rate2;
  // }

  exchange(): void {
    if (!this.amount || !this.currency1 || !this.currency2) {
      this.errorMessage = 'Please fill in all fields.';
      return;
    }
  
    const rate1 = this.exchangeRates[this.currency1];
    const rate2 = this.exchangeRates[this.currency2];
  
    this.exchangeRate = rate2 / rate1 ;
  }

}
