import { Component, Input, OnInit } from '@angular/core';
import { DateStartEnd } from '../date-start-end.model';

@Component({
  selector: 'app-inner-date-range-item',
  templateUrl: './inner-date-range-item.component.html',
  styleUrls: ['./inner-date-range-item.component.css']
})

export class InnerDateRangeItemComponent implements OnInit {

@Input() startingDate: Date
@Input() endingDate: Date 

  constructor() { }

  ngOnInit(): void {

  }

}
