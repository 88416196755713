import { Component, OnInit, Injectable, Inject, Input } from '@angular/core';
import {  FormBuilder, NgForm } from '@angular/forms';
import { MatDialogRef , MAT_DIALOG_DATA, _closeDialogVia } from '@angular/material/dialog';
import { DialogmanagerService } from '../../../../../../../../../../../services/other-components-services/dialogmanager.service' ;
import { WordHintInput } from '../../word-hint-input.model';
import { WordHintDetail } from '../../word-hint-detail.model';

import { WordHintInputService } from '../../word-hint-input.service';
import { SharedWordHintInputService } from '../../shared-word-hint-input.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { Feature } from '../../../../feature.model';


//  @Injectable()
//  export class MyService  {

// }


@Component({
  selector: 'app-word-hint-input',
  templateUrl: './word-hint-input.component.html',
  styleUrls: ['./word-hint-input.component.css']
})
export class WordHintInputComponent implements OnInit {

  user: string 

  @Input() wordHintInput : WordHintInput;
  @Input() details : WordHintDetail[]; 
  @Input() index_id : string ; 
  @Input() feature: Feature
  index : number ; 
   message : string;

   @Input() noNeeded : boolean ; 

  
  
   



  constructor(
     private wordHintInputService: WordHintInputService  , 
     private sharedWordHintInputService : SharedWordHintInputService,
     private dms: DialogmanagerService ,
     private _fb: FormBuilder,
     public dialogRef: MatDialogRef<WordHintInputComponent>,
     private userObjectUpdateService: UserObjectUpdateService ,
    @Inject(MAT_DIALOG_DATA) public editData: any) {

  }

   ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail
    
     this.index = this.wordHintInputService.getWordHintInputs().findIndex(x=> x._id === this.index_id)
     this.wordHintInput = this.wordHintInputService.getWordHintInput(this.index);

     /**
      *   need to find the right index to get by finding first the index with owner + id
      *  this.wordHintInput = this.sharedWordHintInputService.getWordHintInput(this.index);
      */


  
  };



  // check on lesson 121 to make sure we update data the right way  
  // make sure we double check for the (splice) data copy so that we don't 
 
  openDialogWordHintEdit(){
    this.dms.WordHintEdit({ 
       index : this.index,
       name: this.wordHintInputService.getWordHintInput(this.index).name ,
       DetailsArray : this.wordHintInputService.getWordHintInput(this.index).details 

      }).subscribe(res => {

      console.log('the dialog was closed ');
        this.wordHintInput.name = res.name,
        this.wordHintInput.details = res.details
      } )
  }



  


      onCheckStatus(){
        console.log (this.wordHintInput)
      }
      
  

       onSubmit(form: NgForm){
        console.log (form)

      }
  }





