import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "src/app/app-routing.module";
import { MaterialDesignModule } from "src/app/material-design/material-design.module";
import { SharedModule } from "src/app/shared/shared.module";
import { SliderEditComponent } from "./slider-list/slider-edit/slider-edit.component";
import { SliderItemComponent } from "./slider-list/slider-item/slider-item.component";
import { SliderListComponent } from "./slider-list/slider-list.component";
import { SliderComponent } from "./slider.component";
import { SingleSliderComponent } from './single-slider/single-slider.component';
import { SingleSliderListComponent } from './single-slider/single-slider-list/single-slider-list.component';
import { SingleSliderItemComponent } from './single-slider/single-slider-list/single-slider-item/single-slider-item.component';
import { SingleSliderEditComponent } from './single-slider/single-slider-list/single-slider-edit/single-slider-edit.component';
import { AuxiliaryComponentsModule } from "src/app/loading-spinner/auxiliary-components.module";


@NgModule({
    declarations:[
        SliderComponent,
        SliderListComponent,
        SliderItemComponent,
        SliderEditComponent,
        SingleSliderComponent,
        SingleSliderListComponent,
        SingleSliderItemComponent,
        SingleSliderEditComponent,        
    ],

    exports:[
        SliderComponent,
        SliderListComponent,
        SliderItemComponent,
        SliderEditComponent,    
        SingleSliderComponent,
        SingleSliderListComponent,
        SingleSliderItemComponent,
        SingleSliderEditComponent

    ],

    imports:[
    AppRoutingModule,
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    MaterialDesignModule, 
    ReactiveFormsModule,
    MatDialogModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule,
    SharedModule,
    AuxiliaryComponentsModule

    ]
})
export class SliderModule{}