import { Component, OnInit , Output, OnDestroy, ViewChild, Input , EventEmitter , HostListener, Inject, ChangeDetectorRef, NgZone} from '@angular/core';
import { Form } from './../../form.model';
import { FormService } from './../../form.service' ;
import { DatePipe } from '@angular/common';
import { Recipient } from './recipient/recipient.model';
import { RecipientService } from './recipient/recipient.service';
import { RecipientIndex } from '../form-edit/recipient-index-list/recipient-index.model';
import { RecipientIndexService } from '../form-edit/recipient-index-list/recipient-index.service';
import { ParagraphFeature } from '../form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature.model';

import { Subscription, Subject , Observable, Observer, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatTabGroup } from '@angular/material/tabs';

import { FormDateAndTime } from 'src/app/date-and-time/form-date-and-time/form-date-and-time.model';
import { DateTime } from 'luxon';

import { ParagraphResult } from '../../form-results/form-results-list/form-result-item/paragraph-results/paragraph-result.model';
import { Sender } from '../../form-results/form-results-list/form-result-item/sender/sender.model';
import { FormResult } from '../../form-results/form-result.model';
import { FormResultsService } from '../../form-results/form-results.service';

import { Chat } from 'src/app/chats/chat.model';

import { ActivatedRoute, Router, Params } from '@angular/router';

import { DialogmanagerService } from 'src/app/services/other-components-services/dialogmanager.service';
import { MatDialogRef , MAT_DIALOG_DATA, _closeDialogVia} from '@angular/material/dialog';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { FormFeatureContainerService } from '../form-index-topic-list/form-feature-container.service';
import { FormFeatureContainer } from '../form-index-topic-list/form-feature-container.model';
import { RecipientIndexListItem } from '../form-edit/recipient-index-list/recipient-index-list-item.model';
import { RecipientIndexListItemService } from '../form-edit/recipient-index-list/recipient-index-list-item.service';
import { StoreFormItemsService } from 'src/app/services/get-and-store-components/store-form-items.service';
import { AdviseIfHttpStoreComponentsCallHasBeenMadeService } from 'src/app/services/other-components-services/advise-if-http-store-components-has-been-made.service';
import { StoreFormItemPlusChildsConfirmationService } from 'src/app/services/get-and-store-components/store-form-item-plus-childs-confirmation.service';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { ErrorsDialogManagerService } from 'src/app/services/other-components-services/errors-dialog-manager.service';
import { LogoutService } from 'src/app/login/logout.service';
import { Title } from '@angular/platform-browser';
import { RecipientIndexColumn } from '../form-edit/recipient-index-list/recipient-index-column.model';
import { RecipientIndexColumnService } from '../form-edit/recipient-index-list/recipient-index-column.service';
import { GetComponentsHttpRequestService } from 'src/app/services/gets-services/get-components-http-request.sevice';
import { RecipientFeatureService } from '../form-edit/recipient-index-list/recipient-feature.service';
import { environment } from 'src/environments/environment';
import { ParagraphService } from './paragraph/paragraph.service';

/** Custom options the configure the tooltip's default show/hide delays. */
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 2000,
  hideDelay: 100,
  touchendHideDelay: 1000,
};

@Component({
  selector: 'app-form-item',
  templateUrl: './form-item.component.html',
  styleUrls: ['./form-item.component.css'],
  providers: [{provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}],
})
export class FormItemComponent implements OnInit , OnDestroy {

  /**
   *  package properties for online / offline detection and mode
   */
  onlineOffline: boolean = navigator.onLine
  // ------------------------------------------------------------

  @ViewChild('tabs') tabGroup: MatTabGroup

  user: string

//  subjectEditForm : FormGroup ;

  
  form : Form ;
  _id : string ;
  @Output() recipientId: number ; 
  @Output() name: string ;
   recipientIndex: RecipientIndex ;
   recipientIndexListItem: RecipientIndexListItem 

  recipientIndexes: RecipientIndex[] = [];
  recipient: Recipient ;
  secondMailrecipient: Recipient ; 

  recipientIds: string[];
  paragraphFeatures : ParagraphFeature[]

  paragraphFeaturesExist: boolean = false 

  droppedRecipientIndex=true ;

  /** onlyRecipient */
  onlyRecipient: boolean = true ;

  /**this is to add the new tabs in the right location
   * this case if it's in form item ,  the recipientIds need to be added to form item service
   */
  youAreInFormEdit = false ;

  id: number ;

  isFormItem = true ;

  isMessageSide: boolean = true 

  /** to check if the recipientIndex is dropped */
  isDropped : boolean = false 

  /**
   * restart component serve when we have sent the form and we want to re-initialize 
   * the form item and easily unsubscribe all the subscription and child components 
   */
 //2 restartComponent: boolean

  /** from form service SubjectBehavior */
  receivedFormId: number ;
  receivedForm_id: string;
  // ** this is the event when clicked from form topic
  clickEventFromFormTopicIndexSubscription: Subscription ;
/**
 * to subscribe the paragraph results
 */
  subscribeToOneParagraphResultAtTheTime = new Subject<number>(); // number of the paragraphFeature.drop_id

  /**
   * here we start to build the forms reults  -------------------------------------
   */

   paragraphResult: ParagraphResult;
   paragraphResults: ParagraphResult[];
   sentParagraphResult: ParagraphResult;
   sentParagraphResults: ParagraphResult[];
   receivedParagraphResult: ParagraphResult;
   receivedParagraphResults: ParagraphResult[];

   finalSentParagraphResult: ParagraphResult;
  finalSentParagraphResults: ParagraphResult[] = [];

  finalReceivedParagraphResult: ParagraphResult;
  finalReceivedParagraphResults: ParagraphResult[] = [];

  finalSentFormResultForMessage: FormResult;
  finalSentFormResultForMail: FormResult

  //  recipientResult: Recipient;

   isForwardToMail: boolean ;
   isSentToMessage: boolean ;

  formDateAndTime: FormDateAndTime;
  dt = DateTime.now() ;
  dateTime: DateTime;

  date: string;
  timeHere: any ;
  timeAtSendForm: string;

  chat: Chat ;
  chats: Chat[];
  senderChatPartecipants : string[];
  receiverChatPartecipants: string[]

  recipId: number;
  // to tell recipient-item , and subscribe to the subscriptions
  weAreInFormItem = true ; 
  aFormExist: boolean 

  // ----------------------------------------------------------------------------

  changingRecipientId: Subject<void> = new Subject<void>();




   /**
    * groups of things to send to chat service to check if they correspond 
    */
   groupNameToCheck: string;
   currentFormPartecipants: string[]

   index: number ;
   recipient_index: number;
   recipientGroupName: string

   /**
    * possible chat numbers 
    */
   futureFormResultChat_roomid : string ;
   futureFormResultChatId : string ;

   chatExist : boolean 
   chatExistOnIndex: number 
   chatHasToBeCreated: boolean

   finalSubject: string;


  //  trialForm : Form
  //  trialForms: Form[]
   isLoading = false
   noFormsYet = false

    /**
    *  email composer
    */
     mailTextFinalComposition: string 
     mailTextReceiverTo: string ;
     mailTextReceiverCc: string ;
     mailTextReceiverBcc: string ;
     mailTextSubject: string ;
     mailTextRecipientGreetings: string;
     mailTextBody: string[] ; 
     mailTextBodyPart: string;
     almostFinalMailTextBody: string[];
     finalMailTextBody: string ;


     paragraphTextCounter: number;

    //  links : any[]= ['name:%0D%0AANDAREemail/name:%0D%0A', 'link1.com', 'link2.com', 'link3.com','link45678.com'];
    // mailList: any[]= [ 'tony@tony.com', 'bepy@bepy.com']

    updatedRecipientIndex: Subject<RecipientIndex> = new Subject<RecipientIndex>();
    selectedRecipient_position: number 

    restartRecipientItemComponent = new Subject<void>()
    restartRecipientListComponent = new Subject<void>()
    restartParagraphItemComponents = new Subject<void>()

    /**
     * tell recipient Item that Recipient List is taking over form Item and don't subscribe 
     */
    subscribeIsDroppedToRecipientItem = new Subject<void>()
    recipientListHasBeenDropped: boolean

    /**
     * cut and open formItem from FormComponent with isLoading
     */
  @Output()  emitInfoToCloseOpenFormItem = new EventEmitter<void>()

  /**
   * subscription to online/offline
   */
  subscriptionToOnlineOffline : Subscription


 // subscription from recipientIndexList and service to change recipient list
 subscriptionToChangeRecipientList: Subscription 
 /**
  * on update email 
  */
 updateOnUserEmail : Subscription


    aRecipientIndex_Substitute_isActive: boolean
    recipientIndex_exist: boolean = false 

    count: number 

    isLoadingForm: boolean = false 
    isSendingMessageEmail: boolean 
    stopLoadingFormSubscription: Subscription 
    askUserToLoginAgainSubscription: Subscription 

    askToDoubleCheckIfFormPlusChildsAlreadyExistOnStoreSubscription: Subscription 

    // checkIfFormItemExistOnformFeaturesofTypeOfIndex: Subscription 
    reconstructFormItemAfterParagraphsHasbeenStoredSubscription: Subscription 

    personalFormFeaturesAreLoadedSubscription: Subscription 
    othersFormFeaturesHaveBeenStoredSubscription: Subscription
    onCloseLoginDialogPopUpSubscription: Subscription

    routeParameterSubscription: Subscription

    isLoadingReceiverResult: boolean = false 

    isMessageOnly: boolean 
    isEmailOnly: boolean 
    isEmailMessage: boolean 
    messageRecipientIsTemporaryHidden: boolean = false 
    mailRecipientIsTemporaryHidden: boolean = false 

    isPersonalRecipientIndexListItem: boolean // serve to choose type of sorting of the recipient list 

    updatingRecipientIndexListItemsFromFeatureIndexUpdate: Subscription 
    updateSharedRecipientListItem_fromRecipientFeature = new Subject<RecipientIndexListItem>()

 
    startLoadRecipientsNumber1 = new Subject<void>()
    startLoadRecipientsNumber2 = new Subject<void>()

    isProduction: boolean

    unblockLoginPopUpToPopAgainSubscription: Subscription



 constructor(   private formService: FormService,
                private recipientService: RecipientService,
                private datePipe:DatePipe,
                private recipientIndexService: RecipientIndexService,
                private formResultsService: FormResultsService,
                private storeFormItemPlusChildsConfirmationService: StoreFormItemPlusChildsConfirmationService , 
                private formFeatureContainerService: FormFeatureContainerService,
                private recipientIndexListItemService: RecipientIndexListItemService , 
                private storeFormItemService: StoreFormItemsService ,
                private adviseIfHttpStoreComponentsCallHasBeenMadeService: AdviseIfHttpStoreComponentsCallHasBeenMadeService , 
                private router: Router,
                private route: ActivatedRoute ,
                private dms: DialogmanagerService, 
                private userObjectUpdateService: UserObjectUpdateService ,
                private postComponentsHttpRequestService: PostComponentsHttpRequestService ,
                public  dialogRef: MatDialogRef<FormItemComponent>,
                private errorsDialogManagerService: ErrorsDialogManagerService , 
                private logoutService: LogoutService , 
                private titleService: Title , 
                private cdr: ChangeDetectorRef ,
                private ngZone: NgZone ,
                private recipientIndexColumnService: RecipientIndexColumnService, 
                private getComponentsHttpRequestService: GetComponentsHttpRequestService,
                private paragraphService: ParagraphService,
                private recipientFeatureService: RecipientFeatureService,
                @Inject(MAT_DIALOG_DATA) public editData: any ) {
                  this.isProduction = environment.production
                 }
 
 @HostListener('document:visibilitychange', ['$event'])
 visibilitychange() {
    if (document.hidden){
          console.log("FORM ITEM IS HIDDEN " , new Date());
    } else {
          console.log("FORM ITEM IS VISIBLE" , new Date());

          this.routeParameterSubscription = this.route.params
               .subscribe((params:Params) => {
                const newParam = params['id']
                if ( this.isValidFormItem_id(newParam) ){ this.ngOnInit() }

               } )

         
          
          
      
    }
 }

 
 ngOnInit(){

  this.user = this.userObjectUpdateService.userEmail

  this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
    this.user = updatedUserEmail
    console.log('updated USER = ', this.user)
  })

  if ( this.user === null ){ this.messageRecipientIsTemporaryHidden = true }
  if ( this.user !== null ){ this.messageRecipientIsTemporaryHidden = false }

  console.log('this route = ',this.route)
  
/**
 * package online / offline property -------------------------------------------------
 */
  console.log('this device is online : ',this.onlineOffline)
  this.subscriptionToOnlineOffline = this.createOnline$().subscribe(isOnline => console.log( this.onlineOffline = isOnline,
    'this device is now online: ', this.onlineOffline, DateTime.now().toString() ));
// ------------------------------------------------------------------------------------

this.isMobile()
console.log(this.isMobile())
  
this.isLoading = true 
this.isSendingMessageEmail = false 

this.recipientListHasBeenDropped = false 


this.aRecipientIndex_Substitute_isActive = false 
 this.recipientIndex_exist = false 

 this.mailTextBody = [];
 this.mailTextBodyPart = '';
 this.mailTextRecipientGreetings = '';
 this.paragraphTextCounter = 0;
 /**
  * define form parameters
  */
  this.routeParameterSubscription = this.route.params
      .subscribe((params:Params) => this.changeForm_id(params))


   // this.timeHere = DateTime.local().toISO();
   this.timeHere = DateTime.now().toString();

    // this.date = this.dt.toLocaleString(DateTime.DATETIME_MED)
  this.date = this.dt.setLocale('it').toLocaleString()
  console.log(this.dt)
  this.dateTime = DateTime.local()
  console.log(this.dateTime)
  this.dt = DateTime.now();

  this.chatHasToBeCreated = false 

  this.sentParagraphResults = [] ;
  this.receivedParagraphResults = [];

  this.senderChatPartecipants = [];
  this.receiverChatPartecipants = []


   this.almostFinalMailTextBody = [];
   this.paragraphTextCounter = 0;

  



this.stopLoadingFormSubscription = this.storeFormItemService.stopLoadingForm
    .subscribe(()=>  {
       this.onFormNotAvailableOrIndexistent() 
    }     
)

this.askToDoubleCheckIfFormPlusChildsAlreadyExistOnStoreSubscription = this.storeFormItemService.askToDoubleCheckIfFormPlusChildsAlreadyExistOnStore
    .subscribe((res: any )=> {
      // { typeOfIndex, component_id } 
      const typeOfIndex = res.typeOfIndex 
      const component_id = res.component_id
      this.doubleCheckIfFormItemWasPreviouslyStored_afterItTriedToDownloadOnce( typeOfIndex, component_id )
    })

this.askUserToLoginAgainSubscription = this.storeFormItemService.askFormItemToLoginAgainOrStop
    .subscribe((component_idAndTypeOfIndex: any)=> {
      this.onAskingUserToLoginAgainOrBackTobasic(component_idAndTypeOfIndex)
    }
)

 // subscription from recipientIndexList and service to change recipient list
 this.subscriptionToChangeRecipientList = this.recipientIndexService.getRecipientIndexFromRecipientIndexListSelected()
 .subscribe((dataOfRecipientIndexAndRecipientIndexListItem)=>
  this.changeRecipientTorecipientList(dataOfRecipientIndexAndRecipientIndexListItem))



this.reconstructFormItemAfterParagraphsHasbeenStoredSubscription = this.storeFormItemPlusChildsConfirmationService.formItem_id_ToAddToFormItem
    .subscribe((formItem_id: string )=> {

      const updatedFormItem: Form = this.formService.getFormBy_id(formItem_id)
      // const currentTypeOfIndex: string = updatedFormPlusTypeOfIndex.typeOfIndex 
      const currentTypeOfIndex: string = 'public'

      this.recomposeForm( updatedFormItem , currentTypeOfIndex )
})

this.onCloseLoginDialogPopUpSubscription = this.logoutService.getClickToCloseLoginDialogPopUp()
    .subscribe(()=> {
      this.onFormNotAvailableOrIndexistent()
    }
)

this.updatingRecipientIndexListItemsFromFeatureIndexUpdate = this.recipientIndexListItemService.updateWorkingSharedRecipientIndexListItem
      .subscribe((res)=>{
        console.log('LOADING UPDATED RECIPIENT INDEX LIST ITEMS')
        // this.isLoading = true 
        this.ngZone.run(() => {
          if ( this.recipientIndexListItem !== undefined ){
            this.recipientIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItemBy_id(this.recipientIndexListItem._id)
            /**
             * now send an observable for recipient to change ! 
             */
            this.updateSharedRecipientListItem_fromRecipientFeature.next(this.recipientIndexListItem)
          }
        });
        this.cdr.detectChanges();
     
})

this.unblockLoginPopUpToPopAgainSubscription = this.logoutService.closeLogingDialogPopUp
    .subscribe(()=>{
      this.onTurnLoginPopUpNotVisible()
    })



};

isValidFormItem_id(inputString: string): boolean {
  if (inputString.startsWith("formItem-")) {
    const numericPart = inputString.slice(9); // Remove "formItem-" from the beginning
    const numericValue = parseInt(numericPart, 10); // Try to parse the numeric part

    return !isNaN(numericValue); // Check if it's a valid number
  }

  return false; // Doesn't start with "formItem-"
}

 /**
 * package online / offline property -------------------------------------------------
 */

  createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  };
  
  // -----------------------------------------------------------------------------------


  isMobile(){
    // credit to Timothy Huang for this regex test: 
    // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        return true
   }
   else{
        return false
   }
  } 

  onSetFirstRecipientIndex(){
    // ({recipientIndex , recipientIndexListItem })

    const recipientIndex: RecipientIndex = this.recipientIndexService.getRecipientIndexBy_UIID('recipientIndex-1')
    const recipientIndexListItem: RecipientIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItemBy_UIID('recipientIndexListItem-1')
    this.changeRecipientTorecipientList({ recipientIndex, recipientIndexListItem })

  }
  
  changeRecipientTorecipientList(dataOfRecipientIndexAndRecipientIndexListItem){

    this.isLoading = true
    console.log(dataOfRecipientIndexAndRecipientIndexListItem)

    this.recipientIndex = dataOfRecipientIndexAndRecipientIndexListItem.recipientIndex

    /**
     * send a subscription to formList to check and update if recipientIndex exist 
     */

    this.recipientIndex_exist = true 

    this.recipient = this.recipientService.getRecipientBy_id(this.recipientIndex.recipient_id)

    if ( this.recipientIndex.useMyOwnMailRecipient === false ){
      this.secondMailrecipient = this.recipientService.getRecipientBy_id(this.recipientIndex.mailRecipient_idCopy)
    }

    this.recipientIndexListItem = dataOfRecipientIndexAndRecipientIndexListItem.recipientIndexListItem 
    console.log(this.recipientIndexListItem)

    this.define_type_of_recipientIndexListItem()


    console.log(this.recipientIndex);

    this.updatedRecipientIndex.next(this.recipientIndex)
    this.defineMailAccountsText()
    this.defineWhatTypeOfMessageIsSending()

    this.selectedRecipient_position = 0

    //this.sendInfoAboutForm_FormFeatContainer_selRecipIndex_toFormList_afterChangedRecipIndex(this.form , this.recipientIndex._id )

    this.sendInfoAboutForm_FormFeatContainer_selRecipIndex_toFormList(this.form , this.recipientIndex._id )
    console.log('is LOADING')
    setTimeout(()=> {
    this.isLoading = false
    this.isDropped = true
    }, 300)
    console.log('timer gone')
};

/**
  * isMessageOnly: boolean 
  * isEmailOnly: boolean 
  * isEmailMessage: boolean 
  */
 /**
  * need to add properties like 
  * messageRecipientIsTemporaryHidden 
  * mailRecipientIsTemporaryHidden
  */
onEmitTemporaryHideRecipientsMessage(){
  console.log('HIDE MESSAGE')

  this.messageRecipientIsTemporaryHidden = true 
  this.defineWhatTypeOfMessageIsSending()
}

onEmitTemporaryHideRecipientsMail(){
  console.log('HIDE MAIL')
  this.mailRecipientIsTemporaryHidden = true 
  this.defineWhatTypeOfMessageIsSending()
}

onRestoreRecipientMessage(){
  this.messageRecipientIsTemporaryHidden = false 
  this.defineWhatTypeOfMessageIsSending()
}

onRestoreRecipientMail(){
  this.mailRecipientIsTemporaryHidden = false 
  this.defineWhatTypeOfMessageIsSending()
}

defineWhatTypeOfMessageIsSending(){

  //  for each of these possibilities add other 4 possibilities for the hiddens 
  /**
   * 4 more possibilities  X 2 because if we are using our own recipient or secondrecipient
   *  hidden message / hidden mail
   *  hidden message only 
   *  hidden mail only
   *  both not hidden 
   */

  /**
   * both not hidden 
   */
  if ( this.recipientIndex.useMyOwnMailRecipient === true ){

    if ( this.recipient.toMessage.length > 0 
      && this.recipient.toMail.length === 0 
      && this.messageRecipientIsTemporaryHidden === false 
      && this.mailRecipientIsTemporaryHidden === false )

 { this.isMessageOnly = true , this.isEmailOnly = false , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length > 0 
   && this.recipient.toMail.length === 0 
   && this.messageRecipientIsTemporaryHidden === true 
   && this.mailRecipientIsTemporaryHidden === false )
   
 { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length > 0 
   && this.recipient.toMail.length === 0 
   && this.messageRecipientIsTemporaryHidden === false 
   && this.mailRecipientIsTemporaryHidden === true )
   
 { this.isMessageOnly = true , this.isEmailOnly = false , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length > 0 
   && this.recipient.toMail.length === 0 
   && this.messageRecipientIsTemporaryHidden === true 
   && this.mailRecipientIsTemporaryHidden === true )
   
 { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = false  }
 
 /**
  * hidden message / hidden mail
  */
 if ( this.recipient.toMessage.length === 0 
      && this.recipient.toMail.length > 0 
      && this.messageRecipientIsTemporaryHidden === false  
      && this.mailRecipientIsTemporaryHidden === false 
      )
    { this.isMessageOnly = false , this.isEmailOnly = true , this.isEmailMessage = false  }
 
 if ( this.recipient.toMessage.length === 0 
   && this.recipient.toMail.length > 0 
   && this.messageRecipientIsTemporaryHidden === true 
   && this.mailRecipientIsTemporaryHidden === false 
   )
 { this.isMessageOnly = false , this.isEmailOnly = true , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length === 0 
   && this.recipient.toMail.length > 0 
   && this.messageRecipientIsTemporaryHidden === false  
   && this.mailRecipientIsTemporaryHidden === true
   )
 { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length === 0 
   && this.recipient.toMail.length > 0 
   && this.messageRecipientIsTemporaryHidden === true
   && this.mailRecipientIsTemporaryHidden === true
   )
 { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = false  }

 /**
  * hidden mail only
  */
 if ( this.recipient.toMessage.length > 0 
      && this.recipient.toMail.length > 0 
      && this.messageRecipientIsTemporaryHidden === false
      && this.mailRecipientIsTemporaryHidden === false 
      )
    { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = true  }
 
 if ( this.recipient.toMessage.length > 0 
   && this.recipient.toMail.length > 0 
   && this.messageRecipientIsTemporaryHidden === true 
   && this.mailRecipientIsTemporaryHidden === false 
   )
 { this.isMessageOnly = false , this.isEmailOnly = true , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length > 0 
   && this.recipient.toMail.length > 0 
   && this.messageRecipientIsTemporaryHidden === false  
   && this.mailRecipientIsTemporaryHidden === true
   )
 { this.isMessageOnly = true , this.isEmailOnly = false , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length > 0 
   && this.recipient.toMail.length > 0 
   && this.messageRecipientIsTemporaryHidden === true 
   && this.mailRecipientIsTemporaryHidden === true
   )
 { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = false  }
 
 /**
  * hidden message only 
  */
 if ( this.recipient.toMessage.length === 0 && this.recipient.toMail.length === 0 
      )
    { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = false  }
 

 // console.log( 'IS MESSAGE ONLY =', this.isMessageOnly , 'IS MAIL ONLY = ', this.isEmailOnly , 'IS BOTH MAIL+MESSAGE = ', this.isEmailMessage )

  }

  if ( this.recipientIndex.useMyOwnMailRecipient === false ){

    if ( this.recipient.toMessage.length > 0 
      && this.secondMailrecipient.toMail.length === 0 
      && this.messageRecipientIsTemporaryHidden === false 
      && this.mailRecipientIsTemporaryHidden === false )

 { this.isMessageOnly = true , this.isEmailOnly = false , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length > 0 
   && this.secondMailrecipient.toMail.length === 0 
   && this.messageRecipientIsTemporaryHidden === true 
   && this.mailRecipientIsTemporaryHidden === false )
   
 { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length > 0 
   && this.secondMailrecipient.toMail.length === 0 
   && this.messageRecipientIsTemporaryHidden === false 
   && this.mailRecipientIsTemporaryHidden === true )
   
 { this.isMessageOnly = true , this.isEmailOnly = false , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length > 0 
   && this.secondMailrecipient.toMail.length === 0 
   && this.messageRecipientIsTemporaryHidden === true 
   && this.mailRecipientIsTemporaryHidden === true )
   
 { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = false  }
 
 /**
  * hidden message / hidden mail
  */
 if ( this.recipient.toMessage.length === 0 
      && this.secondMailrecipient.toMail.length > 0 
      && this.messageRecipientIsTemporaryHidden === false  
      && this.mailRecipientIsTemporaryHidden === false 
      )
    { this.isMessageOnly = false , this.isEmailOnly = true , this.isEmailMessage = false  }
 
 if ( this.recipient.toMessage.length === 0 
   && this.secondMailrecipient.toMail.length > 0 
   && this.messageRecipientIsTemporaryHidden === true 
   && this.mailRecipientIsTemporaryHidden === false 
   )
 { this.isMessageOnly = false , this.isEmailOnly = true , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length === 0 
   && this.secondMailrecipient.toMail.length > 0 
   && this.messageRecipientIsTemporaryHidden === false  
   && this.mailRecipientIsTemporaryHidden === true
   )
 { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length === 0 
   && this.secondMailrecipient.toMail.length > 0 
   && this.messageRecipientIsTemporaryHidden === true
   && this.mailRecipientIsTemporaryHidden === true
   )
 { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = false  }

 /**
  * hidden mail only
  */
 if ( this.recipient.toMessage.length > 0 
      && this.secondMailrecipient.toMail.length > 0 
      && this.messageRecipientIsTemporaryHidden === false
      && this.mailRecipientIsTemporaryHidden === false 
      )
    { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = true  }
 
 if ( this.recipient.toMessage.length > 0 
   && this.secondMailrecipient.toMail.length > 0 
   && this.messageRecipientIsTemporaryHidden === true 
   && this.mailRecipientIsTemporaryHidden === false 
   )
 { this.isMessageOnly = false , this.isEmailOnly = true , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length > 0 
   && this.secondMailrecipient.toMail.length > 0 
   && this.messageRecipientIsTemporaryHidden === false  
   && this.mailRecipientIsTemporaryHidden === true
   )
 { this.isMessageOnly = true , this.isEmailOnly = false , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length > 0 
   && this.secondMailrecipient.toMail.length > 0 
   && this.messageRecipientIsTemporaryHidden === true 
   && this.mailRecipientIsTemporaryHidden === true
   )
 { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = false  }
 
 /**
  * hidden message only 
  */
 if ( this.recipient.toMessage.length === 0 && this.recipient.toMail.length === 0 
      )
    { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = false  }
 

 // console.log( 'IS MESSAGE ONLY =', this.isMessageOnly , 'IS MAIL ONLY = ', this.isEmailOnly , 'IS BOTH MAIL+MESSAGE = ', this.isEmailMessage )

  }



}



changeForm_id(params: Params){

  console.log("params = ", params )

  this.isLoadingForm = true

  this._id = params['id']

  console.log(this._id)
/**
 * START LOADING AND CHECK FOR EACH CASE WHEN TO STOP IT 
 */
  this.isLoading = true  

  this.checkIfFormExist(this._id)
 
};

checkIfFormExist( form_id: string ){

  /**
   * 
   * first understand if this form already exist in one of my formFeatureContainer 
   * if it exist check which topic type => then get http of the correct type of form 
   * 
   * check if form item + childs have been already stored from adviseHttp 
   * 
   * if it doesn't exist check if the form is public => yes download , no pop up dialog 
   * 
   */
  if ( this.user === null ){

    this.form = this.formService.getFormBy_id(form_id)
    console.log(this.form)

    if ( this.form === undefined ){ this.checkIfFormItemIsPublic(form_id)}
    if ( this.form !== undefined ){ this.checkIfFormExistOnFormFeatureContainers(form_id) }
   
  }
  if ( this.user !== null ){

        this.checkIfFormExistOnFormFeatureContainers(form_id)
  }

};


checkIfFormExistOnFormFeatureContainers(form_id: string){
  console.log('checkIfFormExistOnFormFeatureContainers')

  if ( this.formFeatureContainerService.formFeatureContainers.findIndex(x=> x.form_id === form_id) === -1 ){ this.checkIfFormItemIsPublic(form_id) , console.log('form doesn t exist on our own formFeatureContainers') }
  if ( this.formFeatureContainerService.formFeatureContainers.findIndex(x=> x.form_id === form_id) !== -1 ){ this.checkWhichFormTopicIndexTypeItBelongToAndSendFormListInfoAboutRecipinentIndex(form_id),  console.log('form exist')}

};

checkIfFormItemIsPublic(form_id: string){

  if ( this.isValidFormItem_id(form_id) ){ this.storeFormItemService.getAndStoreSharedFormItemByComponent_id(this.user, 'public', form_id )}
  // if it's invalid and we are logging in stop loading and move to the list 
  if ( !this.isValidFormItem_id(form_id) && this.user !== null ){ 
   this.router.navigate(['../form-list'], {relativeTo: this.route.parent});
   }
  
};


checkWhichFormTopicIndexTypeItBelongToAndSendFormListInfoAboutRecipinentIndex(form_id: string ){
console.log('check Which FormTopicIndex Type It  Belong To And Send FormListInfo About RecipinentIndex')
  const formFeatureContainers: FormFeatureContainer[] = []
  for ( let i=0; i<this.formFeatureContainerService.getFormFeatureContainers().length ; i++){
      if ( this.formFeatureContainerService.getFormFeatureContainer(i).form_id === form_id ){ formFeatureContainers.push(this.formFeatureContainerService.getFormFeatureContainer(i))}

      if ( i === this.formFeatureContainerService.getFormFeatureContainers().length-1 ){
           this.selectBestFormFeatureContainerType(formFeatureContainers, form_id)
      }
  }

};

selectBestFormFeatureContainerType(formFeatureContainers: FormFeatureContainer[], form_id: string ){
console.log('selectBestFormFeatureContainerType')

          if (formFeatureContainers.findIndex(x=> x.name === 'personal') !== -1) { 
              this.checkIfItsBeenStoredWithChildsAlready( 'personal', form_id)
            } else if (formFeatureContainers.findIndex(x=> x.name === 'share') !== -1 ){
                       this.checkIfItsBeenStoredWithChildsAlready( 'share' , form_id)
            } else if (formFeatureContainers.findIndex(x=> x.name === 'preference') !== -1 ){ 
                       this.checkIfItsBeenStoredWithChildsAlready( 'preference' , form_id)
            } else if (formFeatureContainers.findIndex(x=> x.name === 'public') !== -1 ){ 
                       this.checkIfItsBeenStoredWithChildsAlready( 'public' , form_id)
            }

};


checkIfItsBeenStoredWithChildsAlready( typeOfIndex : string , form_id: string){

  console.log( "it's checking if forms with childs has been stored ")

  console.log(this.adviseIfHttpStoreComponentsCallHasBeenMadeService.formItemPlusChildsHasBeenStoredList)

  const indexOfStoredFormItem = this.adviseIfHttpStoreComponentsCallHasBeenMadeService.formItemPlusChildsHasBeenStoredList.findIndex(x=> x.form_id === form_id )
  console.log(this.user)

  if ( this.user === null ){
    this.form = this.formService.getFormBy_id(this._id)
    if ( typeOfIndex === 'public'){ this.storeFormItemService.getAndStoreSharedFormItemByComponent_id(this.user , typeOfIndex, form_id )}
    if ( typeOfIndex !== 'public'){ this.recomposeForm( this.form , typeOfIndex ) }

  }
  if ( this.user !== null ){
  
      console.log('going for this type of index => ', typeOfIndex)
        
        if ( typeOfIndex === 'personal'){ this.storeFormItemService.getAndStorePersonalFormItemPlusChildsByComponent_id(this.user, form_id, typeOfIndex)}
        if ( typeOfIndex !== 'personal'){ this.storeFormItemService.getAndStoreSharedFormItemByComponent_id(this.user , typeOfIndex, form_id )}
       
    // }

  }

};

doubleCheckIfFormItemWasPreviouslyStored_afterItTriedToDownloadOnce(typeOfIndex : string , form_id: string){
  if ( this.user !== null ){
    // let formWithChildsHasBeenStored: boolean 

       const indexOfStoredFormItem = this.adviseIfHttpStoreComponentsCallHasBeenMadeService.formItemPlusChildsHasBeenStoredList.findIndex(x=> x.form_id === form_id )
       if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.formItemPlusChildsHasBeenStoredList[indexOfStoredFormItem].hasBeenStored === true  ){ 
    //  formWithChildsHasBeenStored = true
    //  console.log('formWithChildsHasBeenStored => ', formWithChildsHasBeenStored)
     // continue to recompose form 
         this.form = this.formService.getFormBy_id(this._id);
         console.log(this.form)
         this.recomposeForm(this.form , typeOfIndex)
        }

       if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.formItemPlusChildsHasBeenStoredList[indexOfStoredFormItem].hasBeenStored === false ){

          this.onFormNotAvailableOrIndexistent()
          this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'the form is currenlty not available'})
      }


// if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.formItemPlusChildsHasBeenStoredList[indexOfStoredFormItem].hasBeenStored === false ){ 
//    formWithChildsHasBeenStored = false 
//    console.log('formWithChildsHasBeenStored => ', formWithChildsHasBeenStored)
//     console.log('going for this type of index => ', typeOfIndex)
      
//       if ( typeOfIndex === 'personal'){ this.storeFormItemService.getAndStorePersonalFormItemPlusChildsByComponent_id(this.user, form_id, typeOfIndex)}
//       if ( typeOfIndex !== 'personal'){ this.storeFormItemService.getAndStoreSharedFormItemByComponent_id(this.user , typeOfIndex, form_id )}
//   }

}
}



recomposeForm(form: Form , typeOfIndex: string ){

  this.form = form
  console.log(this.form , form)
  this.loginPopUpIsVisible = false 

  this.setExactRouting()

  this.finalSubject = this.form.formSubject
  this.mailTextSubject = encodeURIComponent(this.finalSubject)
  this.titleService.setTitle(this.finalSubject)
  this.aFormExist = true

  // need to check if the form is shared to usr or if it's public or it's our own 
  const form_id: string = this.form._id
  const currentTypeOfIndex: string = typeOfIndex

if (  this.form.owner === this.user ){
  /**
   *  check if recipientIndex belong to user and if still exist 
   *  if belong to other and maybe doesn't exist anymore ( case of we are out of a chat )
   *  we need to make it as select 
   */
  this.recipientIndex = this.recipientIndexService.getRecipientIndexBy_id(this.form.recipientIndex_id)

  if ( this.recipientIndex !== undefined ){

    if ( this.recipientIndex.useMyOwnMailRecipient ){
      console.log(this.recipientIndex)
      this.returnActualRecipientIndexListItemInUse(this.recipientIndex) // check what recipientIndexListItem we have 
      this.recipientIndex_exist = true  
      this.defineRecipientIndex(this.form)
    }
    if ( !this.recipientIndex.useMyOwnMailRecipient  ){
          const recipient_id = this.recipientIndex.recipient_id 
          if ( this.recipientFeatureService.getRecipientFeature().receivedRecipient_ids.includes(recipient_id) ){
               this.returnActualRecipientIndexListItemInUse(this.recipientIndex) // check what recipientIndexListItem we have 
               this.recipientIndex_exist = true  
               this.defineRecipientIndex(this.form)
          }

          if ( !this.recipientFeatureService.getRecipientFeature().receivedRecipient_ids.includes(recipient_id) ){
            // replace this formItem recipientIndex with first sure existing recipientIndex + sure existing recipient
               const newRecipientIndex_index = this.recipientIndexService.getRecipientIndexes().findIndex(x=> x.useMyOwnMailRecipient === true )
               const newRecipientIndex: RecipientIndex = this.recipientIndexService.getRecipientIndex(newRecipientIndex_index)

               this.form.recipientIndex_id = newRecipientIndex._id
               this.form.noNeedRecipientAndSubject = true 
               this.returnActualRecipientIndexListItemInUse(this.recipientIndex) // check what recipientIndexListItem we have 
               this.recipientIndex_exist = true  
               this.defineRecipientIndex(this.form)

          }

    }


  }

  if ( this.recipientIndex === undefined ){
    this.recipientIndex_exist = false 
    this.defineRecipientIndex(this.form)
  }
 
}

 
 /**
  * we need to find out if this form has been saved with a user recipientIndex which is stored into 
  * formFeatureContainer
  * 1- find which formFeature
  * 2- find which formFeatureContainer 
  * 3-check if formFeatureContainer recipientIndex_id !== null  
  * 4- if recipientIndex_id !== null => apply new recipientIndex to this form 
  * 
  * at the end we just need to RECONFIRM 
  * 1- recipientIndexFeature has to be rebuilt 
  * 
  */
 if ( this.form.owner !== this.user){
                         this.recipientIndex_exist = false 
                         this.defineRecipientIndex(this.form)


}

}


/**
 * 
 * @param form define if there is a recipient index 
 *  2- define if recipient is still available ( in the case of shared )
 */
defineRecipientIndex( form: Form ){

  if ( this.recipientIndex_exist === true ){ 

    /**
      * need to add the logic on if the recipient owner and change the service accordingly 
      */

    if ( this.recipientService.recipients.findIndex(x=> x._id === this.recipientIndex.recipient_id) !== -1 ){

    this.recipient = this.recipientService.getRecipientBy_id(this.recipientIndex.recipient_id)
   
    /**
     * if it's NOT OUR RECIPIENT , put recipient.message all the recipients involved 
     * and put mail as second recipient 
     */
    if ( this.recipientIndex.useMyOwnMailRecipient === false ){
      
      this.recipient.toMessage = this.recipient.recipientList // inut the recipientGroup in the message if user isn't ours 
      this.secondMailrecipient = this.recipientService.getRecipientBy_id(this.recipientIndex.mailRecipient_idCopy)
    }
    console.log(this.recipient)

     this.defineMailAccountsText()
     this.defineWhatTypeOfMessageIsSending()
     console.log('this recipientIndex = ', this.recipientIndex )
    }

    if ( this.recipientService.recipients.findIndex(x=> x._id === this.recipientIndex.recipient_id) == -1 ){

      // find the first recipientIndex with useMyOwnmail = true 
      const newrecipientIndex_index = this.recipientIndexService.getRecipientIndexes().findIndex(x=> x.useMyOwnMailRecipient === true )
      const newrecipientIndex = this.recipientIndexService.getRecipientIndex(newrecipientIndex_index)
      console.log('new recipient index to use => ', newrecipientIndex )

      this.recipientIndex = newrecipientIndex 
      this.form.recipientIndex_id = this.recipientIndex._id 
      console.log('new recipinet index => ', this.recipientIndex, 'new form => ', this.form  )
      this.recipient = this.recipientService.getRecipientBy_id(this.recipientIndex.recipient_id)

      this.returnActualRecipientIndexListItemInUse(this.recipientIndex)

      // this.defineMailAccountsText()
      // this.defineWhatTypeOfMessageIsSending()
      // console.log('this recipientIndex = ', this.recipientIndex )

       this.form.noNeedRecipientAndSubject = true 

       // send a warning to say the group is temporary unavailable 
       // edit the form to reset the recipients 
       this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'the group is temporary unavailable, edit the form to reset the recipients '})



      
    }


  }

  if ( this.recipientIndex_exist === false ){
    if ( this.formFeatureContainerService.getAllSharedPlusPreferenceFormFeatureContainers().findIndex(x=> x.form_id === this.form._id ) !== -1 ){ 
         
        const actualFormFeatureContainer: FormFeatureContainer = this.formFeatureContainerService.getAllSharedPlusPreferenceFormFeatureContainers().find(x=>x.form_id === this.form._id)

        if ( actualFormFeatureContainer.recipientIndex_id === null ){
          this.aRecipientIndex_Substitute_isActive = false 
          this.recipientIndex = undefined 
        }

        if ( actualFormFeatureContainer.recipientIndex_id !== null ){

         this.aRecipientIndex_Substitute_isActive = true

         this.recipientIndex = this.recipientIndexService.getRecipientIndexBy_id(this.formFeatureContainerService.getAllSharedPlusPreferenceFormFeatureContainers().find(x=> x.form_id === this.form._id).recipientIndex_id)

         this.returnActualRecipientIndexListItemInUse(this.recipientIndex)
         this.recipient = this.recipientService.getRecipientBy_id(this.recipientIndex.recipient_id)
         console.log(this.recipient)
   
         if ( this.recipientIndex.useMyOwnMailRecipient === false ){
          this.secondMailrecipient = this.recipientService.getRecipientBy_id(this.recipientIndex.mailRecipient_idCopy)
        }

          this.defineMailAccountsText()
          this.defineWhatTypeOfMessageIsSending()
         
        }

        
    }

    if ( this.formFeatureContainerService.getAllSharedPlusPreferenceFormFeatureContainers().findIndex(x=> x.form_id === this.form._id ) === -1 ){ 
         this.aRecipientIndex_Substitute_isActive = false 
         this.recipientIndex = undefined 

    }
  }

  console.log('this recipientIndex = ', this.recipientIndex)
  // this.paragraphFeaturesExist = false 
  this.defineParagraphFeatures(form)

}


onEmitUpdatedRecipientIndexListItem_afterDeleteToMessageEmail($event){
  console.log( 'DELETE THIS RECIPIENT AND RECIPINDEX, GET PREVIOUS RECIPIENT OR MY OWN => ', $event)

  // need to update the 
  const updatedRecipientIndexListItem: RecipientIndexListItem = $event 

   // NEED TO UPDATE THE RECIPIENTINDEXLISTITEM 
   // NEED TO FIND OUR OWN FIRST RECIPIENTINDEX AND INDEXLIST ITEM AND REPLACE THIS FORM WITH THIS RECIPIENTINDEX 
   // NEED TO SAY THE FORM IS NO NEED RECIPIENT ABD SUBJECT 
  if ( this.recipientIndexColumnService.getRecipientIndexColumnBy_type('shared').recipientIndexListItem_ids.findIndex(x=> x === updatedRecipientIndexListItem._id ) !== -1 ){

       this.ngZone.run(()=>{

      
       const indexOfRecipinetIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItems().findIndex(x=> x._id === updatedRecipientIndexListItem._id )
       this.recipientIndexListItemService.recipientIndexListItems[indexOfRecipinetIndexListItem] = updatedRecipientIndexListItem

       const personalRecipientIndexColumn = this.recipientIndexColumnService.getRecipientIndexColumnBy_type('personal')
       const personalRecipientIndexListItem_id = personalRecipientIndexColumn.recipientIndexListItem_ids[0]
       const personalrecipientIndex_id = this.recipientIndexListItemService.getRecipientIndexListItemBy_id(personalRecipientIndexListItem_id).recipientIndexSelected_id

       this.form.noNeedRecipientAndSubject = true 
       this.form.recipientIndex_id = personalrecipientIndex_id
       this.recipientIndex = this.recipientIndexService.getRecipientIndexBy_id(personalrecipientIndex_id)
       this.recipient = this.recipientService.getRecipientBy_id(this.recipientIndex.recipient_id)

      })

      this.cdr.detectChanges()

  } 


  if ( this.recipientIndexColumnService.getRecipientIndexColumnBy_type('personal').recipientIndexListItem_ids.findIndex(x=> x === updatedRecipientIndexListItem._id ) !== -1 ){
    console.log( 'SHOULD NOT DO ANYTHING')
  } 
  
    
   this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: "YOU EXITED THE GROUP , OR DOESN'T EXIST ANYMORE,  SET UP THE FORM TO SEND"})
}



defineParagraphFeatures(form: Form){

  this.paragraphFeatures = []
  let counter: number = 0
  for ( let i=0; i<form.paragraph_ids.length; i++){
  
    const newParagraph = this.paragraphService.getParagraphBy_id(this.form.paragraph_ids[i])
    const newParagraphFeature_id = i
    const newOwner = newParagraph.owner;
    const newDateAndTime = null  
    const newIsAccepted = true ;
    const newAllUsersAllowed = false 
    const newUserListAllowed = []
    const newIsBeenShared = false 
    const newCanBeReshared = newParagraph.canBeResharedByOthers
    const newType =  'paragraphFeature'
    const newCounterId = i ;
    const newParagraphFeatureDropId = i ;
    const newParagraph_id = this.form.paragraph_ids[i];
    const newParagraphFeature2 = new ParagraphFeature ( newParagraphFeature_id, newOwner, newDateAndTime, 
                                                       newIsAccepted, newAllUsersAllowed, newUserListAllowed, 
                                                       newIsBeenShared , newCanBeReshared, newType,  newCounterId, 
                                                       newParagraphFeatureDropId , newParagraph_id )
   
    this.paragraphFeatures.push(newParagraphFeature2)
    console.log(this.paragraphFeatures)

    counter = counter+1

    if ( counter === form.paragraph_ids.length ){

         this.endFormItemWithResults()
    }
   }

  

};

endFormItemWithResults(){
 
   /** return with single group of recipients */
 this.isDropped = false;
 /**empty the drop space  */
 this.recipientIndexes = [];
 this.droppedRecipientIndex = true ;

 this.formService.onChangedForm_id(this.form._id)
//  this.formService.onStoppingLoadingSpinnerAtFormList()

  /**
 *  RECREATE ! and re-compile all FEATURE RESULTS!  create initial dummy paragraphResults --------------------------------------------------
 */

  

   this.paragraphResults = []
   const newParagraphResultName = 'prova'
   const newParagraphResult = new ParagraphResult (  newParagraphResultName, [] ) ;
  
   const newParagraphResults: ParagraphResult[] = []
   const newMailTextBodyPart = ''
   this.mailTextBody = []
   for ( let z=0; z<this.paragraphFeatures.length; z++){
     newParagraphResults.push(newParagraphResult)
     this.mailTextBody.push(newMailTextBodyPart)
   }
   this.paragraphResults = newParagraphResults
   console.log(this.paragraphResults)
  
   this.mailTextSubject = encodeURIComponent(this.finalSubject)
   this.finalMailTextBody = null 
  
    setTimeout(()=>{   // this gives time to some input subscription to unsubscribe their value 
      this.isLoadingForm = false 
      this.isLoading = false
    },200)
    
    console.log('is LOADING')
    // giv somtimemes for isLoadingForm to be out and have time to load the rest of components 

    let recipientIndex_id: string 
    if ( this.recipientIndex === undefined ){ recipientIndex_id === undefined }
    if ( this.recipientIndex !== undefined ){ recipientIndex_id === this.recipientIndex._id }

    this.sendInfoAboutForm_FormFeatContainer_selRecipIndex_toFormList( this.form , recipientIndex_id )

    // setTimeout(() => {
    //   this.paragraphFeaturesExist = true 
    // }, 200);

  
  /**
  *  END RECREATE ! create initial dummy paragraphResults --------------------------------------------------
  */
}

setExactRouting(){

  console.log( 'EXACT ROUT')

  if ( this.form.owner !== this.user ){
     
    if ( this.formFeatureContainerService.getAllSharedPlusPreferenceFormFeatureContainers().findIndex(x=> x.form_id === this.form._id) !== -1 ){
      if ( this.formFeatureContainerService.getFormFeatureContainersBy_type('share').findIndex(x=> x.form_id === this.form._id ) !== -1 ){
           this.router.navigate(['/form-list/shared-form', this.form._id ], {relativeTo: this.route.parent })
      } 
      if ( this.formFeatureContainerService.getFormFeatureContainersBy_type('preference').findIndex(x=> x.form_id === this.form._id ) !== -1 ){
           this.router.navigate(['/form-list/preferred-form', this.form._id ], {relativeTo: this.route.parent })
      } 
   }
    if ( this.formFeatureContainerService.getFormFeatureContainersBy_type('public').findIndex(x=> x.form_id === this.form._id) !== -1 ){
      this.router.navigate(['/form-list/public-form', this.form._id ], {relativeTo: this.route.parent })
    }

    if ( this.formFeatureContainerService.getFormFeatureContainers().findIndex(x=> x.form_id === this.form._id) === -1 ){
      this.router.navigate(['/form-list/public-form', this.form._id ], {relativeTo: this.route.parent })
    }

  }

  if ( this.form.owner === this.user ){
    this.router.navigate(['/form-list/personal-form', this.form._id ], {relativeTo: this.route.parent })
  }
 

}


sendInfoAboutForm_FormFeatContainer_selRecipIndex_toFormList( currentForm: Form, selectedRecipientIndex_id: string ){

  let newForm: Form = currentForm 
  let selectedFormFeatureContainer: FormFeatureContainer

  if ( this.form.owner === this.user ){ 
       selectedFormFeatureContainer = this.formFeatureContainerService.getFormFeatureContainersBy_type('personal').find( x=> x.form_id === this.form._id ) 
   
  }

  if ( this.form.owner !== this.user ){ 

    if ( this.formFeatureContainerService.getAllSharedPlusPreferenceFormFeatureContainers().findIndex(x=> x.form_id === this.form._id) !== -1 ){
            
            selectedFormFeatureContainer = this.formFeatureContainerService.getAllSharedPlusPreferenceFormFeatureContainers().find( x=> x.form_id === this.form._id ) 
            console.log('FORMFEATURECONTAINER SELECTED = ',selectedFormFeatureContainer)
    }

    if ( this.formFeatureContainerService.getAllSharedPlusPreferenceFormFeatureContainers().findIndex(x=> x.form_id === this.form._id) === -1 ){

            selectedFormFeatureContainer = undefined 

    }

    if ( this.formFeatureContainerService.getFormFeatureContainers().findIndex(x=> x.form_id === this.form._id) === -1 ){

      selectedFormFeatureContainer = undefined 

}

  }

  this.formService.sendForm_featFormContainer_selRecipIndex_id( { newForm , selectedFormFeatureContainer, selectedRecipientIndex_id })

}


onFormNotAvailableOrIndexistent(){

  /**
  * these will be put if form can't be displayed or is NON Existent 
  */
   this.aFormExist = false 
  
   this.router.navigate(['../form-list'], {relativeTo: this.route.parent});
  //  this.dms.PopUpErrorDialog({ textContent: "the form can't be displayed or is not existant, maybe login to see if it's been shared with you"})
   return 

}

onTurnLoginPopUpNotVisible(){
  this.loginPopUpIsVisible = false 
}

askToLoginOnlyOnceIn3sec: boolean = false 
loginPopUpIsVisible: boolean = false 
onAskingUserToLoginAgainOrBackTobasic(component_idAndTypeOfIndex: any ){

  /**
   * at this point user is null we need to check if user wants to login 
   * and check again on form or quit search
   * 
   * might want to add a "counter" as protection to ask only once or repeat this ony once 
   */
  console.log('ASK TO LOGIN AGAIN OR STOP ' , component_idAndTypeOfIndex)

  if ( !this.askToLoginOnlyOnceIn3sec && !this.loginPopUpIsVisible ){
        this.askToLoginOnlyOnceIn3sec = true 
        this.loginPopUpIsVisible = true 
    this.errorsDialogManagerService.LoginPopUpDialog()

    setTimeout(() => {
      this.askToLoginOnlyOnceIn3sec = false 
    }, 3000);
  }

  

}

onUpdateSubjectTextResult($event){
  console.log($event)
  this.finalSubject = $event
  console.log(this.finalSubject)
  
  this.mailTextSubject = encodeURIComponent(this.finalSubject)
  console.log(this.mailTextSubject)
  
};


returnActualRecipientIndexListItemInUse(recipientIndex: RecipientIndex){

//  return this.recipientIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItems().find(obj=> obj.recipientIndexList_ids.includes(recipientIndex._id))
  for ( let i=0 ; i<this.recipientIndexListItemService.getRecipientIndexListItems().length ; i++){
    for ( let y=0; y< this.recipientIndexListItemService.getRecipientIndexListItem(i).recipientIndexList_ids.length ; y++ ){
      if ( recipientIndex._id === this.recipientIndexListItemService.getRecipientIndexListItem(i).recipientIndexList_ids[y]){
        this.recipientIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItem(i)
        return
      }
    }
  }
}

onStartLoadingOtherRecipient1(){
  this.startLoadRecipientsNumber2.next()
} 

onStartLoadingOtherRecipient2(){
  this.startLoadRecipientsNumber1.next()
} 



/**
 * 
 * @param event 
 */
 onChangeIndex(event){

 this.isLoading = true 


  this.isDropped = true
  this.recipientIndex = event 
  console.log(this.recipientIndex);

  this.recipientGroupName = this.recipientIndexListItem.recipientIndexListName;

  /**
   * need to add the logic on if the recipient owner and change the service accordingly 
   */
  this.recipient = this.recipientService.getRecipientBy_id(this.recipientIndex.recipient_id)
  console.log(this.recipient)
  
  if ( this.recipientIndex.useMyOwnMailRecipient === false ){
    this.secondMailrecipient = this.recipientService.getRecipientBy_id(this.recipientIndex.mailRecipient_idCopy)
  }

  this.defineMailAccountsText()

   // update the recipientIndexListItem with the newly created in case we just created a new recipient otherwise is just refresh
   this.recipientIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItemBy_id(this.recipientIndexListItem._id)
   console.log(this.recipientIndexListItem)
   this.define_type_of_recipientIndexListItem()
   /**
    * find selectedRecipient position 
    */
   for ( let i=0; i< this.recipientIndexListItem.recipientIndexList_ids.length; i++){
     if ( this.recipientIndexListItem.recipientIndexList_ids[i] === this.recipientIndex._id ){this.selectedRecipient_position = i }
   }
   console.log(this.selectedRecipient_position)

   this.messageRecipientIsTemporaryHidden = false 
   if ( this.user === null ){ this.messageRecipientIsTemporaryHidden = true }
   this.mailRecipientIsTemporaryHidden = false 

   this.defineWhatTypeOfMessageIsSending()

  this.sendInfoAboutForm_FormFeatContainer_selRecipIndex_toFormList(this.form , this.recipientIndex._id )

 setTimeout(()=>{
  this.isLoading = false
},200)



}

define_type_of_recipientIndexListItem(){
  /**
   * check what kind of column the recipientIndexListItem is on , to understand what kind of sorting 
   * we have to use 
   */
  const sharedRecipientIndexColumn: RecipientIndexColumn = this.recipientIndexColumnService.getRecipientIndexColumnBy_type('shared')

  if ( sharedRecipientIndexColumn.recipientIndexListItem_ids.findIndex(x=> x === this.recipientIndexListItem._id) === -1 ){ this.isPersonalRecipientIndexListItem = true }
  if ( sharedRecipientIndexColumn.recipientIndexListItem_ids.findIndex(x=> x === this.recipientIndexListItem._id) !== -1 ){ this.isPersonalRecipientIndexListItem = false }

this.sort_recipients_according_to_typeOfColumn()
}

sort_recipients_according_to_typeOfColumn(){

  if ( this.isPersonalRecipientIndexListItem === true ){
    /**
    *  sort the recipientIndexes array in alphabeticNumeric order 
    */
     this.recipientIndexListItem.recipientIndexList_ids.sort(function(a,b) {
      return a.localeCompare(b, 'en', { numeric: true })
    })
  }

  if ( this.isPersonalRecipientIndexListItem === false ){
    /**
    *  sort the recipientIndexes array in latest to previous dateTime 
    * 
    * probably need to have a common property time with CHAT 
    */
    //  this.recipientIndexListItem.recipientIndexList_ids.sort(function(a,b) {
    //   return a.localeCompare(b, 'en', { numeric: true })
    // })
  }
}


onSendFormAndStayInFormItem(){

  if ( this.user !== null ){ 
    if ( this.isMessageOnly || this.isEmailMessage  ){ this.onCheckIfRecipientIsStillValid() } // if send by email only no need to check 
    if ( this.isEmailOnly ){ this.onSendFormAndStayInFormItem_real() } // if send by message also , need to double check on users 
}

if ( this.user === null ){ this.onSendFormAndStayInFormItem_real()
}
}

onCheckIfRecipientIsStillValid(){
  /**
   * if it's not my own recipient i check on recipientFeature recipients 
   */
  if ( this.recipientIndex.useMyOwnMailRecipient ){ this.onCheckingMyOwnUpdatedRecipient()}
  /**
   * if it's my own recipient i double check on my my own recipient
   */
  if ( !this.recipientIndex.useMyOwnMailRecipient){ this.onCheckingSharedRecipient_onRecipientFeatureChilds() }
 }

  /**
  * recall my own recipient to update the recipient message list , in case someone ecxcluded 
  * himself from the list 
  */
  onCheckingMyOwnUpdatedRecipient(){

    this.getComponentsHttpRequestService.httpGetUserComponentBy_id(this.user , this.recipient._id)
        .subscribe((res: Recipient )=>
        {
          console.log(res)
          this.recipient = res 
          this.recipientService.updateRecipient(this.recipient)
  
  
          this.onSendFormAndStayInFormItem_real()
        }, error => {
          console.log( error , 'throw error')
          this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'AN ERROR OCCURRED , CHECK YOUR CONNECTION OR TRY LATER'})
        }
        )
  
   }


 
   onCheckingSharedRecipient_onRecipientFeatureChilds(){

    const newRecipientFeature = this.recipientFeatureService.getRecipientFeature()
    console.log(newRecipientFeature)
  
    this.getComponentsHttpRequestService.httpGetUserComponentWithChildsBy_id(newRecipientFeature._id)
        .subscribe((recipientFeaturePlusChilds: any)=>{
         console.log(recipientFeaturePlusChilds)
  
         const sharedRecipients: Recipient[] = recipientFeaturePlusChilds.childList 
         
         
         if ( sharedRecipients.findIndex(x=> x._id === this.recipient._id ) !== -1 ){
         const index = sharedRecipients.findIndex(x=> x._id === this.recipient._id )
  
         /**
          * check if this recipient recipientList still has my email inside 
          */
  
         /**
          * if my email is still inside then
          */
         if ( sharedRecipients[index].recipientList.findIndex(x=> x === this.user) !== -1 ){
              this.recipient = sharedRecipients[index]
              this.recipient.toMessage = sharedRecipients[index].recipientList
              this.recipientService.updateRecipient(this.recipient)
   
              this.onSendFormAndStayInFormItem_real()
         }
  
          /**
          * if my email is NOT inside then
          * we should eliminate this recipient from the service 
          * we should update the list of shared recipientIndexes on coulums and here too 
          * restart this create form page with our user and restart recipient index column 
          */
          if ( sharedRecipients[index].recipientList.findIndex(x=> x === this.user) === -1 ){
            
            // eliminate recipient from service 
            const indexOfrecipientToDeleteFromService = this.recipientService.getRecipients().findIndex(x=> x._id ===this.recipient._id )
            this.recipientService.recipients.splice(indexOfrecipientToDeleteFromService, 1 )
            this.recipientService.updateInitialRecipients(this.recipientService.recipients)

             this.form.noNeedRecipientAndSubject = true 


             const  recipientIndexListItem = this.updateRecipientIndexListItem()
             console.log( 'THIS GROUP IS NO LONGER ACTIVE, formItem line 1618')
             this.onEmitUpdatedRecipientIndexListItem_afterDeleteToMessageEmail(recipientIndexListItem)

            //  this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'THIS GROUP IS NO LONGER ACTIVE, edit the form to reset the recipients '})
             
             this.router.navigate(['../form-list/personal-form',this.form._id], {relativeTo: this.route.parent})
  
  
           }
  
        }

        if ( sharedRecipients.findIndex(x=> x._id === this.recipient._id ) === -1 ){
  
            const indexOfrecipientToDeleteFromService = this.recipientService.getRecipients().findIndex(x=> x._id ===this.recipient._id )
            this.recipientService.recipients.splice(indexOfrecipientToDeleteFromService, 1 )
            this.recipientService.updateInitialRecipients(this.recipientService.recipients)

          this.form.noNeedRecipientAndSubject = true 

          const  recipientIndexListItem = this.updateRecipientIndexListItem()
          console.log( 'THIS GROUP IS NO LONGER ACTIVE, formEdit line 1618 ')

          
          this.onEmitUpdatedRecipientIndexListItem_afterDeleteToMessageEmail(recipientIndexListItem)
          // this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'THIS GROUP IS NO LONGER ACTIVE, line 1561 formITEM.ts still need to eliminate user'})
          
            this.router.navigate(['../form-list'], {relativeTo: this.route.parent})
  
        }
  
      }, error => {
        console.log(error , 'throw error')
  
        this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'AN ERROR OCCURRED , CHECK YOUR CONNECTION OR TRY LATER'})

        this.router.navigate(['../form-list'], {relativeTo: this.route.parent})
  
      })
  
   }

   updateRecipientIndexListItem(): RecipientIndexListItem{

    const indexOfRecipientIndexContainingRecipient = this.recipientIndexService.getRecipientIndexes().findIndex(x=> x.recipient_id === this.recipient._id)
          const recipientIndex_id = this.recipientIndexService.getRecipientIndex(indexOfRecipientIndexContainingRecipient)._id
          const index = this.recipientIndexColumnService.getRecipientIndexColumnBy_type('shared').recipientIndexListItem_ids[0]
          const recipientIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItemBy_id(index)
          const indexOfRecipientIndexInSharedRecipientIndexListItem = recipientIndexListItem.recipientIndexList_ids.findIndex(x=> x === recipientIndex_id)
  
          recipientIndexListItem.recipientIndexList_ids.splice( indexOfRecipientIndexInSharedRecipientIndexListItem, 1 )
          
          if( recipientIndexListItem.recipientIndexSelected_id === recipientIndex_id ){
            if ( recipientIndexListItem.recipientIndexList_ids.length > 0 ){
                 recipientIndexListItem.recipientIndexSelected_id = recipientIndexListItem.recipientIndexList_ids[0]
            }
            if ( recipientIndexListItem.recipientIndexList_ids.length === 0 ){
                // here we need to put selected at null and advise recipientIndexColumn to close 
                recipientIndexListItem.recipientIndexSelected_id = null 
  
            }
  
          }
  
    return recipientIndexListItem
   }

/**
 * send form and stay in the formItem page so that it can be re-used right away
 */
 onSendFormAndStayInFormItem_real(){ 

  if (this.recipientIndex.useMyOwnMailRecipient === true ){
    this.secondMailrecipient = this.recipient
   } // this is just tokeep the function going when the second recipient is not present 

  // this.timeAtSendForm = DateTime.now().toString();
  this.timeAtSendForm = this.datePipe.transform( new Date(), 'yyyy-MM-dd HH:mm:ss' )

   console.log('recipient index exist => ', this.recipientIndex_exist , 'a recipientIndex Substitute is active =>', this.aRecipientIndex_Substitute_isActive)
  if ( this.recipientIndex_exist || this.aRecipientIndex_Substitute_isActive ){

    const new_recipient_id = null 
    const newComponentName = 'recipient'
    const newUIID = null 
    const newChat_roomid = this.recipient.chatRoomId
    const newOwner = this.recipient.owner
    const newDateAndTime = this.timeAtSendForm ;
    const newUpdateDateAndTime = null 
    const newAllUsersAllowed = this.recipient.allUsersAllowed
    const newUserListAllowed = this.recipient.userListAllowed
    const newBlockedUserList = this.recipient.blockedUserList
    const newName = this.recipient.name
    const newRecipientList = this.recipient.recipientList
    const newToMessage = this.recipient.toMessage
    const newToMail = [];
    const newCcmail = [];
    const newBccMail = [];
    const newGreeting = '' ;
    
    const newRecipientResultForMessage = new Recipient ( new_recipient_id, newComponentName, newUIID, newOwner, newDateAndTime , newUpdateDateAndTime, newChat_roomid,
                                         newAllUsersAllowed, newUserListAllowed, newBlockedUserList,
                                         newName, newRecipientList , newToMessage, 
                                         newToMail, newCcmail, newBccMail , newGreeting);
    const newRecipientList2 = []
    const newToMessage2 = [];
    const newChat_roomid2 = ""
    const newToMail2 = this.recipient.toMail;
    const newCcmail2 = this.recipient.ccMail
    const newBccMail2 = this.recipient.bccMail
    const newGreeting2 = this.recipient.greeting

    const newOwner3 = this.user 
    const newAllUsersAllowed3 = this.secondMailrecipient.allUsersAllowed
    const newUserListAllowed3 = this.secondMailrecipient.userListAllowed
    const newBlockedUserList3 = this.secondMailrecipient.blockedUserList
    const newRecipientList3 = []
    const newToMessage3 = [];
    const newChat_roomid3 = ""
    const newToMail3 = this.secondMailrecipient.toMail;
    const newCcmail3 = this.secondMailrecipient.ccMail
    const newBccMail3 = this.secondMailrecipient.bccMail
    const newGreeting3 = this.secondMailrecipient.greeting

    const newRecipientResultForMail = new Recipient ( new_recipient_id, newComponentName, newUIID, newOwner, newDateAndTime , newUpdateDateAndTime, newChat_roomid2,
      newAllUsersAllowed, newUserListAllowed, newBlockedUserList,
      newName, newRecipientList2 , newToMessage2, 
      newToMail2, newCcmail2, newBccMail2 , newGreeting2 );
    /**
     * to use if it's shared recipientIndex
     */
    const newRecipientResultForMailWhenShared = new Recipient ( new_recipient_id, newComponentName, newUIID, newOwner3, newDateAndTime , newUpdateDateAndTime, newChat_roomid3,
      newAllUsersAllowed3, newUserListAllowed3, newBlockedUserList3,
      newName, newRecipientList3 , newToMessage3, 
      newToMail3, newCcmail3, newBccMail3 , newGreeting3 );


    

       this.isSendingMessageEmail = true 
  
       console.log(this.mailTextFinalComposition)
       /**
        * public subject: string ,
        public isFixSubject: boolean , 
        public paragraphResults: ParagraphResult[] ,
        public seq: number , 
        public updateDateAndTime: string , 
        public hasFollowingParagraphItem_idToShow : string 
        */

       const new_id = (this.formResultsService.getFormResults().length+1).toString() ;
       const new_id2 = (this.formResultsService.getFormResults().length+2).toString() ;
       const newIsActive = true ; 
       const newIsDeleted = false ;
       const newIsSelected = false ;
       const newformReadFlag = true ;
       const newIsShareble = true 
       const newIsACopyOfAMessage = false 
       const newIsForwardToMail = this.isForwardToMail ;
       const newIsForwarded = false ;
       const newSender = new Sender (this.user) ;
       const newSenderGroup = '' ;
       const newSenderType = '' ;
       const newReceiverGroup= this.recipientIndexListItem.recipientIndexListName ;
       const newReceiverType = 'private' ;
       const newUserChatRoomId = this.recipient._id
       const newSubject = this.finalSubject ;
       const newParagraphResults = this.paragraphResults 
       const newSeq = null 
       const newUpdateDateAndTime1 = null 
       const newHasFollowingParagraphItemToShow = undefined 
     
     
       const newSentFormResultForMessage = new FormResult (  new_id,
                                                   newIsActive ,
                                                   newIsDeleted,
                                                   newIsSelected,
                                                   newformReadFlag, 
                                                   newIsShareble,
                                                   newIsACopyOfAMessage,
                                                   newIsForwardToMail,
                                                   newIsForwarded,
                                                   newSender,
                                                   newSenderGroup,
                                                   newSenderType,
                                                   newReceiverGroup,
                                                   newReceiverType,
                                                   newUserChatRoomId,
                                                   newRecipientResultForMessage,
                                                   newDateAndTime,
                                                   newSubject,
                                                   newParagraphResults , 
                                                   newSeq,
                                                   newUpdateDateAndTime1, 
                                                   newHasFollowingParagraphItemToShow
                                                   ) ;

    const newSentFormResultForMail = new FormResult (  
       new_id2,
       newIsActive ,
       newIsDeleted,
       newIsSelected,
       newformReadFlag,
       newIsShareble,
       newIsACopyOfAMessage,
       newIsForwardToMail,
       newIsForwarded,
       newSender,
       newSenderGroup,
       newSenderType,
       newReceiverGroup,
       newReceiverType,
       null,
       newRecipientResultForMail,
       newDateAndTime,
       newSubject,
       newParagraphResults , 
       newSeq,
       newUpdateDateAndTime,
       newHasFollowingParagraphItemToShow
       ) ;

       const newSentFormResultForMailAsCopyRecipient = new FormResult (
        new_id2,
        newIsActive ,
        newIsDeleted,
        newIsSelected,
        newformReadFlag, 
        newIsShareble,
        newIsACopyOfAMessage,
        newIsForwardToMail,
        newIsForwarded,
        newSender,
        newSenderGroup,
        newSenderType,
        newReceiverGroup,
        newReceiverType,
        null,
        newRecipientResultForMailWhenShared,
        newDateAndTime,
        newSubject,
        newParagraphResults , 
        newSeq,
        newUpdateDateAndTime,
        newHasFollowingParagraphItemToShow
        ) ;
     
     
    //  this.finalSentFormResultForMessage = newSentFormResultForMessage
    //  this.finalSentFormResultForMail = newSentFormResultForMail

    
     if ( this.user !== null ){

  /**
   * if send then we send the text composition after we are sure the formResult has been stored 
   * if any error we ask if we still want to send by email anyway yes or no , and deal with it 
   * with additional steps
   */
  if ( this.recipientIndex.useMyOwnMailRecipient === true ){
      if ( this.isEmailMessage === true ){
      this.postComponentsHttpRequestService.httpPostFormResult( this.user ,  newSentFormResultForMessage  )
      .subscribe((result: any )=> { 

        console.log(result)

        const newFormResult = result.formResult
        const newUserReceiveFormResultId = result.userReceiveFormResultId
        const newWrongRecipientUserList = result.wrongRecipientUserList

        // this.formResultsService.addFormResult(newFormResult)

        if (this.recipient.toMail.length > 0){

          this.postComponentsHttpRequestService.httpPostMailFormResult( this.user ,  newSentFormResultForMail )
              .subscribe((result: any )=> {

                console.log( result)
                // this.formResultsService.addFormResult(result)

                this.mailTextFinalComposition ='mailto:'+this.mailTextReceiverTo+
                '?cc='+this.mailTextReceiverCc+
                '&bcc='+this.mailTextReceiverBcc+
                '&subject='+this.mailTextSubject+
                '&body='+this.mailTextRecipientGreetings+'%0D%0A%0D%0A'+
                 this.finalMailTextBody+'%0D%0A'
                // this.links.join('%0D%0A%0D%0A')
                //  this.mailList.join('%0D%0A%0D%0A')+
                //  this.mailList;

                 window.location.href = this.mailTextFinalComposition

                 this.dms.FormItemGoesToResultsOrChat({ 
                  finalSentFormResult: newFormResult,
                  chat_id: this.recipient.chatRoomId
                })
          
                this.emitInfoToCloseOpenFormItem.emit();
             
               /**
                * navigate to reload component for then come back to form item again , serve as restarter for this component 
                */
                this.router.navigate(['reload-form'], {relativeTo: this.route.parent});

              }, error =>{
                console.log(error , 'error on the email post')
              })
        }
      
      }, error => {
        console.log( error , 'error on the message post' )

      })
   }

  //  if ( this.recipient.toMessage.length === 0  && this.recipient.toMail.length > 0 ){
    if ( this.isEmailOnly === true  ){

      this.postComponentsHttpRequestService.httpPostMailFormResult( this.user ,  newSentFormResultForMail )
          .subscribe((newFormResult : FormResult)=> {
            console.log(newFormResult)
  
            // this.formResultsService.addFormResult( newFormResult )

              this.mailTextFinalComposition ='mailto:'+this.mailTextReceiverTo+
                                             '?cc='+this.mailTextReceiverCc+
                                             '&bcc='+this.mailTextReceiverBcc+
                                             '&subject='+this.mailTextSubject+
                                             '&body='+this.mailTextRecipientGreetings+'%0D%0A%0D%0A'+
                                              this.finalMailTextBody+'%0D%0A'
                      // this.links.join('%0D%0A%0D%0A')
                      //  this.mailList.join('%0D%0A%0D%0A')+
                      //  this.mailList;
              
              window.location.href = this.mailTextFinalComposition
              
      
              this.dms.FormItemGoesToResultsOrChat({ 
                finalSentFormResult: newFormResult ,
                chat_id: this.recipient.chatRoomId
              })
        
              this.emitInfoToCloseOpenFormItem.emit();
           
             /**
              * navigate to reload component for then come back to form item again , serve as restarter for this component 
              */
              this.router.navigate(['reload-form'], {relativeTo: this.route.parent});
            
            }, error => {
              console.log( error )
      
            })
   }

  //  if ( this.recipient.toMessage.length > 0 && this.recipient.toMail.length === 0 ){
    if ( this.isMessageOnly === true ){
    this.postComponentsHttpRequestService.httpPostFormResult( this.user , newSentFormResultForMessage  )
    .subscribe((result: any )=> { 

      console.log(result)

      const newFormResult = result.formResult
      const newUserReceiveFormResultId = result.userReceiveFormResultId
      const newWrongRecipientUserList = result.wrongRecipientUserList

      // this.formResultsService.addFormResult(newFormResult)

      this.dms.FormItemGoesToResultsOrChat({ 
        finalSentFormResult: newFormResult ,
        chat_id: this.recipient.chatRoomId
      })

      this.emitInfoToCloseOpenFormItem.emit();
   
     /**
      * navigate to reload component for then come back to form item again , serve as restarter for this component 
      */
      this.router.navigate(['reload-form'], {relativeTo: this.route.parent});
    
    }, error => {
      console.log( error )

    })
   }

  }

  if ( this.recipientIndex.useMyOwnMailRecipient === false ){
    if ( this.isEmailMessage === true ){
      this.postComponentsHttpRequestService.httpPostFormResult( this.user , newSentFormResultForMessage )
      .subscribe((result: any )=> { 

        console.log(result)

        const newFormResult = result.formResult
        const newUserReceiveFormResultId = result.userReceiveFormResultId
        const newWrongRecipientUserList = result.wrongRecipientUserList

        // this.formResultsService.addFormResult(newFormResult)

          this.postComponentsHttpRequestService.httpPostMailFormResult( this.user , newSentFormResultForMailAsCopyRecipient)
              .subscribe((result: any )=> {

                console.log( result)
                // this.formResultsService.addFormResult(result)

                this.mailTextFinalComposition ='mailto:'+this.mailTextReceiverTo+
                '?cc='+this.mailTextReceiverCc+
                '&bcc='+this.mailTextReceiverBcc+
                '&subject='+this.mailTextSubject+
                '&body='+this.mailTextRecipientGreetings+'%0D%0A%0D%0A'+
                 this.finalMailTextBody+'%0D%0A'
                // this.links.join('%0D%0A%0D%0A')
                //  this.mailList.join('%0D%0A%0D%0A')+
                //  this.mailList;

                 window.location.href = this.mailTextFinalComposition

                 this.dms.FormItemGoesToResultsOrChat({ 
                  finalSentFormResult: newFormResult ,
                  chat_id: this.recipient.chatRoomId
                })
          
                this.emitInfoToCloseOpenFormItem.emit();
             
               /**
                * navigate to reload component for then come back to form item again , serve as restarter for this component 
                */
                this.router.navigate(['reload-form'], {relativeTo: this.route.parent});

              }, error =>{
                console.log(error , 'error on the email post')
              })
      
      
      }, error => {
        console.log( error , 'error on the message post' )

      })
   }

  //  if ( this.recipient.toMessage.length === 0  && this.recipient.toMail.length > 0 ){
    if ( this.isEmailOnly === true  ){

      this.postComponentsHttpRequestService.httpPostMailFormResult( this.user ,  newSentFormResultForMailAsCopyRecipient)
          .subscribe((newFormResult : FormResult)=> {
            console.log(newFormResult)
  
            // this.formResultsService.addFormResult( newFormResult )

              this.mailTextFinalComposition ='mailto:'+this.mailTextReceiverTo+
                                             '?cc='+this.mailTextReceiverCc+
                                             '&bcc='+this.mailTextReceiverBcc+
                                             '&subject='+this.mailTextSubject+
                                             '&body='+this.mailTextRecipientGreetings+'%0D%0A%0D%0A'+
                                              this.finalMailTextBody+'%0D%0A'
                      // this.links.join('%0D%0A%0D%0A')
                      //  this.mailList.join('%0D%0A%0D%0A')+
                      //  this.mailList;
              
              window.location.href = this.mailTextFinalComposition
              
      
              this.dms.FormItemGoesToResultsOrChat({ 
                finalSentFormResult: newFormResult ,
                chat_id: this.recipient.chatRoomId
              })
        
              this.emitInfoToCloseOpenFormItem.emit();
           
             /**
              * navigate to reload component for then come back to form item again , serve as restarter for this component 
              */
              this.router.navigate(['reload-form'], {relativeTo: this.route.parent});
            
            }, error => {
              console.log( error )
      
            })
   }

  //  if ( this.recipient.toMessage.length > 0 && this.recipient.toMail.length === 0 ){
    if ( this.isMessageOnly === true ){
    this.postComponentsHttpRequestService.httpPostFormResult( this.user , newSentFormResultForMessage )
    .subscribe((result: any )=> { 

      console.log(result)

      const newFormResult = result.formResult
      const newUserReceiveFormResultId = result.userReceiveFormResultId
      const newWrongRecipientUserList = result.wrongRecipientUserList

      // this.formResultsService.addFormResult(newFormResult)

      this.dms.FormItemGoesToResultsOrChat({ 
        finalSentFormResult: newFormResult ,
        chat_id: this.recipient.chatRoomId
      })

      this.emitInfoToCloseOpenFormItem.emit();
   
     /**
      * navigate to reload component for then come back to form item again , serve as restarter for this component 
      */
      this.router.navigate(['reload-form'], {relativeTo: this.route.parent});
    
    }, error => {
      console.log( error )

    })
   }
  }

     }
     
     
    
     
     if ( this.user === null ){

      if ( this.isEmailMessage === true ){

        this.formResultsService.addFormResult(newSentFormResultForMessage)
        this.formResultsService.addFormResult(newSentFormResultForMail)
        console.log(newSentFormResultForMessage, newSentFormResultForMail)
       // console.log(this.finalSentFormResult.receiver.sentToMessage)
  
        this.mailTextFinalComposition ='mailto:'+this.mailTextReceiverTo+
                                       '?cc='+this.mailTextReceiverCc+
                                       '&bcc='+this.mailTextReceiverBcc+
                                       '&subject='+this.mailTextSubject+
                                       '&body='+this.mailTextRecipientGreetings+'%0D%0A%0D%0A'+
                                        this.finalMailTextBody+'%0D%0A'
         // this.links.join('%0D%0A%0D%0A')
         //  this.mailList.join('%0D%0A%0D%0A')+
         //  this.mailList;
        window.location.href = this.mailTextFinalComposition
        this.dms.FormItemGoesToResultsOrChat({ 
          finalSentFormResult: newSentFormResultForMessage,
          chat_id: this.recipient.chatRoomId
        })
  
        this.emitInfoToCloseOpenFormItem.emit();
       /**
        * navigate to reload component for then come back to form item again , serve as restarter for this component 
        */
        this.router.navigate(['reload-form'], {relativeTo: this.route.parent});
      
       }

      if ( this.isEmailOnly === true  ){
        this.formResultsService.addFormResult(newSentFormResultForMail)
        console.log(newSentFormResultForMail)
  
        this.mailTextFinalComposition ='mailto:'+this.mailTextReceiverTo+
                                       '?cc='+this.mailTextReceiverCc+
                                       '&bcc='+this.mailTextReceiverBcc+
                                       '&subject='+this.mailTextSubject+
                                       '&body='+this.mailTextRecipientGreetings+'%0D%0A%0D%0A'+
                                        this.finalMailTextBody+'%0D%0A'
         // this.links.join('%0D%0A%0D%0A')
         //  this.mailList.join('%0D%0A%0D%0A')+
         //  this.mailList;
        window.location.href = this.mailTextFinalComposition
        this.dms.FormItemGoesToResultsOrChat({ 
          finalSentFormResult: newSentFormResultForMail ,
          chat_id: this.recipient.chatRoomId 
        })

        
  
        this.emitInfoToCloseOpenFormItem.emit();
       /**
        * navigate to reload component for then come back to form item again , serve as restarter for this component 
        */
        this.router.navigate(['reload-form'], {relativeTo: this.route.parent});
      
      }

      if ( this.isMessageOnly === true  ){
        this.formResultsService.addFormResult(newSentFormResultForMessage)
        console.log(newSentFormResultForMessage)
  
        this.dms.FormItemGoesToResultsOrChat({ 
          finalSentFormResult: newSentFormResultForMessage ,
          chat_id: this.recipient.chatRoomId
        })
  
        this.emitInfoToCloseOpenFormItem.emit();
       /**
        * navigate to reload component for then come back to form item again , serve as restarter for this component 
        */
        this.router.navigate(['reload-form'], {relativeTo: this.route.parent});
      
      }

    
     }
  
  }
};

onEmitRecipientResult($event){

  this.recipient = $event
  this.isLoadingReceiverResult = true 

  this.defineMailAccountsText()

  this.defineWhatTypeOfMessageIsSending()

  setTimeout(()=>{
    this.isLoadingReceiverResult = false 
  },300)

 }

onEmitSecondRecipientResult($event){
  this.secondMailrecipient = $event 
  this.isLoadingReceiverResult = true 

  this.defineMailAccountsText()

  this.defineWhatTypeOfMessageIsSending()

  setTimeout(()=>{
    this.isLoadingReceiverResult = false 
  },300)
}

 defineMailAccountsText(){


  if ( this.recipientIndex.useMyOwnMailRecipient === true ){
    this.mailTextReceiverTo = this.recipient.toMail.toString();
    this.mailTextReceiverCc = this.recipient.ccMail.toString();
    this.mailTextReceiverBcc = this.recipient.bccMail.toString();
    this.mailTextRecipientGreetings = encodeURIComponent(this.recipient.greeting)
  }

  if ( this.recipientIndex.useMyOwnMailRecipient === false ){
    this.mailTextReceiverTo = this.secondMailrecipient.toMail.toString();
    this.mailTextReceiverCc = this.secondMailrecipient.ccMail.toString();
    this.mailTextReceiverBcc = this.secondMailrecipient.bccMail.toString();
    this.mailTextRecipientGreetings = encodeURIComponent(this.secondMailrecipient.greeting)

  }
 
 }


onUpdateBothParagraphResultTextResultAndIndex($event){
  console.log($event)

  /**
   * we have to restart the count of paragraphResults and text everytime we 
   * 1- split a paragraph
   * 2- add a paragraph
   * 3- move a paragraph
   * 
   * make a subscription to all the single/standard components that they have to resend the onSend()
   * once any of these 3 operations is completed ( means the list is stable )
   * plus we have to build a new dummy paragraphResults at each change , maybe no need when we just move
   * the paragraph, as the number of paragraphs remain the same 
   * 
   * 4 - we should advice for example when a form is missing a paragraph by method confront if paragraph_id
   * exist on stock , and if it doesn't advise user thta is missing and checking if he wants to continue
   * If is missing and the user wants to continu , we need to reformulate the dummy paragraphResult as he would 
   * maybe miss a paragraphResult 
   * 
   * 5- if a paragraph( shared/public/preferred ) has been changed , we should advise too 
   */

  this.paragraphResults[$event.newParagraphFeatureDropId] = $event.newParagraphResult

  this.mailTextBody[$event.newParagraphFeatureDropId] = $event.newMailTextParagraphBodyPart+ '%0A%0A'

  for (let i=0; i<this.mailTextBody.length; i++){
    if (i === 0){ this.finalMailTextBody = this.mailTextBody[i]}
    if (i !== 0){ this.finalMailTextBody = this.finalMailTextBody + this.mailTextBody[i]}
  }

  console.log(this.paragraphResults)
  console.log(this.mailTextBody, this.finalMailTextBody)

 };


/**
 * destroyoying subscription
 */
 ngOnDestroy(){

  this.subscriptionToOnlineOffline.unsubscribe()
  this.subscriptionToChangeRecipientList.unsubscribe()
  this.updateOnUserEmail.unsubscribe()
  this.reconstructFormItemAfterParagraphsHasbeenStoredSubscription.unsubscribe()
  this.routeParameterSubscription.unsubscribe()
  this.askUserToLoginAgainSubscription.unsubscribe()
  this.onCloseLoginDialogPopUpSubscription.unsubscribe()
  this.updatingRecipientIndexListItemsFromFeatureIndexUpdate.unsubscribe()
  this.unblockLoginPopUpToPopAgainSubscription?.unsubscribe()
  
}

  

}
