export class MultiEntryCalendar {

    constructor( public _id: string, 
                 public componentName: string , 
                 public UIID: string , 
                 public owner: string,
                 public allUsersAllowed: boolean,
                 public userListAllowed: string[],
                 public dateAndTime: string ,
                 public name : string,
                 public description: string ,
                 public multiEntries: Date[] ){}
}