import { Component, OnInit , Input , Output, EventEmitter} from '@angular/core';
import { FormGroup , FormControl} from '@angular/forms';
import { Subscription , Observable} from 'rxjs';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';

@Component({
  selector: 'app-new-subject-form',
  templateUrl: './new-subject-form.component.html',
  styleUrls: ['./new-subject-form.component.css']
})
export class NewSubjectFormComponent implements OnInit {

  user: string 

  subjectEditForm : FormGroup ;

  newSubjectSubscription: Subscription;
  @Input() subscribeNewSubject: Observable<void>
  @Output() updateFormSubject  = new EventEmitter<string>()

  newSubjectName: string

  constructor(  private userObjectUpdateService: UserObjectUpdateService ) { }

  ngOnInit(): void {

    this.user = this.userObjectUpdateService.userEmail

this.subjectEditForm = new FormGroup ({
  name : new FormControl (''),
})

this.newSubjectSubscription = this.subscribeNewSubject.subscribe(()=> this.submitSubjectEditForm())
  }



  submitSubjectEditForm(){
    this.newSubjectName = this.subjectEditForm.value.name
    console.log(this.newSubjectName)
    this.updateFormSubject.emit(this.newSubjectName)
  }

  ngOnDestroy(): void{
    this.newSubjectSubscription.unsubscribe()
  }

}
