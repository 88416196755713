import { Injectable } from '@angular/core';
import { MatDialog , MatDialogConfig , MatDialogRef } from '@angular/material/dialog'; 
import { Observable, observable } from 'rxjs' ; 
import { WordInputEditComponent } from '../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/word/word-input-list/word-input-edit/word-input-edit.component';
import { WordLessEditComponent} from '../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/word-less/word-less-list/word-less-edit/word-less-edit.component';
import { WordHintEditComponent} from '../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/word-hint/word-hint-list/word-hint-edit/word-hint-edit.component';
import { SingleSliderEditComponent } from '../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/slider/single-slider/single-slider-list/single-slider-edit/single-slider-edit.component';
import { FixLayoutEditComponent } from '../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/layout/fix-layout-list/fix-layout-edit/fix-layout-edit.component';
import { FixLayoutEditStringComponent } from '../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/layout/fix-layout-list/fix-layout-edit-string/fix-layout-edit-string.component';
import { FixLayoutEditDescriptionComponent } from '../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/layout/fix-layout-list/fix-layout-edit-description/fix-layout-edit-description.component';
import { FixLayoutEditChartComponent } from '../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/layout/fix-layout-list/fix-layout-edit-chart/fix-layout-edit-chart.component';
import { FixLayoutEditNumberComponent } from '../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/layout/fix-layout-list/fix-layout-edit-number/fix-layout-edit-number.component';
import { FixTextEditComponent } from '../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/texts/fix-text-list/fix-text-edit/fix-text-edit.component';
import { CalendarEditComponent } from '../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/calendar-list/calendar-edit/calendar-edit.component';
import { MultiEntryCalendarEditComponent } from '../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/multi-entry-calendar/multi-entry-calendar-list/multi-entry-calendar-edit/multi-entry-calendar-edit.component';
import { CalculatorMixTableEditComponent } from '../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calculator/calculator-mix-table-list/calculator-mix-table-edit/calculator-mix-table-edit.component';
import { FormItemSentDialogComponent } from '../../forms/form-list/form-item/form-item-sent-dialog/form-item-sent-dialog.component';
import { PopUpErrorDialogComponent } from '../../loading-spinner/pop-up-error-dialog/pop-up-error-dialog.component';
import { QuickCommunicationPopUpComponent } from 'src/app/loading-spinner/quick-communication-pop-up/quick-communication-pop-up.component';
import { FixDatesFilterComponent } from 'src/app/forms/form-results/form-results-filter/filter-dates/fix-dates-filter/fix-dates-filter.component';
import { SharedCalendarStatusEditComponent } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/shared-calendar-status-list/shared-calendar-status-edit/shared-calendar-status-edit.component';
import { AddSharedCalendarStatusItemRangeComponent } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/shared-calendar-status-list/add-shared-calendar-status-item-range/add-shared-calendar-status-item-range.component';
import { DeleteSharedCalendarStatusItemRangesComponent } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/shared-calendar-status-list/delete-shared-calendar-status-item-ranges/delete-shared-calendar-status-item-ranges.component';
import { AddSharedFormOrParagraphComponent } from 'src/app/forms/form-list/add-shared-form-or-paragraph/add-shared-form-or-paragraph.component';
import { tap } from 'rxjs/operators';
import { InnerComponentEditDialogComponent } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/shared-calendar-status-list/inner-component-edit-dialog/inner-component-edit-dialog.component';
import { DuplicateFeatureParagraphFormComponent } from 'src/app/loading-spinner/duplicate-feature-paragraph-form/duplicate-feature-paragraph-form.component';


@Injectable ({
    providedIn: 'root' 
})

export class DialogmanagerService {



    constructor ( private dialog: MatDialog ){}


    public WordInputEdit ( data: any =[]): Observable<any> {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'add-css-class';
        dialogConfig.disableClose = false ;
        dialogConfig.data = data;
        let dialogRef: MatDialogRef<WordInputEditComponent>;
        dialogRef = this.dialog.open(WordInputEditComponent, dialogConfig);

        return dialogRef.afterClosed();
    } 


    public WordLessEdit ( data: any =[]): Observable<any> {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'add-css-class';
        dialogConfig.disableClose = false ;
        dialogConfig.data = data;
        let dialogRef: MatDialogRef<WordLessEditComponent>;
        dialogRef = this.dialog.open(WordLessEditComponent, dialogConfig);
        return dialogRef.afterClosed();

    } 


    public WordHintEdit ( data: any =[]): Observable<any> {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'add-css-class';
        dialogConfig.disableClose = false ;
        dialogConfig.data = data;
        let dialogRef: MatDialogRef<WordHintEditComponent>;
        dialogRef = this.dialog.open(WordHintEditComponent, dialogConfig);
        return dialogRef.afterClosed();

    } 


    public SingleSliderEdit ( data: any =[]): Observable<any> {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'add-css-class';
        dialogConfig.disableClose = false ;
        dialogConfig.data = data;
        let dialogRef: MatDialogRef<SingleSliderEditComponent>;
        dialogRef = this.dialog.open(SingleSliderEditComponent, dialogConfig);
        return dialogRef.afterClosed();

    } 


    public FixLayoutEdit ( data: any =[]): Observable<any> {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'add-css-class';
        dialogConfig.disableClose = false ;
        dialogConfig.data = data;
        let dialogRef: MatDialogRef<FixLayoutEditComponent>;
        dialogRef = this.dialog.open(FixLayoutEditComponent, dialogConfig);
        return dialogRef.afterClosed();

    } 

    public FixLayoutEditString ( data: any =[]): Observable<any> {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'add-css-class';
        dialogConfig.disableClose = false ;
        dialogConfig.data = data;
        let dialogRef: MatDialogRef<FixLayoutEditStringComponent>;
        dialogRef = this.dialog.open(FixLayoutEditStringComponent, dialogConfig);
        return dialogRef.afterClosed();

    } 

    public FixLayoutEditNumber ( data: any =[]): Observable<any> {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'add-css-class';
        dialogConfig.disableClose = false  ;
        dialogConfig.data = data;
        let dialogRef: MatDialogRef<FixLayoutEditNumberComponent>;
        dialogRef = this.dialog.open(FixLayoutEditNumberComponent, dialogConfig);
        return dialogRef.afterClosed();

    } 

    public FixLayoutEditDescription ( data: any =[]): Observable<any> {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'add-css-class';
        dialogConfig.disableClose = false  ;
        dialogConfig.data = data;
        let dialogRef: MatDialogRef<FixLayoutEditDescriptionComponent>;
        dialogRef = this.dialog.open(FixLayoutEditDescriptionComponent, dialogConfig);
        return dialogRef.afterClosed();

    } 

    public FixLayoutEditChart ( data: any =[]): Observable<any> {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'add-css-class';
        dialogConfig.disableClose = false ;
        dialogConfig.data = data;
        let dialogRef: MatDialogRef<FixLayoutEditChartComponent>;
        dialogRef = this.dialog.open(FixLayoutEditChartComponent, dialogConfig);
        return dialogRef.afterClosed();

    } 

    public FixTextEdit ( data: any =[]): Observable<any> {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = '';
        dialogConfig.disableClose = false  ;
        dialogConfig.data = data;
        let dialogRef: MatDialogRef<FixTextEditComponent>;
        dialogRef = this.dialog.open(FixTextEditComponent, dialogConfig);
        return dialogRef.afterClosed();

    } 

    public CalendarEdit ( data: any =[]): Observable<any> {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'add-css-class';
        dialogConfig.disableClose = true ;
        dialogConfig.data = data;
        let dialogRef: MatDialogRef<CalendarEditComponent>;
        dialogRef = this.dialog.open(CalendarEditComponent, dialogConfig);
        return dialogRef.afterClosed();

    } 

    public MultiEntryCalendarEdit ( data: any =[]): Observable<any> {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'add-css-class';
        dialogConfig.disableClose = false ;
        dialogConfig.data = data;
        let dialogRef: MatDialogRef<MultiEntryCalendarEditComponent>;
        dialogRef = this.dialog.open(MultiEntryCalendarEditComponent, dialogConfig);
        return dialogRef.afterClosed();

    } 

    public CalculatorMixTableEdit ( data: any =[]): Observable<any> {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'add-css-class';
        dialogConfig.disableClose = false ;
        dialogConfig.data = data;
        let dialogRef: MatDialogRef<CalculatorMixTableEditComponent>;
        dialogRef = this.dialog.open( CalculatorMixTableEditComponent, dialogConfig);
        return dialogRef.afterClosed();

    } 


    public FormItemGoesToResultsOrChat (data: any=[]): Observable<any> {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'dialog-container'; // class="dialog-container"
        dialogConfig.disableClose = false ;
        dialogConfig.data = data;
        let dialogRef: MatDialogRef<FormItemSentDialogComponent>;
        dialogRef = this.dialog.open( FormItemSentDialogComponent, dialogConfig);
        return dialogRef.afterClosed();

    } 


    public PopUpErrorDialog (data: any=[]): Observable<any> {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'add-css-class';
        dialogConfig.disableClose = false ;
        dialogConfig.data = data;
        let dialogRef: MatDialogRef<PopUpErrorDialogComponent>;
        dialogRef = this.dialog.open( PopUpErrorDialogComponent, dialogConfig);
        return dialogRef.afterClosed();

    } 

 
        public aQuickCommunicationIsON: boolean = false 
        public QuickCommunicationPopUpComponent (data: any=[]): void {
        /**
         * if a dialog is already open , we shouldstop the dialod to ope again
         */
        this.aQuickCommunicationIsON = true
        console.log(' A QUICK COMMUNICATION IS ON =>',this.aQuickCommunicationIsON)
        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'add-css-class';
        dialogConfig.disableClose = false ;
        dialogConfig.data = data;
        let dialogRef: MatDialogRef<QuickCommunicationPopUpComponent>;
        dialogRef = this.dialog.open( QuickCommunicationPopUpComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(() => {
                  this.aQuickCommunicationIsON = false
                  console.log( 'THE DIALOG WAS CLOSED, A QUICK COMMUNICATION IS ON => ', this.aQuickCommunicationIsON )      
        });

    } 

    public FixDatesFilter (data: any=[]): Observable<any> {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'add-css-class';
        dialogConfig.disableClose = false ;
        dialogConfig.data = data;
        let dialogRef: MatDialogRef<FixDatesFilterComponent>;
        dialogRef = this.dialog.open( FixDatesFilterComponent, dialogConfig);
        return dialogRef.afterClosed();

    } 

    public sharedCalendarStatusEditComponent (data: any=[]): Observable<any> {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'add-css-class';
        dialogConfig.disableClose = false ;
        dialogConfig.data = data;
        let dialogRef: MatDialogRef<SharedCalendarStatusEditComponent>;
        dialogRef = this.dialog.open( SharedCalendarStatusEditComponent, dialogConfig);
        return dialogRef.afterClosed();

    } 

    public addSharedCalendarStatusItemRangeComponent (data: any=[]): Observable<any> {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'add-css-class';
        dialogConfig.disableClose = false ;
        dialogConfig.data = data;
        let dialogRef: MatDialogRef<AddSharedCalendarStatusItemRangeComponent>;
        dialogRef = this.dialog.open( AddSharedCalendarStatusItemRangeComponent, dialogConfig);
        return dialogRef.afterClosed();

    } 

    public deleteSharedCalendarStatusItemRangesComponent (data: any=[]): Observable<any> {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'add-css-class';
        dialogConfig.disableClose = false ;
        dialogConfig.data = data;
        let dialogRef: MatDialogRef<DeleteSharedCalendarStatusItemRangesComponent>;
        dialogRef = this.dialog.open( DeleteSharedCalendarStatusItemRangesComponent, dialogConfig);
        return dialogRef.afterClosed();

    } 

    public innerComponentEditDialogComponent (data: any=[]): Observable<any> {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'add-css-class';
        dialogConfig.disableClose = false ;
        dialogConfig.data = data;
        let dialogRef: MatDialogRef<InnerComponentEditDialogComponent>;
        dialogRef = this.dialog.open( InnerComponentEditDialogComponent, dialogConfig);
        return dialogRef.afterClosed();

    } 

    public addSharedFormOrParagraphComponent (data: any=[]): Observable<any> {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'add-css-class';
        dialogConfig.disableClose = false ;
        dialogConfig.data = data;
        let dialogRef: MatDialogRef<AddSharedFormOrParagraphComponent>;
        dialogRef = this.dialog.open( AddSharedFormOrParagraphComponent, dialogConfig);
        return dialogRef.afterClosed();

    } 

    public duplicateFeatureParagraphFormComponent (data: any=[]): Observable<any> {
        var dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'add-css-class';
        dialogConfig.disableClose = false ;
        dialogConfig.data = data;
        let dialogRef: MatDialogRef<DuplicateFeatureParagraphFormComponent>;
        dialogRef = this.dialog.open( DuplicateFeatureParagraphFormComponent, dialogConfig);
        return dialogRef.afterClosed();

    } 


    // public LoginPopUpDialog(data: any=[]): Observable<any> {
    //     var dialogConfig = new MatDialogConfig();
    //     dialogConfig.panelClass = 'add-css-class';
    //     dialogConfig.disableClose = false ;
    //     dialogConfig.data = data;
    //     let dialogRef: MatDialogRef<LoginComponent>;
    //     dialogRef = this.dialog.open( LoginComponent, dialogConfig);
    //     return dialogRef.afterClosed();

    // } 



    

    


    // public addFormDialogue ( data: any =[]): Observable<any> {
    //     var dialogConfig = new MatDialogConfig();
    //     dialogConfig.panelClass = 'add-css-class';
    //     dialogConfig.disableClose = true;
    //     dialogConfig.data = data;
    //     let dialogRef: MatDialogRef<AddFormDialogueComponent>;
    //     dialogRef = this.dialog.open(AddFormDialogueComponent, dialogConfig);
    //     return dialogRef.afterClosed();

    // } 






}

