import { Injectable } from "@angular/core";

// ------ base ( singleBase ) -------------------------------------
import { SingleWord } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/single-word/single-word.model";
import { SingleWordInput } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/single-word/single-word-input-list/single-word-input.model";
import { ToggleSingleWordButton } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/buttons/toggle-single-word-button/toggle-single-word-button.model";
import { SingleDropDownSelect } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/select/select-list/single-drop-down-select/single-drop-down-select.model";
import { SingleSlider } from "../../forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/slider/single-slider/single-slider.model";
// ------ base  -------------------------------------
import { SingleWordService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/single-word/single-word.service";
import { SingleWordInputService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/single-word/single-word-input-list/single-word-input.service";
import { ToggleSingleWordButtonService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/buttons/toggle-single-word-button/toggle-single-word-button.service";
import { SingleDropDownSelectService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/select/select-list/single-drop-down-select/single-drop-down-select.service";
import { SingleSliderService } from "src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/slider/single-slider/single-slider-list/single-slider.service";



@Injectable ({
    providedIn: 'root' 
})

export class StoreSingleBaseComponentsService {

    constructor ( private singleWordService: SingleWordService , 
                  private singleWordInputService: SingleWordInputService , 
                  private toggleSingleWordButtonService: ToggleSingleWordButtonService , 
                  private singleDropDownSelectService: SingleDropDownSelectService ,
                  private singleSliderService: SingleSliderService 
                ){}


    unpackAndDistributeSingleBaseComponents(singleBaseComponentsAndTypes: any , isComingFromFormItemCall: boolean , isComingFromMultiFeatures: boolean ){

        console.log( 'singleFeature type + list items = ', singleBaseComponentsAndTypes.singleFeatureTypeList, singleBaseComponentsAndTypes.multiFeatureChild_list )

        for ( let i=0; i<singleBaseComponentsAndTypes.singleFeatureTypeList.length ; i++){

            if ( singleBaseComponentsAndTypes.singleFeatureTypeList[i] === 'singleWord' ){ this.singleWordReconstructAndShare(singleBaseComponentsAndTypes.multiFeatureChild_list[i] , isComingFromFormItemCall , isComingFromMultiFeatures )}
            if ( singleBaseComponentsAndTypes.singleFeatureTypeList[i] === 'singleWordInput' ){ this.singleWordInputReconstructAndShare(singleBaseComponentsAndTypes.multiFeatureChild_list[i], isComingFromFormItemCall , isComingFromMultiFeatures )}
            if ( singleBaseComponentsAndTypes.singleFeatureTypeList[i] === 'toggleSingleWordButton' ){this.toggleSingleWordButtonReconstructAndShare(singleBaseComponentsAndTypes.multiFeatureChild_list[i], isComingFromFormItemCall , isComingFromMultiFeatures )}
            if ( singleBaseComponentsAndTypes.singleFeatureTypeList[i] === 'singleDropDownSelect' ){this.singleDropDownSelectReconstructAndShare(singleBaseComponentsAndTypes.multiFeatureChild_list[i], isComingFromFormItemCall , isComingFromMultiFeatures )}
            if ( singleBaseComponentsAndTypes.singleFeatureTypeList[i] === 'singleSlider' ){this.singleSliderReconstructAndShare(singleBaseComponentsAndTypes.multiFeatureChild_list[i], isComingFromFormItemCall , isComingFromMultiFeatures )}

        };
    };


    singleWordReconstructAndShare(singleComponentToBuild: any , isComingFromFormItemCall: boolean , isComingFromMultiFeatures: boolean ){

    const new_id = singleComponentToBuild._id
    const newComponentName = singleComponentToBuild.componentName
    const newUIID = singleComponentToBuild.UIID
    const newOwner = singleComponentToBuild.owner
    const newAllUsersAllowed = singleComponentToBuild.allUsersAllowed
    const newUserListAllowed = singleComponentToBuild.userListAllowed
    const newDateAndTime = singleComponentToBuild.dateAndTime
    const newName = singleComponentToBuild.name
    const newSingleWordDetails = singleComponentToBuild.singleWordDetails

    const newSingleWord = new SingleWord ( new_id, newComponentName, newUIID, newOwner, newDateAndTime, newAllUsersAllowed, newUserListAllowed, newName, newSingleWordDetails);
    
    this.singleWordService.addNewSingleWord(newSingleWord , isComingFromFormItemCall , isComingFromMultiFeatures)

    }

    singleWordInputReconstructAndShare(singleComponentToBuild: any , isComingFromFormItemCall: boolean , isComingFromMultiFeatures: boolean){

    const new_id = singleComponentToBuild._id
    const newComponentName = singleComponentToBuild.componentName
    const newUIID = singleComponentToBuild.UIID 
    const newOwner = singleComponentToBuild.owner
    const newAllUsersAllowed = singleComponentToBuild.allUsersAllowed
    const newUserListAllowed = singleComponentToBuild.userListAllowed
    const newDateAndTime = singleComponentToBuild.dateAndTime
    const newName = singleComponentToBuild.name
    const newSingleWordInputDetails = singleComponentToBuild.singleWordInputDetails

    const newSingleWordInput = new SingleWordInput ( new_id, newComponentName, newUIID, newOwner, newDateAndTime , newAllUsersAllowed, newUserListAllowed, newName, newSingleWordInputDetails);
    
    this.singleWordInputService.addNewSingleWordInput(newSingleWordInput , isComingFromFormItemCall , isComingFromMultiFeatures )
    }

    toggleSingleWordButtonReconstructAndShare(singleComponentToBuild: any , isComingFromFormItemCall: boolean , isComingFromMultiFeatures: boolean){
       
    const new_id = singleComponentToBuild._id
    const newComponentName = 'toggleSingleWordButton'
    const newUIID = singleComponentToBuild.UIID
    const newOwner = singleComponentToBuild.owner
    const newAllUsersAllowed = singleComponentToBuild.allUsersAllowed
    const newUserListAllowed = singleComponentToBuild.userListAllowed
    const newDateAndTime = singleComponentToBuild.dateAndTime
    const newName = singleComponentToBuild.name
    const newToggleSingleWordDetails = singleComponentToBuild.toggleSingleWordDetails

    const newToggleSingleWordButton = new ToggleSingleWordButton (new_id , newComponentName, newUIID, newOwner, newDateAndTime, newAllUsersAllowed, newUserListAllowed, newName, newToggleSingleWordDetails)
    
    this.toggleSingleWordButtonService.addNewToggleSingleWordButton(newToggleSingleWordButton , isComingFromFormItemCall , isComingFromMultiFeatures )
    }

    singleDropDownSelectReconstructAndShare(singleComponentToBuild: any , isComingFromFormItemCall: boolean , isComingFromMultiFeatures: boolean){

    const new_id = singleComponentToBuild._id
    const newComponentName = 'singleDropDownSelect'
    const newUIID = singleComponentToBuild.UIID
    const newOwner = singleComponentToBuild.owner
    const newAllUsersAllowed = singleComponentToBuild.allUsersAllowed
    const newUserListAllowed = singleComponentToBuild.userListAllowed
    const newDateAndTime = singleComponentToBuild.dateAndTime
    const newName = singleComponentToBuild.name
    const newDescription = singleComponentToBuild.description 
    const newContents = singleComponentToBuild.contents 

    const newSingleDropDownSelect = new SingleDropDownSelect (new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed,
          newDateAndTime, newName, newDescription, newContents )
    this.singleDropDownSelectService.addNewSingleDropDownSelect(newSingleDropDownSelect , isComingFromFormItemCall , isComingFromMultiFeatures )
    }

    singleSliderReconstructAndShare(singleComponentToBuild: any , isComingFromFormItemCall: boolean , isComingFromMultiFeatures: boolean){

    const new_id = singleComponentToBuild._id
    const newComponentName = singleComponentToBuild.componentName
    const newUIID = singleComponentToBuild.UIID
    const newOwner = singleComponentToBuild.owner
    const newAllUsersAllowed = singleComponentToBuild.allUsersAllowed
    const newUserListAllowed = singleComponentToBuild.userListAllowed
    const newDateAndTime = singleComponentToBuild.dateAndTime
    const newName = singleComponentToBuild.name
    const newSingleSliderDetails = singleComponentToBuild.singleSliderDetails // this is alrwady an array 

    const newSingleSlider = new SingleSlider (new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed, newDateAndTime, newName, newSingleSliderDetails )
    this.singleSliderService.addNewSingleSlider(newSingleSlider , isComingFromFormItemCall , isComingFromMultiFeatures )
    }


}