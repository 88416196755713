import { Feature } from "../paragraph-item/features/feature.model";

export class FeatureContainer {

    constructor ( public _id: string, 
                  public componentName: string , 
                  public UIID: string , 
                  public owner: string , 
                  public allUsersAllowed: boolean , 
                  public userListAllowed: string[],
                  public dateAndTime: string , 
                  public isActive: boolean , 
                  public isAccepted: boolean , 
                  public name: string ,
                  public componentReference_id: string, 
                  public features: Feature[] // need to use array just to justify drag and drop
                  // but eventually always put just 1 feature and point to the 0 index 
                  ){

    }

}

/**
 * export class ParagraphFeatureContainer {

    constructor( public paragraphFeatureReference_id: string, 
                 public isActive: boolean, 
                 public isAccepted: boolean, 
                 public name: string, // can be the name of the topic at sender 
                 public paragraphFeatureState: boolean , // visible or not is to change at every check
                 public paragraphFeature: ParagraphFeature ){}
}
 */