import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-is-taking-far-too-long',
  templateUrl: './is-taking-far-too-long.component.html',
  styleUrls: ['./is-taking-far-too-long.component.css']
})
export class IsTakingFarTooLongComponent implements OnInit {

  thisIsTakingFarTooLong: string

  counter: number

  constructor() { }

  ngOnInit(){

    this.thisIsTakingFarTooLong = ''

    this.deployWordLetterByLetter(0)

  }


  deployWordLetterByLetter( letterPosition: number){

    const initialWord: string = 'this is taking F?!%$&* long !'
    this.counter = letterPosition

    if ( this.counter < initialWord.length-1 ){
      this.letterByLetter(initialWord.charAt(this.counter))
    }

  }

  letterByLetter( letter: string ){

    const initialWord: string = 'this is taking F?!%$&* long !'

    this.counter = this.counter+1

    setTimeout(()=>{
      this.thisIsTakingFarTooLong = this.thisIsTakingFarTooLong+letter

      if (this.thisIsTakingFarTooLong.length === initialWord.length-1){this.counter = 0 , this.correctInitialWord() }
      if (this.thisIsTakingFarTooLong.length < initialWord.length-1){ this.deployWordLetterByLetter(this.counter) }

    },200)
   
  }


  correctInitialWord(){
    const totalWord1 = 'this is taking FAR $&* long'
   
    const totalWord2 = 'this is taking FAR TOO long !!!'


    setTimeout(()=>{
      this.thisIsTakingFarTooLong = totalWord1
    },500)

    setTimeout(()=>{
      this.thisIsTakingFarTooLong = totalWord2
    },1000)



  }

}
