import { Component, OnInit , ViewChild, AfterViewChecked, ChangeDetectorRef, NgZone } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subject, Subscription, Observer, fromEvent, merge } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';
import { MatTabGroup } from '@angular/material/tabs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RecipientIndex } from '../forms/form-list/form-edit/recipient-index-list/recipient-index.model';
import { RecipientIndexService } from '../forms/form-list/form-edit/recipient-index-list/recipient-index.service';
import { ChatDialogService } from './chat-dialog.service';
import { Header } from '../header/header.model';
import { HeaderService } from '../header/header.service';
import { Chat } from './chat.model';
import { ChatService } from './chat.service';
import { DateTime } from 'luxon';
import { UserObjectUpdateService } from '../services/other-components-services/user-object-update.service';
import { Title } from '@angular/platform-browser';
import { RecipientService } from '../forms/form-list/form-item/recipient/recipient.service';
import { Recipient } from '../forms/form-list/form-item/recipient/recipient.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorsDialogManagerService } from '../services/other-components-services/errors-dialog-manager.service';
import { LogoutService } from '../login/logout.service';


@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.css']
})
export class ChatsComponent implements OnInit {

  user: string 

  /**
   *  package properties for online / offline detection and mode
   */
   onlineOffline: boolean = navigator.onLine
   // ------------------------------------------------------------
 
  isPersonal: boolean; 
  isPublic: boolean;
  isShared: boolean; 
  isPreferred: boolean;
  isOpenIndexDesktop: boolean;
  isOpenIndexMobile: boolean;

  form: FormGroup

  aChatHasBeenSelected: boolean
  weAreInMobileMode: boolean; 
  chat: Chat
  recipient: Recipient

  aParagraphHasToBeChosen: boolean

  weAreInContentItem: boolean; // to differentiate if we are in content list or content item on mobile and switch view
  // need to receive a subscription from the click on the index that turn list into content item view 


  @ViewChild('drawer') public sidenav: MatSidenav;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  @ViewChild('tabs') tabGroup: MatTabGroup 

  subscribeNewFormResults: Subject<void> = new Subject<void>();

  subscribeSelectedChat = new Subject<Recipient>()

  /**
   * receiving thesubscription hat inform the form has been sent from mobile chat
   * and need to close the paragraph Index 
   */
  receiveSubscriptionFromChatFormEditToCloseSideNav: Subscription

  /**
   * subscription to online/offline
   */

  subscriptionToOnlineOffline: Subscription 

  /**
   * subscription to open sideNavBar coming from chat item mobile 
   */
  openSideNavBarFromChatItemClickSubscription: Subscription

  /**
   * subscription from chat item once displayed inform chats and all others that chat has been selected 
   * and all other info 
   */
  chatItemHasBeenDisplayed: Subscription
  updateOnUserEmail: Subscription 


  showChatRecipientName : boolean 
  isLoading: boolean = false 

  isLoadingFullPage: boolean = false 

  onCloseLoginDialogPopUpSubscription: Subscription 
  aChatHasBeenSelectedSubscription: Subscription 


  constructor( private breakpointObserver: BreakpointObserver,
               private chatService: ChatService,
               private recipientIndexService: RecipientIndexService ,
               private chatDialogService: ChatDialogService,
               private headerService: HeaderService,
               private userObjectUpdateService: UserObjectUpdateService ,
               private readonly changeDetectorRef: ChangeDetectorRef,
               private recipientService: RecipientService , 
               private titleService: Title , 
               private router: Router,
               private route: ActivatedRoute,
               private errorsDialogManagerService: ErrorsDialogManagerService ,
               private logoutService: LogoutService , 
               private cdr: ChangeDetectorRef ,
               private ngZone: NgZone

               ){
                this.receiveSubscriptionFromChatFormEditToCloseSideNav = this.chatService.getCloseSideNavOnceMobileFormHasBeenSent()
                    .subscribe(()=> {
                      this.closeSideNavFromChatSentForm()
                });
                this.openSideNavBarFromChatItemClickSubscription = this.chatService.getClickEventToOpenSideNavFromChatItem()
                    .subscribe(()=> { 
                      this.onOpenNavBarDrawerParagraphIndex() 
                });
                this.chatItemHasBeenDisplayed = this.chatService.getChatFromChatItemIsDisplayed()
                    .subscribe((recipientResult: Recipient)=> { 
                        this.aChatHasBeenDisplayed(recipientResult)
                });
               }

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
        .subscribe((updatedUserEmail)=>{
         this.user = updatedUserEmail 
         this.localUpdates(this.user)
        console.log('updated USER = ', this.user)
    })

    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    {  this.weAreInMobileMode = true ,  console.log('isMobile')} 
    else { this.weAreInMobileMode = false,  console.log( 'isDesktop' )} 

    /**
 * package online / offline property -------------------------------------------------
 */
  console.log('this device is online : ',this.onlineOffline)
  this.subscriptionToOnlineOffline = this.createOnline$().subscribe(isOnline => console.log( this.onlineOffline = isOnline,
    'this device is now online: ', this.onlineOffline, DateTime.now().toString() ));
// ------------------------------------------------------------------------------------


    if ( this.user === null ){ 
         this.isLoadingFullPage = true 
         this.errorsDialogManagerService.LoginPopUpDialog()
    }

    if( this.user !== null ){

    this.titleService.setTitle("groups")
    this.isPersonal = true;
    this.isPublic = false; 
    this.isShared = false;
    this.isPreferred = false

    this.isOpenIndexDesktop = true;
    this.isOpenIndexMobile = false;

    this.weAreInContentItem = true

    this.aChatHasBeenSelected = false
    this.aParagraphHasToBeChosen = false

    const newHeaderIndexSelection = new Header (false, false, false,  false, false, true, false, false )
    this.headerService.nextIndexSelection(newHeaderIndexSelection)

    this.showChatRecipientName = true
    }

    this.onCloseLoginDialogPopUpSubscription = this.logoutService.getClickToCloseLoginDialogPopUp()
    .subscribe(()=> {
      console.log('CANNOT STAY IN THIS PAGE ')
      this.router.navigate( ['../create-form'], { relativeTo: this.route })
    }
    )

    this.aChatHasBeenSelectedSubscription = this.chatService.aChatHasBeenSelectedFromChatFormResultList
        .subscribe(()=>{
          this.aChatHasBeenSelected = true 
        })


    
  
  };

   /**
 * package online / offline property -------------------------------------------------
 */

    createOnline$() {
      return merge<boolean>(
        fromEvent(window, 'offline').pipe(map(() => false)),
        fromEvent(window, 'online').pipe(map(() => true)),
        new Observable((sub: Observer<boolean>) => {
          sub.next(navigator.onLine);
          sub.complete();
        }));
    };
    
    // -----------------------------------------------------------------------------------
  localUpdates(updatedUserEmail: string){
    if ( updatedUserEmail === null ){ this.router.navigate( ['../create-form'], { relativeTo: this.route }) }
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  };

  aChatHasBeenDisplayed(recipientResult: Recipient){
    console.log(recipientResult)
    // this.chat = chat
    this.aChatHasBeenSelected = true
  }




   onUpdateSelectedChat($event){
    this.ngZone.run(()=>{
      this.aChatHasBeenSelected = true 
      console.log($event)
      // this.recipient = $event 
      this.recipient = this.chatService.selectedChat.recipientSelected
      // this.subscribeSelectedChat.next(this.recipient)
    })
    this.cdr.detectChanges()
    

   }

  onReturnToChatList(){
    this.aChatHasBeenSelected = false
    this.sidenav.close()
  
  }

  onToggleIndexMenu(){
      this.isOpenIndexMobile = false
      this.aParagraphHasToBeChosen = false
      this.showChatRecipientName = true
      this.isOpenIndexDesktop = !this.isOpenIndexDesktop; 
    
  }

  onToggleIndexMenuMobile(){
    this.isOpenIndexDesktop = true
    this.isOpenIndexMobile = !this.isOpenIndexMobile
    this.aParagraphHasToBeChosen = !this.aParagraphHasToBeChosen 
    this.showChatRecipientName = !this.showChatRecipientName
  }

  closeSideNavFromChatSentForm(){
    if (this.weAreInMobileMode === true ){ this.aParagraphHasToBeChosen = false
                                 this.aChatHasBeenSelected = true 
                                 this.showChatRecipientName = true
                                 this.sidenav.close()}
  }

  onPersonalParagraphsDesktop(){

    this.isLoading = true 

    this.isPersonal = true;
    this.isPublic = false ;
    this.isShared = false ; 
    this.isPreferred = false;

    this.isOpenIndexMobile = false; 

    setTimeout(()=>{
      this.isLoading = false 
    },100)
  }



  onPublicParagraphsDesktop(){

    this.isLoading = true 

    this.isPersonal = false;
    this.isPublic = true ;
    this.isShared = false ; 
    this.isPreferred = false

    this.isOpenIndexMobile = false; 

    setTimeout(()=>{
      this.isLoading = false 
    },100)
  }

 

  onSharedParagraphsDesktop(){
    this.isLoading = true 

    this.isPersonal = false;
    this.isPublic = false ;
    this.isShared = true ; 
    this.isPreferred = false

    this.isOpenIndexMobile = false; 

    setTimeout(()=>{
      this.isLoading = false 
    },100)
  }

  onPreferredParagraphsDesktop(){
    this.isLoading = true 

    this.isPersonal = false;
    this.isPublic = false ;
    this.isShared = false ; 
    this.isPreferred = true;

    this.isOpenIndexMobile = false; 

    setTimeout(()=>{
      this.isLoading = false 
    },100)
  }

  onPersonalParagraphsMobile(){
    this.isLoading = true 

    this.isPersonal = true;
    this.isPublic = false ;
    this.isShared = false ; 
    this.isPreferred = false;

    this.isOpenIndexDesktop = true; 

    setTimeout(()=>{
      this.isLoading = false 
    },100)
  }



  onPublicParagraphsMobile(){
    this.isLoading = true 

    this.isPersonal = false;
    this.isPublic = true ;
    this.isShared = false ; 
    this.isPreferred = false;

    this.isOpenIndexDesktop = true; 

    setTimeout(()=>{
      this.isLoading = false 
    },100)
  }

 

  onSharedParagraphsMobile(){
    this.isLoading = true 

    this.isPersonal = false;
    this.isPublic = false ;
    this.isShared = true ; 
    this.isPreferred = false;

    this.isOpenIndexDesktop = true; 

    setTimeout(()=>{
      this.isLoading = false 
    },100)
  }

  onPreferredParagraphsMobile(){
    this.isLoading = true 
    
    this.isPersonal = false;
    this.isPublic = false ;
    this.isShared = false ; 
    this.isPreferred = true;

    this.isOpenIndexDesktop = true; 

    setTimeout(()=>{
      this.isLoading = false 
    },100)
  }

/**
 * dropDown the list of current existing recipient index , and possibility to add a new one 
 */
  onCreateNewChatDialog(){
    this.chatDialogService.CreateNewChat({
      
    })
  };

  onOpenNavBarDrawerParagraphIndex(){
    //if(this.weAreInMobileMode === true ){
      console.log('opening navBar drawer')
      this.aParagraphHasToBeChosen = !this.aParagraphHasToBeChosen 
      this.isOpenIndexMobile = !this.isOpenIndexMobile; 
      this.weAreInMobileMode = true
      this.isOpenIndexDesktop = true
      this.showChatRecipientName = false
      this.sidenav.open()
    

  } 

  onShowRecipientDetails(){
   console.log('partecipants details', this.recipientService.getRecipientByChat_id(this.chat._id).recipientList )


  }

  ngOnDestroy():void{
    this.subscriptionToOnlineOffline.unsubscribe()
    this.openSideNavBarFromChatItemClickSubscription.unsubscribe()
    this.chatItemHasBeenDisplayed.unsubscribe()
    this.updateOnUserEmail.unsubscribe()
  }

 

}
