import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-drop-down-select',
  templateUrl: './single-drop-down-select.component.html',
  styleUrls: ['./single-drop-down-select.component.css']
})
export class SingleDropDownSelectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
