import { Component, OnInit , ElementRef, ViewChild, Output , EventEmitter, Input, ChangeDetectorRef, NgZone} from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { DragDropService } from '../../dragdrop.service';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import { Form } from './../../form.model';
import { FormService } from './../../form.service' ;
import { Recipient } from '../form-item/recipient/recipient.model';
import { RecipientService } from '../form-item/recipient/recipient.service';

import { ParagraphFeature } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature.model';
import { RecipientIndex } from 'src/app/forms/form-list/form-edit/recipient-index-list/recipient-index.model';
import { RecipientIndexService } from './recipient-index-list/recipient-index.service';

import { RecipientId } from './recipient-index-list/recipient-id.model';
import { ParagraphService } from '../form-item/paragraph/paragraph.service';
import { HeaderService } from 'src/app/header/header.service';
import { Header } from 'src/app/header/header.model';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription} from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { FormIndexTopic } from '../form-index-topic-list/form-index-topic.model';
import { MatDialog } from '@angular/material/dialog';
import { DialogmanagerService } from 'src/app/services/other-components-services/dialogmanager.service';
import { AddFormDialogueComponent } from './add-form-dialogue/add-form-dialogue.component';
import { DateTime } from 'luxon';
import { ActivatedRoute, Router } from '@angular/router';

/* from here onare the import tobuild the form-result */

import { FormResult } from '../../form-results/form-result.model';
import { Sender } from '../../form-results/form-results-list/form-result-item/sender/sender.model';
import { FormDateAndTime } from 'src/app/date-and-time/form-date-and-time/form-date-and-time.model';

import { ParagraphResult } from '../../form-results/form-results-list/form-result-item/paragraph-results/paragraph-result.model';
import { ParagraphDateAndTime } from 'src/app/date-and-time/paragraph-date-and-time/paragraph-date-and-time.model';

import { FeatureResult } from '../../form-results/form-results-list/form-result-item/paragraph-results/feature-results/feature-result.model';

import { ToggleWordButtonDetailResult } from '../../form-results/form-results-list/form-result-item/paragraph-results/feature-results/toggle-word-button-result/toggle-word-button-detail-result.model';
import { WordInputDetailResult } from '../../form-results/form-results-list/form-result-item/paragraph-results/feature-results/word-input-result/word-input-detail-result.model';
import { WordDetailResult } from '../../form-results/form-results-list/form-result-item/paragraph-results/feature-results/word-result/word-detail-result.model';

import { SingleFeatureDetailResult } from '../../form-results/form-results-list/form-result-item/paragraph-results/feature-results/single-feature-results/single-feature-detail-result.model';

import { SingleToggleWordButtonDetailResult } from '../../form-results/form-results-list/form-result-item/paragraph-results/feature-results/single-feature-results/single-toggle-word-button-result/single-toggle-word-button-detail-result.model';
import { SingleWordInputDetailResult } from '../../form-results/form-results-list/form-result-item/paragraph-results/feature-results/single-feature-results/single-word-input-result/single-word-input-detail-result.model';
import { SingleWordDetailResult } from '../../form-results/form-results-list/form-result-item/paragraph-results/feature-results/single-feature-results/single-word-result/single-word-detail-result.model';

import { FormResultsService } from '../../form-results/form-results.service';
import { Paragraph } from '../form-item/paragraph/paragraph.model';


import { Subject } from 'rxjs';
import { Chat } from 'src/app/chats/chat.model';
import { ChatService } from 'src/app/chats/chat.service';
import { PartecipantGroup } from 'src/app/chats/partecipants.model';

import { PostComponentsHttpRequestService } from '../../../services/post-and-put-services/post-components-http-request.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { RecipientIndexListItem } from './recipient-index-list/recipient-index-list-item.model';
import { RecipientIndexListItemService } from './recipient-index-list/recipient-index-list-item.service';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions} from '@angular/material/tooltip';
import { ParagraphFeatureContainerService } from './form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature-container.service';
import { StoreParagraphItemPlusChildsConfirmationService } from 'src/app/services/get-and-store-components/store-paragraph-item-plus-childs-confirmation.service';
import { RecipientIndexColumn } from './recipient-index-list/recipient-index-column.model';
import { RecipientIndexColumnService } from './recipient-index-list/recipient-index-column.service';
import { ErrorsDialogManagerService } from 'src/app/services/other-components-services/errors-dialog-manager.service';
import { Title } from '@angular/platform-browser';
import { MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { AddFormBottomSheetComponent } from './add-form-bottom-sheet/add-form-bottom-sheet.component';
import { LogoutService } from 'src/app/login/logout.service';
import { IndexType } from '../../index-type.model';
import { StoreParagraphItemsService } from 'src/app/services/get-and-store-components/store-paragraph-items.service';
import { GetComponentsHttpRequestService } from 'src/app/services/gets-services/get-components-http-request.sevice';
import { RecipientFeatureService } from './recipient-index-list/recipient-feature.service';
import { environment } from 'src/environments/environment';
import { DuplicateFeatureParagraphFormService } from 'src/app/services/shared-services/duplicate-feature-paragraph-form.service';
import { DuplicateFeatureParagraphFormComponent } from 'src/app/loading-spinner/duplicate-feature-paragraph-form/duplicate-feature-paragraph-form.component';
import { DuplicateFeatureParagraphFormBottomSheetComponent } from 'src/app/loading-spinner/duplicate-feature-paragraph-form-bottom-sheet/duplicate-feature-paragraph-form-bottom-sheet.component';


export interface DialogData {
  form: Form ;

}

/** Custom options the configure the tooltip's default show/hide delays. */
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 2000,
  hideDelay: 100,
  touchendHideDelay: 1000,
};


@Component({
  selector: 'app-form-edit',
  templateUrl: './form-edit.component.html',
  styleUrls: ['./form-edit.component.css'],
  providers: [{provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}],
})

export class FormEditComponent implements OnInit {

  brother: string = "https://github.com/angular/components/blob/main/LICENSE"

  user: string

   /**
   *  package properties for online / offline detection and mode
   */
    onlineOffline: boolean = navigator.onLine
    // ------------------------------------------------------------
  

  @ViewChild('nameInput', { static: true }) nameInputRef: ElementRef;
  @ViewChild('newParagraphLoadingArea') newParagraphLoadingArea: ElementRef

  recipient : Recipient ;
  secondMailrecipient: Recipient ; 
  
  
  // currentIndex : number ;
  @Input() changeIndex: number ;
  previousId: number ;
  @Input() recipientIndexId : number ;

  @Input() form_id: string ;
  @Input() editMode: boolean; 
  @Input() paramsRecipientIndex_id: string 
  @Input() paragraphIndexType: IndexType
 
  // outputthis id to Paragraph Item component 
   @Output() id: number 
  dropped : boolean ;
 // droppedRecipientIndex: boolean ;
  droppedRecipientAndParagraphs: boolean ;

  // recipientId: RecipientId ;
  // recipientIds: string[];
  recipientIndex: RecipientIndex;
  recipientIndexListItem: RecipientIndexListItem

  updatedRecipientIndexSubscription: Subscription
  @Input() subscribeUpdatedRecipientIndex : Observable<RecipientIndex>


  recipientIndexes: RecipientIndex[];
  paragraphFeatures : ParagraphFeature[] ;
  paragraphFeature : ParagraphFeature ;

  @Input() changingRecipientIndexId: number 

  selectedRecipient_position: number // serve to identify the correct selected index we display 


/**
 * here below we start to define the formResult components 
 */
  formResult : FormResult ;
  formResults: FormResult[];
  formDateAndTime: FormDateAndTime;

 
  isMessageSide: boolean  = true // serve to distinguish recipients type message and email

  temporaryMessageOff: boolean = false 
  temposaryEmailOff: boolean = false 

  sender: Sender;
  isForwardToMail: boolean ;
  isSentToMessage: boolean ;

  paragraph: Paragraph;
  paragraphs: Paragraph[];
  paragraphResult: ParagraphResult;
  paragraphResults: ParagraphResult[];
  sentParagraphResult: ParagraphResult;
  sentParagraphResults: ParagraphResult[];
  receivedParagraphResult: ParagraphResult;
  receivedParagraphResults: ParagraphResult[];

  paragraphDateAndTime: ParagraphDateAndTime;

  featureResult: FeatureResult;
  featureResults: FeatureResult[];

  toggleWordButtonDetailResult: ToggleWordButtonDetailResult;
  toggleWordButtonDetailResults: ToggleWordButtonDetailResult[];

  wordInputDetailResult: WordInputDetailResult;
  wordInputDetailResults: WordInputDetailResult[];

  wordDetailResult: WordDetailResult;
  wordDetailResults: WordDetailResult[];

  singleFeatureDetailResult: SingleFeatureDetailResult;
  singleFeatureDetailResults: SingleFeatureDetailResult[];

  singleToggleWordButtonDetailResult: SingleToggleWordButtonDetailResult;
  singleToggleWordButtonDetailResults: SingleToggleWordButtonDetailResult[];

  singleWordInputDetailResult: SingleWordInputDetailResult;
  singleWordInputDetailResults: SingleWordInputDetailResult[];

  singleWordDetailResult: SingleWordDetailResult;
  singleWordDetailResults: SingleWordDetailResult[];

  /**
   * this list will serve to reorder the sequence of paragraphResults 
   * dictated by the drag&drops ... it will change at every event , including when the 
   * paragraph get deleted 
   */
  reorderParagraphList: number[];
  paragraphCount: number = 0 ;
  
  paragraphResultsOrder: number [];
  paragraphSplicedValue: number ;
  /**
   *  will finalize the paragraphResult with the correct lineup  with new 
   *  finalParagraphResult
   */
  finalSentParagraphResult: ParagraphResult;
  finalSentParagraphResults: ParagraphResult[] ;

  finalReceivedParagraphResult: ParagraphResult;
  finalReceivedParagraphResults: ParagraphResult[];

  
  finalSentFormResultForMessage: FormResult 
  finalSentFormResultForMail: FormResult

//  paragraphDescription: string ; 

  findParagIndex: number

  isFixedSubject: boolean 
 


  @Output() name: string ;

  // needed is to cencel the buttons edit etc etc on the feature items ...
  @Output() needed = false ;

  form : Form ;
  isFormItem = false ;

  count: number = 0;

  /** new counter for paragraphFeaturess*/
  countParagraphFeatures: number ;

/** to decide what to show */
  youAreInFormEdit = true;

  /** to send information of paragraph id when editing */
  paragraphIndexId : string ;

  /** to tell header we are not in paragraph-edit */
  weGoingToComponentIndex = false ;

  isMobile= true ;

  /** to evidentiate ONLY DROP PARAGRAPHS when somebody throw the wrong item  */
  onlyParagraph: boolean = true ; 

  /** onlyRecipient */
  onlyRecipient: boolean = true ;

  showBottomButtons : boolean ; 

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  formIndexTopic: FormIndexTopic ;
  formIndexTopics: FormIndexTopic[];

  timeNY : any ;
  timeHere: any ;

  dt = DateTime.now() ;
  dateTime: DateTime;

  date: string;
  timeAtSendForm: string ;

  /**
   * here we start to declare the result (used onSend())
   */
 //  subscribeClickEvent: Subject<void> = new Subject<void>();
   subscribeRecipientIndex: Subject<void> = new Subject<void>();

   chat: Chat;
   chats: Chat[];
   senderChatPartecipants : string[];
   receiverChatPartecipants: string[];

   finalIsVisible: boolean

 

   index: number ;
   findParagraphIndex : number ;

   /**
    * possible chat numbers 
    */
   futureFormResultChat_roomid : string 
  
   currentChat_id: string
   chatExist : boolean 
   chatExistOnIndex: number 
   chatHasToBeCreated: boolean

   finalSubject: string;
   finalSubjectIsPresent: boolean 
   subjectComponentOffsetTop: number 

   paragraphComponentOffsetTop: number 
   singleParagraphRemovedLocation: number 


   listOfDescriptions: string[]
 




   /**
    *  email composer
    */
     mailTextFinalComposition: string 
     mailTextReceiverTo: string ;
     mailTextReceiverCc: string ;
     mailTextReceiverBcc: string ;
     mailTextRecipientGreetings: string;
     mailTextSubject: string ;
     mailTextBody: string[] ; 
     mailTextBodyPart: string;
 //    almostFinalMailTextBody: string[];
     finalMailTextBody: string ;
    


     paragraphTextCounter: number;

  //  updatedRecipientIndex: Subject<RecipientIndex> = new Subject<RecipientIndex>();

    /**
     * close sideNav if we are in mobile mobe 
     */
  @Output() closeSideNavOnCompleteFormEditFunctions = new EventEmitter()

  /**
   * close and reopen formEdit for few moments to restart and delete subscriptions
   */
   @Output() closeSideNavContentOnSendingFormResult = new EventEmitter()

   @Output() onScrollToSubjectOnParentComponent = new EventEmitter<number>()
   @Output() onScrollToTopOnParentComponent = new EventEmitter()
   @Output() onScrollToBottomOnParentComponent = new EventEmitter<number>()
   @Output() onScrollToRemovedParagraph = new EventEmitter<number>()

   @Output() onOpenSideNavFromFormEdit = new EventEmitter<void>()


   /**
    * replacement for updated formIndexTopic / formFeature date and time + subject 
    */
     updatedFormFeatureSubjectName : string
     updatedFormFeatureDateAndTime : string
     actualFormIndexTopic : number 
     updatedSubject: string

/**
 * latestForm is the current state of the form that we are editing , serve in case we change page
 * in this case when we edit a paragraph , and once edited we are able to come back to the 
 * current state where we left 
 * 
 * we also have to do the same with the weAreINEditForm / weAreInCreateForm state .
 */
latestFormSubscription: Subscription 
// latestForm: Form 
toRemeberForm: Form

isAnnouncingIsEditingAFormWithLoadingSpinner: boolean
recipientIndexListItemExist: boolean 


/**
 * adding paragraphFeature from paragraphTopicIndex to paragraphFeatureList
 */

isLoadingANewParagraphFeature: boolean
isLoading: boolean 
isSendingMessageEmail: boolean 
/**
 * adding all paragraphFeatues to be ready to edit a form
 */
nothingToAddOfParagraphFeatureWithChildsOfThisType: Subscription 
// sequenceOfStoredParagraphFeatures: string[]


/**
 * to hide subject and recipients in case of navigation mode only
 */

noNeedRecipientAndSubject: boolean = false 

startLoadingParagraphSubscriptionFromParagraphFeatureContainer: Subscription 
updateOnceParagraphChildsHaveBeenStored: Subscription 
updateParagraphsWithNullUserparagraph: Subscription

isLoadingReceiverResult: boolean = false 

updateOnUserEmail: Subscription 

isMessageOnly: boolean 
isEmailOnly: boolean 
isEmailMessage: boolean 
messageRecipientIsTemporaryHidden: boolean = false 
mailRecipientIsTemporaryHidden: boolean = false 

isLoadingSubRecipientItems: boolean = false 

startLoadRecipientsNumber1 = new Subject<void>()
startLoadRecipientsNumber2 = new Subject<void>()
updateSharedRecipientListItem_fromRecipientFeature = new Subject<RecipientIndexListItem>()

isPersonalRecipientIndexListItem: boolean // serve to choose type of sorting of the recipient list 

updatingRecipientIndexListItemsFromFeatureIndexUpdate: Subscription 
onContinueInitializeCreateFormNullUserSubscription: Subscription 
paragraphWithChildsIsNotAvailableAnymore: Subscription 

resetRecipientIndexColumnSharedRecipients = new Subject<void>()

isProduction: boolean 






 constructor( private DragDrop: DragDropService ,
              private datePipe:DatePipe,
              private formService: FormService,
              private recipientService: RecipientService,
              private recipientIndexService: RecipientIndexService,
              private recipientIndexColumnService: RecipientIndexColumnService , 
              private headerService: HeaderService,
              private breakpointObserver: BreakpointObserver,
              private dialog: MatDialog,
              private router: Router,
              private route: ActivatedRoute ,
              private formResultsService: FormResultsService,
              private paragraphService: ParagraphService ,
              private userObjectUpdateService: UserObjectUpdateService ,
              private paragraphFeatureContainerService: ParagraphFeatureContainerService , 
              private recipientIndexListItemService: RecipientIndexListItemService,
              private dms: DialogmanagerService ,
              private storeParagraphItemPlusChildsConfirmationService : StoreParagraphItemPlusChildsConfirmationService , 
              private postComponentsHttpRequestService: PostComponentsHttpRequestService , 
              private errorsDialogManagerService: ErrorsDialogManagerService , 
              private titleService: Title ,
              private cdr: ChangeDetectorRef ,
              private ngZone: NgZone,
              private _bottomSheet: MatBottomSheet , 
              private logoutService: LogoutService , 
              private storeParagraphItemsService: StoreParagraphItemsService ,
              private getComponentsHttpRequestService: GetComponentsHttpRequestService , 
              private recipientFeatureService: RecipientFeatureService,
              private location: Location,
              private duplicateFeatureParagraphFormService: DuplicateFeatureParagraphFormService
            ) { 
              this.isProduction = environment.production
            }

 ngOnInit() { 

  this.user = this.userObjectUpdateService.userEmail

  this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
        .subscribe((updatedUserEmail)=>{
         this.user = updatedUserEmail
        console.log('updated USER = ', this.user)
    })

  this.isLoading = true
  this.isSendingMessageEmail = false 

  if (this.user === null ){ this.messageRecipientIsTemporaryHidden = true }

  console.log( 'is edit mode : ', this.editMode)

  this.updatingRecipientIndexListItemsFromFeatureIndexUpdate = this.recipientIndexListItemService.updateWorkingSharedRecipientIndexListItem
      .subscribe(()=>{
        console.log('LOADING UPDATED RECIPIENT INDEX LIST ITEMS')
        this.ngZone.run(() => {
          if ( this.recipientIndexListItem !== undefined ){
            this.recipientIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItemBy_id(this.recipientIndexListItem._id)
            this.updateSharedRecipientListItem_fromRecipientFeature.next(this.recipientIndexListItem)
          }
        });
        this.cdr.detectChanges();
      })

  console.log(this.editMode)
  if ( this.editMode === true ) { this.titleService.setTitle( "edit saas") ,  this.continueAsWeAreInEditingMode() }
  if ( this.editMode === false ) {  this.titleService.setTitle( "create saas"), this.continueAsWeAreInCreateMode() }

  // this.sequenceOfStoredParagraphFeatures = []

  if (this.youAreInFormEdit === true){
    this.updatedRecipientIndexSubscription = this.subscribeUpdatedRecipientIndex
                                           .subscribe((dataOfRecipientIndexAndRecipientIndexListItem: any)=> 
                                           {this.onUpdateRecipientIndex(dataOfRecipientIndexAndRecipientIndexListItem)} )
  }
  
  // transform paragraph to paragraphFeature 
  this.isLoadingANewParagraphFeature = false

  this.startLoadingParagraphSubscriptionFromParagraphFeatureContainer = this.paragraphFeatureContainerService.receiveSubjectFromParagraphFeatureContainerToStarLoadingNewParagraph()
      .subscribe(()=> { this.isLoadingANewParagraphFeature = true  })

  this.updateOnceParagraphChildsHaveBeenStored = this.storeParagraphItemPlusChildsConfirmationService.paragraphItemToAddToFormEdit
      .subscribe((paragraphToAdd_id : string )=> {  

        const paragraphToAdd =  this.paragraphService.getParagraphBy_id(paragraphToAdd_id )
        this.addParagraphToList(paragraphToAdd) , console.log('adding this paragraph and transforming to paragraphFeature = ', paragraphToAdd)}
  )

  this.updateParagraphsWithNullUserparagraph = this.formService.$paragraphOfNullUserToAdd
      .subscribe((paragraph: Paragraph)=>{
      this.addParagraphToList(paragraph)
  })

  this.onContinueInitializeCreateFormNullUserSubscription = this.logoutService.continueCreateFormInitializationWithRecipientIndex1
  .subscribe(()=>{
    this.continueInitializeNullUserCreateForm()
  })

  this.paragraphWithChildsIsNotAvailableAnymore = this.storeParagraphItemsService.adviseParagraphWithChilds_NotAvailableAnymore
      .subscribe(()=>{
        this.isLoadingANewParagraphFeature = false
      })
  
 

 };



 goToLink(){
  window.open(this.brother , "_blank" )
 }

continueAsWeAreInEditingMode(){

  this.isAnnouncingIsEditingAFormWithLoadingSpinner = true 
  setTimeout(()=> {
    this.isAnnouncingIsEditingAFormWithLoadingSpinner = false
  }, 3000 )

  this.latestFormSubscription = this.formService.changingForm.subscribe( receiveForm => {
    this.form = receiveForm
    console.log(this.form)
  })

  this.isFixedSubject = this.form.isFixedSubject

        // this serve in case someone just happen to reopen the pc on edit mode with close laptop and ther is no latest
       // form in there anymore , so is re-sent to create form

let mismatch: boolean  
if ( this.containsNumber(this.form._id) && !this.user ){ mismatch = true }
if ( !this.containsNumber(this.form._id) && this.user ){ mismatch = true }
if ( this.containsNumber(this.form._id) && this.user ){ mismatch = false }
if ( !this.containsNumber(this.form._id) && !this.user ){ mismatch = false }

console.log( 'is MISMATCH => ', mismatch)

if (this.form._id === null || mismatch === true ){ this.router.navigate(['/create-form' ], {relativeTo: this.route.parent }) }

if (this.form._id !== null && mismatch !== true ){

   this.form = this.formService.getFormBy_id(this.form._id)
   console.log(this.form)
   this.finalSubject = this.form.formSubject
   if (this.finalSubject.length > 0 ){this.finalSubjectIsPresent = true }
   if (this.finalSubject.length === 0 ){this.finalSubjectIsPresent = false }
   const newTitle: string = "edit: "+this.finalSubject
   this.titleService.setTitle( newTitle ) // setting new title on TAB 
   this.mailTextSubject = encodeURIComponent(this.finalSubject)

   let recipientIndex_id: string = this.form.recipientIndex_id
   if (recipientIndex_id === null || recipientIndex_id === undefined  ){
       recipientIndex_id = this.recipientIndexService.getRecipientIndexBy_UIID("recipientIndex-1")._id
   }
   console.log(recipientIndex_id)

   this.recipientIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItems().find( obj=> obj.recipientIndexList_ids.includes(recipientIndex_id)) 


  console.log( 'RECIPIENT INDEX LIST ITEM = ', this.recipientIndexListItem )

  if ( this.recipientIndexListItem !== undefined ){
    this.recipientIndexListItemExist = true 
    this.recipientIndex = this.recipientIndexService.getRecipientIndexBy_id(recipientIndex_id);
    
    // if (Array.isArray(this.recipientIndex.recipient_id)) {
    //   if ( this.recipientIndex.recipient_id.length > 1 ){
    //     console.log("IS ARRAY OF RECIPIENT_ID")
    //     const recipientList: any = this.recipientIndex.recipient_id 
    //   const stringList: string[] = recipientList
    //   const indexOfrecipient =  this.getIndexWithLowestNumber(stringList)
    //   const recipient_id = stringList[indexOfrecipient]
    //   this.recipient = this.recipientService.getRecipientBy_id(recipient_id);
    //   }
    // }

    // if ( !Array.isArray(this.recipientIndex.recipient_id)){
    //   this.recipient = this.recipientService.getRecipientBy_id(this.recipientIndex.recipient_id);
    // }
    this.recipient = this.recipientService.getRecipientBy_id(this.recipientIndex.recipient_id);
   
    if ( this.recipientIndex.useMyOwnMailRecipient === false ){
      this.secondMailrecipient = this.recipientService.getRecipientBy_id(this.recipientIndex.mailRecipient_idCopy)
    }
  }
  if ( this.recipientIndexListItem === undefined ){
    /**
     * here send a notification that is not anymore in this group 
     * plus let user choose new recipientIndex 
     */
    this.recipientIndexListItemExist = false 
    // we don't need recipientIndex and recipient in this case , a select a group of recipients will show up 
  }




  this.reorderParagraphList = [];
  /**
   * for each form.paragraph_ids we have to look for its corrispondent paragraphFeature 
   */
  this.paragraphFeatures = [] 

  for ( let i=0; i<this.form.paragraph_ids.length ; i++){

    const newParagraph: Paragraph = this.paragraphService.getParagraphBy_id(this.form.paragraph_ids[i])
  
    const newParagraphFeature_id = i+1
    const newOwner = newParagraph.owner
    const newDateAndTime = newParagraph.dateAndTime
    const newIsAccepted = true ;
    const newAllUsersAllowed = newParagraph.allUsersAllowed
    const newUserListAllowed = newParagraph.userListAllowed
    let isBeenShared: boolean 
    if ( newAllUsersAllowed === true || newUserListAllowed.length > 0 ){ isBeenShared = true }
    if ( newAllUsersAllowed === false && newUserListAllowed.length === 0 ){ isBeenShared = false }
    const newIsBeenShared = isBeenShared 
    const newCanBeReshared = newParagraph.canBeResharedByOthers 
    const newType = 'paragraphFeature'
    const newCounterId = i ;
    const newParagraphFeatureDropId = i ;
    const newParagraph_id = this.form.paragraph_ids[i];
    const newnewParagraphFeature = new ParagraphFeature ( newParagraphFeature_id, newOwner, newDateAndTime, 
                                                       newIsAccepted, newAllUsersAllowed, newUserListAllowed, 
                                                       newIsBeenShared , newCanBeReshared, newType,  newCounterId, 
                                                       newParagraphFeatureDropId , newParagraph_id )

    this.reorderParagraphList.push(i)
    this.paragraphFeatures.push(newnewParagraphFeature)
  }
 
  this.paragraphCount = this.paragraphFeatures.length ;
  for ( let i=0; i<this.paragraphFeatures.length; i++){
    this.reorderParagraphList.push(i)
  }

 
  this.dropped = false ;
  this.droppedRecipientAndParagraphs = false ;
  this.recreateInitialDummyResults()

  this.finalizeLastData()

  // setTimeout(()=>{
  //   this.isLoading = false 
  // },300)

}

};

getIndexWithLowestNumber(strings: string[]): number {
  let lowestNumber = Infinity;
  let lowestNumberIndex = -1;

  Array.from(strings).forEach((str, index) => {
    const number = parseInt(str.match(/\d+$/)[0], 10);
    if (number < lowestNumber) {
      lowestNumber = number;
      lowestNumberIndex = index;
    }
  });

  return lowestNumberIndex;
}


containsNumber(input: string): boolean {
  // Check if the input is a valid number
  if (!isNaN(Number(input))) {
    return false; // If it's a valid number, return false
  }

  // Check if the input contains any digits
  return /\d/.test(input);
}


continueAsWeAreInCreateMode(){

  this.isLoading = true

  this.isAnnouncingIsEditingAFormWithLoadingSpinner = false

  const startingRecipientIndexColumn: RecipientIndexColumn = this.recipientIndexColumnService.getRecipientIndexColumnBy_type('personal')
  const startingRecipientIndexListItem: RecipientIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItemBy_id(startingRecipientIndexColumn.recipientIndexListItem_ids[0] )
  const startingRecipientIndex_id: string = startingRecipientIndexListItem.recipientIndexSelected_id
  const startingEditForm: Form = new Form ( null , 'form', null, '', '','',  false, [], false, false, false, true , false,  '' , startingRecipientIndex_id , [] )
  
  this.form = startingEditForm 

  if ( this.paramsRecipientIndex_id === 'oooooooo'){
       this.recipientIndex = this.recipientIndexService.getRecipientIndexBy_id( startingEditForm.recipientIndex_id)
       console.log(this.recipientIndex)
       this.continueAsWeAreInCreateMode2()

  }
  if ( this.paramsRecipientIndex_id !== 'oooooooo'){
       if ( this.user === null ){ 
            const newRecipientIndex_id = 'recipientIn-'+this.paramsRecipientIndex_id 
            console.log(newRecipientIndex_id)
            if ( this.recipientIndexService.getRecipientIndexes().findIndex(x=> x._id === newRecipientIndex_id ) !== -1){
                 this.recipientIndex = this.recipientIndexService.getRecipientIndexBy_id( newRecipientIndex_id)
                 console.log(this.recipientIndex)
                 this.continueAsWeAreInCreateMode2()
            }
            if ( this.recipientIndexService.getRecipientIndexes().findIndex(x=> x._id === newRecipientIndex_id ) === -1){
                 console.log( 'ASK TO LOGIN , THROW LOGIN POP UP')
                
                 this.errorsDialogManagerService.LoginPopUpDialog()
            }
        }
       if ( this.user !== null ){ 
            const newRecipientIndex_id = 'recipientIndex-'+this.paramsRecipientIndex_id 
            console.log(newRecipientIndex_id)
            if ( this.recipientIndexService.getRecipientIndexes().findIndex(x=> x._id === newRecipientIndex_id ) !== -1){
                 this.recipientIndex = this.recipientIndexService.getRecipientIndexBy_id( newRecipientIndex_id)
                 console.log(this.recipientIndex)
                 /**
                  * here to divide if recipient exist or doesn't exist 
                  */
                 if (this.recipientService.getRecipients().findIndex(x=> x._id === this.recipientIndex.recipient_id) !== -1 ){
                  this.continueAsWeAreInCreateMode2()
                 }
                 if (this.recipientService.getRecipients().findIndex(x=> x._id === this.recipientIndex.recipient_id) === -1){
                     console.log('the recipient doesn t exist anymore ,user is not in this group anymore')
                     this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'THIS GROUP IS NOT AVAILABLE ANYMORE'})
                     /**
                      * restart from our own recipientIndex 
                      */
                     const startingRecipientIndexColumn: RecipientIndexColumn = this.recipientIndexColumnService.getRecipientIndexColumnBy_type('personal')
                     const startingRecipientIndexListItem: RecipientIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItemBy_id(startingRecipientIndexColumn.recipientIndexListItem_ids[0] )
                     const startingRecipientIndex_id: string = startingRecipientIndexListItem.recipientIndexSelected_id
                     const startingEditForm: Form = new Form ( null , 'formItem', null, '', '','',  false, [], false, false, false, true , false,  '' , startingRecipientIndex_id , [] )
                     // setting the form according to how the form was 
                     this.recipientIndex = this.recipientIndexService.getRecipientIndexBy_id(this.form.recipientIndex_id)
                     this.form = startingEditForm 
                     this.continueAsWeAreInCreateMode2()
                 }
                
            }
            if ( this.recipientIndexService.getRecipientIndexes().findIndex(x=> x._id === newRecipientIndex_id ) === -1){
                 console.log( 'ERROR GO BACK , BUT THIS SHOULDN T HAPPEN')
                 this.router.navigate(['../create-form' ], {relativeTo: this.route.parent })
                 this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'AN ERROR HAS OCCURRED'})
                 /**
                  * recreate
                  */
                 const startingRecipientIndexColumn: RecipientIndexColumn = this.recipientIndexColumnService.getRecipientIndexColumnBy_type('personal')
                 const startingRecipientIndexListItem: RecipientIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItemBy_id(startingRecipientIndexColumn.recipientIndexListItem_ids[0] )
                 const startingRecipientIndex_id: string = startingRecipientIndexListItem.recipientIndexSelected_id
                 const startingEditForm: Form = new Form ( null , 'formItem', null, '', '','',  false, [], false, false, false, true , false,  '' , startingRecipientIndex_id , [] )
                 // setting the form according to how the form was 
                 this.recipientIndex = this.recipientIndexService.getRecipientIndexBy_id(this.form.recipientIndex_id)
                 this.form = startingEditForm 
                 
                 this.continueAsWeAreInCreateMode2()

            }
       }
      
  }

}

continueInitializeNullUserCreateForm(){

  this.router.navigate(['../create-form' ], {relativeTo: this.route.parent })
  const startingRecipientIndexColumn: RecipientIndexColumn = this.recipientIndexColumnService.getRecipientIndexColumnBy_type('personal')
                 const startingRecipientIndexListItem: RecipientIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItemBy_id(startingRecipientIndexColumn.recipientIndexListItem_ids[0] )
                 const startingRecipientIndex_id: string = startingRecipientIndexListItem.recipientIndexSelected_id
                 const startingEditForm: Form = new Form ( null , 'formItem', null, '', '','',  false, [], false, false, false, true , false,  '' , startingRecipientIndex_id , [] )
                 // setting the form according to how the form was 
                 this.recipientIndex = this.recipientIndexService.getRecipientIndexBy_id(this.form.recipientIndex_id)
                 this.form = startingEditForm 
                 
                 this.continueAsWeAreInCreateMode2()
}


continueAsWeAreInCreateMode2(){

   this.isFixedSubject = false 

   this.recipientIndexListItemExist = true 
   /**
    * find the recipientIndex_id inside of one of the recipientIndexListItems . recipientIndexList_ids 
    */
   this.recipientIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItems().find( obj=> obj.recipientIndexList_ids.includes(this.recipientIndex._id)) 

   console.log(this.recipientIndexListItem)
   this.define_type_of_recipientIndexListItem()


   this.finalSubject = this.form.formSubject
   if (this.finalSubject.length > 0 ){this.finalSubjectIsPresent = true }
   if (this.finalSubject.length === 0 ){this.finalSubjectIsPresent = false }
   this.mailTextSubject = encodeURIComponent(this.finalSubject)
   this.reorderParagraphList = []
   const newParagraphFeatures: ParagraphFeature[] = []

   for ( let i=0; i<this.form.paragraph_ids.length; i++ ){
    
    const newParagraph: Paragraph = this.paragraphService.getParagraphBy_id(this.form.paragraph_ids[i])
 
    const newParagraphFeature_id = i+1
    const newOwner = newParagraph.owner
    const newDateAndTime = newParagraph.dateAndTime
    const newIsAccepted = true ;
    const newAllUsersAllowed = newParagraph.allUsersAllowed
    const newUserListAllowed = newParagraph.userListAllowed
    let isBeenShared: boolean 
    if ( newAllUsersAllowed === true || newUserListAllowed.length > 0 ){ isBeenShared = true }
    if ( newAllUsersAllowed === false && newUserListAllowed.length === 0 ){ isBeenShared = false }
    const newIsBeenShared = isBeenShared
    const newCanBeReshared = newParagraph.canBeResharedByOthers
    const newType = 'paragraphFeature'
    const newCounterId = i ;
    const newParagraphFeatureDropId = i ;
    const newParagraph_id = this.form.paragraph_ids[i];
    const newParagraphFeature2 = new ParagraphFeature ( newParagraphFeature_id, newOwner, newDateAndTime, 
                                                       newIsAccepted, newAllUsersAllowed, newUserListAllowed, 
                                                       newIsBeenShared , newCanBeReshared, newType,  newCounterId, 
                                                       newParagraphFeatureDropId , newParagraph_id )
    this.reorderParagraphList.push(i)
    newParagraphFeatures.push(newParagraphFeature2)
    
  }
   this.paragraphFeatures = newParagraphFeatures
   this.paragraphCount = this.paragraphFeatures.length
   if(this.paragraphFeatures.length > 0   ){ this.dropped = false }
   if(this.paragraphFeatures.length === 0 ){ this.dropped = true }

   if(this.paragraphFeatures.length > 0   ){ this.droppedRecipientAndParagraphs = true }
   if(this.paragraphFeatures.length === 0 ){ this.droppedRecipientAndParagraphs = false }
  

   if ( this.recipientService.getRecipients().findIndex(x=> x._id === this.recipientIndex.recipient_id) !== -1){
        this.recipient = this.recipientService.getRecipientBy_id(this.recipientIndex.recipient_id )
        console.log(this.recipient);
        if ( this.recipientIndex.useMyOwnMailRecipient === false ){
        this.secondMailrecipient = this.recipientService.getRecipientBy_id(this.recipientIndex.mailRecipient_idCopy)
        }
        this.recreateInitialDummyResults()
        this.finalizeLastData()

        setTimeout(()=>{
        this.isLoading = false 
        },500)

    } // END IF RECIPIENT IS PRESENT 

    if ( this.recipientService.getRecipients().findIndex(x=> x._id === this.recipientIndex.recipient_id) !== -1){
         console.log('NOT IN THIS GROUP ANYMORE, THROW COMMUNICATION AND ROUTE AGAIN')
    }

  
};


finalizeLastData(){

this.youAreInFormEdit = true;
// this.recipientResult = this.recipient;
this.defineMailAccountsText()

console.log(this.recipientIndex)

  /**
   * just trying Date and Time
   */
  this.timeNY = DateTime.local().setZone("America/New_York").toISO();
  console.log(this.timeNY)

  console.log(DateTime.local())

  // this.timeHere = DateTime.local().toISO();
  this.timeHere = DateTime.now().toString();
  console.log(this.timeHere);

  
  // this.date = this.dt.toLocaleString(DateTime.DATETIME_MED)
  this.date = this.dt.setLocale('it').toLocaleString()
  console.log(this.dt)
  this.dateTime = DateTime.local()
  console.log(this.dateTime)
  this.dt = DateTime.now();


/**
 * give header initial color to create form in case in here
 */
const newHeaderIndexSelection = new Header (true, false, false, false, false, false, false, false )
this.headerService.nextIndexSelection(newHeaderIndexSelection)

/** telling heade that we are not in paragraph-edit and change the header name accordingly 
 * THIS SHOULD GO TO ALL ngOnInit of all components that show no paragraphEdit
*/
// this.headerService.nextMessage(this.weGoingToComponentIndex);

/**
 * start to declare form Result to initialize
 */
this.formResult = null

console.log(this.formResult)

this.sentParagraphResults = [];
this.receivedParagraphResults = [];
this.finalSentParagraphResults = [];
this.finalReceivedParagraphResults = [];

this.chatHasToBeCreated = false 

this.defineWhatTypeOfMessageIsSending()

setTimeout(()=>{
  this.isLoading = false 
},500)

};

onEmitIfSubjectIsFixed($event){
  this.isFixedSubject = $event
}

onEmitUpdatedRecipientIndexListItem_afterDeleteToMessageEmail($event){
 
  // the recipientFeature has already been updated earlier 
  // just need to find the new recipientIndex and listItem to be in , 
  // to replace the old shared recipient list item with this new one 

  const updatedRecipientIndexListItem: RecipientIndexListItem = $event 

  if ( this.recipientIndexColumnService.getRecipientIndexColumnBy_type('shared').recipientIndexListItem_ids.findIndex(x=> x === updatedRecipientIndexListItem._id ) !== -1 ){
     console.log('WE ARE EXITING A SHARED GROUP')

      this.isLoading = true

      // this.messageRecipientIsTemporaryHidden = true
      // this.mailRecipientIsTemporaryHidden = true 
   
      // updating the new recipinetIndexListItem 
      const indexOfRecipientIndexToReplace = this.recipientIndexListItemService.getRecipientIndexListItems().findIndex(x=> x._id === updatedRecipientIndexListItem._id )
      this.recipientIndexListItemService.recipientIndexListItems[indexOfRecipientIndexToReplace] = updatedRecipientIndexListItem 
      this.recipientIndexListItemService.updateInitialComponents(this.recipientIndexListItemService.recipientIndexListItems)

      if ( updatedRecipientIndexListItem.recipientIndexSelected_id === null  ){
        // at this point there are no more shared recipients and we can ask recipientIndexList column to
        // hide shared groups
          this.recipientIndexColumnService.onHideSharedRecipientIndexColumn()
      }
  
     // find the first of our recipientIndex 
       const personalRecipientIndexColumn = this.recipientIndexColumnService.getRecipientIndexColumnBy_type('personal')
       const personalRecipientIndexListItem_id = personalRecipientIndexColumn.recipientIndexListItem_ids[0]
       const personalrecipientIndex_id = this.recipientIndexListItemService.getRecipientIndexListItemBy_id(personalRecipientIndexListItem_id).recipientIndexSelected_id
   
       const newRecipientIndex = this.recipientIndexService.getRecipientIndexBy_id(personalrecipientIndex_id)

       
       // send the new URL with the exact recipinetIndex_id in case we are in the create mode = !editMode 
        if ( !this.editMode){ 
             
             this.changeUserUrlParams_onCreateForm_Recipient(newRecipientIndex)
        }
         
          this.recipientIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItemBy_id(personalRecipientIndexListItem_id)
          this.recipientIndexColumnService.onResetMomentarelySharedColumn()
          this.onChangeIndex(newRecipientIndex)
          this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: "YOU LEFT THAT GROUP, OR IT'S NOT AVAILABLE ANYMORE "})
    }



   


}

changeUserUrlParams_onCreateForm_Recipient(recipientIndex: RecipientIndex){

  this.recipientIndex = recipientIndex
  const newRecipientIndex_id: string = this.recipientIndex._id ;

  const base = 'recipientIndex-';
  const extractedString = newRecipientIndex_id.split(base)[1];
  console.log(extractedString); 

  const currentUrl = this.location.path();
  console.log(currentUrl)
  const lastIndex = currentUrl.lastIndexOf('/');
  const extractedString2 = currentUrl.substring(lastIndex + 1);

  console.log(extractedString2);

  this.router.navigate([ 'create-form', extractedString, extractedString2 ] ) 


}


/**
 * duplicate paragraph with a pop up window to ask new paragraph name and feature name 
 */
onDuplicateParagraph(i: number ){
  console.log("duplicating paragraph pos.: ", i , 
               this.paragraphService.getParagraphBy_id(this.paragraphFeatures[i].paragraph_id),

  )

  const duplicateParagraph: Paragraph = this.paragraphService.getParagraphBy_id(this.paragraphFeatures[i].paragraph_id)

  if ( !this.isMobileDevice() ){ 
        this.dms.duplicateFeatureParagraphFormComponent({ paragraph: duplicateParagraph })
    }
    
    if ( this.isMobileDevice() ){ 
         this._bottomSheet.open( DuplicateFeatureParagraphFormBottomSheetComponent , {
         data: {
          paragraph : duplicateParagraph
          }
    
      })
      }

}



defineWhatTypeOfMessageIsSending(){

  //  for each of these possibilities add other 4 possibilities for the hiddens 
  /**
   * 4 more possibilities  X 2 because if we are using our own recipient or secondrecipient
   *  hidden message / hidden mail
   *  hidden message only 
   *  hidden mail only
   *  both not hidden 
   */

  /**
   * both not hidden 
   */
  if ( this.recipientIndex.useMyOwnMailRecipient === true ){

    if ( this.recipient.toMessage.length > 0 
      && this.recipient.toMail.length === 0 
      && this.messageRecipientIsTemporaryHidden === false 
      && this.mailRecipientIsTemporaryHidden === false )

 { this.isMessageOnly = true , this.isEmailOnly = false , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length > 0 
   && this.recipient.toMail.length === 0 
   && this.messageRecipientIsTemporaryHidden === true 
   && this.mailRecipientIsTemporaryHidden === false )
   
 { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length > 0 
   && this.recipient.toMail.length === 0 
   && this.messageRecipientIsTemporaryHidden === false 
   && this.mailRecipientIsTemporaryHidden === true )
   
 { this.isMessageOnly = true , this.isEmailOnly = false , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length > 0 
   && this.recipient.toMail.length === 0 
   && this.messageRecipientIsTemporaryHidden === true 
   && this.mailRecipientIsTemporaryHidden === true )
   
 { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = false  }
 
 /**
  * hidden message / hidden mail
  */
 if ( this.recipient.toMessage.length === 0 
      && this.recipient.toMail.length > 0 
      && this.messageRecipientIsTemporaryHidden === false  
      && this.mailRecipientIsTemporaryHidden === false 
      )
    { this.isMessageOnly = false , this.isEmailOnly = true , this.isEmailMessage = false  }
 
 if ( this.recipient.toMessage.length === 0 
   && this.recipient.toMail.length > 0 
   && this.messageRecipientIsTemporaryHidden === true 
   && this.mailRecipientIsTemporaryHidden === false 
   )
 { this.isMessageOnly = false , this.isEmailOnly = true , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length === 0 
   && this.recipient.toMail.length > 0 
   && this.messageRecipientIsTemporaryHidden === false  
   && this.mailRecipientIsTemporaryHidden === true
   )
 { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length === 0 
   && this.recipient.toMail.length > 0 
   && this.messageRecipientIsTemporaryHidden === true
   && this.mailRecipientIsTemporaryHidden === true
   )
 { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = false  }

 /**
  * hidden mail only
  */
 if ( this.recipient.toMessage.length > 0 
      && this.recipient.toMail.length > 0 
      && this.messageRecipientIsTemporaryHidden === false
      && this.mailRecipientIsTemporaryHidden === false 
      )
    { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = true  }
 
 if ( this.recipient.toMessage.length > 0 
   && this.recipient.toMail.length > 0 
   && this.messageRecipientIsTemporaryHidden === true 
   && this.mailRecipientIsTemporaryHidden === false 
   )
 { this.isMessageOnly = false , this.isEmailOnly = true , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length > 0 
   && this.recipient.toMail.length > 0 
   && this.messageRecipientIsTemporaryHidden === false  
   && this.mailRecipientIsTemporaryHidden === true
   )
 { this.isMessageOnly = true , this.isEmailOnly = false , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length > 0 
   && this.recipient.toMail.length > 0 
   && this.messageRecipientIsTemporaryHidden === true 
   && this.mailRecipientIsTemporaryHidden === true
   )
 { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = false  }
 
 /**
  * hidden message only 
  */
 if ( this.recipient.toMessage.length === 0 && this.recipient.toMail.length === 0 
      )
    { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = false  }
 
 /**
  * check if form no need Recipients in case it has to be saved 
  */
 if ( this.messageRecipientIsTemporaryHidden === true 
      && this.mailRecipientIsTemporaryHidden === true ){ this.noNeedRecipientAndSubject = true }
 
 if ( this.messageRecipientIsTemporaryHidden !== true || this.mailRecipientIsTemporaryHidden !== true ){ this.noNeedRecipientAndSubject = false }

// console.log( 'IS MESSAGE ONLY =', this.isMessageOnly , 'IS MAIL ONLY = ', this.isEmailOnly , 'IS BOTH MAIL+MESSAGE = ', this.isEmailMessage )

  }

  if ( this.recipientIndex.useMyOwnMailRecipient === false ){
    
    if ( this.recipient.toMessage.length > 0 
      && this.secondMailrecipient.toMail.length === 0 
      && this.messageRecipientIsTemporaryHidden === false 
      && this.mailRecipientIsTemporaryHidden === false )

 { this.isMessageOnly = true , this.isEmailOnly = false , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length > 0 
   && this.secondMailrecipient.toMail.length === 0 
   && this.messageRecipientIsTemporaryHidden === true 
   && this.mailRecipientIsTemporaryHidden === false )
   
 { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length > 0 
   && this.secondMailrecipient.toMail.length === 0 
   && this.messageRecipientIsTemporaryHidden === false 
   && this.mailRecipientIsTemporaryHidden === true )
   
 { this.isMessageOnly = true , this.isEmailOnly = false , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length > 0 
   && this.secondMailrecipient.toMail.length === 0 
   && this.messageRecipientIsTemporaryHidden === true 
   && this.mailRecipientIsTemporaryHidden === true )
   
 { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = false  }
 
 /**
  * hidden message / hidden mail
  */
 if ( this.recipient.toMessage.length === 0 
      && this.secondMailrecipient.toMail.length > 0 
      && this.messageRecipientIsTemporaryHidden === false  
      && this.mailRecipientIsTemporaryHidden === false 
      )
    { this.isMessageOnly = false , this.isEmailOnly = true , this.isEmailMessage = false  }
 
 if ( this.recipient.toMessage.length === 0 
   && this.secondMailrecipient.toMail.length > 0 
   && this.messageRecipientIsTemporaryHidden === true 
   && this.mailRecipientIsTemporaryHidden === false 
   )
 { this.isMessageOnly = false , this.isEmailOnly = true , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length === 0 
   && this.secondMailrecipient.toMail.length > 0 
   && this.messageRecipientIsTemporaryHidden === false  
   && this.mailRecipientIsTemporaryHidden === true
   )
 { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length === 0 
   && this.secondMailrecipient.toMail.length > 0 
   && this.messageRecipientIsTemporaryHidden === true
   && this.mailRecipientIsTemporaryHidden === true
   )
 { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = false  }

 /**
  * hidden mail only
  */
 if ( this.recipient.toMessage.length > 0 
      && this.secondMailrecipient.toMail.length > 0 
      && this.messageRecipientIsTemporaryHidden === false
      && this.mailRecipientIsTemporaryHidden === false 
      )
    { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = true  }
 
 if ( this.recipient.toMessage.length > 0 
   && this.secondMailrecipient.toMail.length > 0 
   && this.messageRecipientIsTemporaryHidden === true 
   && this.mailRecipientIsTemporaryHidden === false 
   )
 { this.isMessageOnly = false , this.isEmailOnly = true , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length > 0 
   && this.secondMailrecipient.toMail.length > 0 
   && this.messageRecipientIsTemporaryHidden === false  
   && this.mailRecipientIsTemporaryHidden === true
   )
 { this.isMessageOnly = true , this.isEmailOnly = false , this.isEmailMessage = false  }

 if ( this.recipient.toMessage.length > 0 
   && this.secondMailrecipient.toMail.length > 0 
   && this.messageRecipientIsTemporaryHidden === true 
   && this.mailRecipientIsTemporaryHidden === true
   )
 { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = false  }
 
 /**
  * hidden message only 
  */
 if ( this.recipient.toMessage.length === 0 && this.recipient.toMail.length === 0 
      )
    { this.isMessageOnly = false , this.isEmailOnly = false , this.isEmailMessage = false  }
 
 /**
  * check if form no need Recipients in case it has to be saved 
  */
 if ( this.messageRecipientIsTemporaryHidden === true 
      && this.mailRecipientIsTemporaryHidden === true ){ this.noNeedRecipientAndSubject = true }
 
 if ( this.messageRecipientIsTemporaryHidden !== true || this.mailRecipientIsTemporaryHidden !== true ){ this.noNeedRecipientAndSubject = false }

//  console.log( 'IS MESSAGE ONLY =', this.isMessageOnly , 'IS MAIL ONLY = ', this.isEmailOnly , 'IS BOTH MAIL+MESSAGE = ', this.isEmailMessage )

  }

}


recreateInitialDummyResults(){
  /**
 * create initial dummy paragraphResults --------------------------------------------------
 */

/**
 *  RECREATE ! and re-compile all FEATURE RESULTS!  create initial dummy paragraphResults --------------------------------------------------
 */
 this.paragraphResults = []
 const newParagraphResultName = 'prova'
 const newParagraphResult = new ParagraphResult (  newParagraphResultName, [] ) ;

 const newParagraphResults: ParagraphResult[] = []
 const newMailTextBodyPart = ''
 this.mailTextBody = []
 for ( let z=0; z<this.paragraphFeatures.length; z++){
   newParagraphResults.push(newParagraphResult)
   this.mailTextBody.push(newMailTextBodyPart)
 }
 this.paragraphResults = newParagraphResults

 this.finalMailTextBody = null 

 this.formService.sendRequestToFinalizeResultAgain()
/**
*  END RECREATE ! create initial dummy paragraphResults --------------------------------------------------
*/

  // create dummy text 

  this.mailTextBody = [];
  this.mailTextBodyPart = '';
  this.mailTextRecipientGreetings = '';
  this.paragraphTextCounter = 0;

//--------------------------------------------------------------------------------------------
}



 /**
  * need to add properties like 
  * messageRecipientIsTemporaryHidden 
  * mailRecipientIsTemporaryHidden
  */
  onEmitTemporaryHideRecipientsMessage(){
    console.log('HIDE MESSAGE')

    this.messageRecipientIsTemporaryHidden = true 
    this.defineWhatTypeOfMessageIsSending()
  }

  onEmitTemporaryHideRecipientsMail(){
    console.log('HIDE MAIL')
    this.mailRecipientIsTemporaryHidden = true 
    this.defineWhatTypeOfMessageIsSending()
  }

  onRestoreRecipientMessage(){
    this.messageRecipientIsTemporaryHidden = false 
    this.defineWhatTypeOfMessageIsSending()
  }

  onRestoreRecipientMail(){
    this.mailRecipientIsTemporaryHidden = false 
    this.defineWhatTypeOfMessageIsSending()
  }
  

 onUpdateRecipientIndex(dataOfRecipientIndexAndRecipientIndexListItem: any){

  this.noNeedRecipientAndSubject = false 
  this.isLoading = true

  console.log(dataOfRecipientIndexAndRecipientIndexListItem)
  this.recipientIndex = dataOfRecipientIndexAndRecipientIndexListItem.newRecipientIndex
  console.log(this.recipientIndex , dataOfRecipientIndexAndRecipientIndexListItem.newRecipientIndex)

  this.recipientIndexListItemExist = true 
  this.recipientIndexListItem = dataOfRecipientIndexAndRecipientIndexListItem.newRecipientIndexListItem

  // define and then sort 
  this.define_type_of_recipientIndexListItem()

  /**
   * reset recipientIndexListItem.recipientIndex_listIds from small to big when is our recipientIndexListItem
   */

  /**
   * reset recipientIndexListItem.recipientIndex_listIds from latest to before if shared list 
   */


  this.recipient = this.recipientService.getRecipientBy_id(this.recipientIndex.recipient_id)
  // this.recipientIds = this.recipientIndexListItem.recipientIndexList_ids
  // console.log(this.recipient)
  if ( this.recipientIndex.useMyOwnMailRecipient === false ){
    this.secondMailrecipient = this.recipientService.getRecipientBy_id(this.recipientIndex.mailRecipient_idCopy)
  }

  /**
   * for formResult
   */
  // this.recipientResult = this.recipient; aaa 
  this.defineMailAccountsText()

  this.selectedRecipient_position = 0
  console.log(this.recipientIndex)

  this.messageRecipientIsTemporaryHidden = false 
  this.mailRecipientIsTemporaryHidden = false 

  this.defineWhatTypeOfMessageIsSending()

  setTimeout(()=>{
    this.isLoading = false 
  },300)

 }


 onUpdateSubjectTextResult($event){
  console.log($event)
  this.finalSubject = $event
  if (this.finalSubject.length > 0 ){this.finalSubjectIsPresent = true }
  if (this.finalSubject.length === 0 ){this.finalSubjectIsPresent = false }

  this.titleService.setTitle( "create: "+ this.finalSubject)

  console.log(this.finalSubject)

  /**
   * this will serve for final composition 
   */
  this.mailTextSubject = encodeURIComponent(this.finalSubject)
  
};

 onStopParagraphItemLoadingSpinner(){
  console.log('it stopped spinning')
  setTimeout(()=>{
    this.isLoadingANewParagraphFeature = false
  }, 200)
 
 }

 onOpenSideNav(){
  console.log('opening sideNav on mobile')
  this.onOpenSideNavFromFormEdit.emit()
 }


 addParagraphToList(paragraphToAdd: Paragraph){

  // add an object that signal if the paragraph added has 1 or more base components 

  const newParagraphLoadingOffset: any =  this.newParagraphLoadingArea
  console.log(newParagraphLoadingOffset)
  this.paragraphComponentOffsetTop = newParagraphLoadingOffset.nativeElement.offsetTop
  console.log(this.paragraphComponentOffsetTop)

  this.onScrollToBottomOnParentComponent.emit(this.paragraphComponentOffsetTop)

  console.log(paragraphToAdd , "CURENT FORM = ", this.form)

  const newParagraphFeature_id = this.paragraphFeatures.length+1
  const newOwner = paragraphToAdd.owner;
  const newDateAndTime = paragraphToAdd.dateAndTime; 
  const newIsAccepted = true ;
  const newAllUsersAllowed = paragraphToAdd.allUsersAllowed
  const newUserListAllowed = paragraphToAdd.userListAllowed
  const newIsBeenShared = false 
  const newCanBeReshared = paragraphToAdd.canBeResharedByOthers
  const newType =  'paragraphFeature'
  const newCounterId = this.paragraphFeatures.length
  const newParagraphFeatureDropId = this.paragraphFeatures.length
  const newParagraph_id = paragraphToAdd._id
  const newParagraphFeature = new ParagraphFeature ( newParagraphFeature_id, newOwner, newDateAndTime, 
                                                     newIsAccepted, newAllUsersAllowed, newUserListAllowed, 
                                                     newIsBeenShared , newCanBeReshared, newType,  newCounterId, 
                                                     newParagraphFeatureDropId , newParagraph_id )
  this.paragraphFeatures.push(newParagraphFeature)
/**
 * create initial dummy paragraphResults -------------------------------
 */
   const newParagraphResultName = paragraphToAdd.name
   const newParagraphResult = new ParagraphResult (  newParagraphResultName, [] ) ;
   this.paragraphResults.push(newParagraphResult)

   const newMailTextBodyPart = ''
   this.mailTextBody.push(newMailTextBodyPart)
   
//-----------------------------------------------------------------------
  this.dropped = false

  this.count = 0 ;
  for ( let i=0 ; i<this.paragraphFeatures.length ; i++){
         this.paragraphFeatures[i].paragraphFeatureDropId = this.count++
  }
  this.count= 0 ;

    for (let j=0 ; j<this.paragraphFeatures.length ; j++ ){
      this.reorderParagraphList[j] = this.paragraphFeatures[j].counterId ;
    }
    console.log('reorderParagraphList',this.reorderParagraphList)

  if ( this.dropped === false ){
    this.droppedRecipientAndParagraphs = true
  } else {
  this.droppedRecipientAndParagraphs = false
  }


 }


/**
 * 
 * @param event  on drop the ParagraphFeature to the FormEdit 
 */
 onDropParagraphFeature(event: CdkDragDrop<ParagraphFeature[]>) {

  /**
   * i have to make the drop layout also for Paragraph objects list , so that the events 
   * on them correspont to the actual html position !!! and not only for paragraphFeture,
   * because the event happen in the list of paragraphs and not in the list of paragraphFeature !
   */

  if (event.previousContainer === event.container){
    this.DragDrop.dropParagraphFeature(event);
     /** this function a;ways keep the array dropId in sequence */
  this.count = 0 ;
  for ( let i=0 ; i<this.paragraphFeatures.length ; i++){
         this.paragraphFeatures[i].paragraphFeatureDropId = this.count++
  }
  this.count= 0 ;
  /**
   * checking the list of paragraphResult index 
   */
   for (let j=0 ; j<this.paragraphFeatures.length ; j++ ){
    this.reorderParagraphList[j] = this.paragraphFeatures[j].counterId ;
  }
  console.log( 'reorderParagraphList = ',this.reorderParagraphList)

  };

/**
 *  RECREATE ! and re-compile all FEATURE RESULTS!  create initial dummy paragraphResults --------------------------------------------------
 */


 this.paragraphResults = []
 const newParagraphResultName = 'try getRequestToFinalizeResultAgain()'
 const newParagraphResult = new ParagraphResult (  newParagraphResultName, [] ) ;

 const newParagraphResults: ParagraphResult[] = []
 const newMailTextBodyPart = ''
 this.mailTextBody = []
 for ( let z=0; z<this.paragraphFeatures.length; z++){
   newParagraphResults.push(newParagraphResult)
   this.mailTextBody.push(newMailTextBodyPart)
 }
 this.paragraphResults = newParagraphResults

 this.finalMailTextBody = null 

 this.formService.sendRequestToFinalizeResultAgain()
/**
*  END RECREATE ! create initial dummy paragraphResults --------------------------------------------------
*/
    
  
 }

 /**
  * 
  * @param item prevent wrong items to end up dropped in the wrong place
  * @returns 
  */
 paragraphPredicate(item: CdkDragDrop<ParagraphFeature>) {
  return true  ; 
}

onStartLoadingOtherRecipient1(){
  this.startLoadRecipientsNumber2.next()
} 

onStartLoadingOtherRecipient2(){
  this.startLoadRecipientsNumber1.next()
} 



/** change index when there is mat-tab change in recipient-group */
onChangeIndex(event){ 
 
  this.isLoading = true
  this.recipientIndex = event
  console.log(this.recipientIndex)
  /**
   * need to add the logic on if the recipient owner and change the service accordingly 
   */
  this.recipient = this.recipientService.getRecipientBy_id(this.recipientIndex.recipient_id)
  if ( this.recipientIndex.useMyOwnMailRecipient === false ){
    this.secondMailrecipient = this.recipientService.getRecipientBy_id(this.recipientIndex.mailRecipient_idCopy)
  }

  this.defineMailAccountsText()

  // update the recipientIndexListItem with the newly created in case we just created a new recipient otherwise is just refresh
  this.recipientIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItemBy_id(this.recipientIndexListItem._id)
  console.log(this.recipientIndexListItem)
  this.define_type_of_recipientIndexListItem()
  /**
   * find selectedRecipient position 
   */
  for ( let i=0; i< this.recipientIndexListItem.recipientIndexList_ids.length; i++){
    if ( this.recipientIndexListItem.recipientIndexList_ids[i] === this.recipientIndex._id ){this.selectedRecipient_position = i }
  }

  this.messageRecipientIsTemporaryHidden = false 
  if ( this.user === null ){ this.messageRecipientIsTemporaryHidden = true }
  this.mailRecipientIsTemporaryHidden = false 

  this.defineWhatTypeOfMessageIsSending()

  setTimeout(()=>{
    this.isLoading = false
  },200)


}

define_type_of_recipientIndexListItem(){
  /**
   * check what kind of column the recipientIndexListItem is on , to understand what kind of sorting 
   * we have to use 
   */
  const sharedRecipientIndexColumn: RecipientIndexColumn = this.recipientIndexColumnService.getRecipientIndexColumnBy_type('shared')

  if ( sharedRecipientIndexColumn.recipientIndexListItem_ids.findIndex(x=> x === this.recipientIndexListItem._id) === -1 ){ this.isPersonalRecipientIndexListItem = true }
  if ( sharedRecipientIndexColumn.recipientIndexListItem_ids.findIndex(x=> x === this.recipientIndexListItem._id) !== -1 ){ this.isPersonalRecipientIndexListItem = false }

  this.sort_recipients_according_to_typeOfColumn()
}

sort_recipients_according_to_typeOfColumn(){

  if ( this.isPersonalRecipientIndexListItem === true ){
    /**
    *  sort the recipientIndexes array in alphabeticNumeric order 
    */
   console.log(this.recipientIndexListItem)
     this.recipientIndexListItem.recipientIndexList_ids.sort(function(a,b) {
      return a.localeCompare(b, 'en', { numeric: true })
    })
  }

  if ( this.isPersonalRecipientIndexListItem === false ){
    /**
    *  sort the recipientIndexes array in latest to previous dateTime 
    */
    //  this.recipientIndexListItem.recipientIndexList_ids.sort(function(a,b) {
    //   return a.localeCompare(b, 'en', { numeric: true })
    // })
  }
}

onUndefinedRecipient(event){
   this.recipientIndex = event
}

onOpenAddFormDialog(){
  const new_form_id = (this.formService.getForms().length+1).toString() ;
  const newComponentName = 'formItem'
  const newUIID= 'formItem-'+(this.formService.getUserForms(this.user).length+1).toString()
  const newOwner = this.user ;
  const newDateAndTime = null ;
  const newUpdateDateAndTime = null 
  const newAllUsersAllowed = false ;
  const newUserListAllowed = [];
  const newCanBeResharedByOthers = false 
  const newNoNeedecipientAndSubject = this.noNeedRecipientAndSubject
  const newIsFixedSubject = this.isFixedSubject 
  const newIsSharable = true 
  const newIsBeenShared = false ;
  const newformSubject = this.finalSubject
  const newRecipientIndex_id = this.recipientIndex._id
  const newParagraphFeatures = this.paragraphFeatures
  const newParagraph_ids = []
  for ( let i=0; i<newParagraphFeatures.length; i++){
    newParagraph_ids.push(newParagraphFeatures[i].paragraph_id)
  }

 
  const newForm = new Form ( new_form_id,
                             newComponentName,
                             newUIID,
                             newOwner ,
                             newDateAndTime,
                             newUpdateDateAndTime,
                             newAllUsersAllowed,
                             newUserListAllowed,
                             newCanBeResharedByOthers,
                             newNoNeedecipientAndSubject ,
                             newIsFixedSubject, 
                             newIsSharable, 
                             newIsBeenShared,
                             newformSubject,
                             newRecipientIndex_id ,
                             newParagraph_ids
                             )
                            
  
  
  console.log(newForm)

  this.form = newForm

/**
 * if this is mobile open bottom sheet 
 */

if ( !this.isMobileDevice() ){ 
this.dialog.open( AddFormDialogueComponent, {
  data: {
    form : this.form
  }
});
}

if ( this.isMobileDevice() ){ 
     this._bottomSheet.open( AddFormBottomSheetComponent , {
     data: {
      form : this.form
      }

  })
  }
  
}

isMobileDevice(): boolean {
  return window.matchMedia('(max-width: 767px)').matches;
}


 /** remove ParagraphFeature, paragraphResult and mailText from the array  */
onRemoveParagraphFeature(i){

  /**
   * 
   */
  const elementSubject: any = document.getElementsByTagName('app-paragraph-item')
  this.singleParagraphRemovedLocation = elementSubject[i].offsetTop
       console.log( elementSubject[i].offsetTop )
  this.onScrollToRemovedParagraph.emit(this.singleParagraphRemovedLocation)
 

  this.paragraphFeatures.splice(i , 1);
  console.log(this.paragraphFeatures)
  /**
   * re-asset paragraphCount
   */
  this.paragraphCount = this.paragraphCount-1


   /** this function always keep the array dropId in sequence */
   this.count = 0 ;
   for ( let i=0 ; i<this.paragraphFeatures.length ; i++){
          this.paragraphFeatures[i].paragraphFeatureDropId = this.count++
   };
   this.count = 0 ;


   /** this function take away the button to add forms when we remove all paragraphs */
  if ( this.paragraphFeatures.length > 0 ){
    this.dropped = false
  } else {
    this.dropped = true
  }

  console.log(this.paragraphFeatures)
  if ( this.dropped === false ){
    this.droppedRecipientAndParagraphs = true
  } else {
  this.droppedRecipientAndParagraphs = false
  }

/**
 *  RECREATE ! and re-compile all FEATURE RESULTS!  create initial dummy paragraphResults --------------------------------------------------
 */

this.paragraphResults.splice(i, 1)
this.mailTextBody.splice(i,1)

  for (let j=0; j<this.mailTextBody.length; j++){
    if (j=== 0){ this.finalMailTextBody = this.mailTextBody[j]}
    if (j!== 0){ this.finalMailTextBody = this.finalMailTextBody + this.mailTextBody[j]}
  }

console.log(this.paragraphResults,this.mailTextBody, this.finalMailTextBody)


/**
 *  END RECREATE ! create initial dummy paragraphResults --------------------------------------------------
 */

}

onEditParagraph(i){
  const newHeaderIndexSelection = new Header (false, true, false, false, false, false, false, false )
  this.headerService.nextIndexSelection(newHeaderIndexSelection)


 this.paragraphIndexId = this.paragraphFeatures[i].paragraph_id;
 console.log('i=', i);
 
 /**
  * sending latest form to formService , so that we maintain the form structure once back from paragraphEdit 
  * we also have to advise which type of form ( edit form or create form )
  */
  const newParagraph_ids = []
  for ( let i=0; i<this.paragraphFeatures.length; i++){
    newParagraph_ids.push(this.paragraphFeatures[i].paragraph_id)
  }



 this.router.navigate(['../edit-paragraph', this.paragraphFeatures[i].paragraph_id ], {relativeTo: this.route.parent})

};

 
 /** to clear the form and start again  */
 onClearForm(){


   this.paragraphFeatures = [];
   this.dropped = true ; 
   this.droppedRecipientAndParagraphs = false
   this.count = 0 ;
   this.paragraphCount = 0;
   this.paragraphResults = [];
   this.finalSentParagraphResults = [];
   this.finalReceivedParagraphResults = [];
   this.sentParagraphResults = [];
   this.receivedParagraphResults = [];
   this.reorderParagraphList = [];

   this.onScrollToTop()

 }



 /**
  * isMessageOnly: boolean 
    isEmailOnly: boolean 
    isEmailMessage: boolean 
  */
 
 onSendForm(){

  this.droppedRecipientAndParagraphs = false 

  if ( this.user !== null ){ 
      if ( this.isMessageOnly || this.isEmailMessage  ){ this.onCheckIfRecipientIsStillValid() } // if send by email only no need to check 
      if ( this.isEmailOnly ){ this.onContinueSendingForm() } // if send by message also , need to double check on users 
  }

  if ( this.user === null ){ this.onContinueSendingForm()
  }
 }

  /**
   * whether is our recipient or not , always check if the recipient message emails are still the same 
   * if it's only send by email no need to check 
   */
 onCheckIfRecipientIsStillValid(){
  /**
   * if it's not my own recipient i check on recipientFeature recipients 
   */
  if ( this.recipientIndex.useMyOwnMailRecipient ){ this.onCheckingMyOwnUpdatedRecipient()}
  /**
   * if it's my own recipient i double check on my my own recipient
   */
  if ( !this.recipientIndex.useMyOwnMailRecipient){ this.onCheckingSharedRecipient_onRecipientFeatureChilds() }
 }

 /**
  * recall my own recipient to update the recipient message list , in case someone ecxcluded 
  * himself from the list 
  */
 onCheckingMyOwnUpdatedRecipient(){

  this.getComponentsHttpRequestService.httpGetUserComponentBy_id(this.user , this.recipient._id)
      .subscribe((res: Recipient )=>
      {
        console.log(res)
        this.recipient = res 
        this.recipientService.updateRecipient(this.recipient)
        // redefine what kind of message is going to send after checking the updated recipient
        this.defineWhatTypeOfMessageIsSending()
        this.onContinueSendingForm()


      }, error => {
        console.log( error , 'throw error')
        this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'AN ERROR OCCURRED , CHECK YOUR CONNECTION OR TRY LATER'})
      }
      )

 }

 onCheckingSharedRecipient_onRecipientFeatureChilds(){

  const newRecipientFeature = this.recipientFeatureService.getRecipientFeature()
  console.log(newRecipientFeature)

  this.getComponentsHttpRequestService.httpGetUserComponentWithChildsBy_id(newRecipientFeature._id)
      .subscribe((recipientFeaturePlusChilds: any)=>{
       console.log(recipientFeaturePlusChilds)

       const sharedRecipients: Recipient[] = recipientFeaturePlusChilds.childList 
       
       
       if ( sharedRecipients.findIndex(x=> x._id === this.recipient._id ) !== -1 ){
       const index = sharedRecipients.findIndex(x=> x._id === this.recipient._id )

       /**
        * check if this recipient recipientList still has my email inside 
        */

       /**
        * if my email is still inside then
        */
       if ( sharedRecipients[index].recipientList.findIndex(x=> x === this.user) !== -1 ){
        this.recipient = sharedRecipients[index]
        this.recipient.toMessage = sharedRecipients[index].recipientList
        this.recipientService.updateRecipient(this.recipient)

        this.defineWhatTypeOfMessageIsSending()

        this.onContinueSendingForm()
       }

        /**
        * if my email is NOT inside then
        * we should eliminate this recipient from the service 
        * we should update the list of shared recipientIndexes on coulums and here too 
        * restart this create form page with our user and restart recipient index column 
        */
        if ( sharedRecipients[index].recipientList.findIndex(x=> x === this.user) === -1 ){
          // this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'THIS GROUP IS NO LONGER ACTIVE, line 1797 formEdit.ts still need to eliminate user'})

          const indexOfrecipientToDeleteFromService = this.recipientService.getRecipients().findIndex(x=> x._id ===this.recipient._id )
            this.recipientService.recipients.splice(indexOfrecipientToDeleteFromService, 1 )
            this.recipientService.updateInitialRecipients(this.recipientService.recipients)

          const  recipientIndexListItem = this.updateRecipientIndexListItem()
          console.log( 'THIS GROUP IS NO LONGER ACTIVE, formEdit line 1890 ')
          this.onEmitUpdatedRecipientIndexListItem_afterDeleteToMessageEmail(recipientIndexListItem)
          /**
           * send subject to recipientIndexColumn 
           */
          // this.resetRecipientIndexColumnSharedRecipients.next()
          // this.router.navigate(['../create-form'], {relativeTo: this.route.parent})


        }

      }

      if ( sharedRecipients.findIndex(x=> x._id === this.recipient._id ) === -1 ){

            const indexOfrecipientToDeleteFromService = this.recipientService.getRecipients().findIndex(x=> x._id ===this.recipient._id )
            this.recipientService.recipients.splice(indexOfrecipientToDeleteFromService, 1 )
            this.recipientService.updateInitialRecipients(this.recipientService.recipients)

        const  recipientIndexListItem = this.updateRecipientIndexListItem()
        console.log( 'THIS GROUP IS NO LONGER ACTIVE, formEdit line 1919')
        this.onEmitUpdatedRecipientIndexListItem_afterDeleteToMessageEmail(recipientIndexListItem)
        
  
          // this.resetRecipientIndexColumnSharedRecipients.next()
      

      }







    }, error => {
      console.log(error , 'manage error formEdit 1935')

      this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'ERROR'})

    })

 }

 updateRecipientIndexListItem(): RecipientIndexListItem{

  const indexOfRecipientIndexContainingRecipient = this.recipientIndexService.getRecipientIndexes().findIndex(x=> x.recipient_id === this.recipient._id)
        const recipientIndex_id = this.recipientIndexService.getRecipientIndex(indexOfRecipientIndexContainingRecipient)._id
        const index = this.recipientIndexColumnService.getRecipientIndexColumnBy_type('shared').recipientIndexListItem_ids[0]
        const recipientIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItemBy_id(index)
        const indexOfRecipientIndexInSharedRecipientIndexListItem = recipientIndexListItem.recipientIndexList_ids.findIndex(x=> x === recipientIndex_id)

        recipientIndexListItem.recipientIndexList_ids.splice( indexOfRecipientIndexInSharedRecipientIndexListItem, 1 )
        
        if( recipientIndexListItem.recipientIndexSelected_id === recipientIndex_id ){
          if ( recipientIndexListItem.recipientIndexList_ids.length > 0 ){
               recipientIndexListItem.recipientIndexSelected_id = recipientIndexListItem.recipientIndexList_ids[0]
          }
          if ( recipientIndexListItem.recipientIndexList_ids.length === 0 ){
              // here we need to put selected at null and advise recipientIndexColumn to close 
              recipientIndexListItem.recipientIndexSelected_id = null 

          }

        }

  return recipientIndexListItem
 }



  onContinueSendingForm(){

  // "2023-04-02T23:55:26.807+02:00"  this is the time form we have to use 
  // "04/02/2023 11:55:26 PM" this is the time form we have to use 

  if (this.recipientIndex.useMyOwnMailRecipient === true ){
      this.secondMailrecipient = this.recipient 
      console.log(this.secondMailrecipient)
     } // this is just tokeep the function going when the second recipient is not present 

  this.isSendingMessageEmail = true 
 
  this.timeAtSendForm = this.datePipe.transform( new Date(), 'yyyy-MM-dd HH:mm:ss' )
 
  console.log(this.mailTextFinalComposition)

    const new_recipient_id = ""
    const newComponentName = 'recipient'
    const newUIID = ""
    const newChat_roomid = this.recipient.chatRoomId
    const newOwner = this.recipient.owner
    const newDateAndTime = this.recipient.dateAndTime;
    const newUpdateDateAndTime = this.recipient.updateDateAndTime
    const newAllUsersAllowed = this.recipient.allUsersAllowed
    const newUserListAllowed = this.recipient.userListAllowed
    const newBlockedUserList = this.recipient.blockedUserList
    const newName = this.recipient.name
    const newRecipientList = this.recipient.recipientList
    const newToMessage = this.recipient.toMessage
    const newToMail = [];
    const newCcmail = [];
    const newBccMail = [];
    const newGreeting = '' ;
    
    const newRecipientResultForMessage = new Recipient ( new_recipient_id, newComponentName, newUIID, newOwner, newDateAndTime , newUpdateDateAndTime, newChat_roomid,
                                         newAllUsersAllowed, newUserListAllowed, newBlockedUserList,
                                         newName, newRecipientList , newToMessage, 
                                         newToMail, newCcmail, newBccMail , newGreeting);
    const newRecipientList2 = []
    const newToMessage2 = [];
    const newChat_roomid2 = ""
    const newToMail2 = this.recipient.toMail;
    const newCcmail2 = this.recipient.ccMail
    const newBccMail2 = this.recipient.bccMail
    const newGreeting2 = this.recipient.greeting

  
      const newOwner3 = this.user 
      const newAllUsersAllowed3 = this.secondMailrecipient.allUsersAllowed
      const newUserListAllowed3 = this.secondMailrecipient.userListAllowed
      const newBlockedUserList3 = this.secondMailrecipient.blockedUserList
      const newRecipientList3 = []
      const newToMessage3 = [];
      const newChat_roomid3 = ""
      const newToMail3 = this.secondMailrecipient.toMail;
      const newCcmail3 = this.secondMailrecipient.ccMail
      const newBccMail3 = this.secondMailrecipient.bccMail
      const newGreeting3 = this.secondMailrecipient.greeting


    /**
     * to use with my recipient
     */
    const newRecipientResultForMail = new Recipient ( new_recipient_id, newComponentName, newUIID, newOwner, newDateAndTime , newUpdateDateAndTime, newChat_roomid2,
      newAllUsersAllowed, newUserListAllowed, newBlockedUserList,
      newName, newRecipientList2 , newToMessage2, 
      newToMail2, newCcmail2, newBccMail2 , newGreeting2 );

    /**
     * to use if it's shared recipientIndex
     */
    const newRecipientResultForMailWhenShared = new Recipient ( new_recipient_id, newComponentName, newUIID, newOwner3, newDateAndTime , newUpdateDateAndTime, newChat_roomid3,
      newAllUsersAllowed3, newUserListAllowed3, newBlockedUserList3,
      newName, newRecipientList3 , newToMessage3, 
      newToMail3, newCcmail3, newBccMail3 , newGreeting3 );
   




  const new_id = (this.formResultsService.getFormResults().length+1).toString() ;
  const new_id2 = (this.formResultsService.getFormResults().length+2).toString()
  const newIsActive = true ; 
  const newIsDeleted = false ;
  const newIsSelected = false ;
  const newformReadFlag = true ;
  const newIsShareble = true ;
  const newIsACopyOfAMessage = false 
  const newIsForwardToMail = this.isForwardToMail ;
  const newIsForwarded = false ;
  const newSender = new Sender ( this.user) ;
  const newSenderGroup = '' ;
  const newSenderType = '' ;
  const newReceiverGroup= this.recipientIndexListItem.recipientIndexListName ;
  const newReceiverType = 'private' ;
  const newFormDateAndTime = this.timeAtSendForm
  const newUserChatRoomId = this.recipient._id
  const newSubject = this.finalSubject ;
  const newParagraphResults = this.paragraphResults 
  const newSeq = null 
  const newUpdateDateAndTime1 = null 
  const newHasFollowingParagraphItemToShow = ''


  const newSentFormResultForMessage = new FormResult (  new_id,
                                              newIsActive ,
                                              newIsDeleted,
                                              newIsSelected,
                                              newformReadFlag,
                                              newIsACopyOfAMessage,
                                              newIsShareble, 
                                              newIsForwardToMail,
                                              newIsForwarded,
                                              newSender,
                                              newSenderGroup,
                                              newSenderType,
                                              newReceiverGroup,
                                              newReceiverType,
                                              newUserChatRoomId,
                                              newRecipientResultForMessage,
                                              newFormDateAndTime,
                                              newSubject,
                                              newParagraphResults ,
                                              newSeq,
                                              newUpdateDateAndTime1,
                                              newHasFollowingParagraphItemToShow
                                              ) ;
const newSentFormResultForMail = new FormResult (  
       new_id2,
       newIsActive ,
       newIsDeleted,
       newIsSelected,
       newformReadFlag,
       newIsShareble,
       newIsACopyOfAMessage,
       newIsForwardToMail,
       newIsForwarded,
       newSender,
       newSenderGroup,
       newSenderType,
       newReceiverGroup,
       newReceiverType,
       null,
       newRecipientResultForMail,
       newFormDateAndTime,
       newSubject,
       newParagraphResults , 
       newSeq,
       newUpdateDateAndTime,
       newHasFollowingParagraphItemToShow
) ;


const newSentFormResultForMailAsCopyRecipient = new FormResult (
  new_id2,
  newIsActive ,
  newIsDeleted,
  newIsSelected,
  newformReadFlag,
  newIsShareble,
  newIsACopyOfAMessage,
  newIsForwardToMail,
  newIsForwarded,
  newSender,
  newSenderGroup,
  newSenderType,
  newReceiverGroup,
  newReceiverType,
  null,
  newRecipientResultForMailWhenShared,
  newFormDateAndTime,
  newSubject,
  newParagraphResults , 
  newSeq,
  newUpdateDateAndTime,
  newHasFollowingParagraphItemToShow
  ) ;



 if ( this.user !== null ){

  /**
   * if send then we send the text composition after we are sure the formResult has been stored 
   * if any error we ask if we still want to send by email anyway yes or no , and deal with it 
   * with additional steps
   */
  if ( this.recipientIndex.useMyOwnMailRecipient === true ){
    if ( this.isEmailMessage === true ){
      this.postComponentsHttpRequestService.httpPostFormResult( this.user , newSentFormResultForMessage  )
      .subscribe((result: any )=> { 

        console.log(result)

        const newFormResult = result.formResult
        const newUserReceiveFormResultId = result.userReceiveFormResultId
        const newWrongRecipientUserList = result.wrongRecipientUserList

        // this.formResultsService.addFormResult(newFormResult)

          this.postComponentsHttpRequestService.httpPostMailFormResult( this.user , newSentFormResultForMail)
              .subscribe((result2: any )=> {

                console.log( result2)

                // this.formResultsService.addFormResult(result2)

                this.mailTextFinalComposition ='mailto:'+this.mailTextReceiverTo+
                '?cc='+this.mailTextReceiverCc+
                '&bcc='+this.mailTextReceiverBcc+
                '&subject='+this.mailTextSubject+
                '&body='+this.mailTextRecipientGreetings+'%0D%0A%0D%0A'+
                 this.finalMailTextBody+'%0D%0A'
                // this.links.join('%0D%0A%0D%0A')
                //  this.mailList.join('%0D%0A%0D%0A')+
                //  this.mailList;

                 window.location.href = this.mailTextFinalComposition

                 this.dms.FormItemGoesToResultsOrChat({ 
                  finalSentFormResult: newFormResult ,
                  chat_id: this.recipient.chatRoomId
                })

                this.dropped = true
                this.closeSideNavContentOnSendingFormResult.emit()
                this.closeSideNavOnCompleteFormEditFunctions.emit();
          
               // this.emitInfoToCloseOpenFormItem.emit();
             
               /**
                * navigate to reload component for then come back to form item again , serve as restarter for this component 
                */
              //  this.router.navigate(['reload-form'], {relativeTo: this.route.parent});

              }, error =>{
                console.log(error , 'error on the email post')
              })
        // }
      
      }, error => {
        console.log( error , 'error on the message post' )

      })
   }

  //  if ( this.recipient.toMessage.length === 0  && this.recipient.toMail.length > 0 ){
    if ( this.isEmailOnly === true  ){

      this.postComponentsHttpRequestService.httpPostMailFormResult( this.user , newSentFormResultForMail )
          .subscribe((newFormResult : FormResult)=> {
            console.log(newFormResult)

              this.mailTextFinalComposition ='mailto:'+this.mailTextReceiverTo+
                                             '?cc='+this.mailTextReceiverCc+
                                             '&bcc='+this.mailTextReceiverBcc+
                                             '&subject='+this.mailTextSubject+
                                             '&body='+this.mailTextRecipientGreetings+'%0D%0A%0D%0A'+
                                              this.finalMailTextBody+'%0D%0A'
                      // this.links.join('%0D%0A%0D%0A')
                      //  this.mailList.join('%0D%0A%0D%0A')+
                      //  this.mailList;
              
              window.location.href = this.mailTextFinalComposition
              
      
              this.dms.FormItemGoesToResultsOrChat({ 
                finalSentFormResult: newFormResult ,
                chat_id: this.recipient.chatRoomId
              })

              this.dropped = true
              this.closeSideNavContentOnSendingFormResult.emit()
              this.closeSideNavOnCompleteFormEditFunctions.emit();
        
            
           
             /**
              * navigate to reload component for then come back to form item again , serve as restarter for this component 
              */
            //  this.router.navigate(['reload-form'], {relativeTo: this.route.parent});
            
            }, error => {
              console.log( error )
      
            })
   }

  //  if ( this.recipient.toMessage.length > 0 && this.recipient.toMail.length === 0 ){
    if ( this.isMessageOnly === true ){
    this.postComponentsHttpRequestService.httpPostFormResult( this.user , newSentFormResultForMessage )
    .subscribe((result: any )=> { 

      console.log(result)

      const newFormResult = result.formResult
      const newUserReceiveFormResultId = result.userReceiveFormResultId
      const newWrongRecipientUserList = result.wrongRecipientUserList

      // the formResult will be called once there , we don't need to store it 

      this.dms.FormItemGoesToResultsOrChat({ 
        finalSentFormResult: newFormResult ,
        chat_id: this.recipient.chatRoomId
      })

      this.dropped = true
      this.closeSideNavContentOnSendingFormResult.emit()
      this.closeSideNavOnCompleteFormEditFunctions.emit();

   
     /**
      * navigate to reload component for then come back to form item again , serve as restarter for this component 
      */
     // this.router.navigate(['reload-form'], {relativeTo: this.route.parent});
    
    }, error => {
      console.log( error )

    })
   }

    if ( !this.isEmailMessage && !this.isEmailOnly && !this.isMessageOnly === true ){
      // the message can't be sent , restart 
      this.dropped = true
      this.closeSideNavContentOnSendingFormResult.emit()
      this.closeSideNavOnCompleteFormEditFunctions.emit();

      this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: "SORRY , CAN'T BE CURRENTLY SENT"})

    }
  }

  if ( this.recipientIndex.useMyOwnMailRecipient === false ){
    if ( this.isEmailMessage === true ){
      this.postComponentsHttpRequestService.httpPostFormResult( this.user , newSentFormResultForMessage  )
      .subscribe((result: any )=> { 

        console.log(result)

        const newFormResult = result.formResult
        const newUserReceiveFormResultId = result.userReceiveFormResultId
        const newWrongRecipientUserList = result.wrongRecipientUserList

        // this.formResultsService.addFormResult(newFormResult)

          this.postComponentsHttpRequestService.httpPostMailFormResult( this.user , newSentFormResultForMailAsCopyRecipient)
              .subscribe((result2: any )=> {

                console.log( result2)

                // this.formResultsService.addFormResult(result2)

                this.mailTextFinalComposition ='mailto:'+this.mailTextReceiverTo+
                '?cc='+this.mailTextReceiverCc+
                '&bcc='+this.mailTextReceiverBcc+
                '&subject='+this.mailTextSubject+
                '&body='+this.mailTextRecipientGreetings+'%0D%0A%0D%0A'+
                 this.finalMailTextBody+'%0D%0A'
                // this.links.join('%0D%0A%0D%0A')
                //  this.mailList.join('%0D%0A%0D%0A')+
                //  this.mailList;

                 window.location.href = this.mailTextFinalComposition

                 this.dms.FormItemGoesToResultsOrChat({ 
                  finalSentFormResult: newFormResult ,
                  chat_id: this.recipient.chatRoomId
                })

                this.dropped = true
                this.closeSideNavContentOnSendingFormResult.emit()
                this.closeSideNavOnCompleteFormEditFunctions.emit();
          
               // this.emitInfoToCloseOpenFormItem.emit();
             
               /**
                * navigate to reload component for then come back to form item again , serve as restarter for this component 
                */
              //  this.router.navigate(['reload-form'], {relativeTo: this.route.parent});

              }, error =>{
                console.log(error , 'error on the email post')
              })
        // }
      
      }, error => {
        console.log( error , 'error on the message post' )

      })
   }

  //  if ( this.recipient.toMessage.length === 0  && this.recipient.toMail.length > 0 ){
    if ( this.isEmailOnly === true  ){

      this.postComponentsHttpRequestService.httpPostMailFormResult( this.user ,  newSentFormResultForMailAsCopyRecipient  )
          .subscribe((newFormResult : FormResult)=> {
            console.log(newFormResult)
  
            // this.formResultsService.addFormResult( newFormResult )

              this.mailTextFinalComposition ='mailto:'+this.mailTextReceiverTo+
                                             '?cc='+this.mailTextReceiverCc+
                                             '&bcc='+this.mailTextReceiverBcc+
                                             '&subject='+this.mailTextSubject+
                                             '&body='+this.mailTextRecipientGreetings+'%0D%0A%0D%0A'+
                                              this.finalMailTextBody+'%0D%0A'
                      // this.links.join('%0D%0A%0D%0A')
                      //  this.mailList.join('%0D%0A%0D%0A')+
                      //  this.mailList;
              
              window.location.href = this.mailTextFinalComposition
              
      
              this.dms.FormItemGoesToResultsOrChat({ 
                finalSentFormResult: newFormResult ,
                chat_id: this.recipient.chatRoomId
              })

              this.dropped = true
              this.closeSideNavContentOnSendingFormResult.emit()
              this.closeSideNavOnCompleteFormEditFunctions.emit();
        
            
           
             /**
              * navigate to reload component for then come back to form item again , serve as restarter for this component 
              */
            //  this.router.navigate(['reload-form'], {relativeTo: this.route.parent});
            
            }, error => {
              console.log( error )
      
            })
   }

  //  if ( this.recipient.toMessage.length > 0 && this.recipient.toMail.length === 0 ){
    if ( this.isMessageOnly === true ){
    this.postComponentsHttpRequestService.httpPostFormResult( this.user , newSentFormResultForMessage )
    .subscribe((result: any )=> { 

      console.log(result)

      const newFormResult = result.formResult
      const newUserReceiveFormResultId = result.userReceiveFormResultId
      const newWrongRecipientUserList = result.wrongRecipientUserList

      // this.formResultsService.addFormResult(newFormResult)

      this.dms.FormItemGoesToResultsOrChat({ 
        finalSentFormResult: newFormResult ,
        chat_id: this.recipient.chatRoomId
      })

      this.dropped = true
      this.closeSideNavContentOnSendingFormResult.emit()
      this.closeSideNavOnCompleteFormEditFunctions.emit();

   
     /**
      * navigate to reload component for then come back to form item again , serve as restarter for this component 
      */
     // this.router.navigate(['reload-form'], {relativeTo: this.route.parent});
    
    }, error => {
      console.log( error )

    })
   }

   if ( !this.isEmailMessage && !this.isEmailOnly && !this.isMessageOnly === true ){
    // the message can't be sent , restart 
    this.dropped = true
    this.closeSideNavContentOnSendingFormResult.emit()
    this.closeSideNavOnCompleteFormEditFunctions.emit();

    this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: "SORRY , CURRENTLY UNABLE TO SEND"})
  }
  }
}

if ( this.user === null ){

  if ( this.isEmailMessage === true ){ 
    this.formResultsService.addFormResult(newSentFormResultForMessage)
    this.formResultsService.addFormResult(newSentFormResultForMail)
    
  
     this.mailTextFinalComposition ='mailto:'+this.mailTextReceiverTo+
     '?cc='+this.mailTextReceiverCc+
     '&bcc='+this.mailTextReceiverBcc+
     '&subject='+this.mailTextSubject+
     '&body='+this.mailTextRecipientGreetings+'%0D%0A%0D%0A'+
      this.finalMailTextBody+'%0D%0A'
      // this.links.join('%0D%0A%0D%0A')
      //  this.mailList.join('%0D%0A%0D%0A')+
      //  this.mailList;
     window.location.href = this.mailTextFinalComposition
  
    /**
     * we "store" the result and send the text message also 
     */
    this.dms.FormItemGoesToResultsOrChat({ 
      finalSentFormResult: newSentFormResultForMessage ,
      chat_id: this.recipient.chatRoomId
    })
  
      this.dropped = true
      this.closeSideNavContentOnSendingFormResult.emit()
      this.closeSideNavOnCompleteFormEditFunctions.emit();
  }

  if ( this.isEmailOnly === true  ){

    this.formResultsService.addFormResult(newSentFormResultForMail)
     console.log(newSentFormResultForMail)
  
     this.mailTextFinalComposition ='mailto:'+this.mailTextReceiverTo+
     '?cc='+this.mailTextReceiverCc+
     '&bcc='+this.mailTextReceiverBcc+
     '&subject='+this.mailTextSubject+
     '&body='+this.mailTextRecipientGreetings+'%0D%0A%0D%0A'+
      this.finalMailTextBody+'%0D%0A'
      // this.links.join('%0D%0A%0D%0A')
      //  this.mailList.join('%0D%0A%0D%0A')+
      //  this.mailList;
     window.location.href = this.mailTextFinalComposition
  
    /**
     * we "store" the result and send the text message also 
     */
    this.dms.FormItemGoesToResultsOrChat({ 
      finalSentFormResult: newSentFormResultForMail ,
      chat_id: this.recipient.chatRoomId
    })
  
      this.dropped = true
      this.closeSideNavContentOnSendingFormResult.emit()
      this.closeSideNavOnCompleteFormEditFunctions.emit();

  }

  if ( this.isMessageOnly === true  ){

    this.formResultsService.addFormResult(newSentFormResultForMessage)
  
    /**
     * we "store" the result and send the text message also 
     */
    this.dms.FormItemGoesToResultsOrChat({ 
      finalSentFormResult: newSentFormResultForMessage,
      chat_id: this.recipient.chatRoomId
    })
  
      this.dropped = true
      this.closeSideNavContentOnSendingFormResult.emit()
      this.closeSideNavOnCompleteFormEditFunctions.emit();

  }


  } 



// line 1067 - 1335  to fix on formEdit
// const startingEditForm: Form = new Form ( null , '', '', false, [], false, '', this.recipientIndexService.getRecipientIndex(0)._id , [], [] )
// this.formService.onUpdateLatestShownForm(startingEditForm) 
  
 };
 
 onEmitRecipientResult($event){
  

  this.recipient = $event
  this.isLoadingReceiverResult = true 

  this.defineMailAccountsText()

  this.defineWhatTypeOfMessageIsSending()

  setTimeout(()=>{
    this.isLoadingReceiverResult = false 
  },300)

 }

 onEmitSecondRecipientResult($event){

  this.secondMailrecipient = $event 
  this.isLoadingReceiverResult = true 

  this.defineMailAccountsText()

  this.defineWhatTypeOfMessageIsSending()

  setTimeout(()=>{
    this.isLoadingReceiverResult = false 
  },300)
}

 defineMailAccountsText(){

  // if (Array.isArray(this.recipientIndex.recipient_id)) {
  //   if ( this.recipientIndex.recipient_id.length > 1 ){
  //     console.log("IS ARRAY OF RECIPIENT_ID")
  //     const recipientList: any = this.recipientIndex.recipient_id 
  //   const stringList: string[] = recipientList
  //   const indexOfrecipient =  this.getIndexWithLowestNumber(stringList)
  //   const recipient_id = stringList[indexOfrecipient]
  //   this.recipient = this.recipientService.getRecipientBy_id(recipient_id);
  //   }
  // }

  // if ( !Array.isArray(this.recipientIndex.recipient_id)){
  //   this.recipient = this.recipientService.getRecipientBy_id(this.recipientIndex.recipient_id);
  // }


  if ( this.recipientIndex.useMyOwnMailRecipient === true ){
    this.mailTextReceiverTo = this.recipient.toMail.toString();
    this.mailTextReceiverCc = this.recipient.ccMail.toString();
    this.mailTextReceiverBcc = this.recipient.bccMail.toString();
    this.mailTextRecipientGreetings = encodeURIComponent(this.recipient.greeting)
  }

  if ( this.recipientIndex.useMyOwnMailRecipient === false ){
    this.mailTextReceiverTo = this.secondMailrecipient.toMail.toString();
    this.mailTextReceiverCc = this.secondMailrecipient.ccMail.toString();
    this.mailTextReceiverBcc = this.secondMailrecipient.bccMail.toString();
    this.mailTextRecipientGreetings = encodeURIComponent(this.secondMailrecipient.greeting)

  }
 
 }

 

 onUpdateBothParagraphResultTextResultAndIndex($event){
  console.log($event)

  /**
   * we have to restart the count of paragraphResults and text everytime we 
   * 1- split a paragraph
   * 2- add a paragraph
   * 3- move a paragraph
   * 
   *  subscription to all the single/standard components that they have to resend the onSend()
   * once any of these 3 operations is completed ( means the list is stable )
   * plus we have to build a new dummy paragraphResults at each change , maybe no need when we just move
   * the paragraph, as the number of paragraphs remain the same 
   * 
   * 4 - we should advice for example when a form is missing a paragraph by method confront if paragraph_id
   * exist on stock , and if it doesn't advise user thta is missing and checking if he wants to continue
   * If is missing and the user wants to continu , we need to reformulate the dummy paragraphResult as he would 
   * maybe miss a paragraphResult 
   * 
   * 5- if a paragraph( shared/public/preferred ) has been changed , we should advise too 
   */

  // if ( this.paragraphResults.length === 1 ){this.paragraphResults[$event.newParagraphFeatureDropId] = $event.newParagraphResult}
  // if ( this.paragraphResults.length   > 1 && $event.newParagraphFeatureDropId !== 0 ){ this.paragraphResults[$event.newParagraphFeatureDropId] = $event.newParagraphResult }
  
  this.paragraphResults[$event.newParagraphFeatureDropId] = $event.newParagraphResult

  this.mailTextBody[$event.newParagraphFeatureDropId] = $event.newMailTextParagraphBodyPart+ '%0A%0A'

  for (let i=0; i<this.mailTextBody.length; i++){
    if (i=== 0){ this.finalMailTextBody = this.mailTextBody[i]}
    if (i!== 0){ this.finalMailTextBody = this.finalMailTextBody + this.mailTextBody[i]}
  }

  console.log(this.paragraphResults)
  console.log(this.mailTextBody, this.finalMailTextBody)

 };


onUpdateForm(){
  const new_id = this.form._id
  const newComponentName = this.form.componentName
  const newUIID = this.form.UIID
  const newOwner = this.user ;
  const newDateAndTime = DateTime.now().toString() ;
  const newUpdateDateAndTime = null 
  const newAllUsersAllowed = this.form.allUsersAllowed ;
  const newUserListAllowed = this.form.userListAllowed ;
  const newCanBeResharedByOthers = this.form.canBeResharedByOthers
  const newNoNeedecipientAndSubject = this.noNeedRecipientAndSubject
  const newIsFixedSubject = this.isFixedSubject 
  const newIsShareble = true 
  const newIsBeenShared = this.form.isBeenShared ; 
   const newformSubject = this.finalSubject
  const newRecipientIndex_id = this.recipientIndex._id;
  const newParagraphFeatures = this.paragraphFeatures;
  const newParagraph_ids = []
  for ( let i=0; i<newParagraphFeatures.length; i++){
    newParagraph_ids.push(newParagraphFeatures[i].paragraph_id)
  };

  const newForm = new Form ( new_id,
                             newComponentName,
                             newUIID,
                             newOwner ,
                             newDateAndTime,
                             newUpdateDateAndTime ,
                             newAllUsersAllowed,
                             newUserListAllowed, 
                             newCanBeResharedByOthers, 
                             newNoNeedecipientAndSubject ,
                             newIsFixedSubject,
                             newIsShareble,
                             newIsBeenShared,
                             newformSubject, 
                             newRecipientIndex_id ,
                             newParagraph_ids)

  console.log(newForm)

  this.formService.updateForm(newForm)

if ( this.user === null ){

const startingEditForm: Form = new Form ( null ,'form', null, '', '','',  false, [], false, false, false, true , false, '', this.recipientIndexService.getRecipientIndex(0)._id , [] )
this.formService.onUpdateLatestShownForm(startingEditForm)

this.router.navigate(['../form-list/reload-form'], {relativeTo: this.route.parent})
}

if ( this.user !== null ){

this.postComponentsHttpRequestService.httpUpdateComponent(this.user, 'formItem', newForm ).subscribe(response => {
console.log(response)

const startingEditForm: Form = new Form ( null , 'form', null,'', '','',  false, [], false, false, false, true, false, '', this.recipientIndexService.getRecipientIndex(0)._id , [] )
this.formService.onUpdateLatestShownForm(startingEditForm)


this.router.navigate(['../form-list/reload-form'], {relativeTo: this.route.parent})
                            
}, error => {
  console.log( 'an error occurred while saving update form ', error )
})

/**
 * update startingForm @ formservice  
 */
// line 1067 - 1335  to fix on formEdit

// const startingEditForm: Form = new Form ( null , 'form', null,'', '', false, [], false, false , false, '', this.recipientIndexService.getRecipientIndex(0)._id , [] )
// this.formService.onUpdateLatestShownForm(startingEditForm)



}

}

/**
 * set a unique method that decide which once to send 
 */
onScrollToSubject(){
  console.log("sending scroll to subject event")
  this.onScrollToSubjectOnParentComponent.emit(this.subjectComponentOffsetTop)
}

onScrollToTop(){
  console.log("sending scroll to top event")
  this.onScrollToTopOnParentComponent.emit()
}

onScrollToBottom(){
  console.log("sending scroll to bottom event")
  this.onScrollToBottomOnParentComponent.emit(this.paragraphComponentOffsetTop)
}




ngOnDestroy(){


  this.updateOnUserEmail.unsubscribe()
   if (this.youAreInFormEdit) { this.updatedRecipientIndexSubscription.unsubscribe() };

   this.startLoadingParagraphSubscriptionFromParagraphFeatureContainer.unsubscribe()
  //  this.subscriptionToOnlineOffline.unsubscribe()
   this.updateOnceParagraphChildsHaveBeenStored.unsubscribe()
   this.updateParagraphsWithNullUserparagraph.unsubscribe()
   this.updatingRecipientIndexListItemsFromFeatureIndexUpdate.unsubscribe()
   this.onContinueInitializeCreateFormNullUserSubscription.unsubscribe()
   this.paragraphWithChildsIsNotAvailableAnymore.unsubscribe()
}



}

