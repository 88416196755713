import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { SharedService } from '../shared.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-sibiling1',
  templateUrl: './sibiling1.component.html',
  styleUrls: ['./sibiling1.component.css']
})
export class Sibiling1Component implements OnInit {

  // LongPressCount
  @ViewChild('button') button: ElementRef;
  longPress = 'first state';
  longPressing = 0;
  isLongPressed = false;

  // longPress 
  value = 0



  message:string;
  jason = 'beppe@huynh.com'

  constructor( private sharedService:SharedService) {

   }


  mailText: string ;

  ngOnInit(){
    this.sharedService.sharedMessage.subscribe(message => this.message = message);
    this.mailText ='mailto: someone@yoursite.com, io@gmail.com, tony@huynh.com?cc=someoneelse@theirsite.com, another@thatsite.com, me@mysite.com&bcc=lastperson@theirsite.com&subject=Big%20News&body=name:%0D%0AANDAREemail/name:%0D%0A'+this.links.join('%0D%0A%0D%0A')+this.mailList}

    // .join(" this is what stay right after the first link%0D%0Atogether with this, this is what stay after the comma%0D%0Aand this too right after%0D%0A%0D%0A");
  

  name = 'test';
  links : any[]= ['link1.com', 'link2.com', 'link3.com','link45678.com'];
  mailList: any[]= [ 'tony@tony.com', 'bepy@bepy.com']

  // long press count 

  // using Long-Press-Count directive
  onLongPress() {
    this.longPressing = null;
    if (this.isLongPressed) this.button.nativeElement.style.backgroundColor = 'green';
    else this.button.nativeElement.style.backgroundColor = 'orange';
    this.isLongPressed = !this.isLongPressed;
    this.longPress = this.isLongPressed ? 'second state' : 'first state';
  }

  onLongPressing() {
    this.longPressing += 1;
  }

  // using LongPress directive
  increment() {
    this.value += 1;
  }

  decrement() {
    this.value -= 1;
  }

 





  // Transfer Items Between Lists
  MoviesList = [
    // 'The Far Side of the World',
    // 'Morituri',
    // 'Napoleon Dynamite',
    // 'Pulp Fiction',
    // 'Blade Runner',
    // 'Cool Hand Luke',
    // 'Heat',
    // 'Juice'    
  ];


  onDrop(event: CdkDragDrop<string[]>) {
    this.sharedService.drop(event);
  }

  clickMe(){
this.sharedService.sendClickEvent();
  }

  newMessage() {
    this.sharedService.nextMessage("Second Message")
  }





}
