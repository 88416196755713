import { Injectable } from "@angular/core";
import { DropDownSelect } from "./drop-down-select.model";
import { InitialNullSetupService } from "src/app/services/starting-services/initial-null-setup.service";
import { Subject, Subscription } from "rxjs";
import { StoreFormItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-form-item-plus-childs-confirmation.service";
import { StoreParagraphItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-paragraph-item-plus-childs-confirmation.service";
import { StoreTabItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-tab-item-plus-childs-confirmation.service";

@Injectable ({
    providedIn: 'root' 
})

export class DropDownSelectService {

    constructor( private initialNullSetupService: InitialNullSetupService , 
                 private storeFormItemPlusChildsConfirmationService: StoreFormItemPlusChildsConfirmationService ,
                 private storeParagraphItemPlusChildsConfirmationService: StoreParagraphItemPlusChildsConfirmationService , 
                 private storeTabItemPlusChildsConfirmationService: StoreTabItemPlusChildsConfirmationService 
                 ){}

    dropDownSelects: DropDownSelect[] = this.initialNullSetupService.initialNullDropDownSelects

    initialPersonalDropDownSelectHaveBeenStored = new Subject<void>()

     updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.dropDownSelectsChangedBackToNull
     .subscribe((res)=> {
          this.dropDownSelects = res 
          console.log('updated dropDownSelects back to null = ', res)

    });

    

    getDropDownSelects(){
        return this.dropDownSelects
    };

    getDropDownSelect_by_id(_id:string){
        const newDropDownSelect: DropDownSelect = this.dropDownSelects.find(
            (res)=> {
                return res._id === _id
            }
        )
        return newDropDownSelect
    }

    getDropDownSelect(index: number){
        return this.dropDownSelects[index]
    };

    onAddNew(dropDownSelect: DropDownSelect){
        this.dropDownSelects.push(dropDownSelect)
        this.updateInitialComponents(this.dropDownSelects)
    }

    addDropDownSelectsOnly(dropDownSelects: DropDownSelect[]){

        for ( let i=0;i<dropDownSelects.length ; i++ ){
            if ( this.dropDownSelects.findIndex(x=> x._id === dropDownSelects[i]._id ) === -1 ){
                this.dropDownSelects.push(dropDownSelects[i])
            }
            if (this.dropDownSelects.findIndex(x=> x._id === dropDownSelects[i]._id ) !== -1 ){
                const index = this.dropDownSelects.findIndex(x=> x._id === dropDownSelects[i]._id)
                this.dropDownSelects[index] = dropDownSelects[i]
            }
            if (i === dropDownSelects.length-1){
                this.updateInitialComponents(this.dropDownSelects)
            }
        }

    }

    addNewDropDownSelects(dropDownSelects: DropDownSelect[]){
        for ( let i=0;i<dropDownSelects.length ; i++ ){
            if ( this.dropDownSelects.findIndex(x=> x._id === dropDownSelects[i]._id ) === -1 ){
                this.dropDownSelects.push(dropDownSelects[i])
            }
            if ( i === dropDownSelects.length-1){
                this.updateInitialComponents(this.dropDownSelects)
                this.initialPersonalDropDownSelectHaveBeenStored.next()
            }
        }
        // this.initialPersonalDropDownSelectHaveBeenStored.next()
    }

    addNewDropDownSelect(dropDownToAdd: DropDownSelect , isComingFromFormItemCall: boolean , isComingFromMultiFeatures: boolean ){

        if ( this.dropDownSelects.findIndex(x=> x._id === dropDownToAdd._id ) !== -1 ) { 
             const index = this.dropDownSelects.findIndex(x=> x._id === dropDownToAdd._id )
             this.dropDownSelects[index] = dropDownToAdd
            this.updateInitialComponents(this.dropDownSelects)
       }

        if ( this.dropDownSelects.findIndex(x=> x._id === dropDownToAdd._id ) === -1 ) { 
             this.dropDownSelects.push(dropDownToAdd)
             this.updateInitialComponents(this.dropDownSelects)
        }

        if ( isComingFromFormItemCall === true  ){ this.storeFormItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenFormItemPlusChildsAreStored( dropDownToAdd._id) }
        if ( isComingFromFormItemCall === false ){ this.storeParagraphItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenParagraphItemPlusChildsAreStored( dropDownToAdd._id) }
        if ( isComingFromMultiFeatures=== false ){ this.storeTabItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenTabItemPlusChildsAreStored( dropDownToAdd._id) }
  
        console.log('DROPDOWNS = ', this.dropDownSelects)
    };

    updateInitialComponents(updatedDropDownSelects: DropDownSelect[]){
        this.initialNullSetupService.initialNullDropDownSelects = updatedDropDownSelects
    }

    ngOnDestroy(){
        this.updateInitialNullDatafromInitialNullSetupService.unsubscribe()
    }

}