import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-numbers-add-to-previous',
  templateUrl: './numbers-add-to-previous.component.html',
  styleUrls: ['./numbers-add-to-previous.component.css']
})
export class NumbersAddToPreviousComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
