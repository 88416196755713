import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "src/app/app-routing.module";
import { MaterialDesignModule } from "src/app/material-design/material-design.module";
import { SharedModule } from "src/app/shared/shared.module";
import { FormResultsListComponent } from "./form-results-list/form-results-list.component";
import { FormResultsComponent } from "./form-results.component";
import { DateAndTimeModule } from "src/app/date-and-time/date-and-time.module";
import { ContactsModule } from "src/app/contacts/contacts.module";
import { ParagraphResultsComponent } from './form-results-list/form-result-item/paragraph-results/paragraph-results.component';
import { FeatureResultsComponent } from './form-results-list/form-result-item/paragraph-results/feature-results/feature-results.component';
import { SingleWordInputResultComponent } from './form-results-list/form-result-item/paragraph-results/feature-results/single-feature-results/single-word-input-result/single-word-input-result.component';
import { SingleWordResultComponent } from './form-results-list/form-result-item/paragraph-results/feature-results/single-feature-results/single-word-result/single-word-result.component';
import { WordInputResultComponent } from './form-results-list/form-result-item/paragraph-results/feature-results/word-input-result/word-input-result.component';
import { WordResultComponent } from './form-results-list/form-result-item/paragraph-results/feature-results/word-result/word-result.component';
import { ToggleWordButtonResultComponent } from './form-results-list/form-result-item/paragraph-results/feature-results/toggle-word-button-result/toggle-word-button-result.component';
import { SingleToggleWordButtonResultComponent } from './form-results-list/form-result-item/paragraph-results/feature-results/single-feature-results/single-toggle-word-button-result/single-toggle-word-button-result.component';
import { SingleFeatureResultsComponent } from './form-results-list/form-result-item/paragraph-results/feature-results/single-feature-results/single-feature-results.component';
import { FormResultItemComponent } from "./form-results-list/form-result-item/form-result-item.component";
import { SenderComponent } from './form-results-list/form-result-item/sender/sender.component';
import { ReceiverComponent } from './form-results-list/form-result-item/receiver/receiver.component';
import { FormResultsIndexComponent } from './form-results-index/form-results-index.component';
import { FormResultsFilterComponent } from './form-results-filter/form-results-filter.component';
import { FormResultIndexItemComponent } from './form-results-index/form-result-index-item/form-result-index-item.component';
import { PipesModule } from "src/app/pipes/pipes.module";
import { InputModule } from "../form-list/form-item/paragraph/paragraph-list/paragraph-item/features/input/input.module";
import { TabFeatureResultsComponent } from "./form-results-list/form-result-item/paragraph-results/feature-results/tab-feature-results/tab-feature-results.component";
import { DrappLayoutModule } from "../form-list/form-item/paragraph/paragraph-list/paragraph-item/features/layout/drapp-layout.module";
import { MatInputAutosizeModule } from 'mat-input-autosize';
import { FixLayoutResultsComponent } from "./form-results-list/form-result-item/paragraph-results/feature-results/fix-layout-results/fix-layout-results.component";
import { FixTextResultComponent } from './form-results-list/form-result-item/paragraph-results/feature-results/fix-text-result/fix-text-result.component';
import { RecipientModule } from "../form-list/form-item/recipient/recipient.module";
import { OneDateCalendarResultComponent } from './form-results-list/form-result-item/paragraph-results/feature-results/one-date-calendar-result/one-date-calendar-result.component';
import { CalendarModule } from "../form-list/form-item/paragraph/paragraph-list/paragraph-item/features/calendar/calendar.module";
import { MultiEntryCalendarResultComponent } from './form-results-list/form-result-item/paragraph-results/feature-results/multi-entry-calendar-result/multi-entry-calendar-result.component';
import { CalculatorMixTableResultComponent } from './form-results-list/form-result-item/paragraph-results/feature-results/calculator-mix-table-result/calculator-mix-table-result.component';
import { DropDownSelectResultComponent } from './form-results-list/form-result-item/paragraph-results/feature-results/drop-down-select-result/drop-down-select-result.component';
import { SingleDropDownSelectResultComponent } from './form-results-list/form-result-item/paragraph-results/feature-results/single-feature-results/single-drop-down-select-result/single-drop-down-select-result.component';
import { SingleSliderResultComponent } from './form-results-list/form-result-item/paragraph-results/feature-results/single-feature-results/single-slider-result/single-slider-result.component';
import { SliderModule } from "../form-list/form-item/paragraph/paragraph-list/paragraph-item/features/slider/slider.module";
import { MatSliderModule } from "@angular/material/slider";
import { CheckBoxResultComponent } from './form-results-list/form-result-item/paragraph-results/feature-results/check-box-result/check-box-result.component';
import { CommentResultComponent } from './form-results-list/form-result-item/paragraph-results/feature-results/comment-result/comment-result.component';
import { AuxiliaryComponentsModule } from "src/app/loading-spinner/auxiliary-components.module";
import { DatePipe } from "@angular/common";
import { FilterFromToCopiesComponent } from './form-results-filter/filter-from-to-copies/filter-from-to-copies.component';
import { FilterSubjetsParagraphsComponent } from './form-results-filter/filter-subjets-paragraphs/filter-subjets-paragraphs.component';
import { FilterDatesComponent } from './form-results-filter/filter-dates/filter-dates.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { FilterMailSentToComponent } from './form-results-filter/filter-mail-sent-to/filter-mail-sent-to.component';
import { FixDatesFilterComponent } from './form-results-filter/filter-dates/fix-dates-filter/fix-dates-filter.component';
import { FilterGroupComponent } from './form-results-filter/filter-group/filter-group.component';
import { FormResultItemsComponent } from './form-results-list/form-result-items/form-result-items.component';
import { FormResultStartComponent } from './form-results-list/form-result-start/form-result-start.component';
import { SharedCalendarStatusResultComponent } from './form-results-list/form-result-item/paragraph-results/feature-results/shared-calendar-status-result/shared-calendar-status-result.component';
import { DropdownResultComponent } from './form-results-list/form-result-item/paragraph-results/feature-results/inner-array-select-result/dropdown-result/dropdown-result.component';
import { CheckboxResultComponent } from './form-results-list/form-result-item/paragraph-results/feature-results/inner-array-select-result/checkbox-result/checkbox-result.component';
import { FixtextResultComponent } from './form-results-list/form-result-item/paragraph-results/feature-results/inner-array-select-result/fixtext-result/fixtext-result.component';
import { InnerinputResultComponent } from './form-results-list/form-result-item/paragraph-results/feature-results/inner-array-select-result/innerinput-result/innerinput-result.component';


@NgModule({
    declarations: [
    FormResultsComponent,
    FormResultsListComponent,
    ParagraphResultsComponent,
    FeatureResultsComponent,
    SingleWordInputResultComponent,
    SingleWordResultComponent,
    WordInputResultComponent,
    WordResultComponent,
    ToggleWordButtonResultComponent,
    SingleToggleWordButtonResultComponent,
    SingleFeatureResultsComponent,
    FormResultItemComponent,
    SenderComponent,
    ReceiverComponent,
    FormResultsIndexComponent,
    FormResultsFilterComponent,
    FormResultIndexItemComponent,
    TabFeatureResultsComponent,
    FixLayoutResultsComponent,
    FixTextResultComponent,
    OneDateCalendarResultComponent,
    MultiEntryCalendarResultComponent,
    CalculatorMixTableResultComponent,
    DropDownSelectResultComponent,
    SingleDropDownSelectResultComponent,
    SingleSliderResultComponent,
    CheckBoxResultComponent,
    CommentResultComponent,
    FilterFromToCopiesComponent,
    FilterSubjetsParagraphsComponent,
    FilterDatesComponent,
    FilterMailSentToComponent,
    FixDatesFilterComponent,
    FilterGroupComponent,
    FormResultItemsComponent,
    FormResultStartComponent,
    SharedCalendarStatusResultComponent,
    DropdownResultComponent,
    CheckboxResultComponent,
    FixtextResultComponent,
    InnerinputResultComponent




   
   



    ] ,

    exports: [
        FormResultsComponent,
        FormResultsListComponent,
        ParagraphResultsComponent,
        FeatureResultsComponent,
        SingleWordInputResultComponent,
        SingleWordResultComponent,
        WordInputResultComponent,
        WordResultComponent,
        ToggleWordButtonResultComponent,
        SingleToggleWordButtonResultComponent,
        SingleFeatureResultsComponent,
        FormResultItemComponent,
        SenderComponent,
        ReceiverComponent,
        FormResultsIndexComponent,
        FormResultsFilterComponent,
        FormResultIndexItemComponent,
        TabFeatureResultsComponent,
        FixLayoutResultsComponent,
        OneDateCalendarResultComponent,
        MultiEntryCalendarResultComponent,
        CalculatorMixTableResultComponent,
        DropDownSelectResultComponent,
        SingleDropDownSelectResultComponent,
        SingleSliderResultComponent,
        CheckBoxResultComponent,
        FilterFromToCopiesComponent,
        FilterSubjetsParagraphsComponent,
        FilterDatesComponent,
        FormResultStartComponent,
        SharedCalendarStatusResultComponent,
        DropdownResultComponent,
        CheckboxResultComponent,
        FixtextResultComponent,
        InnerinputResultComponent


   
       
    ],

    imports: [
        AppRoutingModule,
        CommonModule,
        FormsModule,
        BrowserAnimationsModule,
        MaterialDesignModule, 
        ReactiveFormsModule,
        MatDialogModule,
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatExpansionModule,
        SharedModule,
        DateAndTimeModule,
        ContactsModule,
        PipesModule, 
        InputModule,
        DrappLayoutModule,
        MatInputAutosizeModule,
        RecipientModule,
        CalendarModule, 
        SliderModule,
        MatSliderModule , 
        AuxiliaryComponentsModule ,
        MatCheckboxModule ,
        MatChipsModule 
      
       
      
    ],

providers: [
    DatePipe
]


})
export class FormResultsModule {}