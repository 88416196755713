<p>charts works!</p>


<h1>1.)single</h1>
 <ngx-charts-bar-vertical
              [view]="view"
              [scheme]="colorScheme"
              [results]="single"
              [gradient]="gradient"
              [xAxis]="showXAxis"
              [yAxis]="showYAxis"
              [legend]="showLegend"
              [showXAxisLabel]="showXAxisLabel"
              [showYAxisLabel]="showYAxisLabel"
              [xAxisLabel]="xAxisLabel"
              [yAxisLabel]="yAxisLabel"
             >
</ngx-charts-bar-vertical>
<!-- <hr>
<h1>2.)pair</h1>
 <ngx-charts-bar-vertical-2d
              [view]="view"
              [scheme]="colorScheme"
              [results]="multi"
              [gradient]="gradient"
              [xAxis]="showXAxis"
              [yAxis]="showYAxis"
              [legend]="showLegend"
              [showXAxisLabel]="showXAxisLabel"
              [showYAxisLabel]="showYAxisLabel"
              [xAxisLabel]="xAxisLabel"
              [yAxisLabel]="yAxisLabel"
            >
</ngx-charts-bar-vertical-2d>
<hr>
<h1>3.)Pai-chart</h1>
<ngx-charts-pie-chart
              
                [scheme]="colorScheme"
                [results]="single"
                [legend]="showLegend"
               
                [labels]="showLabels"
                [doughnut]="doughnut"
                [gradient]="gradient"
              > -->
