import { Injectable } from "@angular/core";
import { CheckBoxGroup } from "./check-box-group.model";
import { CheckBoxSelect } from "./check-box-select.model";
import { CheckBox } from "./check-box.model";

@Injectable ({
    providedIn: 'root' 
})

export class CheckBoxSelectService {

    checkBoxSelects: CheckBoxSelect[] = [

    ];


    getCheckBoxSelects(){
        return this.checkBoxSelects
    };

    getCheckBoxSelect(index: number){
        return this.checkBoxSelects[index]
    };

    addCheckBoxSelect(checkBoxSelect: CheckBoxSelect){
        this.checkBoxSelects.push(checkBoxSelect)
    }
}