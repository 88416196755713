import { Component, OnInit, Input, Output , EventEmitter} from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SingleDateCalendar } from '../single-date-calendar.model';
import { SingleDateCalendarService } from '../single-date-calendar.service';
import { Feature } from '../../../feature.model';
import { FeatureResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/feature-result.model';
import { OneDateCalendarDetailResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/one-date-calendar-result/one-date-calendar-result.model';
import { DialogmanagerService } from '../../../../../../../../../../services/other-components-services/dialogmanager.service'
import { Subscription, Observable } from 'rxjs';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { FormService } from 'src/app/forms/form.service';

@Component({
  selector: 'app-calendar-item',
  templateUrl: './calendar-item.component.html',
  styleUrls: ['./calendar-item.component.css']
})
export class CalendarItemComponent implements OnInit {

  user: string 
  date: string;
  // currentDate = new Date()
  todayDate: string;
  actualDate: Date ;
  @Input() index_id: string;
  @Input() isParagraphEdit: boolean;
  @Input() feature: Feature

  @Input() isTabItem: boolean
  @Input() tab_index: number 

  index: number;
  singleDateCalendar: SingleDateCalendar ;

    // noNeeded is for stuff like edit buttons to be visibles or not
  @Input() noNeeded : boolean  ;



  /**
   * receiving the click from wordInputList to edit the component
   */
  @Input() subscriptionToEditComponentItemIsOn: boolean;
  currentFeature: Feature;


  @Input() editFeatureItem: Observable<void>;
  editFeatureEventFromCalendarList: Subscription; 
   
  featureResult: FeatureResult;
  oneDateCalendarDetailResult: OneDateCalendarDetailResult;
  mailTextFeatureBodyPart: string ;

  today = new Date() ;
  day: number;
  month : number
  year: number
  dayNumber: number


  @Output() updateBothFeatureResultAndTextPlusIndex = new EventEmitter<any>()
  requestToFinalizeResultAgain: Subscription 


  constructor( private singleDateCalendarService: SingleDateCalendarService,
               private dms: DialogmanagerService , 
               private formService: FormService,
               private userObjectUpdateService: UserObjectUpdateService) { }

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

    this.index = this.singleDateCalendarService.getSingleDateCalendars().findIndex(x => x._id === this.index_id)

    this.singleDateCalendar= this.singleDateCalendarService.getSingleDateCalendar(this.index)

    console.log('feature = ', this.feature, this.singleDateCalendar)


/**
 *  NEED TO PUT AN IF STATEMENT AND CHOOSE BETWEEN PERSONAL OR SHARED SERVICE
 * this.wordInput = this.sharedWordInputService.getWordInput(this.index)
 */




  if (this.isParagraphEdit === true){
this.editFeatureEventFromCalendarList = this.editFeatureItem.subscribe((data)=> this.checkIfMatchId(data))
}

  /**
 * preparing featureResult in case no date is selected , we use today date 
 */
      const newFeatureResultType = 'oneDateCalendarDetailResult';
      const newFeatureResultName = this.singleDateCalendar.name;
      const newOneDateCalendarDetailResults : OneDateCalendarDetailResult[] = [];

      for ( let i=0; i<this.singleDateCalendar.details.length ; i++){
        const newDescription = this.singleDateCalendar.details[i].description;
        const newDateResult = this.singleDateCalendar.details[i].date
   
        const newOneDateCalendarDetailResult = new OneDateCalendarDetailResult ( newDescription, newDateResult);
        newOneDateCalendarDetailResults.push( newOneDateCalendarDetailResult )

      }

      const newFeatureResult = new FeatureResult ( newFeatureResultType, newFeatureResultName, newOneDateCalendarDetailResults )

      this.featureResult = newFeatureResult
      console.log(this.featureResult)

      this.mailTextFeatureBodyPart = '%0D%0A'
      
        for ( let i=0; i<newOneDateCalendarDetailResults.length; i++){

        this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent(newOneDateCalendarDetailResults[i].description)+'%20%20'
        this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent((newOneDateCalendarDetailResults[i].dateResult).toDateString())+'%20%20'
     
      }

      /**
       * usual send method
       */
     
        this.requestToFinalizeResultAgain = this.formService.getRequestToFinalizeResultAgain().subscribe(()=> this.onSend())

        setTimeout(()=>{
          this.onSend()
        },200)

    
      console.log(this.index)

  
  }

  updateDate( iDetailNumber: number , event: MatDatepickerInputEvent<Date>) {
    this.date = (`${event.value}`);
    this.actualDate = (event.value);

    this.singleDateCalendar.details[iDetailNumber].date = this.actualDate

  /**
   * update only the featureresult that is been changed from the ngOnInIt
   */

       const newDescription = this.singleDateCalendar.details[iDetailNumber].description
       const newDateResult = this.actualDate
       const newOneDateCalendarDetailResult = new OneDateCalendarDetailResult ( newDescription, newDateResult)

   
       this.featureResult.featureDetailResults[iDetailNumber] = newOneDateCalendarDetailResult
       console.log(this.featureResult)

       this.mailTextFeatureBodyPart = '%0D%0A'
      
       for ( let i=0; i<this.singleDateCalendar.details.length; i++){
 
         this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent(this.singleDateCalendar.details[i].description)+'%20%20'
         this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+encodeURIComponent((this.singleDateCalendar.details[i].date).toDateString())+'%20%20'
 
       }

       this.mailTextFeatureBodyPart = this.mailTextFeatureBodyPart+'%0D%0A'

       /**
        *  usual send method 
        */
       if (this.isParagraphEdit !== true){
        setTimeout(()=>{
          this.onSend()
        },200)
       } 
      


  
  }

  checkIfMatchId(data){
    this.currentFeature = data ;
    console.log(this.currentFeature)

    if (this.currentFeature.feature_id === this.index_id){
      this.openDialogSingleDateCalendarEdit()
    }
  }

  openDialogSingleDateCalendarEdit(){
    this.dms.CalendarEdit({
 
      name: this.singleDateCalendar.name,
      details: this.singleDateCalendar.details

    }).subscribe (res => {

      this.singleDateCalendar.name = res.name,
      this.singleDateCalendar.details = res.details

      
    })

  }


  onSend(){
  this.onUpdateFeatureResultEvent();
  };


  onUpdateFeatureResultEvent(){

    if ( this.isTabItem === true){ this.tab_index = this.tab_index }
    if ( this.isTabItem !== true){ this.tab_index = null }

    const newTab_index = this.tab_index

    const newFeatureDrop_Id = this.feature.dropId
    const newFeatureResult: FeatureResult = this.featureResult
    const newMailTextFeatureBodyPart = this.mailTextFeatureBodyPart

    this.updateBothFeatureResultAndTextPlusIndex.emit({ newTab_index ,newFeatureDrop_Id, newFeatureResult, newMailTextFeatureBodyPart})
    
  };


  ngOnDestroy(){
      
      if (this.isParagraphEdit !== true){ this.requestToFinalizeResultAgain.unsubscribe()}
  }

}
