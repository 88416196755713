<app-loading-spinner *ngIf="isLoadingList && !error"></app-loading-spinner>

       <div *ngIf="error">
       <br>
       <div *ngIf="error && !isLoadingList" 
            class="alert alert-danger">
            <h1>OOOPS ! Check Network Connection</h1>
                  <p>{{error}}</p>
        </div>
       </div>
                

<mat-card *ngIf="!isLoadingList && !error" class="mat-elevation-z0"> 

    <mat-tab-group #tabs 
     [(selectedIndex)]="selectedIndex"
     class="remove-border-bottom"
     header-less-tabs
     (selectedTabChange)="onLinkClick($event)"    
     headerPosition="below" 
     animationDuration="0ms" >

    

           <mat-tab *ngFor="let calculator of calculatorMixTables ; index as i ">
               
               <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

                <app-calculator-mix-table-item
                                *ngIf="!isLoading"
                                [isTabItem]="false"
                                [isParagraphEdit]="isParagraphEdit" 
                                [feature]="feature_from_paragraphEdit"
                                [noNeeded]="false" 
                                [index_id]="calculator._id"
                                [subscriptionToEditComponentItemIsOn]="subscriptionToEditComponentItemIsOn"
                                [editFeatureItem]="clickOnEditFeatureItem.asObservable()">
                </app-calculator-mix-table-item>  



                     <ng-template mat-tab-label>
                     <a style="cursor: pointer">
                          {{i+1}} - {{calculator.name}} </a>
                     </ng-template>
                   

               
        
           </mat-tab>  
           
           <div *ngIf="createButtonLimit">
               <mat-tab disabled > 
                    <ng-template  mat-tab-label>
                         <a (click)="onCreate()"> create new </a>
                    </ng-template>
                </mat-tab>
           </div>

           <div *ngIf="calculatorMixTables.length > 1">
               <div *ngIf="createButtonLimit">
                   <mat-tab disabled > 
                        <ng-template  mat-tab-label>
                             <a> create copy of  </a>
                        </ng-template>
                    </mat-tab>
               </div>
          </div>

           

    </mat-tab-group>

</mat-card>
