import { Component, OnInit, Inject } from '@angular/core';
import { FixLayout } from "../fix-layout.model"
import { FixLayoutTableTD } from '../fix-layout-table-td.model';
import { FixLayoutService} from "../fix-layout.service"
import { FormGroup, FormControl, FormBuilder, Validators, FormArray} from '@angular/forms';
import { MatDialogRef , MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-fix-layout-edit-string',
  templateUrl: './fix-layout-edit-string.component.html',
  styleUrls: ['./fix-layout-edit-string.component.css']
})
export class FixLayoutEditStringComponent implements OnInit {

  fixLayoutEditStringForm: FormGroup 

  i = this.editData.indexI
  j = this.editData.indexJ
  editFixLayout: FixLayout

  editFixLayoutTableTD: FixLayoutTableTD

  label: string 
  name: string 

  constructor(  public dialogRef: MatDialogRef<FixLayoutEditStringComponent>,
                @Inject(MAT_DIALOG_DATA) public editData: any ) { }

 

  ngOnInit(): void {

    this.editFixLayout = this.editData.fixLayout
    console.log(this.editFixLayout)

    this.editFixLayoutTableTD = this.editFixLayout.fixLayoutTableTRs[this.i].fixLayoutTableTDs[this.j]
    console.log(this.editFixLayoutTableTD)

    this.label = this.editFixLayout.fixLayoutTableTRs[this.i].fixLayoutTableTDs[this.j].tableTDDetailStringLabel
    this.name = this.editFixLayout.fixLayoutTableTRs[this.i].fixLayoutTableTDs[this.j].tableTDDetailString



    this.fixLayoutEditStringForm = new FormGroup ({
      label : new FormControl ( this.label ) ,
      name : new FormControl ( this.name)
    })
    
  }

  onSubmitString(){
    console.log(this.fixLayoutEditStringForm)
    if (this.fixLayoutEditStringForm.invalid){
      return
    }
    this.dialogRef.close(this.fixLayoutEditStringForm.value),
    this.editData.value
  }

}
