<div class="col-xs-12">

    <div class="InnerComponentFrame">
<div class="innerItemFrame2">
    <div class="col-xs-12">

        <div class="row">
            <h2 style="color: #3f51b5">name</h2>
        </div>

        <app-name-input-only
        [inputName]="rangeInfo.name"
        [inputLabelInterface]="inputDropdownInnerCompNameInterface"
        (updatedNameEvent)="onUpdateNameInputEvent($event)"
        (updateNameInputValidity)="onUpdateNameInputValidity($event)"
        [isEmptyingInputNameAfterAdded]="emptyInputNameAfterAdded.asObservable()"
        >
        </app-name-input-only>


    </div>
</div>
    </div>

    <!-- <div class="InnerComponentFrame" *ngIf="!isAboutToDeleteRangeInfo">
        <div class="innerItemFrame2">
            <div class="col-xs-12">


    <div class="row">
        <div class="col">
            <app-users-access-edit 
              [accessType]="accessType"
              [usersAccess]="rangeInfo.usersAccess"
              (updatedUsersAccessEvent)="onUpdateUsersAccess($event)">
            </app-users-access-edit>
        </div>
    </div>

            </div>
        </div>
    </div> -->


    <div class="InnerComponentFrame" *ngIf="!isAboutToDeleteRangeInfo">
        <div class="innerItemFrame2">
            <div class="col-xs-12">
          
                <br>
                    <div class="row">
                        <h2 style="color: #3f51b5">component overview</h2>
                    </div>
                    <br>
          
          <form>
            <mat-form-field class="full-width" appearance="outline">
                <mat-label> {{rangeInfo.name}}</mat-label>
                <mat-select [(ngModel)]="selectedValue" 
                            name="item" 
                            [disabled]="isDisabled"
                            (selectionChange)="onItemSelection()">
                  <mat-option *ngFor="let detail of rangeInfo.rangeDetails; let i=index" [value]="detail.name">
                    {{detail.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
          </form>

<hr>

<div class="row">
    <h2 style="color: #3f51b5">component edit</h2>
</div>
<br>

          <div *ngFor="let detail of rangeInfo.rangeDetails; let i=index">


            <div class="row">
              <div class="col-1">
                <svg *ngIf="!isEditingDetail && rangeInfo.rangeDetails.length >1" (click)="onDeleteDetail(i)" style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                 </svg>
              </div>
              <div class="col-9">
                 <p *ngIf="isEditingDetail && !(editingDetailPosition == i)"> 
                  {{detail.name}}
                 </p>
                 <p *ngIf="!isEditingDetail"> 
                   {{detail.name}}
                 </p>
               
                 <app-name-input-only *ngIf="isEditingDetail && (editingDetailPosition == i)"
                 [inputName]="detail.name"
                 [inputLabelInterface]="inputEditTextLabelInterface"
                 (updatedNameEvent)="onUpdateTextEditEvent($event)"
                 (updateNameInputValidity)="onUpdateTextEditValidity($event)"
                 [isEmptyingInputNameAfterAdded]="emptyInputTextAfterEdit.asObservable()"
                 >
                 </app-name-input-only>

              </div>
              <div class="col-1">

                  <svg *ngIf="!isEditingDetail" (click)="onEditDetail(i)"
                       style="color: lightgrey; cursor: pointer" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear-wide" viewBox="0 0 16 16">
                       <path d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 1 0-9.995 4.998 4.998 0 0 1 0 9.996z"/>
                  </svg>

                  <button *ngIf="isEditingDetail && (editingDetailPosition == i) && editedTextIsValid"
                          type="button" 
                          class="btn btn-primary"
                          (click)="onConfirmText(i)">
                   ok
                  </button>
              </div>
 
              <br *ngIf="isHandset$ | async">
          </div>
          </div>
       
<hr>
<div class="row">
    <h2 style="color: #3f51b5">add selection</h2>
</div>


<div class="row" *ngIf="!isEditingDetail">

<div class="col-10" *ngIf="!(isHandset$ | async)">
<app-name-input-only
        [inputName]="initialText"
        [inputLabelInterface]="inputTextLabelInterface"
        (updatedNameEvent)="onUpdateTextInputEvent($event)"
        (updateNameInputValidity)="onUpdateTextInputValidity($event)"
        [isEmptyingInputNameAfterAdded]="emptyInputTextAfterAdded.asObservable()"
        >
</app-name-input-only>
</div>

<!-- <div class="col-1"></div> -->

<div class="col-1" *ngIf="inputNewTextValid && !(isHandset$ | async)">
  <button type="button" 
          class="btn btn-primary"
          (click)="onAddText()">
           +
  </button>
</div>

<div class="col-xs-12" *ngIf="isHandset$ | async" >
<app-name-input-only
        [inputName]="initialText"
        [inputLabelInterface]="inputTextLabelInterface"
        (updatedNameEvent)="onUpdateTextInputEvent($event)"
        (updateNameInputValidity)="onUpdateTextInputValidity($event)"
        [isEmptyingInputNameAfterAdded]="emptyInputTextAfterAdded.asObservable()"
        >
</app-name-input-only>
</div>

<div class="row" *ngIf="inputNewTextValid && (isHandset$ | async)">
<div class="d-flex justify-content-center">
    <button type="button" 
    class="btn btn-primary"
    (click)="onAddText()">
     +
    </button>
</div>
</div>


</div>

<br *ngIf="isHandset$ | async">
          

          
          
            </div>
          </div>
    </div>

    <div class="InnerComponentFrame">
        <div class="innerItemFrame2">
            <div class="col-xs-12">
                <div class="row" *ngIf="isAboutToDeleteRangeInfo">
                    <p>are you sure you want to delete this info ? </p>
                </div>
                <br>
                <div class="row">
        
                    <div class="col-xs-11" *ngIf="isAboutToDeleteRangeInfo">
                        <div class="col-xs-6">
                            <button (click)="onDoubleConfirmDeleteInnerDetail()" 
                                     class="btn btn-danger"> 
                                     confirm deleting 
                            </button>
                        </div>
                        <div class="col-xs-3">
                            <button (click)="onReturnToEditRangeInfo()" 
                                     class="btn btn-primary"> 
                                     return to edit 
                            </button>
                        </div>
                    </div>

                    <div class="col-xs-11" *ngIf="!isAboutToDeleteRangeInfo">
                        <div class="col-xs-7">
                                <button (click)="onDeleteInnerDetail()" 
                                        class="btn btn-danger"> 
                                        delete
                                </button>
                        </div>
                        <div class="col-xs-2">
                                <button (click)="onConfirmInnerDetail()" 
                                        class="btn btn-primary"> 
                                        confirm 
                                </button>
                        </div>
                    </div>
                   
                </div>
                <br>
            </div>
        </div>
    </div>


    
    <br>

</div>
