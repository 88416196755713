import { Component, OnInit, Input } from '@angular/core';
import { SingleSlider } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/slider/single-slider/single-slider.model';

import { SingleSliderDetail } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/slider/single-slider/single-slider-detail.model';
@Component({
  selector: 'app-single-slider-result',
  templateUrl: './single-slider-result.component.html',
  styleUrls: ['./single-slider-result.component.css']
})
export class SingleSliderResultComponent implements OnInit {

  @Input() singleSliderDetailResult: SingleSliderDetail
 
  constructor() { }

  ngOnInit(): void {
  }

  getSliderTickInterval(){
    if (this.singleSliderDetailResult.showTicks) {
      return this.singleSliderDetailResult.autoTicks ? 'auto' : this.singleSliderDetailResult.tickInterval;
    }

    return 0;
  }

  sliderChange($event){
    if($event.value !== this.singleSliderDetailResult.value ){
      this.singleSliderDetailResult.value = this.singleSliderDetailResult.value
    } else {
      return
    }
}

}
