import { Component, OnInit, ViewChild ,Input, Output, EventEmitter} from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { WordHintInputService } from '../word-hint-input.service';
import { WordHintInput} from '../word-hint-input.model';
import { Feature} from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/feature.model';
import { WordHintDetail} from '../word-hint-detail.model';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';

@Component({
  selector: 'app-word-hint-list',
  templateUrl: './word-hint-list.component.html',
  styleUrls: ['./word-hint-list.component.css']
})
export class WordHintListComponent implements OnInit {

  user: string

  wordHintInput : WordHintInput;
  wordsHintInput : WordHintInput[];
  newTabIndex0: number ;
  newtabIndex : number ;
   panelOpenState = false;
  customCollapsedHeight: string = '20px';
  customExpandedHeight: string = '20px';
  index : number ;
  i : number ;

  @Input() feature_from_paragraphEdit: Feature 
  selectedIndex: number ;
  @Input() dropId : number ;
  feature : Feature ;
  details : WordHintDetail[];

  @Output() noNeeded: boolean ;

  /** emit the event to praragraph edit to change the array list */
  @Output() changeIndex = new EventEmitter<Feature>()  ; 
  
  /** limit on wordHint to be added */
  createButtonLimit: boolean;
  wordHintLimit = 25 ;
  @Input() currentI : number ;
  
 @ViewChild('tabs') tabGroup: MatTabGroup



  constructor( private wordHintInputService: WordHintInputService ,
    private userObjectUpdateService: UserObjectUpdateService ) { }

 

  ngOnInit(): void {

   this.user = this.userObjectUpdateService.userEmail

   this.wordsHintInput=this.wordHintInputService.getWordHintInputs(); 

   this.selectedIndex = this.wordHintInputService.getWordHintInputs().findIndex(x=> x._id === this.feature_from_paragraphEdit.feature_id )

   if ( this.wordHintInputService.getWordHintInputs().length < this.wordHintLimit ){
     this.createButtonLimit = true 
   } else {
     this.createButtonLimit = false ;
   };
}




onLinkClick($event) {
  this.index = this.tabGroup.selectedIndex ; 

  const newOwner = this.user ; 
  const newIsNew = false
  const newDropId = this.currentI ;
  //const newFeatureId = this.index ;
  const newFeature_id = this.wordHintInputService.getWordHintInput(this.index)._id
  const newFeatureName = 'wordInput+sentence' ;
  const newFeature = new Feature (  newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;

  this.feature = newFeature


    this.changeIndex.emit(this.feature)

    console.log(this.feature)
     
   }


   // qui creo un nuovo Word Input , ma allo stesso tempo devo anche  creare un nuovo feature !! 
  onCreateNew(){
    this.newTabIndex0 = this.wordHintInputService.getWordHintInputs().length+1;

    const new_id = this.newTabIndex0.toString();
    const newOwner = this.user ; 
    const newDateAndTime = null
    const newName =  'new tab '+ this.newTabIndex0 ;
    const newLabel = 'new label '+ this.newTabIndex0;
    const newPlaceholder = 'edit';
    const newHint = 'edit' ;

    const newDetail =  new WordHintDetail (newLabel, newPlaceholder, newHint) ;

    const newDetails =  [newDetail] ;
    const newWordHintInput = new WordHintInput ( new_id, newOwner, newDateAndTime , newName , newDetails);

    this.wordHintInputService.onAddNew(newWordHintInput);

    
  }

  onCreate(){
 this.onCreateNew()
 this.newtabIndex = this.wordHintInputService.getWordHintInputs().length-1;
 
 const newOwner = this.user ; 
 const newIsNew = false
 const newDropId = this.currentI ;
 //const newFeatureId = this.newtabIndex;
 const newFeature_id = this.wordHintInputService.getWordHintInput(this.newtabIndex)._id
 const newFeatureName = 'wordInput+sentence' ;
 const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;

 this.feature = newFeature

 this.changeIndex.emit(this.feature);

 if ( this.wordHintInputService.getWordHintInputs().length < this.wordHintLimit ){
  this.createButtonLimit = true 
} else {
  this.createButtonLimit = false ;
};

 console.log(this.feature)
    
   
    
  }






}
