import { RangeInfoResult } from "./range-info-result.model";

export class SharedCalendarStatusResult {

    constructor( public start: string,
                 public end: string,
                 public usersAccessResult: string[],
                 public rangeInfoResults: RangeInfoResult[]
                 
      ){}

}
