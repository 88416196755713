import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-link-grid-edit',
  templateUrl: './link-grid-edit.component.html',
  styleUrls: ['./link-grid-edit.component.css']
})
export class LinkGridEditComponent implements OnInit {

  buttonGotClicked: boolean 


  constructor( private route: ActivatedRoute , 
               private router: Router 
             ) { }

  ngOnInit(){
    this.buttonGotClicked = false 
  }

  onPrimoButton(){
    console.log('primo is clicked')
    this.buttonGotClicked = true 
    setTimeout(()=>{
      this.buttonGotClicked = false
      this.router.navigate(['/form-list/preferred-form', '3' ], { relativeTo: this.route.parent})
    },200)

    // this.router.navigate(['/form-list/preferred-form', '3' ], { relativeTo: this.route.parent})
  }

  getButtonColorOnClick(){
    return this.buttonGotClicked === true ? 'grey': 'white';
  }

  

}
