import { Component, Input, OnInit, Output, ViewChild, EventEmitter, OnDestroy } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { ParagraphService } from '../../paragraph.service';
import { Paragraph} from '../../paragraph.model';

/**
 * here start to import ParagraphResult to implement it from here
 */

import { ParagraphResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/paragraph-result.model';
import { FeatureResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/feature-result.model';
import { Subject, Subscription } from 'rxjs';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { ParagraphFeature } from 'src/app/forms/form-list/form-edit/form-edit-paragraphs-index/paragraph-index-topic-list/paragraph-index-topic/paragraph-feature.model';
import { WordDetailResult } from 'src/app/forms/form-results/form-results-list/form-result-item/paragraph-results/feature-results/word-result/word-detail-result.model';



@Component({
  selector: 'app-paragraph-item',
  templateUrl: './paragraph-item.component.html',
  styleUrls: ['./paragraph-item.component.css'],
  
})
export class ParagraphItemComponent implements OnInit , OnDestroy {

  user: string 

  done = [];
  panelOpenState = false;
  customCollapsedHeight: string = '20px';
  customExpandedHeight: string = '20px';
  index : number ;
  @Input() index_id : string ;
  @Output() selectedIndex : number ; 

 @Input() paragraphFeature: ParagraphFeature

  //selectorId: number ;

  paragraph : Paragraph ;

  /**
   *  this allow us to hide the buttons on the component
   *  */
  @Output() noNeeded : boolean ;

  @ViewChild('tabs') tabGroup: MatTabGroup

  /**
   * here we start to declare paragraphResult
   */

  paragraphResult: ParagraphResult;
  featureResult: FeatureResult;
  featureResults: FeatureResult[];
  

  // subscribeToFeaturesOneByOne = new Subject<number>();

  @Output() paragraphHasBeenUploadedStopLoadingSpinner = new EventEmitter<void>()

  
  @Input() isFormItem: boolean
  @Input() youAreInFormEdit: boolean

  /** it's to let subscriptions on ngOnInIt to fire  */
  isParagraphEdit = false ; 

  trialParagraphs: Paragraph[]
  trialParagraph: Paragraph; 
  isLoading = false 
  error = false

  /**
   * to make the subscriptiuons of normal components works precisely if under tab item or under paragraph edit 
   * and paragraphItem
   */
  isUnderTabs = false

  /**
   * 
   */
   featureResultTextBodyParts: string[]
   mailTextParagraphBodyPart: string;
   featureCounter: number

   updateOnUserEmail: Subscription

  @Output() updateBothParagraphResultAndTextPlusIndex = new EventEmitter<any>()

  adviseInnerComponentIsItVisible = new Subject<boolean>()




  constructor( private paragraphService: ParagraphService ,
               private userObjectUpdateService: UserObjectUpdateService ) { }


           

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
    })
     
      /**
       * if index have to be found in sharedParagraphService then we need to search for id
       * through id and owner
       */
      this.paragraph = this.paragraphService.getParagraphBy_id(this.index_id) ;

      console.log(this.paragraph, this.paragraphFeature);
      console.log('position for result / paragraph ', this.paragraph.name , this.paragraph._id)
  

     this.paragraphHasBeenUploadedStopLoadingSpinner.emit()


    /**
     * preparing dummy paragraphResult
     */
    const newFeatureResultType: string = 'wordResult'
    const newFeatureResultName: string = ''
    const newFeatureDetailResult = new WordDetailResult ('')
    const newFeatureDetailResults = [newFeatureDetailResult]

    const newFeatureResult = new FeatureResult (newFeatureResultType, newFeatureResultName, newFeatureDetailResults)

    const featureResults = []
    this.featureResultTextBodyParts = []
    for ( let i=0; i<this.paragraph.features.length; i++){
      featureResults.push(newFeatureResult)
      this.featureResultTextBodyParts.push('') // creating initial featureResultTextBodyParts
    }
    this.featureResults = featureResults

    const newParagraphResultName = this.paragraph.name
    const newParagraphResult = new ParagraphResult (  newParagraphResultName, this.featureResults ) ;

    this.paragraphResult = newParagraphResult

    
  };


/**
 * 
 * @param $event receiving result data from featureComponents  
 */
  onUpdateBothFeatureResultTextResultAndIndex($event){
    console.log($event , this.paragraphFeature)

    this.featureResults[$event.newFeatureDrop_Id] = $event.newFeatureResult

    const newParagraphResultName = this.paragraph.name
    const newParagraphResult = new ParagraphResult (  newParagraphResultName, this.featureResults )

    this.paragraphResult = newParagraphResult

    console.log(this.paragraphResult)

    /**
     * now preparing the paragraph by TEXT 
     */
    this.featureResultTextBodyParts[$event.newFeatureDrop_Id] = $event.newMailTextFeatureBodyPart

    for ( let i= 0; i<this.paragraph.features.length; i++){
      if ( i === 0 ){ this.mailTextParagraphBodyPart = this.featureResultTextBodyParts[i] +'%0D%0A' }
      if ( i !== 0 ){ this.mailTextParagraphBodyPart = this.mailTextParagraphBodyPart + this.featureResultTextBodyParts[i] +'%0D%0A' }

    }
    console.log(this.mailTextParagraphBodyPart)

    this.onSend()
  

  }

  onSend(){

    const newParagraphFeatureDropId = this.paragraphFeature.paragraphFeatureDropId
    const newParagraphResult = this.paragraphResult
    const newMailTextParagraphBodyPart = this.mailTextParagraphBodyPart

    this.updateBothParagraphResultAndTextPlusIndex.emit({newParagraphFeatureDropId, newParagraphResult, newMailTextParagraphBodyPart})
    

  };

  onComponentVisible($event , i){
    console.log( $event , i , this.paragraph.features[i].feature_id )
    this.adviseInnerComponentIsItVisible.next($event)
  }

    ngOnDestroy(){
      this.updateOnUserEmail?.unsubscribe()
    }


  

}
