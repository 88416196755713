
<br>
<div class="row">
    <div class="col-xs-12">

        <div class="col-xs-9">
            <div *ngIf="!addSubject"  class="row">
                <button (click)="onClickSubjectButton()" 
                         type="button"
                         style="background-color: orange ;"
                         class="btn btn-lg">
                          {{selectedSubject}}  
                         <svg *ngIf="!only1Subject" style="color: white;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                            <path fill-rule="evenodd" d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                          </svg>
                </button>
            </div>

            <div *ngIf="addSubject"  class="row">
                <app-new-subject-form 
                [subscribeNewSubject]="subscribeFormNewSubject.asObservable()"
                (updateFormSubject)='onUpdateFromSubject($event)'>
                </app-new-subject-form>
            </div>
        </div>

        <div class="col-xs-3">
            <div class="col-6">
                <button 
                *ngIf="giveSubjectButtonOption"
                (click)="onAddSubject()"
                type="button"
                style="background-color: orange;"
                class="btn btn-lg">
                {{ addSubject ? '<' : 'new subject'}}
                </button>
            </div>
            <div class="col-6">
           
            </div>
           
            <!-- would be good to have a button with max 5-6 form subjects
            and if more than that it transform to a dropdown -->
        </div>
    </div>
</div>
<br>


    <div class="ParagraphComponentFrame">
    
            <app-chat-fill-paragraph 
            [paragraph]="paragraph"
            [paragraphFeature]="paragraphFeature"
            (updateBothParagraphResultAndTextPlusIndex)='onUpdateBothParagraphResultTextResultAndIndex($event)'>
            </app-chat-fill-paragraph>

    </div>

<br>

            <div *ngIf="!messageHasBeenSent" class="row">
                <button  style="background-color:#3f51b5" 
                         class="btn btn-lg"
                         (click)="onSendForm()" >
                          <h2  align="center" style="color: white;">  send </h2>
                </button>
            </div>

            <app-loading-spinner *ngIf="messageHasBeenSent"></app-loading-spinner>
           

  



