import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bar-qr-codes',
  templateUrl: './bar-qr-codes.component.html',
  styleUrls: ['./bar-qr-codes.component.css']
})
export class BarQrCodesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
