<!-- <p>chat-paragraph-result-list works!</p> -->


 <div *ngFor="let paragraphResult of paragraphResults; let i=index">

    <app-chat-paragraph-result-item 
                        [sentToMessage]="sentToMessage"
                        [isReceived]="isReceived"
                        [sender]="sender"
                        [subject]="subject"
                        [paragraphResult]="paragraphResult"
                        [dateAndTime]="dateAndTime"
                       >
                       <!--  [subscribeUpdatedFormResults]="subscribeNewFormResults.asObservable()" -->
    </app-chat-paragraph-result-item>

</div>



