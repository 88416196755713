import { Component, OnInit, Inject , ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-slider-edit',
  templateUrl: './slider-edit.component.html',
  styleUrls: ['./slider-edit.component.css']
})
export class SliderEditComponent implements OnInit {

  constructor() { }

  ngOnInit(){
  }

 
}
