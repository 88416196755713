import { Component, Input, OnInit  } from '@angular/core';
import { FixTextDetailResult } from './fix-text-detail-result.model';

@Component({
  selector: 'app-fix-text-result',
  templateUrl: './fix-text-result.component.html',
  styleUrls: ['./fix-text-result.component.css']
})
export class FixTextResultComponent implements OnInit {

  @Input() fixTextDetailResults: FixTextDetailResult[]

  constructor() { }

  ngOnInit(): void {
  }

}
