import { Component, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { FeatureTopic } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-edit/feature-topic.model';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { FeatureTopicService } from '../feature-topic.service';
import { FeatureIndexSelection } from './feature-index-selection.model';
import { FeatureIndexTopicList } from '../feature-index-topic-list.model';
import { FeatureIndexTopicListService } from '../feature-index-topic-list.service';
import { Subscription } from 'rxjs';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { A } from '@angular/cdk/keycodes';
import { AdviseIfHttpStoreComponentsCallHasBeenMadeService } from 'src/app/services/other-components-services/advise-if-http-store-components-has-been-made.service';
import { StoreBaseComponentsService } from 'src/app/services/get-and-store-components/store-base-components.service';

@Component({
  selector: 'app-feature-index-topic-list',
  templateUrl: './feature-index-topic-list.component.html',
  styleUrls: ['./feature-index-topic-list.component.css']
})
export class FeatureIndexTopicListComponent implements OnInit {

  user: string 

  featureTopic: FeatureTopic;
  featureTopics: FeatureTopic[];

  featureIndexTopicListItem: FeatureIndexTopicList

  featureTopic_ids_names: string[] 

  indexSelection: FeatureIndexSelection

  weAreInStandardParagraphEdit: boolean
  weAreInMultiFeatureEdit: boolean 

  isDragDroppingFeatureIndexTopic: boolean = false 

  isTab: boolean
  isPulsing: boolean = false 

  totLengthOfSingleFeatures: number 

  updateOnUserEmail: Subscription

  baseMultiTabsHaveBeenSuccesfullyStoredSubscription: Subscription 
  indexSelectionSubscription : Subscription 

  // isFetchingBaseComponents: boolean = false 

  constructor( private featureTopicService: FeatureTopicService , 
               private userObjectUpdateService: UserObjectUpdateService,
               private storeBaseComponentsService: StoreBaseComponentsService ,
               private postComponentsHttpRequestService: PostComponentsHttpRequestService ,
               private adviseIfHttpStoreComponentsCallHasBeenMadeService: AdviseIfHttpStoreComponentsCallHasBeenMadeService ,
               private featureIndexTopicListService: FeatureIndexTopicListService ) { }

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

    /**
     * when user got changed from null to a user subscription 
     */
    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
    });

    this.featureIndexTopicListItem = this.featureIndexTopicListService.getFeatureIndexTopicListItem_by_type('personal')
    console.log(this.featureIndexTopicListItem)

    this.indexSelectionSubscription = this.featureTopicService.sharedIndexSelection
        .subscribe(indexSelection => { 
                   this.updateIndexSelection(indexSelection)
    } )

    console.log('indexSelection : ' , this.indexSelection)

    // this.baseMultiTabsHaveBeenSuccesfullyStoredSubscription = this.adviseIfHttpStoreComponentsCallHasBeenMadeService.baseMultiFeatureTabsComponentsAreNowInStore
    // .subscribe(()=> this.isFetchingBaseComponents = false )
    // this.letItPulse()
  }


  updateIndexSelection(indexSelection: FeatureIndexSelection){
    this.indexSelection = indexSelection
    console.log(console.log('indexSelection : ' , this.indexSelection))
    this.updateListOfFeatureTopic_ids_names()
  }

  updateListOfFeatureTopic_ids_names(){
     /**
     * giving names to the featureTopics to dragDrop 
     */
      const featureTopic_ids_names: string[] = []
      for ( let i=0; i<this.featureIndexTopicListItem.featureTopic_ids.length ; i++){

        if ( this.indexSelection.weAreInStandardParagraphEdit === true && this.indexSelection.weAreInTabsEdit === false ){
          if (this.featureTopicService.getFeatureTopicBy_id(this.featureIndexTopicListItem.featureTopic_ids[i]).isStandardFeature === true ){
            featureTopic_ids_names.push(this.featureTopicService.getFeatureTopicBy_id(this.featureIndexTopicListItem.featureTopic_ids[i]).name)
          }
        
        }
        if ( this.indexSelection.weAreInStandardParagraphEdit === true && this.indexSelection.weAreInTabsEdit === false  ){
          if (this.featureTopicService.getFeatureTopicBy_id(this.featureIndexTopicListItem.featureTopic_ids[i]).isStandardFeature === false && this.featureTopicService.getFeatureTopicBy_id(this.featureIndexTopicListItem.featureTopic_ids[i]).isTab === true ){
          featureTopic_ids_names.push(this.featureTopicService.getFeatureTopicBy_id(this.featureIndexTopicListItem.featureTopic_ids[i]).name)
          }
        }

        if ( this.indexSelection.weAreInStandardParagraphEdit === false && this.indexSelection.weAreInTabsEdit === false  ){
          if (this.featureTopicService.getFeatureTopicBy_id(this.featureIndexTopicListItem.featureTopic_ids[i]).isStandardFeature === false && this.featureTopicService.getFeatureTopicBy_id( this.featureIndexTopicListItem.featureTopic_ids[i]).isTab === false ){
          featureTopic_ids_names.push(this.featureTopicService.getFeatureTopicBy_id(this.featureIndexTopicListItem.featureTopic_ids[i]).name)
          }
        }
      }
  
      this.featureTopic_ids_names = featureTopic_ids_names
      console.log(this.featureTopic_ids_names)

  }

  onDragDropFeatureIndexTopic(){
    this.isDragDroppingFeatureIndexTopic = true 
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.featureTopic_ids_names, event.previousIndex, event.currentIndex);

    console.log ( 'event = ' , event )
    console.log ( 'item , item.__ngContext__  = ' , event.item )
    console.log ( 'event previous index / current index = ' , event.previousIndex, event.currentIndex )

    // find which _id has been moved 
    const nameOfMovedItem: string =  this.featureTopic_ids_names[event.currentIndex]
    console.log('name of removed item = ',nameOfMovedItem)
    const _idRemovedItem: string = this.featureTopicService.getFeatureTopic(this.featureTopicService.getFeatureTopics().findIndex(x=> x.name === nameOfMovedItem ))._id
    console.log('_id of removed item = ', _idRemovedItem)

    // list of singleFeature featureTopic_ids
    const listOfSingleFeatureTopic_ids: string[] = []
    this.totLengthOfSingleFeatures = 0
      for (let i=0; i<this.featureIndexTopicListItem.featureTopic_ids.length ; i++){
        if( this.featureTopicService.getFeatureTopicBy_id(this.featureIndexTopicListItem.featureTopic_ids[i]).isStandardFeature === false && this.featureTopicService.getFeatureTopicBy_id(this.featureIndexTopicListItem.featureTopic_ids[i]).isTab === false  ){
          listOfSingleFeatureTopic_ids.push(this.featureIndexTopicListItem.featureTopic_ids[i])
          this.totLengthOfSingleFeatures = this.totLengthOfSingleFeatures+1
        }
      }
    console.log(listOfSingleFeatureTopic_ids , this.totLengthOfSingleFeatures )
    

    // list of total featureTopic_ids
    const totFeatureTopics = []
    let totFeatureTopics_ids = [].concat(totFeatureTopics, this.featureIndexTopicListItem.featureTopic_ids )
    console.log(totFeatureTopics_ids)
  

    if ( this.indexSelection.weAreInStandardParagraphEdit === true || this.indexSelection.weAreInTabsEdit === true ){
    const featureTopicToSplit_position: number = this.totLengthOfSingleFeatures + event.previousIndex
    const featureTopicToAdd_position: number = this.totLengthOfSingleFeatures + event.currentIndex
    console.log(' index to split + add = ',featureTopicToSplit_position , featureTopicToAdd_position)

    totFeatureTopics_ids.splice(featureTopicToSplit_position, 1)
    totFeatureTopics_ids.splice(featureTopicToAdd_position, 0, _idRemovedItem )

    console.log(totFeatureTopics_ids)

        const newFeatureIndexTopicList_id = this.featureIndexTopicListItem._id
        const newFeatureIndexTopicListComponentName = this.featureIndexTopicListItem.componentName
        const newFeatureIndexTopicListUIID = this.featureIndexTopicListItem.UIID
        const newFeatureIndexTopicListOwner = this.user 
        const newFeatureIndexTopicListAllUsersAllowed = this.featureIndexTopicListItem.allUsersAllowed
        const newFeatureIndexTopicListUserListAllowed = this.featureIndexTopicListItem.userListAllowed
        const newFeatureIndexTopicListDateAndTime = null
        const newFeatureIndexTopicListType = this.featureIndexTopicListItem.type
        const newFeatureIndexTopicListFeatureTopic_ids = totFeatureTopics_ids
                        
        const newFeatureIndexTopicList = new FeatureIndexTopicList( newFeatureIndexTopicList_id, newFeatureIndexTopicListComponentName, newFeatureIndexTopicListUIID,
                      newFeatureIndexTopicListOwner, newFeatureIndexTopicListAllUsersAllowed, newFeatureIndexTopicListUserListAllowed,
                      newFeatureIndexTopicListDateAndTime, newFeatureIndexTopicListType, newFeatureIndexTopicListFeatureTopic_ids )

    if ( this.user ===  null ){ 
      this.featureIndexTopicListItem = newFeatureIndexTopicList
      this.featureIndexTopicListService.featureIndexTopicListItems[this.featureIndexTopicListService.getFeatureIndexTopicListItems().findIndex(x=> x._id === this.featureIndexTopicListItem._id)] = newFeatureIndexTopicList
      this.isDragDroppingFeatureIndexTopic = false
    }
    if ( this.user !==  null ){
      // need to call http update PUT
      this.postComponentsHttpRequestService.httpUpdateComponent(this.user, 'featureIndexTopicList', newFeatureIndexTopicList)
          .subscribe((res)=> 
          {console.log(res)
            this.featureIndexTopicListItem = newFeatureIndexTopicList
            this.featureIndexTopicListService.featureIndexTopicListItems[this.featureIndexTopicListService.getFeatureIndexTopicListItems().findIndex(x=> x._id === this.featureIndexTopicListItem._id)] = newFeatureIndexTopicList
            this.isDragDroppingFeatureIndexTopic = false
          
          })
     }

    };


    if ( this.indexSelection.weAreInStandardParagraphEdit === false && this.indexSelection.weAreInTabsEdit === false  ){
      const featureTopicToSplit_position: number = event.previousIndex
      const featureTopicToAdd_position: number = event.currentIndex
      console.log(' index to split + add = ',featureTopicToSplit_position , featureTopicToAdd_position)
  
      totFeatureTopics_ids.splice(featureTopicToSplit_position, 1)
      totFeatureTopics_ids.splice(featureTopicToAdd_position, 0, _idRemovedItem )
  
      console.log(totFeatureTopics_ids)
  
          const newFeatureIndexTopicList_id = this.featureIndexTopicListItem._id
          const newFeatureIndexTopicListComponentName = this.featureIndexTopicListItem.componentName
          const newFeatureIndexTopicListUIID = this.featureIndexTopicListItem.UIID
          const newFeatureIndexTopicListOwner = this.user 
          const newFeatureIndexTopicListAllUsersAllowed = this.featureIndexTopicListItem.allUsersAllowed
          const newFeatureIndexTopicListUserListAllowed = this.featureIndexTopicListItem.userListAllowed
          const newFeatureIndexTopicListDateAndTime = null
          const newFeatureIndexTopicListType = this.featureIndexTopicListItem.type
          const newFeatureIndexTopicListFeatureTopic_ids = totFeatureTopics_ids
                          
          const newFeatureIndexTopicList = new FeatureIndexTopicList( newFeatureIndexTopicList_id, newFeatureIndexTopicListComponentName, newFeatureIndexTopicListUIID,
                        newFeatureIndexTopicListOwner, newFeatureIndexTopicListAllUsersAllowed, newFeatureIndexTopicListUserListAllowed,
                        newFeatureIndexTopicListDateAndTime, newFeatureIndexTopicListType, newFeatureIndexTopicListFeatureTopic_ids )
  
      if ( this.user ===  null ){ 
        this.featureIndexTopicListItem = newFeatureIndexTopicList
        this.featureIndexTopicListService.featureIndexTopicListItems[this.featureIndexTopicListService.getFeatureIndexTopicListItems().findIndex(x=> x._id === this.featureIndexTopicListItem._id)] = newFeatureIndexTopicList
        this.isDragDroppingFeatureIndexTopic = false
      }
      if ( this.user !==  null ){
        // need to call http update PUT
        this.postComponentsHttpRequestService.httpUpdateComponent(this.user, 'featureIndexTopicList', newFeatureIndexTopicList)
          .subscribe((res)=> 
          {console.log(res)
            this.featureIndexTopicListItem = newFeatureIndexTopicList
            this.featureIndexTopicListService.featureIndexTopicListItems[this.featureIndexTopicListService.getFeatureIndexTopicListItems().findIndex(x=> x._id === this.featureIndexTopicListItem._id)] = newFeatureIndexTopicList
            this.isDragDroppingFeatureIndexTopic = false
          
          })
       }
    }

  
  }



  ngOnDestroy(){
    this.updateOnUserEmail.unsubscribe()
    this.indexSelectionSubscription.unsubscribe()
  }



}
