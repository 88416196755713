
<h2 style="color: #3f51b5">{{rangeInfoResult.name}}</h2>

    <form #form="ngForm" >
      <section>
  
        <div *ngFor="let detail of rangeInfoResult.rangeDetailResults; let i=index">
          <div class="checkbox-row">
            <p style="color:#3f51b5; font-weight: bold;" *ngIf="detail.selected">
              <input *ngIf="!isFalseEditing"
                     type="checkbox" 
                     [(ngModel)]="detail.selected" 
                     [name]="'checkbox-' + i"
                     (ngModelChange)="onCheckboxChange($event, i)"
                   > 
        
              {{ detail.name }}
            </p>
            <p *ngIf="!detail.selected">
                <input type="checkbox" 
                       [ngModel]="detail.selected" 
                       [name]="'checkbox-' + i"
                       [disabled]="true"
                     > 
          
                {{ detail.name }}
            </p>
           
          </div>
        </div>
        
      </section>
    
    </form>
