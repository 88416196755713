
    <div class="col" *ngIf="this.userExist">
        <app-chat-form-result-list 
                  [chat]="chat"
                  [subscribeUpdatedFormResults]="subscribeNewFormResults.asObservable()"
                   >
        </app-chat-form-result-list>
    </div>
 







