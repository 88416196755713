<!-- <p>form-feature-container works!</p> -->
<div *ngIf="!isLoading">
<div *ngIf="formExist">
  
<!-- <div *ngIf="!formFeatureContainer.isAccepted" 
     class="example-box pointer" 
     (click)="onAcceptFormContainer()"
     style="color:red"  > 
     {{formName}}
</div> -->

<div *ngIf="!formFeatureContainer.isAccepted" 
     matTooltip="CLICK TO ACCEPT / DECLINE THIS FORM"
     matTooltipPosition="right"
     class="example-box"> 

     <button *ngIf="!(isHandset$ | async)"
             class="btn btn-primary"  
             type="button" 
             matBadge="1" matBadgeColor="accent"
             (click)="onAcceptFormContainer()">
      {{formName}}
    </button>

     <button *ngIf="isHandset$ | async"
             class="btn btn-primary"  
             type="button" 
             matBadge="1" matBadgeColor="accent"
             (click)="onAcceptFormContainer()">
      click / {{formName}}
    </button>
</div>



<!-- add long press to accept  -->


<div *ngIf="typeOfFormIndexTopic.isPersonal"
matTooltipPosition="right">
<div *ngIf="formFeatureContainer.isAccepted" > 
<div *ngIf="formFeatureContainer.formFeatureState" >

  <app-form-feature 
  [typeOfFormIndexTopic]="typeOfFormIndexTopic"
  [formIndexTopic]="formIndexTopic"
  [index]="index"
  [form_id]="formFeatureContainer.form_id"
  (adviseFormListOnLoadingForm)="onAdviseFormListOnLoadingForm()">
</app-form-feature>


</div>
</div>
</div>

<div *ngIf="typeOfFormIndexTopic.isShared">
<div *ngIf="formFeatureContainer.isAccepted" > 
  <div *ngIf="formFeatureContainer.formFeatureState" >
    <div *ngIf="formFeatureContainer.isActive">
  
    <app-form-feature 
    [typeOfFormIndexTopic]="typeOfFormIndexTopic"
    [formIndexTopic]="formIndexTopic"
    [index]="index"
    [form_id]="formFeatureContainer.form_id"
    (adviseFormListOnLoadingForm)="onAdviseFormListOnLoadingForm()">
  </app-form-feature>
  
    </div>
  </div>
</div>
</div>

<div *ngIf="typeOfFormIndexTopic.isPreferred">
  <div *ngIf="formFeatureContainer.isAccepted" > 
    <div *ngIf="formFeatureContainer.formFeatureState" >
    
      <app-form-feature 
      [typeOfFormIndexTopic]="typeOfFormIndexTopic"
      [formIndexTopic]="formIndexTopic"
      [index]="index"
      [form_id]="formFeatureContainer.form_id"
      (adviseFormListOnLoadingForm)="onAdviseFormListOnLoadingForm()">
    </app-form-feature>
    
    
    </div>
  </div>
  </div>

<div *ngIf="typeOfFormIndexTopic.isPublic">
<div *ngIf="formFeatureContainer.isAccepted" > 
  <div *ngIf="formFeatureContainer.formFeatureState" >
  
    <div *ngIf="!isBeenAddedToPreferred && !(isHandset$ | async)"
         longPress 
         (mouseLongPress)="onAddFormToPreferred(formFeatureContainer.form_id)"
         matTooltip="LONG PRESS TO ADD AS PREFERRED"
         matTooltipClass="example-tooltip-red"
         matTooltipPosition="right">
        <app-form-feature 
              [typeOfFormIndexTopic]="typeOfFormIndexTopic"
              [formIndexTopic]="formIndexTopic"
              [index]="index"
              [form_id]="formFeatureContainer.form_id"
              (adviseFormListOnLoadingForm)="onAdviseFormListOnLoadingForm()">
         </app-form-feature>
    </div>

    <div *ngIf="!isBeenAddedToPreferred && (isHandset$ | async)"
         longPress 
         (mouseLongPress)="onAddFormToPreferred(formFeatureContainer.form_id)">
           <app-form-feature 
               [typeOfFormIndexTopic]="typeOfFormIndexTopic"
               [formIndexTopic]="formIndexTopic"
               [index]="index"
               [form_id]="formFeatureContainer.form_id"
               (adviseFormListOnLoadingForm)="onAdviseFormListOnLoadingForm()">
           </app-form-feature>
           <p class="half-small d-flex justify-content-end align-items-end" style="color:#3f51b5" > long press add to preferred</p>
     </div>

    <div *ngIf="isBeenAddedToPreferred">
           <app-form-feature 
           [typeOfFormIndexTopic]="typeOfFormIndexTopic"
           [formIndexTopic]="formIndexTopic"
           [index]="index"
           [form_id]="formFeatureContainer.form_id"
           (adviseFormListOnLoadingForm)="onAdviseFormListOnLoadingForm()">
      </app-form-feature>

    </div>
  
  
  </div>
</div>
</div>

</div>
</div>
