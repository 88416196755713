import { Component, Input, OnInit } from '@angular/core';
import { InnerArraySelect } from '../inner-array-select.model';
import { RangeInfo } from '../range-info.model';

@Component({
  selector: 'app-inner-button-item',
  templateUrl: './inner-button-item.component.html',
  styleUrls: ['./inner-button-item.component.css']
})
export class InnerButtonItemComponent implements OnInit {

  @Input() rangeInfo: RangeInfo
  @Input() isEditing: boolean 

  innerButtonItem: InnerArraySelect

  constructor() { }

  ngOnInit(): void {
  }

}
