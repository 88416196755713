export class FeatureIndexTopicList {

    constructor ( public _id: string ,
                  public componentName: string , 
                  public UIID: string ,  
                  public owner: string , 
                  public allUsersAllowed: boolean,
                  public userListAllowed: string[],
                  public dateAndTime: string , 
                  public type: string , // personal, shared, preferred , public 
                  public featureTopic_ids: string[]){}
}