import { Component, Input, OnInit, Output, ViewChild , EventEmitter, OnDestroy } from '@angular/core';
import { CdkDragDrop , moveItemInArray, copyArrayItem  } from '@angular/cdk/drag-drop';
import { MatTabGroup } from '@angular/material/tabs';
import { Feature } from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/feature.model';
import { DragDropService } from 'src/app/forms/dragdrop.service';
import { FormGroup , FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

import { MultiFeatures } from '../multi-features.model';
import { MultiFeaturesService } from '../multi-features.service';
import { FeatureTopicService } from '../../../../paragraph-edit/feature-topic.service';
import { FeatureIndexSelection } from '../../../../paragraph-edit/feature-index-topic-list/feature-index-selection.model';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { HeaderService } from 'src/app/header/header.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions} from '@angular/material/tooltip';
import { ParagraphService } from '../../../../../paragraph.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';

interface Editing {
  isEditingStandard: boolean,
  isEditingMultiFeature: boolean ,
  isEditingTab: boolean
}

/** Custom options the configure the tooltip's default show/hide delays. */
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 2000,
  hideDelay: 100,
  touchendHideDelay: 1000,
};



@Component({
  selector: 'app-multi-features-edit',
  templateUrl: './multi-features-edit.component.html',
  styleUrls: ['./multi-features-edit.component.css'],
  providers: [{provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults}],

})
export class MultiFeaturesEditComponent implements OnInit , OnDestroy {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  user: string

  weAreInMultiFeatureEdit: boolean = true ;

  multiFeatureNameForm: FormGroup;
  receiveMultiFeatureId: Subscription;
  actualMultiFeatureId: number ;

  @ViewChild('tabs') tabGroup: MatTabGroup

  /** HERE START THE PROPERTIES FOR THE SECOND DROP */

  feature: Feature;
  features: Feature[];

  @Input() multiFeature: MultiFeatures;
  multiFeatures: MultiFeatures[];
  isParagraphEdit: boolean = true ;

  @Input() changeMultiFeatureIndex: number

   /** droppoed help hide or unhide the buttons before any feature is dropped  */
   droppedSingleFeature : boolean = false ;

   droppedSingleFeatureId : number ;

   @Output() windexSingleFeature : number ;

   previxSingleFeature: number ;

   countSingleFeature: 0;

   /**this number will serve as a indication on which item can stay visible to edit */
   isEditMultiFeature : boolean = false ;

   multiFeatureUpdatedNameValue: string 



   count = 0;
   previx : number ;

   dropped = true ;

   @Output() windex : number ;

   @Input() index: number


   @Output() updateMultiFeature  = new EventEmitter<MultiFeatures>()

   holdCreateUpdateButtons_onLoadingVisual: boolean = false 

   addingNewFeature: Subscription 
   
  constructor( private DragDrop : DragDropService,
               private multiFeaturesService: MultiFeaturesService, 
               private featureTopicService: FeatureTopicService,
               private headerService: HeaderService,
               private breakpointObserver: BreakpointObserver,
               private userObjectUpdateService: UserObjectUpdateService ,
               private postComponentsHttpRequestService: PostComponentsHttpRequestService ,
               private paragraphService: ParagraphService,

               ) {
            
                }

  ngOnInit() {

    this.user = this.userObjectUpdateService.userEmail

     if ( this.multiFeature.features.length === 0 ){ this.droppedSingleFeature = true  }
     if ( this.multiFeature.features.length > 0){ this.droppedSingleFeature = false }

     this.features = this.multiFeature.features 
     this.multiFeatureNameForm = new FormGroup({
      name : new FormControl (this.multiFeature.name ),
     })

     const  newIsEditing: Editing = { isEditingStandard: false , isEditingMultiFeature: true, isEditingTab: false }
     this.paragraphService.adviseFeatureContainerIsEditing(newIsEditing)



    this.multiFeatureNameForm.valueChanges.subscribe(
      (value) =>  { console.log(value)
     });

     this.addingNewFeature = this.multiFeaturesService.addSingleFeatureToMultiFeatureEdit
         .subscribe((res: Feature)=> {
          this.onAddSingleFeature(res)
         })



  }

 
  onAddSingleFeature(addingFeature: Feature){

    this.features = this.multiFeature.features
    this.droppedSingleFeature = false ;

    const newOwner = addingFeature.owner ;  
    const newIsNew = false
    const newDropId = this.count++;
    const newFeature_id = addingFeature.feature_id;
    const newFeatureName = addingFeature.featureName;
    
    const newFeature = new Feature ( newOwner, newIsNew,  newDropId , newFeature_id, newFeatureName) ;

    this.features.push(newFeature) ;

    this.count = 0 ;
    for ( let i=0 ; i<this.features.length ; i++){
           this.features[i].dropId = this.count++
    }

    if (this.features.length === 0){
        this.droppedSingleFeature = true ;
    }

    console.log (this.features );

  }


  onDropSingleFeature(event: CdkDragDrop<Feature[]>) {

    /**
     * if on drop there is still present a feature with "noCase name we take it off"
     */
  
   this.features = this.multiFeature.features
   this.droppedSingleFeature = false ;
   console.log(this.multiFeature)
    this.DragDrop.dropSingleFeature(event);

      /** here we avoid to add singleFeature features with this if */
      // if (this.features[event.currentIndex].featureName !== 'single word'){
      //   this.features.splice(event.currentIndex, 1);
        
      // }

    
    console.log(this.multiFeature)

  
    this.dropped = false ;
    this.windex = event.currentIndex;
    this.previx = event.previousIndex;

    if (event.previousContainer !== event.container) {

    const newOwner = this.user ;  
    const newIsNew = false
    const newDropId = this.count++;
  //  const newFeatureId = 0 ;
    const newFeature_id = this.features[event.currentIndex].feature_id;
    console.log(newFeature_id)
    const newFeatureName = this.features[event.currentIndex].featureName;
    
    const newFeature = new Feature ( newOwner, newIsNew,  newDropId , newFeature_id, newFeatureName) ;

    this.features[event.currentIndex] = newFeature ;
    console.log(this.features )
      
      }

       /** this additional function is tomake sure the dropId are always in sequences 
      */
        this.count = 0 ;
        for ( let i=0 ; i<this.features.length ; i++){
               this.features[i].dropId = this.count++
          
        }

        if (this.features.length === 0){
          this.droppedSingleFeature = true ;
        }
  
    console.log (this.features );
    
   
    
  }

  onChangedMultiFeatureIndex(event){
      this.feature = event;
      this.windex = event.currentIndex;
      console.log(event);
      /** we update the features on tghe index found , with the new feature 
       * this.features[this.feature.dropId] = this.feature */  
      
      this.features[this.feature.dropId] = this.feature;
  
       /** this additional function is tomake sure the dropId are always different  */
      this.count = 0 ;
      for ( let i=0 ; i<this.features.length ; i++){
         this.features[i].dropId = this.count++
         }
      this.count = 0 ;
  
      console.log(this.features);
  

  }

  /** emit an event to update paragraphEdit */
  onUpdateMultiFeatureEdit(){
    const new_id = this.multiFeature._id ;
    console.log(this.multiFeature._id)
    const newComponentName = this.multiFeature.componentName
    const newUIID = this.multiFeature.UIID
    const newOwner = this.user
    const newDateAndTime = null ;
    const newAllUsersAllowed = this.multiFeature.allUsersAllowed ;
    const newUserListAllowed = this.multiFeature.userListAllowed ;
    const newName = this.multiFeatureNameForm.value.name ;
    const newFeatures = this.features ;
    const newSingleBase_id_list = []
    for ( let i=0; i<this.features.length ; i++){
      newSingleBase_id_list.push(this.features[i].feature_id)
    }

    const newMultiFeatures = new MultiFeatures ( new_id, newComponentName, newUIID, newOwner, newDateAndTime, newAllUsersAllowed , 
                             newUserListAllowed, newName, newFeatures, newSingleBase_id_list ) ;

    if ( this.user !== null ){
      this.postComponentsHttpRequestService.httpUpdateComponent( this.user, 'multiFeature' , newMultiFeatures ).subscribe( response => {
        console.log( response)
      
      } , error => {
        console.log('THE HAS BEEN AN AEEOR ON UPDATING ', error )
      })

    }
        this.multiFeature = newMultiFeatures;
        this.multiFeaturesService.onUpdateMultiFeature(newMultiFeatures)
      
        this.updateMultiFeature.emit() ;
        console.log(this.multiFeature)
    
        const newUpdateFeatureIndexSelection = new FeatureIndexSelection ( true, false )
        this.featureTopicService.nextIndexSelection(newUpdateFeatureIndexSelection)
    
        this.headerService.nextComponentIndexSelection(false)

        const  newIsEditing: Editing = { isEditingStandard: true , isEditingMultiFeature: false, isEditingTab: false }
        this.paragraphService.adviseFeatureContainerIsEditing(newIsEditing)

  }




  onRemoveMultiFeature(i){
    this.features.splice(i, 1)
    console.log( this.features);

     /** this additional function is tomake sure the dropId are always different  */
     this.count = 0 ;
     for ( let i=0 ; i<this.features.length ; i++){
        this.features[i].dropId = this.count++
        } 
     this.count = 0 ;

     if (this.features.length === 0){
      this.droppedSingleFeature = true ;
    }
  }

  onHoldCreateUpdateButtons(){
    this.holdCreateUpdateButtons_onLoadingVisual = true 

    setTimeout(()=>{
      this.holdCreateUpdateButtons_onLoadingVisual = false 
    }, 4500)
  }

  ngOnDestroy(): void {
      this.addingNewFeature.unsubscribe()
  }


  // onEditMultiFeatures(i){}



}
