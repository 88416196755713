<!-- <p>check-box-select-item works!</p> -->

<section class="example-section" [formGroup]="toppings">
    <h4> select F&B venue type</h4>
    <p><mat-checkbox formControlName="restaurant">restaurant</mat-checkbox></p>
    <p><mat-checkbox formControlName="bar">bar</mat-checkbox></p>
    <p><mat-checkbox formControlName="lounge">lounge</mat-checkbox></p>
    <p><mat-checkbox formControlName="club">club</mat-checkbox></p>
    <p><mat-checkbox formControlName="foodTruck">food truck</mat-checkbox></p>

  </section>


  
  <!-- <section class="example-section" [formGroup]="toppings">
    <h4>You chose:</h4>
    {{toppings.value | json}}
  </section> -->
