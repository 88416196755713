import { Component, OnInit } from '@angular/core';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';

@Component({
  selector: 'app-contact-edit',
  templateUrl: './contact-edit.component.html',
  styleUrls: ['./contact-edit.component.css']
})
export class ContactEditComponent implements OnInit {

  user: string

  constructor(  private userObjectUpdateService: UserObjectUpdateService ) { }

  ngOnInit(): void {

    this.user = this.userObjectUpdateService.userEmail
  }

}
