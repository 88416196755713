import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http"
import { environment } from "src/environments/environment";


@Injectable ({
    providedIn: 'root' 
})

export class GetComponentsHttpRequestService {

    private domain: string

    constructor (private http: HttpClient  ){
        this.domain = environment.domain
    }

    httpGetComponentsByType(
            user: string ,
            type: string ){

                return this.http.get(
                    encodeURI(`${this.domain}`)+ encodeURI('market-in-service/rest/component?objectType=') 
                                                                                                    + encodeURIComponent(type) 
                                                                                                    + encodeURI('&user=') 
                                                                                                    + encodeURIComponent(user)
                )
    };

    httpGetUserComponentBy_id(user: string , component_id: string ){

        return this.http.get(
            encodeURI(`${this.domain}`) + encodeURI('market-in-service/rest/componentWithChild/') + encodeURIComponent(component_id)
        )
    }


    /**
     *  
     * @param user 
     * @param typeOfIndex 
     * @param objectType shpuld be paragraphFeatureIndex or formFeatureIndex 
     * @returns 
     */
    httpGetUserComponentsByTypeOfIndexAndObjectType( 
            user : string , typeOfIndex: string, objectType: string){

                return this.http.get(
                    encodeURI(`${this.domain}`) + encodeURI('market-in-service/rest/' ) + encodeURIComponent(typeOfIndex)
                                                                                + encodeURI('/components?objectType=')   
                                                                                + encodeURIComponent(objectType)     
                                                                                + encodeURI('&user=')
                                                                                + encodeURIComponent(user)
                    
                    
                )

    };

    httpGetPublicComponentByUserAndObject_id( user : string , component_id: string  ){

        return this.http.get(
            encodeURI(`${this.domain}`) + encodeURI('market-in-service/rest/public/components?user=' ) + encodeURIComponent(user)
                                                                                               + encodeURI('&componentId=')
                                                                                               + encodeURIComponent(component_id)
        )
    }

    httpGetPublicComponentsByTypeOfObjectAndOwnerAndUser( objectType: string , owner: string , user: string){

        return this.http.get(
            encodeURI(`${this.domain}`) + encodeURI('market-in-service/rest/public/components?objectType=') + encodeURIComponent(objectType)
                                                                                                    + encodeURI('&user=')
                                                                                                    + encodeURIComponent(user)
                                                                                                    + encodeURI('&owner=')
                                                                                                    + encodeURIComponent(owner)
        )
    }


    httpGetUserComponentWithChildsBy_id(component_id: string ){

        return this.http.get(
            encodeURI(`${this.domain}`) + encodeURI('market-in-service/rest/componentWithChild/') + encodeURIComponent(component_id)  
        )
    }



    httpGetUserComponentsWithChildsByTypeAndUser(
                                    type: string , 
                                    user: string){

        return this.http.get( 
            encodeURI(`${this.domain}`) + encodeURI('market-in-service/rest/componentWithChild?objectType=') + encodeURIComponent(type) 
                                                                                                     + encodeURI('&user=') 
                                                                                                     + encodeURIComponent(user)
        ) 
    };


    httpGetAllSharedObjectsByUser(
            user: string ){

                return this.http.get(
                    encodeURI(`${this.domain}`) + encodeURI('market-in-service/rest/public/components?user=') + encodeURIComponent(user)
                )
    }


     // this should be used to get most of formFeatures and childs when changing clicking on formTopic and 
    // getting only the precise formsFeatures of a selected email  

    // could be also done for paragraphFeatures when the app become bigger 

    getComponentsWithChildsByColumnAndObjectTypeAndUserLastSeqOwner( columnType: string ,objectType: string , user: string, lastSeq: string , owner: string ){
        return this.http.get(
            encodeURI(`${this.domain}`) + encodeURI('market-in-service/rest/') + encodeURIComponent(columnType) // personal / shared /preferred
                                                                       + encodeURI('/componentsWithChilds?objectType=')
                                                                       + encodeURIComponent(objectType) 
                                                                       + encodeURI('&user=')
                                                                       + encodeURIComponent(user)  
                                                                       + encodeURI('&lastSeq=')
                                                                       + encodeURIComponent(lastSeq)
                                                                       + encodeURI('&owner=')
                                                                       + encodeURIComponent(owner)
        )
        
    }


     // this should be used to get most of paragraphFeatures and childs when changing paragraphIndexTopics

     getComponentsWithChildsByColumnAndObjectTypeAndUser( typeOfIndex: string ,
        objectType: string , 
        user: string){

        return this.http.get(
            encodeURI(`${this.domain}`) + encodeURI('market-in-service/rest/') 
      + encodeURIComponent(typeOfIndex) // personal / shared /preferred
      + encodeURI('/componentsWithChilds?objectType=')
      + encodeURIComponent(objectType) 
      + encodeURI('&user=')
      + encodeURIComponent(user)  
      )
      
    }


    getComponentWithChildsByUserAndTypeOfIndexAndComponent_id( user: string , typeOfIndex: string , component_id: string ){

        return this.http.get(
            encodeURI(`${this.domain}`) + encodeURI('market-in-service/rest/') + encodeURIComponent(typeOfIndex) // share /preference / public 
                                                                       + encodeURI('/componentsWithChilds?user=')
                                                                       + encodeURIComponent(user)
                                                                       + encodeURI('&componentId=')
                                                                       + encodeURIComponent(component_id)


        )

    };

// ------------ START GET FORM RESULTS -----------------------------------------------------------------// 
    

    /**
     * first call of results without lastSEQ 
     * getting message formResults by type , user, dates, and count 
     */

    /**
     * 
     * @param type  MESSAGE FIRST CALL / NO SUBJECT 
     * @param user 
     * @param dateFrom 
     * @param dateTo 
     * @param count 
     * @returns 
     */
    getMessageFormResults_Type_User_DateFrom_DateTo_Count( type: string , user: string , dateFrom: string , dateTo: string , count: number ){

        return this.http.get(
            encodeURI(`${this.domain}`) + encodeURI('market-in-service/rest/formresult/') + encodeURIComponent(type) // send , rceive - all 
                                                                                  + encodeURI('?user=')
                                                                                  + encodeURIComponent(user)
                                                                                  + encodeURI('&dateFrom=')
                                                                                  + encodeURIComponent(dateFrom)
                                                                                  + encodeURI('&dateTo=')
                                                                                  + encodeURIComponent(dateTo) // 2023-03-10T18:00:00 example date 
                                                                                  + encodeURI('&count=')
                                                                                  + encodeURIComponent(count)
        )

    }

         /**
     * getting formResults by type , user, Subject/Paragraph, dates, and count 
     */

    //  &paragDescr=home fitting windows
    /**
     * 
     * @param type  MESSAGE FIRST CALL 
     * @param user 
     * @param subjParag_type  WITH SUBJECT / PARAGRAPH
     * @param subjParag_title 
     * @param dateFrom 
     * @param dateTo 
     * @param count 
     * @returns 
     */
    getMessageFormResults_Type_User_SubjectParagraph_DateFrom_DateTo_Count( type: string , user: string , 
                                                                            subjParag_type: string, subjParag_title: string, 
                                                                            dateFrom: string , dateTo: string , 
                                                                            count: number ){

                return this.http.get(
                    encodeURI(`${this.domain}`) + encodeURI('market-in-service/rest/formresult/') + encodeURIComponent(type) 
                                                                                                       + encodeURI('?user=')
                                                                                                       + encodeURIComponent(user)
                                                                                                       + encodeURI('&')
                                                                                                       + encodeURIComponent(subjParag_type) // paragDescr or subject
                                                                                                       + encodeURI('=')
                                                                                                       + encodeURIComponent(subjParag_title)
                                                                                                       + encodeURI('&dateFrom=')
                                                                                                       + encodeURIComponent(dateFrom)
                                                                                                       + encodeURI('&dateTo=')
                                                                                                       + encodeURIComponent(dateTo) // 2023-03-10T18:00:00 example date 
                                                                                                       + encodeURI('&count=')
                                                                                                       + encodeURIComponent(count)
                )

    }

       // ---------------------------  start second kind of call with LAST SEQ --------------------------------------------------------------------------------------------------------------------
    /**
     * getting formResults by type , user, dates, and count + last Seq 
     */

    /**
     * 
     * @param type MESSAGE SECOND CALL NO PARAG/ NO SUBJECT
     * @param user 
     * @param dateFrom 
     * @param dateTo 
     * @param count 
     * @param lastSeq 
     * @returns 
     */
    getMessageFormResults_Type_User_DateFrom_DateTo_Count_LastSeq( type: string , user: string , dateFrom: string , 
                                                                   dateTo: string , count: number , lastSeq: number){

            return this.http.get(
                encodeURI(`${this.domain}`) + encodeURI('market-in-service/rest/formresult/') + encodeURIComponent(type) // send , rceive - all 
                                                                                                   + encodeURI('?user=')
                                                                                                   + encodeURIComponent(user)
                                                                                                   + encodeURI('&dateFrom=')
                                                                                                   + encodeURIComponent(dateFrom)
                                                                                                   + encodeURI('&dateTo=')
                                                                                                   + encodeURIComponent(dateTo) // 2023-03-10T18:00:00 example date 
                                                                                                   + encodeURI('&count=')
                                                                                                   + encodeURIComponent(count)
                                                                                                   + encodeURI('&lastSeq=')
                                                                                                   + encodeURIComponent(lastSeq)
            )

    };

        /**
     * getting formResults by type , user, subject/paragraph,  dates, and count + LAST SEQ 
     */

     /**
      * 
      * @param type   MESSAGE
      * @param user 
      * @param subjParag_type   SUBJECT OR PARAGRAPH SECOND CALL 
      * @param subjParag_title 
      * @param dateFrom 
      * @param dateTo 
      * @param count 
      * @param lastSeq 
      * @returns 
      */
     getMessageFormResults_Type_User_SubjectParagraph_DateFrom_DateTo_Count_LastSeq( type: string , 
                                                                                     user: string , 
                                                                                     subjParag_type: string, 
                                                                                     subjParag_title: string, 
                                                                                     dateFrom: string , 
                                                                                     dateTo: string , 
                                                                                     count: number , 
                                                                                     lastSeq: number ){

                return this.http.get(
                    encodeURI(`${this.domain}`) + encodeURI('market-in-service/rest/formresult/') + encodeURIComponent(type) // send , rceive - all 
                                                                                                       + encodeURI('?user=')
                                                                                                       + encodeURIComponent(user)
                                                                                                       + encodeURI('&')
                                                                                                       + encodeURIComponent(subjParag_type) // paragDescr or subject
                                                                                                       + encodeURI('=')
                                                                                                       + encodeURIComponent(subjParag_title)
                                                                                                       + encodeURI('&dateFrom=')
                                                                                                       + encodeURIComponent(dateFrom)
                                                                                                       + encodeURI('&dateTo=')
                                                                                                       + encodeURIComponent(dateTo) // 2023-03-10T18:00:00 example date 
                                                                                                       + encodeURI('&count=')
                                                                                                       + encodeURIComponent(count)
                                                                                                       + encodeURI('&lastSeq=')
                                                                                                       + encodeURIComponent(lastSeq)
)

}

/**
 * -------------------- END MESSAGE  CALLS -----------------------------------------------------------------
 */

/**
 * -------------------- START EMAIL CALLS -----------------------------------------------------------------
 */

    /**
     * first call of results without lastSEQ
     * @param user 
     * @param dateFrom 
     * @param dateTo 
     * @param count 
     * @returns 
     */
    getMailFormResultsWith_User_DateFrom_DateTo_Count( user: string , dateFrom: string , dateTo: string , count: number ){

        return this.http.get(
            encodeURI(`${this.domain}`) + encodeURI('market-in-service/rest/mailformresult/send?user=') + encodeURIComponent(user)
                                                                                                + encodeURI('&dateFrom=')
                                                                                                + encodeURIComponent(dateFrom)
                                                                                                + encodeURI('&dateTo=')
                                                                                                + encodeURIComponent(dateTo) // 2023-03-10T18:00:00 example date 
                                                                                                + encodeURI('&count=')
                                                                                                + encodeURIComponent(count)
        )
    }


  



 
     /**
      * 
      * @param user 
      * @param subjParag_type 
      * @param subjParag_title 
      * @param dateFrom 
      * @param dateTo 
      * @param count 
      * @returns 
      */
     getMailFormResultsWith_User_SubjectParagraph_DateFrom_DateTo_Count( user: string , subjParag_type: string, 
                                                                         subjParag_title: string, dateFrom: string , 
                                                                         dateTo: string , count: number ){

        return this.http.get(
            encodeURI(`${this.domain}`) + encodeURI('market-in-service/rest/mailformresult/send?user=') + user
                                                                                                + encodeURI('&')
                                                                                                + subjParag_type // paragDescr or subject
                                                                                                + encodeURI('=')
                                                                                                + subjParag_title
                                                                                                + encodeURI('&dateFrom=')
                                                                                                + dateFrom
                                                                                                + encodeURI('&dateTo=')
                                                                                                + dateTo // 2023-03-10T18:00:00 example date 
                                                                                                + encodeURI('&count=')
                                                                                                + count
        )
 
     } 





  

    /**
     * 
     * @param user 
     * @param dateFrom 
     * @param dateTo 
     * @param count 
     * @param lastSeq 
     * @returns 
     */
    getMailFormResultsWith_User_DateFrom_DateTo_Count_LastSeq( user: string , dateFrom: string , dateTo: string , 
                                                               count: number, lastSeq: number  ){

        return this.http.get(
            encodeURI(`${this.domain}`) + encodeURI('market-in-service/rest/mailformresult/send?user=') + encodeURIComponent(user)
                                                                                                + encodeURI('&dateFrom=')
                                                                                                + encodeURIComponent(dateFrom)
                                                                                                + encodeURI('&dateTo=')
                                                                                                + encodeURIComponent(dateTo) // 2023-03-10T18:00:00 example date 
                                                                                                + encodeURI('&count=')
                                                                                                + encodeURIComponent(count)
                                                                                                + encodeURI('&lastSeq=')
                                                                                                + encodeURIComponent(lastSeq)
        )

    }


    

 

   
 
     /**
      * 
      * @param user 
      * @param subjParag_type 
      * @param subjParag_title 
      * @param dateFrom 
      * @param dateTo 
      * @param count 
      * @param lastSeq 
      * @returns 
      */
     getMailFormResultsWith_User_SubjectParagraph_DateFrom_DateTo_Count_LastSeq( user: string ,
                                                                                 subjParag_type: string, 
                                                                                 subjParag_title: string, 
                                                                                 dateFrom: string , 
                                                                                 dateTo: string , 
                                                                                 count: number, 
                                                                                 lastSeq: number  ){

        return this.http.get(
            encodeURI(`${this.domain}`) + encodeURI('market-in-service/rest/mailformresult/send?user=') + encodeURIComponent(user)
                                                                                                + encodeURI('&')
                                                                                                + encodeURIComponent(subjParag_type) // paragDescr or subject
                                                                                                + encodeURI('=')
                                                                                                + encodeURIComponent(subjParag_title)
                                                                                                + encodeURI('&dateFrom=')
                                                                                                + encodeURIComponent(dateFrom)
                                                                                                + encodeURI('&dateTo=')
                                                                                                + encodeURIComponent(dateTo) // 2023-03-10T18:00:00 example date 
                                                                                                + encodeURI('&count=')
                                                                                                + encodeURIComponent(count)
                                                                                                + encodeURI('&lastSeq=')
                                                                                                + encodeURIComponent(lastSeq)
        )
 
     };

     /**
      * 
      * ---------  START GET RESULT WITH USERCHATROOMID FILTER ------------------------------------------------
      */


     getAllChats(){
       
        return this.http.get(
            encodeURI(`${this.domain}`) + encodeURI('market-in-service/rest/chatRoom/all?user=beppe@beppe.com&countCR=10&countFR=3')
        )
     }


     getAll_Initial_Chats_User_ChatsCount_FormresultsCount( user: string , 
                                                            dateFrom: string , 
                                                            dateTo: string,
                                                            chatCount: number, 
                                                            formResultsCount: number 
                                                            ){
     
        return this.http.get(
            encodeURI(`${this.domain}`) + encodeURI('market-in-service/rest/chatRoom/all?user=') + encodeURIComponent(user)
                                                                                         + encodeURI('&dateFrom=')
                                                                                         + encodeURIComponent(dateFrom)
                                                                                         + encodeURI('&dateTo=')
                                                                                         + encodeURIComponent(dateTo)
                                                                                         + encodeURI('&countCR')
                                                                                         + encodeURIComponent(chatCount)
                                                                                         + encodeURI('&countFR')
                                                                                         + encodeURIComponent(formResultsCount)

        )
         
     }


     get_CHAT_InitialFormResults_UserChatRoomId_Count( userEmail: string , userChatRoomId: string , count: number ){

        return this.http.get(
            encodeURI(`${this.domain}`) + encodeURI('market-in-service/rest/formresult/all') + encodeURI('?user=')
                                                                                     + encodeURIComponent(userEmail)
                                                                                     + encodeURI('&userChatRoomId=')
                                                                                     + encodeURIComponent(userChatRoomId)
                                                                                     + encodeURI('&count=')
                                                                                     + encodeURIComponent(count)
        )
     }

       /**
     * first call with userChatRoomId NO subject/paragraph / no lastSeq 
     * this is for the first call on selecting a chat to watch , both on results and chatItem pages 
     * @param type 
     * @param user 
     * @param userChatRoomId 
     * @param dateFrom 
     * @param dateTo 
     * @param count 
     * @returns 
     */
    get_CHAT_MessageFormResults_Type_User_DateFrom_DateTo_Count_UserChatRoomId( type: string , user: string , userChatRoomId: string, dateFrom: string , dateTo: string , count: number ){

        return this.http.get(
            encodeURI(`${this.domain}`) + encodeURI('market-in-service/rest/formresult/') + encodeURIComponent(type) // send , rceive - all 
                                                                                  + encodeURI('?user=')
                                                                                  + encodeURIComponent(user)
                                                                                  + encodeURI('&userChatRoomId=')
                                                                                  + encodeURIComponent(userChatRoomId)
                                                                                  + encodeURI('&dateFrom=')
                                                                                  + encodeURIComponent(dateFrom)
                                                                                  + encodeURI('&dateTo=')
                                                                                  + encodeURIComponent(dateTo) // 2023-03-10T18:00:00 example date 
                                                                                  + encodeURI('&count=')
                                                                                  + encodeURIComponent(count)
        )

    }

    /**
     *  FIRST CALL CHATROOM WITH SUBJECT/PARAGRAPH NO LAST SEQ 
     * @param type 
     * @param user 
     * @param userChatRoomId 
     * @param subjParag_type 
     * @param subjParag_title 
     * @param dateFrom 
     * @param dateTo 
     * @param count 
     * @returns 
     */
    get_CHAT_MessageFormResults_Type_User_DateFrom_DateTo_Count_UserChatRoomId__SubjectParagraph( type: string , 
                                                                                                  user: string , 
                                                                                                  userChatRoomId: string, 
                                                                                                  subjParag_type: string, 
                                                                                                  subjParag_title: string, 
                                                                                                  dateFrom: string , 
                                                                                                  dateTo: string , 
                                                                                                  count: number ){

             return this.http.get( 
                encodeURI(`${this.domain}`) + encodeURI('market-in-service/rest/formresult/') + encodeURIComponent(type) // send , rceive - all 
                                                                                          + encodeURI('?user=')
                                                                                          + encodeURIComponent(user)
                                                                                          + encodeURI('&userChatRoomId=')
                                                                                          + encodeURIComponent(userChatRoomId)
                                                                                          + encodeURI('&')
                                                                                          + encodeURIComponent(subjParag_type) // paragDescr or subject
                                                                                          + encodeURI('=')
                                                                                          + encodeURIComponent(subjParag_title)
                                                                                          + encodeURI('&dateFrom=')
                                                                                          + encodeURIComponent(dateFrom)
                                                                                          + encodeURI('&dateTo=')
                                                                                          + encodeURIComponent(dateTo) // 2023-03-10T18:00:00 example date 
                                                                                          + encodeURI('&count=')
                                                                                          + encodeURIComponent(count)
              )

}
/**
 *  GET CHAT SECOND CALL RESULTS WITH LAST SEQ - NO SUBJECT
 * @param type 
 * @param user 
 * @param userChatRoomId 
 * @param dateFrom 
 * @param dateTo 
 * @param count 
 * @param lastSeq 
 * @returns 
 */
get_CHAT_MessageFormResults_Type_User_UserChatRoomId_DateFrom_DateTo_Count_LastSeq( type: string , user: string , userChatRoomId: string, dateFrom: string , 
                                                                                    dateTo: string , count: number , lastSeq: number){

                return this.http.get(
                    encodeURI(`${this.domain}`) + encodeURI('market-in-service/rest/formresult/') + encodeURIComponent(type) // send , rceive - all 
                                                                                                       + encodeURI('?user=')
                                                                                                       + encodeURIComponent(user)
                                                                                                       + encodeURI('&userChatRoomId=')
                                                                                                       + encodeURIComponent(userChatRoomId)
                                                                                                       + encodeURI('&dateFrom=')
                                                                                                       + encodeURIComponent(dateFrom)
                                                                                                       + encodeURI('&dateTo=')
                                                                                                       + encodeURIComponent(dateTo) // 2023-03-10T18:00:00 example date 
                                                                                                       + encodeURI('&count=')
                                                                                                       + encodeURIComponent(count)
                                                                                                       + encodeURI('&lastSeq=')
                                                                                                       + encodeURIComponent(lastSeq)
)

}

/**
 *  get  following 
 * @param user 
 * @param userChatRoomId 
 * @param count 
 * @param lastSeq 
 * @returns 
 */
get_CHAT_Following_FormResults_UserChatRoomId_Count_LastSeq(user: string , userChatRoomId: string, count: number , lastSeq: number ){

    return this.http.get(
        encodeURI(`${this.domain}`) + encodeURI('market-in-service/rest/formresult/all') + encodeURI('?user=')
                                                                                              + encodeURIComponent(user)
                                                                                              + encodeURI('&userChatRoomId=')
                                                                                              + encodeURIComponent(userChatRoomId)
                                                                                              + encodeURI('&count=')
                                                                                              + encodeURIComponent(count)
                                                                                              + encodeURI('&lastSeq=')
                                                                                              + encodeURIComponent(lastSeq)

    )

}

/**
 * GET CHAT SECOND CALL RESULTS WITH LAST SEQ AND SUBJECT
 * @param type 
 * @param user 
 * @param userChatRoomId 
 * @param subjParag_type 
 * @param subjParag_title 
 * @param dateFrom 
 * @param dateTo 
 * @param count 
 * @param lastSeq 
 * @returns 
 */
get_CHAT_MessageFormResults_Type_User_UserChatRoomId_SubjectParagraph_DateFrom_DateTo_Count_LastSeq( type: string , 
                                                                                                     user: string , 
                                                                                                     userChatRoomId: string,
                                                                                                     subjParag_type: string, 
                                                                                                     subjParag_title: string, 
                                                                                                     dateFrom: string , 
                                                                                                     dateTo: string , 
                                                                                                     count: number , 
                                                                                                     lastSeq: number ){

            return this.http.get(
                encodeURI(`${this.domain}`) + encodeURI('market-in-service/rest/formresult/') + encodeURIComponent(type) // send , rceive - all 
                                                                                                   + encodeURI('?user=')
                                                                                                   + encodeURIComponent(user)
                                                                                                   + encodeURI('&userChatRoomId=')
                                                                                                   + encodeURIComponent(userChatRoomId)
                                                                                                   + encodeURI('&')
                                                                                                   + encodeURIComponent(subjParag_type) // paragDescr or subject
                                                                                                   + encodeURI('=')
                                                                                                   + encodeURIComponent(subjParag_title)
                                                                                                   + encodeURI('&dateFrom=')
                                                                                                   + encodeURIComponent(dateFrom)
                                                                                                   + encodeURI('&dateTo=')
                                                                                                   + encodeURIComponent(dateTo) // 2023-03-10T18:00:00 example date 
                                                                                                   + encodeURI('&count=')
                                                                                                   + encodeURIComponent(count)
                                                                                                   + encodeURI('&lastSeq=')
                                                                                                   + encodeURIComponent(lastSeq)
            )
}














}