import { Injectable } from "@angular/core";
import { MatDialog , MatDialogConfig , MatDialogRef } from '@angular/material/dialog'; 
import { Observable } from 'rxjs' ; 
import { ChatFillFormComponent } from "./chat-item/chat-edit/chat-fill-form/chat-fill-form.component";
import { NewChatDialogComponent } from "./new-chat-dialog/new-chat-dialog.component";


@Injectable ({
    providedIn: 'root'
})

export class ChatDialogService {

constructor ( private dialog: MatDialog ){}

// : Observable<any>
public ChatFillForm (data: any =[]) {

    var dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ''
    dialogConfig.disableClose = false ;
    dialogConfig.data = data;
    let dialogRef: MatDialogRef<ChatFillFormComponent>;
    dialogRef = this.dialog.open(ChatFillFormComponent, dialogConfig);
   // return dialogRef.afterClosed();

}

public CreateNewChat (data: any =[]): Observable<any> {

    var dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = ''
    dialogConfig.disableClose = false ;
    dialogConfig.data = data;
    let dialogRef: MatDialogRef<NewChatDialogComponent>;
    dialogRef = this.dialog.open(NewChatDialogComponent, dialogConfig);
    return dialogRef.afterClosed();

}



}


