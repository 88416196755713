import { Component, Input, OnInit } from '@angular/core';
import { Recipient } from '../../../recipient.model';

@Component({
  selector: 'app-group-name',
  templateUrl: './group-name.component.html',
  styleUrls: ['./group-name.component.css']
})
export class GroupNameComponent implements OnInit {

  @Input() recipient: Recipient

  constructor() { }

  ngOnInit(): void {
  }

}
