<br>

<div class="row" *ngIf="!(isHandset$ | async)">
    <div class="col-4">

                     <div class="col-xs-12">
                        <div class="row" >
                            <div class="d-flex justify-content-center align-items-center">
                                <h2 style="color: #3f51b5"> {{featureResultName}}</h2>
                            </div>
                        </div>
                     </div>
                    
                
                    <div class="custom-calendar">
                      <mat-calendar #calendar
                      *ngIf="!isUpdatingCalendar"
                      [selected]="selectedDates" 
                      [dateClass]="dateClassCallback" >
                      </mat-calendar>
                      <mat-calendar #calendar
                      *ngIf="isUpdatingCalendar"
                      [selected]="selectedDates" 
                      [dateClass]="dateClassCallback" >
                      </mat-calendar>
                    
                    </div>
                
                
        <!-- END side card -->
    </div>

   


    <div class="col-7" > 
         <!-- START SECOND side card -->

         <div class="InnerComponentFrame">
            <br>
            <app-inner-date-range-item
            [startingDate]="sharedCalendarStatusResult.start"
            [endingDate]="sharedCalendarStatusResult.end">
            </app-inner-date-range-item>
         </div>

         <div>
            <!-- *ngIf="canViewIt" -->
         <div *ngFor="let rangeInfoResult of sharedCalendarStatusResult.rangeInfoResults; let i=index">

            <div [ngSwitch]="rangeInfoResult.type"> 

                <div  *ngSwitchCase="'innerdropdown'">
                    <div class="InnerComponentFrame" >
                        <!-- *ngIf="rangeInfoResultsCanViewIt[i]" -->
                        <app-dropdown-result
                        [rangeInfoResult]="rangeInfoResult">
                        </app-dropdown-result>
                    </div>
                </div>


                <div  *ngSwitchCase="'innercheckbox'">
                    <div class="InnerComponentFrame">
                        <!-- *ngIf="rangeInfoResultsCanViewIt[i]" -->
                        <app-checkbox-result
                        [rangeInfoResult]="rangeInfoResult">
                        </app-checkbox-result>
                    </div>
                </div>


                <div  *ngSwitchCase="'innertext'">
                    <div class="InnerComponentFrame">
                        <!-- *ngIf="rangeInfoResultsCanViewIt[i]" -->
                        <app-fixtext-result
                        [rangeInfoResult]="rangeInfoResult">
                        </app-fixtext-result>
                    </div>
                </div>


                <div  *ngSwitchCase="'innerinput'">
                    <div class="InnerComponentFrame">
                        <!-- *ngIf="rangeInfoResultsCanViewIt[i]" -->
                        <app-innerinput-result
                        [rangeInfoResult]="rangeInfoResult">
                        </app-innerinput-result>
                    </div>
                </div>


            </div> 
          

         </div>
         </div>
         
         <!-- END SECOND side card -->
    </div>
<br>
</div>

<div class="row" *ngIf="isHandset$ | async">
    <div class="col">

                    <div class="col-xs-12">
                        <div class="row" >
                            <div class="d-flex justify-content-center align-items-center">
                                <h2 style="color: #3f51b5"> {{featureResultName}}</h2>
                            </div>
                        </div>
                    </div>
                
                    <div class="custom-calendar">
                      <mat-calendar #calendar
                      *ngIf="!isUpdatingCalendar"
                      [selected]="selectedDates" 
                      [dateClass]="dateClassCallback" >
                      </mat-calendar>
                      <mat-calendar #calendar
                      *ngIf="isUpdatingCalendar"
                      [selected]="selectedDates" 
                      [dateClass]="dateClassCallback" >
                      </mat-calendar>
                    
                    </div>
                
                
        <!-- END side card -->
    </div>

   


    <div class="col" > 
         <!-- START SECOND side card -->

         <div class="InnerComponentFrame">
            <br>
            <app-inner-date-range-item
            [startingDate]="sharedCalendarStatusResult.start"
            [endingDate]="sharedCalendarStatusResult.end">
            </app-inner-date-range-item>
         </div>

         <div>
            <!-- *ngIf="canViewIt" -->
         <div *ngFor="let rangeInfoResult of sharedCalendarStatusResult.rangeInfoResults; let i=index">

            <div [ngSwitch]="rangeInfoResult.type"> 

                <div  *ngSwitchCase="'innerdropdown'">
                    <div class="InnerComponentFrame">
                        <!-- *ngIf="rangeInfoResultsCanViewIt[i]" -->
                        <app-dropdown-result
                        [rangeInfoResult]="rangeInfoResult">
                        </app-dropdown-result>
                    </div>
                </div>


                <div  *ngSwitchCase="'innercheckbox'">
                    <div class="InnerComponentFrame">
                        <!-- *ngIf="rangeInfoResultsCanViewIt[i]" -->
                        <app-checkbox-result
                        [rangeInfoResult]="rangeInfoResult">
                        </app-checkbox-result>
                    </div>
                </div>


                <div  *ngSwitchCase="'innertext'">
                    <div class="InnerComponentFrame">
                        <!-- *ngIf="rangeInfoResultsCanViewIt[i]" -->
                        <app-fixtext-result
                        [rangeInfoResult]="rangeInfoResult">
                        </app-fixtext-result>
                    </div>
                </div>


                <div  *ngSwitchCase="'innerinput'">
                    <div class="InnerComponentFrame">
                        <!-- *ngIf="rangeInfoResultsCanViewIt[i]" -->
                        <app-innerinput-result
                        [rangeInfoResult]="rangeInfoResult">
                        </app-innerinput-result>
                    </div>
                </div>


            </div> 
          

         </div>
         </div>
         
         <!-- END SECOND side card -->
    </div>
<br>
</div>