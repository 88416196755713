<app-loading-spinner *ngIf="isLoading"></app-loading-spinner>

<br *ngIf="!isLoading">

<mat-tab-group *ngIf="!isLoading"
#tabs 
[(selectedIndex)]="selectedIndex"
(selectedTabChange)="onLinkClick($event)"    
animationDuration="0ms" >

<br>



      <mat-tab *ngFor="let tabDetail of tab.tabDetails; let i = index ">

          <!-- mat-tab-label -->
                <ng-template mat-tab-label >
                <a style="cursor: pointer; color: red">
                     {{i+1}} - {{tabDetail.name}}  </a>
                </ng-template>
                <br>

                <div  *ngFor="let feature of tabDetail.features ; let y = index " >


                    <div> 
             
                       <div [ngSwitch]="feature.featureName">
             
                       <app-word-input 
                       [isTabItem]="true"
                       [tab_index]="i"
                       [isParagraphEdit]="false"
                       [feature]="feature"
                       (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)'    
                       [noNeeded]="true" 
                       [index_id]="feature.feature_id" 
                       *ngSwitchCase="'wordInput'">
                       </app-word-input>
                       <!-- [subscribeToChildComponentsResultsAgain]="subscribeToChildComponentsResultsAgain.asObservable()" -->
             
                       
                       <app-multi-features 
                       [isTabItem]="true"
                       [tab_index]="i"
                       [isParagraphEdit]="false"
                       [feature]="feature"
                       (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)'
                       [noNeeded]="true" 
                       [index_id]="feature.feature_id" 
                       [paragraphEdit_selectedIndex_id]="feature.feature_id"
                       *ngSwitchCase="'multiFeatures'">
                       </app-multi-features>
                       <!-- [subscribeToChildComponentsResultsAgain]="subscribeToChildComponentsResultsAgain.asObservable()" -->

                       <app-fix-layout-item
                       [isTabItem]="true"
                       [tab_index]="i"
                       [isParagraphEdit]="false"
                       [feature]="feature"
                       (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)'
                       [noNeeded]="true"
                       [index_id]="feature.feature_id"
                       *ngSwitchCase="'fixLayout'">
                       </app-fix-layout-item>
                       <!--  [subscribeToChildComponentsResultsAgain]="subscribeToChildComponentsResultsAgain.asObservable()" -->
                     
             
                       <app-fix-text-item
                       [isTabItem]="true"
                       [tab_index]="i"
                       [isParagraphEdit]="false"
                       [feature]="feature"
                       (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)' 
                       [noNeeded]="true"
                       [index_id]="feature.feature_id"
                       *ngSwitchCase="'fixText'">
                       </app-fix-text-item>

                       <app-calendar-item
                       [isTabItem]="true"
                       [tab_index]="i"
                       [isParagraphEdit]="false"
                       [feature]="feature"
                       (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)'
                       [noNeeded]="true"
                       [index_id]="feature.feature_id"
                       *ngSwitchCase="'oneDateCalendar'">
                       </app-calendar-item>

                       <app-multi-entry-calendar-item
                       [isTabItem]="true"
                       [tab_index]="i"
                       [isParagraphEdit]="false"
                       [feature]="feature"
                       (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)'
                       [noNeeded]="true"
                       [index_id]="feature.feature_id"
                       *ngSwitchCase="'multiEntryCalendar'">
                       </app-multi-entry-calendar-item>

                       <app-shared-calendar-status-item
                       [isTabItem]="true"
                       [tab_index]="i"
                       [isParagraphEdit]="false"
                       [feature]="feature"
                       (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)'
                       [noNeeded]="true"
                       [index_id]="feature.feature_id"
                       *ngSwitchCase="'sharedCalendarStatus'">
                       </app-shared-calendar-status-item>
                       
             
                       <app-calculator-mix-table-item
                       [isTabItem]="true"
                       [tab_index]="i"
                       [isParagraphEdit]="false"
                       [feature]="feature" 
                       (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)'
                       [noNeeded]="true"
                       [index_id]="feature.feature_id"
                       *ngSwitchCase="'calculatorMixTable'">
                       </app-calculator-mix-table-item>

                  
                       <app-drop-down-select-item
                       [isTabItem]="true"
                       [tab_index]="i"
                       [isParagraphEdit]="false"
                       [feature]="feature"
                       (updateBothFeatureResultAndTextPlusIndex)='onUpdateBothFeatureResultTextResultAndIndex($event)'
                       [noNeeded]="true"
                       [index_id]="feature.feature_id"
                       *ngSwitchCase="'dropDownSelect'">
                       </app-drop-down-select-item>

                       <app-word-hint-input 
                       [isTabItem]="true"
                       [tab_index]="i"
                       [noNeeded]="true" 
                       [feature]="feature"
                       [index]="feature.featureid" 
                       *ngSwitchCase="'wordInput+sentence'">
                       </app-word-hint-input> 
             
                       <app-word-less-input 
                       [noNeeded]="true" 
                       [feature]="feature"
                       [index]="feature.featureid" 
                       *ngSwitchCase="'word simple'">
                       </app-word-less-input>
             
                       <app-phrase-button 
                       *ngSwitchCase="'phrase'">
                       </app-phrase-button>
             
                       <app-form-input    
                       *ngSwitchCase="'form'">
                       </app-form-input>
             
                       <app-horizon-add   
                       *ngSwitchCase="'mix form'">
                      </app-horizon-add>
                      </div>
               
                    </div>
                   
                 </div>  


              

          
   
      </mat-tab> 

      
</mat-tab-group>

