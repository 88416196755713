import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-loading-spinner',
  templateUrl: './single-loading-spinner.component.html',
  styleUrls: ['./single-loading-spinner.component.css']
})
export class SingleLoadingSpinnerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
