<!-- <p>we are in the button component </p> -->

<div class="row">
    <div class="col-xs-12">
        <h2>we are in the button component </h2>
        <p>choose which button you want to use </p>
            <button> word button </button>
            <button> phrase button </button>
            <button> email button with alias ? </button>

    </div>
</div>
