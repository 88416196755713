

<div *ngIf="!isLoading">
<div *ngIf="paragraphExist">
<!-- <div *ngIf="!paragraphFeatureContainer.isAccepted" 
     class="example-box pointer" 
     (click)="onAcceptFeatureContainer()"
     style="color:red"  >
      {{paragraphName}} 
</div> -->
<div *ngIf="!paragraphFeatureContainer.isAccepted" 
     matTooltip="CLICK TO ACCEPT / DECLINE THIS PARAGRAPH"
     matTooltipPosition="right"
     class="example-box"  > 

     <button *ngIf="!(isHandset$ | async)"
             class="btn btn-primary" 
             type="button"
             matBadge="1" matBadgeColor="accent"
             (click)="onAcceptFeatureContainer()"> 
             {{paragraphName}} 
     </button>

     <button *ngIf="isHandset$ | async"
             class="btn btn-primary" 
             type="button"
             matBadge="1" matBadgeColor="accent"
             (click)="onAcceptFeatureContainer()"> 
             click / {{paragraphName}} 
     </button>
  
</div>
</div>

<div *ngIf="paragraphExist">
<div *ngIf="paragraphFeatureContainer.isAccepted && paragraphFeatureContainer.isActive" style="background-color: azure;"  class="example-box"   > 


            <div *ngIf="isPersonal" class="col-2 pointer"  [hidden]="isDoubleClick">
               <div *ngIf="!isLoadingShareIcon">
                    <svg *ngIf="isBeenShared2Public" (click)="onShareParagraphIndex()" style="color: red" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-asterisk" viewBox="0 0 16 16">
                         <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"/>
                    </svg>
                    <svg *ngIf="isBeenShared2Contacts" (click)="onShareParagraphIndex()" style="color: red"  xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                         <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
                    </svg> 
                    <svg *ngIf="!isBeenShared" (click)="onShareParagraphIndex()" style="color: #3f51b5;"  xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
                         <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
                    </svg> 
               </div> 
            </div>

           
            <div *ngIf="isPersonal" class="col-10 pointer" (click)="onAddParagraphToFormEdit(paragraphFeatureContainer.paragraph_id)" >
                     <app-paragraph-index 
                                  [isClicked]="isClicked" 
                                  [paragraph_id]="paragraphFeatureContainer.paragraph_id">
                     </app-paragraph-index>
            </div>
            

         
           <div class="col-12 pointer" *ngIf="!isPersonal && !isPublic">
               <div class="col-12 pointer" (click)="onAddParagraphToFormEdit(paragraphFeatureContainer.paragraph_id)" >
                    
                    <app-paragraph-index 
                    [isClicked]="isClicked"  
                    [paragraph_id]="paragraphFeatureContainer.paragraph_id">
                    </app-paragraph-index>
           </div>
           </div>

           <div class="col-12 pointer" 
                *ngIf="isPublic && !(isHandset$ | async)"
                matTooltip="LONG PRESS TO ADD AS PREFERRED"
                matTooltipClass="example-tooltip-red"
                matTooltipPosition="right"  >
               <div *ngIf="!isbeenAddedToPreferred"
                    (click)="onAddParagraphToFormEdit(paragraphFeatureContainer.paragraph_id)"
                    longPress 
                    (mouseLongPress)="onAddParagraphToPreferred(paragraphFeatureContainer.paragraph_id)"
                    >
                    <app-paragraph-index  
                                 [isClicked]="isClicked" 
                                 [paragraph_id]="paragraphFeatureContainer.paragraph_id">
                    </app-paragraph-index>
               </div>
               <div *ngIf="isbeenAddedToPreferred"
                    (click)="onAddParagraphToFormEdit(paragraphFeatureContainer.paragraph_id)" >
                    <app-paragraph-index  
                                 [isClicked]="isClicked" 
                                 [paragraph_id]="paragraphFeatureContainer.paragraph_id">
                    </app-paragraph-index>
                   
               </div>
           </div>

           <div class="col-12 pointer" 
                *ngIf="isPublic && (isHandset$ | async)" >
               <div *ngIf="!isbeenAddedToPreferred"
                    (click)="onAddParagraphToFormEdit(paragraphFeatureContainer.paragraph_id)"
                    longPress 
                    (mouseLongPress)="onAddParagraphToPreferred(paragraphFeatureContainer.paragraph_id)"
                    >
                    <app-paragraph-index  
                                 [isClicked]="isClicked" 
                                 [paragraph_id]="paragraphFeatureContainer.paragraph_id">
                    </app-paragraph-index>
               </div>

               <p *ngIf="!isbeenAddedToPreferred && (isHandset$ | async)" 
                  class="half-small d-flex justify-content-end align-items-end" 
                  style="color:#3f51b5" > 
                  long press add to preferred
               </p>
              
               <div *ngIf="isbeenAddedToPreferred"
                    (click)="onAddParagraphToFormEdit(paragraphFeatureContainer.paragraph_id)" >
                    <app-paragraph-index  
                                 [isClicked]="isClicked" 
                                 [paragraph_id]="paragraphFeatureContainer.paragraph_id">
                    </app-paragraph-index>
                   
               </div>


           </div>
     

</div>
</div>
</div>