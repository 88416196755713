import { Injectable } from "@angular/core";
import { ToggleSingleWordButton } from "./toggle-single-word-button.model";
import { InitialNullSetupService } from "src/app/services/starting-services/initial-null-setup.service";
import { Subject, Subscription } from "rxjs";
import { StoreFormItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-form-item-plus-childs-confirmation.service";
import { StoreParagraphItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-paragraph-item-plus-childs-confirmation.service";
import { StoreMultibaseItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-multiBase-item-plus-childs-confirmation.service";
import { StoreTabItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-tab-item-plus-childs-confirmation.service";


@Injectable ({
    providedIn: 'root' 
})

export class ToggleSingleWordButtonService {

    constructor( 
                 private initialNullSetupService: InitialNullSetupService ,
                 private storeFormItemPlusChildsConfirmationService: StoreFormItemPlusChildsConfirmationService , 
                 private storeParagraphItemPlusChildsConfirmationService: StoreParagraphItemPlusChildsConfirmationService ,
                 private storeTabItemPlusChildsConfirmationService: StoreTabItemPlusChildsConfirmationService ,
                 private storeMultibaseItemPlusChildsConfirmationService: StoreMultibaseItemPlusChildsConfirmationService
                 ){}


toggleSingleWordButtons: ToggleSingleWordButton[]= this.initialNullSetupService.initialNullToggleSingleWordButtons
toggleSingleWordButtonsHaveBeenStored = new Subject<void>()


updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.toggleSingleWordButtonsChangedBackToNull
.subscribe((res)=> {
this.toggleSingleWordButtons = res
console.log('updated toggleSingleWordButton at null service = ', res)
})


    getToggleSingleWordButton(id: number){
        return this.toggleSingleWordButtons[id];
    }

    getToggleSingleWordButtonBy_id(_id: string){
        const newToggleSingleWordButton: ToggleSingleWordButton = this.toggleSingleWordButtons.find(
            (res)=> {
                return res._id === _id
            }
        )
        return newToggleSingleWordButton

    }

    getToggleSingleWordButtons(){
        return this.toggleSingleWordButtons ;
    }

    onAddToggleSingleWordButton(toggleSingleWordButton: ToggleSingleWordButton){
        this.toggleSingleWordButtons.push(toggleSingleWordButton)
        this.updateInitialComponent(this.toggleSingleWordButtons)
        this.toggleSingleWordButtonsHaveBeenStored.next()
    }

    addNewToggleSingleWordButtons(toggleSingleWordButtons: ToggleSingleWordButton[]){

        for ( let i=0; i<toggleSingleWordButtons.length ; i++  ){
            if ( this.toggleSingleWordButtons.findIndex(x=> x._id === toggleSingleWordButtons[i]._id )=== -1 ){
                 this.toggleSingleWordButtons.push(toggleSingleWordButtons[i])
            }
            if ( i === toggleSingleWordButtons.length-1){
                this.updateInitialComponent(this.toggleSingleWordButtons)
                this.toggleSingleWordButtonsHaveBeenStored.next()
            }
        }
        // this.toggleSingleWordButtonsHaveBeenStored.next()

    }

    addNewToggleSingleWordButton(toggleSingleWordButtonToAdd: ToggleSingleWordButton , isComingFromFormItemCall: boolean , isComingFromMultiFeatures: boolean ){

        if ( this.toggleSingleWordButtons.findIndex(x=>x._id === toggleSingleWordButtonToAdd._id) !== -1){ 
            const index = this.toggleSingleWordButtons.findIndex(x=>x._id === toggleSingleWordButtonToAdd._id)
            this.toggleSingleWordButtons[index] = toggleSingleWordButtonToAdd
            this.updateInitialComponent(this.toggleSingleWordButtons)
        }

        if ( this.toggleSingleWordButtons.findIndex(x=>x._id === toggleSingleWordButtonToAdd._id) === -1){ 
             this.toggleSingleWordButtons.push(toggleSingleWordButtonToAdd) 
             this.updateInitialComponent(this.toggleSingleWordButtons)
            }
       
        if ( isComingFromFormItemCall === true  ){ this.storeFormItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenFormItemPlusChildsAreStored( toggleSingleWordButtonToAdd._id) }
        if ( isComingFromFormItemCall === false ){ this.storeParagraphItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenParagraphItemPlusChildsAreStored( toggleSingleWordButtonToAdd._id) }
        if ( isComingFromMultiFeatures === true ){ this.storeMultibaseItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenMultiFeatureItemPlusChildsAreStored( toggleSingleWordButtonToAdd._id)}
        if ( isComingFromMultiFeatures=== false ){ this.storeTabItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenTabItemPlusChildsAreStored( toggleSingleWordButtonToAdd._id) }
       
        console.log('TOGGLESINGLEWORDBUTTONS= ',this.toggleSingleWordButtons)
    }

    updateInitialComponent(updatedToggleSingleWordButtons: ToggleSingleWordButton[]){
        this.initialNullSetupService.initialNullToggleSingleWordButtons = updatedToggleSingleWordButtons

    }

    ngOnDestroy(){
        this.updateInitialNullDatafromInitialNullSetupService.unsubscribe()
    }


}