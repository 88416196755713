// timeout.interceptor.ts
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorsDialogManagerService } from './services/other-components-services/errors-dialog-manager.service';

const TIMEOUT_DURATION = 8000; // 8 seconds

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {

  constructor( private router: Router,
               private route: ActivatedRoute,
               private errorsDialogManagerService: ErrorsDialogManagerService ){}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      timeout(TIMEOUT_DURATION),
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 0) {
          // Handle timeout error here
          // Show a popup or perform any other action
          console.error('Request timed 8 sec out ');

          const currentRoute = this.router.url 

          this.router.navigate([currentRoute], {relativeTo: this.route.parent });

          this.errorsDialogManagerService.QuickCommunicationPopUpComponent({ quickComment: 'something wrong with the connection, please try again later'})
          //     .subscribe(result => {
          //   if (result) {
          //     console.log('Error dialog was closed with result:', result);
          //     // Do something with the result here
          //   } else {
          //     console.log('Error dialog was closed without a result');
          //   }
          // });

        }

        return throwError(error);
      })
    );
  }
}
