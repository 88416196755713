import { Component, Input, OnInit, ViewChild , Output, EventEmitter} from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { WordLessInputService } from '../word-less-input.service';
import { WordLessInput } from '../word-less-input.model';

import { Feature} from 'src/app/forms/form-list/form-item/paragraph/paragraph-list/paragraph-item/features/feature.model';
import { WordLessDetail } from '../word-less-detail.model'
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';



@Component({
  selector: 'app-word-less-list',
  templateUrl: './word-less-list.component.html',
  styleUrls: ['./word-less-list.component.css']
})
export class WordLessListComponent implements OnInit {

  user: string 

  wordLessInput : WordLessInput;
  wordsLessInput : WordLessInput[];
  // these tabsIndex are to create and re-position focus once created a new tab
  newTabIndex0: number ;
  newtabIndex : number ;

  customCollapsedHeight: string = '20px';
  customExpandedHeight: string = '20px';

  index : number ;
  @Input() feature_from_paragraphEdit: Feature 
  selectedIndex: number
  i : number ;
  @Input() dropId : number ;
  feature: Feature ;

  @Output() noNeeded: boolean ; 

  @Output() changeIndex = new EventEmitter<Feature>()  ; 

  /** limit on word less that can be added */
  createButtonLimit : boolean ;
  wordLessLimit = 25 ;

  @Input() currentI : number ;

  
  
 @ViewChild('tabs') tabGroup: MatTabGroup

  constructor(private wordLessInputService: WordLessInputService , 
    private userObjectUpdateService: UserObjectUpdateService ) { }

    ngOnInit(): void {

      this.user = this.userObjectUpdateService.userEmail
      this.wordsLessInput=this.wordLessInputService.getWordLessInputs();

      this.selectedIndex = this.wordLessInputService.getWordLessInputs().findIndex(x=> x._id === this.feature_from_paragraphEdit.feature_id )

      if ( this.wordLessInputService.getWordLessInputs().length < this.wordLessLimit){
        this.createButtonLimit = true ;
      } else {
        this.createButtonLimit = false 
      };
   }
   
   getIndex(index){
     console.log(index);//clicked index
   }
   
   
   onLinkClick($event) {
    // let index = $event.index ;
    this.index = this.tabGroup.selectedIndex ; 

    const newOwner = this.user ; 
    const newIsNew = false ;
    const newDropId = this.currentI ;
   // const newFeatureId = this.index ;
    const newFeature_id = this.wordLessInputService.getWordLessInput(this.index)._id
    const newFeatureName = 'word simple' ;
    const newFeature = new Feature (newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;

    this.feature = newFeature


    this.changeIndex.emit(this.feature)

    console.log(this.feature)
    console.log (this.index)
       
     }


     onCreateNew(){
      this.newTabIndex0 = this.wordLessInputService.getWordLessInputs().length+1;

      const new_id = this.newTabIndex0.toString() ;
      const newOwner = this.user ; 
      const newDateAndTime = null ;
      const newName =  'new tab '+ this.newTabIndex0 ;
      const newLabel = 'new label '+ this.newTabIndex0;
      const newPlaceholder = 'to edit';
     
  
      const newDetail =  new WordLessDetail (newLabel, newPlaceholder) ;
  
      const newDetails =  [newDetail] ;
      const newWordLessInput = new WordLessInput ( new_id, newOwner, newDateAndTime, newName , newDetails);
  
      this.wordLessInputService.onAddNew(newWordLessInput);
  
      
  
      
    }
  
    onCreate(){
      this.onCreateNew()
   this.newtabIndex = this.wordLessInputService.getWordLessInputs().length-1;

   const newOwner = this.user ; 
   const newIsNew = false
   const newDropId = this.currentI;
  // const newFeatureId = this.newtabIndex;
   const newFeature_id = this.wordLessInputService.getWordLessInput(this.newtabIndex)._id
   const newFeatureName = 'word simple' ;
   const newFeature = new Feature (  newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
  
   this.feature = newFeature
  
   this.changeIndex.emit(this.feature)

   if ( this.wordLessInputService.getWordLessInputs().length < this.wordLessLimit){
    this.createButtonLimit = true ;
  } else {
    this.createButtonLimit = false 
  };
  
   console.log(this.feature)
      
     
      
    }
  






   
   }
   
