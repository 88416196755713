import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendar-range-edit',
  templateUrl: './calendar-range-edit.component.html',
  styleUrls: ['./calendar-range-edit.component.css']
})
export class CalendarRangeEditComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
