<!-- if the form is personal  -->

<div *ngIf="typeOfFormIndexTopic.isPersonal" style="background-color: azure;" class="example-box">

    <div class="col-2 pointer">
        <svg *ngIf="!form.isBeenShared" (click)="onShareFormIndex()" style="color: #3f51b5;"  xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
          <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
        </svg>   

        <svg *ngIf="isBeenShared2Public" (click)="onShareFormIndex()" style="color: red" xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-asterisk" viewBox="0 0 16 16">
          <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"/>
         </svg>
    
        <svg *ngIf="isBeenShared2Contacts" (click)="onShareFormIndex()"  style="color: red"  xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
          <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
        </svg>  

    </div>
     
    
      <div class="col-10" (click)="onSendForm_id()">                 
  
       <div style="color: #3f51b5; background-color: azure;" class="inner-box">
        <app-form-name 
                      [isClicked]="isClicked"
                      [name]="form.formSubject">
        </app-form-name>

      </div>
      </div>

</div>

<!-- END if the form is personal  -->

<!-- if the form is not personal  -->

<div *ngIf="!typeOfFormIndexTopic.isPersonal" style="background-color: azure;" class="example-box">
   
  
    <div class="col-12" (click)="onSendForm_id()">                 

     <div style="color: #3f51b5;" class="inner-box">
      <app-form-name
                    [isClicked]="isClicked"
                    [name]="form.formSubject">
      </app-form-name>
    
    </div>
    </div>

</div>

<!-- END if the form is not personal  -->
