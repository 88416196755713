import { MultiFeatures } from "./multi-features.model";
import { Injectable } from "@angular/core";
import { MultiFeaturesHttpRequestService } from "./multi-features-http-request.service";
import { InitialNullSetupService } from "src/app/services/starting-services/initial-null-setup.service";
import { Subject, Subscription } from "rxjs";
import { StoreFormItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-form-item-plus-childs-confirmation.service";
import { StoreParagraphItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-paragraph-item-plus-childs-confirmation.service";
import { StoreTabItemPlusChildsConfirmationService } from "src/app/services/get-and-store-components/store-tab-item-plus-childs-confirmation.service";
import { Feature } from "../feature.model";


@Injectable ({
    providedIn: 'root' 
})

export class MultiFeaturesService {

    constructor( private multiFeaturesHttpRequestService: MultiFeaturesHttpRequestService ,
                 private initialNullSetupService: InitialNullSetupService ,
                 private storeTabItemPlusChildsConfirmationService: StoreTabItemPlusChildsConfirmationService ,
                 private storeFormItemPlusChildsConfirmationService: StoreFormItemPlusChildsConfirmationService , 
                 private storeParagraphItemPlusChildsConfirmationService: StoreParagraphItemPlusChildsConfirmationService ,
                  ){}


    multiFeaturesHasBeenStored = new Subject<void>()
    public multiFeatures : MultiFeatures[] = this.initialNullSetupService.initialNullMultiFeatures

    updateInitialNullDatafromInitialNullSetupService: Subscription = this.initialNullSetupService.multiFeaturesChangedBackToNull
    .subscribe((res)=> {
    this.multiFeatures = res 
    console.log('updated multiFeatures back to null = ', res)
    })


    getMultiFeature(id: number){
        const multiFeature = this.multiFeatures[id]
        return multiFeature
    };

    getMultiFeatureBy_id(_id: string){
        const multiFeature = this.multiFeatures.find(
            (res) => {
                return res._id === _id
            }
        )
        return multiFeature
    }


    getMultiFeatures(){
        return this.multiFeatures ;
    };

    onAddNew( multiFeature: MultiFeatures){
        this.multiFeatures.push(multiFeature)
        this.updateInitialComponents(this.multiFeatures)
    };

    onUpdateMultiFeature(updatedMultiFeature: MultiFeatures){

        const index = this.multiFeatures.findIndex(x=> x._id === updatedMultiFeature._id)
        this.multiFeatures[index] = updatedMultiFeature 
        console.log( 'multiFeature UPDATED')
        this.updateInitialComponents(this.multiFeatures)

    }

    /**
     * 
     * @param newFeature 
     */
    addSingleFeatureToMultiFeatureEdit = new Subject<Feature>()
    onAddSingleFeatureToMultiFeatureEdit(newFeature: Feature){
        this.addSingleFeatureToMultiFeatureEdit.next(newFeature)
    }


    fetchMultiFeatures(){
        return this.multiFeaturesHttpRequestService.httpFetchMultiFeatures()
    };

    addNewMultiFeatures(multiFeatures: MultiFeatures[]){
        for ( let i=0; i<multiFeatures.length ; i++ ){
            if ( this.multiFeatures.findIndex(x=> x._id === multiFeatures[i]._id ) === -1 ){
                this.multiFeatures.push(multiFeatures[i])
            }
            if ( i === multiFeatures.length-1){
                this.updateInitialComponents(this.multiFeatures)
                console.log( 'multiFeatures has been stored')
                this.multiFeaturesHasBeenStored.next() // send to multiFeatureList so it can continue to show list 
            }
        }
        // console.log( 'multiFeatures has been stored')
        // this.multiFeaturesHasBeenStored.next() // send to multiFeatureList so it can continue to show list 
    }

    addNewMultiFeature(multiFeatureToAdd: MultiFeatures , isComingFromFormItemCall: boolean , isComingFromMultiFeatures: boolean ){

        if ( this.multiFeatures.findIndex(x=> x._id === multiFeatureToAdd._id) !== -1 ){ 
             const index = this.multiFeatures.findIndex(x=> x._id === multiFeatureToAdd._id)
             this.multiFeatures[index] = multiFeatureToAdd
            this.updateInitialComponents(this.multiFeatures)
        }

        if ( this.multiFeatures.findIndex(x=> x._id === multiFeatureToAdd._id) === -1 ){ 
             this.multiFeatures.push(multiFeatureToAdd)
             this.updateInitialComponents(this.multiFeatures)
        }

        if ( isComingFromFormItemCall === true  ){ this.storeFormItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenFormItemPlusChildsAreStored( multiFeatureToAdd._id) }
        if ( isComingFromFormItemCall === false ){ this.storeParagraphItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenParagraphItemPlusChildsAreStored( multiFeatureToAdd._id) }
        if ( isComingFromMultiFeatures=== false ){ this.storeTabItemPlusChildsConfirmationService.addStoredComponentConfirmationAndAdviseWhenTabItemPlusChildsAreStored( multiFeatureToAdd._id) }
   
        console.log('UPDATED MULTIFEATURES = ', this.multiFeatures)
    }

    updateInitialComponents(updatedMultiFeatures: MultiFeatures[]){
        this.initialNullSetupService.initialNullMultiFeatures = updatedMultiFeatures
    }

    ngOnDestroy(){
        this.updateInitialNullDatafromInitialNullSetupService.unsubscribe()
    }


}           

