<p>horizon-add works!</p>

<!-- <div class="example-container"> -->


<div class="row">
    <div class="col-xs-12">
    <form [formGroup]="addmore">
        <div class="card">
            <div class="class-header text-center"> add daily task report </div>
            <div class="card-body">

                <div class="row">
                    <div class="col-xs-4">
                        <mat-form-field appearance="outline">
                            <mat-label> title </mat-label>
                            <input matInput formControlName="title">
                             <!-- <mat-error *ngIf="(f.title.dirty && f.title.invalid==true )">
                            <span> Please enetr valid data </span> 
                            </mat-error> -->
                        </mat-form-field>
                    </div>

                    <div class="col-xs-4">
                        <mat-form-field appearance="outline">
                            <mat-label> type </mat-label>
                            <mat-select formControlName="type">
                                <mat-option value="">
                                    none
                                </mat-option>
                                <mat-option value="1">
                                    Plan
                                </mat-option>
                                <mat-option value="1">
                                    Routine
                                </mat-option>
                                <mat-option value="1">
                                    R&D 
                                </mat-option> 
                                <!-- <mat-option *ngFor="let parentPOSDepartment of parentPOSDepartment"
                                [value]="parentPOSDepartment.id">
                                {{parentPOSDepartment.text}}
                            </mat-option> -->
                            </mat-select>
                        </mat-form-field>
                    </div>


                    <div class="col-xs-4">
                        <mat-form-field appearance="outline">
                            <mat-label>description</mat-label>
                            <textarea matInput formControlName="description"></textarea>
                        </mat-form-field>
                    </div>
                </div>



                <div class="row">
                    <div class="col-xs-12 table-responsive" formArrayName="ItemRows">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th style="white-space: nowrap;padding-right: 4em;float:center"><span class="required-field"></span> time range</th>
                                    <th style="white-space: nowrap;padding-right: 4em;float:center"> Learn</th>
                                    <th style="white-space: nowrap;padding-right: 4em;float:center"> Description</th>
                                    <th style="white-space: nowrap;padding-right: 4em;float:center"> Suggestion</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let itemrow of addmore.controls.ItemRows['controls']; let i=index" [formGroupName]="i">

                                    <td style="white-space: nowrap;"><input id="timerange{{i}}" formControlName="timerange" class="form-control"></td>
                                    <td><textarea id="learn{{i}}" formControlName="learn" class="form-control reset"></textarea></td>
                                    <td><textarea formControlName="description" class="form-control reset"></textarea></td>
                                    <td><textarea formControlName="suggestion" class="form-control reset"></textarea></td>
                                    <td><button *ngIf="addmore.controls.ItemRows.controls.length > 1" (click)="deleteRow(i)" class="btn btn-danger"> delete row</button></td>

                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td><button type="button" (click)="addNewRow()" class="btn btn-primary"> + <i class="fa fa-plus-circle" aria-hidden="true"></i> </button></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>



            </div>
        </div>
        <button (click)="submit()"> submit </button>

    </form>
    </div>
</div>

 
