<!-- <p>comment works!</p> -->


<div class="row">
    <div class="col-xs-12">

      <br>

        <form [formGroup]="commentTextForm" (onSubmit)="onSubmit()">
          
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>Leave a comment</mat-label>
              <!-- autofocus -->
              <textarea matInput
                        #comment 
                        maxlength="5000" 
                        type="text"
                        id="textComment"
                        formControlName="textComment"
                        placeholder="Ex. it makes me feel gooood!...">
              </textarea>
              <mat-hint align="end">{{comment.value.length}} / 5000</mat-hint>
            </mat-form-field>
          </form>


    </div>
</div>

<p>{{key}}</p>