import { Component, OnInit,  ViewChild, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-bar-code-scanner',
  templateUrl: './bar-code-scanner.component.html',
  styleUrls: ['./bar-code-scanner.component.css']
})
export class BarCodeScannerComponent implements OnInit {



  constructor() { }

  ngOnInit(): void {
  }



}
