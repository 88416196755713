import { Component, Input, OnInit } from '@angular/core';
import { SingleToggleWordButtonDetailResult } from './single-toggle-word-button-detail-result.model';

@Component({
  selector: 'app-single-toggle-word-button-result',
  templateUrl: './single-toggle-word-button-result.component.html',
  styleUrls: ['./single-toggle-word-button-result.component.css']
})
export class SingleToggleWordButtonResultComponent implements OnInit {

  @Input() singleToggleWordButtonDetailResult: SingleToggleWordButtonDetailResult




  constructor() { }

  ngOnInit(): void{
  }

}
