import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-toggle-word-button-item',
  templateUrl: './toggle-word-button-item.component.html',
  styleUrls: ['./toggle-word-button-item.component.css']
})
export class ToggleWordButtonItemComponent implements OnInit {

  constructor() { }

 ngOnInit(){}

}
