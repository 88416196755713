import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { RecipientIndexColumn } from '../recipient-index-list/recipient-index-column.model';
import { RecipientIndexColumnService } from '../recipient-index-list/recipient-index-column.service';
import { Subscription, interval } from 'rxjs';
import { UpdateRecipientFeatureService } from 'src/app/services/updates-on-working-app/update-recipient-feature.service';
import { RecipientFeatureService } from '../recipient-index-list/recipient-feature.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { RecipientFeature } from '../recipient-index-list/recipient-feature.model';

@Component({
  selector: 'app-recipient-index-column',
  templateUrl: './recipient-index-column.component.html',
  styleUrls: ['./recipient-index-column.component.css']
})
export class RecipientIndexColumnComponent implements OnInit , OnDestroy {

  user: string 

  @Input() youAreInFormEdit: boolean

  recipientIndexColumns: RecipientIndexColumn[]

  @Output() updateRecipientIndex = new EventEmitter<any>()
 
  // timeIntervalSubscription: Subscription
  updateOnUserEmail: Subscription 

  updateWithLatestRecipientIndexColumnsSubscription: Subscription 

  hideSharedRecipientIndexColumnSubscription: Subscription 

  intervalLong: any; // to keep interval unique 
  updateTimeInterval: number = 90000
  canUpdate: boolean = true 
  itHasBeenMoreThanTimeInterval: boolean = false 

  constructor( private recipientIndexColumnService: RecipientIndexColumnService , 
               private updateRecipientFeatureService: UpdateRecipientFeatureService , 
               private userObjectUpdateService: UserObjectUpdateService , 
               private recipientFeatureService: RecipientFeatureService , 
               private cdr: ChangeDetectorRef ,
               private ngZone: NgZone
    ) { }

    @HostListener('document:visibilitychange', ['$event'])
    visibilitychange() {
       if (document.hidden){
             console.log("RECIPIENT COLUMN IS HIDDEN " , new Date());
       } else {
             console.log("RECIPIENT COLUMN IS VISIBLE" , new Date());
             if ( this.itHasBeenMoreThanTimeInterval && this.user !== null ){
              console.log('it has been more than', this.updateTimeInterval , ' sec' )
          
               
                this.updateThisSharedcalendarInfo_http() // updatecomponent 
                this.setupUpdateInterval() // restart time interval
              
    
             }
       }
    }

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
     .subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
     })

    this.recipientIndexColumns = this.recipientIndexColumnService.getRecipientIndexColumns_SortByType()
    

         const veryFirstCurrentRecipientFeature: RecipientFeature = this.recipientFeatureService.getRecipientFeature()
         const veryFirstCurrentRecipientFeature_Recipients_ids: string[] = veryFirstCurrentRecipientFeature.receivedRecipient_ids
    if (document.visibilityState === "visible" && this.user !== null ) {
      console.log(veryFirstCurrentRecipientFeature)
      setTimeout(()=>{
        this.updateRecipientFeatureService.getRecipientFeatureWithChilds(this.user , veryFirstCurrentRecipientFeature_Recipients_ids, veryFirstCurrentRecipientFeature._id )
      },1500)
    }

    this.setupUpdateInterval()
  
      
    this.updateWithLatestRecipientIndexColumnsSubscription = this.recipientIndexColumnService.updateWithLatestRecipientIndexColumns
        .subscribe((latestRecipientIndexColumns: RecipientIndexColumn[])=> {
          this.ngZone.run(() => {
            this.recipientIndexColumns = latestRecipientIndexColumns.sort((a,b) => {
              return <any>(b.type) - <any>(a.type)
            })
          });
          this.cdr.detectChanges();
    })

    this.hideSharedRecipientIndexColumnSubscription = this.recipientIndexColumnService.hideSharedRecipientIndexColumn
        .subscribe(()=>{
            console.log('HIDE OR MAKE IT NULL OPERATION WHEN CLICK ON SHARED GROUPS')
        })
        
  }

  setupUpdateInterval() {

    this.clearInterval() // Clear existing interval, if any
 
    if ( this.user !== null ){
 
    this.intervalLong = setInterval(()=> {
 
     if ( document.hidden && this.user !== null ){
         // if document is hidden and it has been more than time = false it has to become true 
         // but if it has been true it doesn't have to become false , it remain true 
         if ( !this.itHasBeenMoreThanTimeInterval ){
           this.itHasBeenMoreThanTimeInterval = !this.itHasBeenMoreThanTimeInterval
         }
     }
     if ( !document.hidden && this.user !== null ){ 
         this.updateThisSharedcalendarInfo_http()
         this.itHasBeenMoreThanTimeInterval = false 
     }
     if ( !document.hidden && this.user !== null ){ 
          if ( !this.itHasBeenMoreThanTimeInterval ){
                this.itHasBeenMoreThanTimeInterval = !this.itHasBeenMoreThanTimeInterval
          }
   }
 
 
    },this.updateTimeInterval)
   }
 
   }
 
   clearInterval() {
     clearInterval(this.intervalLong);
   }

   updateThisSharedcalendarInfo_http(){
    console.log('UPDATING RECIPIENT INDEX COLUMN OF => ', this.user )
    const currentRecipientFeature: RecipientFeature = this.recipientFeatureService.getRecipientFeature()
    const currentRecipientFeature_Recipients_ids: string[] = currentRecipientFeature.receivedRecipient_ids
            /**
             * add IF shared recipient is on display 
             */
           
    this.updateRecipientFeatureService.getRecipientFeatureWithChilds(this.user , currentRecipientFeature_Recipients_ids, currentRecipientFeature._id )
            

   }

  onUpdateRecipientIndex($event){
    this.updateRecipientIndex.emit($event)
  }

  ngOnDestroy(): void {
      this.updateOnUserEmail.unsubscribe()
      this.clearInterval();
  }

 

}
