import { Component, ViewChild, OnInit , Output , EventEmitter , Input, OnDestroy, ChangeDetectorRef, NgZone} from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import { Recipient } from 'src/app/forms/form-list/form-item/recipient/recipient.model';
import { RecipientIndex } from 'src/app/forms/form-list/form-edit/recipient-index-list/recipient-index.model';
import { RecipientIndexService } from '../../../form-edit/recipient-index-list/recipient-index.service';
import { RecipientId } from 'src/app/forms/form-list/form-edit/recipient-index-list/recipient-id.model';
import { RecipientService } from '../recipient.service';
import { Observable , Subject, Subscription } from 'rxjs';
import { Chat } from 'src/app/chats/chat.model';
import { PartecipantGroup } from 'src/app/chats/partecipants.model';
import { Sender } from 'src/app/forms/form-results/form-results-list/form-result-item/sender/sender.model';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { RecipientIndexListItem } from '../../../form-edit/recipient-index-list/recipient-index-list-item.model';
import { RecipientIndexListItemService } from '../../../form-edit/recipient-index-list/recipient-index-list-item.service';
import { StorePersonalFeatureIndexComponentsService } from 'src/app/services/store-initial-components/store-personal-feature-index-components.service';
import { CreateUpdateMultiComponentsService } from 'src/app/services/shared-services/create-update-multi-components.service';
import { RecipientIndexColumn } from '../../../form-edit/recipient-index-list/recipient-index-column.model';
import { StorePersonalRecipientComponentsService } from 'src/app/services/store-initial-components/store-personal-recipient-components.service';
import { RecipientIndexColumnService } from '../../../form-edit/recipient-index-list/recipient-index-column.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IndexType } from 'src/app/forms/index-type.model';
import { Location } from '@angular/common';



@Component({
  selector: 'app-recipient-list',
  templateUrl: './recipient-list.component.html',
  styleUrls: ['./recipient-list.component.css']
})


export class RecipientListComponent implements OnInit , OnDestroy{

  user: string
  recipient: Recipient;
  recipients: Recipient[];
 recipientId: RecipientId;

 newRecipientId: string

 partecipantGroup: PartecipantGroup
 sender: Sender
 timeAtChatCreation: string 

  @Input() youAreInFormEdit: boolean
  @Input() recipientIndex: RecipientIndex;
  recipientIndexes: RecipientIndex[];
  @Input() isEditMode: boolean 

  @Input() recipientIndexId : number ;
  @Input() selectedIndex: number ;
  @Input() recipientIndexListItem: RecipientIndexListItem
  @Input() recipientGroupName : string ;
  @Input() paragraphIndexType: IndexType


  @Input() isPersonalRecipientIndexListItem: boolean 
  @Input() isMessageSide: boolean
  @Input() isLoadingSubRecipientItems: Observable<void>
  startLoadRecipientItemSubscription: Subscription 

  @Input() updateRecipiendIndexListItems: Observable<RecipientIndexListItem>
  updateSharedRecipientIndexListItem_FromRecipientFeatureSubscription: Subscription 

  recipientIndexColumn: RecipientIndexColumn

  newTabIndex0: number ;
  newTabIndex : number ;

  findRecipientIndexId: number
  

  /** this serve as a limit to mail groups */
  createButtonLimit : boolean ;
  recipientsLimit = 10

  /** this to check if we are in the form edit and no form create, and put the right buttons in */
  inFormEdit = true ;

  @Output() changeIndex = new EventEmitter<RecipientIndex>()  ; 
  @Output() undefinedRecipient = new EventEmitter<any>();
  @Output() startLoadingOtherRecipient = new EventEmitter<void>()

  @ViewChild('tabs') tabGroup: MatTabGroup

  /**
   * here start to subscribe the results
   */

   subscribeRecipientIndex: Subject<void> = new Subject<void>();

  @Output() emitTemporaryHideRecipientsMessage = new EventEmitter<void>()
  @Output() emitTemporaryHideRecipientsMail = new EventEmitter<void>()

  @Output() emitSecondRecipientResult = new EventEmitter<Recipient>()
  @Output() emitRecipientResult = new EventEmitter<Recipient>()

  @Output() emitUpdatedRecipientIndexListItem = new EventEmitter<RecipientIndexListItem>()

  trialRecipients: Recipient[]

  isLoading: boolean
  error = false

  trialChats: Chat[]

  newRecpientIndexForUpdateForm: RecipientIndex 

  updateOnUserEmail: Subscription



  constructor(
               private recipientService: RecipientService ,
               private recipientIndexService: RecipientIndexService,
               private breakpointObserver: BreakpointObserver ,
               private userObjectUpdateService: UserObjectUpdateService,
               private recipientIndexListItemService: RecipientIndexListItemService , 
               private createUpdateMultiComponentsService: CreateUpdateMultiComponentsService , 
               private storePersonalFeatureIndexComponentsService: StorePersonalFeatureIndexComponentsService ,
               private storePersonalRecipientComponentsService: StorePersonalRecipientComponentsService ,
               private recipientIndexColumnService: RecipientIndexColumnService ,
               private cdr: ChangeDetectorRef ,
               private ngZone: NgZone ,
               private router: Router,
               private route: ActivatedRoute,
               private location: Location,

               ) {}

    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );


     /** recipient list component stand in Form-Edit or Form-item and has to behave differently 
      * according to where it seat : if we are creating a form ( and creating an new recipIndex ), 
      * or editing an exhisting form ( updating form-item), where the recipients are known but need update  */          

  ngOnInit(){


    this.user = this.userObjectUpdateService.userEmail

    //console.log(this.recipientService.getOnlyUserRecipientsList(this.user))

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail.subscribe((updatedUserEmail)=>{
      this.user = updatedUserEmail
      console.log('updated USER = ', this.user)
    }) 

    console.log( this.user , this.recipientIndex);
    console.log(this.recipientIndexListItem)
    this.sort_recipients_according_to_typeOfColumn()


    console.log(this.isEditMode)


    this.recipients = [] // check the recipients in use 

    for ( let i=0; i<this.recipientIndexListItem.recipientIndexList_ids.length ; i++){
      const newRecipientIndex_id: string = this.recipientIndexListItem.recipientIndexList_ids[i]
      const newRecipientIndex: RecipientIndex = this.recipientIndexService.getRecipientIndexBy_id(newRecipientIndex_id)
      const newRecipient: Recipient = this.recipientService.getRecipientBy_id(newRecipientIndex.recipient_id)
      
    // let newRecipient: Recipient 

    //   if (Array.isArray(newRecipientIndex.recipient_id)) {
    //     if ( this.recipientIndex.recipient_id.length > 1 ){
    //       console.log("IS ARRAY OF RECIPIENT_ID")
    //       const recipientList: any = newRecipientIndex.recipient_id 
    //     const stringList: string[] = recipientList
    //     const indexOfrecipient =  this.getIndexWithLowestNumber(stringList)
    //     const recipient_id = stringList[indexOfrecipient]
    //     newRecipient = this.recipientService.getRecipientBy_id(recipient_id);
    //     }
    //   }
    
    //   if ( !Array.isArray( newRecipientIndex.recipient_id)){
    //     newRecipient = this.recipientService.getRecipientBy_id(newRecipientIndex.recipient_id);
    //   }
      
      this.recipients.push(newRecipient)
      // console.log(this.recipients)
    }


    console.log(this.selectedIndex)
    ;
    if ( this.recipientIndexListItem.recipientIndexList_ids.length < this.recipientsLimit ){
      this.createButtonLimit = true;
    } else  {
      this.createButtonLimit = false 
    };

    if (this.recipientIndexListItem.recipientIndexListName === 'shared recipients'){ this.recipientIndexColumnService.sharedRecipientIndexListItem_isVisibleNow = true }
    if (this.recipientIndexListItem.recipientIndexListName !== 'shared recipients'){ this.recipientIndexColumnService.sharedRecipientIndexListItem_isVisibleNow = false }

    this.startLoadRecipientItemSubscription = this.isLoadingSubRecipientItems.subscribe(()=> this.isLoading = true )
  //  this.recipientResultSubscription = this.subscribeRecipientResult.subscribe(()=> this.onSubscribeRecipientResult())

  this.updateSharedRecipientIndexListItem_FromRecipientFeatureSubscription = this.updateRecipiendIndexListItems
      .subscribe((updatedRecipientIndexListItem: RecipientIndexListItem )=>{
        console.log('subscription to recipient list has arrived')
        /**
         * if we are having the same recipientIndexListItem on the DOM we update it
         */
        if ( updatedRecipientIndexListItem._id === this.recipientIndexListItem._id ){
          this.ngZone.run(() => {
                 this.recipientIndexListItem = updatedRecipientIndexListItem
                 console.log('new recipientIndexListItem is in place => ', updatedRecipientIndexListItem)
                     this.recipients = [] // check the recipients in use 
                          for ( let i=0; i<this.recipientIndexListItem.recipientIndexList_ids.length ; i++){
                                const newRecipientIndex_id: string = this.recipientIndexListItem.recipientIndexList_ids[i]
                                const newRecipient: Recipient = this.recipientService.getRecipientBy_id(this.recipientIndexService.getRecipientIndexBy_id(newRecipientIndex_id).recipient_id)
                                this.recipients.push(newRecipient)
                          }
          });
          this.cdr.detectChanges();
        }
      })

   this.isLoading = false


  }

  // getIndexWithLowestNumber(strings: string[]): number {
  //   let lowestNumber = Infinity;
  //   let lowestNumberIndex = -1;
  
  //   Array.from(strings).forEach((str, index) => {
  //     const number = parseInt(str.match(/\d+$/)[0], 10);
  //     if (number < lowestNumber) {
  //       lowestNumber = number;
  //       lowestNumberIndex = index;
  //     }
  //   });
  
  //   return lowestNumberIndex;
  // }

  customSort(a, b) {
    // Extract the numeric part of the _id
    const idA = parseInt(a._id.split('-')[1]);
    const idB = parseInt(b._id.split('-')[1]);
  
    // Compare the numeric parts
    if (idA < idB) return -1;
    if (idA > idB) return 1;
    return 0;
  }



// i need to do another linkClick specific for when is done by creating a new recipient 
   onLinkClick($event) {

    if ( this.youAreInFormEdit ){ this.youAreInFormEdit_tabClick()}
    if ( !this.youAreInFormEdit){ this.youAreInFormItem_tabEditClick()}

}

youAreInFormEdit_tabClick(){

     this.isLoading = true
     this.selectedIndex = this.tabGroup.selectedIndex;
     console.log( 'clicked selected index',this.selectedIndex)

    this.recipientIndex = this.recipientIndexService.getRecipientIndexBy_id(this.recipientIndexListItem.recipientIndexList_ids[this.selectedIndex])
   
    if (this.user !== null ){ 
                if ( !this.isEditMode ){ this.changeUserUrlParams_onCreateForm_Recipient(this.recipientIndex)}
              
    }
     
    if (this.user === null) { 
                if ( !this.isEditMode ){ this.changeNullUserUrlParams_onCreateForm_Recipient(this.recipientIndex )}
             
    }
   


    this.changeIndex.emit(this.recipientIndex);
      console.log(this.recipientIndex)
  
  
     setTimeout(()=>{
      this.isLoading = false
     }, 100)

}

youAreInFormItem_tabEditClick(){
      this.isLoading = true
      this.selectedIndex = this.tabGroup.selectedIndex;
      console.log( 'clicked selected index',this.selectedIndex)
  
      this.recipientIndex = this.recipientIndexService.getRecipientIndexBy_id(this.recipientIndexListItem.recipientIndexList_ids[this.selectedIndex])
  
      this.changeIndex.emit(this.recipientIndex);
      console.log(this.recipientIndex)
  
  
     setTimeout(()=>{
      this.isLoading = false
     }, 100)
}

sort_recipients_according_to_typeOfColumn(){

  if ( this.isPersonalRecipientIndexListItem === true ){
    /**
    *  sort the recipientIndexes array in alphabeticNumeric order 
    */
     this.recipientIndexListItem.recipientIndexList_ids.sort(function(a,b) {
      return a.localeCompare(b, 'en', { numeric: true })
    })
  }

  if ( this.isPersonalRecipientIndexListItem === false ){
    /**
    *  sort the recipientIndexes array in latest to previous dateTime 
    */
    //  this.recipientIndexListItem.recipientIndexList_ids.sort(function(a,b) {
    //   return a.localeCompare(b, 'en', { numeric: true })
    // })
  }
}


onEmitTemporaryHideRecipientsMessage(){
  this.emitTemporaryHideRecipientsMessage.emit()
}

onEmitTemporaryHideRecipientsMail(){
  this.emitTemporaryHideRecipientsMail.emit()
}

onEmitUpdatedRecipientIndexListItem_afterDeleteMyEmail($event){
  console.log( 'DELETE THIS RECIPIENT AND RECIPINDEX, GET PREVIOUS RECIPIENT OR MY OWN => ', $event)
  this.emitUpdatedRecipientIndexListItem.emit($event)
}

onChangeRecipientName(event){
  this.isLoading = true 
  console.log(event)

  const newRecipient: Recipient = event 

  this.recipients[this.recipients.findIndex(x=>x._id === newRecipient._id)] = newRecipient 

  this.changeIndex.emit(this.recipientIndex);

  setTimeout(()=>{
    this.isLoading = false 
  },200)
};

/** create a new tab and recipient on recipient Group */
onCreate(){
  this.startLoadingOtherRecipient.emit()
  this.isLoading = true
  this.selectedIndex = this.selectedIndex 
  // this.createNewChat()

  if ( this.user !== null ){ console.log('chech for updated recipientIndexes'), this.httpCheckUpdatedRecipientIndexLength() }
  if ( this.user === null ){ console.log('going straight to produce new recipient') , this.createNewChat() }

};

private requestInProgress_createPersonal_Recipient_RecipientIndex_UPDATE_RecipientIndexListItem = false 
httpCheckUpdatedRecipientIndexLength(){

  this.storePersonalFeatureIndexComponentsService.getPersonalUpdatedFeatureIndex_components(this.user)
      .subscribe((res: any) =>{
        console.log(res)

        const httpFeatureIndexComponentResult: any = res 
         /**
          * recipientIndexes update
          */
        const newRecipientIndexes: RecipientIndex[] = httpFeatureIndexComponentResult.recipientIndex

        const newRecipient_Index_UUID_length: string = (newRecipientIndexes.length+1).toString()

         /**
         * if recipientIndexes are different length , store new recipientIndexes
         * + update only recipIndexListItem and columns Personal , leave to recipientFeature do the shared
         * + get personal recipients 
         */
         if (newRecipientIndexes.length !== this.recipientIndexService.getRecipientIndexes().length ){
          console.log('THERE ARE MORE RECIPIENTS , ADDING FIRST AND UPDATE ONLY PERSONAL RECIPIENT_INDEX_COLUMN')

          const newArrayRecipientIndexColumns: RecipientIndexColumn[] = httpFeatureIndexComponentResult.recipientIndexColumn
          const newArrayRecipientIndexListItems: RecipientIndexListItem[] = httpFeatureIndexComponentResult.recipientIndexListItem 

          this.storePersonalRecipientComponentsService.getUserCurrentRecipientsComponents(this.user)
              .subscribe((httpResult: any )=>{
                console.log(httpResult)

                const updatedRecipientList: Recipient[] = httpResult.recipient

                this.storeAllNewPersonalObjects_CreateNewRecipient_RecipientIndex( newArrayRecipientIndexColumns, 
                                                                                   newArrayRecipientIndexListItems,
                                                                                   newRecipientIndexes, 
                                                                                   updatedRecipientList , 
                                                                                   newRecipient_Index_UUID_length )


              }, error => {
                console.log( 'ERROR ON GETTING UPDATE ON RECIPIENTS , THROW ERROR', error )
              })

        }; // END IF RECIPIENTINDEXES LENGTH ARE DIFFERENT


        /**
         * if recipient Indexes are still the same , then proceed as we are 
         */
        if (newRecipientIndexes.length === this.recipientIndexService.getRecipientIndexes().length ){
            console.log('STILL THE SAME RECIPINTS LENGTH')

            const actualRecipientIndexListItem : RecipientIndexListItem = this.recipientIndexListItem 
            const newRecipientIndex_UIID : string = 'recipientIndex-'+(this.recipientIndexService.getRecipientIndexes().length+1).toString()

            const new_recipient_id = null 
            const newComponentName = 'recipient'
            const newUIID = 'recipient-'+(this.recipientIndexService.getRecipientIndexes().length+1).toString()
            const newChat_roomid = null
            const newOwner = this.user ;
            const newDateAndTime = null
            const newUpdateDateAndTime = null
            const newAllUsersAllowed = false ;
            const newUserListAllowed = []; 
            const newBlockedUserList = [];
            const newName = 'recipient-'+ (this.recipientIndexListItem.recipientIndexList_ids.length+1).toString();
            const newRecipientList = [];
            const newToMessage = [];
            const newToMail = [];
            const newCcmail = [];
            const newBccMail = [];
            const newGreeting = '' ;

            const newRecipient = new Recipient ( new_recipient_id, newComponentName, newUIID, newOwner, newDateAndTime , newUpdateDateAndTime, newChat_roomid,
                                     newAllUsersAllowed, newUserListAllowed, newBlockedUserList,
                                     newName, newRecipientList , newToMessage, 
                                     newToMail, newCcmail, newBccMail , newGreeting);

        if ( !this.requestInProgress_createPersonal_Recipient_RecipientIndex_UPDATE_RecipientIndexListItem ){
              this.requestInProgress_createPersonal_Recipient_RecipientIndex_UPDATE_RecipientIndexListItem = true 
        this.createUpdateMultiComponentsService.createPersonal_Recipient_RecipientIndex_UPDATE_RecipientIndexListItem(this.user, newRecipient, newRecipientIndex_UIID , actualRecipientIndexListItem )
            .subscribe((res: any)=> {
              console.log(res)

              const newlyCreatedRecipient: Recipient = res.recipient[0]
              const newlyCreatedRecipientIndex: RecipientIndex = res.featureIndex[0]
              const updatedRecipientIndexListItem: RecipientIndexListItem = res.featureIndex[1]

              this.recipientService.addRecipient(newlyCreatedRecipient)
              this.recipientIndexService.addRecipientIndex(newlyCreatedRecipientIndex)
              this.recipientIndexListItemService.updateInitialRecipientIndexListItem(updatedRecipientIndexListItem)

              if ( this.youAreInFormEdit ){ 
                
                if ( !this.isEditMode ){ this.changeUserUrlParams_onCreateForm_Recipient(newlyCreatedRecipientIndex)}
                // if ( this.isEditMode ){ this.changeUserUrlParams_onEditForm_Recipient()}
              }

              this.isLoading = false 
              this.changeIndex.emit(newlyCreatedRecipientIndex);

              setTimeout(() => {
                this.requestInProgress_createPersonal_Recipient_RecipientIndex_UPDATE_RecipientIndexListItem = false 
              }, 300);

            }, error => {
              console.log('THROW ERROR ON CREATING RECIPIENT/INDEX/ UPDATE LIST ITEM', error)
            })

        }

        }

      }, error => {
        console.log('BUILD POP UP ERROR', error )
      })

}

/**
 * 
 * @param newRecipientIndexColumns change personal one with updated ( leave shared one as it is )
 * @param newRecipientIndexListItems change only personal ones with updated ( leave the shared one as it is)
 * @param newRecipientIndexes store all the new recipientIndexes 
 * @param newRecipients add only new recipients // if we update all recipients we cancel the shared ones
 * 
 * START PRODUCING THE NEW RECIPIENT + RECIPIENT INDEX FROM THE UPDATED RECIPIENT-INDEX LENGTH 
 */
private requestInProgress_createPersonal_Recipient_RecipientIndex_UPDATE_RecipientIndexListItem2 = false 
storeAllNewPersonalObjects_CreateNewRecipient_RecipientIndex( newRecipientIndexColumns: RecipientIndexColumn[], 
                                               newRecipientIndexListItems: RecipientIndexListItem[],
                                               newRecipientIndexes: RecipientIndex[] , 
                                               newRecipients: Recipient[] ,
                                               newRecipient_Index_UUID_length: string ){



    this.recipientIndexColumnService.updateRecipientIndexColumns(newRecipientIndexColumns)
    // this.recipientIndexListItemService.updateRecipientIndexListItems(newRecipientIndexListItems)
    this.recipientIndexService.updateRecipientIndexes(newRecipientIndexes)

    console.log('UPDATING ALL RECIPIENT INDEX COLUMNS/ LISTITEMS ETC ')

    for (let i=0; i< newRecipients.length ; i++){
         this.recipientService.addRecipient(newRecipients[i])
    } 

                  /**
                   * only update personal recipientIndexListItems , leave shared as it is 
                   * 
                  */
      const indexOfSharedRecipientIndexListItem: number = newRecipientIndexListItems.findIndex(x=> x.UIID === 'sharedRecipientIndexListItem-2')
      const currentSharedRecipientIndexListItem: RecipientIndexListItem = this.recipientIndexListItemService.getRecipientIndexListItemBy_UIID('sharedRecipientIndexListItem-2')

      newRecipientIndexListItems[indexOfSharedRecipientIndexListItem] = currentSharedRecipientIndexListItem 
      
      this.recipientIndexListItemService.updateRecipientIndexListItems(newRecipientIndexListItems)
      console.log('UPDATING ALL RECIPIENT INDEX COLUMNS/ LISTITEMS ETC ')

      // this.recipientIndexListItemService.onJustUpdateRecipientsInGenerals()

            /**
             * HERE NOW WE HAVE TO USE THE NEW RECIPIENTINDEXLISTITEM REFERENCE LENGTH+1
             */
            const actualRecipientIndexListItem : RecipientIndexListItem = newRecipientIndexListItems.find(x=> x._id=== this.recipientIndexListItem._id )
            const newRecipientIndex_UIID : string = 'recipientIndex-'+newRecipient_Index_UUID_length

            const new_recipient_id = null 
            const newComponentName = 'recipient'
            const newUIID = 'recipient-'+newRecipient_Index_UUID_length
            const newChat_roomid = null
            const newOwner = this.user ;
            const newDateAndTime = null
            const newUpdateDateAndTime = null 
            const newAllUsersAllowed = false ;
            const newUserListAllowed = []; 
            const newBlockedUserList = [];
            const newName = 'recipient-'+(actualRecipientIndexListItem.recipientIndexList_ids.length+1).toString()
            const newRecipientList = [];
            const newToMessage = [];
            const newToMail = [];
            const newCcmail = [];
            const newBccMail = [];
            const newGreeting = '' ;

            /**
             * this is a secondRecipient type
             */
            const newRecipient = new Recipient ( new_recipient_id, newComponentName, newUIID, newOwner, newDateAndTime ,newUpdateDateAndTime, newChat_roomid,
                                     newAllUsersAllowed, newUserListAllowed, newBlockedUserList,
                                     newName, newRecipientList , newToMessage, 
                                     newToMail, newCcmail, newBccMail , newGreeting);
                                     
            if ( !this.requestInProgress_createPersonal_Recipient_RecipientIndex_UPDATE_RecipientIndexListItem2 ){
                  this.requestInProgress_createPersonal_Recipient_RecipientIndex_UPDATE_RecipientIndexListItem2 = true 

            this.createUpdateMultiComponentsService.createPersonal_Recipient_RecipientIndex_UPDATE_RecipientIndexListItem(this.user, newRecipient, newRecipientIndex_UIID , actualRecipientIndexListItem )
            .subscribe((res: any)=> {
              console.log(res)

              const newlyCreatedRecipient: Recipient = res.recipient[0]
              const newlyCreatedRecipientIndex: RecipientIndex = res.featureIndex[0]
              const updatedRecipientIndexListItem: RecipientIndexListItem = res.featureIndex[1]

              this.recipientService.addRecipient(newlyCreatedRecipient)
              this.recipientIndexService.addRecipientIndex(newlyCreatedRecipientIndex)
              this.recipientIndexListItemService.updateInitialRecipientIndexListItem(updatedRecipientIndexListItem)
              /**
               * should send a subject to recipientColumns and recipientIndexListItems to update their components 
               * or it should be done all time at their service once updated 
               */

              if (this.youAreInFormEdit){ 
                if ( !this.isEditMode ){ this.changeUserUrlParams_onCreateForm_Recipient(newlyCreatedRecipientIndex)}
              }
    

              this.isLoading = false 
              this.changeIndex.emit(newlyCreatedRecipientIndex);

              setTimeout(() => {
                this.requestInProgress_createPersonal_Recipient_RecipientIndex_UPDATE_RecipientIndexListItem2 = false 
              }, 250);

            }, error => {
              console.log('THROW ERROR ON CREATING RECIPIENT/INDEX/ UPDATE LIST ITEM', error)
            })

            }

}

changeUserUrlParams_onCreateForm_Recipient(recipientIndex: RecipientIndex){

   this.selectedIndex = this.tabGroup.selectedIndex;
   console.log( 'clicked selected index',this.selectedIndex)

   this.recipientIndex = recipientIndex
   const newRecipientIndex_id: string = this.recipientIndex._id ;

   const base = 'recipientIndex-';
   const extractedString = newRecipientIndex_id.split(base)[1];
   console.log(extractedString); 

   const currentUrl = this.location.path();
   console.log(currentUrl)
   const lastIndex = currentUrl.lastIndexOf('/');
   const extractedString2 = currentUrl.substring(lastIndex + 1);

   console.log(extractedString2);

   this.router.navigate([ 'create-form', extractedString, extractedString2 ] ) 


}


changeNullUserUrlParams_onCreateForm_Recipient(recipientIndex: RecipientIndex ){

  this.selectedIndex = this.tabGroup.selectedIndex;
   console.log( 'clicked selected index',this.selectedIndex)

   this.recipientIndex = recipientIndex
   const newRecipientIndex_id: string = this.recipientIndex._id ;

   const base = 'recipientIn-';
   const extractedString = newRecipientIndex_id.split(base)[1];
   console.log(extractedString);

   const currentUrl = this.location.path();
   console.log(currentUrl)
   const lastIndex = currentUrl.lastIndexOf('/');
   const extractedString2 = currentUrl.substring(lastIndex + 1);

  this.router.navigate([ 'create-form', extractedString, extractedString2 ] ) 
   
}




/** 0 - get also all featureIndex to check recipientIndexes + indexListItems if updated 
 *  1- get http recipients to understand if we are updated ( maybe we have another page open where we created new recipients )
 *  2- store the new recipients if any
 *  3- count and start producing the new recipient using recipientIndex tot length just to be sure 
 */



createNewChat(){

  console.log( 'creating new recipient and chat')

  /** this newTabIndex0 is actually the latest recipientIds in this recioientGroup only and not 
   * the latest recipient 
   */
this.newTabIndex0 = this.recipientIndexListItem.recipientIndexList_ids.length+1 ;

const new_recipient_id = 'recipi-'+(this.recipientIndexService.getRecipientIndexes().length+1).toString()
const newComponentName = 'recipient'
const newUIID = 'recipient-'+(this.recipientIndexService.getRecipientIndexes().length+1).toString()
const newChat_roomid = null
const newOwner = this.user ;
const newDateAndTime = null 
const newUpdateDateAndTime = null 
const newAllUsersAllowed = false ;
const newUserListAllowed = []; 
const newBlockedUserList = [];
const newName ='recipient-'+ (this.newTabIndex0).toString()
const newRecipientList = [];
const newToMessage = [];
const newToMail = [];
const newCcmail = [];
const newBccMail = [];
const newGreeting = '' ;

const newRecipient = new Recipient ( new_recipient_id, newComponentName, newUIID, newOwner, newDateAndTime , newUpdateDateAndTime, newChat_roomid,
                                     newAllUsersAllowed, newUserListAllowed, newBlockedUserList,
                                     newName, newRecipientList , newToMessage, 
                                     newToMail, newCcmail, newBccMail , newGreeting);


  const newChatSender = new Sender ( this.user) 
  const newPartecipants = []
  const newPartecipantGroup = new PartecipantGroup (newChatSender, newPartecipants) ;
  console.log(newPartecipantGroup)
  const new_id = null
  const newChatAllUsersAllowed = false ;
  const newChatUserListAllowed = []; 
  const newIsVisible = false ;
  const newChatRoomDateAndTime = null
  const newChatRoomUpdateDateAndTime = null
  const newChatRecipient = newRecipient 
  const newIsNewChat = true
  const newIsBeenUpdated = false
  const newIsSelected = false ; 
  const newSubject = ''
    
  const newChatRoom = new Chat (  
    new_id, 
    this.user, 
    newChatAllUsersAllowed,
    newChatUserListAllowed,
    newIsVisible,
    newChatRoomDateAndTime ,
    newChatRoomUpdateDateAndTime,
    newIsNewChat, 
    newIsBeenUpdated, 
    newIsSelected , 
    newSubject
  )                               
 
  console.log(newChatRoom)
  console.log(newChatRecipient)

  if ( this.user === null ){
    this.recipientService.addRecipient(newChatRecipient),

    this.recipients = this.recipientService.getRecipients(),
    console.log('recipisnts list = ', this.recipients )
    this.onCreateNewRecipientIndex(newRecipient._id)
  }


}

/** add recipientId to RecipientIndex service */
onCreateNewRecipientIndex(recipient_id: string ){
  
  if ( this.recipientIndexListItem.recipientIndexList_ids.length < this.recipientsLimit ){
    this.createButtonLimit = true;
  } else {
    this.createButtonLimit = false 
  };

  //----------------------------------------------------------
  /** here below we build again the new recipientIndex to be emitted AND we need to UPDATE HTTP and add the new 
   * recip_id ot the recipIds  */ 
const new_id = 'recipientIn-'+(this.recipientIndexService.getRecipientIndexes().length+1).toString()  ;
const newComponentName = 'recipientIndex'
const newUIID = 'recipientIndex-'+(this.recipientIndexService.getRecipientIndexes().length+1).toString() 
const newOwner = this.user ;
const newAllUsersAllowed = false ;
const newUserListAllowed = [];
const newDateAndTime = null ;
const newIsAccepted = true 
const newUseMyOwnMailRecipient = true 
const newDropId = 0 
const newRecipient_id = recipient_id 
const newMailRecipient_idCopy = null 

const newRecipientIndex = new RecipientIndex ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed,
                                                          newDateAndTime,newIsAccepted,newUseMyOwnMailRecipient,  newDropId , newRecipient_id , newMailRecipient_idCopy)

if (this.user === null){
  this.recipientIndexService.addRecipientIndex(newRecipientIndex)
  this.newRecpientIndexForUpdateForm = newRecipientIndex
  console.log(this.newRecpientIndexForUpdateForm)
  this.onUpdateRecipientIndexListItem(newRecipientIndex._id)
 
}

}


onUpdateRecipientIndexListItem(recipientIndex_id: string){
/**
 * updating recipientIndexListItem with new added recipientIndex_id 
 */
console.log(this.recipientIndexListItem)
const new_id = this.recipientIndexListItem._id
const newComponentName = this.recipientIndexListItem.componentName
const newUIID = this.recipientIndexListItem.UIID
const newOwner = this.user
const newAllUsersAllowed = this.recipientIndexListItem.allUsersAllowed
const newUserListAllowed = this.recipientIndexListItem.userListAllowed
const newDateAndTime = this.recipientIndexListItem.dateAndTime
const newRecipientIndexListName = this.recipientIndexListItem.recipientIndexListName
const newRecipientIndexList_ids = this.recipientIndexListItem.recipientIndexList_ids
console.log(newRecipientIndexList_ids)

newRecipientIndexList_ids.push(recipientIndex_id)
const newRecipientIndexSelected_id = this.recipientIndexListItem.recipientIndexSelected_id

const newRecipientIndexListItem = new RecipientIndexListItem ( new_id, newComponentName, newUIID, newOwner, newAllUsersAllowed, newUserListAllowed , 
                       newDateAndTime, newRecipientIndexListName, newRecipientIndexList_ids, newRecipientIndexSelected_id )

if ( this.user === null ){
this.recipientIndexListItem = newRecipientIndexListItem

this.recipientIndexListItemService.recipientIndexListItems[this.recipientIndexListItemService.getRecipientIndexListItems().findIndex(x=> x._id === newRecipientIndexListItem._id)] = newRecipientIndexListItem

console.log(this.recipientIndexListItemService.getRecipientIndexListItems())


// if( this.youAreInFormEdit ){ 
//          if ( !this.isEditMode ){ this.changeNullUserUrlParams_onCreateForm_Recipient(this.recipientIndex )}
// }
        
this.isLoading = false

/**emit the event to FORM-EDIT to determine the right recipientIndex if the form issent or shared  */
this.changeIndex.emit(this.newRecpientIndexForUpdateForm);
console.log('', this.newRecpientIndexForUpdateForm)

if( this.youAreInFormEdit ){ 
  if ( !this.isEditMode ){ 
    const newRecipientIndex = this.recipientIndexService.getRecipientIndexBy_id(recipientIndex_id)
    this.changeNullUserUrlParams_onCreateForm_Recipient( newRecipientIndex )}
}

}


};



onEmitSecondRecipientResult($event){
  this.emitSecondRecipientResult.emit($event)
}

onEmitRecipientResult($event){
  this.emitRecipientResult.emit($event)
}

ngOnDestroy(){
  this.updateOnUserEmail.unsubscribe()
  this.startLoadRecipientItemSubscription.unsubscribe()
  this.updateSharedRecipientIndexListItem_FromRecipientFeatureSubscription.unsubscribe()
}




}


