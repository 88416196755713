import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import { Tab } from './tab.model';
import { TabService } from './tab.service';
import { MatTabGroup } from '@angular/material/tabs';
import { Feature } from '../../feature.model';
import { Observable, Subject, Subscription  } from 'rxjs';
import { TabDetail } from './tab-detail.model';
import { PostComponentsHttpRequestService } from 'src/app/services/post-and-put-services/post-components-http-request.service';
import { UserObjectUpdateService } from 'src/app/services/other-components-services/user-object-update.service';
import { CreateNewFeatureContainerAndAddToFeatureContainerListItemService } from 'src/app/services/shared-services/create-new-feature-container-and-update-feature-container-list-item.service';
import { AdviseIfHttpStoreComponentsCallHasBeenMadeService } from 'src/app/services/other-components-services/advise-if-http-store-components-has-been-made.service';
import { StoreBaseComponentsService } from 'src/app/services/get-and-store-components/store-base-components.service';
import { FeatureIndexTopicList } from '../../../../paragraph-edit/feature-index-topic-list.model';
import { FeatureIndexTopicListService } from '../../../../paragraph-edit/feature-index-topic-list.service';
import { FeatureContainerListItem } from '../../../../paragraph-edit/feature-container-list-item.model';
import { FeatureContainerListItemService } from '../../../../paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container-list-item/feature-container-list-items.service';
import { FeatureContainer } from '../../../../paragraph-edit/feature-container.model';
import { FeatureContainerService } from '../../../../paragraph-edit/feature-index-topic-list/feature-index-topic/feature-container/feature-container.service';


@Component({
  selector: 'app-tab-list',
  templateUrl: './tab-list.component.html',
  styleUrls: ['./tab-list.component.css']
})
export class TabListComponent implements OnInit , OnDestroy {

  user : string 

  thisTypeOfComponent:string = 'tabs'

  tabList: Tab[]
  tab: Tab
  @ViewChild('tabs') tabGroup: MatTabGroup

  // @Input() selectedIndex_id: string;
  @Input() feature_from_paragraphEdit: Feature 
  selectedIndex: number;
  @Input() currentI : number ;
  @Input() isParagraphEdit: boolean


   /**
   * we need to have anoter boolean to control if the normal components receive the subscriptions 
   * and not receiving them when they are under tabs and not under paragraphEdit 
   */
    isUnderTabs: boolean  = true


  @Output() eventIndex: number ;

  @Output() changeIndex = new EventEmitter<Feature>() 

  index: number 
  feature: Feature
  currentFeature: Feature


  receiveFeatureInfoFromParagraphEdit: Subscription; 
  @Input() collectFeatureId: Observable<Feature>

  newTabIndex0: number
  newTabIndex: number

  isLoading: boolean
  isLoadingList: boolean 

  updateOnUserEmail: Subscription

  matToolTipMessageOnCreateNew: string 

  createButtonLimit : boolean ;
  tabListLimit = 10 ;

  initialPersonalTabsHaveBeenStored: Subscription 
  featureContainerPlusParentsHaveBeenStored: Subscription



  constructor(private tabService: TabService,
              private userObjectUpdateService: UserObjectUpdateService ,
              private storeBaseComponentsService: StoreBaseComponentsService ,
              private adviseIfHttpStoreComponentsCallHasBeenMadeService: AdviseIfHttpStoreComponentsCallHasBeenMadeService , 
              private postComponentsHttpRequestService:PostComponentsHttpRequestService,
              private featureIndexTopicListService: FeatureIndexTopicListService , 
              private featureContainerListItemService: FeatureContainerListItemService ,
              private featureContainerService: FeatureContainerService , 
              private createNewFeatureContainerAndAddToFeatureContainerListItemService: CreateNewFeatureContainerAndAddToFeatureContainerListItemService ) { }

  ngOnInit(){

    this.user = this.userObjectUpdateService.userEmail

    this.updateOnUserEmail = this.userObjectUpdateService.updateUserEmail
    .subscribe((updatedUserEmail)=>{
     this.user = updatedUserEmail
     console.log('updated USER = ', this.user)
    })

    this.isLoadingList = true 

    if ( this.user === null ){ this.continueInitialOnInit()}
    if ( this.user !== null ){
      if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.tabsHasBeenStored === true  ){  this.continueInitialOnInit() }
      if ( this.adviseIfHttpStoreComponentsCallHasBeenMadeService.tabsHasBeenStored === false ){ this.storeBaseComponentsService.callBaseComponentsListByUserAndType(this.user , 'tabs') }
    }

    this.receiveFeatureInfoFromParagraphEdit = this.collectFeatureId.subscribe((data)=> this.updateCurrentFeature(data))

    this.initialPersonalTabsHaveBeenStored = this.tabService.inilitalPersonalTabListHaveBeenStored
        .subscribe(()=> {
          this.continueInitialOnInit()
          this.adviseIfHttpStoreComponentsCallHasBeenMadeService.tabsHasBeenStored = true 
    })

    this.featureContainerPlusParentsHaveBeenStored = this.featureContainerListItemService.newFeatureContainerAndparentsHaveBeenStored
    .subscribe((feature_plus_typeOfComponent: any )=> {

     const currentFeature:Feature = feature_plus_typeOfComponent.feature 
     const currentTypeOfComponent: string = feature_plus_typeOfComponent.featureContainerName
     console.log(feature_plus_typeOfComponent)
 
     if ( currentTypeOfComponent === this.thisTypeOfComponent ){
          this.onEmitToParagraphEditChangedIndex(currentFeature)
     }
    })
    
  }

  continueInitialOnInit(){

    const featureIndexTopicListItem: FeatureIndexTopicList = this.featureIndexTopicListService.getFeatureIndexTopicListItem_by_type('personal')
    console.log('feature index topic list item = ',featureIndexTopicListItem)
  
    const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('tabs')

    this.tabList = []
    for ( let i=0; i<personalFeatureContainerListItem.featureContainer_ids.length ; i++ ){
          let currentFeatureContainer: FeatureContainer = this.featureContainerService.getFeatureContainerBy_id(personalFeatureContainerListItem.featureContainer_ids[i])
          this.tabList.push(this.tabService.getTabBy_id(currentFeatureContainer.componentReference_id))
    }
    console.log(this.tabList)

    this.selectedIndex =  this.tabList.findIndex(x=> x._id === this.feature_from_paragraphEdit.feature_id)

    this.matToolTipMessageOnCreateNew = " CREATE A COPY OF  '1 - "+this.tabList[0].name+"'"
    
  if ( this.tabList.length < this.tabListLimit){ this.createButtonLimit = true }
  if ( this.tabList.length >= this.tabListLimit ) { this.createButtonLimit = false }
   
  if (this.user === null ){ setTimeout(()=>{ this.isLoading = false , this.isLoadingList = false },200) }
  if (this.user !== null ){ this.isLoading = false , this.isLoadingList = false  }
 
    console.log(this.tabList , this.currentI)

  }


  /** for each click we recreate a new feature  */
onLinkClick($event) {

  this.index = this.tabGroup.selectedIndex ; 
 
  const newOwner = this.user 
  const newIsNew = false
  const newDropId = this.currentI;
  const newFeature_id = this.tabList[this.index]._id
  const newFeatureName = 'tabs' ;
  const newFeature = new Feature ( newOwner, newIsNew, newDropId, newFeature_id, newFeatureName) ;
 
  this.feature = newFeature;
 
  this.changeIndex.emit(this.feature)
  this.eventIndex = this.currentI ;
 
  console.log('drop id =', this.currentI)
  console.log(this.feature)
 
   }

    /**
   * 
   * @param data feature that is been transmitted from paragraphEdit to check which of the 
   * features list has to pass the subscription to the feature item to be edited 
   */
  updateCurrentFeature(data){
    this.currentFeature = data;
    console.log(this.currentFeature)
  }

  Create(){
    this.isLoading = true 
    this.isLoadingList = true 
    this.onCreate()
  }


  onCreate(){

    const personalFeatureContainerListItem: FeatureContainerListItem = this.featureContainerListItemService.getPersonalFeatureContainersLisItemBy_Type('tabs')
    this.newTabIndex0 = personalFeatureContainerListItem.featureContainer_ids.length+1
    
    const new_id = 't'+this.newTabIndex0.toString() 
    const newComponentName = 'tab'
    const newUIID ='tab-'+this.newTabIndex0.toString() 
    const newOwner = this.user
    const newDateAndTime = null 
    const newAllUsersAllowed = false
    const newUserListAllowed = []
    const newTabName = 'new tab '+ this.newTabIndex0

    const newTabDetails = this.tabList[0].tabDetails
    const newFeatures_id_list = this.tabList[0].feature_id_list


    const newTab = new Tab ( new_id, newComponentName, newUIID, newOwner, newDateAndTime, 
                             newAllUsersAllowed, newUserListAllowed, 
                             newTabName, newTabDetails , newFeatures_id_list)

    if ( this.user === null ){

      this.tabService.addTab(newTab)

      const newOwner = this.user; 
      const newIsNew = false
      const newDropId = this.currentI ;
      const newFeature_id = newTab._id
      const newFeatureName = 'tabs' ;
      const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
  
      this.feature = newFeature

      this.tabList.push(newTab)

      if ( this.tabList.length < this.tabListLimit){ this.createButtonLimit = true }
      if ( this.tabList.length >= this.tabListLimit ) { this.createButtonLimit = false }
   
      this.createNewFeatureContainerAndAddToFeatureContainerListItemService.createFeatureContainer_toAddOnFeatureContainerListItem(newFeature, this.user )

    }

    if ( this.user !== null ){
    this.postComponentsHttpRequestService.httpStoreComponent(this.user, 'tab', newTab )
        .subscribe((response: Tab)=> {

      this.tabService.addTab(response)

      this.adviseIfHttpStoreComponentsCallHasBeenMadeService.add_TabComponent_id_NotStoredYet(response._id)

      const newOwner = this.user; 
      const newIsNew = false
      const newDropId = this.currentI ;
      const newFeature_id = response._id
      const newFeatureName = 'tabs' ;
      const newFeature = new Feature ( newOwner, newIsNew, newDropId , newFeature_id, newFeatureName) ;
  
      this.feature = newFeature
      this.tabList.push(response)

      if ( this.tabList.length < this.tabListLimit){ this.createButtonLimit = true }
      if ( this.tabList.length >= this.tabListLimit ) { this.createButtonLimit = false }

      this.createNewFeatureContainerAndAddToFeatureContainerListItemService.createFeatureContainer_toAddOnFeatureContainerListItem(newFeature, this.user )

        })
    }


  }

  
  /**
   * export class  TabDetail {

    constructor  ( public name: string, 
                   public features: Feature[] ) {}
}

   * export class Tab {

constructor(  public _id : string ,
              public owner : string ,
              public dateAndTime: string,
              public allUsersAllowed: boolean, 
              public userListAllowed: string[] ,  
              public name: string ,  
              public tabDetails: TabDetail[] ){}

   */
  onEmitToParagraphEditChangedIndex(toEmitFeature: Feature ){
      console.log('is emitting changed feature')
      this.changeIndex.emit(toEmitFeature)
                // once this feature is emitted the paragraphEdit will change index and this whole 
                // component will restart again
  }

  ngOnDestroy() {
    this.updateOnUserEmail.unsubscribe()
    this.initialPersonalTabsHaveBeenStored.unsubscribe()
    this.featureContainerPlusParentsHaveBeenStored.unsubscribe()
  }



}
