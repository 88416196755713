import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { NgModule , CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "src/app/app-routing.module";
import { MaterialDesignModule } from "src/app/material-design/material-design.module";
import { SharedModule } from "src/app/shared/shared.module";
import { EmailButtonComponent } from "./email-button/email-button.component";
import { PhraseButtonComponent } from "./phrase-button/phrase-button.component";
import { ToggleSingleWordButtonEditComponent } from "./toggle-single-word-button/toggle-single-word-button-list/toggle-single-word-button-edit/toggle-single-word-button-edit.component";
import { ToggleSingleWordButtonItemComponent } from "./toggle-single-word-button/toggle-single-word-button-list/toggle-single-word-button-item/toggle-single-word-button-item.component";
import { ToggleSingleWordButtonListComponent } from "./toggle-single-word-button/toggle-single-word-button-list/toggle-single-word-button-list.component";
import { ToggleSingleWordButtonComponent } from "./toggle-single-word-button/toggle-single-word-button.component";
import { ToggleWordButtonEditComponent } from "./toggle-word-button/toggle-word-button-list/toggle-word-button-edit/toggle-word-button-edit.component";
import { ToggleWordButtonItemComponent } from "./toggle-word-button/toggle-word-button-list/toggle-word-button-item/toggle-word-button-item.component";
import { ToggleWordButtonListComponent } from "./toggle-word-button/toggle-word-button-list/toggle-word-button-list.component";
import { ToggleWordButtonComponent } from "./toggle-word-button/toggle-word-button.component";
import { WordButtonComponent } from "./word-button/word-button.component";
import { DoubleClickButtonComponent } from './double-click-button/double-click-button.component';
import { AuxiliaryComponentsModule } from "src/app/loading-spinner/auxiliary-components.module";


@NgModule({
    declarations: [
        EmailButtonComponent,
        WordButtonComponent,
        PhraseButtonComponent,
        ToggleWordButtonComponent,
        ToggleWordButtonListComponent,
        ToggleWordButtonItemComponent,
        ToggleWordButtonEditComponent,
        ToggleSingleWordButtonComponent,
        ToggleSingleWordButtonListComponent,
        ToggleSingleWordButtonItemComponent,
        ToggleSingleWordButtonEditComponent,
        DoubleClickButtonComponent,

    ],

    exports: [
        EmailButtonComponent,
        WordButtonComponent,
        PhraseButtonComponent,
        ToggleWordButtonComponent,
        ToggleWordButtonListComponent,
        ToggleWordButtonItemComponent,
        ToggleWordButtonEditComponent,
        ToggleSingleWordButtonComponent,
        ToggleSingleWordButtonListComponent,
        ToggleSingleWordButtonItemComponent,
        ToggleSingleWordButtonEditComponent,
        DoubleClickButtonComponent,
    ],

    imports:[

    AppRoutingModule,
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    MaterialDesignModule, 
    ReactiveFormsModule,
    MatDialogModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatExpansionModule,
    SharedModule,
    AuxiliaryComponentsModule

    ],

    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})
export class ButtonsModule{}